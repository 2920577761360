import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ChartsModule } from 'ng2-charts';
// import { ChartistModule } from 'ng-chartist';
// import { NgxChartsModule } from '@swimlane/ngx-charts';

import { CalendarModule, CalendarDateFormatter } from 'angular-calendar';

/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends


import { DashboardRoutes } from 'src/app/dashboards/dashboard.routing';


import { Dashboard1Component } from 'src/app/dashboards/dashboard1/dashboard1.component';


import { DelayedTripsComponent } from 'src/app/dashboards/dashboard-components/delayed-trips/delayed-trips.component';
import { FortnightStatementSummaryComponent } from 'src/app/dashboards/dashboard-components/fortnight-statement-summary/fortnight-statement-summary.component';
import { ScheduledTripsFromComponent } from 'src/app/dashboards/dashboard-components/scheduled-trips-from/scheduled-trips-from.component';
import { TripsOnTransitFromComponent } from 'src/app/dashboards/dashboard-components/trips-on-transit-from/trips-on-transit-from.component';
import { TripsOnTransitToComponent } from 'src/app/dashboards/dashboard-components/trips-on-transit-to/trips-on-transit-to.component';
import { ScheduledTripsToComponent } from 'src/app/dashboards/dashboard-components/scheduled-trips-to/scheduled-trips-to.component';
import { SourceStocksSummaryComponent } from 'src/app/dashboards/dashboard-components/source-stocks-summary/source-stocks-summary.component';
import { StocksSummaryComponent } from 'src/app/dashboards/dashboard-components/stocks-summary/stocks-summary.component';
import { AgentRankingComponent } from 'src/app/dashboards/dashboard-components/agent-ranking/agent-ranking.component';
import { BookingAgentStatementComponent } from 'src/app/dashboards/dashboard-components/booking-agent-statement/booking-agent-statement.component';
import { DoorDeliveryConsignmentNotDeliveredDetailsComponent } from 'src/app/dashboards/dashboard-components/door-delivery-consignment-not-delivered-details/door-delivery-consignment-not-delivered-details.component';
import { UnpaidBookingAgentStatementComponent } from 'src/app/dashboards/dashboard-components/unpaid-booking-agent-statement/unpaid-booking-agent-statement.component';
import { SrdLabourHamaliStatementComponent } from 'src/app/dashboards/dashboard-components/srd-labour-hamali-statement/srd-labour-hamali-statement.component';
import { BookingStationsStocksSummaryComponent } from 'src/app/dashboards/dashboard-components/booking-stations-stocks-summary/booking-stations-stocks-summary.component';
import { LrsShortAndExtraComponent } from 'src/app/dashboards/dashboard-components/lrs-short-and-extra/lrs-short-and-extra.component';
import { CashmemoPendingFromBinnymillsComponent } from 'src/app/dashboards/dashboard-components/cashmemo-pending-from-binnymills/cashmemo-pending-from-binnymills.component';
import { LocalTripsComponent } from 'src/app/dashboards/dashboard-components/local-trips/local-trips.component';
import { PendingHireslipGenerationComponent } from 'src/app/dashboards/dashboard-components/pending-hireslip-generation/pending-hireslip-generation.component';
import { ScheduledLocalTripsComponent } from 'src/app/dashboards/dashboard-components/scheduled-local-trips/scheduled-local-trips.component';
import { CashmemoPendingAndMissingComponent } from 'src/app/dashboards/dashboard-components/cashmemo-pending-and-missing/cashmemo-pending-and-missing.component';
import { FinalNoticePendingEmailComponent } from 'src/app/dashboards/dashboard-components/final-notice-pending-email/final-notice-pending-email.component';
import { PendingConsigneeComponent } from 'src/app/dashboards/dashboard-components/pending-consignee/pending-consignee.component';
import { CodLrDetailsComponent } from 'src/app/dashboards/dashboard-components/cod-lr-details/cod-lr-details.component';
import { ExpensesOsPaymentFollowupComponent } from 'src/app/dashboards/dashboard-components/expenses-os-payment-followup/expenses-os-payment-followup.component';
import { PendingHamaliMemosComponent } from 'src/app/dashboards/dashboard-components/pending-hamali-memos/pending-hamali-memos.component';
import { PendingStocksSmsComponent } from 'src/app/dashboards/dashboard-components/pending-stocks-sms/pending-stocks-sms.component';
import { TrucksRequiredForTripComponent } from 'src/app/dashboards/dashboard-components/trucks-required-for-trip/trucks-required-for-trip.component';
import { TripsOfAgentBookingComponent } from 'src/app/dashboards/dashboard-components/trips-of-agent-booking/trips-of-agent-booking.component';
import { LeftToPartyStatementsPendingComponent } from 'src/app/dashboards/dashboard-components/left-to-party-statements-pending/left-to-party-statements-pending.component';
import { TransitLrsComponent } from 'src/app/dashboards/dashboard-components/transit-lrs/transit-lrs.component';
import { SummaryComponent } from 'src/app/dashboards/dashboard-components/summary/summary.component';
import { BlockLrDetailsComponent } from 'src/app/dashboards/dashboard-components/block-lr-details/block-lr-details.component';
import { PendingCommodityComponent } from 'src/app/dashboards/dashboard-components/pending-commodity/pending-commodity.component';


import { LrDetailsOfRemainingCashMemosComponent } from 'src/app/dashboards/dashboards-popup/lr-details-of-remaining-cash-memos/lr-details-of-remaining-cash-memos.component';
import { EditConsigneeDetailsComponent } from 'src/app/dashboards/dashboards-popup/edit-consignee-details/edit-consignee-details.component';
import { PendingLrDetailsComponent } from 'src/app/dashboards/dashboards-popup/pending-lr-details/pending-lr-details.component';
import { LrDetailsComponent } from 'src/app/dashboards/dashboards-popup/lr-details/lr-details.component';
import { LabourHamaliStatementDetailsComponent } from 'src/app/dashboards/dashboards-popup/labour-hamali-statement-details/labour-hamali-statement-details.component';
import { BookingTripDetailsComponent } from 'src/app/dashboards/dashboards-popup/booking-trip-details/booking-trip-details.component';
import { MaterialModule } from "src/app/material.module";
import { } from 'src/app/stock/driver/barcode-scanning/barcode-scanning.component';
import { DriverModule } from 'src/app/stock/driver/driver.module';
import { AgentStockReportComponent } from 'src/app/dashboards/dashboards-popup/agent-stock-report/agent-stock-report.component';
import { TripDeliveryConfirmationDetailsComponent } from 'src/app/dashboards/dashboards-popup/trip-delivery-confirmation-details/trip-delivery-confirmation-details.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { TripsOnTransitComponent } from 'src/app/dashboards/dashboard-components/trips-on-transit/trips-on-transit.component';
import { FortnightStatementDetailsComponent } from 'src/app/dashboards/dashboards-popup/fortnight-statement-details/fortnight-statement-details.component';
import { EntryModule } from 'src/app/lr/entry/entry.module';
import { StatementReportsModule } from 'src/app/report/statement-reports/statement-reports.module';
import { HamaliReportModule } from 'src/app/report/hamali-report/hamali-report.module';
import { BookingAgentStatementDetailsComponent } from 'src/app/dashboards/dashboards-popup/booking-agent-statement-details/booking-agent-statement-details.component';
import { BookingsModule } from 'src/app/report/bookings/bookings.module';
import { StocksDestinationSummaryComponent } from 'src/app/dashboards/dashboard-components/stocks-destination-summary/stocks-destination-summary.component';
import { BookingTripDetailsBarcodeComponent } from 'src/app/dashboards/dashboards-popup/booking-trip-details-barcode/booking-trip-details-barcode.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        // ChartsModule,
        // ChartistModule,
        // RouterModule.forChild(DashboardRoutes),
        PerfectScrollbarModule,
        CalendarModule.forRoot(),
        // NgxChartsModule,
        DataTablesModule,
        MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, 
        AutocompleteModule, DailyReportModule, MatDialogModule, MaterialModule, DriverModule,
        CashmemoReportModule, EntryModule,StatementReportsModule,HamaliReportModule,BookingsModule
    ],
    declarations: [
        Dashboard1Component,
        DelayedTripsComponent,
        FortnightStatementSummaryComponent,
        ScheduledTripsFromComponent,
        TripsOnTransitFromComponent,
        TripsOnTransitToComponent,
        ScheduledTripsToComponent,
        SourceStocksSummaryComponent,
        StocksSummaryComponent,
        AgentRankingComponent,
        BookingAgentStatementComponent,
        DoorDeliveryConsignmentNotDeliveredDetailsComponent,
        UnpaidBookingAgentStatementComponent,
        SrdLabourHamaliStatementComponent,
        BookingStationsStocksSummaryComponent,
        LrsShortAndExtraComponent,
        CashmemoPendingFromBinnymillsComponent,
        LocalTripsComponent,
        PendingHireslipGenerationComponent,
        ScheduledLocalTripsComponent,
        CashmemoPendingAndMissingComponent,
        FinalNoticePendingEmailComponent,
        PendingConsigneeComponent,
        CodLrDetailsComponent,
        ExpensesOsPaymentFollowupComponent,
        PendingHamaliMemosComponent,
        PendingStocksSmsComponent,
        TrucksRequiredForTripComponent,
        TripsOfAgentBookingComponent,
        LeftToPartyStatementsPendingComponent,
        TransitLrsComponent,
        SummaryComponent,
        BlockLrDetailsComponent,
        PendingCommodityComponent,
        LrDetailsOfRemainingCashMemosComponent,
        EditConsigneeDetailsComponent,
        PendingLrDetailsComponent,
        LrDetailsComponent,
        LabourHamaliStatementDetailsComponent,
        BookingTripDetailsComponent,
        AgentStockReportComponent,
        TripDeliveryConfirmationDetailsComponent,
        TripsOnTransitComponent,
        FortnightStatementDetailsComponent,
        BookingAgentStatementDetailsComponent,
        StocksDestinationSummaryComponent,
        BookingTripDetailsBarcodeComponent
    ],

    entryComponents: [LrDetailsComponent, LrDetailsOfRemainingCashMemosComponent, EditConsigneeDetailsComponent, PendingLrDetailsComponent, AgentStockReportComponent, TripDeliveryConfirmationDetailsComponent],
    exports: [
        ScheduledTripsFromComponent
    ],

})
export class DashboardModule { }
