<h1 class="title_custom" mat-dialog-title>
    <h6 style="margin-bottom: 0px;">Godown Stocks Report LR Details</h6>
</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div *ngIf="showSpinnerForAction" class="col-md-8">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center'
                                    style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="box-body">
                            <table datatable id="{{pageId}}lrDetailsPopupTable"
                                class="table table-striped table-bordered row-border hover"
                                [dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">
                                <thead>
                                    <tr>
                                        <th>LR No</th>
                                        <th>Consignee</th>
                                        <th>Consignor</th>
                                        <th>Bkg Date</th>
                                        <th>Art</th>
                                        <th>Src</th>
                                        <th>Dest</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>Commodity</th>
                                        <th>Arrival Date</th>
                                        <th>In Stock Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lrDetailList of lrDetailLists">
                                        <td>{{ lrDetailList.lrNumber }}</td>
                                        <td>{{ lrDetailList.consigneeName }}</td>
                                        <td>{{ lrDetailList.consignorName }}</td>
                                        <td>{{ lrDetailList.bookingDateStr }}</td>
                                        <td>{{ lrDetailList.totalArticles }}</td>
                                        <td>{{ lrDetailList.source }}</td>
                                        <td>{{ lrDetailList.destination }}</td>
                                        <td>{{ lrDetailList.actualWeight }}</td>
                                        <td>{{ lrDetailList.chargedWeight }}</td>
                                        <td>{{ lrDetailList.column2 }}</td>
                                        <td>{{ lrDetailList.unloadingDateStr }}</td>
                                        <td>{{ lrDetailList.diffDays }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="float: right;">
    <button class="btn btn-outline-success" mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</div>