import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { StocksInTakenReportComponent } from 'src/app/report/stocks-report/stocks-in-taken-report/stocks-in-taken-report.component';
import { CommodityReportComponent } from 'src/app/report/stocks-report/commodity-report/commodity-report.component';
import { GodownStocksBookingReportComponent } from 'src/app/report/stocks-report/godown-stocks-booking-report/godown-stocks-booking-report.component';
import { GodownStocksReportComponent } from 'src/app/report/stocks-report/godown-stocks-report/godown-stocks-report.component';
import { PendingStocksDetailsReportComponent } from 'src/app/report/stocks-report/pending-stocks-details-report/pending-stocks-details-report.component';
import { StocksUnloadingReportComponent } from 'src/app/report/stocks-report/stocks-unloading-report/stocks-unloading-report.component';
import { StorageReportComponent } from 'src/app/report/stocks-report/storage-report/storage-report.component';
import { MaterialModule } from "src/app/material.module";
import { DeliveryUnloadedLrDetainedComponent } from 'src/app/report/stocks-report/delivery-unloaded-lr-detained/delivery-unloaded-lr-detained.component';
import { DeliveryUnloadedLrNotDetainedComponent } from 'src/app/report/stocks-report/delivery-unloaded-lr-notdetained/delivery-unloaded-lr-notdetained.component';
import { BookingAgentDispatchPerformanceReportComponent } from 'src/app/report/stocks-report/booking-agent-dispatch-performance-report/booking-agent-dispatch-performance-report.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { LrReportModule } from 'src/app/report/lr-report/lr-report.module';
import { CommodityReportDetailsPrintComponent } from 'src/app/report/stocks-report/commodity-report-details-print/commodity-report-details-print.component';
import { StorageReportLrDetailsPopupComponent } from './storage-report-lr-details-popup/storage-report-lr-details-popup.component.';
import { GodownStocksRptLrDetailsPopupComponent } from './godown-stocks-rpt-lr-details-popup/godown-stocks-rpt-lr-details-popup.component';
import { DestinationStocksReportComponent } from './destination-stocks-report/destination-stocks-report.component';
@NgModule({
    imports: [CommonModule,
        //  RouterModule.forChild(StocksReportRoutes),
        FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, 
        ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule, CashmemoReportModule, LrReportModule],

    declarations: [
        StocksInTakenReportComponent,
        CommodityReportComponent,
        GodownStocksBookingReportComponent,
        GodownStocksReportComponent,
        PendingStocksDetailsReportComponent,
        StocksUnloadingReportComponent,
        StorageReportComponent,
        DeliveryUnloadedLrDetainedComponent,
        DeliveryUnloadedLrNotDetainedComponent,
        BookingAgentDispatchPerformanceReportComponent,
        CommodityReportDetailsPrintComponent,
        StorageReportLrDetailsPopupComponent,
        GodownStocksRptLrDetailsPopupComponent,
        DestinationStocksReportComponent
    ],
    entryComponents: [StorageReportLrDetailsPopupComponent, GodownStocksRptLrDetailsPopupComponent
    ]
})
export class StocksReportModule { }