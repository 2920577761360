export class RequestDto {
    status: string;
    mode: string;
    mobileNo: string;
    lrNumber: string;
    otp: string;
    userId: string;
    type: string;
    consigneeId: number;
    param1: string;
    param2: string;
    companyId: string;
    param3: number;
    list: String[];
    param4: string;
    param5: string;
    pincode: string;
    apiType: string;
    destination: string;
    modeV2: string;
    mainStation: string;
    partyType: string;
    id: number;
}