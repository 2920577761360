//CHG_Ver1: Chnaged Destinatin dropdown data to agentsubstation masters substation By Imran on 03052024 

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterService } from 'src/app/dataService/master-service';
import { Observable, Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { LRDto } from 'src/app/dto/LR-dto';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { merge } from 'rxjs';

@Component({
    selector: 'app-specific-consignee-details',
    templateUrl: './specific-consignee-details.component.html',
    styleUrls: ['./specific-consignee-details.component.css']
})
export class SpecificConsigneeDetailsComponent implements OnInit {

    consigneeDataList: any;
    gstNumberDataList: any;
    consigneeNameDataList: any;
    destinationDataList: any;
    partyMasterDtoSearch: PartyMasterDto = new PartyMasterDto();
    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    partyMasterDeleteDto: PartyMasterDto = new PartyMasterDto();
    partyMasterDtoCheckRet: any;
    partyMasterDtoSaveRet: any;
    partyMasterDtoDelelteRet: any;
    saveResp: string;


    selectedConsigneeName: any;
    selectedGstNumber: any;
    selectedCity: any;
    loadingIndicator = true;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    consingeeId = 0;
    gstNumber: any;
    consigneeName: any;
    gstNoConsignee: any;
    id = 0;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerSpecificConsigneeDetails: Subject<any> = new Subject();
    dtOptionsSpecificConsigneeDetails: any;
    validateSavePackName: any;
    validateDelete: any;
    pageId = "scdct";
    destinationName = [
        { id: 1, destinationName: 'Ernakulam' },
        { id: 2, destinationName: 'Edappally' },
    ];
    showSpinnerForAction = false;

    lrDtoDestination: LRDto = new LRDto();
    destinationOptions: LRDto[];
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    constructor(private masterService: MasterService, private masterReadService: MasterReadService,
        private router: Router, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
            this.getSpecificConsigneeList();
        }
    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) {
            el.focus();
        }
    }

    ngOnInit(): void {
        this.dtOptionsSpecificConsigneeDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerSpecificConsigneeDetails.unsubscribe();
    }

    // ngAfterViewInit(): void {
    //     // this.dtOptionsSpecificConsigneeDetails.next();
    // }

    getConsigneeDetailsRead() {
        this.partyMasterDtoSearch = new PartyMasterDto();
    }

    getSpecificConsigneeList = () => {
        this.getConsigneeDetailsRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getSpecificConsigneeDetails(this.partyMasterDtoSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    this.consigneeDataList = response;
                    $("#" + this.pageId + "consigneeDetailsDatatableId").DataTable().destroy();
                    this.dtTriggerSpecificConsigneeDetails.next();
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Consignee Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };




    validateSave() {
        this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
        this.selectedGstNumber = $("#" + this.pageId + "gstNoConsignee").val();
        this.selectedCity = $("#" + this.pageId + "destination").val();
        if (this.selectedConsigneeName == '' || this.selectedConsigneeName == null
            || this.selectedGstNumber == '' || this.selectedGstNumber == null
            || this.selectedCity == '' || this.selectedCity == null) {
            swal("Not Allowed", "Please select/enter all the mandatory fields to proceed!", "warning");
        } else {
            swal({
                title: "Confirm Add/Update",
                text: "Sure you want to Add/Update the entered Specific Consignee details?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yes) => {
                if (yes) {
                    this.saveSpecificConsigneeDetails();
                }
            });
        }

    }

    specificConsigneeDetailsData() {
        this.partyMasterDto = new PartyMasterDto();
        this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
        this.selectedGstNumber = $("#" + this.pageId + "gstNoConsignee").val();
        this.selectedCity = $("#" + this.pageId + "destination").val();
        console.log("selectedConsigneeName");
        console.log(this.selectedConsigneeName);


        this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDto.userName = this.userDataDtoReturnSession.userId;
        this.partyMasterDto.gstNoConsignee = this.selectedGstNumber;
        this.partyMasterDto.consigneeName = this.selectedConsigneeName;
        this.partyMasterDto.destination = this.selectedCity;
        this.partyMasterDto.id = this.id;
    }

    saveSpecificConsigneeDetails = () => {
        this.specificConsigneeDetailsData();
        this.masterService.saveSpecificConsigneeDetails(this.partyMasterDto).
            subscribe((data) => {
                this.partyMasterDtoSaveRet = data;
                if (this.partyMasterDtoSaveRet.status == "Success") {
                    swal({
                        title: "Success",
                        text: "Specific Consignee Details saved Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getSpecificConsigneeList();
                } else if (this.partyMasterDtoSaveRet.status == "Exist") {
                    swal({
                        title: "Not Allowed",
                        text: "Consignee details available with the same GST number & Destination, please verify it or edit the existing details.",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Save of Specific Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Save Specific Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    rowSelected(consigneeData) {
        $("#" + this.pageId + "consigneeName").val(consigneeData.consigneeName);
        // this.partyMasterDto.consigneeName = consigneeData.consigneeName;
        $("#" + this.pageId + "gstNoConsignee").val(consigneeData.gstNoConsignee);
        $("#" + this.pageId + "gstNoConsignee").prop('disabled', true);
        // this.partyMasterDto.gstNoConsignee = consigneeData.gstNoConsignee;
        $("#" + this.pageId + "destination").val(consigneeData.destination);
        this.id = consigneeData.id;
        //        console.log( this.selectedName );
    }


    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        // this.consigneeId = 0;
        this.selectedCity = null;
        this.selectedConsigneeName = null;
        this.selectedGstNumber = null;
        this.partyMasterDto = new PartyMasterDto();
        this.id = 0;
        $("#" + this.pageId + "gstNoConsignee").prop('disabled', false);
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    validateDeleteRow() {
        this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
        this.selectedGstNumber = $("#" + this.pageId + "gstNoConsignee").val();
        this.selectedCity = $("#" + this.pageId + "destination").val();
        if (this.selectedConsigneeName == '' || this.selectedConsigneeName == null
            || this.selectedGstNumber == '' || this.selectedGstNumber == null
            || this.selectedCity == '' || this.selectedCity == null || this.id == null || this.id == 0) {
            swal("Not Allowed", "Please select the details to proceed!", "warning");
        } else {
            swal({
                title: "Confirm Delete",
                text: "Sure you want to Delete the selected Specific Consignee details?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yes) => {
                if (yes) {
                    this.deleteSpecificConsigneeDetails();
                }
            });
        }
    }

    getDeleteSpecificConsigneeDetails() {
        this.partyMasterDeleteDto = new PartyMasterDto();
        this.partyMasterDeleteDto.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDeleteDto.userName = this.userDataDtoReturnSession.userId;
        this.partyMasterDeleteDto.id = this.id;
    }

    deleteSpecificConsigneeDetails = () => {
        this.getDeleteSpecificConsigneeDetails();
        this.masterService.deleteSpecificConsigneeDetails(this.partyMasterDeleteDto).
            subscribe((data) => {
                this.partyMasterDtoDelelteRet = data;
                if (this.partyMasterDtoDelelteRet.status == "success") {
                    swal({
                        title: "Success",
                        text: "Specific Consignee Details Deleted Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getSpecificConsigneeList();

                } else {
                    swal({
                        title: "Error",
                        text: "Error While Deleting Specific Consignee Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Deleting Specific Consignee Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }

    clickListnerForDestinationList(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destination").val(this.modelDestination.destination);
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "LrForm";
        //CHG_Ver1
        this.lrDtoDestination.reportMode = "subStation";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log("done");
    };

}
