import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends
//for drag and drop starts
import { DragulaModule } from 'ng2-dragula';
//for drag and drop ends
import { MaterialModule } from "src/app/material.module";


import { TripCreationRoutes } from 'src/app/trip-creation/trip-creation.routing';

import { TripCreationStockSummaryComponent } from 'src/app/trip-creation/trip-creation-stock-summary/trip-creation-stock-summary.component';
import { TripCreationStockDetailsComponent } from 'src/app/trip-creation/trip-creation-stock-details/trip-creation-stock-details.component';
import { StocksGroupingComponent } from 'src/app/trip-creation/stocks-grouping/stocks-grouping.component';
import { TripCreationInvoiceDetailsComponent } from 'src/app/trip-creation/trip-creation-invoice-details/trip-creation-invoice-details.component';
import { TripCreationMakeATripComponent } from 'src/app/trip-creation/trip-creation-make-a-trip/trip-creation-make-a-trip.component';
import { TripCreationInvoiceComponent } from 'src/app/trip-creation/trip-creation-invoice/trip-creation-invoice.component';
import { TripCreationHireslipGenerationComponent } from 'src/app/trip-creation/trip-creation-hireslip-generation/trip-creation-hireslip-generation.component';
import { InvoiceLoadingsheetPrintComponent } from './invoice-loadingsheet-print/invoice-loadingsheet-print.component';


//import { InvoiceDetailsPrintComponent } from './invoice-details-print/invoice-details-print.component';
import { InvoiceDetailsPrintComponent } from 'src/app/trip-creation/invoice-details-print/invoice-details-print.component';
//import { InvoiceSummaryPrintComponent } from './invoice-summary-print/invoice-summary-print.component';
import { InvoiceSummaryPrintComponent } from 'src/app/trip-creation/invoice-summary-print/invoice-summary-print.component';
import { HamaliDetailReportComponent } from 'src/app/trip-creation/hamali-detail-report/hamali-detail-report.component';
import { MultiplePointDetailsComponent } from 'src/app/trip-creation/multiple-point-details/multiple-point-details.component';
import { MultipleInvoiceLastPointDetailsComponent } from 'src/app/trip-creation/multipleinvoice-lastpoint-details/multipleinvoice-lastpoint-details.component';
import { TruckMasterComponent } from 'src/app/master/transhipment/truck-master/truck-master.component';
//import { HireslipDetailsPrintComponent } from './hireslip-details-print/hireslip-details-print.component';
import { HireslipDetailsPrintComponent } from 'src/app/trip-creation/hireslip-details-print/hireslip-details-print.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { TranshipmentModule } from 'src/app/master/transhipment/transhipment.module';
import { TransferModule } from 'src/app/stock/transfer/transfer.module';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { InvoiceLoadingSheetComponent } from './invoice-loading-sheet/invoice-loading-sheet.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { InvoiceLoadingSheetFlowComponent } from './invoice-loading-sheet-flow/invoice-loading-sheet-flow.component';
import { LrEntryFormPrint3Component } from './lrentryform-print3/lrentryform-print3.component';
import { LoadingInvoiceEditComponent } from './loading-invoice-edit/loading-invoice-edit.component';
import { LocalTripLoadingSheetFlowComponent } from './local-trip-loading-sheet-flow/local-trip-loading-sheet-flow.component';

@NgModule({
    imports: [
        CommonModule,
        // RouterModule.forChild(TripCreationRoutes),
        FormsModule,
        NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule,
        ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule, DragulaModule,
        NgxBarcodeModule, TranshipmentModule, TransferModule, CashmemoReportModule, NgxBarcode6Module, NgxDatatableModule],
    declarations: [TripCreationStockSummaryComponent, TripCreationStockDetailsComponent,
        StocksGroupingComponent, TripCreationInvoiceDetailsComponent,
        TripCreationMakeATripComponent, TripCreationInvoiceComponent,
        TripCreationHireslipGenerationComponent, InvoiceLoadingsheetPrintComponent,
        InvoiceDetailsPrintComponent, InvoiceSummaryPrintComponent,
        HamaliDetailReportComponent, MultiplePointDetailsComponent,
        MultipleInvoiceLastPointDetailsComponent,
        HireslipDetailsPrintComponent, InvoiceLoadingSheetComponent, InvoiceLoadingSheetFlowComponent,LrEntryFormPrint3Component,
        LoadingInvoiceEditComponent,LocalTripLoadingSheetFlowComponent],
    entryComponents: [HamaliDetailReportComponent, MultiplePointDetailsComponent,
        MultipleInvoiceLastPointDetailsComponent],
    exports: [StocksGroupingComponent,LrEntryFormPrint3Component]
})
export class TripCreationModule { }
