<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" id="{{pageId}}storageReportId" *ngIf="isLoggedIn">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <!-- <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Storage Report</h6>
                </div> -->
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <!-- 											the first autocomplete starts -->
                                            <div class="col-sm-12 col-md-12">

                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignee Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>
                                                        <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA" (selectItem)="rowSelectedConsignee($event)" [ngModelOptions]="{standalone: true}"
                                                            [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                </div>

                                            </div>
                                            <!-- 											the first autocomplete ends -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod();">Clear</button>
                        </div>
                    </div>


                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <!-- column -->
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>

                            <!-- <button type="submit" class="dt-button" style="margin-left: 55%;" (click)="customPrintStorageRpt()" id="{{pageId}}printAllBtn">
                                <span><i class="fas fa-print">Print</i></span>
                            </button> -->
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-8"></div>
                                        </div>
                                        <div class="row p-t-10">
                                            <div class="col-md-8">
                                                <h6 class="card-title">Storage Stocks Report</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="box-body">
                                                    <table datatable id="{{pageId}}storageReportTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStorageReport" [dtTrigger]="dtTriggerStorageReport">

                                                        <thead>
                                                            <tr>
                                                                <th>Party</th>
                                                                <th>Tot LRs</th>
                                                                <th>Tot Art</th>
                                                                <th>To Pay</th>
                                                                <th>Stocks At</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let storageReportData of storageReportDataList ">
                                                                <td>{{ storageReportData.consigneeName }}</td>
                                                                <!-- <td>{{ storageReportData.totalLrs }}</td> -->
                                                                <td style='font-size: 13px; font-weight: bold;'>
                                                                    <a style="color: green; cursor: pointer;" (click)="lrDetailsPopup(storageReportData)">
                                                                        <u>{{ storageReportData.totalLrs }}</u>
                                                                    </a>
                                                                </td>
                                                                <td>{{ storageReportData.totalArticles }}</td>
                                                                <td>{{ storageReportData.toPay }}</td>
                                                                <td>{{ storageReportData.stocksAt }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>
                                                        </tfoot>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Row -->

                </div>
            </div>

        </div>
    </div>


    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>