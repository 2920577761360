<head>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
</head>
<!-- Row -->
<div class="row" id="{{pageId}}stockGroupingId">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <div class="row">
                    <div class="col-md-12">
                        <h6 class="card-title text-white">Trip Creation</h6>
                    </div>
                </div>
            </div>
            <div class="row system_responsive" style="margin-bottom: 5px;">
                <div class="col-md-12">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-horizontal-stepper [linear]="false" #stepper>
                                    <!--one starts -->
                                    <mat-step [stepControl]="firstFormGroup">
                                        <!--<form [formGroup]="firstFormGroup"></form>-->
                                        <ng-template matStepLabel>Stocks Grouping</ng-template>
                                        <div class="row">
                                            <!-- spinner start-->
                                            <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Loading Details.....</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- spinner end-->
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <!--<app-trip-creation-stock-summary>
													</app-trip-creation-stock-summary>-->
                                                <div class="card-body">
                                                    <div class="box-body">
                                                        <h6 style="border-bottom: 1px solid orange;" class="card-title">
                                                            Stocks Summary</h6>
                                                        <button type="submit" class="dt-button" style="margin-left: 88%;" (click)="customPrintStocksSummaryRpt()" id="{{pageId}}printAllBtn">
                                                            <span><i class="fas fa-print">Print</i></span>
                                                        </button>
                                                        <table #datatableStockSummary datatable id='{{pageId}}stockSummaryTableId' class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStockSummary" [dtTrigger]="dtTriggerStockSummary">
                                                            <thead>
                                                                <tr>
                                                                    <th>Destination</th>
                                                                    <th>Total LRs</th>
                                                                    <th>Articles</th>
                                                                    <th>Act Wgt</th>
                                                                    <th>Chg Wgt</th>
                                                                    <th>Amount</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let stockSummaryData of stockSummaryDataList ">
                                                                    <td>{{ stockSummaryData.endDest }}</td>
                                                                    <td>{{ stockSummaryData.totalLrs }}</td>
                                                                    <td>{{ stockSummaryData.totalArticles }}</td>
                                                                    <td>{{ stockSummaryData.actualWeight }}</td>
                                                                    <td>{{ stockSummaryData.chargedWeight }}</td>
                                                                    <td>{{ stockSummaryData.totalAmount }}</td>
                                                                    <td><button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableLRDetailsBtn" (click)="rowSelectedGetTripLRDeatils(stockSummaryData);">
                                                                            <i title="Get Total LR Details"
                                                                                class="fas fa-pencil-alt"></i>
                                                                        </button></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 vl">
                                                <!--<app-trip-creation-stock-details>
													</app-trip-creation-stock-details>-->
                                                <div class="card-body">
                                                    <div class="box-body">
                                                        <h6 style="border-bottom: 1px solid orange;" class="card-title">
                                                            Stocks Details - {{ selectDestination }}</h6>
                                                        <button type="submit" class="dt-button" style="margin-left: 88%;" (click)="customPrintStocksDetailsRpt()" id="{{pageId}}printAllBtn">
                                                            <span><i class="fas fa-print">Print</i></span>
                                                        </button>
                                                        <table #datatableStockDetails datatable id='{{pageId}}stockDetailsTableId' class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStockDetails" [dtTrigger]="dtTriggerStockDetails">
                                                            <thead>
                                                                <tr>
                                                                    <th>Urgent Daily</th>
                                                                    <th *ngIf="viewEwayBillStatus">E-Way Bill Status
                                                                    </th>
                                                                    <th>LR Number</th>
                                                                    <th>Agent Name</th>
                                                                    <th>Marked On Src</th>
                                                                    <th>Consignee Name</th>
                                                                    <th>Destination</th>
                                                                    <th>Booking Date</th>
                                                                    <th>Articles</th>
                                                                    <th>Act Wgt</th>
                                                                    <th>Chg Wgt</th>
                                                                    <th>Amount</th>
                                                                    <th *ngIf="viewDeliveryManager">Arrival Date</th>
                                                                    <th *ngIf="viewDeliveryManager">DiffDate (Bkg-Today)
                                                                    </th>
                                                                    <th>Note</th>
                                                                    <th *ngIf="ewayBillAlert">E-Way Bill No</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let stockDetailsData of stockDetailsDataList ">
                                                                    <td>{{ stockDetailsData.urgentDly }}</td>
                                                                    <td *ngIf="viewEwayBillStatus">{{ stockDetailsData.ewayBillStatus }}</td>
                                                                    <td>
                                                                        <a class="hyperLink_td" (click)="lrNumberToEnquiryForm(stockDetailsData)">
                                                                            <u>{{
                                                                                stockDetailsData.lrNumber }}</u>
                                                                        </a>
                                                                    </td>
                                                                    <td>{{ stockDetailsData.agentName }}</td>
                                                                    <td>{{ stockDetailsData.urgentDlyMarkedAtStr }}</td>
                                                                    <td>{{ stockDetailsData.consigneeName }}</td>
                                                                    <td>{{ stockDetailsData.destination }}</td>
                                                                    <td>{{ stockDetailsData.bookingDateStr }}</td>
                                                                    <td>{{ stockDetailsData.totalArticles }}</td>
                                                                    <td>{{ stockDetailsData.actualWeight }}</td>
                                                                    <td>{{ stockDetailsData.chargedWeight }}</td>
                                                                    <td>{{ stockDetailsData.totalAmount }}</td>
                                                                    <td *ngIf="viewDeliveryManager">{{ stockDetailsData.arrivalDate }}</td>
                                                                    <td *ngIf="viewDeliveryManager">{{ stockDetailsData.dispatchedwithin }}</td>
                                                                    <td>{{ stockDetailsData.remarks }}</td>
                                                                    <td *ngIf="ewayBillAlert">{{ stockDetailsData.eWayBillNo }}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td></td>
                                                                    <td *ngIf="viewEwayBillStatus"></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td *ngIf="viewDeliveryManager"></td>
                                                                    <td *ngIf="viewDeliveryManager"></td>
                                                                    <td></td>
                                                                    <td *ngIf="ewayBillAlert"></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12" style="text-align: right;">
                                                <button class="btn btn-dark m-r-10" id="{{pageId}}nextToMakeATripBtn" *ngIf="nextTomakeAtripBtnHideShow" (click)="validateNextToMakeTripButton(tripToDestList)">Make
                                                    a Trip</button>
                                                <button class="btn btn-success m-r-10" id="{{pageId}}makeATripBtn" *ngIf="makeAtripBtnHideShow" (click)="validateMakeATripButton();" mat-button matStepperNext>Make a Trip</button>
                                                <!--<button class="btn btn-info m-r-10" id="{{pageId}}tripSheetBtn"
														*ngIf="tripSheetBtnHideShow">Trip Sheet</button>-->
                                            </div>
                                        </div>
                                    </mat-step>
                                    <!--one flow is ends -->
                                    <!-- two flow is starts  -->
                                    <mat-step [stepControl]="secondFormGroup">
                                        <!--<form [formGroup]="secondFormGroup"></form>-->
                                        <ng-template matStepLabel>Invoice Details</ng-template>
                                        <!--<div class="row">
												<div class="col-md-12">
													<app-trip-creation-invoice-details>
													</app-trip-creation-invoice-details>
												</div>
												<div class="col-md-12" style="text-align: right;">
													<button class="btn btn-primary m-r-10" id="{{pageId}}startFromStocksGroupingBtn"
														mat-button>Start
														Over</button>
													<button class="btn btn-info m-r-10" id="{{pageId}}resetInInvoiceDetailsBtn"
														mat-button>Reset</button>
													<button class="btn btn-dark m-r-10" id="{{pageId}}backToStocksGroupingBtn"
														mat-button matStepperPrevious>Back</button>
													<button class="btn btn-success m-r-10" id="{{pageId}}nextToMakeATripBtn"
														mat-button matStepperNext>Next</button>
												</div>
											</div>-->

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div *ngIf="showSpinnerForActionForInvoiceDetails" class="col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <mat-progress-bar mode="indeterminate" style="color: green;"> </mat-progress-bar>
                                                                <br>
                                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                    Please Wait Loading Details.....</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12">
                                                                <ul class="timeline timeline-left">
                                                                    <li class="timeline-inverted timeline-item">
                                                                        <div class="timeline-badge success">
                                                                            <i class="fas fa-home"></i>
                                                                        </div>
                                                                        <div class="timeline-panel">
                                                                            <div class="timeline-heading">
                                                                                <h4 class="timeline-title">
                                                                                    {{fromStationLocation}}</h4>
                                                                            </div>

                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="custome_Effects">
                                                                            <i class="fas fa-truck " title="Trip Creation"></i>
                                                                        </div>
                                                                    </li>
                                                                    <li class="timeline-inverted timeline-item">
                                                                        <div class="timeline-badge success">
                                                                            <i class="fas fa-warehouse"></i>
                                                                        </div>
                                                                        <div class="timeline-panel">
                                                                            <div class="timeline-heading">
                                                                                <h4 class="timeline-title">
                                                                                    {{toStationLocation}}</h4>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 vl">
                                                        <div class="col-sm-12 col-md-12 bbtitle">
                                                            <h6 class="card-title">Invoice Info</h6>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 p-t-10">
                                                                <div class="control">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label>Loading Incharge<span
                                                                                    class="mandatoryField_Style"> *
                                                                                </span>
                                                                            </label>
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <i
                                                                                        class="ti-truck"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input id="{{pageId}}loaderIncharge" type="text" class="form-control" (selectItem)="clickListnerForLoaderIncharge($event)" [(ngModel)]="modelLoaderIncharge" [ngbTypeahead]="searchLoaderIncharge" [resultFormatter]="formatterLoaderIncharge" [inputFormatter]="formatterLoaderIncharge"
                                                                                (focus)="focusLoaderInchargeTA$.next($any($event).target.value)" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 p-t-10">
                                                                <div class="control">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label>Loader Head <span
                                                                                    class="mandatoryField_Style"> *
                                                                                </span>
                                                                            </label>
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <i
                                                                                        class="ti-truck"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input id="{{pageId}}loaderHead" type="text" class="form-control" (selectItem)="clickListnerForLoaderHead($event)" [(ngModel)]="modelLoaderHead" [ngbTypeahead]="searchLoaderHead" [resultFormatter]="formatterLoaderHead" [inputFormatter]="formatterLoaderHead"
                                                                                (focus)="focusLoaderHeadTA$.next($any($event).target.value)" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="control">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label>Loader Name <span
                                                                                    class="mandatoryField_Style"> *
                                                                                </span>
                                                                            </label>

                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <i
                                                                                        class="ti-user"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input id="{{pageId}}loaderStaffName" type="text" class="form-control" (selectItem)="clickListnerForLoaderStaffName($event)" [(ngModel)]="modelLoaderStaffName" [ngbTypeahead]="searchLoaderStaffName" [resultFormatter]="formatterLoaderStaffName" [inputFormatter]="formatterLoaderStaffName"
                                                                                (focus)="focusLoaderStaffNameTA$.next($any($event).target.value)" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- ASR_V1_CHG - Starts -->
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Enter Vehicle No</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-id-card"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}truckNumberSearchId" class="form-control" aria-describedby="basic-addon11" autocomplete="off" (keypress)="getTruckMasterDetailsSingleEvent($event.keyCode)">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="control">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label>Select Vehicle No <span
                                                                                    class="mandatoryField_Style"> *
                                                                                </span>
                                                                            </label>
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <i
                                                                                        class="fas fa-truck"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input id="{{pageId}}truckNumber" type="text" class="form-control" (selectItem)="clickListnerForTruckNumber($event)" [(ngModel)]="modelTruckNumber" [ngbTypeahead]="searchTruckNumber" [resultFormatter]="formatterTruckNumber" [inputFormatter]="formatterTruckNumber"
                                                                                (focus)="focusTruckNumber$.next($any($event).target.value)" />

                                                                            <span style="background-color: orange; color: #fff" class="input-group-text"> <i
                                                                                    class="fas fa-undo"
                                                                                    title="Load All Vehicle"
                                                                                    style="cursor: pointer;"
                                                                                    (click)="getTruckMasterDetailsList()"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div [hidden]="!blockPanAadharValidation" class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Pan No <span
                                                                                class="mandatoryField_Style"> *
                                                                            </span></label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-id-card"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}panNumberSearchId" class="form-control" aria-describedby="basic-addon11" autocomplete="off">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- ASR_V1_CHG - End -->
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="control">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label>Supplier <span
                                                                                    class="mandatoryField_Style"> *
                                                                                </span>
                                                                            </label>
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <i
                                                                                        class="fas fa-user"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input id="{{pageId}}supplierName" type="text" class="form-control" (selectItem)="clickListnerForSupplier($event)" [(ngModel)]="modelSupplierName" [ngbTypeahead]="searchSupplierName" [resultFormatter]="formatterSupplierName" [inputFormatter]="formatterSupplierName"
                                                                                (focus)="focusSupplierNameTA$.next($any($event).target.value)" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <!-- <div [hidden]="!blockPanAadharValidation" class="col-md-12" style="text-align: center; margin-bottom: 5px;margin-left: 120px;">
                                                                <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}validatePanAadharBtn" (click)="confirmPanAadharLinkVerification(truckMasterPopUp);">Validate PAN Aadhar Link</button>
                                                            </div> -->

                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="control">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label>Driver Name <span
                                                                                    class="mandatoryField_Style"> *
                                                                                </span>
                                                                            </label>

                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <i
                                                                                        class="ti-user"></i>
                                                                                </span>
                                                                            </div>
                                                                            <ng-template #rt let-r="result" let-t="term">
                                                                                <ngb-highlight [result]="r.driverName" [term]="t"></ngb-highlight>
                                                                                -
                                                                                <ngb-highlight [result]="r.licenceNo" [term]="t"></ngb-highlight>
                                                                            </ng-template>
                                                                            <input id="{{pageId}}driverName" type="text" class="form-control" (selectItem)="clickListnerForDriverName($event)" [(ngModel)]="modelDriverName" [ngbTypeahead]="searchDriverName" [resultFormatter]="formatterDriverName" [inputFormatter]="formatterDriverName"
                                                                                [resultTemplate]="rt" (focus)="focusDriverNameTA$.next($any($event).target.value)" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Licence Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-id-card"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}licenceNumber" class="form-control" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForLicNo($event.keyCode)">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Bay No <span
                                                                                class="mandatoryField_Style">
                                                                                * </span>
                                                                        </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-search"></i>
                                                                            </span>
                                                                        </div>
                                                                        <!--(change)="selectBayNo(bayNumber.value)"-->
                                                                        <select class="custom-select col-12" id="{{pageId}}bayNumber" name="bayNumber" #bayNumber (keypress)="onKeyPressFieldForBayNo($event.keyCode)">
                                                                            <option selected value="Select Bay">Select
                                                                                Bay</option>
                                                                            <option value="Bay 1">Bay 1</option>
                                                                            <option value="Bay 2">Bay 2</option>
                                                                            <option value="Bay 3">Bay 3</option>
                                                                            <option value="Bay 4">Bay 4</option>
                                                                            <option value="Bay 5">Bay 5</option>
                                                                            <option value="Bay 6">Bay 6</option>
                                                                            <option value="Bay 7">Bay 7</option>
                                                                            <option value="Bay 8">Bay 8</option>
                                                                            <option value="Bay 9">Bay 9</option>
                                                                            <option value="Bay 10">Bay 10</option>
                                                                            <option value="Bay 11">Bay 11</option>
                                                                            <option value="Bay 12">Bay 12</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Bay Loading Date <span
                                                                                class="mandatoryField_Style"> * </span>
                                                                        </label> <input class="form-control" id="{{pageId}}bayLoadingDates" [(ngModel)]="bayLoadingDateModel" placeholder="yyyy-mm-dd"
                                                                            name="bayLoadingDates" ngbDatepicker #bayLoadingDates="ngbDatepicker" autocomplete="off" (keypress)="onKeyPressFieldForBayDate($event.keyCode)">
                                                                        <div class="input-group-append" (click)="bayLoadingDates.toggle()">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 ">
                                                                <div class="form-group ">
                                                                    <div class="input-group ">
                                                                        <label>Bay Loading Time <span
                                                                                class="mandatoryField_Style"> * </span>
                                                                        </label>
                                                                        <div class="input-group-prepend ">
                                                                            <span class="input-group-text "> <i
                                                                                    class="fas fa-clock"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="time" class="form-control " id="{{pageId}}bayLoadingTime" autocomplete="off" aria-describedby="basic-addon11" (keypress)="onKeyPressFieldForBayTime($event.keyCode)">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 vl">
                                                        <div class="col-sm-12 col-md-12 bbtitle">
                                                            <h6 class="card-title">TP Info</h6>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 p-t-10">
                                                                <div class="control">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label>Entry CP</label>
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <i
                                                                                        class="mdi mdi-truck-delivery"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input id="{{pageId}}entryCP" type="text" class="form-control" (selectItem)="clickListnerForEntryCP($event)" [(ngModel)]="modelEntryCP" [ngbTypeahead]="searchEntryCP" [resultFormatter]="formatterEntryCP" [inputFormatter]="formatterEntryCP" (focus)="focusEntryCPTA$.next($any($event).target.value)"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="control">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label>Exit CP</label>
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <i
                                                                                        class="mdi mdi-truck-delivery"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input id="{{pageId}}exitCP" type="text" class="form-control" (selectItem)="clickListnerForExitCP($event)" [(ngModel)]="modelExitCP" [ngbTypeahead]="searchExitCP" [resultFormatter]="formatterExitCP" [inputFormatter]="formatterExitCP" (focus)="focusExitCPTA$.next($any($event).target.value)"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Exp Exit Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="expExitDates" id="{{pageId}}expExitDates" ngbDatepicker [(ngModel)]="modelExpExitDate" #expExitDates="ngbDatepicker"
                                                                            autocomplete="off">
                                                                        <div class="input-group-append" (click)="expExitDates.toggle()">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Exp Exit Days</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" name="expExitDays" id="{{pageId}}expExitDays" class="form-control" aria-describedby="basic-addon11" (keypress)="onKeyPressFieldForExpDay($event.keyCode)" autocomplete="off">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="form-group">
                                                                        <label>Remarks</label>
                                                                        <div class="form-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-notes-medical"></i>
                                                                            </span>
                                                                        </div>
                                                                        <textarea type="text" class="form-control" id="{{pageId}}remarks" rows="4"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" style="text-align: right;">
                                                <button *ngIf="nextToInvoiceDetailOfDarkBtnHideShow" class="btn btn-dark m-r-10" id="{{pageId}}nextDarkBtn" (click)="validateDarkNextBtn(truckMasterPopUp)" [disabled]="disableNextButton">Next</button>
                                                <button *ngIf="nextToInvoiceDetailOfGreenBtnHideShow" class="btn btn-success m-r-10" id="{{pageId}}nextToMakeATripBtn" (click)="validateNextMakeATrip();" mat-button matStepperNext [disabled]="disableNextButton">Next</button>
                                                <button class="btn btn-dark m-r-10" id="{{pageId}}clearForInvoiceDetailsBtnid" (click)="clearInvoiceDetailsBtn();">Clear</button>
                                            </div>
                                        </div>
                                    </mat-step>
                                    <!-- two flow is end -->
                                    <!-- three flow is starts -->
                                    <mat-step [stepControl]="thirdFormGroup">
                                        <!--<form [formGroup]="thirdFormGroup"></form>-->
                                        <ng-template matStepLabel>Make a Trip</ng-template>
                                        <!--<div class="row">
												<div class="col-md-12">
													<app-trip-creation-make-a-trip></app-trip-creation-make-a-trip>
												</div>
												<div class="col-md-12" style="text-align: right;">
													<button class="btn btn-primary m-r-10" id="{{pageId}}startFromStocksGroupingBtn"
														mat-button>Start
														Over</button>
													<button class="btn btn-info m-r-10" id="{{pageId}}resetInMakeATripBtn" mat-button
														matStepperPrevious>Reset</button>
	
													<button class="btn btn-dark m-r-10" id="{{pageId}}backToInvoiceDetailsBtn"
														mat-button matStepperPrevious>Back</button>
													<button class="btn btn-success m-r-10" id="{{pageId}}nextToBtn" mat-button
														matStepperNext>Next</button>
												</div>
											</div>-->
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <!-- spinner start-->
                                                    <div *ngIf="showSpinnerActionForMakeATrip" class="col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <mat-progress-bar mode="indeterminate" style="color: green;"> </mat-progress-bar>
                                                                <br>
                                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                    Please Wait Loading Details.....</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- spinner end-->
                                                    <div class="col-md-2 ">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <!-- the first autocomplete starts -->
                                                                <div class="col-sm-12 col-md-12" *ngIf="hideshowTruckCapacity">
                                                                    <div class="control">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <!-- <label>Truck Capacity</label> -->
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-weight"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input id="{{pageId}}truckCapacity" type="text" class="form-control" (selectItem)="clickListnerForTruckCapacity($event)" [(ngModel)]="modelTruckCapacity" [ngbTypeahead]="searchTruckCapacity" [resultFormatter]="formatterTruckCapacity" [inputFormatter]="formatterTruckCapacity"
                                                                                    (focus)="focusTruckCapacityTA$.next($any($event).target.value)" Placeholder="Select Truck Capacity" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-12 col-md-12" *ngIf="hideshowControlsBins">
                                                                    <div class="control">
                                                                        <div class="form-group">
                                                                            <div class="input-group">
                                                                                <!-- <label>Bins</label> -->
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text"> <i
                                                                                            class="fas fa-search"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input id="{{pageId}}controlBins" type="text" class="form-control" (selectItem)="clickListnerForControlBins($event)" [(ngModel)]="modelControlBins" [ngbTypeahead]="searchControlBins" [resultFormatter]="formatterControlBins" [inputFormatter]="formatterControlBins"
                                                                                    (focus)="focusControlBinsTA$.next($any($event).target.value)" Placeholder="Select Bins" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-12 col-md-12">
                                                                    <div class="form-group">
                                                                        <div class="input-group" style="display: flex; gap: 0;">
                                                                            <!-- <label>LR Number</label> -->
                                                                            <!-- <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <i
                                                                                        class="fas fa-clipboard-list"></i>
                                                                                </span>
                                                                            </div> -->
                                                                            <input type="text" id="{{pageId}}lrNumberPrefix" class="form-control" aria-describedby="basic-addon11" autocomplete="off" [(ngModel)]="showLrCodeInputFields" style="flex: 0 0 6ch;">
                                                                            <input type="text" Placeholder="LR Number" id="{{pageId}}lrNumber" class="form-control" (keypress)="keyEventHandlerLRNumber($event.keyCode)" aria-describedby="basic-addon11" autocomplete="off" style="flex: 1;">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-12 col-md-12">
                                                                    <ul class="timeline timeline-left">
                                                                        <li class="timeline-inverted timeline-item">
                                                                            <div class="timeline-badge success">
                                                                                <i class="fas fa-home"></i>
                                                                            </div>
                                                                            <div class="timeline-panel">
                                                                                <div class="timeline-heading">
                                                                                    <h4 class="timeline-title">
                                                                                        {{fromStationLocation}}</h4>
                                                                                </div>

                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="custome_Effects">
                                                                                <i class="fas fa-truck " title="Trip Creation"></i>
                                                                            </div>
                                                                        </li>
                                                                        <li class="timeline-inverted timeline-item">
                                                                            <div class="timeline-badge success">
                                                                                <i class="fas fa-warehouse"></i>
                                                                            </div>
                                                                            <div class="timeline-panel">
                                                                                <div class="timeline-heading">
                                                                                    <h4 class="timeline-title">
                                                                                        {{toStationLocation}}</h4>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                    <h4 *ngIf="userDataDtoReturnSession.newPartyInvBlock" class="timeline-title" style="color: rgb(255, 0, 0); font-weight: bold; font-size: 14px;">
                                                                        *Red Color indicates for <br>new party</h4>
                                                                </div>
                                                                <!--HERE LOCATION START-->
                                                                <!--<div class="col-sm-12 col-md-12">
																		<ul class="timeline timeline-left">
																			<li class="timeline-inverted timeline-item">
																				<div class="timeline-badge success">
																					<i class="fas fa-home"></i>
																				</div>
																				<div class="timeline-panel">
																					<div class="timeline-heading">
																						<h4 class="timeline-title">
																							{{fromStationLocation}}
																						</h4>
																					</div>
	
																				</div>
																			</li>
																			<li>
																				<div class="custome_Effects">
																					<i class="fas fa-truck "
																						title="Trip Creation"></i>
																				</div>
																			</li>
																			<li class="timeline-inverted timeline-item">
																				<div class="timeline-badge success">
																					<i class="fas fa-warehouse"></i>
																				</div>
																				<div class="timeline-panel">
																					<div class="timeline-heading">
																						<h4 class="timeline-title">
																							{{toStationLocation}}</h4>
																					</div>
																				</div>
																			</li>
																		</ul>
																	</div>-->
                                                                <!--HERE LOCATION END-->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--Col-md-2 end-->
                                                    <div class="col-md-10 vl">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="box-body">
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-8">
                                                                                <h6 class="card-title">Eligible LRs</h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-4" style="text-align: right;">
                                                                                <button class="btn btn-primary m-r-10" (click)="clearBtnEligibleLrs()" style="padding: 1px 4px;">Clear
                                                                                    All</button>
                                                                            </div>
                                                                        </div>
                                                                        <table datatable id="{{pageId}}eligibleLrsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsEligibleLrs" [dtTrigger]="dtTriggerEligibleLrs">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Urgent Daily</th>
                                                                                    <th *ngIf="viewEwayBillStatus">E-Way Bill Status</th>
                                                                                    <th>E-Way Bill No</th>
                                                                                    <th>LR Number</th>
                                                                                    <th>Articles</th>
                                                                                    <th>Act Wgt</th>
                                                                                    <th>Chg Wgt</th>
                                                                                    <th>To Pay</th>
                                                                                    <th>Paid</th>
                                                                                    <th>Goods Value</th>
                                                                                    <th>Booking Date</th>
                                                                                    <th>Consignee</th>
                                                                                    <th>Destination</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngFor="let eligibleLrsData of eligibleLrsDataList ">
                                                                                    <td>{{ eligibleLrsData.urgentDly }}
                                                                                    </td>
                                                                                    <td *ngIf="viewEwayBillStatus">{{ eligibleLrsData.ewayBillStatus }}
                                                                                    </td>
                                                                                    <td>{{ eligibleLrsData.eWayBillNo }}
                                                                                    </td>
                                                                                    <!-- Added for coloring new party lrs -->
                                                                                    <!-- <td>{{ eligibleLrsData.lrNumber }}
                                                                                    </td> -->
                                                                                    <td *ngIf="userDataDtoReturnSession.newPartyInvBlock&&eligibleLrsData.remarks!=null && eligibleLrsData.remarks!=undefined && eligibleLrsData.remarks =='NW PRTY'" style="color: rgb(255, 6, 6); font-weight: bold; font-size: 14px;">
                                                                                        {{ eligibleLrsData.lrNumber }}
                                                                                    </td>
                                                                                    <td *ngIf="userDataDtoReturnSession.newPartyInvBlock==null||!userDataDtoReturnSession.newPartyInvBlock||eligibleLrsData.remarks === null|| eligibleLrsData.remarks === undefined|| eligibleLrsData.remarks != 'NW PRTY'">
                                                                                        {{ eligibleLrsData.lrNumber }}
                                                                                    </td>
                                                                                    <td>{{ eligibleLrsData.totalArticles }}
                                                                                    </td>
                                                                                    <td>{{ eligibleLrsData.actualWeight }}
                                                                                    </td>
                                                                                    <td>{{ eligibleLrsData.chargedWeight }}
                                                                                    </td>
                                                                                    <td>{{ eligibleLrsData.toPay }}</td>
                                                                                    <td>{{ eligibleLrsData.paid }}</td>
                                                                                    <td>{{ eligibleLrsData.goodsValue }}
                                                                                    </td>
                                                                                    <td>{{ eligibleLrsData.bookingDateStr }}
                                                                                    </td>
                                                                                    <td>{{ eligibleLrsData.consigneeName }}
                                                                                    </td>
                                                                                    <td>{{ eligibleLrsData.destination }}
                                                                                    </td>
                                                                                    <td><button type="button" style="padding: 1px 4px; background-color: lightgrey;" class="btn m-r-10" (click)="rowSelectedEligibleLrDelete(eligibleLrsData);">
                                                                                            <i title="Remove From Eligible Lrs"
                                                                                                class="fas fa-trash"></i>
                                                                                        </button></td>
                                                                                </tr>
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td *ngIf="viewEwayBillStatus"></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 vl">
                                                                    <div class="box-body">
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-6">
                                                                                <h6 class="card-title">Remaining LRs
                                                                                </h6>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-6" style='text-align: end;'>
                                                                                <button class="btn btn-primary m-r-10" style="padding: 1px 4px;" (click)="validateMoveAllBtnForMakeATrip();" title="Move All To Eligible Lrs" id="{{pageId}}moveAllBtn">Move
                                                                                    All</button>
                                                                                <button class="btn btn-dark m-r-10" style="padding: 1px 4px;" id="{{pageId}}resetBtn" (click)="resetDatatableBtn()">Reset</button>
                                                                            </div>
                                                                        </div>
                                                                        <table datatable id="{{pageId}}remainingLrsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionRemainingLrs" [dtTrigger]="dtTriggerRemainingLrs">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Urgent Daily</th>
                                                                                    <th>LR Number</th>
                                                                                    <th>Articles</th>
                                                                                    <th>Actual Weight</th>
                                                                                    <th>Charged Weight</th>
                                                                                    <th>To Pay</th>
                                                                                    <th>Paid</th>
                                                                                    <th>Goods Value</th>
                                                                                    <th>Consignee</th>
                                                                                    <th>Destination</th>
                                                                                    <th>Booking Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngFor="let remainingLrsData of remainingLrsDataList">
                                                                                    <td>{{ remainingLrsData.urgentDly }}
                                                                                    </td>
                                                                                    <!-- <td>{{ remainingLrsData.lrNumber }}
                                                                                    </td> -->
                                                                                    <td *ngIf="userDataDtoReturnSession.newPartyInvBlock&&remainingLrsData.remarks!=null && remainingLrsData.remarks!=undefined && remainingLrsData.remarks =='NW PRTY'" style="color: rgb(248, 3, 3); font-weight: bold; font-size: 14px;">
                                                                                        {{ remainingLrsData.lrNumber }}
                                                                                    </td>
                                                                                    <td *ngIf="userDataDtoReturnSession.newPartyInvBlock==null||!userDataDtoReturnSession.newPartyInvBlock||remainingLrsData.remarks === null|| remainingLrsData.remarks === undefined|| remainingLrsData.remarks != 'NW PRTY'">
                                                                                        {{ remainingLrsData.lrNumber }}
                                                                                    </td>
                                                                                    <td>{{ remainingLrsData.totalArticles }}
                                                                                    </td>
                                                                                    <td>{{ remainingLrsData.actualWeight }}
                                                                                    </td>
                                                                                    <td>{{ remainingLrsData.chargedWeight }}
                                                                                    </td>
                                                                                    <td>{{ remainingLrsData.toPay }}
                                                                                    </td>
                                                                                    <td>{{ remainingLrsData.paid }}</td>
                                                                                    <td>{{ remainingLrsData.goodsValue }}
                                                                                    </td>
                                                                                    <td>{{ remainingLrsData.consigneeName }}
                                                                                    </td>
                                                                                    <td>{{ remainingLrsData.destination }}
                                                                                    </td>
                                                                                    <td>{{ remainingLrsData.bookingDateStr }}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--Col md-10 end-->
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12" style="text-align: right;">
                                                        <button class="btn btn-dark m-r-10" id="{{pageId}}backToInvoiceDetailsBtn" (click)="backBtnOfMakeAtrip();" mat-button matStepperPrevious>Back</button>
                                                        <button class="btn btn-dark m-r-10" *ngIf="viewNextDarkBtnOfInvoice" id="{{pageId}}nextDarkBtnForInvoiceDetailsBtnId" (click)="validateNextDarkBtnForInvoiceDetails();">Next</button>
                                                        <button class="btn btn-success m-r-10" *ngIf="viewNextBtnOfInvoice" id="{{pageId}}nextGreenBtnForInvoiceDetailsBtnId" (click)="validateNextBtnForInvoiceDetails();" mat-button matStepperNext>Next</button>
                                                        <!--
																<button class="btn btn-info m-r-10" id="{{pageId}}resetInMakeATripBtn"
																mat-button matStepperPrevious>Reset</button>
																<button class="btn btn-dark m-r-10" id="{{pageId}}backToInvoiceDetailsBtn"
																mat-button matStepperPrevious>Back</button>
															<button class="btn btn-success m-r-10" id="{{pageId}}nextToInvoiceBtn"
																(click)="btnNext()" mat-button matStepperNext>Next</button>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-step>
                                    <!-- three flow is ends -->
                                    <!--four flow is starts -->
                                    <mat-step [stepControl]="fourthFormGroup">
                                        <!--<form [formGroup]="fourthFormGroup"></form>-->
                                        <ng-template matStepLabel>Invoice</ng-template>
                                        <!--<div class="row">
												<div class="col-md-12">
													<app-trip-creation-invoice></app-trip-creation-invoice>
												</div>
												<div class="col-md-12" style="text-align: right;">
													<button class="btn btn-primary m-r-10" id="{{pageId}}startFromStocksGroupingBtn"
														mat-button>Start
														Over</button>
													<button class="btn btn-dark m-r-10" id="{{pageId}}backToInvoiceDetailsBtn"
														mat-button matStepperPrevious>Back</button>
													<button class="btn btn-success m-r-10" id="{{pageId}}nextToBtn" mat-button
														matStepperNext>Next</button>
												</div>
											</div>-->
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <!-- spinner start-->
                                                    <div *ngIf="showSpinnerActionForInvoice" class="col-md-12">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <mat-progress-bar mode="indeterminate" style="color: green;"> </mat-progress-bar>
                                                                <br>
                                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                    Please Wait Loading Details.....</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- spinner end-->
                                                    <div class="col-md-12">
                                                        <div class="card-body">
                                                            <div class="box-body">
                                                                <h6 style="border-bottom: 1px solid orange;" class="card-title">Inovice</h6>
                                                                <table datatable id="{{pageId}}invoiceTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoice" [dtTrigger]="dtTriggerInvoice">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>E-Way Bill Status</th>
                                                                            <th>E-Way Bill No</th>
                                                                            <th [hidden]="!viewCheckBoxInvoiceDummyLr">
                                                                                Select</th>
                                                                            <th>LR Number</th>
                                                                            <th>Consignor</th>
                                                                            <th>Consignee</th>
                                                                            <th>Destination</th>
                                                                            <th>Articles</th>
                                                                            <th>Description</th>
                                                                            <th>Booking Date</th>
                                                                            <th>Actual Weight</th>
                                                                            <th>Charged Weight</th>
                                                                            <th>To Pay</th>
                                                                            <th>Paid</th>
                                                                            <th>Goods Value</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let invoiceData of invoiceDataList let i = index">
                                                                            <td>{{ invoiceData.ewayBillStatus }}</td>
                                                                            <td>{{ invoiceData.eWayBillNo }}</td>
                                                                            <td [hidden]="!viewCheckBoxInvoiceDummyLr">
                                                                                <input type="checkbox" [(ngModel)]="invoiceData.lrDummyInv" (change)="checkSelectedLrDummtInvoice($event,invoiceData,i);">
                                                                                <!--{{ invoiceData.lrDummyInv }}-->
                                                                            </td>
                                                                            <td>{{ invoiceData.lrNumber }}</td>
                                                                            <td>{{ invoiceData.consignorName }}</td>
                                                                            <td>{{ invoiceData.consigneeName }}</td>
                                                                            <td>{{ invoiceData.destination }}</td>
                                                                            <td>{{ invoiceData.totalArticles }}</td>
                                                                            <td>{{ invoiceData.description }}</td>
                                                                            <td>{{ invoiceData.bookingDateStr }}</td>
                                                                            <td>{{ invoiceData.actualWeight }}</td>
                                                                            <td>{{ invoiceData.chargedWeight }}</td>
                                                                            <td>{{ invoiceData.toPay }}</td>
                                                                            <td>{{ invoiceData.paid }}</td>
                                                                            <td>{{ invoiceData.goodsValue }}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td [hidden]="!viewCheckBoxInvoiceDummyLr">
                                                                            </td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12" style="text-align: right;">
                                                        <!--<button class="btn btn-success m-r-10"
																id="{{pageId}}nextToPrintLoadingSheetBtn"
																(click)="hamaliDetailsBtn();">Get
																Hamali Details</button>-->
                                                        <button class="btn btn-success m-r-10" id="{{pageId}}nextToPrintLoadingSheetBtn" (click)="printLoadingSheet()" mat-button>Loading
                                                            Sheet</button>
                                                        <button class="btn btn-success m-r-10" (click)="validateCreateTripSheetBtn();">Create
                                                            Hire Slip</button>
                                                        <button class="btn btn-dark m-r-10" id="{{pageId}}backToInvoiceDetailsBtn" (click)="backBtnOfInvoice();" mat-button matStepperPrevious>Back</button>
                                                        <button class="btn btn-dark m-r-10" (click)="overAllResetBtn();">Reset</button>
                                                        <button class="btn btn-primary m-r-10" (click)="startOverBtn();">Start Over</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-step>
                                    <!-- four flow is ends  -->
                                    <!-- five flow is starts -->
                                    <mat-step [stepControl]="fifthFormGroup">
                                        <!--<form [formGroup]="fifthFormGroup"></form>-->
                                        <ng-template matStepLabel>Hireslip Generation</ng-template>
                                        <!--<div class="row">
												<div class="col-md-12">
													<app-trip-creation-hireslip-generation>
													</app-trip-creation-hireslip-generation>
												</div>
												<div class="col-md-12" style="text-align: right;">
													<button class="btn btn-dark m-r-10" id="{{pageId}}backToInvoiceDetailsBtn"
														mat-button matStepperPrevious>Back</button>
													<button class="btn btn-success m-r-10" id="{{pageId}}nextToBtn" mat-button
														matStepperNext>Next</button>
												</div>
											</div>-->
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="card">
                                                    <div class="row">
                                                        <!-- spinner start-->
                                                        <div *ngIf="showSpinnerActionForHireslip" class="col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <mat-progress-bar mode="indeterminate" style="color: green;"> </mat-progress-bar>
                                                                    <br>
                                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                        Please Wait Loading Details.....</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- spinner end-->
                                                        <!--col-md 4 Hireslip-->
                                                        <div class="col-md-4">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="card-body">
                                                                        <h6 style="border-bottom: 1px solid orange;" class="card-title">Trip Details</h6>

                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Invoice Number</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fas fa-file-alt"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control" id="{{pageId}}inoviceNumber" aria-describedby="basic-addon11" (keypress)="onKeyPressFieldForInvoiceNo($event.keyCode)" autocomplete="off" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Invoice Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="invoiceDates" id="{{pageId}}invoiceDates" ngbDatepicker #invoiceDates="ngbDatepicker"
                                                                                        autocomplete="off" />
                                                                                    <div class="input-group-append" (click)="invoiceDates.toggle()">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fa fa-calendar"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Hireslip Number</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fas fa-file-alt"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control" id="{{pageId}}hireslipNumber" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForHireslipNo($event.keyCode)" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Source</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i class="fas fa-box"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control" id="{{pageId}}source" aria-describedby="basic-addon11" autocomplete="off" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Destination</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i class="fas fa-road"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control" id="{{pageId}}destination" aria-describedby="basic-addon11" autocomplete="off" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Number Of LR's</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fas fa-clipboard-list"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control" id="{{pageId}}numberOfLrs" aria-describedby="basic-addon11" autocomplete="off" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Number Of Articles</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i class="fas fa-clone"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control" id="{{pageId}}numberOfArticles" aria-describedby="basic-addon11" autocomplete="off" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Truck Total GUR Wt</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fas fa-weight"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="number" class="form-control" id="{{pageId}}gurWeightTotal" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForTruckTotalWgt($event.keyCode)" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Gurantee Weight</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fas fa-weight"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="number" class="form-control" id="{{pageId}}guranteeWeight" aria-describedby="basic-addon11" (keypress)="eventHandlerGurWgt($event.keyCode)" autocomplete="off" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Kanta Weight</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fas fa-weight"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="number" class="form-control" id="{{pageId}}kantaWeight" aria-describedby="basic-addon11" (keypress)="eventHandlerKantaWgt($event.keyCode)" autocomplete="off" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Loaded By</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i class="ti-user"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control" id="{{pageId}}loadedBy" aria-describedby="basic-addon11" (keypress)="eventHandlerLoadedBy($event.keyCode)" autocomplete="off" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="card-body">
                                                                        <h6 style="border-bottom: 1px solid orange;" class="card-title">Goods Short & Extra Details
                                                                        </h6>
                                                                        <div class="box-body">
                                                                            <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsGoodsShortAndExtraDetails" [dtTrigger]="dtTriggerGoodsShortAndExtraDetails" id="{{pageId}}goodsShortageExtraLrDetailsId">

                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>LR No</th>
                                                                                        <th>Art</th>
                                                                                        <th>Extra</th>
                                                                                        <th>Open</th>
                                                                                        <th>Short</th>
                                                                                        <th>Wet</th>
                                                                                        <th>Remarks</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr *ngFor="let goodsShortAndExtraDetailsData of goodsShortAndExtraDetailsDataList ">
                                                                                        <td>{{ goodsShortAndExtraDetailsData.lrNumber }}
                                                                                        </td>
                                                                                        <td>{{ goodsShortAndExtraDetailsData.totalArticles }}
                                                                                        </td>
                                                                                        <td>{{ goodsShortAndExtraDetailsData.excessGoods }}
                                                                                        </td>
                                                                                        <td>{{ goodsShortAndExtraDetailsData.openConditionGoods }}
                                                                                        </td>
                                                                                        <td>{{ goodsShortAndExtraDetailsData.shortGoods }}
                                                                                        </td>
                                                                                        <td>{{ goodsShortAndExtraDetailsData.wetConditionGoods }}
                                                                                        </td>
                                                                                        <td>{{ goodsShortAndExtraDetailsData.remarks }}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12" [hidden]="!viewShortExtraDescription">
                                                                    <h5 class="cstm_efftect" id="{{pageId}}shortExtraDescriptionId"></h5>
                                                                </div>
                                                            </div>

                                                            <div class="row" *ngIf = "showTwoPointLrsTable">
                                                                <div class="col-md-12">
                                                                    <div class="card-body">
                                                                        <h6 style="border-bottom: 1px solid orange;" class="card-title">2 Point LR's
                                                                        </h6>
                                                                        <div [hidden]="hideAndShowForCkhStatus" class="col-sm-12 col-md-12">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <label>Add More 2 Point &nbsp;
                                                                                        Delivery LR</label>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fas fa-file-alt"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="text" class="form-control" id="{{pageId}}addMoreTowPointDeliveryLr" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForAddTwoPointDeliveryLr($event.keyCode)" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="box-body">
                                                                            <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTwoPointLrDetails" [dtTrigger]="dtTriggerTwoPointLrDetails" id="{{pageId}}twoPointLrDetailsTableId">

                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>LR No</th>
                                                                                        <!-- <th>Consignee</th>
                                                                                        <th>Consignor</th> -->
                                                                                        <th>AW</th>
                                                                                        <th>CW</th>
                                                                                        <th>2.5 Ton >=</th>
                                                                                        <th [hidden]="hideAndShowForCkhStatus">2 Point</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr *ngFor="let twoPointLrDetails of twoPointLrDetailsList ">
                                                                                        <td>{{ twoPointLrDetails.lrNumber }}
                                                                                        </td>
                                                                                        <!-- <td>{{ twoPointLrDetails.consigneeName }}
                                                                                        </td>
                                                                                        <td>{{ twoPointLrDetails.consignorName }}
                                                                                        </td> -->
                                                                                        <td>{{ twoPointLrDetails.actualWeight }}
                                                                                        </td>
                                                                                        <td>{{ twoPointLrDetails.chargedWeight }}
                                                                                        </td>
                                                                                        <td>{{ twoPointLrDetails.chkStatus }}
                                                                                        </td>
                                                                                        <td [hidden]="hideAndShowForCkhStatus"><mat-slide-toggle [(ngModel)]="twoPointLrDetails.isTwoPointDeliveryLr"  color="primary" (change)="onMonitorDelPerfToggleChange($event, twoPointLrDetails)">
                                                                                        </mat-slide-toggle>
                                                                                    </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--col md 4 hireslip 1 section end-->
                                                        <!--col md 4 hireslip 2- section start-->
                                                        <div class="col-md-4 vl">
                                                            <div class="row">
                                                                <div class="col-md-12 ">
                                                                    <div class="card-body">
                                                                        <h6 style="border-bottom: 1px solid orange;" class="card-title">Vehicle Details</h6>
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Lorry Number</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i class="ti-truck"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}lorryNumber" aria-describedby=" basic-addon11" autocomplete="off" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Lorry Owner</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-user"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}lorryOwner" aria-describedby=" basic-addon11" autocomplete="off" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Truck Length</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-boxes"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}truckLength" aria-describedby=" basic-addon11" autocomplete="off" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Truck Type</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-boxes"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input id="{{pageId}}truckType" type="text" class="form-control" (selectItem)="clickListnerForTruckType($event)" [(ngModel)]="modelTruckType" [ngbTypeahead]="searchTruckType" [resultFormatter]="formatterTruckType" [inputFormatter]="formatterTruckType"
                                                                                            (focus)="focusTruckTypeTA$.next($any($event).target.value)" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group" id="{{pageId}}lorryOwnerDetails">
                                                                                        <a (click)="truckMasterInPopUpPage(truckMasterPopUp)"><u
                                                                                                class="hyperLink_td">Update
                                                                                                Lorry Owner
                                                                                                Details</u></a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Contact Person</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-user"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}contactPerson" aria-describedby=" basic-addon11 " autocomplete="off" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Address</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-hashtag"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}address" aria-describedby=" basic-addon11 " autocomplete="off" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Driver Name</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-user"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}driverNameText" aria-describedby=" basic-addon11 " autocomplete="off" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Driver License No</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-id-card"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}driverLicenseNo" aria-describedby=" basic-addon11 " autocomplete="off" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-sm-12">
                                                                                <div class="row">
                                                                                    <!--<div class="col-sm-12 col-md-5">
																							<label>Driver Mobile No</label>
																						</div>-->
                                                                                    <div class="col-sm-12 col-md-12">
                                                                                        <div class="form-group">
                                                                                            <div class="input-group">
                                                                                                <label>Driver Mobile
                                                                                                    No</label>
                                                                                                <div class="input-group-prepend">
                                                                                                    <span class="input-group-text">
                                                                                                        <i
                                                                                                            class="fas fa-phone"></i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <input type="text" class="form-control" id="{{pageId}}driverMobileNo" aria-describedby=" basic-addon11 " autocomplete="off" (keypress)="onKeyPressFieldForDriverMobileNo($event.keyCode)" />
                                                                                                <div class="input-group-prepend">
                                                                                                    <button type="button" id="{{pageId}}updateDriverBtnId" title="Update Driver New Number" (click)="addDriverBtnClickPopShow(hireSlipUpdateDriverNo)">
                                                                                                        <i
                                                                                                            class="fas fa-plus"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!--<div class="col-sm-12 col-md-2">
																							<button type="button"
																								title="Update Driver New Number"
																								(click)="addDriverBtnClickPopShow(hireSlipUpdateDriverNo)">
																								<i class="fas fa-plus"></i>
																							</button>
																						</div>-->
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Supplier Name</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-user"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}supplierNameText" aria-describedby=" basic-addon11 " autocomplete="off" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Pan No</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-id-card"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}panNumber" aria-describedby=" basic-addon11 " autocomplete="off" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Insurance No</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-id-card"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}insuranceNo" aria-describedby=" basic-addon11 " autocomplete="off" (keypress)="onKeyPressFieldForInsuranceNo($event.keyCode)" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Insurance Expiring Date</label>
                                                                                        <input
                                                                                            id="{{pageId}}insuranceExpDate"
                                                                                            class="form-control"
                                                                                            placeholder="yyyy-mm-dd"
                                                                                            name="dates"
                                                                                            ngbDatepicker
                                                                                            #dates="ngbDatepicker"
                                                                                            (blur)="validateDate()"
                                                                                            (dateSelect)="validateDate()"
                                                                                            [minDate]="minDate"
                                                                                        />
                                                                                        <div class="input-group-append" (click)="dates.toggle()">
                                                                                            <span class="input-group-text"> <i class="fa fa-calendar"></i></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>No of NO EWB in
                                                                                            Consolidated-System</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-laptop"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}noOfNoEwbInConsolidatedSystem" aria-describedby="
																								 basic-addon11 " autocomplete="off" (keypress)="onKeyPressFieldForEWBSystem($event.keyCode)" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>No of NO EWB in
                                                                                            Consolidated-Manual</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-keyboard"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}noOfNoEwbInConsolidatedManual" aria-describedby=" basic-addon11 " autocomplete="off" (keypress)="onKeyPressFieldForEWBManual($event.keyCode)" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Consolidated Eway
                                                                                            Bill</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-file-alt"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="number" class="form-control" id="{{pageId}}consolidatedEwayBill" aria-describedby=" basic-addon11 " autocomplete="off" title="Must Be 10 Number" (keypress)="onKeyPressFieldForConsolidateEWB($event.keyCode)" onCopy="return true" onPaste="return false"
                                                                                            oninput="javascript: if(this.value.length > this.maxLength) 
																								this.value=value.slice(0,this.maxLength);" maxLength="10" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Confirm Consolidated Eway
                                                                                            Bill</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-file-alt"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="number" class="form-control" id="{{pageId}}confirmConsolidatedEwayBill" aria-describedby=" basic-addon11 " autocomplete="off" title="Must Be 10 Number" (keypress)="onKeyPressFieldForConfirmConsolidateEWB($event.keyCode)" onCopy="return false"
                                                                                            onPaste="return false" oninput="javascript: if(this.value.length > this.maxLength) 
																								this.value=value.slice(0,this.maxLength);" maxLength="10" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <!--col md 4 hireslip 2 section end-->
                                                        <!--col md 4 hireslip 3 section start-->
                                                        <div class="col-md-4 vl">
                                                            <div class="row">
                                                                <div class="col-md-12 ">
                                                                    <div class="card-body">
                                                                        <h6 style="border-bottom: 1px solid orange;" class="card-title">Billing Details</h6>
                                                                        <!--	<div class="row">-->
                                                                        <div *ngIf="hideAndShowInputFieldsForSelectedDestination">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 col-md-12">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Base HireAmt</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-rupee-sign"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input type="number" class="form-control" id="{{pageId}}baseHireAmt" aria-describedby=" basic-addon11 " (change)="baseHireAmtChgBlur($event)" (keypress)="onKeyPressFieldForBaseHireAmt($event.keyCode)" autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12 col-md-12">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Door Open</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-rupee-sign"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input type="number" class="form-control" id="{{pageId}}doorOpen" aria-describedby=" basic-addon11 " (change)="doorOpenAmtChgBlur($event)" (keypress)="onKeyPressFieldForDoorOpen($event.keyCode)" autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12 col-md-12">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Vehicle
                                                                                                Height</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-rupee-sign"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input type="number" class="form-control" id="{{pageId}}vehicleHeight" aria-describedby=" basic-addon11 " (change)="vehicleHeightAmtChgBlur($event)" (keypress)="onKeyPressFieldForVehicleHgt($event.keyCode)" autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12 col-md-12">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Door Delivery</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-rupee-sign"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input type="number" class="form-control" id="{{pageId}}doorDelivery" aria-describedby=" basic-addon11 " (change)="doordeliveryAmtChgBlur($event)" (keypress)="onKeyPressFieldForDoorDely($event.keyCode)" autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12 col-md-12">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Extra Weight</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-rupee-sign"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input type="number" class="form-control" id="{{pageId}}extraWeight" aria-describedby=" basic-addon11 " (change)="extraWgtAmtChgBlur($event)" (keypress)="onKeyPressFieldForExtraWgt($event.keyCode)" autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12 col-md-12">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Check Post</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-rupee-sign"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input type="number" class="form-control" id="{{pageId}}checkPost" aria-describedby=" basic-addon11 " (change)="checkPostAmtChgBlur($event)" (keypress)="onKeyPressFieldForCheckPost($event.keyCode)" autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12 col-md-12">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Other Charges</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-rupee-sign"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input type="number" class="form-control" id="{{pageId}}otherCharges" aria-describedby=" basic-addon11 " (change)="otherChargeAmtChgBlur($event)" (keypress)="onKeyPressFieldForOtherChrgs($event.keyCode)" autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div [hidden]="!viewHireEntry" class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Total Hire</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-rupee-sign"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="number" class="form-control" id="{{pageId}}totalHire" aria-describedby=" basic-addon11 " autocomplete="off" (change)="totalHireAmtChgBlur($event)" (keypress)="onKeyPressFieldForTotalHire($event.keyCode)" [disabled]="disableHireEntry" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div [hidden]="!viewHireEntry" class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Advance</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-rupee-sign"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="number" class="form-control" id="{{pageId}}advance" aria-describedby=" basic-addon11" (change)="advanceAmtChgBlur($event)" (keypress)="eventHandlerAdvance($event.keyCode)" autocomplete="off" [disabled]="disableHireEntry" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div [hidden]="!viewHireEntry" class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Balance</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-rupee-sign"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="number" class="form-control" id="{{pageId}}balance" aria-describedby=" basic-addon11 " autocomplete="off" (keypress)="onKeyPressFieldForBalance($event.keyCode)" [disabled]="disableHireEntry" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div [hidden]="!viewHireEntry" class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Touching Hire</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-rupee-sign"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="number" class="form-control" id="{{pageId}}touchingHire" aria-describedby=" basic-addon11 " autocomplete="off" (keypress)="onKeyPressFieldForTouchingHire($event.keyCode)" [disabled]="disableHireEntry" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <div class="input-group">
                                                                                        <label>Payable At</label>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text">
                                                                                                <i
                                                                                                    class="fas fa-map-marker-alt"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <input type="text" class="form-control" id="{{pageId}}payableAt" aria-describedby=" basic-addon11 " autocomplete="off" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="form-group">
                                                                                    <label>Remarks</label>
                                                                                    <div class="form-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <i
                                                                                                class="fas fa-notes-medical"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <textarea type="text" class="form-control" id="{{pageId}}hireslipRemarksId" rows="4"></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="hideAndShowInputFieldsDeliveryPoint">
                                                                            <div class="row">
                                                                                <div class="col-sm-12">
                                                                                    <div class="row">
                                                                                        <div class="col-sm-12 col-md-12">
                                                                                            <label>Delivery
                                                                                                Point</label>
                                                                                        </div>
                                                                                        <!--<div class="col-sm-12 col-md-6">
																														<label>Delivery Point</label>
																												</div>-->
                                                                                        <div class="col-sm-12 col-md-6">
                                                                                            <div class="form-group">
                                                                                                <div class="custom-control custom-checkbox">
                                                                                                    <input type="checkbox" class="custom-control-input" name="deliveryPointIdForSingle" id="{{pageId}}deliveryPointIdForSingle" checked #deliveryPointIdForSingle (change)="deliveryPointSingleCheckBoxEvent($event)">
                                                                                                    <label class=" custom-control-label wtfull " for="{{pageId}}deliveryPointIdForSingle">Single</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-sm-12 col-md-6">
                                                                                            <div class="form-group">
                                                                                                <div class="custom-control custom-checkbox">
                                                                                                    <input type="checkbox" class="custom-control-input" name="deliveryPointIdForMultiple" id="{{pageId}}deliveryPointIdForMultiple" #deliveryPointIdForMultiple (change)="deliveryPointMultipleCheckBoxEvent($event)">
                                                                                                    <label class=" custom-control-label wtfull " for="{{pageId}}deliveryPointIdForMultiple">Muliple</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12 col-md-12">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Delivery
                                                                                                Points</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-truck-loading"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input type="number" class="form-control" id="{{pageId}}deliveryPoints" aria-describedby=" basic-addon11 " autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <!-- <div class="col-sm-12 col-md-6">
                                                                                    <div class="form-group">
                                                                                        <div class="custom-control custom-checkbox">
                                                                                            <input type="checkbox" class="custom-control-input" id="{{pageId}}twoPointDD" (change)="twoPointDDCheckBoxEvent($event)">
                                                                                            <label class=" custom-control-label wtfull " for="{{pageId}}twoPointDD">2
                                                                                                Point Door
                                                                                                Delivery</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div *ngIf="hideShowTwoPointDoorDelivery" class="col-sm-12 col-md-12">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>2 Point Door Delivery
                                                                                                LRs(Multiple
                                                                                                LRs separated by
                                                                                                comma(,)):</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-truck-loading"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input type="text" class="form-control" id="{{pageId}}twoPointsDoorDeliveryLrs" aria-describedby=" basic-addon11 " autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div> -->
                                                                                <div class="col-sm-12 col-md-12">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Reward
                                                                                                Applicable</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-file-medical"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <select class="custom-select col-12" id="{{pageId}}printRewardDetails">
                                                                                                <option value="yes">Yes
                                                                                                </option>
                                                                                                <option selected
                                                                                                    value="no">No
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-6">
                                                                                <div class="form-group">
                                                                                    <div class="custom-control custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" id="{{pageId}}isMultiInvoiceTripId" (change)="multiInvTripCheckBoxEvent($event)">
                                                                                        <label class=" custom-control-label wtfull " for="{{pageId}}isMultiInvoiceTripId">Is
                                                                                            Multi-Invoice Trip?</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12" *ngIf="hideShowIsLastPointDelivery">
                                                                                <div class="form-group">
                                                                                    <div class="custom-control custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" id="{{pageId}}islastPointDeliveryId" (change)="isLastPointDeliveryLinkedHireslipListner($event);">
                                                                                        <label class=" custom-control-label wtfull " for="{{pageId}}islastPointDeliveryId">Is
                                                                                            Last Point Delivery?</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="card-body">
                                                                        <h6 class="card-title">Date & Time</h6>
                                                                        <div class="row">
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="control">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Departure
                                                                                                Date</label> <input class="form-control" placeholder="yyyy-mm-dd"
                                                                                                name="departureDate" id="{{pageId}}departureDate" ngbDatepicker #departureDates="ngbDatepicker" autocomplete="off" />
                                                                                            <div *ngIf="showDepartureDateIcon" class="input-group-append" (click)="departureDates.toggle()">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fa fa-calendar"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="control">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Departure
                                                                                                Time</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-clock"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input class="form-control" id="{{pageId}}departureTime" type="time" name="departureTime" (change)="departureTimeCalListner();" autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="control">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Scheduled
                                                                                                Date</label> <input class="form-control" placeholder="yyyy-mm-dd"
                                                                                                name="scheduleDate" id="{{pageId}}scheduleDate" ngbDatepicker #scheduleDates="ngbDatepicker" autocomplete="off" />
                                                                                            <div class="input-group-append" (click)="scheduleDates.toggle()">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fa fa-calendar"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-sm-12 col-md-12">
                                                                                <div class="control">
                                                                                    <div class="form-group">
                                                                                        <div class="input-group">
                                                                                            <label>Scheduled
                                                                                                Time</label>
                                                                                            <div class="input-group-prepend">
                                                                                                <span class="input-group-text">
                                                                                                    <i
                                                                                                        class="fas fa-clock"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <input class="form-control" id="{{pageId}}scheduleTime" type="time" name="scheduleTime" autocomplete="off" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--col md 4 hireslip 3 section end-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <button type="submit" class="btn btn-success m-r-10" *ngIf="startoverBtnHideAndShow" style='float: right;' (click)="startOverFromHireslipBtn();">Start Over</button>
                                                <button type="submit" class="btn btn-success m-r-10" *ngIf="resetBtnHideAndShow" style='float: right;' (click)="resetFormHireslipBtnBtn();">Reset</button>
                                                <!-- 										<button type="button" (click)="invoiceSummaryBtnForPrint();" -->
                                                <!-- 											*ngIf="hideAndShowInvSummPrintBtn" style='float: right;' -->
                                                <!-- 											class="btn btn-dark" id="{{pageId}}btnInvoiceSummaryPrint"> -->
                                                <!-- 											Print Invoice Summary</button> -->
                                                <button type="button" class="btn btn-dark" id="{{pageId}}btnHireSlipPrint" *ngIf="hideAndShowPrintHireslipBtn" style='float: right;' (click)="printHireslipButton();">Print Hire
                                                    Slip</button>
                                                <!-- 										<button type="button" class="btn btn-dark" id="{{pageId}}printButton" -->
                                                <!-- 											*ngIf="hideAndShowPrintInvoiceBtn" style='float: right;' -->
                                                <!-- 											(click)="printInvoiceButton();">Print Invoice</button> -->
                                                <button type="button" *ngIf="departureButtonHideShow" style='float: right;' class="btn btn-dark" id="{{pageId}}depButton" (click)="departureButton();">Departure</button>
                                                <button type="button" class="btn btn-dark" id="{{pageId}}saveButton" *ngIf="saveAndPrintButtonHideShow" style='float: right;' (click)="saveBtn();">Save &
                                                    Print</button>
                                            </div>
                                        </div>
                                    </mat-step>
                                    <!--five flow is ends -->
                                </mat-horizontal-stepper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>
<!--Popup Work Start HERE Stock Grouping-->
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #tripToDestList let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Trip Info
                    </h6>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body ">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-sm-12 col-md-12">
                                <div class="control">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Trip To</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-university"></i>
                                                </span>
                                            </div>
                                            <input id="{{pageId}}tripToDestType" type="text" class="form-control" (selectItem)="clickListnerForTripToDestType($event)" [(ngModel)]="modelTripToDestType" [ngbTypeahead]="searchTripToDestType" [resultFormatter]="formatterTripToDestType" [inputFormatter]="formatterTripToDestType"
                                                (focus)="focusTripToDestTypeTA$.next($any($event).target.value)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style='border-bottom: 1px solid #e9ecef;'>
                    <div class="row">
                        <button mat-button (click)="btnProceedTripTo(openLocalTripPopUpWidget);
							d('Cross click')">Proceed</button>
                        <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')">Close</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<!-- Print Work Start HERE Stock Grouping-->

<!-- 5 th page of Pop up Hire Slip start-->
<!--<div class="col-md-12">
		<div class="form-group">
			<div class="input-group" id="{{pageId}}popupDetailsNotAssigned">
				<ng-template #hireSlipContentPopUp let-ok="close" let-d="dismiss">
					<div class="modal-header">
						<h5>Linked Hireslip Details</h5>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12 col-md-4">
											<div class="form-group">
												<div class="input-group">
													<input id="{{pageId}}multiInvoiceDestinationId" type="text" class="form-control"
														(selectItem)="clickListnerForMultiInvoiceStation($event)"
														[(ngModel)]="modelMultiInvoiceDest"
														[ngbTypeahead]="searchMultiInvoiceDest"
														[resultFormatter]="formatterMultiInvoiceDest"
														[inputFormatter]="formatterMultiInvoiceDest"
														(focus)="focusMultiInvoiceDestTA$.next($any($event).target.value)" />
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-4">
											<div class="form-group">
												<div class="input-group">
													<input type="text" class="form-control"
														id="{{pageId}}multiInvoicePopupInvoiceFormat"
														aria-describedby=" basic-addon11 " autocomplete="off" />
	
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-2">
											<div class="form-group">
												<div class="input-group">
													<input type="text" class="form-control" id="{{pageId}}multiInvoicePopUpNo"
														aria-describedby=" basic-addon11 "
														(keypress)="keyEventHandlerMultiInvoicePopUp($event.keyCode)"
														autocomplete="off" />
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-2">
											<button title='Clear Value' type="button" class="btn btn-dark" id="{{pageId}}clear">
												<i class="fas fa-times"> </i>
											</button>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div class="card-body">
											<div class="box-body">
												<table datatable class="table table-striped table-bordered row-border hover"
													[dtOptions]="dtOptionsHireslipDetails"
													[dtTrigger]="dtTriggerHireslipDetails">
	
													<thead>
														<tr>
															<th>Invoice Number</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														<tr
															*ngFor="let multiInvoiceNoData of gridTosetInvoiceNumberList let i = index ">
															<td>{{ multiInvoiceNoData.cmMultiInvoice }}</td>
															<td>
																<button
																	style="padding: 1px 4px; background-color: #ffffff00;"
																	class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
																	(click)="rowSelectedDelete(multipleInvoiceNoLinkedHireslip,i)">
																	<i title="Remove" class="fas fa-trash"></i>
																</button>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>-->

<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}">
            <ng-template #hireSlipUpdateDriverNo let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>Update Driver Number</h5>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Driver New Number</label>
                                                <div class="input-group-prepend"></div>
                                                <input type="number" class="form-control" id="{{pageId}}driverNewMobileNumber" aria-describedby=" basic-addon11 " autocomplete="off" (keypress)="onKeyPressFieldForDriverNewMobileNo($event.keyCode)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-2">
                                        <button type="button" class="btn btn-dark" id="{{pageId}}updateBtnId" (click)="updateDriverNewMobileNoPopUpCreation()">
                                            Update</button>
                                    </div>
                                    <div class="col-sm-12 col-md-2">
                                        <button type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeNewDriverPopupBtn();">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #truckMasterPopUp let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>Truck Master</h5>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <app-truck-master></app-truck-master>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #uploadTruckOwnerPopUp let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>Declaration Upload</h5>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Truck Owner Declaration</label>
                                                <div class="input-group-prepend"></div>
                                                <input type="file" id="{{pageId}}truckOwnerFileUpload" (change)="onFileChangedTruckOwner($event)" #fileInputTruckOwner />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-2">
                                        <button type="button" class="btn btn-dark" id="{{pageId}}updateBtnId" (click)="uploadFileOfTruckOwnerDetails()">Upload</button>
                                    </div>
                                    <div class="col-sm-12 col-md-2">
                                        <button type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="d('Cross click')">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #uploadRCInsLicForTruck let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>Declaration Upload</h5>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>RC</label>
                                                <div class="input-group-prepend"></div>
                                                <input type="file" id="{{pageId}}popUpTruckRcFileUpload" (change)="onFileChangedTruckDetailPopUpRC($event)" #fileInputTruckForRC />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <button style='margin-top: 15px;' type="button" class="btn btn-success " id="{{pageId}}updateBtnId" (click)="uploadFileForTruckRCPopUpBtnUpload()">
                                            Upload</button>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Insurance</label>
                                                <div class="input-group-prepend"></div>
                                                <input type="file" id="{{pageId}}popUpTruckInsuranceFileUpload" (change)="onFileChangedTruckDetailPopUpInsurance($event)" #fileInputTruckForInsurance />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <button style='margin-top: 15px;' type="button" class="btn btn-success " id="{{pageId}}updateBtnId" (click)="uploadFileForTruckInsurancePopUpBtnUpload()">
                                            Upload</button>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>License</label>
                                                <div class="input-group-prepend"></div>
                                                <input type="file" id="{{pageId}}popUpTruckLicenseFileUpload" (change)="onFileChangedTruckDetailPopUpLicense($event)" #fileInputTruckForLicense />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <button style='margin-top: 15px;' type="button" class="btn btn-success " id="{{pageId}}updateBtnId" (click)="uploadFileForTruckLicensePopUpBtnUpload()">
                                            Upload</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <!--<div class="col-sm-12 col-md-2">
										<button type="button" class="btn btn-dark" id="{{pageId}}updateBtnId"
											(click)="uploadFileForTruckRCInsLicInPopUpBtnUpload()">
											Upload</button>
									</div>-->
                                    <div class="col-md-12 ">
                                        <button style='float: right;' type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="d('Cross click')">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<!-- 5 th page of Pop up Hire Slip end-->

<!-- Print Work Start Here Invoice  4 & 5 th page-->

<div *ngIf="viewInvoiceLoadingSheetPrint" onafterprint="afterPrint()" id="{{pageId}}printInvoiceLoadingSheet">
    <app-invoice-loadingsheet-print></app-invoice-loadingsheet-print>
</div>

<div *ngIf="viewInvoiceDetailsPrint" onafterprint="afterPrint()" id="{{pageId}}printInvoiceDetails">
    <app-invoice-details-print></app-invoice-details-print>
</div>
<div *ngIf="viewInvoiceSummaryPrint" onafterprint="afterPrint()" id="{{pageId}}printInvoiceSummary">
    <app-invoice-summary-print></app-invoice-summary-print>
</div>

<!--5 th page-->

<div *ngIf="viewInvoiceDetailsPrint_Hireslip" onafterprint="afterPrint()" id="{{pageId}}printInvoiceDetailsHireslip">
    <app-invoice-details-print></app-invoice-details-print>
</div>
<div *ngIf="viewInvoiceSummaryPrint_Hireslip" onafterprint="afterPrint()" id="{{pageId}}printInvoiceSummaryHireslip">
    <app-invoice-summary-print></app-invoice-summary-print>
</div>
<div *ngIf="viewHireslipDetailsPrint" onafterprint="afterPrint()" id="{{pageId}}printHireSlipDetailsCSS">
    <app-hireslip-details-print></app-hireslip-details-print>
</div>
<!-- Print Work Start Here Invoice  4 & 5 th page-->

<!-- LOCAL TRIP OPEN-->

<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #openLocalTripPopUpWidget let-ok="close" let-d="dismiss">
                <!--<div class="modal-header">
						<h5>Declaration Upload</h5>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>-->
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <!--<div *ngIf="viewStockLocalBookingTripPage" onafterprint="afterPrint()"
											id="{{pageId}}viewStockLocalBookingTripId">
											<app-hireslip-details-print></app-hireslip-details-print>
										</div>-->
                                    <app-stocks-for-local-booking></app-stocks-for-local-booking>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <button type="button" style='float: right;' class="btn btn-dark" id="{{pageId}}cancelBtnForLocalTripWidgetId" (click)="d('Cross click')">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>

<!-- LOCAL TRIP CLOSE-->