<h1 class="title_custom" mat-dialog-title>
	<h6 style="margin-bottom: 0px;">Short & Extra Details</h6>
</h1>
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
                    <div *ngIf="showSpinnerForAction" class="col-md-8">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
					<div class="row">
                        <div class="col-md-3">
                            <h6 style="white-space: nowrap;">
                                <strong>Invoice No :&nbsp;</strong>
                                <span style="color: green; font-weight: bold;" id="{{pageId}}invoiceNumber">{{listOfDataToGet}}</span>
                            </h6>
                        </div>
                    </div>
					<div class="row">
						<div class="box-body">
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsShortAndExtraDetails"
								[dtTrigger]="dtTriggerShortAndExtraDetails">

								<thead>
									<tr>
                                        <th [hidden]=true>LR No</th>
										<th>Art</th>
										<th>Status</th>
										<th>Belongs To</th>
										<th>Invoice Number</th>
										<th>Vehicle Number</th>
										<th>Remarks</th>
										<th>Entered By</th>
										<th>Entered At</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let shortExtraData of shortExtraDataLists ">
                                        <td [hidden]=true>{{ shortExtraData.lrNumber }}</td>
										<td>{{ shortExtraData.lrShortage }}</td>
										<td>{{ shortExtraData.status }}</td>
										<td>{{ shortExtraData.destination }}</td>
										<td>{{ shortExtraData.invoiceNumber }}</td>
										<td>{{ shortExtraData.vehicleNumber }}</td>
										<td>{{ shortExtraData.remarks }}</td>
										<td>{{ shortExtraData.userName }}</td>
										<td>{{ shortExtraData.enteredDateStr }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
                                        <td [hidden]=true>></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	
	<button class="btn btn-outline-success" mat-button
		[mat-dialog-close]="true" cdkFocusInitial>Close</button>

</div>
