<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showHireslipRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info hide-header" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Hireslip Details</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
                                                            <option selected value="hireslipNumber">Hireslip
                                                                Number</option>
                                                            <option value="dateWise">Date Wise</option>
                                                            <option value="summaryWise">Summary Wise</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByHireslipNo" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Hireslip Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}hireslipNumberID" #hireslipNumber name="hireslipNumberID" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByDateWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}sourceType" name="sourceType" #sourceType (change)="sourceTypeMode(sourceType.value)">
                                                            <option value="individualSource" selected>Individual
                                                                Source</option>
                                                            <option value="mainSource">Main Source</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="searchBySmryWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Summary Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}summaryType" name="summaryType" #summaryType (change)="summaryTypeMode(summaryType.value)">
                                                            <option value="mainInvoice" selected>Main
                                                                Invoice</option>
                                                            <option value="dummyInvoice">Dummy Invoice</option>
                                                            <option value="viaReport">Via Report</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewDate" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" [(ngModel)]="setTodayDateOnFromDate" placeholder="dd-mm-yyyy" name="fromDate" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewDate" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input class="form-control" id="{{pageId}}toDate" placeholder="dd-mm-yyyy" [(ngModel)]="setTodayDateOnToDate" name="toDate" ngbDatepicker #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showSrc" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="sourceListener($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                            placeholder="Select Source" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div *ngIf="showDest" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                            </span>
                                                        </div>
                                                        <input #destination id="{{pageId}}destinationID" type="text" class="form-control" [(ngModel)]="modelDropDownDestination" [ngbTypeahead]="searchDropDownInputFieldDestination" (selectItem)="clickListnerForDestination($event)" [resultFormatter]="formatterDropDownDestination"
                                                            [inputFormatter]="formatterDropDownDestination" placeholder="Select Destination" (focus)="focusDropDownDestinationTA$.next($any($event).target.value)" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showSrcSum" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}mainBookingSourceId" (selectItem)="clickListnerForMainBookingSource($event)" [(ngModel)]="modelMainBookingSource" [ngbTypeahead]="searchMainBookingSource" [resultFormatter]="formatterMainBookingSource"
                                                            [inputFormatter]="formatterMainBookingSource" (focus)="focusMainBookingSourceTA$.next($any($event).target.value)" placeholder="Select Source.." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showDestSum" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input id="{{pageId}}destinationSum" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestinationSum($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateBtnSearch()">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div *ngIf="showSpinnerForAction" class="col-md-8">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                    </mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="row p-t-10">
                                        <div class="col-md-8">
                                            <h6 class="card-title" [hidden]="!showForNoAndDateWise">Hireslip Details
                                            </h6>
                                            <h6 class="card-title" [hidden]="!showForMainAndDummyWise">HireSlip Details Report-Summary Wise</h6>
                                            <h6 class="card-title" [hidden]="!showForViaReportTable">HireSlip Details Report-Summary Via</h6>

                                        </div>
                                        <div class="col-md-4">
                                            <button type="submit" class="dt-button" [hidden]="!showForNoAndDateWise" style="margin-left: 75%;" (click)="customPrintHirslipRptForNumberAndDateWise()" id="{{pageId}}printBtnForHireslip">
                                                <span><i class="fas fa-print">Print</i></span>
                                            </button>
                                            <button type="submit" class="dt-button" [hidden]="!showForMainAndDummyWise" id="{{pageId}}printBtnForMainAndDummy" style="margin-left: 75%;" (click)="customPrintHirslipRptForMainAndDummyInvoiceWise()">
                                                <span><i class="fas fa-print">Print</i></span>
                                            </button>
                                            <button type="submit" class="dt-button" [hidden]="!showForViaReportTable" style="margin-left: 75%;" id="{{pageId}}printBtnForVia">
                                                <span><i class="fas fa-print">Print</i></span>
                                            </button>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="box-body" [hidden]="!showForNoAndDateWise">
                                                <table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}hireandDateDataTable" [dtOptions]="dtOptionsHireNdDateWise" [dtTrigger]="dtTriggerHireNdDateWise">

                                                    <thead>
                                                        <tr>
                                                            <th>Hireslip Date</th>
                                                            <th>Arrival Date</th>
                                                            <th>Hireslip No</th>
                                                            <th>Truck No</th>
                                                            <th>Driver No</th>
                                                            <th>Truck Owner</th>
                                                            <th>Owner Pan No.</th>
                                                            <th>Truck Type</th>
                                                            <th>Supplier Name </th>
                                                            <th>Supplier Contact No </th>
                                                            <th>Supplier Pan</th>
                                                            <th>LRs</th>
                                                            <th *ngIf="hideshowForGoodsLimitTransId">
                                                                Below {{hideshowForGoodsValueLimitForTransIdStr}}</th>
                                                            <th *ngIf="hideshowForGoodsLimitTransId">
                                                                Above {{hideshowForGoodsValueLimitForTransIdStr}}</th>

                                                            <th>Art</th>
                                                            <th>Gur Wgt</th>
                                                            <th>Kan Wgt</th>
                                                            <th>Act Wgt</th>
                                                            <th>Chg Wgt</th>
                                                            <th [hidden]="!showHyperLinkHireEdit">Hire Edit</th>
                                                            <th [hidden]="!showHireColumns">Total Hire</th>
                                                            <th [hidden]="!showHireColumns">Advance</th>
                                                            <th [hidden]="!showHireColumns">Balance</th>
                                                            <th [hidden]="!showHireColumns">Touching Hire</th>
                                                            <th>To Pay</th>
                                                            <th>Paid</th>
                                                            <th>Goods Value</th>
                                                            <th>F O V</th>
                                                            <th>Destination</th>
                                                            <th>Loading Incharge</th>
                                                            <th>Loader Head</th>
                                                            <th>Loader Name</th>
                                                            <th>Truck Status</th>
                                                            <th>Multiple Pt Hireslip</th>
                                                            <th>EWay Bill No</th>
                                                            <th>Tracking Id</th>
                                                            <th>Mobile Carrier</th>
                                                            <th>Tracking Status</th>
                                                            <th>Tracking Remarks</th>
                                                            <th>Driver Last Location </th>
                                                            <th>Last Ping Time</th>
                                                            <th>Remarks</th>
                                                            <th>Short & Extra</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let hireNdDataWise of hireNdDataWiseDataList ">
                                                            <td>{{ hireNdDataWise.hireSlipDateStr }}</td>
                                                            <td>{{ hireNdDataWise.arrivalDateStrTwo }}</td>
                                                            <td>
                                                                <a style="color: red; cursor: pointer;" (click)="tripScheduledForPopUpBtnLink(tripScheduledPopUpTemplate,hireNdDataWise)">
                                                                    <u>{{ hireNdDataWise.hireslipnumber }}</u>
                                                                </a>
                                                            </td>
                                                            <td>{{ hireNdDataWise.vehicleNumber }}</td>
                                                            <td>{{ hireNdDataWise.driverMobileNumber }}</td>
                                                            <td>{{ hireNdDataWise.companyName }}</td>
                                                            <td>{{ hireNdDataWise.tinNumber }}</td>
                                                            <td>{{ hireNdDataWise.truckType }}</td>
                                                            <td>{{ hireNdDataWise.suppliername }}</td>
                                                            <td>{{ hireNdDataWise.suppPhNo }}</td>
                                                            <td>{{ hireNdDataWise.supplierpanno }}</td>
                                                            <td>{{ hireNdDataWise.totalLRs }}</td>
                                                            <td *ngIf="hideshowForGoodsLimitTransId">{{ hireNdDataWise.goodsValueBelow }}</td>
                                                            <td *ngIf="hideshowForGoodsLimitTransId">{{ hireNdDataWise.goodsValueAbove }}</td>
                                                            <td>{{ hireNdDataWise.totalArticles }}</td>
                                                            <td>{{ hireNdDataWise.gurWeight }}</td>
                                                            <td>{{ hireNdDataWise.kantaWeight }}</td>
                                                            <td>{{ hireNdDataWise.actWeight }}</td>
                                                            <td>{{ hireNdDataWise.chargedWt }}</td>

                                                            <td [hidden]="!showHyperLinkHireEdit" style="cursor: pointer; color: blue;text-decoration: underline;" (click)="openHireEditPopUp(contentHireedit,hireNdDataWise)">
                                                                {{ hireNdDataWise.totalhire }}</td>
                                                            <td [hidden]="!showHireColumns">{{ hireNdDataWise.totalhire }}
                                                            </td>

                                                            <td [hidden]="!showHireColumns">{{ hireNdDataWise.advance }}
                                                            </td>
                                                            <td [hidden]="!showHireColumns">{{ hireNdDataWise.balance }}
                                                            </td>
                                                            <td [hidden]="!showHireColumns">{{ hireNdDataWise.touchingHire }}</td>
                                                            <td>{{ hireNdDataWise.toPay }}</td>
                                                            <td>{{ hireNdDataWise.paid }}</td>
                                                            <td>{{ hireNdDataWise.goodsValue }}</td>
                                                            <td>{{ hireNdDataWise.riskChgAmt }}</td>
                                                            <td>{{ hireNdDataWise.toStation }}</td>
                                                            <td>{{ hireNdDataWise.inchargeName }}</td>
                                                            <td>{{ hireNdDataWise.loaderHead }}</td>
                                                            <td>{{ hireNdDataWise.loadedby }}</td>
                                                            <td>{{ hireNdDataWise.status }}</td>
                                                            <td>{{ hireNdDataWise.hireslipnumber }}</td>
                                                            <td>{{ hireNdDataWise.ewayBill }}</td>
                                                            <td>{{ hireNdDataWise.trackingFTTripId==null ? "NA": hireNdDataWise.trackingFTTripId }}</td>
                                                            <td>{{ hireNdDataWise.trackingFTCarrier }}</td>
                                                            <td>{{ hireNdDataWise.trackingFTConsentStatus }}</td>
                                                            <td>{{ hireNdDataWise.ftRemarks }}</td>
                                                            <td>{{ hireNdDataWise.location }}</td>
                                                            <td>{{ hireNdDataWise.arrivaldateStr }}</td>
                                                            <td>{{ hireNdDataWise.hireslipRemarks }}</td>
                                                            <td style='font-size: 13px; font-weight: bold;'>
                                                                <span *ngIf="hireNdDataWise.chkStatus == 'Yes'">
                                                                    <a style='color: green; cursor: pointer;' (click)="shortAndExtraDetails(hireNdDataWise)">
                                                                        <u>{{ hireNdDataWise.chkStatus }}</u>
                                                                    </a>
                                                                </span>
                                                                <span *ngIf="hireNdDataWise.chkStatus != 'Yes'" style='color: blue;'>
                                                                    {{ hireNdDataWise.chkStatus }}
                                                                </span>
                                                            </td>
                                                            
                                                            
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td *ngIf="hideshowForGoodsLimitTransId"></td>
                                                            <td *ngIf="hideshowForGoodsLimitTransId"></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td [hidden]="!showHyperLinkHireEdit"></td>
                                                            <td [hidden]="!showHireColumns"></td>
                                                            <td [hidden]="!showHireColumns"></td>
                                                            <td [hidden]="!showHireColumns"></td>
                                                            <td [hidden]="!showHireColumns"></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div class="box-body" [hidden]="!showForMainAndDummyWise">
                                                <table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}mainandDummyWiseDatatable" [dtOptions]="dtOptionInvoice" [dtTrigger]="dtTriggerInvoice">
                                                    <thead>
                                                        <tr>
                                                            <th>Station</th>
                                                            <th>Actual Weight</th>
                                                            <th>Charged Weight</th>
                                                            <th>Guarantee Weight</th>
                                                            <th>Kanta Weight</th>
                                                            <th>To Pay</th>
                                                            <th>Paid</th>
                                                            <th>F O V</th>
                                                            <th [hidden]="!showHireColumns">Hire</th>
                                                            <th>Main Agent</th>
                                                            <th [hidden]="!showHireColumns">Advance</th>
                                                            <th [hidden]="!showHireColumns">Balance</th>
                                                            <th [hidden]="!showHireColumns">Touching Hire</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr *ngFor="let smryInvoiceData of smryInvoiceDataList ">
                                                            <td>{{ smryInvoiceData.toStation }}</td>
                                                            <td>{{ smryInvoiceData.actWeight }}</td>
                                                            <td>{{ smryInvoiceData.chargedWt }}</td>
                                                            <td>{{ smryInvoiceData.gurWeight }}</td>
                                                            <td>{{ smryInvoiceData.kantaWeight }}</td>
                                                            <td>{{ smryInvoiceData.toPay }}</td>
                                                            <td>{{ smryInvoiceData.paid }}</td>
                                                            <td>{{ smryInvoiceData.riskChgAmt }}</td>
                                                            <td [hidden]="!showHireColumns">{{ smryInvoiceData.totalhire }}
                                                            </td>
                                                            <td>{{ smryInvoiceData.branch }}</td>
                                                            <td [hidden]="!showHireColumns">{{ smryInvoiceData.advance }}
                                                            </td>
                                                            <td [hidden]="!showHireColumns">{{ smryInvoiceData.balance }}
                                                            </td>
                                                            <td [hidden]="!showHireColumns">{{ smryInvoiceData.touchingHire }}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td [hidden]="!showHireColumns"></td>
                                                            <td></td>
                                                            <td [hidden]="!showHireColumns"></td>
                                                            <td [hidden]="!showHireColumns"></td>
                                                            <td [hidden]="!showHireColumns"></td>
                                                        </tr>
                                                    </tfoot>

                                                </table>
                                            </div>

                                            <div class="box-body" [hidden]="!showForViaReportTable">
                                                <table datatable class="table table-striped table-bordered row-border hover" id="{{pageId}}viaReportTable" [dtOptions]="dtOptionViaReport" [dtTrigger]="dtTriggerViaReport">
                                                    <thead>
                                                        <tr>
                                                            <th>Station</th>
                                                            <th>Actual Weight</th>
                                                            <th>Charged Weight</th>
                                                            <th>To Pay</th>
                                                            <th>Paid</th>
                                                            <th>F O V</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr *ngFor="let smryViaReportData of smryViaReportDataList ">
                                                            <td>{{ smryViaReportData.lrDestination }}</td>
                                                            <td>{{ smryViaReportData.actWeight }}</td>
                                                            <td>{{ smryViaReportData.chargedWt }}</td>
                                                            <td>{{ smryViaReportData.toPay }}</td>
                                                            <td>{{ smryViaReportData.paid }}</td>
                                                            <td>{{ smryViaReportData.riskChgAmt }}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>

                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>


    <div class="col-md-12" id="{{pageId}}viewTripScheduledShowOrHidePopUp">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #tripScheduledPopUpTemplate let-ok="close" let-d="dismiss">

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div *ngIf="viewTripScheduledPopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupTripScheduledRpt">
                                                <app-stocks-grouping></app-stocks-grouping>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}printInvSummButton" (click)="invoiceSummaryBtnForPrint()">Print Invoice
                                                Summary</button>
                                            <button type="button" class="btn btn-dark" id="{{pageId}}printInvButton" style='float: right;' (click)="printBookingInvoice();">Print
                                                Invoice</button>
                                            <!-- 										<button type="button" class="btn btn-dark" -->
                                            <!-- 											id="{{pageId}}btnHireSlipPrint" style='float: right;' -->
                                            <!-- 											(click)="printHireslipButton();">Print Hire Slip</button> -->
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeTripScheduledPopUp()">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <!-- Hire amount edit option -->
    <ng-template #contentHireedit let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h6 class="modal-title" id="{{pageId}}modal-basic-title">Edit Hire amount details for Hirelsip Number : {{setHireslipNo}} </h6>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Total Hire</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-rupee-sign"></i>
                                    </span>
                                </div>
                                <input type="number" class="form-control" id="{{pageId}}totalHire" aria-describedby="basic-addon11" autocomplete="off" (change)="totalHireAmtChgBlur()" (keypress)="onKeyPressFieldForTotalHire($event.keyCode)" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Advance</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-rupee-sign"></i>
                                    </span>
                                </div>
                                <input type="number" class="form-control" id="{{pageId}}advance" aria-describedby=" basic-addon11" (change)="totalHireAmtChgBlur()" (keypress)="onKeyPressFieldForTotalHire($event.keyCode)" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Balance</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-rupee-sign"></i>
                                    </span>
                                </div>
                                <input type="number" class="form-control" id="{{pageId}}balance" aria-describedby=" basic-addon11 " autocomplete="off" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <button type="submit" class="btn btn-dark" (click)="updateHireslipAmtBtn()">Update</button>
                        <button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</body>

</html>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>
<div *ngIf="viewInvoiceSummaryPrint_Hireslip" onafterprint="afterPrint()" id="{{pageId}}printInvoiceSummaryHireslip">
    <app-invoice-summary-print></app-invoice-summary-print>
</div>
<div *ngIf="viewInvoiceDetailsPrint_Hireslip" onafterprint="afterPrint()" id="{{pageId}}printInvoiceDetailsHireslip">
    <app-invoice-details-print></app-invoice-details-print>
</div>