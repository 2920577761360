import { LRDto } from "./LR-dto";

export class UserDataDto {
    eMailAddress: string;
    userPasswd: string;
    firstname: string;
    lastName: string;
    addressId: string;
    vehiclesAllotted: number;
    createdBy: string;
    dateCreated: Date;
    updatedBy: string;
    dateUpdated: Date;
    timeStamp: Date;
    companyId: string;
    branchId: string;
    name: string;
    role: string;
    office: string;
    stationType: string;
    collectioMan: string;
    collManContct: string;
    memoNumber: string;
    list: string[];
    mode: string;
    mainStation: string;
    batchNumber: string;
    partyName: string;
    newPassword: string;
    chequeNumber: string;
    bankName: string;
    chequeDate: Date;
    paymentType: string;
    completedOn: Date;
    address: string;
    city: string;
    officeType: string;
    godownName: string;
    destType: string;
    serviceTax: number;
    serviceTaxAmt: number;
    tdsChg: number;
    stationCode: string;
    phoneNo: number;
    consigneeId: number;
    //    List<String> stationList;
    userName: string;
    contactNo: string;
    //    SortedMap<String, List<String>> sortedMapFeatures;
    //List < String > listOfDashboard;
    bankId: number;
    features: string;
    featuresName: string;
    paymentAmt: number;
    mainAdminStation: string;
    chequeStr: string;
    pdcStr: string;
    proviStr: string;
    chqAmt: number;
    pdcAmt: number;
    proviAmt: number;
    amount: number;
    type: string;
    dateStr: string;
    financialYear: string;
    maxChgWgt: number;
    maxActWgt: number;
    ratePerChgWgt: number;
    cmpEmailId: string;
    cmpEmailPassword: string;
    smsDuration: number;
    fromTime: string;
    toTime: string;
    timeLogin: boolean;
    faxNo: number;
    pinCode: number;
    addressEditCompany: string;
    phoneEditCompany: number;
    allowDuplicateMemo: boolean;
    allowDupMemoMessage: boolean;
    allowLogoMemo: boolean;
    IP: string;
    credentials: string;
    addition: number;
    delivDateOnArrival: boolean;
    belongsTo: string;
    menu: string;
    devmenu: string;
    mainStationAddress: string;
    mainStationTelephone: string;
    mainStationFax: string;
    // Niyamath
    srdPercentage: number;
    hamaliPercentage: number;
    payment: number;
    unit: string;
    unloadingHamaliChg: number;
    compMail: string;
    compMailPwd: string;
    blockedLrCount: number;
    isDestMemoRollBack: boolean;
    isCollectionManBlock: boolean;
    isOpenTrip: boolean;
    isGoodsLimitTransId: boolean;
    goodsLimitForTransId: string;
    autoLRCode: string;
    paymentAmtFormatted: string;
    chqAmtFormatted: string;
    pdcAmtFormatted: string;
    proviAmtFormatted: string;
    totalAmtFormatted: string;
    statementNo: string;
    validateTPReq: boolean;
    collectioManTo: string;
    gstNumber: string;
    balance: number;
    refNumber: string;
    status: string;
    collectionFlow: boolean;
    isLRHamaliEffect: boolean;
    isCODLrMemoBlock: boolean;
    //    List<String> ewayBillNoBlocks;
    ewayBillAlert: boolean;
    scChg: number;
    rcChg: number;
    blockBankLr: boolean;
    blockSelfLr: boolean;
    saveOrEdit: string;
    allEmailId: string;
    selectedEmail: string;
    userId: string;
    id: number;
    amountLimit: number;
    totalAmount: number;
    listOfEmails: string[];
    pdcType: string;
    pdcChequeNo: string;
    pdcAmount: number;
    chqType: string;
    chqNumber: string;
    listChqDetails: string[];
    financialYearSplit: string;
    result: string;
    memoStatus: string;
    column1: string;
    inLaserCashMemo: boolean;
    fovPerc: number;
    gcCharge: number;
    others: number;
    isLrCode: boolean;
    lrCode: string;
    unloadingHamaliChgGD: number;
    unitGD: string;
    listOfResponse: any;
    isLocalPending: boolean;
    listLessDetails: string[];
    listNEFTDetails: string[];
    listCashDetails: string[];
    listOdMenuId: string[];

    lrDtos: LRDto[];
    showStkGrpBins: boolean;

    //imran
    listOfScreenNames: string[];
    lrDtosFTRouteExcep: LRDto[];

    // basha bhaiya
    bayFlowInLR: boolean;
    //Basha21062021
    goodsValueLimitForTransIdStr: string;
    goodsValueLimitForTransId: number;
    eWayBillValidBeforeInHrs: number;
    memoTimePeriod: number;
    blockDestMemoTimePeriod: boolean;
    memoAssignEnabled: boolean;
    localTripMulti: boolean;
    localTripTrns: boolean;
    specCollectionFlow: boolean;
    disableHireInGeneration: boolean;
    isPincodeValidateLR: boolean;
    pinCodeStr: string;
    isDeleted: boolean;
    credentialsWhatsApp: string;
    lrbkgWhatsappMsg: boolean;
    hamaliddPerArt: number;
    showddHamaliLessDestMemo: boolean;
    demurrageExmpDays: number;
    demurrageChg: number;
    demurrageUnit: string;
    cftFormula: string;
    cftRate: number;
    consignorId: number;
    topartyConsignorId: number;
    fromDate: Date;
    toDate: Date;
    hamaliOtherEditBlockDestMemo: boolean;
    whatsAppApiKey: string;
    whatsAppApiLRBookFromNoKaleyra: string;
    whatsAppApiLrBkgTempNameKaleyra: string;
    demurageMsgPrintInDestMemo: boolean;
    destMemoPaymentModeBlock: boolean;
    sendDestPaymentCollectionWhatsApp: boolean;
    groupStation: string;
    showdestmemoloadingchgsetup: boolean;
    loadingChg: number;
    unitLoading: string;
    serviceChgOtherArea: number;
    unitServiceChgOtherArea: string;
    enableConsolidatedEWBAutoGeneration: boolean;
    enableConsolidatedEWBAutoGenerationLocalTrip: boolean;
    ewayAlertInMakingLocalTrip: boolean;
    destLocalTripBlock: boolean;
    blockChgWgtLrEntry: boolean;
    blockChgWgtLrEdit: boolean;
    sendWhatsappToConsigneeOnLRDelete: boolean;
    sendWhatsappToConsignorOnLRDelete: boolean;
    enableEwaybillPartBFlowOnLrEntry: boolean;
    leftToPartyBlockEnabled: boolean;
    destLeftToPartyAckSubmitPeriod: number;
    invoiceNumber: string;
    invoiceDate: Date;
    sendStockSms: boolean;
    lrDeleteMasterPwdReq: boolean;
    lrDeleteConsentReq: boolean;
    destPaymentQREnabled: boolean;
    blockLoadingForPANValidation: boolean;
    listDebitACDetails: string[];
    listUPIDetails: string[];
    listOfAppUserModule: UserDataDto[];
    screenName: string;
    routerLink: string;
    enableBarcodeGen:boolean;


}