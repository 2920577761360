import { TruckDataDto } from "./TruckData-dto";
import { MessageDto } from "./Message-dto";
import { HireSlipDto } from "./HireSlip-dto";
import { FortNightStmtDto } from "./FortNightStmt-dto";

export class LRDto {

  vehicleNumber: string;
  vehiCompName: string;
  vehiCompAddress: string;
  vehiCompCity: string;
  vehiCompPincode: string;
  vehiCompContactNum: string;
  vehiCompPanNum: string;
  vehiCompTinNum: string;
  driverName: string;
  lrNumber: string;
  bookingDate: Date;
  bookingDateStr: string;
  source: string;
  destination: string;
  agentName: string;
  consignorName: string;
  consigneeName: string;
  consignorId: number;
  consigneeId: number;
  totalArticles: number;
  description: string;
  actualWeight: number;
  chargedWeight: number;
  hamali: number;
  serviceCharge: number;
  aoc: number;
  insurance: number;
  others: number;
  totalAmount: number;
  deliverygodown: string;
  toPay: number;
  paid: number;
  goodsValue: number;
  billNumber: string;
  billDate: Date;
  agentId: string;
  invoiceNumber: string;
  status: string;
  financialYear: string;
  cashMemoNumber: string;
  cashMemoDate: Date;
  cashStxAmount: number;
  discountAmount: number;
  receiptCharge: number;
  userName: string;
  entryBy: string;
  enteredDate: Date;
  destinationCount: number;
  // list: string[];
  endDest: string;
  vehiCapacity: number;
  branch: string;
  subStations: string;
  branchType: string;
  totalLrs: number;
  stationType: string;
  toStation: string;
  fromStation: string;
  mode: string;
  lrsShortage: number;
  lrsExtra: number;
  stocksAt: string;
  additional: string;
  column1: string;
  goodsType: string;
  actualArt: number;
  rebookingStatus: string;
  lastUpdatedDate: Date;
  rebookingDate: Date;
  transportName: string;
  rebookingLrNumber: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
  column6: string;
  size: number;
  amount: string;
  dummyInvoice: string;
  lastUpdatedBy: string;
  noticeNumber: string;
  checkPostName: string;
  catchDate: Date;
  godownName: string;
  KVHInvNo: string;
  invoiceDL: string;
  BrokerName: string;
  GeneratedBy: string;
  collectionMan: string;
  Memo: string;
  MemoDate: Date;
  localCharge: number;
  GrossCharge: number;
  StatisticalCharge: number;
  hamaliGD: number;
  hamaliDD: number;
  driverContactNum: string;
  departuredate: Date;
  scheduleddate: Date;
  arrivalDate: Date;
  departureTime: string;
  scheduledTime: string;
  arrivalTime: string;
  invoiceDate: Date;
  hireslipDate: Date;
  tripId: number;
  tripDate: Date;
  tripNo: number;
  areaOfDelivery: string;
  memoHamaliGD: number;
  memoHamaliDD: number;
  OfficeType: string;
  address: string;
  loadingdate: Date;
  dispatchedwithin: number;
  dispatchedwithin1: number;
  arrivalwithin: number;
  deliveredwithin: number;
  id: number;
  totallrsfromTripSheet: number;
  totalarticlesfromTripSheet: number;
  deliveryareafromTripSheet: string;
  vehicleNofromTripSheet: string;
  tripNofromTripSheet: number;
  transportNameFromVehicleMaster: string;
  bookedFrom: string;
  fromDate: string;
  toDate: string;
  totalInvoice: number;
  reason: any;
  deliveryDate: Date;
  mainstation: string;
  deliveryarea: string;
  frieghtAmt: number;
  doorDelivChg: number;
  lcChg: number;
  bcChg: number;
  serviceTax: number;
  reportMode: string;
  servicetax: number;
  fromdate: Date;
  todate: Date;
  deliveryPoints: string;
  noOfDelvPnts: number;
  destType: string;
  deliveryType: string;
  demurage: number;
  subTotal: number;
  grandTotal: number;
  servTaxAmt: number;
  deliveryMode: string;
  paymentMode: string;
  memoName: string;
  guranteeWt: number;
  date: string;
  dateInDate: Date;
  fromLrNo: number;
  toLrNo: number;
  fortNightStmtNo: string;
  consignorTinNo: string;
  mainCommodity: string;
  role: string;
  password: string;
  subCommodity: string;
  commodityTP: string;
  packNature: string;
  tpNeeded: boolean;
  withOut: boolean;
  packName: string;
  staffName: string;
  commodityName: string;
  commodityCode: string;
  noOfLrs: number;
  privateMarker: string;
  loaderName: string;
  driverLicenceNo: string;
  supplierName: string;
  chgWtPercent: number;
  actWtPercent: number;
  destinationType: string;
  cashmemoType: string;
  collectionDate: Date;
  invoiceType: string;
  serTaxExemp: boolean;
  dummyVehicleNo: string;
  dummyCompanyName: string;
  dummyAddress: string;
  dummyCity: string;
  dummyPincode: string;
  dummyContactNo: string;
  dummyDriverName: string;
  dummyDrivContNo: string;
  dummyBrokerName: string;
  dummyGeneratedBy: string;
  dummyDepDate: Date;
  dummyDepTime: string;
  dummySchDate: Date;
  dummySchTime: string;
  dummyArrDAte: Date;
  dummyArrTime: string;
  via: string;
  memoGeneratedBy: string;
  devpMsg: string;
  sendMsg: boolean;
  mobileNum: string;
  message: string;
  bookDate: string;
  mainAgent: string;
  reportName: string;
  kantaWeight: number;
  name: string;
  emailId: string;
  isMerged: boolean;
  stationCode: string;
  isLrInCp: boolean;
  isCertNo: boolean;
  lrStatus: string;
  memoStatus: string;
  memoPayMentSts: string;
  lrStockCheck: boolean;
  adminReport: string;
  shortSummary: string;
  stmtDate: Date;
  stmtFromToPeriod: string;
  bkgAgtRptAgent: string;
  bkgAgtRptDestination: string;
  city: string;
  remarks: string;
  totalHire: number;
  unLoadingDate: Date;
  unloaderHead: string;
  AgtChgWgt: number;
  monthName: string;
  // private List<Double> listToPay;
  invoiceDateStr: string;
  addition: string;
  deduction: string;
  chequeAmt: string;
  cashAmt: number;
  pdcAmt: string;
  viaTotalAmt: number;
  viaActWeight: number;
  viaChargedWeight: number;
  mixActWt: number;
  mixChgWt: number;
  mixActKantaWeights: number;
  mixTotalAmount: number;
  trucksPerDay: string;
  frtPerKgStr: string;
  frtPerKg: number;
  hirePerKg: number;
  periodRange: number;
  listOfStation: string[];
  // private List<String> listOfPeriods;
  enteredChargeWeight: number;
  diffValue: number;
  office: string;
  //private List<String> listOfStationCodes;
  rateSrcStation: string;
  rateSrcHamali: number;
  rateCommodity: string;
  rateDestHamali: number;
  rateUnit: string;
  rateServiceChg: number;
  ratePerUnit: string;
  rateReceiptChg: number;
  rateGcChg: number;
  rate: number;
  rateDiscountOn: string;
  toBeBilled: boolean;
  toBeBilledParty: boolean;
  memoInvId: number;
  isPrint: boolean;
  blocked: boolean;
  blockedRemarks: string;
  enteredLessAmt: number;
  isLRCode: boolean;
  lrCode: string;
  //private List<Integer> listOfConsigneId;
  listOfConsigneId: number[];
  baseFreight: number;
  gcCharge: number;
  hamaliLR: number;
  //private HashMap<Integer, String> hashMap;
  incentiveAmt: number;
  penaltyAmt: number;
  //private SortedMap<Integer, String> sortedMap;
  cashMemoTime: Date;
  cashMemoBlocked: boolean;
  smsDuration: number;
  idLrShortage: number;
  labourHamaliStmt: string;
  fromTime: string;
  toTime: string;
  timeLogin: boolean;
  contactPerson: string;
  diffDays: number;
  duplicateMemo: boolean;
  transitMemoPrt: boolean;
  serviceTaxChanged: number;
  serviceTaxDiff: number;
  consignorMobileNo: string;
  unldgCommodities: string;
  unldgChg: string;
  unldgUnit: string;
  unldgSource: string;
  bookingAgntStmt: string;
  isPickUpLR: boolean;
  isLrDummyInv: boolean;
  addressAgent: string;
  isUnLoadByConsignee: boolean;
  DoorDelivAmt: number;
  doorDelivAmtChg: number;
  commodityConfirm: boolean;
  reminderSms: boolean;
  stockSms: boolean;
  avgTruckReq: number;
  bkgMemoAmt: number;
  isTrue: boolean;
  rights: string;
  actualDays: number;
  daysTaken: number;
  delayDays: number;
  fromAgtDays: number;
  atSRCDays: number;
  toTransDays: number;
  atTransDays: number;
  toDestDays: number;
  ddAddress: string;
  lrLorryCopy: boolean;
  lrConsigneeCopy: boolean;
  lrConsignorCopy: boolean;
  validBookDate: boolean;
  gatePass: boolean;
  selfLr: boolean;
  dateMode: string;
  eDeclarationCommodity: string;
  smsCredential: string;
  pinCode: string;
  officeNo: string;
  Pref: string;
  supplierPannumber: string;
  godownEntry: string;
  isBankLr: string;
  bankName: string;
  totalDownlaod: string;
  toatalgiftsent: string;
  totAndroidDownlaods: string;
  totIphDownlaods: string;
  state: string;
  eDeclarationPwd: string;
  bankLr: string;
  ccAttach: boolean;
  eSugamNo: string;
  chgWtLr: number;
  packNatureLr: string;
  localBkgDeliveryArea: string;
  actLrEntryDate: Date;
  clerkName: string;
  tripFrom: string;
  eDeclarationBy: string;
  destHamali: number;
  destHamaliPerArt: number;
  dummyinvoice2: string;
  srcHamali: number;
  bkgReceiptCharge: number;
  bkgServiceCharge: number;
  bkgMemoDisountAmt: number;
  bkgLrTotalAmount: number;
  bkgMemoSubTotal: number;
  bkgMemoGrandTotal: number;
  lrShortage: string;
  srcUserName: string;
  destUserName: string;
  srcArt: string;
  destArt: string;
  totalConsignee: number;
  activeConsignee: number;
  nonActiveConsignee: number;
  totalDelConsignee: number;
  totalMumConsignee: number;
  activeDelConsignee: number;
  nonActiveDelConsignee: number;
  activelMumConsignee: number;
  nonActiveMumConsignee: number;
  barCodeUrl: string;
  viewPod: string;
  sourceCity: string;
  destinationCity: string;
  sourceState: string;
  destinationState: string;
  companyId: string;
  desCommodityName: string;
  srcMemo: string;
  srcMemoDate: Date;
  desMemo: string;
  desMemoDate: Date;
  isAutoLR: boolean;
  addressConsignee: string;
  addressConsignor: string;
  faxNo: string;
  groupName: string;
  fromChallanNo: number;
  toChallanNo: number;
  noOfChallan: number;
  challanNo: string;
  withLetterHead: boolean;
  commodityId: number;
  roundOffAmt: number;
  loadingChg: number;
  thappiChg: number;
  levi: number;
  warefare: number;
  extLdgChg: number;
  extThappiChg: number;
  unLdgChg: number;
  extUnLdgChg: number;
  isDoorDeliv: boolean;
  ddAmt: number;
  multiArtSize: string;
  isHandWithCare: boolean;
  isNewConsignee: boolean;
  isCod: boolean;
  isEditDelete: boolean;
  actualLessAmt: number;
  LcCharge: boolean;
  BcCharge: boolean;
  AOCCharge: boolean;
  InsCharge: boolean;
  DDCharge: boolean;
  isFixedRateCharge: boolean;
  OthersCharge: boolean;
  HamaliCharge: boolean;
  gstNoConsignee: string;
  gstNoConsignor: string;
  consigneeAdress: string;
  consignorAdress: string;
  consigneeState: string;
  consignorState: string;
  consigneeStateCode: string;
  consignorStateCode: string;
  //    IGST: number;
  //    IGSTAmt: number;
  //    CGST: number;
  //    CGSTAmt: number;
  //    SGST: number;
  //    SGSTAmt: number;
  igstamt: number
  cgstamt: number;
  sgstamt: number;
  igst: number;
  cgst: number;
  sgst: number
  gstAmtTotal: number;
  code: string;
  isUrgentDly: boolean;
  isUrgentDlyLoaded: boolean;
  urgentDlyMarkedAt: Date;
  transportId: string;
  isGSTMemo: boolean;
  urgentDly: string;
  partyArea: string;
  consigneeMobileNo: string;
  branchAddress: string;
  branchCity: string;
  branchPincode: number;
  branchPhNo: number;
  branchEmailId: string;
  eWayBillNo: string;
  arrDateAtTrnspDate: Date;
  depDateFrmTrnspDate: Date;
  arrDateAtTrnspDate2: Date;
  depDateFrmTrnspDate2: Date;
  isOrderGoods: boolean;
  perUnit: boolean;
  settlementMode: string;
  chequeNumber: string;
  settlementAmt: number;
  chequeDate: Date;
  settlementDate: Date;
  claimRemarks: string;
  claimchequeNumber: string;
  claimBankName: string;
  claimchequeDate: Date;
  goodsReturn: string;
  riskCharge: number;
  riskChg: boolean;
  giftElgMode: string;
  partyType: string;
  giftStatus: string;
  localTripStatus: string;
  isOpenTrip: boolean;
  autoLRCode: string;
  bookingLocalTripId: number;
  memoBlockReason: string;
  memoBlockDate: Date;
  memoBlocked: boolean;
  qty: number;
  // private List<LRDto> lrDtos;
  amtFormatted: string;
  lat: number;
  lng: number;
  deviceId: string;
  location: string;
  refNumber: string;
  partyId: number;
  tdsCertfNo: string;
  imgURLTDS: string;
  imgUploadedTDS: boolean;
  isGujDisc: boolean;
  docStatusTds: string;
  cmAtMemoGeneration: string;
  hamaliRC: number;
  hamaliSC: number;
  codPaymentMode: string;
  codPaymentBankName: string;
  codPaymentChqNo: string;
  codPaymentRefNo: string;
  codPaymentChqDate: Date;
  ton: string;
  clerkAmt: number;
  unloadingHamaliAmt: number;
  tripsheetHamali: number;
  memoLessAmt: number;
  memoLessType: string;
  InsChg: boolean;
  DD: boolean;
  riskChargeSet: boolean;
  fovPerc: number;
  chqAmt: number;
  neftAmt: number;
  mLessAmt: number;
  enableBankLrForDestMemo: boolean;
  autoId: number;
  localTripNumber: string;
  enableSelfLrForDestMemo: boolean;
  consignorIndex: string;
  listPdcDetails: string[];
  serachBy: string;
  listFromStations: string[];
  listToStations: string[];
  mainStationDesc: string;
  station: string;
  invoiceGeneratedBy: string;
  lrGeneratedBy: string;
  articlesInMultiple: string;
  length: string;
  breadth: string;
  height: string;
  listOfmultipleArt: string[];
  enableBlockedLrForDestMemo: boolean;
  cashMemoBlock1: boolean;
  chkStatus: string;
  destList: string;
  listOfDestination: string[];
  isAllow: boolean;
  toDayDate: Date;
  memoNumber: string;
  serTaxPayBy: number;
  lrEntryCopyType: string;
  lrEntryMode: string;
  lrEntryPrintMode: string;
  multiArtSizeForPrint: string;
  doorDeliveryAmount: number;
  listOfmultiplePack: string[];
  multiplePackNatureNew: string;
  multiplePackNatureArt: number;
  isLocalPending: boolean;
  pickLr: boolean;
  otherChgAmtDestMemo: number;
  codChg: number;
  challanKantaWgt: number;
  challanTotLrs: number;
  loadingDateStr: string;
  officeType: string;

  module: string;
  selectedTripTo: string;
  lrDtoEligible: LRDto[];
  lrDtoRemains: LRDto[];
  lrDtoList: LRDto[];

  enteredDateStr: string;
  deliveryDateStr: string;
  departuredateStr: string;
  arrivalDateStr: string;
  depDateFrmTrnspDateStr: string;
  arrDateAtTrnspDateStr: string;
  DepDateFrmTrnspDate2Str: string;
  arrDateAtTrnspDate2Str: string;

  ewayBillStatus: string;
  tripTo: string;
  truckDataDto: TruckDataDto = new TruckDataDto();
  //addded 23-02-2021
  list: Array<String>; //replace top same
  //invoiceDL: string;
  isHandWithCareStr: string;
  selfLrStr: string;

  credentials: string;
  lrDtos: LRDto[];
  isRateEffect: boolean;

  ///Added For Date In lr enquiry form 220321
  billDateStr: string;
  collectionDateStr: string;
  cashMemoTimeStr: string;
  ewayBillValidUpto: Date;
  vehicleNofromTripSheetSrc: string;
  tripDateSrc: string;

  localBkgDeliveryAreaSrc: string;
  dummyDriverNameSrc: string;
  offfice: LRDto;

  //stocklocal bookin @maaz 27032021
  localTripStation: string;
  isLocalTripSepLogic: boolean;
  localTripSpeLogicStations: string;
  bayNo: string;

  //basha bhaiay
  //bayNo:string; already declared
  bayUnloadingTime: string;
  bayUnloadingDate: Date;

  // imran 03042021
  updateUnLoader: string;
  loaderHead: string;
  subStation: string;

  // maaz 15042021
  stmtDateStr: string;
  //basha 19052021
  unloadingDateStr: string;
  hireslipDateStr: string;
  vendorNameAndItemName: string;
  //basha 24052021
  memo: string;
  //26052021
  listAgent: Array<String>;
  hashMapForFileUpload: Map<String, Number> = new Map<String, Number>();
  tripDateStr: string;
  //18062021
  messageDto: MessageDto = new MessageDto();
  hireSlipDto: HireSlipDto = new HireSlipDto();
  hireSlipDtoList: HireSlipDto[];
  fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();
  loadingHamali: number;
  unLoadingHamali: number;
  totLoadingHamaliAmt: number;
  totUnloadingHamaliAmt: number;
  truckType: string;
  fromPeriodStr: string;
  toPeriodStr: string;
  agentCode: string;
  bayFlow: boolean;
  barcodeValue: string;
  dwsawb: string;
  dwsweight: number;
  dwsvolumeweight: number;
  artSize: string;
  dwsListOfmultipleArt: string[];
  diffTotalArticles: number;
  diffActualWeight: number;
  diffChargedWeight: number;
  dwsawbTotal: number;
  listOfmultipleEwayBill: string[];
  isMultiEwayBill: boolean;
  isEwayBillExempted: boolean;
  listOfEWay: Array<String>;
  listOfLrBarcodes: LRDto[];
  scannedArticles: number;
  listOfBarcodes: string[];
  remArticles: number;
  fortNightStmtDtosArray: FortNightStmtDto[];
  localTripMulti: boolean;
  localTripTrns: boolean;
  mobileNumber: string;
  consignorRateId: number;
  credentialsWhatsApp: string;
  consignorEInvoice: string;
  consignorEInvoiceEnable: string;
  ddHamaliLess: number;
  demurageExmp: boolean;
  actLrEntryDateStr: string;
  viewPodLrCopy: string;
  fromRange: number;
  toRange: number;
  considerValue: number;
  inchargeName: string;
  chgWgtRange: number;
  chgWgtCft: number;
  chgWgtDiff: number;
  cftRate: number;
  commonCommodity: string;
  tpStmtNo: string;
  consigneeMemoBlocked: boolean;
  consigneeMemoBlockReason: string;
  enableHamaliEditForDestMemo: boolean;
  ccCopyEnabled: boolean;
  tdsLessDiscountType: string;
  tdsLessDisc: string;
  tdsLessNotDisc: string;
  partyName: string;
  tdsAmount: number;
  chqDate: Date;
  diffAmount: number;
  tdsDocument: string;
  refrenceNumber: string;
  createdDate: Date;
  areaName: String;
  createdDateStr: String;
  respStatus: number;
  ewayBillValidUptoStr: string;
  enableCCCopyLRForDestMemo: boolean;
  whatsAppApiKey: string;
  whatsAppApiLRBookFromNoKaleyra: string;
  whatsAppApiLrBkgTempNameKaleyra: string;
  enableDemurageLRForDestMemo: boolean;
  isOfferLR: boolean;
  groupStation: string;
  consigneeRateId: number;
  respEwayBillNo: string;
  extnRemarks: string;
  ewayBillDCNo: string;
  isExpired: boolean;
  sizeTotArt: number;
  sizeTotActWgt: number;
  sizeTotChgWgt: number;
  loadingArticle: number;
  detainedDate: any;
  gstDetainedDate: any;
  gstDetainedRemarks: any;

  // lrBkgDateCustomChange
  increamentDays: number;
  weekendDays: string;
  addingDays: number;
  weekendDaysNumber: number;
  startTime: number;
  endTime: number;
  stmtNo: string;
  ewbVehicleNo: string;
  consignorAdress2: string;
  consignorPlace: string;
  consignorPincode: string;
  consigneeAdress2: string;
  consigneePlace: string;
  consigneePincode: string;
  cessRate: number;
  cessAmount: number;
  cessNonAdvolRate: number;
  cessNonAdvolAmount: number;
  loadingRemarks: string;
  isGstDetained: boolean;
  listOfmultipleArtCft: string[];
  multiArtSizeCft: string;
  isPerformanceMonitor:boolean;
  checked: boolean;
  isSizeExceeded: boolean;
  commodityKgLimit:number
  isPerRateKg:boolean;
  isHandWithCarePrint: boolean; 
  isBlockedEwayBill: boolean;
  consignorPartyType: string;
  consigneePartyType: string;
  lorryHire: number;
  advance: number;
  balance: number;
  whatsAppApiLRBookFromNoVerloop: string;
  whatsAppApiLrBkgTempNameVerloop: string;
  isTwoPointDeliveryLr: boolean;
  subArea: string;
  twoPointLrs: string;
  enableMemoEdit: boolean;
  tripDateSrcStr: string;
  type: string;
  gstNumber: string;
  unclearedLrReason: string;
  isUnclearedLr: boolean;
}