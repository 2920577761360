<div *ngIf="isLoggedIn" class="page row system_responsive " id="section_to_print" style="margin-bottom: 5px;">
    <div id="image-lrprint-consignee">
        <div class="container border">
            <div class="col-md-12">
                <!--first row starts-->
                <div class="row" style="padding-top: 5px;">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-7">

                                <!--<img id="{{pageId}}barcodeImg" stylid="{{pageId}}height: 70px;">-->
                                <!-- For loacl -->
                                <!--<img src='src/cashMemoBarcode/{{rateDtoForPrint.withOutSlash}}.jpg' style="height: 70px;"> -->
                                <!-- For QA -->
                                <!-- 							<img src='D:/SRD_BardcodeImg/destCashMemoBarcode/'+{{rateDtoForPrint.withOutSlash}}+'.jpg' style="height: 70px;"> -->
                                <!-- For Live -->
                                <!--<img src='/home/ubuntu/SRDNewAppDeploymentFiles/SRD_BardcodeImg/destCashMemoBarcode/{{rateDtoForPrint.withOutSlash}}.jpg' style="height: 70px;"> -->

                                <tr *ngFor="let PS of PrintBarcode">
                                    <!-- <ngx-barcode [bc-width]="1.5" [bc-height]="27" [bc-font-size]="13"
									[bc-value]="PS.barcode" [bc-display-value]="true">

								</ngx-barcode> -->
                                    <ngx-barcode6 [bc-format]="'CODE128'" [bc-width]="1.5" [bc-height]="27"
                                        [bc-font-size]="13" [bc-value]="PS.barcode" [bc-display-value]="true">
                                    </ngx-barcode6>
                                </tr>

                            </div>
                            <div class="col-md-5">
                                <h6 class="border_bottom dsply-ib">CASH/CREDIT MEMO
                                    <strong *ngIf="duplicatePrintText">({{ duplicatePrintText }})</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row" style="padding-top: 5px; padding-bottom: 15px;">
                            <div class="col-md-6" style="margin-left: -10px;">
                                <div class="form-group">
                                    <div class="input-group">
                                        <h6>
                                            <strong> {{addressLineOne}}<br>{{addressLineTwo}}<br>{{cityPin}}
                                            </strong>
                                        </h6>

                                    </div>
                                </div>


                            </div>
                            <div class="col-md-6">
                                <h6>
                                    <strong> Tel.: {{phone}}<br>Email : {{emailId}}
                                    </strong>
                                </h6>
                            </div>
                            <div class="col-md-12" style="text-align: center;">
                                <h6>
                                    <strong> {{dupMsg}} </strong>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!--<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">-->
                    </div>
                </div>

                <!--first row ends -->
                <!--seconde row Starts-->
                <div class="row">
                    <div class="col-md-6">
                        <!-- <div *ngIf="isNewParty" class="row">
                            <div class="col-md-12">
                            </div>
                        </div> -->

                        <div class="row" style="padding-bottom: 10px;">
                            <div class="col-md-3">
                                <h6 style="font-size: 16px !important;">
                                    <strong>MEMO NO</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6 style="font-size: 16px !important;">
                                    <span
                                        style="float: left; margin-left: -10px; font-weight: bolder; font-weight: bolder;">:
                                    </span><strong>
                                        {{rateDtoForPrint.memoNumber}}{{slash}}{{rateDtoForPrint.cashMemoType}}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>CONSIGNOR</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{ rateDtoForPrint.consignor }}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>ADDRESS</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{ rateDtoForPrint.consignorAddress }}</strong>
                                </h6>
                            </div>
                        </div>
                        <!-- 					<div class="row"> -->
                        <!-- 						<div class="col-md-3"> -->
                        <!-- 							<h6>DISTRIC</h6> -->
                        <!-- 						</div> -->
                        <!-- 						<div class="col-md-9" style="margin-left: -30px;"> -->
                        <!-- 							<h6>: HAJJAR</h6> -->
                        <!-- 						</div> -->
                        <!-- 					</div> -->
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>STATE</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{rateDtoForPrint.consignorState}}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>STATE CODE</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{ rateDtoForPrint.consignorStateCode}}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>GSTIN No</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{ rateDtoForPrint.gstConsgnor }}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>MOBILE No</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{rateDtoForPrint.consignorMobileNo }}</strong>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- <div *ngIf="isNewParty" class="row">
                            <div class="col-md-12">
                                <h6>
                                    <strong> NRG </strong>
                                </h6>
                            </div>
                        </div> -->

                        <div class="row" style="padding-bottom: 10px;">
                            <div class="col-md-3">
                                <h6 style="font-size: 16px !important;">
                                    <strong>DATE</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px; display: flex;">
                                <h6 style="font-size: 16px !important;">
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{ todayDate}}</strong>
                                </h6>

                                <h6 *ngIf="isNewParty"
                                    style="font-weight: bolder; font-size: 20px !important;margin-left: 250px;">
                                    <strong>NRG</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>CONSIGNEE</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{ rateDtoForPrint.consignee}}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>ADDRESS</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{rateDtoForPrint.consigneeAddress }}</strong>
                                </h6>
                            </div>
                        </div>
                        <!-- 					<div class="row" style="padding-top: 10px"> -->
                        <!-- 						<div class="col-md-3"></div> -->
                        <!-- 						<div class="col-md-9" style="margin-left: -30px;"></div> -->
                        <!-- 					</div> -->
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>STATE</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{rateDtoForPrint.consigneeState}}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>STATE CODE</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{rateDtoForPrint.consigneeStateCode}}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>GSTIN No</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{rateDtoForPrint.gstConsignee}}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6>
                                    <strong>MOBILE No</strong>
                                </h6>
                            </div>
                            <div class="col-md-9" style="margin-left: -30px;">
                                <h6>
                                    <span style="float: left; margin-left: -10px; font-weight: bolder;">:
                                    </span><strong>{{rateDtoForPrint.consigneeMobileNo }}</strong>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!--seconde row ends-->
                <!--third row starts-->
                <div class="row border_top border_bottom">
                    <div class="col-md-8 txt_ac">
                        <h6>
                            <strong>BOOKING DETAILS</strong>
                        </h6>
                    </div>
                    <div class="col-md-4 txt_ac">
                        <h6>
                            <strong>AMOUNT CHARGES</strong>
                        </h6>
                    </div>
                </div>
                <!--third row ends-->
                <!--fourth row starts-->
                <div class="row">
                    <div class="col-md-8 border_right">
                        <div class="row" style="height: 50%;">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>Invoice No</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{rateDtoForPrint.invoiceNumber}}</h6>
                                    </div>
                                </div>
                                <div class="row" style="font-size: 15px !important;">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>G.C. Note No</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>
                                            : <strong>{{rateDtoForPrint.gcNoteNumber}}</strong>
                                        </h6>
                                    </div>
                                </div>
                                <div *ngIf="viewCommodity" class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>Commodity</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{rateDtoForPrint.commodityName}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>Godown</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{rateDtoForPrint.godown}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>Collection Man</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{collMan}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>Paid</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{rateDtoForPrint.paid}}</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 border_left_V2">
                                <div *ngIf="viewEWayBill" class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>E- Way Bill No</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{eWayBill}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>Booking Date</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{rateDtoForPrint.bookingDate}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>No. Of Article</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{rateDtoForPrint.totalArticles}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>Weight</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{rateDtoForPrint.weight}} Kgs</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>Area</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{rateDtoForPrint.area}}
                                            <span *ngIf="showSubArea">({{ rateDtoForPrint.subArea }})</span>
                                        </h6>
                                    </div>
                                </div>

                                <div *ngIf="viewConsolidatedEwayBill" class="row">
                                    <div class="col-md-5">
                                        <h6>
                                            <strong>Consolidated E-Waybill</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7" style="margin-left: -10px;">
                                        <h6>: {{rateDtoForPrint.consolidatedEWayBillNo}}</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- <div class="row border_top">
                            <div class="col-md-12">
                                <div *ngIf="viewEWayBill" class="row">
                                    <div class="col-md-3">
                                        <h6>
                                            <strong>E- Way Bill No</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-9" style="margin-left: -40px;">
                                        <h6>: {{eWayBill}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <h6>
                                            <strong>Booking Date</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-9" style="margin-left: -40px;">
                                        <h6>: {{rateDtoForPrint.bookingDate}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <h6>
                                            <strong>No. Of Article</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-9" style="margin-left: -40px;">
                                        <h6>: {{rateDtoForPrint.totalArticles}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <h6>
                                            <strong>Weight</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-9" style="margin-left: -40px;">
                                        <h6>: {{rateDtoForPrint.weight}} Kgs</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <h6>
                                            <strong>Area</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-9" style="margin-left: -40px;">
                                        <h6>: {{rateDtoForPrint.area}}</h6>
                                    </div>
                                </div>

                                <div *ngIf="viewConsolidatedEwayBill" class="row">
                                    <div class="col-md-3">
                                        <h6>
                                            <strong>Consolidated E-Waybill</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-9" style="margin-left: -40px;">
                                        <h6>: {{rateDtoForPrint.consolidatedEWayBillNo}}</h6>
                                    </div>
                                </div>

                            </div>
                        </div> -->

                        <!-- <div class="row border_top" style="padding: 1px;">
                            <div class="col-md-6"
                                style="font-size: 13px; padding: 5px 0px 0px 10px; margin-left: -10px; max-width: 100% !important; flex: 0 0 45%;">
                                <h6 style="font-size: 13px;">GST is payable on Reverse Charge Mechanism</h6>
                            </div>
                            <div class="col-md-6" style="max-width: 100% !important; flex: 0 0 55%;">
                                <div class="row">
                                    <div class="col-md-4">
                                        <h6 style="font-size: 12px;">
                                            <strong>Pan NO</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8" style="margin-left: -7px;">
                                        <h6 style="font-size: 12px;">
                                            : AAMCS7073E / <strong>SAC CODE</strong>: 996791
                                        </h6>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <h6 style="font-size: 12px;">
                                            <strong>CIN</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8" style="margin-left: -7px;">
                                        <h6 style="font-size: 12px;">: U63090MH2009PTC189328</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <h6 style="font-size: 12px;">
                                            <strong>Transporter Id</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8" style="margin-left: -7px;">
                                        <h6 style="font-size: 12px;">: 29AAMCS7073E1Z0</h6>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="row border_top" style="padding: 1px;">

                            <div class="col-md-6"
                                style="text-align: center; padding: 5px; border-right: 1px solid black; width: 100%;">

                                <div style="text-align: left; font-size: 13px; font-weight: bold; border-bottom: 1px solid black !important;"
                                    class="row align-items-center border_bottom"  *ngIf="rateDtoForPrint.paidFromPaymentLink">
                                    {{onlinePaymentRemarks}}
                                </div>


                                <div class="row align-items-center" style="margin-top: 10px; width: 100%;">

                                    <div class="col-md-6 text-center">
                                        <img src="{{qrImgPath}}" alt="QRCode" style="height: 85px;">
                                    </div>


                                    <div class="col-md-6">
                                        <h6 style="font-size: 13px;">Please scan the QR to make the payment</h6>
                                    </div>
                                </div>
                            </div>




                            <div class="col-md-6 border_left_V2">
                                <div class="row">
                                    <div class="col-md-12" style="font-size: 13px; padding: 5px;">
                                        <h6 style="font-size: 13px;">GST is payable on Reverse Charge Mechanism</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <h6 style="font-size: 12px;">
                                            <strong>Pan NO</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8">
                                        <h6 style="font-size: 12px;">
                                            : AAMCS7073E / <strong>SAC CODE</strong>: 996791
                                        </h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <h6 style="font-size: 12px;">
                                            <strong>CIN</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8">
                                        <h6 style="font-size: 12px;">: U63090MH2009PTC189328</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <h6 style="font-size: 12px;">
                                            <strong>Transporter Id</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8">
                                        <h6 style="font-size: 12px;">: 29AAMCS7073E1Z0</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row border_top" style="padding: 0px;">
                            <div class="col-md-12 fontS_13 font_bold" style="margin-left: -10px;">
                                <h6>
                                    <strong>{{bottomMsg1}}</strong>
                                    <br>
                                    <strong>{{remakrsOfferMsg}}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row border_top" style="padding: 1px;">
                            <div class="col-md-12" style="margin-left: -10px;">
                                <h6 class="font_bold" [innerHtml]="bankDetails" style="font-size: 13px;">
                                    <strong>{{bankDetails}}</strong>
                                </h6>
                            </div>
                        </div>
                        <!-- 					<div class="row border_top" style="padding: 1px;"> -->
                        <!-- 						<div class="col-md-12 fontS_13"> -->
                        <!-- 							<h6> -->

                        <!-- 							</h6> -->
                        <!-- 						</div> -->
                        <!-- 					</div> -->

                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-6 border_right">
                                <div class="row" style="margin-left: -24px;">
                                    <div class="col-md-12">
                                        <h6>
                                            <strong>Lorry Freight</strong>
                                        </h6>
                                    </div>
                                    <div *ngIf="viewLocalChg" class="col-md-12">
                                        <h6>
                                            <strong>Local Charge</strong>
                                        </h6>
                                    </div>
                                    <div *ngIf="viewHamaliGd" class="col-md-12">
                                        <h6>
                                            <strong *ngIf="!loadingHamaliOptionFlowPrint">Hamali G.D</strong>
                                            <strong *ngIf="loadingHamaliOptionFlowPrint">Loading Hamali</strong>
                                        </h6>
                                    </div>
                                    <div *ngIf="viewUnloadingChg" class="col-md-12">
                                        <h6>
                                            <strong>Unloading Chg</strong>
                                        </h6>
                                    </div>
                                    <div *ngIf="viewDoorDlryChg" class="col-md-12">
                                        <h6>
                                            <strong>Door Dlry Chg</strong>
                                        </h6>
                                    </div>
                                    <div *ngIf="viewHamaliDd" class="col-md-12">
                                        <h6>
                                            <strong>Hamali D.D **</strong>
                                        </h6>
                                    </div>

                                    <div class="col-md-12">
                                        <h6>
                                            <strong>Demurrage</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-12">
                                        <h6>
                                            <strong>Receipt Chg</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-12">
                                        <h6>
                                            <strong>Service Chg</strong>
                                        </h6>
                                    </div>

                                    <div *ngIf="viewOthers" class="col-md-12">
                                        <h6>
                                            <strong>Others</strong>
                                        </h6>
                                    </div>

                                    <div *ngIf="viewDiscount" class="col-md-12">
                                        <h6>
                                            <strong>Less</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-12 fontS_15">
                                        <h6>
                                            <strong>Total Rs</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-12">
                                        <h6>
                                            <strong>CGST<span
                                                    *ngIf="viewgst">&emsp;-&nbsp;{{rateDtoForPrint.cgst}}%</span></strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-12">
                                        <h6>
                                            <strong>SGST<span
                                                    *ngIf="viewgst">&emsp;-&nbsp;{{rateDtoForPrint.sgst}}%</span></strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-12">
                                        <h6>
                                            <strong>IGST<span
                                                    *ngIf="viewgst">&emsp;&nbsp;-&nbsp;{{rateDtoForPrint.igst}}%</span></strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-12" style="padding-top: 18px;">
                                        <h6 class="fontS_15">
                                            <strong>Grand Total</strong>
                                        </h6>
                                    </div>


                                </div>
                            </div>
                            <div class="col-md-3 border_right" style="text-align: right;" id="{{pageId}}left">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h6>{{rateDtoForPrint.lorryFreight}}</h6>
                                    </div>
                                    <div *ngIf="viewLocalChg" class="col-md-12">
                                        <h6>{{rateDtoForPrint.localCharge}}</h6>
                                    </div>
                                    <div *ngIf="viewHamaliGd" class="col-md-12">
                                        <h6>{{rateDtoForPrint.hamaligd}}</h6>
                                    </div>
                                    <div *ngIf="viewUnloadingChg" class="col-md-12">
                                        <h6>{{unloadingChg}}</h6>
                                    </div>
                                    <div *ngIf="viewDoorDlryChg" class="col-md-12">
                                        <h6>{{hamaliDd}}</h6>
                                    </div>
                                    <div *ngIf="viewHamaliDd" class="col-md-12"></div>

                                    <div class="col-md-12">
                                        <h6>{{rateDtoForPrint.demurrage}}</h6>
                                    </div>
                                    <div class="col-md-12">
                                        <h6>{{rateDtoForPrint.receiptCharges}}</h6>
                                    </div>
                                    <div class="col-md-12">
                                        <h6>{{rateDtoForPrint.serviceCharges}}</h6>
                                    </div>

                                    <div *ngIf="viewOthers" class="col-md-12">
                                        <h6>
                                            {{rateDtoForPrint.otherChgAmt}}
                                        </h6>
                                    </div>

                                    <div *ngIf="viewDiscount" class="col-md-12"></div>
                                    <div class="col-md-12 fontS_15">
                                        <h6>
                                            <strong>{{rateDtoForPrint.grandTotal}}</strong>
                                        </h6>
                                    </div>
                                    <hr>
                                    <div class="col-md-12"></div>
                                    <div class="col-md-12"></div>
                                    <div class="col-md-12"></div>
                                    <hr>
                                    <div class="col-md-12" style="margin-top: 10px;">
                                        <h6 class="fontS_15">
                                            <strong>{{rateDtoForPrint.grandTotal}}</strong>
                                        </h6>
                                    </div>



                                </div>
                            </div>
                            <div class="col-md-3 border_left" style="text-align: right;" id="{{pageId}}right">
                                <div class="row">
                                    <div class="col-md-12"></div>
                                    <div *ngIf="viewLocalChg" class="col-md-12"></div>
                                    <div *ngIf="viewHamaliGd" class="col-md-12"></div>
                                    <div *ngIf="viewUnloadingChg" class="col-md-12"></div>
                                    <div *ngIf="viewDoorDlryChg" class="col-md-12"></div>
                                    <div *ngIf="viewHamaliDd" class="col-md-12">
                                        <h6>{{hamaliDd}}</h6>
                                    </div>
                                    <div class="col-md-12"></div>
                                    <div class="col-md-12"></div>
                                    <div class="col-md-12"></div>

                                    <div *ngIf="viewOthers" class="col-md-12"></div>

                                    <div *ngIf="viewDiscount" class="col-md-12">
                                        <h6>{{rateDtoForPrint.discount}}</h6>
                                    </div>
                                    <div class="col-md-12"></div>
                                    <div class="col-md-12">
                                        <h6>{{rateDtoForPrint.cgstamt}}</h6>
                                    </div>
                                    <div class="col-md-12">
                                        <h6>{{rateDtoForPrint.sgstamt}}</h6>
                                    </div>
                                    <div class="col-md-12">
                                        <h6>{{rateDtoForPrint.igstamt}}</h6>
                                    </div>
                                    <div class="col-md-12" style="margin-top: 7px;">
                                        <h6 class="fontS_15">
                                            <strong>{{rateDtoForPrint.gstAmtTotal}}</strong>
                                        </h6>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="row border_top">
                            <div class="col-md-12" style="margin-left: -10px;">
                                <h6>
                                    <strong>IN Rupees : {{dummyMemo}} {{grandTotal}}
                                        {{roundAmt}}</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row border_top">
                            <div class="col-md-12" style="margin-left: -10px;">
                                <h5>
                                    <strong>FOR SRD LOGISTICS PVT.LTD.</strong>
                                </h5>
                                <h6 class="txt_ac fontS_12">
                                    <strong>{{rateDtoForPrint.enteredBy}}</strong>
                                </h6>
                                <h6 class="fontS_12" style="float: right;">
                                    <strong>THANK YOU</strong>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!--fourth row ends-->
            </div>
        </div>
    </div>

</div>
<!-- Row -->
<!--<script>
var img=document.getElementById('barcodeImg');
img.src="src/cashMemoBarcode/"+{{rateDtoForPrint.withOutSlash}}+".jpg";
</script>-->


<!-- <div id="{{pageId}}print"> -->
<!-- 	<app-cashmemo-genrate-print></app-cashmemo-genrate-print> -->
<!-- </div> -->