<html>

<head>
    <style>
        /* to fix the height of a single row in drag and drop starts */
        
        .table-striped tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, .05);
            max-height: 24px;
        }
        
        .table-striped tbody tr:nth-of-type(even) {
            max-height: 24px;
        }
        /* to fix the height of a single row in drag and drop ends */
    </style>
</head>

<body>

    <!-- Row -->
    <div class="row system_responsive">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">{{contentHeader}}</h6>
                </div>
                <div class="row">
                    <!-- column -->
                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-md-4 ">
                        <div class="card-body">

                            <!-- <h6 class="card-title">Tempo Details</h6> -->
                            <div class="row">
                                <!-- 											the first autocomplete starts -->
                                <div class="col-sm-12 col-md-12">
                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Destination</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-road"></i>
													</span>
                                                </div>
                                                <input #destination id="{{pageId}}destination" name="destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="searchDestination" (selectItem)="consigneeDestListener($event)" [resultFormatter]="formatterDestination"
                                                    [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" placeholder="Select Destination" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <!-- 											the first autocomplete ends -->
                            </div>
                            <div class="row">
                                <div class="col-md-12 p-t-10">
                                    <div class="box-body">
                                        <div class="row" style="border-bottom: 1px solid orange; padding-bottom: 4px;">

                                            <div class="col-sm-12 col-md-5">
                                                <h6 class="card-title">Repeated Consignee List</h6>
                                            </div>


                                            <div class="col-sm-12 col-md-7">
                                                <button style="padding: 1px 4px;" id="{{pageId}}confirmBtn" type="submit" class="btn btn-success m-r-10" (click)="confrimBtnMethod();">Confirm</button>
                                                <button style="padding: 1px 4px; background: blue;" type="submit" class="btn btn-success m-r-10" id="{{pageId}}moveToMergeBtn" (click)="moveToMergeMethod();">Move
													to Merge</button>
                                            </div>
                                        </div>
                                        <!-- 										<h6 style="border-bottom: 1px solid orange;" -->
                                        <!-- 											class="card-title">Repeated Consignee List</h6> -->

                                        <!-- 							<the first datatable starts  -->
                                        <div class="row p-t-10">
                                            <div class="col-sm-12 col-md-12">
                                                <table datatable id="{{pageId}}repeatedConsigneeId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsRepeatedConsigneeList" [dtTrigger]="dtTriggerRepeatedConsigneeList">

                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Repeated Consignee Name</th>
                                                            <th>Consignee GST Number</th>
                                                            <th>Is Ret Set?</th>
                                                        </tr>
                                                    </thead>
                                                    <!-- <tbody style="height: 40vh;" class='dragndrop' [dragula]='"first-bag"'> -->
                                                    <tbody>
                                                        <tr *ngFor="let repeatedConsigneeListData of repeatedConsigneeListDataList ">
                                                            <td><input type="checkbox" [checked]="multiSelect" id='selected' style="margin-left: 20px" (change)="rowCheckBoxChecked($event, repeatedConsigneeListData)" />
                                                            </td>
                                                            <td style="cursor: pointer;" (click)="rowSelectedFromRepeated(repeatedConsigneeListData);">{{ repeatedConsigneeListData.consigneeName }}</td>
                                                            <td>{{ repeatedConsigneeListData.gstNoConsignee }}</td>
                                                            <td *ngIf="repeatedConsigneeListData.isAdminRateSet== false">
                                                                {{ repeatedConsigneeListData.isAdminRateSet== true ? 'Yes' : 'No'}}</td>
                                                            <td *ngIf="repeatedConsigneeListData.isAdminRateSet== true" style='font-size: 13px; color: red; font-weight: bold;'>
                                                                {{ repeatedConsigneeListData.isAdminRateSet== true ? 'Yes' : 'No'}}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-4 col-md-4">
                                <button style="padding: 1px 4px;" id="{{pageId}}mergeAllBtn" type="submit" class="btn btn-success m-r-10" (click)="getNewConsigneeDetailsAndDoMergeMethod();">Merge All</button>
                            </div> -->
                        </div>
                    </div>
                    <!-- 							the first datatable ends  -->



                    <div class="col-md-4 vl">
                        <div class="card-body">

                            <!-- <h6 class="card-title">Tempo Details</h6> -->
                            <div class="row">
                                <!-- 							<the second datatable starts  -->
                                <div class="col-md-12 p-t-10">
                                    <div class="box-body">
                                        <div class="row" style="border-bottom: 1px solid orange; padding-bottom: 4px;">
                                            <div class="col-sm-12 col-md-6">
                                                <h6 class="card-title">Merge Consignee List</h6>
                                            </div>
                                            <div class="col-sm-12 col-md-6" align='right'>
                                                <button style="padding: 1px 4px;background: brown;" type="submit" class="btn btn-success m-r-10" id="{{pageId}}mergeBtn" (click)="mergeBtnMethod();">Merge</button>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <h6 class="card-title"></h6>
                                            </div>
                                        </div>
                                        <table datatable id="{{pageId}}mergeConsigneeId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionMergeConsigneeList" [dtTrigger]="dtTriggerMergeConsigneeList">
                                            <thead>
                                                <tr>
                                                    <th>Consignee Name</th>
                                                    <th>Gst Number</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <!-- <tbody style="height: 40vh;" class='dragndrop' [dragula]='"first-bag"'> -->
                                            <tbody>
                                                <tr *ngFor="let mergeConsigneeListData of mergeConsigneeListDataList ">
                                                    <td>{{ mergeConsigneeListData.consigneeName }}</td>
                                                    <td>{{ mergeConsigneeListData.gstNoConsignee }}</td>
                                                    <td>
                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteForMerge(mergeConsigneeListData,i);">
															<i title="Remove" class="fas fa-trash"></i>
														</button>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                                <!--							 the second datatable ends  -->
                            </div>

                            <div class="row p-t-10">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Consignee Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-user"></i>
												</span>
                                            </div>
                                            <input #consigneeNameConfirmed type="text" id="{{pageId}}consigneeNameConfirmed" name="consigneeNameConfirmed" [(ngModel)]="consigneeName" class="form-control" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <!-- 						<div class="col-md-12" style="text-align: center;"> -->
                        <!-- 							<button type="submit" class="btn btn-success m-r-10" -->
                        <!-- 								id="{{pageId}}confirmBtn">Confirm</button> -->
                        <!-- 							<button type="submit" class="btn btn-dark" id="{{pageId}}mergeBtn">Merge</button> -->
                        <!-- 						</div> -->
                    </div>
                    <!-- 							the third datatable starts  -->
                    <div class="col-md-4 vl">
                        <div class="card-body">
                            <div class="row">
                                <!-- 											the second autocomplete starts -->
                                <div class="col-sm-12 col-md-12">
                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Consignee Index</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
													</span>
                                                </div>
                                                <input #consigneeIndex id="{{pageId}}consigneeIndex" name="consigneeIndex" type="text" class="form-control" placeholder="Select Consignee Index" (selectItem)="rowSelectedConsigneeIndex($event)" [ngbTypeahead]="consigneeIndexSearchTA" (focus)="focusConsigneeIndexTA$.next($any($event).target.value)"
                                                    (click)="clickTA(instanceConsigneeIndex)" #instanceConsigneeIndex="ngbTypeahead" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- 											the second autocomplete ends -->
                            </div>
                            <div class="row">

                                <div class="col-sm-12 col-md-12">
                                    <div class="box-body">
                                        <div class="row" style="border-bottom: 1px solid orange;">

                                            <div class="col-sm-12 col-md-8">
                                                <h6 class="card-title">Confirmed Consignee List</h6>
                                            </div>


                                            <div class="col-sm-12 col-md-4" align='right'>
                                                <button style="padding: 1px 4px;" type="submit" (click)="undoBtnMethod();" class="btn btn-dark m-r-10" id="{{pageId}}undoBtn">Undo</button>
                                            </div>
                                        </div>
                                        <div class="row p-t-10">
                                            <div class="col-sm-12 col-md-12">
                                                <table datatable id="{{pageId}}confirmedConsigneeId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionConfirmedConsigneeList" [dtTrigger]="dtTriggerConfirmedConsigneeList">
                                                    <thead>
                                                        <tr>
                                                            <th>Confirmed Consignee Name</th>
                                                            <th>Consignee GST Number</th>
                                                            <th>Is Ret Set?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr style="cursor: pointer;" *ngFor="let confirmedConsigneeListData of confirmedConsigneeListDataList; let i = index" (click)="rowSelectedForUndo(confirmedConsigneeListData);">
                                                            <td>{{ confirmedConsigneeListData.consigneeName }}</td>
                                                            <td>{{ confirmedConsigneeListData.gstNoConsignee }}</td>
                                                            <td *ngIf="confirmedConsigneeListData.isAdminRateSet== false">
                                                                {{ confirmedConsigneeListData.isAdminRateSet== true ? 'Yes' : 'No'}}</td>
                                                            <td *ngIf="confirmedConsigneeListData.isAdminRateSet== true" style='font-size: 13px; color: red; font-weight: bold;'>
                                                                {{ confirmedConsigneeListData.isAdminRateSet== true ? 'Yes' : 'No'}}</td>
                                                        </tr>

                                                        <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

                                                    </tbody>

                                                </table>
                                            </div>
                                            <!-- 							the third datatable ends  -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="input-group" id="{{pageId}}popupDetailsTwo">
                            <ng-template #content let-yes="close" let-no="close" let-ok="close" let-d="dismiss">
                                <div class="modal-body">
                                    <div class="row" style="text-align: center;">
                                        <div class="col-md-12">
                                            <!-- 										<h5 class="m-b-10">Sure you want to UnConfirm the -->
                                            <!-- 											selected Consignees?</h5> -->
                                            <h5 class="m-b-10">
                                                <i class="fas fa-exclamation-triangle"></i> Please select consignee name to Undo
                                            </h5>
                                        </div>
                                        <div class="col-md-12 p-t-10">
                                            <button type="button" class="btn btn-outline-secondary" (click)="ok('Yes click')">Ok</button>
                                            <!-- 										<button type="button" class="btn btn-outline-secondary" -->
                                            <!-- 											(click)="yes('Yes click')">Yes</button> -->
                                            <!-- 										<button type="button" class="btn btn-outline-danger" -->
                                            <!-- 											(click)="no('No click')">No</button> -->
                                        </div>
                                    </div>
                                </div>
                            </ng-template>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</body>

</html>