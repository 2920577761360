//CHG-V-CNM:01 - @Imran chg, added a button to get data of destination blocked consignee in export added 2 columns on 30/04/2024
//CHG-V-CNM:02 - @Imran chg, Blocked CFT Checkbox for all users except mussadik bhai On 03/05/2024 By Imran
//CHG-V-CNM:03 - @Asrar Jr chg, Consignee & Consignor Master search by GST (on 07/06/2024)
//CHG-V-CNM:04 - @Asrar Jr chg, Given Rights for Monitor Performance (on 05/09/2024)
//CHG-V-CNM:05 - @Asrar Jr chg, Given Rights for Save & Finish button (on 05/09/2024)
//CHG-V-CNM:06 - @Asrar Jr chg, Given Rights for only Update (on 16/09/2024)
//CHG-V-CNM:07 - @Asrar Jr chg, Add subArea Field (on 05/11/2024)


import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbModalRef, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, from, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { MasterDto } from 'src/app/dto/master-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { PartyModel } from "src/app/models/party-model";
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import swal from 'sweetalert';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { MatDialog } from '@angular/material/dialog';
import { ConsigneeMultipleMobileNumberDetailsComponent } from 'src/app/master/consignee/consignee-multiple-mobilenumber-details/consignee-multiple-mobilenumber-details.component';
import *  as moment from 'moment';
import { ConsigneeMobileNumberUpdateDatatableComponent } from 'src/app/master/consignee/consignee-mobilenumber-update-datatable/consignee-mobilenumber-update-datatable.component';
import { ConsigneeActiveNonActiveDetailsComponent } from 'src/app/master/consignee/consignee-active-nonactive-details/consignee-active-nonactive-details.component';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { FormControl } from '@angular/forms';
import { CflFormulaSetupSpecificComponent } from '../../fixing/cfl-formula-setup-specific/cfl-formula-setup-specific.component';

@Component({
	selector: 'app-consignee-master',
	templateUrl: './consignee-master.component.html',
	styleUrls: ['./consignee-master.component.css']
})

export class ConsigneeMasterComponent implements OnInit {
	masterDto: MasterDto = new MasterDto();
	partyModel: PartyModel = new PartyModel();
	company: string;
	viewArea = false;
	newArea = false;
	newCollectionMan = false;
	model: NgbDateStruct;
	date: { year: number; month: number };
	showSpinnerForAction = false;
	showSpinnerForAction1 = false;
	showSpinnerForAction2 = false;
	gettingDataFrmServiceFrCommodityTable: any;
	gettingDataFrmServiceFrConsigneeCommodityTable: any;
	gettingDataFrmServiceFrConsigneeStatusDetailsTable: any;
	//    gettingDataFrmServiceFrConsigneeDetailsTable: any;
	gettingDataFrmServiceFrMobileNumberPopUpTable: any;

	commodityDataList: any;
	consigneeCommodityDataList: any;
	consigneeStatusDetailsDataList: any;
	//consigneeDetailsDataList: ConsigneedetailsModel;
	consigneeDetailsDataList: any;
	mobileNumberPopUpDataList: any;

	onDestroyUnsubscribtionCommodity: Subscription;
	onDestroyUnsubscribtionConsigneeCommodity: Subscription;
	onDestroyUnsubscribtionConsigneeStatusDetails: Subscription;
	//    onDestroyUnsubscribtionConsigneeDetails: Subscription;
	onDestroyUnsubscribtionMobileNumberPopUp: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

	dtTriggerCommodity: Subject<any> = new Subject();
	dtTriggerConsigneeCommodity: Subject<any> = new Subject();
	dtTriggerConsigneeStatusDetails: Subject<any> = new Subject();
	dtTriggerConsigneeDetails: Subject<any> = new Subject();
	dtTriggerMobileNumberPopUp: Subject<any> = new Subject();

	dataTable: any;

	dtOptionsCommodity: any;
	dtOptionsConsigneeCommodity: any;
	dtOptionsConsigneeStatusDetails: any;
	dtOptionsConsigneeDetails: any;
	dtOptionsMobileNumberPopUp: any;
	// for datatable ends
	pageId = "conmc";

	isLoggedIn = true;
	userDataDtoReturnSession: any;

	//drop down 
	partyMasterDtoOptions: PartyMasterDto[];
	public modelStateNameAndCode: any;
	modelStateNameAndCodeTA: Array<PartyMasterDto> = [];
	focusStateNameAndCodeTA$ = new Subject<string>();
	searchStateNameAndCode = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusStateNameAndCodeTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.modelStateNameAndCodeTA
				: this.modelStateNameAndCodeTA.filter(v => v.state.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterStateNameAndCode = (x: { state: string }) => x.state;


	userDtoCollectionMan: UserDataDto = new UserDataDto();
	userDtoCollectionManAddNew: UserDataDto = new UserDataDto();
	userDataDtoOptions: UserDataDto[];
	public modelCollectionMan: any;
	modelCollectionManTA: Array<UserDataDto> = [];
	focusCollectionManTA$ = new Subject<string>();
	searchCollectionMan = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCollectionManTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.modelCollectionManTA
				: this.modelCollectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;

	lrDtoForStation: LRDto = new LRDto();

	lrDtoOptions: LRDto[];
	public modelDestinationCity: any;
	modelDestinationCityTA: Array<LRDto> = [];
	focusDestinationCityTA$ = new Subject<string>();
	searchDestinationCity = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationCityTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.modelDestinationCityTA
				: this.modelDestinationCityTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestinationCity = (x: { destination: string }) => x.destination;


	rateMasterDto: RateMasterDto = new RateMasterDto();

	rateMasterDtoOptions: RateMasterDto[];
	rateMasterDtoCommodityUnldAddNew: RateMasterDto = new RateMasterDto();
	public modelCommodityMainUnldg: any;
	modelCommodityMainUnldgTA: Array<RateMasterDto> = [];
	focusCommodityMainUnldgTA$ = new Subject<string>();
	searchCommodityMainUnldg = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCommodityMainUnldgTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.modelCommodityMainUnldgTA
				: this.modelCommodityMainUnldgTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCommodityMainUnldg = (x: { commodityName: string }) => x.commodityName;

	rateMasterDtoCommodityMainOptions: RateMasterDto[];
	public modelCommodityMain: any;
	modelCommodityMainTA: Array<RateMasterDto> = [];
	//modelCommodityMainTA= new Array<RateMasterDto>();
	focusCommodityMainTA$ = new Subject<string>();
	searchCommodityMain = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCommodityMainTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.modelCommodityMainTA
				: this.modelCommodityMainTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCommodityMain = (x: { commodityName: string }) => x.commodityName;

	lrDtoSourceOptions: LRDto[];
	lrDtoSourceAddNew: LRDto = new LRDto();
	lrDtoSourc: LRDto = new LRDto();
	public modelSource: any;
	modelSourceTA: Array<LRDto> = [];
	focusSourceTA$ = new Subject<string>();
	searchSource = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusSourceTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.modelSourceTA
				: this.modelSourceTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterSource = (x: { source: string }) => x.source;

	lrDtoAreaOptions: LRDto[];
	lrDtoAreaAddNew: LRDto = new LRDto();
	lrDtoArea: LRDto = new LRDto();
	public modelArea: any;
	modelAreaTA: Array<LRDto> = [];
	focusAreaTA$ = new Subject<string>();
	searchArea = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusAreaTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.modelAreaTA
				: this.modelAreaTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterArea = (x: { destination: string }) => x.destination;

	/* ENd Drop Down*/

	destView: boolean = false; // not to be this  clear

	rgBlock: boolean = false; // not this its session
	flexTableStorageRpt: boolean = false; // not this its session
	isEditSettings: boolean = false; // not this its session

	////CHG-V-CNM:05
	// viewSaveBtn: boolean = true; // need to false at clear
	viewSaveBtn: boolean = false;

	viewUpdateBtn: boolean = false; // need to false at clear

	viewPartyAreaField: boolean = true; // not this its session

	/* On Load Datatable Work Start*/
	partyMasterDtoForGridRecofig: PartyMasterDto = new PartyMasterDto();

	/* On Load Datatable Work End*/

	/* Consignee Status Details Datatable Work Start */
	lrDtoForConsigneeStatus: LRDto = new LRDto();
	/* Consignee Status Details Datatable Work End */

	/* Consignee Details Datatable Edit Work START */
	partyMasterDtoForGridRecofigEdit: PartyMasterDto = new PartyMasterDto();
	private newAttributeSetConsigneeCommoditySet: any = {};
	editConsigneeCommodityTableId: any;
	private newAttributeSetUnldgCommoditySet: any = {};
	editUnldgCommodityTableId: any;
	viewAddMobileNo = 'Add New';
	consigneeMobileNoList: any;
	returnListOfMobileNumber: any;
	/* Consignee Details Datatable Edit Work End */

	/* Consignee Details Save Operation Start*/

	partyMasterDto: PartyMasterDto = new PartyMasterDto();
	partyMasterDtoForSave: PartyMasterDto = new PartyMasterDto();

	validateSelectedCityDestForSave: any;
	validateSelectedConsigneeNameForSave: any;
	validateSelectedContactPersonForSave: any;
	validateSelectedMobileNumberForSave: any;
	validateSelectedStateNameForSave: any;
	validateSelectedGstNoForSave: any;
	validateSelectedOfficeNoForSave: any;
	validateSelectedEDeclPwdForSave: any;
	validateSelectedEmailidForSave: any;
	validateSelectedAddressForSave: any;
	validateSelectedPinCodeForSave: any;
	validateSelectedConsigneeIdForSave: any;
	validateSelectedStateCodeForSave: any;

	validateSelectedDeliveryTypeForSave: any;
	validateSelectedCollectionManForSave: any;
	validateSelectedPartyAreaForSave: any;
	validateSelectedAreaForSave: any;

	validateSelectedModeForSave: any;
	validateSelectedDesBlockForSave: any;
	validateSelectedSendRMDSMSForSave: any;
	validateSelectedSendStockSMSForSave: any;
	validateSelectedPopUpAlertForSave: any;
	validateSelectedSRCBlockForSave: any;
	enterdedMemoTimePeriod
	validateSelectedBlockMessagetemplateForSave: any;
	validateSelectedIsAlertPopuptemplateForSave: any;
	validateSelectedIsSrcBlocktemplateForSave: any;
	validateSelectedDemurageExmpForSave: any;

	/* Consignee Details Save Operation End*/

	validateSelectedDestination: any;
	validateSelectedConsigneeId: any;
	validateSelectedStateCode: any;
	validateSelectedPaymentModeForSave: any;
	disableCftForSave: any;
	validateSelectedLeftToPartyForSave: any;

	/*validateSelectedBlockMessage: any;
	validateSelectedSrcBlockMessage: any;
	validateSelectedAlertPopMessage: any;*/

	modalRefferenceCollectionManPemplatePopUp: NgbModalRef;
	modalRefferenceCollectionManClosePopUp: string;
	@ViewChild('collectionManPopUpTemplate') private collectionManPopUpTemplate;

	modalRefferenceDeliveryAreaPemplatePopUp: NgbModalRef;
	modalRefferenceDeliveryAreaClosePopUp: string;
	@ViewChild('newAreaPopUpTemplate') private newAreaPopUpTemplate;

	modalRefferenceMessageSrcBlockTemplatePopUp: NgbModalRef;
	modalRefferenceMessageSrcBlockTemplateClosePopUp: string;
	@ViewChild('srcBlockMessagePopUpTemplate') private srcBlockMessagePopUpTemplate;


	partyMasterDtoForNewCollectionman: PartyMasterDto = new PartyMasterDto();
	validateNewCollectionMan: any;

	lrDtoForNewArea: LRDto = new LRDto();
	validateNewArea: any;

	viewBlockMessageRemarks: boolean = false;
	viewisPopUpAlertMessageRemarks: boolean = false;
	viewSrcBlockMessageRemarks: boolean = false;
	blockMessageRemarksId: any;
	srcBlockMessageRemarksId: any;
	popUpAlertMessageRemarks: any;

	/* print customize*/
	viewCustomPrintV1 = false;
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;


	fromDatePrint: any;
	toDatePrint: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	//Custom Print Footer
	summary1: number;
	summary2: number;
	summary3: number;
	summary4: number;
	summary5: number;
	summary6: number;
	/* print customize*/

	controlConsigneeIndex = new FormControl();

	consigneeIndexOptions = [
		{ id: 1, label: 'A' },
		{ id: 2, label: 'B' },
		{ id: 3, label: 'C' },
		{ id: 4, label: 'D' },
		{ id: 5, label: 'E' },
		{ id: 6, label: 'F' },
		{ id: 7, label: 'G' },
		{ id: 8, label: 'H' },
		{ id: 9, label: 'I' },
		{ id: 10, label: 'J' },
		{ id: 11, label: 'K' },
		{ id: 12, label: 'L' },
		{ id: 13, label: 'M' },
		{ id: 14, label: 'N' },
		{ id: 15, label: 'O' },
		{ id: 16, label: 'P' },
		{ id: 17, label: 'Q' },
		{ id: 18, label: 'R' },
		{ id: 19, label: 'S' },
		{ id: 20, label: 'T' },
		{ id: 21, label: 'U' },
		{ id: 22, label: 'V' },
		{ id: 23, label: 'W' },
		{ id: 24, label: 'X' },
		{ id: 25, label: 'Y' },
		{ id: 26, label: 'Z' },
		{ id: 27, label: 'ALL' }
	];

	//For consignee Index
	public modelConsigneeIndex: any;
	@ViewChild('instanceConsigneeIndex') instanceConsigneeIndex: NgbTypeahead;
	consigneeIndexTA: Array<any> = [];
	focusConsigneeIndexTA$ = new Subject<string>();
	consigneeIndexSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeIndexTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeIndexTA
				: this.consigneeIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	///
	selectedConsigneeIndex: any;
	viewLeftPartyOption: boolean = false;
	blockCFTCheckBox: boolean = false;
	validateSearchByGst: any;
	isMonitorDelPerformance: boolean = false;
	viewMonitorPerfToggle: boolean = false;
	rightsForToggle: boolean = false;
	rightsViewMonitorPerfToggle: boolean = false;
	rightsForViewSaveBtn: boolean = false;
	rightsForOnlyUpdateBtn: boolean = false;
	validateSubAreaForSave: any;

	constructor(private masterService: MasterService, private router: Router,
		private masterreadService: MasterReadService, private modalService: NgbModal,
		private consigneeService: ConsigneeService, public dialog: MatDialog,
		public changeDetectorRef: ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			//          sweet alert starts
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
			//            sweet alert ends
		}

		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
					//CHG-V-CNM:02-Else if added
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "BlockNotShow CongMaster") {
						this.rgBlock = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "ChkBoxShow CongMaster") {
						this.flexTableStorageRpt = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "EditSettings CongMaster") {
						this.isEditSettings = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LeftToPartEnableOption CgneeMaster") {
						this.viewLeftPartyOption = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "ConsigneeMaster BlockCFTCheckbox") {
						this.blockCFTCheckBox = true;
					}

					//CHG-V-CNM:04
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "ConsigneeMaster PerformanceMonitor") {
						this.rightsViewMonitorPerfToggle = true;
					}
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "ConsigneeMaster AddUpdt") {
						this.viewSaveBtn = true;
						this.rightsForViewSaveBtn = true;
					}
					//CHG-V-CNM:06
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "ConsigneeMasterUpdate") {
						this.rightsForOnlyUpdateBtn = true;
					}
					console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights[i]);
				}
			}
			this.setConsigneeIndex();
			this.getStatesMethod();
			this.getCollectionManDetailsList();
			this.getStationDtails();
			this.getCommodityMasterDetailsList();
			this.getUnladgSourceList();
			this.getBranchAreaDetails();

			this.destView = true;

			if (this.destView) {
				//CHG-V-CNM:01
				this.gridReconfigure("A", false, null);
			} else {
				//CHG-V-CNM:01
				this.gridReconfigure("A", false, null);
			}
			if (this.isEditSettings) {
				this.enableSettingsEdit();
			} else {
				this.disableSettingsEdit();
			}

			if (this.userDataDtoReturnSession.mainStation != null &&
				this.userDataDtoReturnSession.mainStation == "") {
				this.viewPartyAreaField = true;
			} else {
				this.viewPartyAreaField = false;
			}

		}

	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	setConsigneeIndex() {
		for (let i = 0; i < this.consigneeIndexOptions.length; i++) {
			this.consigneeIndexTA.push(this.consigneeIndexOptions[i].label);
		}
	}

	// for datatable starts
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {

		/* the first datatable starts  */
		this.dtOptionsCommodity = {
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 90,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			searching: false,
		},
			/* the first datatable ends */

			/* the second datatable starts */
			this.dtOptionsConsigneeCommodity = {
				dom: 'Bfrtip',
				buttons: [],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 90,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				searching: false,
			},
			/* the second datatable ends */

			/* the third datatable starts */
			this.dtOptionsConsigneeStatusDetails = {
				dom: 'Bfrtip',
				buttons: [],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 380,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				searching: false,
			},
			/* the third datatable ends */

			/* the fourth datatable starts */
			this.dtOptionsConsigneeDetails = {
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							return "Consignee Master Details - " +
								"Station : " + $("#conmcdestinationCityId").val() + " -  " +
								"Date : " + moment(new Date()).format('DD-MM-YYYY') + "";
						},
						exportOptions: {
							columns: [0, 1, 5, 4, 6, 7, 8, 9, 10, 11]
						}
					}
					/*,
					{
						extend: 'print',
						text: '<i class="fas fa-print"> Print</i>',
						titleAttr: 'Print',
						footer: true,
						title: function () {
							return "Consignee Master Details - " +
								"Station : " + $("#conmcdestinationCityId").val() + " -  " +
								"Date : " + moment(new Date()).format('DD-MM-YYYY') + "";
						},
						exportOptions: {
							columns: [0, 2, 5, 4, 6, 7, 8, 9]
						}
					}*/
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 400,
				"scrollCollapse": true,
				paging: false,
				info: true,
				// deferRender: true,
				// serverSide: true,
				bSortClasses: false,
				deferRender: true,
				scroller: true
			},
			/* the fourth datatable ends */
			/* the fifth datatable starts  */
			this.dtOptionsMobileNumberPopUp = {
				dom: 'Bfrtip',
				buttons: [],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 200,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				searching: false,
			}
	}


	ngOnDestroy(): void {
		// for datatable starts
		this.dtTriggerCommodity.unsubscribe();
		this.dtTriggerConsigneeCommodity.unsubscribe();
		this.dtTriggerConsigneeStatusDetails.unsubscribe();
		this.dtTriggerConsigneeDetails.unsubscribe();
		this.dtTriggerMobileNumberPopUp.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerCommodity.next();
		this.dtTriggerConsigneeCommodity.next();
		this.dtTriggerConsigneeStatusDetails.next();
		this.dtTriggerConsigneeDetails.next();
		this.dtTriggerMobileNumberPopUp.next();
	}




	/* for the select option with filter starts */

	transform(items: any[], searchTerm: string, labelKey?: string): any {
		if (!items || !searchTerm) {
			return items;
		}

		return items.filter(
			item =>
				item[labelKey || 'label']
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) === true
		);
	}

	/* for the select option with filter ends */

	/* for popup modal starts */
	openPopUpModalCollectionMan(collectionManPopUpTemplate) {
		this.modalRefferenceCollectionManPemplatePopUp = this.modalService.open(collectionManPopUpTemplate,
			{ centered: true, size: "sm" });
		this.modalRefferenceCollectionManPemplatePopUp.result.then((result) => {
			this.modalRefferenceCollectionManClosePopUp = `Closed with: ${result}`;
		}, reason => {
			this.modalRefferenceCollectionManClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	openPopUpModalDeliveryNewArea(newAreaPopUpTemplate) {
		this.modalRefferenceDeliveryAreaPemplatePopUp = this.modalService.open(newAreaPopUpTemplate,
			{ centered: true, size: "sm" });
		this.modalRefferenceDeliveryAreaPemplatePopUp.result.then((result) => {
			this.modalRefferenceDeliveryAreaClosePopUp = `Closed with: ${result}`;
		}, reason => {
			this.modalRefferenceDeliveryAreaClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
	/* for popup modal ends */


	/* focus drop down start */

	focusRegStateTA(e) {
		if (e.keyCode == 13) { $("#" + this.pageId + "gstNumber").focus(); }
	}
	focusCollectionManTA(e) {
		if (e.keyCode == 13) { $("#" + this.pageId + "partyArea").focus(); }
	}
	focusDestinationCityTA(e) {
		if (e.keyCode == 13) { $("#" + this.pageId + "firstName").focus(); }
	}
	focusCommodityMainUnldgTA(e) {
		if (e.keyCode == 13) { $("#" + this.pageId + "source").focus(); }
	}
	focusCommodityMainTA(e) {
		if (e.keyCode == 13) { console.log(e); }
	}

	/* focus drop down end */

	/* on change listner of drop down static start */

	delivTypeMethod(deliveryType: string) {
		if (deliveryType === 'doorDelivery') {
			if (this.destView) {
				this.getBranchAreaDetails();
			}
			this.viewArea = true;
		} else if (deliveryType === 'godown') {
			this.viewArea = false;
		} else {
			this.viewArea = false;
		}
	}

	areaMethod(areaSelect: string) {
		if (areaSelect === 'addNew') {
			this.newArea = true;
		} else {
			this.newArea = false;
		}
	}

	public collectionManMethod(event) {
		console.log("HAI");
		if (event === 'Add New') {
			this.newCollectionMan = true;
		} else {
			this.newCollectionMan = false;
		}
	}

	/* on change listner of drop down static end */


	/* on click listner of drop down dynamic start */

	clickListnerForStateNameAndCode(e: NgbTypeaheadSelectItemEvent, fubi: any) {
		this.modelStateNameAndCode = e.item;
		$("#" + this.pageId + "stateNameAndCodeId").val(this.modelStateNameAndCode.state);
	}

	clickListnerForCollectionMan(e: NgbTypeaheadSelectItemEvent, collectionManPopUpTemplate) {
		this.modelCollectionMan = e.item;
		$("#" + this.pageId + "collectionManId").val(this.modelCollectionMan.collectioMan);
		if ($("#" + this.pageId + "collectionManId").val() == 'Add New') {
			this.openPopUpModalCollectionMan(collectionManPopUpTemplate);
		} else {

		}
	}

	clickListnerForDestinationCity(e: NgbTypeaheadSelectItemEvent, fubi: any) {
		this.modelDestinationCity = e.item;
		$("#" + this.pageId + "destinationCityId").val(this.modelDestinationCity.destination);
		if ($("#" + this.pageId + "destinationCityId").val() != null && $("#" + this.pageId + "destinationCityId").val() != undefined &&
			$("#" + this.pageId + "destinationCityId").val() != "") {
			this.getCommodityMasterDetailsList();
			this.getCollectionManDetailsList();
			//CHG-V-CNM:01
			this.gridReconfigure("A", false, null);
		}
	}
	clickListnerForCommodityMainUnldg(e: NgbTypeaheadSelectItemEvent, fubi: any) {
		this.modelCommodityMainUnldg = e.item;
		$("#" + this.pageId + "CommodityMainUnldgId").val(this.modelCommodityMainUnldg.commodityName);
	}
	clickListnerForCommodityMain(e: NgbTypeaheadSelectItemEvent, fubi: any) {
		this.modelCommodityMain = e.item;
		//console.log(this.modelCommodityMain);
		$("#" + this.pageId + "CommodityMainId").val(this.modelCommodityMain.commodityName);
	}

	clickListnerForSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
		this.modelSource = e.item;
		//console.log(this.modelSource);
		$("#" + this.pageId + "source").val(this.modelSource.source);
	}
	clickListnerForArea(e: NgbTypeaheadSelectItemEvent, newAreaPopUpTemplate) {
		this.modelArea = e.item;
		//console.log(this.modelSource);
		$("#" + this.pageId + "area").val(this.modelArea.destination);

		if ($("#" + this.pageId + "area").val() == 'Add New') {
			this.openPopUpModalDeliveryNewArea(newAreaPopUpTemplate);
		} else {

		}
	}

	/* on click listner of drop down dynamic end */

	/* on click listner of drop down dynamic service start  */

	getStatesMethod() {
		this.partyMasterDto = new PartyMasterDto();
		this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
		this.masterreadService.getStateDetailsService(this.partyMasterDto).subscribe(
			(response) => {
				// console.log(response);
				if (response.length == 0) {
					this.partyMasterDtoOptions = [];
					this.modelStateNameAndCodeTA = [];
				} else {
					this.partyMasterDtoOptions = response;
					this.modelStateNameAndCodeTA = [];
					for (let i = 0; i < this.partyMasterDtoOptions.length; i++) {
						this.modelStateNameAndCodeTA.push(this.partyMasterDtoOptions[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting getStatesMethod", "warning");
			},
			() => console.log('done');
	}

	getDetailsForCollectionMasterRead() {
		this.userDtoCollectionMan = new UserDataDto();
		this.validateSelectedDestination = $("#" + this.pageId + "destinationCityId").val();
		if (this.validateSelectedDestination == null || this.validateSelectedDestination == undefined ||
			this.validateSelectedDestination == "") {
			this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
		} else {
			this.userDtoCollectionMan.branchId = this.validateSelectedDestination;
		}

		this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
		this.userDtoCollectionMan.status = "D";
	}

	getCollectionManDetailsList() {
		this.getDetailsForCollectionMasterRead();
		this.masterreadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
			(response) => {
				if (response.length == 0) {
					this.userDataDtoOptions = [];
					this.modelCollectionManTA = [];
				} else {
					this.userDataDtoOptions = response;
					this.modelCollectionManTA = [];
					this.userDtoCollectionManAddNew.collectioMan = "Add New";
					this.modelCollectionManTA.push(this.userDtoCollectionManAddNew);
					for (let i = 0; i < this.userDataDtoOptions.length; i++) {
						this.modelCollectionManTA.push(this.userDataDtoOptions[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Problem occur while getting Collection Man Details", "warning");
			},
			() => console.log('done');
	};

	getStationInfo() {
		this.lrDtoForStation = new LRDto();
		this.lrDtoForStation.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoForStation.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForStation.mode = "LrForm_ConsigneeMaster";

		if (this.userDataDtoReturnSession.officeType == "Delivery Office" &&
			this.userDataDtoReturnSession.role != "Super Administrator") {
			this.lrDtoForStation.reportMode = "specific";
			this.lrDtoForStation.destination = this.userDataDtoReturnSession.mainStation;
		}
	}


	getStationDtails = () => {
		this.getStationInfo();
		this.masterreadService.getDestinationForLREntryService(this.lrDtoForStation).subscribe(
			(response) => {
				if (response.length == 0) {
					this.lrDtoOptions = [];
					this.modelDestinationCityTA = [];
				} else {
					this.lrDtoOptions = response;
					this.modelDestinationCityTA = [];
					for (let i = 0; i < this.lrDtoOptions.length; i++) {
						this.modelDestinationCityTA.push(this.lrDtoOptions[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Station Details", "warning");
			},
			() => console.log('done');
	};

	getCommodityListRead() {
		this.rateMasterDto = new RateMasterDto();
		this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
		this.validateSelectedDestination = $("#" + this.pageId + "destinationCityId").val();
		if (this.validateSelectedDestination == null || this.validateSelectedDestination == undefined ||
			this.validateSelectedDestination == "") {
			console.log(this.userDataDtoReturnSession.mainStation);
			this.rateMasterDto.destination = this.userDataDtoReturnSession.mainStation;
		} else {
			this.rateMasterDto.destination = this.validateSelectedDestination;
		}
		this.rateMasterDto.mode = "mainConsigneeMaster";
		this.rateMasterDto.status = "Working";
	}

	getCommodityMasterDetailsList = () => {
		this.getCommodityListRead();
		this.masterreadService.getCommodityDetails(this.rateMasterDto).subscribe(
			(response) => {
				console.log(response);
				if (response.length == 0) {
					this.rateMasterDtoOptions = [];
					this.modelCommodityMainUnldgTA = [];

					this.rateMasterDtoCommodityMainOptions = [];
					this.modelCommodityMainTA = [];
				} else {
					this.rateMasterDtoOptions = response;
					this.modelCommodityMainUnldgTA = [];

					this.rateMasterDtoCommodityMainOptions = response;
					this.modelCommodityMainTA = [];

					this.rateMasterDtoCommodityUnldAddNew.commodityName = 'All';
					this.modelCommodityMainUnldgTA.push(this.rateMasterDtoCommodityUnldAddNew);
					for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
						this.modelCommodityMainUnldgTA.push(this.rateMasterDtoOptions[i]);
					}


					for (let i = 0; i < this.rateMasterDtoCommodityMainOptions.length; i++) {
						this.modelCommodityMainTA.push(this.rateMasterDtoCommodityMainOptions[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Commodity Master Details", "warning");
			},
			() => console.log('done');
	};

	getUnladgSourceList() {
		this.lrDtoSourc = new LRDto();
		this.lrDtoSourc.companyId = this.userDataDtoReturnSession.companyId;
		this.masterreadService.getBookingMainStationsSource(this.lrDtoSourc).subscribe(
			(response) => {
				if (response.length == 0) {
					this.lrDtoSourceOptions = [];
					this.modelSourceTA = [];

					this.lrDtoSourceAddNew = new LRDto();
					this.lrDtoSourceAddNew.source = "All";
					this.modelSourceTA.push(this.lrDtoSourceAddNew);
				} else {
					this.lrDtoSourceOptions = [];
					this.modelSourceTA = [];

					this.lrDtoSourceAddNew = new LRDto();
					this.lrDtoSourceAddNew.source = "All";
					this.modelSourceTA.push(this.lrDtoSourceAddNew);

					this.lrDtoSourceOptions = response;

					for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
						this.modelSourceTA.push(this.lrDtoSourceOptions[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Booking Main Stations Source", "warning");
			},
			() => console.log('done');
	}

	getBranchAreaDetails() {
		this.lrDtoArea = new LRDto();
		this.lrDtoArea.branch = this.userDataDtoReturnSession.office;
		this.lrDtoArea.mode = "ALL";
		this.validateSelectedDestination = $("#" + this.pageId + "destinationCityId").val();
		console.log('this.validateSelectedDestination');
		console.log(this.validateSelectedDestination);
		if (this.validateSelectedDestination == null || this.validateSelectedDestination == undefined ||
			this.validateSelectedDestination == "") {
			console.log(this.userDataDtoReturnSession.mainStation);
			this.lrDtoArea.mainstation = this.userDataDtoReturnSession.mainStation;
			this.lrDtoArea.destination = this.userDataDtoReturnSession.mainStation;
		} else {
			this.lrDtoArea.mainstation = this.validateSelectedDestination;
			this.lrDtoArea.destination = this.validateSelectedDestination;
		}

		this.lrDtoArea.companyId = this.userDataDtoReturnSession.companyId;
		this.masterreadService.getBranchAreas(this.lrDtoArea).subscribe(
			(response) => {
				console.log(response);
				if (response.length == 0) {
					this.lrDtoAreaOptions = [];
					this.modelAreaTA = [];

					this.lrDtoAreaAddNew = new LRDto();
					this.lrDtoAreaAddNew.destination = "Add New";
					this.modelAreaTA.push(this.lrDtoAreaAddNew);
				} else {
					this.lrDtoAreaOptions = [];
					this.modelAreaTA = [];

					this.lrDtoAreaAddNew = new LRDto();
					this.lrDtoAreaAddNew.destination = "Add New";
					this.modelAreaTA.push(this.lrDtoAreaAddNew);

					this.lrDtoAreaOptions = response;

					for (let i = 0; i < this.lrDtoAreaOptions.length; i++) {
						this.modelAreaTA.push(this.lrDtoAreaOptions[i]);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Branch Areas", "warning");
			},
			() => console.log('done');
	}

	/* on click listner of drop down dynamic service end  */

	/* On Load Datatable Work Start*/
	//CHG-V-CNM:01
	gridReconfigure(index, destBlocked, mode) {
		this.partyMasterDtoForGridRecofig = new PartyMasterDto();
		//		this.partyMasterDtoForGridRecofig.mode = "specific";
		//CHG-V-CNM:01//Before The mode is index
		if (destBlocked) {
			this.partyMasterDtoForGridRecofig.mode = "blockedconsignee";
		} else {
			this.partyMasterDtoForGridRecofig.mode = "index";
		}

		this.validateSelectedDestination = $("#" + this.pageId + "destinationCityId").val();
		if (this.validateSelectedDestination == null || this.validateSelectedDestination == undefined ||
			this.validateSelectedDestination == "") {
			this.partyMasterDtoForGridRecofig.office = this.userDataDtoReturnSession.mainStation;
		} else {
			this.partyMasterDtoForGridRecofig.office = this.validateSelectedDestination;
		}

		this.partyMasterDtoForGridRecofig.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDtoForGridRecofig.indexValue = index;

		//CHG-V-CNM:03
		if (mode != null && mode == "gstSearch") {
			this.partyMasterDtoForGridRecofig.gstNoConsignee = this.validateSearchByGst;
			this.partyMasterDtoForGridRecofig.mode = mode;
		}

		this.showSpinnerForAction = true;

		console.log(this.partyMasterDtoForGridRecofig);
		this.masterreadService.getConsigneeMaster(this.partyMasterDtoForGridRecofig).subscribe(
			(response) => {
				console.log(response);
				this.consigneeDetailsDataList = [];
				$("#" + this.pageId + "consigneeDetailsTableId").DataTable().destroy();
				if (response.length > 0) {
					this.consigneeDetailsDataList = response;
				} else {
					swal("Alert", "No details found for this Consignee Master", "warning");
				}
				this.dtTriggerConsigneeDetails.next();
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Consignee Details", "warning");
			},
			() => console.log('done');
	}

	/* On Load Datatable Work End */

	/* Consignee Status Details Datatable Work Start */

	getConsigneeDetailsList() {
		if ($("#" + this.pageId + "destinationCityId").val() == null || $("#" + this.pageId + "destinationCityId").val() == undefined ||
			$("#" + this.pageId + "destinationCityId").val() == "") {
			swal("Empty Selection", "Please select the City to get the details", "warning");
			return false;
		} else {
			swal({
				title: "Alert",
				text: "Sure you want to get the Consignee Details, and it will take some time to get result",
				icon: "info",
				buttons: ["No", "Yes"]
			}).then((isConfirm) => {
				if (isConfirm) {
					this.getConsigneeStatusDetails();
				}
			});
		}
	}

	getConsigneeStatusDetails() {
		this.lrDtoForConsigneeStatus = new LRDto();
		this.validateSelectedDestination = $("#" + this.pageId + "destinationCityId").val();
		this.lrDtoForConsigneeStatus.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForConsigneeStatus.mode = this.validateSelectedDestination;
		this.lrDtoForConsigneeStatus.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeActNonActive(this.lrDtoForConsigneeStatus).subscribe(
			(response) => {
				console.log(response);
				this.consigneeStatusDetailsDataList = [];
				$("#" + this.pageId + "consigneeStatusTableId").DataTable().destroy();
				this.consigneeStatusDetailsDataList = response;
				this.dtTriggerConsigneeStatusDetails.next();
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Consignee Active & Non Active Details", "warning");
			},
			() => console.log('done');
	}

	/* Consignee Status Details Datatable Work End */

	/* Consignee Details Datatable Edit Work End */

	rowSelectedEditConsigneeDeatils(consigneeDetailsData) {
		this.showSpinnerForAction = true;
		console.log(consigneeDetailsData);

		//CHG-V-CNM:04
		// this.viewMonitorPerfToggle=true;
		if (this.rightsViewMonitorPerfToggle) {
			this.viewMonitorPerfToggle = true;
		} else {
			this.viewMonitorPerfToggle = false;
		}

		this.setFieldValues(consigneeDetailsData);
		/*setTimeout(() => {this.beforeEditClearField();}, 5000);*/
		this.showSpinnerForAction = false;
		this.changeDetectorRef.detectChanges();
	}

	setFieldValues(consigneeDetailsData) {

		// setTimeout(() => {
		this.validateSelectedConsigneeId = 0;
		this.validateSelectedStateCode = null;

		this.viewSaveBtn = false; // need to false at clear

		//CHG-V-CNM:05 
		// if (this.rightsForViewSaveBtn) {
		// 	this.viewUpdateBtn = true; // need to false at clear
		// }

		////CHG-V-CNM:06
		if (this.rightsForOnlyUpdateBtn) {
			this.viewUpdateBtn = true;
		}

		$("#" + this.pageId + "destinationCityId").val(consigneeDetailsData.city);
		$("#" + this.pageId + "consigneeNameId").val(consigneeDetailsData.consigneeName);
		$("#" + this.pageId + "consigneeNameId").prop('disabled', true);
		this.validateSelectedConsigneeId = consigneeDetailsData.consigneeId;
		console.log("this.validateSelectedConsigneeId");
		console.log(this.validateSelectedConsigneeId);
		$("#" + this.pageId + "contactPersonId").val(consigneeDetailsData.contactPerson);
		$("#" + this.pageId + "stateNameAndCodeId").val(consigneeDetailsData.state);
		this.validateSelectedStateCode = consigneeDetailsData.stateCode;
		$("#" + this.pageId + "gstNumberId").val(consigneeDetailsData.gstNoConsignee);
		$("#" + this.pageId + "officeNumberId").val(consigneeDetailsData.officeNumber);
		$("#" + this.pageId + "emailId").val(consigneeDetailsData.email);
		$("#" + this.pageId + "addressId").val(consigneeDetailsData.address);
		$("#" + this.pageId + "pincode").val(consigneeDetailsData.pincode);
		$("#" + this.pageId + "eDeclarationPassWordId").val(consigneeDetailsData.eDeclarationPwd);

		this.consigneeMobileNoList = null;
		this.consigneeMobileNoList = consigneeDetailsData.mobileNumber
		if (consigneeDetailsData.mobileNumber != null) {
			//this.viewAddMobileNo=consigneeDetailsData.mobileNumber;
			this.viewAddMobileNo = 'View';
		} else {
			this.viewAddMobileNo = 'Add New';
		}

		// this.showSpinnerForAction = true;
		// setTimeout(() => {
		if (consigneeDetailsData.deliveryType != null && consigneeDetailsData.deliveryType == "Select...") {
			$("#" + this.pageId + "deliveryType").val('select');
			this.viewArea = false;
		} else if (consigneeDetailsData.deliveryType != null && consigneeDetailsData.deliveryType == "Godown Delivery") {
			$("#" + this.pageId + "deliveryType").val('godown');
			this.viewArea = false;
		} else if (consigneeDetailsData.deliveryType != null && consigneeDetailsData.deliveryType == "Door Delivery") {
			$("#" + this.pageId + "deliveryType").val('doorDelivery');
			this.viewArea = true;
		} else {
			$("#" + this.pageId + "deliveryType").val('select');
			this.viewArea = false;
		}
		// this.showSpinnerForAction = false;
		// }, 2000);

		if (this.viewArea) {
			// this.showSpinnerForAction = true;
			setTimeout(() => {
			$("#" + this.pageId + "area").val(consigneeDetailsData.area);
			// CHG-V-CNM:07
			$("#" + this.pageId + "subArea").val(consigneeDetailsData.subArea);
			// this.showSpinnerForAction = false;
			}, 2000);
			
		}

		if (consigneeDetailsData.collectionMan != null && consigneeDetailsData.collectionMan != undefined &&
			consigneeDetailsData.collectionMan != "" && consigneeDetailsData.collectionMan != "NA") {
			$("#" + this.pageId + "collectionManId").val(consigneeDetailsData.collectionMan);
		}

		if (consigneeDetailsData.partyArea != null && consigneeDetailsData.partyArea == "Binnymills") {
			$("#" + this.pageId + "partyAreaId").val('binnymills');
		} else if (consigneeDetailsData.partyArea != null && consigneeDetailsData.partyArea == "Konditope") {
			$("#" + this.pageId + "partyAreaId").val('konditope');
		}

		// this.showSpinnerForAction = true;
		// setTimeout(() => {
		if (consigneeDetailsData.allowed) {
			$("#" + this.pageId + "modeAllowedId").val('true');
		} else {
			$("#" + this.pageId + "modeAllowedId").val('false');
		}

		if (consigneeDetailsData.blocked) {
			$("#" + this.pageId + "desBlockId").val('true');
			this.viewBlockMessageRemarks = true;
		} else {
			$("#" + this.pageId + "desBlockId").val('false');
			this.viewBlockMessageRemarks = false;
		}

		if (consigneeDetailsData.sendRemindSMS) {
			$("#" + this.pageId + "sendRemainderSmsId").val('true');
		} else {
			$("#" + this.pageId + "sendRemainderSmsId").val('false');
		}

		//pendg
		if (consigneeDetailsData.sendStockSms) {
			$("#" + this.pageId + "sendStockSmsId").val('true');
		} else {
			$("#" + this.pageId + "sendStockSmsId").val('false');
		}

		if (consigneeDetailsData.isAlert) {
			$("#" + this.pageId + "popUpAlertId").val('true');
			this.viewisPopUpAlertMessageRemarks = true;
		} else {
			$("#" + this.pageId + "popUpAlertId").val('false');
			this.viewisPopUpAlertMessageRemarks = false;
		}

		if (consigneeDetailsData.isSrcBlock) {
			$("#" + this.pageId + "srcBlockId").val('true');
			this.viewSrcBlockMessageRemarks = true;
		} else {
			$("#" + this.pageId + "srcBlockId").val('false');
			this.viewSrcBlockMessageRemarks = false;
		}

		if (consigneeDetailsData.demurageExmp) {
			$("#" + this.pageId + "demurageExmp").val('true');
		} else {
			$("#" + this.pageId + "demurageExmp").val('false');
		}

		// }, 1000);

		// this.showSpinnerForAction = true;
		// setTimeout(() => {


		this.blockMessageRemarksId = consigneeDetailsData.message;
		// if (this.viewBlockMessageRemarks) {
		// 	console.log(this.viewBlockMessageRemarks);
		// 	if (consigneeDetailsData.message != null &&
		// 		consigneeDetailsData.message != undefined &&
		// 		consigneeDetailsData.message != "" &&
		// 		consigneeDetailsData.message != "NA") {
		// 		$("#" + this.pageId + "blockMessageRemarksId").val(consigneeDetailsData.message);
		// 	}
		// }

		this.popUpAlertMessageRemarks = consigneeDetailsData.alertMessage;
		// if (this.viewisPopUpAlertMessageRemarks) {
		// 	if (consigneeDetailsData.alertMessage != null &&
		// 		consigneeDetailsData.alertMessage != undefined &&
		// 		consigneeDetailsData.alertMessage != "" &&
		// 		consigneeDetailsData.alertMessage != "NA") {
		// 		$("#" + this.pageId + "isPopupAlertMessageRemarksId").val(consigneeDetailsData.alertMessage);
		// 	}
		// }

		this.srcBlockMessageRemarksId = consigneeDetailsData.srcAlertMessage;
		// if (this.viewSrcBlockMessageRemarks) {
		// 	if (consigneeDetailsData.srcAlertMessage != null &&
		// 		consigneeDetailsData.srcAlertMessage != undefined &&
		// 		consigneeDetailsData.srcAlertMessage != "" &&
		// 		consigneeDetailsData.srcAlertMessage != "NA") {
		// 		$("#" + this.pageId + "isSrcBlockMessageRemarksId").val(consigneeDetailsData.srcAlertMessage);
		// 	}
		// }
		// }, 1000);
		console.log("consigneeDetailsData.multipleCommodity");
		console.log(consigneeDetailsData.multipleCommodity);
		if (consigneeDetailsData.multipleCommodity != null &&
			consigneeDetailsData.multipleCommodity != undefined &&
			consigneeDetailsData.multipleCommodity != "") {
			var multipleCommodityList = [];
			this.consigneeCommodityDataList = [];
			$("#" + this.pageId + "consigneeCommodityTableId").DataTable().destroy();
			multipleCommodityList = consigneeDetailsData.multipleCommodity.split("#");
			if (multipleCommodityList != null && multipleCommodityList != undefined &&
				multipleCommodityList.length > 0) {
				for (let i = 0; i < multipleCommodityList.length; i++) {
					this.newAttributeSetConsigneeCommoditySet = {};
					this.newAttributeSetConsigneeCommoditySet.commodity = multipleCommodityList[i];
					this.consigneeCommodityDataList.push(this.newAttributeSetConsigneeCommoditySet);
				}
			}
			this.dtTriggerConsigneeCommodity.next();
		} else {
			this.consigneeCommodityDataList = [];
			$("#" + this.pageId + "consigneeCommodityTableId").DataTable().destroy();
			this.dtTriggerConsigneeCommodity.next();
		}

		if (consigneeDetailsData.unldgCommodities != null &&
			consigneeDetailsData.unldgCommodities != undefined &&
			consigneeDetailsData.unldgCommodities != "") {
			this.commodityDataList = [];
			$("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();

			var unldgCommoditiesList = [];
			var unldgChgList = [];
			var unldgSourceList = [];
			var unldgUnitList = [];

			unldgCommoditiesList = consigneeDetailsData.unldgCommodities.split("#");
			unldgChgList = consigneeDetailsData.unldgChg.split("#");
			unldgSourceList = consigneeDetailsData.unldgSource.split("#");
			unldgUnitList = consigneeDetailsData.unldgUnit.split("#");

			if (unldgCommoditiesList != null && unldgCommoditiesList != undefined &&
				unldgCommoditiesList.length > 0) {
				for (let i = 0; i < unldgCommoditiesList.length; i++) {
					this.newAttributeSetUnldgCommoditySet = {};
					this.newAttributeSetUnldgCommoditySet.commodity = unldgCommoditiesList[i];
					this.newAttributeSetUnldgCommoditySet.unldgChg = unldgChgList[i];
					this.newAttributeSetUnldgCommoditySet.source = unldgSourceList[i];
					this.newAttributeSetUnldgCommoditySet.unit = unldgUnitList[i];
					this.commodityDataList.push(this.newAttributeSetUnldgCommoditySet);
				}
			}
			this.dtTriggerCommodity.next();
		} else {
			this.commodityDataList = [];
			$("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
			this.dtTriggerCommodity.next();
		}
		$("#" + this.pageId + "memoTimePeriod").val(consigneeDetailsData.memoTimePeriod);

		if (consigneeDetailsData.paymentMode != null && consigneeDetailsData.paymentMode == "Cash") {
			$("#" + this.pageId + "paymentMode").val('Cash');
		} else if (consigneeDetailsData.paymentMode != null && consigneeDetailsData.paymentMode == "Outstanding") {
			$("#" + this.pageId + "paymentMode").val('Outstanding');
		}

		if (consigneeDetailsData.disableDefaultCft != null && consigneeDetailsData.disableDefaultCft) {
			$("#" + this.pageId + "checkboxCft").prop('checked', true);
		} else {
			$("#" + this.pageId + "checkboxCft").prop('checked', false);
		}

		if (consigneeDetailsData.leftToPartyEnable != null && consigneeDetailsData.leftToPartyEnable == "Yes") {
			$("#" + this.pageId + "leftToPartyAllow").val('Yes');
		} else if (consigneeDetailsData.leftToPartyEnable != null && consigneeDetailsData.leftToPartyEnable == "No") {
			$("#" + this.pageId + "leftToPartyAllow").val('No');
		}
		// this.showSpinnerForAction = false;
		// this.changeDetectorRef.detectChanges();
		// }, 1000);
		this.isMonitorDelPerformance = consigneeDetailsData.isPerformanceMonitor;
	}

	/* Consignee Details Datatable Edit Work End */

	/* Consignee Cpmmodity Datatable Add,Edit  & Delete Work Start */
	addConsigneeCommodityDetailsTable() {
		if ($("#" + this.pageId + "commodityMainId").val() == null ||
			$("#" + this.pageId + "commodityMainId").val() == undefined ||
			$("#" + this.pageId + "commodityMainId").val() == "") {
			swal("Mandatory Field", "Please select the Commodity", "warning");
			return false;
		} else {
			this.reconfigureAddMultiConsigneeCommodity();
		}
	}

	reconfigureAddMultiConsigneeCommodity() {
		this.newAttributeSetConsigneeCommoditySet = {};
		this.newAttributeSetConsigneeCommoditySet.commodity = $("#" + this.pageId + "commodityMainId").val();

		if (this.editConsigneeCommodityTableId != null && this.editConsigneeCommodityTableId != undefined) {
			this.consigneeCommodityDataList.splice(this.editConsigneeCommodityTableId, 1);
		}

		if (this.consigneeCommodityDataList == null || this.consigneeCommodityDataList == undefined ||
			this.consigneeCommodityDataList.length == 0) {
			this.consigneeCommodityDataList = [];
		}

		if (this.consigneeCommodityDataList.length == 0) {
			$("#" + this.pageId + "consigneeCommodityTableId").DataTable().destroy();
			this.consigneeCommodityDataList.push(this.newAttributeSetConsigneeCommoditySet);
			this.dtTriggerConsigneeCommodity.next();

			this.newAttributeSetConsigneeCommoditySet = {};
			$("#" + this.pageId + "commodityMainId").val('');
			this.modelCommodityMain = null;
			this.editConsigneeCommodityTableId = null;
		} else {
			const result = this.consigneeCommodityDataList.filter(consigneeCommodityData =>
				consigneeCommodityData.commodity === this.newAttributeSetConsigneeCommoditySet.commodity);
			console.log(result);
			if (result.length > 0) {
				swal({
					title: "Duplicate Entry",
					text: "Commodity " + this.newAttributeSetConsigneeCommoditySet.commodity + " is Already Exist",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
			} else {
				$("#" + this.pageId + "consigneeCommodityTableId").DataTable().destroy();
				this.consigneeCommodityDataList.push(this.newAttributeSetConsigneeCommoditySet);
				this.dtTriggerConsigneeCommodity.next();

				this.newAttributeSetConsigneeCommoditySet = {};
				$("#" + this.pageId + "commodityMainId").val('');
				this.modelCommodityMain = null;
				this.editConsigneeCommodityTableId = null;
			}
		}
	}

	rowSelectedEditConsigneeCommodityDeatils(consigneeCommodityData, index) {
		$("#" + this.pageId + "commodityMainId").val(consigneeCommodityData.commodity);
		this.editConsigneeCommodityTableId = index;
	}

	deleteRowSelectedConsigneeCommodity(consigneeCommodityData, index) {
		this.consigneeCommodityDataList.splice(index, 1);
		$("#" + this.pageId + "commodityMainId").val('');
		$("#" + this.pageId + "consigneeCommodityTableId").DataTable().destroy();
		this.dtTriggerConsigneeCommodity.next();
	}

	clearConsigneeCommodityDetailsTable() {
		this.newAttributeSetConsigneeCommoditySet = {};
		this.editConsigneeCommodityTableId = null;
		$("#" + this.pageId + "commodityMainId").val('');
		//$("#"+this.pageId+"consigneeCommodityTableId").DataTable().destroy();
		//this.dtTriggerConsigneeCommodity.next();
		this.modelCommodityMain = null;
	}

	addCommodityDetailsTable() {
		if ($("#" + this.pageId + "CommodityMainUnldgId").val() == null || $("#" + this.pageId + "CommodityMainUnldgId").val() == undefined ||
			$("#" + this.pageId + "CommodityMainUnldgId").val() == "" || $("#" + this.pageId + "source").val() == null ||
			$("#" + this.pageId + "source").val() == undefined || $("#" + this.pageId + "source").val() == "" ||
			$("#" + this.pageId + "unldnChg").val() == null || $("#" + this.pageId + "unldnChg").val() == undefined ||
			$("#" + this.pageId + "unldnChg").val() == "" || $("#" + this.pageId + "unitId").val() == null ||
			$("#" + this.pageId + "unitId").val() == undefined || $("#" + this.pageId + "unitId").val() == "" ||
			$("#" + this.pageId + "unitId").val() == "Unit") {
			swal("Mandatory Field", "Please select the Mandatory Field", "warning");
			return false;
		} else {
			this.reconfigureAddMultiCUnldgCommodity();
		}
	}

	reconfigureAddMultiCUnldgCommodity() {
		this.newAttributeSetUnldgCommoditySet = {};
		this.newAttributeSetUnldgCommoditySet.commodity = $("#" + this.pageId + "CommodityMainUnldgId").val();
		this.newAttributeSetUnldgCommoditySet.unldgChg = $("#" + this.pageId + "unldnChg").val();
		this.newAttributeSetUnldgCommoditySet.source = $("#" + this.pageId + "source").val();
		this.newAttributeSetUnldgCommoditySet.unit = $("#" + this.pageId + "unitId").val();

		//alert(this.editUnldgCommodityTableId);
		if (this.editUnldgCommodityTableId != null && this.editUnldgCommodityTableId != undefined) {
			this.commodityDataList.splice(this.editUnldgCommodityTableId, 1);
		}

		if (this.commodityDataList == null || this.commodityDataList == undefined ||
			this.commodityDataList.length == 0) {
			this.commodityDataList = [];
		}

		if (this.commodityDataList.length == 0) {
			$("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
			this.commodityDataList.push(this.newAttributeSetUnldgCommoditySet);
			this.dtTriggerCommodity.next();

			this.newAttributeSetUnldgCommoditySet = {};
			this.editUnldgCommodityTableId = null;
			$("#" + this.pageId + "CommodityMainUnldgId").val('');
			$("#" + this.pageId + "source").val('All');
			$("#" + this.pageId + "unldnChg").val('');
			$("#" + this.pageId + "unitId").val('Unit');
			this.modelCommodityMainUnldg = null;
			this.modelSource = null;
		} else {
			const result = this.commodityDataList.filter(commodityData =>
				commodityData.commodity === this.newAttributeSetUnldgCommoditySet.commodity);
			console.log(result);
			if (result.length > 0) {
				swal({
					title: "Duplicate Entry",
					text: "Commodity " + this.newAttributeSetUnldgCommoditySet.commodity + " is Already Exist",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
			} else {
				$("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
				this.commodityDataList.push(this.newAttributeSetUnldgCommoditySet);
				this.dtTriggerCommodity.next();

				this.newAttributeSetUnldgCommoditySet = {};
				this.editUnldgCommodityTableId = null;
				$("#" + this.pageId + "CommodityMainUnldgId").val('');
				$("#" + this.pageId + "source").val('All');
				$("#" + this.pageId + "unldnChg").val('');
				$("#" + this.pageId + "unitId").val('Unit');
				this.modelCommodityMainUnldg = null;
				this.modelSource = null;
			}
		}
	}

	rowSelectedEditUnldgCommodityDeatils(commodityData, index) {
		$("#" + this.pageId + "CommodityMainUnldgId").val(commodityData.commodity);
		$("#" + this.pageId + "unldnChg").val(commodityData.unldgChg);
		$("#" + this.pageId + "source").val(commodityData.source);
		$("#" + this.pageId + "unitId").val(commodityData.unit);
		this.editUnldgCommodityTableId = index;
		console.log(this.editUnldgCommodityTableId);
	}

	deleteRowSelectedUnldgCommodity(commodityData, index) {
		this.commodityDataList.splice(index, 1);
		$("#" + this.pageId + "CommodityMainUnldgId").val('');
		$("#" + this.pageId + "source").val('All');
		$("#" + this.pageId + "unldnChg").val('');
		$("#" + this.pageId + "unitId").val('Unit');
		$("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
		this.dtTriggerCommodity.next();
	}

	clearCommodityDetailsTable() {
		this.newAttributeSetUnldgCommoditySet = {};
		this.editUnldgCommodityTableId = null;
		$("#" + this.pageId + "CommodityMainUnldgId").val('');
		$("#" + this.pageId + "source").val('All');
		$("#" + this.pageId + "unldnChg").val('');
		$("#" + this.pageId + "unitId").val('Unit');
		this.modelCommodityMainUnldg = null;
		this.modelSource = null;
	}

	/* Consignee Cpmmodity Datatable Add,Edit & Delete Work End */

	/* Mobile Number Pop Up Work Start */

	viewMobileNumber() {
		localStorage.clear();
		localStorage.setItem('ConsigneeMobileNo', JSON.stringify("ConsigneeMobileNo"));
		if (this.consigneeMobileNoList != null &&
			this.consigneeMobileNoList != undefined &&
			this.consigneeMobileNoList.length > 0) {
			localStorage.setItem('ConsigneeMobileNoLocalStorage', JSON.stringify(this.consigneeMobileNoList));
		}

		const dialogRef = this.dialog.open(ConsigneeMultipleMobileNumberDetailsComponent, {
		});

		dialogRef.afterClosed().subscribe(result => {
			this.returnListOfMobileNumber = [];
			if (result != null && result != undefined &&
				result.length > 0) {
				let obj = JSON.parse(result);
				var dataSetConsignorMobileNo = null;
				for (let i = 0; i < obj.length; i++) {
					this.returnListOfMobileNumber.push(obj[i].mobileNo);
				}

				var mobileNoNew = null;
				for (let i = 0; i < this.returnListOfMobileNumber.length; i++) {
					if (i == 0) {
						mobileNoNew = this.returnListOfMobileNumber[i];
					} else {
						mobileNoNew += "#" + this.returnListOfMobileNumber[i];
					}
				}

				this.consigneeMobileNoList = null;
				this.consigneeMobileNoList = mobileNoNew;
				console.log(dataSetConsignorMobileNo);
				console.log(this.returnListOfMobileNumber);
			} else {
				this.returnListOfMobileNumber = [];
				console.log(this.returnListOfMobileNumber);
			}
			console.log(`Dialog result: ${result}`);
		});

	}

	/* Mobile Number Pop Up Work End */

	/* Save Validation & Save Opertion Done Start */

	validateSaveConsignee() {
		this.validateSelectedCityDestForSave = $("#" + this.pageId + "destinationCityId").val();
		this.validateSelectedConsigneeNameForSave = $("#" + this.pageId + "consigneeNameId").val();
		this.validateSelectedGstNoForSave = $("#" + this.pageId + "gstNumberId").val();

		if (this.validateSelectedCityDestForSave == null || this.validateSelectedCityDestForSave == undefined || this.validateSelectedCityDestForSave == ''
			|| this.validateSelectedConsigneeNameForSave == null || this.validateSelectedConsigneeNameForSave == undefined || this.validateSelectedConsigneeNameForSave == ''
			|| this.validateSelectedGstNoForSave == null || this.validateSelectedGstNoForSave == undefined || this.validateSelectedGstNoForSave == '') {
			swal("Not Allowed", "Please Enter the City, Consignee Name & GST No are mandatory fields to proceed!", "warning");
		} else {
			swal({
				title: "Confirm Save",
				text: "Sure You Want to Create the Consignee Details",
				icon: "info",
				buttons: ["No", "Yes"]
			}).then((isConfirm) => {
				if (isConfirm) {
					this.setSaveConsigneeDetails();
				}
			});
		}
	}

	validateUpdateConsignee() {
		this.validateSelectedCityDestForSave = $("#" + this.pageId + "destinationCityId").val();
		this.validateSelectedConsigneeNameForSave = $("#" + this.pageId + "consigneeNameId").val();
		this.validateSelectedGstNoForSave = $("#" + this.pageId + "gstNumberId").val();

		if (this.validateSelectedCityDestForSave == null || this.validateSelectedCityDestForSave == undefined || this.validateSelectedCityDestForSave == ''
			|| this.validateSelectedConsigneeNameForSave == null || this.validateSelectedConsigneeNameForSave == undefined || this.validateSelectedConsigneeNameForSave == ''
			|| this.validateSelectedGstNoForSave == null || this.validateSelectedGstNoForSave == undefined || this.validateSelectedGstNoForSave == '') {
			swal("Not Allowed", "Please Enter the City, Consignee Name & GST No are mandatory fields to proceed!", "warning");
		} else {
			swal({
				title: "Confirm Update",
				text: "Sure You Want to Update the Consignee Details",
				icon: "info",
				buttons: ["No", "Yes"]
			}).then((isConfirm) => {
				if (isConfirm) {
					this.setUpdateConsigneeDetails();
				}
			});
		}
	}

	setSaveConsigneeDetails() {
		this.partyMasterDtoForSave = new PartyMasterDto();
		this.partyMasterDtoForSave = this.getSaveDetails();
		console.log(this.partyMasterDtoForSave);
		this.masterService.createConsignee(this.partyMasterDtoForSave).subscribe(
			(response) => {
				console.log(response);
				if (response.status != null && response.status != undefined &&
					response.status == "sucess") {
					this.clearFieldAll();
					swal("Consignee Details", "Consignee Details Created Successfully", "sucess");
					if (this.destView) {
						//CHG-V-CNM:01
						this.gridReconfigure(this.selectedConsigneeIndex, false, null);
					} else {
						//CHG-V-CNM:01
						this.gridReconfigure(this.selectedConsigneeIndex, false, null);
					}
				} else {
					swal("Failed", "Failed to Created a Consignee Details", "warning");
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Created Consignee Details", "warning");
			},
			() => console.log('done');
	}

	setUpdateConsigneeDetails() {
		this.partyMasterDtoForSave = new PartyMasterDto();
		this.partyMasterDtoForSave = this.getSaveDetails();
		console.log(this.partyMasterDtoForSave);
		this.masterService.createConsignee(this.partyMasterDtoForSave).subscribe(
			(response) => {
				console.log(response);
				if (response.status != null && response.status != undefined &&
					response.status == "sucess") {
					this.clearFieldAll();
					swal("Consignee Details", "Consignee Details Updated Successfully", "Sucess");
					if (this.destView) {
						//CHG-V-CNM:01
						this.gridReconfigure(this.selectedConsigneeIndex, false, null);
					} else {
						//CHG-V-CNM:01
						this.gridReconfigure(this.selectedConsigneeIndex, false, null);
					}

				} else {
					swal("Failed", "Failed to Updated a Consignee Details", "warning");
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Updated Consignee Details", "warning");
			},
			() => console.log('done');
	}

	getSaveDetails() {
		this.partyMasterDto = new PartyMasterDto();

		this.validateSelectedCityDestForSave = null;
		this.validateSelectedConsigneeNameForSave = null;
		this.validateSelectedContactPersonForSave = null;
		this.validateSelectedMobileNumberForSave = null;
		this.validateSelectedStateNameForSave = null;
		this.validateSelectedGstNoForSave = null;
		this.validateSelectedOfficeNoForSave = null;
		this.validateSelectedEDeclPwdForSave = null;
		this.validateSelectedEmailidForSave = null;
		this.validateSelectedAddressForSave = null;
		this.validateSelectedPinCodeForSave = null;
		this.validateSelectedConsigneeIdForSave = null;
		this.validateSelectedStateCodeForSave = null;

		this.validateSelectedDeliveryTypeForSave = null;
		this.validateSelectedCollectionManForSave = null;
		this.validateSelectedPartyAreaForSave = null;
		this.validateSelectedAreaForSave = null;

		this.validateSelectedModeForSave = null;
		this.validateSelectedDesBlockForSave = null;
		this.validateSelectedSendRMDSMSForSave = null;
		this.validateSelectedSendStockSMSForSave = null;
		this.validateSelectedPopUpAlertForSave = null;
		this.validateSelectedSRCBlockForSave = null;
		this.enterdedMemoTimePeriod = null;
		this.validateSelectedBlockMessagetemplateForSave = null;
		this.validateSelectedIsAlertPopuptemplateForSave = null;
		this.validateSelectedIsSrcBlocktemplateForSave = null;
		this.validateSelectedDemurageExmpForSave = null;
		this.validateSelectedPaymentModeForSave = null;
		this.disableCftForSave = null;
		this.validateSelectedLeftToPartyForSave = null;
		// CHG-V-CNM:07
		this.validateSubAreaForSave = null;

		this.validateSelectedCityDestForSave = $("#" + this.pageId + "destinationCityId").val();
		this.validateSelectedConsigneeNameForSave = $("#" + this.pageId + "consigneeNameId").val();
		this.validateSelectedContactPersonForSave = $("#" + this.pageId + "contactPersonId").val();
		this.validateSelectedMobileNumberForSave = null;
		this.validateSelectedStateNameForSave = $("#" + this.pageId + "stateNameAndCodeId").val();
		this.validateSelectedGstNoForSave = $("#" + this.pageId + "gstNumberId").val();
		this.validateSelectedOfficeNoForSave = $("#" + this.pageId + "officeNumberId").val();
		this.validateSelectedEDeclPwdForSave = $("#" + this.pageId + "eDeclarationPassWordId").val();
		this.validateSelectedEmailidForSave = $("#" + this.pageId + "emailId").val();
		this.validateSelectedAddressForSave = $("#" + this.pageId + "addressId").val();
		this.validateSelectedPinCodeForSave = $("#" + this.pageId + "pincode").val();
		console.log(this.validateSelectedConsigneeId);
		if (this.validateSelectedConsigneeId != null && this.validateSelectedConsigneeId != undefined &&
			this.validateSelectedConsigneeId != "" && this.validateSelectedConsigneeId != 0) {

			this.validateSelectedConsigneeIdForSave = this.validateSelectedConsigneeId;
			console.log("if");
		} else {
			console.log("else");
			this.validateSelectedConsigneeIdForSave = 0;
		}

		if (this.validateSelectedStateCode != null && this.validateSelectedStateCode != undefined &&
			this.validateSelectedStateCode != "") {
			this.validateSelectedStateCodeForSave = this.validateSelectedStateCode;
		}


		this.validateSelectedDeliveryTypeForSave = $("#" + this.pageId + "deliveryType").val();
		this.validateSelectedCollectionManForSave = $("#" + this.pageId + "collectionManId").val();
		this.validateSelectedPartyAreaForSave = $("#" + this.pageId + "partyAreaId").val();
		this.validateSelectedAreaForSave = $("#" + this.pageId + "area").val();

		this.validateSelectedModeForSave = $("#" + this.pageId + "modeAllowedId").val();
		this.validateSelectedDesBlockForSave = $("#" + this.pageId + "desBlockId").val();
		this.validateSelectedSendRMDSMSForSave = $("#" + this.pageId + "sendRemainderSmsId").val();
		this.validateSelectedSendStockSMSForSave = $("#" + this.pageId + "sendStockSmsId").val();
		this.validateSelectedPopUpAlertForSave = $("#" + this.pageId + "popUpAlertId").val();
		this.validateSelectedSRCBlockForSave = $("#" + this.pageId + "srcBlockId").val();
		this.enterdedMemoTimePeriod = $("#" + this.pageId + "memoTimePeriod").val();
		this.validateSelectedDemurageExmpForSave = $("#" + this.pageId + "demurageExmp").val();
		this.validateSelectedPaymentModeForSave = $("#" + this.pageId + "paymentMode").val();
		this.disableCftForSave = $("#" + this.pageId + "checkboxCft").is(":checked");
		this.validateSelectedLeftToPartyForSave = $("#" + this.pageId + "leftToPartyAllow").val();
		// CHG-V-CNM:07
		this.validateSubAreaForSave = $("#" + this.pageId + "subArea").val();

		if (this.destView) {
			this.partyMasterDto.city = this.validateSelectedCityDestForSave;
		} else {
			this.partyMasterDto.city = this.userDataDtoReturnSession.mainStation;
		}
		this.partyMasterDto.consigneeName = this.validateSelectedConsigneeNameForSave.toUpperCase();
		this.partyMasterDto.consigneeId = this.validateSelectedConsigneeIdForSave;
		this.partyMasterDto.contactPerson = this.validateSelectedContactPersonForSave;

		if (this.validateSelectedStateCode != null && this.validateSelectedStateCode != undefined &&
			this.validateSelectedStateCode != "") {
			this.validateSelectedStateCodeForSave = this.validateSelectedStateCode;
			this.partyMasterDto.stateCode = this.validateSelectedStateCodeForSave;
		}
		this.partyMasterDto.state = this.validateSelectedStateNameForSave;
		this.partyMasterDto.gstNoConsignee = this.validateSelectedGstNoForSave;
		this.partyMasterDto.officeNumber = this.validateSelectedOfficeNoForSave;
		this.partyMasterDto.eDeclarationPwd = this.validateSelectedEDeclPwdForSave;
		this.partyMasterDto.email = this.validateSelectedEmailidForSave;
		this.partyMasterDto.address = this.validateSelectedAddressForSave;
		this.partyMasterDto.pincode = this.validateSelectedPinCodeForSave;

		if ($("#" + this.pageId + "deliveryType").val() == "select") {
			this.partyMasterDto.deliveryType = "Select...";
		} else if ($("#" + this.pageId + "deliveryType").val() == "godown") {
			this.partyMasterDto.deliveryType = "Godown Delivery";
		} else if ($("#" + this.pageId + "deliveryType").val() == "doorDelivery") {
			this.partyMasterDto.deliveryType = "Door Delivery";
		} else {
			this.partyMasterDto.deliveryType = "Select...";
		}

		if (this.viewArea && $("#" + this.pageId + "deliveryType").val() == "doorDelivery") {
			this.partyMasterDto.area = this.validateSelectedAreaForSave;
			// CHG-V-CNM:07
			this.partyMasterDto.subArea = this.validateSubAreaForSave;
		}

		this.partyMasterDto.collectionMan = this.validateSelectedCollectionManForSave;
		this.partyMasterDto.partyArea = this.validateSelectedPartyAreaForSave;

		var mobileNoNew = null;
		mobileNoNew = this.consigneeMobileNoList;
		console.log(this.consigneeMobileNoList);
		console.log(mobileNoNew);

		if (mobileNoNew != null && mobileNoNew != undefined) {
			this.partyMasterDto.mobileNumber = mobileNoNew;
		}

		if ($("#" + this.pageId + "modeAllowedId").val() == "true") {
			this.partyMasterDto.isAllowed = true;
			this.partyMasterDto.isConsigneeAllow = true;
		} else {
			this.partyMasterDto.isAllowed = false;
			this.partyMasterDto.isConsigneeAllow = false;
		}

		if ($("#" + this.pageId + "desBlockId").val() == "true") {
			this.partyMasterDto.isBlocked = true;
			this.partyMasterDto.destBlocked = true;
		} else {
			this.partyMasterDto.isBlocked = false;
			this.partyMasterDto.destBlocked = false;
		}
		// block msg pendg

		if ($("#" + this.pageId + "sendRemainderSmsId").val() == "true") {
			this.partyMasterDto.sendRmdSMS = true;
		} else {
			this.partyMasterDto.sendRmdSMS = false;
		}

		if ($("#" + this.pageId + "sendStockSmsId").val() == "true") {
			this.partyMasterDto.sendStockSms = true;
		} else {
			this.partyMasterDto.sendStockSms = false;
		}

		if ($("#" + this.pageId + "popUpAlertId").val() == "true") {
			this.partyMasterDto.isAlert = true;
		} else {
			this.partyMasterDto.isAlert = false;
		}

		//isAlert msg pend

		if ($("#" + this.pageId + "srcBlockId").val() == "true") {
			this.partyMasterDto.isSrcBlock = true;
		} else {
			this.partyMasterDto.isSrcBlock = false;
		}

		if ($("#" + this.pageId + "demurageExmp").val() == "true") {
			this.partyMasterDto.demurageExmp = true;
		} else {
			this.partyMasterDto.demurageExmp = false;
		}

		//isSrcBlock msg pend

		var multipleCommodity = null;

		if (this.consigneeCommodityDataList != null &&
			this.consigneeCommodityDataList != undefined &&
			this.consigneeCommodityDataList.length > 0) {

			for (let i = 0; i < this.consigneeCommodityDataList.length; i++) {
				multipleCommodity = multipleCommodity + "#"
					+ this.consigneeCommodityDataList[i].commodity;
			}
		}

		if (multipleCommodity != null && multipleCommodity.endsWith("#")) {
			multipleCommodity = multipleCommodity.replace("# ", "");
		}
		if (multipleCommodity != null) {
			multipleCommodity = multipleCommodity.replace("null#", "");
			multipleCommodity = multipleCommodity.replace("##", "#");
		}
		this.partyMasterDto.multipleCommodity = multipleCommodity;

		var unldgCommodities = null, unldgChg = null, unldgUnit = null, unldgSource = null;

		if (this.commodityDataList != null &&
			this.commodityDataList != undefined &&
			this.commodityDataList.length > 0) {

			for (let i = 0; i < this.commodityDataList.length; i++) {

				unldgCommodities = unldgCommodities + "#" + this.commodityDataList[i].commodity;

				unldgChg = unldgChg + "#" + this.commodityDataList[i].unldgChg;

				unldgUnit = unldgUnit + "#" + this.commodityDataList[i].unit;

				unldgSource = unldgSource + "#" + this.commodityDataList[i].source;
			}
		}

		if (unldgCommodities != null && unldgCommodities.endsWith("#")) {
			unldgCommodities = unldgCommodities.replace("# ", "");
		}

		if (unldgCommodities != null) {
			unldgCommodities = unldgCommodities.replace("null#", "");
			unldgCommodities = unldgCommodities.replace("##", "#");
		}

		if (unldgChg != null && unldgChg.endsWith("#")) {
			unldgChg = unldgChg.replace("# ", "");
		}

		if (unldgChg != null) {
			unldgChg = unldgChg.replace("null#", "");
			unldgChg = unldgChg.replace("##", "#");
		}

		if (unldgUnit != null && unldgUnit.endsWith("#")) {
			unldgUnit = unldgUnit.replace("# ", "");
		}

		if (unldgUnit != null) {
			unldgUnit = unldgUnit.replace("null#", "");
			unldgUnit = unldgUnit.replace("##", "#");
		}

		if (unldgSource != null && unldgSource.endsWith("#")) {
			unldgSource = unldgSource.replace("# ", "");
		}

		if (unldgSource != null) {
			unldgSource = unldgSource.replace("null#", "");
			unldgSource = unldgSource.replace("##", "#");
		}

		this.partyMasterDto.unldgCommodities = unldgCommodities;
		this.partyMasterDto.unldgChg = unldgChg;
		this.partyMasterDto.unldgUnit = unldgUnit;
		this.partyMasterDto.unldgSource = unldgSource;

		this.validateSelectedBlockMessagetemplateForSave = null;
		this.validateSelectedIsAlertPopuptemplateForSave = null;
		this.validateSelectedIsSrcBlocktemplateForSave = null;
		/* Block Message */
		if ($("#" + this.pageId + "desBlockId").val() == "true" && this.viewBlockMessageRemarks) {
			if ($("#" + this.pageId + "blockMessageRemarksId").val() != null &&
				$("#" + this.pageId + "blockMessageRemarksId").val() != undefined &&
				$("#" + this.pageId + "blockMessageRemarksId").val() != "") {
				this.validateSelectedBlockMessagetemplateForSave = $("#" + this.pageId + "blockMessageRemarksId").val();
				this.partyMasterDto.message = this.validateSelectedBlockMessagetemplateForSave;
			} else {
				this.partyMasterDto.message = null;
			}
		} else {
			this.partyMasterDto.message = null;
		}

		/* Alert Pop Up Message */
		if ($("#" + this.pageId + "popUpAlertId").val() == "true" && this.viewisPopUpAlertMessageRemarks) {
			if ($("#" + this.pageId + "isPopupAlertMessageRemarksId").val() != null &&
				$("#" + this.pageId + "isPopupAlertMessageRemarksId").val() != undefined &&
				$("#" + this.pageId + "isPopupAlertMessageRemarksId").val() != "") {
				this.validateSelectedIsAlertPopuptemplateForSave = $("#" + this.pageId + "isPopupAlertMessageRemarksId").val();
				this.partyMasterDto.alertMessage = this.validateSelectedIsAlertPopuptemplateForSave;
			} else {
				this.partyMasterDto.alertMessage = null;
			}
		} else {
			this.partyMasterDto.alertMessage = null;
		}

		/* Src Block Message */
		if ($("#" + this.pageId + "srcBlockId").val() == "true" && this.viewSrcBlockMessageRemarks) {
			if ($("#" + this.pageId + "isSrcBlockMessageRemarksId").val() != null &&
				$("#" + this.pageId + "isSrcBlockMessageRemarksId").val() != undefined &&
				$("#" + this.pageId + "isSrcBlockMessageRemarksId").val() != "") {
				this.validateSelectedIsSrcBlocktemplateForSave = $("#" + this.pageId + "isSrcBlockMessageRemarksId").val();
				this.partyMasterDto.srcAlertMessage = this.validateSelectedIsSrcBlocktemplateForSave;
			} else {
				this.partyMasterDto.srcAlertMessage = null;
			}
		} else {
			this.partyMasterDto.srcAlertMessage = null;
		}

		this.partyMasterDto.mode = "Master";
		this.partyMasterDto.userName = this.userDataDtoReturnSession.userId;
		this.partyMasterDto.office = this.userDataDtoReturnSession.office;
		this.partyMasterDto.role = this.userDataDtoReturnSession.role;
		this.partyMasterDto.status = "Completed";
		this.partyMasterDto.branch = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDto.memoTimePeriod = this.enterdedMemoTimePeriod;
		this.partyMasterDto.paymentMode = this.validateSelectedPaymentModeForSave;
		this.partyMasterDto.disableDefaultCft = this.disableCftForSave;
		this.partyMasterDto.leftToPartyEnable = this.validateSelectedLeftToPartyForSave;
		this.partyMasterDto.isPerformanceMonitor = this.isMonitorDelPerformance;
		return this.partyMasterDto;
	}

	/* Save Validation & Save Opertion Done End */

	/* Mobile Number update Button Start */

	validateMobileNumberUpdationBtn() {
		localStorage.clear();
		localStorage.setItem('ConsigneeGridViewMobileNo', JSON.stringify("ConsigneeGridViewMobileNo"));
		if (this.destView) {
			this.validateSelectedDestination = $("#" + this.pageId + "destinationCityId").val();
			localStorage.setItem('ConsigneeGridViewMobileNoLocalStorage',
				JSON.stringify(this.validateSelectedDestination));
		} else {
			localStorage.setItem('ConsigneeGridViewMobileNoLocalStorage',
				JSON.stringify(this.userDataDtoReturnSession.mainStation));
		}

		const dialogRef = this.dialog.open(ConsigneeMobileNumberUpdateDatatableComponent, {
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
			if (result == null || result == undefined || result == "Close") {
				console.log(`Dialog result: ${result}`);
			} else {
				this.mobileNUmberUpdationSustomizePrintPopupBtn(result);
				console.log(`Dialog result: ${result}`);
			}
		});

	}

	/* Mobile Number update Button End */

	/* Collection Man Add New Pop up Start Here */

	saveNewCollectionManPopUpBtn() {
		if ($("#" + this.pageId + "newCollectionManId").val() == null || $("#" + this.pageId + "newCollectionManId").val() == undefined ||
			$("#" + this.pageId + "newCollectionManId").val() == "") {
			swal("Mandatory Field", "Please enter the Collection Man Field", "warning");
		} else {
			this.addNewCollectionManDetails();
		}
	}

	addNewCollectionManDetails() {
		this.partyMasterDtoForNewCollectionman = new PartyMasterDto();
		this.validateNewCollectionMan = $("#" + this.pageId + "newCollectionManId").val();
		this.partyMasterDtoForNewCollectionman.branch = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForNewCollectionman.collectionMan = this.validateNewCollectionMan;
		this.partyMasterDtoForNewCollectionman.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction1 = true;
		this.masterreadService.addNewCollectionManDetails(this.partyMasterDtoForNewCollectionman).subscribe(
			(response) => {
				console.log(response);
				if (response.status == "success") {
					swal("Alert", "Collection Man Name Save Successfully", "success");
					this.getCollectionManDetailsList();
					this.partyMasterDtoForNewCollectionman = new PartyMasterDto();
					this.validateNewCollectionMan = null;
					$("#" + this.pageId + "newCollectionManId").val('');
					this.modalRefferenceCollectionManPemplatePopUp.close();
				} else if (response.status == "duplicate") {
					swal("Duplicate Entry", "Collection Man " + $("#" + this.pageId + "newCollectionManId").val() + "Already Exist", "warning");
				} else {
					swal("Failed", "Failed to save Collection Man Details", "warning");
				}
				this.showSpinnerForAction1 = false;
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction1 = false;
				swal("Error", "Server Error While Adding Collection Man Details", "warning");
			},
			() => console.log('done');
	}

	closeNewCollectionManPopUpBtn() {
		this.partyMasterDtoForNewCollectionman = new PartyMasterDto();
		this.validateNewCollectionMan = null;
		$("#" + this.pageId + "newCollectionManId").val('');
		this.modalRefferenceCollectionManPemplatePopUp.close();
	}

	/* Collection Man Add New Pop up Start End */

	/* Delivery Area Add New Pop up Start Here */

	saveNewDeliveryAreaPopUpBtn() {
		if ($("#" + this.pageId + "newAreaId").val() == null || $("#" + this.pageId + "newAreaId").val() == undefined ||
			$("#" + this.pageId + "newAreaId").val() == "") {
			swal("Mandatory Field", "Please enter the New Area Field", "warning");
		} else {
			this.addNewDeliveryAreaDetails();
		}
	}

	addNewDeliveryAreaDetails() {
		this.lrDtoForNewArea = new LRDto();
		this.validateNewArea = $("#" + this.pageId + "newAreaId").val();

		this.lrDtoForNewArea.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForNewArea.subStations = this.validateNewArea;
		this.lrDtoForNewArea.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction2 = true;
		this.masterreadService.addNewDeliveryAreaDetails(this.lrDtoForNewArea).subscribe(
			(response) => {
				console.log(response);
				if (response.status == "success") {
					swal("Alert", "Area Name Save Successfully", "success");
					//$("#"+this.pageId+"newAreaId").val($("#"+this.pageId+"newAreaId").val());
					this.getBranchAreaDetails();
					this.lrDtoForNewArea = new LRDto();
					this.validateNewArea = null;
					$("#" + this.pageId + "newAreaId").val('');
					this.modalRefferenceDeliveryAreaPemplatePopUp.close();
				} else if (response.status == "duplicate") {
					swal("Duplicate Entry", "Area Name " + $("#" + this.pageId + "newAreaId").val() + "Already Exist", "warning");
				} else {
					swal("Failed", "Failed to save Area Name Details", "warning");
				}
				this.showSpinnerForAction2 = false;
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction2 = false;
				swal("Error", "Server Error While Adding Area Name Details", "warning");
			},
			() => console.log('done');
	}

	closeNewDeliveryAreaPopUpBtn() {
		this.lrDtoForNewArea = new LRDto();
		this.validateNewArea = null;
		$("#" + this.pageId + "newAreaId").val('');
		this.modalRefferenceDeliveryAreaPemplatePopUp.close();
	}

	/* Delivery Area Add New Pop up Start Here */

	/* Get Each Consignee Active & Non Active Pop Up Start Details & Get Operation Start */

	//All of this 
	/*consigneeActiveNonActivePopUpBtnLink(consigneeStatusDetailsData) {
		console.log(consigneeStatusDetailsData);
		localStorage.clear();
		localStorage.setItem('ConsigneeStatusGrid', JSON.stringify("ConsigneeStatusGrid"));
		this.validateSelectedDestination = $("#"+this.pageId+"destinationCityId").val();
		localStorage.setItem('ConsigneeStatusGridLocalStorage',
			JSON.stringify(this.validateSelectedDestination));
		localStorage.setItem('ConsigneeStatusGridLocalStorage1',
			JSON.stringify(consigneeStatusDetailsData));


		const dialogRef = this.dialog.open(ConsigneeActiveNonActiveDetailsComponent, {
		});

		dialogRef.afterClosed().subscribe(result => {
			localStorage.clear();
			console.log(`Dialog result: ${result}`);
		});
	}*/

	//active nonactive consignee
	totalconsigneeActiveNonActivePopUpBtnLink(consigneeStatusDetailsData) {
		console.log(consigneeStatusDetailsData);
		localStorage.clear();
		localStorage.setItem('ConsigneeStatusGrid', JSON.stringify("ConsigneeStatusGrid"));
		this.validateSelectedDestination = $("#" + this.pageId + "destinationCityId").val();
		localStorage.setItem('ConsigneeStatusGridLocalStorage',
			JSON.stringify(this.validateSelectedDestination));
		localStorage.setItem('ConsigneeStatusGridLocalStorage1',
			JSON.stringify(consigneeStatusDetailsData));
		localStorage.setItem('ConsigneeStatusSourceLocalStorage',
			JSON.stringify("All"));

		const dialogRef = this.dialog.open(ConsigneeActiveNonActiveDetailsComponent, {
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
			localStorage.clear();
		});
	}

	//active consignee
	consigneeActivePopUpBtnLink(consigneeStatusDetailsData) {
		console.log(consigneeStatusDetailsData);
		localStorage.clear();
		localStorage.setItem('ConsigneeStatusGrid', JSON.stringify("ConsigneeStatusGrid"));
		this.validateSelectedDestination = $("#" + this.pageId + "destinationCityId").val();
		localStorage.setItem('ConsigneeStatusGridLocalStorage',
			JSON.stringify(this.validateSelectedDestination));
		localStorage.setItem('ConsigneeStatusGridLocalStorage1',
			JSON.stringify(consigneeStatusDetailsData));
		localStorage.setItem('ConsigneeStatusSourceLocalStorage',
			JSON.stringify("Active"));


		const dialogRef = this.dialog.open(ConsigneeActiveNonActiveDetailsComponent, {
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
			localStorage.clear();
		});
	}

	//non active consignee

	consigneenonActivePopUpBtnLink(consigneeStatusDetailsData) {
		console.log(consigneeStatusDetailsData);
		localStorage.clear();
		localStorage.setItem('ConsigneeStatusGrid', JSON.stringify("ConsigneeStatusGrid"));
		this.validateSelectedDestination = $("#" + this.pageId + "destinationCityId").val();
		localStorage.setItem('ConsigneeStatusGridLocalStorage',
			JSON.stringify(this.validateSelectedDestination));
		localStorage.setItem('ConsigneeStatusGridLocalStorage1',
			JSON.stringify(consigneeStatusDetailsData));
		localStorage.setItem('ConsigneeStatusSourceLocalStorage',
			JSON.stringify("NonActive"));

		const dialogRef = this.dialog.open(ConsigneeActiveNonActiveDetailsComponent, {
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
			localStorage.clear();
		});
	}

	/* Get Each Consignee Active & Non Active Pop Up Start Details & Get Operation End */

	/* Consignee Master Print */

	customizePrintBtn() {
		if (this.consigneeDetailsDataList.length == 0) {
			swal({
				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Party Name", "GST Number", "Address",
				"Area", "Mobile Number", "Contact Person", "Collection Man", "E-Mail-ID"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [15, 15, 20, 10, 10, 10, 10, 10];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
			for (let i = 0; i < this.consigneeDetailsDataList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [
					this.consigneeDetailsDataList[i].consigneeName,
					this.consigneeDetailsDataList[i].gstNoConsignee,
					this.consigneeDetailsDataList[i].address,
					this.consigneeDetailsDataList[i].area,
					this.consigneeDetailsDataList[i].mobileNumber,
					this.consigneeDetailsDataList[i].contactPerson,
					this.consigneeDetailsDataList[i].collectionMan,
					this.consigneeDetailsDataList[i].email
				];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				//this.summary1 = this.summary1 + this.consigneeDetailsDataList[i].discountAmount;
				//this.summary2 = this.summary2 + this.consigneeDetailsDataList[i].enteredLessAmt;
				//this.summary3 = this.summary3 + this.consigneeDetailsDataList[i].diffValue;
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = [
				"Total : " + this.consigneeDetailsDataList.length,
				"", "", "", "", "", "", ""];
			//heading logics For Date
			//this.fromDatePrint = $("#"+this.pageId+"fromDates").val();
			//this.toDatePrint = $("#"+this.pageId+"toDates").val();
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Consignee Master Details");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}
	/* Consignee Master Print */

	/* Mobile Number Update Print */

	mobileNUmberUpdationSustomizePrintPopupBtn(consigneeMobileNumberUpdationDetailsDataList) {
		if (consigneeMobileNumberUpdationDetailsDataList.length == 0) {
			swal({
				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Party Name", "GST Number", "Contact Person",
				"Mobile Number", "Office Number", "Email Id", "Address", "Collection Man",
				"Commodity"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [14, 12, 10, 10, 10, 10, 10, 10, 14];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
			for (let i = 0; i < consigneeMobileNumberUpdationDetailsDataList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [
					consigneeMobileNumberUpdationDetailsDataList[i].consigneeName,
					consigneeMobileNumberUpdationDetailsDataList[i].gstNoConsignee,
					consigneeMobileNumberUpdationDetailsDataList[i].contactPerson,
					consigneeMobileNumberUpdationDetailsDataList[i].mobileNumber,
					consigneeMobileNumberUpdationDetailsDataList[i].officeNumber,
					consigneeMobileNumberUpdationDetailsDataList[i].email,
					consigneeMobileNumberUpdationDetailsDataList[i].address,
					consigneeMobileNumberUpdationDetailsDataList[i].collectionMan,
					consigneeMobileNumberUpdationDetailsDataList[i].multipleCommodity
				];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				//this.summary1 = this.summary1 + this.consigneeDetailsDataList[i].discountAmount;
				//this.summary2 = this.summary2 + this.consigneeDetailsDataList[i].enteredLessAmt;
				//this.summary3 = this.summary3 + this.consigneeDetailsDataList[i].diffValue;
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = [
				"Total : " + consigneeMobileNumberUpdationDetailsDataList.length,
				"", "", "", "", "", "", "", ""];
			//heading logics For Date
			//this.fromDatePrint = $("#"+this.pageId+"fromDates").val();
			//this.toDatePrint = $("#"+this.pageId+"toDates").val();
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "In Complete Consignees Details Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

	/* Mobile Number Update Print */

	/* AlertMessage satrt*/

	desBlockMethod(desBlockId: string) {
		if (desBlockId === 'true') {
			this.viewBlockMessageRemarks = true;
		} else {
			this.viewBlockMessageRemarks = false;
		}
	}

	isPopUpAlertMethod(popUpAlertId: string) {
		if (popUpAlertId === 'true') {
			this.viewisPopUpAlertMessageRemarks = true;
		} else {
			this.viewisPopUpAlertMessageRemarks = false;
		}
	}

	srcBlockMethod(srcBlockId: string) {
		if (srcBlockId === 'true') {
			this.viewSrcBlockMessageRemarks = true;
		} else {
			this.viewSrcBlockMessageRemarks = false;
		}
	}


	/* Src Block Message Start */

	/*srcBlockMethod(srcBlockId: string, srcBlockMessagePopUpTemplate) {
		if (srcBlockId === 'true') {
			this.validateSelectedSrcBlockMessage = null;
			this.validateSelectedSrcBlockMessage = null;
			this.modalRefferenceMessageSrcBlockTemplatePopUp = this.modalService.open(srcBlockMessagePopUpTemplate,
				{ centered: true, size: "sm" });
			this.modalRefferenceMessageSrcBlockTemplatePopUp.result.then((result) => {
				this.modalRefferenceMessageSrcBlockTemplateClosePopUp = `Closed with: ${result}`;
			}, reason => {
				this.modalRefferenceMessageSrcBlockTemplateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
			});
			
		} else {
			console.log("Src Block Message False");
		}
	}

	setMessageOfSrcBlockPopUpBtn() {
		this.modalRefferenceMessageSrcBlockTemplatePopUp.close();
		console.log($("#"+this.pageId+"srcBlockMessageId").val());
	}
*/
	/* Src Block Message End */

	/*desBlockMethod(desBlockId: string) {
		if (desBlockId === 'true') {
			this.clickMessageOfBlockRRemarks();
		} else {
			console.log("Block Message False");
		}
	}

	clickMessageOfBlockRRemarks() {
		localStorage.clear();
		localStorage.setItem('ConsigneeBlockMessage', JSON.stringify("ConsigneeBlockMessage"));
		localStorage.setItem('ConsigneeBlockMessageData', JSON.stringify(this.validateSelectedBlockMessage));
		const dialogRef = this.dialog.open(ConsigneeEnterMessageAlertComponent, {
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(result);
			console.log(`Dialog result: ${result}`);
		});
	}*/

	/* AlertMessage end */

	disableSettingsEdit() {
		setTimeout(() => {
			$("#" + this.pageId + "modeAllowedId").prop('disabled', true);
			$("#" + this.pageId + "desBlockId").prop('disabled', true);
			$("#" + this.pageId + "sendRemainderSmsId").prop('disabled', true);
			$("#" + this.pageId + "sendStockSmsId").prop('disabled', true);
			$("#" + this.pageId + "popUpAlertId").prop('disabled', true);
			$("#" + this.pageId + "srcBlockId").prop('disabled', true);
			$("#" + this.pageId + "demurageExmp").prop('disabled', true);

			$("#" + this.pageId + "source").prop('disabled', true);
			$("#" + this.pageId + "unldnChg").prop('disabled', true);
			$("#" + this.pageId + "unitId").prop('disabled', true);
		}, 1000);
	}

	enableSettingsEdit() {
		setTimeout(() => {
			$("#" + this.pageId + "modeAllowedId").prop('disabled', false);
			$("#" + this.pageId + "desBlockId").prop('disabled', false);
			$("#" + this.pageId + "sendRemainderSmsId").prop('disabled', false);
			$("#" + this.pageId + "sendStockSmsId").prop('disabled', false);
			$("#" + this.pageId + "popUpAlertId").prop('disabled', false);
			$("#" + this.pageId + "srcBlockId").prop('disabled', false);
			$("#" + this.pageId + "demurageExmp").prop('disabled', false);

			$("#" + this.pageId + "source").prop('disabled', false);
			$("#" + this.pageId + "unldnChg").prop('disabled', false);
			$("#" + this.pageId + "unitId").prop('disabled', false);
		}, 1000);
	}

	rowSelectedConsigneeIndex(e: NgbTypeaheadSelectItemEvent) {
		this.modelConsigneeIndex = e.item;
		this.selectedConsigneeIndex = this.modelConsigneeIndex;
		//CHG-V-CNM:01
		this.gridReconfigure(this.selectedConsigneeIndex, false, null);
	}

	/* Clear Click Btn ClearField Start */
	validateClearBtn() {
		this.clearFieldAll();
		this.selectedConsigneeIndex = null;
		this.modelConsigneeIndex = null;
		$("#" + this.pageId + "consigneeIndex").val('');
	}

	clearFieldAll() {
		$("input[type=text], textarea").val("");
		$("input[type=number]").val("");
		$("#" + this.pageId + "modeAllowedId").val('false');
		$("#" + this.pageId + "desBlockId").val('false');
		$("#" + this.pageId + "sendRemainderSmsId").val('true');
		$("#" + this.pageId + "sendStockSmsId").val('true');
		$("#" + this.pageId + "popUpAlertId").val('false');
		$("#" + this.pageId + "popUpAlertId").val('false');
		$("#" + this.pageId + "srcBlockId").val('false');
		$("#" + this.pageId + "demurageExmp").val('false');
		$("#" + this.pageId + "deliveryType").val('select');
		$("#" + this.pageId + "paymentMode").val('Cash');
		$("#" + this.pageId + "leftToPartyAllow").val('No');

		this.modelDestinationCity = null;
		this.modelStateNameAndCode = null;
		this.modelCollectionMan = null;
		this.modelArea = null;

		this.viewArea = false;

		this.clearCommodityDetailsTable();
		this.clearConsigneeCommodityDetailsTable();

		this.consigneeCommodityDataList = [];
		$("#" + this.pageId + "consigneeCommodityTableId").DataTable().destroy();
		this.dtTriggerConsigneeCommodity.next();

		this.commodityDataList = [];
		$("#" + this.pageId + "unldgCommodityTableId").DataTable().destroy();
		this.dtTriggerCommodity.next();

		//CHG-V-CNM:05
		if (this.rightsForViewSaveBtn) {
			this.viewSaveBtn = true; // need to false at clear
		}

		this.viewUpdateBtn = false; // need to false at clear
		$("#" + this.pageId + "consigneeNameId").prop('disabled', false);

		/* On Load Datatable Work Start*/
		this.partyMasterDtoForGridRecofig = new PartyMasterDto();

		/* On Load Datatable Work End*/

		/* Consignee Status Details Datatable Work Start */
		this.lrDtoForConsigneeStatus = new LRDto();
		/* Consignee Status Details Datatable Work End */

		/* Consignee Details Datatable Edit Work START */
		this.partyMasterDtoForGridRecofigEdit = new PartyMasterDto();
		this.newAttributeSetConsigneeCommoditySet = {};
		this.editConsigneeCommodityTableId = null;
		this.newAttributeSetUnldgCommoditySet = {};
		this.editUnldgCommodityTableId = null;
		this.viewAddMobileNo = 'Add New';
		this.consigneeMobileNoList = null;
		this.returnListOfMobileNumber = null;
		/* Consignee Details Datatable Edit Work End */

		/* Consignee Details Save Operation Start*/
		this.partyMasterDto = new PartyMasterDto();
		this.partyMasterDtoForSave = new PartyMasterDto();
		this.validateSelectedCityDestForSave = null;
		this.validateSelectedConsigneeNameForSave = null;
		this.validateSelectedContactPersonForSave = null;
		this.validateSelectedMobileNumberForSave = null;
		this.validateSelectedStateNameForSave = null;
		this.validateSelectedGstNoForSave = null;
		this.validateSelectedOfficeNoForSave = null;
		this.validateSelectedEDeclPwdForSave = null;
		this.validateSelectedEmailidForSave = null;
		this.validateSelectedAddressForSave = null;
		this.validateSelectedPinCodeForSave = null;
		this.validateSelectedConsigneeIdForSave = null;
		this.validateSelectedStateCodeForSave = null;

		this.validateSelectedDeliveryTypeForSave = null;
		this.validateSelectedCollectionManForSave = null;
		this.validateSelectedPartyAreaForSave = null;
		this.validateSelectedAreaForSave = null;

		this.validateSelectedModeForSave = null;
		this.validateSelectedDesBlockForSave = null;
		this.validateSelectedSendRMDSMSForSave = null;
		this.validateSelectedSendStockSMSForSave = null;
		this.validateSelectedPopUpAlertForSave = null;
		this.validateSelectedSRCBlockForSave = null;
		this.enterdedMemoTimePeriod = null;
		this.validateSelectedBlockMessagetemplateForSave = null;
		this.validateSelectedIsAlertPopuptemplateForSave = null;
		this.validateSelectedIsSrcBlocktemplateForSave = null;
		/* Consignee Details Save Operation End*/

		this.validateSelectedDestination = null;
		this.validateSelectedConsigneeId = null;
		this.validateSelectedStateCode = null;

		this.viewBlockMessageRemarks = false;
		this.viewisPopUpAlertMessageRemarks = false;
		this.viewSrcBlockMessageRemarks = false;
		this.blockMessageRemarksId = null;
		this.srcBlockMessageRemarksId = null;
		this.validateSelectedPaymentModeForSave = null;
		this.disableCftForSave = null;
		$("#" + this.pageId + "checkboxCft").prop('checked', false);
		this.validateSelectedLeftToPartyForSave = null;
		this.isMonitorDelPerformance = false;
		this.viewMonitorPerfToggle = false;
		this.validateSubAreaForSave = null;
		//CHG-V-CNM:06
		// if (this.rightsForOnlyUpdateBtn) {
		// 	this.viewUpdateBtn = true; // need to false at clear
		// }
	}
	/* Clear Click Btn ClearField End*/


	cftSetupForConsignee(event) {
		if (this.validateSelectedConsigneeId == null || this.validateSelectedConsigneeId == 0) {
			swal("Not Allowed", "Please select Consignee!", "warning");
		} else {
			console.log('cftSetupForConsignee');
			if (event.target.checked) {
				console.log('cftSetupForConsignee - true');
				localStorage.clear();
				localStorage.setItem('screenname', "destination");

				this.validateSelectedDestination = $("#" + this.pageId + "destinationCityId").val();
				if (this.validateSelectedDestination == null || this.validateSelectedDestination == undefined ||
					this.validateSelectedDestination == "") {
					localStorage.setItem('cftbranch', this.userDataDtoReturnSession.mainStation);
				} else {
					localStorage.setItem('cftbranch', this.validateSelectedDestination);
					localStorage.setItem('cftconsigneeid', this.validateSelectedConsigneeId);
				}
				const dialogRef = this.dialog.open(CflFormulaSetupSpecificComponent, {
				});

				dialogRef.afterClosed().subscribe(result => {
					console.log(`Dialog result: ${result}`);
					if (result == null || result == undefined || result == "Close") {
						console.log(`Dialog result: ${result}`);
					} else {
						this.mobileNUmberUpdationSustomizePrintPopupBtn(result);
						console.log(`Dialog result: ${result}`);
					}
				});
			} else {
				console.log('cftSetupForConsignee - false');
			}
		}
	}
	//CHG-V-CNM:01
	getDestBlockedConsigneeDetails() {
		this.gridReconfigure(this.selectedConsigneeIndex, true, null);
	}

	//CHG-V-CNM:03
	validateForSearchByGst(event) {
		if (event.charCode == 13) {
			this.validateSearchByGst = $("#" + this.pageId + "searchByGstId").val();
			if (this.validateSearchByGst == null || this.validateSearchByGst == undefined || this.validateSearchByGst == '') {
				swal({
					title: "Mandatory Fields",
					text: "Please Enter Gst Number!",
					icon: "warning",
					closeOnClickOutside: true,
					closeOnEsc: true,
				})
			} else {
				this.gridReconfigure(null, false, 'gstSearch');
			}
		}
	}
	onMonitorDelPerfToggleChange(event) {
		console.log(event);
		this.isMonitorDelPerformance = event.checked;
		console.log(this.isMonitorDelPerformance);
		if (this.isMonitorDelPerformance) {
			swal({
				title: "Confirm Update",
				text: "Sure you want to put this consignee to monitor",
				icon: "info",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((yesBtn) => {
				if (yesBtn) {
					this.updatePartyMonitorPerformance(event.checked);
				} else {
					swal.close();
				}

			})
		}
	}

	updatePartyMonitorPerformance(checked) {
		this.validateSelectedConsigneeNameForSave = $("#" + this.pageId + "consigneeNameId").val();
		this.partyMasterDto = new PartyMasterDto();

		console.log(this.validateSelectedConsigneeId);
		if (this.validateSelectedConsigneeId != null && this.validateSelectedConsigneeId != undefined &&
			this.validateSelectedConsigneeId != "" && this.validateSelectedConsigneeId != 0) {

			this.validateSelectedConsigneeIdForSave = this.validateSelectedConsigneeId;
			console.log("if");
		} else {
			console.log("else");
			this.validateSelectedConsigneeIdForSave = 0;
		}
		this.partyMasterDto.isPerformanceMonitor = checked;
		this.partyMasterDto.consigneeId = this.validateSelectedConsigneeIdForSave;

		this.partyMasterDto.userName = this.userDataDtoReturnSession.userId;
		this.masterService.updateConsigneePerfMonitor(this.partyMasterDto).subscribe(
			(response) => {
				console.log(response);
				this.showSpinnerForAction = false;
				if (response.status == "success") {
					swal("Success", "Consignor Details Save Successfully", "success");

				} else {
					swal("Failed", "Failed to save consignor details", "warning");
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Adding Consignor Details", "warning");
			},
			() => console.log('done');
	}

}
