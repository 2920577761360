<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-5">
                    <div *ngIf="showSpinnerForAction" class="col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center'
                                    style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-home"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control"
                                                            id="{{pageId}}mainBookingSourceId"
                                                            [(ngModel)]="modelMainBookingSource"
                                                            [ngbTypeahead]="searchMainBookingSource"
                                                            [resultFormatter]="formatterMainBookingSource"
                                                            [inputFormatter]="formatterMainBookingSource"
                                                            (focus)="focusMainBookingSourceTA$.next($any($event).target.value)"
                                                            placeholder="Select Main Source.." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>

                                                        <input id="{{pageId}}destination" type="text"
                                                            class="form-control" [(ngModel)]="modelDestination"
                                                            [ngbTypeahead]="searchDestination"
                                                            [resultFormatter]="formatterDestination"
                                                            [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Party Type &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}partyType"
                                                        name="partyType" #partyType>
                                                        <option value="Consignee">Consignee</option>
                                                        <option value="Consignor">Consignor</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Block Payment Type &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}blockPymtType"
                                                        name="blockPymtType" #blockPymtType>
                                                        <option value="Both">Both</option>
                                                        <option value="toPay">To Pay</option>
                                                        <option value="paid">Paid</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Gst Number &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-hashtag"></i>
                                                            </span>
                                                        </div>
                                                        <input #gstNumber type="text" class="form-control"
                                                            aria-describedby="basic-addon11" id="{{pageId}}gstNumber"
                                                            name="gstNumber"
                                                            (keypress)="validateForSearchByGst($event)">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        &nbsp;
                                        <div class="col-md-12 ">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="box-body">
                                                        <table datatable id="{{pageId}}partywiseDetailsTable"
                                                            class="table table-striped table-bordered row-border hover"
                                                            [dtOptions]="dtOptionsPartywiseDetails"
                                                            [dtTrigger]="dtTriggerPartywiseDetails">
                                                            <thead>
                                                                <tr>
                                                                    <th>Party Name</th>
                                                                    <th>Address</th>
                                                                    <th>City</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let showPartywiseDetails of showPartywiseDetailsForLrBlock">
                                                                    <td>{{ showPartywiseDetails.partyName }}</td>
                                                                    <td>{{ showPartywiseDetails.address }}</td>
                                                                    <td>{{ showPartywiseDetails.city }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    &nbsp;
                                    <div class="col-sm-12 col-md-12">
                                        <div class="col-md-12" style="text-align: center;">
                                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn"
                                                #saveBtn (click)="validateLrBlockPartywiseDetailsMethod()">Block Booking</button>
                                            <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn"
                                                (click)="clearFields()">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-7 vl">
                    <div class="row">
                        <div class="col-md-12 vl">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title">LR Blocked Partywise Details</h6>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}lrBlockedPartywiseDetailsTable"
                                            class="table table-striped table-bordered row-border hover"
                                            [dtOptions]="dtOptionsLrBlockedPartywiseDetails"
                                            [dtTrigger]="dtTriggerLrBlockedPartywiseDetails">
                                            <thead>
                                                <tr>
                                                    <th>Source</th>
                                                    <th>Destination</th>
                                                    <th>Party Type</th>
                                                    <th>Block Pymt Type</th>
                                                    <th>Gst No</th>
                                                    <th>Edit/Unblock</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let lrBlockedPartywiseDetail of lrBlockedPartywiseDetailLists; let i = index">
                                                    <td>{{ lrBlockedPartywiseDetail.source }}</td>
                                                    <td>{{ lrBlockedPartywiseDetail.destination }}</td>
                                                    <td>{{ lrBlockedPartywiseDetail.partyType }}</td>
                                                    <td>{{ lrBlockedPartywiseDetail.paymentMode }}</td>
                                                    <td>{{ lrBlockedPartywiseDetail.gstNumber }}</td>
                                                    <td><button style="padding: 1px 4px; background-color: #ffffff00;"
                                                            class="btn m-r-10" id="{{pageId}}editBtn"
                                                            (click)="rowSelectedView(lrBlockedPartywiseDetail);">
                                                            <i title="View" class="fas fa-edit"></i>
                                                        </button>&nbsp;<button
                                                            style="padding: 1px 4px; background-color: #ffffff00;"
                                                            class="btn m-r-10" id="{{pageId}}unblockBtn"
                                                            (click)="setUnblockLrPartywiseDetails(lrBlockedPartywiseDetail)">
                                                            <i title="View" class="fas fa-unlock"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>