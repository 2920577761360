//Asrar_Jr_Chg_V1 : 14/05/2024 - Need excel export
//Asrar_Jr_Chg_V2 : 20/06/2024 .req Abubakar Bh when attempting to set up the CFT range, the system is displaying a message stating that the range must be greater than the from range.

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';

@Component({
    selector: 'app-cfl-formula-setup',
    templateUrl: './cfl-formula-setup.component.html',
    styleUrls: ['./cfl-formula-setup.component.css']
})
export class CflFormulaSetupComponent implements OnInit {

    lrDtoSave: LRDto = new LRDto();
    destinationDataListSave: LRDto[];
    lrDtoRet: any;
    destinationDataList: LRDto[];

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    supAdmView: any;
    lorryHireDeductionDataList: any;
    splitTimeDetails: any;
    commaSplitTime: any;
    undersCodeSplitTime: any;
    slabSetupDataList: Array<any> = [];
    slabSetupDataAllList: Array<any> = [];
    private newAttribute: any = {};
    editTableId: null;
    //for the select option with filter starts

    controlSource = new FormControl();
    controlDestination = new FormControl();

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerCflSlabsSetup: Subject<any> = new Subject();
    dtTriggerCflSlabsAllSetup: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsCflSlabsSetup: any;
    dtOptionsCflSlabsAllSetup: any;

    selectedFromStation: any;
    selectedToStation: any;

    lrDtoSearch: LRDto = new LRDto();
    lrDtoFromOptions: LRDto[];
    fromTA: Array<LRDto> = [];
    focusFromTA$ = new Subject<string>();
    searchFrom = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromTA
                : this.fromTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterFrom = (x: { mainstation: string }) => x.mainstation;

    lrDtoToOptions: LRDto[];
    toTA: Array<LRDto> = [];
    focusToTA$ = new Subject<string>();
    searchTo = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusToTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.toTA
                : this.toTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterTo = (x: { mainstation: string }) => x.mainstation;
    pageId = "lhdsc";

    // source
    agentDetailsOptionsSet: AgentDetailsDto[];
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    public agentNameModal: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    agentNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { source: string }) => x.source;

    /* For Destination Drop Down */
    destinationOptions: LRDto[];
    lrDtoToStation: LRDto = new LRDto();
    destinationDtoForAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { mainstation: string }) => x.mainstation;

    /* Commodity Drop Down */
    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

    enteredSourceForSave: any;
    enteredCommodityForSave: any;
    enteredDestinationForSave: any;
    

    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.timeSettingDetailsTable();
            this.getBranchList();
            this.getAgentDetailList();
            this.getCommodityMasterDetailsList();
            this.activeRangeDetailsData();
            
        }
    }

    ngOnInit() {
    }

    // //  service starts
    // getUserDetailsForReadTime() {
    //     this.selectedFromStation = $("#" + this.pageId + "source").val();
    //     this.selectedToStation = $("#" + this.pageId + "destination").val();

    //     this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
    //     this.hireSlipDto.fromstation = this.selectedFromStation;
    //     this.hireSlipDto.tostation = this.selectedToStation;
    // }


    // getlorryHireDeductionList = () => {
    //     //        console.log( this.hireSlipDto.fromstation );
    //     this.getUserDetailsForReadTime();
    //     this.masterReadService.getLorryHireDeductionService(this.hireSlipDto).subscribe(
    //         (response) => {
    //             if (response) {
    //                 //                    console.log( response );
    //                 this.lorryHireDeductionDataList = response;
    //                 this.hireSlipDto = this.lorryHireDeductionDataList;
    //                 this.hireSlipDto.buffTimeInMin = this.lorryHireDeductionDataList.buffTimeInMin.toFixed();
    //                 this.lorryHireDeductionSetupDataList = this.hireSlipDto.listTimeSettings;
    //                 //                  this.dtTriggerExpensesHeads.next();
    //                 //                    console.log( this.lorryHireDeductionDataList );
    //             }
    //             //                else {
    //             //                    swal( {
    //             //                        title: "Warning",
    //             //                        text: "No records found for the selected city!",
    //             //                        icon: "warning",
    //             //                        closeOnClickOutside: false,
    //             //                        closeOnEsc: false,
    //             //                    } );

    //             //                }
    //             this.changeDetectorRef.detectChanges();
    //         }), (error) => swal({
    //             title: "Error",
    //             text: "Server Error While Getting Time Details",
    //             icon: "warning",
    //             closeOnClickOutside: false,
    //             closeOnEsc: false,
    //         }),
    //         () => console.log('done');
    // };

    //    service ends


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    timeSettingDetailsTable() {
        // var exportCFTDetailsArray = [];
        // exportCFTDetailsArray = [0,1,2];

        //the first datatable starts
        this.dtOptionsCflSlabsSetup = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
        },
            this.dtOptionsCflSlabsAllSetup = {
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //Asrar_Jr_Chg_V1
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Export</i>',
                        titleAttr: 'Export',
                        title: function () {
                            return "CFT Details";
                        },
                        exportOptions: {
                            columns: [0, 1, 2]
                        }
                    }
                ],
                searching: true,
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 350,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                pagingType: 'full_numbers',
                pageLength: 9,

                //Asrar_Jr_Chg_V1
                select: true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                }
            }
    }

    ngOnDestroy(): void {
        this.dtTriggerCflSlabsSetup.unsubscribe();
        this.dtTriggerCflSlabsAllSetup.unsubscribe();
    }
    ngAfterViewInit(): void {
        // this.dtTriggerCflSlabsSetup.next();
        // this.dtTriggerCflSlabsAllSetup.next();
    }

    addInTable() {
        //Asrar_Jr_Chg_V2
        this.newAttribute.fromRange = parseFloat($("#" + this.pageId + "fromRange").val().toString());
        this.newAttribute.toRange = parseFloat($("#" + this.pageId + "toRange").val().toString());
        this.newAttribute.considerValue = $("#" + this.pageId + "considerValueField").val();
        console.log(this.newAttribute);

        if (((this.newAttribute.toRange) > (this.newAttribute.fromRange)) && (this.newAttribute.considerValue != '')) {
            if (this.editTableId != null) {
                this.slabSetupDataList.splice(this.editTableId, 1);
            }
            if (this.slabSetupDataList == null) {
                this.slabSetupDataList = [];
            }

            if (this.slabSetupDataList.length == 0) {
                this.slabSetupDataList.push(this.newAttribute);
            } else {

                let array = this.slabSetupDataList;
                this.slabSetupDataList.push(this.newAttribute);

            }
            this.dtTriggerCflSlabsSetup.next();
            this.newAttribute = {};
            $("#" + this.pageId + "fromRange").val('');
            $("#" + this.pageId + "toRange").val('');
            $("#" + this.pageId + "considerValueField").val('');
            $("#" + this.pageId + "hiddenIndex").val('');

            this.editTableId = null;
        } else {
            swal({
                title: "Warning",
                text: "To Range must been greater than From Range",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        }

    }
    rowSelectedEdit(timeSettingEntryTableData, index) {
        console.log(timeSettingEntryTableData.fromRange);
        $("#" + this.pageId + "fromRange").val(timeSettingEntryTableData.fromRange);
        $("#" + this.pageId + "toRange").val(timeSettingEntryTableData.toRange);
        $("#" + this.pageId + "considerValueField").val(timeSettingEntryTableData.considerValue);
        $("#" + this.pageId + "hiddenIndex").val(index);
        this.editTableId = index;
    }
    rowSelectedDelete(index) {
        this.slabSetupDataList.splice(index, 1);
        $("#" + this.pageId + "fromRange").val('');
        $("#" + this.pageId + "toRange").val('');
        $("#" + this.pageId + "considerValueField").val('');
        $("#" + this.pageId + "hiddenIndex").val('');
    }

    validateSetupDetails() {
        this.destinationDataListSave = [];
        this.enteredSourceForSave = $("#" + this.pageId + "agentName").val();
        this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
        this.enteredCommodityForSave = $("#" + this.pageId + "commodityListId").val();

        if (this.enteredSourceForSave == '' || this.enteredSourceForSave == null
            || this.enteredDestinationForSave == '' || this.enteredDestinationForSave == null
            || this.enteredCommodityForSave == '' || this.enteredCommodityForSave == null) {
            swal("Not Allowed", "Please select/enter all the mandatory fields to proceed!", "warning");
        } else if (this.slabSetupDataList.length == 0) {
            swal("Not Allowed", "No CFT range found to proceed, please check!", "warning");
        } else {
            swal({
                title: "Confirm Add/Update",
                text: "Sure you want to Add/Update the entered CFT range details?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yes) => {
                if (yes) {
                    this.saveSetupDetails();
                }
            });
        }
    }
    userSetupDetails() {
        for (let i = 0; i < this.slabSetupDataList.length; i++) {
            this.lrDtoSave = new LRDto();
            this.lrDtoSave.fromRange = this.slabSetupDataList[i].fromRange;
            this.lrDtoSave.toRange = this.slabSetupDataList[i].toRange;
            this.lrDtoSave.considerValue = this.slabSetupDataList[i].considerValue;
            this.lrDtoSave.source = this.enteredSourceForSave;
            this.lrDtoSave.destination = this.enteredDestinationForSave;
            this.lrDtoSave.commodityName = this.enteredCommodityForSave;
            this.lrDtoSave.userName = this.userDataDtoReturnSession.userId;
            this.destinationDataListSave.push(this.lrDtoSave);
        }
    }
    saveSetupDetails = () => {
        this.userSetupDetails();
        this.masterService.setupCflSlabsDetails(this.destinationDataListSave).
            subscribe((data) => {
                this.lrDtoRet = data;
                if (this.lrDtoRet.status == "Success") {
                    swal("Success", "CFT range details saved/updated successfully!", "info");
                    this.clearFields();
                } else {
                    swal("Failed", "Failed to save/update the CFT range details!", "error");
                }
            }), (error) => {
                swal("Error", "Server problem occurred while saving/updating the CFT range details!", "error");
            },
            () => console.log('done');
    }

    // clearAll() {
    //     $('input[type="text"],[type="number"]').val('');
    //     this.lorryHireDeductionSetupDataList = [];
    //     $("#" + this.pageId + "source").val('');
    //     $("#" + this.pageId + "destination").val('');
    //     this.hireSlipDto.fromstation = null;
    //     this.hireSlipDto.tostation = null;
    // }

    // validateDeleteRow() {
    //     this.deleteLorryHireDeductionDetails();
    // }
    getBranchList() {
        this.destinationDataList = []
        this.lrDtoSearch = new LRDto();
        this.lrDtoSearch.mode = "activeDeliveryOfc";
        this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoSearch).subscribe(
            (response) => {
                if (response) {
                    this.destinationTA = [];
                    if (response.length > 0) {
                        this.destinationDataList = response;
                        for (let i = 0; i < this.destinationDataList.length; i++) {
                            this.destinationTA.push(this.destinationDataList[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the the destination details", "info");
            },
            () => console.log('done');
    }

    clearFields() {
        this.lrDtoRet = null;
        this.destinationDataListSave = [];
        this.newAttribute = {};
        $("#" + this.pageId + "fromRange").val('');
        $("#" + this.pageId + "toRange").val('');
        $("#" + this.pageId + "considerValueField").val('');
        $("#" + this.pageId + "hiddenIndex").val('');

        $("#" + this.pageId + "agentName").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "commodityListId").val('');
        this.enteredSourceForSave = null;
        this.enteredDestinationForSave = null;
        this.enteredCommodityForSave = null;
        this.editTableId = null;
        this.slabSetupDataList = [];
        $("#" + this.pageId + "slabsTableId").DataTable().destroy();
        this.dtTriggerCflSlabsSetup.next();
        this.changeDetectorRef.detectChanges();
    }

    activeRangeDetailsData() {
        this.lrDtoSave = new LRDto();
        this.getActiveRangeDetailsData();
    }

    getActiveRangeDetailsData() {
        this.masterService.getActiveCFTSlabDetails(this.lrDtoSave).subscribe(
            (response) => {
                console.log(response);
                this.slabSetupDataAllList = [];
                $("#" + this.pageId + "slabSetupDataAllTableId").DataTable().destroy();
                this.slabSetupDataAllList = response;
                if (response.length == 0) {
                    swal("No Records", "No CFT details found!", "info");
                }
                setTimeout(() => {
                    this.dtTriggerCflSlabsAllSetup.next();
                }, 1000);
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server problem occurred while getting the CFT details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }

    rowSelectedView(slabSetupDataAll) {
        this.lrDtoSave = new LRDto();
        this.lrDtoSave.source = slabSetupDataAll.source;
        this.lrDtoSave.destination = slabSetupDataAll.destination;
        this.lrDtoSave.commodityName = slabSetupDataAll.commodityName;

        $("#" + this.pageId + "agentName").val(slabSetupDataAll.source);
        $("#" + this.pageId + "destination").val(slabSetupDataAll.destination);
        $("#" + this.pageId + "commodityListId").val(slabSetupDataAll.commodityName);
        this.getCFTSlabDetailsData();
    }

    getCFTSlabDetailsData() {
        this.masterService.getCFTSlabDetails(this.lrDtoSave).subscribe(
            (response) => {
                console.log(response);
                this.slabSetupDataList = [];
                $("#" + this.pageId + "slabsTableId").DataTable().destroy();
                this.slabSetupDataList = response;
                if (response.length == 0) {
                    swal("No Records", "No CFT details found!", "info");
                }
                setTimeout(() => {
                    this.dtTriggerCflSlabsSetup.next();
                }, 1000);
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server problem occurred while getting the CFT details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }

    getUserValuesForAgentDetailsList() {
        this.agentDtoParam = new AgentDetailsDto();
        this.agentDtoParam.mode = "MainAndGrpRateMaster";
        this.agentDtoParam.status = "Working";
        this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParam.mainStation = "ALL";
    }

    getAgentDetailList() {
        $("#" + this.pageId + "agentName").val('');
        this.enteredSourceForSave = null;
        this.getUserValuesForAgentDetailsList();
        this.masterService.getAgentsDetails(this.agentDtoParam).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                } else {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                    this.agentDetailsOptionsSet = response;
                    this.agentDtoParam = new AgentDetailsDto();
                    this.agentDtoParam.source = "ALL";
                    this.agentNameTA.push(this.agentDtoParam);
                    for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
                        this.agentNameTA.push(this.agentDetailsOptionsSet[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    getCommodityListRead() {
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "mainConsigneeMaster";
    }

    getCommodityMasterDetailsList = () => {
        this.getCommodityListRead();
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                if (response) {
                    console.log(response);
                    if (response.length == 0) {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;
                        this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
                        this.rateMasterDtoCommodityListAllOption.commodityName = "ALL";
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                        this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
                        this.rateMasterDtoCommodityListAllOption.commodityName = "Others";
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Commodity Master Details", "warning");
            },
            () => console.log('done');
    }

    clearDetails() {
        this.clearFields();
    }  

}
