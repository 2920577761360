<!-- Row -->
<div class="row" id="{{pageId}}showAutoSearch">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">LR Edit</h6>
            </div>
            <div class="row">
                <!-- spinner start-->
                <div *ngIf="showSpinnerForAction" class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <!-- spinner end-->
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-4">
                                    <div class="form-group">
                                        <!-- <label>Lr Number</label> -->
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-clipboard"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}editWithLrNumber" class="form-control" placeholder="Enter LR Number Here....." aria-describedby="basic-addon11" autocomplete="off" (keypress)="onkeyPressListnerLRNumber($event);">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-8">
                                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}getBtn" (click)="getLrDetailsValues();">Get
                                        Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr style="margin: 0px;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card" style="margin: 0px;">
                                <div class="card-body">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Destination</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-road"></i>
                                                    </span>
                                                </div>
                                                <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" (selectItem)="clickListnerForDestination($event)" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                    (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" (keypress)="onKeyPressListnerForDestination($event)" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Agent Name</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="ti-user"></i>
                                                    </span>
                                                </div>
                                                <input id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="modelAgentName" [ngbTypeahead]="searchAgentName" (selectItem)="clickListnerForAgentName($event)" [resultFormatter]="formatterAgentName" [inputFormatter]="formatterAgentName"
                                                    (focus)="focusAgentNameTA$.next($any($event).target.value)" placeholder="Select Agent Name" (keypress)="onKeyPressListnerForAgentName($event)" readonly />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Party Goods Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-box-open"></i>
                                                    </span>
                                                </div>
                                                <!-- <input id="{{pageId}}partyGoodsType" type="text" class="form-control"
													[(ngModel)]="modelPartyGoodsType"
													[ngbTypeahead]="searchPartyGoodsType"
													(selectItem)="clickListnerForPartyGoodsType($event)"
													[resultFormatter]="formatterPartyGoodsType"
													[inputFormatter]="formatterPartyGoodsType"
													(focus)="focusPartyGoodsTypeTA$.next($any($event).target.value)"
													placeholder="Select Party Goods Type"
													(keypress)="onKeyPressListnerForPartyGoodsType($event)" /> -->
                                                <!-- (keyup)="partyCommodityFocus($event)"
													(click)="clickTA(instancePartyCommodity)" -->
                                                <input #partyGoodsType id="{{pageId}}partyGoodsType" type="text" class="form-control" [ngbTypeahead]="partyCommoditySearchTA" (focus)="focusPartyCommodityTA$.next($any($event).target.value)" #instancePartyCommodity="ngbTypeahead" (change)="clickListnerForCommodity($event);"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewAddNewGoodsTypeField">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Goods Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-clipboard-list"></i>
                                                        </span>
                                                    </div>
                                                    <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                        [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Booking Date</label> <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="modelBookingDate" name="bookingDates" id="{{pageId}}bookingDates" ngbDatepicker #bookingDates="ngbDatepicker"
                                                    autocomplete="off" readonly />
                                                <div *ngIf="!blockEditBkgDate" class="input-group-append" (click)="bookingDates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Article</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-clone"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}article" autocomplete="off" (keypress)="onKeyPressListnerForArticle($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Commodity Desc.</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class="fas fa-clipboard-list"></i>
                                                    </span>
                                                </div>
                                                <input id="{{pageId}}commodityDescription" type="text" class="form-control" [(ngModel)]="modelCommodityDescription" [ngbTypeahead]="searchCommodityDescription" (selectItem)="clickListnerForCommodityDescription($event)" [resultFormatter]="formatterCommodityDescription"
                                                    [inputFormatter]="formatterCommodityDescription" (focus)="focusCommodityDescriptionTA$.next($any($event).target.value)" placeholder="Select Commodity Desc" (keypress)="onKeyPressListnerForCommodityDesc($event)"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Actual Weight </label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}actualWeight" autocomplete="off" (keypress)="onKeyPressListnerForActualWgt($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Charged Weight</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}chargedWeight" autocomplete="off" (keypress)="onKeyPressListnerForChargedWgt($event)" [readonly]="chgWgtReadOnlyEdit">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Pack Nature</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class=" fas fa-truck-loading"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}packNature" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Goods Value</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}goodsValue" autocomplete="off" (keypress)="onKeyPressListnerForGoodsValue($event)" (change)="goodsValueOnBlur($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>PVT Mark</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-paint-brush"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}pvtMarkId" autocomplete="off" (keypress)="onKeyPressListnerForPrivateMrk($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group ">
                                                <label>Article Size</label> <a style="cursor: pointer; color: blue; font-weight: bolder;" (click)="openArticeSizePopUp();">{{viewAddArticleSize}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 vl">
                            <div class="card" style="margin: 0px;">
                                <div class="card-body">
                                    <h6 class="card-title">Consignor Details</h6>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <select class="custom-select col-12" id="{{pageId}}selectType" name="selectType" #selectType (change)="selectedType(selectType.value)">
                                                    <option selected value="gst">GST Number</option>
                                                    <option value="pan">PAN Card</option>   
                                                    <option value="aadhar">Aadhar Card</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}consignorGstNumber" (keypress)="onkeyPressListnerGSTConsignor($event, openPopupForAadhar);" autocomplete="off" [placeholder]="consignorTextField">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input id="{{pageId}}consignorName" type="text" class="form-control" [(ngModel)]="modelConsignorName" [ngbTypeahead]="searchConsignorName" (selectItem)="clickListnerForConsignorName($event,content9)" [resultFormatter]="formatterConsignorName" [inputFormatter]="formatterConsignorName"
                                                    (focus)="focusConsignorNameTA$.next($any($event).target.value)" placeholder="Select Consignor Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <h6 class="card-title">Consignee Details</h6>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
                                                    <option selected value="gst">GST Number</option>
                                                    <option value="pan">PAN Card</option>   
                                                    <option value="aadhar">Aadhar Card</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}consigneeGstNumber" (keypress)="onkeyPressListnerGSTConsignee($event, openPopupForAadhar);" autocomplete="off" [placeholder]="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input id="{{pageId}}consigneeName" type="text" class="form-control" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="searchConsigneeName" (selectItem)="clickListnerForConsigneeName($event,content10)" [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName"
                                                    (focus)="focusConsigneeNameTA$.next($any($event).target.value)" placeholder="Select Consignee Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}consigneeMobileNo" autocomplete="off" placeholder="Consignee Mobile No" readonly>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group" id="{{pageId}}popupDetails">
                                                <ng-template #openPopupForAadhar let-c="close" let-d="dismiss">
                                                    <div class="modal-header">
                                                        <h6 class="modal-title" id="{{pageId}}modal-basic-title">Please enter the Aadhar OTP
                                                        </h6>
                                                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="col-sm-12 col-md-8">
                                                                    <div class="form-group">
                                                                        <div class="input-group">
                                                                            <label>OTP :</label>
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text">
                                                                                    <i
                                                                                        class=" fas fa-key"></i>
                                                                                </span>
                                                                            </div>
                                                                            <input class="form-control col-12" #otpId id="{{pageId}}otpId" type="text" placeholder="Enter OTP Number" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div #consignorEInvoiceSaveBtn class="col-sm-12 col-md-12">
                                                                    <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}aadharProceedBtnId" (click)="getDetailsFromAadharApiMethod()">Proceed</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>

                                    <hr>
                                    <h6 class="card-title">Freight Details</h6>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Base Freight</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}baseFreight" autocomplete="off" (keypress)="onKeyPressListnerForBaseFreight($event)" (change)="baseFreightOnBlur($event)" [readonly]="isFrieghtFieldsReadOnly">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Hamali</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}hamaliId" autocomplete="off" (keypress)="onKeyPressListnerForHamali($event)" (change)="hamaliChgOnBlur($event)" [readonly]="isFrieghtFieldsReadOnly">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>GC.Charges</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}gcCharges" autocomplete="off" (keypress)="onKeyPressListnerForGCCharge($event)" (change)="gcChgOnBlur($event)" [readonly]="isFrieghtFieldsReadOnly">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>LC</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}lcChgId" autocomplete="off" (keypress)="onKeyPressListnerForLCCharge($event)" (change)="lcChgOnBlur($event)" [readonly]="isFrieghtFieldsReadOnly">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>BC</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}bcChgId" autocomplete="off" (keypress)="onKeyPressListnerForBCCharge($event)" (change)="bcChgOnBlur($event)" [readonly]="isFrieghtFieldsReadOnly">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>F.O.V</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}fovChgId" autocomplete="off" readonly (change)="fovChgOnBlur($event)">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6 vl">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>A.O.C</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <input type="number" class="form-control" aria-describedby=" basic-addon11" id="{{pageId}}aocChgId" autocomplete="off" (keypress)="onKeyPressListnerForAOCCharge($event)" (change)="aocChgOnBlur($event)" [readonly]="isFrieghtFieldsReadOnly">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 ">
                                            <div class="form-group ">
                                                <div class="input-group ">
                                                    <label>Others</label>
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text "> <i
                                                                class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <input type="number" class="form-control " aria-describedby="basic-addon11" id="{{pageId}}othersChgId" autocomplete="off" (keypress)="onKeyPressListnerForOthers($event)" (change)="othersChgOnBlur($event)" [readonly]="isFrieghtFieldsReadOnly">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 ">
                                            <div class="form-group ">
                                                <div class="input-group ">
                                                    <label>Door Delivery</label>
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text "> <i class=" fas fa-truck "></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}doorDeliveryMode" name="doorDeliveryMode" #doorDeliveryMode (change)="doorDeliMode(doorDeliveryMode.value)">
                                                        <option selected value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="doorDeliveryYes" class="col-sm-12 col-md-12">
                                            <div class="form-group ">
                                                <div class="input-group ">
                                                    <label>DD Address</label>
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text "> <i
                                                                class=" fas fa-hashtag "></i>
                                                        </span>
                                                    </div>
                                                    <textarea class="form-control " rows="1 " id="{{pageId}}doorDeliveryAddress"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="doorDeliveryYes" class="col-sm-12 col-md-12 ">
                                            <div class="form-group ">
                                                <div class="input-group ">
                                                    <label>DD</label>
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text "> <i class="fas fa-hashtag "></i>
                                                        </span>
                                                    </div>
                                                    <input type="number" class="form-control " aria-describedby="basic-addon11 " id="{{pageId}}doorDeliveryAmount" autocomplete="off" (keypress)="onKeyPressListnerForDoorDeliveryAmount($event)" (change)="doorDeliveryChgOnBlur($event)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 ">
                                            <div class="form-group ">
                                                <div class="input-group ">
                                                    <label>Total Amount</label>
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text "> <i
                                                                class="fas fa-rupee-sign "></i>
                                                        </span>
                                                    </div>
                                                    <input type="number " class="form-control " aria-describedby="basic-addon11" id="{{pageId}}totalAmount" autocomplete="off" readonly>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 ">
                                            <div class="form-group ">
                                                <div class="input-group ">
                                                    <label>Bill No.</label>
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text "> <i class="fas fa-hashtag "></i>
                                                        </span>
                                                    </div>
                                                    <input type="text " class="form-control " aria-describedby="basic-addon11 " id="{{pageId}}billNoId" autocomplete="off" (keypress)="onKeyPressListnerForBillNo($event)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Bill Date</label> <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="modelBillDate" name="billDate" id="{{pageId}}billDate" ngbDatepicker #billDate="ngbDatepicker" autocomplete="off"
                                                        (keypress)="onKeyPressListnerForBillDate($event)" />
                                                    <div class="input-group-append" (click)="billDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Payment Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}paymentType" name="paymentType" #paymentType (change)="paymentTypeMode(paymentType.value)">
                                                        <option selected value="toPay">To Pay</option>
                                                        <option value="paid">Paid</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="paymentTypePaid" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label> Ser Tax Paying By </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}serTaxPayingBy">
                                                        <option value="1" selected>Consignor</option>
                                                        <option value="2">GTA</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Bank LR</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-university"></i>
                                                        </span>
                                                    </div>
                                                    <select id="{{pageId}}bankLrId" class="custom-select col-12">
                                                        <option selected value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>E-Sugam No</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}eSugamNo" autocomplete="off" (keypress)="onKeyPressListnerForESugamNo($event)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>E-Way Bill No</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}eWayBillNo" autocomplete="off" (keypress)="onKeyPressListnerForEWayBillNo($event)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>E-Way BillValidUpto</label> <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="modelEwayBillValidUpto" name="ewayBillValidUpto" id="{{pageId}}ewayBillValidUpto" ngbDatepicker #ewayBillValidUpto="ngbDatepicker"
                                                        autocomplete="off" (keypress)="onKeyPressListnerForEWayBillValidateUpto($event)" readonly />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group ">
                                                    <a style="cursor: pointer; color: blue; font-weight: bolder;" (click)="eWayBillValidUptoDateFocus(multipleEwayBillPopupOpen)">{{viewAddMultipleEwaybill}}</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}popupDetails">
                                                    <ng-template #multipleEwayBillPopupOpen let-c="close" let-d="dismiss">
                                                        <div class="modal-header">
                                                            <h6 class="modal-title" id="{{pageId}}modal-basic-title">
                                                                Multiple E-way Details</h6>
                                                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-sm-12 col-md-4">
                                                                            <div class="form-group">
                                                                                <div class="input-group" id="{{pageId}}eWallBillLbl">
                                                                                    <label class="wtfull">E-Way
                                                                                        Bill No.</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-4">
                                                                            <div class="form-group">
                                                                                <div class="input-group" id="{{pageId}}eWayDateLbl">
                                                                                    <label class="wtfull">E-Way
                                                                                        Bill Valid
                                                                                        Upto</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-sm-12 col-md-4">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <input type="number" aria-describedby=" basic-addon11 " autocomplete="off" title="Must Be 12 Number" class="form-control" id="{{pageId}}eWayBillNoMulti" (keyup)="eWayBillNoMultiFocus($event)" oninput="javascript: if(this.value.length > this.maxLength) 
                                                                                    this.value=value.slice(0,this.maxLength);" maxLength="12" #eWayBillNoMulti>&nbsp;
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-4">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <input class="form-control" id="{{pageId}}eWayBillValidUptoDateMulti" name="eWayBillValidUptoDateMulti" (keyup)="eWayBillValidUptoDateFocusMulti($event)" ngbDatepicker [(ngModel)]="eWayBillValidUptoDateMultiModel" placeholder="dd-mm-yyyy" #eWayBillValidUptoDateMulti="ngbDatepicker"
                                                                                        (keydown.arrowdown)="eWayBillValidUptoDateMulti.toggle();" (click)="eWayBillValidUptoDateMulti.toggle();" disabled>&nbsp;
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-sm-12 col-md-1">
                                                                            <div class="form-group">
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <a href="javascript:;" type='button' id="{{pageId}}addInTableMultiEwayBtn" #addInTableMultiEwayBtn class="btn btn-icon-only yellow" (click)="chkDuplicateMultiEwayBillNo();">
                                                                                            <i class="fa fa-plus"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="box-body">
                                                                                <table datatable id="{{pageId}}multipleEwayPopupTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsMultiEway" [dtTrigger]="dtTriggerMultEway">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>E-way Bill No
                                                                                            </th>
                                                                                            <th>E-Way Bill Valid Upto
                                                                                            </th>
                                                                                            <th>Action</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr *ngFor="let multiEwayTableData of multiEwayTableDataList let i = index">
                                                                                            <td>{{ multiEwayTableData.eWayBillNo }}
                                                                                            </td>
                                                                                            <td>{{ multiEwayTableData.ewayBillValidUpto }}
                                                                                            </td>
                                                                                            <td>
                                                                                                <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEditMultiEway(multiEwayTableData,i);">
                                                                                                    <i title="Edit"
                                                                                                        class="fas fa-pencil-alt"></i>
                                                                                                </button>&nbsp;
                                                                                                <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteMultiEway(multiEwayTableData,i)">
                                                                                                    <i title="Remove"
                                                                                                        class="fas fa-trash"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveMultiArtBtn" (click)="multiEwayFocus();d('Cross click')">Save</button>
                                                                            <button type="submit" class="btn btn-success m-r-10" (click)="multiEwayFocus();d('Cross click')" id="{{pageId}}closeMultiArtBtn">Close</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <!--<a style="cursor: pointer; color: blue;"
                                                    (click)="open7(multipleEwayBillPopupOpen)"><u> View Details</u></a>-->
                                                </div>
                                            </div>
                                        </div>
                                        <!--<div class="col-sm-12 col-md-12">
                      <div class="form-group">
                        <div class="input-group">
                          <label>E-Declaration Commodity</label>
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class=" fas fa-hashtag"></i>
                            </span>
                          </div>
                          <input type="text" class="form-control" aria-describedby="basic-addon11"
                            id="{{pageId}}eDeclarationCommodity" autocomplete="off">
                        </div>
                      </div>
                    </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 vl">
                            <div class="card" style="margin: 0px;">
                                <div class="card-body">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Article Charged Weight Extra</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}articleChargedWeightExtra" autocomplete="off" (keypress)="onKeyPressListnerForArticleChgWgtExt($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group ">
                                                <label>Pack Nature</label> <a style="cursor: pointer; color: blue; font-weight: bolder;" (click)="multiplePackNaturePopUp();">{{viewAddMultiPackNature}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Remarks</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}remarksId" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxAoc"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxAoc">AOC</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="paymentTypePaid" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxToBeBilled"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxToBeBilled">To Be Billed</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxPickupLr"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxPickupLr">Pickup
                                                    LR </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxunloadedByConsignee"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxunloadedByConsignee">Unloaded By
                                                    Consignee</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxHandleWithCare"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxHandleWithCare">Handle With Care</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxGatePass"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxGatePass">Gate
                                                    Pass</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="showCCAttacheOption" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxCcAttach"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxCcAttach">C.C.Attach</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxSelfLr" (change)="toggleEditableForSelfLr($event)"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxSelfLr">Self
                                                    LR</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxCOD" #checkboxCOD (change)="codCheckBoxEvent($event)"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxCOD">COD</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewCODChargeAmtField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>COD Charge</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}codChargeAmount" autocomplete="off" (change)="codChgAmtOnBlur($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxOrderGoods"> <label class="custom-control-label wtfull" for="{{pageId}}checkboxOrderGoods">Order Goods</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="width: 80%; border-top: none; margin: 3px;">
                <div class="col-md-3"></div>
                <div class="col-md-6" style="text-align: center;">

                    <!-- check valid E-Way bill No before update lrDetails if ewayBillNo is not null as per discuss (Asrar Jr 0n 20/09/2024) -->
                    <!-- <button type="submit" class="btn btn-dark" id="{{pageId}}updateBtn" (click)="validateSaveUpdateBtnDetails();">Update</button> -->
                    <button type="submit" class="btn btn-dark" id="{{pageId}}updateBtn" (click)="validateUpdateBtnOnLrDetails();">Update</button>
                    <button type="submit" class="btn btn-dark" id="{{pageId}}deleteBtn" *ngIf="viewDeleteBtn" (click)="sendNotoficationOnLRDelete(contentDeleteNotofication)" disabled>Delete</button>
                    <!-- <button type="submit" class="btn btn-dark" id="{{pageId}}autoLRPrintBtnId" *ngIf="viewAutoLRPrintBtn" (click)="lrRePrint();">Auto LR
						Print</button> -->
                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}clearBtn" (click)="clearFieldBtn();">Clear</button>
                </div>
                <div class="col-md-3"></div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<!--Pop up start here-->
<div class="col-sm-12 col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #content10 let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Consignee Details
                    </h6>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div *ngIf="showSpinnerForPopup" class="col-md-12 p-t-10">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait getting PAN Details.....</h6>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Add New Consignee</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-file"></i>
                                            </span>
                                        </div>
                                        <input #newConsignee type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}newConsignee" (keypress)="onKeyPressListnerNewConsigneeName($event, gstNoConsignee)" [(ngModel)]="newConsigneeName">
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>GST Number</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                            </span>
                                        </div>
                                        <input #gstNoConsignee type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}gstNoConsignee" (keypress)="onKeyPressListnerNewConsigneeGSTNo($event, newConsigneeAddress)">
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>{{consigneePopupLabel}}</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                            </span>
                                        </div>
                                        <input #gstNoConsignee type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}gstNoConsignee" (keypress)="onKeyPressListnerNewConsigneeGSTNo($event, newConsigneeAddress)" [(ngModel)]="gstNoConsigneeModel">
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Address</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                            </span>
                                        </div>
                                        <input #newConsigneeAddress type="text" id="{{pageId}}newConsigneeAddress" class="form-control" aria-describedby="basic-addon11" (keypress)="onKeyPressListnerNewConsigneeAddress($event, consigneeState)">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-8">
                                <div class="control">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>State</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-map-marker-alt"></i>
                                                </span>
                                            </div>
                                            <!--<input #consigneeState class="auto_selectOption input is-medium" id="{{pageId}}consigneeState"
                        placeholder="Select State">-->
                                            <input #consigneeState id="{{pageId}}consigneeState" type="text" class="form-control" (selectItem)="clickListnerForConsigneeState($event)" [(ngModel)]="modelConsigneeState" [ngbTypeahead]="searchConsigneeState" [resultFormatter]="formatterConsigneeState"
                                                [inputFormatter]="formatterConsigneeState" (focus)="focusConsigneeStateTA$.next($any($event).target.value)" autocomplete="off" autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12">
                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}closePopupBtn" (click)="d('Cross click')">Close</button>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="ValidateClearNewConsigneeDetails()">Clear</button>
                            </div>
                            <div #newConsigneeSaveBtn class="col-sm-12 col-md-12">
                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}newConsigneeSaveBtn" (click)="validateNewConsignee()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>


<div class="col-sm-12 col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #content9 let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Consignor Details
                    </h6>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div *ngIf="showSpinnerForPopup" class="col-md-12 p-t-10">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait getting PAN Details.....</h6>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Add New Consignor</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-file"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}newConsignor" (keypress)="onKeyPressListnerForNewConsignorName($event);" [(ngModel)]="newConsignorName">
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>GST Number</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                            </span>
                                        </div>
                                        <input #gstNoConsignor type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}gstNoConsignor" (keypress)="onKeyPressListnerForNewConsignorGSTNo($event)">
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>{{consignorPopupLabel}}</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                            </span>
                                        </div>
                                        <input #gstNoConsignor type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}gstNoConsignor" (keypress)="onKeyPressListnerForNewConsignorGSTNo($event)" [(ngModel)]="gstNoConsignorModel">
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Address</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                            </span>
                                        </div>
                                        <input #newConsignorAddress type="text" id="{{pageId}}newConsignorAddress" class="form-control" aria-describedby="basic-addon11" (keypress)="onKeyPressListnerForNewConsignorAddress($event, consignorState)">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-8">
                                <div class="control">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>State</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-map-marker-alt"></i>
                                                </span>
                                            </div>
                                            <!--<input #consignorState class="auto_selectOption input is-medium" id="{{pageId}}consignorState"
                        placeholder="Select State" #consignorState [formControl]="controlConsignorState"
                        [appAutocomplete]="autocompleteConsignorState"
                        (keyup)="fieldFocus($event, consignorMobileNumber)">-->

                                            <input #consignorState id="{{pageId}}consignorState" type="text" class="form-control" (selectItem)="clickListnerForConsignorState($event)" [(ngModel)]="modelConsignorState" [ngbTypeahead]="searchConsignorState" [resultFormatter]="formatterConsignorState"
                                                [inputFormatter]="formatterConsignorState" (focus)="focusConsignorStateTA$.next($any($event).target.value)" autocomplete="off" autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Mobile Number</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                            </span>
                                        </div>
                                        <input #consignorMobileNumber type="number" id="{{pageId}}consignorMobileNumber" name="consignorMobileNumber" class="form-control" aria-describedby="basic-addon11" (keypress)="onKeyPressListnerForNewConsignorMobileNo($event)">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Office Number</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                            </span>
                                        </div>
                                        <input #consignorOfficeNumber type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}consignorOfficeNumber" (keypress)="onKeyPressListnerForNewConsignorOfficeNo($event, newConsignorSaveBtn)">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}closePopupBtn" (click)="d('Cross click')">Close</button>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="validateClearNewConsignorDetails()">Clear</button>
                            </div>
                            <div #newConsignorSaveBtn class="col-sm-12 col-md-12">
                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}newConsignorSaveBtn" (click)="validateNewConsignor()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<!-- delete send notification popup -->
<ng-template #contentDeleteNotofication let-c="close " let-d="dismiss ">
    <div class="modal-header ">
        <h6 class="modal-title " id="{{pageId}}modal-basic-title ">Please select the reason for LR Delete</h6>

        <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') ">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <div class="row">
            <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                <div class="form-group">
                    <div class="input-group">
                        <mat-progress-bar mode="indeterminate" style="color: green;">
                        </mat-progress-bar>
                        <br>
                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                            Please Wait Uploading Document....</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="col-md-12">
                <fieldset class="radio">
                    <div class="col-sm-12 col-md-12">
                        <input type="radio" name="lrdeletenotification" value="Consignor Taken Back" (change)="lrDeleteRadioEvent($event.target.value)"> <label style="margin-left: 10px; width: 85%;">Goods are taken back by Consignor</label>
                    </div>

                    <div *ngIf="lrDeleteReturnBack && lrDeleteConsentReq" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label style="width: 85%;">Upload Consent Copy :</label> <input type="file" id="{{pageId}}lrDeleteConsentCopy" (change)="onFileChangedConsent($event)" #fileInputAocLr>
                                <button style="float: right;" type="button" class="btn btn-primary m-r-10" id="{{pageId}}uploadBtnId" (click)="uploadBtnConsentCopy()">Upload</button>
                            </div>
                        </div>
                    </div>
                    <br>

                    <div *ngIf="lrDeleteReturnBack && lrDeleteConsentReq" class="col-sm-12 col-md-12">
                        <div class="form-group ">
                            <div class="input-group ">
                                <label style="width: 14%;">Enter PIN</label>
                                <div class="input-group-prepend ">
                                    <span class="input-group-text "> <i class="fas fa-hashtag "></i>
                                    </span>
                                </div>
                                <input type="password" class="form-control " aria-describedby="basic-addon11 " id="{{pageId}}lrDeleteOtp" autocomplete="off">
                                <button style="float: right; margin-left:10px;" type="button" class="btn btn-primary m-r-10" id="{{pageId}}lrDeleteOtpBtn" (click)="sendLrDeleteOtpToConsignee('WhatsApp')">PIN In WhatsApp</button>
                                <button style="float: right; margin-left:10px;" type="button" class="btn btn-primary m-r-10" id="{{pageId}}lrDeleteOtpBtn" (click)="sendLrDeleteOtpToConsignee('SMS')">PIN In SMS</button>
                            </div>
                        </div>
                    </div>

                    <br>
                    <div class="col-sm-12 col-md-12">
                        <input type="radio" name="lrdeletenotification" value="Technical Reason" (change)="lrDeleteRadioEvent($event.target.value)"> <label style="margin-left: 10px; width: 85%;">Technical reasons</label>
                    </div>
                    <div *ngIf="lrDeleteTechReason && lrDeleteMasterPwdReq" class="col-sm-12 col-md-12 ">
                        <div class="form-group ">
                            <div class="input-group ">
                                <label>Master Password</label>
                                <div class="input-group-prepend ">
                                    <span class="input-group-text "> <i class="fas fa-hashtag "></i>
                                    </span>
                                </div>
                                <input type="password" class="form-control " aria-describedby="basic-addon11 " id="{{pageId}}lrDeleteMasterPwd" autocomplete="off">
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>

            <div class="col-md-12">
                <div class="form-group">
                    <div class="input-group">
                        <label>Remarks</label>
                        <div class="input-group-prepend">
                            <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                            </span>
                        </div>
                        <textarea class="form-control" rows="1" id="{{pageId}}reasonForDelete"></textarea>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 ">
            </div>

            <div class="col-md-12">
                <h6>Note :</h6>
                <h6 style="font-size:13px;">1. LR delete notification will send to Consignee/Consignor via WhatsApp/SMS.
                </h6>
                <h6 *ngIf="lrDeleteReturnBack && lrDeleteConsentReq" style="font-size:13px;">2. Consent Upload file size must be less than 1 MB.</h6>
                <h6 *ngIf="lrDeleteReturnBack && lrDeleteConsentReq" style="font-size:13px;">3. In case of Goods taken back, must validate the PIN which will send to Consignee on Send PIN Click.</h6>
            </div>

            <div class="col-sm-12 col-md-12 ">
            </div>
            <div class="col-sm-12 col-md-12 ">
                <button type="submit " class="btn btn-dark " (click)="validateDeleteBtnDetails() ">Confirm
                    Delete</button>
                <button style="padding: 1px 4px; float:right; " type="submit " class="btn btn-dark " id="{{pageId}}clearPopupBtn " (click)="lrDeletePopupClose()">Cancel</button>
            </div>

        </div>
    </div>
</ng-template>

<div *ngIf="viewLrEntryPrint" onafterprint="afterPrint()" id="{{pageId}}printLrEntryForm">
    <app-lrentryform-print3></app-lrentryform-print3>
</div>