export class TruckDataDto {
  truckNumber: string;
  ownerName: string;
  address: string;
  city: string;
  pinCodeNumber: string;
  tinNo: string;
  truckCapacity: string;
  vehicleName: string;
  userId: string;
  truckcapacity: number;
  actual: number;
  bulkyandsemi: number;
  actuallogic: number;
  bulkyandsemilogic: number;
  office: string;
  supplierName: string;
  state: string;
  contactNo: string;
  contactPerson: string;
  status: string;
  id: number;
  cpName: string;
  cpType: string;
  cpCode: string;
  loaderName: string;
  driverName: string;
  entryCP: string;
  exitCP: string;
  expExitDate: Date;
  expExitDays: number;
  remarks: string;
  licNo: string;
  panNo: string;
  driverMobNo: string;
  suppAdd: string;
  suppPanNo: string;
  suppPhNo: string;
  driverAdd: string;
  driverLicState: string;
  driverPincode: string;
  ownerRegState: string;
  ownerPincode: string;
  consigneeName: string;
  consigneeTinNo: string;
  consigneeState: string;
  consigneeAdd: string;
  consignorName: string;
  consignorTinNo: string;
  consignorState: string;
  consignorAdd: string;
  commodityName: string;
  commodityDesc: string;
  commodityValue: string;
  commodityQty: string;
  entryCPCode: string;
  exitCPCode: string;
  driverLicStateCode: string;
  ownerStateCode: string;
  consigneeStateCode: string;
  consignorStateCode: string;
  commodityCode: string;
  stateCode: string;
  lrNumber: string;
  bookingDate: Date;
  quantity: number;
  goodsValue: number;
  //    List<TruckDataDto> truckDataDtoList;
  hireslipNumber: string;
  mainStation: string;
  truckType: string;
  consignorId: number;
  consigneeId: number;
  driverId: number;
  loaderHead: string;
  multipleSource: string;
  multipleHamali: string;
  imgURLRc: string;
  imgURLIns: string;
  imgUploadedIns: boolean;
  imgUploadedRc: boolean;
  imgURLLic: string;
  suppCode: string;
  imgURLPan: string;
  imgUploadedPan: boolean;
  capacity: number;
  trkLgth: number;
  insPolNo: string;
  mode: string;
  financialYear: string;
  tripTo: string;
  lat: number;
  lng: number;
  companyId: string;
  truckTypeNew: string;
  truckCapcityDouble: number;
  isActive: boolean;

  //added at 20-02-2021
  bayNo: string;
  bayUnloadingTime: string;
  bayUnloadingDate: string;

  isValid: boolean;
  fromRange: number;
  toRange: number;
  truckTypeId: number;
  driverChg: number;
  area: string;
  inchargeName: string;
  acType: string;
  acNumber: string;
  bankName: string;
  acHolder: string;
  ifscCode: string;
  branchName: string;
  truckDataDtoList: TruckDataDto[];
  hireslipDate: Date;
  ddVehicleNo: string;
  panNoValidation: string;
  mrngTripExtAmt: number;
  insuranceExpDate: Date;
}