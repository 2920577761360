import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';

@Component({
    selector: 'app-destination-stocks-report',
    templateUrl: './destination-stocks-report.component.html',
    styleUrls: ['./destination-stocks-report.component.css']
})
export class DestinationStocksReportComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    pageId = "deststkrpt";

    sourceOptions: LRDto[];
    lrDtoSource: LRDto = new LRDto();
    public modelSource: any;
    sourceTA: Array<LRDto> = [];
    lrDtoSourceOptions: LRDto[];
    lrDtoSourceAllOption: LRDto = new LRDto();
    showSpinnerForAction: boolean = false;
    selectedDestination: any;
    selectedSource: any;
    lrDtoForGet: LRDto = new LRDto();
    destinationStocksRptDetails: any;
    dtTriggerdestinationStocksRpt: Subject<any> = new Subject();
    dtOptionsdestinationStocksRpt: any;


    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    lrDtoDestinationAll: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.destinationTA
                    : this.destinationTA.filter(
                        (v) =>
                            v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterDestination = (x: { destination: string }) => x.destination;


    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef, private lrReportService: LrReportService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
            this.getSourceDetails();
        }
    }

    ngOnInit() {
        this.dtOptionsdestinationStocksRpt = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    title: function () {
                        return "Destination Stocks Report Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            searching: true,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
            select: true,
            footer: true,
           "footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalLrs = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var articles = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var toPay = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var paid = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var actualWgt = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chargedWgt = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);


                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totalLrs);
				$(api.column(2).footer()).html(articles);
				$(api.column(3).footer()).html(toPay);
				$(api.column(4).footer()).html(paid);
				$(api.column(5).footer()).html(actualWgt);
				$(api.column(6).footer()).html(chargedWgt);

			}
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerdestinationStocksRpt.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerdestinationStocksRpt.next();
    }

    getSourceDetailedList() {
        this.lrDtoSource.mode = 'Booking';
        this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSource.reportMode = 'InclMainStation';
        console.log(this.lrDtoSource);
    }

    getSourceDetails() {
        this.getSourceDetailedList();
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSource).subscribe(
            (response) => {
                if (response) {
                    if (response.length > 0) {
                        this.lrDtoSourceOptions = response;
                        console.log(this.lrDtoSourceOptions);
                        this.sourceTA = [];

                        this.lrDtoSourceAllOption = new LRDto();
                        this.lrDtoSourceAllOption.subStations = "All";
                        this.sourceTA.push(this.lrDtoSourceAllOption);

                        for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
                            this.sourceTA.push(this.lrDtoSourceOptions[i]);
                        }
                        // this.lrDtoSourceAllOption = new LRDto();
                        // this.lrDtoSourceAllOption.subStations = "Non Delhi";
                        // this.sourceTA.push(this.lrDtoSourceAllOption);

                        // this.lrDtoSourceAllOption = new LRDto();
                        // this.lrDtoSourceAllOption.subStations = "Delhi & Non Delhi";
                        // this.sourceTA.push(this.lrDtoSourceAllOption);

                        // for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
                        //     this.searchSrcListInLoop.push(this.lrDtoSourceOptions[i].subStations);
                        //     this.searchAgentListInLoop.push(this.agentNameOptions[i].subStations);
                        // }
                        // $("#" + this.pageId + "source").val('All');
                        // $("#" + this.pageId + "agentName").val('All');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                // this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Agent Details", "info");
            },
            () => console.log('done');

    };

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "LrForm";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    // this.destinationDtoForAll = new LRDto();
                    // this.destinationDtoForAll.destination = "ALL";
                    // this.destinationTA.push(this.destinationDtoForAll);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log("done");
    };

    getDestinationwiseStocksRptDetails() {
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();

        this.lrDtoForGet = new LRDto();
        this.lrDtoForGet.source = this.selectedSource;
        this.lrDtoForGet.destination = this.selectedDestination;
        console.log(this.lrDtoForGet);

        if (this.selectedSource == null || this.selectedSource == undefined || this.selectedSource == ''
            || this.selectedDestination == null || this.selectedDestination == undefined || this.selectedDestination == ''
        ) {
            swal({
                title: "Mandatory Fields",
                text: "Please select all fields are Mandatory!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            this.showSpinnerForAction = true;
            this.lrReportService.getDestinationwiseStocksReportDetails(this.lrDtoForGet).subscribe(
                (response) => {
                    this.showSpinnerForAction = false;
                    console.log(response);
                    $("#" + this.pageId + "destinationStocksRptTable").DataTable().destroy();
                    this.destinationStocksRptDetails = [];
                    if (response.length > 0) {
                        this.destinationStocksRptDetails = response;
                        console.log(this.destinationStocksRptDetails);
                    } else {
                        swal("Alert", "No details found for the selected details", "warning");
                    }
                    this.dtTriggerdestinationStocksRpt.next();
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    this.showSpinnerForAction = false;
                    swal("Error", "Server Error While Getting Destination Stocks Details", "warning");
                },
                () => console.log('done');
        }
    }

    clearDetails() {
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "destination").val('');
        this.selectedSource = null;
        this.selectedDestination = null;
        $("#" + this.pageId + "destinationStocksRptTable").DataTable().destroy();
        this.destinationStocksRptDetails = [];
        this.dtTriggerdestinationStocksRpt.next();
    }
}
