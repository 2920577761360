<!-- Row -->

<body>
    <div class="row" *ngIf="isLoggedIn">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <!-- <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Generate Cash Memo</h6>
                </div> -->

                <!-- 				<div> -->
                <!-- 					<h6>Barcode</h6> -->

                <!-- 					<tr *ngFor="let PS of PrintSerials"> -->
                <!-- 						<ngx-barcode [bc-width]="1" [bc-height]="25" [bc-font-size]="13" -->
                <!-- 							[bc-value]="PS.SerialId" [bc-display-value]="true"> -->

                <!-- 						</ngx-barcode> -->
                <!-- 					</tr> -->
                <!-- 				</div> -->

                <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>LR Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-clipboard"></i>
                                                    </span>
                                                </div>

                                                <input type="text" class="form-control" id="{{pageId}}lrNumber"
                                                    #lrNumber (keyup)="lrNumberOnEnter($event)"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <button type="submit" class="btn btn-success m-r-10" (click)="lrNumberGetDtls()"
                                            id="{{pageId}}getBtn">Get Details</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <h5 *ngIf="viewMemoAlreadyGeneratedMsg" class="cstm_efftect">
                                            <i class="fas fa-clipboard-list"></i>&nbsp;CashMemo Already Generated
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row system_responsive" style="margin-bottom: 5px;">
                    <div class="col-md-3">
                        <div class="card" style="margin: 0px;">
                            <div class="card-body">
                                <h6 class="card-title">LR Details</h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Booking Date</label> <input id="{{pageId}}bookingDate"
                                                    class="form-control" placeholder="dd-mm-yyyy" name="bookingDates"
                                                    ngbDatepicker #bookingDates="ngbDatepicker" readonly>
                                                <div class="input-group-append">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Invoice Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-clipboard"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}invoiceNumber"
                                                    #invoiceNumber aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Invoice Date</label> <input id="{{pageId}}invoiceDate"
                                                    class="form-control" placeholder="dd-mm-yyyy" name="invoiceDates"
                                                    ngbDatepicker #invoiceDates="ngbDatepicker" readonly>
                                                <div class="input-group-append">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewCommodity" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Commodity</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class="fas fa-clipboard-list"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}commodity"
                                                    #commodity aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="viewCommodityCombo" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                    <label>Commodity Name</label> <input id="{{pageId}}commodityCombo"
                                                        type="text" class="form-control" [(ngModel)]="commodityCombo"
                                                        [ngbTypeahead]="commodityComboSearchTA"
                                                        (focus)="focusCommodityComboTA$.next($any($event).target.value)"
                                                        (click)="clickCommodityComboTA(instanceCommodityCombo)"
                                                        #instanceCommodityCombo="ngbTypeahead" #elem />

                                                    <button type="submit" class="btn btn-success m-r-10"
                                                        id="{{pageId}}btnRecalculate"
                                                        (click)="rcBtmValidate('button')">RC</button>

                                                    <!-- 														<button (click)="openTypeahead(instanceCommodityCombo)">Open Typeahead</button> -->
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Source Station</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-home"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}sourceStation"
                                                    #sourceStation aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewDestination" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Destination</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-road"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}destination"
                                                    #destination aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Agent</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}agentName"
                                                    #agentName aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Consignor Name</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}consignorName"
                                                    #consignorName aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Consignee Name</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}consigneeName"
                                                    #consigneeName aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>No.Of Article</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-clone"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}noOfArticle"
                                                    #noOfArticle aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Actual Weight </label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}actualWeight"
                                                    #actualWeight aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Charged Weight</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}chargedWeight"
                                                    #chargedWeight aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Base Freight</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}baseFreight"
                                                    #baseFreight aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Goods Value</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}goodsValue"
                                                    #goodsValue aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Ewaybill No</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}ewayBillNo"
                                                    #ewayBillNo aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>E-Way BillValidUpto</label> <input class="form-control"
                                                    placeholder="yyyy-mm-dd" [(ngModel)]="modelEwayBillValidUpto"
                                                    name="ewayBillValidUpto" id="{{pageId}}ewayBillValidUpto"
                                                    ngbDatepicker #ewayBillValidUpto="ngbDatepicker" autocomplete="off"
                                                    readonly />
                                                <div class="input-group-append">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 vl">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>To Pay</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}toPay" #toPay
                                                    aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Paid</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}paid" #paid
                                                    aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style="width: 90%;">
                                    <h6 class="card-title">Billing Details</h6>
                                    <div *ngIf="viewMemoDate" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Cashmemo Date</label> <input id="{{pageId}}cashmemoDate"
                                                    class="form-control" placeholder="dd-mm-yyyy" name="cashmemoDates"
                                                    ngbDatepicker #cashmemoDates="ngbDatepicker"
                                                    [readonly]="readonlyCashmemoDate">
                                                <div class="input-group-append" (click)="cashmemoDates.toggle()"
                                                    disabled>
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewDeliveryType" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Delivery Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="icon-home"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}deliveryType"
                                                    name="deliveryType" #deliveryType (change)="deliveryTypeMethod()">
                                                    <option value="Select Delivery Type">Select
                                                        Delivery Type</option>
                                                    <!-- 													<option selected value="Godown">Godown</option> -->
                                                    <option value="Godown">Godown</option>
                                                    <option value="Door Delivery">Door Delivery</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewDeliveryTypeTxt" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Delivery Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="icon-home"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}deliveryTypeTxt"
                                                    [(ngModel)]="deliveryTypeTxt" readonly
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 																(change)="deliveryTypeMethod(deliveryType.value)" -->
                                    <div *ngIf="areaBillingDetailsWiseFieldSet" class="col-sm-12 col-md-12">

                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                        <label>Area</label> <input
                                                            id="{{pageId}}areaBillingDtlsFieldSet" type="text"
                                                            class="form-control" [ngbTypeahead]="areaBillingSearchTA"
                                                            (focus)="focusAreaBillingTA$.next($any($event).target.value)"
                                                            (click)="clickAreaBillingTA$.next($any($event).target.value)"
                                                            (keyup)="areaMethod($any($event).target.value,$event)"
                                                            #instanceAreaBilling="ngbTypeahead"
                                                            [readonly]="readOnlyFieldsonDestMemoLrGen" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group" *ngIf="viewNewAreaBillingDtlsFieldSet">
                                            <div class="input-group">
                                                <label>New Area</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-home"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control"
                                                    id="{{pageId}}newAreaBillingDtlsFieldSet"
                                                    #newAreaBillingDtlsFieldSet aria-describedby="basic-addon11"
                                                    [readonly]="readOnlyFieldsonDestMemoLrGen">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Local Charge</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}localCharge"
                                                    (keyup)="localChgFocus($event)" #localCharge
                                                    [readonly]="readonlyLocalCharge || readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen"
                                                    (change)="localChgBlur($event)" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewHamaliGd" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label *ngIf="!loadingHamaliOptionFlow">Hamali GD</label>
                                                <label *ngIf="loadingHamaliOptionFlow">Loading Hamali</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}hamaliGd"
                                                    (keyup)="hamaliGdFocus($event)" #hamaliGd
                                                    [readonly]="readonlyHamaliGd || readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen"
                                                    (change)="hamaliGdBlur($event)" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewUnloadingHamali" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unloading Hamali</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}unloadingHamali"
                                                    [(ngModel)]="unloadingHamali"
                                                    [readonly]="readonlyUnloadingHamali || readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen"
                                                    (keyup)="unloadingHamaliFocus($event)"
                                                    (change)="unloadingHamaliBlur($event)"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewHamaliDd" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Hamali DD</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}hamaliDd"
                                                    (keyup)="hamaliDdFocus($event)" (change)="hamaliDdBlur($event)"
                                                    [(ngModel)]="hamaliDd"
                                                    [readonly]="readonlyHamaliDd || readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Receipt Charges</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control"
                                                    (keyup)="rcptChgBillSideFocus($event)"
                                                    id="{{pageId}}rcptChgBillSide"
                                                    [readonly]="readonlyRcptChgBillSide || readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen"
                                                    #rcptChgBillSide (blur)="rcptChgBillSideBlur($event)"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Service Charges</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control"
                                                    [readonly]="readonlySrvChgBillSide || readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen"
                                                    id="{{pageId}}srvChgBillSide" (keyup)="srvChgBillSideFocus($event)"
                                                    #srvChgBillSide (blur)="srvChgBillSideBlur($event)"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Demurrage</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}demurrage"
                                                    (keyup)="demurrageFocus($event)" #demurrage
                                                    (blur)="demurrageBlur($event)" aria-describedby="basic-addon11"
                                                    [readonly]="readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen">
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="viewOthers" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Others</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}others"
                                                    (keyup)="othersFocus($event)" #others (change)="othersBlur($event)"
                                                    [readonly]="readonlyOtherChg || readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Discount</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}discount"
                                                    [readonly]="readonlyDiscount || readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen"
                                                    #discount aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>FOV Less</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}fovLess"
                                                    [readonly]="readonlyFovLess || readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen"
                                                    #fovLess aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- new field added for chennai -->
                                    <div *ngIf="hamaliDDRateLessStatusShow" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Net Rate DD Hamali Less</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}ddHamaliLess"
                                                    [readonly]="true" #ddHamaliLess aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Total Discount</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}totalDiscount"
                                                    (keyup)="totalDiscountFocus($event)" [(ngModel)]="totalDiscount"
                                                    [readonly]="readonlyTotalDiscount || readonlyBillingDetails || readOnlyFieldsonDestMemoLrGen"
                                                    (blur)="totalDiscountBlur($event)" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 vl">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <!-- 								<div class="col-sm-12 col-md-12"> -->
                                    <!-- 									<div class="form-group"> -->
                                    <!-- 										<div class="input-group" id="{{pageId}}subTotal"> -->
                                    <!-- 											<label>Sub Total</label> -->
                                    <!-- 											<div class="input-group-prepend"> -->
                                    <!-- 												<span class="input-group-text"> <i -->
                                    <!-- 													class="fas fa-rupee-sign"></i> -->
                                    <!-- 												</span> -->
                                    <!-- 											</div> -->
                                    <!-- 											<input type="number" class="form-control" -->
                                    <!-- 												aria-describedby="basic-addon11" readonly> -->
                                    <!-- 										</div> -->
                                    <!-- 									</div> -->
                                    <!-- 								</div> -->
                                    <div class="col-sm-12 col-md-12 ">
                                        <div class="form-group ">
                                            <div class="input-group ">
                                                <label>Grand Total</label>
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text "> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control " id="{{pageId}}grandTotal"
                                                    #grandTotal aria-describedby="basic-addon11 " readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewRoundOffAmt" class="col-sm-12 col-md-12 ">
                                        <div class="form-group ">
                                            <div class="input-group ">
                                                <label>Round Off Amt</label>
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text "> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control " id="{{pageId}}roundOffAmt"
                                                    #roundOffAmt aria-describedby="basic-addon11 " readonly>
                                            </div>
                                        </div>
                                    </div>

                                    <hr style="width: 80%;">
                                    <h6 class="card-title">GST Details</h6>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>CGST</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}cgst" #cgst
                                                    aria-describedby="basic-addon11" disabled>&nbsp; <input
                                                    type="number" class="form-control" id="{{pageId}}cgstAmt" #cgstAmt
                                                    aria-describedby="basic-addon11" disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>SGST</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}sgst" #sgst
                                                    aria-describedby="basic-addon11" disabled>&nbsp; <input
                                                    type="number" class="form-control" id="{{pageId}}sgstAmt" #sgstAmt
                                                    aria-describedby="basic-addon11" disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>IGST</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}igst" #igst
                                                    aria-describedby="basic-addon11" disabled>&nbsp; <input
                                                    type="number" class="form-control" id="{{pageId}}igstAmt" #igstAmt
                                                    aria-describedby="basic-addon11" disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>GST Total</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}gstTotal"
                                                    #gstTotal aria-describedby="basic-addon11" disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="showSpinner" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group" style="margin-right: 160px;">
                                                <mat-spinner></mat-spinner>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="showSpinner" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <h6 class="card-title" style="color: blue;">Please wait..processing the
                                                    task......
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style="width: 90%;">
                                    <h6 class="card-title">Collection Details</h6>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Payment Mode</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}paymentMode"
                                                    #paymentMode
                                                    (change)="paymentModeMethod(paymentMode.value, paymentMethodPopup)">
                                                    <option selected value="Select">Select Payment Mode</option>
                                                    <option [hidden]="hideOSMode" value="Outstanding">Outstanding
                                                    </option>
                                                    <!-- <option *ngIf="viewCashOption" value="Cash">Cash</option> -->
                                                    <option [hidden]="hideCashMode" value="Cash">Cash</option>
                                                    <!-- 												the below PP is removed  -->
                                                    <!-- 												<option value="PP">PP</option> -->
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Delivery Mode</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}deliveryMode"
                                                    #deliveryMode (change)="deliveryModeMethod(deliveryMode.value)"
                                                    readonly  [disabled]="readOnlyFieldsonDestMemoLrGen">
                                                    <option selected value="GD">GD</option>
                                                    <option value="DD">DD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewTripsheetHamali" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Tripsheet Hamali</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}tripsheetHamali"
                                                    [readonly]="readonlyTripsheetHamali || readOnlyFieldsonDestMemoLrGen"
                                                    #tripsheetHamali aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div [hidden]="!showVehicleNumberOption" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Vehicle Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                    </span>
                                                </div>
                                                <input #vehicleNumberDropDown
                                                    id="{{pageId}}dropDownInputFieldVehicleNumber" type="text"
                                                    class="form-control"
                                                    [ngbTypeahead]="searchDropDownInputFieldVehicleNumber"
                                                    [resultFormatter]="formatterDropDownVehicleNumber"
                                                    [inputFormatter]="formatterDropDownVehicleNumber"
                                                    placeholder="Select Vehicle Number"
                                                    (focus)="focusDropDownVehicleNumberTA$.next($any($event).target.value)"
                                                    (selectItem)="rowSelectedVehicleNo($event)"
                                                    [readonly]="readOnlyFieldsonDestMemoLrGen" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- For Vehicle name added by imran on 13022024 -->
                                    <div [hidden]="!showVehicleNumberOption" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Vehicle Name</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}vehicleName"
                                                    #vehicleName aria-describedby="basic-addon11" disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <div [hidden]="!showVehicleNumberOptionGD" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Vehicle Number</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}gdVehicleNumber"
                                                    #gdVehicleNumber aria-describedby="basic-addon11"
                                                    [readonly]="readOnlyFieldsonDestMemoLrGen">
                                            </div>
                                        </div>
                                    </div>


                                    <!-- 								<div class="col-sm-12 col-md-12"> -->
                                    <!-- 									<div class="form-group"> -->
                                    <!-- 										<div class="input-group" id="{{pageId}}collectionMan"> -->
                                    <!-- 											<label>Collection Man</label> -->
                                    <!-- 											<div class="input-group-prepend"> -->
                                    <!-- 												<span class="input-group-text"> <i -->
                                    <!-- 													class="fas fa-user"></i> -->
                                    <!-- 												</span> -->
                                    <!-- 											</div> -->
                                    <!-- 											<select class="custom-select col-12" id="{{pageId}}collectionMan" -->
                                    <!-- 												name="collectionMan" #collectionMan -->
                                    <!-- 												(change)="collectionManMethod(collectionMan.value)"> -->
                                    <!-- 												<option selected>Select Collection Man</option> -->
                                    <!-- 												<option value="addNew">Add New Collection Man</option> -->
                                    <!-- 												<option value="1">AHMED</option> -->
                                    <!-- 												<option value="2">ALI</option> -->
                                    <!-- 											</select> -->
                                    <!-- 										</div> -->
                                    <!-- 									</div> -->
                                    <!-- 								</div> -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                    <label>Collection Man</label>
                                                    <input id="{{pageId}}collectionMan" type="text" class="form-control"
                                                        [ngbTypeahead]="cmSearchTA"
                                                        (click)="clickCMTA$.next($any($event).target.value)"
                                                        (focus)="focusCMTA$.next($any($event).target.value)"
                                                        (keyup)="collectionManMethod($any($event).target.value,$event)"
                                                        #instanceCM="ngbTypeahead"
                                                        [readonly]="readOnlyFieldsonDestMemoLrGen" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div *ngIf="viewNewCollectionMans" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>New Collection Man</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}newCollectionMan"
                                                    #newCollectionMan aria-describedby="basic-addon11"
                                                    [readonly]="readOnlyFieldsonDestMemoLrGen">
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="viewDirectAssgOption" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input"
                                                    (change)="toggleChkBoxForDirectAssg($event)"
                                                    id="{{pageId}}directAssg" #directAssg
                                                    [readonly]="readonlyDirectAssg || readOnlyFieldsonDestMemoLrGen">
                                                <label class="custom-control-label wtfull"
                                                    for="{{pageId}}directAssg">Direct Assigned To Collection</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 vl">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <!-- 								<div class="col-sm-12 col-md-12"> -->
                                    <!-- 									<div class="form-group"> -->
                                    <!-- 										<div class="input-group"> -->
                                    <!-- 											<label>Area</label> -->
                                    <!-- 											<div class="input-group-prepend"> -->
                                    <!-- 												<span class="input-group-text"> <i -->
                                    <!-- 													class="fas fa-home"></i> -->
                                    <!-- 												</span> -->
                                    <!-- 											</div> -->
                                    <!-- 											<select class="custom-select col-12" id="{{pageId}}area" name="area" -->
                                    <!-- 												#area (change)="areaMethod(area.value)"> -->
                                    <!-- 												<option selected>Select Area</option> -->
                                    <!-- 												<option value="addNew">Add New</option> -->
                                    <!-- 												<option value="1">Account</option> -->
                                    <!-- 												<option value="2">Cash</option> -->
                                    <!-- 											</select> -->
                                    <!-- 										</div> -->
                                    <!-- 									</div> -->
                                    <!-- 								</div> -->

                                    <div *ngIf="areaCollectionDetailsWiseFieldSet" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Area</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-home"></i>
                                                        </span>
                                                    </div>
                                                    <input class="auto_selectOption input is-medium"
                                                        placeholder="Select Area"
                                                        id="{{pageId}}areaCollectionDtlsFieldSet"
                                                        [formControl]="controlAreaCollectionDtlsFieldSet"
                                                        #areaCollectionDtlsFieldSet
                                                        [appAutocomplete]="autocompleteAreaCollectionDtlsFieldSet"
                                                        [readonly]="readOnlyFieldsonDestMemoLrGen">
                                                </div>
                                            </div>
                                        </div>

                                        <app-autocomplete #autocompleteAreaCollectionDtlsFieldSet="appAutocomplete">
                                            <ng-template appAutocompleteContent>
                                                <ng-container
                                                    *ngIf="(branchAreaDataList | filterGodownName: controlAreaCollectionDtlsFieldSet.value) as resultAreaCollectionDtlsFieldSet">
                                                    <!-- <ng-container *ngIf="(areaDataFromRoutingAndAgentSubstationList | filterGodownName: controlAreaCollectionDtlsFieldSet.value) as resultAreaCollectionDtlsFieldSet"> -->
                                                    <app-option *ngFor="let option of resultAreaCollectionDtlsFieldSet"
                                                        (click)="areaMethod(option)" [value]="option.godownName">
                                                        {{ option.godownName }} </app-option>
                                                    <app-option class="no-result"
                                                        *ngIf="!resultAreaCollectionDtlsFieldSet.length">No result
                                                    </app-option>
                                                </ng-container>
                                            </ng-template>
                                        </app-autocomplete>


                                        <div *ngIf="viewNewAreaCollectionDtlsFieldSet" class="form-group">
                                            <div class="input-group">
                                                <label>New Area</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-home"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control"
                                                    id="{{pageId}}newAreaCollectionDtlsFieldSet"
                                                    #newAreaCollectionDtlsFieldSet aria-describedby="basic-addon11"
                                                    [readonly]="readOnlyFieldsonDestMemoLrGen">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewGeneratedBy" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Generated By</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}generatedBy"
                                                    [(ngModel)]="generatedBy" aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input"
                                                    (change)="toggleChkBoxForLetterHeader($event)"
                                                    id="{{pageId}}letterHead" #letterHead
                                                    [readonly]="readonlyCbLetterHead || readOnlyFieldsonDestMemoLrGen">
                                                <label class="custom-control-label wtfull"
                                                    for="{{pageId}}letterHead">With
                                                    Letter Head</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewCmAtMemoGeneration" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>CM At Memo Gn.</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control"
                                                    id="{{pageId}}collectionMenAtMemoGeneration"
                                                    [(ngModel)]="collectionMenAtMemoGeneration"
                                                    aria-describedby="basic-addon11" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <br style="width: 90%;">
                                    <h6 class="card-title">Standard Rate Master</h6>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Rate Id</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}srmRateId"
                                                    [(ngModel)]="srmRateId" [readonly]=true
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Source Station</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-home"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control"
                                                    [readonly]="readonlySrmSourceStation || readOnlyFieldsonDestMemoLrGen"
                                                    id="{{pageId}}srmSourceStation" #srmSourceStation
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewSrmRate" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Rate</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}srmRate"
                                                    [(ngModel)]="srmRate"
                                                    [readonly]="readonlySrmRate || readOnlyFieldsonDestMemoLrGen"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewSrmRate" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Per Unit</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}srmPerUnit"
                                                    [(ngModel)]="srmPerUnit"
                                                    [readonly]="readonlySrmPerUnit || readOnlyFieldsonDestMemoLrGen"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewSrmRatePercentage" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Rate Percentage</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control"
                                                    [readonly]="readonlySrmRatePercentage || readOnlyFieldsonDestMemoLrGen"
                                                    [(ngModel)]="srmRatePercentage" id="{{pageId}}srmRatePercentage"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewSrmDiscount" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Discount</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}srmDiscount"
                                                    [readonly]="readonlySrmDiscount || readOnlyFieldsonDestMemoLrGen" [(ngModel)]="srmDiscount"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>G.C.Charges</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}srmGcCharges"
                                                    [readonly]="readonlySrmGcCharges || readOnlyFieldsonDestMemoLrGen" [(ngModel)]="srmGcCharge"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Source Hamali</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}srmSourceHamali"
                                                    [readonly]="readonlySrmSourceHamali || readOnlyFieldsonDestMemoLrGen" [(ngModel)]="srmSourceHamali"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Destination Hamali</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control"
                                                    [readonly]="readonlySrmDestinationHamali || readOnlyFieldsonDestMemoLrGen"
                                                    id="{{pageId}}srmDestinationHamali"
                                                    [(ngModel)]="srmDestinationHamali" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Receipt Charges</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control"
                                                    [readonly]="readonlySrmReciptCharges || readOnlyFieldsonDestMemoLrGen"
                                                    id="{{pageId}}srmReciptCharges" [(ngModel)]="srmReciptCharges"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Service Charges</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control"
                                                    [readonly]="readonlySrmServiceCharges || readOnlyFieldsonDestMemoLrGen"
                                                    id="{{pageId}}srmServiceCharges" [(ngModel)]="srmServiceCharges"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Net Rate DD Hamali Less</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control"
                                                    [readonly]="readonlyNetRateHamaliLess || readOnlyFieldsonDestMemoLrGen"
                                                    id="{{pageId}}srmNetRateHamaliLess"
                                                    [(ngModel)]="srmNetRateHamaliLess" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Other Charges</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" [readonly]="readonlySrmOtherChg || readOnlyFieldsonDestMemoLrGen"
                                                    id="{{pageId}}srmOtherChg" [(ngModel)]="srmOtherChg"
                                                    aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>FOV</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control"
                                                    [readonly]="readonlyNetRateHamaliLess || readOnlyFieldsonDestMemoLrGen" id="{{pageId}}srmFov"
                                                    [(ngModel)]="srmFov" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
				    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Fixed By</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" [readonly]="readonlyFixedBy"
                                                    id="{{pageId}}fixedBy" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-12">
                                                        <h5 *ngIf="viewIsSizeExeededLrText" class="cstm_efftect">
                                                            <i></i>&nbsp;Size Exceeded : Yes [ND]
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="input-group" id="{{pageId}}popupDetails">
                                <ng-template #paymentMethodPopup let-c="close" let-d="dismiss">
                                    <div class="modal-header">
                                        <h6 class="modal-title" id="{{pageId}}modal-basic-title">Payment Details For
                                            Cash Mode</h6>
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="d('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body ">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Payment Type</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-search"></i>
                                                                </span>
                                                            </div>
                                                            <select class="custom-select col-12"
                                                                id="{{pageId}}paymentType" name="paymentType"
                                                                [(ngModel)]="selectedPaymentType"
                                                                (change)="paymentTypeMode()">
                                                                <option value="" selected>Select Payment Mode</option>
                                                                <option value="Cash">Cash</option>
                                                                <option value="Cheque/PDC">Cheque/PDC</option>
                                                                <option value="NEFT/IMPS">NEFT/IMPS</option>
                                                                <option value="Debit Account">Debit A/c</option>
                                                                <option value="UPI">UPI</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div *ngIf="viewByChequeNumber" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Cheque Number</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-file-alt"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" class="form-control"
                                                                id="{{pageId}}chequeNumber"
                                                                [(ngModel)]="enteredChequeNumber"
                                                                aria-describedby="basic-addon11" >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="viewByChequeDate" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Cheque Date</label>


                                                            <!-- 														<input id="{{pageId}}chequeDate" -->
                                                            <!-- 															class="form-control" placeholder="dd-mm-yyyy" -->
                                                            <!-- 															name="chequeDate" ngbDatepicker -->
                                                            <!-- 															#chequeDate="ngbDatepicker"> -->
                                                            <!-- 														<div class="input-group-append" -->
                                                            <!-- 															(click)="chequeDate.toggle()"> -->
                                                            <!-- 															<span class="input-group-text"> <i -->
                                                            <!-- 																class="fa fa-calendar"></i> -->
                                                            <!-- 															</span> -->
                                                            <!-- 														</div> -->

                                                            <!-- 														<input type="date" id="{{pageId}}chequeDate" name="chequeDate" -->
                                                            <!-- 															placeholder="dd-mm-yyyy"> -->


                                                            <input class="form-control" id="{{pageId}}chequeDate"
                                                                name="chequeDate" [(ngModel)]="enteredChequeDate"
                                                                ngbDatepicker #chequeDate="ngbDatepicker" >
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary calendar"
                                                                    (click)="chequeDate.toggle()"
                                                                    type="button"></button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="viewByConsigneeBankName" class="col-sm-12 col-md-12">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Consignee Bank Name</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-university"></i>
                                                                    </span>
                                                                </div>
                                                                <input class="auto_selectOption input is-medium"
                                                                    placeholder="Select Bank"
                                                                    [formControl]="controlConsigneeBankName"
                                                                    id="{{pageId}}consigneeBankName"
                                                                    [(ngModel)]="selectedBankName"
                                                                    [appAutocomplete]="autocompleteConsigneeBankName" >
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <app-autocomplete #autocompleteConsigneeBankName="appAutocomplete">
                                                        <ng-template appAutocompleteContent>
                                                            <ng-container
                                                                *ngIf="(consigneeBankNameArray | filterBankName: controlConsigneeBankName.value) as resultConsigneeBankName">
                                                                <app-option
                                                                    *ngFor="let option of resultConsigneeBankName"
                                                                    (click)="partyBankMethod(option)"
                                                                    [value]="option.bankName">
                                                                    {{ option.bankName }} </app-option>
                                                                <app-option class="no-result"
                                                                    *ngIf="!resultConsigneeBankName.length">No result
                                                                </app-option>
                                                            </ng-container>
                                                        </ng-template>
                                                    </app-autocomplete>
                                                </div>

                                                <!-- 											<div class="col-sm-12 col-md-12"> -->
                                                <!-- 												<div class="control"> -->
                                                <!-- 													<div class="form-group"> -->
                                                <!-- 														<div class="input-group"> -->
                                                <!-- 															<label>Area</label> -->
                                                <!-- 															<div class="input-group-prepend"> -->
                                                <!-- 																<span class="input-group-text"> <i -->
                                                <!-- 																	class="fas fa-home"></i> -->
                                                <!-- 																</span> -->
                                                <!-- 															</div> -->
                                                <!-- 															<input class="auto_selectOption input is-medium" -->
                                                <!-- 																placeholder="Select Area" -->
                                                <!-- 																id="{{pageId}}areaCollectionDtlsFieldSet" -->
                                                <!-- 																[formControl]="controlAreaCollectionDtlsFieldSet" -->
                                                <!-- 																#areaCollectionDtlsFieldSet -->
                                                <!-- 																[appAutocomplete]="autocompleteAreaCollectionDtlsFieldSet"> -->
                                                <!-- 														</div> -->
                                                <!-- 													</div> -->
                                                <!-- 												</div> -->

                                                <!-- 												<app-autocomplete -->
                                                <!-- 													#autocompleteAreaCollectionDtlsFieldSet="appAutocomplete"> -->
                                                <!-- 												<ng-template appAutocompleteContent> <ng-container -->
                                                <!-- 													*ngIf="(branchAreaDataList | filterGodownName: controlAreaCollectionDtlsFieldSet.value) as resultAreaCollectionDtlsFieldSet"> -->
                                                <!-- 												<app-option -->
                                                <!-- 													*ngFor="let option of resultAreaCollectionDtlsFieldSet" -->
                                                <!-- 													(click)="areaMethod(option)" [value]="option.godownName"> -->
                                                <!-- 												{{ option.godownName }} </app-option> <app-option class="no-result" -->
                                                <!-- 													*ngIf="!resultAreaCollectionDtlsFieldSet.length">No -->
                                                <!-- 												result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
                                                <!-- 											</div> -->
                                                <!-- 										the below is commented because in live it were not opening and input on selecting add new bank, but added as per my knowledge  -->
                                                <!-- 							 <div class="col-sm-12 col-md-12"> -->
                                                <!-- 											<div class="form-group"> -->
                                                <!-- 												<div class="input-group" id="{{pageId}}bankName"> -->
                                                <!-- 													<label>Bank Name</label> -->
                                                <!-- 													<div class="input-group-prepend"> -->
                                                <!-- 														<span class="input-group-text"> <i -->
                                                <!-- 															class="fas fa-university"></i> -->
                                                <!-- 														</span> -->
                                                <!-- 													</div> -->
                                                <!-- 													<input type="text" class="form-control" -->
                                                <!-- 														aria-describedby="basic-addon11"> -->
                                                <!-- 												</div> -->
                                                <!-- 											</div> -->
                                                <!-- 										</div> -->
                                                <div *ngIf="viewNewBankName" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>New Bank Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-university"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" class="form-control"
                                                                id="{{pageId}}newBankName"
                                                                [(ngModel)]="enteredNewBankName"
                                                                aria-describedby="basic-addon11" [readonly]="readOnlyFieldsonDestMemoLrGen">
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 							<div *ngIf="viewByCash" class="col-sm-12 col-md-12"> -->
                                                <!-- 								<div class="form-group"> -->
                                                <!-- 									<div class="input-group"> -->
                                                <!-- 										<label>:</label> -->
                                                <!-- 										<div class="input-group-prepend"> -->
                                                <!-- 											<span class="input-group-text"> <i -->
                                                <!-- 												class="fas fa-rupee-sign"></i> -->
                                                <!-- 											</span> -->
                                                <!-- 										</div> -->
                                                <!-- 										<input type="number" class="form-control" id="{{pageId}}amount" #amount -->
                                                <!-- 											aria-describedby="basic-addon11"> -->
                                                <!-- 									</div> -->
                                                <!-- 								</div> -->
                                                <!-- 							</div> -->
                                                <div *ngIf="viewByReferenceNumber" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Reference Number</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-file-alt"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" class="form-control"
                                                                id="{{pageId}}referenceNumber"
                                                                [(ngModel)]="enteredReferenceNumber"
                                                                aria-describedby="basic-addon11" [readonly]="readOnlyFieldsonDestMemoLrGen">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer" style='border-bottom: 1px solid #e9ecef;'>
                                        <div class="row">
                                            <button type="button" class="btn btn-outline-dark"
                                                (click)="methodToStoreData();d('Cross click')">OK</button>

                                            <button type="button" class="btn btn-outline-dark"
                                                (click)="c('Save click')">Close</button>
                                        </div>
                                    </div>


                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <ng-template #reasonForEdit let-c="close" let-d="dismiss">
                                    <div class="modal-header">
                                        <h6 class="modal-title" id="{{pageId}}modal-basic-title">Reason For Edit
                                        </h6>
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="d('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body ">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="form-group">
                                                        <label>Reason</label>
                                                        <div class="form-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class=" fas fa-question"></i>
                                                            </span>
                                                        </div>
                                                        <textarea #reasonEdit class="form-control" rows="2"
                                                            id="{{pageId}}reasonEdit" (keyup)="reasonEditFocus($event)"
                                                            name="reasonEdit" [(ngModel)]="reasonEditValue"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer" style='border-bottom: 1px solid #e9ecef;'>
                                        <div class="row">
                                            <button type="button" class="btn btn-outline-dark"
                                                id="{{pageId}}confirmEdit"
                                                (click)="d('Cross click');btnEditConfirm()">Confirm
                                                Edit</button>
                                            <!-- 										<button type="button" class="btn btn-outline-dark" -->
                                            <!-- 											(click)="c('Save click')">Close</button> -->
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>



                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <ng-template #reasonForDelete let-c="close" let-d="dismiss">
                                    <div class="modal-header">
                                        <h6 class="modal-title" id="{{pageId}}modal-basic-title">Reson For Delete
                                        </h6>
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="d('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body ">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="form-group">
                                                        <label>Reason</label>
                                                        <div class="form-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class=" fas fa-question"></i>
                                                            </span>
                                                        </div>
                                                        <textarea #reasonDelete class="form-control" rows="2"
                                                            id="{{pageId}}reasonDelete"
                                                            (keyup)="reasonDeleteFocus($event)" name="reasonDelete"
                                                            [(ngModel)]="reasonDeleteValue"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer" style='border-bottom: 1px solid #e9ecef;'>
                                        <div class="row">
                                            <button type="button" class="btn btn-outline-dark"
                                                id="{{pageId}}confirmDelete"
                                                (click)="d('Cross click');btnDeleteConfirm()">Confirm
                                                Delete</button>
                                            <!-- 										<button type="button" class="btn btn-outline-dark" -->
                                            <!-- 											(click)="c('Save click')">Close</button> -->
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button *ngIf="viewSaveBtn" type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn"
                            (click)="btnSave()" disabled>Save</button>
                        <!-- 		open3(reasonForEdit)			open3(reasonForDelete)		 -->
                        <button *ngIf="viewUpdateBtn" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}updateBtn" (click)="btnUpdate(reasonForEdit)" >Update</button>
                        <!-- 						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}cashBtn" -->
                        <!-- 							(click)="openPaymentTypePopup( paymentMethodPopup )">Cash</button> -->
                        <button *ngIf="viewPrintBtn" type="submit" class="btn btn-dark" id="{{pageId}}printBtn"
                            (click)="methodForPrintBtn()">Print</button>
                        <button *ngIf="viewDeleteBtn" type="submit" class="btn btn-danger" id="{{pageId}}deleteBtn"
                            (click)="deleteBtn(reasonForDelete)">Delete</button>
                        <!-- 							(click)="printMemo()" -->
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}clearBtn" #clearBtn
                            (click)="clearFieldsValidate()">Clear</button>
                        <button *ngIf="viewTransitMemoPrtBtn" type="submit" class="btn btn-dark"
                            id="{{pageId}}btnPrintTransitmemo" (click)="methodForPrintTransitMemo()">Print Transit
                            Memo</button>
                        <button *ngIf="viewCodLrEnable" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}enableCodMemoBtn" (click)="methodForCodEnableLr()"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Enable COD Memo</button>
                        <!-- 						<button type="submit" class="btn btn-dark" id="{{pageId}}deleteBtn">Delete</button> -->
                        <button *ngIf="viewEnableBankLr" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}enableBankLrBtn" (click)="methodForEnableBankLr()"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Enable Bank Lr</button>

                        <button *ngIf="viewEnableSelfLr" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}enableSelfLrBtn" (click)="methodForEnableSelfLr()"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Enable Self Lr</button>

                        <button *ngIf="viewEnableBlockLr" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}enableBlockLrBtn" (click)="methodForEnableBlockLr()"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Enable Block Lr</button>
                        <button *ngIf="viewHamaliEditEnable" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}enableHamaliEditMemoBtn" (click)="methodForUpdateHamaliConfirm()"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Enable
                            Hamali/Other Edit</button>
                        <button *ngIf="viewCCCopyEditEnable" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}enableCCCopyEditMemoBtn" (click)="methodForEnableCCCopyConfirm()"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Enable CC
                            Copy LR</button>
                        <button *ngIf="viewDemurageLREnable" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}enableDemurageLRBlockMemoBtn"
                            (click)="methodForEnableDemurageConfirm()"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Enable Demurage LR</button>
                        <button *ngIf="viewEditDelvBtn" type="submit" class="btn btn-dark" (click)="editDelvBtn()"
                            id="{{pageId}}editDelvBtn"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Edit</button>
                        <button *ngIf="viewEnableDuplicateMemoBtn" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}enableDuplicateMemoBtn" (click)="methodForEnableDuplicateMemo()"
                            disabled  [readonly]="readOnlyFieldsonDestMemoLrGen" >Enable
                            Duplicate Memo</button>

                        <button *ngIf="viewEditBtn" type="submit" class="btn btn-dark" (click)="editBtn()"
                            id="{{pageId}}editBtn"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Edit</button>
                        <button *ngIf="viewTransitMemoEnableBtn" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}btnEnableTransitMemo" (click)="methodForEnableTransitMemo()" disabled>Enable
                            Transit Memo</button>
                        <button *ngIf="viewGenerateCEWbBtn" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}generateCWB"
                            (click)="generateConsolidateEwaybillForGeneratedMemo('regenerate')"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Generate Consolidated
                            EWB</button>

                        <button *ngIf="printGenerateCEWbBtn" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}printCWB" (click)="printCEWB()"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Download Consolidated EWB</button>

                        <button *ngIf="viewGenerateDCBtn" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}generateDC" (click)="generateDCForGeneratedMemo('regenerate')"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Generate
                            Delivery Challan</button>

                        <button *ngIf="printGenerateDCBtn" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}printDC" (click)="printDCEwaybill()"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Download Delivery Challan</button>
                        <!-- 													(click)="openCommodityList(commodityList)" -->

                        <button *ngIf="viewMemoFieldsEdit" type="submit" class="btn btn-success m-r-10"
                            id="{{pageId}}enableMemoFieldsEdit" (click)="methodForEnableMemoFieldsEdit()"  [readonly]="readOnlyFieldsonDestMemoLrGen" >Enable Memo
                            Fields Edit</button>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>




    <div class="col-md-12" *ngIf="viewCommodityPopup">
        <div class="form-group">
            <div class="input-group">
                <ng-template #commodityList let-c="close" let-d="dismiss">
                    <div class="modal-header">
                        <h6 class="modal-title" id="{{pageId}}modal-basic-title">Commodity Details
                        </h6>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body ">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="col-sm-12 col-md-12">
                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Goods Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-university"></i>
                                                    </span>
                                                </div>
                                                <input class="auto_selectOption input is-medium"
                                                    placeholder="Select Commodity" [formControl]="controlGoodsType"
                                                    id="{{pageId}}goodsType" #goodsType
                                                    [appAutocomplete]="autocompleteGoodsType">
                                            </div>
                                        </div>
                                    </div>

                                    <app-autocomplete #autocompleteGoodsType="appAutocomplete">
                                        <ng-template appAutocompleteContent>
                                            <ng-container
                                                *ngIf="(dataListOfCommodity | filterSubCommodity: controlGoodsType.value) as resultGoodsType">
                                                <app-option (click)="goodsTypeMethod(option)"
                                                    *ngFor="let option of resultGoodsType"
                                                    [value]="option.subCommodity"> {{ option.subCommodity }}
                                                </app-option>
                                                <app-option class="no-result" *ngIf="!resultGoodsType.length">No result
                                                </app-option>
                                            </ng-container>
                                        </ng-template>
                                    </app-autocomplete>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" style='border-bottom: 1px solid #e9ecef;'>
                        <div class="row">
                            <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')">OK</button>

                            <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')">Close</button>
                        </div>
                    </div>
                    <!-- 								<div class="modal-footer" style='border-bottom: 1px solid #e9ecef;'> -->
                    <!-- 					<div class="row"> -->
                    <!-- 						<button type="button" class="btn btn-outline-dark" -->
                    <!-- 							(click)="noLrFieldsBtnOk()">OK</button> -->
                    <!-- 						<button type="button" class="btn btn-outline-dark" -->
                    <!-- 							(click)="noLrFieldsBtnClose()">Close</button> -->
                    <!-- 					</div> -->
                    <!-- 				</div> -->
                </ng-template>
            </div>
        </div>
    </div>
    <div *ngIf="!isQrPrintMemo">
        <div *ngIf="viewCashMemoPrint" onafterprint="afterPrint()" id="{{pageId}}printCashMemo">
            <app-cashmemo-genrate-print></app-cashmemo-genrate-print>
        </div>
    </div>

    <div *ngIf="isQrPrintMemo">
        <div *ngIf="viewCashMemoPrint" onafterprint="afterPrint()" id="{{pageId}}printCashMemo">
            <app-cashmemo-genrate-print-qr></app-cashmemo-genrate-print-qr>
        </div>
    </div>
</body>