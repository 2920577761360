//Chg_Ver_PLC_01==As per rights for agents blocking fields and features added by imran on 20052204
//Chg_Ver_PLC_02==Blocking discount calculations if the size exceeded added by Imran on 02/08/2024 REF_Sprint-2_Point_02_4
//Chg_Ver_PLC_03 == If the Lr belongs to difernt destination blocked saving less amount 02/09/2024
//Chg_Ver_PLC_04 : Validate on save lrLessCheckDetails (Asrar Jr / 09/09/2024)
//Chg_Ver_PLC_05 : Get commodity data in the dropdown if available at the source; otherwise, get data from group by source (Asrar Jr / 29-10-2024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModalRef, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import *  as moment from 'moment';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { PartyLessCheckDetailsComponent } from 'src/app/fortnightStatement/others/party-less-check-details/party-less-check-details.component';
import { MatDialog } from '@angular/material/dialog';
import { MlessCheckPopupComponent } from 'src/app/fortnightStatement/others/mless-check-popup/mless-check-popup.component';
import { LrService } from 'src/app/dataService/lr-service';
import { PartyLessCheckDetailsV2Component } from '../party-less-check-details-V2/party-less-check-details-V2.component';
import { MemoReport } from 'src/app/dataService/memo-report';
import { ReportService } from 'src/app/dataService/report-service';
// import { saveAs } from 'file-saver';
import * as fileSaver from 'file-saver';
// import FileSaver = require('file-saver');
@Component({
    selector: 'app-party-less-check',
    templateUrl: './party-less-check.component.html',
    styleUrls: ['./party-less-check.component.css']
})
export class PartyLessCheckComponent implements OnInit {
    getDataFrmServiceFrTable: any;
    totalLessDataList: any;
    enteredLessDataList: any;
    autoLessDataList: any;
    stmtNoDetailsDataList: any;
    stmtNoMainSrcDetailsDataList: any;
    stmtNoBlockedLrDataList: any;
    checkedUncheckedLrsDatewiseList: any;
    enteredLessLrsDatewiseList: any;
    onDestroyUnsubscribtionStatementReport: Subscription;
    loadingIndicator = true;
    searchByType: string;
    rptMode: string;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerTotalLess: Subject<any> = new Subject();
    dtTriggerEnteredLess: Subject<any> = new Subject();
    dtTriggerAutoLess: Subject<any> = new Subject();
    dtTriggerStmtNoWise: Subject<any> = new Subject();
    dtTriggerStmtNoMainSrcWise: Subject<any> = new Subject();
    dtTriggerStmtNoBlockedLr: Subject<any> = new Subject();
    dtTriggerLrDetailsChkUnCkhdDateWise: Subject<any> = new Subject();
    dtTriggerEnteredLessDatewise: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsTotalLess: any;
    dtOptionsAutoLess: any;
    dtOptionsEnteredLess: any;
    dtOptionsStmtNoWise: any;
    dtOptionsStmtNoMainSrcWise: any;
    dtOptionsStmtNoBlockedLr: any;
    dtOptionsLrDetailsChkUnCkhdDateWise: any;
    dtOptionsEnteredLessDatewise: any;

    validateFromDate: any;
    validateToDate: any;
    validateDestination: any;
    viewChkUnCkhedLessTable = true;
    viewEnteredLessTable = false;
    viewAutoLessTable = false;
    viewStmtNoWiseTable = false;
    viewDateWiseTable = false;
    viewChkUnCkhedLessDatewiseTable = false;
    viewEnteredLessDatewiseTable = false;
    enteredStmtNo: any;
    lrDtoMainSrc: LRDto = new LRDto();

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    showSpinnerForAction = false;
    validateStatementNumber: any = null;
    enteredLrNumber: any;
    selectedAgentName: any;
    selectedDestination: any;
    selectedState: any;
    commodityComboDataList: any;
    returnRemarks: any;
    lrDtoForUpdateInsertLRLess: LRDto[];
    lrDtoForUpdateInsertLRLessReturn = null;
    lrDtoForIsLrLessChecked: LRDto[];
    lrDtoForIsLrLessCheckedReturn: any;
    enteredLessAmt: any;
    selectedConsigneeId: any;
    selectedConsigneeName: any;
    selectedCommodity: any;
    lrDtoForRcMemoSingleLr: LRDto[];
    lrDtoForRcMemoCallListReturn: any;

    searchByAuthorityFortNightDto: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();
    lrDtoForExport: LRDto = new LRDto();
    fortNightDtoLocalStorage: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoBlockedLr: FortNightStmtDto = new FortNightStmtDto();
    modalRefferenceStmtVerifcationPopUp: NgbModalRef;
    modalRefferenceStmtVerifcationClosePopUp: string;
    @ViewChild('stmtVerificationLrsDetailsPopUpTemplate') private stmtVerificationLrsDetailsPopUpTemplate;

    viewStmtVerificationRptPopUp = false;
    validateLocalStorageStmtNo: any = null;

    rightsForStmtVerifRpt = "Right For Stmt Verification SuperAdmin"
    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;
    totLrSummary: number;
    totArtSummary: number;
    actWghtSummary: number;
    chgWghtSummary: number;
    toPaySummary: number;
    paidSummary: number;
    servTaxSummary: number;

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    commodityComboTA: Array<any> = [];
    @ViewChild('instanceCommodityCombo') instanceCommodityCombo: NgbTypeahead;
    focusCommodityComboTA$ = new Subject<string>();
    commodityComboSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityComboTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityComboTA
                : this.commodityComboTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    // fromPeriodForExport: any;
    // toPeriodForExport: any;
    fortNightStmtDtoDiffLr: FortNightStmtDto = new FortNightStmtDto();
    modalRefferenceUploadPopUp: NgbModalRef;
    modalRefferenceUploadClosePopUp: string;
    uploadPopupStmtNo: any;
    selectedPartyLessChkStmt1Details: File;
    selectedPartyLessChkStmt2Details: File;
    cashMemoDtoForPartyLesChkUpdate: CashMemoDto = new CashMemoDto();
    isUploaded: boolean = false;
    showSpinnerForActionForUpload = false;

    pageId = "plchkc";
    viewAdminFields: boolean = false;
    stmtNoDetailsSpecificStatementData: any;
    lrDtoFinStatusUpdate: LRDto = new LRDto();
    blockLessCheckFinishedFields: boolean = false;
    enteredRemarks: any;
    isSizeExceeded: Boolean = false;
    constructor(private destStmtReport: DestStmtReport,
        private router: Router,
        private masterReadService: MasterReadService,
        public dialog: MatDialog,
        private lrService: LrService,
        public changeDetectorRef: ChangeDetectorRef,
        private modalService: NgbModal,
        private memoReport: MemoReport,
        private reportService: ReportService) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            //by defualy mode
            this.rptMode = "Chkd Ann Un Chkd Lrs";
            // this.searchByType = "chkedUnChked";
            // this.getDestinationDetails(this.searchByType);
            //Chg_Ver_PLC_01
            for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

                if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                    && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Party Less Check Admin View") {
                    this.viewAdminFields = true;
                    this.searchByType = "chkedUnChked";
                }
            }
            if (!this.viewAdminFields) {
                this.searchByType = "stmtNoWise";
            }
            this.searchByMode(this.searchByType);
            this.getDestinationDetails(this.searchByType);
        }
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    ngOnInit(): void {
        var exportColumnsForStmtNoWise = [];
        if (this.viewAdminFields) {
            exportColumnsForStmtNoWise = [0, 13, 1, 2, 3, 25, 14, 4, 5, 6, 15, 7, 8, 9, 10, 11, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 27, 28, 29, 30, 31, 32, 33];
        } else {
            exportColumnsForStmtNoWise = [0, 13, 1, 2, 3, 25, 14, 4, 5, 6, 15, 8, 10, 11];
        }
        this.dtOptionsTotalLess = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "Party Less Check - Checked & Unchecked - " +
                            "From Date : " + moment($("#plchkcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#plchkctoDates").val()).format('DD-MM-YYYY') + " - " +
                            "Destination : " + $("#plchkcdestinationId").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var totLrs = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totLess = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var lessLrs = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var checkedLrs = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var unCheckedLrs = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actLess = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var enteredLess = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var diffLess = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(5).footer()).html(totLrs);
                $(api.column(6).footer()).html(totAmt);
                $(api.column(7).footer()).html(totLess);
                $(api.column(8).footer()).html(lessLrs);
                $(api.column(9).footer()).html(checkedLrs);
                $(api.column(10).footer()).html(unCheckedLrs);
                $(api.column(11).footer()).html(actLess);
                $(api.column(12).footer()).html(enteredLess);
                $(api.column(13).footer()).html(diffLess);
            },
        },
            this.dtOptionsAutoLess = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Export</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            return "Party Less Check - Auto Less - " +
                                "From Date : " + moment($("#plchkcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#plchkctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + $("#plchkcdestinationId").val() + "";
                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var totLrs = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totSysLess = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(4).footer()).html(totLrs);
                    $(api.column(5).footer()).html(totSysLess);
                },
            },
            this.dtOptionsEnteredLess = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Export</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            return "Party Less Check - Entered Less - " +
                                "From Date : " + moment($("#plchkcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#plchkctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + $("#plchkcdestinationId").val() + "";
                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var totLrs = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totEntLessChk = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totArt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totActWgt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totChgWgt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totActLess = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totEntLess = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totDiffLess = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html("");
                    $(api.column(2).footer()).html("");
                    $(api.column(3).footer()).html("");
                    $(api.column(4).footer()).html(totLrs);
                    $(api.column(5).footer()).html(totEntLessChk);
                    $(api.column(6).footer()).html(totArt);
                    $(api.column(7).footer()).html(totActWgt);
                    $(api.column(8).footer()).html(totChgWgt);
                    $(api.column(9).footer()).html(totActLess);
                    $(api.column(10).footer()).html(totEntLess);
                    $(api.column(11).footer()).html(totDiffLess);
                },
            },
            this.dtOptionsStmtNoWise = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        footer: true,
                        text: '<i class="fas fa-file-excel"> Export</i>',
                        messageTop: function () {
                            // this.messageTopMethod();
                            var returnHeader = null;

                            returnHeader = " Agent Name: " + $("#plchkcagentName").val()
                                + "   Statement Number: " + $("#plchkcstmtNo").val()
                                + "   Statement Period : " + $("#plchkcfromPeriodForExport").val()
                                + " -  " + $("#plchkctoPeriodForExport").val();
                            //+ "   Date : " + moment($("#plchkcfromDate").val()).format('DD-MM-YYYY');
                            return returnHeader;
                            // var returnHeader = null;
                            // returnHeader = " Agent Name:: " + $("#plchkcagentName") + " Statement Number: " + $("#plchkcstmtNo").val() +
                            //     " Statement Period : " + this.fromPeriodForExport + " -  " +
                            //     "" + this.toPeriodForExport +
                            //     " Date : " + this.todayDate;
                            // return returnHeader;
                        },
                        messageBottom: function () {
                            var returnFooter = null;
                            returnFooter = "Note: 0 is displayed if the diff. between Actual less and entered less is between -1 to +1 ";
                            return returnFooter;
                        },
                        customize: function (xlsx: { xl: { worksheets: { [x: string]: any; }; }; }) {
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];

                            //for border in table export
                            $('row c', sheet).attr('s', '25');
                            $('row c[r^="E"]', sheet).attr('s', '25');
                            $('row c[r^="N"]', sheet).attr('s', '25');

                            // for first column to be bold
                            $('row:first c', sheet).attr('s', '2');
                            //$('row:eq(0) c', sheet).attr( 's', '3' );
                            // for second and third column to be bold
                            $('row:nth-child(2) c', sheet).attr('s', '2');
                            $('row:nth-child(3) c', sheet).attr('s', '2');
                            // for last column to be bold
                            $('row:last c', sheet).attr('s', '2');
                            // for width to be declare obj
                            var col = $('col', sheet);
                            // width set for all 
                            // $(col).attr('width', 13);
                            // width set for particular
                            $(col[0]).attr('width', 5);
                            $(col[1]).attr('width', 10);
                            $(col[2]).attr('width', 11);
                            $(col[3]).attr('width', 5);
                            $(col[4]).attr('width', 20);
                            $(col[5]).attr('width', 9);
                            $(col[6]).attr('width', 7);
                            $(col[7]).attr('width', 6);
                            $(col[8]).attr('width', 8);
                            $(col[9]).attr('width', 5);
                            $(col[10]).attr('width', 8);
                            $(col[11]).attr('width', 8);
                            $(col[12]).attr('width', 8);
                            $(col[13]).attr('width', 8);
                            $(col[14]).attr('width', 20);
                            $(col[15]).attr('width', 20);
                            $(col[16]).attr('width', 6);
                            $(col[17]).attr('width', 8);
                            $(col[18]).attr('width', 5);
                            $(col[19]).attr('width', 5);
                            $(col[20]).attr('width', 5);
                            $(col[21]).attr('width', 5);
                            $(col[22]).attr('width', 5);
                            $(col[23]).attr('width', 5);
                            $(col[25]).attr('width', 5);
                            $(col[26]).attr('width', 5);
                            $(col[27]).attr('width', 5);
                            $(col[28]).attr('width', 5);
                            $(col[29]).attr('width', 5);
                            $(col[30]).attr('width', 5);
                            $(col[31]).attr('width', 5);
                            $(col[32]).attr('width', 5);
                            $(col[33]).attr('width', 5);

                            // for wrap text for particulr column  
                            $('row c[r^="E"]', sheet).attr('s', '55');
                            $('row c[r^="N"]', sheet).attr('s', '55');
                            // for wrap text for all
                            // $('row c', sheet).attr('s', '55');

                        },
                        titleAttr: 'Export All',
                        title: 'Party Less Details Report (Note:Negative(-) sign indicates excess in Agent Entered Less Amount)',
                        exportOptions: {
                            columns: exportColumnsForStmtNoWise
                            //stripHtml: false
                        }
                    },
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var totArt = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totActWgt = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totChgWgt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totEntLess = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totActLess = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totLessDiff = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    //$(api.column(0).footer()).html('Total : ' + data.length);
                    // $(api.column(3).footer()).html(totArt);
                    // $(api.column(4).footer()).html(totActWgt);
                    // $(api.column(5).footer()).html(totChgWgt);
                    $(api.column(8).footer()).html((totEntLess).toFixed(0));
                    $(api.column(7).footer()).html((totActLess).toFixed(0));
                    $(api.column(9).footer()).html((totLessDiff).toFixed(0));
                }
            },
            this.dtOptionsStmtNoMainSrcWise = {
                dom: 'Bfrtip',
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                buttons: [],
                searching: false,
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 150,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var totActLess = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var totEntLess = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totLessDiff = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html((totActLess).toFixed(0));
                    $(api.column(3).footer()).html((totEntLess).toFixed(0));
                    $(api.column(4).footer()).html((totLessDiff).toFixed(0));
                },
            },
            this.dtOptionsStmtNoBlockedLr = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                searching: false,
                responsive: true,
                "scrollX": true,
                "scrollY": 250,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    // computing column Total of the complete result 
                    var totArt = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totActWgt = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totChgWgt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totEntLess = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totActLess = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totLessDiff = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    // Update footer by showing the total with the reference of the column index 
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    // $(api.column(3).footer()).html(totArt);
                    // $(api.column(4).footer()).html(totActWgt);
                    // $(api.column(5).footer()).html(totChgWgt);
                    $(api.column(7).footer()).html((totEntLess).toFixed(0));
                    $(api.column(6).footer()).html((totActLess).toFixed(0));
                    $(api.column(8).footer()).html((totLessDiff).toFixed(0));
                }
            },
            this.dtOptionsLrDetailsChkUnCkhdDateWise = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        title: function () {
                            return "Party Less Check - Checked & Unchecked Date Wise - " +
                                "From Date : " + moment($("#plchkcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#plchkctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + $("#plchkcdestinationId").val() + "";;
                        },
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 300,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };

                    var toPay = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var mlessAmtTot = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totAmt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var totArt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var actWght = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var actLess = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var entLess = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var diffLess = api.column(13).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total');
                    $(api.column(1).footer()).html(data.length);
                    $(api.column(4).footer()).html(toPay);
                    $(api.column(5).footer()).html("" + (mlessAmtTot == null ? 0 : Math.round(mlessAmtTot)));
                    $(api.column(6).footer()).html(totAmt);
                    $(api.column(7).footer()).html(totArt);
                    $(api.column(8).footer()).html(actWght);
                    $(api.column(9).footer()).html(chgWgt);
                    $(api.column(11).footer()).html(actLess);
                    $(api.column(12).footer()).html(entLess);
                    $(api.column(13).footer()).html(diffLess);
                },
            },
            this.dtOptionsEnteredLessDatewise = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        title: function () {
                            return "Statement Entered LRs Report - Date Wise";
                        },
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 300,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var baseFrgt = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var sHamali = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var lcChg = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var bcChg = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var aocChg = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var fovChg = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var ddChg = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var gcChg = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var othChg = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPay = api.column(13).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var mlessAmtTot = api.column(14).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totAmt = api.column(15).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var totArt = api.column(16).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var actWght = api.column(17).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(18).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var actLess = api.column(20).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var entLess = api.column(21).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var diffLess = api.column(22).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total');
                    $(api.column(1).footer()).html(data.length);
                    $(api.column(4).footer()).html(baseFrgt);
                    $(api.column(5).footer()).html(sHamali);
                    $(api.column(6).footer()).html(lcChg);
                    $(api.column(7).footer()).html(bcChg);
                    $(api.column(8).footer()).html(aocChg);
                    $(api.column(9).footer()).html(fovChg);
                    $(api.column(10).footer()).html(ddChg);
                    $(api.column(11).footer()).html(gcChg);
                    $(api.column(12).footer()).html(othChg);
                    $(api.column(13).footer()).html(toPay);
                    $(api.column(14).footer()).html(mlessAmtTot);
                    $(api.column(15).footer()).html(totAmt);
                    $(api.column(16).footer()).html(totArt);
                    $(api.column(17).footer()).html(actWght);
                    $(api.column(18).footer()).html(chgWgt);
                    $(api.column(20).footer()).html(actLess);
                    $(api.column(21).footer()).html(entLess);
                    $(api.column(22).footer()).html(diffLess);
                },
            }
    }

    ngAfterViewInit(): void {
        this.dtTriggerTotalLess.next();
        this.dtTriggerEnteredLess.next();
        this.dtTriggerAutoLess.next();
        this.dtTriggerStmtNoWise.next();
        this.dtTriggerStmtNoMainSrcWise.next();
        this.dtTriggerStmtNoBlockedLr.next();
        this.dtTriggerLrDetailsChkUnCkhdDateWise.next();
        this.dtTriggerEnteredLessDatewise.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerTotalLess.unsubscribe();
        this.dtTriggerEnteredLess.unsubscribe();
        this.dtTriggerAutoLess.unsubscribe();
        this.dtTriggerStmtNoWise.unsubscribe();
        this.dtTriggerStmtNoMainSrcWise.unsubscribe();
        this.dtTriggerStmtNoBlockedLr.unsubscribe();
        this.dtTriggerLrDetailsChkUnCkhdDateWise.unsubscribe();
        this.dtTriggerEnteredLessDatewise.unsubscribe();
    }

    clearBtn() {
        this.clearField();
    }

    clearField() {
        $("#" + this.pageId + "statementNo").val('');
        $("#" + this.pageId + "statementVerificationTableId").DataTable().destroy();
    }

    searchByMode(searchBy: string) {
        if (searchBy === 'chkedUnChked') {
            this.rptMode = "Chkd Ann Un Chkd Lrs";
            this.viewChkUnCkhedLessTable = true;
            this.viewEnteredLessTable = false;
            this.viewAutoLessTable = false;
            this.viewStmtNoWiseTable = false;
            this.viewDateWiseTable = false;
            this.viewChkUnCkhedLessDatewiseTable = false;
            this.viewEnteredLessDatewiseTable = false;
            this.searchByType = "chkedUnChked";
            this.getDestinationDetails(searchBy);
        } else if (searchBy === 'enteredLess') {
            this.rptMode = "EnteredLess";
            this.viewEnteredLessTable = true;
            this.viewChkUnCkhedLessTable = false;
            this.viewAutoLessTable = false;
            this.viewStmtNoWiseTable = false;
            this.viewDateWiseTable = false;
            this.viewChkUnCkhedLessDatewiseTable = false;
            this.viewEnteredLessDatewiseTable = false;
            this.searchByType = "enteredLess";
            this.getDestinationDetails(searchBy);
        } else if (searchBy === 'autoRateLess') {
            this.rptMode = "Chkd Ann Un Chkd Lrs";
            this.viewAutoLessTable = true;
            this.viewEnteredLessTable = false;
            this.viewChkUnCkhedLessTable = false;
            this.viewStmtNoWiseTable = false;
            this.viewDateWiseTable = false;
            this.viewChkUnCkhedLessDatewiseTable = false;
            this.viewEnteredLessDatewiseTable = false;
            this.searchByType = "autoRateLess";
            this.getDestinationDetails(searchBy);
        } else if (searchBy === 'stmtNoWise') {
            this.rptMode = "Chkd Ann Un Chkd Lrs";
            this.viewAutoLessTable = false;
            this.viewEnteredLessTable = false;
            this.viewChkUnCkhedLessTable = false;
            this.viewDateWiseTable = false;
            this.viewStmtNoWiseTable = true;
            this.viewChkUnCkhedLessDatewiseTable = false;
            this.viewEnteredLessDatewiseTable = false;
            this.searchByType = "stmtNoWise";
            this.getDestinationDetails(searchBy);
        } else if (searchBy === 'stmtDateWise') {
            this.rptMode = "Chkd Ann Un Chkd Lrs";
            this.viewAutoLessTable = false;
            this.viewEnteredLessTable = false;
            this.viewChkUnCkhedLessTable = false;
            this.viewDateWiseTable = true;
            this.viewStmtNoWiseTable = true;
            this.viewChkUnCkhedLessDatewiseTable = false;
            this.viewEnteredLessDatewiseTable = false;
            this.searchByType = "stmtNoWise";
            this.getDestinationDetails(searchBy);
        } else if (searchBy === 'chkedUnChkedDatewise') {
            this.rptMode = "Chkd And Un Chkd Datewise";
            this.viewChkUnCkhedLessTable = false;
            this.viewEnteredLessTable = false;
            this.viewAutoLessTable = false;
            this.viewStmtNoWiseTable = false;
            this.viewDateWiseTable = false;
            this.viewChkUnCkhedLessDatewiseTable = true;
            this.viewEnteredLessDatewiseTable = false;
            this.searchByType = "chkedUnChkedDatewise";
            this.getDestinationDetails(searchBy);
        } else if (searchBy === 'enteredLessDatewise') {
            this.rptMode = "EnteredLess";
            this.viewEnteredLessTable = false;
            this.viewChkUnCkhedLessTable = false;
            this.viewAutoLessTable = false;
            this.viewStmtNoWiseTable = false;
            this.viewDateWiseTable = false;
            this.viewChkUnCkhedLessDatewiseTable = false;
            this.viewEnteredLessDatewiseTable = true;
            this.searchByType = "enteredLessDatewise";
            this.getDestinationDetails(searchBy);
        }
    }

    validateSearchBtn() {
        if (this.searchByType === "stmtNoWise" && (($("#" + this.pageId + "stmtNo").val() == null) || ($("#" + this.pageId + "stmtNo").val() == undefined) ||
            ($("#" + this.pageId + "stmtNo").val() == ""))) {
            swal("Mandatory Fields", "Please enter the statement number", "warning");
            return false;
        } else if (this.searchByType != "stmtNoWise" && (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
            ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
            ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
            ($("#" + this.pageId + "destinationId").val() == null) || ($("#" + this.pageId + "destinationId").val() == undefined) ||
            ($("#" + this.pageId + "destinationId").val() == ""))) {
            swal("Mandatory Fields", "Please select the mandatory fields", "warning");
            return false;
        } else {
            if (this.searchByType === "chkedUnChked") {
                this.getTotalLessReport();
            } else if (this.searchByType === "enteredLess") {
                this.getEnteredLessReport();
            } else if (this.searchByType === "autoRateLess") {
                this.getAutoLessReport();
            } else if (this.searchByType === "stmtNoWise") {
                if (!this.viewAdminFields) {
                    this.getSpecificStatementNoWiseData();

                } else {

                    this.getStmNoWiseEnteredLessLrDetailsReport();
                }

            } else if (this.searchByType === "stmtDateWise") {

                if (!this.viewAdminFields) {

                    this.getSpecificStatementNoWiseData();
                } else {

                    this.getStmNoWiseEnteredLessLrDetailsReport();
                }
            } else if (this.searchByType === "chkedUnChkedDatewise") {
                this.getCheckedUncheckedLrsDatewiseDetails();
            } else if (this.searchByType === "enteredLessDatewise") {
                this.getEnteredLessLrsDatewiseDetails();
            }
        }
    }

    getTotalLessRptDetailsData() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();
        this.validateDestination = $("#" + this.pageId + "destinationId").val();

        this.fortNightStmtDto.fromDate = this.validateFromDate;
        this.fortNightStmtDto.toDate = this.validateToDate
        this.fortNightStmtDto.destination = this.validateDestination;
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDto.branch = this.userDataDtoReturnSession.office;
        this.fortNightStmtDto.status = "StmtRpt";
        this.fortNightStmtDto.mode = "DEST";
        this.fortNightStmtDto.reportMode = this.rptMode;
        $("#" + this.pageId + "textId").val(this.validateDestination);

    }

    getTotalLessReport() {
        this.getTotalLessRptDetailsData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getStmtLessCheckedAndUncheckedLrs(this.fortNightStmtDto).subscribe(
            (response) => {
                this.totalLessDataList = [];
                $("#" + this.pageId + "totalLessTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.totalLessDataList = response;
                    console.log("this.totalLessDataList");
                    console.log(this.totalLessDataList);
                    this.dtTriggerTotalLess.next();
                } else {
                    this.dtTriggerTotalLess.next();
                    swal("Party Less Check", "No datas found for this information!", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            }, () => console.log('done');
    }

    getEnteredLessReport() {
        this.getTotalLessRptDetailsData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getStmtLessCheckedAndUncheckedLrs(this.fortNightStmtDto).subscribe(
            (response) => {
                this.enteredLessDataList = [];
                $("#" + this.pageId + "enteredLessTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.enteredLessDataList = response;
                    this.dtTriggerEnteredLess.next();
                } else {
                    this.dtTriggerEnteredLess.next();
                    swal("Party Less Check", "No datas found for this information!", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            }, () => console.log('done');
    }

    getAutoLessReport() {
        this.getTotalLessRptDetailsData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getStmtLessCheckedAndUncheckedLrs(this.fortNightStmtDto).subscribe(
            (response) => {
                this.autoLessDataList = [];
                $("#" + this.pageId + "autoLessTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.autoLessDataList = response;
                    this.dtTriggerEnteredLess.next();
                } else {
                    this.dtTriggerEnteredLess.next();
                    swal("Party Less Check", "No datas found for this information!", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            }, () => console.log('done');
    }

    getTotalLessRptLrDetailsStmtAllData() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
        this.fortNightStmtDto.stmtNo = this.enteredStmtNo;
        this.fortNightStmtDto.mode = "Entredlesslrs";
        this.fortNightStmtDto.reportMode = "StmtLess";
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getStmNoWiseEnteredLessLrDetailsReport() {

        this.getTotalLessRptLrDetailsStmtAllData();
        this.showSpinnerForAction = true;
        $("#" + this.pageId + "textId").val("");
        $("#" + this.pageId + "agentName").val("");
        $("#" + this.pageId + "fromPeriodForExport").val('');
        $("#" + this.pageId + "toPeriodForExport").val('');
        this.destStmtReport.getLessUncheckedLrDetailsV2(this.fortNightStmtDto).subscribe(
            (response) => {
                this.stmtNoDetailsDataList = [];
                $("#" + this.pageId + "stmtNoWiseTableId").DataTable().destroy();
                this.stmtNoMainSrcDetailsDataList = [];
                $("#" + this.pageId + "stmtNoMainSrcWiseTableId").DataTable().destroy();
                this.stmtNoBlockedLrDataList = [];
                $("#" + this.pageId + "stmtNoBlockedLrTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.stmtNoDetailsDataList = response;

                    //console.log("less ...." + this.stmtNoDetailsDataList[3].DDCharge + "-" + this.stmtNoDetailsDataList[3].AOCCharge + "-" + this.stmtNoDetailsDataList[3].HamaliCharge);
                    //console.log("less ...." + this.stmtNoDetailsDataList[3].ddChargeNew + "-" + this.stmtNoDetailsDataList[3].aocChargeNew + "-" + this.stmtNoDetailsDataList[3].hamaliCharge);
                    $("#" + this.pageId + "textId").val(this.stmtNoDetailsDataList[0].destination);

                    $("#" + this.pageId + "agentName").val(this.stmtNoDetailsDataList[0].destination);
                    let from = null;
                    let to = null;
                    from = $("#" + this.pageId + "fromPeriodForExport").val(this.stmtNoDetailsDataList[0].fromPeriodStr);
                    to = $("#" + this.pageId + "toPeriodForExport").val(this.stmtNoDetailsDataList[0].toPeriodStr);
                    // console.log("from, to");
                    // console.log(from, to);
                    this.dtTriggerStmtNoWise.next();

                    this.getStmtNoMainSrcWiseReport();
                    this.getDestinationForLREntryFormForLessCheck(this.stmtNoDetailsDataList[0].destination);
                    this.getStmtNoBlockedLrReport();
                    //Chg_Ver_PLC_01
                    if ((!this.viewAdminFields) && (this.stmtNoDetailsDataList[0].status != null && this.stmtNoDetailsDataList[0].status == 'Finished')) {
                        this.blockLessCheckFinishedFields = true

                    } else {
                        this.blockLessCheckFinishedFields = false;
                    }

                } else {
                    this.dtTriggerStmtNoWise.next();
                    this.dtTriggerStmtNoMainSrcWise.next();
                    this.dtTriggerStmtNoBlockedLr.next();
                    swal("Party Less Check", "No datas found for this information!", "info");
                    this.showSpinnerForAction = false;
                    this.blockLessCheckFinishedFields = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            }, () => console.log('done');
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails(mode) {
        if (!this.viewAdminFields) {
            this.destinationTA = [];
            this.lrDtoDestination = new LRDto();
            this.lrDtoDestination.destination = this.userDataDtoReturnSession.office;
            console.log(this.userDataDtoReturnSession.office + ">>>>>>" + this.lrDtoDestination)
            this.destinationTA.push(this.lrDtoDestination);
        } else {
            this.showSpinnerForAction = true;
            this.getDestinationMethod();
            this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
                (response) => {
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        if (mode === 'chkedUnChked') {
                            this.lrDtoDestination = new LRDto();
                            this.lrDtoDestination.destination = "ALL";
                            this.destinationTA.push(this.lrDtoDestination);
                        }
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                        this.showSpinnerForAction = false;
                    } else {
                        this.showSpinnerForAction = false;
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    this.showSpinnerForAction = false;
                    swal("Server Error", "While Getting destination details", "warning");
                },
                () => console.log('done');
        }

    };

    totalLrsDetailsPopup(totalLessData, mode) {
        localStorage.clear();
        localStorage.setItem('totalLessDataPopup', JSON.stringify(totalLessData));
        localStorage.setItem('totalLessMode', JSON.stringify(mode));
        const dialogRef = this.dialog.open(PartyLessCheckDetailsComponent, {

        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            // this.router.navigate(['.'], { relativeTo: this.route });
        });
    }

    getStmtMainScrWiseUserDetails() {
        this.lrDtoMainSrc = new LRDto();
        this.lrDtoMainSrc.fortNightStmtNo = this.enteredStmtNo;
    }

    getStmtNoMainSrcWiseReport() {
        this.getStmtMainScrWiseUserDetails();
        this.destStmtReport.getLrLessCheckDetailsSrcGrouped(this.lrDtoMainSrc).subscribe(
            (response) => {
                this.stmtNoMainSrcDetailsDataList = [];
                $("#" + this.pageId + "stmtNoMainSrcWiseTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.stmtNoMainSrcDetailsDataList = response;
                    this.dtTriggerStmtNoMainSrcWise.next();
                } else {
                    this.dtTriggerStmtNoMainSrcWise.next();
                    swal("Party Less Check", "No datas found for this information!", "info");
                    this.showSpinnerForAction = false;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            }, () => console.log('done');
    }


    mLessDetailsPopup(): void {
        this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
        localStorage.clear();
        localStorage.setItem('mLessStmtNo', this.enteredStmtNo);
        const dialogRef = this.dialog.open(MlessCheckPopupComponent, {
        });

        dialogRef.afterClosed().subscribe(result => {
            // this.router.navigate(['.'], { relativeTo: this.route });
        });
    }

    confirmRemoveStmtWise(stmtNoDetailsData) {
        if ((!this.viewAdminFields) && (this.stmtNoDetailsDataList[0].status != null && this.stmtNoDetailsDataList[0].status == 'Finished')) {

            swal("Not Allowed", "Less check for This statement has been generated so can't delete the LR!", "warning");
        } else {
            swal({
                title: "Confirmation",
                text: "Sure you want to delete the less details of selected LR?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.deleteLRLessStmtWise(stmtNoDetailsData);

                }
            })
        }

    }

    deleteLRLessStmtWise(stmtNoDetailsData) {
        this.showSpinnerForAction = true;
        this.lrDtoMainSrc = new LRDto();
        this.lrDtoMainSrc.lrNumber = stmtNoDetailsData.lrNumber;

        this.lrService.deleteLRLessCheck(this.lrDtoMainSrc).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Success", "Selected LR less details deleted successfully!", "info");
                    this.getStmNoWiseEnteredLessLrDetailsReport();
                } else if (response.status == "Failed") {
                    swal("Failed", "Failed to delete the details!", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while deleting the details", "info");
            }, () => console.log('done');
    }

    getDestinationForLREntryFormForLessCheck(agent) {
        this.lrDtoMainSrc = new LRDto();
        this.lrDtoMainSrc.mode = "specific";
        this.lrDtoMainSrc.destination = agent;
        this.lrDtoMainSrc.companyId = this.userDataDtoReturnSession.companyId;

        this.masterReadService.getDestinationForLREntryFormForLessCheck(this.lrDtoMainSrc).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal("Not Found", "No record found for this LR Agent", "info");
                } else {
                    this.selectedDestination = response[0].destination;
                    this.selectedState = response[0].state;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server problem occurred while getting the details", "info");
            }, () => console.log('done');
    }

    /* getDestinationForLREntryForm(){
         this.lrDtoMainSrc = new LRDto();
         this.lrDtoMainSrc.lrNumber = this.enteredLrNumber;
     	
         this.destStmtReport.getDiscountAmtMemoLrEntry(this.lrDtoMainSrc).subscribe(
                 (response) => {
                     if (response.agentId == null) {
                         swal("Not Found", "No record found for this LR Number", "info");
                     } else if (response.agentId == this.selectedAgentName) {
                         this.setFieldsValues(response);
                     }
                     this.showSpinnerForAction = false;
                     this.changeDetectorRef.detectChanges();
                 }), (error) => {
                     this.showSpinnerForAction = false;
                     swal("Error", "Server problem occurred while deleting the details", "info");
                 }, () => console.log('done');
     }*/

    lrSearchMtd(e) {
        if (e.keyCode == 13) {
            this.selectedAgentName = $("#" + this.pageId + "agentName").val();
            this.enteredLrNumber = $("#" + this.pageId + "lrNo").val();
            this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
            if ((this.enteredStmtNo == null) || (this.enteredStmtNo == undefined) || (this.enteredStmtNo == "")) {
                swal("Not allowed", "Please enter the Statement Number to proceed", "info");
            } else if ((this.enteredLrNumber == null) || (this.enteredLrNumber == undefined) || (this.enteredLrNumber == "")) {
                swal("Not allowed", "Please enter the LR Number to proceed", "info");
            } else {
                //this.clearInputFields();
                this.getLrEnquiryDetails();
            }

        }
    }

    getLrEnquiryDetails() {
        this.lrDtoMainSrc = new LRDto();
        this.lrDtoMainSrc.lrNumber = this.enteredLrNumber;
        this.lrDtoMainSrc.companyId = this.userDataDtoReturnSession.companyId;
        this.destStmtReport.getDiscountAmtMemoLrEntry(this.lrDtoMainSrc).subscribe(
            (response) => {
                if (response.agentId == null) {
                    swal("Not Found", "No record found for this LR Number", "info");
                } else if (response.agentId.toLowerCase() == this.selectedAgentName.toLowerCase()) {
                    console.log("agent names1 : " + response.agentId.toLowerCase() + "-" + this.selectedAgentName.toLowerCase());
                    this.selectedConsigneeId = response.consigneeId;
                    this.selectedConsigneeName = response.consigneeName;
                    // alert("Check>>>>>>" + response.isSizeExceeded)
                    this.isSizeExceeded = response.isSizeExceeded;
                    this.setFieldsValues(response);
                    this.getCommodityFromAdminRateMater(response);
                    //$("#" + this.pageId + "lessAmt").focus();
                    $("#" + this.pageId + "remarks").focus();
                } else {
                    //Chg_Ver_PLC_03
                    console.log("agent names2 : " + response.agentId.toLowerCase() + "-" + this.selectedAgentName.toLowerCase());
                    swal("Not allowed", "This Lr No : " + this.enteredLrNumber + " Is Blocked Due To Different Agent Name", "info");
                    $("#" + this.pageId + "lrNo").val('');
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while getting the details", "info");
            }, () => console.log('done');
    }

    setFieldsValues(response) {
        $("#" + this.pageId + "discountAmt").val(response.discountAmount == null ? 0 : response.discountAmount);
        $("#" + this.pageId + "masterRate").val(response.rate);
        $("#" + this.pageId + "masterGcChg").val(response.gcCharge);
        $("#" + this.pageId + "masterDestHamali").val(response.destination);
        $("#" + this.pageId + "masterRecChg").val(response.receiptCharge);
        $("#" + this.pageId + "masterSerChg").val(response.serviceCharge);
        $("#" + this.pageId + "masterSource").val(response.source);
        $("#" + this.pageId + "masterPerUnit").val(response.ratePerUnit);
        $("#" + this.pageId + "masterDiscount").val(response.mode);
    }

    getCommodityFromAdminRateMater(data) {
        this.lrDtoMainSrc = new LRDto();
        this.lrDtoMainSrc.source = data.agentName;
        this.lrDtoMainSrc.consigneeId = data.consigneeId;
        this.lrDtoMainSrc.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoMainSrc.mode = "partyLessCheck";
        this.lrDtoMainSrc.state = this.selectedState;
        this.lrDtoMainSrc.destination = this.selectedDestination;
        console.log(this.lrDtoMainSrc);

        //Chg_Ver_PLC_05
        // this.masterReadService.getCommodityFromAdminRateMaster(this.lrDtoMainSrc).subscribe(
        this.masterReadService.getCommodityFromAdminRateMasterGroupBySource(this.lrDtoMainSrc).subscribe(
            (response) => {
                if (response) {
                    this.commodityComboDataList = null;
                    this.commodityComboDataList = response;
                    this.commodityComboTA = [];
                    for (let i = 0; i < this.commodityComboDataList.length; i++) {
                        this.commodityComboTA.push(this.commodityComboDataList[i].commodityName);
                    }

                    $("#" + this.pageId + "commodityCombo").val(data.subCommodity);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Problem occur while getting Commodity From Admin Rate Master",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');

    }
    //Chg_Ver_PLC_02
    getLrStatus(e) {
        if (e.keyCode == 13) {
            if (this.isSizeExceeded) {
                swal("Not Allowed", "This LR is ND-Size exceeded since no discount. For further details, contact admin.", "warning");
                $("#" + this.pageId + "lessAmt").val("");
            } else {
                this.enteredLrNumber = $("#" + this.pageId + "lrNo").val();
                this.lrDtoMainSrc = new LRDto();
                this.lrDtoMainSrc.lrNumber = this.enteredLrNumber;
                this.lrDtoMainSrc.companyId = this.userDataDtoReturnSession.companyId;
                this.destStmtReport.getLrStatusPartyLess(this.lrDtoMainSrc).subscribe(
                    (response) => {
                        if (response.fortNightStmtNo != null) {
                            //swal("Warning", "LR Number - " + this.enteredLrNumber + " is already entered in the Statement No - " + response.fortNightStmtNo, "info");
                            swal({
                                title: "Warning",
                                text: "LR Number - " + this.enteredLrNumber + " is already entered in the Statement No - " + response.fortNightStmtNo,
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            }).then(() => {
                                this.doLRCrossCheckNew();
                            })
                        } else {
                            this.doLRCrossCheckNew();
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        this.showSpinnerForAction = false;
                        swal("Error", "Server problem occurred while getting the details", "info");
                    }, () => console.log('done');
            }

        }
    }

    doLRCrossCheckNew() {
        this.returnRemarks = null;
        this.lrDtoMainSrc = new LRDto();
        this.lrDtoMainSrc.lrNumber = this.enteredLrNumber;
        this.lrDtoMainSrc.fortNightStmtNo = this.enteredStmtNo;
        this.lrDtoMainSrc.companyId = this.userDataDtoReturnSession.companyId;
        this.destStmtReport.partyLessCrossCheck(this.lrDtoMainSrc).subscribe(
            (response) => {
                if (response.status == "notentered") {
                    this.returnRemarks = "Not In Software";
                    swal("Warning", "LR Number not entered in Software", "warning");
                } else if (response.status == "notinstmt") {
                    this.returnRemarks = "Not In Statement";
                    swal("Warning", "LR Number not in the Statement", "warning");
                }
                //Chg_Ver_PLC_04
                // this.setUpdateAndInsertLRLessChkDetails();
                this.validateUpdateAndInsertLRLessChkDetails();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while getting the details", "info");
            }, () => console.log('done');
    }

    getUpdateAndInsertLRLessChkDetails() {
        this.lrDtoForUpdateInsertLRLess = [];
        this.enteredLessAmt = $("#" + this.pageId + "lessAmt").val();
        this.lrDtoMainSrc = new LRDto();
        this.lrDtoMainSrc.lrNumber = this.enteredLrNumber;
        this.lrDtoMainSrc.agentName = this.selectedAgentName;
        this.lrDtoMainSrc.fortNightStmtNo = this.enteredStmtNo;
        this.lrDtoMainSrc.discountAmount = this.enteredLessAmt;
        this.lrDtoMainSrc.userName = this.userDataDtoReturnSession.userId;
        //as per Abubakar bh remarks is now getting from field, since agent will enter manually @28/05/2024
        //this.lrDtoMainSrc.remarks = this.returnRemarks;
        this.enteredRemarks = $("#" + this.pageId + "remarks").val();
        this.lrDtoMainSrc.remarks = this.enteredRemarks;

        this.lrDtoMainSrc.id = 0;
        this.lrDtoMainSrc.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoMainSrc.memoLessType = "lesscheck";
        this.lrDtoForUpdateInsertLRLess.push(this.lrDtoMainSrc);
    }

    setUpdateAndInsertLRLessChkDetails() {
        this.getUpdateAndInsertLRLessChkDetails();
        this.showSpinnerForAction = true;
        this.lrService.insertIntoLRLessCheck(this.lrDtoForUpdateInsertLRLess)
            .subscribe((response) => {
                this.lrDtoForUpdateInsertLRLessReturn = null;
                this.lrDtoForUpdateInsertLRLessReturn = response;
                this.showSpinnerForAction = false;
                if (this.lrDtoForUpdateInsertLRLessReturn.status == "success") {
                    this.setIsLrLessCheckedDetails(this.lrDtoForUpdateInsertLRLess);
                } else {
                    swal("Failed", "Failed to capture the less details", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While insert capture the less Details", "info");
            },
            () => console.log("done");
    }

    //Chg_Ver_PLC_04
    validateUpdateAndInsertLRLessChkDetails() {
        this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
        if ((this.enteredStmtNo == null) || (this.enteredStmtNo == undefined) || (this.enteredStmtNo == "")) {
            swal("Not allowed", "Please enter the Statement Number to proceed", "info");
        } else {
            this.setUpdateAndInsertLRLessChkDetails();
        } 
    }

    setIsLrLessCheckedDetails(lrDtoForUpdateInsertLRLess) {
        this.lrDtoForIsLrLessChecked = [];
        this.lrDtoForIsLrLessChecked = lrDtoForUpdateInsertLRLess;
        this.showSpinnerForAction = true;
        this.lrService.setIsLrLessChecked(this.lrDtoForIsLrLessChecked)
            .subscribe((response) => {
                this.lrDtoForIsLrLessCheckedReturn = null;
                this.lrDtoForIsLrLessCheckedReturn = response;
                console.log(response);
                this.showSpinnerForAction = false;
                if (this.lrDtoForIsLrLessCheckedReturn.status == "success") {
                    swal("Success", "Less details successfully updated!", "success");
                    this.clearFields();
                } else if (this.lrDtoForIsLrLessCheckedReturn.status == "nodata") {
                    swal("No Record", "No Data Found For This LR No!", "warning");
                } else {
                    swal("Failed", "Failed to update the less details", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While update the less Details", "info");
            },
            () => console.log("done");
    }

    clearFields() {
        this.enteredLrNumber = null;
        this.returnRemarks = null;
        this.lrDtoForUpdateInsertLRLess = [];
        this.lrDtoForUpdateInsertLRLessReturn = null;
        this.lrDtoForIsLrLessChecked = [];
        this.lrDtoForIsLrLessCheckedReturn = null;
        this.enteredLessAmt = null;
        this.selectedConsigneeId = null;
        this.selectedConsigneeName = null;
        this.selectedCommodity = null;
        this.lrDtoForRcMemoSingleLr = [];
        this.enteredRemarks = null;

        $("#" + this.pageId + "lrNo").val("");
        $("#" + this.pageId + "discountAmt").val("");
        $("#" + this.pageId + "masterRate").val("");
        $("#" + this.pageId + "masterGcChg").val("");
        $("#" + this.pageId + "masterDestHamali").val("");
        $("#" + this.pageId + "masterRecChg").val("");
        $("#" + this.pageId + "masterSerChg").val("");
        $("#" + this.pageId + "masterSource").val("");
        $("#" + this.pageId + "masterPerUnit").val("");
        $("#" + this.pageId + "masterDiscount").val("");
        $("#" + this.pageId + "lessAmt").val("");
        $("#" + this.pageId + "discountAmt").val("");
        $("#" + this.pageId + "commodityCombo").val("");
        $("#" + this.pageId + "remarks").val("");
        $("#" + this.pageId + "lrNo").focus();
    }

    rcBtmValidate() {
        if ($("#" + this.pageId + "commodityCombo").val() == null ||
            $("#" + this.pageId + "commodityCombo").val() == undefined ||
            $("#" + this.pageId + "commodityCombo").val() == "") {
            swal("Not Allowed", "Please select commodity to recalculate the memo", "warning");
            return false;
        } else {
            swal({
                title: "Confirm Action",
                text: "Sure you want to recalculate the discount for the selected LR?",
                icon: "info",
                buttons: ["No", "Yes"]
            }).then((isConfirm) => {
                if (isConfirm) {

                    this.rcMemoCall();
                }
            });
        }
    }

    getRcMemoLrDetailsForSingleLr() {
        this.selectedCommodity = $("#" + this.pageId + "commodityCombo").val();
        this.lrDtoMainSrc = new LRDto();
        this.lrDtoForRcMemoSingleLr = [];
        this.lrDtoMainSrc.lrNumber = this.enteredLrNumber;
        this.lrDtoMainSrc.source = this.selectedAgentName;
        this.lrDtoMainSrc.subCommodity = this.selectedCommodity;
        this.lrDtoMainSrc.consigneeId = this.selectedConsigneeId;
        this.lrDtoMainSrc.consigneeName = this.selectedConsigneeName;
        this.lrDtoMainSrc.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoMainSrc.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForRcMemoSingleLr.push(this.lrDtoMainSrc);
        return this.lrDtoForRcMemoSingleLr;
    }

    rcMemoCall() {
        this.showSpinnerForAction = true;
        this.getRcMemoLrDetailsForSingleLr();
        this.lrService.recalculateBkgMemoAfterAdminRateOnDestLessChkUpload(this.lrDtoForRcMemoSingleLr)
            .subscribe((response) => {
                this.lrDtoForRcMemoCallListReturn = null;
                this.lrDtoForRcMemoCallListReturn = response;
                this.showSpinnerForAction = false;
                var failedSuccessMsg = this.lrDtoForRcMemoCallListReturn.status.split("-");
                if (failedSuccessMsg[0] != null &&
                    failedSuccessMsg[0] == "success") {
                    this.getLrEnquiryDetails();
                } else {
                    var failedMsg = this.lrDtoForRcMemoCallListReturn.status.split("-");
                    swal("Failed", "Failed to recalculate the Discount! The LR Number is " + failedMsg[1] + "", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Recalculate the Discount!", "info");
            },
            () => console.log("done");
    }

    validateDiifLessLRBtn() {
        if (this.searchByType === "stmtNoWise" && (($("#" + this.pageId + "stmtNo").val() == null) || ($("#" + this.pageId + "stmtNo").val() == undefined) ||
            ($("#" + this.pageId + "stmtNo").val() == ""))) {
            swal("Mandatory Fields", "Please enter the statement number", "warning");
            return false;
        } else {
            this.getStmNoWiseEnteredDiffLessLrDetailsReport();
        }
    }
    getStmNoWiseEnteredDiffLessLr() {
        this.fortNightStmtDtoDiffLr = new FortNightStmtDto();
        this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
        this.fortNightStmtDtoDiffLr.stmtNo = this.enteredStmtNo;
        this.fortNightStmtDtoDiffLr.mode = "DiffLessLrs";
        this.fortNightStmtDtoDiffLr.reportMode = "StmtLess";
        this.fortNightStmtDtoDiffLr.companyId = this.userDataDtoReturnSession.companyId;
    }

    getStmNoWiseEnteredDiffLessLrDetailsReport() {
        this.getStmNoWiseEnteredDiffLessLr();
        this.showSpinnerForAction = true;
        $("#" + this.pageId + "textId").val("");
        $("#" + this.pageId + "agentName").val("");
        $("#" + this.pageId + "fromPeriodForExport").val('');
        $("#" + this.pageId + "toPeriodForExport").val('');
        this.destStmtReport.getLessUncheckedLrDetailsV2(this.fortNightStmtDtoDiffLr).subscribe(
            (response) => {
                this.stmtNoDetailsDataList = [];
                $("#" + this.pageId + "stmtNoWiseTableId").DataTable().destroy();

                this.stmtNoMainSrcDetailsDataList = [];
                $("#" + this.pageId + "stmtNoMainSrcWiseTableId").DataTable().destroy();
                this.stmtNoBlockedLrDataList = [];
                $("#" + this.pageId + "stmtNoBlockedLrTableId").DataTable().destroy();

                if (response.length > 0) {
                    this.stmtNoDetailsDataList = response;
                    console.log("this.stmtNoDetailsDataList");
                    console.log(this.stmtNoDetailsDataList);
                    // console.log("less ...." + this.stmtNoDetailsDataList[3].DDCharge + "-" + this.stmtNoDetailsDataList[3].AOCCharge + "-" + this.stmtNoDetailsDataList[3].HamaliCharge);
                    // console.log("less ...." + this.stmtNoDetailsDataList[3].ddChargeNew + "-" + this.stmtNoDetailsDataList[3].aocChargeNew + "-" + this.stmtNoDetailsDataList[3].hamaliCharge);
                    $("#" + this.pageId + "textId").val(this.stmtNoDetailsDataList[0].destination);

                    $("#" + this.pageId + "agentName").val(this.stmtNoDetailsDataList[0].destination);
                    let from = null;
                    let to = null;
                    from = $("#" + this.pageId + "fromPeriodForExport").val(this.stmtNoDetailsDataList[0].fromPeriodStr);
                    to = $("#" + this.pageId + "toPeriodForExport").val(this.stmtNoDetailsDataList[0].toPeriodStr);
                    console.log("from, to");
                    console.log(from, to);
                    this.dtTriggerStmtNoWise.next();

                    this.getStmtNoMainSrcWiseReport();
                    this.getDestinationForLREntryFormForLessCheck(this.stmtNoDetailsDataList[0].destination);
                    this.getStmtNoBlockedLrReport();
                } else {
                    this.dtTriggerStmtNoWise.next();
                    this.dtTriggerStmtNoMainSrcWise.next();
                    this.dtTriggerStmtNoBlockedLr.next();
                    swal("Party Less Check", "No datas found for this information!", "info");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            }, () => console.log('done');
    }

    clickListnerForDestinationDateWise(e) {
        //Chg_Ver_PLC_01
        this.validateDestination = $("#" + this.pageId + "destinationIdDateWise").val();
        if (e.keyCode == 13) {
            if (this.viewAdminFields) {
                this.getStatmentNumberDateWiseService();
            } else if (this.validateDestination != null && (this.validateDestination.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase())) {
                this.getStatmentNumberDateWiseService();
            } else {
                swal("Warning", "Not allowed to view other Destination Agent  info!", "warning");
            }
        }
    }

    getStatmentNumberDateWise() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.validateFromDate = $("#" + this.pageId + "fromDatesDateWise").val();
        this.validateToDate = $("#" + this.pageId + "toDatesDateWise").val();
        this.validateDestination = $("#" + this.pageId + "destinationIdDateWise").val();

        this.fortNightStmtDto.fromDate = this.validateFromDate;
        this.fortNightStmtDto.toDate = this.validateToDate
        this.fortNightStmtDto.destination = this.validateDestination;
    }
    getStatmentNumberDateWiseService() {
        this.showSpinnerForAction = true;
        $("#" + this.pageId + "stmtNo").val('');
        $("#" + this.pageId + "agentName").val('');
        this.getStatmentNumberDateWise();
        this.destStmtReport.getStmtNoDateWise(this.fortNightStmtDto).subscribe(
            (response) => {
                if (response.status == 'Found') {
                    $("#" + this.pageId + "stmtNo").val(response.stmtNo);
                    $("#" + this.pageId + "agentName").val(response.destination);
                    $("#" + this.pageId + "stmtNo").focus();
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                    swal("Warning", "No statement found for the selected period!", "warning");
                    $("#" + this.pageId + "stmtNo").val('');
                    this.stmtNoDetailsDataList = [];
                    $("#" + this.pageId + "stmtNoWiseTableId").DataTable().destroy();
                    this.stmtNoMainSrcDetailsDataList = [];
                    $("#" + this.pageId + "stmtNoMainSrcWiseTableId").DataTable().destroy();
                    this.stmtNoBlockedLrDataList = [];
                    $("#" + this.pageId + "stmtNoBlockedLrTableId").DataTable().destroy();
                    this.dtTriggerStmtNoWise.next();
                    this.dtTriggerStmtNoMainSrcWise.next();
                    this.dtTriggerStmtNoBlockedLr.next();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    totalLrsDetailsV2Popup(totalLessData, mode) {
        localStorage.clear();
        localStorage.setItem('totalLessDataPopup', JSON.stringify(totalLessData));
        localStorage.setItem('totalLessMode', JSON.stringify(mode));
        const dialogRef = this.dialog.open(PartyLessCheckDetailsV2Component, {

        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            // this.router.navigate(['.'], { relativeTo: this.route });
        });
    }

    ///Upload Process Starts From here
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    setUploadMtd(totalLessData, uploadPopUpTemplate) {
        this.uploadPopupStmtNo = '';
        if (totalLessData.stmtId != null && totalLessData.stmtId != '') {
            swal({
                title: "Confirm Upload",
                text: "Sure U Want To Confirm Upload?",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((statusBackMemo) => {
                if (statusBackMemo) {
                    this.uploadPopupStmtNo = totalLessData.stmtId;
                    this.modalRefferenceUploadPopUp = this.modalService.open(uploadPopUpTemplate,
                        { centered: true });
                    this.modalRefferenceUploadPopUp.result.then((result) => {
                        this.modalRefferenceUploadClosePopUp = `Closed with: ${result}`;
                    }, reason => {
                        this.modalRefferenceUploadClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
                    });
                }

            });
        } else {

            swal({
                title: "Warning",
                text: "No Details found !",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        }
    }

    onFileChangedPartyLessChkStmt1(event) {
        this.selectedPartyLessChkStmt1Details = event.target.files[0];
        console.log(this.selectedPartyLessChkStmt1Details);
    }
    onFileChangedPartyLessChkStmt2(event) {
        this.selectedPartyLessChkStmt2Details = event.target.files[0];
        console.log(this.selectedPartyLessChkStmt2Details);
    }


    uploadPartyLessChkStmtBtnPopUp() {
        console.log($("#" + this.pageId + "partyLessChkStmtFile1Upload").val());
        console.log($("#" + this.pageId + "partyLessChkStmtFile2Upload").val());


        if ($("#" + this.pageId + "partyLessChkStmtFile1Upload").val() != null && $("#" + this.pageId + "partyLessChkStmtFile1Upload").val() != "") {
            this.showSpinnerForActionForUpload = true;
            if ((this.selectedPartyLessChkStmt1Details.type != null &&
                (this.selectedPartyLessChkStmt1Details.type == "image/jpeg" ||
                    this.selectedPartyLessChkStmt1Details.type == "image/jpg" ||
                    this.selectedPartyLessChkStmt1Details.type == "image/png" ||
                    this.selectedPartyLessChkStmt1Details.type == "application/pdf"))) {
                var splitTypeToPartyStmt1 = this.selectedPartyLessChkStmt1Details.type.split("/");

                const commonListToPartyStmt1 = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeToPartyStmt1[1] + "&&" + this.uploadPopupStmtNo + "";
                console.log("commonListToPartyStmt1");
                console.log(commonListToPartyStmt1);
                const formData = new FormData();
                formData.append(commonListToPartyStmt1, commonListToPartyStmt1);
                formData.append('myfilePartyLessChkStmt1', this.selectedPartyLessChkStmt1Details, this.selectedPartyLessChkStmt1Details.name);
                console.log("formData >> 1");
                console.log(formData);
                this.masterReadService.uploadFile(formData).subscribe(
                    (response) => {
                        if (response) {
                            console.log(response);
                            this.showSpinnerForActionForUpload = false;
                            if (response[0] != "Error") {
                                swal("File Upload", "File Uploaded Succcessfully", "success");
                                this.isUploaded = true;
                            } else {
                                swal("ERROR", "Correct the File and Upload Again1", "error");
                            }
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        this.showSpinnerForActionForUpload = false;
                        swal("Error", "Server Error While Uploading File", "info");
                    }, () => console.log('done');
            } else {
                this.showSpinnerForActionForUpload = false;
                swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
            }
        }


        if ($("#" + this.pageId + "partyLessChkStmtFile2Upload").val() != null && $("#" + this.pageId + "partyLessChkStmtFile2Upload").val() != "") {
            this.showSpinnerForActionForUpload = true;
            if ((this.selectedPartyLessChkStmt2Details.type != null &&
                (this.selectedPartyLessChkStmt2Details.type == "image/jpeg" ||
                    this.selectedPartyLessChkStmt2Details.type == "image/jpg" ||
                    this.selectedPartyLessChkStmt2Details.type == "image/png" ||
                    this.selectedPartyLessChkStmt2Details.type == "application/pdf"))) {
                var splitTypeToPartyStmt2 = this.selectedPartyLessChkStmt2Details.type.split("/");

                const commonListToPartyStmt2 = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeToPartyStmt2[1] + "&&" + this.uploadPopupStmtNo + "";
                console.log("commonListToPartyStmt2");
                console.log(commonListToPartyStmt2);
                const formData = new FormData();
                formData.append(commonListToPartyStmt2, commonListToPartyStmt2);
                formData.append('myfilePartyLessChkStmt2', this.selectedPartyLessChkStmt2Details, this.selectedPartyLessChkStmt2Details.name);
                console.log("formData >> 2");
                console.log(formData);
                this.masterReadService.uploadFile(formData).subscribe(
                    (response) => {
                        if (response) {
                            this.showSpinnerForActionForUpload = false;
                            console.log(response);
                            if (response[0] != "Error") {
                                swal("File Upload", "File Uploaded Succcessfully", "success");
                                this.isUploaded = true;
                            } else {
                                swal("ERROR", "Correct the File and Upload Again 2", "error");
                            }
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        this.showSpinnerForActionForUpload = false;
                        swal("Error", "Server Error While Uploading File", "info");
                    }, () => console.log('done');
            } else {
                this.showSpinnerForActionForUpload = false;
                swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
            }
        }


    }
    applyPartyLessChkStmtBtnPopUp() {
        this.updateUploadPartyLessCheckDetails();
    }

    closePartyLessChkStmtBtnPopUp() {
        this.modalRefferenceUploadPopUp.close();
        this.isUploaded = false;
        this.uploadPopupStmtNo = '';
        $("#" + this.pageId + "partyLessChkStmtFile1Upload").val('');
        $("#" + this.pageId + "partyLessChkStmtFile2Upload").val('');


    }

    updateUploadPartyLessCheckDetails() {

        this.cashMemoDtoForPartyLesChkUpdate = new CashMemoDto();
        this.cashMemoDtoForPartyLesChkUpdate.statementNo = this.uploadPopupStmtNo;
        this.isUploaded = true;
        //for Copy1
        if (this.isUploaded == true) {
            if ($("#" + this.pageId + "partyLessChkStmtFile1Upload").val() != null && $("#" + this.pageId + "partyLessChkStmtFile1Upload").val() != "") {
                const constImgUrlsStmt1 = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + this.uploadPopupStmtNo + "_" + this.selectedPartyLessChkStmt1Details.name + "";
                this.cashMemoDtoForPartyLesChkUpdate.imgURLStmCpy = constImgUrlsStmt1;
                this.cashMemoDtoForPartyLesChkUpdate.imgUploadedStmCpy = true;
            }


        } else {
            this.cashMemoDtoForPartyLesChkUpdate.imgUploadedStmCpy = false;
        }
        //for Copy2
        if (this.isUploaded == true) {
            if ($("#" + this.pageId + "partyLessChkStmtFile2Upload").val() != null && $("#" + this.pageId + "partyLessChkStmtFile2Upload").val() != "") {
                const constImgUrlsStmt2 = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + this.uploadPopupStmtNo + "_" + this.selectedPartyLessChkStmt2Details.name + "";
                this.cashMemoDtoForPartyLesChkUpdate.imgURLStmCpy2 = constImgUrlsStmt2;
                this.cashMemoDtoForPartyLesChkUpdate.imgUploadedStmCpy2 = true;
            }

        } else {
            this.cashMemoDtoForPartyLesChkUpdate.imgUploadedStmCpy2 = false;

        }

        this.showSpinnerForAction = true;
        console.log("this.cashMemoDtoForPartyLesChkUpdate >> ");
        console.log(this.cashMemoDtoForPartyLesChkUpdate);
        this.updatePartyLessCheckDetailsMethod();
    }

    updatePartyLessCheckDetailsMethod = () => {
        this.cashMemoDtoForPartyLesChkUpdate.enteredBy = this.userDataDtoReturnSession.userId;
        this.memoReport.updateUploadPartyLessCheck(this.cashMemoDtoForPartyLesChkUpdate).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.status == 'failed') {
                    swal({
                        title: "Error",
                        text: "Problem occurred while updating details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else if (response.status == 'success') {
                    swal({
                        title: "Success",
                        text: "Details updated",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                }
                this.modalRefferenceUploadPopUp.close();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Problem occurred while updating the acknowledgment details", "info");
            }, () => console.log('done');
    };

    getImgForCpy1(totalLessData) {
        console.log("totalLessData.statementCopyURL");
        console.log(totalLessData.statementCopyURL);
        if (totalLessData.statementCopyURL == null || totalLessData.statementCopyURL == undefined || totalLessData.statementCopyURL == 'NA') {

        } else {
            window.open(totalLessData.statementCopyURL, '_blank');
        }
    }

    getImgForCpy2(totalLessData) {
        console.log("totalLessData.statementCopyURL2");
        console.log(totalLessData.statementCopyURL2);
        if (totalLessData.statementCopyURL2 == null || totalLessData.statementCopyURL2 == undefined || totalLessData.statementCopyURL2 == 'NA') {

        } else {
            window.open(totalLessData.statementCopyURL2, '_blank');
        }
    }

    getStmtBlockedLrDetails() {
        this.fortNightStmtDtoBlockedLr = new FortNightStmtDto();
        this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
        this.fortNightStmtDtoBlockedLr.stmtNo = this.enteredStmtNo;
        this.fortNightStmtDtoBlockedLr.companyId = this.userDataDtoReturnSession.companyId;
    }

    getStmtNoBlockedLrReport() {
        this.getStmtBlockedLrDetails();
        this.destStmtReport.getLessCheckedBlockLrDetails(this.fortNightStmtDtoBlockedLr).subscribe(
            (response) => {
                this.stmtNoBlockedLrDataList = [];
                $("#" + this.pageId + "stmtNoBlockedLrTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.stmtNoBlockedLrDataList = response;
                    this.dtTriggerStmtNoBlockedLr.next();
                } else {
                    this.dtTriggerStmtNoBlockedLr.next();
                    swal("Party Less Check", "No datas found for Blocked LR!", "info");
                    this.showSpinnerForAction = false;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            }, () => console.log('done');
    }
    setDataForExport() {
        this.selectedAgentName = null;
        this.enteredStmtNo = null;
        this.validateFromDate = null;
        this.validateToDate = null;

        this.selectedAgentName = $("#" + this.pageId + "agentName").val();
        this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
        this.validateFromDate = $("#" + this.pageId + "fromPeriodForExport").val();
        this.validateToDate = $("#" + this.pageId + "toPeriodForExport").val();


        this.lrDtoForExport = new LRDto();
        this.lrDtoForExport.fortNightStmtNo = this.enteredStmtNo;
        this.lrDtoForExport.agentName = this.selectedAgentName;
        this.lrDtoForExport.fromDate = this.validateFromDate;
        this.lrDtoForExport.toDate = this.validateToDate;
        this.lrDtoForExport.description = "Party Less Check Report (Note:Negative(-) sign indicates excess in Agent Entered Less Amount)";
        this.lrDtoForExport.status = "Note: 0 is displayed if the diff. between Actual less and entered less is between -1 to +1";
        this.lrDtoForExport.lrDtos = this.stmtNoDetailsDataList;
        this.lrDtoForExport.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForExport.name = "PartyLessCheckReport";

    }

    getDataForExport() {
        // || (this.stmtNoBlockedLrDataList == null && this.stmtNoBlockedLrDataList == undefined &&
        //     this.stmtNoBlockedLrDataList.length == 0)
        if ((this.stmtNoDetailsDataList == null && this.stmtNoDetailsDataList == undefined &&
            this.stmtNoDetailsDataList.length == 0)) {
            swal({
                title: "Oops!!!",
                text: "No data found to proceed!",
                icon: "warning",
            });
        } else {
            this.setDataForExport();
            this.showSpinnerForAction = true;
            console.log('this.lrDtoForExport');
            console.log(this.lrDtoForExport);
            this.reportService.createExcelFileService(this.lrDtoForExport).subscribe(
                (response) => {
                    this.showSpinnerForAction = false;
                    console.log("response");
                    console.log(response);
                    if (response.status == "success") {
                        console.log("response.name");
                        console.log(response.name);
                        // var b = new Blob([response.name], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                        // saveAs(b, "PartyLessCheck.xlsx");//this is FileSaver.js function
                        // window.open(response.name, "_blank");
                        // blob => saveAs(blob, response.name);
                        // FileSaver.saveAs(response.name, "image.jpg");
                        // this.pdfService.getExcel(this.fileName, this.fileType).subscribe((responseMessage) => {
                        // let file = new Blob([responseMessage], { type: 'application/vnd.ms-excel' });
                        // var fileURL = URL.createObjectURL(response.name);
                        // console.log("fileURL");
                        // console.log(fileURL);
                        // window.open(fileURL, "_blank");
                    }
                    //     $("#"+this.pageId+"mergeCommodityId").DataTable().destroy();
                    //     this.mergeCommodityListDataList = [];
                    //     this.dtTriggerMergeCommodityList.next();
                    //     this.getCommodityConfirmedList();

                    //     $("#"+this.pageId+"commodityNameConfirmed").val('');
                    //     this.oldCommodityName = '';
                    //     this.commodityName = '';
                    //     this.lrDtoList = [];
                    //     this.lrDtoListForMerge = [];
                    //     this.selectedCommodityName = '';
                    //     this.lrDtoMergeBtnSingle = new LRDto();
                    //     this.lrDtoMergeBtnList = new LRDto();
                    //     this.newAttributeForRepeat = {};
                    //     this.newAttributeForMerge = {};
                    //     swal({
                    //         title: "Success",
                    //         text: "Merged Successfully",
                    //         icon: "success",
                    //         closeOnClickOutside: false,
                    //         closeOnEsc: false,
                    //     });


                    // } else if (response.status == 'Failed') {
                    //     swal({
                    //         title: "Failed",
                    //         text: "Problem occur while confirm the Commodity",
                    //         icon: "error",
                    //         closeOnClickOutside: false,
                    //         closeOnEsc: false,

                    //     });

                    // }

                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    this.showSpinnerForAction = false;
                    swal("Error", "Server Problem Occurred while Downloading Excel", "info");
                }, () => console.log('done');
        }
    }

    getCheckedUncheckedLrsDatewiseData() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();
        this.validateDestination = $("#" + this.pageId + "destinationId").val();

        this.fortNightStmtDto.fromDate = this.validateFromDate;
        this.fortNightStmtDto.toDate = this.validateToDate
        this.fortNightStmtDto.destination = this.validateDestination;
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDto.branch = this.userDataDtoReturnSession.office;
        $("#" + this.pageId + "textId").val(this.validateDestination);
    }

    getCheckedUncheckedLrsDatewiseDetails() {
        this.getCheckedUncheckedLrsDatewiseData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getLessUncheckedLrDatewise(this.fortNightStmtDto).subscribe(
            (response) => {
                this.checkedUncheckedLrsDatewiseList = [];
                $("#" + this.pageId + "lrDetailsChkUnCkhdDateWiseId").DataTable().destroy();
                if (response.length > 0) {
                    this.checkedUncheckedLrsDatewiseList = response;
                    this.dtTriggerLrDetailsChkUnCkhdDateWise.next();
                    console.log("stmt Date");
                    console.log(this.checkedUncheckedLrsDatewiseList);
                } else {
                    this.dtTriggerLrDetailsChkUnCkhdDateWise.next();
                    swal("Alert", "NO DATAS FOUND FOR THIS INFORMATION", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While getting of Statement verfication Details", "info");
            }, () => console.log('done');
    }

    getEnteredLessLrsDatewiseData() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();
        this.validateDestination = $("#" + this.pageId + "destinationId").val();

        this.fortNightStmtDto.fromDate = this.validateFromDate;
        this.fortNightStmtDto.toDate = this.validateToDate
        this.fortNightStmtDto.destination = this.validateDestination;
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDto.branch = this.userDataDtoReturnSession.office;
        $("#" + this.pageId + "textId").val(this.validateDestination);
    }

    getEnteredLessLrsDatewiseDetails() {
        console.log('1');
        this.getEnteredLessLrsDatewiseData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getEnteredLessLrsDatewise(this.fortNightStmtDto).subscribe(
            (response) => {
                this.enteredLessLrsDatewiseList = [];
                console.log('2');
                console.log(this.enteredLessLrsDatewiseList);
                $("#" + this.pageId + "enteredLessTableDatewiseId").DataTable().destroy();
                if (response.length > 0) {
                    this.enteredLessLrsDatewiseList = response;
                    this.dtTriggerEnteredLessDatewise.next();
                } else {
                    this.dtTriggerEnteredLessDatewise.next();
                    swal("Alert", "NO DATAS FOUND FOR THIS INFORMATION", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While getting of Details", "info");
            }, () => console.log('done');
    }

    ////
    getSpecificStatementNoWiseDataUserValues() {
        this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
        this.fortNightStmtDto = new FortNightStmtDto();
        this.fortNightStmtDto.mode = "specific";
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDto.destination = this.userDataDtoReturnSession.office;
        this.fortNightStmtDto.stmtId = this.enteredStmtNo;

    }
    getSpecificStatementNoWiseData = () => {

        this.stmtNoDetailsSpecificStatementData = [];
        this.getSpecificStatementNoWiseDataUserValues();
        this.destStmtReport.getFortNightStmt(this.fortNightStmtDto).subscribe(
            (response) => {

                if (response.length == 0) {

                    swal("Alert", "NO DATAS FOUND FOR THIS INFORMATION", "info");
                    this.stmtNoDetailsSpecificStatementData = [];
                } else {

                    this.stmtNoDetailsSpecificStatementData = response;
                    console.log(this.stmtNoDetailsSpecificStatementData);
                    let stmtNodestinationRtn = this.stmtNoDetailsSpecificStatementData[0].destination.toLowerCase();
                    console.log("stmtNodestinationRtn" + stmtNodestinationRtn);
                    console.log("this.userDataDtoReturnSession.office.toLowerCase" + this.userDataDtoReturnSession.office.toLowerCase());
                    if (this.userDataDtoReturnSession.office.toLowerCase() == stmtNodestinationRtn) {
                        this.getStmNoWiseEnteredLessLrDetailsReport();
                    } else {

                        swal("Party Less Check", "Not allowed to view information!", "info");
                        this.showSpinnerForAction = false;
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Statement Details", "info");
            }, () => console.log('done');
    };

    confirmFinishBtn() {
        swal({
            title: "Confirmation",
            text: "Sure you want to finish the less check entries of this Statement Number?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.updateLrLessCheckFinishStatus();
            }
        })
    }


    updateLrLessCheckFinishStatus() {
        this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
        this.lrDtoFinStatusUpdate = new LRDto();
        this.lrDtoFinStatusUpdate.status = 'Finished';
        this.lrDtoFinStatusUpdate.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoFinStatusUpdate.stmtNo = this.enteredStmtNo;

        this.showSpinnerForAction = true;
        this.lrService.setLrLessStatementFinished(this.lrDtoFinStatusUpdate)
            .subscribe((response) => {
                this.lrDtoForIsLrLessCheckedReturn = null;
                this.lrDtoForIsLrLessCheckedReturn = response;
                console.log(response);
                this.showSpinnerForAction = false;
                if (this.lrDtoForIsLrLessCheckedReturn.status == "success") {
                    swal("Success", "Less details successfully updated!", "success");
                    this.clearFields();
                } else if (this.lrDtoForIsLrLessCheckedReturn.status == "nodata") {
                    swal("No Record", "No Data Found For This Statement No!", "warning");
                } else {
                    swal("Failed", "Failed to update the less details", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While update the less Details", "info");
            },
            () => console.log("done");
    }

    getStatmentNumberWiseService(e) {
        if (e.keyCode == 13) {
            $("#" + this.pageId + "agentName").val('');
            this.showSpinnerForAction = true;
            this.fortNightStmtDto = new FortNightStmtDto();
            this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
            this.fortNightStmtDto.stmtNo = this.enteredStmtNo;
            this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
            this.destStmtReport.getStmtNoWise(this.fortNightStmtDto).subscribe(
                (response) => {
                    if (response.status == 'Found') {
                        this.showSpinnerForAction = false;
                        if (this.viewAdminFields) {
                            $("#" + this.pageId + "agentName").val(response.destination);
                            $("#" + this.pageId + "lrNo").focus();
                        } else if (response.destination.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase()) {
                            $("#" + this.pageId + "agentName").val(response.destination);
                            $("#" + this.pageId + "lrNo").focus();
                        } else {
                            swal("Not Allowed", "Not allowed to view/enter other Destination Agent details!", "warning");
                        }
                    } else {
                        this.showSpinnerForAction = false;
                        swal("Warning", "No statement details found!", "warning");
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    this.showSpinnerForAction = false;
                    swal("Server Error", "Server problem occurred while getting Statement details", "error");
                },
                () => console.log('done');
        }
    }

    lrRemakrsEvent(e) {
        if (e.keyCode == 13) {
            $("#" + this.pageId + "lessAmt").focus();
        }
    }
    //Chg_Ver_PLC_03
    lrSearchMtdBlur(e) {

        this.selectedAgentName = $("#" + this.pageId + "agentName").val();
        this.enteredLrNumber = $("#" + this.pageId + "lrNo").val();
        this.enteredStmtNo = $("#" + this.pageId + "stmtNo").val();
        if ((this.enteredStmtNo == null) || (this.enteredStmtNo == undefined) || (this.enteredStmtNo == "")) {
            swal("Not allowed", "Please enter the Statement Number to proceed", "info");
        } else if ((this.enteredLrNumber == null) || (this.enteredLrNumber == undefined) || (this.enteredLrNumber == "")) {
            swal("Not allowed", "Please enter the LR Number to proceed", "info");

        } else {
            //this.clearInputFields();
            this.getLrEnquiryDetails();
        }

    }

}
