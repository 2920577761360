// Asrar_Jr_Chg_V1 : 08/05/2024 - For added to hide print in UI after click on cancel button
// Imran_HRD_Chg_V2 : 14/06/2024 - Added for alowing admin to edit hire details only one time other than blocked Added by imran on 14/06/2024 PID='19'
// Asrar_Jr_Chg_V3 : 07/11/2024 - Showing Short & Extra details for selected invoice

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { InvoiceDto } from "src/app/dto/Invoice-dto";
import { StockService } from 'src/app/dataService/stock-service';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import *  as moment from 'moment';
import { ShortExtraDetailsPopupComponent } from '../short-extra-details-popup/short-extra-details-popup.component';

@Component({
    selector: 'app-hireslip-details',
    templateUrl: './hireslip-details.component.html',
    styleUrls: ['./hireslip-details.component.css']
})
export class HireslipDetailsComponent implements OnInit {

    hireNdDataWiseDataList: any;
    smryInvoiceDataList: any;
    smryViaReportDataList: any;
    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;

    searchByHireslipNo = true;
    searchByDateWise = false;
    viewDate = false;
    viewSource = true;
    viewDestination = false;
    searchBySmryWise = false;
    sourceTypeMain = false;
    sourceTypeIndividual = false;
    summaryWiseInvoiceTable = false;
    summaryWiseViaReportTable = false;
    comboBoxAllsum = true;
    DummyInvView = false;
    showDummyInvoice = 'HireSlip Rpt Show Dummy Invoice';

    showSrc = false;
    showDest = false;
    showSrcSum = false;
    showDestSum = false;
    showForNoAndDateWise = true;
    showForMainAndDummyWise = false;
    showForViaReportTable = false;

    showSrcDest = "Hireslip Rpt Super Admin";
    ///
    //     for Booking Manager is Hireslip Rpt Bkg Mgr 
    //     for Booking Office and Administrator is Hireslip Rpt Bkg Ofc Admin
    //     for Delivery Manager is Hireslip Rpt Dly Mgr
    //     for Collection Manager is Hireslip Rpt Coll Mgr
    //     for Delivery Office and Administrator is Hireslip Rpt Dly Ofc Admin
    //     for Barcode is Hireslip Rpt Barcode
    //     for Transshipment Manager is Hireslip Rpt Trnas Mgr
    //     for Booking Administrator is Hireslip Rpt Bkg Admin
    //     for Super Administrator is Hireslip Rpt Super Admin
    ///

    hireDto: HireSlipDto = new HireSlipDto();
    selectedSearchBy: any;
    selectedHireslipNo: any;
    selectedFromDate: any;
    selectedToDate: any;
    selectedDest: any;
    selectedSrc: any;
    selectedDestSum: any;
    selectedSrcSum: any;
    selectedMainStation: any;
    selectedSearchByDateWise: any;
    selectedSearchBySmryWise: any;
    sourceAllList: Array<any> = [];
    destAllList: Array<any> = [];
    searchSrcList: Array<any> = [];
    searchDestList: Array<any> = [];
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    lrDtoFromStn: LRDto = new LRDto();
    lrDtoFromStnAll: LRDto = new LRDto();
    lrDtosFromStnOptionsGet: LRDto[];
    agentDto: AgentDetailsDto = new AgentDetailsDto();
    agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    agentDetailsOptionsGet: AgentDetailsDto[];
    fromStationOptionGet: any;
    lrDtoFromStnSum: LRDto = new LRDto();
    lrDtoFromStnSumAll: LRDto = new LRDto();
    lrDtosFromStnSumOptionsGet: LRDto[];
    agentDtoSum: AgentDetailsDto = new AgentDetailsDto();
    agentDtoAllSum: AgentDetailsDto = new AgentDetailsDto();
    agentDetailsOptionsGetSum: AgentDetailsDto[];
    lrDtoSrcSpec: LRDto = new LRDto();
    lrDtoSrcSpecAll: LRDto = new LRDto();
    lrDtosSrcSpecOptionsGet: LRDto[];
    lrDtoSrcAll: LRDto = new LRDto();
    lrDtoSrcAllAll: LRDto = new LRDto();
    lrDtosSrcAllOptionsGet: LRDto[];
    lrDtoToStationAll: LRDto = new LRDto();
    lrDtoToStation: LRDto = new LRDto();
    lrDtosToStationOptionsGet: LRDto[];
    lrDtoToStationSumAll: LRDto = new LRDto();
    lrDtoToStationSum: LRDto = new LRDto();
    lrDtosToStationSumOptionsGet: LRDto[];
    lrDtoToStationSumSpecAll: LRDto = new LRDto();
    lrDtoToStationSumSpec: LRDto = new LRDto();
    lrDtosToStationSumSpecOptionsGet: LRDto[];
    lrDtoDestWithTransAll: LRDto = new LRDto();
    lrDtoDestWithTrans: LRDto = new LRDto();
    lrDtosDestWithTransOptionsGet: LRDto[];
    agentDtoSrcVia: AgentDetailsDto = new AgentDetailsDto();
    agentDtoSrcViaAll: AgentDetailsDto = new AgentDetailsDto();
    agentDetailsSrcViaOptionsGet: AgentDetailsDto[];
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerHireNdDateWise: Subject<any> = new Subject();
    dtTriggerInvoice: Subject<any> = new Subject();
    dtTriggerViaReport: Subject<any> = new Subject();
    dtOptionsHireNdDateWise: any;
    dtOptionInvoice: any;
    dtOptionViaReport: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;

    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    destinationSumOption: Array<LRDto> = [];
    public modelDestination: any;
    destinationSumTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationSumTA
                : this.destinationSumTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { godownName: string }) => x.godownName;

    destinationDtoForAll: LRDto = new LRDto();
    destinationList: Array<any> = [];
    ///For source
    agentDtoOptionSourceStationAll: AgentDetailsDto = new AgentDetailsDto();
    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelMainBookingSource: any;
    mainBookingSourceTA: Array<AgentDetailsDto> = [];
    focusMainBookingSourceTA$ = new Subject<string>();
    searchMainBookingSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainBookingSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainBookingSourceTA
                : this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;
    //For Sorce Main Stations
    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();
    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { source: string }) => x.source;
    // formatterSource = (x: { subStations: string }) => x.subStations;
    sourceList: Array<any> = [];
    showSpinnerForAction = false;
    hideshowForGoodsLimitTransId = false;
    hireslipNumberRet: any;
    listDto: Array<LRDto> = [];
    isMatched = false;

    rightsOfHirelsipRpt: any;
    tripScheduledPopUp: NgbModalRef;
    tripScheduledClosePopUp: string;
    @ViewChild('tripScheduledPopUpTemplate') private tripScheduledPopUpTemplate;
    viewTripScheduledPopUp = false;
    invoicedtoForSearchOtherDetails: InvoiceDto = new InvoiceDto();
    hireslipdtoGetDetailsForPopup: HireSlipDto = new HireSlipDto();
    hireslipdtoRecDetails: any;
    hireSlipDtoForInvoiceDetails: HireSlipDto = new HireSlipDto();


    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    viewCustomPrintV1 = false;
    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    setTodayDateOnToDate: any;
    setTodayDateOnFromDate: any;
    grandtotallr = 0;
    grandArticles = 0;
    grandGurWt = 0;
    grandKantaWt = 0;
    grandTotHire = 0;
    grandAdvance = 0;
    grandBalance = 0;
    grandTopay = 0;
    grandPaid = 0;
    grandActWt = 0;
    grandChWt = 0;
    grandRiskChg = 0;
    grandGoodsValueBelow = 0;
    grandGoodsValueAbove = 0;
    agentDtoDetailsSourceStation: AgentDetailsDto[];
    lrDtoSourceAddOption: LRDto = new LRDto();
    destinationOption: Array<LRDto> = [];

    //for prints
    summaryInvoiceForLrDtoPrint: LRDto = new LRDto();
    viewInvoiceSummaryPrint_Hireslip = false;
    lrDtoPrintInvoiceBtn: LRDto = new LRDto();
    hireSlipDtoForCreateHireslipLocalStorage: HireSlipDto = new HireSlipDto();
    viewInvoiceDetailsPrint_Hireslip = false;
    //

    public modelDropDownDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDropDownDestinationTA$ = new Subject<string>();
    searchDropDownInputFieldDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownDestination = (x: { godownName: string }) => x.godownName;
    hideshowForGoodsValueLimitForTransIdStr: any;
    pageId = "hrsld";

    setHireslipNo: any;
    setHireslipAmt: any;
    setHireslipBalance: any;
    setHireslipAdvance: any;
    showHyperLinkHireEdit: Boolean = false;
    //showHyperLinkHireEditSingleHire: Boolean = false;
    hireslipDtoHireEdit: HireSlipDto = new HireSlipDto();
    enteredHireslipAmt: any;
    enteredHireslipBalance: any;
    enteredHireslipAdvance: any;
    showHireColumns: Boolean = false;
    showHyperLinkAndHire: Boolean = false;
    selectedInvoiceNumber: any;

    constructor(private router: Router, private reportService: ReportService, public dialog: MatDialog,
        private masterReadService: MasterReadService, private modalService: NgbModal, private stockService: StockService,
        public changeDetectorRef: ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.showForNoAndDateWise = true;
            this.hideshowForGoodsLimitTransId = this.userDataDtoReturnSession.isGoodsLimitTransId;
            this.hideshowForGoodsValueLimitForTransIdStr = this.userDataDtoReturnSession.goodsValueLimitForTransIdStr;
            this.selectTodayDate();
            ///Rights
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "HireSlipRpt DummyInvoiceView") {
                        this.DummyInvView = true;
                    }
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Hireslip Rpt R1") {
                        this.rightsOfHirelsipRpt = 'Hireslip Rpt R1';
                        // For Booking Mananger 
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Hireslip Rpt R2") {
                        console.log('check');
                        this.rightsOfHirelsipRpt = 'Hireslip Rpt R2';
                        //For Booking Administrator
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Hireslip Rpt R3") {
                        this.rightsOfHirelsipRpt = 'Hireslip Rpt R3';
                        // ForDelivery Manager
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Hireslip Rpt R4") {
                        this.rightsOfHirelsipRpt = 'Hireslip Rpt R4';
                        //For Collection Manager
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Hireslip Rpt R5") {
                        this.rightsOfHirelsipRpt = 'Hireslip Rpt R5';
                        // For Transshipment Manager
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Hireslip Rpt R6") {
                        this.rightsOfHirelsipRpt = 'Hireslip Rpt R6';
                        //For Super Administrator
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Hireslip Rpt R7") {
                        this.rightsOfHirelsipRpt = 'Hireslip Rpt R7';
                        //For Delivery office --> Administrator
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Hireslip Rpt Hire Edit") {
                        this.showHyperLinkHireEdit = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Hireslip Rpt Show Hire") {
                        this.showHireColumns = true;
                    }
                }
            }

            if (this.showHyperLinkHireEdit && this.showHireColumns) {
                this.showHyperLinkAndHire = true;
            }
            /////
            this.gettingFromStation();
            this.getToStationDetailsList();
            // this.getDestinationDetails();
            // this.getMainBookingSourceDetails();
        }
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var showOverAllSummary = false;
        if (this.hideshowForGoodsLimitTransId == true) {
            showOverAllSummary = true;
        } else {
            showOverAllSummary = false;
        }

        var exportHireDetailsArray = [], exportHireSummaryArray = [];
        if (this.showHireColumns) {
            exportHireDetailsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 41, 42];
            exportHireSummaryArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        } else {
            exportHireDetailsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 41, 42];
            exportHireSummaryArray = [0, 1, 2, 3, 4, 5, 6, 7, 9];
        }
        this.dtOptionsHireNdDateWise = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: exportHireDetailsArray
                },
                title: function () {
                    var returnHireNdDateWise = null;
                    returnHireNdDateWise = "Hireslip Details Report " +
                        "Hireslip Number: " + $("#hrsldhireslipNumberID").val();
                    return returnHireNdDateWise;
                }
            }],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 320,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            columnDefs: [
                { width: 200, targets: 0 }
            ],
            fixedColumns: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                console.log(api);
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                if (showOverAllSummary == true) {

                    var totallr = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var belowLimit = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var aboveLimit = api.column(13).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var articles = api.column(14).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var gurwt = api.column(15).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var kantawt = api.column(16).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actwt = api.column(17).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chargedWt = api.column(18).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totalhire = api.column(19).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totalhire2 = api.column(20).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var advance = api.column(21).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var balance = api.column(22).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var touchingHire = api.column(23).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var topay = api.column(24).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paid = api.column(25).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var riskChgAmt = api.column(27).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(11).footer()).html(totallr);
                    $(api.column(12).footer()).html(belowLimit);
                    $(api.column(13).footer()).html(aboveLimit);
                    $(api.column(14).footer()).html(articles);
                    $(api.column(15).footer()).html(gurwt);
                    $(api.column(16).footer()).html(kantawt);
                    $(api.column(17).footer()).html(actwt);
                    $(api.column(18).footer()).html(chargedWt);
                    $(api.column(19).footer()).html(totalhire);
                    $(api.column(20).footer()).html(totalhire2);
                    $(api.column(21).footer()).html(advance);
                    $(api.column(22).footer()).html(balance);
                    $(api.column(23).footer()).html(touchingHire);
                    $(api.column(24).footer()).html(topay);
                    $(api.column(25).footer()).html(paid);
                    $(api.column(27).footer()).html(riskChgAmt);
                } else {
                    var totallr = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var articles = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var gurwt = api.column(13).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var kantawt = api.column(14).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actwt = api.column(15).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chargedWt = api.column(16).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totalhire = api.column(17).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totalhire2 = api.column(18).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var advance = api.column(19).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var balance = api.column(20).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var topay = api.column(22).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paid = api.column(23).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var riskChgAmt = api.column(25).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var touchingHire = api.column(26).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(11).footer()).html(totallr);
                    $(api.column(12).footer()).html(articles);
                    $(api.column(13).footer()).html(gurwt);
                    $(api.column(14).footer()).html(kantawt);
                    $(api.column(15).footer()).html(actwt);
                    $(api.column(16).footer()).html(chargedWt);
                    $(api.column(17).footer()).html(totalhire);
                    $(api.column(18).footer()).html(totalhire2);
                    $(api.column(19).footer()).html(advance);
                    $(api.column(20).footer()).html(balance);
                    $(api.column(26).footer()).html(touchingHire);
                    $(api.column(22).footer()).html(topay);
                    $(api.column(23).footer()).html(paid);
                    $(api.column(25).footer()).html(riskChgAmt);
                }
            }
        }
        this.dtOptionInvoice = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: exportHireSummaryArray
                },
                title: function () {
                    var returnInvoice = null;
                    returnInvoice = "Hireslip Details Report- Summary Wise " +
                        " From Date : " + moment($("#hrsldfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#hrsldtoDate").val()).format('DD-MM-YYYY');
                    return returnInvoice;
                }
            }],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var actwt = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chargedWt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var gurwt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var kantawt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var topay = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var riskChgAmt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalhire = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var advance = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var balance = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var touchingHire = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(actwt);
                $(api.column(2).footer()).html(chargedWt);
                $(api.column(3).footer()).html(gurwt);
                $(api.column(4).footer()).html(kantawt);
                $(api.column(5).footer()).html(topay);
                $(api.column(6).footer()).html(paid);
                $(api.column(7).footer()).html(riskChgAmt);
                $(api.column(8).footer()).html(totalhire);
                $(api.column(9).footer()).html();
                $(api.column(10).footer()).html(advance);
                $(api.column(11).footer()).html(balance);
                $(api.column(12).footer()).html(touchingHire);
            }
        }

        this.dtOptionViaReport = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                title: function () {
                    var returnSummaryExcelAmtColt = null;
                    returnSummaryExcelAmtColt = "Hireslip Details Report-Summary Wise " +
                        " From Date : " + moment($("#hrsldfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#hrsldtoDate").val()).format('DD-MM-YYYY');
                    return returnSummaryExcelAmtColt;
                }
            }],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var actwt = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chargedWt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var topay = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var riskChgAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(actwt);
                $(api.column(2).footer()).html(chargedWt);
                $(api.column(3).footer()).html(topay);
                $(api.column(4).footer()).html(paid);
                $(api.column(5).footer()).html(riskChgAmt);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerHireNdDateWise.next();
        this.dtTriggerInvoice.next();
        this.dtTriggerViaReport.next();
    }
    ngOnDestroy(): void {
        this.dtTriggerHireNdDateWise.unsubscribe();
        this.dtTriggerInvoice.unsubscribe();
        this.dtTriggerViaReport.unsubscribe();
    }

    clearTable() {
        $("#" + this.pageId + "hireandDateDataTable").DataTable().destroy();
        this.dtTriggerHireNdDateWise.next();
        $("#" + this.pageId + "mainandDummyWiseDatatable").DataTable().destroy();
        this.dtTriggerInvoice.next();
        $("#" + this.pageId + "viaReportTable").DataTable().destroy();
        this.dtTriggerViaReport.next();

    }
    searchByMode(searchBy: string) {
        if (searchBy === 'hireslipNumber') {
            this.searchByHireslipNo = true;
            this.viewDate = false;
            this.searchByDateWise = false;
            this.searchBySmryWise = false;
            this.showSrc = false;
            this.showDest = false;
            this.showSrcSum = false;
            this.showDestSum = false;
            this.showForNoAndDateWise = true;
            this.showForViaReportTable = false;
            this.showForMainAndDummyWise = false;
            this.hireNdDataWiseDataList = [];
            this.clearTable();
        } else if (searchBy === 'dateWise') {
            this.showForNoAndDateWise = true;
            this.showForViaReportTable = false;
            this.showForMainAndDummyWise = false;
            this.searchByDateWiseMethod();
            this.hireNdDataWiseDataList = [];
            this.selectTodayDate();
            this.clearTable();
        } else if (searchBy === 'summaryWise') {
            this.showForNoAndDateWise = false;
            this.showForViaReportTable = false;
            this.showForMainAndDummyWise = true;
            this.showSrcSum = false;
            this.showSrc = true;
            this.gettingFromStationSum();
            this.getToStationDetailsListSum();

            this.searchBySummaryWiseMethod();
            this.selectTodayDate();
            this.clearTable();
        } else {
            this.searchByHireslipNo = false;
            this.searchByDateWise = false;
            this.viewDate = false;
            this.viewSource = false;
            this.viewDestination = false;
            this.searchBySmryWise = false;
            this.showForNoAndDateWise = false;
            this.clearTable();
        }
    }
    sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#" + this.pageId + "sourceId").val(this.modelSource.subStations);
    }
    clickListnerForMainBookingSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelMainBookingSource = e.item;
        $("#" + this.pageId + "mainBookingSourceId").val(this.modelMainBookingSource.mainBookStations);
    }
    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDropDownDestination = e.item;
        $("#" + this.pageId + "destinationID").val(this.modelDropDownDestination.destination);
    }
    clickListnerForDestinationSum(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationSum").val(this.modelDestination.destination);

    }
    sourceTypeMode(sourceType: string) {
        if (sourceType === 'mainSource') {
            this.searchByDateWiseMainSource();
        } else if (sourceType === 'individualSource') {
            this.searchByDateWiseIndividualSource();
        } else {

        }
    }
    summaryTypeMode(summaryType: string) {
        if (summaryType === 'mainInvoice') {
            this.summaryWiseInvoiceTable = true;
            this.summaryWiseViaReportTable = false;
            this.showSrcSum = false;
            this.showSrc = true;
            this.searchBySummaryWiseMainInv();
            this.smryInvoiceDataList = [];
            this.selectTodayDate();
            this.clearTable();
        } else if (summaryType === 'dummyInvoice') {
            this.summaryWiseInvoiceTable = true;
            this.summaryWiseViaReportTable = false;
            this.showSrcSum = false;
            this.showSrc = true;
            this.searchBySummaryWiseDummyInv();
            this.smryInvoiceDataList = [];
            this.selectTodayDate();
            this.clearTable();
        } else if (summaryType === 'viaReport') {
            this.summaryWiseInvoiceTable = false;
            this.summaryWiseViaReportTable = true;
            this.showSrcSum = true;
            this.showSrc = false;
            this.searchBySummaryWiseViaReport();
            this.smryViaReportDataList = [];
            this.clearTable();
            this.selectTodayDate();
        } else {
            this.summaryWiseInvoiceTable = false;
            this.summaryWiseViaReportTable = false;
            this.clearTable();
        }
    }
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
    }
    searchByDateWiseMethod() {
        this.searchByHireslipNo = false;
        this.searchBySmryWise = false;
        this.searchByDateWise = true;
        this.showSrc = true;
        this.showDest = true;
        this.viewDate = true;
        this.showSrcSum = false;
        this.showDestSum = false;

        if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R5' || this.rightsOfHirelsipRpt == 'Hireslip Rpt R6') {
            this.showSrc = true;
            this.showDest = true;
        } else if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R1') {
            this.showSrc = false;
            this.showDest = true;
        } else if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R3' || this.rightsOfHirelsipRpt == 'Hireslip Rpt R4' || this.rightsOfHirelsipRpt == 'Hireslip Rpt R7') {
            this.showSrc = true;
            this.showDest = false;
        }
    }
    /// For Date Wise Main Source
    searchByDateWiseMainSource() {
        //this.getSourceStationDetails();
        this.showSrc = false;
        this.showSrcSum = true;
        this.getMainBookingSourceDetails();
    }
    /// For Date Wise Individual Source
    searchByDateWiseIndividualSource() {
        this.showSrc = true;
        this.showSrcSum = false;
        this.gettingFromStation();
        this.getToStationDetailsList();

    }
    /// For Summary Wise Method   
    searchBySummaryWiseMethod() {
        if (this.comboBoxAllsum) {
            this.getToStationDetailsListSum();
            this.gettingFromStationSum();
            this.comboBoxAllsum = false;
        }
        // this is added by basha so that  For Summary Wise Main Invoice it show the proper result in dropdown
        if (this.DummyInvView) {
            this.getSourceAllList();
            this.getToStationDetailsListSum();
        }

        this.summaryView();
    }
    /// For Summary Wise Main Invoice   
    searchBySummaryWiseMainInv() {
        this.showForMainAndDummyWise = true;
        this.showForViaReportTable = false;
        this.showForNoAndDateWise = false;

        this.getSourceAllList();
        this.getToStationDetailsListSum();
        this.summaryView();


    }
    /// For Summary Wise Dummy Invoice  
    searchBySummaryWiseDummyInv() {
        this.showForMainAndDummyWise = true;
        this.showForViaReportTable = false;
        this.showForNoAndDateWise = false;
        this.getSourceSpecList();
        this.getToStationDetailsListSumSpec();
        this.summaryView();
    }
    /// For Summary Wise Via Report  
    searchBySummaryWiseViaReport() {
        this.showForViaReportTable = true;
        this.showForMainAndDummyWise = false;
        this.showForNoAndDateWise = false;

        this.clearFields();
        this.getMainBookingSourceDetails();
        this.gettingDestWithTrans();
    }
    summaryView() {
        this.clearFields();
        this.searchByHireslipNo = false;
        this.searchByDateWise = false;
        this.viewDate = true;
        this.showSrc = false;
        this.showDest = false;
        this.showSrcSum = true;
        this.showDestSum = true;
        if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R5' || this.rightsOfHirelsipRpt == 'Hireslip Rpt R6') {
            this.showSrcSum = true;
            this.showDestSum = true;
        } else if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R1') {
            this.showSrcSum = false;
            this.showDestSum = true;
        } else if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R3' || this.rightsOfHirelsipRpt == 'Hireslip Rpt R4' || this.rightsOfHirelsipRpt == 'Hireslip Rpt R7') {
            this.showSrcSum = true;
            this.showDestSum = false;
        }

        if (this.DummyInvView) {
            this.searchBySmryWise = true;
        }
    }

    clearFields() {
        $('input[type="text"],[type="number"]').val('');
        // Set Date As new Date 
        //        $("#"+this.pageId+"fromDate" ).val(new Date()  );
        //        $("#"+this.pageId+"toDate" ).val(new Date() );
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "sourceId").val('ALL');
        $("#" + this.pageId + "mainBookingSourceId").val('ALL');
        $("#" + this.pageId + "destinationID").val('ALL');
        $("#" + this.pageId + "destinationSum").val('');
        $("#" + this.pageId + "hireslipNumberID").val('');
        this.hireNdDataWiseDataList = null;
        this.smryInvoiceDataList = null;
        this.smryViaReportDataList = null;
    }
    //From Station here need to check the stationList
    gettingFromStation() {
        if ((this.rightsOfHirelsipRpt == 'Hireslip Rpt R2') && (this.userDataDtoReturnSession.stationList != null)) {
            this.getAgentListDetails();
        } else {
            this.gettingFromStationList();
        }
    }
    gettingFromStationDetails() {
        this.lrDtoFromStn.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoFromStn.mode = "notStmtInvRpt";
        this.showSpinnerForAction = true;
    }
    gettingFromStationList = () => {
        this.gettingFromStationDetails();
        this.masterReadService.getFromStationService(this.lrDtoFromStn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.sourceSubStationNameTA = [];
                    this.sourceStationOptions = response;
                    this.lrDtoFromStnAll.source = "ALL";
                    this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
                    for (let i = 0; i < this.sourceStationOptions.length; i++) {
                        this.sourceSubStationNameTA.push(this.sourceStationOptions[i]);

                    }
                    $("#" + this.pageId + "sourceId").val('ALL');

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    // get Agent List 
    getAgentListDetails() {
        this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDto.column2 = "Working";
        this.agentDto.mode = "mainBranch";
        if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R2') {
            this.agentDto.mainStation = this.userDataDtoReturnSession.mainStation;
        } else {
            this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        }
        this.showSpinnerForAction = true;
        this.getAgentList();
    }

    getAgentList = () => {
        this.masterReadService.getSourceListDetails(this.agentDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.sourceSubStationNameTA = [];
                    this.agentDtoDetailsSourceStation = [];
                    this.agentDtoDetailsSourceStation = response;
                    console.log(this.agentDtoDetailsSourceStation);
                    this.lrDtoFromStnAll.source = "ALL";
                    this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
                    for (let i = 0; i < this.agentDtoDetailsSourceStation.length; i++) {
                        this.lrDtoSourceAddOption = new LRDto();
                        this.lrDtoSourceAddOption.source = this.agentDtoDetailsSourceStation[i].subStation;
                        this.sourceSubStationNameTA.push(this.lrDtoSourceAddOption);
                    }

                    $("#" + this.pageId + "sourceId").val('ALL');
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    /////////////////////////////////////////////////
    /// Get To Stations

    getToStationDetails() {
        this.lrDtoToStation.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
    }
    getToStationDetailsList = () => {
        this.getToStationDetails();
        this.masterReadService.getDestinationListDetails(this.lrDtoToStation).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Destination records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    if (response.length == 0) {
                        this.destinationOption = [];
                        this.destinationTA = [];
                    } else {
                        this.destinationOption = [];
                        this.destinationTA = [];
                        this.destinationOption = response;
                        this.lrDtoToStationAll.godownName = "ALL";
                        this.destinationTA.push(this.lrDtoToStationAll);
                        for (let i = 0; i < this.destinationOption.length; i++) {
                            this.destinationTA.push(this.destinationOption[i]);
                        }
                        $("#" + this.pageId + "destinationID").val('ALL');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    ///For Main Source and via wise search this is used as source
    getMainBookingSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }
    getMainBookingSourceDetails() {
        this.getMainBookingSourceDataDetails();
        this.showSpinnerForAction = true;
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                //console.log(response);
                if (response.length > 0) {
                    this.mainBookingSourceTA = [];
                    this.agentDtoOptionSourceStation = [];
                    this.agentDtoOptionSourceStation = response;
                    this.agentDtoOptionSourceStationAll.mainBookStations = "ALL";
                    this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);
                    this.sourceList = [];
                    console.log('am here ')
                    for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                        this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
                        this.sourceList.push(this.agentDtoOptionSourceStation[i].mainBookStations);
                    }
                    this.sourceAllList = [];
                    for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                        this.sourceAllList.push(this.agentDtoOptionSourceStation[i].mainBookStations);
                    }
                    console.log(this.sourceAllList);
                    this.showSpinnerForAction = false;
                    $("#" + this.pageId + "mainBookingSourceId").val('ALL');
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Biiking Source Details", "info");
            },
            () => console.log('done');

    };
    /// get Dest With Trans  it is used as destination for Via Report
    gettingDestWithTransDetails() {
        this.lrDtoDestWithTrans.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestWithTrans.mode = "destAndTrans";
        this.showSpinnerForAction = true;
        console.log('gettingDestWithTrans');
    }
    gettingDestWithTrans = () => {
        this.gettingDestWithTransDetails();
        this.masterReadService.getDestinationListDetails(this.lrDtoDestWithTrans).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Destination records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.destinationSumOption = [];
                    this.destinationSumTA = [];
                    this.destinationSumOption = response;
                    for (let i = 0; i < this.destinationSumOption.length; i++) {
                        this.destinationSumTA.push(this.destinationSumOption[i]);
                    }

                    this.destAllList = [];
                    for (let i = 0; i < this.destinationSumOption.length; i++) {
                        this.destAllList.push(this.destinationSumOption[i].godownName);
                    }
                    $("#" + this.pageId + "destinationSum").val('ALL');
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    ////////////////


    //From Station Summary

    gettingFromStationSum() {
        if ((this.rightsOfHirelsipRpt == 'Hireslip Rpt R2') && (this.userDataDtoReturnSession.stationList != null)) {
            this.getAgentListDetailsSum();
        } else {
            this.gettingFromStationListSum();
        }
    }
    gettingFromStationDetailsSum() {
        this.lrDtoFromStnSum.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoFromStnSum.mode = "notStmtInvRpt";
        this.showSpinnerForAction = true;
    }
    gettingFromStationListSum = () => {
        this.gettingFromStationDetailsSum();
        this.masterReadService.getFromStationService(this.lrDtoFromStnSum).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtosFromStnSumOptionsGet = [];
                    this.lrDtosFromStnSumOptionsGet = response;
                    console.log(this.lrDtosFromStnSumOptionsGet);
                    this.sourceAllList = [];
                    this.mainBookingSourceTA = [];
                    this.agentDtoOptionSourceStationAll.mainBookStations = "ALL";
                    this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);
                    for (let i = 0; i < this.lrDtosFromStnSumOptionsGet.length; i++) {
                        this.agentDtoOptionSourceStationAll = new AgentDetailsDto();
                        this.agentDtoOptionSourceStationAll.mainBookStations = this.lrDtosFromStnSumOptionsGet[i].source;
                        this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);

                    }
                    for (let i = 0; i < this.lrDtosFromStnSumOptionsGet.length; i++) {
                        this.sourceAllList.push(this.lrDtosFromStnSumOptionsGet[i].source);
                    }
                    $("#" + this.pageId + "mainBookingSourceId").val('ALL');
                    //                    console.log( this.lrDtosFromStnSumOptionsGet );

                    //                    this.srclist is pending 
                    //                    sourceAllList.add(dto.get(i).getSource());

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    // get Agent List Summary
    getAgentListDetailsSum() {
        this.agentDtoSum.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoSum.column2 = "Working";
        this.agentDtoSum.mode = "mainBranch";
        if ((this.rightsOfHirelsipRpt == 'Hireslip Rpt R2') && (this.userDataDtoReturnSession.stationList != null)) {
            this.agentDtoSum.mainStation = this.userDataDtoReturnSession.mainStation;
        } else {
            this.agentDtoSum.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        }
        this.showSpinnerForAction = true;
        this.getAgentListSum();

    }


    getAgentListSum = () => {
        this.masterReadService.getSourceListDetails(this.agentDtoSum).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.agentDetailsOptionsGetSum = [];
                    this.agentDetailsOptionsGetSum = response;
                    this.sourceAllList = [];
                    for (let i = 0; i < this.agentDetailsOptionsGetSum.length; i++) {
                        this.sourceAllList.push(this.agentDetailsOptionsGetSum[i].subStation);
                    }

                    //                    console.log( this.agentDetailsOptionsGetSum );

                    //                    ListSrc is Pending
                    //                    sourceAllList.add(agentDtos.get(i));
                    this.mainBookingSourceTA = [];
                    this.agentDtoAllSum.mainBookStations = "ALL";
                    this.mainBookingSourceTA.push(this.agentDtoAllSum);
                    for (let i = 0; i < this.agentDetailsOptionsGetSum.length; i++) {
                        this.agentDtoOptionSourceStationAll = new AgentDetailsDto();
                        this.agentDtoOptionSourceStationAll.mainBookStations = this.agentDetailsOptionsGetSum[i].subStation;
                        this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);

                    }
                    $("#" + this.pageId + "mainBookingSourceId").val('ALL');

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    ////
    /// get To Station Sum
    getToStationDetailsSum() {
        this.lrDtoToStationSum.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoToStationSum.mode = "hireSlipRptSumWis";
        this.showSpinnerForAction = true;
        console.log('getToStationDetailsSum All for searchBySummaryWiseMainInv');
    }
    getToStationDetailsListSum = () => {
        this.getToStationDetailsSum();
        this.masterReadService.getDestinationListDetails(this.lrDtoToStationSum).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Destination records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.lrDtosToStationSumOptionsGet = [];
                    this.destAllList = [];
                    this.destinationSumTA = [];
                } else {
                    this.lrDtosToStationSumOptionsGet = [];
                    this.destAllList = [];
                    this.destinationSumTA = [];
                    this.lrDtosToStationSumOptionsGet = response;

                    for (let i = 0; i < this.lrDtosToStationSumOptionsGet.length; i++) {
                        this.destAllList.push(this.lrDtosToStationSumOptionsGet[i].godownName);
                    }
                    //                    console.log( this.lrDtosToStationSumOptionsGet );
                    //                    destAllList.add(dto.get(i).getGodownName());

                    this.lrDtoToStationSumAll.godownName = "ALL";
                    this.destinationSumTA.push(this.lrDtoToStationSumAll);
                    for (let i = 0; i < this.lrDtosToStationSumOptionsGet.length; i++) {
                        this.destinationSumTA.push(this.lrDtosToStationSumOptionsGet[i]);
                    }
                    $("#" + this.pageId + "destinationSum").val('ALL');

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    ///
    ///get Source All 
    getSourceAllDetails() {
        this.lrDtoSrcAll.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSrcAll.mode = "trnsTripRptSRC";
        this.showSpinnerForAction = true;
        console.log('source All for searchBySummaryWiseMainInv');
    }
    getSourceAllList = () => {
        this.getSourceAllDetails();
        this.masterReadService.getFromStationService(this.lrDtoSrcAll).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtosFromStnSumOptionsGet = [];
                    this.lrDtosFromStnSumOptionsGet = response;
                    console.log(this.lrDtosFromStnSumOptionsGet);
                    this.sourceAllList = [];
                    for (let i = 0; i < this.lrDtosFromStnSumOptionsGet.length; i++) {
                        this.sourceAllList.push(this.lrDtosFromStnSumOptionsGet[i].source);
                    }
                    this.mainBookingSourceTA = [];
                    this.agentDtoAllSum.mainBookStations = "ALL";
                    this.mainBookingSourceTA.push(this.agentDtoAllSum);

                    for (let i = 0; i < this.lrDtosFromStnSumOptionsGet.length; i++) {
                        this.agentDtoOptionSourceStationAll = new AgentDetailsDto();
                        this.agentDtoOptionSourceStationAll.mainBookStations = this.lrDtosFromStnSumOptionsGet[i].source;
                        this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);

                    }
                    $("#" + this.pageId + "mainBookingSourceId").val('ALL');
                    console.log('ffff');
                    console.log(this.sourceAllList);
                    //this.lrDtoFromStnSumAll.source = "ALL";
                    //this.lrDtosFromStnSumOptionsGet.push(this.lrDtoFromStnSumAll);
                    //                    this.srclist is pending 
                    //                  sourceAllList.add(agentDtos.get(i));
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };


    ///get Source Spec 

    getSourceSpecDetails() {
        this.lrDtoSrcSpec.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSrcSpec.mode = "notStmtInvRpt";
        this.showSpinnerForAction = true;
        console.log('getSourceSpecList All for searchBySummaryWiseDummyInv');
    }
    getSourceSpecList = () => {
        this.getSourceSpecDetails();
        this.masterReadService.getFromStationService(this.lrDtoSrcSpec).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtosFromStnSumOptionsGet = response;
                    this.sourceAllList = [];
                    for (let i = 0; i < this.lrDtosFromStnSumOptionsGet.length; i++) {
                        this.sourceAllList.push(this.lrDtosFromStnSumOptionsGet[i].source);
                    }

                    this.mainBookingSourceTA = [];
                    this.agentDtoAllSum.mainBookStations = "ALL";
                    this.mainBookingSourceTA.push(this.agentDtoAllSum);
                    for (let i = 0; i < this.lrDtosFromStnSumOptionsGet.length; i++) {
                        this.agentDtoOptionSourceStationAll = new AgentDetailsDto();
                        this.agentDtoOptionSourceStationAll.mainBookStations = this.lrDtosFromStnSumOptionsGet[i].source;
                        this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);
                    }
                    //                    console.log( this.lrDtosFromStnSumOptionsGet );
                    this.lrDtoFromStnSumAll.source = "ALL";
                    this.lrDtosFromStnSumOptionsGet.push(this.lrDtoFromStnSumAll);
                    //                    ListSrc is Pending
                    //                  sourceAllList.add(agentDtos.get(i));

                    $("#" + this.pageId + "mainBookingSourceId").val('ALL');

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    /// get To Station Sum Spec
    getToStationDetailsSumSpec() {
        this.lrDtoToStationSumSpec.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoToStationSumSpec.mode = "hireSlipRptSumWisSpec";
        this.showSpinnerForAction = true;
        console.log('getToStationDetailsListSumSpec  for searchBySummaryWiseDummyInv');
    }
    getToStationDetailsListSumSpec = () => {
        this.getToStationDetailsSumSpec();
        this.masterReadService.getDestinationListDetails(this.lrDtoToStationSumSpec).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No DestinFixedSizeVirtualScrollStrategyation records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtosToStationSumOptionsGet = [];
                    this.lrDtosToStationSumOptionsGet = response;
                    this.destAllList = [];
                    for (let i = 0; i < this.lrDtosToStationSumOptionsGet.length; i++) {
                        this.destAllList.push(this.lrDtosToStationSumOptionsGet[i].godownName);
                    }
                    //                    console.log( this.lrDtosToStationSumOptionsGet );

                    // this.lrDtosToStationSumOptionsGet.push(this.lrDtoToStationSumAll);
                    //                    destAllList.add(dto.get(i).getGodownName());
                    this.destinationSumOption = [];
                    this.destinationSumTA = [];
                    this.lrDtoToStationSumAll.godownName = "ALL";
                    this.destinationSumTA.push(this.lrDtoToStationSumAll);
                    for (let i = 0; i < this.lrDtosToStationSumOptionsGet.length; i++) {
                        this.destinationSumTA.push(this.lrDtosToStationSumOptionsGet[i]);
                    }
                    $("#" + this.pageId + "destinationSum").val('ALL');

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };


    //btn search Work 

    validateBtnSearch() {
        this.getHireslipReportDetails();
    }

    getHireslipReportDetails() {
        //this.showHyperLinkHireEditSingleHire = false;
        this.selectedSrc = $("#" + this.pageId + "sourceId").val();
        this.selectedDest = $("#" + this.pageId + "destinationID").val();
        this.selectedSrcSum = $("#" + this.pageId + "mainBookingSourceId").val();
        this.selectedDestSum = $("#" + this.pageId + "destinationSum").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedHireslipNo = $("#" + this.pageId + "hireslipNumberID").val();
        this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
        this.selectedSearchByDateWise = $("#" + this.pageId + "sourceType").val();
        this.selectedSearchBySmryWise = $("#" + this.pageId + "summaryType").val();
        this.selectedMainStation = this.userDataDtoReturnSession.mainStation;
        //        store.removeAll();
        this.hireDto = new HireSlipDto();
        this.hireDto.status = null;
        this.hireDto.companyid = this.userDataDtoReturnSession.companyId;
        if (this.selectedSearchBy == 'hireslipNumber') {
            if (this.selectedHireslipNo == null || this.selectedHireslipNo == '') {
                swal({
                    title: "Warning",
                    text: "Please Enter the Hireslip number",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {
                if ((this.rightsOfHirelsipRpt == 'Hireslip Rpt R3')
                    || (this.rightsOfHirelsipRpt == 'Hireslip Rpt R4')
                    || (this.rightsOfHirelsipRpt == 'Hireslip Rpt R7')) {
                    this.hireDto.role = "delivery";
                    this.hireDto.toStation = this.userDataDtoReturnSession.office;
                    this.hireDto.tostation = this.userDataDtoReturnSession.mainStation;
                }

                this.getHireslipNumber();
            }
        } else if (this.selectedSearchBy == 'dateWise') {
            if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedFromDate == undefined || this.selectedToDate == null || this.selectedToDate == '' || this.selectedToDate == undefined) {
                swal({
                    title: "Warning",
                    text: "From Date And To Date Are Mandatory Fields",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {

                this.hireDto.fromDate = this.selectedFromDate;
                this.hireDto.toDate = this.selectedToDate;
                this.hireDto.stationList = this.userDataDtoReturnSession.stationList;

                if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R1') {
                    //For booking Manager 
                    this.hireDto.role = "booking";
                } else if ((this.rightsOfHirelsipRpt == 'Hireslip Rpt R3')
                    || (this.rightsOfHirelsipRpt == 'Hireslip Rpt R4')
                    || (this.rightsOfHirelsipRpt == 'Hireslip Rpt R7')) {
                    //For Delivery Manager 
                    this.hireDto.role = "delivery";

                } else if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R5') {
                    // For Transhipment
                    this.hireDto.role = "superadmin";
                } else if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R2') {
                    //Booking Administrator
                    this.hireDto.role = "adminStation";
                } else {
                    this.hireDto.role = "superadmin";
                }
                if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R5' || this.rightsOfHirelsipRpt == 'Hireslip Rpt R6' || this.rightsOfHirelsipRpt == 'Hireslip Rpt R2') {
                    // For transhipment, superadmin, booking adminsitrator
                    this.hireDto.fromStation = this.selectedSrc;
                    this.hireDto.toStation = this.selectedDest;
                    console.log(this.selectedSrc, this.selectedDest, this.hireDto.fromStation, this.hireDto.toStation)
                    if (this.selectedFromDate != null
                        && this.selectedToDate != null
                        && (this.selectedSrc == "ALL") && this.selectedDest == "ALL") {
                        this.hireDto.mode = "all";
                    } else if (this.selectedFromDate != null
                        && this.selectedToDate != null
                        && (this.selectedSrc != "ALL") && this.selectedDest != "ALL") {
                        this.hireDto.mode = "fromTo";
                    } else if (this.selectedFromDate != null
                        && this.selectedToDate != null
                        && (this.selectedSrc == "ALL") && this.selectedDest != "ALL") {
                        this.hireDto.mode = "toOnly";
                    } else if (this.selectedFromDate != null
                        && this.selectedToDate != null
                        && (this.selectedSrc != "ALL") && this.selectedDest == "ALL") {
                        this.hireDto.mode = "fromOnly";
                    }
                } else if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R1') {
                    // For Booking Manager
                    this.hireDto.fromStation = this.userDataDtoReturnSession.office;
                    this.hireDto.toStation = this.selectedDest;
                    if (this.selectedFromDate != null
                        && this.selectedToDate != null
                        && this.selectedDest == "ALL") {
                        this.hireDto.mode = "all";
                    } else if (this.selectedFromDate != null
                        && this.selectedToDate != null
                        && this.selectedDest != "ALL") {
                        this.hireDto.mode = "dest";
                    }
                } else if ((this.rightsOfHirelsipRpt == 'Hireslip Rpt R3')
                    || (this.rightsOfHirelsipRpt == 'Hireslip Rpt R4')
                    || (this.rightsOfHirelsipRpt == 'Hireslip Rpt R7')) {
                    if (this.selectedMainStation == "Chennai") {
                        this.hireDto.toStation = this.userDataDtoReturnSession.office;
                        this.hireDto.tostation = this.userDataDtoReturnSession.mainStation;
                    } else {
                        this.hireDto.toStation = this.userDataDtoReturnSession.office;
                    }
                    this.hireDto.fromStation = this.selectedSrc;
                    if (this.selectedFromDate != null
                        && this.selectedToDate != null
                        && this.selectedSrc == "ALL") {
                        this.hireDto.mode = "all";
                    } else if (this.selectedFromDate != null
                        && this.selectedToDate != null
                        && this.selectedSrc != "ALL") {
                        this.hireDto.mode = "source";
                    }
                }
                if (this.selectedSearchByDateWise == "mainSource") {
                    this.hireDto.fromStation = this.selectedSrcSum;
                    this.hireDto.mode = "MainSrc";
                }
                this.gridReconfigure();
            }
        } else if (this.selectedSearchBy == 'summaryWise') {
            if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedFromDate == undefined || this.selectedToDate == null || this.selectedToDate == '' || this.selectedToDate == undefined) {
                swal({
                    title: "Warning",
                    text: "From Date And To Date Are Mandatory Fields",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

            } else {
                this.searchSrcList = [];
                this.searchDestList = [];
                this.hireDto.fromDate = this.selectedFromDate;
                this.hireDto.toDate = this.selectedToDate;
                this.hireDto.fromDateInDate = this.selectedFromDate;
                this.hireDto.toDateInDate = this.selectedToDate;
                if (this.showDestSum == true) {
                    console.log('dest true');
                    console.log(this.selectedDestSum);
                    if ((this.selectedDestSum != null) && (this.selectedDestSum != "") && (this.selectedDestSum == "ALL")) {
                        console.log(this.destAllList);
                        console.log('inside');
                        this.hireDto.listOfDest = this.destAllList;
                    } else {
                        console.log('outside');
                        console.log(this.selectedDestSum);
                        console.log(this.selectedDestSum);
                        this.searchDestList.push(this.selectedDestSum);
                        this.hireDto.listOfDest = this.searchDestList;
                    }
                } else {
                    console.log('dest false');
                    this.searchDestList.push(this.userDataDtoReturnSession.mainStation);
                    this.hireDto.listOfDest = this.searchDestList;
                }
                if (this.showSrcSum == true) {
                    console.log('srd true');
                    console.log(this.selectedSrcSum);
                    if ((this.selectedSrcSum != null) && (this.selectedSrcSum != "") && (this.selectedSrcSum == "ALL")) {
                        console.log('inside src');
                        console.log(this.selectedSrcSum);
                        this.hireDto.listOfSource = this.sourceAllList;
                    } else {
                        console.log('outside src');
                        console.log(this.selectedSrcSum);
                        this.searchSrcList.push(this.selectedSrcSum);
                        this.hireDto.listOfSource = this.searchSrcList;

                    }
                } else {
                    console.log('srd false');
                    console.log(this.searchSrcList);
                    this.searchSrcList.push(this.userDataDtoReturnSession.mainStation);
                    // for testing basha 
                    //this.searchSrcList.push('bakoli');
                    this.hireDto.listOfSource = this.searchSrcList;
                }
                if (this.DummyInvView == true && this.selectedSearchBySmryWise == 'dummyInvoice') {
                    this.hireDto.status = "Dummy";
                    this.gridReconfigureSummary();
                } else if (this.DummyInvView == true && this.selectedSearchBySmryWise == 'viaReport') {
                    this.hireDto.status = "Via";
                    this.gridReconfigureSummary();
                } else {
                    this.hireDto.status = null;
                    this.gridReconfigureSummary();
                }





            }
        }
    }

    getHireslipNumber = () => {
        this.showSpinnerForAction = true;
        this.selectedHireslipNo = $("#" + this.pageId + "hireslipNumberID").val();
        this.hireDto.hireslipnumber = this.selectedHireslipNo;
        this.hireDto.goodsValueFOVLimit = (this.userDataDtoReturnSession.goodsValueLimitForTransId == null ? 0.0 : this.userDataDtoReturnSession.goodsValueLimitForTransId);
        console.log('hirelsip No Wise');
        console.log(this.hireDto);
        this.reportService.getHireslipNumberRpt(this.hireDto).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "hireandDateDataTable").DataTable().destroy();

                this.hireNdDataWiseDataList = [];
                this.hireslipNumberRet = [];
                this.hireslipNumberRet = response;

                // if (this.showHyperLinkHireEdit) {
                //     this.showHyperLinkHireEditSingleHire = true;
                // } else {
                //     this.showHyperLinkHireEditSingleHire = false;
                // }

                if (response.length == 0) {
                    swal({
                        title: "Alert",
                        text: "Hireslip number does not exist!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else if (this.hireslipNumberRet[0].fromStation != null && (this.hireslipNumberRet[0].fromStation.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase())) {
                    this.listDto = this.hireslipNumberRet;
                    this.hireNdDataWiseDataList = response;
                    console.log('1');
                } else if (this.hireslipNumberRet[0].toStation != null && ((this.hireslipNumberRet[0].toStation.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase()) || (this.hireslipNumberRet[0].toStation.toLowerCase() == this.userDataDtoReturnSession.mainStation.toLowerCase()))) {
                    this.listDto = this.hireslipNumberRet;
                    this.hireNdDataWiseDataList = response;
                    console.log('2');
                } else if (this.hireslipNumberRet[0].fromStation != null && this.hireslipNumberRet[0].toStation != null && ((this.hireslipNumberRet[0].fromStation.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase()) || (this.hireslipNumberRet[0].toStation.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase()))) {
                    this.listDto = this.hireslipNumberRet;
                    this.hireNdDataWiseDataList = response;
                    console.log('3');
                } else if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R6') {
                    this.listDto = this.hireslipNumberRet;
                    this.hireNdDataWiseDataList = response;
                    console.log('4');
                } else if (this.rightsOfHirelsipRpt == 'Hireslip Rpt R2') {
                    this.isMatched = false;
                    console.log('5');
                    for (let i = 0; i < this.userDataDtoReturnSession.stationList.length; i++) {

                        if (this.hireslipNumberRet[0].fromStation == this.userDataDtoReturnSession.stationList[i]) {
                            this.isMatched = true;
                        }
                    }
                    if (this.isMatched == true) {
                        this.listDto = this.hireslipNumberRet;
                        this.hireNdDataWiseDataList = response;
                    } else {

                        swal({
                            title: "Alert",
                            text: "Not allowed to view",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                            buttons: ["No", "Yes"],
                        }).then((update) => {
                            if (update) {
                                $("#" + this.pageId + "hireslipNumberID").val('');

                            } else {
                                //do nothing
                            }
                        });

                    }
                } else {
                    if (this.hireslipNumberRet.length > 0 && this.hireslipNumberRet[0].isMultiPntDelv) {
                        this.listDto = this.hireslipNumberRet;
                        this.hireNdDataWiseDataList = response;
                    } else {

                        swal({
                            title: "Alert",
                            text: "Not allowed to view",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                            buttons: ["No", "Yes"],
                        }).then((yes) => {
                            if (yes) {
                                $("#" + this.pageId + "hireslipNumberID").val('');

                            } else {
                                //do nothing
                            }
                        });

                    }



                }


                this.dtTriggerHireNdDateWise.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  details", "info");
            }, () => console.log('done');
    };
    gridReconfigure = () => {
        this.showSpinnerForAction = true;
        this.hireDto.goodsValueFOVLimit = (this.userDataDtoReturnSession.goodsValueLimitForTransId == null ? 0.0 : this.userDataDtoReturnSession.goodsValueLimitForTransId);
        console.log('Date Wise');
        console.log(this.hireDto);
        this.reportService.getHireslipDateWiseRpt(this.hireDto).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "hireandDateDataTable").DataTable().destroy();
                this.hireNdDataWiseDataList = [];
                this.hireslipNumberRet = [];
                this.hireslipNumberRet = response;
                if (response.length == 0) {
                    swal({
                        title: "Alert",
                        text: "No Details Found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });


                } else {

                    this.hireNdDataWiseDataList = response;

                }
                this.dtTriggerHireNdDateWise.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  details", "info");
            }, () => console.log('done');
    };


    /// For  Summary Wise
    gridReconfigureSummary() {
        this.getSummaryWiseDetails();
    }

    getSummaryWiseDetails = () => {
        this.showSpinnerForAction = true;
        console.log('Summary Wise');
        console.log(this.hireDto);
        this.selectedSearchBySmryWise = $("#" + this.pageId + "summaryType").val();
        /*  if (this.selectedSearchBySmryWise == 'mainInvoice' || this.selectedSearchBySmryWise == 'dummyInvoice') {
              this.getHireslipSummaryMainAndDummyList();
          } else {
              this.getHireslipSummaryViaReportList()
          }*/
        this.reportService.getHireslipSummaryWiseRpt(this.hireDto).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    // this.clearTable();
                    $("#" + this.pageId + "mainandDummyWiseDatatable").DataTable().destroy();
                    this.smryInvoiceDataList = [];
                    $("#" + this.pageId + "viaReportTable").DataTable().destroy();
                    this.smryViaReportDataList = [];

                    if (this.selectedSearchBySmryWise == 'mainInvoice' || this.selectedSearchBySmryWise == 'dummyInvoice') {
                        this.smryInvoiceDataList = response;
                    } else {
                        this.smryViaReportDataList = response;
                    }
                    this.dtTriggerInvoice.next();
                    this.dtTriggerViaReport.next();


                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  details", "info");
            }, () => console.log('done');
    };




    tripScheduledForPopUpBtnLink(tripScheduledPopUpTemplate, hireNdDataWise) {

        this.hireSlipDtoForInvoiceDetails = new HireSlipDto();
        this.hireSlipDtoForInvoiceDetails.hireslipnumber = hireNdDataWise.hireslipnumber;
        this.hireSlipDtoForInvoiceDetails.branch = this.userDataDtoReturnSession.office;
        this.hireSlipDtoForInvoiceDetails.companyid = this.userDataDtoReturnSession.companyId;
        this.stockService.getHireSlipDetails(this.hireSlipDtoForInvoiceDetails).subscribe(

            (response) => {
                if (response) {
                    console.log('get');
                    this.hireslipdtoRecDetails = response;
                    console.log(this.hireslipdtoRecDetails);
                    this.hireslipdtoGetDetailsForPopup = new HireSlipDto();
                    this.hireslipdtoGetDetailsForPopup = this.hireslipdtoRecDetails;

                    this.tripScheduledPopUp = this.modalService.open(tripScheduledPopUpTemplate,
                        { centered: true, windowClass: "myCustomModalClass" });
                    this.tripScheduledPopUp.result.then((result) => {
                        this.tripScheduledClosePopUp = `Closed with: ${result}`;
                    }, reason => {
                        this.tripScheduledClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
                    });
                    localStorage.clear();
                    localStorage.setItem('popupHireslipRpt', JSON.stringify(this.hireslipdtoGetDetailsForPopup));
                    // const dialogRef = this.dialog.open( StocksGroupingComponent, {

                    //                 } );

                    // dialogRef.afterClosed().subscribe( result => {
                    //     console.log( 'The dialog was closed' );
                    //     $("#"+this.pageId+"btnUnLoadRpt").prop("disabled", true);
                    //     this.router.navigate( ['.'], { relativeTo: this.route } );
                    // } );
                    //localStorage.setItem('popupUnloadingSheetOtherPrintDetails', JSON.stringify(this.hireslipdtoGetDetailsForPopup));
                    this.viewTripScheduledPopUp = true;
                    window.addEventListener('afterPopUp', (event) => {
                        this.viewTripScheduledPopUp = false;
                    });

                } else {
                    swal("No Records", "No Records found for this search", "info");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server Problem Occurred While getting the Memo Less Description Details", "info");
            },
            () => console.log('done');


    }


    closeTripScheduledPopUp() {
        this.tripScheduledPopUp.close();
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    customPrintHirslipRptForNumberAndDateWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedHireslipNo = $("#" + this.pageId + "hireslipNumberID").val();
        this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
        if (this.hireNdDataWiseDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Hire Slip  Date.", "Hire Slip  No.", "Truck No.", "Driver Mobile No.", "Truck Owner", "Owner Pan No.", "Truck Type", "Supplier", "Supplier Pan No", "LRs", "Below " + this.hideshowForGoodsValueLimitForTransIdStr, "Above " + this.hideshowForGoodsValueLimitForTransIdStr, "Total Articles", "Guarantee Weight", "Kanta Weight", "Actual  Weight", "Charged Weight", "Total Hire", "Advance", "Balance", "To Pay", "Paid", "Goods Value", "F O V", "Destination", "Truck Status", "EWay Bill No", "Tracking Id", "Carrier", "Tracking Status", "Tracking Remarks", "Last Location", "Last Ping Time"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.2, 2.5, 2.5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.grandArticles = 0;
            this.grandtotallr = 0;
            this.grandGurWt = 0;
            this.grandKantaWt = 0;
            this.grandTotHire = 0;
            this.grandAdvance = 0;
            this.grandBalance = 0;
            this.grandTopay = 0;
            this.grandPaid = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandRiskChg = 0;
            this.grandGoodsValueBelow = 0;
            this.grandGoodsValueAbove = 0;

            for (let i = 0; i < this.hireNdDataWiseDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.hireNdDataWiseDataList[i].hireSlipDateStr, this.hireNdDataWiseDataList[i].hireslipnumber, this.hireNdDataWiseDataList[i].vehicleNumber, this.hireNdDataWiseDataList[i].driverMobileNumber, this.hireNdDataWiseDataList[i].companyName, this.hireNdDataWiseDataList[i].tinNumber, this.hireNdDataWiseDataList[i].truckType, this.hireNdDataWiseDataList[i].suppliername, this.hireNdDataWiseDataList[i].supplierpanno, this.hireNdDataWiseDataList[i].totalLRs, this.hireNdDataWiseDataList[i].goodsValueBelow, this.hireNdDataWiseDataList[i].goodsValueAbove, this.hireNdDataWiseDataList[i].totalArticles, this.hireNdDataWiseDataList[i].gurWeight, this.hireNdDataWiseDataList[i].kantaWeight, this.hireNdDataWiseDataList[i].actWeight, this.hireNdDataWiseDataList[i].chargedWt, this.hireNdDataWiseDataList[i].totalhire, this.hireNdDataWiseDataList[i].advance, this.hireNdDataWiseDataList[i].balance, this.hireNdDataWiseDataList[i].toPay, this.hireNdDataWiseDataList[i].paid, this.hireNdDataWiseDataList[i].goodsValue, this.hireNdDataWiseDataList[i].riskChgAmt, this.hireNdDataWiseDataList[i].toStation, this.hireNdDataWiseDataList[i].status, this.hireNdDataWiseDataList[i].ewayBill, this.hireNdDataWiseDataList[i].trackingFTTripId, this.hireNdDataWiseDataList[i].trackingFTCarrier, this.hireNdDataWiseDataList[i].trackingFTConsentStatus, this.hireNdDataWiseDataList[i].ftRemarks, this.hireNdDataWiseDataList[i].location, this.hireNdDataWiseDataList[i].lastupdateddate];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);



                this.grandtotallr = +this.grandtotallr + +this.hireNdDataWiseDataList[i].totalLRs;
                this.grandArticles = +this.grandArticles + +this.hireNdDataWiseDataList[i].totalArticles;
                this.grandGurWt = +this.grandGurWt + +this.hireNdDataWiseDataList[i].gurWeight;
                this.grandKantaWt = +this.grandKantaWt + +this.hireNdDataWiseDataList[i].kantaWeight;
                this.grandTotHire = +this.grandTotHire + +this.hireNdDataWiseDataList[i].totalhire;
                this.grandAdvance = +this.grandAdvance + +this.hireNdDataWiseDataList[i].advance;
                this.grandBalance = +this.grandBalance + +this.hireNdDataWiseDataList[i].balance;
                this.grandTopay = +this.grandTopay + +this.hireNdDataWiseDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.hireNdDataWiseDataList[i].paid;
                this.grandActWt = +this.grandActWt + +this.hireNdDataWiseDataList[i].actWeight;
                this.grandChWt = +this.grandChWt + +this.hireNdDataWiseDataList[i].chargedWt;
                this.grandRiskChg = +this.grandRiskChg + +(this.hireNdDataWiseDataList[i].riskChgAmt == null ? 0.0
                    : this.hireNdDataWiseDataList[i].riskChgAmt);

                this.grandGoodsValueBelow = +this.grandGoodsValueBelow + +(this.hireNdDataWiseDataList[i].goodsValueBelow == null ? 0
                    : this.hireNdDataWiseDataList[i].goodsValueBelow);
                this.grandGoodsValueAbove = +this.grandGoodsValueBelow + +(this.hireNdDataWiseDataList[i].goodsValueAbove == null ? 0
                    : this.hireNdDataWiseDataList[i].goodsValueAbove);
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.hireNdDataWiseDataList.length, "", "", "", "", "", "", "", "", this.grandtotallr, this.grandGoodsValueBelow, this.grandGoodsValueAbove, this.grandArticles, this.grandGurWt, this.grandKantaWt, this.grandActWt, this.grandChWt, this.grandTotHire, this.grandAdvance, this.grandBalance, this.grandTopay, this.grandPaid, "", this.grandRiskChg, "", "", "", "", "", "", "", "", ""];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];

            if (this.selectedSearchBy == "hireslipNumber") {
                this.selectedHireslipNo = $("#" + this.pageId + "hireslipNumberID").val();
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Hireslip No."];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedHireslipNo];
            } else {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];
            }

            //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.lrDtoForSearch.entryBy];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Hireslip Details Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }


    customPrintHirslipRptForMainAndDummyInvoiceWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();

        if (this.smryInvoiceDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["STATION", "ACTWT", "CHG WT", "GUR-WT", "KANTA WT", "TOPAY", "PAID", "F O V", "ADVANCE", "BALANCE", "HIRE"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 5, 5, 10, 10, 10, 10, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandGurWt = 0;
            this.grandKantaWt = 0;
            this.grandTotHire = 0;
            this.grandAdvance = 0;
            this.grandBalance = 0;
            this.grandTopay = 0;
            this.grandPaid = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandRiskChg = 0;



            for (let i = 0; i < this.smryInvoiceDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.smryInvoiceDataList[i].toStation, this.smryInvoiceDataList[i].actWeight, this.smryInvoiceDataList[i].chargedWt, this.smryInvoiceDataList[i].gurWeight, this.smryInvoiceDataList[i].kantaWeight, this.smryInvoiceDataList[i].toPay, this.smryInvoiceDataList[i].paid, this.smryInvoiceDataList[i].riskChgAmt, this.smryInvoiceDataList[i].advance, this.smryInvoiceDataList[i].balance, this.smryInvoiceDataList[i].totalhire];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);




                this.grandGurWt = +this.grandGurWt + +this.smryInvoiceDataList[i].gurWeight;
                this.grandKantaWt = +this.grandKantaWt + +this.smryInvoiceDataList[i].kantaWeight;
                this.grandTotHire = +this.grandTotHire + +this.smryInvoiceDataList[i].totalhire;
                this.grandAdvance = +this.grandAdvance + +this.smryInvoiceDataList[i].advance;
                this.grandBalance = +this.grandBalance + +this.smryInvoiceDataList[i].balance;
                this.grandTopay = +this.grandTopay + +this.smryInvoiceDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.smryInvoiceDataList[i].paid;
                this.grandActWt = +this.grandActWt + +this.smryInvoiceDataList[i].actWeight;
                this.grandChWt = +this.grandChWt + +this.smryInvoiceDataList[i].chargedWt;
                this.grandRiskChg = +this.grandRiskChg + +(this.smryInvoiceDataList[i].riskChgAmt == null ? 0.0
                    : this.smryInvoiceDataList[i].riskChgAmt);

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.smryInvoiceDataList.length, this.grandActWt, this.grandChWt, this.grandGurWt, this.grandKantaWt, this.grandTopay, this.grandPaid, this.grandRiskChg, this.grandAdvance, this.grandBalance, this.grandTotHire];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

            //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.lrDtoForSearch.entryBy];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Hireslip Details Report - Summary Wise");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    clearAll() {
        this.clearFields();
        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
        $("#" + this.pageId + "searchBy").val('hireslipNumber');
        this.clearTable();
        this.viewTripScheduledPopUp = false;
        this.invoicedtoForSearchOtherDetails = new InvoiceDto();
        this.hireslipdtoGetDetailsForPopup = new HireSlipDto();
        this.hireslipdtoRecDetails = '';
        this.hireSlipDtoForInvoiceDetails = new HireSlipDto();


        //For Custom Print
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = [];
        this.cashMemoDtoForCustomPrintListColumnWidths = [];
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];

        this.viewCustomPrintV1 = false;
        this.fromDatePrint = '';
        this.toDatePrint = '';
        this.cashMemoDtoForCustomPrintListHeadingV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];

        this.grandtotallr = 0;
        this.grandArticles = 0;
        this.grandGurWt = 0;
        this.grandKantaWt = 0;
        this.grandTotHire = 0;
        this.grandAdvance = 0;
        this.grandBalance = 0;
        this.grandTopay = 0;
        this.grandPaid = 0;
        this.grandActWt = 0;
        this.grandChWt = 0;
        this.grandRiskChg = 0;
        this.grandGoodsValueBelow = 0;
        this.grandGoodsValueAbove = 0;
        this.showSpinnerForAction = false;
        this.smryInvoiceDataList = [];
        this.smryViaReportDataList = [];
        this.hireNdDataWiseDataList = [];
        this.hireslipNumberRet = [];
        this.searchByHireslipNo = true;
        this.viewDate = false;
        this.searchByDateWise = false;
        this.searchBySmryWise = false;
        this.showSrc = false;
        this.showDest = false;
        this.showSrcSum = false;
        this.showDestSum = false;
        this.showForNoAndDateWise = true;
        this.showForViaReportTable = false;
        this.showForMainAndDummyWise = false;

    }

    invoiceSummaryBtnForPrint() {
        this.summaryInvoiceForLrDtoPrint = new LRDto();
        this.summaryInvoiceForLrDtoPrint.invoiceNumber = this.hireslipdtoGetDetailsForPopup.hireslipnumber;
        this.summaryInvoiceForLrDtoPrint.fromStation = this.hireslipdtoGetDetailsForPopup.fromstation;
        this.summaryInvoiceForLrDtoPrint.toStation = this.hireslipdtoGetDetailsForPopup.tostation;
        this.summaryInvoiceForLrDtoPrint.companyId = this.userDataDtoReturnSession.companyId;
        this.stockService.getInvoiceDestinationSummary(this.summaryInvoiceForLrDtoPrint).subscribe(
            (response) => {
                if (response != null) {
                    this.destInvoiceSummaryReportPrintOut_HireslipPage(this.summaryInvoiceForLrDtoPrint, response);
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }
    destInvoiceSummaryReportPrintOut_HireslipPage(summaryInvoiceForLrDtoPrint, response) {
        localStorage.clear();
        localStorage.setItem('invoiceSummaryForLRDtoPrint',
            JSON.stringify(summaryInvoiceForLrDtoPrint));
        localStorage.setItem('invoiceSummaryForLRDtoListPrint',
            JSON.stringify(response));

        this.tripScheduledPopUp.close();
        this.viewInvoiceSummaryPrint_Hireslip = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewInvoiceSummaryPrint_Hireslip) {
                this.viewInvoiceSummaryPrint_Hireslip = false;
                summaryInvoiceForLrDtoPrint = new LRDto();
                response = [];
                localStorage.clear();
            }
        });
    }

    printBookingInvoice(hireSlipDtoForInvoicePrintBtn) {
        this.lrDtoPrintInvoiceBtn = new LRDto();
        this.lrDtoPrintInvoiceBtn.invoiceNumber = this.hireslipdtoGetDetailsForPopup.hireslipnumber;
        this.lrDtoPrintInvoiceBtn.companyId = this.userDataDtoReturnSession.companyId;

        this.stockService.getBookingInvoiceDetails(this.lrDtoPrintInvoiceBtn).subscribe(
            (response) => {
                if (this.hireSlipDtoForCreateHireslipLocalStorage.invoiceType == "Dummy") {
                    //alert("Dummy Print Method Pending");
                    this.bookingInvoiceNewPrint_InHireslip(this.hireslipdtoGetDetailsForPopup,
                        this.hireslipdtoGetDetailsForPopup.listLrsShortage, response);
                } else {
                    this.bookingInvoiceNewPrint_InHireslip(this.hireslipdtoGetDetailsForPopup,
                        this.hireslipdtoGetDetailsForPopup.listLrsShortage, response);
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }

    bookingInvoiceNewPrint_InHireslip(hireSlipDtoForBookingInvoicePrint, invoiceListOfLrShortage,
        invoiceForLrDto) {
        localStorage.clear();
        localStorage.setItem('invoiceDetailsForBookingHireslipDtoPrint',
            JSON.stringify(hireSlipDtoForBookingInvoicePrint));
        localStorage.setItem('invoiceDetailsForBookingListOfLrsShortPrint',
            JSON.stringify(invoiceListOfLrShortage));
        localStorage.setItem('invoiceDetailsForBookingLrDtoPrint', JSON.stringify(invoiceForLrDto));
        this.tripScheduledPopUp.close();
        this.viewInvoiceDetailsPrint_Hireslip = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewInvoiceDetailsPrint_Hireslip) {
                this.viewInvoiceDetailsPrint_Hireslip = false;
                localStorage.clear();
                //Asrar_Jr_Chg_V1
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    openHireEditPopUp(contentHireedit, hireNdDataWise) {
        console.log(hireNdDataWise);
        if (hireNdDataWise.hireamtbalsendtobank != null && hireNdDataWise.hireamtbalsendtobank == true) {
            swal({
                title: "Warning",
                text: "Hire balance amount already paid at destination - Please contact destination",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            })
        } else {
            if (hireNdDataWise.isAdminHireEdit != null && hireNdDataWise.isAdminHireEdit == true) {
                swal({
                    title: "Warning",
                    text: "This hire slip cannot be edited again since its details have already been modified",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {
                this.setHireslipNo = null, this.setHireslipAmt = null, this.setHireslipBalance = null, this.setHireslipAdvance = null;
                this.setHireslipNo = hireNdDataWise.hireslipnumber;
                this.setHireslipAmt = hireNdDataWise.totalhire;
                this.setHireslipAdvance = hireNdDataWise.advance;
                this.setHireslipBalance = hireNdDataWise.balance;

                $("#hrsldtotalHire").val(this.setHireslipAmt);
                $("#hrsldadvance").val(this.setHireslipAdvance);
                $("#hrsldbalance").val(this.setHireslipBalance);

                this.modalService.open(contentHireedit, { centered: true }).result.then(
                    result => {
                        this.closeResult = `Closed with: ${result}`;
                    },
                    reason => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                    }
                );
            }
        }


    }

    updateHireslipAmtBtn() {
        this.enteredHireslipAmt = $("#" + this.pageId + "totalHire").val();
        this.enteredHireslipAdvance = $("#" + this.pageId + "advance").val();
        this.enteredHireslipBalance = $("#" + this.pageId + "balance").val();

        if ((this.enteredHireslipAmt == null) || (this.enteredHireslipAmt == undefined) || (this.enteredHireslipAmt == '') ||
            (this.enteredHireslipAdvance == null) || (this.enteredHireslipAdvance == undefined) || (this.enteredHireslipAdvance == '') ||
            (this.enteredHireslipBalance == null) || (this.enteredHireslipBalance == undefined) || (this.enteredHireslipBalance == '')) {
            swal({
                title: "Mandatory Fields",
                text: "Please fill the required details to proceed!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            })
        } else {
            swal({
                title: "Update Hire Details",
                text: "Sure you want to Update the Lorry Hire details for the Hireslip No '" + this.setHireslipNo + "'",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.hireslipDtoHireEdit = new HireSlipDto();
                    this.hireslipDtoHireEdit.hireslipnumber = this.setHireslipNo;
                    this.hireslipDtoHireEdit.totalhire = this.enteredHireslipAmt
                    this.hireslipDtoHireEdit.advance = this.enteredHireslipAdvance
                    this.hireslipDtoHireEdit.balance = this.enteredHireslipBalance
                    this.hireslipDtoHireEdit.lastupdatedby = this.userDataDtoReturnSession.userName;
                    //Imran_HRD_Chg_V2
                    this.hireslipDtoHireEdit.isAdminHireEdit = true;
                    this.updateHireslipAmtMethod();
                }
            });
        }
    }
    updateHireslipAmtMethod() {
        this.showSpinnerForAction = true;
        this.reportService.updateHireslipHireDetails(this.hireslipDtoHireEdit).subscribe(
            (response) => {
                console.log(response);
                if (response.status == 'Success') {
                    this.clearFieldsHireUpdatePopup();
                    this.modalService.dismissAll();
                    this.getHireslipNumber();
                    swal("Success", "Hire details updated successfully!", "info");
                } else if (response.status == 'Notexist') {
                    swal("Failed", "Hireslip number does not exist, please check again!", "warning");
                } else if (response.status == 'response') {
                    swal("Failed", "Failed to update the Hire details!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while updating the Hire details", "error");
            }, () => console.log('done');
    }

    totalHireAmtChgBlur() {
        this.calculateBalanceAmt();
        $("#" + this.pageId + "advance").focus();
    }

    onKeyPressFieldForTotalHire(event) {
        if (event == 13) {
            this.calculateBalanceAmt();
            $("#" + this.pageId + "advance").focus();
        }
    }

    calculateBalanceAmt() {
        var calculateBalanceAmt = 0;
        this.enteredHireslipAmt = $("#" + this.pageId + "totalHire").val();
        this.enteredHireslipAdvance = $("#" + this.pageId + "advance").val();
        calculateBalanceAmt = +this.enteredHireslipAmt + -this.enteredHireslipAdvance;
        if (calculateBalanceAmt != null &&
            calculateBalanceAmt != 0 &&
            calculateBalanceAmt > 0) {
            $("#" + this.pageId + "balance").val(calculateBalanceAmt);
        } else {
            $("#" + this.pageId + "balance").val(0);
        }
    }

    clearFieldsHireUpdatePopup() {
        this.setHireslipNo = null;
        this.enteredHireslipAmt = null;
        this.enteredHireslipAdvance = null;
        this.enteredHireslipBalance = null;
        $("#" + this.pageId + "totalHire").val('');
        $("#" + this.pageId + "advance").val('');
        $("#" + this.pageId + "balance").val('');
    }

    //Asrar_Jr_Chg_V3
    shortAndExtraDetails(hireNdDataWise) {
        this.selectedInvoiceNumber = hireNdDataWise.hireslipnumber;
        this.shortAndExtraExtraDialog();
    }
    
    shortAndExtraExtraDialog(): void {
        const dialogRef = this.dialog.open(ShortExtraDetailsPopupComponent, {
            data: { listOfDataToPass: this.selectedInvoiceNumber }
        });
    }
}
