<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}truckMasterId">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Truck Master</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card-body">
                        <div class="row">
                            <!-- spinner start-->
                            <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <!-- spinner end-->
                            <div class="col-md-3">
                                <!-- <h6 class="card-title">Tempo Details</h6> -->
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Truck Number*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-truck"></i>
													</span>
                                                </div>
                                                <input #truckNumber (keyup)="fieldFocus($event, ownerName)" id="{{pageId}}truckNumberId" name="truckNumberId" type="text" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.truckNumber" autocomplete="off" [readonly]="readonlytruckNumber"
                                                    (blur)="truckNumberValidationOfBlur($event)" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Owner Name*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
													</span>
                                                </div>
                                                <input #ownerName (keyup)="fieldFocus($event, address)" type="text" class="form-control" id="{{pageId}}ownerName" name="ownerName" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.ownerName" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Address*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
															class="fas fa-map-marker-alt"></i>
													</span>
                                                </div>
                                                <textarea #address (keyup)="fieldFocus($event, city)" class="form-control " rows="1 " [(ngModel)]="truckDataDto.address" id="{{pageId}}truckAddressId" autocomplete="off"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>City*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
															class="fas fa-map-marker-alt"></i>
													</span>
                                                </div>
                                                <input #city (keyup)="fieldFocus($event, stateName)" type="text" class="form-control" id="{{pageId}}city" name="city" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.city" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 											the first autocomplete starts -->
                                    <div class="col-sm-12 col-md-12">

                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Reg.State*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-map-marker-alt"></i>
														</span>
                                                    </div>
                                                    <input #stateName id="{{pageId}}regStateName" type="text" class="form-control" (selectItem)="clickListnerForRegState($event)" [(ngModel)]="modelRegState" [ngbTypeahead]="searchRegState" [resultFormatter]="formatterRegState" [inputFormatter]="formatterRegState"
                                                        (focus)="focusRegStateTA$.next($any($event).target.value)" autocomplete="off" (keyup)="fieldFocus($event, pinCode)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 											the first autocomplete ends -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Pincode*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
													</span>
                                                </div>
                                                <input #pinCode (keyup)="fieldFocus($event, panNumber)" type="number" class="form-control" id="{{pageId}}pinCode" name="pinCode" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.pinCodeNumber" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>PAN(Enter PAN as AAAAA9999A, if you don't
													have PAN)*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-id-card"></i>
													</span>
                                                </div>
                                                <input #panNumber (keyup)="fieldFocus($event, truckCapacityInTones)" type="text" class="form-control" id="{{pageId}}truckOwnerPanNumber" name="truckOwnerPanNumber" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.panNo" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-9">
                                        <div class="form-group">
                                            <label> RC </label> <input type="file" id="{{pageId}}rcFileUploadId" (change)="onFileChangedRC($event)" #fileInputRC />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <button style='float: right; padding: 2px 4px; margin-top: 30px;' type="button" class="btn btn-success m-r-10" (click)="uploadRCFileBtn();">
											<i title="Upload RC File" class="fas fa-upload">
												<!--RC Upload-->
											</i>
										</button>
                                    </div>
                                    <div class="col-sm-12 col-md-9">
                                        <div class="form-group">
                                            <label>Insurance </label> <input type="file" id="{{pageId}}insuranceFileUploadId" (change)="onFileChangedInsurance($event)" #fileInputInsurance />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <button style='float: right; padding: 2px 4px; margin-top: 30px;' type="button" class="btn btn-success m-r-10" (click)="uploadInsuranceFileBtn();">
											<i title="Upload Insurance File" class="fas fa-upload">
												<!--Insurance Upload-->
											</i>
										</button>
                                    </div>
                                    <div class="col-sm-12 col-md-9">
                                        <div class="form-group">
                                            <label> Pan </label> <input type="file" id="{{pageId}}panFileUploadId" (change)="onFileChangedPan($event)" #fileInputPan />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3" style='padding-bottom: 5px;'>
                                        <button style='float: right; padding: 2px 4px; margin-top: 30px;' type="button" class="btn btn-success m-r-10" (click)="uploadPanFileBtn();">
											<i title="Upload Pan File" class="fas fa-upload">
												<!--Pan Upload-->
											</i>
										</button>
                                    </div>
                                    <!--<div class="col-sm-12 col-md-12">
										<button type="button" class="btn btn-success m-r-10" id="{{pageId}}uploadBtn"
											(click)="uploadBtn()">Upload</button>
									</div>-->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Truck Capacity (In Tones)*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-truck"></i>
													</span>
                                                </div>
                                                <input #truckCapacityInTones (keyup)="fieldFocus($event, truckType)" type="number" class="form-control" id="{{pageId}}truckCapacityInTones" name="truckCapacityInTones" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.truckCapcityDouble" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Truck Type*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-truck"></i>
													</span>
                                                </div>

                                                <input #truckType type="text" class="form-control" (selectItem)="clickListnerForTruckTypeCapacity($event)" [(ngModel)]="modelTruckTypeCapacity" [ngbTypeahead]="searchTruckTypeCapacity" [resultFormatter]="formatterTruckTypeCapacity" [inputFormatter]="formatterTruckTypeCapacity"
                                                    (focus)="focusTruckTypeCapacityTA$.next($any($event).target.value)" id="{{pageId}}truckTypeCapacityId" name="truckTypeCapacityId" (keyup)="fieldFocus($event, insurancePolicyNumber)" autocomplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="newTruck" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Add New T.Type*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
													</span>
                                                </div>
                                                <input #addNewTruckType (keyup)="fieldFocus($event, insurancePolicyNumber)" type="text" class="form-control" id="{{pageId}}addNewTruckType" name="addNewTruckType" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.truckTypeNew" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Insurance Policy No. Code*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
													</span>
                                                </div>
                                                <input #insurancePolicyNumber (keyup)="fieldFocus($event, truckLength)" type="text" class="form-control" id="{{pageId}}insurancePolicyNumber" name="insurancePolicyNumber" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.insPolNo" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Insurance Expire At*</label>
                                                <input
                                                    id="{{pageId}}insuranceExpDate"
                                                    class="form-control"
                                                    placeholder="yyyy-mm-dd"
                                                    name="dates"
                                                    ngbDatepicker
                                                    #dates="ngbDatepicker"
                                                    (blur)="validateDate()"
                                                    (dateSelect)="validateDate()"
                                                    [minDate]="minDate"
                                                />
                                                <div class="input-group-append" (click)="dates.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Truck Length (feets)*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-truck"></i>
													</span>
                                                </div>
                                                <input #truckLength (keyup)="fieldFocus($event, saveButton)" type="number" class="form-control" id="{{pageId}}truckLengthFeet" name="truckLengthFeet" aria-describedby="basic-addon11" [(ngModel)]="truckDataDto.trkLgth" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-5">
                                        <button #saveButton type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn" (click)="validateTruckDetails()">Save/Update</button>
                                    </div>
                                    <div class="col-sm-12 col-md-3" #saveBtn>
                                        <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                                    </div>
                                    <div class="col-sm-12 col-md-4" #saveBtn>
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}deleteBtn" (click)="validateDeleteRow()">Delete</button>
                                    </div>
                                </div>
                                <hr style="width: 80%; border-top: none; margin: 3px;">
                            </div>


                            <div class="col-md-9 vl">

                                <div class="row" *ngIf="showBankdetailsentry">
                                    <div class="col-md-12">
                                        <h6 class="card-title">Bank Details</h6>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input #actionType id="{{pageId}}AcType" name="AcType" type="text" class="form-control" placeholder="Select Ac Type" (selectItem)="acTypeFocus($event)" [ngbTypeahead]="acTypeSearchTA" (focus)="focusAcTypeTA$.next($any($event).target.value)" (click)="clickTA(instanceAcType)"
                                                            #instanceAcType="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}acNo" placeholder="AC Number" (keypress)="onKeyPressListnerAcNo($event);">&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" id="{{pageId}}BankName" aria-describedby="basic-addon11" placeholder="Bank Name" (keypress)="onKeyPressListnerBankName($event);">&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}AcHolder" placeholder="AC Holder" (keypress)="onKeyPressListnerAcHolder($event);">&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}IfscCode" placeholder="IFSC Code" (keypress)="onKeyPressListnerIfscCode($event);">&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-2">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}BranchName" placeholder="Branch Name" (keypress)="onKeyPressListnerBranchName($event);">&nbsp;
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="box-body">
                                                                    <table datatable id="{{pageId}}bankDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsBankdetails" [dtTrigger]="dtTriggerBankdetails">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>AC Type</th>
                                                                                <th>AC Number</th>
                                                                                <th>Bank Name</th>
                                                                                <th>AC Holder</th>
                                                                                <th>IFSC Code</th>
                                                                                <th>Branch Name</th>
                                                                                <th><i class="fas fa-trash"></i>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr style=" cursor: pointer;" *ngFor="let bankDetailsData of bankDetailsDataList let i = index" (click)="rowSelectedBankdetailsDataOnEdit(bankDetailsData,i);">
                                                                                <td>{{ bankDetailsData.acType }}
                                                                                </td>
                                                                                <td>{{ bankDetailsData.acNo }}
                                                                                </td>
                                                                                <td>{{ bankDetailsData.bankName }}
                                                                                </td>
                                                                                <td>{{ bankDetailsData.acHolder }}
                                                                                </td>
                                                                                <td>{{ bankDetailsData.ifscCode }}
                                                                                </td>
                                                                                <td>{{ bankDetailsData.branchName }}
                                                                                </td>
                                                                                <td><i style=" cursor: pointer;" placement="left" ngbTooltip="Remove" class="fas fa-trash" (click)="deleteSelectedBankdetails(i);"></i>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="datatableHideShow">
                                    <!-- column -->
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row p-t-10">
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <input #truckNumberSearch id="{{pageId}}truckNumberSearchId" name="truckNumberSearchId" type="text" class="form-control" aria-describedby="basic-addon11" autocomplete="off" placeholder="Enter Truck No" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <button type="submit" class="btn btn-primary m-r-10" style="padding: 1px 4px;" id="{{pageId}}getDetailsBtn" (click)="getTruckMasterDetailsSingle()">
																	<i class="fas fa-file-alt"></i> Get Single
																</button>
                                                                <button type="submit" class="btn btn-primary m-r-10" style="padding: 1px 4px;" id="{{pageId}}getDetailsBtn" (click)="getTruckMasterDetailsList()">
																	<i class="fas fa-file-alt"></i> Get All
																</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="box-body">
                                                            <table datatable id="{{pageId}}truckMasterDatatableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTruckMaster" [dtTrigger]="dtTriggerTruckMaster">

                                                                <thead>
                                                                    <tr>
                                                                        <!-- <th>Action</th> -->
                                                                        <th>Truck No</th>
                                                                        <th>Owner Name</th>
                                                                        <th>Address</th>
                                                                        <th>City</th>
                                                                        <th>Pincode</th>
                                                                        <th>Pan No</th>
                                                                        <th>Truck Capacity</th>
                                                                        <th>Truck Type</th>
                                                                        <th>RC Copy</th>
                                                                        <th>Insurance Copy</th>
                                                                        <th>Pan Copy</th>
                                                                        <th>Ins Policy No</th>
                                                                        <th>Ins Exp Date</th>
                                                                        <th>Truck Length(Feets)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let truckMasterData of truckMasterDataList" (click)="rowSelected(truckMasterData);" style='cursor: pointer;'>
                                                                        <!-- <td>
																			<button style='padding: 2px 4px;'
																				type="button"
																				class="btn btn-success m-r-10"
																				(click)="rowSelected(truckMasterData);">
																				<i title="Edit Truck Master Details"
																					class="fas fa-pencil-alt"> </i>
																			</button>
																		</td> -->
                                                                        <td>{{ truckMasterData.truckNumber }}</td>
                                                                        <td>{{ truckMasterData.ownerName }}</td>
                                                                        <td>{{ truckMasterData.address }}</td>
                                                                        <td>{{ truckMasterData.city }}</td>
                                                                        <td>{{ truckMasterData.pinCodeNumber }}</td>
                                                                        <td>{{ truckMasterData.tinNo }}</td>
                                                                        <td>{{ truckMasterData.truckCapacity }}</td>
                                                                        <td>{{ truckMasterData.truckType }}</td>
                                                                        <td>
                                                                            <a style='cursor: pointer; color: blue;' (click)="getRCImg(truckMasterData);" class="pointer">
                                                                                <u>{{ truckMasterData.imgURLRc == null ?
																					"RC Not
																					Uploaded" : "View Licence Document"
																					}}</u>
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <a style='cursor: pointer; color: blue;' (click)="getInsuranceImg(truckMasterData);" class="pointer"> <u>{{
																					truckMasterData.imgURLIns == null ?
																					"Insurance Not
																					Uploaded" :
																					truckMasterData.imgURLIns == "NA" ?
																					"Insurance Not Uploaded" : "View
																					Insurance
																					Document" }}</u></a>
                                                                        </td>
                                                                        <td>
                                                                            <a style='cursor: pointer; color: blue;' (click)="getPanImg(truckMasterData);" class="pointer">
                                                                                <u>{{ truckMasterData.imgURLPan == null
																					? "Pan
																					Not Uploaded" :
																					truckMasterData.imgURLPan == "NA" ?
																					"Pan Not Uploaded" : "View Pan
																					Document" }}</u>
                                                                            </a>
                                                                        </td>
                                                                        <td>{{ truckMasterData.insPolNo }}</td>
                                                                        <td>{{ truckMasterData.insuranceExpDateStr }}</td>
                                                                        <td>{{ truckMasterData.trkLgth }}</td>
                                                                    </tr>


                                                                    <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>