<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label class="bold-label">Client Name &nbsp;*</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input #clientName id="{{pageId}}clientName" type="text" (keyup)="fieldFocus($event, location)"
                                                class="form-control" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label class="bold-label">Location &nbsp;*</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="ti-home"></i>
                                                </span>
                                            </div>
                                            <input #location id="{{pageId}}location" type="text" class="form-control"
                                                [(ngModel)]="modelsrcDest" [ngModelOptions]="{standalone: true}"
                                                [ngbTypeahead]="searchSrcDest" [resultFormatter]="formatterSrcDest"
                                                [inputFormatter]="formatterSrcDest" placeholder="Select Location"
                                                (focus)="focusSrcDestTA$.next($any($event).target.value)"
                                                #instanceLocation="ngbTypeahead" (keyup)="fieldFocus($event, contactNo)"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label class="bold-label">Contact No &nbsp;*</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input #contactNo id="{{pageId}}contactNo" type="text" class="form-control"
                                                aria-describedby="basic-addon11" (keyup)="fieldFocus($event, agentName)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 vl p-t-10">
                    <hr>
                    <div class="card">
                        <div class="card-body">
                            <div class="row mb-0">
                                <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <mat-progress-bar mode="indeterminate" style="color: green;">
                                            </mat-progress-bar>
                                            <br>
                                            <h6 class="card-title" align='center'
                                                style="color: green; margin: auto; font-size: 18px;">
                                                Please Wait Loading Details.....</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label class="bold-label">Source &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-home"></i>
                                                            </span>
                                                        </div>
                                                        <input #agentName id="{{pageId}}agentName" type="text"
                                                            class="form-control" [(ngModel)]="agentNameModal"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [ngbTypeahead]="agentNameSearchTA"
                                                            [resultFormatter]="formatterAgentName"
                                                            [inputFormatter]="formatterAgentName"
                                                            placeholder="Select Source"
                                                            (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                            #instanceAgentName="ngbTypeahead" (keyup)="fieldFocus($event, destination)"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label class="bold-label">Destination &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>

                                                        <input id="{{pageId}}destination" type="text" #destination
                                                            class="form-control" [(ngModel)]="modelDestination"
                                                            [ngbTypeahead]="searchDestination"
                                                            [resultFormatter]="formatterDestination"
                                                            [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Destination" (keyup)="fieldFocus($event, Commodity)"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label class="bold-label">Rate Type &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-warehouse"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}rateTypeId"
                                                        name="rateType" #rateType (change)="rateTypes(rateType.value)">
                                                        <option value="commodity">Commodity</option>
                                                        <!-- <option value="party">Party</option> -->
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label class="bold-label">Date &nbsp;*</label>
                                                    <input id="{{pageId}}bkgDate" class="form-control"
                                                        placeholder="yyyy-mm-dd" ngbDatepicker #date="ngbDatepicker"
                                                        autocomplete="off" [(ngModel)]="tdyBookingDate"
                                                        #bookingDates="ngbDatepicker" (click)="bookingDates.toggle()">
                                                    <div class="input-group-append" (click)="bkgToDate.toggle()">
                                                        <span class="input-group-text"><i
                                                                class="fa fa-calendar"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label class="bold-label">Commodity &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
                                                        </span>
                                                    </div>
                                                    <input #Commodity id="{{pageId}}commodity" type="text"
                                                        class="form-control" [(ngModel)]="modelCommodityList"
                                                        [ngbTypeahead]="searchCommodityList"
                                                        [resultFormatter]="formatterCommodityList"
                                                        [inputFormatter]="formatterCommodityList"
                                                        (keyup)="partyCommodityFocus($event)"
                                                        (focus)="focusCommodityListTA$.next($any($event).target.value)"
                                                        autocomplete="off" placeholder="Select Commodity.."
                                                        (change)="clickListnerForRateCommodity($event);" />
                                                </div>
                                            </div>
                                        </div>
                                        &nbsp;

                                        <h6 *ngIf="viewPartyFields" class="col-sm-12 col-md-12" class="card-title"
                                            style="padding-left: 15px;">
                                            Consignor Details</h6>
                                        <div *ngIf="viewPartyFields" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #consignorGSTNumber placeholder="Consingor Gst No"
                                                        (keyup)="getConsignorDetailsOnEnter($event)" type="text"
                                                        class="form-control" id="{{pageId}}consignorGSTNumber">
                                                </div>
                                            </div>


                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                        <!-- <input #consignorName id="{{pageId}}consignorName"
                                                            type="text" class="form-control"
                                                            (selectItem)="rowSelectedConsignor($event,content9,contentEinvoice)"
                                                            [(ngModel)]="modelConsignorName"
                                                            [ngbTypeahead]="consignorSearchTA"
                                                            (focus)="focusConsignorTA$.next($any($event).target.value)"
                                                            [resultFormatter]="formatterConsignorName"
                                                            [inputFormatter]="formatterConsignorName"
                                                            (click)="clickTA(instanceConsignorName)"
                                                            #instanceConsignorName="ngbTypeahead"> -->
                                                        <input type="text" class="form-control"
                                                            id="{{pageId}}consignorName"
                                                            aria-describedby="basic-addon11" autocomplete="off"
                                                            readonly>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #consignorMobNo type="text" id="{{pageId}}consignorMobNo"
                                                        name="consignorMobNo" class="form-control"
                                                        aria-describedby="basic-addon11"
                                                        placeholder="Consignor Mobile Number" readonly>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <textarea class="form-control" id="{{pageId}}consignorAddress"
                                                        placeholder="Enter Address" rows="1" readonly></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        &nbsp;
                                        <h6 *ngIf="viewPartyFields" class="col-sm-12 col-md-12" class="card-title"
                                            style="padding-left: 15px;">
                                            Consignee Details</h6>
                                        <div *ngIf="viewPartyFields" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #consigneeGSTNumber placeholder="Consingee Gst No"
                                                        (keyup)="getConsigneeDetailsOnEnter($event)" type="text"
                                                        class="form-control" id="{{pageId}}consigneeGSTNumber">
                                                </div>
                                            </div>

                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                        <input type="text" class="form-control"
                                                            id="{{pageId}}consigneeName"
                                                            aria-describedby="basic-addon11" autocomplete="off"
                                                            readonly>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #consigneeMobNo type="text" id="{{pageId}}consigneeMobNo"
                                                        name="consigneeMobNo" class="form-control"
                                                        aria-describedby="basic-addon11"
                                                        placeholder="Consignor Mobile Number" readonly>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <textarea class="form-control" id="{{pageId}}consigneeAddress"
                                                        placeholder="Enter Address" rows="1" readonly></textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 vl">
                    <hr>
                    <div class="col-md-12" class="row">
                        <div class="col-lg-6 col-md-6 vl">
                            &nbsp;
                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">No.Of Articles</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-clone"></i>
                                            </span>
                                        </div>
                                        <input #noOfArticles id="{{pageId}}noOfArticles" type="number"
                                            (keyup)="fieldFocusArticles($event)" class="form-control"
                                            aria-describedby="basic-addon11">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">Actual Weight</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-weight"></i>
                                            </span>
                                        </div>
                                        <input #actualWeight id="{{pageId}}actualWeight"
                                            (keyup)="actualWeightEvent($event)" (onblur)="actualWeightBlur()"
                                            type="number" class="form-control" aria-describedby="basic-addon11">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">Charged Weight</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-weight"></i>
                                            </span>
                                        </div>
                                        <input #chargedWeight id="{{pageId}}chargedWeight"
                                            (keyup)="chargedWeightEvent($event)" (blur)="chargedWeightBlur(content7)"
                                            type="number" class="form-control" aria-describedby="basic-addon11"
                                            [readonly]="chgWgtReadOnly">

                                        <!-- <input *ngIf="lrArtScanAllow" #chargedWeight id="{{pageId}}chargedWeight"
                                            (keyup)="chargedWeightEvent($event, content7WithScan)"
                                            (blur)="chargedWeightBlur(content7WithScan)" type="number"
                                            class="form-control" aria-describedby="basic-addon11"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <div class="form-group row">
                                    <label class="col-sm-5" class="bold-label">Consignment Size</label>
                                    <div class="col-sm-7">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input"
                                                id="{{pageId}}consignmentSize" #consignmentSize
                                                (change)="allowMultiArtPopUp(content7, $event)">
                                            <label class="custom-control-label" for="{{pageId}}consignmentSize"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">Goods Value</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                            </span>
                                        </div>
                                        <input #goodsValue id="{{pageId}}goodsValue" (keyup)="goodsValueEvent($event)"
                                            (blur)="goodsValueBlur($event)" type="number" class="form-control"
                                            aria-describedby="basic-addon11">
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group" id="{{pageId}}popupDetails">
                                        <ng-template #content7 let-c="close" let-d="dismiss">
                                            <div class="modal-header">
                                                <h6 class="modal-title" id="{{pageId}}modal-basic-title">Multiple
                                                    Article Details</h6>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-4">
                                                        <div class="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" [checked]="viewCftFields"
                                                                    class="custom-control-input"
                                                                    id="{{pageId}}enableAutoCft"
                                                                    (change)="toggleEditableForAutoCft($event)">
                                                                <label class=" custom-control-label wtfull "
                                                                    for="{{pageId}}enableAutoCft">Enable
                                                                    Auto CFT</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-4">
                                                        <div class="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" [checked]="viewCustomCftFields"
                                                                    class="custom-control-input"
                                                                    id="{{pageId}}enableCustomCft"
                                                                    (change)="toggleEditableForCustomCft($event)">
                                                                <label class=" custom-control-label wtfull "
                                                                    for="{{pageId}}enableCustomCft">Custom
                                                                    CFT Value</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="viewCustomCftFields" class="col-sm-12 col-md-4">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>CFT Value</label>
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">
                                                                        <i class="fas fa-weight"></i>
                                                                    </span>
                                                                </div>
                                                                <input #customCftValue id="{{pageId}}customCftValue"
                                                                    type="number" class="form-control"
                                                                    aria-describedby="basic-addon11">
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-1">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}artLbl">
                                                                        <label class="wtfull">Art</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="viewCftFields" class="col-sm-12 col-md-2">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}lengthLbl">
                                                                        <label class="wtfull">Length</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="viewCftFields" class="col-sm-12 col-md-2">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}breadthLbl">
                                                                        <label class="wtfull">Breadth</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="viewCftFields" class="col-sm-12 col-md-2">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}heightLbl">
                                                                        <label class="wtfull">Height</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-2">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}actWgtLbl">
                                                                        <label class="wtfull">Act.Wgt</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-2">
                                                                <div class="form-group">
                                                                    <div class="input-group" id="{{pageId}}chgWgtLbl">
                                                                        <label class="wtfull">Chg.Wgt</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-1">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <input type="number"
                                                                            id="{{pageId}}multiArtarticle"
                                                                            name="multiArtarticle" class="form-control"
                                                                            #multiArtarticle
                                                                            (keyup)="fieldFocusMultiArt($event)"
                                                                            aria-describedby="basic-addon11">&nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="viewCftFields" class="col-sm-12 col-md-2">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <input type="number"
                                                                            id="{{pageId}}multiArtlength"
                                                                            name="multiArtlength" class="form-control"
                                                                            #multiArtlength
                                                                            (keyup)="fieldFocusMultiLgt($event)"
                                                                            aria-describedby="basic-addon11">&nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="viewCftFields" class="col-sm-12 col-md-2">
                                                                <div class="form-group">
                                                                    <div class="input-group">

                                                                        <input type="number"
                                                                            id="{{pageId}}multiArtbreadth"
                                                                            name="multiArtbreadth" class="form-control"
                                                                            aria-describedby="basic-addon11"
                                                                            #multiArtbreadth
                                                                            (keyup)="fieldFocusMultiBdt($event)">&nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="viewCftFields" class="col-sm-12 col-md-2">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <input type="number"
                                                                            id="{{pageId}}multiArtheight"
                                                                            name="multiArtheight" class="form-control"
                                                                            aria-describedby="basic-addon11"
                                                                            #multiArtheight
                                                                            (keyup)="fieldFocusMultiHgt($event)">&nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-2">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <input type="number"
                                                                            id="{{pageId}}multiArtActWgt"
                                                                            name="multiArtActWgt" class="form-control"
                                                                            aria-describedby="basic-addon11"
                                                                            #multiArtActWgt
                                                                            (keyup)="fieldFocusMultiActWgt($event)">&nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-2">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <input type="number"
                                                                            id="{{pageId}}multiArtChgWgt"
                                                                            name="multiArtChgWgt" class="form-control"
                                                                            aria-describedby="basic-addon11"
                                                                            #multiArtChgWgt
                                                                            (keyup)="fieldFocus($event, addBtn)">&nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-5" style='display: none'>
                                                                <div class="form-group">
                                                                    <div class="input-group">

                                                                        <input type="number" id="{{pageId}}hiddenIndex"
                                                                            class="form-control"
                                                                            aria-describedby="basic-addon11">&nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-1">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">

                                                                            <a href="javascript:;" type='button'
                                                                                id="{{pageId}}addInTable()" #addBtn
                                                                                class="btn btn-icon-only yellow"
                                                                                (click)="addInTable();">
                                                                                <i class="fa fa-plus"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="box-body">
                                                                    <!-- 							the first datatble starts -->
                                                                    <!-- timeTable-->
                                                                    <table datatable id="{{pageId}}multipleArticleTable"
                                                                        class="table table-striped table-bordered row-border hover"
                                                                        [dtOptions]="dtOptionsMultiArtTable"
                                                                        [dtTrigger]="dtTriggerMultiArtTable">

                                                                        <thead>
                                                                            <tr>
                                                                                <th>Article</th>
                                                                                <th>Length</th>
                                                                                <th>Breadth</th>
                                                                                <th>Height</th>
                                                                                <th>Act Wgt</th>
                                                                                <th>Chg Wgt</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr
                                                                                *ngFor="let multipleArtTableData of multiArtTableDataList let i = index">
                                                                                <td>{{
                                                                                    multipleArtTableData.articlesInMultiple
                                                                                    }}
                                                                                </td>
                                                                                <td>{{
                                                                                    multipleArtTableData.length
                                                                                    }}
                                                                                </td>
                                                                                <td>{{
                                                                                    multipleArtTableData.breadth
                                                                                    }}
                                                                                </td>
                                                                                <td>{{
                                                                                    multipleArtTableData.height
                                                                                    }}
                                                                                </td>
                                                                                <td>{{
                                                                                    multipleArtTableData.actualWeight
                                                                                    }}
                                                                                </td>
                                                                                <td>{{
                                                                                    multipleArtTableData.chargedWeight
                                                                                    }}
                                                                                </td>
                                                                                <td>
                                                                                    <!-- <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEdit(multipleArtTableData,i);">
                                                                                        <i title="Edit"
                                                                                            class="fas fa-pencil-alt"></i>
                                                                                    </button>&nbsp; -->
                                                                                    <button
                                                                                        style="padding: 1px 4px; background-color: #ffffff00;"
                                                                                        class="btn m-r-10"
                                                                                        id="{{pageId}}tableRemoveBtn"
                                                                                        (click)="rowSelectedDelete(multipleArtTableData,i)">
                                                                                        <i title="Remove"
                                                                                            class="fas fa-trash"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <!-- 																						<td colspan="9">No Data To Display</td> -->
                                                                        </tbody>
                                                                        <tfoot>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tfoot>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <!-- 																			<div class="col-md-12"> -->
                                                            <!-- 																				<button type="submit" class="btn btn-success m-r-10" -->
                                                            <!-- 																					id="{{pageId}}saveMultiArtBtn" -->
                                                            <!-- 																					(click)="onCancelClick();d('Cross click')">Save</button> -->
                                                            <!-- 																				<button type="submit" class="btn btn-success m-r-10" -->
                                                            <!-- 																					(click)="onCancelClick()" id="{{pageId}}closeMultiArtBtn">close</button> -->
                                                            <!-- 																			</div> -->
                                                            <div class="col-md-12">
                                                                <button type="submit" class="btn btn-success m-r-10"
                                                                    id="{{pageId}}saveMultiArtBtn"
                                                                    (click)="getMultiArtActAndChgWgt();">Save</button>
                                                                <button type="submit" class="btn btn-success m-r-10"
                                                                    (click)="multiArtPopupClose($event);"
                                                                    id="{{pageId}}closeMultiArtBtn">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group" id="{{pageId}}popupDetails3">
                                        <ng-template #shareDetails let-c="close" let-d="dismiss">
                                            <div class="modal-header">
                                                <h6 class="modal-title" id="{{pageId}}modal-basic-title "
                                                    style="text-align: center; padding-left: 110px;">Estimate
                                                    Enquiry Details
                                                </h6>
                                                <!-- <button type="button" class="close" aria-label="Close"
                                                    (click)="d('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button> -->
                                            </div>
                                            <div class="modal-body">
                                                <div class="col-sm-12 col-md-12">
                                                    <label style="text-align: center; font-size: 14px"><b>SRD
                                                            Logistics - Estimate</b>
                                                    </label>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <!-- <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label
                                                                        style="text-align: center; font-size: 16px"><u><b>SRD
                                                                                Logistics - Estimate</b>
                                                                        </u></label>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">Client Name : </label>
                                                                    <label>{{clientNameLabel}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">Location : </label>
                                                                    <label>{{locationLabel}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">Contact No : </label>
                                                                    <label>{{contactNoLabel}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">Estimate Date : </label>
                                                                    <label>{{enteredBookingDate}}</label>
                                                                </div>
                                                            </div>&nbsp;

                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">Source : </label>
                                                                    <label>{{selectedAgentName}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">Destination : </label>
                                                                    <label>{{selectedDestination}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">Commodity : </label>
                                                                    <label>{{selectedPartyCommodity}}</label>
                                                                </div>
                                                            </div>&nbsp;

                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">Actual Wt. Kgs : </label>
                                                                    <label>{{actualWeightLable}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">Charged Wt. Kgs: </label>
                                                                    <label>{{chargeWeightLable}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">Articles : </label>
                                                                    <label>{{totArticlesLable}}</label>
                                                                </div>
                                                            </div>

                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">LR Frt. Total : </label>
                                                                    <label id="{{pageId}}qoutedTotalAmt"
                                                                        contenteditable="true"
                                                                        (input)="updateQuotedTotalAmt($event.target.textContent)">
                                                                        {{ totalAmtLabel }}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <label class="bold-label">NOTE - </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="bold-label" style="white-space: nowrap;">These
                                                                are LR charges.
                                                                Destination Labour and Door Delivery</label>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="bold-label" style="white-space: nowrap;">(if
                                                                opted) charges
                                                                additional.</label>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="bold-label"
                                                                style="white-space: nowrap;">Estimate valid till 15
                                                                days from the estimate date.</label>

                                                        </div>
                                                        <div class="col-sm-12 col-md-12" style="text-align: center;">
                                                            <button type="submit" class="btn btn-success m-r-10"
                                                                id="{{pageId}}shareEstBtn" #shareEstBtn
                                                                (click)="validateShareWhatsappEstimateDetails()">Share Via
                                                                Whatsapp</button>
                                                            <button type="submit" class="btn btn-dark"
                                                                id="{{pageId}}clearPopupBtn"
                                                                (click)="d('Cross click')">Close</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>

                            &nbsp;
                            <div class="col-sm-12 col-md-12">
                                <div class="col-md-12" style="text-align: center;">
                                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
                                        #searchBtn (click)="getPartyRateDetails()">Calculate</button>
                                    <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn"
                                        (click)="clearDetails()">Clear</button>
                                </div>
                            </div>
                            <div [hidden]="!partyFrieghtDetails">
                                <hr>
                                <h6 class="card-title">Freight Details</h6>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">B.Freight: </label>
                                        <label>{{partyBaseFreight}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">Hamali: </label>
                                        <label>{{partyHamali}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">GC.Chg: </label>
                                        <label>{{partyGcCharge}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">A.O.C: </label>
                                        <label>{{partyAocCharge}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">F.O.V: </label>
                                        <label>{{enteredRiskChg}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">Others: </label>
                                        <label>{{partyOtherCharge}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <label class="bold-label">Total Amount: </label>
                                        <label>{{totalAmount}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12" style="text-align: center;">
                                    <i class="bi bi-whatsapp" (click)="shareWhatsappDetails(shareDetails)"
                                        id="{{pageId}}shareBtn"
                                        style="cursor: pointer; font-size: 30px; color: green;"></i>
                                </div>
                            </div>
                        </div>

                        <div [hidden]="!consignorRateFlowSet" class="col-lg-6 col-md-6 vl">
                            <h6 class="card-title">Consignor / Commodity Rate Details</h6>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Source: </label>
                                    <label>{{consignorRateSrc}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Destination: </label>
                                    <label>{{consignorRateDest}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Rate For: </label>
                                    <label>{{consignorRateFor}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Commodity: </label>
                                    <label>{{consignorRateCommodity}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Consignor: </label>
                                    <label>{{consignorRateConsignor}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Consignee: </label>
                                    <label>{{consignorRateConsignee}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Rate Type: </label>
                                    <label>{{consignorRateType}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Type: </label>
                                    <label>{{consignorRatePerUnit}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Rate On AW: </label>
                                    <label>{{consignorRatePerUnitRateOnActWgt}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">B. Rate: </label>
                                    <label>{{consignorRateBasicRate}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Hamali: </label>
                                    <label>{{consignorRateHamali}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">GC Chg: </label>
                                    <label>{{consignorRateGcChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">AOC: </label>
                                    <label>{{consignorRateAoc}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">Others: </label>
                                    <label>{{consignorRateOthers}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">DD Chg: </label>
                                    <label>{{consignorRateDDChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label class="bold-label">FOV (%): </label>
                                    <label>{{consignorRateFov}}</label>
                                </div>
                            </div>
                        </div>

                        <div [hidden]="!consigneeRateFlowSet" class="col-lg-6 col-md-6 vl">
                            <h6 class="card-title">Consignee Rate Details</h6>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Source: </label>
                                    <label>{{consigneeRateSrc}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Destination: </label>
                                    <label>{{consigneeRateDest}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Commodity: </label>
                                    <label>{{consigneeRateCommodity}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Consignor: </label>
                                    <label>{{consigneeRateConsignor}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Consignee: </label>
                                    <label>{{consigneeRateConsignee}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Rate Type: </label>
                                    <label>{{consigneeRateUnit}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Type: </label>
                                    <label>{{consigneeRatePerUnit}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Rate On AW: </label>
                                    <label>{{consigneeRateActWgt}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>B. Rate: </label>
                                    <label>{{consigneeRateBasicChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Hamali: </label>
                                    <label>{{consigneeRateHamali}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>GC Chg: </label>
                                    <label>{{consigneeRateGcChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>AOC: </label>
                                    <label>{{consigneeRateAoc}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Others: </label>
                                    <label>{{consigneeRateOthers}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>DD Chg: </label>
                                    <label>{{consigneeRateDDChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>FOV (%): </label>
                                    <label>{{consigneeRateRiskChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>DD Chg: </label>
                                    <label>{{consigneeRateDDChg}}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <label>FOV (%): </label>
                                    <label>{{consigneeRateRiskChg}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>