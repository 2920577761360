// Chg_invoiceDdetailsPrint_1 : KVH req. Request to update main stations in unloading sheet (Added by : Asrar Jr on 08/05/2024)
//Chg_Ver_01 Chenaged new party text from NW PRTY to NPSJ as per req (Chenged By Imran on 16082024)
//Chg_Ver_02 : Two Point Door Delivery Lr Showing from Lrdetails if isTwoPointDeliveryLr is true (By Asrar Jr on 06/11/2024)

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { LRDto } from 'src/app/dto/LR-dto';
import swal from 'sweetalert';
import *  as moment from 'moment';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
@Component({
    selector: 'app-invoice-details-print',
    templateUrl: './invoice-details-print.component.html',
    styleUrls: ['./invoice-details-print.component.css']
})
export class InvoiceDetailsPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    hireSlipDto: HireSlipDto = new HireSlipDto();
    lrDto: LRDto[] = [];
    listOfLrShortage: Array<String> = new Array();
    address: any;
    twoPtLrs: any;
    vehicleNumber: any;
    supplierName: any;
    invoiceNum: any;
    goodsType: any;
    fromStation: any;
    toStation
    gurWgt: any;
    invoiceDate: any;
    kantaWgt: any;

    handleWithCare: any;
    isBankLr: any;
    isSelfLr: any;
    serTax: any;
    totLrsNet: any = 0;
    totArtNet: any = 0;
    totToPayNet: any = 0;
    totPaidNet: any = 0;
    totSerTaxNet: any = 0;
    totActWgtNet: any = 0;
    totChgWgtNet: any = 0;
    totGoodsValueNet: any = 0;
    sizeOfLrdto: any = 0;
    destination: any;
    firstList: any = [];
    secondList: any = [];
    thirdList: any = [];
    divMessages1: any;
    divMessages2: any;
    divMessages3: any;
    appendElement1: any;
    appendElement2: any;
    appendElement3: any;

    lrDtoDataList: any;
    result: any;
    pageId = "indpc";

    constructor(private router: Router, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
            this.hireSlipDto = JSON.parse(localStorage.getItem('invoiceDetailsForBookingHireslipDtoPrint'));
            this.lrDto = JSON.parse(localStorage.getItem('invoiceDetailsForBookingLrDtoPrint'));
            this.listOfLrShortage = JSON.parse(localStorage.getItem('invoiceDetailsForBookingListOfLrsShortPrint'));
            console.log(this.hireSlipDto);
            console.log(this.lrDto);
            //console.log( moment(this.lrDto[0].invoiceDate).format('DD-MM-YYYY'));
            console.log(this.listOfLrShortage);

            //Chg_Ver_02
            // this.twoPtLrs = this.hireSlipDto.twoPtLrs == null ? "NA" : this.hireSlipDto.twoPtLrs;
            this.vehicleNumber = this.hireSlipDto.vehicleNumber == null ? "NA" : this.hireSlipDto.vehicleNumber;
            this.supplierName = this.hireSlipDto.suppliername == null ? "NA" : this.hireSlipDto.suppliername;
            this.invoiceNum = this.hireSlipDto.invoiceNumber == null ? "NA" : this.hireSlipDto.invoiceNumber;
            this.goodsType = this.hireSlipDto.goodsType == null ? "NA" : this.hireSlipDto.goodsType;
            this.fromStation = this.hireSlipDto.fromstation == null ? "NA" : this.hireSlipDto.fromstation;
            this.toStation = this.hireSlipDto.tostation == null ? "NA" : this.hireSlipDto.tostation;
            this.gurWgt = this.hireSlipDto.gurWeight == null ? "NA" : this.hireSlipDto.gurWeight;
            if (this.lrDto.length > 0) {
                this.invoiceDate = this.lrDto[0].invoiceDate == null ? null : moment(this.lrDto[0].invoiceDate).format('DD-MM-YYYY');
            }

            this.kantaWgt = this.hireSlipDto.kantaWeight == null ? "NA" : this.hireSlipDto.kantaWeight;
            console.log("KANTA:" + this.kantaWgt);
            this.sizeOfLrdto = 0;

            //Chg_Ver_02
            let twoPointLrsString = "";
            for (let i = 0; i < this.lrDto.length; i++) {
                this.handleWithCare = "";
                this.isBankLr = "";
                this.isSelfLr = "";
                this.serTax = 0.0;
                if (this.lrDto[i].isHandWithCare && this.lrDto[i].isNewConsignee && this.lrDto[i].isCod) {
                    this.handleWithCare = "HC & NPSJ & COD";
                } else if (this.lrDto[i].isHandWithCare && !this.lrDto[i].isNewConsignee && this.lrDto[i].isCod) {
                    this.handleWithCare = "HC & COD";
                } else if (!this.lrDto[i].isHandWithCare && this.lrDto[i].isNewConsignee && this.lrDto[i].isCod) {
                    this.handleWithCare = "NPSJ & COD";
                } else if (this.lrDto[i].isHandWithCare && this.lrDto[i].isNewConsignee && !this.lrDto[i].isCod) {
                    this.handleWithCare = "Hc & NPSJ";
                } else if (this.lrDto[i].isHandWithCare && !this.lrDto[i].isNewConsignee && !this.lrDto[i].isCod) {
                    this.handleWithCare = "Hc";
                } else if (!this.lrDto[i].isHandWithCare && this.lrDto[i].isNewConsignee && !this.lrDto[i].isCod) {
                    this.handleWithCare = "NPSJ";
                }
                else if (!this.lrDto[i].isHandWithCare && !this.lrDto[i].isNewConsignee && this.lrDto[i].isCod) {
                    this.handleWithCare = "COD";
                }

                if (this.lrDto[i].isBankLr == "1") {
                    this.isBankLr = "BL";
                } else if (this.lrDto[i].isBankLr == "0") {
                    this.isBankLr = "";
                }
                this.lrDto[i].isHandWithCare = this.handleWithCare;
                this.lrDto[i].isHandWithCarePrint = this.lrDto[i].isHandWithCare;
                if (this.lrDto[i].selfLr != null && this.lrDto[i].selfLr) {
                    this.isSelfLr = "Self LR";
                } else {
                    this.isSelfLr = "";
                }
                this.serTax = this.lrDto[i].serviceTax == null ? 0 : this.lrDto[i].serviceTax;

                this.totLrsNet = +this.totLrsNet + +1;
                this.totArtNet = +this.totArtNet + + this.lrDto[i].totalArticles;
                this.totToPayNet = +this.totToPayNet + + this.lrDto[i].toPay;
                this.totPaidNet = +this.totPaidNet + + this.lrDto[i].paid;
                this.totSerTaxNet = +this.totSerTaxNet + + this.serTax;
                this.totActWgtNet = +this.totActWgtNet + + this.lrDto[i].actualWeight;
                this.totChgWgtNet = +this.totChgWgtNet + + this.lrDto[i].chargedWeight;
                this.totGoodsValueNet = +this.totGoodsValueNet + + this.lrDto[i].goodsValue;

                //Chg_Ver_02
                console.log(this.lrDto[i].twoPointLrs);
                if (this.lrDto[i].twoPointLrs) {
                    if (twoPointLrsString) {
                        twoPointLrsString += ", ";
                    }
                    twoPointLrsString += this.lrDto[i].twoPointLrs;
                }
            }
            //Chg_Ver_02
            console.log("twoPointLrsString");
            console.log(twoPointLrsString);
            this.twoPtLrs = twoPointLrsString == null ? "NA" : twoPointLrsString;

            this.lrDtoDataList = [];
            if (this.lrDto.length > 0) {
                this.lrDtoDataList = this.lrDto;
                console.log(this.lrDtoDataList);
                this.setInvoiceDetailsPrintData();
            }
            console.log("HAI-Invoice Details");
            window.setTimeout(function () {
                window.print();
            }, 0);

            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    setInvoiceDetailsPrintData() {
        var groups = new Set(this.lrDtoDataList.map(item => item.deliveryPoints));
        this.result = [];
        groups.forEach(g => {
            /* Each Summary Data Value */
            const totArticle = this.lrDtoDataList
                .filter(i => i.deliveryPoints === g)
                .reduce((sum, i) => +sum + +i.totalArticles, 0)
            const actWgt = this.lrDtoDataList
                .filter(i => i.deliveryPoints === g)
                .reduce((sum, i) => +sum + +i.actualWeight, 0)
            const chgWgt = this.lrDtoDataList
                .filter(i => i.deliveryPoints === g)
                .reduce((sum, i) => +sum + +i.chargedWeight, 0)
            const goodsVal = this.lrDtoDataList
                .filter(i => i.deliveryPoints === g)
                .reduce((sum, i) => +sum + +i.goodsValue, 0)

            // Chg_invoiceDdetailsPrint_1
            const destination = this.lrDtoDataList
                .filter(i => i.deliveryPoints === g)
                .reduce((acc, curr) => curr.destination || acc, '');

            this.result.push({
                deliveryPoints: g,
                values: this.lrDto.filter(i => i.deliveryPoints === g),
                totalArticles: totArticle,
                actualWeight: actWgt,
                chargedWeight: chgWgt,
                goodsValue: goodsVal,
                destination: destination,
            })
        })
    }


    validatBeforePrint() {

    }

    clearField() {
        this.hireSlipDto = new HireSlipDto();
        this.listOfLrShortage = [];
        this.lrDto = [];
        this.lrDtoDataList = [];
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
