//ChgV1_Imran = Changed path of rewarding screen for new update in rewar flow By Imran On 27062024

import { Routes } from '@angular/router';
import { NotfoundComponent } from './authentication/404/not-found.component';
import { LockComponent } from './authentication/lock/lock.component';
import { LoginComponent } from './authentication/login/login.component';
import { Login2Component } from './authentication/login2/login2.component';
import { SignupComponent } from './authentication/signup/signup.component';
import { Signup2Component } from './authentication/signup2/signup2.component';
import { CashmemoGeneratePrintComponent } from './cashmemo/generation/cashmemo-genrate-print/cashmemo-genrate-print.component';
import { PaymentFollowupAssignPrintComponent } from './cashmemo/payment-followup-assign-print/payment-followup-assign-print.component';
import { PaymentFollowupChallanPrintComponent } from './cashmemo/payment-followup-challan-print/payment-followup-challan-print.component';
import { BookingAgentStatementDetailsComponent } from './dashboards/dashboards-popup/booking-agent-statement-details/booking-agent-statement-details.component';
import { BookingTripDetailsComponent } from './dashboards/dashboards-popup/booking-trip-details/booking-trip-details.component';
import { FortnightStatementDetailsComponent } from './dashboards/dashboards-popup/fortnight-statement-details/fortnight-statement-details.component';
import { LabourHamaliStatementDetailsComponent } from './dashboards/dashboards-popup/labour-hamali-statement-details/labour-hamali-statement-details.component';
import { LabourCommissionSetupMumbaiComponent } from './fortnightStatement/hamali-fortnightStatement/labour-commission-setup-mumbai/labour-commission-setup-mumbai.component';
import { DebitNoteStmtComponent } from './fortnightStatement/others/debit-note-stmt/debit-note-stmt.component';
import { FullComponent } from './layouts/full/full.component';
import { InvoiceLrEditComponent } from './lr/edit/invoice-lr-edit/invoice-lr-edit.component';
import { ConsigneeDetailsComponent } from './lr/enquiry/consignee-details/consignee-details.component';
import { LrDeliveryStatusComponent } from './lr/enquiry/lr-delivery-status/lr-delivery-status.component';
import { CashmemoBkgPrintAutoGSTComponent } from './lr/entry/cashmemo-bkg-print-autogst/cashmemo-bkg-print-autogst.component';
import { LrEntryPrintComponent } from './lr/entry/lrentry-print/lrentry-print.component';
import { LrEntryFormPrint2Component } from './lr/entry/lrentryform-print2/lrentryform-print2.component';
import { LrEntryFormPrint3Component } from './lr/entry/lrentryform-print3/lrentryform-print3.component';
import { CommodityMasterComponent } from './master/commodity/commodity-master/commodity-master.component';
import { TpMasterComponent } from './master/commodity/tp-master/tp-master.component';
import { ConsignmentsClaimSettlementEntryComponent } from './master/company/consignments-claim-settlement-entry/consignments-claim-settlement-entry.component';
import { SourceChargesSetupComponent } from './master/company/source-charges-setup/source-charges-setup.component';
import { StaffMasterComponent } from './master/company/staff-master/staff-master.component';
import { UserCreationComponent } from './master/company/user-creation/user-creation.component';
import { UserFeaturesCustomizationComponent } from './master/company/user-features-customization/user-features-customization.component';
import { ConsigneeGroupingComponent } from './master/consignee/consignee-grouping/consignee-grouping.component';
import { RateMasterComponent } from './master/consignee/rate-master/rate-master.component';
import { ConsignorMergeComponent } from './master/consignor/consignor-merge/consignor-merge.component';
import { AgentSetupComponent } from './master/details/agent-setup/agent-setup.component';
import { DebitNoteMasterComponent } from './master/fixing/debit-note-master/debit-note-master.component';
import { ExpensesHeadsComponent } from './master/fixing/expenses-heads/expenses-heads.component';
import { TdsDetailsEntryComponent } from './master/fixing/tds-details-entry/tds-details-entry.component';
import { GetHireslipBarcodeValueComponent } from './master/transhipment/get-hireslip-barcode-value/get-hireslip-barcode-value.component';
import { VehicleSetupMasterComponent } from './master/transhipment/vehicle-setup-master/vehicle-setup-master.component';
// the below tow LrsShortAndExtraComponent and BookingCashmemoComponent is commentted for duplicate name later need to change there name -kamil
// import { LrsShortAndExtraComponent } from './dashboards/dashboard-components/lrs-short-and-extra/lrs-short-and-extra.component';
// import { BookingCashmemoComponent } from './report/cashmemo-report/booking-cashmemo/booking-cashmemo.component';
import { CollectionManGCLeftToPartyPrintComponent } from './report/cashmemo-report/collectionman-gc-left-toparty-print/collectionman-gc-left-toparty-print.component';
import { CustomDynamicPrintV1Component } from './report/cashmemo-report/custom-dynamic-printV1/custom-dynamic-printV1.component';
import { ToBeBilledPartyComponent } from './report/cashmemo-report/to-be-billed-party/to-be-billed-party.component';
import { DoorDeliveryComponent } from './report/daily-report/door-delivery/door-delivery.component';
import { SummaryComponent } from './report/debit/summary/summary.component';
import { HireslipHistoryReportComponent } from './report/hireslip-and-invoice-report/hireslip-history-report/hireslip-history-report.component';
import { DelayedESugamComponent } from './report/lr-report/delayed-e-sugam/delayed-e-sugam.component';
import { LorryHireBalanceReportPrintComponent } from './report/lr-report/lorry-hire-balance-rpt-print/lorry-hire-balance-rpt-print.component';
import { ShortAndExtraConflictComponent } from './report/lr-report/short-and-extra-conflict/short-and-extra-conflict.component';
import { DriverRewardedReportComponent } from './report/others-report/driver-rewarded-report/driver-rewarded-report.component';
import { MonthlyReportComponent } from './report/others-report/monthly-report/monthly-report.component';
import { SalesTaxReportComponent } from './report/others-report/sales-tax-report/sales-tax-report.component';
import { TempoPerformanceReportComponent } from './report/others-report/tempo-performance-report/tempo-performance-report.component';
import { RegularConsigneeReportComponent } from './report/party-report/regular-consignee-report/regular-consignee-report.component';
import { TransitLrsDetailsReportComponent } from './report/party-report/transit-lrs-details-report/transit-lrs-details-report.component';
import { InvoiceReportComponent } from './report/statement-reports/invoice-report/invoice-report.component';
import { HireDetailsByDestinationComponent } from './report/truck-report/hire-details-by-destination/hire-details-by-destination.component';
import { HireslipAdvanceComponent } from './stock/payment/hireslip-advance/hireslip-advance.component';
import { HireslipDetailsPrintComponent } from './trip-creation/hireslip-details-print/hireslip-details-print.component';
import { InvoiceDetailsPrintComponent } from './trip-creation/invoice-details-print/invoice-details-print.component';
import { InvoiceSummaryPrintComponent } from './trip-creation/invoice-summary-print/invoice-summary-print.component';
// By Kamil
import { Dashboard1Component } from './dashboards/dashboard1/dashboard1.component';
import { BookingCashmemoComponent } from './cashmemo/generation/booking-cashmemo/booking-cashmemo.component'
import { GenerateCashmemoComponent } from './cashmemo/generation/generate-cashmemo/generate-cashmemo.component';
import { DummyCashmemoComponent } from './cashmemo/generation/dummy-cashmemo/dummy-cashmemo.component';
import { BillingToSrdCollectionStockComponent } from './cashmemo/transfer-cashmemo/billing-to-srd-collection-stock/billing-to-srd-collection-stock.component';
import { TransferToCollectionComponent } from './cashmemo/transfer-cashmemo/transfer-to-collection/transfer-to-collection.component';
import { CashmemoTransferComponent } from './cashmemo/transfer-cashmemo/cashmemo-transfer/cashmemo-transfer.component';
import { ManualCashmemoCheckComponent } from './cashmemo/transfer-cashmemo/manual-cashmemo-check/manual-cashmemo-check.component';
import { CollectionManCashmemoStockCheckComponent } from './cashmemo/transfer-cashmemo/collection-man-cashmemo-stock-check/collection-man-cashmemo-stock-check.component';
import { CollectionManStockTransferComponent } from './cashmemo/transfer-cashmemo/collection-man-stock-transfer/collection-man-stock-transfer.component';
import { CollectionManToCollectionManStockTransferComponent } from './cashmemo/transfer-cashmemo/collection-man-to-collection-man-stock-transfer/collection-man-to-collection-man-stock-transfer.component';
import { CashmemoBlockComponent } from './cashmemo/cashmemo-block/cashmemo-block.component';
import { PaymentFollowupComponent } from './cashmemo/payment-followup/payment-followup.component';
import { CommissionMasterComponent } from './fortnightStatement/agent/commission-master/commission-master.component';
import { CommodityRateMasterComponent } from './fortnightStatement/agent/commodity-rate-master/commodity-rate-master.component';
import { DestinationAgentStatementGenerationComponent } from './fortnightStatement/agent/destination-agent-statement-generation/destination-agent-statement-generation.component';
import { AmountReceivableFromAgentComponent } from './fortnightStatement/agent/amount-receivable-from-agent/amount-receivable-from-agent.component';
import { AgentCommissionMasterComponent } from './fortnightStatement/booking/agent-commission-master/agent-commission-master.component';
import { AgentStatementGenerationComponent } from './fortnightStatement/booking/agent-statement-generation/agent-statement-generation.component';
import { SrdLabourMasterComponent } from './fortnightStatement/hamali-fortnightStatement/srd-labour-master/srd-labour-master.component';
import { SrdLabourStatementGenerationComponent } from './fortnightStatement/hamali-fortnightStatement/srd-labour-statement-generation/srd-labour-statement-generation.component';
import { PartyLessCheckUploadTypeComponent } from './fortnightStatement/others/party-less-check-upload-type/party-less-check-upload-type.component';
import { BalancingDifferenceTallyReportComponent } from './fortnightStatement/others/balancing-difference-tally-report/balancing-difference-tally-report.component';
import { LrEditComponent } from './lr/edit/lr-edit/lr-edit.component';
import { LrsShortAndExtraComponent } from './lr/edit/lrs-short-and-extra/lrs-short-and-extra.component';
import { LrEnquiryFormComponent } from './lr/enquiry/lr-enquiry-form/lr-enquiry-form.component';
import { LrAdvanceComponent } from './lr/enquiry/lr-advance/lr-advance.component';
import { LrEntryFormComponent } from './lr/entry/lr-entry-form/lrentryform.component';
import { MissAgentsComponent } from './lr/entry/miss-agents/miss-agents.component';
import { ChallanComponent } from './lr/issue/challan/challan.component';
import { LrIssueComponent } from './lr/issue/lr-issue/lr-issue.component';
import { CommodityMergeComponent } from './master/commodity/commodity-merge/commodity-merge.component';
import { SendSmsGroupDetailsComponent } from './master/communication/send-sms-group-details/send-sms-group-details.component';
import { MessageMemoComponent } from './master/communication/message-memo/message-memo.component';
import { EditCompanyComponent } from './master/company/edit-company/edit-company.component';
import { CommonChargesComponent } from './master/company/common-charges/common-charges.component';
import { ExpensesDetailsEntryComponent } from './master/company/expenses-details-entry/expenses-details-entry.component';
import { ConsigneeMasterComponent } from './master/consignee/consignee-master/consignee-master.component';
import { ConsigneeAdminRateMasterComponent } from './master/consignee/consignee-admin-rate-master/consignee-admin-rate-master.component';
import { ConsigneeMergeComponent } from './master/consignee/consignee-merge/consignee-merge.component';
import { ConsignorMasterComponent } from './master/consignor/consignor-master/consignor-master.component';
import { BankComponent } from './master/fixing/bank/bank.component';
import { CollectionComponent } from './master/fixing/collection/collection.component';
import { FovExemptionMasterComponent } from './master/fixing/fov-exemption/fov-exemption.component';
import { SupplierMasterComponent } from './master/supplier/supplier-master/supplier-master.component';
import { DriverMasterComponent } from './master/transhipment/driver-master/driver-master.component';
import { TruckMasterComponent } from './master/transhipment/truck-master/truck-master.component';
import { LorryHireDeductionSetupComponent } from './master/transhipment/lorry-hire-deduction-setup/lorry-hire-deduction-setup.component';
import { NatureOfPackComponent } from './master/transhipment/nature-of-pack/nature-of-pack.component';
import { ExpensesPaymentFollowupReportComponent } from './master/transhipment/expenses-payment-followup-report/expenses-payment-followup-report.component';
import { TrackingLiveReportComponent } from './master/transhipment/tracking-live-report/tracking-live-report.component';
import { PointToPointServiceCheckComponent } from './master/transhipment/point-to-point-service-check/point-to-point-service-check.component';
import { AgentTempoMasterComponent } from './master/transhipment/agent-tempo-master/agent-tempo-master.component';
import { TimeComponent } from './master/trip/time/time.component';
import { TripEtaComponent } from './master/trip/trip-eta-setup/trip-eta-setup.component';
import { AboutUsComponent } from './mobileApps/about-us/about-us.component';
import { LatestNewsComponent } from './mobileApps/latest-news/latest-news.component';
import { OurBranchesComponent } from './mobileApps/our-branches/our-branches.component';
import { RouteMappingComponent } from './mobileApps/route-mapping/route-mapping.component';
import { StatementVerificationComponent } from './report/agents/statement-verification/statement-verification.component';
import { RankingComponent } from './report/agents/ranking/ranking.component';
import { BookingAgentReportComponent } from './report/bookings/booking-agent-report/booking-agent-report.component';
import { AgentExpensesComponent } from './report/bookings/agent-expenses/agent-expenses.component';
import { OfficeHireslipComponent } from './report/bookings/office-hireslip/office-hireslip.component';
import { AgentStatementComponent } from './report/bookings/agent-statement/agent-statement.component';
import { TdsLoadingComponent } from './report/bookings/tds-loading/tds-loading.component';
import { AgentLocalTripComponent } from './report/bookings/agent-local-trip/agent-local-trip.component';
import { AmountReceivableComponent } from './report/cashmemo-report/amount-receivable/amount-receivable.component';
import { PaidReportComponent } from './report/cashmemo-report/paid-report/paid-report.component';
import { CashMemoReportComponent } from './report/cashmemo-report/cash-memo-report/cash-memo-report.component';
import { BookingCashmemoByInvoiceComponent } from './report/cashmemo-report/booking-cashmemo-by-invoice/booking-cashmemo-by-invoice.component';
import { CashmemoDetailsComponent } from './report/cashmemo-report/cashmemo-details/cashmemo-details.component';
import { LetterHeadReportComponent } from './report/cashmemo-report/letter-head-report/letter-head-report.component';
import { CollectionManReportComponent } from './report/cashmemo-report/collection-man-report/collection-man-report.component';
import { SrdCollectionStockReportComponent } from './report/cashmemo-report/srd-collection-stock-report/srd-collection-stock-report.component';
import { MemoLessSearchReportComponent } from './report/cashmemo-report/memo-less-search-report/memo-less-search-report.component';
import { CashMemoInCollectionManStockComponent } from './report/cashmemo-report/cash-memo-in-collection-man-stock/cash-memo-in-collection-man-stock.component';
import { TotalLessComponent } from './report/cashmemo-report/total-less/total-less.component';
import { DailyBookingReportComponent } from './report/daily-report/daily-booking-report/daily-booking-report.component';
import { OfficeReportComponent } from './report/daily-report/office-report/office-report.component';
import { BangaloreOfficeComponent } from './report/daily-report/bangalore-office/bangalore-office.component';
import { DebitNoteReportComponent } from './report/debit/debit-note-report/debit-note-report.component';
import { BrokerReportComponent } from './report/hamali-report/broker-report/broker-report.component';
import { LabourStatementReportComponent } from './report/hamali-report/labour-statement-report/labour-statement-report.component';
import { HireslipDetailsComponent } from './report/hireslip-and-invoice-report/hireslip-details/hireslip-details.component';
import { InvoiceDetailsComponent } from './report/hireslip-and-invoice-report/invoice-details/invoice-details.component';
import { AdvancePaymentComponent } from './report/hireslip-and-invoice-report/advance-payment/advance-payment.component';
import { HireslipUnloadingReportComponent } from './report/hireslip-and-invoice-report/hireslip-unloading-report/hireslip-unloading-report.component';
import { TranshipmentTripComponent } from './report/hireslip-and-invoice-report/transhipment-trip/transhipment-trip.component';
import { LocalTripSheetComponent } from './report/hireslip-and-invoice-report/local-trip-sheet/local-trip-sheet.component';
import { ServiceTaxReportComponent } from './report/hireslip-and-invoice-report/service-tax-report/service-tax-report.component';
import { IssueBalanceComponent } from './report/lr-report/issue-balance/issue-balance.component';
import { LrIssueReportComponent } from './report/lr-report/lr-issue-report/lr-issue-report.component';
import { ChallanIssueComponent } from './report/lr-report/challan-issue/challan-issue.component';
import { ShortAndExtraComponent } from './report/lr-report/short-and-extra/short-and-extra.component';
import { LrDispatchBookingReportComponent } from './report/lr-report/lr-dispatch-booking-report/lr-dispatch-booking-report.component';
import { AutomatedSearchComponent } from './report/lr-report/automated-search/automated-search.component';
import { LrHistoryReportComponent } from './report/lr-report/lr-history-report/lr-history-report.component';
import { LorryHireStatementComponent } from './report/lr-report/lorry-hire-statement/lorry-hire-statement.component';
import { LorryHireBalanceComponent } from './report/lr-report/lorry-hire-balance/lorry-hire-balance.component';
import { LrPerformanceReportComponent } from './report/lr-report/lr-performance-report/lr-performance-report.component';
import { LrRptComponent } from './report/lr-report/lr-rpt/lr-rpt.component';
import { GcNotReceivedReportComponent } from './report/lr-report/gc-not-received-report/gc-not-received-report.component';
import { EWayBillDateValidationExtensionRptComponent } from './report/lr-report/ewaybilldate-validation-extension-rpt/ewaybilldate-validation-extension-rpt.component';
import { LcAndBcReportComponent } from './report/others-report/lc-and-bc-report/lc-and-bc-report.component';
import { LoaderPerformanceReportComponent } from './report/others-report/loader-performance-report/loader-performance-report.component';
import { VehicleTrackingComponent } from './report/others-report/vehicle-tracking/vehicle-tracking.component';
import { ChequeSearchReportComponent } from './report/others-report/cheque-search-report/cheque-search-report.component';
import { ChequeDetailsReportComponent } from './report/others-report/cheque-details-report/cheque-details-report.component';
import { LocalChallanReportComponent } from './report/others-report/local-challan-report/local-challan-report.component';
import { ConsignorReportComponent } from './report/party-report/consignor-report/consignor-report.component';
import { ConsigneeReportComponent } from './report/party-report/consignee-report/consignee-report.component';
import { BusinessStopReportComponent } from './report/party-report/business-stop-report/business-stop-report.component';
import { BusinessStartReportComponent } from './report/party-report/business-start-report/business-start-report.component';
import { PartyLessReportComponent } from './report/party-report/party-less-report/party-less-report.component';
import { PartyWiseOsReportComponent } from './report/party-report/party-wise-os-report/party-wise-os-report.component';
import { PartyWiseTotalStatusDetailsComponent } from './report/party-report/party-wise-total-status-details/party-wise-total-status-details.component';
import { CommodityMismatchReportComponent } from './report/party-report/commodity-mismatch-report/commodity-mismatch-report.component';
import { ConsigneeAdminRateReportComponent } from './report/party-report/consignee-admin-rate-report/consignee-admin-rate-report.component';
import { RateHistoryReportComponent } from './report/party-report/rate-history-report/rate-history-report.component';
import { AdminBusinessRateReportComponent } from './report/party-report/admin-business-rate-report/admin-business-rate-report.component';
import { ConsigneeRateReportComponent } from './report/party-report/consignee-rate-report/consignee-rate-report.component';
import { DiscontinuousDetailsReportComponent } from './report/party-report/discontinuous-details-report/discontinuous-details-report.component';
import { StatementRptComponent } from './report/statement-reports/statement-rpt/statement-rpt.component';
import { PartyLessCheckedAndUncheckedLrDetailsComponent } from './report/statement-reports/party-less-checked-and-unchecked-lr-details/party-less-checked-and-unchecked-lr-details.component';
import { StocksInTakenReportComponent } from './report/stocks-report/stocks-in-taken-report/stocks-in-taken-report.component';
import { CommodityReportComponent } from './report/stocks-report/commodity-report/commodity-report.component';
import { GodownStocksBookingReportComponent } from './report/stocks-report/godown-stocks-booking-report/godown-stocks-booking-report.component';
import { GodownStocksReportComponent } from './report/stocks-report/godown-stocks-report/godown-stocks-report.component';
import { PendingStocksDetailsReportComponent } from './report/stocks-report/pending-stocks-details-report/pending-stocks-details-report.component';
import { StocksUnloadingReportComponent } from './report/stocks-report/stocks-unloading-report/stocks-unloading-report.component';
import { StorageReportComponent } from './report/stocks-report/storage-report/storage-report.component';
import { BookingAgentDispatchPerformanceReportComponent } from './report/stocks-report/booking-agent-dispatch-performance-report/booking-agent-dispatch-performance-report.component';
import { PerformanceReportComponent } from './report/truck-report/performance-report/performance-report.component';
import { TruckReportComponent } from './report/truck-report/truck-rpt/truck-rpt.component';
import { TruckOwnerDeclarationReportComponent } from './report/truck-report/truck-owner-declaration-report/truck-owner-declaration-report.component';
import { HaltAndDispatchedDetailsComponent } from './report/truck-report/halt-and-dispatched-details/halt-and-dispatched-details.component';
import { NonAvailabilityOfTruckReportComponent } from './report/truck-report/non-availability-of-truck/non-availability-of-truck-report.component';
import { ManualStockCheckComponent } from './stock/customization/manual-stock-check/manual-stock-check.component';
import { RewardingComponent } from './stock/driver/rewarding/rewarding.component';
import { HireslipBalanceComponent } from './stock/payment/hireslip-balance/hireslip-balance.component';
import { ShipmentStatusComponent } from './stock/transfer/shipment-status/shipment-status.component';
import { StocksToCpComponent } from './stock/transfer/stocks-to-cp/stocks-to-cp.component';
import { StocksBetweenGodownComponent } from './stock/transfer/stocks-between-godown/stocks-between-godown.component';
import { StocksForLocalBookingComponent } from './stock/transfer/stocks-for-local-booking/stocks-for-local-booking.component';
import { StocksForTripsheetComponent } from './stocks-for-tripsheet-page/stocks-for-tripsheet/stocks-for-tripsheet.component';
import { StocksGroupingComponent } from './trip-creation/stocks-grouping/stocks-grouping.component';
import { PartyLessCheckComponent } from './fortnightStatement/others/party-less-check/party-less-check.component';
import { LrShortAndExtraConsolidateRptComponent } from './report/lr-report/lr-short-and-extra-consolidate-rpt/lr-short-and-extra-consolidate-rpt.component';
import { AgentStockReportComponent } from './dashboards/dashboards-popup/agent-stock-report/agent-stock-report.component';
import { LrDwsWeightReportComponent } from './report/lr-report/lr-dws-wgt-rpt/lr-dws-wgt-rpt.component';
import { StocksForLocalBookingBarcodeComponent } from './stock/transfer/stocks-for-local-booking-barcode/stocks-for-local-booking-barcode.component';
import { LrEntryFormBarcodePrintComponent } from './lr/entry/lrentryform-barcode-print/lrentryform-barcode-print.component';
import { SendSmsComponent } from './master/communication/send-sms/send-sms.component';
import { ConsignorAdminRateMasterComponent } from './master/consignor/consignor-admin-rate-master/consignor-admin-rate-master.component';
import { SrcLrRatecheckRptComponent } from './report/lr-report/src-lr-ratecheck-rpt/src-lr-ratecheck-rpt.component';
import { CashmemoPodUploadComponent } from './report/cashmemo-report/cashmemo-pod-upload/cashmemo-pod-upload.component';
import { VehicleAreaChargeSetupComponent } from './master/transhipment/vehicle-area-charge-setup/vehicle-area-charge-setup.component';
import { GetAdvanceConsentComponent } from './master/transhipment/get-advance-consent/get-advance-consent.component';
import { CflFormulaSetupComponent } from './master/fixing/cfl-formula-setup/cfl-formula-setup.component';
import { InchargeMasterComponent } from './master/company/incharge-master/incharge-master.component';
import { LoaderMasterComponent } from './master/company/loader-master/loader-master.component';
import { CftExemptionMasterComponent } from './master/fixing/cft-exemption/cft-exemption.component';
import { CftRangeReportComponent } from './report/daily-report/cft-range-report/cft-range-report.component';
import { PaymentFollowupBookingComponent } from './cashmemo/payment-followup-booking/payment-followup-booking.component';
import { ConsignorMasterTopartyComponent } from './master/consignor/consignor-master-toparty/consignor-master-toparty.component';
import { BookingPaidCollectionReportComponent } from './report/cashmemo-report/booking-paid-collection-report/booking-paid-collection-report.component';
import { BookingPartyOsReportComponent } from './report/cashmemo-report/booking-party-os-report/booking-party-os-report.component';
import { HirepaymentEnetBankReportComponent } from './report/hireslip-and-invoice-report/hirepayment-enet-bank-report/hirepayment-enet-bank-report.component';
import { LrEntryEnableConsigneecopyComponent } from './lr/entry/lr-entry-enable-consigneecopy/lr-entry-enable-consigneecopy.component';
import { LrHoldReasonComponent } from './lr/edit/lr-hold-reason/lr-hold-reason.component';
import { SpecificConsigneeDetailsComponent } from './master/consignee/specific-consignee-details/specific-consignee-details.component';
import { DestinationCommissionComponent } from './report/agents/destination-commission/destination-commission.component';
import { DestinationIncomeComponent } from './report/agents/destination-income/destination-income.component';
import { AreaComponent } from './master/fixing/area/area.component';
import { ConsigneeMobilenumberConfirmComponent } from './master/consignee/consignee-mobilenumber-confirm/consignee-mobilenumber-confirm.component';
import { ConsignorMobilenumberConfirmComponent } from './master/consignor/consignor-mobilenumber-confirm/consignor-mobilenumber-confirm.component';
import { LrdeleteMasterpwdSetupComponent } from './master/company/lrdelete-masterpwd-setup/lrdelete-masterpwd-setup.component';
import { InvoiceLoadingSheetComponent } from './trip-creation/invoice-loading-sheet/invoice-loading-sheet.component';
import { InvoiceLoadingSheetFlowComponent } from './trip-creation/invoice-loading-sheet-flow/invoice-loading-sheet-flow.component';
import { GstInvoiceDetainedComponent } from './lr/edit/gst-Invoice-Detained/gst-Invoice-Detained.component';
import { PartwiseCFTDetailsReportComponent } from './report/others-report/partwise-cft-details-report/partwise-cft-details-report.component';
import { LrBookingDateCustomChangeComponent } from './master/fixing/lr-booking-date-custom-change/lr-booking-date-custom-change.component';
import { LoadingInvoiceEditComponent } from './trip-creation/loading-invoice-edit/loading-invoice-edit.component';
import { AgentSubstationMasterComponent } from './master/details/agent-substation-master/agent-substation-master.component';
import { EditLrFreightComponent } from './lr/edit/edit-lr-freight/edit-lr-freight.component';
import { RewardingNewVersionComponent } from './stock/driver/rewarding-new-version/rewarding-new-version.component';
import { PartywisePerformanceMonitorComponent } from './master/company/party-wise-performance-monitor/party-wise-performance-monitor.component';
import { PerKgRateSetupComponent } from './master/fixing/per-kg-rate-setup/per-kg-rate-setup.component';
import { CommodityKgLimitSetupComponent } from './master/fixing/commodity-kg-limit-setup/commodity-kg-limit-setup.component';
import { CommodityBookingBlockComponent } from './master/commodity/commodity-booking-block/commodity-booking-block.component';
import { GenerateBarcodeComponent } from './lr/entry/generate-barcode/generate-barcode.component';
import { GenerateBarcodePrintComponent } from './lr/entry/generate-barcode-print/generate-barcode-print.component';
import { LocalTripLoadingSheetFlowComponent } from './trip-creation/local-trip-loading-sheet-flow/local-trip-loading-sheet-flow.component';
import { BookingTripDetailsBarcodeComponent } from './dashboards/dashboards-popup/booking-trip-details-barcode/booking-trip-details-barcode.component';
import { LrBlockedPartyWiseComponent } from './master/fixing/lr-blocked-party-wise/lr-blocked-party-wise.component';
import { DestinationStocksReportComponent } from './report/stocks-report/destination-stocks-report/destination-stocks-report.component';
import { UnclearedLrDetailsComponent } from './stock/transfer/uncleared-lr-details/uncleared-lr-details.component';
import { ReGenerateBarcodeComponent } from './lr/entry/re-generate-barcode/re-generate-barcode.component';
import { BarcodeLoadingSheetMobileComponent } from './lr/entry/barcode-loadingsheet-mobile/barcode-loadingsheet-mobile.component';
import { PartyContactDetailsComponent } from './report/others-report/party-contact-details/party-contact-details.component';
import { LrShortAndExtraConsolidateRptV2Component } from './report/lr-report/lr-short-and-extra-consolidate-rptV2/lr-short-and-extra-consolidate-rptV2.component';
import { CashmemoOnlinePaymentDetailsComponent } from './report/cashmemo-report/cashmemo-online-payment-details/cashmemo-online-payment-details.component';
import { LrRateCalculatorComponent } from './lr/enquiry/lr-rate-calculator/lr-rate-calculator.component';
import { EstimateEnquiryReportComponent } from './report/lr-report/estimate-enquiry-report/estimate-enquiry-report.component';
import { EWBSearchComponent } from './eway-bill/ewb-search/ewb-search.component';
import { PartBUpdateomponent } from './eway-bill/part-b-update/part-b-update.component';
import { GstSearchComponent } from './eway-bill/gst-search/gst-search.component';
import { GenerateNewEWBComponent } from './eway-bill/generate-new-ewb/generate-new-ewb.component';
import { UpdateTransporterIdComponent } from './eway-bill/update-transporterId/update-transporterId.component';
import { GenerateConsolidateEWbComponent } from './eway-bill/generate-consolidate-ewb/generate-consolidate-ewb.component';
import { CancelEWBComponent } from './eway-bill/cancel-ewb/cancel-ewb.component';
import { PaymentLinkTransactionDetailsComponent } from './report/others-report/paymentlink-transaction-details/paymentlink-transaction-details.component';
import { ConsolidateEWBPrintComponent } from './eway-bill/consolidate-ewb-print/consolidate-ewb-print.component';
import { EWBDateExpiryDestinationRptComponent } from './report/lr-report/ewb-date-expiry-destination-rpt/ewb-date-expiry-destination-rpt.component';

export const Approutes: Routes = [
    //{ path: '', redirectTo: 'authentication/login', pathMatch: 'full' },
    { path: 'login', loadChildren: 'src/app/authentication/authentication.module#AuthenticationModule' },
    {
        path: 'authentication/404',
        component: NotfoundComponent
    },
    {
        path: 'authentication/lock',
        component: LockComponent
    },
    {
        path: 'authentication/login',
        component: LoginComponent
    },
    {
        path: 'authentication/login2',
        component: Login2Component
    },
    {
        path: 'authentication/signup',
        component: SignupComponent
    },
    {
        path: 'authentication/signup2',
        component: Signup2Component
    },


    { path: 'lr', loadChildren: 'src/app/lr/lr.module#LrModule' },
    { path: 'masters', loadChildren: 'src/app/master/masters.module#MasterModule' },
    { path: 'cashmemo', loadChildren: 'src/app/cashmemo/cashmemo.module#CashmemoModule' },
    { path: 'fortnightStatement', loadChildren: 'src/app/fortnightStatement/fortnightStatement.module#FortnightStatementModule' },
    { path: 'report', loadChildren: 'src/app/report/report.module#ReportModule' },
    { path: 'stock', loadChildren: 'src/app/stock/stock.module#StockModule' },
    { path: 'mobileApps', loadChildren: 'src/app/mobileApps/mobileApps.module#MobileAppsModule' },
    { path: 'trip-creation', loadChildren: 'src/app/trip-creation/trip-creation.module#TripCreationModule' },
    { path: 'stocks-for-tripsheet-page', loadChildren: 'src/app/stocks-for-tripsheet-page/stocks-for-tripsheet-page.module#StocksForTripSheetPageModule' },
    //Cash memo routing
    { path: 'cashmemo/generation', loadChildren: 'src/app/cashmemo/generation/generation.module#GenerationModule' },
    { path: 'cashmemo/transfer-cashmemo', loadChildren: 'src/app/cashmemo/transfer-cashmemo/transfer-cashmemo.module#TransferCashmemoModule' },
    ///Fort night statement
    { path: 'fortnightStatement/agent', loadChildren: 'src/app/fortnightStatement/agent/agent.module#AgentModule' },
    { path: 'fortnightStatement/booking', loadChildren: 'src/app/fortnightStatement/booking/booking.module#BookingModule' },
    { path: 'fortnightStatement/hamali-fortnightStatement', loadChildren: 'src/app/fortnightStatement/hamali-fortnightStatement/hamali-fortnightStatement.module#HamaliFortnightStatementModule' },
    { path: 'fortnightStatement/others', loadChildren: 'src/app/fortnightStatement/others/others.module#OthersModule' },
    ///Lr 
    { path: 'lr/edit', loadChildren: 'src/app/lr/edit/edit.module#EditModule' },
    { path: 'lr/enquiry', loadChildren: 'src/app/lr/enquiry/enquiry.module#EnquiryModule' },
    { path: 'lr/entry', loadChildren: 'src/app/lr/entry/entry.module#EntryModule' },
    { path: 'lr/issue', loadChildren: 'src/app/lr/issue/issue.module#IssueModule' },
    //Master 
    { path: 'masters/consignee', loadChildren: 'src/app/master/consignee/consignee.module#ConsigneeModule' },
    { path: 'masters/consignor', loadChildren: 'src/app/master/consignor/consignor.module#ConsignorModule' },
    { path: 'masters/supplier', loadChildren: 'src/app/master/supplier/supplier.module#SupplierModule' },
    { path: 'masters/transhipment', loadChildren: 'src/app/master/transhipment/transhipment.module#TranshipmentModule' },
    { path: 'masters/commodity', loadChildren: 'src/app/master/commodity/commodity.module#CommodityModule' },
    { path: 'masters/company', loadChildren: 'src/app/master/company/company.module#CompanyModule' },
    { path: 'masters/fixing', loadChildren: 'src/app/master/fixing/fixing.module#FixingModule' },
    { path: 'masters/trip', loadChildren: 'src/app/master/trip/trip.module#TripModule' },
    { path: 'masters/details', loadChildren: 'src/app/master/details/details.module#DetailsModule' },
    { path: 'masters/communication', loadChildren: 'src/app/master/communication/communication.module#CommunicationModule' },
    //Mobile App
    //report
    { path: 'report/agents', loadChildren: 'src/app/report/agents/agents.module#AgentsModule' },
    { path: 'report/bookings', loadChildren: 'src/app/report/bookings/bookings.module#BookingsModule' },
    { path: 'report/hamali-report', loadChildren: 'src/app/report/hamali-report/hamali-report.module#HamaliReportModule' },
    { path: 'report/cashmemo-report', loadChildren: 'src/app/report/cashmemo-report/cashmemo-report.module#CashmemoReportModule' },
    { path: 'report/daily-report', loadChildren: 'src/app/report/daily-report/daily-report.module#DailyReportModule' },
    { path: 'report/debit', loadChildren: 'src/app/report/debit/debit.module#DebitModule' },
    { path: 'report/lr-report', loadChildren: 'src/app/report/lr-report/lr-report.module#LrReportModule' },
    { path: 'report/others-report', loadChildren: 'src/app/report/others-report/others-report.module#OthersReportModule' },
    { path: 'report/stocks-report', loadChildren: 'src/app/report/stocks-report/stocks-report.module#StocksReportModule' },
    { path: 'report/truck-report', loadChildren: 'src/app/report/truck-report/truck-report.module#TruckReportModule' },
    { path: 'report/party-report', loadChildren: 'src/app/report/party-report/party-report.module#PartyReportModule' },
    { path: 'report/hireslip-and-invoice-report', loadChildren: 'src/app/report/hireslip-and-invoice-report/hireslip-and-invoice-report.module#HireslipAndInvoiceModule' },
    { path: 'report/statement-reports', loadChildren: 'src/app/report/statement-reports/statement-reports.module#StatementReportsModule' },
    // stock routing
    { path: 'stock/customization', loadChildren: 'src/app/stock/customization/customization.module#CustomizationModule' },
    { path: 'stock/driver', loadChildren: 'src/app/stock/driver/driver.module#DriverModule' },
    { path: 'stock/payment', loadChildren: 'src/app/stock/payment/payment.module#PaymentModule' },
    { path: 'stock/transfer', loadChildren: 'src/app/stock/transfer/transfer.module#TransferModule' },
    //Stock for tripsheet
    // Trip Creation 
    // EWay Bill routing
    { path: 'eway-bill', loadChildren: 'src/app/eway-bill/eway-bill.module#EWayBillModule' },
    {
        path: 'dashboard/labourHamaliStatementDetails',
        component: LabourHamaliStatementDetailsComponent
    },
    {
        path: 'dashboard/bookingTripDetails',
        component: BookingTripDetailsComponent
    },
    {
        path: 'dashboard/fortnightStatementDetails',
        component: FortnightStatementDetailsComponent
    },
    {
        path: 'dashboard/bookingAgentStatementDetails',
        component: BookingAgentStatementDetailsComponent
    },
    {
        path: 'cashmemo/paymentFollowupAssignPrint',
        component: PaymentFollowupAssignPrintComponent

    },
    {
        path: 'cashmemo/paymentFollowupChallanPrint',
        component: PaymentFollowupChallanPrintComponent

    },

    {
        path: 'trip-creation/invoiceSummaryPrint',
        component: InvoiceSummaryPrintComponent

    },
    {
        path: 'trip-creation/invoiceDetailsPrint',
        component: InvoiceDetailsPrintComponent

    },
    {
        path: 'trip-creation/hireslipDetailsPrint',
        component: HireslipDetailsPrintComponent

    },
    // Enquiry
    {
        path: 'lr/enquiry/consigneeDetails',
        component: ConsigneeDetailsComponent
    },
    {
        path: 'lr/enquiry/lrDeliveryStatus',
        component: LrDeliveryStatusComponent
    },
    //Agents routing
    /*{
        path: 'report/agentsbookingCashmemo',
        component: BookingCashmemoComponent
    },
    {
        path: 'report/agentsgenerateCashmemo',
        component: GenerateCashmemoComponent
    },
    {
        path: 'report/agentsdummyCashmemo',
        component: DummyCashmemoComponent
    },
    {
        path: 'report/agentscashMemoPrint',
        component: CashmemoGeneratePrintComponent
    },*/
    /// Generating module
    {
        path: 'cashmemo/generation/cashMemoPrint',
        component: CashmemoGeneratePrintComponent,

    },
    //Hamali for night statement
    {
        path: 'fortnightStatement/hamali-fortnightStatement/labourcommissionsetupmumbai',
        component: LabourCommissionSetupMumbaiComponent,
    },
    //Others 
    // {
    //     path: 'fortnightStatement/others/invoicelredit',
    //     // component: InvoiceLrEditComponent,

    // },
    // Debit Note Stmt
    {
        path: 'fortnightStatement/others/debitNoteStmt',
        component: DebitNoteStmtComponent,

    },
    //Edit
    {
        path: 'lr/edit/invoicelredit',
        component: InvoiceLrEditComponent,

    },
    {
        path: 'lr/entry/lrEntryPrint',
        component: LrEntryPrintComponent,
    },
    {
        path: 'lr/entry/lrEntryFormPrint2',
        component: LrEntryFormPrint2Component,
    },
    {
        path: 'lr/entry/cashmemoBkgPrintAutoGSTComponent',
        component: CashmemoBkgPrintAutoGSTComponent,
    },
    {
        path: 'lr/entry/lrEntryFormPrint3',
        component: LrEntryFormPrint3Component,
    },
    {
        path: 'lr/entry/generateBarcodePrint',
        component: GenerateBarcodePrintComponent,
    },
    ///Commodity
    {
        path: 'masters/commodity/commodityMaster',
        component: CommodityMasterComponent,
    },
    {
        path: 'masters/commodity/tpMaster',
        component: TpMasterComponent,
    },
    //Company
    {
        path: 'masters/company/userCreation',
        component: UserCreationComponent
    },

    {
        path: 'masters/company/staffMaster',
        component: StaffMasterComponent
    },
    {
        path: 'masters/company/consignmentsClaimSettlementEntry',
        component: ConsignmentsClaimSettlementEntryComponent
    },
    {
        path: 'masters/company/userFeaturesCustomization',
        component: UserFeaturesCustomizationComponent
    },
    {
        path: 'masters/company/sourceChargesSetup',
        component: SourceChargesSetupComponent
    },
    //Consignee
    {
        path: 'masters/consignee/rateMaster',
        component: RateMasterComponent
    },
    {
        path: 'masters/consignee/consigneeGrouping',
        component: ConsigneeGroupingComponent
    },
    //Consignor
    {
        path: 'masters/consignor/consignorMerge',
        component: ConsignorMergeComponent
    },
    //Detail
    {
        path: 'masters/details/agentSetup',
        component: AgentSetupComponent
    },
    // Fixing
    {
        path: 'masters/fixing/debitNoteMaster',
        component: DebitNoteMasterComponent
    },
    {
        path: 'masters/fixing/expensesHeads',
        component: ExpensesHeadsComponent
    },
    {
        // the below 'Dummy' Added to avoid to open the component on search click
        // path: 'masters/fixing/tdsDetailsEntry',
        path: 'masters/fixing/tdsDetailsEntry',
        component: TdsDetailsEntryComponent
    },
    ///Transhipment
    {
        path: 'masters/transhipment/vehicleSetupMaster',
        component: VehicleSetupMasterComponent
    },
    {
        path: 'masters/transhipment/getHireslipBarcodeValue',
        component: GetHireslipBarcodeValueComponent
    },
    //Cash memo report
    {
        path: 'report/cashmemo-report/tobebilledparty',
        component: ToBeBilledPartyComponent
    },
    /*{
        path: 'report/cashmemo-report/bookingcashmemo',
        component: BookingCashmemoComponent
    },*/
    {
        path: 'report/cashmemo-report/collectionManGCLeftToPartyPrint',
        component: CollectionManGCLeftToPartyPrintComponent
    },
    {
        path: 'report/cashmemo-report/customDynamicPrintV1',
        component: CustomDynamicPrintV1Component
    },
    //Daily 
    {
        path: 'report/daily-report/doorDelivery',
        component: DoorDeliveryComponent
    },
    //Debit
    {
        path: 'report/debit/summary',
        component: SummaryComponent
    },
    //Hireslip
    {
        path: 'report/hireslip-and-invoice-report/hireslipHistoryReport',
        component: HireslipHistoryReportComponent
    },
    //Lr-report
    {
        path: 'report/lr-report/shortAndExtraConflict',
        component: ShortAndExtraConflictComponent
    },
    {
        path: 'report/lr-report/delayedESugam',
        component: DelayedESugamComponent
    },
    {
        path: 'report/lr-report/LorryHireBalanceReportPrint',
        component: LorryHireBalanceReportPrintComponent
    },
    //Others report
    {
        path: 'report/others-report/monthlyReport',
        component: MonthlyReportComponent
    },
    {
        path: 'report/others-report/salesTaxReport',
        component: SalesTaxReportComponent
    },
    {
        path: 'report/others-report/driverRewardedReport',
        component: DriverRewardedReportComponent
    },
    {
        path: 'report/others-report/tempoPerformanceReport',
        component: TempoPerformanceReportComponent
    }, {
        path: 'report/others-report/partywiseCFTDetailsReport',
        component: PartwiseCFTDetailsReportComponent
    },
    ///Party report
    {
        path: 'report/party-report/regularConsigneeReport',
        component: RegularConsigneeReportComponent
    },
    {
        path: 'report/party-report/transitLrsDetailsReport',
        component: TransitLrsDetailsReportComponent
    },

    //truck report

    {
        path: 'report/truck-report/hireDetailsByDestinationReport',
        component: HireDetailsByDestinationComponent
    },

    //Payment
    {
        path: 'stock/payment/hireslipAdvance',
        component: HireslipAdvanceComponent
    },





    // the below code is added as per sidebar.component.html file 
    // Routing By Kamil on 31-07-2021
    // the step is first in comment as per sidebar page name added then it's path and their respective component, 
    // see below as dashboard is page name and path to follow it component


    // Dashboard
    {
        path: 'dashboard/classic',
        component: Dashboard1Component
    },
    // Booking Cash Memo
    {
        path: 'cashmemo/generation/bookingCashmemo',
        component: BookingCashmemoComponent,

    },
    // Generate Cash Memo
    {
        path: 'cashmemo/generation/generateCashmemo',
        component: GenerateCashmemoComponent,

    },
    // Dummy
    {
        path: 'cashmemo/generation/dummyCashmemo',
        component: DummyCashmemoComponent,

    },
    // Billing To SRD Collection Stock
    {
        path: 'cashmemo/transfer-cashmemo/billingToSrdCollectionStock',
        component: BillingToSrdCollectionStockComponent,

    },
    // Transfer To Collection
    {
        path: 'cashmemo/transfer-cashmemo/transferToCollection',
        component: TransferToCollectionComponent,

    },
    // Cashmemo Transfer
    {
        path: 'cashmemo/transfer-cashmemo/cashmemoTransfer',
        component: CashmemoTransferComponent,

    },
    // Manual Cashmemo Check
    {
        path: 'cashmemo/transfer-cashmemo/manualCashmemoCheck',
        component: ManualCashmemoCheckComponent,

    },
    // Collection Man Cashmemo Stock Check
    {
        path: 'cashmemo/transfer-cashmemo/collectionManCashmemoStockCheck',
        component: CollectionManCashmemoStockCheckComponent,

    },
    // Collection Man Stock Transfer
    {
        path: 'cashmemo/transfer-cashmemo/collectionManStockTransfer',
        component: CollectionManStockTransferComponent,

    },
    // Collection Man To Collection Man Stock Transfer
    {
        path: 'cashmemo/transfer-cashmemo/collectionManToCollectionManStockTransfer',
        component: CollectionManToCollectionManStockTransferComponent,

    },
    // Cash Memo Block
    {
        path: 'cashmemo/cashmemoBlock',
        component: CashmemoBlockComponent

    },
    // Payment Followup
    {
        path: 'cashmemo/paymentFollowup',
        component: PaymentFollowupComponent

    },
    // Commission Master
    {
        path: 'fortnightStatement/agent/commissionmaster',
        component: CommissionMasterComponent,

    },
    // Commodity Rate Master
    {
        path: 'fortnightStatement/agent/commodityratemaster',
        component: CommodityRateMasterComponent,

    },
    // Destination Agent Statement Generation
    {
        path: 'fortnightStatement/agent/destinationAgentStatementGeneration',
        component: DestinationAgentStatementGenerationComponent,

    },
    // Amount Receivable From Agent
    {
        path: 'fortnightStatement/agent/amountReceivableFromAgent',
        component: AmountReceivableFromAgentComponent,

    },
    // Agent Commision Master
    {
        path: 'fortnightStatement/booking/agentCommissionMaster',
        component: AgentCommissionMasterComponent,
    },
    // Agent Statement Generation
    {
        path: 'fortnightStatement/booking/agentStatementGeneration',
        component: AgentStatementGenerationComponent,
    },
    // SRD Labour Master
    {
        path: 'fortnightStatement/hamali-fortnightStatement/srdlabourmaster',
        component: SrdLabourMasterComponent,
    },
    // SRD Labour Stmt Generation
    {
        path: 'fortnightStatement/hamali-fortnightStatement/srdLabourStatementGeneration',
        component: SrdLabourStatementGenerationComponent
    },
    // Party Less Check - Upload Type
    {
        path: 'fortnightStatement/others/partyLessCheckUploadType',
        component: PartyLessCheckUploadTypeComponent,

    },
    // Balancing Difference Tally Report
    {
        path: 'fortnightStatement/others/balancingDifferenceTallyReport',
        component: BalancingDifferenceTallyReportComponent,

    },
    // LR Edit
    {
        path: 'lr/edit/lredit',
        component: LrEditComponent,
    },
    // LRs Short & Extra
    {
        path: 'lr/edit/lrsShortAndExtra',
        component: LrsShortAndExtraComponent,
    },
    // LR Enquiry Form
    {
        path: 'lr/enquiry/lrEnquiry',
        component: LrEnquiryFormComponent
    },
    // LR Advance
    {
        path: 'lr/enquiry/lrAdvance',
        component: LrAdvanceComponent
    },
    // LR Entry Form
    {
        path: 'lr/entry/lrentry',
        component: LrEntryFormComponent,
    },
    // Miss Agent
    {
        path: 'lr/entry/missAgent',
        component: MissAgentsComponent,
    },
    // Challan
    {
        path: 'lr/issue/challan',
        component: ChallanComponent,
    },
    // LR Issue
    {
        path: 'lr/issue/lrissue',
        component: LrIssueComponent,
    },
    // Commodity Merge
    {
        path: 'masters/commodity/commodityMerge',
        component: CommodityMergeComponent,
    },
    // Send SMS Group Details
    {
        path: 'masters/communication/sendSmsGroupDetails',
        component: SendSmsGroupDetailsComponent
    },
    // Message On Cashmemo
    {
        path: 'masters/communication/memoMessage',
        component: MessageMemoComponent
    },
    // Edit Company
    {
        path: 'masters/company/editCompany',
        component: EditCompanyComponent
    },
    // Common Charges
    {
        path: 'masters/company/commonCharges',
        component: CommonChargesComponent
    },
    // Expenses Details Entry
    {
        path: 'masters/company/expensesDetailsEntry',
        component: ExpensesDetailsEntryComponent
    },
    // Consignee Master
    {
        path: 'masters/consignee/consigneeMaster',
        component: ConsigneeMasterComponent
    },
    // C/nee Admin Rate Master
    {
        path: 'masters/consignee/consigneeAdminRateMaster',
        component: ConsigneeAdminRateMasterComponent
    },
    // Consignee Merge
    {
        path: 'masters/consignee/consigneeMerge',
        component: ConsigneeMergeComponent
    },
    // Consignor Master
    {
        path: 'masters/consignor/consignorMaster',
        component: ConsignorMasterComponent
    },
    // Bank
    {
        path: 'masters/fixing/bankMaster',
        component: BankComponent
    },
    // Collection
    {
        path: 'masters/fixing/collectionMan',
        component: CollectionComponent
    },
    // FOV Exemption Master for Booking
    {
        path: 'masters/fixing/fovExemptionMaster',
        component: FovExemptionMasterComponent
    },
    // Supplier Master
    {
        path: 'masters/supplier/suppliermaster',
        component: SupplierMasterComponent
    },
    // Driver Master
    {
        path: 'masters/transhipment/driverMaster',
        component: DriverMasterComponent
    },
    // Truck Master
    {
        path: 'masters/transhipment/truckMaster',
        component: TruckMasterComponent
    },
    // Lorry Hire Deduction Setup
    {
        path: 'masters/transhipment/lorryHireDeductionSetupMaster',
        component: LorryHireDeductionSetupComponent
    },
    // Nature Of Pack
    {
        path: 'masters/transhipment/natureOfPack',
        component: NatureOfPackComponent
    },
    // Expenses Payment Followup
    {
        path: 'masters/transhipment/expensesPaymentFollowupReport',
        component: ExpensesPaymentFollowupReportComponent
    },
    // Tracking Report
    {
        path: 'masters/transhipment/trackingLiveReport',
        component: TrackingLiveReportComponent
    },
    // Point To Point Service Check
    {
        path: 'masters/transhipment/pointToPointServiceCheck',
        component: PointToPointServiceCheckComponent
    },
    // Agent Tempo Master
    {
        path: 'masters/transhipment/agentTempoMaster',
        component: AgentTempoMasterComponent
    },
    // Reward Time Setup
    {
        path: 'masters/trip/time',
        component: TimeComponent
    },
    // Trip ETA Setup
    {
        path: 'masters/trip/tripEtaSetup',
        component: TripEtaComponent
    },
    // About Us
    {
        path: 'mobileApps/aboutus',
        component: AboutUsComponent
    },
    // Latest News
    {
        path: 'mobileApps/latestnews',
        component: LatestNewsComponent
    },
    // Our Branches
    {
        path: 'mobileApps/ourbranches',
        component: OurBranchesComponent
    },
    // Route Mapping
    {
        path: 'mobileApps/routeMapping',
        component: RouteMappingComponent
    },
    // Statement Verification
    {
        path: 'report/agents/statementVerification',
        component: StatementVerificationComponent
    },
    // Ranking
    {
        path: 'report/agents/ranking',
        component: RankingComponent
    },
    // Booking Agent Rpt
    {
        path: 'report/bookings/bookingAgentReport',
        component: BookingAgentReportComponent
    },
    // Agent Expenses
    {
        path: 'report/bookings/agentExpenses',
        component: AgentExpensesComponent
    },
    // Office Hireslip
    {
        path: 'report/bookings/officeHireslip',
        component: OfficeHireslipComponent
    },
    // Agent Statement
    {
        path: 'report/bookings/agentStatement',
        component: AgentStatementComponent
    },
    // Agent TDS On Loading
    {
        path: 'report/bookings/bkgAgentTdsLdg',
        component: TdsLoadingComponent
    },
    // Agent Local Trip
    {
        path: 'report/bookings/agentLocalTrip',
        component: AgentLocalTripComponent
    },
    // Amount Receivable
    {
        path: 'report/cashmemo-report/amountreceivable',
        component: AmountReceivableComponent
    },
    // Paid Report
    {
        path: 'report/cashmemo-report/paidreport',
        component: PaidReportComponent
    },
    // Cash Memo Report
    {
        path: 'report/cashmemo-report/cashmemoreport',
        component: CashMemoReportComponent
    },
    // Booking Cashmemo By Invoice
    {
        path: 'report/cashmemo-report/bookingcashmemobyinvoice',
        component: BookingCashmemoByInvoiceComponent
    },
    // Cashmemo Details
    {
        path: 'report/cashmemo-report/cashmemoDetails',
        component: CashmemoDetailsComponent
    },
    // LetterHead Report
    {
        path: 'report/cashmemo-report/letterHeadReport',
        component: LetterHeadReportComponent
    },
    // Collection Man
    {
        path: 'report/cashmemo-report/collectionManReport',
        component: CollectionManReportComponent
    },
    // SRD Collection Stock Report
    {
        path: 'report/cashmemo-report/srdCollectionStockReport',
        component: SrdCollectionStockReportComponent
    },
    // Memo Less Details
    {
        path: 'report/cashmemo-report/memoLessSearchReport',
        component: MemoLessSearchReportComponent
    },
    // Cash Memo In Collection Man Stock
    {
        path: 'report/cashmemo-report/cashMemoInCollectionManStock',
        component: CashMemoInCollectionManStockComponent
    },
    // Total Less Report
    {
        path: 'report/cashmemo-report/totalLessRpt',
        component: TotalLessComponent
    },
    // Daily Booking
    {
        path: 'report/daily-report/dailyBookingReport',
        component: DailyBookingReportComponent
    },
    // Office Report
    {
        path: 'report/daily-report/officeReport',
        component: OfficeReportComponent
    },
    // Bangalore Office Daily Report
    {
        path: 'report/daily-report/bangaloreOffice',
        component: BangaloreOfficeComponent
    },
    // Debit Note Rpt
    {
        path: 'report/debit/debitNoteReport',
        component: DebitNoteReportComponent
    },
    // Broker Report
    {
        path: 'report/hamali-report/brokerreport',
        component: BrokerReportComponent
    },
    // Labour Statement Report
    {
        path: 'report/hamali-report/labourStatementReport',
        component: LabourStatementReportComponent
    },
    // Hireslip Details
    {
        path: 'report/hireslip-and-invoice-report/hireslipDetails',
        component: HireslipDetailsComponent
    },
    // Invoice Details
    {
        path: 'report/hireslip-and-invoice-report/invoiceDetails',
        component: InvoiceDetailsComponent
    },
    // Advance Payment
    {
        path: 'report/hireslip-and-invoice-report/advancePayment',
        component: AdvancePaymentComponent
    },
    // Hireslip Unloading Report
    {
        path: 'report/hireslip-and-invoice-report/hireslipUnloadingReport',
        component: HireslipUnloadingReportComponent
    },
    // Transshipment Trip
    {
        path: 'report/hireslip-and-invoice-report/transhipmentTrip',
        component: TranshipmentTripComponent
    },
    // Local Trip Sheet
    {
        path: 'report/hireslip-and-invoice-report/localTripSheet',
        component: LocalTripSheetComponent
    },
    // Service Tax
    {
        path: 'report/hireslip-and-invoice-report/serviceTaxReport',
        component: ServiceTaxReportComponent
    },
    // Issue Balance
    {
        path: 'report/lr-report/issueBalance',
        component: IssueBalanceComponent
    },
    // LR Issue Rpt
    {
        path: 'report/lr-report/lrIssueReport',
        component: LrIssueReportComponent
    },
    // Challan Issue
    {
        path: 'report/lr-report/challanIssueReport',
        component: ChallanIssueComponent
    },
    // Short And Extra
    {
        path: 'report/lr-report/shortAndExtraReport',
        component: ShortAndExtraComponent
    },
    // LR Dispatch Booking Rpt
    {
        path: 'report/lr-report/lrDispatchBookingReport',
        component: LrDispatchBookingReportComponent
    },
    // Automated Search
    {
        path: 'report/lr-report/automatedSearch',
        component: AutomatedSearchComponent
    },
    // LR History Rpt
    {
        path: 'report/lr-report/lrHistoryReport',
        component: LrHistoryReportComponent
    },
    // Lorry Hire Statement
    {
        path: 'report/lr-report/lorryHireStatement',
        component: LorryHireStatementComponent
    },
    // Lorry Hire Balance
    {
        path: 'report/lr-report/lorryHireBalance',
        component: LorryHireBalanceComponent
    },
    // Performance
    {
        path: 'report/lr-report/lrPerformanceReport',
        component: LrPerformanceReportComponent
    },
    // LR Report
    {
        path: 'report/lr-report/lrRpt',
        component: LrRptComponent
    },
    // G.C. Not Received
    {
        path: 'report/lr-report/gcNotReceivedReport',
        component: GcNotReceivedReportComponent
    },
    // EWay Bill Date Validation Extension Report
    {
        path: 'report/lr-report/eWayBillDateValidationExtensionRpt',
        component: EWayBillDateValidationExtensionRptComponent
    },
    // LC and BC Report
    {
        path: 'report/others-report/lcAndBcReport',
        component: LcAndBcReportComponent
    },
    // Loader Performance Report
    {
        path: 'report/others-report/loaderPerformanceReport',
        component: LoaderPerformanceReportComponent
    },
    // Live Tracking
    {
        path: 'report/others-report/vehicleTracking',
        component: VehicleTrackingComponent
    },
    // Cheque Search Details
    {
        path: 'report/others-report/chequeSearchReport',
        component: ChequeSearchReportComponent
    },
    // Cheque Details
    {
        path: 'report/others-report/chequeDetailsReport',
        component: ChequeDetailsReportComponent
    },
    // Local Challan Report
    {
        path: 'report/others-report/localChallanReport',
        component: LocalChallanReportComponent
    },
    // Consignor
    {
        path: 'report/party-report/consignorReport',
        component: ConsignorReportComponent
    },
    // Consignee Report
    {
        path: 'report/party-report/consigneeReport',
        component: ConsigneeReportComponent
    },
    // Business Stop Report
    {
        path: 'report/party-report/businessStopReport',
        component: BusinessStopReportComponent
    },
    // Business Start Report
    {
        path: 'report/party-report/businessStartReport',
        component: BusinessStartReportComponent
    },
    // Party Less
    {
        path: 'report/party-report/partyLessReport',
        component: PartyLessReportComponent
    },
    // Party Wise OS
    {
        path: 'report/party-report/partyWiseOsReport',
        component: PartyWiseOsReportComponent
    },
    // Party Wise Total Status Details
    {
        path: 'report/party-report/partyWiseTotalStatusDetails',
        component: PartyWiseTotalStatusDetailsComponent
    },
    // Commodity Mismatch Report
    {
        path: 'report/party-report/commodityMismatchReport',
        component: CommodityMismatchReportComponent
    },
    // C/nee Admin Rate Rpt
    {
        path: 'report/party-report/consigneeAdminRateReport',
        component: ConsigneeAdminRateReportComponent
    },
    // Rate History
    {
        path: 'report/party-report/rateHistoryReport',
        component: RateHistoryReportComponent
    },
    // Admin Business Rate Report
    {
        path: 'report/party-report/adminBusinessRateReport',
        component: AdminBusinessRateReportComponent
    },
    // Rate Report
    {
        path: 'report/party-report/consigneeRateReport',
        component: ConsigneeRateReportComponent
    },
    // Discontinuous Details
    {
        path: 'report/party-report/discontinuousDetailsReport',
        component: DiscontinuousDetailsReportComponent
    },
    // Statement Report
    {
        path: 'report/statement-reports/statementRpt',
        component: StatementRptComponent
    },
    // Party Less Checked And Uncheck LR Details
    {
        path: 'report/statement-reports/partyLessCheckedAndUncheckedLrDetails',
        component: PartyLessCheckedAndUncheckedLrDetailsComponent
    },
    // Stocks In Taken Rpt
    {
        path: 'report/stocks-report/stocksInTakenReport',
        component: StocksInTakenReportComponent
    },
    // Commodity Report
    {
        path: 'report/stocks-report/commodityReport',
        component: CommodityReportComponent
    },
    // Godown Stocks Booking
    {
        path: 'report/stocks-report/godownStocksBookingReport',
        component: GodownStocksBookingReportComponent
    },
    // Godown Stocks
    {
        path: 'report/stocks-report/godownStocksReport',
        component: GodownStocksReportComponent
    },
    // Pending Details
    {
        path: 'report/stocks-report/pendingStocksDetailsReport',
        component: PendingStocksDetailsReportComponent
    },
    // Stocks Unloading Report
    {
        path: 'report/stocks-report/stocksUnloadingReport',
        component: StocksUnloadingReportComponent
    },
    // Storage Report
    {
        path: 'report/stocks-report/storageReport',
        component: StorageReportComponent
    },
    // Booking Agents Dispatch Performance Report
    {
        path: 'report/stocks-report/bookingAgentDispatchPerformanceReport',
        component: BookingAgentDispatchPerformanceReportComponent
    },
    // Performance Report
    {
        path: 'report/truck-report/performanceReport',
        component: PerformanceReportComponent
    },
    // Truck Report
    {
        path: 'report/truck-report/truckReport',
        component: TruckReportComponent
    },
    // Truck Owner Declaration Report
    {
        path: 'report/truck-report/truckOwnerDeclarationReport',
        component: TruckOwnerDeclarationReportComponent
    },
    // Halt And Dispatched Details
    {
        path: 'report/truck-report/haltAndDispatchedDetails',
        component: HaltAndDispatchedDetailsComponent
    },
    // Non Availability Of Truck
    {
        path: 'report/truck-report/nonAvailabilityOfTruckReport',
        component: NonAvailabilityOfTruckReportComponent
    },
    // Manual Stock Check
    {
        path: 'stock/customization/manualStockCheck',
        component: ManualStockCheckComponent
    },

    // Rewarding
    {
        path: 'stock/driver/rewarding',
        component: RewardingComponent
    },

    // HireSlip Balance
    {
        path: 'stock/payment/hireslipBalance',
        component: HireslipBalanceComponent
    },
    // Shipment Status
    {
        path: 'stock/transfer/shipmentstatus',
        component: ShipmentStatusComponent
    },
    // Stocks to CP
    {
        path: 'stock/transfer/stocksToCp',
        component: StocksToCpComponent
    },
    // Stocks Between Godowns
    {
        path: 'stock/transfer/stocksBetweenGodown',
        component: StocksBetweenGodownComponent
    },
    // Stocks For Local Booking
    {
        path: 'stock/transfer/stockForLocalBooking',
        component: StocksForLocalBookingComponent
    },
    // Stocks For Tripsheet
    {
        path: 'stocks-for-tripsheet-page/stocksForTripsheet',
        component: StocksForTripsheetComponent
    },
    // Stocks Grouping
    {
        path: 'trip-creation/stocksGrouping',
        component: StocksGroupingComponent
    },
    // Party Less Check
    {
        path: 'fortnightStatement/others/partyLessCheck',
        component: PartyLessCheckComponent,

    },
    // Short & Extra Consolidate
    {
        path: 'report/lr-report/shortAndExtraConsolidateRpt',
        component: LrShortAndExtraConsolidateRptComponent,

    },
    // Dashboard Popup>> Agent Stock Report
    {
        path: 'dashboard/agentStockReport',
        component: AgentStockReportComponent
    },
    //Invoice Report
    {
        path: 'report/statement-reports/invoiceReport',
        component: InvoiceReportComponent
    },
    //Lr DWS Weight Report
    {
        path: 'report/lr-report/lrDwsWgtRpt',
        component: LrDwsWeightReportComponent
    },// Stocks For Local Booking Barcode
    {
        path: 'stock/transfer/stockForLocalBookingBarcode',
        component: StocksForLocalBookingBarcodeComponent
    },
    // 
    {
        path: 'lr/entry/lrEntryFormBarcode',
        component: LrEntryFormBarcodePrintComponent
    },
    // Send SMS 
    {
        path: 'masters/communication/sendSms',
        component: SendSmsComponent
    },
    // Consignor Rate Master
    {
        path: 'masters/consignor/consignorRateMaster',
        component: ConsignorAdminRateMasterComponent
    },
    //src lr check report
    {
        path: 'report/lr-report/srcLRRateChkRpt',
        component: SrcLrRatecheckRptComponent
    },
    //memo pod upload
    {
        path: 'report/cashmemo-report/cashmemoPodUpload',
        component: CashmemoPodUploadComponent
    },
    {
        path: 'masters/transhipment/vehicleAreaChargeSetup',
        component: VehicleAreaChargeSetupComponent
    }, {
        path: 'masters/transhipment/getAdvanceConsent',
        component: GetAdvanceConsentComponent
    }, {
        path: 'masters/fixing/cflFormulaSetup',
        component: CflFormulaSetupComponent
    }, {
        path: 'masters/company/inchargeMaster',
        component: InchargeMasterComponent
    }, {
        path: 'masters/company/loaderHeadMaster',
        component: LoaderMasterComponent
    }, {
        path: 'masters/fixing/cftExemptionSetup',
        component: CftExemptionMasterComponent
    }, {
        path: 'report/daily-report/cftRangeReport',
        component: CftRangeReportComponent
    }, {
        path: 'cashmemo/paymentFollowupBooking',
        component: PaymentFollowupBookingComponent
    }, {
        path: 'masters/consignor/consignorTopartyMaster',
        component: ConsignorMasterTopartyComponent
    }, {
        path: 'report/cashmemo-report/paidCollectionReport',
        component: BookingPaidCollectionReportComponent
    }, {
        path: 'report/cashmemo-report/bkgPartyOsReport',
        component: BookingPartyOsReportComponent
    }, {
        path: 'report/hireslip-and-invoice-report/hirepaymentEnet',
        component: HirepaymentEnetBankReportComponent
    }, {
        path: 'lr/entry/enablelrcccopy',
        component: LrEntryEnableConsigneecopyComponent
    }, {
        path: 'lr/edit/lrHoldReason',
        component: LrHoldReasonComponent
    }, {
        path: 'masters/consignee/specificConsigneeDetails',
        component: SpecificConsigneeDetailsComponent
    }, {
        path: 'report/agents/destinationCommission',
        component: DestinationCommissionComponent
    }, {
        path: 'report/agents/destinationIncome',
        component: DestinationIncomeComponent
    }, {
        path: 'masters/fixing/areaMaster',
        component: AreaComponent
    }, {
        path: 'masters/consignee/consigneeMobileNoConfirm',
        component: ConsigneeMobilenumberConfirmComponent
    }, {
        path: 'masters/consignor/consignorMobileNoConfirm',
        component: ConsignorMobilenumberConfirmComponent
    }, {
        path: 'masters/company/lrDeletePwdSetup',
        component: LrdeleteMasterpwdSetupComponent
    }, {
        path: 'trip-creation/invoiceLoadingSheet',
        component: InvoiceLoadingSheetComponent
    }, {
        path: 'trip-creation/invoiceLoadingSheetFlow',
        component: InvoiceLoadingSheetFlowComponent
    }, {
        path: 'lr/edit/gstInvoiceDetained',
        component: GstInvoiceDetainedComponent
    }, {
        path: 'eway-bill/gstSearch',
        component: GstSearchComponent
    }, {
        path: 'masters/fixing/lrBkgDateCustomChange',
        component: LrBookingDateCustomChangeComponent
    }, {
        path: 'trip-creation/loadingInvoiceEdit',
        component: LoadingInvoiceEditComponent,
    }, {
        path: 'masters/details/agentSubstationMaster',
        component: AgentSubstationMasterComponent,
    }, {
        path: 'eway-bill/generateNewEwb',
        component: GenerateNewEWBComponent
    }, {
        path: 'lr/edit/editLrFreight',
        component: EditLrFreightComponent
    }, {
        path: 'stock/driver/rewardingNewVersion',
        component: RewardingNewVersionComponent
    }, {
        path: 'masters/company/partywisePerformanceMonitor',
        component: PartywisePerformanceMonitorComponent
    }, {
        path: 'masters/fixing/perKgRateSetup',
        component: PerKgRateSetupComponent
    }, {
        path: 'masters/fixing/commodityKgLimitSetup',
        component: CommodityKgLimitSetupComponent
    }, {
        path: 'masters/commodity/commodityBookingBlock',
        component: CommodityBookingBlockComponent,
    }, {
        path: 'lr/entry/generateBarcode',
        component: GenerateBarcodeComponent,
    }, {
        path: 'trip-creation/localTripLoadingSheetFlow',
        component: LocalTripLoadingSheetFlowComponent
    },
    {
        path: 'dashboard/bookingTripDetailsBarcode',
        component: BookingTripDetailsBarcodeComponent
    }, {
        path: 'masters/fixing/lrBlockedPartywise',
        component: LrBlockedPartyWiseComponent
    }, {
        path: 'report/stocks-report/destinationStocksReport',
        component: DestinationStocksReportComponent
    }, {
        path: 'stock/transfer/unclearedLrDetails',
        component: UnclearedLrDetailsComponent
    },

    {
        path: 'lr/entry/reGenerateBarcode',
        component: ReGenerateBarcodeComponent

    },
    {
        path: 'lr/entry/barcodeLoadingSheetMobile',
        component: BarcodeLoadingSheetMobileComponent

    }, {
        path: 'report/others-report/partyContactDetails',
        component: PartyContactDetailsComponent
    }, {
        path: 'report/lr-report/shortAndExtraOverview',
        component: LrShortAndExtraConsolidateRptV2Component,

    }, {
        path: 'report/cashmemo-report/cashmemoOnlinePaymentDetails',
        component: CashmemoOnlinePaymentDetailsComponent
    }, {
        path: 'lr/enquiry/lrRateCalculator',
        component: LrRateCalculatorComponent
    }, {
        path: 'report/lr-report/estimateEnquiryReport',
        component: EstimateEnquiryReportComponent
    }, {
        path: 'eway-bill/ewbSearch',
        component: EWBSearchComponent
    }, {
        path: 'eway-bill/partBUpdate',
        component: PartBUpdateomponent
    }, {
        path: 'eway-bill/updateTransportedId',
        component: UpdateTransporterIdComponent
    }, {
        path: 'eway-bill/generateConsolidateEWB',
        component: GenerateConsolidateEWbComponent
    }, {
        path: 'eway-bill/cancelEWB',
        component: CancelEWBComponent
    }, {
        path: 'report/others-report/paymentLinkTrxDet',
        component: PaymentLinkTransactionDetailsComponent
    }, {
        path: 'eway-bill/consolidateEWBPrint',
        component: ConsolidateEWBPrintComponent
    }, {
        path: 'report/lr-report/ewbDateExpiryDestinationRpt',
        component: EWBDateExpiryDestinationRptComponent
    }

]

