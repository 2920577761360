import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { DatePipe } from '@angular/common';
import { HireslipDetailsComponent } from 'src/app/report/hireslip-and-invoice-report/hireslip-details/hireslip-details.component';
import { InvoiceDetailsComponent } from 'src/app/report/hireslip-and-invoice-report/invoice-details/invoice-details.component';
import { AdvancePaymentComponent } from 'src/app/report/hireslip-and-invoice-report/advance-payment/advance-payment.component';
import { HireslipUnloadingReportComponent } from 'src/app/report/hireslip-and-invoice-report/hireslip-unloading-report/hireslip-unloading-report.component';
import { TranshipmentTripComponent } from 'src/app/report/hireslip-and-invoice-report/transhipment-trip/transhipment-trip.component';
import { HireslipHistoryReportComponent } from 'src/app/report/hireslip-and-invoice-report/hireslip-history-report/hireslip-history-report.component';
import { LocalTripSheetComponent } from 'src/app/report/hireslip-and-invoice-report/local-trip-sheet/local-trip-sheet.component';
import { MaterialModule } from "src/app/material.module";
import { LocalTripSheetLrDetailsComponent } from 'src/app/report/hireslip-and-invoice-report/local-trip-sheet-lr-details/local-trip-sheet-lr-details.component';
import { HireslipUnloadingSheetDetailsReportComponent } from 'src/app/report/hireslip-and-invoice-report/hireslip-unloading-sheet-details-report/hireslip-unloading-sheet-details-report.component';
import { InvoiceDetailsLRSummaryComponent } from 'src/app/report/hireslip-and-invoice-report/invoice-details-lr-summary/invoice-details-lr-summary.component';
import { ServiceTaxReportComponent } from 'src/app/report/hireslip-and-invoice-report/service-tax-report/service-tax-report.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { ChequeInfoPopupComponent } from 'src/app/report/hireslip-and-invoice-report/cheque-details-popup/cheque-info-popup.component';
import { PdcInfoPopupComponent } from 'src/app/report/hireslip-and-invoice-report/pdc-details-popup/pdc-info-popup.component';
import { LorryHireAdvancePaymentDetailsComponent } from 'src/app/report/hireslip-and-invoice-report/lorry-hire-advance-payment-details/lorry-hire-advance-payment-details.component';
import { TripCreationModule } from 'src/app/trip-creation/trip-creation.module';
import { PaymentVoucherReportPrintComponent } from 'src/app/report/hireslip-and-invoice-report/payment-voucher-rpt-print/payment-voucher-rpt-print.component';
import { HireslipAadvanceTdsVoucherPrintComponent } from 'src/app/report/hireslip-and-invoice-report/hireslip-advance-tds-voucher-print/hireslip-advance-tds-voucher-print.component';
import { HireslipUnloadingSheetDetailsReportPrintComponent } from 'src/app/report/hireslip-and-invoice-report/hireslip-unloading-sheet-details-report-print/hireslip-unloading-sheet-details-report-print.component';
import { InvoiceSummaryPrintComponent } from 'src/app/report/hireslip-and-invoice-report/invoice-summary-print/invoice-summary-print.component';
import { InvoiceDetailsPrintComponent } from 'src/app/report/hireslip-and-invoice-report/invoice-details-print/invoice-details-print.component';
import { InvoiceLrdetailsMainsrcPopupComponent } from 'src/app/report/hireslip-and-invoice-report/invoice-lrdetails-mainsrc-popup/invoice-lrdetails-mainsrc-popup.component';
import { InvoiceDetailsRptPrintComponent } from 'src/app/report/hireslip-and-invoice-report/invoice-details-rpt-print/invoice-details-rpt-print.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { HirepaymentEnetBankReportComponent } from './hirepayment-enet-bank-report/hirepayment-enet-bank-report.component';
import { ShortExtraDetailsPopupComponent } from './short-extra-details-popup/short-extra-details-popup.component';

@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild(HireslipAndInvoiceRoutes), 
        FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule, CashmemoReportModule, TripCreationModule, NgxBarcode6Module],
    declarations: [
        HireslipDetailsComponent,
        InvoiceDetailsComponent,
        AdvancePaymentComponent,
        HireslipUnloadingReportComponent,
        TranshipmentTripComponent,
        HireslipHistoryReportComponent,
        LocalTripSheetComponent,
        LocalTripSheetLrDetailsComponent,
        HireslipUnloadingSheetDetailsReportComponent,
        InvoiceDetailsLRSummaryComponent,
        ServiceTaxReportComponent,
        ChequeInfoPopupComponent,
        PdcInfoPopupComponent,
        LorryHireAdvancePaymentDetailsComponent,
        PaymentVoucherReportPrintComponent,
        HireslipAadvanceTdsVoucherPrintComponent,
        HireslipUnloadingSheetDetailsReportPrintComponent,
        InvoiceSummaryPrintComponent,
        InvoiceDetailsPrintComponent,
        InvoiceLrdetailsMainsrcPopupComponent,
        InvoiceDetailsRptPrintComponent,
        HirepaymentEnetBankReportComponent,
        ShortExtraDetailsPopupComponent],
    providers: [
        DatePipe
    ],
    entryComponents: [ChequeInfoPopupComponent, PdcInfoPopupComponent, LorryHireAdvancePaymentDetailsComponent,
        ShortExtraDetailsPopupComponent
    ]
})
export class HireslipAndInvoiceModule { }