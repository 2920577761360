//CHG-V-LREdit:01 - @Asrar chg, Self LR block from all src to chennai @as per Musaddik bh req @31-10-2022
//CHG-V-LREdit:02 - @Asrar chg, bkg date edit block for delhi & bangalore booking @as per Musaddik bh req @14-03-2023
//CHG-V-LREdit:03 - @Asrar chg, C.C.Attach option hide to all except Delhi @12/04/2023
//CHG-V-LREdit:04 - @Asrar chg, remove validate bkg date for all except Jamnagar @as per Musaddik bh req @30-05-2023
//CHG-V-LREdit:05- @Asrar chg, for TDM set specific fov @27/08/2023
//CHG-V-LREdit:06- @Asrar Jr chg, Mohit Issue. Multiple eway bill option like LR Entry @05/06/2024
//CHG-V-LREdit:07- @Asrar Jr chg, Comments Add New option for both Consignor & Consignee and Save Consignor & Consignee Details through GST Api response @05/06/2024
//CHG-V-LREdit:08- @Asrar Jr chg, Passing mode for get consignor details without mainStation on param  @05/06/2024
//CHG-V-LREdit:09 - @Asrar Jr Chg, Validation on search based on selected type @30/08/2024
//CHG-V-LREdit:10 - @Asrar Jr Chg, Add parameter on existing services for open Aadhar OTP popup as per discuss @29/08/2024
//CHG-V-LREdit:11 - @Asrar Jr Chg, Not Gst no validation based on select type @29/08/2024
//CHG-V-LREdit:12 - @Asrar Jr Chg, Validation GST No @30/08/2024
//CHG-V-LREdit:13 - @Asrar Jr Chg, Remove space on enter aadhar no for get details (03/09/2024)
//CHG-V-LREdit:14 - @Asrar Jr Chg, Validation on search based on selected type (04/09/2024)
//CHG-V-LREdit:15 - @Asrar Jr Chg, Remove Add new on Pan and Aadhar Api response data and show Add New popup on Aadhar if not in database as per discuss (06/09/2024)
//CHG-V-LREdit:16 - @Asrar Chg, Added for blocking commodity if blocked by admin (05/09/2024)
//CHG-V-LREdit:17 - @Asrar Chg, Change lable name on Add New option popup and validate entered aadhar no (09/09/2024)
//CHG-V-LREdit:18 - @Asrar Chg, Edit block freight details only enable for User Rights (10/09/2024)
//CHG-V-LREdit:19 - @Asrar Chg, Enable Pan Card option to save party details from API Service as per discuss on (13/09/2024)
//CHG-V-LREdit:20 - @Asrar Jr Chg, Save aadhar details from Add New option add type to show on lrPrint (17/09/2024)
//CHG-V-LREdit:21 - @Asrar Jr Chg, Check valid E-Way bill no on Update lrDetails as per discuss (20/09/2024)
//CHG-V-LREdit:22 - @Asrar Jr Chg, Call PAN Api service on Add New option return data show on popup user can modify details as per discuss on (24/09/2024)
//CHG-V-LREdit:23 - @Asrar Jr Chg, Change Rate details like same as LR-Entry-Form Screen (09/10/2024)
//CHG-V-LREdit:24 - @Asrar Jr Chg, DD Amount Exemption if IsDDAmtBlock is false on Godowntable (01/11/2024)

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { LRDto } from 'src/app/dto/LR-dto';
import { Subject, merge } from 'rxjs';
import { Observable } from 'rxjs';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent, NgbDateStruct, NgbModal, ModalDismissReasons, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import swal from 'sweetalert';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from '@angular/router';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { ReportService } from 'src/app/dataService/report-service';
const my = new Date();
import *  as moment from 'moment';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { MultipleArticleSizeComponent } from "src/app/lr/edit/multiple-article-size/multiple-article-size.component";
import { MatDialog } from '@angular/material/dialog';
import { MultiplePackNatureComponent } from "src/app/lr/edit/multiple-pack-nature/multiple-pack-nature.component";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { RequestDto } from 'src/app/dto/Request-dto';
import { MasterService } from 'src/app/dataService/master-service';
import { ResponseDto } from 'src/app/dto/Response-dto';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { DatePipe } from "@angular/common";
@Component({
  selector: 'app-lr-edit',
  templateUrl: './lr-edit.component.html',
  styleUrls: ['./lr-edit.component.css']
})
export class LrEditComponent implements OnInit {

  isLoggedIn = true;
  userDataDtoReturnSession: any;
  showSpinnerForAction = false;
  model: NgbDateStruct;
  //For Destination
  destinationOptions: LRDto[];
  lrDtoDestination: LRDto = new LRDto();
  public modelDestination: any;
  destinationTA: Array<LRDto> = [];
  focusDestinationTA$ = new Subject<string>();
  searchDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusDestinationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.destinationTA
        : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterDestination = (x: { destination: string }) => x.destination;

  agentNameOptions: AgentDetailsDto[];
  agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
  public modelAgentName: any;
  agentNameTA: Array<AgentDetailsDto> = [];
  focusAgentNameTA$ = new Subject<string>();
  searchAgentName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusAgentNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.agentNameTA
        : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterAgentName = (x: { subStation: string }) => x.subStation;

  commodityDescriptionOptions: RateMasterDto[];
  rateMasterDtoForCommodityDescription: RateMasterDto = new RateMasterDto();
  public modelCommodityDescription: any;
  commodityDescriptionTA: Array<RateMasterDto> = [];
  focusCommodityDescriptionTA$ = new Subject<string>();
  searchCommodityDescription = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusCommodityDescriptionTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.commodityDescriptionTA
        : this.commodityDescriptionTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterCommodityDescription = (x: { commodityName: string }) => x.commodityName;

  consignorNameOptions: PartyMasterDto[];
  partyMasterDtoForConsignorName: PartyMasterDto = new PartyMasterDto();
  partyMasterDtoForConsignorNameAddNew: PartyMasterDto = new PartyMasterDto();
  public modelConsignorName: any;
  consignorNameTA: Array<PartyMasterDto> = [];
  focusConsignorNameTA$ = new Subject<string>();
  searchConsignorName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsignorNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consignorNameTA
        : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsignorName = (x: { consignorName: string }) => x.consignorName;

  consigneeNameOptions: PartyMasterDto[];
  partyMasterDtoForConsigneeeName: PartyMasterDto = new PartyMasterDto();
  partyMasterDtoForConsigneeeNameAddNew: PartyMasterDto = new PartyMasterDto();
  public modelConsigneeName: any;
  consigneeNameTA: Array<PartyMasterDto> = [];
  focusConsigneeNameTA$ = new Subject<string>();
  searchConsigneeName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsigneeNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consigneeNameTA
        : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

  // partyGoodsTypeOptions: PartyMasterDto[];
  // partyMasterDtoForPartyGoodsType: PartyMasterDto = new PartyMasterDto();
  // public modelPartyGoodsType: any;
  // partyGoodsTypeTA: Array<PartyMasterDto> = [];
  // focusPartyGoodsTypeTA$ = new Subject<string>();
  // searchPartyGoodsType = (text$: Observable<string>) => {
  //   const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
  //   const inputFocus$ = this.focusPartyGoodsTypeTA$;

  //   return merge(debouncedText$, inputFocus$).pipe(
  //     /* map(term => (term === '' ? this.partyGoodsTypeTA
  //        : this.partyGoodsTypeTA.filter(v => v.multipleCommodity.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
  //    */
  //     map(term => (term === '' ? this.partyGoodsTypeTA
  //       : this.partyGoodsTypeTA.filter(v => v.multipleCommodity.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
  //   );
  // }
  // formatterPartyGoodsType = (x: { multipleCommodity: string }) => x.multipleCommodity;

  @ViewChild('instancePartyCommodity') instancePartyCommodity: NgbTypeahead;
  partyCommodityTA: Array<any> = [];
  focusPartyCommodityTA$ = new Subject<string>();
  partyCommoditySearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusPartyCommodityTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.partyCommodityTA
        : this.partyCommodityTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  partyMasterDtoForConsigneeState: PartyMasterDto = new PartyMasterDto();
  partyMasterDtoOptionsConsigneeState: PartyMasterDto[];
  public modelConsigneeState: any;
  consigneeStateTA: Array<PartyMasterDto> = [];
  focusConsigneeStateTA$ = new Subject<string>();
  searchConsigneeState = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsigneeStateTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consigneeStateTA
        : this.consigneeStateTA.filter(v => v.state.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsigneeState = (x: { state: string }) => x.state;

  partyMasterDtoForConsignorState: PartyMasterDto = new PartyMasterDto();
  partyMasterDtoOptionsConsignorState: PartyMasterDto[];
  public modelConsignorState: any;
  consignorStateTA: Array<PartyMasterDto> = [];
  focusConsignorStateTA$ = new Subject<string>();
  searchConsignorState = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsignorStateTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consignorStateTA
        : this.consignorStateTA.filter(v => v.state.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsignorState = (x: { state: string }) => x.state;



  doorDeliveryYes = false;
  paymentTypePaid = false;
  viewCODChargeAmtField = false;
  //btn
  viewAutoLRPrintBtn = false;

  // 
  lrNumberForSave: any;
  destinationForSave: any;
  agentNameForSave: any;
  partyGoodsTypeForSave: any;
  bookingDateForSave: any;
  articlesForSave: any;
  commodityDescForSave: any;
  actualWgtForSave: any;
  chargedWgtForSave: any;
  packNatureForSave: any;
  goodsValueForSave: any;
  privateMarkForSave: any;
  articleSize: any;
  consignorGstNumberForSave: any;
  consignorNameForSave: any;
  consigneeGstNumberForSave: any;
  consigneeNameForSave: any;
  baseFreightForSave: any;
  hamaliForSave: any;
  gcChargeForSave: any;
  lcChgForSave: any;
  bcChgForSave: any;
  fovChgForSave: any;
  aocChgForSave: any;
  otherChgForSave: any;
  doorDeliveryForSave: any;
  doorDeliveryAddressForSave: any;
  doorDeliveryAmountForSave: any;
  totalAmountForSave: any;
  billNoForSave: any;
  billDateForSave: any;
  paymentTypeForSave: any;
  bankLrForSave: any;
  esugamNoForSave: any;
  ewayBillNoForSave: any;
  ewayBillValidUpto: any;
  articleChargeWgtForSave: any;
  packNatureLabelForSave: any;
  remarkForSave: any;
  checkBoxAOCForSave: any;
  checkBoxToBeBilledForSave: any;
  checkBoxPickUpLrForSave: any;
  checkBoxUnloadedByConsigneeForSave: any;
  checkBoxHandleWithCareForSave: any;
  checkBoxGatePassNoForSave: any;
  checkBoxCCAttachForSave: any;
  checkBoxSelfLrForSave: any;
  checkBoxCODForSave: any;
  codChargeAmountForSave: any;
  checkBoxOrderGoodForSave: any;
  reasonForDeleteForSave: any;

  //search 
  validateLRNumber: any;
  validateConsigneeGstNo: any;
  validateConsignorGstNo: any;
  validateDestination: any;

  validateCommodityCpCode: any;
  partyGoodsType: any;
  goodsType: any;
  newCommodity: any;
  lrNo: any;

  // new consigneee
  partyDtoForNewConsignee: PartyMasterDto = new PartyMasterDto();
  partyDtoForNewConsigneeSetDropDown: PartyMasterDto = new PartyMasterDto();
  partyDtoForNewConsigneeSet: any;
  enteredNewConsigneeName: any;
  enteredNewConsigneeGSTNo: any;
  enteredNewConsigneeAddress: any;
  enteredNewConsigneeState: any;
  enteredNewConsigneeStateCode: any;
  selectedNewConsigneeName: any;

  // new consignor
  /**/
  partyDtoForNewConsignor: PartyMasterDto = new PartyMasterDto();
  partyDtoForNewConsignorSetDropDown: PartyMasterDto = new PartyMasterDto();
  partyDtoForNewConsignorSet: any;
  enteredNewConsignorName: any;
  enteredNewConsignorGSTNo: any;
  enteredNewConsignorAddress: any;
  enteredNewConsignorState: any;
  enteredNewConsignorStateCode: any;
  enteredNewConsignorMobileNo: any;
  enteredNewConsignorOfficeNo: any;
  selectedNewConsignorName: any;

  selectedConsigneeId: any;
  selectedConsignorId: any;

  modelBookingDate: any;
  modelEwayBillValidUpto: any;
  modelBillDate: any;

  validateBaseFreightAmt: any;
  validateHamaliAmt: any;
  validateGCChargeAmt: any;
  validateLCChargeAmt: any;
  validateBCChargeAmt: any;
  validateFOVAmt: any;
  validateAOCAmt: any;
  validateOthersAmt: any;
  validateDDAmt: any;
  validateCODAmt: any;
  validateTotalAmt: any;
  validateGoodsValueAmt: any;
  validateRiskPerc: any;
  validateRiskChgAmt: any;

  isUpdateAllow: boolean = false;


  lrDtoForGetUserValues: LRDto = new LRDto();
  lrDtoForGetLRNumberDetails: LRDto = new LRDto();
  lrDtoForGetLRNumberDetailsReturn: any;
  partyMasterDtoForConsignee: PartyMasterDto = new PartyMasterDto();

  lrDtoForSaveReturn: LRDto = new LRDto();
  lrDtoForSaveResponseReturn: any;

  lrDtoForDelete: LRDto = new LRDto();
  deleteLRNumber: any;
  deleteReason: any;

  //rights
  lrdr: any;
  isChallanNoEdit: boolean = false;
  isAutoLrReset: boolean = false;
  lrEditPartyTemporary: boolean = false;
  lrEditEwayBill: boolean = false;

  viewDeleteBtn: boolean = false;

  /* here start date on 09062021 */

  viewAddArticleSize = 'Add New';
  viewAddMultiPackNature = 'Add New';

  multiArtSizeList: any;
  returnListOfMultiArtSize: any;

  multiPackNatureList: any;
  returnListOfMultiPackNature: any;

  validateForDestinationValue: any;

  rateMasterDto: RateMasterDto = new RateMasterDto();
  rateMasterDtoOptions: RateMasterDto[];
  rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
  public modelCommodityList: any;
  commodityListTA: Array<RateMasterDto> = [];
  focusCommodityListTA$ = new Subject<string>();
  searchCommodityList = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusCommodityListTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.commodityListTA
        : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

  viewAddNewGoodsTypeField: boolean = false;

  lrDtoAutoLrPrint: LRDto = new LRDto();
  getvalueForLrPrint: any;
  setValueForLrPrint: LRDto = new LRDto();
  viewLrEntryPrint = false;
  automatedSearch = true;
  pageId = "lrec";
  isDestValid = false;
  selectedDestination: any;
  partyDtoForConsignee: PartyMasterDto = new PartyMasterDto();
  selectedAgentName: any;
  bangaloreConsigneeCommdityOptions: any;
  stateByDest: any;
  isMainCommodityValid = false;
  selectedPartyCommodity: any;
  selectedOffice: any;
  blockEditBkgDate: boolean = true;
  showCCAttacheOption = false;
  chgWgtReadOnlyEdit: boolean = false;
  closeResult: string;
  deleteReasonSelect: string = null;
  lrDeleteTechReason: boolean = false;
  lrDeleteReturnBack: boolean = false;
  lrDeleteMasterPwdReq: boolean = false;
  lrDeleteConsentReq: boolean = false;
  requestDto: RequestDto = new RequestDto();
  passwordEntered: any;
  validPassword: boolean = false;
  selectedConsentCopyForUpload: any;
  showSpinnerForActionForUpload = false;
  isConsentFileUploaded: boolean = false;
  consigneeMobileNumber: any;
  validOtp: boolean = false;
  enteredOtp: any;
  consentUploadedFilename: string = null;
  viewAddMultipleEwaybill = 'Add Multiple E-way Bill';
  dtOptionsMultiEway: any;
  dtTriggerMultEway: Subject<any> = new Subject();
  multiEwayBillList: any;
  ewayBillNoList: any;
  ewayBillValidDateList: any;
  multiEwayTableDataList: Array<any> = [];
  editSelected = false;
  editTableId: null;
  enteredMultiEwayBillNo: any;
  private newAttributeMultiEway: any = {};
  eWayBillValidUptoDateMultiModel: any;

  lrtDtoRespForEwayApi: LRDto = new LRDto();
  responseDtoForEwayApi: ResponseDto = new ResponseDto();
  responseDtoForGstApi: ResponseDto = new ResponseDto();
  lrtDtoRespForGstNo: any;
  swalTextMessage: any;
  enteredGstNumber: any;
  enteredGstNumberConsignor: any;
  enteredGstNumberConsignee: any;
  text: any = "Enter Consignee GST Number";
  enteredPanNumberSearch: any;
  panValidation = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
  enteredAadharNumberSearch: any;
  requestDtoForAadhar: RequestDto = new RequestDto();
  requestDtoAadharRes: RequestDto = new RequestDto();
  aadharDetails: any;
  enteredOtpRes: any;
  gstValidation = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  enteredGstNumberSearch: any;
  selectedSearchBy: any;
  consignorTextField: any = "Enter Consignor GST Number";
  selectedSearchByConsignor: any;
  partyType: any;
  isCommodityBlockedBkg: Boolean = false;
  dtoBlockedcmdty: RateMasterDto = new RateMasterDto();
  dtoBlockedCmdtyRtn: ResponseDto = new ResponseDto();
  isFrieghtFieldsReadOnly: boolean = true;
  eWayBillNoForLrEdit: any;
  consignorPopupLabel: any = "Aadhar Number";
  newConsignorName: any;
  gstNoConsignorModel: any;
  consigneePopupLabel: any = "AAdhar Number";
  gstNoConsigneeModel: any;
  newConsigneeName: any;
  showSpinnerForPopup = false;
  requestDtoGetPan: RequestDto = new RequestDto();
  panCardDetails: any;
  rateMasterDtoConsignor: RateMasterDto = new RateMasterDto();
  enteredActualWeight: any;
  enteredChargeWeight: any;
  enteredSource: any;
  totArticles: any;
  commaSplitDest: Array<any> = [];
  destSplit: any;
  enteredBookingDate: any;
  rateMasterDtoConsignorResp: any;
  selectedDoorDelivery: any;
  consignorRateFlow = false;
  destMemoSizeExceedRights: boolean = false;
  rateMasterDtoGet: RateMasterDto = new RateMasterDto();
  commodityKglimit: any;
  isSizeCommodityExceed: boolean = false;
  commodityKgLimitData: LRDto = new LRDto();
  baseFreight: any;
  lrDtoPerKgGet: LRDto = new LRDto();
  lrtDtoPerKgRateReturn: LRDto = new LRDto();
  isPerKgLimitTaken: boolean = false;

  consignorRateFlowSet = false;
  consignorRateId = 0;
  consignorRateFov: any;

  constructor(private router: Router, private masterReadService: MasterReadService, private reportService: ReportService,
    private modalService: NgbModal, private lrService: LrService, public dialog: MatDialog,
    private hireslipService: HireslipService, public changeDetectorRef: ChangeDetectorRef, private masterService: MasterService, private ewaybillService: EwaybillService,
    private datePipe: DatePipe) {

    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

      //CHG-V-LREdit:02
      console.log('MainAdminStation');
      console.log(this.userDataDtoReturnSession.mainAdminStation);
      if (this.userDataDtoReturnSession.mainAdminStation != null) {
        let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
        console.log(MainAdminStation);

        //CHG-V-LREdit:04
        // if (MainAdminStation == 'delhi' || MainAdminStation == 'bangalore bkg head ofc') {
        //   this.blockEditBkgDate = true;
        // }
        //CHG-V-LREdit:03
        if (MainAdminStation != null
          && (MainAdminStation == 'delhi')) {
          this.showCCAttacheOption = true;
        } else {
          this.showCCAttacheOption = false;
        }
      }
      console.log(this.blockEditBkgDate);

      this.viewDeleteBtn = false;
      if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
        for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LRDR1") {
            this.lrdr = 1;
            this.viewDeleteBtn = true;
          } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LRDR2") {
            this.lrdr = 2;
            this.viewDeleteBtn = true;
          } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LRDR3") {
            this.lrdr = 3;
            this.viewDeleteBtn = true;
          } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LRDR4") {
            this.lrdr = 4;
            this.viewDeleteBtn = true;
          } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LRDR5") {
            this.lrdr = 5;
            this.viewDeleteBtn = true;
          } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LRDR6") {
            this.lrdr = 6;
            this.viewDeleteBtn = true;
          } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LRDR7") {
            this.lrdr = 7;
          } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LR Edit Challan No") {
            this.isChallanNoEdit = true;
          } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LR Edit Reset AutoLR") {
            this.isAutoLrReset = true;
          } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LREditPartyTemp") {
            this.lrEditPartyTemporary = true;
          } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Enable LREdit Ewaybill") {
            this.lrEditEwayBill = true;
          }
          //CHG-V-LREdit:18
          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LREdit EnableFreightEdit") {
            this.isFrieghtFieldsReadOnly = false;
          }
        }
      }

      this.lrDeleteMasterPwdReq = this.userDataDtoReturnSession.lrDeleteMasterPwdReq;
      console.log('lrDeleteMasterPwdReq : ' + this.lrDeleteMasterPwdReq);
      this.lrDeleteConsentReq = this.userDataDtoReturnSession.lrDeleteConsentReq;
      console.log('lrDeleteConsentReq : ' + this.lrDeleteConsentReq);

      this.getDestinationDetails();
      this.getAgentDetailList();
      this.setCommodityDetailList();
      this.getStatesMethod();
      this.getCommodityMasterDetailsList();
      this.multiEwayDetailsTable();

      //CHG-V-LREdit:23
      this.consignorRateFlow = this.userDataDtoReturnSession.isConsignorRateFlow;
      //CHG-V-LREdit:23
      if (this.userDataDtoReturnSession.destMemoSizeExceeded) {
        this.destMemoSizeExceedRights = true;
      }
    }
  }

  ngOnInit() {

  }

  logInPage() {
    this.router.navigate(['/authentication/login']);
  }

  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
  }

  /* Drop Down Listner Start Here */

  clickListnerForDestination(e: NgbTypeaheadSelectItemEvent) {
    this.modelDestination = e.item;
    //console.log(this.modelDestination);
    $("#" + this.pageId + "destination").val(this.modelDestination.destination);
    if ($("#" + this.pageId + "destination").val() != null && $("#" + this.pageId + "destination").val() != undefined &&
      $("#" + this.pageId + "destination").val() != "") {
      $("#" + this.pageId + "consignorGstNumber").val('');
      $("#" + this.pageId + "consigneeGstNumber").val('');
      $("#" + this.pageId + "consignorName").val('');
      $("#" + this.pageId + "consigneeName").val('');
      $("#" + this.pageId + "consigneeMobileNo").val('');
    }
  }

  clickListnerForAgentName(e: NgbTypeaheadSelectItemEvent) {
    this.modelAgentName = e.item;
    //console.log(this.modelAgentName);
    $("#" + this.pageId + "agentName").val(this.modelAgentName.subStation);
  }

  clickListnerForCommodityDescription(e: NgbTypeaheadSelectItemEvent) {
    this.modelCommodityDescription = e.item;
    //console.log(this.modelCommodityDescription);
    $("#" + this.pageId + "commodityDescription").val(this.modelCommodityDescription.commodityName);
    this.validateCommodityCpCode = null;
    this.validateCommodityCpCode = this.modelCommodityDescription.commodityCode;
  }

  clickListnerForConsigneeName(e: NgbTypeaheadSelectItemEvent, content10) {
    this.modelConsigneeName = e.item;
    //console.log(this.modelConsigneeName);
    console.log('src block');
    console.log(this.modelConsigneeName.srcAlertMessage);
    if (this.modelConsigneeName.isSrcBlock) {
      swal("No Allowed", this.modelConsigneeName.srcAlertMessage, "warning");
      window.setTimeout(function () {
        this.selectedConsigneeName = null;
        $("#lrecconsigneeName").val('');
      }, 100);
    } else {
      $("#" + this.pageId + "consigneeName").val(this.modelConsigneeName.consigneeName);
      this.selectedConsigneeId = null;
      if ($("#" + this.pageId + "consigneeName").val() == "Add New") {
        this.modalService.open(content10, { centered: true });
        //CHG-V-LREdit:22
        if ($("#" + this.pageId + "searchBy").val() == "pan") {
          this.consigneePopupLabel = "Pan Number";
          this.validatePanNoFieldForGetDetails();
        } else {
          this.consigneePopupLabel = "Aadhar Number";
        }
        //CHG-V-LREdit:22
        // $("#" + this.pageId + "gstNoConsignee").val($("#" + this.pageId + "consigneeGstNumber").val());
        this.gstNoConsigneeModel = ($("#" + this.pageId + "consigneeGstNumber").val().toString().trim().replace(/\s+/g, ''));

        $("#" + this.pageId + "newConsignee").focus();
      } else {
        this.selectedConsigneeId = this.modelConsigneeName.consigneeId;

        //CHG-V-LREdit:23
        if (this.consignorRateFlow) {
          this.getConsignorRateDetails();
        }
      }
    }
  }

  clickListnerForConsignorName(e: NgbTypeaheadSelectItemEvent, content9) {
    this.modelConsignorName = e.item;
    //console.log(this.modelConsignorName);
    $("#" + this.pageId + "consignorName").val(this.modelConsignorName.consignorName);
    this.selectedConsignorId = null;
    if ($("#" + this.pageId + "consignorName").val() == "Add New") {
      this.modalService.open(content9, { centered: true });
      //CHG-V-LREdit:22
      if ($("#" + this.pageId + "selectType").val() == "pan") {
        this.consignorPopupLabel = "Pan Number";
        this.validatePanNoFieldForGetDetails();
      } else {
        this.consignorPopupLabel = "Aadhar Number";
      }
      //CHG-V-LREdit:22
      // $("#" + this.pageId + "gstNoConsignor").val($("#" + this.pageId + "consignorGstNumber").val());
      this.gstNoConsignorModel = ($("#" + this.pageId + "consignorGstNumber").val().toString().trim().replace(/\s+/g, ''));

      $("#" + this.pageId + "newConsignor").focus();
    } else {
      this.selectedConsignorId = this.modelConsignorName.consignorId;

      //CHG-V-LREdit:23
      if (this.consignorRateFlow) {
        this.getConsignorRateDetails();
      }
    }
  }

  // clickListnerForPartyGoodsType(e: NgbTypeaheadSelectItemEvent) {
  //   this.modelPartyGoodsType = e.item;
  //   //console.log(this.modelConsignorName);
  //   $("#" + this.pageId + "partyGoodsType").val(this.modelPartyGoodsType.multipleCommodity);

  //   if ($("#" + this.pageId + "partyGoodsType").val() != null && $("#" + this.pageId + "partyGoodsType").val() != undefined &&
  //     $("#" + this.pageId + "partyGoodsType").val() != "" && $("#" + this.pageId + "partyGoodsType").val() == "Add New") {
  //     this.viewAddNewGoodsTypeField = true;
  //     this.modelCommodityList = null;
  //   } else {
  //     this.viewAddNewGoodsTypeField = false;
  //     this.modelCommodityList = null;
  //   }
  // }

  clickListnerForConsigneeState(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelConsigneeState = e.item;
    console.log(this.modelConsigneeState);
    $("#" + this.pageId + "consigneeState").val(this.modelConsigneeState.state);
    this.enteredNewConsigneeStateCode = null;
    this.enteredNewConsigneeStateCode = this.modelConsigneeState.stateCode;
  }

  clickListnerForConsignorState(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelConsignorState = e.item;
    console.log(this.modelConsignorState);
    $("#" + this.pageId + "consigneeState").val(this.modelConsignorState.state);
    this.enteredNewConsignorStateCode = null;
    this.enteredNewConsignorStateCode = this.modelConsignorState.stateCode;
    $("#" + this.pageId + "consignorMobileNumber").focus();
  }

  /* Drop Down Listner Start End */

  /* Key Press Listner Start Here */

  //CHG-V-LREdit:10
  onkeyPressListnerGSTConsignee(event, openPopupForAadhar) {
    if (event.charCode == 13) {
      if ($("#" + this.pageId + "consigneeGstNumber").val() == null || $("#" + this.pageId + "consigneeGstNumber").val() == undefined ||
        $("#" + this.pageId + "consigneeGstNumber").val() == "") {
        swal("Mandatory Field", "Please enter Consignee Gst Number", "warning");
        return false;
      } else {
        this.calculateRiskCharge();
        this.sumOfSubTotal();

        //CHG-V-LREdit:09
        //CHG-V-LREdit:10
        // this.setConsigneeNameByTinGst(openPopupForAadhar);
        this.partyType = 'consignee';
        this.validationGetConsigneeDetailsBasedOnSelectType(openPopupForAadhar);
      }
    }
  }

  //CHG-V-LREdit:10
  onkeyPressListnerGSTConsignor(event, openPopupForAadhar) {
    if (event.charCode == 13) {
      if ($("#" + this.pageId + "consignorGstNumber").val() == null || $("#" + this.pageId + "consignorGstNumber").val() == undefined ||
        $("#" + this.pageId + "consignorGstNumber").val() == "") {
        swal("Mandatory Field", "Please enter Consignor Gst Number", "warning");
        return false;
      } else {
        this.partyType = 'consignor';
        //CHG-V-LREdit:10
        //CHG-V-LREdit:14
        // this.setConsignorDetailsList(openPopupForAadhar);
        this.validationGetConsignorDetailsBasedOnSelectType(openPopupForAadhar);
      }
    }
  }
  onkeyPressListnerLRNumber(event) {
    if (event.charCode == 13) {
      if ($("#" + this.pageId + "editWithLrNumber").val() == null || $("#" + this.pageId + "editWithLrNumber").val() == undefined ||
        $("#" + this.pageId + "editWithLrNumber").val() == "") {
        swal("Mandatory Field", "Please enter LR Number", "warning");
        return false;
      } else {
        this.getLrEnquiryDetails();
      }
    }
  }

  onKeyPressListnerNewConsigneeName(event) {
    if (event.charCode == 13) {
      $("#" + this.pageId + "gstNoConsignee").focus();
    }
  }

  onKeyPressListnerNewConsigneeGSTNo(event) {
    if (event.charCode == 13) {
      //CHG-V-LREdit:17
      // $("#" + this.pageId + "newConsigneeAddress").focus();

      //CHG-V-LREdit:17
      let enteredConsigneeAadharNo = $("#" + this.pageId + "gstNoConsignee").val().toString().trim().replace(/\s+/g, '');
      if (enteredConsigneeAadharNo.length !== 12 || !/^[2-9][0-9]{11}$/.test(enteredConsigneeAadharNo)) {
        swal("Invalid", "Invalid Aadhar Number", "warning");
      } else {
        $("#" + this.pageId + "newConsigneeAddress").focus();
      }
    }
  }

  onKeyPressListnerNewConsigneeAddress(event) {
    if (event.charCode == 13) {
      $("#" + this.pageId + "consigneeState").focus();
    }
  }
  /* consignor on key press */

  onKeyPressListnerForNewConsignorName(event) {
    if (event.charCode == 13) {
      $("#" + this.pageId + "gstNoConsignor").focus();
    }
  }
  onKeyPressListnerForNewConsignorGSTNo(event) {
    if (event.charCode == 13) {
      //CHG-V-LREdit:17
      // $("#" + this.pageId + "newConsignorAddress").focus();

      //CHG-V-LREdit:17
      let enteredConsignorAadharNo = $("#" + this.pageId + "gstNoConsignor").val().toString().trim().replace(/\s+/g, '');
      if (enteredConsignorAadharNo.length !== 12 || !/^[2-9][0-9]{11}$/.test(enteredConsignorAadharNo)) {
        swal("Invalid", "Invalid Aadhar Number", "warning");
      } else {
        $("#" + this.pageId + "newConsignorAddress").focus();
      }
    }
  }
  onKeyPressListnerForNewConsignorAddress(event) {
    if (event.charCode == 13) {
      $("#" + this.pageId + "consignorState").focus();
    }
  }
  onKeyPressListnerForNewConsignorMobileNo(event) {
    if (event.charCode == 13) {
      $("#" + this.pageId + "consignorOfficeNumber").focus();
    }
  }

  onKeyPressListnerForNewConsignorOfficeNo(event) {
    if (event.charCode == 13) {
      $("#" + this.pageId + "newConsignorSaveBtn").focus();
    }
  }
  /* end Consignor */

  /* Main Key Press */

  onKeyPressListnerForDestination(event) {
    if (event.charCode == 13) { $("#" + this.pageId + "agentName").focus(); }
  }

  onKeyPressListnerForAgentName(event) {
    if (event.charCode == 13) { $("#" + this.pageId + "partyGoodsType").focus(); }
  }

  onKeyPressListnerForPartyGoodsType(event) {
    if (event.charCode == 13) {
      $("#" + this.pageId + "bookingDates").focus();
      //CHG-V-LREdit:23
      if (this.consignorRateFlow) {
        this.getConsignorRateDetails();
      }
    }
  }

  onKeyPressListnerForArticle(event) {
    if (event.charCode == 13) {
      $("#" + this.pageId + "commodityDescription").focus();
      //CHG-V-LREdit:23
      if (this.consignorRateFlow) {
        this.getConsignorRateDetails();
      }
    }
  }
  onKeyPressListnerForCommodityDesc(event) {
    if (event.charCode == 13) { $("#" + this.pageId + "actualWeight").focus(); }
  }

  onKeyPressListnerForActualWgt(event) {
    if (event.charCode == 13) {
      $("#" + this.pageId + "chargedWeight").focus();
      //CHG-V-LREdit:23
      if (this.consignorRateFlow) {
        this.getConsignorRateDetails();
      }
    }
  }

  onKeyPressListnerForChargedWgt(event) {
    if (event.charCode == 13) {
      $("#" + this.pageId + "goodsValue").focus();
      //CHG-V-LREdit:23
      if (this.consignorRateFlow) {
        this.getConsignorRateDetails();
      }
    }
  }

  onKeyPressListnerForGoodsValue(event) {
    if (event.charCode == 13) {
      this.calculateRiskCharge();
      this.sumOfSubTotal();
      $("#" + this.pageId + "pvtMarkId").focus();
    }
  }

  goodsValueOnBlur(e) {
    this.calculateRiskCharge();
    this.sumOfSubTotal();
  }

  onKeyPressListnerForPrivateMrk(event) {
    if (event.charCode == 13) { $("#" + this.pageId + "baseFreight").focus(); }
  }

  onKeyPressListnerForBaseFreight(event) {
    if (event.charCode == 13) {
      this.sumOfSubTotal();
      $("#" + this.pageId + "hamaliId").focus();
    }
  }
  baseFreightOnBlur(e) {
    this.sumOfSubTotal();
  }

  onKeyPressListnerForHamali(event) {
    if (event.charCode == 13) {
      this.sumOfSubTotal();
      $("#" + this.pageId + "gcCharges").focus();
    }
  }

  hamaliChgOnBlur(e) {
    this.sumOfSubTotal();
  }

  onKeyPressListnerForGCCharge(event) {
    if (event.charCode == 13) {
      this.sumOfSubTotal();
      $("#" + this.pageId + "lcChgId").focus();
    }
  }

  gcChgOnBlur(e) {
    this.sumOfSubTotal();
  }

  onKeyPressListnerForLCCharge(event) {
    if (event.charCode == 13) {
      this.sumOfSubTotal();
      $("#" + this.pageId + "bcChgId").focus();
    }
  }

  lcChgOnBlur(e) {
    this.sumOfSubTotal();
  }

  onKeyPressListnerForBCCharge(event) {
    if (event.charCode == 13) {
      this.sumOfSubTotal();
      $("#" + this.pageId + "aocChgId").focus();
    }
  }

  bcChgOnBlur(e) {
    this.sumOfSubTotal();
  }

  fovChgOnBlur(e) {
    this.sumOfSubTotal();
  }

  onKeyPressListnerForAOCCharge(event) {
    if (event.charCode == 13) {
      this.sumOfSubTotal();
      $("#" + this.pageId + "othersChgId").focus();
    }
  }

  aocChgOnBlur(e) {
    this.sumOfSubTotal();
  }

  onKeyPressListnerForOthers(event) {
    if (event.charCode == 13) {
      this.sumOfSubTotal();
      $("#" + this.pageId + "doorDeliveryMode").focus();
    }
  }

  othersChgOnBlur(e) {
    this.sumOfSubTotal();
  }

  onKeyPressListnerForDoorDeliveryAmount(event) {
    if (event.charCode == 13) {
      this.sumOfSubTotal();
      $("#" + this.pageId + "billNoId").focus();
    }
  }

  doorDeliveryChgOnBlur(e) {
    this.sumOfSubTotal();
  }

  onKeyPressListnerForBillNo(event) {
    if (event.charCode == 13) { $("#" + this.pageId + "billDate").focus(); }
  }

  onKeyPressListnerForBillDate(event) {
    if (event.charCode == 13) { $("#" + this.pageId + "paymentType").focus(); }
  }

  onKeyPressListnerForESugamNo(event) {
    if (event.charCode == 13) { $("#" + this.pageId + "eWayBillNo").focus(); }
  }

  onKeyPressListnerForEWayBillNo(event) {
    var ewayBillNo = $("#" + this.pageId + "eWayBillNo").val();
    if (event.charCode == 13) {

      this.getEwayBillValidUptoFromApi('single', ewayBillNo);
      $("#" + this.pageId + "ewayBillValidUpto").focus();

    }
  }

  onKeyPressListnerForEWayBillValidateUpto(event) {
    if (event.charCode == 13) { $("#" + this.pageId + "articleChargedWeightExtra").focus(); }
  }

  onKeyPressListnerForArticleChgWgtExt(event) {
    if (event.charCode == 13) { $("#" + this.pageId + "remarksId").focus(); }
  }

  codChgAmtOnBlur(e) {
    this.sumOfSubTotal();
  }

  sumOfSubTotal() {
    this.validateTotalAmt = 0.0;
    this.validateDDAmt = 0.0;
    this.validateCODAmt = 0.0;

    this.validateBaseFreightAmt = $("#" + this.pageId + "baseFreight").val() == null ? 0.0 : $("#" + this.pageId + "baseFreight").val() == undefined ? 0.0 : $("#" + this.pageId + "baseFreight").val();
    this.validateHamaliAmt = $("#" + this.pageId + "hamaliId").val() == null ? 0.0 : $("#" + this.pageId + "hamaliId").val() == undefined ? 0.0 : $("#" + this.pageId + "hamaliId").val();
    this.validateGCChargeAmt = $("#" + this.pageId + "gcCharges").val() == null ? 0.0 : $("#" + this.pageId + "gcCharges").val() == undefined ? 0.0 : $("#" + this.pageId + "gcCharges").val();
    this.validateLCChargeAmt = $("#" + this.pageId + "lcChgId").val() == null ? 0.0 : $("#" + this.pageId + "lcChgId").val() == undefined ? 0.0 : $("#" + this.pageId + "lcChgId").val();
    this.validateBCChargeAmt = $("#" + this.pageId + "bcChgId").val() == null ? 0.0 : $("#" + this.pageId + "bcChgId").val() == undefined ? 0.0 : $("#" + this.pageId + "bcChgId").val();
    this.validateFOVAmt = $("#" + this.pageId + "fovChgId").val() == null ? 0.0 : $("#" + this.pageId + "fovChgId").val() == undefined ? 0.0 : $("#" + this.pageId + "fovChgId").val();
    this.validateAOCAmt = $("#" + this.pageId + "aocChgId").val() == null ? 0.0 : $("#" + this.pageId + "aocChgId").val() == undefined ? 0.0 : $("#" + this.pageId + "aocChgId").val();
    this.validateOthersAmt = $("#" + this.pageId + "othersChgId").val() == null ? 0.0 : $("#" + this.pageId + "othersChgId").val() == undefined ? 0.0 : $("#" + this.pageId + "othersChgId").val();

    if ($("#" + this.pageId + "doorDeliveryMode").val() == "yes") {
      this.validateDDAmt = $("#" + this.pageId + "doorDeliveryAmount").val() == null ? 0.0 : $("#" + this.pageId + "doorDeliveryAmount").val() == undefined ? 0.0 : $("#" + this.pageId + "doorDeliveryAmount").val();
    }

    if ($("#" + this.pageId + "checkboxCOD").is(":checked") == true) {
      this.validateCODAmt = $("#" + this.pageId + "codChargeAmount").val() == null ? 0.0 : $("#" + this.pageId + "codChargeAmount").val() == undefined ? 0.0 : $("#" + this.pageId + "codChargeAmount").val();
    }

    this.validateTotalAmt = +this.validateBaseFreightAmt + +this.validateHamaliAmt + +this.validateGCChargeAmt + +this.validateLCChargeAmt + +this.validateBCChargeAmt + +this.validateFOVAmt + +this.validateAOCAmt + + this.validateOthersAmt + +this.validateCODAmt + +this.validateDDAmt;
    $("#" + this.pageId + "totalAmount").val(this.validateTotalAmt);
  }

  calculateRiskCharge() {

    this.validateGoodsValueAmt = 0.0;
    this.validateRiskPerc = 0.0;
    this.validateRiskChgAmt = 0.0;

    this.validateGoodsValueAmt = $("#" + this.pageId + "goodsValue").val();

    //this.validateRiskPerc = this.validateAndReturnPercV1;
    if (this.consignorRateFlowSet) {
      this.validateRiskPerc = this.consignorRateFov;
    } else {
      this.validateRiskPerc = this.validateAndReturnPercV2();
    }
    this.validateRiskChgAmt = (this.validateGoodsValueAmt * this.validateRiskPerc) / 100;

    //$("#"+this.pageId+"fovChgId").val(this.validateRiskChgAmt);
    $("#" + this.pageId + "fovChgId").val(Math.round(this.validateRiskChgAmt));
  }

  validateAndReturnPercV1() {
    this.validateRiskPerc = 0.0;

    if (this.userDataDtoReturnSession.mainAdminStation != null &&
      this.userDataDtoReturnSession.mainAdminStation == "Gujarat") {
      this.validateRiskPerc = 0.2;
    } else if (this.userDataDtoReturnSession.mainAdminStation != null &&
      this.userDataDtoReturnSession.mainAdminStation == "MUMBAI") {
      this.validateRiskPerc = 0.2;
    } else if (this.userDataDtoReturnSession.mainAdminStation != null &&
      this.userDataDtoReturnSession.mainAdminStation == "Delhi") {
      this.validateRiskPerc = 0.2;
    } else if (this.userDataDtoReturnSession.mainAdminStation != null &&
      this.userDataDtoReturnSession.mainAdminStation == "NAGPUR") {
      this.validateRiskPerc = 0.0;
    } else if (this.userDataDtoReturnSession.mainAdminStation != null &&
      this.userDataDtoReturnSession.mainAdminStation == "RAJASTHAN") {
      this.validateRiskPerc = 0.2;
    } else if (this.userDataDtoReturnSession.mainAdminStation != null &&
      this.userDataDtoReturnSession.mainAdminStation == "Bangalore Bkg Head Ofc") {
      this.validateRiskPerc = 0.0;
    }
    return this.validateRiskPerc;
  }

  validateAndReturnPercV2() {
    var riskPerc = 0.0;
    let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
    let office = this.userDataDtoReturnSession.office.toLowerCase();
    this.validateForDestinationValue = $("#" + this.pageId + "destination").val();
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();
    this.selectedOffice = null;
    this.selectedOffice = $("#" + this.pageId + "agentName").val();
    this.selectedOffice = this.selectedOffice.toLowerCase();
    console.log('selectedOffice : ' + this.selectedOffice)

    if (this.validateForDestinationValue != null && this.validateForDestinationValue != '') {
      this.validateForDestinationValue = this.validateForDestinationValue.toLowerCase();
      this.validateForDestinationValue = this.validateForDestinationValue.trim();
    } else {
      this.validateForDestinationValue = this.validateForDestinationValue;
    }

    //CHG-V-LREdit:05
    if (this.validateForDestinationValue != null && this.validateForDestinationValue != '' && this.validateForDestinationValue == 'trivandrum') {
      riskPerc = 0.10;
    } else if (this.selectedOffice != null && (this.selectedOffice == 'jagadhri')) {
      riskPerc = 0.0;
    } else if (MainAdminStation != null && MainAdminStation == 'gujarat') {
      //alert(this.validateForDestinationValue);
      /* riskPerc = 0.1; */
      /* changed on 20/07/2019 w.e.f 21/07/2019 */
      /* @09/09/2020  from Gujarat to chennai - no fov as per Manoj mail
       also for Jamnagar (Dhared - JM,Udyog Nagar - JM) fov 0.1% except chennai */
      if (this.validateForDestinationValue != null &&
        this.validateForDestinationValue != undefined &&
        this.validateForDestinationValue != ''
        && this.validateForDestinationValue == 'chennai') {
        riskPerc = 0.0;
      } else if (this.validateForDestinationValue != null && this.validateForDestinationValue != '' && this.validateForDestinationValue == 'madurai') {
        if (this.selectedOffice != null
          && ((this.selectedOffice == 'balotra') || (this.selectedOffice == 'bhilwara') || (this.selectedOffice == 'pali') || (this.selectedOffice == 'jaipur vki') || (this.selectedOffice == 'jaipur sc road') || (this.selectedOffice == 'sanganer')
            || (this.selectedOffice == 'jodhpur') || (this.selectedOffice == 'udaipur mia') || (this.selectedOffice == 'udaipur subcity') || (this.selectedOffice == 'udaipur town hall') || (this.selectedOffice == 'savarkundla'))) {
          //for rajasthan station fov as per norm and for gujarat fov 0 for Madurai @07/12/2021
          riskPerc = 0.05;
        } else {
          riskPerc = 0.0;
        }
      }
      else if (this.selectedOffice != null
        && ((this.selectedOffice == 'dhared - jm') || (this.selectedOffice == 'udyog nagar - jm'))) {
        /* this.riskPerc = 0.1; */
        /*  changed on 06/06/2021 w.e.f 07/06/2021 */
        riskPerc = 0.1;
      } else if (this.selectedOffice != null
        && ((this.selectedOffice == 'balotra') || (this.selectedOffice == 'bhilwara') || (this.selectedOffice == 'pali') || (this.selectedOffice == 'jaipur vki') || (this.selectedOffice == 'jaipur sc road') || (this.selectedOffice == 'sanganer')
          || (this.selectedOffice == 'jodhpur') || (this.selectedOffice == 'udaipur mia') || (this.selectedOffice == 'udaipur subcity') || (this.selectedOffice == 'udaipur town hall') || (this.selectedOffice == 'savarkundla'))) {
        /* revised as per Manoj mail @20/03/2021 */
        riskPerc = 0.05;
      } else if (this.selectedOffice != null && this.selectedOffice == 'saroli - st') {
        riskPerc = 0.05;
      } else {
        /* this.riskPerc = 0.2; */
        /* changed on 06/06/2021 w.e.f 07/06/2021 */
        riskPerc = 0.25;
      }
    } else if (MainAdminStation != null
      && MainAdminStation == 'mumbai') {
      riskPerc = 0.25;
    } else if (MainAdminStation != null && MainAdminStation == 'delhi') {
      //fov 0 for DINA NAGAR as per Sadakath req @18/02/2022
      if (this.selectedAgentName != null && this.selectedAgentName != '' && this.selectedAgentName.trim() == 'DINA NAGAR') {
        riskPerc = 0;
      } else {
        riskPerc = 0.3;
      }
    } else if (MainAdminStation != null && MainAdminStation == 'nagpur') {
      riskPerc = 0.0;
    } else if (MainAdminStation != null
      && MainAdminStation == 'rajasthan') {
      /* this condition will not execute since there is no
       mainadminstation called Rajasthan, this also comes under Gujarat. @20/03/2021 */

      /* @09/09/2020 from RAJASTHAN to chennai - no fov as per Manoj mail */
      if (this.validateForDestinationValue != null &&
        this.validateForDestinationValue != undefined &&
        this.validateForDestinationValue != '' &&
        this.validateForDestinationValue == 'chennai') {
        riskPerc = 0.0;
      } else {
        riskPerc = 0.2;
      }
    }
    //for bng booking fov zero to all dest
    if (MainAdminStation != null && MainAdminStation == 'bangalore bkg head ofc') {
      /* this.riskPerc = 0.2; */
      /* changed to zero on 22/01/2021 as per Sadakath mail */
      riskPerc = 0.0;
    }

    //fov zero to all dest as per Naveed bh @12/02/2024
    if (MainAdminStation != null && MainAdminStation == 'rohtak') {
      riskPerc = 0.0;
    }

    return riskPerc;
  }


  /*  Key Press Listner Start End */


  getDestinationMethod() {
    this.lrDtoDestination = new LRDto();
    this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
    this.lrDtoDestination.mode = "lrEdit";
    this.lrDtoDestination.reportMode = "subStation";
  }
  getDestinationDetails() {
    this.getDestinationMethod();
    this.showSpinnerForAction = true;
    this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
      (response) => {
        this.destinationOptions = [];
        this.destinationTA = [];
        if (response.length > 0) {
          this.destinationOptions = response;
          this.destinationTA = [];
          for (let i = 0; i < this.destinationOptions.length; i++) {
            this.destinationTA.push(this.destinationOptions[i]);
          }
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
      },
      () => console.log('done');
  };



  getUserValuesForAgentDetailsList() {
    this.agentDtoForAgentName = new AgentDetailsDto();
    this.agentDtoForAgentName.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    this.agentDtoForAgentName.column2 = 'Working';
    this.agentDtoForAgentName.companyId = this.userDataDtoReturnSession.companyId;
    this.agentDtoForAgentName.mode = 'Sub';
  }

  getAgentDetailList() {
    this.getUserValuesForAgentDetailsList();
    this.showSpinnerForAction = true;
    this.masterReadService.getSourceListDetails(this.agentDtoForAgentName).subscribe(
      (response) => {
        this.agentNameTA = [];
        this.agentNameOptions = [];
        if (response.length > 0) {
          this.agentNameOptions = response;
          for (let i = 0; i < this.agentNameOptions.length; i++) {
            //console.log( this.agentDetailsOptionsGet[i].subStation );
            this.agentNameTA.push(this.agentNameOptions[i]);
          }
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Source Details", "info");
      }, () => console.log('done');
  };

  getCommodityDetailsList() {
    this.rateMasterDtoForCommodityDescription = new RateMasterDto();
    this.rateMasterDtoForCommodityDescription.companyId = this.userDataDtoReturnSession.companyId;
  }

  setCommodityDetailList() {
    this.getCommodityDetailsList();
    this.showSpinnerForAction = true;
    this.masterReadService.getCommodityNamesDetails(this.rateMasterDtoForCommodityDescription).subscribe(
      (response) => {
        this.commodityDescriptionTA = [];
        this.commodityDescriptionOptions = [];
        if (response.length > 0) {
          this.commodityDescriptionOptions = response;
          for (let i = 0; i < this.commodityDescriptionOptions.length; i++) {
            this.commodityDescriptionTA.push(this.commodityDescriptionOptions[i]);
          }
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the CommodityList For TP Details", "info");
      }, () => console.log('done');
  }

  getConsigneeNameByTinGst() {
    this.partyMasterDtoForConsignee = new PartyMasterDto();
    this.validateDestination = null;
    $("#" + this.pageId + "consigneeName").val('');

    //CHG-V-LREdit:11
    //CHG-V-LREdit:14
    // this.validateConsigneeGstNo = null;
    // this.validateConsigneeGstNo = $("#" + this.pageId + "consigneeGstNumber").val();

    this.validateDestination = $("#" + this.pageId + "destination").val();

    this.partyMasterDtoForConsignee.office = this.validateDestination;
    this.partyMasterDtoForConsignee.companyId = this.userDataDtoReturnSession.companyId;
    this.partyMasterDtoForConsignee.isGST = true;
    this.partyMasterDtoForConsignee.mode = "Tinnumber";
    this.partyMasterDtoForConsignee.gstNoConsignee = this.validateConsigneeGstNo;

  }

  //CHG-V-LREdit:10
  setConsigneeNameByTinGst(openPopupForAadhar) {
    this.getConsigneeNameByTinGst();
    this.showSpinnerForAction = true;
    this.masterReadService.getConsigneeMaster(this.partyMasterDtoForConsignee).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        console.log(response);
        if (response.length == 0) {
          //CHG-V-LREdit:07
          this.enteredGstNumberConsignee = $("#" + this.pageId + "consigneeGstNumber").val();
          console.log(this.enteredGstNumberConsignee);
          if (this.enteredGstNumberConsignee != null || this.enteredGstNumberConsignee != '') {
            this.consigneeNameTA = [];

            //CHG-V-LREdit:12
            // this.getDetailsFromEwayBillSearchByGstNoMethod("consignee");

            if ($("#" + this.pageId + "searchBy").val() == "gst") {
              this.validateGstNoField();
            }
            //CHG-V-LREdit:19
            else if ($("#" + this.pageId + "searchBy").val() == "pan") {
              //
              // this.validatePanNoField();
              //
              this.consigneeNameOptions = [];
              this.consigneeNameTA = [];
              this.partyMasterDtoForConsigneeeNameAddNew = new PartyMasterDto();
              this.partyMasterDtoForConsigneeeNameAddNew.consigneeName = "Add New";
              this.consigneeNameTA.push(this.partyMasterDtoForConsigneeeNameAddNew);
              this.changeDetectorRef.detectChanges();
              $("#" + this.pageId + "consigneeName").focus();
              return false;
            }
            //CHG-V-LREdit:15
            // else if ($("#" + this.pageId + "searchBy").val() == "pan") {
            //   this.validatePanNoField();
            // } else if ($("#" + this.pageId + "searchBy").val() == "aadhar") {
            //   this.validateAadharNoField(openPopupForAadhar);
            // }

            //CHG-V-LREdit:15
            else if ($("#" + this.pageId + "searchBy").val() == "aadhar") {
              this.consigneeNameOptions = [];
              this.consigneeNameTA = [];
              this.partyMasterDtoForConsigneeeNameAddNew = new PartyMasterDto();
              this.partyMasterDtoForConsigneeeNameAddNew.consigneeName = "Add New";
              this.consigneeNameTA.push(this.partyMasterDtoForConsigneeeNameAddNew);
              this.changeDetectorRef.detectChanges();
              $("#" + this.pageId + "consigneeName").focus();
              return false;
            }

          } else {
            swal("Consignee GST Number",
              "No records found for this Consignee GST Number!",
              "warning").then(() => {
                window.setTimeout(function () {
                  $("#" + this.pageId + "consigneeName").focus();
                }, 100);
              });
            this.consigneeNameOptions = [];
            this.consigneeNameTA = [];
            //CHG-V-LREdit:07
            // this.partyMasterDtoForConsigneeeNameAddNew = new PartyMasterDto();
            // this.partyMasterDtoForConsigneeeNameAddNew.consigneeName = "Add New";
            // this.consigneeNameTA.push(this.partyMasterDtoForConsigneeeNameAddNew);
            return false;
          }
        } else {
          this.consigneeNameOptions = [];
          this.consigneeNameTA = [];
          this.consigneeNameOptions = response;
          //CHG-V-LREdit:07
          // this.partyMasterDtoForConsigneeeNameAddNew = new PartyMasterDto();
          // this.partyMasterDtoForConsigneeeNameAddNew.consigneeName = "Add New";
          // this.consigneeNameTA.push(this.partyMasterDtoForConsigneeeNameAddNew);

          for (let i = 0; i < this.consigneeNameOptions.length; i++) {
            this.consigneeNameTA.push(this.consigneeNameOptions[i]);
          }
          //console.log(this.consigneeNameTA[0].consigneeName);

          //CHG-V-LREdit:07
          // if (this.consigneeNameTA[1].isSrcBlock) {
          //   swal("No Allowed", this.consigneeNameTA[1].srcAlertMessage, "warning");
          //   window.setTimeout(function () {
          //     this.selectedConsigneeName = null;
          //     $("#lrecconsigneeName").val('');
          //   }, 100);
          // } else {
          //   $("#" + this.pageId + "consigneeName").val(this.consigneeNameTA[1].consigneeName);
          //   this.selectedConsigneeId = null;
          //   this.selectedConsigneeId = this.consigneeNameTA[1].consigneeId;
          //   $("#" + this.pageId + "baseFreight").focus();

          //CHG-V-LREdit:07
          if (this.consigneeNameTA[0].isSrcBlock) {
            swal("No Allowed", this.consigneeNameTA[0].srcAlertMessage, "warning");
            window.setTimeout(function () {
              this.selectedConsigneeName = null;
              $("#lrecconsigneeName").val('');
            }, 100);
          } else {
            $("#" + this.pageId + "consigneeName").val(this.consigneeNameTA[0].consigneeName);
            this.selectedConsigneeId = null;
            this.selectedConsigneeId = this.consigneeNameTA[0].consigneeId;
            $("#" + this.pageId + "baseFreight").focus();

            this.stateByDest = this.consigneeNameOptions[0].state;
            this.getBangaloreConsigneeCommodityConsignee();
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Consignee Details", "warning");
      },
      () => console.log('done');
  }


  getConsignorDetailsList() {
    $("#" + this.pageId + "consignorName").val('');
    // this.validateConsignorGstNo = null;
    this.partyMasterDtoForConsignorName = new PartyMasterDto();

    //CHG-V-LREdit:14
    // this.validateConsignorGstNo = $("#" + this.pageId + "consignorGstNumber").val();

    this.partyMasterDtoForConsignorName.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    this.partyMasterDtoForConsignorName.companyId = this.userDataDtoReturnSession.companyId;
    this.partyMasterDtoForConsignorName.isGST = true;
    this.partyMasterDtoForConsignorName.mode = "LREntry";
    this.partyMasterDtoForConsignorName.gstNoConsignor = this.validateConsignorGstNo;

    //CHG-V-LREdit:08
    this.partyMasterDtoForConsignorName.rptMode = "LREdit";
  }

  //CHG-V-LREdit:10
  setConsignorDetailsList(openPopupForAadhar) {
    this.getConsignorDetailsList();
    this.showSpinnerForAction = true;
    this.masterReadService.getConsignorMaster(this.partyMasterDtoForConsignorName).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        console.log(response);
        if (response.length == 0) {
          //CHG-V-LREdit:07
          this.enteredGstNumberConsignor = $("#" + this.pageId + "consignorGstNumber").val();
          console.log(this.enteredGstNumberConsignor);
          if (this.enteredGstNumberConsignor != null || this.enteredGstNumberConsignor != '') {
            this.consignorNameTA = [];

            // this.getDetailsFromEwayBillSearchByGstNoMethod("consignor");
            if ($("#" + this.pageId + "selectType").val() == "gst") {
              this.validateGstNoField();
            }
            //CHG-V-LREdit:19
            else if ($("#" + this.pageId + "selectType").val() == "pan") {
              //CHG-V-LREdit:22
              // this.validatePanNoField();
              //CHG-V-LREdit:22
              this.consignorNameOptions = [];
              this.consignorNameTA = [];
              this.partyMasterDtoForConsignorNameAddNew = new PartyMasterDto();
              this.partyMasterDtoForConsignorNameAddNew.consignorName = "Add New";
              this.consignorNameTA.push(this.partyMasterDtoForConsignorNameAddNew);
              this.changeDetectorRef.detectChanges();
              $("#" + this.pageId + "consignorName").focus();
              return false;
            }
            //CHG-V-LREdit:15
            // else if ($("#" + this.pageId + "selectType").val() == "pan") {
            //   this.validatePanNoField();
            // } else if ($("#" + this.pageId + "selectType").val() == "aadhar") {
            //   this.validateAadharNoField(openPopupForAadhar);
            // }

            //CHG-V-LREdit:15
            else if ($("#" + this.pageId + "selectType").val() == "aadhar") {
              this.consignorNameOptions = [];
              this.consignorNameTA = [];
              this.partyMasterDtoForConsignorNameAddNew = new PartyMasterDto();
              this.partyMasterDtoForConsignorNameAddNew.consignorName = "Add New";
              this.consignorNameTA.push(this.partyMasterDtoForConsignorNameAddNew);
              this.changeDetectorRef.detectChanges();
              $("#" + this.pageId + "consignorName").focus();
              return false;
            }

          } else {
            swal("Consignor GST Number",
              "No records found for this Consignor GST Number!",
              "warning").then(() => {
                window.setTimeout(function () {
                  $("#" + this.pageId + "consignorName").focus();
                }, 100);
              });
            this.consignorNameOptions = [];
            this.consignorNameTA = [];
            //CHG-V-LREdit:07
            // this.partyMasterDtoForConsignorNameAddNew = new PartyMasterDto();
            // this.partyMasterDtoForConsignorNameAddNew.consignorName = "Add New";
            // this.consignorNameTA.push(this.partyMasterDtoForConsignorNameAddNew);
            return false;
          }
        } else {
          this.consignorNameOptions = [];
          this.consignorNameTA = [];
          this.consignorNameOptions = response;
          //CHG-V-LREdit:07
          // this.partyMasterDtoForConsignorNameAddNew = new PartyMasterDto();
          // this.partyMasterDtoForConsignorNameAddNew.consignorName = "Add New";
          // this.consignorNameTA.push(this.partyMasterDtoForConsignorNameAddNew);

          for (let i = 0; i < this.consignorNameOptions.length; i++) {
            this.consignorNameTA.push(this.consignorNameOptions[i]);
          }
          //CHG-V-LREdit:07
          // console.log(this.consignorNameTA[1].consignorName);
          // $("#" + this.pageId + "consignorName").val(this.consignorNameTA[1].consignorName);
          // this.selectedConsignorId = null;
          // this.selectedConsignorId = this.consignorNameTA[1].consignorId;

          //CHG-V-LREdit:07
          console.log(this.consignorNameTA);
          $("#" + this.pageId + "consignorName").val(this.consignorNameTA[0].consignorName);
          this.selectedConsignorId = null;
          this.selectedConsignorId = this.consignorNameTA[0].consignorId;
          $("#" + this.pageId + "consigneeGstNumber").focus();
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Consignor Details", "warning");
      },
      () => console.log('done');
  }

  getStatesMethod() {
    this.partyMasterDtoForConsigneeState = new PartyMasterDto();
    this.partyMasterDtoForConsigneeState.companyId = this.userDataDtoReturnSession.companyId;
    this.masterReadService.getStateDetailsService(this.partyMasterDtoForConsigneeState).subscribe(
      (response) => {
        if (response.length == 0) {
          swal("Warning", "No getStatesMethod records found!", "warning");
          this.partyMasterDtoOptionsConsigneeState = [];
          this.consigneeStateTA = [];

          this.partyMasterDtoOptionsConsignorState = [];
          this.consignorStateTA = [];

          return false;
        } else {
          this.partyMasterDtoOptionsConsigneeState = [];
          this.partyMasterDtoOptionsConsignorState = [];

          this.partyMasterDtoOptionsConsigneeState = response;
          this.partyMasterDtoOptionsConsignorState = response;

          this.consigneeStateTA = [];
          this.consignorStateTA = [];

          for (let i = 0; i < this.partyMasterDtoOptionsConsigneeState.length; i++) {
            this.consigneeStateTA.push(this.partyMasterDtoOptionsConsigneeState[i]);
          }

          for (let i = 0; i < this.partyMasterDtoOptionsConsignorState.length; i++) {
            this.consignorStateTA.push(this.partyMasterDtoOptionsConsignorState[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Error While Getting getStatesMethod", "warning");
      },
      () => console.log('done');
  }

  doorDeliMode(doorDelivery: string) {
    if (doorDelivery === 'yes') {
      this.doorDeliveryYes = true;
      $("#" + this.pageId + "doorDeliveryAmount").val(0);
      this.sumOfSubTotal();
      $("#" + this.pageId + "doorDeliveryAmount").focus();
    } else {
      $("#" + this.pageId + "doorDeliveryAmount").val(0);
      this.doorDeliveryYes = false;
      this.sumOfSubTotal();
      $("#" + this.pageId + "billNoId").focus();
    }
  }

  paymentTypeMode(paymentType: string) {
    if (paymentType === 'paid') {
      this.paymentTypePaid = true;
    } else {
      this.paymentTypePaid = false;
    }
  }

  codCheckBoxEvent(values: any) {
    if (values.currentTarget.checked == true) {
      setTimeout(() => {
        this.viewCODChargeAmtField = true;
        $("#" + this.pageId + "codChargeAmount").val('200');
      }, 1000);
      this.sumOfSubTotal();
    } else {
      this.viewCODChargeAmtField = false;
      $("#" + this.pageId + "codChargeAmount").val(0);
      this.sumOfSubTotal();
    }
  }

  getLrDetailsValues() {
    if ($("#" + this.pageId + "editWithLrNumber").val() == null || $("#" + this.pageId + "editWithLrNumber").val() == undefined ||
      $("#" + this.pageId + "editWithLrNumber").val() == "") {
      swal("LR Number", "Please enter the lr number", "warning");
      return false;
    } else {
      this.isUpdateAllow = true;
      this.getLrEnquiryDetails();
    }
  }

  getLrEnquiryDetails() {
    if (this.isFrieghtFieldsReadOnly) {
      $("#" + this.pageId + "baseFreight").prop('readonly', true);
      $("#" + this.pageId + "hamaliId").prop('readonly', true);
      $("#" + this.pageId + "gcCharges").prop('readonly', true);
      $("#" + this.pageId + "lcChgId").prop('readonly', true);
      $("#" + this.pageId + "bcChgId").prop('readonly', true);
      $("#" + this.pageId + "fovChgId").prop('readonly', true);
      $("#" + this.pageId + "aocChgId").prop('readonly', true);
      $("#" + this.pageId + "othersChgId").prop('readonly', true);
    }
    this.lrDtoForGetLRNumberDetails = new LRDto();
    $("#" + this.pageId + "deleteBtn").prop('disabled', false);
    this.validateLRNumber = null;
    this.validateLRNumber = $("#" + this.pageId + "editWithLrNumber").val();

    this.lrDtoForGetLRNumberDetails.lrNumber = this.validateLRNumber;
    this.lrDtoForGetLRNumberDetails.mode = "LR";
    this.lrDtoForGetLRNumberDetails.companyId = this.userDataDtoReturnSession.companyId;
    this.showSpinnerForAction = true;
    this.reportService.getLrEnquiryDetails(this.lrDtoForGetLRNumberDetails).subscribe(
      (response) => {
        //console.log(response)
        this.showSpinnerForAction = false;
        this.lrDtoForGetLRNumberDetailsReturn = null;
        this.lrDtoForGetLRNumberDetailsReturn = response;
        //topay paid
        $("#" + this.pageId + "paymentType").prop('disabled', false);

        $("#" + this.pageId + "editWithLrNumber").prop('readonly', true);

        var booking = false;
        var destination = false;
        var transhipment = false;

        /* if condition start */
        if (this.lrDtoForGetLRNumberDetailsReturn.size > 0 &&
          this.lrDtoForGetLRNumberDetailsReturn.mode != null) {

          if ((this.lrDtoForGetLRNumberDetailsReturn.source == this.lrDtoForGetLRNumberDetailsReturn.stocksAt) &&
            (this.lrDtoForGetLRNumberDetailsReturn.stocksAt != this.lrDtoForGetLRNumberDetailsReturn.destination)) {
            booking = true;
          } else if ((this.lrDtoForGetLRNumberDetailsReturn.source != this.lrDtoForGetLRNumberDetailsReturn.stocksAt) &&
            (this.lrDtoForGetLRNumberDetailsReturn.stocksAt != this.lrDtoForGetLRNumberDetailsReturn.destination) &&
            (this.lrDtoForGetLRNumberDetailsReturn.invoiceNumber == null)) {
            booking = true;
          } else if ((this.lrDtoForGetLRNumberDetailsReturn.source != this.lrDtoForGetLRNumberDetailsReturn.stocksAt) &&
            (this.lrDtoForGetLRNumberDetailsReturn.stocksAt != this.lrDtoForGetLRNumberDetailsReturn.destination)) {
            transhipment = true;
          } else if ((this.lrDtoForGetLRNumberDetailsReturn.source != this.lrDtoForGetLRNumberDetailsReturn.stocksAt) &&
            (this.lrDtoForGetLRNumberDetailsReturn.stocksAt == this.lrDtoForGetLRNumberDetailsReturn.destination)) {
            destination = true;
          }

          if (this.lrDtoForGetLRNumberDetailsReturn.mode == "Pending") {
            if (booking) {
              this.isUpdateAllow = true;
            }
            if (transhipment) {
              this.isUpdateAllow = true;
              //this.setReadOnlyNewFields();
            }
            if (destination) {
              this.isUpdateAllow = true;
              this.setReadOnlyNewFields();
              //topay paid
              $("#" + this.pageId + "paymentType").prop('disabled', true);
            }
          } else if (this.lrDtoForGetLRNumberDetailsReturn.mode == "Loading") {
            if (booking) {
              this.isUpdateAllow = true;
            }
            if (transhipment) {
              this.isUpdateAllow = true;
              //this.setReadOnlyNewFields();
            }
            if (destination) {
              this.isUpdateAllow = true;
              this.setReadOnlyNewFields();
              //topay paid
              $("#" + this.pageId + "paymentType").prop('disabled', true);
            }
          } else if (this.lrDtoForGetLRNumberDetailsReturn.mode == "Scheduled") {
            if (booking) {
              this.isUpdateAllow = true;
            }
            if (transhipment) {
              this.isUpdateAllow = true;
              //this.setReadOnlyNewFields();
            }
            if (destination) {
              this.isUpdateAllow = true;
              this.setReadOnlyNewFields();
              //topay paid
              $("#" + this.pageId + "paymentType").prop('disabled', true);
            }
          } else if (this.lrDtoForGetLRNumberDetailsReturn.mode == "Transit") {
            this.setReadOnlyNewFields();
            if (this.userDataDtoReturnSession.role == "Super Administrator") {
              this.isUpdateAllow = true;
            }
            if (booking) {
              this.isUpdateAllow = false;
            }
            if (transhipment) {
              this.isUpdateAllow = true;
            }
            if (destination) {
              this.isUpdateAllow = true;
              //topay paid
              $("#" + this.pageId + "paymentType").prop('disabled', true);
            }
          } else if (this.lrDtoForGetLRNumberDetailsReturn.mode == "Delivered") {
            this.setReadOnlyNewFields();
            if (booking) {
              this.isUpdateAllow = false;
            }
            if (transhipment) {
              this.isUpdateAllow = true;
            }
            if (destination) {
              this.isUpdateAllow = true;
              //topay paid
              $("#" + this.pageId + "paymentType").prop('disabled', true);
            }
          } else if (this.lrDtoForGetLRNumberDetailsReturn.mode == "CMWT") {
            this.setReadOnlyNewFields();
            if (booking) {
              this.isUpdateAllow = false;
            }
            if (transhipment) {
              this.isUpdateAllow = true;
            }
            if (destination) {
              this.isUpdateAllow = true;
              //topay paid
              $("#" + this.pageId + "paymentType").prop('disabled', true);
            }
          } else if (this.lrDtoForGetLRNumberDetailsReturn.mode == "CMNT") {
            this.setReadOnlyNewFields();
            if (booking) {
              this.isUpdateAllow = false;
            }
            if (transhipment) {
              this.isUpdateAllow = true;
            }
            if (destination) {
              this.isUpdateAllow = true;
              //topay paid
              $("#" + this.pageId + "paymentType").prop('disabled', true);
            }
          } else if (this.lrDtoForGetLRNumberDetailsReturn.mode == "InCp") {
            this.setReadOnlyNewFields();
            if (booking) {
              this.isUpdateAllow = false;
            }
            if (transhipment) {
              this.isUpdateAllow = true;
            }
            if (destination) {
              this.isUpdateAllow = true;
              //topay paid
              $("#" + this.pageId + "paymentType").prop('disabled', true);
            }
          } else if (this.lrDtoForGetLRNumberDetailsReturn.mode == "localPending") {
            if (booking) {
              this.isUpdateAllow = true;
            }
            if (transhipment) {
              this.setReadOnlyNewFields();
              this.isUpdateAllow = true;
            }
            if (destination) {
              this.setReadOnlyNewFields();
              this.isUpdateAllow = true;
              //topay paid
              $("#" + this.pageId + "paymentType").prop('disabled', true);
            }
          } else if (this.lrDtoForGetLRNumberDetailsReturn.mode == "localTransit") {
            this.setReadOnlyNewFields();
            if (this.userDataDtoReturnSession.role == "Super Administrator") {
              this.isUpdateAllow = true;
            }
            if (booking) {
              this.isUpdateAllow = true;
            }
            if (transhipment) {
              this.isUpdateAllow = true;
            }
            if (destination) {
              this.isUpdateAllow = true;
              //topay paid
              $("#" + this.pageId + "paymentType").prop('disabled', true);
            }
          }

          if (this.userDataDtoReturnSession.role == "Super Administrator") {
            //topay paid
            $("#" + this.pageId + "paymentType").prop('disabled', false);
          }
        }
        /* if condition end */

        /* second if condition start */

        if (this.lrDtoForGetLRNumberDetailsReturn.size == 0) {
          swal("Not Found", "LR Number : " + $("#" + this.pageId + "editWithLrNumber").val() + " Not Found", "warning");
          $("#" + this.pageId + "deleteBtn").prop('disabled', true);
        } else if (this.lrDtoForGetLRNumberDetailsReturn.status != null &&
          this.lrDtoForGetLRNumberDetailsReturn.status == "Delivered" ||
          this.lrDtoForGetLRNumberDetailsReturn.status == "CMNT" ||
          this.lrDtoForGetLRNumberDetailsReturn.status == "CMWT") {
          swal("Not Allowed", "LR Number : " + $("#" + this.pageId + "editWithLrNumber").val() + " Edit Blocked", "warning");
          $("#" + this.pageId + "editWithLrNumber").focus()
        } else if (this.lrDtoForGetLRNumberDetailsReturn.fortNightStmtNo != null) {
          swal("Not Allowed", "LR Number :" + $("#" + this.pageId + "editWithLrNumber").val() + " Edit is Blocked Due to Bangalore Statement Generated for this LR", "");
          if (this.lrEditPartyTemporary) {
            /* enabling only party edit temporary @Dated : 21/01/2019 */
            this.setLRDetailsValues(this.lrDtoForGetLRNumberDetailsReturn);
            this.enablePartyOnlyEdit();
          } else if (this.lrEditEwayBill) {
            //enabling ewaybill edit
            this.setLRDetailsValues(this.lrDtoForGetLRNumberDetailsReturn);
            this.enableEwaybillOnlyEdit();
          }
        } else {
          this.setLRDetailsValues(this.lrDtoForGetLRNumberDetailsReturn);
        }

        // if (this.lrDtoForGetLRNumberDetailsReturn.isMultiEwayBill != null &&
        //   this.lrDtoForGetLRNumberDetailsReturn.listOfEWay.length > 0) {
        //   this.getMultiEwayBill();
        // }

        if (this.lrDtoForGetLRNumberDetailsReturn.listOfEWay.length > 0) {
          this.getMultiEwayBill();
        }
        /* second if condition end */
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Error while getting LR Details", "error");
        this.showSpinnerForAction = false;
      },
      () => console.log('done');
  }

  setReadOnlyNewFields() {
    //alert("chk1");
    $("#" + this.pageId + "baseFreight").prop('readonly', true);
    $("#" + this.pageId + "hamaliId").prop('readonly', true);
    $("#" + this.pageId + "gcCharges").prop('readonly', true);
    $("#" + this.pageId + "lcChgId").prop('readonly', true);
    $("#" + this.pageId + "bcChgId").prop('readonly', true);
    $("#" + this.pageId + "fovChgId").prop('readonly', true);
    $("#" + this.pageId + "aocChgId").prop('readonly', true);
    $("#" + this.pageId + "othersChgId").prop('readonly', true);
  }

  setReadOnlyFields() {
    //alert("chk");
    /*$("#"+this.pageId+"destination").prop('readonly', false);
    $("#"+this.pageId+"agentName").prop('readonly', false);
    $("#"+this.pageId+"consignorName").prop('readonly', false);
    $("#"+this.pageId+"bookingDates").prop('readonly', false);*/
    $("#" + this.pageId + "destination").prop('disabled', true);
    $("#" + this.pageId + "agentName").prop('disabled', true);
    $("#" + this.pageId + "consignorName").prop('disabled', true);
    $("#" + this.pageId + "bookingDates").prop('disabled', true);
    $("#" + this.pageId + "packNature").prop('readonly', true);

    $("#" + this.pageId + "article").prop('readonly', true);
    $("#" + this.pageId + "actualWeight").prop('readonly', true);
    $("#" + this.pageId + "chargedWeight").prop('readonly', true);
    $("#" + this.pageId + "goodsValue").prop('readonly', true);
    $("#" + this.pageId + "pvtMarkId").prop('readonly', true);
    $("#" + this.pageId + "billNoId").prop('readonly', true);
    $("#" + this.pageId + "billDate").prop('readonly', false);
    $("#" + this.pageId + "paymentType").prop('readonly', true);
    $("#" + this.pageId + "totalAmount").prop('readonly', true);

    $("#" + this.pageId + "baseFreight").prop('readonly', true);
    $("#" + this.pageId + "hamaliId").prop('readonly', true);
    $("#" + this.pageId + "gcCharges").prop('readonly', true);
    $("#" + this.pageId + "lcChgId").prop('readonly', true);
    $("#" + this.pageId + "bcChgId").prop('readonly', true);
    $("#" + this.pageId + "fovChgId").prop('readonly', true);
    $("#" + this.pageId + "aocChgId").prop('readonly', true);
    $("#" + this.pageId + "othersChgId").prop('readonly', true);

    $("#" + this.pageId + "doorDeliveryMode").prop('readonly', true);
    $("#" + this.pageId + "doorDeliveryAmount").prop('readonly', true);
  }

  enablePartyOnlyEdit() {
    this.setReadOnlyNewFields();
    this.setReadOnlyFields();
    //topay paid
    $("#" + this.pageId + "paymentType").prop('disabled', true);

    $("#" + this.pageId + "consignorGstNumber").prop('readonly', false);
    $("#" + this.pageId + "consignorName").prop('readonly', false);
    $("#" + this.pageId + "consigneeGstNumber").prop('readonly', false);
    $("#" + this.pageId + "consigneeName").prop('readonly', false);

    //comboConsignee.setEnabled(true);
    //comboConsignor.setEnabled(true);
  }


  setLRDetailsValues(lrDtoDetailsResponse) {
    console.log(lrDtoDetailsResponse);
    /* 
    lblLrArtSizDtls.setVisible(true);
    isGSTMemo = dto.getIsGSTMemo();
    */
    this.validateLRNumber = null;
    this.validateLRNumber = $("#" + this.pageId + "editWithLrNumber").val();

    if (this.validateLRNumber != null && (this.validateLRNumber.startsWith("SRD") || this.validateLRNumber.startsWith("srd")
      || this.validateLRNumber.startsWith("BLR") || this.validateLRNumber.startsWith("blr"))) {
      this.viewAutoLRPrintBtn = true;
      this.lrNo = null;
      this.lrNo = $("#" + this.pageId + "editWithLrNumber").val();
    } else {
      this.lrNo = null;
      $("#" + this.pageId + "agentName").prop('disabled', false);
    }

    this.selectedConsigneeId = null;
    this.selectedConsignorId = null;
    $("#" + this.pageId + "consignorGstNumber").val(lrDtoDetailsResponse.gstNoConsignor);
    $("#" + this.pageId + "consigneeGstNumber").val(lrDtoDetailsResponse.gstNoConsignee);
    this.consigneeMobileNumber = lrDtoDetailsResponse.mobileNum;
    console.log(this.consigneeMobileNumber);
    $("#" + this.pageId + "consigneeMobileNo").val(this.consigneeMobileNumber);

    this.selectedConsigneeId = lrDtoDetailsResponse.consigneeId;
    this.selectedConsignorId = lrDtoDetailsResponse.consignorId;

    $("#" + this.pageId + "remarksId").val(lrDtoDetailsResponse.remarks);
    $("#" + this.pageId + "destination").val(lrDtoDetailsResponse.subStations);
    $("#" + this.pageId + "consigneeName").val(lrDtoDetailsResponse.consigneeName);
    $("#" + this.pageId + "consignorName").val(lrDtoDetailsResponse.consignorName);
    if (lrDtoDetailsResponse.bookingDate != null &&
      lrDtoDetailsResponse.bookingDate != undefined &&
      lrDtoDetailsResponse.bookingDate != "") {
      $("#" + this.pageId + "bookingDates").val(moment(lrDtoDetailsResponse.bookingDate).format("YYYY-MM-DD"));
    }
    $("#" + this.pageId + "article").val(lrDtoDetailsResponse.totalArticles);
    $("#" + this.pageId + "actualWeight").val(lrDtoDetailsResponse.actualWeight);
    $("#" + this.pageId + "chargedWeight").val(lrDtoDetailsResponse.chargedWeight);
    $("#" + this.pageId + "totalAmount").val(lrDtoDetailsResponse.totalAmount);
    $("#" + this.pageId + "goodsValue").val(lrDtoDetailsResponse.goodsValue);
    $("#" + this.pageId + "reasonForDelete").val(lrDtoDetailsResponse.reason);

    if (lrDtoDetailsResponse.toPay != 0 || lrDtoDetailsResponse.toPay != 0.0) {
      $("#" + this.pageId + "paymentType").val('toPay');
      this.paymentTypePaid = false;
    } else {
      $("#" + this.pageId + "paymentType").val('paid');
      this.paymentTypePaid = true;
    }

    $("#" + this.pageId + "billNoId").val(lrDtoDetailsResponse.billNumber);
    if (lrDtoDetailsResponse.billDate != null &&
      lrDtoDetailsResponse.billDate != undefined &&
      lrDtoDetailsResponse.billDate != "") {
      $("#" + this.pageId + "billDate").val(moment(lrDtoDetailsResponse.billDate).format("YYYY-MM-DD"));
    }
    $("#" + this.pageId + "agentName").val(lrDtoDetailsResponse.agentId);
    $("#" + this.pageId + "pvtMarkId").val(lrDtoDetailsResponse.privateMarker);
    $("#" + this.pageId + "partyGoodsType").val(lrDtoDetailsResponse.goodsType);
    $("#" + this.pageId + "commodityDescription").val(lrDtoDetailsResponse.commodityTP);
    $("#" + this.pageId + "lcChgId").val(lrDtoDetailsResponse.lcChg);
    $("#" + this.pageId + "bcChgId").val(lrDtoDetailsResponse.bcChg);
    $("#" + this.pageId + "fovChgId").val(lrDtoDetailsResponse.riskCharge);

    if (lrDtoDetailsResponse.withOut != null &&
      lrDtoDetailsResponse.withOut) {
      $("#" + this.pageId + "checkboxAoc").prop('checked', true);
    }

    /*if ($("#"+this.pageId+"paymentType").val() == "paid") {
      if (lrDtoDetailsResponse.pickUpLR != null &&
        lrDtoDetailsResponse.pickUpLR) {
        $("#"+this.pageId+"checkboxPickupLr").prop('checked', true);
        this.paymentTypePaid = true;
        if (lrDtoDetailsResponse.serTaxPayBy != null && lrDtoDetailsResponse.serTaxPayBy != 0) {
          if (lrDtoDetailsResponse.serTaxPayBy == 1) {
            setTimeout(() => { $("#"+this.pageId+"serTaxPayingBy").val("1"); }, 1000);
          } else if (lrDtoDetailsResponse.serTaxPayBy == 2) {
            setTimeout(() => { $("#"+this.pageId+"serTaxPayingBy").val("2"); }, 1000);
          }
        }
      }
    }*/

    if (lrDtoDetailsResponse.pickUpLR != null &&
      lrDtoDetailsResponse.pickUpLR) {
      $("#" + this.pageId + "checkboxPickupLr").prop('checked', true);
    }

    if (this.paymentTypePaid == true) {
      if (lrDtoDetailsResponse.toBeBilledParty != null &&
        lrDtoDetailsResponse.toBeBilledParty) {
        setTimeout(() => {
          $("#" + this.pageId + "checkboxToBeBilled").prop('checked', true);
        }, 1000);
      }

      if (lrDtoDetailsResponse.serTaxPayBy != null &&
        lrDtoDetailsResponse.serTaxPayBy != 0) {
        if (lrDtoDetailsResponse.serTaxPayBy == 1) {
          setTimeout(() => { $("#" + this.pageId + "serTaxPayingBy").val("1"); }, 1000);
        } else if (lrDtoDetailsResponse.serTaxPayBy == 2) {
          setTimeout(() => { $("#" + this.pageId + "serTaxPayingBy").val("2"); }, 1000);
        }
      }
    }

    $("#" + this.pageId + "baseFreight").val(lrDtoDetailsResponse.baseFreight);
    $("#" + this.pageId + "hamaliId").val(lrDtoDetailsResponse.hamaliLR);
    $("#" + this.pageId + "aocChgId").val(lrDtoDetailsResponse.aoc);
    //insurance not using  so not set
    //nfInsurChg.setValue(dto.getInsurance());
    $("#" + this.pageId + "gcCharges").val(lrDtoDetailsResponse.gcCharge);
    $("#" + this.pageId + "othersChgId").val(lrDtoDetailsResponse.others);

    if (lrDtoDetailsResponse.isDoorDeliv != null && lrDtoDetailsResponse.isDoorDeliv) {
      this.doorDeliveryYes = true;
      setTimeout(() => {
        $("#" + this.pageId + "doorDeliveryMode").val('yes');
        $("#" + this.pageId + "doorDeliveryAmount").val(lrDtoDetailsResponse.doorDeliveryAmount);
        $("#" + this.pageId + "doorDeliveryAddress").val(lrDtoDetailsResponse.ddAddress);
      }, 1000);

    } else {
      $("#" + this.pageId + "doorDeliveryMode").val('no');
      $("#" + this.pageId + "doorDeliveryAmount").val('');
      $("#" + this.pageId + "doorDeliveryAddress").val('');
    }

    if (lrDtoDetailsResponse.gatePass != null && lrDtoDetailsResponse.gatePass) {
      $("#" + this.pageId + "checkboxGatePass").prop('checked', true);
    }
    if (lrDtoDetailsResponse.ccAttach != null && lrDtoDetailsResponse.ccAttach) {
      $("#" + this.pageId + "checkboxCcAttach").prop('checked', true);
    }
    if (lrDtoDetailsResponse.selfLr != null && lrDtoDetailsResponse.selfLr) {
      $("#" + this.pageId + "checkboxSelfLr").prop('checked', true);
    }

    if (lrDtoDetailsResponse.bankLr != null &&
      lrDtoDetailsResponse.bankLr != undefined &&
      lrDtoDetailsResponse.bankLr != "") {
      $("#" + this.pageId + "bankLrId").val(lrDtoDetailsResponse.bankLr);
    }

    $("#" + this.pageId + "eSugamNo").val(lrDtoDetailsResponse.eSugamNo);
    $("#" + this.pageId + "eWayBillNo").val(lrDtoDetailsResponse.eWayBillNo);

    if (lrDtoDetailsResponse.ewayBillValidUpto != null &&
      lrDtoDetailsResponse.ewayBillValidUpto != undefined &&
      lrDtoDetailsResponse.ewayBillValidUpto != "") {
      $("#" + this.pageId + "ewayBillValidUpto").val(moment(lrDtoDetailsResponse.ewayBillValidUpto).format("YYYY-MM-DD"));
    }

    $("#" + this.pageId + "articleChargedWeightExtra").val(lrDtoDetailsResponse.chgWtLr);

    if (lrDtoDetailsResponse.isUnLoadByConsignee != null && lrDtoDetailsResponse.isUnLoadByConsignee) {
      $("#" + this.pageId + "checkboxunloadedByConsignee").prop('checked', true);
    }

    if (lrDtoDetailsResponse.isHandWithCare != null && lrDtoDetailsResponse.isHandWithCare) {
      $("#" + this.pageId + "checkboxHandleWithCare").prop('checked', true);
    }

    if (lrDtoDetailsResponse.isCod != null && lrDtoDetailsResponse.isCod) {
      $("#" + this.pageId + "checkboxCOD").prop('checked', true);
      this.viewCODChargeAmtField = true;
      window.setTimeout(function () {
        if (lrDtoDetailsResponse.codChg == null || lrDtoDetailsResponse.codChg == undefined ||
          lrDtoDetailsResponse.codChg == 0) {
          $("#" + this.pageId + "codChargeAmount").val(0);
        } else {
          $("#" + this.pageId + "codChargeAmount").val(lrDtoDetailsResponse.codChg);
        }

      }, 1000);
    }

    if (lrDtoDetailsResponse.isOrderGoods != null && lrDtoDetailsResponse.isOrderGoods) {
      $("#" + this.pageId + "checkboxOrderGoods").prop('checked', true);
    }

    if (lrDtoDetailsResponse.goodsType != null) {
      $("#" + this.pageId + "partyGoodsType").val(lrDtoDetailsResponse.goodsType);
    }

    // if (lrDtoDetailsResponse.commodityCode != null &&
    //   lrDtoDetailsResponse.commodityCode != undefined) {
    //   var multiComm = lrDtoDetailsResponse.commodityCode.split("#");
    //   this.partyGoodsTypeOptions = [];
    //   for (let i = 0; i < multiComm.length; i++) {
    //     this.partyMasterDtoForPartyGoodsType = new PartyMasterDto();
    //     this.partyMasterDtoForPartyGoodsType.multipleCommodity = multiComm[i];
    //     this.partyGoodsTypeOptions.push(this.partyMasterDtoForPartyGoodsType);
    //   }

    //   this.partyMasterDtoForPartyGoodsType = new PartyMasterDto();
    //   this.partyMasterDtoForPartyGoodsType.multipleCommodity = "Add New";
    //   this.partyGoodsTypeTA.push(this.partyMasterDtoForPartyGoodsType);
    //   for (let i = 0; i < this.partyGoodsTypeOptions.length; i++) {
    //     this.partyGoodsTypeTA.push(this.partyGoodsTypeOptions[i]);
    //   }
    // }
    this.stateByDest = lrDtoDetailsResponse.consigneeState;
    this.getBangaloreConsigneeCommodityConsignee();


    /* pendong 
    txteDeclarationCommodity
      .setValue(dto.getEDeclarationCommodity() == null ? null : dto
        .getEDeclarationCommodity());
        cbPartyGoodsType.getStore().removeAll();
    if (dto.getGoodsType() != null) {
      cbPartyGoodsType.setRawValue(dto.getGoodsType());
    }


    if (dto.getPackNatureLr() != null) {
      storeNaturePacklArt.removeAll();
      lblPackNatureDetail.setVisible(true);
      String multiPackNature[] = dto.getPackNatureLr().split("#");
      for (int i = 0; i < multiPackNature.length; i++) {
        String multiPack_Nature[] = dto.getPackNatureLr().split("_");
        storeNaturePacklArt.add(new Maintenance(multiPack_Nature[0],
            multiPack_Nature[1]));
      }
    } else {
      lblPackNatureDetail.setVisible(false);
    }
    */
    this.multiArtSizeList = null;
    this.multiArtSizeList = lrDtoDetailsResponse.multiArtSize;

    if (lrDtoDetailsResponse.multiArtSize != null &&
      lrDtoDetailsResponse.multiArtSize != undefined &&
      lrDtoDetailsResponse.multiArtSize != "") {
      this.viewAddArticleSize = "View";
      if (this.userDataDtoReturnSession.blockChgWgtLrEdit != null && this.userDataDtoReturnSession.blockChgWgtLrEdit) {
        this.chgWgtReadOnlyEdit = true;
      } else {
        this.chgWgtReadOnlyEdit = false;
      }
    } else {
      this.viewAddArticleSize = "Add New";
      this.chgWgtReadOnlyEdit = false;
    }

    $("#" + this.pageId + "packNature").val(lrDtoDetailsResponse.packNature);

    this.multiPackNatureList = null;
    this.multiPackNatureList = lrDtoDetailsResponse.packNatureLr;

    if (lrDtoDetailsResponse.packNatureLr != null &&
      lrDtoDetailsResponse.packNatureLr != undefined &&
      lrDtoDetailsResponse.packNatureLr != "") {
      this.viewAddMultiPackNature = "View";
    } else {
      this.viewAddMultiPackNature = "Add New";
    }
  }

  validateSaveUpdateBtnDetails() {
    this.validateDestinationSelection();
    if (this.isDestValid == true) {
      this.validateMainCommoditySelection();
      if (this.isUpdateAllow) {
        if (this.verifySubTotal() > $("#" + this.pageId + "totalAmount").val() ||
          this.verifySubTotal() < $("#" + this.pageId + "totalAmount").val()) {
          swal("Not Allowed", "Total Amount is incorrect, please verify it!", "warning");
          return false;
        } else if (($("#" + this.pageId + "doorDeliveryMode").val() == "yes") &&
          (($("#" + this.pageId + "doorDeliveryAmount").val() == null) || ($("#" + this.pageId + "doorDeliveryAmount").val() == undefined) ||
            ($("#" + this.pageId + "doorDeliveryAmount").val() == "") || $("#" + this.pageId + "doorDeliveryAmount").val() == 0 ||
            (this.calculateDDAmountRangeToValidate() > $("#" + this.pageId + "doorDeliveryAmount").val())
            //CHG-V-LREdit:24
            && this.userDataDtoReturnSession.isDDAmtBlock)) {
          var minDDAmt = this.userDataDtoReturnSession.ddMinAmtOnLr;
          var minDDPerc = this.userDataDtoReturnSession.ddMinPercOnLr;
          swal("DD Amount", "Mentioned DD Amount is must be at minimum range of "
            + minDDAmt + " or " + minDDPerc + " ps on Charge Weight, whichever is higher!", "");
          $("#" + this.pageId + "doorDeliveryAmount").focus();
          return false;
          ////CHG-V-LREdit:16
        } else if (this.isCommodityBlockedBkg) {
          swal("Not Allowed", this.selectedPartyCommodity + " commodity is blocked for booking, please contact admin!", "warning");
        } else if (($("#" + this.pageId + "partyGoodsType").val() == null) || ($("#" + this.pageId + "partyGoodsType").val() == undefined) ||
          ($("#" + this.pageId + "partyGoodsType").val() == "")) {
          swal("Not Allowed", "Please select Party Goods Type!", "warning");
          return false;
        } else if (this.isMainCommodityValid == false) {
          swal({
            title: "Not Allowed",
            text: "Selected Party Goods Type " + this.selectedPartyCommodity + " is not valid, please check and select again!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          swal({
            title: "Confirm Changes",
            text: "Sure U Want to Update the changes made",
            icon: "info",
            buttons: ["No", "Yes"]
          }).then((isConfirm) => {
            if (isConfirm) {
              this.updateLRDetailsService();
            }
          });
        }
      }
    }
  }

  updateLRDetailsService() {
    this.lrDtoForSaveReturn = new LRDto();
    this.lrDtoForSaveReturn = this.getUserValues();
    console.log(this.lrDtoForSaveReturn);
    this.showSpinnerForAction = true;
    this.lrService.updateLRDetails(this.lrDtoForSaveReturn).subscribe(
      (response) => {
        this.lrDtoForSaveResponseReturn = null;
        this.lrDtoForSaveResponseReturn = response;
        console.log(response);
        if (this.lrDtoForSaveResponseReturn.status != null &&
          this.lrDtoForSaveResponseReturn.status == "updated") {
          swal("LR Number " + $("#" + this.pageId + "editWithLrNumber").val() + "", "Updated Successfully", "success");
          this.clearFieldAll();
        } else {
          swal("Failed ", "Failed to Updated LR Details", "warning");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Error while getting LR Details", "error");
        this.showSpinnerForAction = false;
      },
      () => console.log('done');
  }

  verifySubTotal() {
    this.validateTotalAmt = 0;
    this.validateDDAmt = 0;
    this.validateCODAmt = 0;

    this.validateBaseFreightAmt = $("#" + this.pageId + "baseFreight").val() == null ? 0.0 : $("#" + this.pageId + "baseFreight").val() == undefined ? 0.0 : $("#" + this.pageId + "baseFreight").val();
    this.validateHamaliAmt = $("#" + this.pageId + "hamaliId").val() == null ? 0.0 : $("#" + this.pageId + "hamaliId").val() == undefined ? 0.0 : $("#" + this.pageId + "hamaliId").val();
    this.validateGCChargeAmt = $("#" + this.pageId + "gcCharges").val() == null ? 0.0 : $("#" + this.pageId + "gcCharges").val() == undefined ? 0.0 : $("#" + this.pageId + "gcCharges").val();
    this.validateLCChargeAmt = $("#" + this.pageId + "lcChgId").val() == null ? 0.0 : $("#" + this.pageId + "lcChgId").val() == undefined ? 0.0 : $("#" + this.pageId + "lcChgId").val();
    this.validateBCChargeAmt = $("#" + this.pageId + "bcChgId").val() == null ? 0.0 : $("#" + this.pageId + "bcChgId").val() == undefined ? 0.0 : $("#" + this.pageId + "bcChgId").val();
    this.validateFOVAmt = $("#" + this.pageId + "fovChgId").val() == null ? 0.0 : $("#" + this.pageId + "fovChgId").val() == undefined ? 0.0 : $("#" + this.pageId + "fovChgId").val();
    this.validateAOCAmt = $("#" + this.pageId + "aocChgId").val() == null ? 0.0 : $("#" + this.pageId + "aocChgId").val() == undefined ? 0.0 : $("#" + this.pageId + "aocChgId").val();
    this.validateOthersAmt = $("#" + this.pageId + "othersChgId").val() == null ? 0.0 : $("#" + this.pageId + "othersChgId").val() == undefined ? 0.0 : $("#" + this.pageId + "othersChgId").val();

    if ($("#" + this.pageId + "doorDeliveryMode").val() == "yes") {
      this.validateDDAmt = $("#" + this.pageId + "doorDeliveryAmount").val() == null ? 0.0 : $("#" + this.pageId + "doorDeliveryAmount").val() == undefined ? 0.0 : $("#" + this.pageId + "doorDeliveryAmount").val();
    }

    if ($("#" + this.pageId + "checkboxCOD").is(":checked") == true) {
      this.validateCODAmt = $("#" + this.pageId + "codChargeAmount").val() == null ? 0.0 : $("#" + this.pageId + "codChargeAmount").val() == undefined ? 0.0 : $("#" + this.pageId + "codChargeAmount").val();
    }

    this.validateTotalAmt = +this.validateBaseFreightAmt + +this.validateHamaliAmt + +this.validateGCChargeAmt + +this.validateLCChargeAmt + +this.validateBCChargeAmt + +this.validateFOVAmt + +this.validateAOCAmt + + this.validateOthersAmt + +this.validateCODAmt + +this.validateDDAmt;
    return this.validateTotalAmt;
  }

  calculateDDAmountRangeToValidate() {
    var ddAmtRange = 0.0;
    this.chargedWgtForSave = $("#" + this.pageId + "chargedWeight").val() == null ? 0.0 : $("#" + this.pageId + "chargedWeight").val() == undefined ? 0.0 : $("#" + this.pageId + "chargedWeight").val();
    var ddAmtSys = (this.userDataDtoReturnSession.ddMinPercOnLr * this.chargedWgtForSave);

    if (ddAmtSys < this.userDataDtoReturnSession.ddMinAmtOnLr) {
      ddAmtRange = this.userDataDtoReturnSession.ddMinAmtOnLr;
    } else {
      ddAmtRange = ddAmtSys;
    }
    return ddAmtRange;
  }


  getUserValues() {
    this.lrDtoForGetUserValues = new LRDto();

    this.lrNumberForSave = null;
    this.destinationForSave = null;
    this.agentNameForSave = null;
    this.partyGoodsTypeForSave = null;
    this.bookingDateForSave = null;
    this.articlesForSave = null;
    this.commodityDescForSave = null;
    this.actualWgtForSave = null;
    this.chargedWgtForSave = null;
    this.packNatureForSave = null;
    this.goodsValueForSave = null;
    this.privateMarkForSave = null;
    this.articleSize = null;
    this.consignorGstNumberForSave = null;
    this.consignorNameForSave = null;
    this.consigneeGstNumberForSave = null;
    this.consigneeNameForSave = null;
    this.baseFreightForSave = null;
    this.hamaliForSave = null;
    this.gcChargeForSave = null;
    this.lcChgForSave = null;
    this.bcChgForSave = null;
    this.fovChgForSave = null;
    this.aocChgForSave = null;
    this.otherChgForSave = null;
    this.doorDeliveryForSave = null;
    this.doorDeliveryAddressForSave = null;
    this.doorDeliveryAmountForSave = null;
    this.totalAmountForSave = null;
    this.billNoForSave = null;
    this.billDateForSave = null;
    this.paymentTypeForSave = null;
    this.bankLrForSave = null;
    this.esugamNoForSave = null;
    this.ewayBillNoForSave = null;
    this.ewayBillValidUpto = null;
    this.articleChargeWgtForSave = null;
    this.packNatureLabelForSave = null;
    this.remarkForSave = null;
    this.checkBoxAOCForSave = null;
    this.checkBoxToBeBilledForSave = null;
    this.checkBoxPickUpLrForSave = null;
    this.checkBoxUnloadedByConsigneeForSave = null;
    this.checkBoxHandleWithCareForSave = null;
    this.checkBoxGatePassNoForSave = null;
    this.checkBoxCCAttachForSave = null;
    this.checkBoxSelfLrForSave = null;
    this.checkBoxCODForSave = null;
    this.codChargeAmountForSave = null;
    this.checkBoxOrderGoodForSave = null;
    this.reasonForDeleteForSave = null;


    this.lrNumberForSave = $("#" + this.pageId + "editWithLrNumber").val();
    this.destinationForSave = $("#" + this.pageId + "destination").val();
    this.agentNameForSave = $("#" + this.pageId + "agentName").val();
    //this.partyGoodsTypeForSave = $("#"+this.pageId+"partyGoodsType").val();
    this.bookingDateForSave = $("#" + this.pageId + "bookingDates").val();
    this.articlesForSave = $("#" + this.pageId + "article").val();
    this.commodityDescForSave = $("#" + this.pageId + "commodityDescription").val();
    this.actualWgtForSave = $("#" + this.pageId + "actualWeight").val() == null ? 0.0 : $("#" + this.pageId + "actualWeight").val() == undefined ? 0.0 : $("#" + this.pageId + "actualWeight").val();
    this.chargedWgtForSave = $("#" + this.pageId + "chargedWeight").val() == null ? 0.0 : $("#" + this.pageId + "chargedWeight").val() == undefined ? 0.0 : $("#" + this.pageId + "chargedWeight").val();
    this.packNatureForSave = $("#" + this.pageId + "packNature").val();
    this.goodsValueForSave = $("#" + this.pageId + "goodsValue").val();
    this.privateMarkForSave = $("#" + this.pageId + "pvtMarkId").val();
    this.articleSize = $("#" + this.pageId + "articleSize").val();
    this.consignorGstNumberForSave = $("#" + this.pageId + "consignorGstNumber").val();
    this.consignorNameForSave = $("#" + this.pageId + "consignorName").val();
    this.consigneeGstNumberForSave = $("#" + this.pageId + "consigneeGstNumber").val();
    this.consigneeNameForSave = $("#" + this.pageId + "consigneeName").val();
    this.baseFreightForSave = $("#" + this.pageId + "baseFreight").val();
    this.hamaliForSave = $("#" + this.pageId + "hamaliId").val();
    this.gcChargeForSave = $("#" + this.pageId + "gcCharges").val();
    this.lcChgForSave = $("#" + this.pageId + "lcChgId").val();
    this.bcChgForSave = $("#" + this.pageId + "bcChgId").val();
    this.fovChgForSave = $("#" + this.pageId + "fovChgId").val();
    this.aocChgForSave = $("#" + this.pageId + "aocChgId").val();
    this.otherChgForSave = $("#" + this.pageId + "othersChgId").val();
    this.doorDeliveryForSave = $("#" + this.pageId + "doorDeliveryMode").val();
    this.doorDeliveryAddressForSave = $("#" + this.pageId + "doorDeliveryAddress").val();
    this.doorDeliveryAmountForSave = $("#" + this.pageId + "doorDeliveryAmount").val();
    this.totalAmountForSave = $("#" + this.pageId + "totalAmount").val() == null ? 0.0 : $("#" + this.pageId + "totalAmount").val() == undefined ? 0.0 : $("#" + this.pageId + "totalAmount").val();
    this.billNoForSave = $("#" + this.pageId + "billNoId").val();
    this.billDateForSave = $("#" + this.pageId + "billDate").val();
    this.paymentTypeForSave = $("#" + this.pageId + "paymentType").val();
    this.bankLrForSave = $("#" + this.pageId + "bankLrId").val();
    this.esugamNoForSave = $("#" + this.pageId + "eSugamNo").val();
    this.ewayBillNoForSave = $("#" + this.pageId + "eWayBillNo").val();
    this.ewayBillValidUpto = $("#" + this.pageId + "ewayBillValidUpto").val();
    this.articleChargeWgtForSave = $("#" + this.pageId + "articleChargedWeightExtra").val();
    this.packNatureLabelForSave = $("#" + this.pageId + "packNatureLabelId").val();
    this.remarkForSave = $("#" + this.pageId + "remarksId").val();
    this.checkBoxAOCForSave = $("#" + this.pageId + "checkboxAoc").is(":checked");
    this.checkBoxToBeBilledForSave = $("#" + this.pageId + "checkboxToBeBilled").is(":checked");
    this.checkBoxPickUpLrForSave = $("#" + this.pageId + "checkboxPickupLr").is(":checked");
    this.checkBoxUnloadedByConsigneeForSave = $("#" + this.pageId + "checkboxunloadedByConsignee").is(":checked");
    this.checkBoxHandleWithCareForSave = $("#" + this.pageId + "checkboxHandleWithCare").is(":checked");
    this.checkBoxGatePassNoForSave = $("#" + this.pageId + "checkboxGatePass").is(":checked");
    this.checkBoxCCAttachForSave = $("#" + this.pageId + "checkboxCcAttach").is(":checked");
    this.checkBoxSelfLrForSave = $("#" + this.pageId + "checkboxSelfLr").is(":checked");
    this.checkBoxCODForSave = $("#" + this.pageId + "checkboxCOD").is(":checked");
    this.codChargeAmountForSave = $("#" + this.pageId + "codChargeAmount").val();
    this.checkBoxOrderGoodForSave = $("#" + this.pageId + "checkboxOrderGoods").is(":checked");
    this.reasonForDeleteForSave = $("#" + this.pageId + "reasonForDelete").val();

    this.lrDtoForGetUserValues.lrNumber = this.lrNumberForSave;
    this.lrDtoForGetUserValues.destination = this.destinationForSave;
    this.lrDtoForGetUserValues.bookingDate = this.bookingDateForSave;
    this.lrDtoForGetUserValues.actualArt = this.articlesForSave;
    this.lrDtoForGetUserValues.commodityTP = this.commodityDescForSave;
    if (this.validateCommodityCpCode != null && this.validateCommodityCpCode != undefined &&
      this.validateCommodityCpCode != "" && this.validateCommodityCpCode != "NA") {
      this.lrDtoForGetUserValues.commodityCode = this.validateCommodityCpCode;
    }

    if (this.commodityDescForSave == null || this.commodityDescForSave == undefined ||
      this.commodityDescForSave == "" || this.commodityDescForSave == "NA") {
      this.lrDtoForGetUserValues.tpNeeded = false;
    } else {
      this.lrDtoForGetUserValues.tpNeeded = true;
    }

    this.partyGoodsType = null;
    this.goodsType = null;
    this.newCommodity = null;

    this.partyGoodsTypeForSave = $("#" + this.pageId + "partyGoodsType").val();

    if (this.viewAddNewGoodsTypeField) {
      this.goodsType = $("#" + this.pageId + "commodityListId").val();
    }

    if (this.partyGoodsTypeForSave != null &&
      this.partyGoodsTypeForSave != undefined &&
      this.partyGoodsTypeForSave == "Add New" &&
      this.goodsType != null && this.goodsType != undefined &&
      this.goodsType == "Add New") {

      this.lrDtoForGetUserValues.reportMode = "consignCommodityUpdate";
      this.lrDtoForGetUserValues.column2 = this.newCommodity;
      this.lrDtoForGetUserValues.goodsType = this.newCommodity;

    } else if (this.partyGoodsTypeForSave != null &&
      this.partyGoodsTypeForSave != undefined &&
      this.partyGoodsTypeForSave == "Add New" &&
      this.goodsType != null && this.goodsType != undefined
      && this.goodsType != "Add New") {

      this.lrDtoForGetUserValues.reportMode = "consigneeUpdate";
      this.lrDtoForGetUserValues.column2 = this.goodsType;
      this.lrDtoForGetUserValues.goodsType = this.goodsType;

    } else if (this.partyGoodsTypeForSave != null &&
      this.partyGoodsTypeForSave != undefined &&
      this.partyGoodsTypeForSave != "Add New") {

      //this.lrDtoForGetUserValues.reportMode = "commodityNoUpdate";
      this.lrDtoForGetUserValues.column2 = this.partyGoodsTypeForSave;
      this.lrDtoForGetUserValues.goodsType = this.partyGoodsTypeForSave;

    }

    this.lrDtoForGetUserValues.actualWeight = this.actualWgtForSave;
    this.lrDtoForGetUserValues.chargedWeight = this.chargedWgtForSave;
    if (this.chargedWgtForSave >= (this.actualWgtForSave * 2)) {
      this.lrDtoForGetUserValues.description = "Bulky";
    } else if (this.chargedWgtForSave == this.actualWgtForSave) {
      this.lrDtoForGetUserValues.description = "Actual";
    } else {
      this.lrDtoForGetUserValues.description = "Semi Bulky";
    }

    this.lrDtoForGetUserValues.consigneeName = this.consigneeNameForSave;
    this.lrDtoForGetUserValues.consignorName = this.consignorNameForSave;

    this.lrDtoForGetUserValues.consigneeId = this.selectedConsigneeId;
    this.lrDtoForGetUserValues.consignorId = this.selectedConsignorId;

    this.lrDtoForGetUserValues.goodsValue = this.goodsValueForSave;
    this.lrDtoForGetUserValues.billNumber = this.billNoForSave;
    this.lrDtoForGetUserValues.billDate = this.billDateForSave;
    this.lrDtoForGetUserValues.totalArticles = this.articlesForSave;
    this.lrDtoForGetUserValues.totalArticles = this.articlesForSave;
    if (this.paymentTypeForSave == "toPay") {
      this.lrDtoForGetUserValues.toPay = this.totalAmountForSave;
      this.lrDtoForGetUserValues.paid = 0.0;
    } else {
      this.lrDtoForGetUserValues.toPay = 0.0;
      this.lrDtoForGetUserValues.paid = this.totalAmountForSave;
    }
    this.lrDtoForGetUserValues.totalAmount = this.totalAmountForSave;
    this.lrDtoForGetUserValues.lastUpdatedBy = this.userDataDtoReturnSession.userId;
    this.lrDtoForGetUserValues.agentName = this.agentNameForSave;
    this.lrDtoForGetUserValues.lcChg = this.lcChgForSave;
    this.lrDtoForGetUserValues.bcChg = this.bcChgForSave;
    this.lrDtoForGetUserValues.riskCharge = this.fovChgForSave;
    this.lrDtoForGetUserValues.withOut = this.checkBoxAOCForSave;
    this.lrDtoForGetUserValues.pickLr = this.checkBoxPickUpLrForSave;
    this.lrDtoForGetUserValues.privateMarker = this.privateMarkForSave;
    this.lrDtoForGetUserValues.baseFreight = this.baseFreightForSave;
    this.lrDtoForGetUserValues.gcCharge = this.gcChargeForSave;
    this.lrDtoForGetUserValues.hamaliLR = this.hamaliForSave;
    // not used this not add in ui
    this.lrDtoForGetUserValues.insurance = null;
    this.lrDtoForGetUserValues.aoc = this.aocChgForSave;
    this.lrDtoForGetUserValues.others = this.otherChgForSave;
    this.lrDtoForGetUserValues.frieghtAmt = this.totalAmountForSave;
    this.lrDtoForGetUserValues.serTaxPayBy = 0.0;
    if (this.paymentTypePaid == true) {
      if ($("#" + this.pageId + "serTaxPayingBy").val() == 1) {
        this.lrDtoForGetUserValues.serTaxPayBy = 1;
      } else if ($("#" + this.pageId + "serTaxPayingBy").val() == 2) {
        this.lrDtoForGetUserValues.serTaxPayBy = 2;
      }
    }

    var multipleArtSizes_Save = null;
    multipleArtSizes_Save = this.multiArtSizeList;

    console.log(multipleArtSizes_Save);
    console.log(this.multiArtSizeList);

    if (multipleArtSizes_Save != null && multipleArtSizes_Save != undefined) {
      this.lrDtoForGetUserValues.multiArtSize = multipleArtSizes_Save;
    }

    var multiplePackNature_Save = null;
    multiplePackNature_Save = this.multiPackNatureList;

    console.log(multiplePackNature_Save);
    console.log(this.multiPackNatureList);

    if (multiplePackNature_Save != null && multiplePackNature_Save != undefined) {
      this.lrDtoForGetUserValues.packNatureLr = multiplePackNature_Save;
    }

    this.lrDtoForGetUserValues.packNature = this.packNatureForSave;

    this.lrDtoForGetUserValues.isUnLoadByConsignee = this.checkBoxUnloadedByConsigneeForSave;
    this.lrDtoForGetUserValues.isHandWithCare = this.checkBoxHandleWithCareForSave;
    this.lrDtoForGetUserValues.isCod = this.checkBoxCODForSave;
    this.lrDtoForGetUserValues.codChg = this.codChargeAmountForSave;
    this.lrDtoForGetUserValues.isOrderGoods = this.checkBoxOrderGoodForSave;
    this.lrDtoForGetUserValues.ddAddress = null;
    if (this.doorDeliveryForSave == "yes") {
      this.lrDtoForGetUserValues.ddAddress = this.doorDeliveryAddressForSave;
      this.lrDtoForGetUserValues.doorDeliveryAmount = this.doorDeliveryAmountForSave;
      this.lrDtoForGetUserValues.isDoorDeliv = true;
    } else {
      this.lrDtoForGetUserValues.DoorDelivAmt = 0.0;
      this.lrDtoForGetUserValues.isDoorDeliv = false;
    }

    this.lrDtoForGetUserValues.toBeBilledParty = this.checkBoxToBeBilledForSave;
    this.lrDtoForGetUserValues.gatePass = this.checkBoxGatePassNoForSave;
    this.lrDtoForGetUserValues.ccAttach = this.checkBoxCCAttachForSave;
    this.lrDtoForGetUserValues.selfLr = this.checkBoxSelfLrForSave;
    this.lrDtoForGetUserValues.bankLr = this.bankLrForSave;
    this.lrDtoForGetUserValues.eSugamNo = this.esugamNoForSave;
    this.lrDtoForGetUserValues.eWayBillNo = this.ewayBillNoForSave;
    //pending
    this.lrDtoForGetUserValues.ewayBillValidUpto = this.ewayBillValidUpto;
    this.lrDtoForGetUserValues.eDeclarationCommodity = null;
    this.lrDtoForGetUserValues.chgWtLr = this.articleChargeWgtForSave;
    this.lrDtoForGetUserValues.reason = this.reasonForDeleteForSave;
    this.lrDtoForGetUserValues.gstNoConsignee = this.consigneeGstNumberForSave;
    this.lrDtoForGetUserValues.gstNoConsignor = this.consignorGstNumberForSave;
    this.lrDtoForGetUserValues.remarks = this.remarkForSave;

    this.lrDtoForGetUserValues.companyId = this.userDataDtoReturnSession.companyId;

    this.lrDtoForGetUserValues.listOfmultipleEwayBill = this.multiEwayTableDataList;
    this.lrDtoForGetUserValues.userName = this.userDataDtoReturnSession.userName;

    //CHG-V-LR:01
    if (this.consignorRateFlow) {
      this.lrDtoForGetUserValues.consignorRateId = this.consignorRateId;
    }

    return this.lrDtoForGetUserValues;

  }

  validateNewConsignee() {
    //CHG-V-LREdit:17
    // this.enteredNewConsigneeGSTNo = $("#" + this.pageId + "gstNoConsignee").val();
    this.enteredNewConsigneeGSTNo = $("#" + this.pageId + "gstNoConsignee").val().toString().trim().replace(/\s+/g, '');

    this.enteredNewConsigneeState = $("#" + this.pageId + "consigneeState").val();
    if ((this.enteredNewConsigneeGSTNo == null) || (this.enteredNewConsigneeGSTNo == '')) {
      //CHG-V-LREdit:17
      // swal("Mandatory Field", "Please add New Consignee GST number", "warning");
      swal("Mandatory Field", "Please add New Consignee Aadhar number", "warning");
      return false;
    } else if ((this.enteredNewConsigneeState == null) || (this.enteredNewConsigneeState == '')) {
      swal("Mandatory Field", "Please Select the State", "warning");
      return false;
    }
    //CHG-V-LREdit:17
    else if (($("#" + this.pageId + "searchBy").val() == "aadhar") && (this.enteredNewConsigneeGSTNo.length !== 12 || !/^[2-9][0-9]{11}$/.test(this.enteredNewConsigneeGSTNo))) {
      swal("Invalid", "Invalid Aadhar Number", "warning");
    }
    //CHG-V-LREdit:22
    else if (($("#" + this.pageId + "searchBy").val() == "pan") && (!this.panValidation.test(this.enteredNewConsigneeGSTNo))) {
      swal("Invalid", "Invalid PAN Number", "warning");
      return false;
    } else {
      this.saveNewConsigneeDetails();
    }
  }

  saveNewConsigneeInfo() {
    this.validateDestination = null;

    this.enteredNewConsigneeName = $("#" + this.pageId + "newConsignee").val();
    this.enteredNewConsigneeGSTNo = $("#" + this.pageId + "gstNoConsignee").val();
    this.enteredNewConsigneeAddress = $("#" + this.pageId + "newConsigneeAddress").val();
    this.enteredNewConsigneeState = $("#" + this.pageId + "consigneeState").val();

    this.validateDestination = $("#" + this.pageId + "destination").val();

    this.partyDtoForNewConsignee = new PartyMasterDto();
    this.partyDtoForNewConsignee.companyId = this.userDataDtoReturnSession.companyId;
    this.partyDtoForNewConsignee.consigneeName = this.enteredNewConsigneeName.toUpperCase();
    this.partyDtoForNewConsignee.address = this.enteredNewConsigneeAddress;
    this.partyDtoForNewConsignee.state = this.enteredNewConsigneeState;
    this.partyDtoForNewConsignee.stateCode = this.enteredNewConsigneeStateCode;
    this.partyDtoForNewConsignee.gstNoConsignee = this.enteredNewConsigneeGSTNo.toString().trim().replace(/\s+/g, '');
    this.partyDtoForNewConsignee.status = 'Partial';
    this.partyDtoForNewConsignee.city = this.validateDestination;
    this.partyDtoForNewConsignee.userName = this.userDataDtoReturnSession.userName;

    //CHG-V-LREdit:20
    if ($("#" + this.pageId + "searchBy").val() == "aadhar") {
      this.partyDtoForNewConsignee.consigneeType = 'Aadhar';
    }
    //CHG-V-LREdit:22
    else if ($("#" + this.pageId + "searchBy").val() == "pan") {
      this.partyDtoForNewConsignee.consigneeType = 'Pan';
    }
  }

  saveNewConsigneeDetails() {
    this.saveNewConsigneeInfo();
    this.masterReadService.addConsigneeInLrEntry(this.partyDtoForNewConsignee).subscribe(
      (response) => {
        console.log(response);
        if (response.length == 0) {
          swal("Warning", "Error while saving the new Consignee Details", "warning");
          return false;
        } else {
          this.partyDtoForNewConsigneeSet = response;
          if (this.partyDtoForNewConsigneeSet.status == 'exist') {
            swal("Failure", "Consignee Already Exist!", "warning");
          } else if (this.partyDtoForNewConsigneeSet.status == 'not persisted') {
            swal("Failure", "Ooops..Problem occur while saving Consignee Details..!", "warning");
          } else {
            swal("Success", "New Consignee Name added successfully", "warning");
            this.setNewConsigneeNameDetails(this.partyDtoForNewConsigneeSet);
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Ooops..Problem occur while saving Consignee Details..", "warning");
      },
      () => console.log('done');
  }

  setNewConsigneeNameDetails(partyDtoForNewConsigneeSet) {
    this.selectedConsigneeId = null;
    this.selectedNewConsigneeName = null;
    this.consigneeNameOptions = [];
    this.consigneeNameTA = [];

    this.selectedConsigneeId = partyDtoForNewConsigneeSet.consigneeId;
    this.selectedNewConsigneeName = partyDtoForNewConsigneeSet.consigneeName;

    this.partyDtoForNewConsigneeSetDropDown = new PartyMasterDto();
    this.partyDtoForNewConsigneeSetDropDown.consigneeName = this.selectedNewConsigneeName;
    this.partyDtoForNewConsigneeSetDropDown.consigneeId = this.selectedConsigneeId;

    this.consigneeNameOptions.push(this.partyDtoForNewConsigneeSetDropDown);

    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
      this.consigneeNameTA.push(this.consigneeNameOptions[i]);
    }

    $("#" + this.pageId + "consigneeName").val(partyDtoForNewConsigneeSet.consigneeName);
    this.clearPopUpFieldConsignee();
  }

  ValidateClearNewConsigneeDetails() {
    this.clearPopUpFieldConsignee();
  }

  clearPopUpFieldConsignee() {
    // new consigneee
    this.partyDtoForNewConsignee = new PartyMasterDto();
    this.partyDtoForNewConsigneeSetDropDown = new PartyMasterDto();
    this.partyDtoForNewConsigneeSet = null;
    this.enteredNewConsigneeName = null;
    this.enteredNewConsigneeGSTNo = null;
    this.enteredNewConsigneeAddress = null;
    this.enteredNewConsigneeState = null;
    this.enteredNewConsigneeStateCode = null;
    this.selectedNewConsigneeName = null;

    this.modelConsigneeState = null;

    $("#" + this.pageId + "newConsignee").val("");
    $("#" + this.pageId + "gstNoConsignee").val("");
    $("#" + this.pageId + "newConsigneeAddress").val("");
    $("#" + this.pageId + "consigneeState").val("");
    this.gstNoConsigneeModel = null;
    this.newConsigneeName = null;
  }

  /* consignee pop up end here */

  /* consignor pop up start here */

  validateNewConsignor() {
    //CHG-V-LREdit:17
    // this.enteredNewConsignorGSTNo = $("#" + this.pageId + "gstNoConsignor").val();
    this.enteredNewConsignorGSTNo = $("#" + this.pageId + "gstNoConsignor").val().toString().trim().replace(/\s+/g, '');

    this.enteredNewConsignorState = $("#" + this.pageId + "consignorState").val();
    alert($("#" + this.pageId + "newConsignor").val());
    if (($("#" + this.pageId + "newConsignor").val() == null) || ($("#" + this.pageId + "newConsignor").val() == undefined) ||
      ($("#" + this.pageId + "newConsignor").val() == "")) {
      swal("Mandatory Field", "Please add New Consignor Name", "warning");
      return false;
    } else if (($("#" + this.pageId + "newConsignorAddress").val() == null) || ($("#" + this.pageId + "newConsignorAddress").val() == undefined) ||
      ($("#" + this.pageId + "newConsignorAddress").val() == "")) {
      swal("Mandatory Field", "Please add New Consignor Address", "warning");
      return false;
    } else if ((this.enteredNewConsignorGSTNo == null) || (this.enteredNewConsignorGSTNo == '')) {
      //CHG-V-LREdit:17
      // swal("Mandatory Field", "Please add New Consignor GST number", "warning");
      swal("Mandatory Field", "Please add New Consignor Aadhar number", "warning");
      return false;
    } else if ((this.enteredNewConsignorState == null) || (this.enteredNewConsignorState == '')) {
      swal("Mandatory Field", "Please Select the State", "warning");
      return false;
    }
    //CHG-V-LREdit:17
    else if (($("#" + this.pageId + "selectType").val() == "aadhar") && (this.enteredNewConsignorGSTNo.length !== 12 || !/^[2-9][0-9]{11}$/.test(this.enteredNewConsignorGSTNo))) {
      swal("Invalid", "Invalid Aadhar Number", "warning");
    }
    //CHG-V-LREdit:22
    else if (($("#" + this.pageId + "selectType").val() == "pan") && (!this.panValidation.test(this.enteredNewConsignorGSTNo))) {
      swal("Invalid", "Invalid PAN Number", "warning");
      return false;
    } else {
      this.saveNewConsignorDetails();
    }
  }

  saveNewConsignorInfo() {
    this.enteredNewConsignorName = $("#" + this.pageId + "newConsignor").val();
    this.enteredNewConsignorGSTNo = $("#" + this.pageId + "gstNoConsignor").val();
    this.enteredNewConsignorAddress = $("#" + this.pageId + "newConsignorAddress").val();
    this.enteredNewConsignorState = $("#" + this.pageId + "consignorState").val();
    this.enteredNewConsignorMobileNo = $("#" + this.pageId + "consignorMobileNumber").val();
    this.enteredNewConsignorOfficeNo = $("#" + this.pageId + "consignorOfficeNumber").val();

    this.partyDtoForNewConsignor.companyId = this.userDataDtoReturnSession.companyId;
    this.partyDtoForNewConsignor.consignorName = this.enteredNewConsignorName.toUpperCase();
    this.partyDtoForNewConsignor.address = this.enteredNewConsignorAddress;
    this.partyDtoForNewConsignor.officeNumber = this.enteredNewConsignorOfficeNo;
    this.partyDtoForNewConsignor.state = this.enteredNewConsignorState;
    this.partyDtoForNewConsignor.stateCode = this.enteredNewConsignorStateCode;
    this.partyDtoForNewConsignor.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    this.partyDtoForNewConsignor.consignorId = 0;
    this.partyDtoForNewConsignor.mobileNumber = this.enteredNewConsignorMobileNo;
    this.partyDtoForNewConsignor.gstNoConsignor = this.enteredNewConsignorGSTNo.toString().trim().replace(/\s+/g, '');
    this.partyDtoForNewConsignor.isConfirmed = false;
    this.partyDtoForNewConsignor.isLatest = true;
    this.partyDtoForNewConsignor.userName = this.userDataDtoReturnSession.userName;

    //CHG-V-LREdit:20
    if ($("#" + this.pageId + "selectType").val() == "aadhar") {
      this.partyDtoForNewConsignor.consignorType = 'Aadhar';
    }
    //CHG-V-LREdit:22
    else if ($("#" + this.pageId + "selectType").val() == "pan") {
      this.partyDtoForNewConsignor.consignorType = 'Pan';
    }
  }

  saveNewConsignorDetails() {
    this.saveNewConsignorInfo();
    this.masterReadService.addConsignorInLrEntry(this.partyDtoForNewConsignor).subscribe(
      (response) => {
        console.log(response);
        if (response.length == 0) {
          swal("Warning", "Error while saving the new Consignor Details", "warning");
          return false;
        } else {
          this.partyDtoForNewConsignorSet = response;
          if (this.partyDtoForNewConsignorSet.status == 'exist') {
            swal("Failure", "Consignor Already Exist!", "warning");
          } else if (this.partyDtoForNewConsignorSet.status == 'not persisted') {
            swal("Failure", "Ooops..Problem occur while saving Consignor Details..!", "warning");
          } else {
            swal("Success", "New Consignor Name added successfully", "warning");
            this.getConsignor(this.partyDtoForNewConsignorSet);
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Ooops..Problem occur while saving Consignor Details..", "warning");
      },
      () => console.log('done');
  }

  getConsignor(partyDtoForNewConsignorSet) {
    this.selectedConsignorId = null;
    this.selectedNewConsignorName = null;

    this.consignorNameOptions = [];
    this.consignorNameTA = [];

    this.selectedConsignorId = partyDtoForNewConsignorSet.consignorId;
    this.selectedNewConsignorName = partyDtoForNewConsignorSet.consignorName;

    this.partyDtoForNewConsignorSetDropDown = new PartyMasterDto();
    this.partyDtoForNewConsignorSetDropDown.consignorName = this.selectedNewConsignorName;
    this.partyDtoForNewConsignorSetDropDown.consignorId = this.selectedConsignorId;

    this.consignorNameOptions.push(this.partyDtoForNewConsignorSetDropDown);

    for (let i = 0; i < this.consignorNameOptions.length; i++) {
      this.consignorNameTA.push(this.consignorNameOptions[i]);
    }

    $("#" + this.pageId + "consignorName").val(partyDtoForNewConsignorSet.consigneeName);
    this.clearPopUpFieldConsignor();
  }

  validateClearNewConsignorDetails() {
    this.clearPopUpFieldConsignor();
  }

  clearPopUpFieldConsignor() {
    this.partyDtoForNewConsignor = new PartyMasterDto();
    this.partyDtoForNewConsignorSetDropDown = new PartyMasterDto();
    this.partyDtoForNewConsignorSet = null;
    this.enteredNewConsignorName = null;
    this.enteredNewConsignorGSTNo = null;
    this.enteredNewConsignorAddress = null;
    this.enteredNewConsignorState = null;
    this.enteredNewConsignorStateCode = null;
    this.enteredNewConsignorMobileNo = null;
    this.enteredNewConsignorOfficeNo = null;
    this.selectedNewConsignorName = null;

    this.modelConsignorState = null;

    $("#" + this.pageId + "newConsignor").val('');
    $("#" + this.pageId + "gstNoConsignor").val('');
    $("#" + this.pageId + "newConsignorAddress").val('');
    $("#" + this.pageId + "consignorState").val('');
    $("#" + this.pageId + "consignorMobileNumber").val('');
    $("#" + this.pageId + "consignorOfficeNumber").val('');
    this.newConsignorName = null;
    this.gstNoConsignorModel = null;
  }

  /* consignor pop up end here */

  //Pop up field required
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /* delete */
  validateDeleteBtnDetails() {
    if ($("#" + this.pageId + "editWithLrNumber").val() == null || $("#" + this.pageId + "editWithLrNumber").val() == undefined ||
      $("#" + this.pageId + "editWithLrNumber").val() == "") {
      swal("LR Number", "Please enter the LR Number", "warning");
      return false;
    } else if (this.deleteReasonSelect == null || this.deleteReasonSelect == undefined || this.deleteReasonSelect == "") {
      swal("Reason For Delete", "Please select the reason!", "warning");
      return false;
    } else if (this.lrDeleteMasterPwdReq && this.deleteReasonSelect == "Technical Reason" && ($("#" + this.pageId + "lrDeleteMasterPwd").val() == null || $("#" + this.pageId + "lrDeleteMasterPwd").val() == undefined ||
      $("#" + this.pageId + "lrDeleteMasterPwd").val() == "")) {
      swal("Not Allowed", "Please enter the password to proceed!", "warning");
      return false;
    } else if (this.lrDeleteMasterPwdReq && this.deleteReasonSelect == "Technical Reason") {
      this.validateMasterPwd();
    } else if (this.lrDeleteConsentReq && this.deleteReasonSelect == "Consignor Taken Back" && ($("#" + this.pageId + "lrDeleteConsentCopy").val() == null || $("#" + this.pageId + "lrDeleteConsentCopy").val() == undefined || $("#" + this.pageId + "lrDeleteConsentCopy").val() == "" || !this.isConsentFileUploaded)) {
      swal("Not Allowed", "Please upload the Consent Copy to proceed!", "warning");
      return false;
    } else if (this.lrDeleteConsentReq && this.deleteReasonSelect == "Consignor Taken Back" && ($("#" + this.pageId + "lrDeleteOtp").val() == null || $("#" + this.pageId + "lrDeleteOtp").val() == undefined || $("#" + this.pageId + "lrDeleteOtp").val() == "")) {
      swal("Not Allowed", "Please enter the the PIN to proceed!", "warning");
      return false;
    } else if (this.lrDeleteConsentReq && this.deleteReasonSelect == "Consignor Taken Back") {
      this.validateDeleteOtp();
    } else {
      this.lrDeleteAdditionalValidations();
    }
  }

  lrDeleteAdditionalValidations() {
    if ($("#" + this.pageId + "reasonForDelete").val() == null || $("#" + this.pageId + "reasonForDelete").val() == undefined ||
      $("#" + this.pageId + "reasonForDelete").val() == "") {
      swal("Remarks For Delete", "Please specify the remarks for deletion", "warning");
      return false;
    } else {
      //alert(this.lrdr);
      //this.lrdr = 4;
      if (this.lrdr != null && this.lrdr != undefined && this.lrdr == 6) {
        swal("Not Allowed", "LR Delete is Blocked..Contact Administrator", "warning");
        return false;
      }
      /* else if (this.lrdr != null && this.lrdr != undefined && this.lrdr == 5 &&
         this.isDeleteAllowSet2(this.lrDtoForGetLRNumberDetailsReturn)) {
         swal("Not Allowed", "LR Delete is Blocked..Contact Administrator", "warning");
         return false;
       } */
      else if (this.lrdr != null && this.lrdr != undefined && this.lrdr == 5 &&
        this.isDeleteAllowSet2(this.lrDtoForGetLRNumberDetailsReturn) &&
        this.isDeleteAllowSet1(this.lrDtoForGetLRNumberDetailsReturn) &&
        this.isDeleteAllowSet4(this.lrDtoForGetLRNumberDetailsReturn) &&
        this.isDeleteAllowSet3(this.lrDtoForGetLRNumberDetailsReturn) &&
        this.isDeleteAllowSet5(this.lrDtoForGetLRNumberDetailsReturn)) {
        //swal("Not Allowed", "LR Delete is Blocked..Contact Administrator", "warning");
        //return false;
        this.confirmDelete();
      } else if (this.lrdr != null && this.lrdr != undefined && this.lrdr == 4 &&
        this.isDeleteAllowSet1(this.lrDtoForGetLRNumberDetailsReturn) &&
        this.isDeleteAllowSet4(this.lrDtoForGetLRNumberDetailsReturn) &&
        this.isDeleteAllowSet3(this.lrDtoForGetLRNumberDetailsReturn) &&
        this.isDeleteAllowSet5(this.lrDtoForGetLRNumberDetailsReturn)) {
        this.confirmDelete();
      } else if (this.lrdr != null && this.lrdr != undefined && this.lrdr == 3 &&
        this.isDeleteAllowSet3(this.lrDtoForGetLRNumberDetailsReturn) &&
        this.isDeleteAllowSet4(this.lrDtoForGetLRNumberDetailsReturn) &&
        this.isDeleteAllowSet5(this.lrDtoForGetLRNumberDetailsReturn)) {
        this.confirmDelete();
      } else if (this.lrdr != null && this.lrdr != undefined && this.lrdr == 2 &&
        this.isDeleteAllowSet3(this.lrDtoForGetLRNumberDetailsReturn) &&
        this.isDeleteAllowSet5(this.lrDtoForGetLRNumberDetailsReturn)) {
        this.confirmDelete();
      } else if (this.lrdr != null && this.lrdr != undefined && this.lrdr == 1) {
        if (this.lrDtoForGetLRNumberDetailsReturn.cashMemoNumber != null &&
          this.lrDtoForGetLRNumberDetailsReturn.cashMemoNumber != undefined) {
          this.msgAlert("Cashmemo generated for this LR..Sure U want to Delete");
        } else if (this.lrDtoForGetLRNumberDetailsReturn.fortNightStmtNo != null &&
          this.lrDtoForGetLRNumberDetailsReturn.fortNightStmtNo != undefined) {
          this.msgAlert("LR Delete is Blocked due to Fortnight Statement Generated for this LR.");
        } else if (this.lrDtoForGetLRNumberDetailsReturn.memoInvId != null &&
          this.lrDtoForGetLRNumberDetailsReturn.memoInvId != undefined) {
          this.msgAlert("Booking Memo Invoice generated for this LR..Sure U want to Delete");
        } else if (this.lrDtoForGetLRNumberDetailsReturn.labourHamaliStmt != null &&
          this.lrDtoForGetLRNumberDetailsReturn.labourHamaliStmt != undefined) {
          this.msgAlert("Labour Hamali Statement generated for this LR..Sure U want to Delete");
        } else if (this.lrDtoForGetLRNumberDetailsReturn.bookingAgntStmt != null &&
          this.lrDtoForGetLRNumberDetailsReturn.bookingAgntStmt != undefined) {
          this.msgAlert("Booking Agent Statement generated for this LR..Sure U want to Delete");
        } else if (this.lrDtoForGetLRNumberDetailsReturn.invoiceNumber != null &&
          this.lrDtoForGetLRNumberDetailsReturn.invoiceNumber != undefined) {
          this.msgAlert("Invoice generated for this LR..Sure U want to Delete");
        } else if (this.lrDtoForGetLRNumberDetailsReturn.isPrint != null &&
          this.lrDtoForGetLRNumberDetailsReturn.isPrint != undefined &&
          this.lrDtoForGetLRNumberDetailsReturn.isPrint) {
          this.msgAlert("Booking Cashmemo Printed for this LR..Sure U want to Delete");
        } else {
          this.confirmDelete();
        }
      }
    }
  }

  msgAlert(message) {
    //alert("S");
    swal({
      title: "Alert",
      text: message,
      icon: "info",
      buttons: ["No", "Yes"]
    }).then((isConfirm) => {
      if (isConfirm) {
        this.confirmDelete();
      }
    });
  }

  confirmDelete() {
    swal({
      title: "Confirm Delete",
      text: "Sure U want to delete the LR",
      icon: "info",
      buttons: ["No", "Yes"]
    }).then((isConfirm) => {
      if (isConfirm) {
        this.deleteLRDetails();
      }
    });
  }

  deleteLRDetails() {
    this.lrDtoForDelete = new LRDto();
    this.deleteLRNumber = null;
    this.deleteReason = null;

    this.deleteLRNumber = $("#" + this.pageId + "editWithLrNumber").val();
    this.deleteReason = $("#" + this.pageId + "reasonForDelete").val();
    this.lrDtoForDelete.lrNumber = this.deleteLRNumber;
    this.lrDtoForDelete.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoForDelete.status = "delete";
    this.lrDtoForDelete.reason = this.deleteReason;
    this.lrDtoForDelete.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoForDelete.mode = this.deleteReasonSelect;
    this.lrDtoForDelete.consigneeMobileNo = this.lrDtoForGetLRNumberDetailsReturn.mobileNum;
    this.lrDtoForDelete.consignorMobileNo = this.lrDtoForGetLRNumberDetailsReturn.consignorMobileNo;
    this.lrDtoForDelete.code = this.enteredOtp;
    this.lrDtoForDelete.imgURLTDS = this.consentUploadedFilename;
    this.showSpinnerForAction = true;
    this.lrService.deleteLR(this.lrDtoForDelete).subscribe(
      (response) => {
        console.log(response);
        if (response.status == "success") {
          swal("LR No " + $("#" + this.pageId + "editWithLrNumber").val() + "", "Deleted Successfully", "success");
          this.modalService.dismissAll();
          this.clearFieldAll();
        } else if (response.status == "notfound") {
          swal("LR No " + $("#" + this.pageId + "editWithLrNumber").val() + "", "Not Found", "success");
        } else {
          swal("LR No", "Failed to delete this LR Number", "success");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While deleteLR Details", "info");
      },
      () => console.log('done');

  }

  isDeleteAllowSet1(lrDtoForGetLRNumberDetailsReturn) {
    var isAllow = false;
    if (lrDtoForGetLRNumberDetailsReturn.memoInvId != null) {
      swal("Not Allowed", "LR Delete is Blocked due to Booking Memo Invoice generated for this LR..Contact Super Administrator", "warning");
    } else if (lrDtoForGetLRNumberDetailsReturn.labourHamaliStmt != null) {
      swal("Not Allowed", "LR Delete is Blocked due to Labour Hamali Statement generated for this LR..Contact Super Administrator", "warning");
    } else if (lrDtoForGetLRNumberDetailsReturn.bookingAgntStmt != null) {
      swal("Not Allowed", "LR Delete is Blocked due to Booking Agent Statement generated for this LR..Contact Super Administrator", "warning");
    } else {
      isAllow = true;
    }
    return isAllow;
  }

  isDeleteAllowSet2(lrDtoForGetLRNumberDetailsReturn) {
    var isAllow = false;
    if (lrDtoForGetLRNumberDetailsReturn.invoiceNumber != null) {
      swal("Not Allowed", "LR Delete is Blocked due to Invoice generated for this LR..Contact Administrator", "warning");
    } else {
      isAllow = true;
    }
    return isAllow;
  }

  isDeleteAllowSet3(lrDtoForGetLRNumberDetailsReturn) {
    var isAllow = false;
    if (lrDtoForGetLRNumberDetailsReturn.fortNightStmtNo != null) {
      swal("Not Allowed", "LR Delete is Blocked due to Destination Statement generated for this LR..Contact Super Administrator", "warning");
    } else {
      isAllow = true;
    }
    return isAllow;
  }

  isDeleteAllowSet4(lrDtoForGetLRNumberDetailsReturn) {
    var isAllow = false;
    if (lrDtoForGetLRNumberDetailsReturn.isPrint != null &&
      lrDtoForGetLRNumberDetailsReturn.isPrint) {
      swal("Not Allowed", "LR Delete is Blocked due to Booking Cashmemo Printed for this LR..Contact Administrator", "warning");
    } else {
      isAllow = true;
    }
    return isAllow;
  }

  isDeleteAllowSet5(lrDtoForGetLRNumberDetailsReturn) {
    var isAllow = false;
    if (lrDtoForGetLRNumberDetailsReturn.cashMemoNumber != null) {
      swal("Not Allowed", "LR Delete is Blocked due to Cashmemo generated for this LR..Contact Administrator", "warning");
    } else {
      isAllow = true;
    }
    return isAllow;
  }


  /* multiple article start */

  openArticeSizePopUp() {
    localStorage.clear();
    localStorage.setItem('MultipleArticleSizeData', JSON.stringify("MultipleArticleSizeData"));
    localStorage.setItem('MultipleArticleSizeTotalArticle', JSON.stringify($("#" + this.pageId + "article").val()));
    if (this.multiArtSizeList != null &&
      this.multiArtSizeList != undefined &&
      this.multiArtSizeList.length > 0) {
      localStorage.setItem('MultipleArticleSizeListData', JSON.stringify(this.multiArtSizeList));
    }
    const dialogRef = this.dialog.open(MultipleArticleSizeComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.returnListOfMultiArtSize = [];
      if (result != null && result != undefined &&
        result.length > 0) {
        let obj = JSON.parse(result);
        var multipleArtSizes = null;
        for (let i = 0; i < obj.length; i++) {
          if (i == 0) {
            multipleArtSizes = obj[i].articlesInMultiple + "_" +
              obj[i].length + "_" +
              obj[i].breadth + "_" +
              obj[i].height + "#";;
          } else {
            multipleArtSizes += obj[i].articlesInMultiple + "_" +
              obj[i].length + "_" +
              obj[i].breadth + "_" +
              obj[i].height + "#";;
          }
        }
        console.log(multipleArtSizes);
        this.multiArtSizeList = null;
        this.multiArtSizeList = multipleArtSizes;
        //console.log(this.returnListOfMultiArtSize);
      } else {
        this.returnListOfMultiArtSize = [];
        //console.log(this.returnListOfMultiArtSize);
      }
    });
  }

  /* multipe articl end */

  /* Drop Down Of Good type start */
  clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelCommodityList = e.item;
    $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
  }

  getCommodityMasterDetailsList = () => {
    this.rateMasterDto = new RateMasterDto();
    this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
    this.rateMasterDto.mode = "mainConsigneeMaster";
    this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
      (response) => {
        if (response.length == 0) {
          this.rateMasterDtoOptions = [];
          this.commodityListTA = [];
        } else {
          this.rateMasterDtoOptions = [];
          this.commodityListTA = [];
          this.rateMasterDtoOptions = response;
          for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Error While Getting Commodity Master Details", "warning");
      },
      () => console.log('done');
  };
  /* Drop Down Of Good type end */

  /* multiple pack nature start */

  multiplePackNaturePopUp() {
    localStorage.clear();
    localStorage.setItem('MultiplePackNatureData', JSON.stringify("MultiplePackNatureData"));
    if (this.multiPackNatureList != null &&
      this.multiPackNatureList != undefined &&
      this.multiPackNatureList.length > 0) {
      localStorage.setItem('MultiplePackNatureListData', JSON.stringify(this.multiPackNatureList));
    }
    const dialogRef = this.dialog.open(MultiplePackNatureComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.returnListOfMultiPackNature = [];
      if (result != null && result != undefined &&
        result.length > 0) {
        let obj = JSON.parse(result);
        var multiplePackNatureLr = null;
        for (let i = 0; i < obj.length; i++) {
          if (i == 0) {
            multiplePackNatureLr = obj[i].multiplePackNatureArt + "_" +
              obj[i].multiplePackNatureNew + "#";;
          } else {
            multiplePackNatureLr += obj[i].multiplePackNatureArt + "_" +
              obj[i].multiplePackNatureNew + "#";;
          }
        }
        console.log(multiplePackNatureLr);
        this.multiPackNatureList = null;
        this.multiPackNatureList = multiplePackNatureLr;
        //console.log(this.returnListOfMultiPackNature);
      } else {
        this.returnListOfMultiPackNature = [];
        //console.log(this.returnListOfMultiPackNature);
      }
    });
  }
  /* multiple pack nature end */

  clearFieldAll() {
    $("#" + this.pageId + "editWithLrNumber").prop('readonly', false);
    this.lrNumberForSave = null;
    this.destinationForSave = null;
    this.agentNameForSave = null;
    this.partyGoodsTypeForSave = null;
    this.bookingDateForSave = null;
    this.articlesForSave = null;
    this.commodityDescForSave = null;
    this.actualWgtForSave = null;
    this.chargedWgtForSave = null;
    this.packNatureForSave = null;
    this.goodsValueForSave = null;
    this.privateMarkForSave = null;
    this.articleSize = null;
    this.consignorGstNumberForSave = null;
    this.consignorNameForSave = null;
    this.consigneeGstNumberForSave = null;
    this.consigneeNameForSave = null;
    this.baseFreightForSave = null;
    this.hamaliForSave = null;
    this.gcChargeForSave = null;
    this.lcChgForSave = null;
    this.bcChgForSave = null;
    this.fovChgForSave = null;
    this.aocChgForSave = null;
    this.otherChgForSave = null;
    this.doorDeliveryForSave = null;
    this.doorDeliveryAddressForSave = null;
    this.doorDeliveryAmountForSave = null;
    this.totalAmountForSave = null;
    this.billNoForSave = null;
    this.billDateForSave = null;
    this.paymentTypeForSave = null;
    this.bankLrForSave = null;
    this.esugamNoForSave = null;
    this.ewayBillNoForSave = null;
    this.ewayBillValidUpto = null;
    this.articleChargeWgtForSave = null;
    this.packNatureLabelForSave = null;
    this.remarkForSave = null;
    this.checkBoxAOCForSave = null;
    this.checkBoxToBeBilledForSave = null;
    this.checkBoxPickUpLrForSave = null;
    this.checkBoxUnloadedByConsigneeForSave = null;
    this.checkBoxHandleWithCareForSave = null;
    this.checkBoxGatePassNoForSave = null;
    this.checkBoxCCAttachForSave = null;
    this.checkBoxSelfLrForSave = null;
    this.checkBoxCODForSave = null;
    this.codChargeAmountForSave = null;
    this.checkBoxOrderGoodForSave = null;
    this.reasonForDeleteForSave = null;

    this.validateCommodityCpCode = null;
    this.partyGoodsType = null;
    this.goodsType = null;
    this.newCommodity = null;
    this.lrNo = null;
    this.selectedConsigneeId = null;
    this.selectedConsignorId = null;

    // drop down
    this.modelDestination = null;
    this.modelAgentName = null;
    this.modelCommodityDescription = null;

    //date of ng model
    this.modelBookingDate = null;
    this.modelEwayBillValidUpto = null;
    this.modelBillDate = null;

    //view hide show field
    this.doorDeliveryYes = false;
    this.paymentTypePaid = false;
    this.viewCODChargeAmtField = false;
    //btn
    this.viewAutoLRPrintBtn = false;

    this.validateLRNumber = null;
    this.validateConsigneeGstNo = null;
    this.validateConsignorGstNo = null;
    this.validateDestination = null;

    this.validateCommodityCpCode = null;
    this.partyGoodsType = null;
    this.goodsType = null;
    this.newCommodity = null;

    this.lrDtoForGetUserValues = new LRDto();
    this.lrDtoForGetLRNumberDetails = new LRDto();
    this.lrDtoForGetLRNumberDetailsReturn = null;
    this.partyMasterDtoForConsignee = new PartyMasterDto();

    this.lrDtoForSaveReturn = new LRDto();
    this.lrDtoForSaveResponseReturn = null;


    //this.viewDeleteBtn: boolean = false;

    $("input[type=text], textarea").val("");
    $("input[type=number], textarea").val("");

    $("#" + this.pageId + "totalAmount").val('');
    $("#" + this.pageId + "billNoId").val('');
    $("#" + this.pageId + "billDate").val("");

    $("#" + this.pageId + "paymentType").val("toPay");
    $("#" + this.pageId + "bankLrId").val("0");
    $("#" + this.pageId + "doorDeliveryMode").val("no");

    //check box
    $("#" + this.pageId + "checkboxAoc").prop('checked', false);
    $("#" + this.pageId + "checkboxToBeBilled").prop('checked', false);
    $("#" + this.pageId + "checkboxPickupLr").prop('checked', false);
    $("#" + this.pageId + "checkboxunloadedByConsignee").prop('checked', false);
    $("#" + this.pageId + "checkboxHandleWithCare").prop('checked', false);
    $("#" + this.pageId + "checkboxGatePass").prop('checked', false);
    $("#" + this.pageId + "checkboxCcAttach").prop('checked', false);
    $("#" + this.pageId + "checkboxSelfLr").prop('checked', false);
    $("#" + this.pageId + "checkboxCOD").prop('checked', false);
    $("#" + this.pageId + "checkboxOrderGoods").prop('checked', false);

    //this.viewDeleteBtn = false;
    this.viewAddNewGoodsTypeField = false;
    this.modelCommodityList = null;
    $("#" + this.pageId + "paymentType").prop('disabled', false);

    this.viewAddArticleSize = 'Add New';
    this.viewAddMultiPackNature = 'Add New';

    this.multiArtSizeList = null;
    this.returnListOfMultiArtSize = null;

    this.multiPackNatureList = null;
    this.returnListOfMultiPackNature = null;

    this.validateForDestinationValue = null;

    $("#" + this.pageId + "destination").prop('disabled', false);
    $("#" + this.pageId + "agentName").prop('disabled', false);
    $("#" + this.pageId + "consignorName").prop('disabled', false);
    //CHG-V-LREdit:02
    if (this.blockEditBkgDate) {
      $("#" + this.pageId + "bookingDates").prop('disabled', true);
    } else {
      $("#" + this.pageId + "bookingDates").prop('disabled', false);
    }
    $("#" + this.pageId + "packNature").prop('readonly', false);

    $("#" + this.pageId + "article").prop('readonly', false);
    $("#" + this.pageId + "actualWeight").prop('readonly', false);
    $("#" + this.pageId + "chargedWeight").prop('readonly', false);
    $("#" + this.pageId + "goodsValue").prop('readonly', false);
    $("#" + this.pageId + "pvtMarkId").prop('readonly', false);
    $("#" + this.pageId + "billNoId").prop('readonly', false);
    $("#" + this.pageId + "billDate").prop('readonly', false);
    $("#" + this.pageId + "paymentType").prop('readonly', false);
    $("#" + this.pageId + "totalAmount").prop('readonly', true);

    $("#" + this.pageId + "baseFreight").prop('readonly', false);
    $("#" + this.pageId + "hamaliId").prop('readonly', false);
    $("#" + this.pageId + "gcCharges").prop('readonly', false);
    $("#" + this.pageId + "lcChgId").prop('readonly', false);
    $("#" + this.pageId + "bcChgId").prop('readonly', false);
    $("#" + this.pageId + "fovChgId").prop('readonly', true);
    $("#" + this.pageId + "aocChgId").prop('readonly', false);
    $("#" + this.pageId + "othersChgId").prop('readonly', false);

    $("#" + this.pageId + "doorDeliveryMode").prop('readonly', false);
    $("#" + this.pageId + "doorDeliveryAmount").prop('readonly', false);

    this.isMainCommodityValid = false;
    this.stateByDest = null;
    this.chgWgtReadOnlyEdit = false;
    this.deleteReasonSelect = null;
    this.lrDeleteTechReason = false;
    this.lrDeleteReturnBack = false;
    this.validPassword = false;
    this.passwordEntered = null;
    this.requestDto = new RequestDto();
    this.validOtp = false;
    this.enteredOtp = null;
    this.consentUploadedFilename = null;
    $("#" + this.pageId + "deleteBtn").prop('disabled', true);
    this.editSelected = false;
    this.editTableId = null;
    this.enteredMultiEwayBillNo = null;
    this.newAttributeMultiEway = {};
    this.eWayBillValidUptoDateMultiModel = null;
    this.multiEwayTableDataList = [];
    $("#" + this.pageId + "ewayBillValidUpto").val('');
    $("#" + this.pageId + "bookingDates").val('');
    this.clearMulEway();

    //CHG-V-LREdit:23
    this.isSizeCommodityExceed = false;
    this.isPerKgLimitTaken = false;
    this.consignorRateFlowSet = false;
    this.consignorRateFov = null;
    this.consignorRateId = 0;
  }

  clearFieldBtn() {
    this.clearFieldAll();
  }

  lrRePrint() {
    this.lrNumberForSave = null;
    this.lrNumberForSave = $("#" + this.pageId + "editWithLrNumber").val();
    this.getDataForLrPrint();
  }

  getDataForLrPrint() {
    if (this.lrNumberForSave == null || this.lrNumberForSave == undefined || this.lrNumberForSave == '') {
      swal("Not allowed", "Please enter the Lr No", "info");
      return false;
    } else {
      this.lrDtoAutoLrPrint = new LRDto();
      this.lrDtoAutoLrPrint.lrNumber = this.lrNumberForSave;
      this.lrDtoAutoLrPrint.companyId = this.userDataDtoReturnSession.companyId;
      this.showSpinnerForAction = true;
      this.hireslipService.getAutoLrDetails(this.lrDtoAutoLrPrint).subscribe(
        (response) => {
          this.showSpinnerForAction = false;
          console.log(response);
          if (response.lrNumber != '' && response.lrNumber != null) {
            this.getvalueForLrPrint = '';
            this.getvalueForLrPrint = response;
            this.setValueForLrPrint = new LRDto();
            this.setValueForLrPrint = this.getvalueForLrPrint;
            this.setValueForLrPrint.lrEntryPrintMode = 'original';
            this.printMethod(this.setValueForLrPrint);
          } else {
            swal("Empty", "No Data Found", "info");
          }
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinnerForAction = false;
          swal("Error", "", "info");
        }, () => console.log('done');
    }
  }

  printMethod(setValueForLrPrint) {
    swal({
      title: "Print Confirmation",
      text: "Sure You Want To Take All 3 Copies Print Out Of LR No:" + this.lrNumberForSave,
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((All) => {
      if (All) {
        //For All 
        if ((this.getvalueForLrPrint.lrConsigneeCopy == false) && (this.getvalueForLrPrint.lrConsignorCopy == false) && (this.getvalueForLrPrint.lrLorryCopy == false)) {
          this.printcopy(setValueForLrPrint, "ALL")
        } else {
          swal("Alert", "LR Print is Blocked, Please Contact Booking Administrator", "info");
        }

      } else {

        if (this.getvalueForLrPrint.lrConsignorCopy == false) {
          console.log('consignor');
          this.printTypeMtdForConsignor(setValueForLrPrint, "CONSIGNOR COPY");
        } else {
          if (this.getvalueForLrPrint.lrConsigneeCopy == false) {
            console.log('consignee');
            this.printTypeMtdForConsignee(setValueForLrPrint, "CONSIGNEE COPY");
          } else {
            if (this.getvalueForLrPrint.lrLorryCopy == false) {
              console.log('lorry');
              this.printTypeMtdForLorry(setValueForLrPrint, "LORRY COPY");
            }
          }
        }

      }
    });
  }

  printTypeMtdForConsignor(setValueForLrPrint, lRType) {

    swal({
      title: "Print Confirmation",
      text: "Sure You Want To Take " + lRType + " Print Out Of LR No:" + this.lrNumberForSave,
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((consignor) => {
      if (consignor) {
        this.printcopy(setValueForLrPrint, lRType)
      } else {
        if (this.getvalueForLrPrint.lrConsigneeCopy == false) {
          console.log('consignee');
          this.printTypeMtdForConsignee(setValueForLrPrint, "CONSIGNEE COPY");
        } else {
          if (this.getvalueForLrPrint.lrLorryCopy == false) {
            console.log('lorry');
            this.printTypeMtdForLorry(setValueForLrPrint, "LORRY COPY");
          }
        }

      }
    });
  }
  printTypeMtdForConsignee(setValueForLrPrint, lRType) {

    swal({
      title: "Print Confirmation",
      text: "Sure You Want To Take " + lRType + " Print Out Of LR No:" + this.lrNumberForSave,
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((consignee) => {
      if (consignee) {
        this.printcopy(setValueForLrPrint, lRType)
      } else {
        if (this.getvalueForLrPrint.lrLorryCopy == false) {
          console.log('lorry');
          this.printTypeMtdForLorry(setValueForLrPrint, "LORRY COPY");
        }
      }
    });
  }
  printTypeMtdForLorry(setValueForLrPrint, lRType) {

    swal({
      title: "Print Confirmation",
      text: "Sure You Want To Take " + lRType + " Print Out Of LR No:" + this.lrNumberForSave,
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((lorry) => {
      if (lorry) {
        this.printcopy(setValueForLrPrint, lRType)
      } else {
      }
    });
  }
  printcopy(setValueForLrPrint, lRType) {
    localStorage.clear();
    localStorage.setItem('printAfterSaveFromAutomatedSearchData', JSON.stringify(setValueForLrPrint));
    localStorage.setItem('printAfterSaveFromAutomatedSearchType', JSON.stringify(lRType));
    this.viewLrEntryPrint = true;
    this.automatedSearch = false;
    window.addEventListener('afterprint', (onclick) => {
      if (this.viewLrEntryPrint) {
        this.viewLrEntryPrint = false;
        this.automatedSearch = true;
        localStorage.clear();
        this.lrNumberForSave = null;
        this.getvalueForLrPrint = '';
        this.setValueForLrPrint = new LRDto();
      }
    });
  }

  validateDestinationSelection() {
    this.isDestValid = false
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    //alert(this.selectedDestination);
    for (let i = 0; i < this.destinationOptions.length; i++) {
      if (this.destinationOptions[i].destination.toLowerCase == this.selectedDestination.toLowerCase) {
        this.isDestValid = true;
      }
    }
    if (this.isDestValid == false) {
      swal({
        title: "Not Allowed",
        text: "Selected destination " + this.selectedDestination + " is not valid, please check and select again!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  getBangaloreConsigneeCommodityConsignee = () => {
    this.getBangaloreConsigneeCommodityConsigneeInfo();
    this.masterReadService.getCityMainCommodityDetailsV2(this.partyDtoForConsignee).subscribe(
      (response) => {
        this.bangaloreConsigneeCommdityOptions = '';
        this.partyCommodityTA = [];
        if (response.length > 0) {
          this.bangaloreConsigneeCommdityOptions = response;
          for (let i = 0; i < this.bangaloreConsigneeCommdityOptions.length; i++) {
            this.partyCommodityTA.push(this.bangaloreConsigneeCommdityOptions[i].multipleCommodity);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  getBangaloreConsigneeCommodityConsigneeInfo() {
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();

    this.partyDtoForConsignee = new PartyMasterDto();
    this.partyDtoForConsignee.mode = "bangaloreAllCommodityLREntry";
    this.partyDtoForConsignee.state = this.stateByDest;
    this.partyDtoForConsignee.subStation = this.selectedAgentName;
    this.partyDtoForConsignee.consigneeId = this.selectedConsigneeId;
    this.partyDtoForConsignee.companyId = this.userDataDtoReturnSession.companyId;
    this.partyDtoForConsignee.city = this.selectedDestination;
    this.partyDtoForConsignee.mainStation = this.selectedAgentName;
  }

  validateMainCommoditySelection() {
    this.isMainCommodityValid = false
    this.selectedPartyCommodity = $("#" + this.pageId + "partyGoodsType").val();
    //alert(this.selectedPartyCommodity);
    for (let i = 0; i < this.partyCommodityTA.length; i++) {
      if (this.partyCommodityTA[i] == this.selectedPartyCommodity) {
        this.isMainCommodityValid = true;
      }
    }
  }
  //CHG-V-LREdit:01
  toggleEditableForSelfLr(event) {
    console.log('toggleEditableForSelfLr');
    if (event.target.checked) {
      this.selectedDestination = $("#" + this.pageId + "destination").val();
      console.log(this.selectedDestination.toLowerCase());
      if (this.selectedDestination.toLowerCase() == 'chennai') {
        $("#" + this.pageId + "checkboxSelfLr").prop("checked", false);
        swal("Not Allowed", "Self LR Booking is not allowed for Chennai destination, please contact Admin if any!", "warning");
      }
    }
  }

  enableEwaybillOnlyEdit() {
    this.setReadOnlyNewFields();
    this.setReadOnlyFields();
    $("#" + this.pageId + "eWayBillNo").prop('readonly', false);
    $("#" + this.pageId + "ewayBillValidUpto").prop('readonly', false);

    $("#" + this.pageId + "paymentType").prop('disabled', true);
    $("#" + this.pageId + "doorDeliveryMode").prop('disabled', true);
    $("#" + this.pageId + "bankLrId").prop('disabled', true);

    $("#" + this.pageId + "consignorGstNumber").prop('readonly', true);
    $("#" + this.pageId + "consignorName").prop('readonly', true);
    $("#" + this.pageId + "consigneeGstNumber").prop('readonly', true);
    $("#" + this.pageId + "consigneeName").prop('readonly', true);
  }

  sendNotoficationOnLRDelete(contentDeleteNotofication) {
    this.modalService.open(contentDeleteNotofication, { centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    )
  }

  lrDeleteRadioEvent(value) {
    console.log(value);
    this.deleteReasonSelect = value;
    if (this.deleteReasonSelect == "Technical Reason") {
      this.lrDeleteTechReason = true;
      this.lrDeleteReturnBack = false;
    } else if (this.deleteReasonSelect == "Consignor Taken Back") {
      this.lrDeleteTechReason = false;
      this.lrDeleteReturnBack = true;
    }
  }

  validateMasterPwd() {
    this.validPassword = false;
    this.requestDto = new RequestDto();
    this.passwordEntered = $("#" + this.pageId + "lrDeleteMasterPwd").val();
    this.requestDto.param1 = this.userDataDtoReturnSession.office;
    this.requestDto.companyId = this.userDataDtoReturnSession.companyId;
    this.requestDto.param2 = this.passwordEntered;
    this.requestDto.userId = this.userDataDtoReturnSession.userId;
    this.masterService.validateLRDeleteMasterPwd(this.requestDto).subscribe(
      (response) => {
        if (response.status == "Valid") {
          this.validPassword = true;
          this.lrDeleteAdditionalValidations();
        } else {
          this.validPassword = false;
          swal("Invalid Password", "Entered master password is incorrect, please try again or contact admin!", "warning");
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server problem occurred!", "error");
      }, () => console.log('done');
  }

  onFileChangedConsent(event) {
    this.selectedConsentCopyForUpload = event.target.files[0];
    console.log(this.selectedConsentCopyForUpload);
    console.log(event.target.files.length);
    console.log(event.target.files[1]);
  }

  uploadBtnConsentCopy() {
    this.isConsentFileUploaded = false;
    this.consentUploadedFilename = null;
    this.deleteLRNumber = $("#" + this.pageId + "editWithLrNumber").val();
    console.log($("#" + this.pageId + "lrDeleteConsentCopy").val());
    if ($("#" + this.pageId + "lrDeleteConsentCopy").val() != null && $("#" + this.pageId + "lrDeleteConsentCopy").val() != "") {
      this.showSpinnerForActionForUpload = true;
      console.log(this.selectedConsentCopyForUpload.type);
      if ((this.selectedConsentCopyForUpload.type != null &&
        (this.selectedConsentCopyForUpload.type == "image/jpeg" || this.selectedConsentCopyForUpload.type == "image/jpg" ||
          this.selectedConsentCopyForUpload.type == "image/png" || this.selectedConsentCopyForUpload.type == "application/pdf"))) {
        var splitType = this.selectedConsentCopyForUpload.type.split("/");
        const commonListAdvancePayment = "vehicledriverdocs&&lrdeleteconsent&&" + this.deleteLRNumber + "&&" + splitType[1] + "&&" + this.deleteLRNumber + "";
        const formData = new FormData();
        formData.append(commonListAdvancePayment, commonListAdvancePayment);
        formData.append('lrdeleteconsentuplaod', this.selectedConsentCopyForUpload, this.selectedConsentCopyForUpload.name);

        this.masterReadService.uploadFile(formData).subscribe(
          (response) => {
            if (response) {
              console.log(response);
              console.log(response[0].url);
              this.consentUploadedFilename = this.deleteLRNumber + "_" + this.selectedConsentCopyForUpload.name;
              this.showSpinnerForActionForUpload = false;
              if (response[0] != "Error") {
                swal("File Upload", "File Uploaded Succcessfully", "success");
                this.isConsentFileUploaded = true;
                //this.updateAocLrUploadService(response[0].url, aocLrPopUpTemplate, confirmGodownPopUpTemplate);
              } else {
                swal("ERROR", "Correct the File and Upload Again", "error");
                this.isConsentFileUploaded = false;
              }
            }
            this.changeDetectorRef.detectChanges();
          }), (error) => {
            this.showSpinnerForActionForUpload = false;
            swal("Error", "Server Error While Uploading File", "info");
          }, () => console.log('done');
      } else {
        this.showSpinnerForActionForUpload = false;
        swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
      }
    }
  }

  clearUploadConsentFields() {
    $("#" + this.pageId + "lrDeleteConsentCopy").val('');
    this.selectedConsentCopyForUpload = null;
    this.showSpinnerForActionForUpload = false;
  }

  sendLrDeleteOtpToConsignee(type) {
    this.validateLRNumber = null;
    this.validateLRNumber = $("#" + this.pageId + "editWithLrNumber").val();
    this.requestDto = new RequestDto();
    this.consigneeMobileNumber = $("#" + this.pageId + "consigneeMobileNo").val();
    this.requestDto.type = type;
    this.requestDto.mobileNo = this.consigneeMobileNumber;
    this.requestDto.lrNumber = this.validateLRNumber;
    this.requestDto.userId = this.userDataDtoReturnSession.userId;
    console.log(this.requestDto);
    this.masterReadService.sendLrDeleteOtpPin(this.requestDto).subscribe(
      (response) => {
        if (response.status == 'Success') {
          swal("PIN Sent", "LR delete confirmation PIN for LR Number: " + this.validateLRNumber + " sent to Consignee registered number, please get the PIN to proceed for delete!", "info");
        } else {
          swal("Error", "Problem occur while sending the PIN!", "error");
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server problem occurred!", "error");
      }, () => console.log('done');
  }

  validateDeleteOtp() {
    this.validOtp = false;
    this.requestDto = new RequestDto();
    this.enteredOtp = $("#" + this.pageId + "lrDeleteOtp").val();
    this.requestDto.lrNumber = this.validateLRNumber;
    this.requestDto.param2 = this.enteredOtp;
    this.requestDto.userId = this.userDataDtoReturnSession.userId;
    this.masterReadService.validateLRDeleteOtpPin(this.requestDto).subscribe(
      (response) => {
        if (response.status == "Valid") {
          this.validOtp = true;
          this.lrDeleteAdditionalValidations();
        } else {
          this.validOtp = false;
          swal("Invalid PIN", "Entered PIN is invalid, please verify and enter the correct PIN or contact admin!", "warning");
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server problem occurred!", "error");
      }, () => console.log('done');
  }

  lrDeletePopupClose() {
    this.deleteReasonSelect = null;
    this.lrDeleteTechReason = false;
    this.lrDeleteReturnBack = false;
    this.validPassword = false;
    this.passwordEntered = null;
    this.requestDto = new RequestDto();
    this.validOtp = false;
    this.enteredOtp = null;
    this.consentUploadedFilename = null;
    this.modalService.dismissAll();
  }

  //CHG-V-LREdit:06 Start
  eWayBillValidUptoDateFocus(multipleEwayBillPopupOpen) {
    this.openMultiEwayPopUpWithAlert(multipleEwayBillPopupOpen);
  }

  openMultiEwayPopUpWithAlert(multipleEwayBillPopupOpen) {
    swal({
      title: "E-way Bill Validation",
      text: "You Want To Add Multiple E-way Bill for this LR?",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((multiEwayBill) => {
      if (multiEwayBill) {
        this.modalService.open(multipleEwayBillPopupOpen, { centered: true, windowClass: "myCustomModalClassMultiEwayLr" });

        window.setTimeout(function () {
          $("#lrefceWayBillNoMulti").focus();
        }, 100);
      } else {
        window.setTimeout(function () {
          $("#lrefcbaseFreight").focus();
        }, 600);
      }
    });
  }

  ngAfterViewInit(): void {
    this.dtTriggerMultEway.next();
  }

  ngOnDestroy(): void {
    this.dtTriggerMultEway.unsubscribe();
  }

  multiEwayDetailsTable() {
    this.dtOptionsMultiEway = {
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search..."
      },
      searching: false,
      processing: true,
      responsive: true,
      "scrollX": true,
      "scrollY": 280,
      "scrollCollapse": true,
      "paging": false,
      "info": false,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
        $(api.column(0).footer()).html('Total : ' + data.length);
      }
    }
  }

  getMultiEwayBill() {
    this.multiEwayBillList = this.lrDtoForGetLRNumberDetailsReturn.listOfEWay;
    console.log("multiEwayBillList");
    console.log(this.multiEwayBillList);
    this.multiEwayTableDataList = [];
    for (let i = 0; i < this.multiEwayBillList.length; i++) {
      let ewayBillLists = this.multiEwayBillList[i];
      if (ewayBillLists && ewayBillLists.length > 0) {
        let splitEway = ewayBillLists.split("#");
        this.multiEwayTableDataList.push({
          eWayBillNo: splitEway[0],
          ewayBillValidUpto: splitEway[1]
        });
      }
    }
    console.log("multiEwayTableDataList");
    console.log(this.multiEwayTableDataList)
  }

  rowSelectedEditMultiEway(multiEwayTableDataList, index) {
    this.editSelected = true;
    $("#" + this.pageId + "eWayBillNoMulti").val(multiEwayTableDataList.eWayBillNo);
    $("#" + this.pageId + "eWayBillValidUptoDateMulti").val(multiEwayTableDataList.ewayBillValidUpto);
    $("#" + this.pageId + "hiddenIndex").val(index);
    this.editTableId = index;
  }

  rowSelectedDeleteMultiEway(multiEwayTableDataList, index) {
    this.multiEwayTableDataList.splice(index, 1);
    $("#" + this.pageId + "eWayBillNoMulti").val('');
    $("#" + this.pageId + "eWayBillValidUptoDateMulti").val('');
    $("#" + this.pageId + "hiddenIndex").val('');
  }

  chkDuplicateMultiEwayBillNo() {
    this.enteredMultiEwayBillNo = $("#" + this.pageId + "eWayBillNoMulti").val();
    var validateRemaingLrNumber = this.findEwayDup(this.multiEwayTableDataList, this.enteredMultiEwayBillNo);
    if (validateRemaingLrNumber == this.enteredMultiEwayBillNo && this.editSelected == false) {
      swal("Duplicate", "Eway Bill No '" + this.enteredMultiEwayBillNo + "' Already Added", "warning");
    } else {
      this.addInTableMultiEway();
    }
  }

  findEwayDup(multiEwayTableDataList, eWayBillNo) {
    var checkingRemEwayBillNo = null;
    var remainEwayBillNoReturn = null;
    for (let i = 0; i < multiEwayTableDataList.length; i++) {
      checkingRemEwayBillNo = multiEwayTableDataList[i].eWayBillNo;
      if (checkingRemEwayBillNo == eWayBillNo) {
        remainEwayBillNoReturn = checkingRemEwayBillNo;
        break;
      } else {
        remainEwayBillNoReturn = null;
      }
    }
    return remainEwayBillNoReturn;
  }

  eWayBillValidUptoDateFocusMulti(e) {
    console.log("am in for focus on plus btn");
    if (e.keyCode == 13) {
    }
  }

  eWayBillNoMultiFocus(e) {
    var ewayBillNo = $("#" + this.pageId + "eWayBillNoMulti").val();
    if (e.keyCode == 13) {
      this.getEwayBillValidUptoFromApi('multi', ewayBillNo);
      window.setTimeout(function () {
        $("#lrefceWayBillValidUptoDateMulti").focus();
      }, 100);
    }
  }

  multiEwayFocus() {
    this.eWayBillValidUptoDateMultiModel = null;
    window.setTimeout(function () {
      $("#lrefcbaseFreight").focus();
    }, 100);
  }

  addInTableMultiEway() {
    this.newAttributeMultiEway.eWayBillNo = $("#" + this.pageId + "eWayBillNoMulti").val();
    this.newAttributeMultiEway.ewayBillValidUpto = $("#" + this.pageId + "eWayBillValidUptoDateMulti").val();

    if (this.newAttributeMultiEway.eWayBillNo != '' && this.newAttributeMultiEway.ewayBillValidUpto != '' && this.newAttributeMultiEway.eWayBillNo != null && this.newAttributeMultiEway.ewayBillValidUpto != null) {
      if (this.editTableId != null) {
        this.multiEwayTableDataList.splice(this.editTableId, 1);
        console.log("this.newAttributeMultiEway-chk1");
      }
      this.editTableId = null;
      if (this.multiEwayTableDataList == null) {
        this.multiEwayTableDataList = [];
        console.log("this.newAttributeMultiEway-chk2");
      }
      console.log("this.newAttributeMultiEway");
      console.log(this.newAttributeMultiEway);
      $("#" + this.pageId + "multipleEwayPopupTable").DataTable().destroy();
      if (this.multiEwayTableDataList.length == 0) {
        this.multiEwayTableDataList.push(this.newAttributeMultiEway);
        console.log("this.newAttributeMultiEway-chk3");
        this.editSelected = false;
      } else {
        let array = this.multiEwayTableDataList;
        this.multiEwayTableDataList.push(this.newAttributeMultiEway);
        this.editSelected = false;
        console.log("this.newAttributeMultiEway-chk4");
      }
      console.log(this.multiEwayTableDataList);

      this.newAttributeMultiEway = {};
      $("#" + this.pageId + "eWayBillNoMulti").val('');
      $("#" + this.pageId + "eWayBillValidUptoDateMulti").val('');
      window.setTimeout(function () {
        $("#lrefceWayBillNoMulti").focus();
      }, 100);
      this.clearMulEway();
      this.dtTriggerMultEway.next();

    } else {
      swal({
        title: "Warning",
        text: "All Fields Are Mandatory",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  clearMulEway() {
    $("#" + this.pageId + "eWayBillNoMulti").val('');
    $("#" + this.pageId + "eWayBillValidUptoDateMulti").val('');
  }
  //CHG-V-LREdit:06 End
  getEwayBillValidUptoFromApi(type, ewayBillNo) {

    this.showSpinnerForAction = true;
    // var enteredEWayBillNo = $("#" + this.pageId + "eWayBillNoMulti").val().toString();
    this.lrtDtoRespForEwayApi = new LRDto();
    this.responseDtoForEwayApi = new ResponseDto();
    this.responseDtoForEwayApi.ewayBillNo = ewayBillNo;
    this.ewaybillService.getDetailsFromEwayBillRefreshEwb(this.responseDtoForEwayApi).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        this.lrtDtoRespForEwayApi = response;
        console.log(this.lrtDtoRespForEwayApi);
        console.log(this.lrtDtoRespForEwayApi.ewayBillStatus);
        if (this.lrtDtoRespForEwayApi.ewayBillStatus != null && this.lrtDtoRespForEwayApi.ewayBillStatus == "CANCEL") {
          swal("Not Allowed", "This E-Way Bill Number : " + ewayBillNo + "  show as Cancelled! Please Verify entered E-Way Bill No!", "warning");
          $("#" + this.pageId + "eWayBillNoMulti").val('');
          ewayBillNo = null;
        } else if (this.lrtDtoRespForEwayApi.isBlockedEwayBill) {
          swal("Not Allowed", "The entered E-Way Bill No : " + ewayBillNo + " is expiring today!.. Please do the extension to continue with the LR Generation.", "warning");
          $("#" + this.pageId + "eWayBillNoMulti").val('');
          ewayBillNo = null;
        } else {
          if (this.lrtDtoRespForEwayApi.respStatus == 1) {
            if (this.lrtDtoRespForEwayApi.ewayBillValidUptoStr == null || this.lrtDtoRespForEwayApi.ewayBillValidUptoStr == undefined) {
              swal({
                title: "Warning",
                text: "There is no 'valid upto date' available for this E-way Bill number",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
              })
            } else {
              if (type == 'single') {
                $("#" + this.pageId + "ewayBillValidUpto").val(this.lrtDtoRespForEwayApi.ewayBillValidUptoStr);
              } else {
                $("#" + this.pageId + "eWayBillValidUptoDateMulti").val(this.lrtDtoRespForEwayApi.ewayBillValidUptoStr);
                this.chkDuplicateMultiEwayBillNo();
              }

            }
          } else {

            swal({
              title: "Warning",
              text: "No records found for this E-way Bill number !",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Problem Occurred While getting the details from the EWay Bill Number", "warning");
      }, () => console.log('done');
  }

  //CHG-V-LREdit:07
  setPartyDetailsForGstNumberSearch() {
    this.responseDtoForGstApi = new ResponseDto();
    this.responseDtoForGstApi.token2 = this.userDataDtoReturnSession.mainAdminStation;
    if (this.partyType == "consignor") {
      this.enteredGstNumber = $("#" + this.pageId + "consignorGstNumber").val();
      this.responseDtoForGstApi.token1 = this.enteredGstNumber;
      this.responseDtoForGstApi.message = "Consignor Party";
    } else if (this.partyType == "consignee") {
      this.enteredGstNumber = $("#" + this.pageId + "consigneeGstNumber").val();
      this.responseDtoForGstApi.token1 = this.enteredGstNumber;
      this.responseDtoForGstApi.message = "Consignee Party";

      this.validateDestination = null;
      this.validateDestination = $("#" + this.pageId + "destination").val();
      this.responseDtoForGstApi.token2 = this.validateDestination;
      this.responseDtoForGstApi.mode = 'LrEdit';
    }
    this.responseDtoForGstApi.type = 'Gst';
  }

  //CHG-V-LREdit:07
  getDetailsFromEwayBillSearchByGstNoMethod() {
    this.setPartyDetailsForGstNumberSearch();
    console.log(this.responseDtoForGstApi);
    this.showSpinnerForAction = true;
    this.ewaybillService.getDetailsFromEwayBillSearchByGstNo(this.responseDtoForGstApi).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        this.lrtDtoRespForGstNo = response;
        console.log(this.lrtDtoRespForGstNo);
        if (this.lrtDtoRespForGstNo.chkStatus == "Success") {
          this.setConsignorDetailsListAfterNewAdd();
          window.setTimeout(function () {
            $("#" + this.pageId + "consignorName").focus();
          }, 100);
          this.partyType = null;
          console.log(this.partyType);
        } else if (this.lrtDtoRespForGstNo.dummyStatus == "Success") {
          this.setConsigneeNameByTinGstAfterNewAdd();
          window.setTimeout(function () {
            $("#" + this.pageId + "consigneeName").focus();
          }, 100);
          this.partyType = null;
          console.log(this.partyType);
        }
        if (this.lrtDtoRespForGstNo.respStatus != 1) {
          this.swalTextMessage = "GST Details not available in the gst portal please verify the GST No"
        } else if (this.lrtDtoRespForGstNo.chkStatus == "Failed") {
          this.swalTextMessage = "Failed to Add Consignor Details!"
        } else if (this.lrtDtoRespForGstNo.dummyStatus == "Failed") {
          this.swalTextMessage = "Failed to Add Consignee Details!"
        } else if (this.lrtDtoRespForGstNo.dummyStatus == "Invalid Destination") {
          swal("Not Allowed", "Invalid Destination Please Check the Pincode!", "warning");
        }
        if (this.lrtDtoRespForGstNo.respStatus != 1 || this.lrtDtoRespForGstNo.chkStatus == "Failed"
          || this.lrtDtoRespForGstNo.dummyStatus == "Failed") {
          swal({
            title: "Error",
            text: this.swalTextMessage,
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the details from the Gst Number", "warning");
      }, () => console.log('done');
  }

  validateGstNoField() {
    if (this.partyType == "consignor") {
      this.enteredGstNumberSearch = $("#" + this.pageId + "consignorGstNumber").val();
    } else if (this.partyType == "consignee") {
      this.enteredGstNumberSearch = $("#" + this.pageId + "consigneeGstNumber").val();
    }
    if (!this.gstValidation.test(this.enteredGstNumberSearch)) {
      swal("Invalid", "Invalid GST Number", "warning");
      return false;
    } else {
      this.getDetailsFromEwayBillSearchByGstNoMethod();
    }
  }

  validationGetConsigneeDetailsBasedOnSelectType(openPopupForAadhar) {
    let enteredNumber = $("#" + this.pageId + "consigneeGstNumber").val().toString().trim().replace(/\s+/g, '');
    this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();

    if ((this.selectedSearchBy === 'gst' && !this.gstValidation.test(enteredNumber))) {
      swal("Invalid", "Invalid GST Number", "warning");
    } else if (this.selectedSearchBy === 'pan' && !this.panValidation.test(enteredNumber)) {
      swal("Invalid", "Invalid PAN Number", "warning");
    } else if (this.selectedSearchBy === 'aadhar' && (enteredNumber.length !== 12 || !/^[2-9][0-9]{11}$/.test(enteredNumber))) {
      swal("Invalid", "Invalid Aadhar Number", "warning");
    } else {
      this.validateConsigneeGstNo = enteredNumber;
      this.setConsigneeNameByTinGst(openPopupForAadhar);
    }
  }

  setConsignorDetailsListAfterNewAdd() {
    this.getConsignorDetailsList();
    this.showSpinnerForAction = true;
    this.masterReadService.getConsignorMaster(this.partyMasterDtoForConsignorName).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        console.log(response);
        this.consignorNameOptions = [];
        this.consignorNameTA = [];
        this.consignorNameOptions = response;
        for (let i = 0; i < this.consignorNameOptions.length; i++) {
          this.consignorNameTA.push(this.consignorNameOptions[i]);
        }
        console.log(this.consignorNameTA);
        $("#" + this.pageId + "consignorName").val(this.consignorNameTA[0].consignorName);
        this.selectedConsignorId = null;
        this.selectedConsignorId = this.consignorNameTA[0].consignorId;
        $("#" + this.pageId + "consigneeGstNumber").focus();
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Consignor Details", "warning");
      },
      () => console.log('done');
  }

  setConsigneeNameByTinGstAfterNewAdd() {
    this.getConsigneeNameByTinGst();
    this.showSpinnerForAction = true;
    this.masterReadService.getConsigneeMaster(this.partyMasterDtoForConsignee).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        console.log(response);
        this.consigneeNameOptions = [];
        this.consigneeNameTA = [];
        this.consigneeNameOptions = response;
        for (let i = 0; i < this.consigneeNameOptions.length; i++) {
          this.consigneeNameTA.push(this.consigneeNameOptions[i]);
        }
        if (this.consigneeNameTA[0].isSrcBlock) {
          swal("No Allowed", this.consigneeNameTA[0].srcAlertMessage, "warning");
          window.setTimeout(function () {
            this.selectedConsigneeName = null;
            $("#lrecconsigneeName").val('');
          }, 100);
        } else {
          $("#" + this.pageId + "consigneeName").val(this.consigneeNameTA[0].consigneeName);
          this.selectedConsigneeId = null;
          this.selectedConsigneeId = this.consigneeNameTA[0].consigneeId;
          $("#" + this.pageId + "baseFreight").focus();

          this.stateByDest = this.consigneeNameOptions[0].state;
          this.getBangaloreConsigneeCommodityConsignee();
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Consignee Details", "warning");
      },
      () => console.log('done');
  }

  searchByMode(searchBy: string) {
    if (searchBy === 'gst') {
      $("#" + this.pageId + "consigneeGstNumber").val('');
      this.text = "Enter Consignee GST Number";
    } else if (searchBy === 'pan') {
      $("#" + this.pageId + "consigneeGstNumber").val('');
      this.text = "Enter Pan Card Number";
    } else if (searchBy === 'aadhar') {
      $("#" + this.pageId + "consigneeGstNumber").val('');
      this.text = "Enter Aadhar Card Number";
    } else {
      $("#" + this.pageId + "consigneeGstNumber").val('');
      this.text = "Enter Consignee GST Number";
    }
  }

  getDetailsFromPanApiMethodForLrEntry() {
    this.validateDestination = null;
    this.requestDto = new RequestDto();

    if (this.partyType == "consignor") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consignorGstNumber").val();
      this.requestDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
      this.requestDto.partyType = 'consignorParty';
    } else if (this.partyType == "consignee") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consigneeGstNumber").val();
      this.validateDestination = $("#" + this.pageId + "destination").val();
      this.requestDto.destination = this.validateDestination;
      this.requestDto.partyType = 'consigneeParty';
    }

    this.requestDto.param1 = this.enteredPanNumberSearch;
    this.requestDto.param2 = null;
    this.requestDto.param4 = this.userDataDtoReturnSession.mainStation;
    this.requestDto.param5 = this.userDataDtoReturnSession.office;
    this.requestDto.userId = this.userDataDtoReturnSession.userId;
    this.requestDto.type = 'LrEntry';
    this.requestDto.mode = 'fromLrEnty';
    this.requestDto.apiType = 'Pan';
    this.requestDto.modeV2 = 'LrEdit';
    this.showSpinnerForAction = true;
    console.log(this.requestDto);
  }

  getDetailsFromPanApiServiceForLrEntry() {
    this.getDetailsFromPanApiMethodForLrEntry();
    this.changeDetectorRef.detectChanges();
    this.ewaybillService.getDetailsFromPanCardApiServiceForLrEntry(this.requestDto).subscribe((response) => {
      console.log(response);
      this.showSpinnerForAction = false;
      if (response.orgId != 1) {
        swal("Error", "Pan Details not available in the pan portal please verify the PAN No", "error");
      } else if (response.successful == "Success") {
        if (this.partyType == "consignor") {
          console.log(this.partyType);
          this.setConsignorDetailsListAfterNewAdd();
          window.setTimeout(function () {
            $("#" + this.pageId + "consignorName").focus();
          }, 100);
          this.partyType = null;
        } else if (this.partyType == "consignee") {
          this.setConsigneeNameByTinGstAfterNewAdd();
          window.setTimeout(function () {
            $("#" + this.pageId + "consigneeName").focus();
          }, 100);
          this.partyType = null;
        }
      } else if (response.successful == "Failed") {
        swal("Error", "Failed to Add Pan Details!", "error");
      } else if (response.successful == "Invalid Destination") {
        swal("Not Allowed", "Invalid Destination Please Check the Pincode!", "warning");
      }
      this.showSpinnerForAction = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Serve Error",
          "Failed to get Pan details, please try again or contact Admin!",
          "error"
        );
      },
      () => console.log("done");
  }

  validatePanNoField() {
    if (this.partyType == "consignor") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consignorGstNumber").val();
    } else if (this.partyType == "consignee") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consigneeGstNumber").val();
    }
    if (!this.panValidation.test(this.enteredPanNumberSearch)) {
      swal("Invalid", "Invalid PAN Number", "warning");
      return false;
    } else {
      this.getDetailsFromPanApiServiceForLrEntry();
    }
  }

  validateAadharNoField(openPopupForAadhar) {
    let enteredAadharNumber;
    if (this.partyType == "consignor") {
      enteredAadharNumber = $("#" + this.pageId + "consignorGstNumber").val();
    } else if (this.partyType == "consignee") {
      enteredAadharNumber = $("#" + this.pageId + "consigneeGstNumber").val();
    }
    enteredAadharNumber = enteredAadharNumber.toString();
    enteredAadharNumber = enteredAadharNumber.replace(/\s+/g, '');
    if (enteredAadharNumber.length !== 12 || !/^[2-9][0-9]{11}$/.test(enteredAadharNumber)) {
      swal("Invalid", "Invalid Aadhar Number", "warning");
    } else {
      this.enteredAadharNumberSearch = enteredAadharNumber;
      this.getDetailsFromAadharApiServiceForLrEntry(openPopupForAadhar);
    }
  }

  getDetailsFromAadharApiMethodForLrEntry() {
    this.requestDtoForAadhar = new RequestDto();
    this.requestDtoForAadhar.param1 = this.enteredAadharNumberSearch;
    this.requestDtoForAadhar.userId = this.userDataDtoReturnSession.userId;
    this.showSpinnerForAction = true;
    console.log(this.requestDtoForAadhar);
  }


  getDetailsFromAadharApiServiceForLrEntry(openPopupForAadhar) {
    this.getDetailsFromAadharApiMethodForLrEntry();
    this.changeDetectorRef.detectChanges();
    this.ewaybillService.getDetailsFromAadharCardApiServiceForLrEntry(this.requestDtoForAadhar).subscribe((response) => {
      console.log(response);
      this.showSpinnerForAction = false;
      if (response.orgId == 1) {
        this.aadharDetails = response;
        this.modalService.open(openPopupForAadhar, { centered: true }).result.then(
          result => {
            this.closeResult = `Closed with: ${result}`;
          },
          reason => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      } else if (response.orgId != 1) {
        swal("Error", "Aadhar Details not available in the aadhar portal please verify the Aadhar No", "error");
      }
      this.showSpinnerForAction = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Serve Error",
          "Failed to validate the request, please try again or contact Admin!",
          "error"
        );
      },
      () => console.log("done");
  }

  getDetailsFromAadharMethod() {
    this.enteredOtpRes = $("#" + this.pageId + "otpId").val();
    this.validateDestination = null;
    this.requestDtoAadharRes = new RequestDto();

    if (this.partyType == "consignor") {
      this.requestDtoAadharRes.param2 = this.enteredAadharNumberSearch;
      this.requestDtoAadharRes.mainStation = this.userDataDtoReturnSession.mainAdminStation;
      this.requestDtoAadharRes.partyType = 'consignorParty';
    } else if (this.partyType == "consignee") {
      this.requestDtoAadharRes.param2 = this.enteredAadharNumberSearch;
      this.requestDtoAadharRes.partyType = 'consigneeParty';
      this.validateDestination = $("#" + this.pageId + "destination").val();
      this.requestDtoAadharRes.destination = this.validateDestination;
    }

    this.requestDtoAadharRes.otp = this.enteredOtpRes;
    this.requestDtoAadharRes.param1 = this.aadharDetails.token1;
    this.requestDtoAadharRes.apiType = 'Aadhar';
    this.requestDtoAadharRes.param4 = this.userDataDtoReturnSession.mainStation;
    this.requestDtoAadharRes.param5 = this.userDataDtoReturnSession.office;
    this.requestDtoAadharRes.userId = this.userDataDtoReturnSession.userId;
    this.requestDtoAadharRes.modeV2 = 'LrEdit';
    this.showSpinnerForAction = true;
    console.log(this.requestDtoAadharRes);
  }

  getDetailsFromAadharApiMethod() {
    this.getDetailsFromAadharMethod();
    this.changeDetectorRef.detectChanges();
    this.ewaybillService.getDetailsFromAadharApiAfterOtpServiceForLrEntry(this.requestDtoAadharRes).subscribe((response) => {
      console.log(response);
      this.showSpinnerForAction = false;
      if (response.orgId != 1) {
        swal("Error", "Aadhar Details not available in the aadhar portal please verify the Aadhar No", "error");
      } else if (response.successful == "Success") {
        this.modalService.dismissAll();
        if (this.partyType == "consignor") {
          console.log(this.partyType);
          this.setConsignorDetailsListAfterNewAdd();
          window.setTimeout(function () {
            $("#" + this.pageId + "consignorName").focus();
          }, 100);
          this.partyType = null;
        } else if (this.partyType == "consignee") {
          this.setConsigneeNameByTinGstAfterNewAdd();
          window.setTimeout(function () {
            $("#" + this.pageId + "consigneeName").focus();
          }, 100);
          this.partyType = null;
        }
      } else if (response.successful == "Failed") {
        swal("Error", "Failed to Add Aadhar Details!", "error");
      } else if (response.successful == "Invalid Destination") {
        swal("Not Allowed", "Invalid Destination Please Check the Pincode!", "warning");
      }
      this.showSpinnerForAction = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Serve Error",
          "Failed to Get Aadhar details, please try again or contact Admin!",
          "error"
        );
      },
      () => console.log("done");
  }

  selectedType(selectType: string) {
    if (selectType === 'gst') {
      $("#" + this.pageId + "consignorGstNumber").val('');
      this.consignorTextField = "Enter Consignor GST Number";
    } else if (selectType === 'pan') {
      $("#" + this.pageId + "consignorGstNumber").val('');
      this.consignorTextField = "Enter Pan Card Number";
    } else if (selectType === 'aadhar') {
      $("#" + this.pageId + "consignorGstNumber").val('');
      this.consignorTextField = "Enter Aadhar Card Number";
    } else {
      $("#" + this.pageId + "consignorGstNumber").val('');
      this.consignorTextField = "Enter Consignor GST Number";
    }
  }

  validationGetConsignorDetailsBasedOnSelectType(openPopupForAadhar) {
    let enteredNumber = $("#" + this.pageId + "consignorGstNumber").val().toString().trim().replace(/\s+/g, '');
    this.selectedSearchByConsignor = $("#" + this.pageId + "selectType").val();

    if ((this.selectedSearchByConsignor === 'gst' && !this.gstValidation.test(enteredNumber))) {
      swal("Invalid", "Invalid GST Number", "warning");
    } else if (this.selectedSearchByConsignor === 'pan' && !this.panValidation.test(enteredNumber)) {
      swal("Invalid", "Invalid PAN Number", "warning");
    } else if (this.selectedSearchByConsignor === 'aadhar' && (enteredNumber.length !== 12 || !/^[2-9][0-9]{11}$/.test(enteredNumber))) {
      swal("Invalid", "Invalid Aadhar Number", "warning");
    } else {
      console.log(enteredNumber);
      this.validateConsignorGstNo = enteredNumber;
      this.setConsignorDetailsList(openPopupForAadhar);
    }
  }

  //CHG-V-LREdit:16
  callCommodityBlockedDetails() {
    this.showSpinnerForAction = true;
    this.isCommodityBlockedBkg = false;
    this.dtoBlockedcmdty = new RateMasterDto();
    this.selectedPartyCommodity = $("#" + this.pageId + "partyGoodsType").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();
    this.dtoBlockedcmdty.source = this.selectedAgentName;
    this.dtoBlockedcmdty.destination = this.selectedDestination;
    this.dtoBlockedcmdty.commodityName = this.selectedPartyCommodity;
    this.dtoBlockedcmdty.companyId = this.userDataDtoReturnSession.companyId;
    console.log("LR Edit  - this.dtoBlockedcmdty" + this.dtoBlockedcmdty);
    this.masterReadService.getBlockedCommodityDetails(this.dtoBlockedcmdty).subscribe(
      (response) => {
        this.dtoBlockedCmdtyRtn = new ResponseDto();
        this.showSpinnerForAction = false;
        this.dtoBlockedCmdtyRtn = response;
        console.log(" this.dtoBlockedCmdtyRtn" + this.dtoBlockedCmdtyRtn);
        this.isCommodityBlockedBkg = this.dtoBlockedCmdtyRtn.blockedCommodity;
        if (this.isCommodityBlockedBkg) {
          swal("Not Allowed", this.selectedPartyCommodity + " commodity is blocked for booking, please contact admin!", "warning");
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Problem Occurred While getting the details!", "warning");
      }, () => console.log('done');
  }

  //CHG-V-LREdit:16
  clickListnerForCommodity(e: NgbTypeaheadSelectItemEvent) {
    //alert('ok');
    this.callCommodityBlockedDetails();
  }

  //CHG-V-LREdit:21
  checkValidEwayBillNoOnUpdateLrDetails() {
    this.showSpinnerForAction = true;
    this.eWayBillNoForLrEdit = $("#" + this.pageId + "eWayBillNo").val();
    this.lrtDtoRespForEwayApi = new LRDto();
    this.responseDtoForEwayApi = new ResponseDto();
    this.responseDtoForEwayApi.ewayBillNo = this.eWayBillNoForLrEdit;
    this.ewaybillService.getDetailsFromEwayBillRefreshEwb(this.responseDtoForEwayApi).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        this.lrtDtoRespForEwayApi = response;
        console.log(this.lrtDtoRespForEwayApi);
        if (this.lrtDtoRespForEwayApi.ewayBillStatus != null && this.lrtDtoRespForEwayApi.ewayBillStatus == "CANCEL") {
          swal("Not Allowed", "This E-Way Bill Number : " + this.eWayBillNoForLrEdit + "  show as Cancelled!.. Please Verify entered E-Way Bill No!", "warning");
        } else if (this.lrtDtoRespForEwayApi.isBlockedEwayBill) {
          swal("Not Allowed", "The entered E-Way Bill No : " + this.eWayBillNoForLrEdit + " is expiring today!.. Please do the extension to continue with the LR Generation.", "warning");
        } else {
          this.validateSaveUpdateBtnDetails();
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Problem Occurred While getting the details from the EWay Bill Number", "warning");
      }, () => console.log('done');
  }

  //CHG-V-LREdit:21
  validateUpdateBtnOnLrDetails() {
    this.eWayBillNoForLrEdit = $("#" + this.pageId + "eWayBillNo").val();
    if (this.eWayBillNoForLrEdit != null && this.eWayBillNoForLrEdit != '' && this.eWayBillNoForLrEdit.length < 12) {
      swal({
        title: "Invalid E-Way Bill number",
        text: "Entered E-Way Bill number is Invalid, please verify it. If its not applicable then please remove it.",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if (this.eWayBillNoForLrEdit != null && this.eWayBillNoForLrEdit != '') {
      this.checkValidEwayBillNoOnUpdateLrDetails();
    } else {
      this.validateSaveUpdateBtnDetails();
    }
  }

  //CHG-V-LREdit:22
  validatePanNoFieldForGetDetails() {
    if (this.partyType == "consignor") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consignorGstNumber").val();
    } else if (this.partyType == "consignee") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consigneeGstNumber").val();
    }
    if (!this.panValidation.test(this.enteredPanNumberSearch)) {
      swal("Invalid", "Invalid PAN Number", "warning");
      return false;
    } else {
      this.panAadharValidationService();
    }
  }

  //CHG-V-LREdit:22
  panAadharValidationService() {
    this.requestDtoGetPan = new RequestDto();
    if (this.partyType == "consignor") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consignorGstNumber").val();
    } else if (this.partyType == "consignee") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consigneeGstNumber").val();
    }
    this.requestDtoGetPan.param1 = this.enteredPanNumberSearch;
    this.requestDtoGetPan.param2 = null;
    this.requestDtoGetPan.param4 = this.userDataDtoReturnSession.mainStation;
    this.requestDtoGetPan.param5 = this.userDataDtoReturnSession.office;
    this.requestDtoGetPan.userId = this.userDataDtoReturnSession.userId;
    this.requestDtoGetPan.type = 'Invoice';
    this.showSpinnerForPopup = true;
    console.log(this.requestDtoGetPan);
    this.changeDetectorRef.detectChanges();
    this.ewaybillService.validatePanAadharLinkVerification(this.requestDtoGetPan).subscribe((response) => {
      this.showSpinnerForPopup = false;
      if (response.orgId != 1) {
        swal("Error", "Pan Details not available in the pan portal please verify the PAN No", "error");
      } else {
        this.panCardDetails = response;
        console.log(this.panCardDetails);
        if (this.partyType == "consignor") {
          this.newConsignorName = this.panCardDetails.message;
        } else if (this.partyType == "consignee") {
          this.newConsigneeName = this.panCardDetails.message;
        }
      }
      this.showSpinnerForPopup = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForPopup = false;
        swal(
          "Serve Error",
          "Failed to getting PAN details, please try again or contact Admin!",
          "error"
        );
      },
      () => console.log("done");
  }

  //CHG-V-LREdit:23
  getConsignorRateDetailsUserValues() {
    this.rateMasterDtoConsignor = new RateMasterDto();
    this.enteredActualWeight = $("#" + this.pageId + "actualWeight").val();
    this.enteredChargeWeight = $("#" + this.pageId + "chargedWeight").val();
    this.enteredSource = $("#" + this.pageId + "agentName").val();
    this.totArticles = $("#" + this.pageId + "article").val();

    this.selectedDestination = $("#" + this.pageId + "destination").val();

    this.selectedPartyCommodity = $("#" + this.pageId + "partyGoodsType").val();
    let enterBookingDate = null;
    enterBookingDate = $("#" + this.pageId + "bookingDates").val();
    this.enteredBookingDate = this.datePipe.transform(enterBookingDate, "yyyy-MM-dd");

    this.rateMasterDtoConsignor.totalActWgt = this.enteredActualWeight;
    this.rateMasterDtoConsignor.totalChgWgt = this.enteredChargeWeight;
    this.rateMasterDtoConsignor.source = this.enteredSource;
    this.rateMasterDtoConsignor.totalArticles = this.totArticles;
    this.rateMasterDtoConsignor.station = this.selectedDestination;
    this.rateMasterDtoConsignor.commodityName = this.selectedPartyCommodity;
    this.rateMasterDtoConsignor.bookingDate = this.enteredBookingDate;
    this.rateMasterDtoConsignor.consignorId = this.selectedConsignorId;
    this.rateMasterDtoConsignor.companyId = this.userDataDtoReturnSession.companyId;
    console.log('getConsignorRateDetailsUserValues');
    console.log(this.selectedConsigneeId);
    this.rateMasterDtoConsignor.consigneeId = (this.selectedConsigneeId == null ? 0 : this.selectedConsigneeId);
    this.rateMasterDtoConsignor.consigneeIdTemp = (this.selectedConsigneeId == null ? 0 : this.selectedConsigneeId);
    console.log(this.selectedConsigneeId);
  }

  getConsignorRateDetails() {
    this.resetConsignorRateDetails();
    this.getConsignorRateDetailsUserValues();
    this.masterReadService.calculateLRRateBasedOnConsignorRateService(this.rateMasterDtoConsignor).subscribe(
      (response) => {

        this.rateMasterDtoConsignorResp = response;
        if (this.rateMasterDtoConsignorResp.id != null && this.rateMasterDtoConsignorResp.id > 0) {
          console.log("this.rateMasterDtoConsignorResp" + this.rateMasterDtoConsignorResp);
          console.log("this.rateMasterDtoConsignorResp.riskChargeAmt" + this.rateMasterDtoConsignorResp.riskChargeAmt);

          this.consignorRateFlowSet = true;
          this.consignorRateId = this.rateMasterDtoConsignorResp.id;

          //CHG-V-LR:07
          // this.consignorIdRateId = this.rateMasterDtoConsignorResp.consignorId;

          console.log('rate : ' + this.rateMasterDtoConsignorResp);
          $("#" + this.pageId + "baseFreight").val(this.rateMasterDtoConsignorResp.lorryFreight);

          if (this.rateMasterDtoConsignorResp.ratePerUnit == 'Fixed' && this.destMemoSizeExceedRights) {
            this.getCommodityKgLimitDetails(this.rateMasterDtoConsignorResp.basicCharge);
          } else {
            this.callPerkgRateDetails();
          }
          this.callCommodityBlockedDetails();
          // if (this.userDataDtoReturnSession.lrEntryPerKgLimit != null && this.userDataDtoReturnSession.lrEntryPerKgLimit > 0) {
          //   this.calcBaseFreightWithCommonCharges();
          // }

          $("#" + this.pageId + "hamaliId").val(this.rateMasterDtoConsignorResp.hamaliDD);
          $("#" + this.pageId + "gcCharges").val(this.rateMasterDtoConsignorResp.gcCharge);
          $("#" + this.pageId + "aocChgId").val(this.rateMasterDtoConsignorResp.aocChargeAmt);
          $("#" + this.pageId + "othersChgId").val(this.rateMasterDtoConsignorResp.otherChgAmt);

          // for dd chg @02062022
          this.selectedDoorDelivery = $("#" + this.pageId + "doorDeliveryMode").val();
          if (this.selectedDoorDelivery != null && this.selectedDoorDelivery == 'Yes') {
            $("#" + this.pageId + "doorDeliveryAmount").val(this.rateMasterDtoConsignorResp.ddChargeAmt);
            // this.ddAmtRateChg = this.rateMasterDtoConsignorResp.ddChargeAmt;
          } else {
            $("#" + this.pageId + "doorDeliveryAmount").val('');
            // this.ddAmtRateChg = 0;
          }

          this.consignorRateFov = this.rateMasterDtoConsignorResp.riskChargeAmt;

          if (this.userDataDtoReturnSession.blockFreightRateInLrEntrry != null && this.userDataDtoReturnSession.blockFreightRateInLrEntrry) {
            // this.rateFrieghtFieldsReadOnly();
            this.isFrieghtFieldsReadOnly = true;
          } else {
            this.isFrieghtFieldsReadOnly = false;
          }

          this.calculateRiskCharge();
          this.sumOfSubTotal();
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  }

  resetConsignorRateDetails() {
    $("#" + this.pageId + "baseFreight").val('');
    $("#" + this.pageId + "hamaliId").val('');
    $("#" + this.pageId + "gcCharges").val('');
    $("#" + this.pageId + "aocChgId").val('');
    $("#" + this.pageId + "othersChgId").val('');

    // this.isFrieghtFieldsReadOnly = false;
  }

  getCommodityKgLimitDetails(basicRate) {
    this.commodityKglimit = 0;
    this.isSizeCommodityExceed = false;
    this.enteredChargeWeight = $("#" + this.pageId + "chargedWeight").val();
    this.rateMasterDtoGet = new RateMasterDto();
    this.selectedPartyCommodity = $("#" + this.pageId + "partyGoodsType").val();
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.totArticles = $("#" + this.pageId + "article").val();
    this.rateMasterDtoGet.destination = this.selectedDestination;
    this.rateMasterDtoGet.commodityName = this.selectedPartyCommodity;

    // if (this.viewByAutoLr == true || this.lrCheck == true) {
    //   this.rateMasterDtoGet.source = this.userDataDtoReturnSession.mainStation;
    // } else {
    this.rateMasterDtoGet.source = this.selectedAgentName;
    // }
    this.rateMasterDtoGet.companyId = this.userDataDtoReturnSession.companyId;
    this.masterReadService.getCommoditySizeExceedDetailsForLR(this.rateMasterDtoGet).subscribe(
      (response) => {
        console.log(response);
        var chgWgtPerArt: any;
        this.commodityKgLimitData = response;
        console.log("this.commodityKgLimitData" + this.commodityKgLimitData);
        chgWgtPerArt = this.enteredChargeWeight / this.totArticles;
        console.log("chgWgtPerArt" + chgWgtPerArt);
        if (this.commodityKgLimitData != null) {
          this.commodityKglimit = this.commodityKgLimitData.commodityKgLimit;
          console.log(" this.commodityKglimit" + this.commodityKglimit);
          //Chg_V-Lr:30
          if (this.commodityKglimit != null && this.commodityKglimit) {
            if (parseInt(chgWgtPerArt) > parseInt(this.commodityKglimit)) {
              // swal({
              //   title: "Alert",
              //   text: "Charged Weight is Greater then commodity Limit...Please Check the Charged Weight",
              //   icon: "warning",
              //   closeOnClickOutside: false,
              //   closeOnEsc: false,
              // });
              this.baseFreight = $("#" + this.pageId + "baseFreight").val();

              console.log("this.baseFreight" + this.baseFreight);
              console.log("this.commodityKglimit" + this.commodityKglimit);
              console.log("this.enteredChargedWeight" + this.enteredChargeWeight);
              // var calcRate = ((this.baseFreight / this.commodityKglimit) * this.enteredChargedWeight);
              var calcRate = ((this.baseFreight / this.commodityKglimit) * chgWgtPerArt);

              console.log("calcRate" + calcRate);
              this.baseFreight = calcRate;
              $("#" + this.pageId + "baseFreight").val(calcRate.toFixed(0));

              // this.sum();
              this.sumOfSubTotal();

              this.isSizeCommodityExceed = true;
            } else {
              this.isSizeCommodityExceed = false;
              //Chg_V-Lr:33
              this.callPerkgRateDetails();
            }
          } else {
            //Chg_V-Lr:33
            this.callPerkgRateDetails();
          }

        } else {
          //Chg_V-Lr:33
          this.callPerkgRateDetails();
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal("Error", "Server problem occurred while getting the Commodity KG Limit Details!", "error");
        console.log(error.json())
      },
      () => console.log('done');
  }

  callPerkgRateDetails() {

    this.showSpinnerForAction = true;
    this.enteredSource = $("#" + this.pageId + "agentName").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    // this.stateByDest = this.commaSplitDest[1];
    this.lrDtoPerKgGet = new LRDto();
    this.lrDtoPerKgGet.source = this.enteredSource;
    this.lrDtoPerKgGet.destination = this.selectedDestination;
    this.lrDtoPerKgGet.companyId = this.userDataDtoReturnSession.companyId;

    this.masterReadService.getPerKgRateDetailsForLR(this.lrDtoPerKgGet).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        this.lrtDtoPerKgRateReturn = response;
        if ((this.lrtDtoPerKgRateReturn != null) &&
          (this.lrtDtoPerKgRateReturn.hirePerKg != null && this.lrtDtoPerKgRateReturn.hirePerKg > 0)) {
          this.calcBaseFreightWithPerKGRateChg(this.lrtDtoPerKgRateReturn.hirePerKg);
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Problem Occurred While getting the details from Per KG Charge", "warning");
      }, () => console.log('done');
  }

  calcBaseFreightWithPerKGRateChg(perKgRate) {
    var lrEntryCharges = 0.0, perKGRateChg = 0.0, baseFreight = 0.0, actualWgt = 0.0, baseFreightReturn = 0.0;
    baseFreight = parseFloat($("#" + this.pageId + "baseFreight").val().toString()) || 0.0;
    console.log("baseFreight" + baseFreight);
    actualWgt = parseFloat($("#" + this.pageId + "actualWeight").val().toString()) || 0.0;
    console.log("actualWgt" + actualWgt);
    if (baseFreight > 0 && actualWgt > 0) {
      lrEntryCharges = baseFreight / actualWgt;
      console.log("lrEntryCharges" + lrEntryCharges);
      perKGRateChg = perKgRate == null ? 0.0 : perKgRate;
      console.log("perKGRateChg" + perKGRateChg);
      if (lrEntryCharges > perKGRateChg) {
        baseFreightReturn = actualWgt * perKGRateChg;
        console.log("baseFreightReturn" + baseFreightReturn);
        $("#" + this.pageId + "baseFreight").val(baseFreightReturn.toFixed(0));
        console.log("Base field" + $("#" + this.pageId + "baseFreight").val());

        // this.sum();
        this.sumOfSubTotal();
        //Chg_V-Lr:32
        this.isPerKgLimitTaken = true;
      }
    }
    console.log("lrEntryCharges>>>" + lrEntryCharges);
    console.log("baseFreightReturn" + baseFreightReturn);
  }

}
