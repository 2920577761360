<!-- Row -->
<div class="row">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <h6 class="card-title">Rate Details</h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Rate For</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-search"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}searchMode" name="searchMode" #searchMode (change)="searchModeMethod(searchMode.value)">
													<option value="party">Party</option>
													<option value="commodity">Commodity</option>
													<option value="rateForAll">Rate For All</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">

                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-road"></i>
														</span>
                                                    </div>
                                                    <input #destinationId id="{{pageId}}destinationId" type="text" class="form-control" (selectItem)="clickListnerForDestinationList($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                        (focus)="focusDestinationTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Destination.." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="partyView" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Consignee Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input #consigneeNameId id="{{pageId}}consigneeNameId" type="text" class="form-control" (selectItem)="clickListnerForConsigneeNameList($event)" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="searchConsigneeName" [resultFormatter]="formatterConsigneeName"
                                                        [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Consignee Name.." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>Source</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-road"></i>
														</span>
													</div>
													<input id="{{pageId}}source" type="text" class="form-control"
														[(ngModel)]="modelSource" [ngbTypeahead]="searchSource"
														(selectItem)="clickListnerForSource($event)"
														[resultFormatter]="formatterSource"
														[inputFormatter]="formatterSource"
														(focus)="focusSourceTA$.next($any($event).target.value)"
														placeholder="Select Source" />
												</div>
											</div>
										</div>
									</div> -->

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Main Source</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-box"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}mainBookingSourceId" (selectItem)="clickListnerForMainBookingSource($event)" [(ngModel)]="modelMainBookingSource" [ngbTypeahead]="searchMainBookingSource" [resultFormatter]="formatterMainBookingSource"
                                                    [inputFormatter]="formatterMainBookingSource" (focus)="focusMainBookingSourceTA$.next($any($event).target.value)" placeholder="Select Main Source.." />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class='row p-t-10'>
                                                    <div class="col-sm-12 col-md-12">
                                                        <!--<h6 class="card-title border_bottom">Select multiple
															Parties to set the Rate</h6>-->
                                                    </div>
                                                </div>
                                                <div class="box-body">
                                                    <table datatable id="{{pageId}}selectedSourceTableIdCneeAdmin" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSourceCneeAdmin" [dtTrigger]="dtTriggerSourceCneeAdmin">
                                                        <thead>
                                                            <tr>
                                                                <th>Action</th>
                                                                <th>Source</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let sourceData of sourceDataListCneeAdmin let i = index">
                                                                <td><input type="checkbox" [checked]="multiSelect" name="{{ some }}" [attr.checked]="this.lrDtoListSourceCheckedCneeAdmin.indexOf(sourceData.subStation) !== -1 ? true : false" id='selectedSource'
                                                                        (change)="rowCheckBoxForSourceCneeAdmin($event, sourceData,i)" />
                                                                </td>
                                                                <td>{{ sourceData.subStation }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Commodity</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
																class="fas fa-clipboard-list"></i>
														</span>
                                                    </div>
                                                    <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                        [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unit</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-check"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" #searchUnit id="{{pageId}}searchUnit" (change)="searchUnitMethod(searchUnit.value)">
													<!--<option selected>Select Unit</option>-->
													<option selected value="rate">Rate</option>
													<option value="percentage">Percentage</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewRateField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Rates</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" #searchRate id="{{pageId}}searchRate" (change)="searchRateMethod(searchRate.value)">
													<option selected value="fixedRate">Fixed Rate</option>
													<option value="DiscRate">Discount Rate</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewRateField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>{{rateChangeTxtId}}</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}ratePercentageId" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewPercentageField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unit Percentage</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" #searchUnitPerc id="{{pageId}}searchUnitPerc" name="searchUnitPerc" (change)="searchUnitPercMethod(searchUnitPerc.value)">
													<option selected value="PLR">P tot LR</option>
													<option value="PCM">P tot CM</option>
													<option value="PFRT">P bs FRT</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewPercentageField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Rate(%)</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}percentageRateId" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewGCChgField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>G.C Charge</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}gcCharge">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Hamali Destination</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}hamaliDestinationChg" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewReciptChargeField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Receipt Charge</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}reciptCharge" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewServiceChargeField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Service Charge</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}serviceCharge" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" *ngIf="viewLoadingChgSetup">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Service Charge Unit</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-check"></i>
													</span>
                                                </div>
                                                <select #unitServiceChg class="custom-select col-12" id="{{pageId}}unitServiceChg">
													<option value="Article">Article</option>
													<option value="Actual Weight">Actual Weight</option>
													<option value="Charged Weight" selected>Charged Weight</option>
												</select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" *ngIf="viewPerUnitField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Per Unit</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-check"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}perUnit" #perUnit (change)="rateOnActWgtMethod(perUnit.value)">
													<!--<option selected>Select...</option>-->
													<option value="Article" selected>Article</option>
													<option value="Actual Weight">Actual Weight</option>
													<option value="Chargable Weight">Chargable Weight</option>
												</select>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="viewUnloadingChgField" class="col-sm-12 col-md-12" style="padding-top:10px">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unloading Charge(DD) </label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}unloadingChg" name="unloadingChg">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewUnloadingChgField" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unit(DD)</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class="fas fa-weight"></i>
                                                    </span>
                                                </div>
                                                <select #unit class="custom-select col-12" id="{{pageId}}unloadingChgUnit" name="unloadingChgUnit">
                                                    <option value="Article" selected>Article</option>
                                                    <option value="Actual Weight">Actual Weight</option>
                                                    <option value="Charged Weight">Charged Weight</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="viewUnloadingChgField" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unloading Charge(GD) </label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input #unloadingChg type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}unloadingChgGd" name="unloadingChgGd">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewUnloadingChgField" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Unit(GD)</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class="fas fa-weight"></i>
                                                    </span>
                                                </div>
                                                <select #unit class="custom-select col-12" id="{{pageId}}unloadingChgUnitGd" name="unloadingChgUnitGd">
                                                    <option value="Article" selected>Article</option>
                                                    <option value="Actual Weight">Actual Weight</option>
                                                    <option value="Charged Weight">Charged Weight</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" *ngIf="viewRateOnActWgtField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Rate On AW</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input type="number" class="form-control" [(ngModel)]="rateOnActWgt" id="{{pageId}}rateOnActWgt" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Status</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-check"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}status">
													<option selected value="select">Select</option>
													<option value="Cash">Cash</option>
													<option value="Account">Account</option>
													<option value="Delivery">Delivery</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Fixed By</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
													</span>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}fixedBy">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Effect From</label> <input class="form-control" id="{{pageId}}effectFromDate" placeholder="yyyy-mm-dd" name="effectFromDate" ngbDatepicker #effectFromDate="ngbDatepicker">
                                                <div class="input-group-append" (click)="effectFromDate.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Fixed On</label> <input id="{{pageId}}fixedOnDate" class="form-control" placeholder="yyyy-mm-dd" name="fixedOnDate" ngbDatepicker #fixedOnDate="ngbDatepicker">
                                                <div class="input-group-append" (click)="fixedOnDate.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewPercBSFrtField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>LC</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}lcChg">
													<option selected value="no">No</option>
													<option value="yes">Yes</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewPercBSFrtField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Hamali Src</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" #isHamaliSrc id="{{pageId}}hamaliSrcChg" (change)="isHamaliSrcMethod(isHamaliSrc.value)">
													<option selected value="no">No</option>
													<option value="yes">Yes</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" [hidden]="!showCustomHamailSrcChgCheck">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}customHamaliSrcCheckbox" (change)="toggleCustomHamaliSrcCheckbox($event)">
                                                <label class=" custom-control-label wtfull " for="{{pageId}}customHamaliSrcCheckbox">Custom Src Hamail</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" [hidden]="!customHamailSrcChgCheckStatus">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Custom Hamali Src Chg</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}customHamaliSrcChg" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewPercBSFrtField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>BC</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}bcChg">
													<option selected value="no">No</option>
													<option value="yes">Yes</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewPercBSFrtField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>AOC</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}aocChg">
													<option selected value="no">No</option>
													<option value="yes">Yes</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewPercBSFrtField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>FOV</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" #fovChg id="{{pageId}}fovChg" (change)="searchFovRateMethod(fovChg.value)">
													<option selected value="no">No</option>
													<option value="yes">Yes</option>
												</select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" *ngIf="viewFovRateField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>FOV On</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" [(ngModel)]="fovRate" id="{{pageId}}fovRate" (change)="searchFovRateMethod(fovRate.value)">
													<option selected value="Percentage">Percentage</option>
													<option value="Fixed">Fixed</option>

												</select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" *ngIf="viewFovRateField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>FOV Chg</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input type="number" class="form-control" [(ngModel)]="fovRateChg" id="{{pageId}}fovRateChg" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" *ngIf="viewPercBSFrtField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>DD</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}ddChg">
													<option selected value="no">No</option>
													<option value="yes">Yes</option>
												</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12" *ngIf="viewPercBSFrtField">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Others</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <select class="custom-select col-12" id="{{pageId}}othersChg">
													<option selected value="no">No</option>
													<option value="yes">Yes</option>
												</select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}rateAtSrcCheckbox" (change)="toggleRateAtSrcCheckbox($event)"> <label class=" custom-control-label wtfull " for="{{pageId}}rateAtSrcCheckbox">
                                                    Consider Rate At Booking</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12" *ngIf="rateAtSrcCheckStatus">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Src Hamali Chg</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
													</span>
                                                </div>
                                                <input type="number" class="form-control" id="{{pageId}}srcHamaliChg" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <hr style="width: 80%; border-top: none; margin: 3px;">
                                <div class="row">
                                    <div class="col-md-12" align='center'>
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}nextBtnId" (click)="validateNextBtn();">Next</button>
                                        <button type="submit" class="btn btn-dark" id="{{pageId}}saveBtnId" (click)="validateSaveBtn();">Save</button>
                                        <button type="submit" class="btn btn-success m-r-10" (click)="validateResetBtn();" id="{{pageId}}resetBtn">Reset</button>
                                    </div>
                                </div>
                                <div class="row p-t-10">
                                    <div class="col-md-12" align='center'>
                                        <button type="submit" class="btn btn-dark" id="{{pageId}}updateBtnId" (click)="validateUpdateBtn();">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="col-md-9 vl">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <!-- spinner start-->
                                <div *ngIf="showSpinnerForAction" class="col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <mat-progress-bar mode="indeterminate" style="color: green;">
                                            </mat-progress-bar>
                                            <br>
                                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                Please Wait Loading Details.....</h6>
                                        </div>
                                    </div>
                                </div>
                                <!-- spinner end-->
                                <!-- column -->
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class='row p-t-10'>
                                                <div class="col-sm-12 col-md-12">
                                                    <h6 class="card-title border_bottom">Rate Master</h6>
                                                </div>
                                            </div>
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}consigneeAdminMasterDraftDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsRateMaster" [dtTrigger]="dtTriggerRateMaster">

                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Source</th>
                                                            <th>Rate Type</th>
                                                            <th>Rate</th>
                                                            <th>GC</th>
                                                            <th>LC</th>
                                                            <th>S.Hamali</th>
                                                            <th>BC</th>
                                                            <th>AOC</th>
                                                            <th>FOV</th>
                                                            <th>FOV On</th>
                                                            <th>FOV Chg</th>
                                                            <th>DD</th>
                                                            <th>Others</th>
                                                            <th>RC</th>
                                                            <th>SC</th>
                                                            <th>Per Unit</th>
                                                            <th>Rate On AW</th>
                                                            <th>Commodity</th>
                                                            <th>Fixed By</th>
                                                            <th>WEF Date</th>
                                                            <th>S.Hamali Chg</th>
                                                            <th>Unloading Charge(DD)</th>
                                                            <th>Unit(DD)</th>
                                                            <th>Unloading Charge(GD)</th>
                                                            <th>Unit(GD)</th>
                                                            <th>Custom Src Hamali</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let rateMasterData of rateMasterDataList let i = index">
                                                            <td>
                                                                <i style=" cursor: pointer;" class="fas fa-pencil-alt" title="Edit Consignee Admin Rate Details" (click)="rowSelectedConsigneeAdminDataOnEdit(rateMasterData,i);"></i> &nbsp;&nbsp;
                                                                <i style=" cursor: pointer;" class="fas fa-paste" title="Copy Consignee Admin Rate Details" (click)="rowSelectedConsigneeAdminDataOnCopy(rateMasterData);"></i> &nbsp;&nbsp;
                                                                <i style=" cursor: pointer;" class="fas fa-trash" title="Delete Consignee Admin Rate Details" (click)="deleteSelectedConsigneeAdminDetails(i);"></i>
                                                            </td>
                                                            <td>{{ rateMasterData.source }}</td>
                                                            <td>{{ rateMasterData.rateType }}</td>
                                                            <td>{{ rateMasterData.rate }}</td>
                                                            <td>{{ rateMasterData.gcCharge }}</td>
                                                            <td>{{ rateMasterData.lcChg == true ? "Yes" : "No" }}</td>
                                                            <td>{{ rateMasterData.hamaliChg == true ? "Yes" : "No" }}
                                                            </td>
                                                            <td>{{ rateMasterData.bcChg == true ? "Yes" : "No" }}</td>
                                                            <td>{{ rateMasterData.AOC == true ? "Yes" : "No" }}</td>
                                                            <td>{{ rateMasterData.riskCharge == true ? "Yes" : "No"}}
                                                            </td>
                                                            <td>{{ rateMasterData.fovOn }}</td>
                                                            <td>{{ rateMasterData.fovPerc }}</td>
                                                            <td>{{ rateMasterData.DD == true ?"Yes" : "No" }}</td>
                                                            <td>{{ rateMasterData.others == true ? "Yes" : "No" }}</td>
                                                            <td>{{ rateMasterData.receiptCharges }}</td>
                                                            <td>{{ rateMasterData.serviceCharges }}</td>
                                                            <td>{{ rateMasterData.perUnit }}</td>
                                                            <td>{{ rateMasterData.rateOnActWgt }}</td>
                                                            <td>{{ rateMasterData.commodityName }}</td>
                                                            <td>{{ rateMasterData.fixedBy }}</td>
                                                            <td>{{ rateMasterData.effectiveFromDate }}</td>
                                                            <td>{{ rateMasterData.srcHamaliChg }}</td>
                                                            <td>{{ rateMasterData.unloadingChg }}</td>
                                                            <td>{{ rateMasterData.unloadingChgUnit }}</td>
                                                            <td>{{ rateMasterData.unloadingChgGd }}</td>
                                                            <td>{{ rateMasterData.unloadingChgUnitGd }}</td>
                                                            <td>{{ rateMasterData.customHamaliSrcChg }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class='row p-t-10'>
                                                <div class="col-sm-8">
                                                    <h6 class="card-title border_bottom">Rate For Commodities</h6>
                                                </div>

                                                <div class="col-md-4" align='right'>
                                                    <button type="submit" class="btn btn-dark" id="{{pageId}}deleteMultiRateCommodityBtnId" (click)="deleteSelectedConsigneeAdminCommodityDetailsMulti(stopRatePopUpTemplate);">Delete</button>
                                                </div>
                                            </div>

                                            <div class="box-body">
                                                <table datatable id="{{pageId}}consigneeAdminMasterCommDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCommodityRate" [dtTrigger]="dtTriggerCommodityRate">

                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Commodity</th>
                                                            <th>Source</th>
                                                            <th>Rate Type</th>
                                                            <th>Rate</th>
                                                            <th>GC</th>
                                                            <th>LC</th>
                                                            <th>S.Hamali</th>
                                                            <th>BC</th>
                                                            <th>AOC</th>
                                                            <th>FOV</th>
                                                            <th>FOV On</th>
                                                            <th>FOV Chg</th>
                                                            <th>DD</th>
                                                            <th>Others</th>
                                                            <th>D.Hamali</th>
                                                            <th>RC</th>
                                                            <th>SC</th>
                                                            <th>Per Unit</th>
                                                            <th>Rate On AW</th>
                                                            <th>Fixed By</th>
                                                            <th>WEF Date</th>
                                                            <th>S.Hamali Chg</th>
                                                            <th>Unloading Charge(DD)</th>
                                                            <th>Unit(DD)</th>
                                                            <th>Unloading Charge(GD)</th>
                                                            <th>Unit(GD)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let commodityRateData of commodityRateDataList ">
                                                            <td>
                                                                <input type="checkbox" [checked]="multiSelect" name="{{ some }}" id='selectedLr' (change)="rowCheckBoxCommodityRateClicked($event, commodityRateData)" /> &nbsp;&nbsp;
                                                                <i style=" cursor: pointer;" class="fas fa-pencil-alt" title="Edit Consignee Admin Rate Details" (click)="rowSelectedConsigneeAdminCommodityDataOnEdit(commodityRateData,i);"></i>
                                                            </td>
                                                            <td>{{ commodityRateData.commodityName }}</td>
                                                            <td>{{ commodityRateData.source }}</td>
                                                            <td>{{ commodityRateData.rateType }}</td>
                                                            <td>{{ commodityRateData.rate }}</td>
                                                            <td>{{ commodityRateData.gcCharge }}</td>
                                                            <td>{{ commodityRateData.lcChg == true ? "Yes" : "No" }}
                                                            </td>
                                                            <td>{{ commodityRateData.hamaliChg == true ? "Yes" : "No" }}
                                                            </td>
                                                            <td>{{ commodityRateData.bcChg == true ? "Yes" : "No"}}</td>
                                                            <td>{{ commodityRateData.aoc == true ? "Yes" : "No"}}</td>
                                                            <td>{{ commodityRateData.riskCharge == true ? "Yes" : "No"}}
                                                            </td>
                                                            <td>{{ commodityRateData.fovOn }}</td>
                                                            <td>{{ commodityRateData.fovPerc }}</td>
                                                            <td>{{ commodityRateData.dd == true ? "Yes" : "No"}}</td>
                                                            <td>{{ commodityRateData.others == true ? "Yes" : "No"}}
                                                            </td>
                                                            <td>{{ commodityRateData.hamaliDestination }}</td>
                                                            <td>{{ commodityRateData.receiptCharges }}</td>
                                                            <td>{{ commodityRateData.serviceCharges }}</td>
                                                            <td>{{ commodityRateData.perUnit }}</td>
                                                            <td>{{ commodityRateData.rateOnActWgt }}</td>
                                                            <td>{{ commodityRateData.fixedBy }}</td>
                                                            <td>{{ commodityRateData.effectiveFromDateStg }}</td>
                                                            <td>{{ commodityRateData.srcHamaliChg }}</td>
                                                            <td>{{ commodityRateData.unloadingChg }}</td>
                                                            <td>{{ commodityRateData.unloadingChgUnit }}</td>
                                                            <td>{{ commodityRateData.unloadingChgGd }}</td>
                                                            <td>{{ commodityRateData.unloadingChgUnitGd }}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div *ngIf="showSpinnerForRateParties" class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Loading Details.....</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-user"></i>
                                                                    </span>
                                                                </div>

                                                                <input id="{{pageId}}destinationSearchForRateParty" type="text" class="form-control" [(ngModel)]="modelDestinationSearchParty" [ngbTypeahead]="searchDestinationSearchRptParty" [resultFormatter]="formatterDestinationSearchRptParty" [inputFormatter]="formatterDestinationSearchRptParty"
                                                                    (focus)="focusDestinationSearchRptPartyTA$.next($any($event).target.value)" placeholder="Select Destination" (selectItem)="clickListnerForDestinationRateForParties($event)" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-2">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>
                                                                <input #mainBookingSourceForRatePartyId id="{{pageId}}mainBookingSourceForRatePartyId" type="text" class="form-control" [(ngModel)]="mainBookingSourceForRatePartyModal" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="mainBookingSourceForRateParty" [resultFormatter]="formatterMainBookingSourceForRateParty"
                                                                    [inputFormatter]="formatterMainBookingSourceForRateParty" placeholder="Select Main Source" (focus)="focusMainBookingSourceForRatePartyTA$.next($any($event).target.value)" (selectItem)="clickListnerForMainSourceRateForParties($event)"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-2">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>
                                                                <input #agentNameRptSearchParty id="{{pageId}}agentNameRptSearchParty" type="text" class="form-control" [(ngModel)]="agentNameRptSearchPartyModal" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="agentNameSearchRptSearchPartyTA" [resultFormatter]="formatterAgentNameRptSearchParty"
                                                                    [inputFormatter]="formatterAgentNameRptSearchParty" placeholder="Select Source" (focus)="focusAgentNameRptSearchPartyTA$.next($any($event).target.value)" #instanceAgentNameRptSearchParty="ngbTypeahead"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-box"></i>
                                                                </span>
                                                            </div>
                                                            <input #CommodityList id="{{pageId}}commodityListIdSearchRptParty" type="text" class="form-control" [(ngModel)]="modelCommodityListSearchRptParty" [ngbTypeahead]="searchCommodityListSearchRptParty" [resultFormatter]="formatterCommodityListSearchRptParty"
                                                                [inputFormatter]="formatterCommodityListSearchRptParty" (focus)="focusCommodityListSearchRptPartyTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity" />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input #consigneeNamePartySearch id="{{pageId}}consigneeNamePartySearch" name="consigneeNamePartySearch" type="text" class="form-control" placeholder="Select Consignee" [(ngModel)]="modelConsigneeNamePartySearch" [ngbTypeahead]="consigneeNameSearchPartySearchTA"
                                                                (selectItem)="clickListnerForConsigneeRptSearch($event)" [resultFormatter]="formatterConsigneeNamePartySearch" [inputFormatter]="formatterConsigneeNamePartySearch" (focus)="focusConsigneeNamePartySearchTA$.next($any($event).target.value)"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1" style="text-align: center">
                                                    <button type="submit" class="dt-button" style="margin-left: -70%;font-weight:bold;" id="{{pageId}}partyRateSearchBtn" (click)="validateSetupDetails();">Search</button>
                                                </div>
                                            </div>

                                            <div class='row p-t-10'>
                                                <div class="col-sm-8">
                                                    <h6 class="card-title border_bottom">Rate For Parties</h6>
                                                </div>

                                                <div class="col-md-4" align='right'>
                                                    <button type="submit" class="btn btn-dark" id="{{pageId}}deleteMultiRateBtnId" (click)="deleteSelectedConsigneeAdminPartyDetailsMulti(stopRatePopUpTemplate);">Delete</button>
                                                </div>
                                            </div>
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}consigneeAdminMasterPartyDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsPartyRate" [dtTrigger]="dtTriggerPartyRate">

                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Consignee Name</th>
                                                            <th>Source</th>
                                                            <th>Rate Type</th>
                                                            <th>Rate</th>
                                                            <th>Commodity</th>
                                                            <th>GC</th>
                                                            <th>LC</th>
                                                            <th>S.Hamali</th>
                                                            <th>BC</th>
                                                            <th>AOC</th>
                                                            <th>FOV</th>
                                                            <th>FOV On</th>
                                                            <th>FOV Chg</th>
                                                            <th>DD</th>
                                                            <th>Others</th>
                                                            <th>D.Hamali</th>
                                                            <th>RC</th>
                                                            <th>SC</th>
                                                            <th>SC Unit</th>
                                                            <th>Per Unit</th>
                                                            <th>Rate On AW</th>
                                                            <th>Fixed By</th>
                                                            <th>WEF Date</th>
                                                            <th>S.Hamali Chg</th>
                                                            <th>Unloading Charge(DD)</th>
                                                            <th>Unit(DD)</th>
                                                            <th>Unloading Charge(GD)</th>
                                                            <th>Unit(GD)</th>
                                                            <th>Custom Src Hamali</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let partyRateData of partyRateDataList" style="cursor: pointer;" (click)="rowSelectedConsigneeAdminPartyDataOnEdit(partyRateData,i);">
                                                            <td>
                                                                <input type="checkbox" [checked]="multiSelect" name="{{ some }}" id='selectedLr' (change)="rowCheckBoxPartyRateClicked($event, partyRateData,i)" /> &nbsp;&nbsp;
                                                                <!-- <i style=" cursor: pointer;" class="fas fa-pencil-alt" title="Edit Consignee Admin Rate Details" (click)="rowSelectedConsigneeAdminPartyDataOnEdit(partyRateData,i);"></i> -->
                                                            </td>
                                                            <td>{{ partyRateData.consigneeName }}</td>
                                                            <td>{{ partyRateData.source }}</td>
                                                            <td>{{ partyRateData.rateType }}</td>
                                                            <td>{{ partyRateData.rate }}</td>
                                                            <td>{{ partyRateData.commodityName }}</td>
                                                            <td>{{ partyRateData.gcCharge }}</td>
                                                            <td>{{ partyRateData.lcChg == true ? "Yes" : "No" }}
                                                            </td>
                                                            <td>{{ partyRateData.hamaliChg == true ? "Yes" : "No" }}
                                                            </td>
                                                            <td>{{ partyRateData.bcChg == true ? "Yes" : "No"}}</td>
                                                            <td>{{ partyRateData.aoc == true ? "Yes" : "No" }}</td>
                                                            <td>{{ partyRateData.riskCharge == true ? "Yes" : "No" }}
                                                            </td>
                                                            <td>{{ partyRateData.fovOn }}</td>
                                                            <td>{{ partyRateData.fovPerc }}</td>
                                                            <td>{{ partyRateData.dd == true ? "Yes" : "No" }}</td>
                                                            <td>{{ partyRateData.others == true ? "Yes" : "No"}}</td>
                                                            <td>{{ partyRateData.hamaliDestination }}</td>
                                                            <td>{{ partyRateData.receiptCharges }}</td>
                                                            <td>{{ partyRateData.serviceCharges }}</td>
                                                            <td>{{ partyRateData.serviceChargesUnit }}</td>
                                                            <td>{{ partyRateData.perUnit }}</td>
                                                            <td>{{ partyRateData.rateOnActWgt }}</td>
                                                            <td>{{ partyRateData.fixedBy }}</td>
                                                            <td>{{ partyRateData.effectiveFromDateStg }}</td>
                                                            <td>{{ partyRateData.srcHamaliChg }}</td>
                                                            <td>{{ partyRateData.unloadingChg }}</td>
                                                            <td>{{ partyRateData.unloadingChgUnit }}</td>
                                                            <td>{{ partyRateData.unloadingChgGd }}</td>
                                                            <td>{{ partyRateData.unloadingChgUnitGd }}</td>
                                                            <td>{{ partyRateData.customHamaliSrcChg }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<!--POP UP START HERE-->
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #stopRatePopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>Stop Rate Effective From :</h5>
                    <!--<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>-->
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <!-- spinner start-->
                                    <div *ngIf="showSpinnerForActionForStopEffe" class="col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                    Please Wait Loading Details.....</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- spinner end-->
                                    <div class="col-sm-12 col-md-8">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Effective From</label> <input class="form-control" id="{{pageId}}stopEffectFromDate" placeholder="yyyy-mm-dd" name="stopEffectFromDate" ngbDatepicker #stopEffectFromDate="ngbDatepicker">
                                                <div class="input-group-append" (click)="stopEffectFromDate.toggle()">
                                                    <span class="input-group-text"> <i class="fa fa-calendar"></i>
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-4"></div>
                                    <div class="col-sm-12 col-md-2">
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closePopUp()">Close</button>
                                    </div>
                                    <div class="col-sm-12 col-md-4">
                                        <button style="float: left;" type="button" class="btn btn-success" id="{{pageId}}cancelBtnId" (click)="deleteSelectedConsigneeDetailsMultiPopUp();">OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>