import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import swal from 'sweetalert';
import { Router } from '@angular/router';

@Component({
    selector: 'app-generate-barcode-print',
    templateUrl: './generate-barcode-print.component.html',
    styleUrls: ['./generate-barcode-print.component.css']
})
export class GenerateBarcodePrintComponent implements OnInit, AfterViewInit {
    PrintBarcode = [];
    articleCount: number = 1;
    dataForPrint: any;
    enteredLrNumber: string;
    destination: string;
    isLoggedIn = true;

    constructor(
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        // Check session
        if (sessionStorage.length === 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        } else {
            this.dataForPrint = JSON.parse(localStorage.getItem('printAfterSaveForLrEntry'));
            if (this.dataForPrint) {
                this.prepareBarcodeData('lrEntry');
            }
        }
    }

    ngOnInit(): void {
        const printData = localStorage.getItem('printBarcodeData');
        if (printData) {
            this.PrintBarcode = JSON.parse(printData);
        }
    }

    ngAfterViewInit(): void {
        // Trigger print after view initialization if PrintBarcode data is available
        if (this.PrintBarcode.length) {
            this.changeDetectorRef.detectChanges();
            setTimeout(() => {
                window.print();
                localStorage.removeItem('printBarcodeData'); // Clear data after printing
                // this.router.navigate(['/generate-barcode']); // Optional navigation after print
            }, 500);
        }
    }

    prepareBarcodeData(mode: string) {
        // Populate PrintBarcode array for display
        this.PrintBarcode = [];
        if (mode === 'lrEntry') {
            this.enteredLrNumber = this.dataForPrint.lrNumber;
            this.destination = this.dataForPrint.destination;
            this.articleCount = this.dataForPrint.article;
        }

        for (let i = 1; i <= this.articleCount; i++) {
            this.PrintBarcode.push({
                barcode: this.enteredLrNumber,
                Name: `${this.enteredLrNumber}`,
                Destination: `${this.destination}`,
                ArticleIndex: `${i}/${this.articleCount}`
            });
        }

        // Save data for printing
        localStorage.setItem('printBarcodeData', JSON.stringify(this.PrintBarcode));
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
