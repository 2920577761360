import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
/*old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends

//import { DragulaModule } from 'ng2-dragula';
import { DatePipe } from '@angular/common';

import { TransferRoutes } from 'src/app/stock/transfer/transfer.routing';
import { ShipmentStatusComponent } from 'src/app/stock/transfer/shipment-status/shipment-status.component';
import { StocksToCpComponent } from 'src/app/stock/transfer/stocks-to-cp/stocks-to-cp.component';
import { StocksBetweenGodownComponent } from 'src/app/stock/transfer/stocks-between-godown/stocks-between-godown.component';
import { MaterialModule } from "src/app/material.module";
import { ManualCashmemoCheckAndCollectionmanStockCheckPrintComponent } from 'src/app/cashmemo/transfer-cashmemo/manual-cashmemo-check-and-collectionman-stock-check-print/manual-cashmemo-check-and-collectionman-stock-check-print.component';
import { UnloadingSheetDetailsComponent } from 'src/app/stock/transfer/unloading-sheet-details/unloading-sheet-details.component';
import { ConsigneeModule } from 'src/app/master/consignee/consignee.module';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { StocksForLocalBookingComponent } from 'src/app/stock/transfer/stocks-for-local-booking/stocks-for-local-booking.component';
import { StocksForTripSheetPageModule } from 'src/app/stocks-for-tripsheet-page/stocks-for-tripsheet-page.module';
import { StocksForLocalBookingBarcodeComponent } from './stocks-for-local-booking-barcode/stocks-for-local-booking-barcode.component';
import { UnclearedLrDetailsComponent } from './uncleared-lr-details/uncleared-lr-details.component';

@NgModule({
    imports: [CommonModule, 
        // RouterModule.forChild(TransferRoutes),
         FormsModule, 
        NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule,
        ReactiveFormsModule, AutocompleteModule, DailyReportModule, MaterialModule
        , ConsigneeModule, CashmemoReportModule, StocksForTripSheetPageModule],
    declarations: [
        ShipmentStatusComponent,
        StocksToCpComponent,
        StocksBetweenGodownComponent,
        // ManualCashmemoCheckAndCollectionmanStockCheckPrintComponent,
        UnloadingSheetDetailsComponent,
        StocksForLocalBookingComponent,
        StocksForLocalBookingBarcodeComponent,
        UnclearedLrDetailsComponent
    ],
    entryComponents: [UnloadingSheetDetailsComponent],
    exports: [StocksForLocalBookingComponent, StocksForLocalBookingBarcodeComponent]
})
export class TransferModule { }
