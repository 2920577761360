import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from 'sweetalert';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { RequestDto } from 'src/app/dto/Request-dto';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Observable, merge } from 'rxjs';
import { LRDto } from 'src/app/dto/LR-dto';
import { ResponseDto } from 'src/app/dto/Response-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-part-b-update',
    templateUrl: './part-b-update.component.html',
    styleUrls: ['./part-b-update.component.css']
})

export class PartBUpdateomponent implements OnInit {

    userDataDtoReturnSession: any;
    pageId = "prtBUdt";
    gstSearch = true;
    isLoggedIn = true;
    requestDto: RequestDto = new RequestDto();
    enteredEwayBillNo: any;
    showSpinnerForAction = false;
    showSpinnerForActionForVeh = false;
    fromDetails: string = '';
    toDetails: string = '';
    vehicleDetails: any;
    dtTriggerVehicleDtls: Subject<any> = new Subject();
    partBVehicleNumber = null;
    lrtDtoRespForEwayApi: LRDto = new LRDto();
    enteredReason: any;
    enteredPlaceChange: any;
    enteredTransDocNo: any;
    enteredStateCode: any;
    reasonCode: number;
    dtOptionsVehicleDtls: any;
    responseDtoForEwayApi: ResponseDto = new ResponseDto();
    fromGstin: any;
    fromTrdName: any;
    fromAddr1: any;
    fromAddr2: any;
    fromPincode: any;
    fromPlace: any;
    toGstin: any;
    toTrdName: any;
    toAddr1: any;
    toAddr2: any;
    toPlace: any;
    toPincode: any;
    lrtDtoForEwayApi: LRDto = new LRDto();
    fromAddressDtls: any;
    toAddressDtls: any;
    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    stateCode: any;
    isValid = false;
    returnUdtPartB: any;
    errorCode: any;
    errorMessage: any;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    partyMasterDtoOptions: PartyMasterDto[];
    public modelStateNameAndCode: any;
    modelStateNameAndCodeTA: Array<PartyMasterDto> = [];
    focusStateNameAndCodeTA$ = new Subject<string>();
    searchStateNameAndCode = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusStateNameAndCodeTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelStateNameAndCodeTA
                : this.modelStateNameAndCodeTA.filter(v => v.state.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterStateNameAndCode = (x: { state: string }) => x.state;

    constructor(
        private masterReadService: MasterReadService, private router: Router, private sanitizer: DomSanitizer,
        private ewaybillService: EwaybillService, public changeDetectorRef: ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getStatesMethod();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsVehicleDtls = {
            dom: 'Bfrtip',
            buttons: [
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            searching: false,
            pagingType: 'full_numbers',
            pageLength: 9,
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerVehicleDtls.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerVehicleDtls.next();
    }

    validateForEWayBillNo() {
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        if (this.enteredEwayBillNo == null || this.enteredEwayBillNo == undefined || this.enteredEwayBillNo == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter E-Way Bill Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            this.getDetailsFromEwayBillRefreshEwb();
        }
    }

    getDetailsFromEwayBillRefreshEwb() {
        this.showSpinnerForAction = true;
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        this.lrtDtoRespForEwayApi = new LRDto();
        this.responseDtoForEwayApi = new ResponseDto();
        this.responseDtoForEwayApi.ewayBillNo = this.enteredEwayBillNo;
        //CHG-V-LR:26
        this.responseDtoForEwayApi.token1 = this.userDataDtoReturnSession.mainAdminStation;
        this.ewaybillService.getDetailsFromEwayBillRefreshEwb(this.responseDtoForEwayApi).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.lrtDtoRespForEwayApi = response;
                this.vehicleDetails = [];
                $("#" + this.pageId + "vehicleDetailsTable").DataTable().destroy();
                console.log(this.lrtDtoRespForEwayApi);
                if (this.lrtDtoRespForEwayApi.ewayBillStatus != null && this.lrtDtoRespForEwayApi.ewayBillStatus == "CANCEL") {
                    swal("Not Allowed", "This E-Way Bill Number : " + this.enteredEwayBillNo + "  show as Cancelled!.. Please Verify entered E-Way Bill No!", "warning");
                    $("#" + this.pageId + "eWayBillNo").val('');
                    this.enteredEwayBillNo = null;
                }
                else if (this.lrtDtoRespForEwayApi.isBlockedEwayBill) {
                    swal("Not Allowed", "The entered E-Way Bill No : " + this.enteredEwayBillNo + " is expiring today!.. Please do the extension to continue with the LR Generation.", "warning");
                    $("#" + this.pageId + "eWayBillNo").val('');
                    this.enteredEwayBillNo = null;
                }
                else {
                    if (this.lrtDtoRespForEwayApi.respStatus == 1) {
                        this.setAddressDetails(this.lrtDtoRespForEwayApi);
                    } else {
                        console.log('getDetailsFromEwayBillRefreshEwb : no ewaybill details found!');
                    }
                }
                this.changeDetectorRef.detectChanges();
                this.dtTriggerVehicleDtls.next();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the details from the EWay Bill Number", "warning");
            }, () => console.log('done');
    }

    setAddressDetails(lrtDtoRespForEwayApi) {
        this.fromGstin = lrtDtoRespForEwayApi.gstNoConsignor;
        this.fromTrdName = lrtDtoRespForEwayApi.name;
        this.fromAddr1 = lrtDtoRespForEwayApi.address1;
        this.fromAddr2 = lrtDtoRespForEwayApi.address2;
        this.fromPincode = lrtDtoRespForEwayApi.fromPincode;
        this.fromPlace = lrtDtoRespForEwayApi.source;

        this.toGstin = lrtDtoRespForEwayApi.gstNoConsignee;
        this.toTrdName = lrtDtoRespForEwayApi.consigneeName;
        this.toAddr1 = lrtDtoRespForEwayApi.consigneeAdress;
        this.toAddr2 = lrtDtoRespForEwayApi.consigneeAddress2;
        this.toPlace = lrtDtoRespForEwayApi.destination;
        this.toPincode = lrtDtoRespForEwayApi.pinCode;

        this.fromAddressDtls =
            this.fromGstin + "\n" +
            this.fromTrdName + "\n" +
            this.fromAddr1 + " " + this.fromAddr2 + "\n" +
            this.fromPlace + " - " + this.fromPincode;


        this.toAddressDtls =
            this.toGstin + "\n" +
            this.toTrdName + "\n" +
            this.toAddr1 + " " + this.toAddr2 + "\n" +
            this.toPlace + " - " + this.toPincode;

        this.vehicleDetails = this.lrtDtoRespForEwayApi.vehicleDetails;

        let vehicleDetailsArray = [];
        for (let i = 0; i < this.vehicleDetails.length; i++) {
            let parts = this.vehicleDetails[i].split(" #");

            vehicleDetailsArray.push({
                transDocDate: parts[0] || '',
                vehicleNo: parts[1] || '',
                transDocNo: parts[2] || '',
                changePlace: parts[3] || '',
                enteredDate: parts[4] || '',
                enteredBy: parts[5] || '',
                cewbNo: parts[6] || ''
            });
        }
        this.vehicleDetails = vehicleDetailsArray;
        this.changeDetectorRef.detectChanges();
        // this.dtTriggerVehicleDtls.next();
    }

    validateEwayPartBUpdate() {
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        this.partBVehicleNumber = $("#" + this.pageId + "vehicleNo").val();
        this.enteredReason = $("#" + this.pageId + "reason").val();
        this.enteredPlaceChange = $("#" + this.pageId + "placeChange").val();
        this.enteredTransDocNo = $("#" + this.pageId + "transDocNo").val();
        this.enteredStateCode = $("#" + this.pageId + "stateCode").val();
        this.partBVehicleNumber = $("#" + this.pageId + "vehicleNo").val();
        console.log('validateEwayPartBUpdate - ' + this.partBVehicleNumber);
        // if (!this.validateVehicleNumber()) {
        //     swal({
        //         title: "In-Valid Vehicle Number",
        //         text: "Please enter the correct Vehicle Number to proceed!",
        //         icon: "warning",
        //         closeOnClickOutside: false,
        //         closeOnEsc: false,
        //     });
        // } 
        if (this.enteredEwayBillNo == null || this.enteredEwayBillNo == '' || this.enteredEwayBillNo == undefined
            || this.enteredReason == null || this.enteredReason == '' || this.enteredReason == undefined
            || this.enteredPlaceChange == null || this.enteredPlaceChange == '' || this.enteredPlaceChange == undefined
            || this.enteredStateCode == null || this.enteredStateCode == '' || this.enteredStateCode == undefined
            || this.partBVehicleNumber == null || this.partBVehicleNumber == '' || this.partBVehicleNumber == undefined) {
            swal({
                title: "Not Allowed",
                text: "Please Enter the Mandatory Fields!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            });
        } else {
            swal({
                title: "Confirm Update",
                text: "Sure you want to update the Part-B for the Ewaybill No : " + this.enteredEwayBillNo,
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.ewayBillPartBUpdate();
                } else {
                    swal.close();
                }
            })
        }
    }

    setEwayBillPartBUpdate() {
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        this.partBVehicleNumber = $("#" + this.pageId + "vehicleNo").val();
        this.enteredReason = $("#" + this.pageId + "reason").val();
        this.enteredPlaceChange = $("#" + this.pageId + "placeChange").val();
        this.enteredTransDocNo = $("#" + this.pageId + "transDocNo").val();
        this.enteredStateCode = $("#" + this.pageId + "stateCode").val();

        if (this.enteredReason != null && this.enteredReason == "Due to Break Down") {
            this.reasonCode = 1;
        } else if (this.enteredReason != null && this.enteredReason == "Due to Transshipment") {
            this.reasonCode = 2;
        } else if (this.enteredReason != null && this.enteredReason == "Others") {
            this.reasonCode = 3;
        } else if (this.enteredReason != null && this.enteredReason == "First Time") {
            this.reasonCode = 4;
        }

        this.lrtDtoForEwayApi = new LRDto();
        this.lrtDtoForEwayApi.eWayBillNo = this.enteredEwayBillNo;
        this.lrtDtoForEwayApi.vehicleNumber = this.partBVehicleNumber;
        this.lrtDtoForEwayApi.fromStation = this.enteredPlaceChange;
        this.lrtDtoForEwayApi.lrNumber = this.enteredTransDocNo;
        this.lrtDtoForEwayApi.reason = this.enteredReason;
        this.lrtDtoForEwayApi.fromState = this.stateCode;
        this.lrtDtoForEwayApi.reasonCode = this.reasonCode;
        console.log(this.lrtDtoForEwayApi);
    }

    validateVehicleNumber() {
        this.isValid = false;
        this.partBVehicleNumber = $("#" + this.pageId + "vehicleNo").val();
        if ((this.partBVehicleNumber == '') || (this.partBVehicleNumber == null)) {
            this.isValid = false;
        } else {
            this.partBVehicleNumber = this.partBVehicleNumber.replace(/\s/g, "");

            if (this.partBVehicleNumber.length < 9) {
                this.isValid = false;
            } else {
                if (/\d/.test(this.partBVehicleNumber) && /[a-zA-Z]/.test(this.partBVehicleNumber)) {
                    this.isValid = true;
                } else {
                    this.isValid = false;
                }
            }
        }
        return this.isValid;
    }

    ewayBillPartBUpdate() {
        this.setEwayBillPartBUpdate();
        this.showSpinnerForActionForVeh = true;
        this.ewaybillService.ewaybillPartBUpdate(this.lrtDtoForEwayApi).subscribe(
            (response) => {
                this.returnUdtPartB = response;
                if (response.status == "Success") {
                    swal({
                        title: "Completed",
                        text: "Part-B Successfully Updated!",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    })
                        .then(() => {
                            this.clearFields();
                            this.getDetailsFromEwayBillRefreshEwb();
                        });
                }
                else {
                    // swal("Failed", "Failed to update the Part-B, please try again or contact Admin!", "error");
                    this.errorCode = this.returnUdtPartB.respField;
                    this.errorMessage = this.returnUdtPartB.message;
                    swal({
                        title: "Error",
                        text: "Error Code : " + this.errorCode + " & Message " + this.errorMessage + " While Updating Part B!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.showSpinnerForActionForVeh = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForActionForVeh = false;
                swal("Error", "Error while updating the Part-B!", "error")
            }, () => console.log('done');
    }

    getStatesMethod() {
        this.partyMasterDto = new PartyMasterDto();
        this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.getStateDetailsService(this.partyMasterDto).subscribe(
            (response) => {
                // console.log(response);
                if (response.length == 0) {
                    this.partyMasterDtoOptions = [];
                    this.modelStateNameAndCodeTA = [];
                } else {
                    this.partyMasterDtoOptions = response;
                    this.modelStateNameAndCodeTA = [];
                    for (let i = 0; i < this.partyMasterDtoOptions.length; i++) {
                        this.modelStateNameAndCodeTA.push(this.partyMasterDtoOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting getStatesMethod", "warning");
            },
            () => console.log('done');
    }

    clickListnerForState(e: NgbTypeaheadSelectItemEvent) {
        this.modelStateNameAndCode = e.item;
        this.stateCode = (this.modelStateNameAndCode.code);
        console.log(this.modelStateNameAndCode.code);
    }

    clearField() {
        $("#" + this.pageId + "ewayBillNo").val('');
    }

    clearFields() {
        $("#" + this.pageId + "reason").val('');
        $("#" + this.pageId + "vehicleNo").val('');
        $("#" + this.pageId + "placeChange").val('');
        $("#" + this.pageId + "transDocNo").val('');
        $("#" + this.pageId + "stateCode").val('');
        this.fromAddressDtls = null;
        this.toAddressDtls = null;
        $("#" + this.pageId + "vehicleDetailsTable").DataTable().destroy();
        this.vehicleDetails = [];
        this.dtTriggerVehicleDtls.next();
        this.returnUdtPartB = [];
    }

}
