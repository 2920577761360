import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/dataService/master-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LRDto } from 'src/app/dto/LR-dto';
import *  as moment from 'moment';
import { DatePipe } from "@angular/common";
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';

@Component({
    selector: 'app-uncleared-lr-details',
    templateUrl: './uncleared-lr-details.component.html',
    styleUrls: ['./uncleared-lr-details.component.css']
})
export class UnclearedLrDetailsComponent implements OnInit {

    unclearedLrDataList: any;
    pageId = "unclrdlrdlts";
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerUnclearedLrDetails: Subject<any> = new Subject();
    dtOptionsUnclearedLrDetails: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    lrDtoGet: LRDto = new LRDto();
    lrDtoForSetLrDetailsValue: LRDto = new LRDto();
    validateLRNumber: any;
    bookingDateModal: any;
    dateModal: any;
    lrDtoForSave: LRDto = new LRDto();
    selectedReason: any;
    selectedDate: any;
    enteredRemarks: any;
    rateDtoReason: RateMasterDto = new RateMasterDto();
    reasonsOptions: any;
    autoId: number = 0;
    lrDtoDtoForClear: LRDto = new LRDto();
    lrDtoRet: any;

    public modelReasons: any;
    reasonTA: Array<RateMasterDto> = [];
    focusReasonTA$ = new Subject<string>();
    searchReason = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusReasonTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.reasonTA
                : this.reasonTA.filter(v => v.reason.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterReason = (x: { reason: string }) => x.reason;


    constructor(private masterService: MasterService, private masterReadService: MasterReadService,
        private router: Router, private modalService: NgbModal,
        private lrReport: LrReportService, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getReasonsDetails();
            this.getUnclearedLrDetailsMethod();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsUnclearedLrDetails = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    title: function () {
                        return "Uncleared LR Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            searching: true,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
            select: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                $(api.column(0).footer()).html('Total : ' + data.length);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerUnclearedLrDetails.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerUnclearedLrDetails.unsubscribe();
    }

    lrNumberKeyPressEventListner(event) {
        console.log(event);
        if (event.charCode == 13) {
            this.validateLRNumber = $("#" + this.pageId + "lrNumber").val();
            if (this.validateLRNumber == null || this.validateLRNumber == undefined || this.validateLRNumber == '') {
                swal("Not Allowed", "Please Enter the LR Number!", "warning");
            } else {
                this.getLRForUnclearedLrDetailsMethod();
            }
        }
    }

    getLRForUnclearedLrDetailsMethod() {
        this.lrDtoForSetLrDetailsValue = new LRDto();
        this.validateLRNumber = $("#" + this.pageId + "lrNumber").val();
        this.lrDtoForSetLrDetailsValue.lrNumber = this.validateLRNumber;
        this.lrDtoForSetLrDetailsValue.stocksAt = this.userDataDtoReturnSession.office;
        this.lrDtoForSetLrDetailsValue.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrReport.getLRForUnclearedLrDetails(this.lrDtoForSetLrDetailsValue).subscribe(
            (response) => {
                console.log(response);
                if (response.size == 0) {
                    swal("Not Found", "LR Number " + $("#" + this.pageId + "lrNumber").val() + " not found!", "warning");
                    this.clearFields();
                } else {
                    this.setLRDetailsValues(response);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting LR Details", "warning");
            },
            () => console.log('done');
    }

    setLRDetailsValues(lrdtoReturnResponse) {
        $("#" + this.pageId + "bookingDates").val(lrdtoReturnResponse.bookingDate == null ? null : moment(lrdtoReturnResponse.bookingDate).format("YYYY-MM-DD"));
        $("#" + this.pageId + "destination").val(lrdtoReturnResponse.destination);
        $("#" + this.pageId + "consigneeName").val(lrdtoReturnResponse.consigneeName);
        $("#" + this.pageId + "articles").val(lrdtoReturnResponse.totalArticles);
        $("#" + this.pageId + "consignorName").val(lrdtoReturnResponse.consignorName);
        $("#" + this.pageId + "agentName").val(lrdtoReturnResponse.agentId);
    }

    validateForSaveUnclearedLrdetails() {
        if ($("#" + this.pageId + "lrNumber").val() == null || $("#" + this.pageId + "lrNumber").val() == undefined ||
            $("#" + this.pageId + "lrNumber").val() == "" || $("#" + this.pageId + "reasons").val() == null || $("#" + this.pageId + "reasons").val() == undefined ||
            $("#" + this.pageId + "reasons").val() == "" || $("#" + this.pageId + "dates").val() == null || $("#" + this.pageId + "dates").val() == undefined ||
            $("#" + this.pageId + "dates").val() == "") {
            swal("Mandatory Field", "Please enter the LR No, Reasons & Date are mandatory fields to update the details!", "warning");
        } else {
            swal({
                title: "Confirm Update",
                text: "Sure you want to update the uncleared lr details?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yes) => {
                if (yes) {
                    this.checkSaveUnclearedLrdetails();
                }
            });
        }
    }

    clearFields() {
        $("#" + this.pageId + "lrNumber").val('');
        $("#" + this.pageId + "bookingDates").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "articles").val('');
        $("#" + this.pageId + "consignorName").val('');
        $("#" + this.pageId + "agentName").val('');
        $("#" + this.pageId + "reasons").val('');
        $("#" + this.pageId + "dates").val('');
        $("#" + this.pageId + "remarks").val('');
        this.bookingDateModal = null;
        this.validateLRNumber = null;
        this.dateModal = null;
        this.selectedReason = null;
        this.selectedDate = null;
        this.enteredRemarks = null;
        this.autoId = 0;
    }

    setForSaveUnclearedLrDetails() {
        this.validateLRNumber = $("#" + this.pageId + "lrNumber").val();
        this.selectedReason = $("#" + this.pageId + "reasons").val();
        this.selectedDate = $("#" + this.pageId + "dates").val();
        this.enteredRemarks = $("#" + this.pageId + "remarks").val();

        this.lrDtoForSave = new LRDto();
        this.lrDtoForSave.lrNumber = this.validateLRNumber;
        this.lrDtoForSave.reason = this.selectedReason;
        this.lrDtoForSave.enteredDate = this.selectedDate;
        this.lrDtoForSave.remarks = this.enteredRemarks;
        this.lrDtoForSave.id = this.autoId;
        this.lrDtoForSave.userName = this.userDataDtoReturnSession.userId;
    }

    SaveUnclearedLrdetailsMethod() {
        this.setForSaveUnclearedLrDetails();
        this.masterReadService.saveUnclearedLrdetails(this.lrDtoForSave).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "Success") {
                    swal("Success", "Uncleared Lr Details Updated Successfully", "success");
                    this.getUnclearedLrDetailsMethod();
                    this.clearFields();
                } else {
                    swal("Alert", "Failed to Update Uncleared Lr Details", "error");
                    return false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Update Uncleared Lr Details", "error");
            },
            () => console.log('done');
    };

    getUnclearedLrDetailsInfo() {
        this.lrDtoGet = new LRDto();
        this.lrDtoGet.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoGet.companyId = this.userDataDtoReturnSession.companyId;
    }

    getUnclearedLrDetailsMethod() {
        this.getUnclearedLrDetailsInfo();
        this.showSpinnerForAction = true;
        this.masterReadService.getUnclearedLrDetails(this.lrDtoGet).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                $("#" + this.pageId + "unclearedLrDetailsTable").DataTable().destroy();
                this.unclearedLrDataList = [];
                if (response.length > 0) {
                    this.unclearedLrDataList = response;
                } else {
                    swal("Empty", "No Data Found For Uncleared LR Details", "info");
                }
                this.dtTriggerUnclearedLrDetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Uncleared LR Details", "warning");
            },
            () => console.log('done');
    }

    getReasonsMethod() {
        this.rateDtoReason = new RateMasterDto();
        this.rateDtoReason.companyId = this.userDataDtoReturnSession.companyId;
    }

    getReasonsDetails() {
        this.getReasonsMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getReasonForUnclearedLrDetails(this.rateDtoReason)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response.length > 0) {
                    this.reasonsOptions = response;
                    this.reasonTA = [];
                    for (let i = 0; i < this.reasonsOptions.length; i++) {
                        this.reasonTA.push(this.reasonsOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Reason Details", "info");
            },
            () => console.log("done");
    };

    setClearAndMoveUnclearedLrDetails(unclearedLrData) {
        this.lrDtoDtoForClear = new LRDto();
        this.lrDtoDtoForClear.id = unclearedLrData.id;
        this.lrDtoDtoForClear.lrNumber = unclearedLrData.lrNumber;
        this.lrDtoDtoForClear.userName = this.userDataDtoReturnSession.userId;
        console.log(this.lrDtoDtoForClear);
        this.clearAndMoveUnclearedLrDetailsMethod();
    }

    clearAndMoveUnclearedLrDetailsMethod() {
        swal({
            title: "Confirm Clear",
            text: "Are you sure want to Clear & Move this Lr into stock?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yes) => {
            if (yes) {
                this.masterReadService.clearAndMoveUnclearedLrDetails(this.lrDtoDtoForClear).
                    subscribe((data) => {
                        this.lrDtoRet = data;
                        if (this.lrDtoRet.status == "Success") {
                            swal("Success", "Uncleared LR details have been moved successfully!", "info");
                            this.getUnclearedLrDetailsMethod();
                        } else {
                            swal("Failed", "Failed to move the uncleared lr details!", "error");
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        swal("Error", "Server problem occurred while moving the uncleared lr details!", "error");
                    },
                    () => console.log('done');
            }
        });
    }

    rowSelectedView(unclearedLrData) {
        $("#" + this.pageId + "lrNumber").val(unclearedLrData.lrNumber);
        $("#" + this.pageId + "reasons").val(unclearedLrData.reason);
        $("#" + this.pageId + "dates").val(unclearedLrData.enteredDateStr);
        $("#" + this.pageId + "remarks").val(unclearedLrData.remarks);
        this.autoId = unclearedLrData.id;
    }

    checkDuplicateEntryForSaveUnclearedLrdetailsMethod() {
        this.setForSaveUnclearedLrDetails();
        this.masterReadService.checkDuplicateEntryForSaveUnclearedLrDetails(this.lrDtoForSave).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "Exists") {
                    swal("Duplicate", "These entered details are already updated!", "warning");
                } else {
                    this.SaveUnclearedLrdetailsMethod();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Checking Uncleared Lr Details", "error");
            },
            () => console.log('done');
    };

    checkSaveUnclearedLrdetails() {
        if (this.autoId != null && this.autoId != 0) {
            this.SaveUnclearedLrdetailsMethod();
        } else {
            this.checkDuplicateEntryForSaveUnclearedLrdetailsMethod();
        }
    }

}