<div class="row" *ngIf="isLoggedIn" id="{{pageId}}ewbSearch">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center'
                                            style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>E-Way Bill No * </label>
                                                    <input #ewayBillNo type="text" id="{{pageId}}ewayBillNo"
                                                        class="form-control" placeholder="Search by EWay Bill No"
                                                        aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                            <hr style="width: 80%; border-top: none; margin: 3px;">
                                        </div>
                                    </div>

                                    &nbsp;
                                    <div class="col-md-12" style="text-align: center;">
                                        <button type="submit" class="btn btn-success m-r-10"
                                            (click)="validateForEWayBillNo();" id="{{pageId}}searchBtn">Get PDF</button>
                                        <button type="submit" class="btn btn-success m-r-10"
                                            (click)="validateForEWayBill();" id="{{pageId}}searchBtn">Get
                                            Details</button>
                                        <button type="submit" class="btn btn-dark" (click)="clearBtn();"
                                            id="{{pageId}}clearBtn">Clear</button>
                                    </div>
                                </div>
                                <div class="col-md-9" *ngIf="viewPdfEway">
                                    <div class="search-container">
                                        <div class="pdf-viewer" *ngIf="pdfUrl">
                                            <iframe [src]="pdfUrl" width="100%" height="600px"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-9" *ngIf="viewRefreshEway">
                                    <div class="d-flex justify-content-end mb-2">
                                        <button class="btn btn-primary" (click)="printPage()">Print</button>
                                    </div>
                                    <div id="printSection">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5 class="text-primary">1. E-Way Bill Details</h5>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>E-Way Bill No:</label>
                                                                <label>{{eWayBillNo}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Generated Date:</label>
                                                                <label>{{generatedDateStr}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Generated By:</label>
                                                                <label>{{generatedBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Valid Upto Date :</label>
                                                                <label>{{ewayBillValidUptoStr}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label> Approx Distance:</label>
                                                                <label>{{distance}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Doc No:</label>
                                                                <label>{{docNo}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Doc Date:</label>
                                                                <label>{{docDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="col-md-12">
                                                <h5 class="text-primary">2. Address Details</h5>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>From Address:</label>
                                                                <label>{{fromAddressDtls}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>To Address:</label>
                                                                <label>{{toAddressDtls}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="col-md-12">
                                                <h5 class="text-primary">3. Goods Details</h5>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <table datatable id="{{pageId}}itemDetailsTable"
                                                            class="table table-striped table-bordered row-border hover"
                                                            [dtOptions]="dtOptionsitemDtls"
                                                            [dtTrigger]="dtTriggeritemDtls">
                                                            <thead>
                                                                <tr>
                                                                    <th>HSN Code</th>
                                                                    <th>Prod Name</th>
                                                                    <th>Desc</th>
                                                                    <th>Qty</th>
                                                                    <th>Units</th>
                                                                    <th>Taxable Amt</th>
                                                                    <th>IGST</th>
                                                                    <th>CGST</th>
                                                                    <th>SGST</th>
                                                                    <th>Cess Rate</th>
                                                                    <th>Cess Non Advol</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let itemDetails of itemDetailList">
                                                                    <td>{{ itemDetails.hsnCode }}</td>
                                                                    <td>{{ itemDetails.productName }}</td>
                                                                    <td>{{ itemDetails.productDesc }}</td>
                                                                    <td>{{ itemDetails.quantity }}</td>
                                                                    <td>{{ itemDetails.qtyUnit }}</td>
                                                                    <td>{{ itemDetails.taxableAmount }}</td>
                                                                    <td>{{ itemDetails.igstRate }}</td>
                                                                    <td>{{ itemDetails.cgstRate }}</td>
                                                                    <td>{{ itemDetails.sgstRate }}</td>
                                                                    <td>{{ itemDetails.cessRate }}</td>
                                                                    <td>{{ itemDetails.cessNonAdvol }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Tot. Taxable Amt:</label>
                                                                <label>{{totalAmount}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>CGST Amt:</label>
                                                                <label>{{cgst}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>SGST Amt:</label>
                                                                <label>{{sgst}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>IGST Amt:</label>
                                                                <label>{{igst}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>CESS Amt:</label>
                                                                <label>{{cessAmount}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>CESS Non.Advol Amt:</label>
                                                                <label>{{cessNonAdvolAmount}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Other Amt:</label>
                                                                <label>{{others}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Total Inv. Amt:</label>
                                                                <label>{{grandTotal}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="col-md-12">
                                                <h5 class="text-primary">4. Transportation Details</h5>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Transporter ID & Name :</label>
                                                                <label>{{transportId}} - {{transportName}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Transporter Doc. No & Date
                                                                    :</label>
                                                                <label>{{transDocNo2}} - {{transDocDate2}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="col-md-12">
                                                <h5 class="text-primary">5. Vehicle Details</h5>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <table datatable id="{{pageId}}vehicleDetailsTable"
                                                            class="table table-striped table-bordered row-border hover"
                                                            [dtOptions]="dtOptionsVehicleDtls"
                                                            [dtTrigger]="dtTriggerVehicleDtls">
                                                            <thead>
                                                                <tr>
                                                                    <th>Trans Mode</th>
                                                                    <th>Vehicle No / Trans Doc No</th>
                                                                    <th>From Place</th>
                                                                    <th>Updated By</th>
                                                                    <th>Updated Date</th>
                                                                    <th>Cons.EWB No.</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let vehicle of vehicleDetails">
                                                                    <td>Road</td>
                                                                    <td>{{ vehicle.vehicleNo }} / {{ vehicle.transDocNo}}</td>
                                                                    <td>{{ vehicle.changePlace }}</td>
                                                                    <td>{{ vehicle.enteredBy }}</td>
                                                                    <td>{{ vehicle.enteredDate }}</td>
                                                                    <td>{{ vehicle.cewbNo }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>