<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}invoiceLoadingSheetFlow">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 7px;">
                    <h6 class="card-title text-white">Invoice Loading Sheet - {{selectedLoadingInvoiceNumber}}</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12 vl p-t-12">
                        <div class="row">
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-md-2" style="margin-top: 15px;">
                                <div class="col-sm-12 col-md-12">
                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                <label>Total Ton</label>
                                                <input placeholder="Enter Total Ton" (keyup)="getConsignorGstNumber($event)" type="number" class="form-control" id="{{pageId}}loadingSheetTon" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12">
                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
                                                <label>Freight/Kg</label>
                                                <input placeholder="Enter Freight/Kg" (keyup)="getConsignorGstNumber($event)" type="number" class="form-control" id="{{pageId}}loadingSheetFgtPerKg" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <br>
                            <br>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <h6 style="color: green; cursor: pointer; padding-left: 20px;padding-top: 20px;" (click)="callLoadedLrsSummary(contentLoadedLrSummary)">
                                                    Loaded LR Summary
                                                </h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <h6 style="color: green; cursor: pointer;padding-top: 20px;" (click)="callLoadedLrs(contentLoadedLrs)">
                                                    Loaded LR Details
                                                </h6>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-md-3">
                                        <button style="margin-top: 20px;margin-left: 400px;" type="button" class="btn btn-dark m-r-10" id="{{pageId}}loadingSheetUpdateBtn" (click)="validateLoadingSheetUpdate()">Update</button>
                                    </div> -->

                                    <div class="col-sm-12 col-md-3">
                                        <button style="float: right;margin-top: 20px;" type="button" class="btn btn-dark" id="{{pageId}}loadingSheetUpdateBtn" (click)="validateLoadingSheetUpdate()">Update</button>
                                    </div>

                                    <div class="col-md-3">

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            
                                            <div class="col-md-12">
                                                <div class="box-body">
                                                    <table datatable id="{{pageId}}loadingSheetTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLoadingSheet" [dtTrigger]="dtTriggerLoadingSheet">
                                                        <thead>
                                                            <tr>
                                                                <th>Bkg Date</th>
                                                                <th>Agent</th>
                                                                <th>LR No</th>
                                                                <th>Art</th>
                                                                <th style="width: 150px;">Loading Articles</th>
                                                                <th>Bal Art</th>
                                                                <th>AW</th>
                                                                <th>CW</th>
                                                                <th>Freight</th>
                                                                <th>Pack Nature</th>
                                                                <th class="privateMarkCol">P.Mark</th>
                                                                <th>Remarks</th>
                                                                <th>Destination</th>
                                                                <th>Notes</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let loadingSheetData of loadingSheetDataList" [ngClass]="{'highlightArtRow': loadingSheetData.loadingArticle > 0 && loadingSheetData.remArticles > 0}" [style.font-weight]="loadingSheetData.loadingArticle > 0 ? 'bold' : 'normal'" [style.background-color]="loadingSheetData.loadingArticle > 0 ? 'rgb(48, 253, 127)' : ''">

                                                                <td>{{ loadingSheetData.bookingDateStr }}</td>
                                                                <td>{{ loadingSheetData.code }}</td>
                                                                <td>
                                                                    <a class="hyperLink_td" style="cursor: pointer; font-weight: 800;" (click)="getDataForLrPrint(lrPrintCopyPopUpTemplate,loadingSheetData.lrNumber)">
                                                                        <u>{{loadingSheetData.lrNumber}}</u>
                                                                    </a>
                                                                </td>

                                                                <td>{{ loadingSheetData.totalArticles }}</td>
                                                                <td>
                                                                    <div style="display: flex; align-items: center;">
                                                                        <button type="button" class="btn btn-info waves-light" style="margin-left: 0px; width: 50px;" (click)="onMinusButtonClick(loadingSheetData)">
                                                                            <i class="fas fa-minus-circle"
                                                                                style="font-size: 23px;"></i>
                                                                        </button>
                                                                        <input style="width: 40px; margin: 0 5px;" type="number" (blur)="articleKeyPress($event,loadingSheetData)" class="small-input" [(ngModel)]="loadingSheetData.loadingArticle">
                                                                        <button type="button" class="btn btn-info waves-light" style="width: 50px;" (click)="onPlusButtonClick(loadingSheetData)">
                                                                            <i class="fas fa-plus-circle"
                                                                                style="font-size: 23px;"></i>
                                                                        </button>
                                                                    </div>
                                                                </td>

                                                                <td>{{ loadingSheetData.remArticles }}</td>
                                                                <td>{{ loadingSheetData.actualWeight }}</td>
                                                                <td>{{ loadingSheetData.chargedWeight }}</td>
                                                                <td>{{ loadingSheetData.totalAmount }}</td>
                                                                <td>{{ loadingSheetData.packNature }}</td>
                                                                <td class="privateMarkCol">{{ loadingSheetData.privateMarker }}</td>
                                                                <td>{{ loadingSheetData.remarks }}</td>
                                                                <td>{{ loadingSheetData.destination }}</td>
                                                                <td>
                                                                    <input style="width: 140px;" type="text" class="small-input" id="{{pageId}}loadingRemarks" [(ngModel)]="loadingSheetData.loadingRemarks" (blur)="updateLoadingInvRemarks(loadingSheetData)">

                                                                </td>
                                                                <td><button type='button' name='deleteBtn' id='deleteBtn' (click)="deleteInvoiceLoading(loadingSheetData)" class='btn btn-success btn-edit btn-icon-only blue'><i
                                                                            class="fa fa-trash"></i></button></td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- Row -->

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <div *ngIf="showSpinnerForAction2" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="box-body">
                                    <h6 style="border-bottom: 1px solid orange;" class="card-title">
                                        Stocks Summary</h6>
                                    <table #datatableStockSummary datatable id='{{pageId}}stockSummaryTableId' class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStockSummary" [dtTrigger]="dtTriggerStockSummary">
                                        <thead>
                                            <tr>
                                                <th>Action</th>
                                                <th>Destination</th>
                                                <th>Total LRs</th>
                                                <th>Articles</th>
                                                <th>Act Wgt</th>
                                                <th>Chg Wgt</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let stockSummaryData of stockSummaryDataList ">
                                                <td><button type='button' id="{{pageId}}tableLRDetailsBtn" (click)="rowSelectedGetTripLRDeatils(stockSummaryData);" class='btn btn-success btn-edit btn-icon-only blue'><i
                                                            class="fas fa-pencil-alt"></i></button></td>
                                                <td>{{ stockSummaryData.endDest }}</td>
                                                <td>{{ stockSummaryData.totalLrs }}</td>
                                                <td>{{ stockSummaryData.totalArticles }}</td>
                                                <td>{{ stockSummaryData.actualWeight }}</td>
                                                <td>{{ stockSummaryData.chargedWeight }}</td>
                                                <td>{{ stockSummaryData.totalAmount }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 vl">
                            <div *ngIf="showSpinnerForAction3" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="box-body">
                                    <h6 style="border-bottom: 1px solid orange;" class="card-title">
                                        Stocks Details {{selectedStockDest}}</h6>
                                    <table #datatableStockDetails datatable id='{{pageId}}stockDetailsTableId' class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsStockDetails" [dtTrigger]="dtTriggerStockDetails">
                                        <thead>
                                            <tr>
                                                <th>Action</th>
                                                <th>LR Number</th>
                                                <th>Agent Name</th>
                                                <th>Consignee Name</th>
                                                <th>Booking Date</th>
                                                <th>Articles</th>
                                                <th>Act Wgt</th>
                                                <th>Chg Wgt</th>
                                                <th>Amount</th>
                                                <th>Note</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let stockDetailsData of stockDetailsDataList ">
                                                <td><button type='button' name='addBtn' id='addBtn' (click)="addStockLr(stockDetailsData)" class='btn btn-success btn-edit btn-icon-only blue'><i
                                                            class="fa fa-plus"></i></button></td>
                                                <td>{{stockDetailsData.lrNumber }}</td>
                                                <td>{{ stockDetailsData.agentName }}</td>
                                                <td>{{ stockDetailsData.consigneeName }}</td>
                                                <td>{{ stockDetailsData.bookingDateStr }}</td>
                                                <td>{{ stockDetailsData.totalArticles }}</td>
                                                <td>{{ stockDetailsData.actualWeight }}</td>
                                                <td>{{ stockDetailsData.chargedWeight }}</td>
                                                <td>{{ stockDetailsData.totalAmount }}</td>
                                                <td>{{ stockDetailsData.remarks }}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group">
                <ng-template #lrPrintCopyPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div onafterPopUp="afterPopUp()" id="{{pageId}}popupLrPrintCopyDetails">
                                                <app-lrentryform-print3></app-lrentryform-print3>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeBtnPopup()">Close</button>
                                        </div>
                                        <div class="col-sm-12 col-md-4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

</body>

</html>


<ng-template #contentLoadedLrs let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h6 class="modal-title" id="{{pageId}}modal-basic-title">Loaded LR Details
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="custom-modal redesigned-modal">
        <div class="custom-modal-content">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12 vl">
                        <div *ngIf="showSpinnerForAction3" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="box-body">
                                <h6 style="border-bottom: 1px solid orange;" class="card-title">
                                    Loaded LR Details {{selectedStockDest}}</h6>
                                <table datatable id="{{pageId}}loadedLRsDetailTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsloadedLRsDetail" [dtTrigger]="dtTriggerloadedLRsDetail">
                                    <thead>
                                        <tr>
                                            <th>Bkg Date</th>
                                            <th>LR No</th>
                                            <th>Art</th>
                                            <th>AW</th>
                                            <th>CW</th>
                                            <th>Pack Nature</th>
                                            <th>Notes</th>
                                            <th>P.Mark</th>
                                            <th>Destination</th>
                                            <th>Agent</th>
                                            <th>Freight</th>
                                            <th>Remarks</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let loadedLRsData of loadedLRsDataList">
                                            <tr *ngIf="loadedLRsData.remArticles === 0">
                                                <td>{{ loadedLRsData.bookingDateStr }}</td>
                                                <td>
                                                    <a class="hyperLink_td" style="cursor: pointer; font-weight: 800;" (click)="getDataForLrPrint(lrPrintCopyPopUpTemplate,loadedLRsData.lrNumber)">
                                                        <u>{{loadedLRsData.lrNumber}}</u>
                                                    </a>
                                                </td>
                                                <td>{{ loadedLRsData.totalArticles }}</td>
                                                <td>{{ loadedLRsData.actualWeight }}</td>
                                                <td>{{ loadedLRsData.chargedWeight }}</td>
                                                <td>{{ loadedLRsData.packNature }}</td>
                                                <td>{{ loadedLRsData.remarks }}</td>
                                                <td>{{ loadedLRsData.privateMarker }}</td>
                                                <td>{{ loadedLRsData.destination }}</td>
                                                <td>{{ loadedLRsData.code }}</td>

                                                <td>{{ loadedLRsData.totalAmount }}</td>
                                                <td>{{ loadedLRsData.loadingRemarks }}</td>
                                                <td><button type='button' name='removeLoadedLrBtn' id='removeLoadedLrBtn' (click)="removeLoadedLrInvoiceLoading(loadedLRsData)" class='btn btn-success btn-edit btn-icon-only blue'><i
                                                            class="fa fa-trash"></i></button></td>

                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 form-group m-b-0 text-right">

                        <button type="button" class="btn btn-dark waves-effect waves-light" id="{{pageId}}clearPopupBtn" (click)="popupClose();">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #contentLoadedLrSummary let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h6 class="modal-title" id="{{pageId}}modal-basic-title">Loaded LRs Summary
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="custom-modal redesigned-modal">
        <div class="custom-modal-content">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-10" style="margin-top: 15px;">
                        <div class="form-group">
                            <div class="input-group">
                                <table style="margin-left: 20px; border: 1px solid #ddd;font-size: 14px;">
                                    <thead>
                                        <tr>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;text-align: center;" colspan="1"></th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;text-align: center;" colspan="3">Articles</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;text-align: center;" colspan="3">Actual Weight</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;text-align: center;" colspan="3">Charged Weight</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;text-align: center;" colspan="4">Freight</th>
                                        </tr>
                                    </thead>

                                    <thead>
                                        <tr>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                LRs</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Article</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Loaded</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Balance</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Actual</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Loaded</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Balance</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Charged</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Loaded</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Balance</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Target</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Loaded</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Balance</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Frgt/Kg</th>
                                            <th style="padding: 7px; border: 1px solid #ddd; background-color: #f2f2f2;">
                                                Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;">
                                                {{summaryLrs}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;">
                                                {{summaryArt}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;background-color: lightgreen;">
                                                {{summaryLoadingArt}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;background-color: #f38080;">
                                                {{summaryBalArt}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;">
                                                {{summaryActWt}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center; background-color: lightgreen;">
                                                {{summaryLoadedAW}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;background-color: #f38080;">
                                                {{summaryBalAW}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;">
                                                {{summaryChgWt}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center; background-color: lightgreen;">
                                                {{summaryLoadedCW}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;background-color: #f38080;">
                                                {{summaryBalCW}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;">
                                                {{summaryTargetFrgt}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center; background-color: lightgreen;">
                                                {{summaryLoadedFrgt}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;background-color: #f38080;">
                                                {{summaryBalFrgt}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;">
                                                {{summaryBalFrgtPerActWgt}}</td>
                                            <td style="padding: 7px; border: 1px solid #ddd; text-align: center;">
                                                {{remarks}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 form-group m-b-0 text-right">
                <button type="button" class="btn btn-dark waves-effect waves-light" (click)="popupClose();">Close</button>
            </div>

        </div>
    </div>
</ng-template>