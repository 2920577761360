import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, merge } from 'rxjs';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import swal from 'sweetalert';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';

@Component({
    selector: 'app-agent-substation-master',
    templateUrl: './agent-substation-master.component.html',
    styleUrls: ['./agent-substation-master.component.css']
})

export class AgentSubstationMasterComponent implements OnInit {

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    pageId = "agentsubstnmstr";
    dtTriggerAgentSubstationMaster: Subject<any> = new Subject();
    dtOptionsAgentSubstationMaster: any;
    showSpinnerForAction = false;
    agentDetailsDto: AgentDetailsDto = new AgentDetailsDto();
    agentSubstationDetailsDataList: any;
    readonlyMainStation = false;
    readonlySubstation = false;
    agentDetailsDtoForSave: AgentDetailsDto = new AgentDetailsDto();
    enteredDestination: any;
    enteredSubstation: any;
    enteredContactPerson: any;
    enteredContactNo: any;
    enteredAddress: any;
    enteredDeliveryChg: any;
    enteredDeliveryChgUnit: any;
    enteredldgAndUnldgChg: any;
    enteredldgAndUnldgChgUnit: any;
    enteredgdCharge: any;
    enteredgdUnit: any;
    agentDtoRet: AgentDetailsDto = new AgentDetailsDto();
    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    enteredEmailId: any;
    enteredCity: any;
    enteredState: any;
    enteredLatitude: any;
    enteredLongitude: any;
    viewMainStation: boolean = false;

    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    partyMasterDtoOptions: PartyMasterDto[];
    public modelStateNameAndCode: any;
    modelStateNameAndCodeTA: Array<PartyMasterDto> = [];
    focusStateNameAndCodeTA$ = new Subject<string>();
    searchStateNameAndCode = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusStateNameAndCodeTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelStateNameAndCodeTA
                : this.modelStateNameAndCodeTA.filter(v => v.state.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterStateNameAndCode = (x: { state: string }) => x.state;


    constructor(
        private router: Router, public changeDetectorRef: ChangeDetectorRef,
        private masterReadService: MasterReadService
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
            this.getStatesMethod();
            setTimeout(() => {
                this.getAgentSubstationDetails();
            }, 1000);
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Show All SubStation") {
                        this.viewMainStation = true;
                    }
                }
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsAgentSubstationMaster = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    title: function () {
                        return "Agent Substation Master Details";
                    },
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 310,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                $(api.column(0).footer()).html('Total : ' + data.length);

            },
            // drawCallback: function (settings) {
            //     var api = this.api();
            //     var rows = api.rows({ page: 'current' }).nodes();
            //     var last = null;
            //     var count = 0;

            //     api.column(0, { page: 'current' }).data().each(function (group, i) {
            //         var val = api.row(api.row($(rows).eq(i)).index()).data();
            //         if (last !== group) {
            //             if (i != 0) {
            //                 $(rows).eq(i).before(
            //                     $('<tr style="background-color: #f7cd82 !important; font-weight:bold;"></tr>', {
            //                         class: 'group',
            //                         'data-id': group,
            //                     })
            //                         .append($('<td></td>', { colspan: 1, text: 'Total : ' + count }))
            //                         .append($('<td></td>', { colspan: 1, text: '' }))
            //                         .append($('<td></td>', { colspan: 1, text: '' }))
            //                         .append($('<td></td>', { colspan: 1, text: '' }))
            //                         .append($('<td></td>', { colspan: 1, text: '' }))
            //                         .append($('<td></td>', { colspan: 1, text: '' }))
            //                         .append($('<td></td>', { colspan: 1, text: '' }))
            //                         .append($('<td></td>', { colspan: 1, text: '' }))
            //                         .prop('outerHTML')
            //                 );
            //                 count = 0;

            //             }
            //             $(rows).eq(i).before(
            //                 $('<tr style="background-color: #e2c185 !important; font-weight:bold;text-align: center;"></tr>', {
            //                     class: 'group',
            //                     'data-id': group,
            //                 })
            //                     .append($('<td></td>', { colspan: 8, text: group }))
            //                     .prop('outerHTML')
            //             );
            //             last = group;
            //         }
            //         count++;

            //         if (i == rows.length - 1) {
            //             $(rows).eq(i).after(
            //                 $('<tr style="background-color: #f7cd82 !important; font-weight:bold;"></tr>', {
            //                     class: 'group',
            //                     'data-id': group,
            //                 })
            //                     .append($('<td></td>', { colspan: 1, text: 'Total : ' + count }))
            //                     .append($('<td></td>', { colspan: 1, text: '' }))
            //                     .append($('<td></td>', { colspan: 1, text: '' }))
            //                     .append($('<td></td>', { colspan: 1, text: '' }))
            //                     .append($('<td></td>', { colspan: 1, text: '' }))
            //                     .append($('<td></td>', { colspan: 1, text: '' }))
            //                     .append($('<td></td>', { colspan: 1, text: '' }))
            //                     .append($('<td></td>', { colspan: 1, text: '' }))
            //                     .prop('outerHTML')
            //             );
            //             count = 0;

            //         }
            //     });
            // },

        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerAgentSubstationMaster.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerAgentSubstationMaster.unsubscribe();
    }

    getAgentSubstationDetails = () => {
        this.agentDetailsDto = new AgentDetailsDto();
        this.agentDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDetailsDto.mainStation = this.userDataDtoReturnSession.mainStation;
        if (this.viewMainStation) {
            this.agentDetailsDto.mode = "AllSubstation";
        }
        console.log(this.agentDetailsDto);

        this.showSpinnerForAction = true;
        this.masterReadService.getAgentSubstationDetailLists(this.agentDetailsDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentSubstationMasterId").DataTable().destroy();
                this.agentSubstationDetailsDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Agent Substation Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentSubstationDetailsDataList = response;
                    console.log("Agent list");
                    console.log(this.agentSubstationDetailsDataList);
                }
                this.dtTriggerAgentSubstationMaster.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  Agent Substation details", "info");
            }, () => console.log('done');
    };

    rowSelectedGetAgentSSubstationDeatils(agentSubstationDetailsData) {
        console.log("row select");
        console.log(agentSubstationDetailsData);
        $("#" + this.pageId + "destination").val(agentSubstationDetailsData.mainStation);
        $("#" + this.pageId + "substationId").val(agentSubstationDetailsData.subStation);
        $("#" + this.pageId + "contactPersonId").val(agentSubstationDetailsData.contactPerson);
        $("#" + this.pageId + "contactNoId").val(agentSubstationDetailsData.phoneNo);
        $("#" + this.pageId + "addressId").val(agentSubstationDetailsData.address);
        $("#" + this.pageId + "deliveryChgId").val(agentSubstationDetailsData.crossingChg);
        $("#" + this.pageId + "deliveryChgunitId").val(agentSubstationDetailsData.unitCrossChg);
        $("#" + this.pageId + "ldgAndUnldgChgId").val(agentSubstationDetailsData.hamaliChg);
        $("#" + this.pageId + "ldgAndUnldgUnitId").val(agentSubstationDetailsData.unitHamaliChg);
        $("#" + this.pageId + "gdChargeId").val(agentSubstationDetailsData.subStationGDChg);
        $("#" + this.pageId + "gdUnitId").val(agentSubstationDetailsData.subStationGDChgUnit);
        $("#" + this.pageId + "emailId").val(agentSubstationDetailsData.emailAddress);
        $("#" + this.pageId + "cityId").val(agentSubstationDetailsData.city);
        $("#" + this.pageId + "stateNameAndCodeId").val(agentSubstationDetailsData.state);
        $("#" + this.pageId + "latitudeId").val(agentSubstationDetailsData.lat);
        $("#" + this.pageId + "longitudeId").val(agentSubstationDetailsData.lng);

        this.readonlyMainStation = true;
        this.readonlySubstation = true;
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');
    }

    clearFields() {
        this.readonlyMainStation = false;
        this.readonlySubstation = false;
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "substationId").val('');
        $("#" + this.pageId + "contactPersonId").val('');
        $("#" + this.pageId + "contactNoId").val('');
        $("#" + this.pageId + "addressId").val('');
        $("#" + this.pageId + "deliveryChgId").val('');
        $("#" + this.pageId + "deliveryChgunitId").val('');
        $("#" + this.pageId + "ldgAndUnldgChgId").val('');
        $("#" + this.pageId + "ldgAndUnldgUnitId").val('');
        $("#" + this.pageId + "gdChargeId").val('');
        $("#" + this.pageId + "gdUnitId").val('');
        $("#" + this.pageId + "emailId").val('');
        $("#" + this.pageId + "cityId").val('');
        $("#" + this.pageId + "stateNameAndCodeId").val('');
        $("#" + this.pageId + "latitudeId").val('');
        $("#" + this.pageId + "longitudeId").val('');
        // this.viewMainStation = false;
    }

    validateForAgentSubstionDetailsSave() {
        if ($("#" + this.pageId + "substationId").val() == null ||
            $("#" + this.pageId + "substationId").val() == undefined || $("#" + this.pageId + "substationId").val() == "" ||
            $("#" + this.pageId + "stateNameAndCodeId").val() == null ||
            $("#" + this.pageId + "stateNameAndCodeId").val() == undefined || $("#" + this.pageId + "stateNameAndCodeId").val() == "" ||
            $("#" + this.pageId + "deliveryChgId").val() == null ||
            $("#" + this.pageId + "deliveryChgId").val() == undefined || $("#" + this.pageId + "deliveryChgId").val() == "" ||
            $("#" + this.pageId + "deliveryChgunitId").val() == null || $("#" + this.pageId + "deliveryChgunitId").val() == undefined ||
            $("#" + this.pageId + "deliveryChgunitId").val() == "" || $("#" + this.pageId + "ldgAndUnldgChgId").val() == null ||
            $("#" + this.pageId + "ldgAndUnldgChgId").val() == undefined || $("#" + this.pageId + "ldgAndUnldgChgId").val() == "" ||
            $("#" + this.pageId + "ldgAndUnldgUnitId").val() == null || $("#" + this.pageId + "ldgAndUnldgUnitId").val() == undefined ||
            $("#" + this.pageId + "ldgAndUnldgUnitId").val() == "" || $("#" + this.pageId + "gdChargeId").val() == null ||
            $("#" + this.pageId + "gdChargeId").val() == undefined || $("#" + this.pageId + "gdChargeId").val() == "" ||
            $("#" + this.pageId + "gdUnitId").val() == null || $("#" + this.pageId + "gdUnitId").val() == undefined ||
            $("#" + this.pageId + "gdUnitId").val() == "") {
            swal(" Mandatory Field", "Please enter mandatory to proceed", "warning");
            return false;
        }
        else if (this.viewMainStation && ($("#" + this.pageId + "destination").val() == null || $("#" + this.pageId + "destination").val() == undefined ||
            $("#" + this.pageId + "destination").val() == "")) {
            swal(" Mandatory Field", "Please enter mandatory to proceed", "warning");
        }
        else {
            this.agentSubstionDetailsSave();
        }
    }

    setAgentSubstationDetails() {
        this.enteredDestination = $("#" + this.pageId + "destination").val();
        this.enteredSubstation = $("#" + this.pageId + "substationId").val();
        this.enteredContactPerson = $("#" + this.pageId + "contactPersonId").val();
        this.enteredContactNo = $("#" + this.pageId + "contactNoId").val();
        this.enteredAddress = $("#" + this.pageId + "addressId").val();
        this.enteredDeliveryChg = $("#" + this.pageId + "deliveryChgId").val();
        this.enteredDeliveryChgUnit = $("#" + this.pageId + "deliveryChgunitId").val();
        this.enteredldgAndUnldgChg = $("#" + this.pageId + "ldgAndUnldgChgId").val();
        this.enteredldgAndUnldgChgUnit = $("#" + this.pageId + "ldgAndUnldgUnitId").val();
        this.enteredgdCharge = $("#" + this.pageId + "gdChargeId").val();
        this.enteredgdUnit = $("#" + this.pageId + "gdUnitId").val();
        this.enteredEmailId = $("#" + this.pageId + "emailId").val();
        this.enteredCity = $("#" + this.pageId + "cityId").val();
        this.enteredState = $("#" + this.pageId + "stateNameAndCodeId").val();
        this.enteredLatitude = $("#" + this.pageId + "latitudeId").val();
        this.enteredLongitude = $("#" + this.pageId + "longitudeId").val();

        this.agentDetailsDtoForSave = new AgentDetailsDto();
        if (this.viewMainStation) {
            this.agentDetailsDtoForSave.mainStation = this.enteredDestination;
        } else {
            this.agentDetailsDtoForSave.mainStation = this.userDataDtoReturnSession.mainStation;
        }
        this.agentDetailsDtoForSave.subStation = this.enteredSubstation;
        this.agentDetailsDtoForSave.contactPerson = this.enteredContactPerson;
        this.agentDetailsDtoForSave.phoneNo = this.enteredContactNo;
        this.agentDetailsDtoForSave.address = this.enteredAddress;
        this.agentDetailsDtoForSave.crossingChg = this.enteredDeliveryChg;
        this.agentDetailsDtoForSave.unitCrossChg = this.enteredDeliveryChgUnit;
        this.agentDetailsDtoForSave.hamaliChg = this.enteredldgAndUnldgChg;
        this.agentDetailsDtoForSave.unitHamaliChg = this.enteredldgAndUnldgChgUnit;
        this.agentDetailsDtoForSave.subStationGDChg = this.enteredgdCharge;
        this.agentDetailsDtoForSave.subStationGDChgUnit = this.enteredgdUnit;
        this.agentDetailsDtoForSave.userName = this.userDataDtoReturnSession.userId;
        this.agentDetailsDtoForSave.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDetailsDtoForSave.emailAddress = this.enteredEmailId;
        this.agentDetailsDtoForSave.city = this.enteredCity;
        this.agentDetailsDtoForSave.state = this.enteredState;
        this.agentDetailsDtoForSave.lat = this.enteredLatitude;
        this.agentDetailsDtoForSave.lng = this.enteredLongitude;
    }

    agentSubstionDetailsSave() {
        swal({
            title: "Confirm Save",
            text: "Sure U want to Save Agent Substation Details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.setAgentSubstationDetails();
                console.log("agentDetailsDtoForSave");
                console.log(this.agentDetailsDtoForSave);
                this.masterReadService.saveAgentSubstionDetails(this.agentDetailsDtoForSave)
                    .subscribe(
                        (data) => {
                            this.agentDtoRet = data;
                            if (this.agentDtoRet.status == "Success") {
                                swal("Success", "Agent Substation Details saved/updated successfully!", "info");
                                this.clearFields();
                                setTimeout(() => {
                                    this.getAgentSubstationDetails();
                                }, 1000);
                            } else {
                                swal("Failed", "Failed to save/update the Agent Substation Details!", "error");
                            }
                        },
                        (error) => {
                            swal("Error", "Server problem occurred while saving/updating the Agent Substation Details!", "error");
                        },
                        () => console.log('done')
                    );
            }
        });
    }

    getStatesMethod() {
        this.partyMasterDto = new PartyMasterDto();
        this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.getStateDetailsService(this.partyMasterDto).subscribe(
            (response) => {
                // console.log(response);
                if (response.length == 0) {
                    this.partyMasterDtoOptions = [];
                    this.modelStateNameAndCodeTA = [];
                } else {
                    this.partyMasterDtoOptions = response;
                    this.modelStateNameAndCodeTA = [];
                    for (let i = 0; i < this.partyMasterDtoOptions.length; i++) {
                        this.modelStateNameAndCodeTA.push(this.partyMasterDtoOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting getStatesMethod", "warning");
            },
            () => console.log('done');
    }

}
