//Chg_sms_v1 : Include LR No on send message (Asrar Jr / 11/09/2024)
//Chg_sms_v2 : Undefined Error if not found (Asrar Jr / 11/09/2024)
//Chg_sms_v3 : Send Message for WhatsApp like same SMS (Asrar Jr / 03/10/2024)
//Chg_sms_v4 : Change WhatsApp_Kaleyra to WhatsApp_Verloop Mehtod as per discuss (Asrar Jr / 03/10/2024)

import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { MasterService } from 'src/app/dataService/master-service';
import { Observable } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { map } from 'rxjs/operators';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { MessageDto } from 'src/app/dto/Message-dto';
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-send-sms',
	templateUrl: './send-sms.component.html',
	styleUrls: ['./send-sms.component.css']
})
export class SendSmsComponent implements OnInit {

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = true;
	messageTextModel: any;
	mobileNoModel: any;
	agentDtoSrc: AgentDetailsDto = new AgentDetailsDto();
	// for Source
	sourceStationOptions: AgentDetailsDto[];
	lrDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
	public modelSource: any;
	sourceStationNameTA: Array<AgentDetailsDto> = [];
	focusSourceTA$ = new Subject<string>();
	searchSource = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusSourceTA$;
		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.sourceStationNameTA
				: this.sourceStationNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterSource = (x: { source: string }) => x.source;
	// for dest
	lrDtoToStation: LRDto = new LRDto();
	destinationOption: Array<LRDto> = [];
	public modelDropDownDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDropDownDestinationTA$ = new Subject<string>();
	searchDropDownInputFieldDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDropDownDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDropDownDestination = (x: { godownName: string }) => x.godownName;
	// for group name
	lrDtoForGropName: LRDto = new LRDto();
	lrDtoGrpNameOptions: LRDto[];
	grpNameTA: Array<LRDto> = [];
	focusGrpNameTA$ = new Subject<string>();
	searchGrpName = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusGrpNameTA$;
		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.grpNameTA
				: this.grpNameTA.filter(v => v.groupName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

		);
	}
	formatterGrpName = (x: { groupName: string }) => x.groupName;

	// for template
	messageDtoToGetSmsTemOther: MessageDto = new MessageDto();
	@ViewChild('instanceTemplate') instanceTemplate: NgbTypeahead;
	messageDtoToGetSmsTem: MessageDto = new MessageDto();
	messageDtoTemplateOptions: MessageDto[];
	templateTA: Array<MessageDto> = [];
	focusTemplateTA$ = new Subject<string>();
	searchTemplate = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusTemplateTA$;
		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.templateTA
				: this.templateTA.filter(v => v.template.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

		);
	}
	// for commodity
	@ViewChild('instancePartyCommodity') instancePartyCommodity: NgbTypeahead;
	modelCommodity: any;
	bangaloreConsigneeCommdityOptions: any;
	partyDtoForConsignee: PartyMasterDto = new PartyMasterDto();
	partyCommodityTA: Array<any> = [];
	focusPartyCommodityTA$ = new Subject<string>();
	partyCommoditySearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusPartyCommodityTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.partyCommodityTA
				: this.partyCommodityTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterTemplate = (x: { template: string }) => x.template;
	viewPlace = false;
	viewSrc = false;
	viewDest = false;
	viewInvoiceNo = false;
	viewGrpName = false;
	viewCommodity = false;
	viewMobile = false;
	viewSendBtn = false;
	viewSmsTypeAll = true;
	viewSmsTypeInvoice = false;
	enteredInvoiceNumber: any;
	selectedPlace: any;
	selectedSmsType: any;
	lrDtoForInvOnEnter: LRDto = new LRDto();
	selectedSource: any;
	selectedDestination: any;
	selectedCommodity: any;
	partyMasterDtoForGridCreation: PartyMasterDto = new PartyMasterDto();
	dtTriggerForGridCreation: Subject<any> = new Subject();
	dtOptionsForGridCreation: any;
	gridCreationDataList: any;
	tableTopHeading: any;
	modelGrpNameFotGet: any;
	modelTemplate: any;
	isChecked = false;
	showTextAreaTemplate = false;
	viewInputFieldOne = false;
	viewInputFieldTwo = false;
	viewInputFieldThree = false;
	viewInputFieldFour = false;
	viewInputFieldFive = false;
	viewInputFieldSix = false;
	messageTextAreaTempModel: any;
	enteredMessageTextAreaModel: any;
	enteredInputFieldOne: any;
	enteredInputFieldTwo: any;
	enteredInputFieldThree: any;
	enteredInputFieldFour: any;
	enteredInputFieldFive: any;
	enteredInputFieldSix: any;
	viewSmsPreview = false;
	viewPreviewBtn = false;
	messageDtoToSetSendSms: MessageDto = new MessageDto();
	messageDtoToSendSms: MessageDto[];
	messageDtoCheckedMobileNo: Array<MessageDto> = [];
	messageDtoForSelectedParties: MessageDto = new MessageDto();
	enteredMobileNo: any;
	pageId = "ssmsc";
	messageDtoSendRet = null;
	notificationType = null;
	viewLrNoCheckbox = false;
	checkBoxForLrNo: boolean = false;
	lrDtoForLrInvOnEnter: LRDto = new LRDto();
	includeLrNoDataList: any;
	dtTriggerForIncludeLrNo: Subject<any> = new Subject();
	dtOptionsForIncludeLrNo: any;
	viewIncludeLrNoTable: boolean = false;
	messageDtoForSelectedPartiesIncludeLrNo: MessageDto = new MessageDto();
	messageDtoIncludeLrNo: Array<MessageDto> = [];
	selectedMobileNo: any;
	isCheckedIncludeLrNo = false;
	messageDtoToSendSmsIncludeLrNo: MessageDto[];
	messageType: any;

	constructor(private masterReadService: MasterReadService,
		private router: Router,
		public changeDetectorRef: ChangeDetectorRef,
		private masterService: MasterService) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.getToStationDetailsList();
			// this.getMainCommodityMasterDetailsList("sendSMS");
			this.getBangaloreConsigneeCommodityConsignee();
			this.gettingSrcList();
			this.getGroupList();
			this.getSmsTemplate();
		}

	}
	ngOnInit(): void {
		this.dtOptionsForGridCreation = {
			dom: 'Bfrtip',
			buttons: [
				//    {
				//     extend: 'excel',
				//     text: '<i class="fas fa-file-excel"> Excel</i>',
				//     titleAttr: 'Excel',
				//     exportOptions: {
				//     columns: ':visible'
				//     }
				//      }
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 280,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				$(api.column(0).footer()).html('Total : ' + data.length);
			}
		},
			this.dtOptionsForIncludeLrNo = {
				dom: 'Bfrtip',
				buttons: [

				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				responsive: true,
				"scrollX": true,
				"scrollY": 280,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					$(api.column(0).footer()).html('Total : ' + data.length);
				}
			}
	}

	ngOnDestroy(): void {
		this.dtTriggerForGridCreation.unsubscribe();
		this.dtTriggerForIncludeLrNo.unsubscribe();
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	ngAfterViewInit(): void {
		this.dtTriggerForGridCreation.next();
		this.dtTriggerForIncludeLrNo.next();
	}

	sendSmsTypeMode(sendSmsType: string) {
		if (sendSmsType === 'sourceWise') {
			this.viewPlace = false;
			this.viewSrc = true;
			this.viewDest = false;
			this.viewCommodity = false;
			this.viewInvoiceNo = false;
			this.viewGrpName = false;
			this.viewMobile = true;
			this.viewSendBtn = false;
			this.viewSmsTypeAll = true;
			this.viewSmsTypeInvoice = false;
			this.clearFields();
			$("#" + this.pageId + "smsType").val('general');
			this.viewLrNoCheckbox = false;

		} else if (sendSmsType === 'destinationWise') {
			this.viewPlace = false;
			this.viewSrc = false;
			this.viewDest = true;
			this.viewCommodity = false;
			this.viewInvoiceNo = false;
			this.viewGrpName = false;
			this.viewMobile = true;
			this.viewSendBtn = false;
			this.viewSmsTypeAll = true;
			this.viewSmsTypeInvoice = false;
			this.clearFields();
			$("#" + this.pageId + "smsType").val('general');
			this.viewLrNoCheckbox = false;

		} else if (sendSmsType === 'commodityWise') {
			this.viewPlace = true;
			this.viewSrc = false;
			this.viewDest = false;
			this.viewCommodity = true;
			this.viewInvoiceNo = false;
			this.viewGrpName = false;
			this.viewMobile = true;
			this.viewSendBtn = false;
			this.viewSmsTypeAll = true;
			this.viewSmsTypeInvoice = false;
			this.clearFields();
			$("#" + this.pageId + "smsType").val('general');
			this.viewLrNoCheckbox = false;

		} else if (sendSmsType === 'invoiceWise') {
			this.viewPlace = true;
			this.viewSrc = false;
			this.viewDest = false;
			this.viewCommodity = false;
			this.viewInvoiceNo = true;
			this.viewGrpName = false;
			this.viewMobile = true;
			this.viewSendBtn = false;
			this.viewSmsTypeAll = true;
			this.viewSmsTypeInvoice = true;
			this.clearFields();
			$("#" + this.pageId + "smsType").val('general');

			//Chg_sms_v1
			this.viewLrNoCheckbox = true;

		} else if (sendSmsType === 'srdGroup') {
			this.viewPlace = false;
			this.viewSrc = false;
			this.viewDest = false;
			this.viewCommodity = false;
			this.viewInvoiceNo = false;
			this.viewGrpName = true;
			this.viewMobile = true;
			this.viewSendBtn = true;
			this.viewSmsTypeAll = true;
			this.viewSmsTypeInvoice = false;
			this.clearFields();
			$("#" + this.pageId + "smsType").val('general');
			this.viewLrNoCheckbox = false;

		} else if (sendSmsType === 'other') {
			this.viewPlace = false;
			this.viewSrc = false;
			this.viewDest = false;
			this.viewCommodity = false;
			this.viewInvoiceNo = false;
			this.viewGrpName = false;
			this.viewMobile = true;
			this.viewSendBtn = true;
			this.viewSmsTypeAll = true;
			this.viewSmsTypeInvoice = false;
			this.clearFields();
			$("#" + this.pageId + "smsType").val('other');
			this.viewLrNoCheckbox = false;

		} else {
			this.viewPlace = false;
			this.viewSrc = false;
			this.viewDest = false;
			this.viewCommodity = false;
			this.viewInvoiceNo = false;
			this.viewGrpName = false;
			this.viewMobile = false;
			this.viewSendBtn = false;
			this.viewSmsTypeAll = false;
			this.viewSmsTypeInvoice = false;
			this.clearFields();
			$("#" + this.pageId + "smsType").val('Select SMS Type');
			this.viewLrNoCheckbox = false;
		}
	}
	smsTypeMode(smsType: string) {
		if (smsType === 'general') {
			$("#" + this.pageId + "message").val('');
			this.messageTextModel = null;
		} else if (smsType != 'general' && smsType != 'Select SMS Type') {
			this.getNonGeneralMsg(smsType);
		}
		// else {

		// }
	}
	getNonGeneralMsg(smsType) {
		this.messageTextModel = null;
		if (smsType === "accident") {
			this.messageTextModel = "The truck carrying your consignment has met with an accident.Therefore the delivery of your consignment will be delayed.We apologize for the inconvenience caused.";
		} else if (smsType === "salesTax") {
			this.messageTextModel = "Your consignment has been detained by the sales tax authorities.Therefore the delivery will get delayed.We assure you that we are trying our best to get it delivered to you as soon as possible.";
		} if (smsType === "BreakDown") {
			this.messageTextModel = "The truck carrying your consignment has breakdown.Therefore the delivery of your consignment will be delayed.We apologize for the inconvenience caused.";
		} if (smsType === "roadBlock") {
			this.messageTextModel = "The truck carrying your consignments will arrive late as it is stuck in a road block due to an unavoidable circumstance beyond our control.We are sorry for the inconvenience caused.";
		}

	}
	placeTypeMode(placeType: string) {
		if (placeType === 'source' && this.viewInvoiceNo == false) {
			this.viewSrc = true;
			this.viewDest = false;
		} else if (placeType === 'destination' && this.viewInvoiceNo == false) {
			this.viewSrc = false;
			this.viewDest = true;
		} else {
			this.viewSrc = false;
			this.viewDest = false;
		}
	}
	// source
	detailsForSrc() {
		this.agentDtoSrc = new AgentDetailsDto();
		this.agentDtoSrc.mode = "MainAndGrpRateMaster";
		this.agentDtoSrc.status = "Working";
		this.agentDtoSrc.companyId = this.userDataDtoReturnSession.companyId;
	}
	gettingSrcList = () => {
		this.detailsForSrc();
		this.showSpinnerForAction = true;
		this.masterService.getAgentNamesGrouping(this.agentDtoSrc).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Source records found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {
					this.sourceStationNameTA = [];
					this.sourceStationOptions = response;
					// console.log("this.sourceStationOptions >> Getting Response");
					// console.log(this.sourceStationOptions);
					for (let i = 0; i < this.sourceStationOptions.length; i++) {
						this.sourceStationNameTA.push(this.sourceStationOptions[i]);

					}
				}

				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Source List", "info");
			}, () => console.log('done');
	};

	sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
		this.modelSource = null;
		this.modelSource = e.item;
		$("#" + this.pageId + "sourceId").val(this.modelSource.source);
		this.selectedSource = null;
		this.selectedSmsType = null;
		this.selectedSource = $("#" + this.pageId + "sourceId").val();
		this.selectedSmsType = $("#" + this.pageId + "smsType").val();
		// console.log("this.selectedSource");
		// console.log(this.selectedSource);
		if ((this.selectedSource != null || this.selectedSource != undefined || this.selectedSource != ""
			|| this.selectedSmsType != null || this.selectedSmsType != undefined || this.selectedSmsType != "" || this.selectedSmsType != "Select SMS Type")
			&& (this.viewCommodity != true)) {
			$("#" + this.pageId + "mobileNo").val('');
			this.mobileNoModel = null;
			this.messageDtoCheckedMobileNo = [];
			this.partyMasterDtoForGridCreation = new PartyMasterDto();
			this.partyMasterDtoForGridCreation.city = this.selectedSource;
			this.partyMasterDtoForGridCreation.mode = "sendSMSSourceParty";
			this.partyMasterDtoForGridCreation.companyId == this.userDataDtoReturnSession.companyId;
			// console.log("this.partyMasterDtoForGridCreation in >> sourceListener");
			// console.log(this.partyMasterDtoForGridCreation);
			this.gridCreation(this.partyMasterDtoForGridCreation);
			$("#" + this.pageId + "sourceId").focus();
		} else if (this.viewCommodity != true) {
			swal({
				title: "Warning",
				text: "Fields cannot be Empty!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		}
	}
	//For getting the main Commodity of Consignee 

	getBangaloreConsigneeCommodityConsigneeInfo() {
		this.partyDtoForConsignee = new PartyMasterDto();
		//this.partyDtoForConsignee.mode = "bangaloreSpecificConsigneeCommdity";
		this.partyDtoForConsignee.mode = "bangaloreAllCommodityLREntry";
		this.partyDtoForConsignee.state = "Karnataka";
		// we have hardcoded the destination later it need to do
		this.partyDtoForConsignee.companyId = this.userDataDtoReturnSession.companyId;

	}


	getBangaloreConsigneeCommodityConsignee = () => {
		this.getBangaloreConsigneeCommodityConsigneeInfo();
		this.masterReadService.getCityMainCommodityDetails(this.partyDtoForConsignee).subscribe(
			(response) => {
				this.bangaloreConsigneeCommdityOptions = '';
				this.partyCommodityTA = [];
				if (response.length > 0) {
					this.bangaloreConsigneeCommdityOptions = response;
					//this.consigneeComOptions.multipleCommodity = "Add New";
					//this.bangaloreConsigneeCommdityOptions.push(this.consigneeComOptions);

					for (let i = 0; i < this.bangaloreConsigneeCommdityOptions.length; i++) {
						this.partyCommodityTA.push(this.bangaloreConsigneeCommdityOptions[i].multipleCommodity);
					}

				}

				this.changeDetectorRef.detectChanges();
			}),
			(error) => console.log(error.json()),
			() => console.log('done');
	};

	clickListnerForCommodity(e: NgbTypeaheadSelectItemEvent, fubi: any) {
		this.modelCommodity = null;
		this.modelCommodity = e.item;
		$("#" + this.pageId + "partyCommodity").val(this.modelCommodity);
		this.selectedCommodity = null;
		this.selectedSmsType = null;
		this.selectedPlace = null;
		this.selectedDestination = null;
		this.selectedSource = null;
		this.selectedCommodity = $("#" + this.pageId + "partyCommodity").val();
		this.selectedSmsType = $("#" + this.pageId + "smsType").val();
		this.selectedPlace = $("#" + this.pageId + "placeType").val();
		this.selectedDestination = $("#" + this.pageId + "destinationId").val();
		this.selectedSource = $("#" + this.pageId + "sourceId").val();
		// console.log("this.selectedCommodity");
		// console.log(this.selectedCommodity);

		if (this.selectedCommodity != null || this.selectedCommodity != undefined || this.selectedCommodity != ""
			|| this.selectedSmsType != null || this.selectedSmsType != undefined || this.selectedSmsType != "" || this.selectedSmsType != "Select SMS Type"
			|| this.selectedPlace != null || this.selectedPlace != undefined || this.selectedPlace != "" || this.selectedPlace != "Select Branch") {

			$("#" + this.pageId + "mobileNo").val('');
			this.mobileNoModel = null;
			this.messageDtoCheckedMobileNo = [];
			if (this.selectedPlace == "source") {
				this.partyMasterDtoForGridCreation = new PartyMasterDto();
				this.partyMasterDtoForGridCreation.city = this.selectedSource;
				this.partyMasterDtoForGridCreation.mode = "sendSMSCommoditySourceParty";
				this.partyMasterDtoForGridCreation.multipleCommodity = this.selectedCommodity;
				$("#" + this.pageId + "sourceId").focus();
				this.partyMasterDtoForGridCreation.companyId == this.userDataDtoReturnSession.companyId;
				// console.log("this.partyMasterDtoForGridCreation in >> clickListnerForCommodity >> source");
				// console.log(this.partyMasterDtoForGridCreation);
				this.gridCreation(this.partyMasterDtoForGridCreation);
			} else if (this.selectedPlace == "destination") {
				this.partyMasterDtoForGridCreation = new PartyMasterDto();
				this.partyMasterDtoForGridCreation.city = this.selectedDestination;
				this.partyMasterDtoForGridCreation.mode = "sendSMSCommodityDestinationParty";
				this.partyMasterDtoForGridCreation.multipleCommodity = this.selectedCommodity;
				$("#" + this.pageId + "destinationId").focus();
				this.partyMasterDtoForGridCreation.companyId == this.userDataDtoReturnSession.companyId;
				// console.log("this.partyMasterDtoForGridCreation in >> clickListnerForCommodity >> Dest");
				// console.log(this.partyMasterDtoForGridCreation);
				this.gridCreation(this.partyMasterDtoForGridCreation);
			}
		} else {
			swal({
				title: "Warning",
				text: "Fields cannot be Empty!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		}

	}


	getToStationDetails() {
		this.lrDtoToStation = new LRDto();
		this.lrDtoToStation.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction = true;
	}
	getToStationDetailsList = () => {
		this.getToStationDetails();
		this.masterReadService.getDestinationListDetails(this.lrDtoToStation).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Destination records found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {
					if (response.length == 0) {
						this.destinationOption = [];
						this.destinationTA = [];
					} else {
						this.destinationOption = [];
						this.destinationTA = [];
						this.destinationOption = response;

						for (let i = 0; i < this.destinationOption.length; i++) {
							this.destinationTA.push(this.destinationOption[i]);
						}
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Destination Details", "info");
			}, () => console.log('done');
	};

	clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
		this.modelDropDownDestination = null;
		this.modelDropDownDestination = e.item;
		$("#" + this.pageId + "destinationId").val(this.modelDropDownDestination.godownName);
		this.selectedDestination = null;
		this.selectedSmsType = null;
		this.selectedDestination = $("#" + this.pageId + "destinationId").val();
		this.selectedSmsType = $("#" + this.pageId + "smsType").val();
		// console.log("this.selectedDestination");
		// console.log(this.selectedDestination);

		// this.getMainCommodityMasterDetailsList("sendSMSDestCommd");
		this.getBangaloreConsigneeCommodityConsignee();
		if ((this.selectedDestination != null || this.selectedDestination != undefined || this.selectedDestination != ""
			|| this.selectedSmsType != null || this.selectedSmsType != undefined || this.selectedSmsType != "" || this.selectedSmsType != "Select SMS Type")
			&& (this.viewCommodity != true)) {

			$("#" + this.pageId + "mobileNo").val('');
			this.mobileNoModel = null;
			this.messageDtoCheckedMobileNo = [];
			this.partyMasterDtoForGridCreation = new PartyMasterDto();
			this.partyMasterDtoForGridCreation.city = this.selectedDestination;
			this.partyMasterDtoForGridCreation.mode = "sendSMSDestinationParty";
			this.partyMasterDtoForGridCreation.companyId == this.userDataDtoReturnSession.companyId;
			// console.log("this.partyMasterDtoForGridCreation in >> clickListnerForDestination");
			// console.log(this.partyMasterDtoForGridCreation);
			this.gridCreation(this.partyMasterDtoForGridCreation);
			$("#" + this.pageId + "destinationId").focus();
		} else if (this.viewCommodity != true) {
			swal({
				title: "Warning",
				text: "Fields cannot be Empty!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		}
	}

	clearFields() {
		$("#" + this.pageId + "mobileNo").val('');
		this.mobileNoModel = null;
		$("#" + this.pageId + "message").val('');
		this.messageTextModel = null;
		this.tableTopHeading = "";
		$("#" + this.pageId + "gridCreationTableId").DataTable().destroy();
		this.gridCreationDataList = [];
		this.dtTriggerForGridCreation.next();
		this.showTextAreaTemplate = false;
		// listGridResult.clear();
		// listInvGrid.clear();
		// txtInvNumber.clear();
		// store.removeAll();
		// vpGrid.clear();
		this.viewInputFieldOne = false;
		this.viewInputFieldTwo = false;
		this.viewInputFieldThree = false;
		this.viewInputFieldFour = false;
		this.viewInputFieldFive = false;
		this.viewInputFieldSix = false;
		this.enteredMessageTextAreaModel = "";
		this.enteredInputFieldOne = "";
		this.enteredInputFieldTwo = "";
		this.enteredInputFieldThree = "";
		this.enteredInputFieldFour = "";
		this.enteredInputFieldFive = "";
		this.enteredInputFieldSix = "";
		this.viewSmsPreview = false;
		this.viewPreviewBtn = false;
		this.messageDtoCheckedMobileNo = [];
		$("#" + this.pageId + "template").val('');
		this.modelTemplate = null;
		// $("#" + this.pageId + "sendSmsType").val('Select Send Type');
		$("#" + this.pageId + "smsType").val('Select SMS Type');

		$("#" + this.pageId + "placeType").val('Select Branch');
		$("#" + this.pageId + "sourceId").val('');
		$("#" + this.pageId + "destinationId").val('');
		$("#" + this.pageId + "partyCommodity").val('');
		$("#" + this.pageId + "inovicenNo").val('');
		$("#" + this.pageId + "grpName").val('');
		//Chg_sms_v1
		$("#" + this.pageId + "includeLrNoTableId").DataTable().destroy();
		this.includeLrNoDataList = [];
		this.dtTriggerForIncludeLrNo.next();
	}

	gridCreation(partyMasterDtoForGridCreation) {
		let mode = partyMasterDtoForGridCreation.mode;
		let place = partyMasterDtoForGridCreation.city;
		this.showSpinnerForAction = true;
		// console.log("partyMasterDtoForGridCreation >> gridCreation");
		// console.log(partyMasterDtoForGridCreation);
		this.masterReadService.getSmsDetails(partyMasterDtoForGridCreation).subscribe(
			(response) => {
				$("#" + this.pageId + "gridCreationTableId").DataTable().destroy();
				this.gridCreationDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No records found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {
					this.gridCreationDataList = [];
					this.gridCreationDataList = response;
					// console.log("this.gridCreationDataList");
					// console.log(this.gridCreationDataList);
					this.tableTopHeading = "";
					this.tableTopHeading = place + " - Parties List"


				}
				this.showSpinnerForAction = false;
				this.dtTriggerForGridCreation.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Party Details", "info");
			}, () => console.log('done');
	}
	getInvDetails(e) {
		if (e.keyCode == 13) {
			//Chg_sms_v1
			this.checkBoxForLrNo = false;
			this.checkBoxForLrNo = $("#" + this.pageId + "checkboxLrNo").is(":checked");
			if (this.checkBoxForLrNo) {
				console.log(this.checkBoxForLrNo);
				this.checkInvDetailsOnIncludeLrEnter();
				console.log("if");
			} else {
				this.checkInvDetailsOnEnter();
				console.log("else");
			}
		}
	}

	checkInvDetailsOnEnter() {
		this.enteredInvoiceNumber = null;
		this.selectedPlace = null;
		this.selectedSmsType = null;
		this.enteredInvoiceNumber = $("#" + this.pageId + "inovicenNo").val();
		this.selectedPlace = $("#" + this.pageId + "placeType").val();
		this.selectedSmsType = $("#" + this.pageId + "smsType").val();
		if (this.enteredInvoiceNumber == null || this.enteredInvoiceNumber == undefined || this.enteredInvoiceNumber == ""
			|| this.selectedPlace == null || this.selectedPlace == undefined || this.selectedPlace == "" || this.selectedPlace == "Select Branch"
			|| this.selectedSmsType == null || this.selectedSmsType == undefined || this.selectedSmsType == "" || this.selectedSmsType == "Select SMS Type") {
			swal({
				title: "Warning",
				text: "Fields cannot be Empty!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			$("#" + this.pageId + "mobileNo").val('');
			this.mobileNoModel = null;
			this.messageDtoCheckedMobileNo = [];
			// $("#" + this.pageId + "message").val('');
			// this.messageTextModel = null;
			// 			listInvGrid.clear();
			// 			store.removeAll();
			this.getInvDetailsOnEnter();
		}
	}
	getInvDetailsOnEnter() {
		this.enteredInvoiceNumber = null;
		this.selectedPlace = null;
		this.selectedSmsType = null;
		this.enteredInvoiceNumber = $("#" + this.pageId + "inovicenNo").val();
		this.selectedPlace = $("#" + this.pageId + "placeType").val();
		this.selectedSmsType = $("#" + this.pageId + "smsType").val();
		this.lrDtoForInvOnEnter = new LRDto();
		this.lrDtoForInvOnEnter.invoiceNumber = this.enteredInvoiceNumber;

		if (this.selectedPlace == "source") {
			this.lrDtoForInvOnEnter.status = "src";
		} else {
			this.lrDtoForInvOnEnter.status = "dest";
		}
		this.lrDtoForInvOnEnter.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction = true;
		console.log(this.lrDtoForInvOnEnter);

		// console.log("this.lrDtoForInvOnEnter >> gridCreation");
		// console.log(this.lrDtoForInvOnEnter);
		this.masterReadService.getSmsInvoiceDetails(this.lrDtoForInvOnEnter).subscribe(
			(response) => {
				$("#" + this.pageId + "gridCreationTableId").DataTable().destroy();
				this.gridCreationDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						//Chg_sms_v2
						// text: "No records found for this inovice : " + response[0].invoiceNumber,
						text: "No records found for this inovice : " + this.enteredInvoiceNumber,
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {
					this.gridCreationDataList = [];
					this.gridCreationDataList = response;
					// console.log("this.gridCreationDataList");
					// console.log(this.gridCreationDataList);
					this.tableTopHeading = "";
					this.tableTopHeading = response[0].invoiceNumber + " - Party List";
				}
				this.showSpinnerForAction = false;
				this.dtTriggerForGridCreation.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting invoice Wise Details", "info");
			}, () => console.log('done');
	}
	checkuncheckall() {
		if (this.gridCreationDataList.length == 0) {
			swal("Not Allowed", "No data to Select!", "warning");
			return false;
		} else {
			if (this.isChecked == true) {
				this.isChecked = false;
				this.messageDtoCheckedMobileNo = [];
				$("#" + this.pageId + "mobileNo").val('');
				this.mobileNoModel = null;
			} else {
				this.isChecked = true;
				this.messageDtoCheckedMobileNo = [];
				// console.log("this.gridCreationDataList");
				// console.log(this.gridCreationDataList);
				let mobileNo = "";
				let mobileNoObj = "";
				for (let i = 0; i < this.gridCreationDataList.length; i++) {
					mobileNo = this.gridCreationDataList[i].mobileNum;
					if (mobileNoObj == "") {
						mobileNoObj = mobileNo;
					} else {
						mobileNoObj = mobileNoObj + ","
							+ mobileNo;
					}
				}
				if (mobileNoObj != null) {
					console.log('mobileNoObj');
					mobileNoObj = mobileNoObj.replace("NA", "");
					mobileNoObj = mobileNoObj.replace(/#/g, ",");
					mobileNoObj = mobileNoObj.replace(/-/g, ",");
					mobileNoObj = mobileNoObj.replace(/,,/g, ",");
					mobileNoObj = mobileNoObj.replace(/\*/g, ",");
					mobileNoObj = mobileNoObj.replace(/\//g, ",");
					mobileNoObj = this.removeSpacesFromPrefixSuffixMiddle(mobileNoObj);
				}
				console.log("mobileNoObj-1");
				// console.log(mobileNoObj);
				$("#" + this.pageId + "mobileNo").val(mobileNoObj);

				// this.messageDtoCheckedMobileNo.push(this.gridCreationDataList);
			}
		}
		this.changeDetectorRef.detectChanges();
	}

	getGroupList() {
		this.lrDtoForGropName = new LRDto();
		this.lrDtoForGropName.mainstation = this.userDataDtoReturnSession.mainStation;
		this.masterReadService.getSmsGroupHead(this.lrDtoForGropName).subscribe(
			(response) => {
				if (response) {
					if (response.length > 0) {
						this.lrDtoGrpNameOptions = response;
						this.grpNameTA = [];
						for (let i = 0; i < this.lrDtoGrpNameOptions.length; i++) {
							this.grpNameTA.push(this.lrDtoGrpNameOptions[i]);
						}
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Group Name Details", "info");
			},
			() => console.log('done');
	}

	clickListnerForGrpName(e: NgbTypeaheadSelectItemEvent) {
		this.modelGrpNameFotGet = null;
		this.modelGrpNameFotGet = e.item.groupName;
		// console.log("this.modelGrpNameFotGet");
		// console.log(this.modelGrpNameFotGet);
		this.getGrpMemberDetails();

	}

	getGrpMemberDetails = () => {
		this.lrDtoForGropName = new LRDto();
		this.lrDtoForGropName.groupName = this.modelGrpNameFotGet;
		this.showSpinnerForAction = true;
		this.masterReadService.getSmsGroupDetails(this.lrDtoForGropName).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "No record",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					//  = ;
					let mobileNo = "";
					let mobileNoObj = "";
					for (let i = 0; i < response.length; i++) {
						mobileNo = response[i].mobileNum;
						if (mobileNoObj == "") {
							mobileNoObj = mobileNo;
						} else {
							mobileNoObj = mobileNoObj + ","
								+ mobileNo;
						}
					}
					if (mobileNoObj != null) {
						mobileNoObj.replace("NA", "");
						mobileNoObj.replace("#", ",");
						mobileNoObj.replace(",,", ",");
					}
					// console.log("mobileNoObj");
					// console.log(mobileNoObj);
					$("#" + this.pageId + "mobileNo").val(mobileNoObj);
				}

				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while getting the details", "info");
			}, () => console.log('done');
	}

	getSmsTemplate = () => {
		this.messageDtoToGetSmsTem = new MessageDto();
		this.messageDtoToGetSmsTem.isActive = true;
		this.showSpinnerForAction = true;
		this.masterReadService.getSmsTemplateDetails(this.messageDtoToGetSmsTem).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "No record",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.messageDtoTemplateOptions = response;
					this.templateTA = [];

					// this.messageDtoToGetSmsTemOther = new MessageDto();
					// this.messageDtoToGetSmsTemOther.template = "Other";
					// this.templateTA.push(this.messageDtoToGetSmsTemOther);

					for (let i = 0; i < this.messageDtoTemplateOptions.length; i++) {
						this.templateTA.push(this.messageDtoTemplateOptions[i]);

					}
				}

				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while getting the details", "info");
			}, () => console.log('done');
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}
	clickListnerForTemplate(e: NgbTypeaheadSelectItemEvent) {
		// console.log("e.item");
		// console.log(e.item);
		this.modelTemplate = null;
		this.modelTemplate = e.item;
		// console.log("this.modelTemplate >> clickListnerForTemplate");
		// console.log(this.modelTemplate);
		// if (this.notificationType == 'Whatsapp' && this.modelTemplate == 'Other') {
		// 	this.hideFieldsForWhatsapp();
		// } else {
		//$("#" + this.pageId + "messageTextArea").prop('readonly', true);
		$("#" + this.pageId + "messageTextArea").prop('disabled', true);
		this.setInputFieldsAsPerTemp();
		this.viewPreviewBtn = true;
		this.enteredMessageTextAreaModel = "";
		this.enteredInputFieldOne = "";
		this.enteredInputFieldTwo = "";
		this.enteredInputFieldThree = "";
		this.enteredInputFieldFour = "";
		this.enteredInputFieldFive = "";
		this.enteredInputFieldSix = "";
		$("#" + this.pageId + "messageTextAreaTemp").val('');
		$("#" + this.pageId + "inputFieldOne").val('');
		$("#" + this.pageId + "inputFieldTwo").val('');
		$("#" + this.pageId + "inputFieldThree").val('');
		$("#" + this.pageId + "inputFieldFour").val('');
		$("#" + this.pageId + "inputFieldFive").val('');
		$("#" + this.pageId + "inputFieldSix").val('');
		$("#" + this.pageId + "messageTextArea").val('');
		this.viewSmsPreview = false;
		// }
	}


	setInputFieldsAsPerTemp() {
		if (this.modelTemplate.template != null || this.modelTemplate.template != "") {
			this.showTextAreaTemplate = true;
			this.messageTextAreaTempModel = "";
			$("#" + this.pageId + "messageTextAreaTemp").val('');
			this.messageTextAreaTempModel = this.modelTemplate.template;
			$("#" + this.pageId + "messageTextAreaTemp").val(this.modelTemplate.template);

			if (this.modelTemplate.variable == 1) {
				this.viewInputFieldOne = true;
				this.viewInputFieldTwo = false;
				this.viewInputFieldThree = false;
				this.viewInputFieldFour = false;
				this.viewInputFieldFive = false;
				this.viewInputFieldSix = false;
			} else if (this.modelTemplate.variable == 2) {
				this.viewInputFieldOne = true;
				this.viewInputFieldTwo = true;
				this.viewInputFieldThree = false;
				this.viewInputFieldFour = false;
				this.viewInputFieldFive = false;
				this.viewInputFieldSix = false;
			} else if (this.modelTemplate.variable == 3) {
				this.viewInputFieldOne = true;
				this.viewInputFieldTwo = true;
				this.viewInputFieldThree = true;
				this.viewInputFieldFour = false;
				this.viewInputFieldFive = false;
				this.viewInputFieldSix = false;
			} else if (this.modelTemplate.variable == 4) {
				this.viewInputFieldOne = true;
				this.viewInputFieldTwo = true;
				this.viewInputFieldThree = true;
				this.viewInputFieldFour = true;
				this.viewInputFieldFive = false;
				this.viewInputFieldSix = false;
			} else if (this.modelTemplate.variable == 5) {
				this.viewInputFieldOne = true;
				this.viewInputFieldTwo = true;
				this.viewInputFieldThree = true;
				this.viewInputFieldFour = true;
				this.viewInputFieldFive = true;
				this.viewInputFieldSix = false;
			} else if (this.modelTemplate.variable == 6) {
				this.viewInputFieldOne = true;
				this.viewInputFieldTwo = true;
				this.viewInputFieldThree = true;
				this.viewInputFieldFour = true;
				this.viewInputFieldFive = true;
				this.viewInputFieldSix = true;
			} else {
				this.viewInputFieldOne = false;
				this.viewInputFieldTwo = false;
				this.viewInputFieldThree = false;
				this.viewInputFieldFour = false;
				this.viewInputFieldFive = false;
				this.viewInputFieldSix = false;
			}

		}

	}

	viewSmsTemplPreview() {
		this.enteredMessageTextAreaModel = "";
		this.enteredInputFieldOne = "";
		this.enteredInputFieldTwo = "";
		this.enteredInputFieldThree = "";
		this.enteredInputFieldFour = "";
		this.enteredInputFieldFive = "";
		this.enteredInputFieldSix = "";
		this.enteredMessageTextAreaModel = $("#" + this.pageId + "messageTextAreaTemp").val();
		this.enteredInputFieldOne = $("#" + this.pageId + "inputFieldOne").val();
		this.enteredInputFieldTwo = $("#" + this.pageId + "inputFieldTwo").val();
		this.enteredInputFieldThree = $("#" + this.pageId + "inputFieldThree").val();
		this.enteredInputFieldFour = $("#" + this.pageId + "inputFieldFour").val();
		this.enteredInputFieldFive = $("#" + this.pageId + "inputFieldFive").val();
		this.enteredInputFieldSix = $("#" + this.pageId + "inputFieldSix").val();

		if ((this.enteredMessageTextAreaModel != null || this.enteredMessageTextAreaModel != "") && (this.showTextAreaTemplate == true)) {
			for (var j = 0; j < this.modelTemplate.variable; ++j) {
				if (j == 0) {
					if ((this.viewInputFieldOne == true) && (this.enteredInputFieldOne == null || this.enteredInputFieldOne == "" || this.enteredInputFieldOne == undefined)) {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", "");
					} else {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", this.enteredInputFieldOne);
					}
				}
				if (j == 1) {
					if ((this.viewInputFieldTwo == true) && (this.enteredInputFieldTwo == null || this.enteredInputFieldTwo == "" || this.enteredInputFieldTwo == undefined)) {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", "");
					} else {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", " " + this.enteredInputFieldTwo);
					}
				}
				if (j == 2) {
					if ((this.viewInputFieldThree == true) && (this.enteredInputFieldThree == null || this.enteredInputFieldThree == "" || this.enteredInputFieldThree == undefined)) {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", "");
					} else {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", " " + this.enteredInputFieldThree);
					}
				}
				if (j == 3) {
					if ((this.viewInputFieldFour == true) && (this.enteredInputFieldFour == null || this.enteredInputFieldFour == "" || this.enteredInputFieldFour == undefined)) {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", "");
					} else {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", " " + this.enteredInputFieldFour);
					}
				}
				if (j == 4) {
					if ((this.viewInputFieldFive == true) && (this.enteredInputFieldFive == null || this.enteredInputFieldFive == "" || this.enteredInputFieldFive == undefined)) {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", "");
					} else {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", " " + this.enteredInputFieldFive);
					}
				}
				if (j == 5) {
					if ((this.viewInputFieldSix == true) && (this.enteredInputFieldSix == null || this.enteredInputFieldSix == "" || this.enteredInputFieldSix == undefined)) {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", "");
					} else {
						this.enteredMessageTextAreaModel = this.enteredMessageTextAreaModel.replace("{#var#}", " " + this.enteredInputFieldSix);
					}

				}
			}
			this.viewSmsPreview = true;
			// console.log("this.enteredMessageTextAreaModel");
			// console.log(this.enteredMessageTextAreaModel);
		}

	}
	rowCheckBoxChecked(e, gridCreationData) {
		// console.log("gridCreationData >> checked");
		// console.log(gridCreationData);
		if (e.currentTarget.checked) {
			this.messageDtoForSelectedParties = new MessageDto();
			this.messageDtoForSelectedParties.mobileNum = gridCreationData.mobileNum;

			this.messageDtoCheckedMobileNo.push(this.messageDtoForSelectedParties);
		} else {
			let index = this.messageDtoCheckedMobileNo.findIndex(x => x.mobileNum === gridCreationData.mobileNum);
			this.messageDtoCheckedMobileNo.splice(index, 1);

		}
		// console.log("this.messageDtoCheckedMobileNo");
		// console.log(this.messageDtoCheckedMobileNo);
		let mobileNo = "";
		let mobileNoObj = "";
		for (let i = 0; i < this.messageDtoCheckedMobileNo.length; i++) {
			mobileNo = this.messageDtoCheckedMobileNo[i].mobileNum;
			if (mobileNoObj == "") {
				mobileNoObj = mobileNo;
			} else {
				mobileNoObj = mobileNoObj + ","
					+ mobileNo;
			}

		}
		if (mobileNoObj != null) {
			mobileNoObj.replace("NA", "");
			mobileNoObj.replace("#", ",");
			mobileNoObj.replace(",,", ",");
		}
		// console.log("mobileNoObj");
		// console.log(mobileNoObj);
		$("#" + this.pageId + "mobileNo").val(mobileNoObj);
		this.changeDetectorRef.detectChanges();
	}

	sendSmsConfirm() {
		this.enteredMessageTextAreaModel = "";
		this.enteredMessageTextAreaModel = $("#" + this.pageId + "messageTextArea").val();
		this.mobileNoModel = "";
		this.mobileNoModel = $("#" + this.pageId + "mobileNo").val();
		if ((this.isChecked == false) && (this.enteredMessageTextAreaModel == null || this.enteredMessageTextAreaModel == "" || this.enteredMessageTextAreaModel == undefined || this.mobileNoModel == null || this.mobileNoModel == "" || this.mobileNoModel == undefined)) {
			swal("Not Allowed", "Please select Number, Message Template And Message Preview!", "warning");
			return false;
		} else if ((this.isChecked == true) && (this.gridCreationDataList.length == 0 || this.enteredMessageTextAreaModel == null || this.enteredMessageTextAreaModel == "" || this.enteredMessageTextAreaModel == undefined)) {
			swal("Not Allowed", "Please select Number, Message Template And Message Preview!", "warning");
			return false;
		} else {
			swal({
				title: "Confirm Action",
				text: "Sure you want to Send SMS?",
				icon: "info",
				buttons: ["No", "Yes"]
			}).then((isConfirm) => {
				if (isConfirm) {
					// if (this.isChecked == true) {
					// 	this.sendSmsToAllParties();
					// } else {
					// 	this.sendSmsToSelectedParties();
					// }
					this.sendSmsToSelectedParties();
				}
			});
		}
	}

	sendSmsToSelectedParties() {
		this.messageDtoToSendSms = [];
		this.enteredMessageTextAreaModel = "";
		this.enteredMessageTextAreaModel = $("#" + this.pageId + "messageTextArea").val();
		this.mobileNoModel = '';
		this.mobileNoModel = $("#" + this.pageId + "mobileNo").val();
		// console.log("this.modelTemplate >> clickListnerForTemplate");
		// console.log(this.modelTemplate);
		this.enteredMobileNo = [];
		this.enteredMobileNo = this.mobileNoModel.split(',');

		// console.log("this.enteredMobileNo >> sendSmsToSelectedParties");
		// console.log(this.enteredMobileNo);

		for (let i = 0; i < this.enteredMobileNo.length; i++) {
			this.messageDtoToSetSendSms = new MessageDto();
			this.messageDtoToSetSendSms.message = this.enteredMessageTextAreaModel;
			this.messageDtoToSetSendSms.mobileNum = this.enteredMobileNo[i];
			this.messageDtoToSetSendSms.senderId = this.modelTemplate.senderId;
			this.messageDtoToSetSendSms.templateId = this.modelTemplate.templateId;
			this.messageDtoToSendSms.push(this.messageDtoToSetSendSms);
		}
		// console.log("this.messageDtoToSendSms >> ");
		// console.log(this.messageDtoToSendSms);

		this.showSpinnerForAction = true;
		this.masterReadService.sendSmsDetails(this.messageDtoToSendSms).subscribe(
			(response) => {
				this.messageDtoSendRet = null;
				this.messageDtoSendRet = response;
				if (this.messageDtoSendRet.status == "Success") {
					swal({
						title: "Success",
						text: "SMS Sent Successfully",
						icon: "success",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						//this.resetOverAll();
						this.resetVaraibles();
					})
				} else if (this.messageDtoSendRet.status == "Failed") {
					swal("Error", "Failed to send the SMS", "error");
				}

				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while Sending SMS", "error");
			}, () => console.log('done');
	}


	resetOverAll() {
		this.viewPlace = false;
		this.viewSrc = false;
		this.viewDest = false;
		this.viewInvoiceNo = false;
		this.viewGrpName = false;
		this.viewCommodity = false;
		this.viewMobile = false;
		this.viewSendBtn = false;
		this.viewSmsTypeAll = true;
		this.viewSmsTypeInvoice = false;
		this.enteredInvoiceNumber = null;
		this.selectedPlace = null;
		this.selectedSmsType = null;
		this.lrDtoForInvOnEnter = new LRDto();
		this.selectedSource = null;
		this.selectedDestination = null;
		this.selectedCommodity = null;
		this.partyMasterDtoForGridCreation = new PartyMasterDto();
		this.tableTopHeading = "";
		this.modelGrpNameFotGet = null;
		this.modelTemplate = null;
		this.isChecked = false;
		this.showTextAreaTemplate = false;
		this.viewInputFieldOne = false;
		this.viewInputFieldTwo = false;
		this.viewInputFieldThree = false;
		this.viewInputFieldFour = false;
		this.viewInputFieldFive = false;
		this.viewInputFieldSix = false;
		this.messageTextAreaTempModel = "";
		this.enteredMessageTextAreaModel = null;
		this.enteredInputFieldOne = null;
		this.enteredInputFieldTwo = null;
		this.enteredInputFieldThree = null;
		this.enteredInputFieldFour = null;
		this.enteredInputFieldFive = null;
		this.enteredInputFieldSix = null;
		this.viewSmsPreview = false;
		this.viewPreviewBtn = false;
		this.messageDtoToSetSendSms = new MessageDto();
		this.messageDtoToSendSms = [];
		this.messageDtoCheckedMobileNo = [];
		this.messageDtoForSelectedParties = new MessageDto();
		this.modelSource = null;
		this.modelDropDownDestination = null;
		this.modelCommodity = null;
		$("#" + this.pageId + "sendSmsType").val('Select Send Type');
		$("#" + this.pageId + "smsType").val('Select SMS Type');

		$("#" + this.pageId + "placeType").val('Select Branch');
		$("#" + this.pageId + "sourceId").val('');
		$("#" + this.pageId + "destinationId").val('');
		$("#" + this.pageId + "partyCommodity").val('');
		$("#" + this.pageId + "inovicenNo").val('');
		$("#" + this.pageId + "grpName").val('');
		$("#" + this.pageId + "template").val('');
		$("#" + this.pageId + "messageTextAreaTemp").val('');
		$("#" + this.pageId + "inputFieldOne").val('');
		$("#" + this.pageId + "inputFieldTwo").val('');
		$("#" + this.pageId + "inputFieldThree").val('');
		$("#" + this.pageId + "inputFieldFour").val('');
		$("#" + this.pageId + "inputFieldFive").val('');
		$("#" + this.pageId + "inputFieldSix").val('');
		$("#" + this.pageId + "messageTextArea").val('');
		$("#" + this.pageId + "mobileNo").val('');
		this.mobileNoModel = null;

		$("#" + this.pageId + "gridCreationTableId").DataTable().destroy();
		this.gridCreationDataList = [];
		this.dtTriggerForGridCreation.next();
		this.viewLrNoCheckbox = false;
		$("#" + this.pageId + "checkboxLrNo").prop('checked', false);
		$("#" + this.pageId + "includeLrNoTableId").DataTable().destroy();
		this.includeLrNoDataList = [];
		this.dtTriggerForIncludeLrNo.next();
		this.isCheckedIncludeLrNo = false;
	}

	resetVaraibles() {
		this.enteredInputFieldOne = null;
		this.enteredInputFieldTwo = null;
		this.enteredInputFieldThree = null;
		this.enteredInputFieldFour = null;
		this.enteredInputFieldFive = null;
		this.enteredInputFieldSix = null;
		this.enteredMessageTextAreaModel = null;
		$("#" + this.pageId + "inputFieldOne").val('');
		$("#" + this.pageId + "inputFieldTwo").val('');
		$("#" + this.pageId + "inputFieldThree").val('');
		$("#" + this.pageId + "inputFieldFour").val('');
		$("#" + this.pageId + "inputFieldFive").val('');
		$("#" + this.pageId + "inputFieldSix").val('');
		$("#" + this.pageId + "messageTextArea").val('');
		$("#" + this.pageId + "mobileNo").val('');
		this.mobileNoModel = null;
	}

	notificationTypeMode(smsType: string) {
		this.notificationType = smsType;
		//alert(this.notificationType);
	}

	hideFieldsForWhatsapp() {
		this.viewInputFieldOne = false;
		this.viewInputFieldTwo = false;
		this.viewInputFieldThree = false;
		this.viewInputFieldFour = false;
		this.viewInputFieldFive = false;
		this.viewInputFieldSix = false;
		this.modelTemplate = null;
		this.viewSmsPreview = true;
		this.viewPreviewBtn = false;
		this.showTextAreaTemplate = false;
		//$("#" + this.pageId + "messageTextArea").prop('readonly', false);
		$("#" + this.pageId + "messageTextArea").prop('disabled', false);
	}

	removeSpacesFromPrefixSuffixMiddle(value): string {
		// Remove spaces from the prefix
		value = value.replace(/^\s+/, '');

		// Remove spaces from the suffix
		value = value.replace(/\s+$/, '');

		// Remove spaces from the middle
		value = value.replace(/\s+/g, ',');

		return value;
	}

	//Chg_sms_v1 start
	checkInvDetailsOnIncludeLrEnter() {
		this.enteredInvoiceNumber = null;
		this.selectedPlace = null;
		this.selectedSmsType = null;
		this.enteredInvoiceNumber = $("#" + this.pageId + "inovicenNo").val();
		this.selectedPlace = $("#" + this.pageId + "placeType").val();
		this.selectedSmsType = $("#" + this.pageId + "smsType").val();
		if (this.enteredInvoiceNumber == null || this.enteredInvoiceNumber == undefined || this.enteredInvoiceNumber == ""
			|| this.selectedPlace == null || this.selectedPlace == undefined || this.selectedPlace == "" || this.selectedPlace == "Select Branch"
			|| this.selectedSmsType == null || this.selectedSmsType == undefined || this.selectedSmsType == "" || this.selectedSmsType == "Select SMS Type") {
			swal({
				title: "Warning",
				text: "SMS Type, Place & Invoice are Mandatory Fields!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			$("#" + this.pageId + "mobileNo").val('');
			this.mobileNoModel = null;
			this.messageDtoCheckedMobileNo = [];
			this.getInvDetailsOnIncludeLrEnter();
		}
	}

	getInvDetailsOnIncludeLrEnter() {
		this.enteredInvoiceNumber = null;
		this.selectedPlace = null;
		this.selectedSmsType = null;
		this.enteredInvoiceNumber = $("#" + this.pageId + "inovicenNo").val();
		this.selectedPlace = $("#" + this.pageId + "placeType").val();
		this.selectedSmsType = $("#" + this.pageId + "smsType").val();
		this.lrDtoForLrInvOnEnter = new LRDto();
		this.lrDtoForLrInvOnEnter.invoiceNumber = this.enteredInvoiceNumber;

		if (this.selectedPlace == "source") {
			this.lrDtoForLrInvOnEnter.status = "src";
		} else {
			this.lrDtoForLrInvOnEnter.status = "dest";
		}
		this.lrDtoForLrInvOnEnter.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction = true;
		console.log(this.lrDtoForLrInvOnEnter);
		this.masterReadService.getSmsInvoiceDetailsIncludeLrNo(this.lrDtoForLrInvOnEnter).subscribe(
			(response) => {
				$("#" + this.pageId + "includeLrNoTableId").DataTable().destroy();
				this.includeLrNoDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No records found for this inovice : " + this.enteredInvoiceNumber,
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {
					this.includeLrNoDataList = [];
					this.includeLrNoDataList = response;
					console.log("this.includeLrNoDataList");
					console.log(this.includeLrNoDataList);
					this.tableTopHeading = "";
					this.tableTopHeading = response[0].invoiceNumber + " - Party List";
				}
				this.showSpinnerForAction = false;
				this.dtTriggerForIncludeLrNo.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting invoice Wise Details", "info");
			}, () => console.log('done');
	}

	sendSmsConfirmIncludeLrNo() {
		this.enteredMessageTextAreaModel = "";
		this.enteredMessageTextAreaModel = $("#" + this.pageId + "messageTextArea").val();
		if ((this.includeLrNoDataList.length == 0 || this.enteredMessageTextAreaModel == null || this.enteredMessageTextAreaModel == "" || this.enteredMessageTextAreaModel == undefined)) {
			swal("Not Allowed", "Please select Number, Message Template And Message Preview!", "warning");
			return false;
		} else {
			swal({
				title: "Confirm Action",
				text: "Sure you want to Send SMS?",
				icon: "info",
				buttons: ["No", "Yes"]
			}).then((isConfirm) => {
				if (isConfirm) {
					this.sendSmsToSelectedPartiesIncludeLrNo();
				}
			});
		}
	}

	sendSmsToSelectedPartiesIncludeLrNo() {
		this.messageDtoToSendSmsIncludeLrNo = [];
		this.enteredMessageTextAreaModel = "";
		this.enteredMessageTextAreaModel = $("#" + this.pageId + "messageTextArea").val();

		for (let i = 0; i < this.includeLrNoDataList.length; i++) {
			this.messageDtoForSelectedPartiesIncludeLrNo = new MessageDto();
			this.messageDtoForSelectedPartiesIncludeLrNo.message = this.enteredMessageTextAreaModel;
			this.messageDtoForSelectedPartiesIncludeLrNo.mobileNum = this.includeLrNoDataList[i].mobileNum;
			this.messageDtoForSelectedPartiesIncludeLrNo.lrNumber = this.includeLrNoDataList[i].lrNumber;
			this.messageDtoForSelectedPartiesIncludeLrNo.senderId = this.modelTemplate.senderId;
			this.messageDtoForSelectedPartiesIncludeLrNo.templateId = this.modelTemplate.templateId;
			this.messageDtoToSendSmsIncludeLrNo.push(this.messageDtoForSelectedPartiesIncludeLrNo);
		}
		this.showSpinnerForAction = true;
		console.log(this.messageDtoToSendSmsIncludeLrNo);
		this.masterReadService.sendSmsDetailsIncludeLrNo(this.messageDtoToSendSmsIncludeLrNo).subscribe(
			(response) => {
				this.messageDtoSendRet = null;
				this.messageDtoSendRet = response;
				if (this.messageDtoSendRet.status == "Success") {
					swal({
						title: "Success",
						text: "SMS Sent Successfully",
						icon: "success",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.resetVaraibles();
					})
				} else if (this.messageDtoSendRet.status == "Failed") {
					swal("Error", "Failed to send the SMS", "error");
				}

				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while Sending SMS", "error");
			}, () => console.log('done');
	}

	sendSmsConfirmWithType() {
		this.checkBoxForLrNo = false;
		this.checkBoxForLrNo = $("#" + this.pageId + "checkboxLrNo").is(":checked");
		this.messageType = $("#" + this.pageId + "notificationType").val();
		console.log(this.messageType);
		if (this.checkBoxForLrNo) {
			if (this.messageType == 'Whatsapp') {
				this.sendWhatsAppConfirmIncludeLrNo();
			} else {
			console.log("if SMS");
			console.log(this.checkBoxForLrNo);
			this.sendSmsConfirmIncludeLrNo();
			}
		} else {
			console.log("else SMS");
			this.sendSmsConfirm();
		}
	}

	checkboxIncludeLrNo(event) {
		if (event.target.checked) {
			console.log("if table");
			this.viewIncludeLrNoTable = true;
			this.viewMobile = false;
			$("#" + this.pageId + "mobileNo").val('');
			this.mobileNoModel = null;
			$("#" + this.pageId + "gridCreationTableId").DataTable().destroy();
			this.gridCreationDataList = [];
			this.dtTriggerForGridCreation.next();
			$("#" + this.pageId + "includeLrNoTableId").DataTable().destroy();
			this.includeLrNoDataList = [];
			this.dtTriggerForIncludeLrNo.next();
			
		} else {
			console.log("else table");
			this.viewIncludeLrNoTable = false;
			this.viewMobile = true;
			$("#" + this.pageId + "gridCreationTableId").DataTable().destroy();
			this.gridCreationDataList = [];
			this.dtTriggerForGridCreation.next();
			$("#" + this.pageId + "includeLrNoTableId").DataTable().destroy();
			this.includeLrNoDataList = [];
			this.dtTriggerForIncludeLrNo.next();
			
		}
		this.changeDetectorRef.detectChanges();
	}
	//Chg_sms_v1 End

	//Chg_sms_v3
	sendWhatsAppConfirmIncludeLrNo() {
		this.enteredMessageTextAreaModel = "";
		this.enteredMessageTextAreaModel = $("#" + this.pageId + "messageTextArea").val();
		if ((this.includeLrNoDataList.length == 0 || this.enteredMessageTextAreaModel == null || this.enteredMessageTextAreaModel == "" || this.enteredMessageTextAreaModel == undefined)) {
			swal("Not Allowed", "Please select Number, Message Template And Message Preview!", "warning");
			return false;
		} else {
			swal({
				title: "Confirm Action",
				text: "Sure you want to Send WhatsApp Message?",
				icon: "info",
				buttons: ["No", "Yes"]
			}).then((isConfirm) => {
				if (isConfirm) {
					this.sendWhatsAppToSelectedPartiesIncludeLrNo();
				}
			});
		}
	}

	sendWhatsAppToSelectedPartiesIncludeLrNo() {
		this.messageDtoToSendSmsIncludeLrNo = [];
		this.enteredMessageTextAreaModel = "";
		this.enteredMessageTextAreaModel = $("#" + this.pageId + "messageTextArea").val();

		for (let i = 0; i < this.includeLrNoDataList.length; i++) {
			this.messageDtoForSelectedPartiesIncludeLrNo = new MessageDto();
			this.messageDtoForSelectedPartiesIncludeLrNo.message = this.enteredMessageTextAreaModel;
			this.messageDtoForSelectedPartiesIncludeLrNo.mobileNum = this.includeLrNoDataList[i].mobileNum;
			this.messageDtoForSelectedPartiesIncludeLrNo.lrNumber = this.includeLrNoDataList[i].lrNumber;
			this.messageDtoForSelectedPartiesIncludeLrNo.senderId = this.modelTemplate.senderId;
			this.messageDtoForSelectedPartiesIncludeLrNo.templateId = this.modelTemplate.templateId;
			this.messageDtoToSendSmsIncludeLrNo.push(this.messageDtoForSelectedPartiesIncludeLrNo);
		}
		this.showSpinnerForAction = true;
		console.log(this.messageDtoToSendSmsIncludeLrNo);

		//Chg_sms_v4
		this.masterReadService.sendWhatsAppDetailsIncludeLrNo(this.messageDtoToSendSmsIncludeLrNo).subscribe(
		// this.masterReadService.sendWhatsAppDetailsIncludeLrNoVerloop(this.messageDtoToSendSmsIncludeLrNo).subscribe(
			(response) => {
				this.messageDtoSendRet = null;
				this.messageDtoSendRet = response;
				if (this.messageDtoSendRet.status == "Success") {
					swal({
						title: "Success",
						text: "WhatsApp Message Sent Successfully!",
						icon: "success",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.resetVaraibles();
					})
				} else if (this.messageDtoSendRet.status == "Failed") {
					swal("Error", "Failed to send the WhatsApp Message!", "error");
				}

				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while Sending WhatsApp Message!", "error");
			}, () => console.log('done');
	}
 }

