<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showDailyBkgRpt">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Daily Booking Report</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <!-- <div class="col-sm-12 col-md-12">
                      <div class="form-group">
                        <div class="input-group">
                          <label>View Type</label>
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-question"></i>
                            </span>
                          </div>
                           <select class="custom-select col-12"   id="{{pageId}}viewType">
                            <option selected value="summaryWise">Summary Wise</option>
                            <option value="detailsWise">Details Wise</option>
                          </select>
                        </div>
                      </div>
                    </div>-->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="{{pageId}}godownRpt" [(ngModel)]="viewByGodownRpt">
                                                    <label class=" custom-control-label wtfull " for="{{pageId}}godownRpt">Godown Report</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="{{pageId}}orderGoodsRpt" [(ngModel)]="viewByOrderGoodsRpt">
                                                    <label class=" custom-control-label wtfull" for="{{pageId}}orderGoodsRpt">Order Goods</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="!viewByGodownRpt" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label>
                                                    <!-- <input ng-change="vm.dateString = (dateObj | date: 'dd/dd/yyyy')" class="form-control" (click)="e.toggle()" placeholder="yyyy-mm-dd"
                                  name="dp" [(ngModel)]="model" ngbDatepicker #e="ngbDatepicker">-->
                                                    <input class="form-control" id="{{pageId}}fromDate" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="!viewByGodownRpt" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input #sourceDropDown id="{{pageId}}dropDownInputFieldSource" type="text" class="form-control" [(ngModel)]="modelDropDownSource" [ngbTypeahead]="searchDropDownInputFieldSource" [resultFormatter]="formatterDropDownSource" [inputFormatter]="formatterDropDownSource"
                                                        placeholder="Select Source" (focus)="focusDropDownSourceTA$.next($any($event).target.value)" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input #destinationDropDown id="{{pageId}}dropDownInputFieldDestination" type="text" class="form-control" [(ngModel)]="modelDropDownDestination" [ngbTypeahead]="searchDropDownInputFieldDestination" [resultFormatter]="formatterDropDownDestination" [inputFormatter]="formatterDropDownDestination"
                                                        placeholder="Select Destination" (focus)="focusDropDownDestinationTA$.next($any($event).target.value)" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateBtnSearch()">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                    </div>
                </div>
                <div class="col-md-9 vl p-t-10">
                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button type="submit" class="dt-button" style="float: right;" (click)="customPrintPartyWiseOsSumRpt()" id="{{pageId}}printAllBtn">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="box-body">
                            <table datatable id="{{pageId}}dailyBkgLrsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsDailyBkgReport" [dtTrigger]="dtTriggerDailyBkgReport">
                                <thead>
                                    <tr>
                                        <th [hidden]=true>Destination</th>
                                        <th>LR No</th>
                                        <th>Source</th>
                                        <th>Consignee</th>
                                        <th>Consignee GST</th>
                                        <th>Consignor</th>
                                        <th>Consignor GST</th>
                                        <th>Act Wt</th>
                                        <th>Chg Wt</th>
                                        <th>Mode</th>
                                        <th>Art</th>
                                        <th>Commodity</th>
                                        <th>Size</th>
                                        <th>Basic Frgt</th>
                                        <th>Src Hamali</th>
                                        <th>GC Chg</th>
                                        <th>F O V</th>
                                        <th>LC Chg</th>
                                        <th>BC Chg</th>
                                        <th>Others</th>
                                        <th>Is DD</th>
                                        <th>DD Amt</th>
                                        <th>LR Tot</th>
                                        <th>Paid</th>
                                        <th>Destination Memo Date</th>
                                        <th>Pvt Marker</th>
                                        <th>Booking Date(A)</th>
                                        <th>Entry Date(B)</th>
                                        <th>Diff(B-A)</th>
                                        <th>Dep.Dt Frm Src(C)</th>
                                        <th>Diff(C-B)</th>
                                        <th>Src Inv No</th>
                                        <th>Arr.Dt At 1st Stop(D)</th>
                                        <th>Diff(D-C)</th>
                                        <th>Dep.Dt Frm 1st Stop(E)</th>
                                        <th>Dep.Frm 1st Stop Inv No</th>
                                        <th>Diff(E-D)</th>
                                        <th>Arr.Dt At 2nd Stop(F)</th>
                                        <th>Diff(F-E)</th>
                                        <th>Dep.Dt Frm 2nd Stop(G)</th>
                                        <th>Dep.Frm 2nd Stop Inv No</th>
                                        <th>Diff(G-F)</th>
                                        <th>Arr.Dt At Dest(H)</th>
                                        <th>Diff(H-G)</th>
                                        <th>Delivery Date(I)</th>
                                        <th>Diff(I-H)</th>
                                        <th>Tot No.of Days</th>
                                        <th>Cur Dt(-)Bkg Dt(Only for Transit Lrs)</th>
                                        <th>Current Status</th>
                                        <th>Eway Bill Number</th>
                                        <th>Eway Bill Validity</th>
                                        <th>Scanning Date</th>
                                        <th>Scheduled Date(Main Invoice)</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dailyBkgReportData of dailyBkgReportDataList ">
                                        <td [hidden]=true>{{ dailyBkgReportData.destination }}</td>
                                        <td>
                                            <a class="hyperLink_td" (click)="lrNumberToEnquiryForm(dailyBkgReportData)">
                                                <u>{{
                                                    dailyBkgReportData.lrNumber }}</u>
                                            </a>
                                        </td>
                                        <td>{{ dailyBkgReportData.source }}</td>
                                        <td>{{ dailyBkgReportData.consigneeName }}</td>
                                        <td>{{ dailyBkgReportData.gstNoConsignee }}</td>
                                        <td>{{ dailyBkgReportData.consignorName }}</td>
                                        <td>{{ dailyBkgReportData.gstNoConsignor }}</td>
                                        <td>{{ dailyBkgReportData.actualWeight }}</td>
                                        <td>{{ dailyBkgReportData.chargedWeight}}</td>
                                        <td>{{ dailyBkgReportData.paymentMode }}</td>
                                        <td>{{ dailyBkgReportData.totalArticles }}</td>
                                        <td>{{ dailyBkgReportData.commodityName }}</td>
                                        <td>{{ dailyBkgReportData.multiArtSize }}</td>
                                        <td>{{ dailyBkgReportData.baseFreight }}</td>
                                        <td>{{ dailyBkgReportData.srcHamali}}</td>
                                        <td>{{ dailyBkgReportData.gcCharge }}</td>
                                        <td>{{ dailyBkgReportData.riskCharge }}</td>
                                        <td>{{ dailyBkgReportData.lcChg }}</td>
                                        <td>{{ dailyBkgReportData.bcChg }}</td>
                                        <td>{{ dailyBkgReportData.others }}</td>
                                        <td>{{ dailyBkgReportData.isDoorDeliv }}</td>
                                        <td>{{ dailyBkgReportData.ddAmt }}</td>
                                        <td>{{ dailyBkgReportData.toPay }}</td>
                                        <td>{{ dailyBkgReportData.paid }}</td>
                                        <td>{{ dailyBkgReportData.memoDateStr }}</td>
                                        <td>{{ dailyBkgReportData.privateMarker }}</td>
                                        <td>{{ dailyBkgReportData.bookingDateStr }}</td>
                                        <td>{{ dailyBkgReportData.enteredDateStr }}</td>
                                        <td>{{ dailyBkgReportData.diff9 }}</td>
                                        <td>{{ dailyBkgReportData.departuredateStr }}</td>
                                        <td>{{ dailyBkgReportData.diff8 }}</td>
                                        <td>{{ dailyBkgReportData.invoiceNumber }}</td>
                                        <td>{{ dailyBkgReportData.arrivalDateStr }}</td>
                                        <td>{{ dailyBkgReportData.diff1 }}</td>
                                        <td>{{ dailyBkgReportData.depDateFrmTrnspDateStr}}</td>
                                        <td>{{ dailyBkgReportData.dummyInvoice}}</td>
                                        <td>{{ dailyBkgReportData.diff2 }}</td>
                                        <td>{{ dailyBkgReportData.arrDateAtTrnspDateStr }}</td>
                                        <td>{{ dailyBkgReportData.diff3 }}</td>
                                        <td>{{ dailyBkgReportData.depDateFrmTrnspDate2Str }}</td>
                                        <td>{{ dailyBkgReportData.dummyInvoice2}}</td>
                                        <td>{{ dailyBkgReportData.diff4 }}</td>
                                        <td>{{ dailyBkgReportData.arrDateAtTrnspDate2Str }}</td>
                                        <td>{{ dailyBkgReportData.diff5 }}</td>
                                        <td>{{ dailyBkgReportData.deliveryDateStr }}</td>
                                        <td>{{ dailyBkgReportData.diff6 }}</td>
                                        <td>{{ dailyBkgReportData.diffDays }}</td>
                                        <td>{{ dailyBkgReportData.diff7 }}</td>
                                        <td>{{ dailyBkgReportData.status }}</td>
                                        <td>{{ dailyBkgReportData.eWayBillNo }}</td>
                                        <td>{{ dailyBkgReportData.ewayBillValidUpto }}</td>
                                        <td>{{ dailyBkgReportData.rewardingAmtDateStr }}</td>
                                        <td>{{ dailyBkgReportData.scheduleddateStr }}</td>

                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td [hidden]=true>Total</td>
                                        <td style="text-align: left"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <!-- <app-custom-dynamic-printV1></app-custom-dynamic-printV1> -->
    <app-daily-bkg-rpt-print></app-daily-bkg-rpt-print>
</div>