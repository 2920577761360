//Chg_V1 : Add prefix code for lrNumber (Asrar Jr / 23-09-2024)
//Chg_V2 : Add Fields and Calculate Amount (Asrar Jr / 30-09-2024)
//Chg_V3 : Changed getting status from button click becuase added a scheduled trip button (Imran / 08112024)

import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { StockService } from 'src/app/dataService/stock-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { LRDto } from 'src/app/dto/LR-dto';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { DriverdetailsDto } from 'src/app/dto/Driverdetails-dto';
import { MasterService } from 'src/app/dataService/master-service';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { RequestDto } from 'src/app/dto/Request-dto';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-stocks-for-local-booking',
    templateUrl: './stocks-for-local-booking.component.html',
    styleUrls: ['./stocks-for-local-booking.component.css']
})
export class StocksForLocalBookingComponent implements OnInit {
    @ViewChild('stepper') stepper: MatStepper;

    linear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    fourthFormGroup: FormGroup;

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    showSpinnerForTripScheduleAction = false;
    showSpinnerForTripDetailsAction = false;

    //for datatable starts
    dataTable: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    //1 st stock for trip sheet level - start
    //Stock Summary & Details Table start
    onDestroyUnsubscribtionStockSummary: Subscription;
    onDestroyUnsubscribtionStockDetails: Subscription;

    dtOptionsStockSummary: any;
    dtOptionsStockDetails: any;

    dtTriggerStockSummary: Subject<any> = new Subject();
    dtTriggerStockDetails: Subject<any> = new Subject();

    stockSummaryDataList: any;
    stockDetailsDataList: any;
    //Stock Summary & Details Table end

    //Stock eligible & remaining lr Table start

    onDestroyUnsubscribtionEligibleLrs: Subscription;
    onDestroyUnsubscribtionRemainingLrs: Subscription;

    dtTriggerEligibleLrs: Subject<any> = new Subject();
    dtTriggerRemainingLrs: Subject<any> = new Subject();

    dtOptionsEligibleLrs: any;
    dtOptionRemainingLrs: any;

    eligibleLrsDataList: any = [];
    remainingLrsDataList: any;

    //Stock eligible & remaining lr Table end

    //for datatable end

    rightsToShowDeliveryMan = "StockForTripsheet ShowDelvryMan";
    showDeliveryMan = false;
    viewBinsStockMsg: any = null;
    viewLocalTripsheetMsg: any = null;

    lrDtoArticleSummary: LRDto = new LRDto();
    lrDtoStocksDetails: LRDto = new LRDto();
    //lrDtoPassLocalStorage: LRDto = new LRDto();
    lrDtoPassTripSchedulePage: LRDto = new LRDto();

    tripSheetBlackBtnHideShow = false;
    tripSheetBtnHideShow = false;

    tripSheetBtnId: string;
    dummyTripSheetBtnId: string;
    //makeATripBtnId: string;
    //tripSheetBtnId: string;

    //1 st stock for trip sheet level - end

    // 2 nd stock for trip sheet level - start 

    // Drop Down Field Start
    branchBinsOption: Array<LRDto> = [];
    lrDtoBranchBins: LRDto = new LRDto();
    validateLrDtoBins: LRDto = new LRDto();
    lrDtoSelectedBranchBins: LRDto = new LRDto();
    public modelDropDownBins: any;
    branchBinsTA: Array<LRDto> = [];
    focusDropDownBinsTA$ = new Subject<string>();
    searchDropDownInputFieldBins = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownBinsTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.branchBinsTA
                : this.branchBinsTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownBins = (x: { godownName: string }) => x.godownName;

    // Drop Down Field End

    //stockTripSheetReturnLocalStorage: LRDto = new LRDto();
    lrDtoForScheduleTripsheetReturn: LRDto = new LRDto();
    lrDtoControlBinService: LRDto = new LRDto();
    //lrDtoCreateATripDetailLocalStorage: LRDto = new LRDto();
    lrDtoCreateATripDetail: LRDto = new LRDto();
    //Dashboard
    //lrDtoFromDashboardScheduleLocalTrip: LRDto = new LRDto();
    //lrDtoForScduleLrDashboardLocalTrip: LRDto = new LRDto();


    enteredStockBinsDestination: any = null;
    enteredLrNumber: any = null;
    selectedControBinsDataOfAllLrsList: any = [];
    resultList: any = null;

    viewNextDarkBtnForScheduleTrip = false;
    viewNextGreenBtnForScheduleTrip = false;

    /* 2 nd stock for trip sheet level - end*/

    /* 3 rd stock for trip sheet level - start */

    truckDataDtoNewVehicleNumberSave: TruckDataDto = new TruckDataDto();
    truckDataDtoNewVehicleNumberSaveReturn: any = null;

    vehicleNumberOption: Array<TruckDataDto> = [];
    truckDataDtoVehicleNumber: TruckDataDto = new TruckDataDto();
    truckDataDtoAddNewVehicleNumber: TruckDataDto = new TruckDataDto();
    public modelDropDownVehicleNumber: any;
    vehicleNumberTA: Array<TruckDataDto> = [];
    focusDropDownVehicleNumberTA$ = new Subject<string>();
    searchDropDownInputFieldVehicleNumber = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownVehicleNumberTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.vehicleNumberTA
                : this.vehicleNumberTA.filter(v => v.truckNumber.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownVehicleNumber = (x: { truckNumber: string }) => x.truckNumber;

    driverNameOption: Array<DriverdetailsDto> = [];
    driverDataDtoDriverName: DriverdetailsDto = new DriverdetailsDto();
    public modelDropDownDriverName: any;
    driverNameTA: Array<DriverdetailsDto> = [];
    focusDropDownDriverNameTA$ = new Subject<string>();
    searchDropDownInputFieldDriverName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownDriverNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.driverNameTA
                : this.driverNameTA.filter(v => v.driverName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownDriverName = (x: { driverName: string }) => x.driverName;

    labourNameOption: Array<LRDto> = [];
    lrDtoLabourName: LRDto = new LRDto();
    public modelDropDownLabourName: any;
    labourNameTA: Array<LRDto> = [];
    focusDropDownLabourNameTA$ = new Subject<string>();
    searchDropDownInputFieldLabourName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownLabourNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.labourNameTA
                : this.labourNameTA.filter(v => v.loaderName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownLabourName = (x: { loaderName: string }) => x.loaderName;

    labourHeadNameOption: Array<HireSlipDto> = [];
    hireSlipDtoLabourHeadName: HireSlipDto = new HireSlipDto();
    public modelDropDownLabourHeadName: any;
    labourHeadNameTA: Array<HireSlipDto> = [];
    focusDropDownLabourHeadNameTA$ = new Subject<string>();
    searchDropDownInputFieldLabourHeadName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownLabourHeadNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.labourHeadNameTA
                : this.labourHeadNameTA.filter(v => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownLabourHeadName = (x: { loaderHead: string }) => x.loaderHead;

    modalRefferenceVehicleNumberPopUp: NgbModalRef;
    modalRefferenceVehicleNumberClosePopUp: string;
    @ViewChild('vehicleNumberPopUpTemplate') private vehicleNumberPopUpTemplate;

    enteredPopUpNewVehicleNumber: any = null;
    eneterdPopUpNewVehicleName: any = null;

    enteredlabourNameListOfHamaliAdd: any = null;
    enteredlabourHeadNameListOfHamaliAdd: any = null;

    viewToShowDeliveryManField = false;
    viewScheduleTripBtn = false;

    lrDtoSetTripDetails: LRDto = new LRDto();
    lrDtoSetTripDetailsReturn: LRDto = new LRDto();
    lrdtoNewTripCreateAndPrint: LRDto = new LRDto();
    lrdtoUpdateTripCreateAndPrint: LRDto = new LRDto();
    lrdtoForAddNewScheduleTrip: LRDto = new LRDto();
    lrdtoForUpdateScheduleTrip: LRDto = new LRDto();
    listOfLrs: string[];

    validateTotArticles: number;
    validateTotAmt: number;
    validateActWgt: number;
    validateChgWgt: number;
    validateDdAmt: number;

    enteredVehicleNumber: any;
    enteredDriverName: any;
    enteredDriverMobileNo: any;
    enteredLabourHamaliName: any;
    enteredLoaderHeadName: any;
    enteredTransportName: any;

    hashmapVehicle: Map<String, String> = new Map<String, String>();
    viewStockTripsheetPrint = false;
    viewStockTripsheetPrintOld = false;
    /* 3 rd stock for trip sheet level - end */

    /* 2 nd page */
    localStorageOfStockGrouping: any;
    localStorageOfStockGroupingDestination: any;
    /* 2 nd page */


    lrDtoListRemChecked: Array<LRDto> = [];
    lrDtoListRemCheckedIndex: any;
    pageId = "sflbc";
    consolidateEwaybillEnabled: boolean = false;
    requestDto: RequestDto = new RequestDto();
    showLrCodeInputFields: any;
    showAutoLrCode: any;
    prefixLrNumber: any;
    enteredLorryHire: any;
    enteredAdvance: any;
    balanceAmt: any;
    @ViewChild("advance") advanceField: ElementRef;
    viewScheduleTripBtnV2: boolean = false;
    constructor(private modalService: NgbModal, private router: Router, private masterReadService: MasterReadService,
        private stockService: StockService, private hireslipService: HireslipService, private masterService: MasterService,
        public changeDetectorRef: ChangeDetectorRef, private ewaybillService: EwaybillService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }
        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.enableConsolidatedEWBAutoGenerationLocalTrip != null && this.userDataDtoReturnSession.enableConsolidatedEWBAutoGenerationLocalTrip) {
                this.consolidateEwaybillEnabled = true;
            }
            /*1 st stock for trip sheet level - start*/
            this.viewBinsStockMsg = "Bins Stock";
            this.viewLocalTripsheetMsg = "";
            /*if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "StockForTripsheet ShowDelvryMan") {
                        this.showDeliveryMan = true;
                    }
                }
            }*/

            this.setTripCrationForArticlesStocksSummaryDetailsList();

            /*1 st stock for trip sheet level - end*/

            /*2 nd stock for trip sheet level - start*/

            //this.setBranchAreaDetailsList();

            /*2 nd stock for trip sheet level - end*/

            /*3 rd stock for trip sheet level - start*/
            /* 
            if (this.showDeliveryMan != null && this.showDeliveryMan == true) {
             */
            if (this.userDataDtoReturnSession.office != null &&
                this.userDataDtoReturnSession.office == "Binnymills") {
                this.viewToShowDeliveryManField = true;
                this.setLabourHeadNameDetailsList();
            } else {
                this.viewToShowDeliveryManField = false;
            }

            this.setVehicleDetailsList();
            this.setDriverNameDetailsList();
            this.setLabourNameDetailsList();

            /*3 rd stock for trip sheet level - end*/

            /*2 nd stock for trip sheet level - start*/
            //this.lrDtoFromDashboardScheduleLocalTrip = JSON.parse(localStorage.getItem('stockForTripsheetDashboardLocalStorage'));
            //localStorage.clear();
            //console.log(this.lrDtoFromDashboardScheduleLocalTrip);

            /*2 nd stock for trip sheet level - end*/

            //
            this.localStorageOfStockGrouping = null;
            this.localStorageOfStockGroupingDestination = null;
            this.localStorageOfStockGrouping = JSON.parse(localStorage.getItem('stockGroupingPageRedirect'));
            this.localStorageOfStockGroupingDestination = JSON.parse(localStorage.getItem('stockGroupingPageSelectedDestination'));
            console.log(this.localStorageOfStockGrouping);
            localStorage.clear();
            // Chg_V1
            this.showAutoLrCode = this.userDataDtoReturnSession.autoLRCode;
            if (this.showAutoLrCode != null) {
                this.showLrCodeInputFields = this.showAutoLrCode;
            }
            if (this.userDataDtoReturnSession.enableBarcodeGen != null && this.userDataDtoReturnSession.enableBarcodeGen) {
                this.viewScheduleTripBtnV2 = true;
            }


        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit() {
        /*1 st stock for trip sheet level - start*/
        this.dtOptionsStockSummary = {
            dom: 'Bfrtip',
            buttons: [
            ],
            order: [[1, 'asc']],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            pagingType: 'full_numbers',
            // pageLength: 6,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totLrs = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var articles = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totLrs);
                $(api.column(2).footer()).html(articles);
                $(api.column(3).footer()).html(actWgt);
                $(api.column(4).footer()).html(chgWgt);
                $(api.column(5).footer()).html(totAmt);
            }
        }
        this.dtOptionsStockDetails = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            pagingType: 'full_numbers',
            //pageLength: 5,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var articles = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(4).footer()).html(articles);
                $(api.column(5).footer()).html(actWgt);
                $(api.column(6).footer()).html(chgWgt);
                $(api.column(7).footer()).html(totAmt);
            }
        }
        /*1 st stock for trip sheet level - end*/
        /*2 nd stock for trip sheet level - start*/
        this.dtOptionsEligibleLrs = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            pagingType: 'full_numbers',
            //pageLength: 5,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var articles = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(articles);
                $(api.column(7).footer()).html(actWgt);
                $(api.column(8).footer()).html(chgWgt);
                $(api.column(9).footer()).html(totAmt);
            }
        }, this.dtOptionRemainingLrs = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            pagingType: 'full_numbers',
            //pageLength: 5,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var articles = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totAmt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(articles);
                $(api.column(7).footer()).html(actWgt);
                $(api.column(8).footer()).html(chgWgt);
                $(api.column(9).footer()).html(totAmt);
            }
        }
        /*2 nd stock for trip sheet level - end*/
    }

    ngAfterViewInit(): void {
        /*1 st stock for trip sheet level - start*/
        //this.dtTriggerStockSummary.next();
        this.dtTriggerStockDetails.next();
        /*1 st stock for trip sheet level - end*/

        /*2 nd stock for trip sheet level - start*/
        //this.dtTriggerEligibleLrs.next();
        //this.dtTriggerRemainingLrs.next();
        /*2 nd stock for trip sheet level - end*/

        /* if (this.lrDtoFromDashboardScheduleLocalTrip != null &&
             this.lrDtoFromDashboardScheduleLocalTrip != undefined) {
             this.setDashboardFroScheduleLocalTripSheet();
         }*/

        if (this.localStorageOfStockGrouping != null &&
            this.localStorageOfStockGrouping != undefined &&
            this.localStorageOfStockGrouping == "Stock Grouping Page") {

            this.viewNextDarkBtnForScheduleTrip = false;
            this.viewNextGreenBtnForScheduleTrip = false;
            this.viewLocalTripsheetMsg = this.localStorageOfStockGroupingDestination;

            this.lrDtoPassTripSchedulePage = new LRDto();
            this.lrDtoPassTripSchedulePage.destination = this.localStorageOfStockGroupingDestination;
            this.lrDtoPassTripSchedulePage.mode = "localgrouping";
            this.lrDtoPassTripSchedulePage.invoiceNumber = null;
            this.lrDtoPassTripSchedulePage.module = "booking";
            this.lrDtoPassTripSchedulePage.selectedTripTo = this.localStorageOfStockGroupingDestination;
            this.lrDtoPassTripSchedulePage.tripId = 0;
            this.lrDtoPassTripSchedulePage.localTripStation = this.localStorageOfStockGroupingDestination;
            setTimeout(() => {
                this.stepper.selectedIndex = 1;
                this.dtTriggerEligibleLrs.next();
                this.dtTriggerRemainingLrs.next();
            }, 1000);

            setTimeout(() => {
                //$("#"+this.pageId+"dropDownInputFieldBins").val(this.tripSheetBtnId);
                this.getMethodForBranchArea();
            }, 2000);
        }

    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        /*1 st stock for trip sheet level - start*/
        this.dtTriggerStockSummary.unsubscribe();
        this.dtTriggerStockDetails.unsubscribe();
        /*1 st stock for trip sheet level - end*/
        /*2 nd stock for trip sheet level - start*/
        this.dtTriggerEligibleLrs.unsubscribe();
        this.dtTriggerRemainingLrs.unsubscribe();
        /*2 nd stock for trip sheet level - end*/
    }

    /*  Main 1 st stock for trip sheet level - start*/
    getTripCrationForArticlesStocksSummaryDetails() {
        this.lrDtoArticleSummary = new LRDto();
        this.lrDtoArticleSummary.branch = this.userDataDtoReturnSession.office;
        this.lrDtoArticleSummary.branchType = this.userDataDtoReturnSession.stationType;
        this.lrDtoArticleSummary.companyId = this.userDataDtoReturnSession.companyId;
    }
    setTripCrationForArticlesStocksSummaryDetailsList() {
        this.getTripCrationForArticlesStocksSummaryDetails();
        this.showSpinnerForAction = true;
        this.stockService.getArticlesStocksSummForLocalBooking(this.lrDtoArticleSummary).subscribe(
            (response) => {
                console.log(response);
                this.stockSummaryDataList = [];
                $("#" + this.pageId + "stockSummaryTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.stockSummaryDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                setTimeout(() => {
                    this.dtTriggerStockSummary.next();
                }, 3000);
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  Stock Summary Details", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    };

    rowSelectedGetTripLRDeatils(stockSummaryTableData) {
        this.lrDtoStocksDetails = new LRDto();
        this.tripSheetBtnId = null;
        this.dummyTripSheetBtnId = null;

        // it is for message show top heading to which destination
        this.viewBinsStockMsg = stockSummaryTableData.endDest;
        this.viewLocalTripsheetMsg = stockSummaryTableData.localTripSpeLogicStations;

        this.stockDetailsDataList = [];
        $("#" + this.pageId + "localBookingForStockDetailsTableId").DataTable().destroy();
        this.dtTriggerStockDetails.next();

        this.lrDtoStocksDetails.branch = this.userDataDtoReturnSession.office;
        if (stockSummaryTableData.totalLrs > 0) {
            //this.makeATripBtnId = stockSummaryTableData.endDest;
            // this.tripSheetBtnId = stockSummaryTableData.endDest;
            this.tripSheetBtnId = stockSummaryTableData.endDest;
            this.dummyTripSheetBtnId = stockSummaryTableData.localTripSpeLogicStations;

            this.lrDtoStocksDetails.destination = stockSummaryTableData.localTripSpeLogicStations;
            this.lrDtoStocksDetails.stationType = stockSummaryTableData.stationType;
            this.lrDtoStocksDetails.subStations = stockSummaryTableData.localTripSpeLogicStations;
            this.lrDtoStocksDetails.branchType = this.userDataDtoReturnSession.stationType;
            this.lrDtoStocksDetails.companyId = this.userDataDtoReturnSession.companyId;

            this.getTripCreationForArticlesStocksDetailsList(this.lrDtoStocksDetails);
        }
    }

    getTripCreationForArticlesStocksDetailsList(lrDtoStocksDetails) {
        this.showSpinnerForAction = true;
        this.stockService.getArticlesStocksForLocalBooking(lrDtoStocksDetails).subscribe(
            (response) => {
                console.log(response);
                this.stockDetailsDataList = [];
                $("#" + this.pageId + "localBookingForStockDetailsTableId").DataTable().destroy();
                this.stockDetailsDataList = response;
                setTimeout(() => {
                    this.dtTriggerStockDetails.next();
                }, 1000);

                if (response.length == 0) {
                    this.tripSheetBlackBtnHideShow = false;
                } else if (this.lrDtoStocksDetails.stationType != null &&
                    ((this.lrDtoStocksDetails.stationType != "")) &&
                    (this.lrDtoStocksDetails.stationType == "local")) {
                    this.tripSheetBlackBtnHideShow = true;
                } else if (this.lrDtoStocksDetails.stationType != null &&
                    ((this.lrDtoStocksDetails.stationType != "")) &&
                    (this.lrDtoStocksDetails.stationType == "Others")) {
                    this.tripSheetBlackBtnHideShow = true;
                } else if (this.lrDtoStocksDetails.stationType != null &&
                    ((this.lrDtoStocksDetails.stationType != "")) &&
                    (this.lrDtoStocksDetails.stationType == "UnDelivered")) {
                    this.tripSheetBlackBtnHideShow = true;
                } else {
                    this.tripSheetBlackBtnHideShow = true;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  Stock Details Details", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    };

    tripSheetBlackBtnClickListner() {
        console.log(this.tripSheetBtnId);
        if (this.tripSheetBtnId != null && this.tripSheetBtnId == "Others") {
            swal("Trips For Other", "Can't generate Trips for UnAssigned Lrs", "warning");
            return false;
        } else {
            // this.tripSheetBtnHideShow = true;
            // this.tripSheetBlackBtnHideShow = false;
            // swal("OK", "Please Click The Trip Sheet button again to continue", "info");
            this.tripSheetBtnClickListner();
        }
        this.changeDetectorRef.detectChanges();
    }

    tripSheetBtnClickListner() {
        console.log(this.tripSheetBtnId);
        /* 
        if (this.showDeliveryMan != null && this.showDeliveryMan == true) {
        */
        if (this.userDataDtoReturnSession.office != null &&
            this.userDataDtoReturnSession.office == "Binnymills") {
            this.lrDtoPassTripSchedulePage = new LRDto();
            this.lrDtoPassTripSchedulePage.destination = this.tripSheetBtnId;
            this.lrDtoPassTripSchedulePage.mode = "localgrouping";
            this.lrDtoPassTripSchedulePage.invoiceNumber = null;
            this.lrDtoPassTripSchedulePage.tripId = 0;
        } else {
            this.lrDtoPassTripSchedulePage = new LRDto();
            this.lrDtoPassTripSchedulePage.destination = this.tripSheetBtnId;
            this.lrDtoPassTripSchedulePage.mode = "localgrouping";
            this.lrDtoPassTripSchedulePage.invoiceNumber = null;
            this.lrDtoPassTripSchedulePage.module = "booking";
            this.lrDtoPassTripSchedulePage.selectedTripTo = this.tripSheetBtnId;
            this.lrDtoPassTripSchedulePage.tripId = 0;
            this.lrDtoPassTripSchedulePage.localTripStation = this.dummyTripSheetBtnId;
        }

        this.clearFieldForStockTripSheetSchedule();
        setTimeout(() => {
            // $("#"+this.pageId+"dropDownInputFieldBins").val(this.tripSheetBtnId);
            this.getMethodForBranchArea();
        }, 2000);

        /*setTimeout(() => {
            $("#"+this.pageId+"dropDownInputFieldBins").val(this.tripSheetBtnId);
            this.setControlBinsDestinationDetails();
        }, 2000);*/
        // this.changeDetectorRef.detectChanges();
    }
    clearFieldForStockTripSheetSchedule() {
        /* 2 nd stock for trip sheet level - start */
        this.viewNextDarkBtnForScheduleTrip = false;
        this.viewNextGreenBtnForScheduleTrip = false;
        this.remainingLrsDataList = [];
        this.eligibleLrsDataList = [];
        $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
        $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
        this.dtTriggerEligibleLrs.next();
        this.dtTriggerRemainingLrs.next();

        this.modelDropDownBins = "";
        $("#" + this.pageId + "lrNumber").val('');
        /* 2 nd stock for trip sheet level - end */
    }

    /* Main 1 st stock for trip sheet level - end*/

    /* Main 2 nd stock for trip sheet level - start*/

    onKeyPressListnerForLRNumber($event) {
        if ($event.charCode == 13) {
            console.log($event.charCode);
            if ($("#" + this.pageId + "lrNumber").val() == null || $("#" + this.pageId + "lrNumber").val() == undefined ||
                $("#" + this.pageId + "lrNumber").val() == "") {
                swal("LR Number", "Please Enter The LR Number Field", "warning");
                return false;
            } else {
                this.setLRNumberDetailsValueInDatatTable();
            }

        }
    }

    onKeyPressListnerForBinsDestination($event) {
        if ($event.charCode == 13) {
            this.setControlBinsDestinationDetails();
        }
    }

    clickListnerForDropDownInputFieldBins(event) {
        this.modelDropDownBins = event.item;
        $("#" + this.pageId + "dropDownInputFieldBins").val(this.modelDropDownBins.godownName);
        console.log(this.modelDropDownBins);
        this.setControlBinsDestinationDetails();
    }

    /*getMethodForBranchArea() {
        this.lrDtoBranchBins = new LRDto();
        this.lrDtoBranchBins.mode = this.userDataDtoReturnSession.office;
        this.lrDtoBranchBins.companyId = this.userDataDtoReturnSession.companyId;
    }*/

    getMethodForBranchArea() {
        this.lrDtoBranchBins = new LRDto();
        this.lrDtoBranchBins.mode = this.userDataDtoReturnSession.office;
        this.lrDtoBranchBins.companyId = this.userDataDtoReturnSession.companyId;

        if (this.userDataDtoReturnSession.isOpenTrip != null &&
            this.userDataDtoReturnSession.isOpenTrip) {
            this.setBranchAreaDetailsList();
        } else if (this.lrDtoPassTripSchedulePage.module != null &&
            this.lrDtoPassTripSchedulePage.module == "booking") {
            this.lrDtoSelectedBranchBins = new LRDto();
            this.lrDtoSelectedBranchBins.godownName = this.lrDtoPassTripSchedulePage.localTripStation;
            this.branchBinsTA.push(this.lrDtoSelectedBranchBins);
            $("#" + this.pageId + "dropDownInputFieldBins").val(this.lrDtoStocksDetails.destination);
            this.setControlBinsDestinationDetails();
        } else {
            this.setBranchAreaDetailsList();
        }
    }

    setBranchAreaDetailsList() {
        //this.getMethodForBranchArea();
        this.showSpinnerForTripScheduleAction = true;
        this.masterReadService.getBranchAreaDetailsService(this.lrDtoBranchBins).subscribe(
            (response) => {
                console.log("BINS");
                console.log(response);
                if (response.length > 0) {
                    this.branchBinsOption = [];
                    this.branchBinsTA = [];
                    this.branchBinsOption = response;
                    if (this.localStorageOfStockGrouping != null &&
                        this.localStorageOfStockGrouping != undefined &&
                        this.localStorageOfStockGrouping == "Stock Grouping Page") {
                        for (let i = 0; i < this.branchBinsOption.length; i++) {
                            this.branchBinsTA.push(this.branchBinsOption[i]);
                        }
                    } else {
                        for (let i = 0; i < this.branchBinsOption.length; i++) {
                            //if (this.branchBinsOption[i].godownName == this.lrDtoPassTripSchedulePage.destination) {
                            this.branchBinsTA.push(this.branchBinsOption[i]);
                            //}
                        }
                    }
                    console.log(this.branchBinsTA);
                    //hashmap concept pending here
                    this.showSpinnerForTripScheduleAction = false;
                } else {
                    this.branchBinsOption = [];
                    this.branchBinsTA = [];
                    this.showSpinnerForTripScheduleAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripScheduleAction = false;
                swal("Server Error", "Problem occur while getting Area Details", "error");
            },
            () => console.log('done');
    };

    setControlBinsDestinationDetails() {
        this.lrDtoControlBinService = new LRDto();
        this.lrDtoForScheduleTripsheetReturn = new LRDto();
        this.lrDtoForScheduleTripsheetReturn = this.lrDtoPassTripSchedulePage;

        console.log(this.lrDtoForScheduleTripsheetReturn);
        this.enteredStockBinsDestination = $("#" + this.pageId + "dropDownInputFieldBins").val();
        this.lrDtoControlBinService.destination = this.enteredStockBinsDestination;
        this.lrDtoControlBinService.branch = this.userDataDtoReturnSession.office;
        this.lrDtoControlBinService.branchType = this.userDataDtoReturnSession.role;
        this.lrDtoControlBinService.stationType = "local";
        this.lrDtoControlBinService.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoControlBinService.mode = "all";

        if (this.userDataDtoReturnSession.isOpenTrip != null &&
            this.userDataDtoReturnSession.isOpenTrip) {
            this.getSelectedBinLrsOpenTrip();
        } else {
            this.getSelectedBinLrs();
        }
    };

    getSelectedBinLrsOpenTrip() {
        this.showSpinnerForTripScheduleAction = true;
        this.stockService.getBookingStocksForLocalOpenTrip(this.lrDtoControlBinService).subscribe(
            (response) => {
                console.log(response);
                this.selectedControBinsDataOfAllLrsList = [];
                for (let key in response) {
                    if (key == "AllLRs") {
                        this.selectedControBinsDataOfAllLrsList = response[key];
                    }
                }
                this.remainingLrsDataList = [];
                //this.eligibleLrsDataList = [];
                if (this.selectedControBinsDataOfAllLrsList != null &&
                    this.selectedControBinsDataOfAllLrsList.length > 0) {
                    this.remainingLrsDataList = this.selectedControBinsDataOfAllLrsList;
                }
                console.log(this.remainingLrsDataList);
                console.log(this.eligibleLrsDataList);
                this.remainingLrsDataList = this.remainingLrsDataList.filter(remainingLrsData =>
                    !this.eligibleLrsDataList.find(eligibleLrsData =>
                        (eligibleLrsData.lrNumber === remainingLrsData.lrNumber)));

                console.log(this.remainingLrsDataList);
                $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
                this.dtTriggerRemainingLrs.next();
                this.showSpinnerForTripScheduleAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForTripScheduleAction = false;
                swal("Server Error", "Problem occur while getting  Stock For Local OpenTrip", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }

    getSelectedBinLrs() {
        this.showSpinnerForTripScheduleAction = true;
        this.stockService.getBookingStocksForLocal(this.lrDtoControlBinService).subscribe(
            (response) => {
                console.log(response);
                this.selectedControBinsDataOfAllLrsList = [];
                for (let key in response) {
                    if (key == "AllLRs") {
                        this.selectedControBinsDataOfAllLrsList = response[key];
                    }
                }
                this.remainingLrsDataList = [];
                if (this.selectedControBinsDataOfAllLrsList != null &&
                    this.selectedControBinsDataOfAllLrsList.length > 0) {
                    this.remainingLrsDataList = this.selectedControBinsDataOfAllLrsList;
                }
                console.log(this.remainingLrsDataList);
                console.log(this.eligibleLrsDataList);
                this.remainingLrsDataList = this.remainingLrsDataList.filter(remainingLrsData =>
                    !this.eligibleLrsDataList.find(eligibleLrsData =>
                        (eligibleLrsData.lrNumber === remainingLrsData.lrNumber)));

                console.log(this.remainingLrsDataList);
                $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
                this.dtTriggerRemainingLrs.next();
                this.showSpinnerForTripScheduleAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForTripScheduleAction = false;
                swal("Server Error", "Problem occur while getting  Stock For Local", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }

    setLRNumberDetailsValueInDatatTable() {
        this.enteredLrNumber = null;
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

        //Chg_V1
        this.prefixLrNumber = $("#" + this.pageId + "lrNumberPrefix").val();
        this.enteredLrNumber = this.prefixLrNumber + this.enteredLrNumber;
        console.log(this.enteredLrNumber);

        // finding eligible & remaing lrs method
        this.showSpinnerForTripScheduleAction = true;
        var validateRemaingLrNumber = this.findRemainingLrsDup(this.remainingLrsDataList, this.enteredLrNumber);
        var validateEligibleLrNumber = this.findEligibleLrs(this.eligibleLrsDataList, this.enteredLrNumber);
        this.showSpinnerForTripScheduleAction = false;
        console.log("EligibleFind: " + validateEligibleLrNumber);
        console.log("RemaingFind: " + validateRemaingLrNumber);
        if (validateRemaingLrNumber == null && validateEligibleLrNumber == null) {
            swal("Alert", "LR number does not exist", "warning");
            $("#" + this.pageId + "lrNumber").focus();
        } else if (validateEligibleLrNumber != null) {
            swal("Make Invoice", "LR Number '" + $("#" + this.pageId + "lrNumber").val() + "'Already Transfered", "warning");
            $("#" + this.pageId + "lrNumber").focus();
        } else {
            this.setLrNumberEligibleAndRemaingLrs();
            $("#" + this.pageId + "lrNumber").val('');
            $("#" + this.pageId + "lrNumber").focus();
        }
    }

    findRemainingLrsDup(findRemaingLrs, lrNumber) {
        var checkingRemLrNumber = null;
        var remainLrNumberReturn = null;
        for (let i = 0; i < findRemaingLrs.length; i++) {
            checkingRemLrNumber = findRemaingLrs[i].lrNumber;
            if (checkingRemLrNumber == lrNumber) {
                remainLrNumberReturn = checkingRemLrNumber;
                break;
            } else {
                remainLrNumberReturn = null;
            }
        }
        return remainLrNumberReturn;
    }

    findEligibleLrs(findEligibleLrs, lrNumber) {
        var checkingEligLrNumber = null;
        var eligibleLrNumberReturn = null;
        for (let i = 0; i < findEligibleLrs.length; i++) {
            checkingEligLrNumber = findEligibleLrs[i].lrNumber;
            if (checkingEligLrNumber == lrNumber) {
                eligibleLrNumberReturn = checkingEligLrNumber;
                break;
            } else {
                eligibleLrNumberReturn = null;
            }
        }
        return eligibleLrNumberReturn;
    }

    setLrNumberEligibleAndRemaingLrs() {
        this.resultList = null;
        this.enteredLrNumber = null;
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

        //Chg_V1
        this.prefixLrNumber = $("#" + this.pageId + "lrNumberPrefix").val();
        this.enteredLrNumber = this.prefixLrNumber + this.enteredLrNumber;

        this.showSpinnerForTripScheduleAction = true;
        this.resultList = this.remainingLrsDataList.filter(remainingLrsData =>
            remainingLrsData.lrNumber == this.enteredLrNumber);
        if (this.resultList.length > 0) {
            for (let i = 0; i < this.resultList.length; i++) {
                this.eligibleLrsDataList.push(this.resultList[i]);
            }
            $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerEligibleLrs.next();
            }, 1000);

            const deletedContrat = this.remainingLrsDataList.find(x => x.lrNumber === this.enteredLrNumber);
            this.remainingLrsDataList.splice(this.remainingLrsDataList.indexOf(deletedContrat), 1);
            $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerRemainingLrs.next();
            }, 1000);
            this.viewNextDarkBtnForScheduleTrip = true;
            // console.log(deletedContrat);
        }
        this.showSpinnerForTripScheduleAction = false;
    }
    validateMoveAllBtnForScheduleTrip() {
        console.log(this.remainingLrsDataList);
        console.log(this.remainingLrsDataList.length);
        this.showSpinnerForTripScheduleAction = true;
        for (let i = 0; i < this.remainingLrsDataList.length; i++) {
            this.eligibleLrsDataList.push(this.remainingLrsDataList[i]);
        }
        $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
        setTimeout(() => {
            this.dtTriggerEligibleLrs.next();
        }, 1000);
        this.remainingLrsDataList = [];
        $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
        setTimeout(() => {
            this.dtTriggerRemainingLrs.next();
        }, 1000);
        this.showSpinnerForTripScheduleAction = false;
        this.viewNextDarkBtnForScheduleTrip = true;
    }

    rowSelectedEligibleLrDelete(selectedEligibleLrsRowData) {
        this.enteredLrNumber = null;
        this.enteredLrNumber = selectedEligibleLrsRowData.lrNumber;
        this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

        // finding eligible & remaing lrs method
        this.showSpinnerForTripScheduleAction = true;
        var validateRemaingLrNumber = this.findRemainingLrsDup(this.remainingLrsDataList, this.enteredLrNumber);
        var validateEligibleLrNumber = this.findEligibleLrs(this.eligibleLrsDataList, this.enteredLrNumber);
        this.showSpinnerForTripScheduleAction = false;
        console.log("EligibleFind: " + validateEligibleLrNumber);
        console.log("RemaingFind: " + validateRemaingLrNumber);
        if (validateRemaingLrNumber == null && validateEligibleLrNumber == null) {
            swal("Alert", "LR number does not exist", "warning");
        } else if (validateRemaingLrNumber != null) {
            swal("Make Invoice", "LR Number '" + selectedEligibleLrsRowData.lrNumber + "'Already Transfered", "warning");
        } else {
            this.resultList = null;
            this.showSpinnerForTripScheduleAction = true;
            this.resultList = this.eligibleLrsDataList.filter(eligibleLrsData =>
                eligibleLrsData.lrNumber == selectedEligibleLrsRowData.lrNumber);
            if (this.resultList.length > 0) {
                for (let i = 0; i < this.resultList.length; i++) {
                    this.remainingLrsDataList.push(this.resultList[i]);
                }
                $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerRemainingLrs.next();
                }, 1000);

                const deletedContrat = this.eligibleLrsDataList.find(x => x.lrNumber === selectedEligibleLrsRowData.lrNumber);
                this.eligibleLrsDataList.splice(this.eligibleLrsDataList.indexOf(deletedContrat), 1);
                $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerEligibleLrs.next();
                }, 1000);
            }
            this.showSpinnerForTripScheduleAction = false;
        }
        if (this.eligibleLrsDataList.length == 0) {
            this.viewNextDarkBtnForScheduleTrip = false;
        }
    }

    stockTripClearBtn() {
        this.remainingLrsDataList = [];
        this.eligibleLrsDataList = [];
        $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
        $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
        this.dtTriggerEligibleLrs.next();
        this.dtTriggerRemainingLrs.next();

        this.modelDropDownBins = null;
        $("#" + this.pageId + "lrNumber").val('');

        this.lrDtoForScheduleTripsheetReturn = new LRDto();
    }
    backBtnForStockTripSheet() {
        /*1nd stock for trip sheet level - end */
        this.stockDetailsDataList = [];
        $("#" + this.pageId + "localBookingForStockDetailsTableId").DataTable().destroy();
        this.dtTriggerStockDetails.next();
        this.viewBinsStockMsg = "Bins Stock";
        this.viewLocalTripsheetMsg = "";

        this.tripSheetBlackBtnHideShow = false;
        this.tripSheetBtnHideShow = false;

        this.tripSheetBtnId = null;
        this.dummyTripSheetBtnId = null;
        /*1nd stock for trip sheet level - end */
    }
    resetBtnTripSheduleFrom() {
        swal({
            title: "Reset Confirm",
            text: "Sure U want to reset this Trip details",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.stepper.reset();
                this.clearAllField();
                this.setTripCrationForArticlesStocksSummaryDetailsList();
            }
        });

    }

    validateNextBtnForScheduleTrip() {
        if (this.eligibleLrsDataList == null || this.eligibleLrsDataList == undefined ||
            this.eligibleLrsDataList == "" || this.eligibleLrsDataList.length == 0) {
            swal("Not Allowed", "No Lrs Found", "warning");
            return false;
        } else {
            // this.viewNextDarkBtnForScheduleTrip = false;
            // this.viewNextGreenBtnForScheduleTrip = true;
            // swal("OK", "Please Click The Next button again to continue", "info");
            this.scheduleForNextTripBtn();
        }
        this.changeDetectorRef.detectChanges();
    }

    scheduleForNextTripBtn() {
        this.lrDtoCreateATripDetail = new LRDto();
        this.lrDtoCreateATripDetail.lrDtoEligible = this.eligibleLrsDataList;
        /*3 rd stock for trip sheet level - start */
        this.viewScheduleTripBtn = false;
        if (this.userDataDtoReturnSession.mainStation == "Chennai") {
            this.viewScheduleTripBtn = true;
        }
        /*3 rd stock for trip sheet level - end */
    }

    /* Main 2 nd stock for trip sheet level - end */

    /* Main 3 rd stock for trip sheet level - start */

    clickListnerForDropDownInputFieldVehicleNumber(event, vehicleNumberPopUpTemplate) {
        this.modelDropDownVehicleNumber = event.item;
        $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val(this.modelDropDownVehicleNumber.truckNumber);
        if ($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == "Add New") {
            this.vehicleNumberPopUpBtnLink(vehicleNumberPopUpTemplate);
        }
        console.log(this.modelDropDownVehicleNumber);
    }

    getVehicleNumberDetails() {
        this.truckDataDtoVehicleNumber = new TruckDataDto();
        this.truckDataDtoVehicleNumber.office = this.userDataDtoReturnSession.mainStation;
        this.truckDataDtoVehicleNumber.isActive = true;
        this.truckDataDtoVehicleNumber.companyId = this.userDataDtoReturnSession.companyId;
    }

    setVehicleDetailsList() {
        this.getVehicleNumberDetails();
        this.showSpinnerForTripDetailsAction = true;
        this.masterReadService.getVehicleSetupMaster(this.truckDataDtoVehicleNumber).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.vehicleNumberOption = [];
                    this.vehicleNumberTA = [];
                    this.vehicleNumberOption = response;
                    this.truckDataDtoAddNewVehicleNumber = new TruckDataDto();
                    this.truckDataDtoAddNewVehicleNumber.truckNumber = 'Add New';
                    this.vehicleNumberTA.push(this.truckDataDtoAddNewVehicleNumber);
                    for (let i = 0; i < this.vehicleNumberOption.length; i++) {
                        this.vehicleNumberTA.push(this.vehicleNumberOption[i]);
                        this.hashmapVehicle.set(this.vehicleNumberOption[i].truckNumber,
                            this.vehicleNumberOption[i].vehicleName);
                    }
                    //hashmap concept pending here
                    this.showSpinnerForTripDetailsAction = false;
                } else {
                    this.vehicleNumberOption = [];
                    this.vehicleNumberTA = [];
                    this.truckDataDtoAddNewVehicleNumber = new TruckDataDto();
                    this.truckDataDtoAddNewVehicleNumber.truckNumber = 'Add New';
                    this.vehicleNumberTA.push(this.truckDataDtoAddNewVehicleNumber);
                    this.showSpinnerForTripDetailsAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while getting Vehicle Setup Master", "error");
            },
            () => console.log('done');
    };

    clickListnerForDropDownInputFieldDriverName(event) {
        this.modelDropDownDriverName = event.item;
        $("#" + this.pageId + "dropDownInputFieldDriverName").val(this.modelDropDownDriverName.driverName);
        console.log(this.modelDropDownDriverName);
        $("#" + this.pageId + "inputFieldDriverConatctNumber").val(this.modelDropDownDriverName.mobileNo == null ? null :
            this.modelDropDownDriverName.mobileNo == undefined ? null :
                this.modelDropDownDriverName.mobileNo);


    }

    getDriverNameDetails() {
        this.driverDataDtoDriverName = new DriverdetailsDto();
        this.driverDataDtoDriverName.companyId = this.userDataDtoReturnSession.companyId;
        this.driverDataDtoDriverName.status = "Working";
        if (this.userDataDtoReturnSession.mainAdminStation != null) {
            this.driverDataDtoDriverName.mode = "MainStation";
            this.driverDataDtoDriverName.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        } else {
            this.driverDataDtoDriverName.mode = "Station";
            this.driverDataDtoDriverName.mainStation = this.userDataDtoReturnSession.office;

        }
    }

    setDriverNameDetailsList() {
        this.getDriverNameDetails();
        this.showSpinnerForTripDetailsAction = true;
        this.masterReadService.getDriver(this.driverDataDtoDriverName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.driverNameOption = [];
                    this.driverNameTA = [];
                    this.driverNameOption = response;
                    for (let i = 0; i < this.driverNameOption.length; i++) {
                        this.driverNameTA.push(this.driverNameOption[i]);
                    }
                    this.showSpinnerForTripDetailsAction = false;
                } else {
                    this.driverNameOption = [];
                    this.driverNameTA = [];
                    this.showSpinnerForTripDetailsAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while getting Vehicle Setup Master", "error");
            },
            () => console.log('done');
    };

    onKeyPressListnerForDropDownLabourName(event) {
        if (event.charCode == 13) {
            this.enteredlabourNameListOfHamaliAdd = $("#" + this.pageId + "dropDownInputFieldLabourName").val();
            //$("#"+this.pageId+"hamalis").focus();
            if ($("#" + this.pageId + "hamalis").val() != null &&
                $("#" + this.pageId + "hamalis").val() != undefined &&
                $("#" + this.pageId + "hamalis").val() != "") {
                this.enteredlabourNameListOfHamaliAdd = $("#" + this.pageId + "hamalis").val() + "," +
                    this.enteredlabourNameListOfHamaliAdd;
            }
            $("#" + this.pageId + "hamalis").val(this.enteredlabourNameListOfHamaliAdd == null ? null :
                this.enteredlabourNameListOfHamaliAdd == undefined ? null :
                    this.enteredlabourNameListOfHamaliAdd);
            this.modelDropDownLabourName = null;
            $("#" + this.pageId + "dropDownInputFieldLabourName").val('');
        }

    }
    clickListnerForDropDownInputFieldLabourName(event) {
        this.modelDropDownLabourName = event.item;
        $("#" + this.pageId + "dropDownInputFieldLabourName").val(this.modelDropDownLabourName.loaderName);
        this.enteredlabourNameListOfHamaliAdd = $("#" + this.pageId + "dropDownInputFieldLabourName").val();
        //$("#"+this.pageId+"hamalis").focus();
        if ($("#" + this.pageId + "hamalis").val() != null &&
            $("#" + this.pageId + "hamalis").val() != undefined &&
            $("#" + this.pageId + "hamalis").val() != "") {
            this.enteredlabourNameListOfHamaliAdd = $("#" + this.pageId + "hamalis").val() + "," +
                this.enteredlabourNameListOfHamaliAdd;
        }
        $("#" + this.pageId + "hamalis").val(this.enteredlabourNameListOfHamaliAdd == null ? null :
            this.enteredlabourNameListOfHamaliAdd == undefined ? null :
                this.enteredlabourNameListOfHamaliAdd);
        this.modelDropDownLabourName = null;
        $("#" + this.pageId + "dropDownInputFieldLabourName").val('');
    }

    getLabourNameDetails() {
        this.lrDtoLabourName = new LRDto();
        this.lrDtoLabourName.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoLabourName.companyId = this.userDataDtoReturnSession.companyId;
    }

    setLabourNameDetailsList() {
        this.getLabourNameDetails();
        this.showSpinnerForTripDetailsAction = true;
        this.masterReadService.getLabourDetails(this.lrDtoLabourName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.labourNameOption = [];
                    this.labourNameTA = [];
                    this.labourNameOption = response;
                    for (let i = 0; i < this.labourNameOption.length; i++) {
                        this.labourNameTA.push(this.labourNameOption[i]);
                    }
                    this.showSpinnerForTripDetailsAction = false;
                } else {
                    this.labourNameOption = [];
                    this.labourNameTA = [];
                    this.showSpinnerForTripDetailsAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while getting Vehicle Setup Master", "error");
            },
            () => console.log('done');
    };

    onKeyPressListnerForDropDownLabourHead(event) {
        if (event.charCode == 13) {
            this.enteredlabourHeadNameListOfHamaliAdd = $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();
            //$("#"+this.pageId+"hamalis").focus();
            if ($("#" + this.pageId + "hamalis").val() != null &&
                $("#" + this.pageId + "hamalis").val() != undefined &&
                $("#" + this.pageId + "hamalis").val() != "") {
                this.enteredlabourHeadNameListOfHamaliAdd = $("#" + this.pageId + "hamalis").val() + "," +
                    this.enteredlabourHeadNameListOfHamaliAdd;
            }
            $("#" + this.pageId + "hamalis").val(this.enteredlabourHeadNameListOfHamaliAdd == null ? null :
                this.enteredlabourHeadNameListOfHamaliAdd == undefined ? null :
                    this.enteredlabourHeadNameListOfHamaliAdd);
            this.modelDropDownLabourHeadName = null;
            $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val('');
        }
    }
    clickListnerForDropDownInputFieldLabourHeadName(event) {
        this.modelDropDownLabourHeadName = event.item;
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val(this.modelDropDownLabourHeadName.loaderHead);

        this.enteredlabourHeadNameListOfHamaliAdd = $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();
        //$("#"+this.pageId+"hamalis").focus();
        if ($("#" + this.pageId + "hamalis").val() != null &&
            $("#" + this.pageId + "hamalis").val() != undefined &&
            $("#" + this.pageId + "hamalis").val() != "") {
            this.enteredlabourHeadNameListOfHamaliAdd = $("#" + this.pageId + "hamalis").val() + "," +
                this.enteredlabourHeadNameListOfHamaliAdd;
        }
        $("#" + this.pageId + "hamalis").val(this.enteredlabourHeadNameListOfHamaliAdd == null ? null :
            this.enteredlabourHeadNameListOfHamaliAdd == undefined ? null :
                this.enteredlabourHeadNameListOfHamaliAdd);
        this.modelDropDownLabourHeadName = null;
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val('');
    }

    getLabourHeadNameDetails() {
        this.hireSlipDtoLabourHeadName = new HireSlipDto();
        this.hireSlipDtoLabourHeadName.branch = this.userDataDtoReturnSession.mainStation;
        this.hireSlipDtoLabourHeadName.companyid = this.userDataDtoReturnSession.companyId;
    }

    setLabourHeadNameDetailsList() {
        this.getLabourHeadNameDetails();
        this.showSpinnerForTripDetailsAction = true;
        this.masterReadService.getLoaderHeadsDetails(this.hireSlipDtoLabourHeadName).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                    this.labourHeadNameOption = response;
                    for (let i = 0; i < this.labourHeadNameOption.length; i++) {
                        this.labourHeadNameTA.push(this.labourHeadNameOption[i]);
                    }
                    this.showSpinnerForTripDetailsAction = false;
                } else {
                    this.labourHeadNameOption = [];
                    this.labourHeadNameTA = [];
                    this.showSpinnerForTripDetailsAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while getting Loader Heads details", "error");
            },
            () => console.log('done');
    };

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    vehicleNumberPopUpBtnLink(vehicleNumberPopUpTemplate) {
        this.modalRefferenceVehicleNumberPopUp = this.modalService.open(vehicleNumberPopUpTemplate,
            { centered: true, size: "sm" });
        this.modalRefferenceVehicleNumberPopUp.result.then((result) => {
            this.modalRefferenceVehicleNumberClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceVehicleNumberClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    closePopUpVehiclePopup() {
        $("#" + this.pageId + "addNewVehicleNumberId").val('');
        $("#" + this.pageId + "addNewVehicleNameId").val('');
        $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val('');
        this.modelDropDownVehicleNumber = null;
        this.modalRefferenceVehicleNumberPopUp.close();
    }

    resetBtnVehicleNumberPopup() {
        $("#" + this.pageId + "addNewVehicleNumberId").val('');
        $("#" + this.pageId + "addNewVehicleNameId").val('');
        $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val('');
        this.modelDropDownVehicleNumber = null;
    }

    saveBtnVehicleNumberPopup() {

        if ($("#" + this.pageId + "addNewVehicleNumberId").val() == null || $("#" + this.pageId + "addNewVehicleNumberId").val() == undefined ||
            $("#" + this.pageId + "addNewVehicleNumberId").val() == "" || $("#" + this.pageId + "addNewVehicleNameId").val() == null ||
            $("#" + this.pageId + "addNewVehicleNameId").val() == undefined || $("#" + this.pageId + "addNewVehicleNameId").val() == "") {
            swal("Mandatory Fields", "Please enter the mandatory mields", "warning");
            return false;
        } else {
            this.truckDataDtoNewVehicleNumberSave = new TruckDataDto();
            this.enteredPopUpNewVehicleNumber = $("#" + this.pageId + "addNewVehicleNumberId").val();
            this.eneterdPopUpNewVehicleName = $("#" + this.pageId + "addNewVehicleNameId").val();
            this.truckDataDtoNewVehicleNumberSave.truckNumber = this.enteredPopUpNewVehicleNumber;
            this.truckDataDtoNewVehicleNumberSave.vehicleName = this.eneterdPopUpNewVehicleName;
            this.truckDataDtoNewVehicleNumberSave.office = this.userDataDtoReturnSession.office;
            this.truckDataDtoNewVehicleNumberSave.mainStation = this.userDataDtoReturnSession.mainStation;
            this.truckDataDtoNewVehicleNumberSave.companyId = this.userDataDtoReturnSession.companyId;

            this.masterService.createVehicle(this.truckDataDtoNewVehicleNumberSave).subscribe(
                (response) => {
                    console.log(response);
                    this.truckDataDtoNewVehicleNumberSaveReturn = null;
                    this.truckDataDtoNewVehicleNumberSaveReturn = response;
                    if (this.truckDataDtoNewVehicleNumberSaveReturn.status == "persisted") {
                        $("#" + this.pageId + "addNewVehicleNumberId").val('');
                        $("#" + this.pageId + "addNewVehicleNameId").val('');
                        $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val('');
                        this.modelDropDownVehicleNumber = null;
                        this.modalRefferenceVehicleNumberPopUp.close();
                        this.setVehicleDetailsList();
                        swal("Sucessfully Added", "Vehicle Details Added Sucessfully", "success");
                        //this.showSpinnerForAction = false;
                    } else {
                        swal("Failure", "Failed to Create Vehicle Details", "error");
                        //this.showSpinnerForAction = false;
                    }
                    this.changeDetectorRef.detectChanges();
                }), (error) => {
                    //this.showSpinnerForAction = false;
                    swal("Server Error", "Problem occur while create Vehicle Setup ", "error");
                },
                () => console.log('done');
        }
    }

    validateCreateTripSheet(status) {
        //Chg_V2
        this.enteredLorryHire = parseFloat($("#" + this.pageId + "lorryHire").val().toString() || '0');
        this.enteredAdvance = parseFloat($("#" + this.pageId + "advance").val().toString() || '0');

        if (($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == "") ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == "")) {
            swal("Mandatory Field", "Vehicle Number & Driver Name is Mandatory Field", "warning");
            return false;
        }
        //Chg_V2
        else if ((this.enteredAdvance != null && this.enteredAdvance > 0) && (this.enteredAdvance > this.enteredLorryHire || this.enteredLorryHire === 0)) {
            swal({
                title: "Not Allowed",
                text: "Advance amount must be equal or lesser than Lorry Hire Amount!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.enteredVehicleNumber = $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val();
            this.enteredVehicleNumber = this.enteredVehicleNumber.replace(/\s/g, "");
            this.enteredVehicleNumber = this.enteredVehicleNumber.replace("-", "");
            this.enteredVehicleNumber = this.enteredVehicleNumber.replace(".", "");
            this.enteredVehicleNumber = this.enteredVehicleNumber.replace(",", "");
            console.log(this.enteredVehicleNumber);
            if (this.enteredVehicleNumber.length < 8 || this.enteredVehicleNumber.length > 11) {
                swal("Invalid Vehicle Number", "Selected Vehicle Number is invalid, please verify it ", "warning");
            } else {
                var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
                if (format.test(this.enteredVehicleNumber)) {
                    swal("Invalid Vehicle Number", "Selected Vehicle number is not valid, please verify it ", "warning");
                } else {
                    console.log('Valid Vehicle Number');
                    var msgText='';
                    if(status=='Scheduled'){
                        msgText='Sure you want to schedule this trip';
                    }else{
                        msgText='Sure U want to Create this Trip';
                    }
                    swal({
                        title: "Trip Sheet",
                        text: msgText,
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((sureYesBtn) => {
                        if (sureYesBtn) {
                            this.lrDtoSetTripDetailsReturn = new LRDto();
                            //Chg_V3
                            // this.lrDtoSetTripDetailsReturn = this.getTripDetails("Pending");
                            this.lrDtoSetTripDetailsReturn = this.getTripDetails(status);
                            console.log(this.lrDtoPassTripSchedulePage.tripId);
                            // its value cmg from 1 page when trip create  1 st level
                            if (this.lrDtoPassTripSchedulePage.tripId != null &&
                                this.lrDtoPassTripSchedulePage.tripId > 0) {
                                this.lrDtoSetTripDetailsReturn.tripId = this.lrDtoPassTripSchedulePage.tripId;
                                this.departUpdatedTrip(this.lrDtoSetTripDetailsReturn);
                            } else {
                                this.departNewTrip(this.lrDtoSetTripDetailsReturn);
                            }
                        }
                    })
                }
            }
        }
    }

    getTripDetails(status) {
        console.log(this.lrDtoCreateATripDetail);
        this.lrDtoSetTripDetails = new LRDto();
        this.listOfLrs = [];
        this.validateTotArticles = 0;
        this.validateTotAmt = 0.0;
        this.validateActWgt = 0.0;
        this.validateChgWgt = 0.0;
        this.validateDdAmt = 0.0;

        this.enteredVehicleNumber = null;
        this.enteredDriverName = null;
        this.enteredDriverMobileNo = null;
        this.enteredLabourHamaliName = null;
        this.enteredLoaderHeadName = null;

        for (let i = 0; i < this.lrDtoCreateATripDetail.lrDtoEligible.length; i++) {
            this.listOfLrs.push(this.lrDtoCreateATripDetail.lrDtoEligible[i].lrNumber);

            this.validateTotArticles = +this.validateTotArticles + +this.lrDtoCreateATripDetail.lrDtoEligible[i].totalArticles;
            this.validateTotAmt = +this.validateTotAmt + +this.lrDtoCreateATripDetail.lrDtoEligible[i].totalAmount;
            this.validateActWgt = +this.validateActWgt + +this.lrDtoCreateATripDetail.lrDtoEligible[i].actualWeight;
            this.validateChgWgt = +this.validateChgWgt + +this.lrDtoCreateATripDetail.lrDtoEligible[i].chargedWeight;
            // it is only for destination 
            //this.validateDdAmt = +this.validateDdAmt + +this.lrDtoCreateATripDetail.lrDtoEligible[i].memoHamaliDD;
        }
        this.enteredVehicleNumber = $("#" + this.pageId + "dropDownInputFieldVehicleNumber").val();
        this.enteredDriverName = $("#" + this.pageId + "dropDownInputFieldDriverName").val();
        this.enteredDriverMobileNo = $("#" + this.pageId + "inputFieldDriverConatctNumber").val();
        this.enteredLabourHamaliName = $("#" + this.pageId + "hamalis").val();
        //Chg_V2
        this.enteredLorryHire = $("#" + this.pageId + "lorryHire").val();
        this.enteredAdvance = $("#" + this.pageId + "advance").val();
        this.balanceAmt = $("#" + this.pageId + "balance").val();

        this.lrDtoSetTripDetails.list = this.listOfLrs;
        this.lrDtoSetTripDetails.totalArticles = this.validateTotArticles;
        this.lrDtoSetTripDetails.totalAmount = this.validateTotAmt;
        this.lrDtoSetTripDetails.actualWeight = this.validateActWgt;
        this.lrDtoSetTripDetails.chargedWeight = this.validateChgWgt;
        //this.lrDtoSetTripDetails.hamaliDD = this.validateDdAmt;

        this.lrDtoSetTripDetails.vehicleNumber = this.enteredVehicleNumber;
        this.enteredTransportName = this.hashmapVehicle.get(this.enteredVehicleNumber);
        console.log(this.enteredTransportName);
        this.lrDtoSetTripDetails.transportName = this.enteredTransportName;

        // its value cmg from 1 page when trip create  1 st level
        this.lrDtoSetTripDetails.areaOfDelivery = this.lrDtoPassTripSchedulePage.selectedTripTo;
        this.lrDtoSetTripDetails.localTripSpeLogicStations = this.lrDtoPassTripSchedulePage.localTripStation;

        this.lrDtoSetTripDetails.branch = this.userDataDtoReturnSession.office;
        this.lrDtoSetTripDetails.totalLrs = this.lrDtoCreateATripDetail.lrDtoEligible.length;
        this.lrDtoSetTripDetails.entryBy = this.userDataDtoReturnSession.userId;
        this.lrDtoSetTripDetails.driverName = this.enteredDriverName;
        this.lrDtoSetTripDetails.status = status;
        this.lrDtoSetTripDetails.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDtoSetTripDetails.labourHamaliStmt = this.enteredLabourHamaliName;
        this.lrDtoSetTripDetails.mobileNum = this.enteredDriverMobileNo;
        this.lrDtoSetTripDetails.isOpenTrip = this.userDataDtoReturnSession.isOpenTrip;
        this.lrDtoSetTripDetails.localTripMulti = this.userDataDtoReturnSession.localTripMulti;
        if (this.userDataDtoReturnSession.mainStation == "Chennai") {
            this.lrDtoSetTripDetails.timeLogin = true;
        } else {
            this.lrDtoSetTripDetails.timeLogin = false;
        }
        // its value cmg from 1 page when trip create  1 st level
        this.lrDtoSetTripDetails.mode = this.lrDtoPassTripSchedulePage.module;
        this.lrDtoSetTripDetails.companyId = this.userDataDtoReturnSession.companyId;

        if (this.viewToShowDeliveryManField == true) {
            this.enteredLoaderHeadName = $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();
            this.lrDtoSetTripDetails.loaderName = this.enteredLoaderHeadName;
        }
        //Chg_V2
        this.lrDtoSetTripDetails.lorryHire = this.enteredLorryHire;
        this.lrDtoSetTripDetails.advance = this.enteredAdvance;
        this.lrDtoSetTripDetails.balance = this.balanceAmt;
        console.log(this.lrDtoSetTripDetails);
        return this.lrDtoSetTripDetails;
    }

    departNewTrip(lrdtoNewTripCreate) {
        console.log(lrdtoNewTripCreate);
        this.lrdtoNewTripCreateAndPrint = new LRDto();
        this.lrdtoNewTripCreateAndPrint = lrdtoNewTripCreate;
        this.showSpinnerForTripDetailsAction = true;
        if (this.viewScheduleTripBtnV2) {
            $("#" + this.pageId + "scheduleTripBtn").prop('disabled', true);
        } else {
            $("#" + this.pageId + "createTripSheetBtn").prop('disabled', true);
        }

        this.hireslipService.addTripSheetDetails(this.lrdtoNewTripCreateAndPrint).subscribe(
            (response) => {
                console.log(response);
                console.log("ADDRES");
                if (response.status != null && response.status == "Success") {
                    this.lrdtoNewTripCreateAndPrint.tripNo = response.tripNo;
                    this.lrdtoNewTripCreateAndPrint.tripDate = response.tripDate;
                    this.lrdtoNewTripCreateAndPrint.localTripNumber = response.localTripNumber;
                    this.lrdtoNewTripCreateAndPrint.lrDtoEligible = this.eligibleLrsDataList;

                    if (this.consolidateEwaybillEnabled && lrdtoNewTripCreate.status != 'Scheduled') {
                        console.log('this.consolidateEwaybillEnabled');
                        this.confirmGenerateConsolidateEwaybill(response.id);
                    } else {
                        this.printMtd(null);
                    }
                    //Chg_V2
                    this.clearAllField();
                } else {
                    swal("Server Error", "Failed to  Create A TripSheet Details ", "error");
                    if (this.viewScheduleTripBtnV2) {
                        $("#" + this.pageId + "scheduleTripBtn").prop('disabled', false);
                    } else {
                        $("#" + this.pageId + "createTripSheetBtn").prop('disabled', false);
                    }
                }
                this.showSpinnerForTripDetailsAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                if (this.viewScheduleTripBtnV2) {
                    $("#" + this.pageId + "scheduleTripBtn").prop('disabled', false);
                } else {
                    $("#" + this.pageId + "createTripSheetBtn").prop('disabled', false);
                }
                swal("Server Error", "Problem occur while adding a  TripSheet Details ", "error");
            },
            () => console.log('done');
    }


    departUpdatedTrip(lrdtoUpdateTrip) {
        console.log(lrdtoUpdateTrip);
        this.lrdtoUpdateTripCreateAndPrint = new LRDto();
        this.lrdtoUpdateTripCreateAndPrint = lrdtoUpdateTrip;
        this.showSpinnerForTripDetailsAction = true;
        this.hireslipService.updateDestLocalTripSheetDetails(this.lrdtoUpdateTripCreateAndPrint).subscribe(
            (response) => {
                console.log(response);
                if (response.status != null && response.status == "Success") {
                    this.lrdtoUpdateTripCreateAndPrint.tripNo = response.tripNo;
                    this.lrdtoUpdateTripCreateAndPrint.tripDate = response.tripDate;
                    //this.lrdtoUpdateTripCreateAndPrint.localTripNumber = response.localTripNumber;
                    this.lrdtoUpdateTripCreateAndPrint.lrDtoEligible = this.eligibleLrsDataList;
                    swal({
                        title: "Print Trip Sheet",
                        text: "Sure U want to print this Trip Sheet",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((sureTripPrintYes) => {
                        if (sureTripPrintYes) {
                            localStorage.clear();
                            this.lrdtoUpdateTripCreateAndPrint.areaOfDelivery = this.lrDtoPassTripSchedulePage.localTripStation;
                            localStorage.setItem('stockTripsheetDriverPrint', JSON.stringify(this.lrdtoUpdateTripCreateAndPrint));
                            this.printMtd(null);
                            // this.viewStockTripsheetPrint = true;
                            // setTimeout(() => {
                            //     this.viewStockTripsheetPrint = false;
                            //     localStorage.clear();
                            //     this.clearAllField();
                            // }, 2000);
                        } else {
                            this.clearAllField();
                        }
                    });
                } else {
                    swal("Server Error", "Failed to  Update A TripSheet Details ", "error");
                }
                this.showSpinnerForTripDetailsAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while Update a  TripSheet Details ", "error");
            },
            () => console.log('done');
    }

    printMtd(ewayBill) {
        swal({
            title: "Print Trip Sheet",
            text: "Sure U want to print this Trip Sheet",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureTripPrintYesBtn) => {
            if (sureTripPrintYesBtn) {
                localStorage.clear();
                this.lrdtoNewTripCreateAndPrint.areaOfDelivery = this.lrDtoPassTripSchedulePage.localTripStation;
                this.lrdtoNewTripCreateAndPrint.eWayBillNo = ewayBill;
                localStorage.setItem('stockTripsheetDriverPrint', JSON.stringify(this.lrdtoNewTripCreateAndPrint));
                let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
                if (MainAdminStation != null && (MainAdminStation == 'delhi')) {
                    this.viewStockTripsheetPrintOld = true;
                } else {
                    this.viewStockTripsheetPrint = true;
                }
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewStockTripsheetPrint || this.viewStockTripsheetPrintOld) {
                        this.viewStockTripsheetPrint = false;
                        this.viewStockTripsheetPrintOld = false;
                        this.changeDetectorRef.detectChanges();
                        localStorage.clear();
                        this.clearAllField();
                        if (this.lrdtoNewTripCreateAndPrint.status != null && this.lrdtoNewTripCreateAndPrint.status == 'Scheduled') {
                            swal("Trip Scheduled", "Trip Scheduled successfully, please go to dashboard for further operation", "success");
                        }
                    }
                });
                this.changeDetectorRef.detectChanges();
                // this.viewStockTripsheetPrint = true;
                // setTimeout(() => {
                //     this.viewStockTripsheetPrint = false;
                //     localStorage.clear();
                //     this.clearAllField();
                // }, 2000);
            } else {
                this.clearAllField();
            }
            this.changeDetectorRef.detectChanges();
        })
    }

    validateScheduleTripSheet() {
        if (($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldVehicleNumber").val() == "") ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == null) ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == undefined) ||
            ($("#" + this.pageId + "dropDownInputFieldDriverName").val() == "")) {
            swal("Mandatory Field", "Vehicle Number & Driver Name is Mandatory Field", "warning");
            return false;
        } else {
            swal({
                title: "Trip Sheet",
                text: "Sure U want to Schedule this Trip",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.lrDtoSetTripDetailsReturn = new LRDto();
                    this.lrDtoSetTripDetailsReturn = this.getTripDetails("Scheduled");
                    console.log(this.lrDtoPassTripSchedulePage.tripId);
                    // its value cmg from 1 page when trip create  1 st level
                    if (this.lrDtoPassTripSchedulePage.tripId != null &&
                        this.lrDtoPassTripSchedulePage.tripId > 0) {
                        this.lrDtoSetTripDetailsReturn.tripId = this.lrDtoPassTripSchedulePage.tripId;
                        this.scheduleUpdateTrip(this.lrDtoSetTripDetailsReturn);
                    } else {
                        this.scheduleAddTrip(this.lrDtoSetTripDetailsReturn);
                    }
                }
            });
        }
    }

    scheduleAddTrip(lrdtoAddScheduleTrip) {
        this.lrdtoForAddNewScheduleTrip = new LRDto();
        this.lrdtoForAddNewScheduleTrip = lrdtoAddScheduleTrip;
        this.showSpinnerForTripDetailsAction = true;
        this.hireslipService.addTripSheetDetails(this.lrdtoForAddNewScheduleTrip).subscribe(
            (response) => {
                console.log(response);
                if (response.status != null && response.status == "Success") {
                    swal("Trip Scheduled", "Trip Scheduled successfully", "success");
                    this.clearAllField();
                } else {
                    swal("Server Error", "Failed to  Create Schedule TripSheet Details ", "error");
                }
                this.showSpinnerForTripDetailsAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while adding a  Schedule TripSheet Details ", "error");
            },
            () => console.log('done');
    }

    scheduleUpdateTrip(lrdtoUpdateScheduleTrip) {
        this.lrdtoForUpdateScheduleTrip = new LRDto();
        this.lrdtoForUpdateScheduleTrip = lrdtoUpdateScheduleTrip;
        this.showSpinnerForTripDetailsAction = true;
        this.hireslipService.updateDestLocalTripSheetDetails(this.lrdtoForUpdateScheduleTrip).subscribe(
            (response) => {
                console.log(response);
                if (response.status != null && response.status == "Success") {
                    swal("Trip Scheduled", "Trip Scheduled successfully", "success");
                    this.clearAllField();
                } else {
                    swal("Server Error", "Failed to  Update A Schedule Trip Details ", "error");
                }
                this.showSpinnerForTripDetailsAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForTripDetailsAction = false;
                swal("Server Error", "Problem occur while Update A Trip Details ", "error");
            },
            () => console.log('done');

    }

    resetBtnTripDetailsFrom() {
        swal({
            title: "Reset Confirm",
            text: "Sure U want to reset this Trip details",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.stepper.reset();
                this.clearAllField();
                this.setTripCrationForArticlesStocksSummaryDetailsList();
            }
        });

    }

    startOverBtnTripDetailsFrom() {
        this.stepper.reset();
        this.clearAllField();
        this.setTripCrationForArticlesStocksSummaryDetailsList();
    }

    backBtnForStockTripSheetDetails() {
        /*2nd stock for trip sheet level - end */
        /*  if (this.eligibleLrsDataList.length == null ||
              this.eligibleLrsDataList.length == undefined ||
              this.eligibleLrsDataList.length == 0) {*/
        if (this.eligibleLrsDataList == null ||
            this.eligibleLrsDataList == undefined ||
            this.eligibleLrsDataList.length == 0) {

            this.remainingLrsDataList = [];
            this.eligibleLrsDataList = [];
            $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
            $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
            this.dtTriggerEligibleLrs.next();
            this.dtTriggerRemainingLrs.next();
            this.modelDropDownBins = null;
        }

        $("#" + this.pageId + "lrNumber").val('');
        this.viewNextDarkBtnForScheduleTrip = true;
        this.viewNextGreenBtnForScheduleTrip = false;

        this.lrDtoForScheduleTripsheetReturn = new LRDto();
        //this.lrDtoFromDashboardScheduleLocalTrip = new LRDto();
        /*2nd stock for trip sheet level - end */
    }

    clearAllField() {
        /*1nd stock for trip sheet level - end */
        this.stockDetailsDataList = [];
        $("#" + this.pageId + "localBookingForStockDetailsTableId").DataTable().destroy();
        this.dtTriggerStockDetails.next();
        this.viewBinsStockMsg = "Bins Stock";
        this.viewLocalTripsheetMsg = "";

        this.tripSheetBlackBtnHideShow = false;
        this.tripSheetBtnHideShow = false;


        this.tripSheetBtnId = null;
        this.dummyTripSheetBtnId = null;
        /*1nd stock for trip sheet level - end */

        /*2nd stock for trip sheet level - end */
        this.remainingLrsDataList = [];
        this.eligibleLrsDataList = [];
        $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
        $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
        //this.dtTriggerEligibleLrs.next();
        //this.dtTriggerRemainingLrs.next();

        this.modelDropDownBins = null;

        this.viewNextDarkBtnForScheduleTrip = false;
        this.viewNextGreenBtnForScheduleTrip = false;

        this.lrDtoForScheduleTripsheetReturn = new LRDto();
        //this.lrDtoFromDashboardScheduleLocalTrip = new LRDto();
        /*2nd stock for trip sheet level - end */

        /* 3 rd stock for trip sheet level - start*/
        this.viewScheduleTripBtn = false;
        this.modelDropDownVehicleNumber = null;
        this.modelDropDownDriverName = null;
        $("#" + this.pageId + "inputFieldDriverConatctNumber").val('');
        this.modelDropDownLabourHeadName = null;
        this.modelDropDownLabourName = null;
        $("#" + this.pageId + "hamalis").val('');
        /* 3 rd stock for trip sheet level - end */

        localStorage.clear();

        this.localStorageOfStockGrouping = null;
        this.localStorageOfStockGroupingDestination = null;
        $("#" + this.pageId + "lorryHire").val('');
        $("#" + this.pageId + "advance").val('');
        $("#" + this.pageId + "balance").val('');
    }

    rowCheckBoxChecked(e, remainingLrsData, index) {
        if (e.currentTarget.checked) {
            if (this.lrDtoListRemChecked.length == 0) {
                this.lrDtoListRemChecked = [];
                this.lrDtoListRemCheckedIndex = [];
                this.lrDtoListRemChecked.push(remainingLrsData);
                this.lrDtoListRemCheckedIndex.push(index);
            } else {
                this.lrDtoListRemChecked.push(remainingLrsData);
                this.lrDtoListRemCheckedIndex.push(index);
            }
        } else {
            let index = this.lrDtoListRemChecked.indexOf(remainingLrsData);
            if (index > -1) {
                this.lrDtoListRemChecked.splice(index, 1);
                this.lrDtoListRemCheckedIndex.splice(index, 1);
            }
        }
    }

    validateMoveSelectedLrs() {
        //console.log("checked LRs : "+this.lrDtoListRemChecked);
        //console.log("checked LRs index: "+this.lrDtoListRemCheckedIndex);

        for (let i = 0; i < this.lrDtoListRemChecked.length; i++) {
            this.eligibleLrsDataList.push(this.lrDtoListRemChecked[i]);
        }

        //this.lrDtoListRemCheckedTemp = this.remainingLrsDataList;

        for (let i = 0; i < this.lrDtoListRemCheckedIndex.length; i++) {
            let lrNumber1 = this.lrDtoListRemChecked[i].lrNumber;
            //console.log('1 - '+lrNumber1);
            for (let j = 0; j < this.remainingLrsDataList.length; j++) {
                let lrNumber2 = this.remainingLrsDataList[j].lrNumber;
                //console.log('2 - '+lrNumber2);
                if (lrNumber1 == lrNumber2) {
                    //console.log('3');
                    //let index = this.remainingLrsDataList.indexOf(this.lrDtoListRemCheckedTemp[i]);
                    let index = this.remainingLrsDataList.findIndex(x => x.lrNumber === this.lrDtoListRemChecked[i].lrNumber);
                    //console.log('4 - '+index);
                    this.remainingLrsDataList.splice(index, 1);
                }
            }
        }

        $("#" + this.pageId + "eligibleLrsTableId").DataTable().destroy();
        setTimeout(() => {
            this.dtTriggerEligibleLrs.next();
        }, 500);

        $("#" + this.pageId + "remainingLrsTableId").DataTable().destroy();
        setTimeout(() => {
            this.dtTriggerRemainingLrs.next();
        }, 500);
        this.viewNextDarkBtnForScheduleTrip = true;
        this.lrDtoListRemChecked = [];
        this.lrDtoListRemCheckedIndex = [];
    }

    /* Main 3 rd stock for trip sheet level - start */

    /*2nd level dashboard start */
    /*  setDashboardFroScheduleLocalTripSheet() {
          this.stepper.selectedIndex = 1;
          if (this.userDataDtoReturnSession.office != null &&
              this.userDataDtoReturnSession.office == "Binnymills") {
              this.lrDtoPassTripSchedulePage = new LRDto();
              this.lrDtoPassTripSchedulePage = this.lrDtoFromDashboardScheduleLocalTrip;
          } else {
              this.lrDtoPassTripSchedulePage = new LRDto();
              this.lrDtoPassTripSchedulePage = this.lrDtoFromDashboardScheduleLocalTrip;
          }
          this.viewLocalTripsheetMsg = this.lrDtoFromDashboardScheduleLocalTrip.destination;
          this.showSpinnerForTripScheduleAction = true;
          this.clearFieldForStockTripSheetSchedule();
          this.showSpinnerForTripScheduleAction = false;
          setTimeout(() => {
              this.getScheduledLRDetailsFromDashboard();
          }, 2000);
      }
  
      getScheduledLRDetailsFromDashboard() {
          //console.log("checking");
          //console.log(this.lrDtoPassTripSchedulePage);
          this.lrDtoForScduleLrDashboardLocalTrip = new LRDto();
          this.lrDtoForScduleLrDashboardLocalTrip.tripId = this.lrDtoPassTripSchedulePage.tripId;
          this.lrDtoForScduleLrDashboardLocalTrip.status = "Scheduled";
          this.lrDtoForScduleLrDashboardLocalTrip.godownName = this.userDataDtoReturnSession.office;
          this.lrDtoForScduleLrDashboardLocalTrip.companyId = this.userDataDtoReturnSession.companyId;
          this.showSpinnerForTripDetailsAction = true;
          this.hireslipService.getLocalTripsLrsDetails(this.lrDtoForScduleLrDashboardLocalTrip).subscribe(
              (response) => {
                  //console.log(response);
                  this.eligibleLrsDataList = [];
                  $("#"+this.pageId+"eligibleLrsTableId").DataTable().destroy();
                  if (response.length > 0) {
                      this.eligibleLrsDataList = response;
                      this.showSpinnerForTripDetailsAction = false;
                  } else {
                      swal("Alert", "No Record Found", "warning");
                      this.showSpinnerForTripDetailsAction = false;
                  }
                  this.dtTriggerEligibleLrs.next();
              }), (error) => {
                  this.showSpinnerForTripDetailsAction = false;
                  swal("Server Error", "Problem occur while getting local trip lrs Details ", "error");
              },
              () => console.log('done');
      };*/
    /*2nd level dashboard end */

    confirmGenerateConsolidateEwaybill(tripId) {
        swal({
            title: "Confirm Generate",
            text: "You Want to generate the Consolidate Eway Bill for this Trip?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.generateConsolidateEwaybill(tripId);
            } else {
                swal.close();
                this.printMtd(null);
            }
        })
    }

    generateConsolidateEwaybill(tripId) {
        this.requestDto = new RequestDto();
        this.requestDto.param3 = tripId;
        this.requestDto.mode = 'Booking';
        this.showSpinnerForAction = true;
        this.ewaybillService.generateConsolidateEwaybillLocalTrip(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal({
                        title: "Completed",
                        text: "Consolidated Eway bill generated. Eway Bill No : " + response.ewayBillNo + ". Want to take Local Tripsheet Print?",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yesBtn) => {
                        if (yesBtn) {
                            this.printMtd(response.ewayBillNo);
                        }
                    })
                    this.downloadConsolidatedEWBPdf(response.ewayBillNo, response.token1);
                } else {
                    swal({
                        title: "Failed",
                        text: "Failed to generate the Consolidated Eway bill, please try again or contact Admin! Want to take Local Tripsheet Print?",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((yesBtn) => {
                        if (yesBtn) {
                            this.printMtd(null);
                        }
                    })
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Error while process the Consolidated Eway bill generation request!", "error")
            }, () => console.log('done');
    }

    downloadConsolidatedEWBPdf(ewayBillNo, token) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": 5 };

        const authToken = token;

        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, authToken).subscribe((data) => {
            console.log('downloadPdf - ' + ewayBillNo);
            const blob = new Blob([data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, ewayBillNo + '-CEWB.pdf');
        });
    }

    //Chg_V2
    calculateLorryHireAmt() {
        this.enteredLorryHire = parseFloat($("#" + this.pageId + "lorryHire").val().toString() || '0');
        this.enteredAdvance = parseFloat($("#" + this.pageId + "advance").val().toString() || '0');
        console.log(this.enteredLorryHire);
        console.log(this.enteredAdvance);

        if ((this.enteredAdvance != null && this.enteredAdvance > 0) && (this.enteredAdvance > this.enteredLorryHire || this.enteredLorryHire === 0)) {
            swal({
                title: "Not Allowed",
                text: "Advance amount must be equal or lesser than Lorry Hire Amount!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            $("#" + this.pageId + "balance").val('');
        } else {
            if (this.enteredLorryHire > 0 || this.enteredAdvance > 0) {
                this.balanceAmt = this.enteredLorryHire - this.enteredAdvance;
                $("#" + this.pageId + "balance").val(this.balanceAmt);
            } else {
                $("#" + this.pageId + "balance").val('');
            }
        }
    }

    calculateLorryHireAmtOnEnter(e, e1) {
        if (e.keyCode == 13) {
            this.advanceField.nativeElement.focus();
            this.calculateLorryHireAmt();
        }
    }

    calculateLorryHireAmtOnBlur(e) {
        this.calculateLorryHireAmt();
    }
}

