<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}lrEnquiryForm">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row ">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">

                                <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                            <br>
                                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                Please Wait Loading Details.....</h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="form-group">
                                        <div class="input-group ">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="icon-home"></i>
                                                </span>
                                            </div>
                                            <select class="custom-select col-12" id="{{pageId}}searchBy">
                                                <option value="lrNumberWise">Lr Number</option>
                                                <option value="eWayBillWise">E-Way Bill</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <div class="input-group ">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="icon-speech"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}lrAndEwayNumber" class="form-control" aria-describedby="basic-addon11" (keyup)="getLrEnquiry($event)">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <button type="submit" class="btn btn-dark" id="{{pageId}}searchBtn" (click)="validateBtnSearch();">Search</button>
                                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}clearBtn" (click)="validateClear()">Clear</button>

                                </div>
                                <div class="col-md-4">
                                    <div class="input-group ">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="icon-list"></i>
                                            </span>
                                        </div>

                                        <select class="custom-select col-6" id="{{pageId}}actionType" name="actionType" #actionType (change)="actionTypeMethod(actionType.value,lrPrintCopyPopUpTemplate,contentVehicleUpdatePopup)">
                                            <option>Select Action</option>
                                            <option value='lrAdvance'>LR Advance</option>
                                            <option value='lrTrack'>Track Lr</option>
                                            <option *ngIf="lblPrint" value='lrPrint'>LR Print</option>
                                            <option>Update Consignee</option>
                                            <option>Consignee Business Details</option>
                                            <option *ngIf="viewPodLbl" value='lrPod'>POD Copy</option>
                                            <option *ngIf="viewGetPodOtpLbl" value='lrPodOtp'>Get POD Pin</option>
                                            <option *ngIf="viewLRDownloadEwaybillLbl" value='lrDownloadEwaybill'>
                                                Download LR Ewaybill</option>
                                            <!-- removed this as per Musaddik bh since its given in memo generation and trip report -->
                                            <!-- <option *ngIf="viewEwaybillDCLbl" value='lrEwaybillDC'>Generate Ewaybill DC</option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <!--<select class="custom-select col-4" id="{{pageId}}searchBy" >
					
					<option value="" *ngIf="lblUpdateCnge">Update Consignee</option>
					<option value="" *ngIf="lblPrint">LR Print</option>
					<option value="" *ngIf="lblNewConsignee">New Party</option>
					<option value="" *ngIf="lblConsigneeBusinessDetails">Consignee Business Details</option>
				</select>-->
            </div>
            <div class="row ">
                <div *ngIf="lblCashmemoBlock" class="col-sm-12 col-md-3">
                    <div class="form-group">
                        <div class="input-group" id="{{pageId}}popupDetails">
                            <ng-template #content11 let-c="close" let-d="dismiss">
                                <div class="modal-header">
                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Cashmemo Block Reason</h6>
                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="col-sm-12 col-md-8">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Reason</label>
                                                        <!-- <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}memoBlockedReason" [readonly]="isReadOnly" [(ngModel)]="lrEnquiryRet.memoBlockReason"> -->
                                                        <textarea type="text" class="form-control" id="{{pageId}}memoBlockedReason" style="cursor: pointer; font-weight: bold; color: blue; height: 20%; width: 55%;" aria-describedby="basic-addon11" [readonly]="isReadOnly" [(ngModel)]="lrEnquiryRet.memoBlockReason"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-8">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Blocked Date</label>
                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}memoBlockedDate" [readonly]="isReadOnly" [(ngModel)]="lrEnquiryRet.memoBlockDateStr">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <a style="cursor: pointer; color: blue; padding-left: 10px;" (click)="open11(content11)"><u>Cashmemo Generation is
                                    Blocked</u></a>
                        </div>
                    </div>
                </div>
                <div *ngIf="viewDetainedByGst" class="col-sm-12 col-md-3">
                    <div class="form-group">
                        <div class="input-group" style="text-align: center;">
                            <h6 style="color: green;">
                                <b style="text-align: center;">This LR is Detained by GST Department</b>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div *ngIf="lblConsigneeCashmemoBlock" class="col-sm-12 col-md-3">
                    <div class="form-group">
                        <div class="input-group" id="{{pageId}}popupDetails">
                            <ng-template #content11 let-c="close" let-d="dismiss">
                                <div class="modal-header">
                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Consignee Cashmemo Block Reason
                                    </h6>
                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="col-sm-12 col-md-8">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Reason</label>
                                                        <textarea type="text" class="form-control" id="{{pageId}}memoBlockedConsigneeReason" style="cursor: pointer; font-weight: bold; color: blue; height: 20%; width: 55%;" aria-describedby="basic-addon11" [readonly]="isReadOnly" [(ngModel)]="lrEnquiryRet.consigneeMemoBlockReason"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <a style="cursor: pointer; color: blue; padding-left: 10px;" (click)="openConsigneeMemoBlockedPopup(content11)"><u>Consignee Cashmemo Generation is
                                    Blocked</u></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" style="text-align: center;">

                <div class="card">

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4"></div>
                            <div *ngIf="!viewDetainedByGst">
                                <div *ngIf="!viewReasonLbl" class="col-sm-8 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group" style="text-align: center;">
                                            <h6 style="color: green;">
                                                <b style="text-align: center;">{{showStatus}}
                                                    <span *ngIf="showUncleredLrReason">({{ unclearedLrReason }})</span>
                                                </b>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="viewReasonLbl" class="col-sm-12 col-md-4">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <h6 style="color: green;">
                                                <b>{{showStatus}}</b>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="viewReasonLbl" class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <h6 style="color: green;">
                                            <b>{{showReasonLbl}}</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="viewCCCopyLbl" class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <h6 style="color: red;">
                                            <b>{{consigneeCopyLbl}}</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <!-- <div *ngIf="viewLrHoldReasonLbl" class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <h6 style="color: green;">
                                            <b>{{showLrHoldLbl}}</b>
                                        </h6>
                                    </div>
                                </div>
                            </div> -->

                            <!-- for pod testing -->
                            <!-- <div *ngIf="viewPodLbl" class="col-sm-12 col-md-8">
                                <div class="form-group">
                                    <div class="input-group">
                                        <h6 style="color: green;cursor: pointer;">
                                            <a (click)="getImgPod();">
                                                <u>View POD</u>
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>



            <hr>
            <div class="row " style="margin-bottom: 0px;">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group ">
                                            <label>Challan No</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="icon-speech"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}challanNo" name="challanNo" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Entered Date</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}enteredDate" name="enteredDate" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Received At Bkg</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}bkgRecDate" name="bkgRecDate" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Paid</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input type="number" id="{{pageId}}paid" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group ">
                                            <label>Agent Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="icon-speech"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}agentName" name="agentName" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group ">
                                            <label>Booking Date</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}bookingDate" name="bookingDate" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Private Marker</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-paint-brush"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}privateMarker" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group" id="{{pageId}}popupDetails">
                                            <label>Freight Amt</label>
                                            <ng-template #content2 let-c="close" let-d="dismiss">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Freight Details
                                                    </h6>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Base Freight</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}frtAmtBaseFreight" [(ngModel)]="lrDtoFreight.baseFreight" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Hamali</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}frtAmtHamali" [(ngModel)]="lrDtoFreight.hamaliLR" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>GC Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}frtAmtGCCharge" [(ngModel)]="lrDtoFreight.gcCharge" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>LC Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}frtAmtLCCharge" [(ngModel)]="lrDtoFreight.lcChg" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>BC Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}frtAmtBCCharge" [(ngModel)]="lrDtoFreight.bcChg" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>F O V</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" #frtAmtFOV id="{{pageId}}frtAmtFOV" name="frtAmtFOV" [(ngModel)]="lrDtoFreight.riskCharge" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>A.O.C</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}frtAmtAOC" [(ngModel)]="lrDtoFreight.aoc" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Insurance Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}frtAmtInsuranceCharge" [(ngModel)]="lrDtoFreight.insurance" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Others</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}frtAmtOthers" [(ngModel)]="lrDtoFreight.others" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>DD </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}frtAmtDD" [(ngModel)]="lrDtoFreight.DoorDelivAmt" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>COD Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrDtoFreight.codChg" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Total Amount </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}frtAmtTotalAmt" [(ngModel)]="lrDtoFreight.totalAmount" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <a *ngIf="showFreight" style="cursor: pointer; color: blue;" (click)="open2(content2)"><u> View Details</u></a>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Generated By</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="ti-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" #generatedBy id="{{pageId}}generatedBy" name="generatedBy" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>LR Number</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-clipboard"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}lrNumber" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Commodity</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-clone"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}commonCommodity" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="input-group " id="{{pageId}}articleSize">
                                        <label>Articles Size</label> <a *ngIf="showArticleSize" style="cursor: pointer; color: blue;" (click)="getArticleSizeTable()"><u>
                                                View Size</u></a>
                                        <a *ngIf="showMultipleArticlesRange" style="cursor: pointer; color: rgb(255, 51, 0); margin-left: 1pc;" (click)="getArticleRangeTable()"><u>
                                                    View Range</u></a>
                                    </div>
                                </div>


                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Labour Contractor</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="ti-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}labourContractor" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group ">
                                            <label>No.Of Articles</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-clone"></i>
                                                </span>
                                            </div>
                                            <input type="number" id="{{pageId}}noOfArticles" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Rate Commodity</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-clone"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}mainCommodity" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Commodity Desc</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-clone"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}goodsType" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group" id="{{pageId}}popupDetails3">
                                            <label>Invoice Number</label>
                                            <ng-template #content8 let-c="close" let-d="dismiss">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Invoice Details
                                                    </h6>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label style="color: blue; font-size: 16px"><u><b>Invoice
                                                                                    Details</b> </u></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Invoice Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="icon-speech"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}invoiceNo" name="invoiceNo" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.invoiceNumber" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12"></div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group ">
                                                                        <label>Company Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-home"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}companyName" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehiCompName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group ">
                                                                        <label>Vehicle Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-home"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}mainVehicleNo" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehicleNumber" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group ">
                                                                        <label>Address</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class=" fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}vehicleCompanyAddress" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehiCompAddress" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group ">
                                                                        <label>City</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class=" fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}vehicleCompCity" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehiCompCity" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Pincode</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class=" fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" id="{{pageId}}pinCode" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehiCompPincode" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Contact Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class=" fas fa-phone"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" id="{{pageId}}vehicleCompContactNumber" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.vehiCompContactNum" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Driver Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}driverName" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.driverName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Driver Con. No.</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class=" fas fa-phone"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" id="{{pageId}}driverContactNumber" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.driverContactNum" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Broker Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}brokerName" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.brokerName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Generated By</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}invoiceGeneratedBy" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.invoiceGeneratedBy" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Loader Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}loaderName" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.loaderName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label style="color: blue; font-size: 16px"><u><b>Date
                                                                                    Info</b> </u></label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Departure Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}departureDate" name="departureDate" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.departuredateStr" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Departure Time</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-clock"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}departureTime" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.departureTime" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Schedule Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}scheduledDate" name="scheduledDate" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.scheduleddateStr" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Schedule Time</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-clock"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}scheduleTime" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.scheduledTime" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group ">
                                                                        <label>Arrival Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}arrivalDate" name="arrivalDate" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.arrivalDateStr" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Arrival Time</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-clock"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" id="{{pageId}}arrivalTime" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.arrivalTime" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <button style="padding: 1px 4px; float: right; margin-top: 10pc;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <a style="cursor: pointer; color: blue;" (click)="open8(content8)"><u>{{showInvoiceNumber}}</u></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">

                                    <div class="form-group">
                                        <div class="input-group " id="{{pageId}}shortExtra">
                                            <label>Short/Extra </label> <a *ngIf="showShortExtra" style="cursor: pointer; color: blue;" (click)="getShortExtraTable()"><u>
                                                    View Details</u></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Goods Value</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-clipboard"></i>
                                                </span>
                                            </div>
                                            <input type="number" id="{{pageId}}goodsValue" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Bill Number</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-file-alt"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}billNumber" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>



                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group" id="{{pageId}}popupDetails2">
                                            <label>Dummy Invoice 1</label>
                                            <ng-template #content4 let-c="close" let-d="dismiss">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Dummy Invoice Details</h6>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label style="color: blue; font-size: 16px"><u><b>Dummy
                                                                                    Invoice Info</b> </u></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Invoice Number </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvInvoiceNumber" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyInvoice" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Vehicle Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvVehicleNumber" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyVehicleNo" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Company Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvCompanyName" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyCompanyName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Address</label>
                                                                        <div class="input-group-prepend" width=120%>
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvAddress" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyAddress" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>City</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvCity" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyCity" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Pincode</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvPincode" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyPincode" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Contact Number </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-phone"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvContactNumber" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyContactNo" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Driver Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvDriverName" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyDriverName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Driver Contact Number </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-phone"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvDriverContNumber" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyDrivContNo" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Broker Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvBrokerName" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyBrokerName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Generated By </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvGeneratedBy" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyGeneratedBy" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label style="color: blue; font-size: 16px"><u><b>Dummy
                                                                                    Invoice Date</b> </u></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Departure Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvDepartureDate" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyDepDate" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Departure Time</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-clock"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvDepartureTime" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyDepTime" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Schedule Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvScheduleDate" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummySchDate" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Schedule Time</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-clock"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvScheduleTime" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummySchTime" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Arrival Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvArrivalDate" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyArrDAte" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Arrival Time</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-clock"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInvArrivalTime" [(ngModel)]="lrDtoForDummyInvoiceRetDto.dummyArrTime" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-12">
                                                                <button style="padding: 1px 4px; float: right; margin-top: 8pc;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <a style="cursor: pointer; color: blue;" (click)="open4(content4)"><u>{{viewDummyInvoice}}</u></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group ">
                                            <label>Source</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-clone"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}source" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>H.W. Care</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="ti-truck"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}handleWithCare" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Bill Date</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}billDate" name="billDate" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>





                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group" id="{{pageId}}popupDetails2">
                                            <label>Dummy Invoice 2</label>
                                            <ng-template #content7 let-c="close" let-d="dismiss">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Dummy Invoice 2 Details</h6>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label style="color: blue; font-size: 16px"><u><b>Dummy
                                                                                    Invoice 2 Info</b> </u></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Invoice Number </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2InvoiceNumber" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyinvoice2" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Vehicle Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2VehicleNumber" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyVehicleNo" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Company Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2CompanyName" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyCompanyName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Address</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2Address" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyAddress" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>City</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2City" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyCity" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Pincode</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2Pincode" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyPincode" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Contact Number </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-phone"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2ContactNumber" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyContactNo" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Driver Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2DriverName" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyDriverName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Driver Contact Number </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-phone"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2DriverContNumber" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyDrivContNo" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Broker Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2BrokerName" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyBrokerName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Generated By </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2GeneratedBy" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyGeneratedBy" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label style="color: blue; font-size: 16px"><u><b>Dummy
                                                                                    Invoice Date</b> </u></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Departure Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2DepartureDate" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyDepDate" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Departure Time</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-clock"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2DepartureTime" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyDepTime" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Schedule Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2ScheduleDate" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummySchDate" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Schedule Time</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-clock"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2ScheduleTime" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummySchTime" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Arrival Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2ArrivalDate" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyArrDAte" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Arrival Time</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-clock"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}dumInv2ArrivalTime" [(ngModel)]="lrDtoForDummyInvoice2RetDto.dummyArrTime" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-12">
                                                                <button style="padding: 1px 4px; float: right; margin-top: 8pc;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <a style="cursor: pointer; color: blue;" (click)="open7(content7)"><u>{{viewDummyInvoice2}}</u></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Consignor GST</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}consignorGstNumber" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>COD</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="ti-truck"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}cod" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-12 col-md-3" *ngIf="showSingleEwayBill">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>E-Way Bill No</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}eWayBillNo" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <!-- *ngIf="showMultiEwayBill" -->
                                            <!-- <label style="flex: 5;">E-Way Bill No</label>
                                            <div class="input-group-prepend" style="flex: 0;">
                                                <span class="input-group-text"><i class="fas fa-hashtag"></i></span>
                                            </div>
                                            <input type="text" id="{{pageId}}eWayBillNo"
                                                   class="form-control" aria-describedby="basic-addon11"
                                                   [readonly]="isReadOnly" style="flex: 4;"> -->
                                            <a style="cursor: pointer; color: blue; font-weight: bolder; padding-left: 25px;"
                                                (click)="getMultiEwayBillsNo()">{{viewEwaybill}}</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <label>Bay Info</label> <a *ngIf="showShortExtra" style="cursor: pointer; color: blue;" (click)="getBayInfoTable()"><u> View
                                            Details</u></a>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Consignor Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <textarea type="text" class="form-control" id="{{pageId}}consignorName" rows="4" style="cursor: pointer; font-weight: bold; color: blue;" aria-describedby="basic-addon11" [readonly]="isReadOnly" (click)="showConsignorDetails()"></textarea>
                                            <!--	<input type="text" id="{{pageId}}consignorName" class="form-control"
												style="cursor: pointer; font-weight: bold; color: blue;"
												aria-describedby="basic-addon11" [readonly]="isReadOnly"
												(click)="showConsignorDetails()">-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group" id="{{pageId}}popupDetails">
                                            <label>COD Payment</label>
                                            <ng-template #content10 let-c="close" let-d="dismiss">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">COD Payment Details
                                                    </h6>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Payment Mode</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}codPaymentMode" [(ngModel)]="lrEnquiryRet.codPaymentMode" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Bank Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}codPaymentBankName" [(ngModel)]="lrEnquiryRet.codPaymentBankName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Cheque Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}codPaymentChqNo" [(ngModel)]="lrEnquiryRet.codPaymentChqNo" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Cheque Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}codPaymentChqDate" [(ngModel)]="lrEnquiryRet.codPaymentChqDate" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Reference Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}codPaymentRefNo" [(ngModel)]="lrEnquiryRet.codPaymentRefNo" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="col-sm-12 col-md-12">
                                                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <a *ngIf="showCODLbl" style="cursor: pointer; color: blue;" (click)="open10(content10)"><u> View Details</u></a>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-12 col-md-3" *ngIf="showSingleEwayBill">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>E-Way Bill Valid</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="ti-ticket"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly" id="{{pageId}}ewayBillValidUpto" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div> -->

                                <div class="col-sm-12 col-md-3">
                                    <!-- *ngIf="showMultiEwayBill" -->
                                    <div class="form-group">
                                        <div class="input-group">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group" id="{{pageId}}popupDetails5">
                                            <label>Booking Local Trip</label>
                                            <ng-template #contentSrcLocalTrp let-c="close" let-d="dismiss">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Booking Local Trip Details</h6>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Vehicle Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-truck"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" #localTripVehicleNumber class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srclocalTripVehicleNumber" name="localTripVehicleNumber" [(ngModel)]="lrDtoSetLocal.vehicleNofromTripSheetSrc" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Area</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}localTripArea" [(ngModel)]="lrDtoSetLocal.localBkgDeliveryAreaSrc" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Driver Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}localTripDriverName" [(ngModel)]="lrDtoSetLocal.dummyDriverNameSrc" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Trip Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}localTripTripDate" [(ngModel)]="lrDtoSetLocal.tripDateSrcStr" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <!-- 														<div class="col-sm-12 col-md-8"> -->
                                                            <!-- 															<div class="form-group"> -->
                                                            <!-- 																<div class="input-group"> -->
                                                            <!-- 																	<label></label> -->
                                                            <!-- 																	<div class="input-group-prepend"> -->
                                                            <!-- 																		<button align="center" type="submit" -->
                                                            <!-- 																			class="btn btn-dark" (click)="getLocaltrip()">Action</button> -->
                                                            <!-- 																	</div> -->

                                                            <!-- 																</div> -->
                                                            <!-- 															</div> -->
                                                            <!-- 														</div> -->


                                                            <div class="col-sm-12 col-md-12">
                                                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <span style="margin-left: 5px;">
                                                <a *ngIf="lblBookingLocalTripDetails"
                                                    style="cursor: pointer; color: blue; font-size: 13px;"
                                                    (click)="openSrcLocalTrp(contentSrcLocalTrp)"><u>
                                                        {{ localBkgTripNo }}</u></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Destination</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-road"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}destination" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Order Goods</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="ti-truck"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}orderGoods" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Delivery Mode</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}deliveryMode" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>





                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group" id="{{pageId}}popupDetails5">
                                            <label>Dest Local Trip</label>
                                            <ng-template #content5 let-c="close" let-d="dismiss">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Destination Local Trip Details</h6>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Vehicle Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-truck"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" #localTripVehicleNumber class="form-control" aria-describedby="basic-addon11" id="{{pageId}}localTripVehicleNumber" name="localTripVehicleNumber" [(ngModel)]="lrDtoSetLocal.vehicleNumber" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Area</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}localTripArea" [(ngModel)]="lrDtoSetLocal.deliveryarea" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Driver Name</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="ti-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}localTripDriverName" [(ngModel)]="lrDtoSetLocal.driverName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Trip Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}localTripTripDate" [(ngModel)]="lrDtoSetLocal.tripDate" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Trip Id</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destLocalTripId" [(ngModel)]="lrDtoSetLocal.tripId" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Trip Status</label>
                                                                        <div class="input-group-prepend">
                                                                            <h6>
                                                                                <span>{{showTripStatus}}</span>
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label></label>
                                                                        <div class="input-group-prepend">
                                                                            <button align="center" type="submit" class="btn btn-dark" (click)="slectedRowOfLocalTrip()">Action</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-sm-12 col-md-12">
                                                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <a *ngIf="lblLocalTripDetails" style="cursor: pointer; color: blue;" (click)="open5(content5)"><u> View Details</u></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Sub Station</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}subStation" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>C.C.Attach</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="ti-truck"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}cCAttach" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>AOC</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}aoc" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group" id="{{pageId}}popupDetails9">
                                            <label>Source Memo</label>
                                            <ng-template #content9 let-c="close" let-d="dismiss">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Billing Details
                                                    </h6>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Memo Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoMemoNumber" [(ngModel)]="lrEnquiryRet.srcMemo" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Memo Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoMemoDate" [(ngModel)]="lrEnquiryRet.srcMemoDate" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Created By</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoCreatedBy" [(ngModel)]="lrEnquiryRet.userName" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Total LR Amt.</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoTotalLRAmt" [(ngModel)]="lrEnquiryRet.bkgLrTotalAmount" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Hamali</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoHamali" [(ngModel)]="lrEnquiryRet.srcHamali" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Service Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoServiceChg" [(ngModel)]="lrEnquiryRet.bkgServiceCharge" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Receipt Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoReceiptChg" [(ngModel)]="lrEnquiryRet.bkgReceiptCharge" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Dest Hamali</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoDestHamali" [(ngModel)]="lrEnquiryRet.destHamali" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Discount</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoDiscount" [(ngModel)]="lrEnquiryRet.bkgMemoDisountAmt" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Sub Total</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoSubTotal" [(ngModel)]="lrEnquiryRet.bkgMemoSubTotal" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Service Tax</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoServiceTax" [(ngModel)]="lrEnquiryRet.bkgMemoServiceTax" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Service Tax Amt.</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoServiceTaxAmt" [(ngModel)]="lrEnquiryRet.bkgMemoServTaxAmt" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-8">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Grand Total</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}srcMemoGrandTotal" [(ngModel)]="lrEnquiryRet.bkgMemoGrandTotal" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <button style="padding: 1px 4px; float: right;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <a *ngIf="lblSrcMemo" style="cursor: pointer; color: blue;" (click)="open9(content9)"><u> {{showSrcCashMemo}}</u></a>
                                            <h6 *ngIf="lblSrcMemoNo">NA</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Consignee GST</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-hashtag"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}consigneeGstNumber" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Actual Weight</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                </span>
                                            </div>
                                            <input type="number" class="form-control" id="{{pageId}}actualWeight" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Godown Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-warehouse"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}godownName" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group" id="{{pageId}}popupDetails3">
                                            <label>Destination Memo</label>
                                            <ng-template #content3 let-c="close" let-d="dismiss">
                                                <div class="modal-header">
                                                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Billing Details
                                                    </h6>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label style="color: blue; font-size: 16px"><u><b>Billing
                                                                                    Details</b> </u></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>To Pay</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" id="{{pageId}}toPay" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.toPay" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Paid</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" id="{{pageId}}paid" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="lrEnquiryRet.paid" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Memo Number </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-file-alt"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoMemoNumber" [(ngModel)]="lrDtoDestMemo.desMemo" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Delivery Type</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-truck"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoDeliveryType" [(ngModel)]="lrDtoDestMemo.deliveryType" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Local Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoLocalCharge" [(ngModel)]="lrDtoDestMemo.localCharge" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Hamali D.D</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoHamaliDD" [(ngModel)]="lrDtoDestMemo.hamaliDD" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Hamali G.D</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoGD" [(ngModel)]="lrDtoDestMemo.hamaliGD" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="showUnloadingChg" class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Unloading Chg</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoUnldgChg" [(ngModel)]="lrDtoDestMemo.unLdgChg" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Receipt Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoReceiptCharge" [(ngModel)]="lrDtoDestMemo.receiptCharge" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Service Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoServiceCharge" [(ngModel)]="lrDtoDestMemo.serviceCharge" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Other Charge</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}otherCharge" [(ngModel)]="lrDtoDestMemo.others" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Demurrage</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoDemurrage" [(ngModel)]="lrDtoDestMemo.demurage" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Discount</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoDiscount" [(ngModel)]="lrDtoDestMemo.discountAmount" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Sub Total</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoSubTotal" [(ngModel)]="lrDtoDestMemo.subTotal" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Service Tax </label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoServiceTax" [(ngModel)]="lrDtoDestMemo.servicetax" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Service Tax Amount</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoServiceTaxAmt" [(ngModel)]="lrDtoDestMemo.servTaxAmt" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Grand Total</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoGrandTotal" [(ngModel)]="lrDtoDestMemo.grandTotal" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Tripsheet Hamali</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoTripsheetHamali" [(ngModel)]="lrDtoDestMemo.tripsheetHamali" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label style="color: blue; font-size: 16px"><u><b>Memo
                                                                                    Info</b> </u></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Memo Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoMemoDate" [(ngModel)]="lrDtoDestMemo.cashMemoTime" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Memo Amount</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoMemoAmt" [(ngModel)]="lrDtoDestMemo.cashStxAmount" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Generated By</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoGeneratedBy" [(ngModel)]="lrDtoDestMemo.memoGeneratedBy" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Delivery Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoDeliveryDate" [(ngModel)]="lrEnquiryRet.deliveryDateStr" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label style="color: blue; font-size: 16px"><u><b>Collection
                                                                                    Info</b> </u></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Collection Man At Memo Generation</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoColManAtMemoGen" [(ngModel)]="lrDtoDestMemo.cmAtMemoGeneration" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Cash Memo Assigned To</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-user"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoCashMemoAssignedTo" [(ngModel)]="lrDtoDestMemo.column2" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Payment Type</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-search"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoPaymentType" [(ngModel)]="lrDtoDestMemo.paymentMode" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Ref Number</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-hashtag"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}refNumber" [(ngModel)]="lrDtoDestMemo.refNumber" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Collection Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoColDate" [(ngModel)]="lrDtoDestMemo.collectionDate" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Payment Mode</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-search"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoPaymentMode" [(ngModel)]="lrDtoDestMemo.cashmemoType" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="showRecDate" class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Received Date</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fa fa-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoReceivedDate" [(ngModel)]="lrDtoDestMemo.dateInDate" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Memo Less Amount</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-rupee-sign"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoMemoLessAmt" [(ngModel)]="lrDtoDestMemo.memoLessAmt" [readonly]="isReadOnly" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <div class="form-group">
                                                                    <div class="input-group">
                                                                        <label>Memo Less Type</label>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"> <i
                                                                                    class="fas fa-search"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}destMemoMemoLessType" [(ngModel)]="lrDtoDestMemo.memoLessType" [readonly]="isReadOnly">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12">
                                                                <button style="padding: 1px 4px; float: right; margin-top: 1pc;" type="submit" class="btn btn-dark" id="{{pageId}}clearPopupBtn" (click)="d('Cross click')">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <a *ngIf="lblDestCashMemo" style="cursor: pointer; color: blue;" (click)="open3(content3)"><u>{{showDestCashMemo}}</u></a>
                                            <h6 *ngIf="lblDestCashMemoNo">NA</h6>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Consignee Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>

                                            <textarea type="text" class="form-control" id="{{pageId}}consigneeName" rows="4" style="cursor: pointer; font-weight: bold; color: blue;" aria-describedby="basic-addon11" [readonly]="isReadOnly" (click)="showConsigneeDetails()"></textarea>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Charged Weight</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                </span>
                                            </div>
                                            <input type="number" class="form-control" id="{{pageId}}chargedWeight" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <h6 style="color: green; padding-left: 10px;">
                                                <b>{{showDestMemoStatus}}</b>
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>e-Invoice ACK No</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="ti-ticket"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly" id="{{pageId}}eInvNoConsignor" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>



                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Consignee Area</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}consigneeArea" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>To Pay</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <input type="number" class="form-control" id="{{pageId}}toPay" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Entered By (Godown)</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}enteredByGodown" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Self LR</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}selfLr" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Pack Nature</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-truck-loading"></i>
                                                </span>
                                            </div>
                                            <input type="text" id="{{pageId}}packNature" class="form-control" aria-describedby="basic-addon11" [readonly]="isReadOnly">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <div class="input-group" id="{{pageId}}gstDetained">
                                            <label>GST Detained</label> <a *ngIf="showGstDetainedFields" style="cursor: pointer; color: blue;" (click)="getGstDetainedFlow()"><u>
                                                    View
                                                    Details</u></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3"></div>



                                <div class="col-sm-12 col-md-3"></div>
                                <div class="col-sm-12 col-md-3"></div>
                                <div class="col-sm-12 col-md-3"></div>
                                <div class="col-sm-12 col-md-3"></div>



                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>
<div *ngIf="viewLrEntryPrint" onafterprint="afterPrint()" id="{{pageId}}printLrEntryForm">
    <app-lrentryform-print3></app-lrentryform-print3>
</div>

<div class="col-md-12" id="{{pageId}}hideLrPrintCopy">
    <div class="form-group">
        <div class="input-group">
            <ng-template #lrPrintCopyPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div *ngIf="viewLrPrintCopyPopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupLrPrintCopyDetails">
                                            <app-lrentryform-print3></app-lrentryform-print3>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}confrimBtnId" (click)="confirmBtnPopup()">Print</button>
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeBtnPopup()">Cancel</button>
                                    </div>
                                    <div class="col-sm-12 col-md-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<!-- vehicle generate DC -->
<ng-template #contentVehicleUpdatePopup let-c="close " let-d="dismiss ">
    <div class="modal-header ">
        <h6 class="modal-title " id="{{pageId}}modal-basic-title ">Generate Delivery Challan</h6>

        <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') ">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <div class="row ">
            <div class="col-md-12 ">
                <div class="col-sm-12 col-md-12 ">
                    <div class="control ">
                        <div class="form-group ">
                            <div class="input-group " id="{{pageId}}scrollable-dropdown-menu ">
                                <label>Vehicle Number</label>
                                <div class="input-group-prepend ">
                                    <span class="input-group-text "> <i class="ti-truck "></i>
                                    </span>
                                </div>
                                <input #vehicleNoForDC id="{{pageId}}vehicleNoForDC" type="text" class="form-control" aria-describedby="basic-addon11">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 ">
                    <button type="submit " class="btn btn-dark " (click)="validateEwayDCGeneration() ">Generate
                        DC</button>
                    <button style="padding: 1px 4px; float:right; " type="submit " class="btn btn-dark " id="{{pageId}}clearPopupBtn " (click)="d( 'Cross click') ">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>