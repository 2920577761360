//Chg_CM_1 : Merge All Consignee details as per discuss (Asrar Jr / 23-09-2024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { DatePipe } from "@angular/common";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { InvoiceDto } from "src/app/dto/Invoice-dto";
@Component({
	selector: 'app-consignee-merge',
	templateUrl: './consignee-merge.component.html',
	styleUrls: ['./consignee-merge.component.css']
})
export class ConsigneeMergeComponent implements OnInit {
	//to insert value of selected row in table to input field starts 
	consigneeName: string;
	selectedRowForUndo: any;
	//to insert value of selected row in table to input field ends

	getDataFrmServiceFrRepeatedConsigneeListTable: any;
	getDataFrmServiceFrMergeConsigneeListTable: any;
	getDataFrmServiceFrConfirmedConsigneeListTable: any;

	repeatedConsigneeListDataList: any;
	// mergeConsigneeListDataList: any;
	mergeConsigneeListDataList: Array<any> = [];
	confirmedConsigneeListDataList: any;


	onDestroyUnsubscribtionRepeatedConsigneeList: Subscription;
	onDestroyUnsubscribtionMergeConsigneeList: Subscription;
	onDestroyUnsubscribtionConfirmedConsigneeList: Subscription;

	//for popup modal starts
	closeResultContent: string;
	//for popup modal ends

	//for the select option with filter starts
	pageId = "cmrgc";


	controlDestination = new FormControl();
	controlConsigneeIndex = new FormControl();

	consigneeIndexOptions = [
		{ id: 1, label: 'A' },
		{ id: 2, label: 'B' },
		{ id: 3, label: 'C' },
		{ id: 4, label: 'D' },
		{ id: 5, label: 'E' },
		{ id: 6, label: 'F' },
		{ id: 7, label: 'G' },
		{ id: 8, label: 'H' },
		{ id: 9, label: 'I' },
		{ id: 10, label: 'J' },
		{ id: 11, label: 'K' },
		{ id: 12, label: 'L' },
		{ id: 13, label: 'M' },
		{ id: 14, label: 'N' },
		{ id: 15, label: 'O' },
		{ id: 16, label: 'P' },
		{ id: 17, label: 'Q' },
		{ id: 18, label: 'R' },
		{ id: 19, label: 'S' },
		{ id: 20, label: 'T' },
		{ id: 21, label: 'U' },
		{ id: 22, label: 'V' },
		{ id: 23, label: 'W' },
		{ id: 24, label: 'X' },
		{ id: 25, label: 'Y' },
		{ id: 26, label: 'Z' }
	];


	//for the select option with filter ends


	//summaryTable:any;

	loadingIndicator = true;


	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;



	dataTable: any;


	//

	dtTriggerRepeatedConsigneeList: Subject<any> = new Subject();
	dtTriggerMergeConsigneeList: Subject<any> = new Subject();
	dtTriggerConfirmedConsigneeList: Subject<any> = new Subject();

	//


	// @ViewChild('dataTable') table: ElementRef;
	dtOptionsRepeatedConsigneeList: any;
	dtOptionMergeConsigneeList: any;
	dtOptionConfirmedConsigneeList: any;

	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	//for ConsigneeName
	consigneeNameTA: Array<PartyMasterDto> = [];
	consigneeNameTATemp: Array<PartyMasterDto> = [];
	focusConsigneeNameTA$ = new Subject<string>();
	consigneeNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeNameTA
				: this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
	///
	//For consignee Index
	public modelConsigneeIndex: any;
	@ViewChild('instanceConsigneeIndex') instanceConsigneeIndex: NgbTypeahead;
	consigneeIndexTA: Array<any> = [];
	focusConsigneeIndexTA$ = new Subject<string>();
	consigneeIndexSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeIndexTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeIndexTA
				: this.consigneeIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	///
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	public modelDestinationFotGet: any;

	modelConsigneeName: any;
	consigneeNameOptions: any;
	partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
	selectedConsigneeIndex: any;
	selectedDestination: any;
	address: any;
	office: any;
	destView = false;
	viewDestination = false;
	selectedIndex: any;
	selectedConsigneeName: any;
	setDestination: Array<any> = [];
	ShowDestForConsigneeMerge = 'Consignee Merge Dest View';
	partyMasterDtoConsigneeRpt: PartyMasterDto = new PartyMasterDto();
	dataForPopupFromShipmentStatusOtherDetails: any;
	invoiceDtoShipmentForConsignee: InvoiceDto = new InvoiceDto();
	lrDtoDestinationToAdd: LRDto = new LRDto();
	mainStn: any;
	partyMasterDtoConsigneeIndex: PartyMasterDto = new PartyMasterDto();
	partyDtoIdList: Array<PartyMasterDto> = [];
	partyDtoIdListForConfirmBtn: Array<PartyMasterDto> = [];
	partyMasterDtoConfrimBtnConsignee: PartyMasterDto = new PartyMasterDto();
	partyMasterDtoUndoBtn: PartyMasterDto = new PartyMasterDto();
	getValRetOfConfrim: any;
	getValRetOfUndo: any;
	oldCgneeName: any;
	oldConsigneeId = 0;
	private newAttributeForMerge: any = {};
	private newAttributeForRepeat: any = {};
	partyMasterDtoMergeBtnSingle: PartyMasterDto = new PartyMasterDto();
	partyMasterDtoMergeBtnList: PartyMasterDto = new PartyMasterDto();
	partyDtoIdListForMergeBtn: Array<PartyMasterDto> = [];
	selectedRowForMerge: any;
	contentHeader = "Consignee Merge";
	partyMasterDtoMergeAll: PartyMasterDto = new PartyMasterDto();

	constructor(private router: Router, private modalService: NgbModal, private masterReadService: MasterReadService, private datePipe: DatePipe, private consigneeService: ConsigneeService, public changeDetectorRef: ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			this.mainStn = this.userDataDtoReturnSession.mainStation == null ? '' : this.userDataDtoReturnSession.mainStation;
			this.dataForPopupFromShipmentStatusOtherDetails = JSON.parse(localStorage.getItem('popupShipmentStatusForMerge'));
			localStorage.clear();
			if (this.dataForPopupFromShipmentStatusOtherDetails != null) {
				this.invoiceDtoShipmentForConsignee = new InvoiceDto();
				this.invoiceDtoShipmentForConsignee = this.dataForPopupFromShipmentStatusOtherDetails;
				this.contentHeader = "Consignee Merge InvoiceWise";

			} else {
				this.contentHeader = "Consignee Merge";
			}

			this.dataForPopupFromShipmentStatusOtherDetails = '';

			///Rights for show Show Destination List
			//            if ( this.ShowDestForConsigneeMerge == "Consignee Merge Dest View" ) {
			//               this.destView = true;	 
			//} 

			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {

				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Consignee Merge Dest View") {
						this.destView = true;
					}

				}
			}
			//need to comment this below
			// this.destView =true;
			if (this.destView == true) {
				//  this.viewDestination=true;
				this.getDestinationDetails();
			} else {
				// this.viewDestination=true;
				if ((this.invoiceDtoShipmentForConsignee != null) && (this.invoiceDtoShipmentForConsignee.mode != null) && (this.invoiceDtoShipmentForConsignee.mode == 'shipmentstatus')) {

				} else {
					this.destinationTA = [];
					this.lrDtoDestinationToAdd = new LRDto();
					this.lrDtoDestinationToAdd.destination = this.userDataDtoReturnSession.mainStation;
					this.destinationTA.push(this.lrDtoDestinationToAdd);
				}

			}
			this.setConsigneeIndex();
			this.gridCreationRepeatCgnee(this.userDataDtoReturnSession.mainStation);
			this.gridCreationConfirmedCgnee(this.userDataDtoReturnSession.mainStation, 'A');

		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}
	setConsigneeIndex() {
		for (let i = 0; i < this.consigneeIndexOptions.length; i++) {
			this.consigneeIndexTA.push(this.consigneeIndexOptions[i].label);
		}
	}
	ngOnInit(): void {

		//first datatable starts
		this.dtOptionsRepeatedConsigneeList = {
			// the below code is for button export starts
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 350,
			"scrollCollapse": true,
			"paging": false,
			"info": true,

		},//first datatable ends
			//second datatable starts 
			this.dtOptionMergeConsigneeList = {
				dom: 'Bfrtip',
				buttons: [],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 330,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
			},
			//second datatable ends
			//third datatable starts

			this.dtOptionConfirmedConsigneeList = {
				// the below code is for button export starts
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							return "Confirmed Consignee Details"
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 350,
				"scrollCollapse": true,
				"paging": false,
				"info": true,
			}
	}



	ngOnDestroy(): void {
		this.dtTriggerRepeatedConsigneeList.unsubscribe();
		this.dtTriggerMergeConsigneeList.unsubscribe();
		this.dtTriggerConfirmedConsigneeList.unsubscribe();
	}

	ngAfterViewInit(): void {
		// this.dtTriggerRepeatedConsigneeList.next();
		this.dtTriggerMergeConsigneeList.next();
		this.dtTriggerConfirmedConsigneeList.next();

	}
	//to insert value of selected row in table to input field starts

	//to insert value of selected row in table to input field ends
	//for popup modal starts 

	openPopup(content) {
		this.modalService.open(content, { centered: true }).result.then(
			resultContent => {
				this.closeResultContent = `Closed with: ${resultContent}`;
			},
			reason => {
				this.closeResultContent = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
	//for popup modal ends
	//for the select option with filter starts

	transform(items: any[], searchTerm: string, labelKey?: string): any {
		if (!items || !searchTerm) {
			return items;
		}

		return items.filter(
			item =>
				item[labelKey || 'label']
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) === true
		);
	}

	//for the select option with filter ends

	//To Get Destination List
	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "destinationOnly";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}

					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');

	}
	consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
		this.modelDestinationFotGet = e.item;
		this.gridCreationRepeatCgnee(this.modelDestinationFotGet.destination);
		this.gridCreationConfirmedCgnee(this.modelDestinationFotGet.destination, 'A');
		//   this.getConsigneeDetails(true);
	}

	rowSelectedConsigneeIndex(e: NgbTypeaheadSelectItemEvent) {
		this.modelConsigneeIndex = e.item;
		this.selectedConsigneeIndex = this.modelConsigneeIndex;
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		if (this.selectedDestination != null && this.selectedDestination !== '' && this.selectedDestination != undefined) {
			this.gridCreationConfirmedCgnee(this.selectedDestination, this.selectedConsigneeIndex);
		} else {
			swal({
				title: "Warning",
				text: "Please select destination",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		}
	}


	//For getting the gridCreationRepeatCgnee Onload Service
	gridCreationRepeatCgnee(destination) {
		this.partyMasterDtoConsigneeRpt = new PartyMasterDto();
		if ((this.invoiceDtoShipmentForConsignee != null) && (this.invoiceDtoShipmentForConsignee.mode != null) && (this.invoiceDtoShipmentForConsignee.mode == 'shipmentstatus')) {
			this.partyMasterDtoConsigneeRpt.mode = 'specificInvoice';
			this.partyMasterDtoConsigneeRpt.invoiceNumber = this.invoiceDtoShipmentForConsignee.invoiceNumber;
		} else {
			this.partyMasterDtoConsigneeRpt.mode = 'specific';
		}
		this.partyMasterDtoConsigneeRpt.office = destination;
		this.partyMasterDtoConsigneeRpt.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.partyMasterDtoConsigneeRpt);
		this.gridCreationRepeatCgneeInfo(destination);
	}
	gridCreationRepeatCgneeInfo = (destination) => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeRepeatDetails(this.partyMasterDtoConsigneeRpt).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if ((this.invoiceDtoShipmentForConsignee != null) && (this.invoiceDtoShipmentForConsignee.mode != null) && (this.invoiceDtoShipmentForConsignee.mode == 'shipmentstatus')) {
					this.destinationTA = [];
					this.lrDtoDestinationToAdd = new LRDto();
					this.lrDtoDestinationToAdd.destination = this.userDataDtoReturnSession.mainStation;
					this.destinationTA.push(this.lrDtoDestinationToAdd);
				}
				$("#" + this.pageId + "repeatedConsigneeId").DataTable().destroy();
				this.repeatedConsigneeListDataList = [];
				console.log('gridCreationRepeatCgneeInfo');
				console.log(response);
				$("#" + this.pageId + "destination").val(destination);
				if (response.length == 0) {
					swal({
						title: "Empty",
						text: "No  Repeated Consignee Details available",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.repeatedConsigneeListDataList = response;
					console.log(this.repeatedConsigneeListDataList);

				}
				this.dtTriggerRepeatedConsigneeList.next();
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Repeated Consignee's  details", "info");
			}, () => console.log('done');
	};


	//For getting the gridCreationConfirmedCgnee Onload Service
	gridCreationConfirmedCgnee(destination, index) {
		this.partyMasterDtoConsigneeIndex = new PartyMasterDto();
		this.partyMasterDtoConsigneeIndex.mode = 'Index';
		this.partyMasterDtoConsigneeIndex.indexValue = index;
		this.partyMasterDtoConsigneeIndex.office = destination;
		this.partyMasterDtoConsigneeIndex.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.partyMasterDtoConsigneeIndex);
		this.gridCreationConfirmedCgneeInfo(destination);
	}
	gridCreationConfirmedCgneeInfo = (destination) => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeConfirmedDetails(this.partyMasterDtoConsigneeIndex).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "confirmedConsigneeId").DataTable().destroy();
				this.confirmedConsigneeListDataList = [];
				console.log('gridCreationRepeatCgneeInfo');
				console.log(response);
				if (response.length == 0) {
					swal({
						title: "Empty",
						text: "No Confrimed Consignee Details available",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {
					this.confirmedConsigneeListDataList = response;
					console.log(this.confirmedConsigneeListDataList);
				}
				this.dtTriggerConfirmedConsigneeList.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Confirmed Consignee's  details", "info");
			}, () => console.log('done');
	};

	rowCheckBoxChecked(e, repeatedConsigneeListData) {
		if (e.currentTarget.checked) {
			if (this.partyDtoIdList.length == 0) {
				this.partyDtoIdList = [];
				this.partyDtoIdList.push(repeatedConsigneeListData);
			} else {
				this.partyDtoIdList.push(repeatedConsigneeListData);
			}

			this.selectedRowForMerge = '';
			this.selectedRowForMerge = repeatedConsigneeListData;
			if (repeatedConsigneeListData.consigneeName != null) {
				this.consigneeName = this.selectedRowForMerge.consigneeName;
				$("#" + this.pageId + "consigneeNameConfirmed").val(this.selectedRowForMerge.consigneeName);
			}

		} else {
			this.selectedRowForMerge = '';
			$("#" + this.pageId + "consigneeNameConfirmed").val('');
			this.selectedConsigneeName = '';

			let index = this.partyDtoIdList.indexOf(repeatedConsigneeListData);
			if (index > -1) {
				this.partyDtoIdList.splice(index, 1);
			}

		}
		console.log(this.partyDtoIdList);
	}
	//On Confirm Btn Clicked
	confrimBtnMethod() {
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeNameConfirmed").val();

		if (this.partyDtoIdList.length == 0) {
			swal({
				title: "Empty Selection",
				text: "Please select Consignee to confirm!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else if (this.partyDtoIdList.length > 1) {
			swal({
				title: "Not Allowed",
				text: "Please select single Consignee to confirm, multiple Consignee not allowed for Confirm!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else if (this.selectedConsigneeName == null || this.selectedConsigneeName == '' || this.selectedConsigneeName == undefined) {
			swal({
				title: "Empty Selection",
				text: "Please select/re select Consignee to confirm!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			swal({
				title: "Confirm Individual",
				text: "Sure you want to Confirm the selected consignees as an Individual",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((update) => {
				if (update) {
					this.getUserValuesForConfirm();

				} else {
					//do nothing
				}
			});

		}
	}

	getUserValuesForConfirm() {
		//	this.partyMasterDtoConfrimBtnConsignee = new PartyMasterDto();
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '') {
			this.selectedConsigneeName = this.selectedConsigneeName.trim().split(' ').filter(item => item.length > 0).join(' ');
		}

		this.partyDtoIdListForConfirmBtn = [];
		for (let i = 0; i < this.partyDtoIdList.length; i++) {
			this.partyMasterDtoConfrimBtnConsignee = new PartyMasterDto();
			this.partyMasterDtoConfrimBtnConsignee.userName = this.userDataDtoReturnSession.userId;
			this.partyMasterDtoConfrimBtnConsignee.consigneeId = this.partyDtoIdList[i].consigneeId;
			this.partyMasterDtoConfrimBtnConsignee.consigneeName = this.selectedConsigneeName;
			this.partyDtoIdListForConfirmBtn.push(this.partyMasterDtoConfrimBtnConsignee);
		}
		console.log(this.partyDtoIdListForConfirmBtn);
		this.getUserValuesForConfirmInfo();
	}
	getUserValuesForConfirmInfo = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.consigneeConfirmed(this.partyDtoIdListForConfirmBtn).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log(response);
				this.getValRetOfConfrim = response;
				if (this.getValRetOfConfrim.status == 'Success') {
					this.gridSummaryRepeat();
					this.gridSummaryConfirmed();
					$("#" + this.pageId + "consigneeNameConfirmed").val('');
					this.oldCgneeName = '';
					this.oldConsigneeId = 0;
					this.partyDtoIdList = [];

				} else if (this.getValRetOfConfrim.status == 'NotExist') {
					swal({
						title: "Failed",
						text: "Consignee Not Exist",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					swal({
						title: "Failed",
						text: "Problem occur while confirm the consignee",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While  the Confirmed Consignee's  details", "info");
			}, () => console.log('done');
	};

	//to insert value of selected row in table to input field starts
	rowSelectedForUndo(confirmedConsigneeListData) {
		this.selectedRowForUndo = '';
		this.selectedRowForUndo = confirmedConsigneeListData;
		if (confirmedConsigneeListData.consigneeName != null) {
			this.consigneeName = this.selectedRowForUndo.consigneeName;
			$("#" + this.pageId + "consigneeNameConfirmed").val(this.selectedRowForUndo.consigneeName);
			this.oldCgneeName = this.selectedRowForUndo.consigneeName;
			this.oldConsigneeId = this.selectedRowForUndo.consigneeId;
		}


		console.log(this.consigneeName, this.oldConsigneeId);
		console.log(this.selectedRowForUndo);
	}
	//On Undo Btn Clicked
	undoBtnMethod() {
		if (this.selectedRowForUndo.consigneeName == '' || this.selectedRowForUndo.consigneeName == null) {
			swal({
				title: "Empty Selection",
				text: "Please select consignee name to Undo",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			swal({
				title: "Confirm Undo",
				text: "Sure you want to UnConfirm the selected consignees",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((update) => {
				if (update) {
					this.getUserValuesForUndo();
				} else {
					//do nothing
				}
			});

		}
	}
	getUserValuesForUndo() {
		this.partyMasterDtoUndoBtn = new PartyMasterDto();
		this.partyMasterDtoUndoBtn.userName = this.userDataDtoReturnSession.userId;
		this.partyMasterDtoUndoBtn.consigneeId = this.oldConsigneeId;
		this.partyMasterDtoUndoBtn.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.partyMasterDtoUndoBtn);
		this.getUserValuesForUndoInfo();
	}
	getUserValuesForUndoInfo = () => {
		this.showSpinnerForAction = true;
		this.consigneeService.undoCgneeMerge(this.partyMasterDtoUndoBtn).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log(response);
				this.getValRetOfUndo = response;
				if (this.getValRetOfUndo.status == 'Success') {
					this.gridSummaryRepeat();
					this.gridSummaryConfirmed();
					$("#" + this.pageId + "consigneeNameConfirmed").val('');
					this.oldCgneeName = '';
					this.oldConsigneeId = 0;
					this.partyDtoIdList = [];
					this.selectedRowForUndo = '';
				} else if (this.getValRetOfUndo.status == 'NotExist') {
					swal({
						title: "Failed",
						text: "Consignee Not Exist",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					swal({
						title: "Failed",
						text: "Problem occur while confirm the consignee",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While  the Confirmed Consignee's  details", "info");
			}, () => console.log('done');
	};
	//Move To Merge Btn
	moveToMergeMethod() {
		if (this.partyDtoIdList.length == 0) {
			swal({
				title: "Empty Selection",
				text: "Please select consignee name to Move",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			this.addInMergeTable();

		}

	}

	addInMergeTable() {
		console.log(this.partyDtoIdList);
		console.log('inside of addInMergeTable')
		this.newAttributeForMerge = {};
		for (let i = 0; i < this.partyDtoIdList.length; i++) {
			console.log(this.partyDtoIdList[i].consigneeId);
			this.newAttributeForMerge.consigneeName = this.partyDtoIdList[i].consigneeName;
			this.newAttributeForMerge.consigneeId = this.partyDtoIdList[i].consigneeId;
			this.newAttributeForMerge.gstNoConsignee = this.partyDtoIdList[i].gstNoConsignee;
			this.newAttributeForMerge.isAdminRateSet = this.partyDtoIdList[i].isAdminRateSet;
			$("#" + this.pageId + "mergeConsigneeId").DataTable().destroy();
			if (this.mergeConsigneeListDataList == null) {
				this.mergeConsigneeListDataList = [];
			}
			if (this.mergeConsigneeListDataList.length == 0) {
				this.mergeConsigneeListDataList.push(this.newAttributeForMerge);
			} else {
				this.mergeConsigneeListDataList.push(this.newAttributeForMerge);
			}
			//this.dtTriggerMergeConsigneeList.next();
			console.log(this.mergeConsigneeListDataList);
			//For Removing
			let index = -1;
			for (let j = 0; j < this.repeatedConsigneeListDataList.length; j++) {

				if (this.repeatedConsigneeListDataList[j].consigneeId === this.partyDtoIdList[i].consigneeId) {
					console.log(this.repeatedConsigneeListDataList[j].consigneeId);
					index = j;
					break;
				}
			}
			if (index === -1) {
				alert("Something gone wrong");
			}
			this.repeatedConsigneeListDataList.splice(index, 1);
			this.newAttributeForMerge = {};
		}
		this.partyDtoIdList = [];
		this.dtTriggerMergeConsigneeList.next();
		$("#" + this.pageId + "repeatedConsigneeId").DataTable().destroy();
		this.dtTriggerRepeatedConsigneeList.next();


	}

	// For Delete From Merge Table
	rowSelectedDeleteForMerge(mergeConsigneeListData, index) {
		console.log(mergeConsigneeListData);
		swal({
			title: "Confirm Remove",
			text: "Sure you want to remove the selected Row",
			icon: "warning",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((remove) => {
			if (remove) {
				const deletedConsigneeId = this.mergeConsigneeListDataList.find(x => x.consigneeId === mergeConsigneeListData.consigneeId);
				this.mergeConsigneeListDataList.splice(this.mergeConsigneeListDataList.indexOf(deletedConsigneeId), 1);

				//this.mergeConsigneeListDataList.splice(index, 1);
				$("#" + this.pageId + "mergeConsigneeId").DataTable().destroy();
				this.dtTriggerMergeConsigneeList.next();
				$("#" + this.pageId + "consigneeNameConfirmed").val('');
				this.oldCgneeName = '';
				this.oldConsigneeId = 0;
				this.addInRepeatedConsigneeTable(mergeConsigneeListData);

			}

		});
	}

	addInRepeatedConsigneeTable(mergeConsigneeListData) {
		console.log(mergeConsigneeListData);
		console.log('inside of addInRepeatedConsigneeTable')
		this.newAttributeForRepeat = {};

		this.newAttributeForRepeat.consigneeName = mergeConsigneeListData.consigneeName;
		this.newAttributeForRepeat.consigneeId = mergeConsigneeListData.consigneeId;
		this.newAttributeForRepeat.gstNoConsignee = mergeConsigneeListData.gstNoConsignee;
		this.newAttributeForRepeat.isAdminRateSet = mergeConsigneeListData.isAdminRateSet;
		$("#" + this.pageId + "repeatedConsigneeId").DataTable().destroy();
		if (this.repeatedConsigneeListDataList == null) {
			this.repeatedConsigneeListDataList = [];
		}
		if (this.repeatedConsigneeListDataList.length == 0) {
			this.repeatedConsigneeListDataList.push(this.newAttributeForRepeat);
		} else {
			this.repeatedConsigneeListDataList.push(this.newAttributeForRepeat);
		}
		//this.dtTriggerMergeConsigneeList.next();
		console.log(this.dtTriggerRepeatedConsigneeList);

		this.newAttributeForRepeat = {};
		this.partyDtoIdList = [];
		this.dtTriggerRepeatedConsigneeList.next();
	}

	//For getting the gridSummaryRepeat
	gridSummaryRepeat() {
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.partyMasterDtoConsigneeRpt = new PartyMasterDto();
		if ((this.invoiceDtoShipmentForConsignee != null) && (this.invoiceDtoShipmentForConsignee.mode != null) && (this.invoiceDtoShipmentForConsignee.mode == 'shipmentstatus')) {
			this.partyMasterDtoConsigneeRpt.mode = 'specificInvoice';
			this.partyMasterDtoConsigneeRpt.invoiceNumber = this.invoiceDtoShipmentForConsignee.invoiceNumber;
		} else {
			this.partyMasterDtoConsigneeRpt.mode = 'specific';
		}
		if (this.selectedDestination != null && this.selectedDestination !== '' && this.selectedDestination != undefined) {
			this.partyMasterDtoConsigneeRpt.office = this.selectedDestination;
		} else {
			this.partyMasterDtoConsigneeRpt.office = this.userDataDtoReturnSession.mainStation;
		}
		//this.partyMasterDtoConsigneeRpt.office = destination;
		this.partyMasterDtoConsigneeRpt.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.partyMasterDtoConsigneeRpt);
		this.gridSummaryRepeatInfo(this.partyMasterDtoConsigneeRpt.office);
	}
	gridSummaryRepeatInfo = (destination) => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeRepeatDetails(this.partyMasterDtoConsigneeRpt).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "repeatedConsigneeId").DataTable().destroy();
				this.repeatedConsigneeListDataList = [];
				console.log('gridCreationRepeatCgneeInfo');
				console.log(response);
				$("#" + this.pageId + "destination").val(destination);
				if (response.length == 0) {
					swal({
						title: "Empty",
						text: "No  Repeated Consignee Details available",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.repeatedConsigneeListDataList = response;
					console.log(this.repeatedConsigneeListDataList);

				}
				this.dtTriggerRepeatedConsigneeList.next();
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Repeated Consignee's  details", "info");
			}, () => console.log('done');
	};

	//For getting the gridCreationConfirmedCgnee
	gridSummaryConfirmed() {
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedConsigneeIndex = $("#" + this.pageId + "consigneeIndex").val();
		this.partyMasterDtoConsigneeIndex = new PartyMasterDto();
		this.partyMasterDtoConsigneeIndex.mode = 'Index';
		if (this.selectedConsigneeIndex != null && this.selectedConsigneeIndex !== '' && this.selectedConsigneeIndex != undefined) {
			this.partyMasterDtoConsigneeIndex.indexValue = this.selectedConsigneeIndex;
		} else {
			this.partyMasterDtoConsigneeIndex.indexValue = 'A';
		}
		//this.partyMasterDtoConsigneeIndex.indexValue = index;

		if (this.selectedDestination != null && this.selectedDestination !== '' && this.selectedDestination != undefined) {
			this.partyMasterDtoConsigneeIndex.office = this.selectedDestination;
		} else {
			this.partyMasterDtoConsigneeIndex.office = this.userDataDtoReturnSession.mainStation;
		}
		//this.partyMasterDtoConsigneeIndex.office = destination;

		this.partyMasterDtoConsigneeIndex.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.partyMasterDtoConsigneeIndex);
		this.gridSummaryConfirmedInfo(this.partyMasterDtoConsigneeIndex.office);
	}
	gridSummaryConfirmedInfo = (destination) => {
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeConfirmedDetails(this.partyMasterDtoConsigneeIndex).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "confirmedConsigneeId").DataTable().destroy();
				this.confirmedConsigneeListDataList = [];
				console.log('gridCreationRepeatCgneeInfo');
				console.log(response);

				if (response.length == 0) {
					swal({
						title: "Empty",
						text: "No  Confrimed Consignee Details available",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.confirmedConsigneeListDataList = response;
					console.log(this.confirmedConsigneeListDataList);

				}
				this.dtTriggerConfirmedConsigneeList.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Confirmed Consignee's  details", "info");
			}, () => console.log('done');
	};

	//On Confirm Btn Clicked
	mergeBtnMethod() {
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeNameConfirmed").val();
		if (this.mergeConsigneeListDataList.length == 0 || this.selectedConsigneeName == null || this.selectedConsigneeName == '' || this.selectedConsigneeName == undefined) {

			swal({
				title: "Empty Selection",
				text: "Please select consignee name to merge",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			swal({
				title: "Confirm Merge",
				text: "Sure you want to Merge the selected consignee",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((update) => {
				if (update) {
					this.getUserValuesForMerge();

				} else {
					//do nothing
				}
			});

		}
	}

	getUserValuesForMerge() {
		this.partyDtoIdListForMergeBtn = [];
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeNameConfirmed").val()

		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '') {
			this.selectedConsigneeName = this.selectedConsigneeName.trim().split(' ').filter(item => item.length > 0).join(' ');
		}
		for (let i = 0; i < this.mergeConsigneeListDataList.length; i++) {
			this.partyMasterDtoMergeBtnList = new PartyMasterDto();
			this.partyMasterDtoMergeBtnList.consigneeId = this.mergeConsigneeListDataList[i].consigneeId;
			this.partyMasterDtoMergeBtnList.consigneeName = this.mergeConsigneeListDataList[i].consigneeName;
			this.partyDtoIdListForMergeBtn.push(this.partyMasterDtoMergeBtnList);
		}

		this.partyMasterDtoMergeBtnSingle = new PartyMasterDto();
		this.partyMasterDtoMergeBtnSingle.consigneeName = this.selectedConsigneeName;
		this.partyMasterDtoMergeBtnSingle.userName = this.userDataDtoReturnSession.userId;
		this.partyMasterDtoMergeBtnSingle.oldCgneeName = this.oldCgneeName;
		this.partyMasterDtoMergeBtnSingle.oldConsigneeId = this.oldConsigneeId;
		this.partyMasterDtoMergeBtnSingle.partyMasterDtos = this.partyDtoIdListForMergeBtn;
		this.partyMasterDtoMergeBtnSingle.companyId = this.userDataDtoReturnSession.companyId;

		console.log(this.partyMasterDtoMergeBtnSingle);
		//Need to comment this below 

		this.doMerge();
		//this.toRecalculateMemoAfterCgneeMerge();

	}
	/*toRecalculateMemoAfterCgneeMerge = () => {
		this.showSpinnerForAction = true;
		//PENDING
				
		this.consigneeService.getConsigneeConfirmedDetails(this.partyMasterDtoMergeBtnSingle).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					this.doMerge();

				} else {
					this.doMerge();

				}
					

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Re Calculate Memo After Consingnee Merge", "info");
			}, () => console.log('done');
	};*/


	doMerge = () => {
		this.showSpinnerForAction = true;
		console.log('Inside Do merge method');
		console.log(this.partyMasterDtoMergeBtnSingle);
		this.consigneeService.consigneeMerge(this.partyMasterDtoMergeBtnSingle).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.status == 'Success') {
					$("#" + this.pageId + "mergeConsigneeId").DataTable().destroy();
					this.mergeConsigneeListDataList = [];
					this.dtTriggerMergeConsigneeList.next();
					this.gridSummaryRepeat();
					this.gridSummaryConfirmed();
					$("#" + this.pageId + "consigneeNameConfirmed").val('');
					this.oldCgneeName = '';
					this.oldConsigneeId = 0;
					this.partyDtoIdList = [];
					this.partyDtoIdListForMergeBtn = [];
					this.selectedConsigneeName = '';
					this.selectedRowForMerge = '';
					this.consigneeName = '';
					swal({
						title: "Success",
						text: "Merged Successfully",
						icon: "success",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					//PENDING
					//ConsigneePendingDetails.serviceCall();

				} else if (response.status == 'Failed') {
					swal({
						title: "Failed",
						text: "Problem occur while confirm the consignee",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				}

				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred while confirm the consignee", "info");
			}, () => console.log('done');
	};

	//to insert value of selected row in table to input field starts
	// rowSelectedFromMerge(mergeConsigneeListData) {
	// 	console.log('rowSelectedFromMerge');
	// 	this.selectedRowForMerge = '';
	// 	this.selectedRowForMerge = mergeConsigneeListData;
	// 	if (mergeConsigneeListData.consigneeName != null) {
	// 		this.consigneeName = this.selectedRowForMerge.consigneeName;
	// 		$("#" + this.pageId + "consigneeNameConfirmed").val(this.selectedRowForMerge.consigneeName);
	// 		this.oldCgneeName = this.selectedRowForMerge.consigneeName;
	// 		this.oldConsigneeId = this.selectedRowForMerge.consigneeId;
	// 	}
	// }

	rowSelectedFromRepeated(repeatedConsigneeListData) {
		console.log('rowSelectedFromRepeated');
		this.selectedRowForMerge = '';
		this.selectedRowForMerge = repeatedConsigneeListData;
		if (repeatedConsigneeListData.consigneeName != null) {
			this.consigneeName = this.selectedRowForMerge.consigneeName;
			$("#" + this.pageId + "consigneeNameConfirmed").val(this.selectedRowForMerge.consigneeName);
		}
	}

	//Chg_CM_1
	getNewConsigneeDetailsAndDoMergeMethod() {
		this.partyMasterDtoMergeAll = new PartyMasterDto();
		this.consigneeService.getNewConsigneeDetailsAndDoMerge(this.partyMasterDtoMergeAll).subscribe(
			(response) => {
				console.log(response);
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				swal("Error", "Server problem occurred while getting the Consignee Details!", "error");
				console.log(error.json())
			},
			() => console.log('done');
	}


}



