<div class="container-fluid">
    <div class="row">
        <div class="col-md-10">
            <div class="card">
                <div class="card-body">
                    <div class="row" id="printSection">
                        <div class="col-md-12">
                            <div *ngIf="PrintBarcode.length === 0" class="text-center">
                                <p>No barcodes available for printing.</p>
                            </div>
                            <table class="table table-borderless">
                                <tr *ngFor="let PS of PrintBarcode" class="barcode-row">
                                    <td class="barcode-cell">
                                        <!-- Barcode Component -->
                                        <ngx-barcode6 class="barcode-print" [bc-format]="'CODE128'" 
                                            [bc-width]="3" [bc-height]="30" [bc-font-size]="15" 
                                            [bc-value]="PS.barcode" [bc-display-value]="false">
                                        </ngx-barcode6>
                                        <!-- Display Details -->
                                        <div>{{ PS.Name }}</div>
                                        <div>{{ PS.Destination }}</div>
                                        <div>Article: {{ PS.ArticleIndex }}</div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
