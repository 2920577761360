<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-4">
                    <div *ngIf="showSpinnerForAction" class="col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                </mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center'
                                    style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input id="{{pageId}}destination" type="text" class="form-control"
                                                        [(ngModel)]="modelDestination"
                                                        [ngbTypeahead]="searchDestination"
                                                        [resultFormatter]="formatterDestination"
                                                        [inputFormatter]="formatterDestination"
                                                        (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                        placeholder="Select Station" />

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-road"></i>
                                                        </span>
                                                    </div>
                                                    <input id="{{pageId}}source" type="text" class="form-control"
                                                        [(ngModel)]="modelSource" [ngbTypeahead]="searchSource"
                                                        [resultFormatter]="formatterSource"
                                                        [inputFormatter]="formatterSource"
                                                        (focus)="focusSourceTA$.next($any($event).target.value)"
                                                        placeholder="Select Source" />
                                                </div>
                                            </div>
                                        </div>

                                        &nbsp;
                                        <div class="col-sm-12 col-md-12">
                                            <div class="col-md-12" style="text-align: center;">
                                                <button type="submit" class="btn btn-success m-r-10"
                                                    id="{{pageId}}saveBtn" #saveBtn
                                                    (click)="getDestinationwiseStocksRptDetails()">Search</button>
                                                <button type="submit" class="btn btn-dark m-r-10"
                                                    id="{{pageId}}clearBtn" (click)="clearDetails()">Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 vl">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title">Destination Stocks Report Details</h6>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}destinationStocksRptTable"
                                            class="table table-striped table-bordered row-border hover"
                                            [dtOptions]="dtOptionsdestinationStocksRpt"
                                            [dtTrigger]="dtTriggerdestinationStocksRpt">

                                            <thead>
                                                <tr>
                                                    <th>Source</th>
                                                    <th>Total LRs</th>
                                                    <th>No Of Articles</th>
                                                    <th>To Pay</th>
                                                    <th>Paid</th>
                                                    <th>Act Wt</th>
                                                    <th>Chg Wt</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let destinationStocksRptDetailData of destinationStocksRptDetails ">
                                                    <td>{{ destinationStocksRptDetailData.source }}</td>
                                                    <td>{{ destinationStocksRptDetailData.totalLrs }}</td>
                                                    <td>{{ destinationStocksRptDetailData.totalArticles }}</td>
                                                    <td>{{ destinationStocksRptDetailData.toPay }}</td>
                                                    <td>{{ destinationStocksRptDetailData.paid }}</td>
                                                    <td>{{ destinationStocksRptDetailData.actualWeight }}</td>
                                                    <td>{{ destinationStocksRptDetailData.chargedWeight }}</td>
                                                </tr>

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>