//Chg_DlyBkg_V1 : Specific for destination and source for All as per discuss (Asrar Jr / 21-10-2024)
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
const my = new Date();
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from "@angular/core";
import { Subject, merge } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { Router } from "@angular/router";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { LrService } from "src/app/dataService/lr-service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import * as moment from "moment";
import swal from "sweetalert";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";

@Component({
  selector: "app-daily-booking-report",
  templateUrl: "./daily-booking-report.component.html",
  styleUrls: ["./daily-booking-report.component.css"],
})
export class DailyBookingReportComponent implements OnInit {
  model: NgbDateStruct;
  model2;
  loadingIndicator = true;
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  defaultGodownName: string;
  getDataFrmServiceFrTable: any;
  dailyBkgReportDataList: any;
  viewByGodownRpt = false;
  viewByOrderGoodsRpt = false;
  pageTitle = "Daily Booking Stock Report";
  controlMainSource = new FormControl();
  controlMainDestination = new FormControl();

  //    agentDetailsSourceOptions: AgentDetails[];
  //    /    srcAllForRpt = "Daily Bkg Rpt Show Src All";
  lrDtosDestinationOptionsGet: LRDto[];
  //    lrDtosDestinationOptions:any;
  //    lrDtosDestinationOptionsGet:any;
  lrDto: LRDto = new LRDto();
  //    lrDtoFromStation: LRDto = new LRDto();
  //    lrDtoFromRet: any;
  lrDtoToStation: LRDto = new LRDto();
  lrDtoToRet: any;
  userDataDtoReturnSession: any;
  isLoggedIn = true;
  selectedSource: any;
  selectedDest: any;
  selectedFromDate: any;
  selectedToDate: any;
  agentDto: AgentDetailsDto = new AgentDetailsDto();
  agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
  lrDtoToStationAll: LRDto = new LRDto();
  agentDetailsSourceOptions: AgentDetailsDto[];
  selectedLrNumber: any;

  //    agentDetailsSourceOptions: any;
  //    agentDetailsSourceOptionsGet: any;
  srcForRpt = null;

  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;

  dtTriggerDailyBkgReport: Subject<any> = new Subject();
  showSpinnerForAction = false;

  sourceOption: Array<AgentDetailsDto> = [];
  agentDetailsDtoSource: AgentDetailsDto = new AgentDetailsDto();
  public modelDropDownSource: any;
  sourceTA: Array<AgentDetailsDto> = [];
  focusDropDownSourceTA$ = new Subject<string>();
  searchDropDownInputFieldSource = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusDropDownSourceTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.sourceTA
          : this.sourceTA.filter(
            (v) => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterDropDownSource = (x: { subStation: string }) => x.subStation;

  destinationOption: Array<LRDto> = [];
  lrDtoDestination: LRDto = new LRDto();
  public modelDropDownDestination: any;
  destinationTA: Array<LRDto> = [];
  focusDropDownDestinationTA$ = new Subject<string>();
  searchDropDownInputFieldDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusDropDownDestinationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.destinationTA
          : this.destinationTA.filter(
            (v) => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterDropDownDestination = (x: { godownName: string }) => x.godownName;

  dataTable: any;
  dtOptionsDailyBkgReport: any;

  //for custom print
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;

  viewCustomPrintV1 = false;
  fromDatePrint: any;
  toDatePrint: any;
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  destForRpt = null;

  pageId = "dlbrc";

  constructor(
    private masterReadService: MasterReadService,
    private router: Router,
    private lrService: LrService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      //          sweet alert starts
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }

    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );

      if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
        for (
          let i = 0;
          i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length;
          i++
        ) {
          if (
            this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
            this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            "Daily Bkg Rpt Show Src All"
          ) {
            this.srcForRpt = "Daily Bkg Rpt Show Src All";
          } else if (
            this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
            this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            "Daily Bkg Rpt Show Src Specific"
          ) {
            this.srcForRpt = "Daily Bkg Rpt Show Src Specific";
          }
          //Chg_DlyBkg_V1
          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
            this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Specific Destination") {
            this.destForRpt = "Specific Destination";
          }
        }
      }

      //Chg_DlyBkg_V1
      // this.getToStationDetailsList();
      if (this.destForRpt == "Specific Destination") {
        this.defaultGodownName = this.userDataDtoReturnSession.office;
        this.destinationOption = [];
        this.destinationTA = [];
        this.lrDtoToStationAll.godownName = this.defaultGodownName;
        this.destinationTA.push(this.lrDtoToStationAll);
      } else {
        this.getToStationDetailsList();
      }

      if (this.srcForRpt == "Daily Bkg Rpt Show Src All") {
        this.getAgentListDetails("SrcAll");
        //                this.defaultGodownName ='All';
      } else if (this.srcForRpt == "Daily Bkg Rpt Show Src Specific") {
        this.getAgentListDetails("specific");
        //                this.defaultGodownName ='All';
      } else {
        this.defaultGodownName = this.userDataDtoReturnSession.office;
        this.sourceOption = [];
        this.sourceTA = [];
        this.agentDtoAll.subStation = this.defaultGodownName;
        this.sourceTA.push(this.agentDtoAll);
      }
    }
  }

  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Do your stuff
        dtInstance.destroy();
      });
    });
  }
  ngOnInit(): void {
    var groupColumnDestination = 0;
    this.dtOptionsDailyBkgReport = {
      dom: "Bfrtip",
      /*buttons: [
                      'excel', 'print'
                  ],*/
      buttons: [
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          footer: true,
          title: function () {
            return (
              "Daily Booking Report - " +
              "From Date : " +
              moment($("#dlbrcfromDate").val()).format("DD-MM-YYYY") +
              " -  " +
              "To Date : " +
              moment($("#dlbrctoDate").val()).format("DD-MM-YYYY") +
              ""
            );
          },
          exportOptions: {
            columns: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
              35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52
            ],
          },
        },
      ],

      //place holder in search/filter in datatable starts
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      //place holder in search/filter in datatable ends

      processing: true,
      //scroll in datatable starts
      responsive: true,
      scrollX: true,
      scrollY: 380,
      scrollCollapse: true,
      //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
      paging: false,
      info: false,
      //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
      //scroll in datatable ends
      drawCallback: function (settings) {
        var api = this.api();
        var rows = api
          .rows({
            page: "current",
          })
          .nodes();
        var last = null;

        var api = this.api();
        var rows = api
          .rows({
            page: "current",
          })
          .nodes();
        var last = null;
        var totArt = 0;
        var actWgt = 0;
        var chgWgt = 0;
        var baseFrgt = 0;
        var srcHamali = 0;
        var gcChg = 0;
        var lcChg = 0;
        var bcChg = 0;
        var others = 0;
        var ddAmt = 0;
        var toPayAmt = 0;
        var paidAmt = 0;
        var fov = 0;
        var count = 0;
        api
          .column(groupColumnDestination, {
            page: "current",
          })
          .data()
          .each(function (group, i) {
            var val = api.row(api.row($(rows).eq(i)).index()).data();
            if (last !== group) {
              if (i != 0) {
                $(rows)
                  .eq(i)
                  .before(
                    $(
                      "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                      {
                        class: "group",
                        "data-id": group,
                      }
                    )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: "Total : ",
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: count,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 4,
                          text: "",
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: actWgt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: chgWgt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: "",
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: totArt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 2,
                          text: "",
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: baseFrgt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: srcHamali,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: gcChg,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: fov,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: lcChg,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: bcChg,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: others,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: "",
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: ddAmt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: toPayAmt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: paidAmt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 29,
                          text: "",
                        })
                      )
                      .prop("outerHTML")
                  );
                count = 0;
                totArt = 0;
                actWgt = 0;
                chgWgt = 0;
                baseFrgt = 0;
                srcHamali = 0;
                gcChg = 0;
                fov = 0;
                lcChg = 0;
                bcChg = 0;
                others = 0;
                ddAmt = 0;
                toPayAmt = 0;
                paidAmt = 0;
              }
              $(rows)
                .eq(i)
                .before(
                  $(
                    "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                    {
                      class: "group",
                      "data-id": group,
                    }
                  )
                    .append(
                      $("<td></td>", {
                        colspan: 51,
                        text: group,
                      })
                    )
                    .prop("outerHTML")
                );
              last = group;
            }
            count++;
            actWgt += +val[7];
            chgWgt += +val[8];
            totArt += +val[10];
            baseFrgt += +val[13];
            srcHamali += +val[14];
            gcChg += +val[15];
            fov += +val[16];
            lcChg += +val[17];
            bcChg += +val[18];
            others += +val[19];
            ddAmt += +val[21];
            toPayAmt += +val[22];
            paidAmt += +val[23];
            if (i == rows.length - 1) {
              $(rows)
                .eq(i)
                .after(
                  $(
                    "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                    {
                      class: "group",
                      "data-id": group,
                    }
                  )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: "Total : ",
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: count,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 4,
                        text: "",
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: actWgt,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: chgWgt,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: "",
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: totArt,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 2,
                        text: "",
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: baseFrgt,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: srcHamali,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: gcChg,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: fov,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: lcChg,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: bcChg,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: others,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: "",
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: ddAmt,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: toPayAmt,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: paidAmt,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 29,
                        text: "",
                      })
                    )
                    .prop("outerHTML")
                );
              count = 0;
              totArt = 0;
              actWgt = 0;
              chgWgt = 0;
              baseFrgt = 0;
              srcHamali = 0;
              gcChg = 0;
              fov = 0;
              lcChg = 0;
              bcChg = 0;
              others = 0;
              ddAmt = 0;
              toPayAmt = 0;
              paidAmt = 0;
            }
          });
      },
      footerCallback: function (row, data, start, end, display) {
        var api = this.api(),
          data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === "string"
            ? +i.replace(/[\$,]/g, "") * 1
            : typeof i === "number"
              ? i
              : 0;
        };

        var actWgt = api
          .column(7)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var chgWgt = api
          .column(8)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var totArt = api
          .column(10)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var basefrgt = api
          .column(13)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var srcHamali = api
          .column(14)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var gcChg = api
          .column(15)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var fovChg = api
          .column(16)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var lcChg = api
          .column(17)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var bcChg = api
          .column(18)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var others = api
          .column(19)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var ddAmt = api
          .column(21)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var toPay = api
          .column(22)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var paid = api
          .column(23)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        $(api.column(1).footer()).html(data.length);
        $(api.column(7).footer()).html(actWgt);
        $(api.column(8).footer()).html(chgWgt);
        $(api.column(10).footer()).html(totArt);
        $(api.column(13).footer()).html(basefrgt);
        $(api.column(14).footer()).html(srcHamali);
        $(api.column(15).footer()).html(gcChg);
        $(api.column(16).footer()).html(fovChg);
        $(api.column(17).footer()).html(lcChg);
        $(api.column(18).footer()).html(bcChg);
        $(api.column(19).footer()).html(others);
        $(api.column(21).footer()).html(ddAmt);
        $(api.column(22).footer()).html(toPay);
        $(api.column(23).footer()).html(paid);
      },
    };

    //the below code is for the getting data through json starts
    //            this.supplierList.getAllData().subscribe(data => {
    //                this.lrDispatchBknRptList = data['data'];
    //                this.dtTriggerSummary.next();
    //                } );
    //        this.getDataFrmServiceFrTable = this.dailyBkgReport.getSummaryData()
    //        this.onDestroyUnsubscribtionDailyBkgReport = this.getDataFrmServiceFrTable.subscribe(data => {
    //            this.dailyBkgReportDataList = data['data'];
    //            this.dtTriggerDailyBkgReport.next();
    //        });
  }
  changeDataTableTitile() {
    return (
      '<table><tr style="width:100%"><td style="width:60%"><img src="assets/images/srdLogisticPrintLogo.png"></td>' +
      '<td style="width:40%"><p>' +
      this.userDataDtoReturnSession.addressId +
      "</p></td></tr></table>"
    );
  }

  chnageDataTableMessageTop() {
    return (
      "<span>" +
      '<div style="text-align:center;">' +
      '<h5 style="border-bottom:1px solid;display:inline-block;"><span style="font-weight:bold">' +
      this.pageTitle +
      "</span></h5>" +
      '<h6 style="font-weight:bold;">' +
      "From : " +
      '<span style="font-weight:400;">' +
      this.lrDto.fromDate +
      "</span>" +
      "To : " +
      '<span style="font-weight:400;">' +
      this.lrDto.toDate +
      "</span>" +
      "</h6>" +
      "</div>" +
      "</span>"
    );
  }
  chnageDataTableMessageTopExcel() {
    return (
      "From: " +
      this.lrDto.fromDate +
      "           " +
      "To: " +
      this.lrDto.toDate
    );
  }

  ngOnDestroy(): void {
    this.dtTriggerDailyBkgReport.unsubscribe();

    //        this.onDestroyUnsubscribtionDailyBkgReport.unsubscribe();
  }

  //for datepicker
  // the selectToday is the method for  selecting todays'z date
  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate(),
    };
  }

  //  To Station
  getToStationDetails() {
    this.lrDtoToStation.companyId = this.userDataDtoReturnSession.companyId;
    if (this.srcForRpt == "Daily Bkg Rpt Show Src All") {
      this.lrDtoToStation.mode = "";
    }
  }
  getToStationDetailsList = () => {
    this.getToStationDetails();
    this.masterReadService
      .getDestinationListDetails(this.lrDtoToStation)
      .subscribe((response) => {
        if (response.length == 0) {
          this.destinationOption = [];
          this.destinationTA = [];
        } else {
          this.destinationOption = [];
          this.destinationTA = [];
          this.destinationOption = response;
          this.lrDtoToStationAll.godownName = "ALL";
          this.destinationTA.push(this.lrDtoToStationAll);
          for (let i = 0; i < this.destinationOption.length; i++) {
            this.destinationTA.push(this.destinationOption[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log("done");
  };
  //  From Station for Booking
  getAgentListDetails(string) {
    this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
    this.agentDto.column2 = "Working";
    if (string == "SrcAll") {
      this.agentDto.mode = "SrcAllForDlyBkgRpt";
    } else {
      this.agentDto.mode = "mainBranch";
      if (this.srcForRpt == "Daily Bkg Rpt Show Src Specific") {
        this.agentDto.mainStation = this.userDataDtoReturnSession.mainStation;
      } else {
        this.agentDto.mainStation =
          this.userDataDtoReturnSession.mainAdminStation;
      }
    }

    this.getAgentList();
  }

  getAgentList = () => {
    //        this.getAgentListDetails();
    this.masterReadService
      .getSourceListDetails(this.agentDto)
      .subscribe((response) => {
        if (response.length == 0) {
          this.sourceOption = [];
          this.sourceTA = [];
        } else {
          this.sourceOption = [];
          this.sourceTA = [];
          this.sourceOption = response;
          if (this.srcForRpt == "Daily Bkg Rpt Show Src All") {
            this.agentDtoAll.subStation = "ALL";
            this.sourceTA.push(this.agentDtoAll);
          } else {
            this.agentDtoAll.subStation = "ALL";
            this.sourceTA.push(this.agentDtoAll);
          }
          for (let i = 0; i < this.sourceOption.length; i++) {
            this.sourceTA.push(this.sourceOption[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal("Error", "Server Error While Getting Source details", "warning");
      },
      () => console.log("done");
  };

  validateBtnSearch() {
    if (
      $("#" + this.pageId + "dropDownInputFieldSource").val() == null ||
      $("#" + this.pageId + "dropDownInputFieldSource").val() == undefined ||
      $("#" + this.pageId + "dropDownInputFieldSource").val() == "" ||
      $("#" + this.pageId + "dropDownInputFieldDestination").val() == null ||
      $("#" + this.pageId + "dropDownInputFieldDestination").val() ==
      undefined ||
      $("#" + this.pageId + "dropDownInputFieldDestination").val() == ""
    ) {
      swal(
        "Mandatory Selection",
        "Please select source and destination to proceed",
        "warning"
      );
      return false;
    } else if (
      this.viewByGodownRpt == false &&
      ($("#" + this.pageId + "fromDate").val() == null ||
        $("#" + this.pageId + "fromDate").val() == undefined ||
        $("#" + this.pageId + "fromDate").val() == "" ||
        $("#" + this.pageId + "toDate").val() == null ||
        $("#" + this.pageId + "toDate").val() == undefined ||
        $("#" + this.pageId + "toDate").val() == "")
    ) {
      swal(
        "Mandatory Selection",
        "Please select From and To Date to proceed",
        "warning"
      );
      return false;
    } else {
      this.gridReconfigure();
    }
  }

  getSearchMethod() {
    this.lrDto = new LRDto();
    this.selectedSource = $(
      "#" + this.pageId + "dropDownInputFieldSource"
    ).val();
    this.selectedDest = $(
      "#" + this.pageId + "dropDownInputFieldDestination"
    ).val();
    this.lrDto.mode = null;
    if (this.viewByGodownRpt == true) {
      this.lrDto.mode = "Godown";
      this.lrDto.fromDate = null;
      this.lrDto.toDate = null;
    } else {
      this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
      this.selectedToDate = $("#" + this.pageId + "toDate").val();
      this.lrDto.fromDate = this.selectedFromDate;
      this.lrDto.toDate = this.selectedToDate;
    }
    this.lrDto.mainstation = this.userDataDtoReturnSession.mainAdminStation;
    this.lrDto.source = this.selectedSource;

    if (this.selectedSource != null && this.selectedSource == "All") {
      //            this.lrDto.listOfStation = this.userDataDtoReturnSession.stationList;
    } else if (this.selectedSource != null && this.selectedSource != "All") {
      //            this.lrDto.listOfStation = this.selectedSource;
    }
    if (this.selectedDest != null) {
      this.lrDto.destination = this.selectedDest;
    }
    this.lrDto.reportMode = this.userDataDtoReturnSession.mainAdminStation;
    if (this.viewByOrderGoodsRpt != null && this.viewByOrderGoodsRpt == true) {
      this.lrDto.isOrderGoods = true;
    } else {
      this.lrDto.isOrderGoods = false;
    }

    this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
    //Chg_DlyBkg_V1
    this.lrDto.officeType = this.userDataDtoReturnSession.officeType;
    console.log(this.lrDto);
  }

  gridReconfigure = () => {
    this.showSpinnerForAction = true;
    this.getSearchMethod();
    //this.reportService.getDailyBookingReport( this.lrDto ).subscribe(
    this.lrService.getDlyBkgDetails(this.lrDto).subscribe((response) => {
      this.dailyBkgReportDataList = [];
      $("#" + this.pageId + "dailyBkgLrsTableId")
        .DataTable()
        .destroy();
      if (response.length > 0) {
        this.dailyBkgReportDataList = response;
        console.log(this.dailyBkgReportDataList);
      } else {
        swal("Alert", "No Data Found", "warning");
      }
      this.dtTriggerDailyBkgReport.next();
      this.showSpinnerForAction = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server error while getting the LR details", "warning");
      },
      () => console.log("done");
  };

  ngAfterViewInit(): void {
    this.dtTriggerDailyBkgReport.next();
  }
  clearAllFields() {
    $("#" + this.pageId + "dailyBkgLrsTableId")
      .DataTable()
      .destroy();
    this.dtTriggerDailyBkgReport.next();
    this.dailyBkgReportDataList = [];
    $("#" + this.pageId + "fromDate").val("");
    $("#" + this.pageId + "toDate").val("");
    $("#" + this.pageId + "dropDownInputFieldSource").val("");
    $("#" + this.pageId + "dropDownInputFieldDestination").val("");
    this.selectedSource = null;
    this.selectedDest = null;
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.lrDto = new LRDto();
  }
  clearAll() {
    this.clearAllFields();
  }

  lrNumberToEnquiryForm(dataList) {
    this.selectedLrNumber = dataList.lrNumber;
    console.log(this.selectedLrNumber);
    localStorage.clear();

    // localStorage.setItem('lrNumberFromEwayBillValidation',
    // 	 JSON.stringify(this.selectedLrNumber));
    //         this.router.navigate(['/lr/enquiry/lrEnquiry']);

    localStorage.setItem("searchBy", "lrnumber");
    localStorage.setItem("enteredLrnumber", this.selectedLrNumber);
    this.router.navigate(["/lr/enquiry/lrEnquiry"]);
  }

  customPrintPartyWiseOsSumRpt() {
    if (this.dailyBkgReportDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();

      this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
      this.selectedToDate = $("#" + this.pageId + "toDate").val();
      console.log(
        this.dailyBkgReportDataList,
        this.selectedFromDate,
        this.selectedToDate
      );
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = [
        "Destination",
        "LR Number",
        "Bkg Date",
        "Art",
        "Actual Wt",
        "Charged Wt",
        "LC",
        "Paid",
      ];
      this.cashMemoDtoForCustomPrintListColumnWidths = [
        20, 15, 15, 7, 10, 13, 10, 10,
      ];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListColumnNames.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName =
          this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth =
          this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }

      this.cashMemoDtoForCustomPrintDataList = [];

      this.cashMemoDtoForCustomPrintDataSummaryList = [];
      // this.totalMemosummary = 0;
      let totalArt = 0;
      let totalActWt = 0;
      let totalChgWt = 0;
      let totalLc = 0;
      let totalPaid = 0;
      for (let i = 0; i < this.dailyBkgReportDataList.length; i++) {
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [
          this.dailyBkgReportDataList[i].destination,
          this.dailyBkgReportDataList[i].lrNumber,
          this.dailyBkgReportDataList[i].bookingDateStr,
          this.dailyBkgReportDataList[i].totalArticles,
          this.dailyBkgReportDataList[i].actualWeight,
          this.dailyBkgReportDataList[i].chargedWeight,
          this.dailyBkgReportDataList[i].lcChg,
          this.dailyBkgReportDataList[i].paid,
        ];
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues =
          this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(
          this.cashMemoDtoForCustomPrintData
        );

        // this.totalMemosummary = this.totalMemosummary + this.dailyBkgReportDataList[i].totalMemos;
        totalArt = totalArt + this.dailyBkgReportDataList[i].totalArticles;
        totalActWt = totalActWt + this.dailyBkgReportDataList[i].actualWeight;
        totalChgWt = totalChgWt + this.dailyBkgReportDataList[i].chargedWeight;
        totalLc = totalLc + this.dailyBkgReportDataList[i].lcChg;
        totalPaid = totalPaid + this.dailyBkgReportDataList[i].paid;
        this.cashMemoDtoForCustomPrintDataSummaryList = [
          "Total : " + this.dailyBkgReportDataList.length,
          "",
          "",
          totalArt,
          totalActWt,
          totalChgWt,
          totalLc,
          totalPaid,
        ];
      }
      //heading logics For Date

      this.cashMemoDtoForCustomPrintListHeadingV1 = [];
      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [
        moment(this.selectedFromDate).format("DD-MM-YYYY") +
        " - " +
        moment(this.selectedToDate).format("DD-MM-YYYY"),
      ];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(
          this.cashMemoDtoForCustomPrint
        );
      }
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintList",
        JSON.stringify(this.cashMemoDtoForCustomPrintList)
      );
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintDataList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintDataSummaryList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV1",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1)
      );
      localStorage.setItem("printTitle", "Daily Booking Report");
      this.viewCustomPrintV1 = true;
      window.addEventListener("afterprint", (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }
}
