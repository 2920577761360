import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Inject } from '@angular/core';
import swal from 'sweetalert';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ReportService } from 'src/app/dataService/report-service';
export interface DialogData {
    listOfDataToPass: string[];
}

@Component({
    selector: 'app-short-extra-details-popup',
    templateUrl: './short-extra-details-popup.component.html',
    styleUrls: ['./short-extra-details-popup.component.css']
})
export class ShortExtraDetailsPopupComponent implements OnInit {

    lrDto: LRDto = new LRDto();
    userDataDtoReturnSession: any;
    shortExtraDataLists: any;
    listOfDataToGet: any;
    list: string[];
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerShortAndExtraDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsShortAndExtraDetails: any;
    pageId = "seldtsp";
    showSpinnerForAction = false;

    constructor(
        private masterReadService: MasterReadService, private reportService: ReportService,
        public dialogRef: MatDialogRef<ShortExtraDetailsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef
    ) {
        this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

        this.listOfDataToGet = this.data.listOfDataToPass;
        this.getShortAndExtraDetailsForHireslipReportMethod();
    }

    ngOnInit(): void {
        var groupColumn = 0;
        this.dtOptionsShortAndExtraDetails = {
            dom: 'Bfrtip',
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    title: function () {
                        return "Short & Extra Details";
                    },
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8]
                    }
                }
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            footerCallback: function (row, data, start, end, display) {
                var api = this.api(),
                    data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === "string"
                        ? +i.replace(/[\$,]/g, "") * 1
                        : typeof i === "number"
                            ? i
                            : 0;
                };
                $(api.column(1).footer()).html("Total : " + data.length);
            },
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var count = 0;
                //var pendingQty = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 7,
                                                "text": ""
                                            }))
                                        .prop('outerHTML'));
                            count = 0;
                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 8,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    if (i == (rows.length - 1)) {
                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total : " + count
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 7,
                                            "text": ""
                                        }))
                                    .prop('outerHTML'));
                        count = 0;
                    }
                });
            }
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerShortAndExtraDetails.unsubscribe();
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    getShortAndExtraDetailsInfo() {
        this.lrDto = new LRDto();
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.branch = this.userDataDtoReturnSession.office;
        this.lrDto.invoiceNumber = this.listOfDataToGet;
        console.log(this.lrDto);
        this.showSpinnerForAction = true;
    }
    getShortAndExtraDetailsForHireslipReportMethod = () => {
        this.getShortAndExtraDetailsInfo();
        this.reportService.getShortAndExtraDetailsForHireslipReport(this.lrDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.shortExtraDataLists = response;
                    console.log("Short/Extra");
                    console.log(this.shortExtraDataLists);
                    this.dtTriggerShortAndExtraDetails.next();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
    };

}
