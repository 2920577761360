//CHG-V-ALRP:56 - @Imran Chg, Added Eway bill number for already genrated barcode for hold lrs (03122024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts

//from the particular global folder ends
//for datatable ends
//for popup modal ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ReportService } from 'src/app/dataService/report-service';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from "@angular/common";
import swal from 'sweetalert';
//import { LrEntryFormComponent } from "src/app/lr/entry/lr-entry-form/lrentryform.component";
export interface DialogData {
    listOfDataToPass: string[];
    listOfDataToPasstoLrEntry: string[];
}
//for modal ends
@Component({
    selector: 'app-automatedlrhold-lr-entry-popup',
    templateUrl: './automatedlrhold-lr-entry-popup.component.html',
    styleUrls: ['./automatedlrhold-lr-entry-popup.component.css']
})
export class AutomatedLrHoldLrEntryPopupComponent implements OnInit {

    //for datatable starts

    lrDto: LRDto = new LRDto();
    userDataDtoReturnSession: any;

    lrDetailsDataList: any;
    //    listOfDataToGet: LRDto = new LRDto();
    listOfDataToGet: any;
    list: string[];
    frmCount; number;
    lrCount: number;
    totalLrs: number;
    enteredTotalLrs: any;
    lrCntPop = 0;
    holdLrs = 0;
    blockedLrCount: any;
    lrDtoMultiLrs: LRDto = new LRDto();
    lrDtoMultiLrsRet: any;
    selectedLrNumber: any;
    readonlyLrNo = true;
    pageId = "alepc";

    //for datatable ends

    //summaryTable:any;

    loadingIndicator = true;

    //for datatable starts
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLrDetails: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsLrDetails: any;

    //for datatable ends

    constructor(
            /* for datatable starts */private masterReadService: MasterReadService, private reportService: ReportService, private router: Router, private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute, /* for datatable ends */
        //for modal starts
        public dialogRef: MatDialogRef<AutomatedLrHoldLrEntryPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef
        //for modal ends
    ) {
        this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        this.blockedLrCount = this.userDataDtoReturnSession.blockedLrCount;
        //        console.log( 'last ' + this.blockedLrCount );

        this.listOfDataToGet = this.data.listOfDataToPass;
        this.getHoldLrsDetails();
        //        console.log( "Finally >>> " + this.listOfDataToGet );

    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    /* for datatable ends */






    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsLrDetails = {
            dom: 'Bfrtip',

            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var columnData = api
                    .column(
                        0)
                    .data();

                // Update footer
                $(api.column(0).footer()).html(
                    columnData.count()
                );


                // Total over this page

                var pageTotals = api
                    .column(2)
                    .data()
                    .reduce(function (a, b) {
                        return intVal(a) + intVal(b)
                    }, 0);
                $(api.column(2).footer()).html(
                    pageTotals
                );
            }
            //end footerCallback      
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
    }

    //for modal starts
    onCancelClick(): void {
        this.dialogRef.close();
    }
    //for modal ends



    getHoldLrsInfo() {
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
        //        console.log( this.lrDto.userName );


    }
    getHoldLrsDetails = () => {
        this.getHoldLrsInfo();
        this.masterReadService.getBlockedLrsList(this.lrDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.lrCntPop = 0;
                    swal({
                        title: "Warning",
                        text: "No records found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    $("#" + this.pageId + "totalLrs").val('');
                } else {
                    this.lrDetailsDataList = response;
                    this.lrCntPop = response.length;
                    $("#" + this.pageId + "totalLrs").val('');
                }
                this.readonlyLrNo = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
    };

    validateBtnSubmit() {
        this.enteredTotalLrs = $("#" + this.pageId + "totalLrs").val();
        //        console.log( '1 ' + this.enteredTotalLrs );
        this.frmCount = this.enteredTotalLrs;
        //        console.log( '2 ' + this.frmCount );
        this.lrCount = this.lrCntPop;
        //        console.log( '3 ' + this.lrCount );
        this.totalLrs = +this.frmCount + +this.lrCount;
        //        console.log( '4 ' + this.totalLrs );
        this.blockedLrCount = this.userDataDtoReturnSession.blockedLrCount;
        //        console.log( 'last ' + this.blockedLrCount );
        if (this.totalLrs > this.blockedLrCount) {
            //            this.holdLrs = +this.totalLrs + -this.frmCount;
            swal({
                title: "Warning",
                text: "Already U have Hold " + this.lrCntPop + " LR's. Not allowed Holding More then " + this.blockedLrCount + " LR's",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            $("#" + this.pageId + "totalLrs").val('');
        } else {
            this.getBlockMultiLrs();
        }
    }

    getBlockMultiLrsInfo() {
        this.enteredTotalLrs = $("#" + this.pageId + "totalLrs").val();
        this.lrDtoMultiLrs.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoMultiLrs.mainstation = this.userDataDtoReturnSession.mainAdminStation;
        this.lrDtoMultiLrs.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoMultiLrs.toLrNo = this.enteredTotalLrs;


    }
    getBlockMultiLrs = () => {
        this.getBlockMultiLrsInfo();
        this.masterReadService.getAutomaticLrNo(this.lrDtoMultiLrs).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    $("#" + this.pageId + "totalLrs").val('');
                } else {
                    this.lrDetailsDataList = response;
                    //                  console.log(this.lrDtoMultiLrsRet);
                    this.getHoldLrsDetails();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
    };

    goToLrEntryPage(lrDetailsData) {
        this.selectedLrNumber = lrDetailsData.lrNumber;
        localStorage.clear();
        localStorage.setItem('lrEntry', this.selectedLrNumber);
        console.log("Setting lrEntry: ", lrDetailsData.lrNumber);
        
        //CHG-V-ALRP:56
        console.log("Setting ewayBillNo: ", lrDetailsData.eWayBillNo);
        localStorage.setItem('lrEntryEwayBillNo', lrDetailsData.eWayBillNo);

        console.log("Local Storage eway bill auto lr hold" + localStorage.getItem('lrEntryEwayBillNo'));
        //        console.log( 'this is the Selected Lr ' + localStorage.getItem('lrEntry') );
        this.dialogRef.close();

        //        this.getAutomatedLrHoldDetails();

    }

    //    getAutomatedLrHoldDetails(): void {
    //
    //        const dialogRef = this.dialog.open( LrEntryFormComponent, {
    //
    //            data: { listOfDataToPasstoLrEntry: this.enteredTotalLrs }
    //        } );
    //
    //        dialogRef.afterClosed().subscribe( result => {
    //
    //            this.router.navigate( ['.'], { relativeTo: this.route } );
    //        } );
    //    }
    validateClear() {
        $("#" + this.pageId + "totalLrs").val('');
    }
}
