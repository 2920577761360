import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Inject } from '@angular/core';
import swal from 'sweetalert';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ReportService } from 'src/app/dataService/report-service';
import { LrReportService } from 'src/app/dataService/lr-report-service';
export interface DialogData {
    consigneeId: any;
    list: any;
    fromStation: any;
}

@Component({
    selector: 'app-godown-stocks-rpt-lr-details-popup',
    templateUrl: './godown-stocks-rpt-lr-details-popup.component.html',
    styleUrls: ['./godown-stocks-rpt-lr-details-popup.component.css']
})
export class GodownStocksRptLrDetailsPopupComponent implements OnInit {

    lrDto: LRDto = new LRDto();
    userDataDtoReturnSession: any;
    lrDetailLists: any;
    consigneeIdForPopup: any;
    // list: string[];
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLrDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrDetails: any;
    pageId = "seldtsp";
    showSpinnerForAction = false;
    stockList: any;
    destination: any;

    constructor(
        private masterReadService: MasterReadService, private reportService: ReportService,
        public dialogRef: MatDialogRef<GodownStocksRptLrDetailsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef,
        private lrReportService: LrReportService) {
        this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

        this.consigneeIdForPopup = this.data.consigneeId;
        this.stockList = this.data.list;
        this.destination = this.data.fromStation;
        this.getLRDetailsForGodowStocksSummaryReport();
    }

    ngOnInit(): void {
        this.dtOptionsLrDetails = {
            dom: 'Bfrtip',
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    footer: true,
                    title: function () {
                        return "LR Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            footerCallback: function (row, data, start, end, display) {
                var api = this.api(),
                    data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === "string"
                        ? +i.replace(/[\$,]/g, "") * 1
                        : typeof i === "number"
                            ? i
                            : 0;
                };
                var totalArticles = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actualweight = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chargedWeight = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(4).footer()).html(totalArticles);
                $(api.column(7).footer()).html(actualweight);
                $(api.column(8).footer()).html(chargedWeight);
            }
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    getLRDetailsForGodowStocksSummaryReportInfo() {
        this.lrDto = new LRDto();
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.destination = this.userDataDtoReturnSession.office;
        this.lrDto.consigneeId = this.consigneeIdForPopup;
        this.lrDto.list = this.stockList;
        this.lrDto.fromStation = this.destination;
        console.log(this.lrDto);
        this.showSpinnerForAction = true;
    }
    getLRDetailsForGodowStocksSummaryReport = () => {
        this.getLRDetailsForGodowStocksSummaryReportInfo();
        this.lrReportService.getLRDetailsForGodowStocksSummaryReport(this.lrDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDetailLists = response;
                    console.log("LR Details");
                    console.log(this.lrDetailLists);
                    this.dtTriggerLrDetails.next();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
    };
}
