//ASR_V1_CHG - 25/11/2021 - Added Actual Wgt column
//Chg_V2 : Add amount details column as per discuss (Asrar Jr / 30-09-2024)
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import swal from 'sweetalert';
import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { MatDialog } from '@angular/material/dialog';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from 'rxjs';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { RequestDto } from 'src/app/dto/Request-dto';
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { DriverdetailsDto } from 'src/app/dto/Driverdetails-dto';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-agent-local-trip',
    templateUrl: './agent-local-trip.component.html',
    styleUrls: ['./agent-local-trip.component.css']
})
export class AgentLocalTripComponent implements OnInit {

    agentLocalTripDataList: any;
    lrDto: LRDto = new LRDto();
    fromStationDataListOptions: any;
    agentDtoFromStationAll: AgentDetailsDto = new AgentDetailsDto();
    agentDtoToStationAll: AgentDetailsDto = new AgentDetailsDto();
    model: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    stationsRights = "Agent Local Trip Stations Wise";
    showReportTypePerRights: boolean;
    fromStations: boolean;
    fromStation: boolean;
    toStations: boolean;
    toStation: boolean;
    fromStationAny: any;
    toStationAny: any;
    fromStationPassing: any;
    toStationPassing: any;
    selectedFromStation: any;
    selectedToStation: any;
    selectedRptType: any;
    selectedFromDate: any;
    selectedToDate: any;
    frmstnLists: any;
    modalRefferenceAgentLocalTripPopUp: NgbModalRef;
    modalRefferenceAgentLocalTripClosePopUp: string;
    @ViewChild('memoLessDescrPopUpTemplate') private memoLessDescrPopUpTemplate;
    viewAgentLocalTriptionPopUp = false;
    pageTitle = "Local Booking Trip Report";
    selectedRow: any;
    controlFromStation = new FormControl();
    controlToStation = new FormControl();

    //For From Station
    fromStationOptions: AgentDetailsDto[];
    fromAgentDto: AgentDetailsDto = new AgentDetailsDto();
    lrDtoFromStationAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelFromStation: any;
    fromStationTA: Array<AgentDetailsDto> = [];
    focusFromStationTA$ = new Subject<string>();
    searchFromStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromStationTA
                : this.fromStationTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterFromStation = (x: { subStation: string }) => x.subStation;
    ///////
    //For To Station
    toStationDataListOptions: AgentDetailsDto[];
    toAgentDto: AgentDetailsDto = new AgentDetailsDto();
    lrDtoToStationAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelToStation: any;
    toStationTA: Array<AgentDetailsDto> = [];
    focusToStationTA$ = new Subject<string>();
    searchToStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusToStationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.toStationTA
                : this.toStationTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterToStation = (x: { subStation: string }) => x.subStation;
    ////
    address: any;
    /////
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerAgentLocalTrip: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsAgentLocalTrip: any;
    //for modal starts
    //    animal: string;
    listOfDataToPass: string[];
    routeQueryParams$: Subscription;
    showSpinnerForAction: boolean;
    agentLocalTripService: any;
    agentDtoFromStationAddOption: AgentDetailsDto;
    //for modal ends
    cityName: string;
    rightsToShowReportMode = 'Rights For BkgAgtlocalTrip Show ReportMode';
    fromStationList: Array<any> = [];
    toStationList: Array<any> = [];
    showFromStations = false;
    showToStations = false;


    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    totLrsSummary: number;
    totArtSummary: number;
    //ASR_V1_CHG
    actWtSummary: number;
    //
    chgWtSummary: number;
    viewCustomPrintV1: boolean;
    agentLocalTripDataForPrint: any;
    viewStockTripsheetPrint = false;
    viewStockTripsheetPrintOld = false;
    pageId = "agltr";
    requestDto: RequestDto = new RequestDto();
    consolidateEwaybillEnabled: boolean = false;
    isVehEditEnabled: boolean = false;

    truckDataDto: TruckDataDto = new TruckDataDto();
    vehicleNumberTruckDtoOptions: TruckDataDto[];

    public modelVehicleNumberUpdate: any;
    vehicleNumberUpdateTA: Array<TruckDataDto> = [];
    focusVehicleNumberUpdateTA$ = new Subject<string>();
    searchVehicleNumberUpdate = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusVehicleNumberUpdateTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.vehicleNumberUpdateTA
                : this.vehicleNumberUpdateTA.filter(v => v.truckNumber.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterVehicleNumberUpdate = (x: { truckNumber: string }) => x.truckNumber;

    setTripNo = null;
    closeResult: string;
    setTripId = null;
    localTripOfc = null;
    updatedVehicleNumber = null;
    driverDataDtoDriverName: DriverdetailsDto = new DriverdetailsDto();
    requestDtoUpdate: RequestDto = new RequestDto();
    showLocalTripHireLryRpt: boolean = false;
    balance: any;
    balanceSummay: any;

    constructor(private reportService: ReportService, private router: Router, private masterReadService: MasterReadService,
        public dialog: MatDialog, private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef,
        private ewaybillService: EwaybillService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.enableConsolidatedEWBAutoGenerationLocalTrip != null && this.userDataDtoReturnSession.enableConsolidatedEWBAutoGenerationLocalTrip) {
                this.consolidateEwaybillEnabled = true;
            }
            if (this.stationsRights == "Agent Local Trip Stations Wise") {
                this.fromStations = true;
                this.toStations = true;
                this.showReportTypePerRights = false;
            } else if (this.stationsRights == "Agent Local Trip ReportType Wise") {
                this.showReportTypePerRights = true;
            }
            // Apply This Logic
            this.getToStationsRead("Self");
            if (this.userDataDtoReturnSession.isOpenTrip != null && this.userDataDtoReturnSession.isOpenTrip) {
                this.getFromStationsRead("Self");
            } else {
                this.getFromStationsRead("Agent");
            }
            this.getToStationsRead("Self");
            this.getAgentNamesFromStations();
            this.getAgentNamesToStations();
            this.getVehicleMasterList();
            //if(this.rightsToShowReportMode == "Rights For BkgAgtlocalTrip Show ReportMode"){
            if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Booking Manager") {
                this.showReportTypePerRights = true;
                this.showFromStations = false;
                this.showToStations = false;
                console.log("hi");
            } else if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.showReportTypePerRights = false;
                this.showFromStations = true;
                this.showToStations = true;
            } else {
                this.showReportTypePerRights = false;
                this.showFromStations = false;
                this.showToStations = true;
            }
            //Chg_V2
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LocalTrip HireLryRpt") {
                        this.showLocalTripHireLryRpt = true;
                    }
                }
            }
        }
    }
    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    ngOnInit(): void {
        //Chg_V2
        let exportColumns: number[];
        if (this.showLocalTripHireLryRpt) {
            exportColumns = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
        } else {
            exportColumns = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
        }
        this.dtOptionsAgentLocalTrip = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    exportOptions: {
                        columns: exportColumns
                    },
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                //ASR_V1_CHG - starts
                var totalLrs = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalArticles = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalActweight = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalChgweight = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                //Chg_V2
                var lorryHire = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var advance = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var balance = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                //Chg_V2
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(7).footer()).html(totalLrs);
                $(api.column(8).footer()).html(totalArticles);
                $(api.column(9).footer()).html(totalActweight);
                $(api.column(10).footer()).html(totalChgweight);
                //Chg_V2
                $(api.column(12).footer()).html(lorryHire);
                $(api.column(13).footer()).html(advance);
                $(api.column(14).footer()).html(balance);
                //ASR_V1_CHG - ends
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerAgentLocalTrip.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerAgentLocalTrip.next();
    }
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    clearAll() {
        this.controlFromStation.reset();
        this.controlToStation.reset();
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "fromStation").val('');
        $("#" + this.pageId + "deliveryArea").val('');
        $("#" + this.pageId + "reportType").val('');
    }
    reportTypeMode(reportType: string) {

        if (reportType == 'Received From') {
            this.showFromStations = true;

            this.showToStations = false;

        } else if (reportType == 'Sent To') {
            this.showFromStations = false;
            this.showToStations = true;

        } else {
            this.showFromStations = false;
            this.showToStations = false;
        }
    }
    getFromStationsRead(string) {

        this.fromAgentDto.mode = "toAgentLocalTrip";
        this.fromAgentDto.status = "Working";
        this.fromAgentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.fromAgentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.fromAgentDto.column2 = "working";
        if (string == "Self") {
            this.fromAgentDto.column3 = "Self";
        } else if (string == "Agent") {
            this.fromAgentDto.column3 = "Agent";
        }
    }

    getAgentNamesFromStations = () => {
        this.getFromStationsRead(String);
        this.masterReadService.getSourceListDetails(this.fromAgentDto).subscribe(
            (response) => {
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.fromStationDataListOptions = response;
                    this.fromStationTA = [];
                    this.agentDtoFromStationAll.subStation = "ALL";
                    this.fromStationTA.push(this.agentDtoFromStationAll);
                    this.fromStationList = [];
                    console.log(this.fromStationDataListOptions);
                    for (let i = 0; i < this.fromStationDataListOptions.length; i++) {
                        this.fromStationTA.push(this.fromStationDataListOptions[i]);
                        this.fromStationList.push(this.fromStationDataListOptions[i].subStation);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Getting From Stations Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    getToStationsRead(string) {
        this.toAgentDto.mode = "toAgentLocalTrip";
        this.toAgentDto.column2 = "working";
        this.toAgentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.toAgentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        //this.toAgentDto.column3 = type;
        if (string == "Self") {
            this.toAgentDto.column3 = "Self";
        }
        this.toAgentDto.status = "Working";
        console.log(this.toAgentDto);
        // this.toStationMethod();
    }
    getAgentNamesToStations() {
        this.getToStationsRead(String);
        this.masterReadService.getSourceListDetails(this.toAgentDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                else {
                    this.toStationDataListOptions = response;
                    this.toStationTA = [];
                    this.agentDtoToStationAll.subStation = "ALL";
                    this.toStationTA.push(this.agentDtoToStationAll);
                    this.toStationList = [];

                    console.log(this.toStationDataListOptions);
                    for (let i = 0; i < this.toStationDataListOptions.length; i++) {
                        this.toStationTA.push(this.toStationDataListOptions[i]);
                        this.toStationList.push(this.toStationDataListOptions[i].subStation);
                    }
                    if (this.userDataDtoReturnSession.localTripMulti != null && this.userDataDtoReturnSession.localTripMulti == true) {
                        this.agentDtoToStationAll.subStation = this.userDataDtoReturnSession.routingSubStation;
                        this.toStationTA.push(this.agentDtoToStationAll);
                        this.toStationList.push(this.agentDtoToStationAll);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Getting To Stations Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    agentLocalTripData() {
        this.selectedFromStation = $("#" + this.pageId + "fromStation").val();
        this.selectedToStation = $("#" + this.pageId + "deliveryArea").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedRptType = $("#" + this.pageId + "reportType").val();
        this.lrDto.fromStation = this.selectedFromStation;
        this.lrDto.toStation = this.selectedToStation;
        //alert($("#"+this.pageId+"deliveryArea").val()+'-'+ $("#"+this.pageId+"fromStation").val());

        if (this.selectedToStation != null && this.selectedToStation == "ALL" && this.selectedToStation != '') {
            this.lrDto.listToStations = this.toStationList;
        } else {
            this.lrDto.toStation = this.selectedToStation;
        }
        if (this.selectedFromStation != null && this.selectedFromStation == "ALL" && this.selectedFromStation != '') {
            this.lrDto.listFromStations = this.fromStationList;
        } else {
            this.lrDto.fromStation = this.selectedFromStation;
        }
        if (this.selectedRptType == null && this.selectedRptType == "" && this.selectedRptType == undefined) {
            this.lrDto.reportMode = "";
        } else {
            this.lrDto.reportMode = this.selectedRptType;
        }
        if (this.selectedToStation == undefined || this.selectedToStation == null || this.selectedToStation == '') {
            this.lrDto.toStation = null;
        }
        if (this.selectedFromStation == undefined || this.selectedFromStation == null || this.selectedFromStation == '') {
            this.lrDto.fromStation = null;
        }

        //this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.branch = this.userDataDtoReturnSession.office;
        this.lrDto.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDto.status = "Pending";
        /* this.lrDto.fromStation = this.selectedFromStation;
         this.lrDto.toStation = this.selectedToStation;*/
        this.lrDto.fromDate = this.selectedFromDate;
        this.lrDto.toDate = this.selectedToDate;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    searchMethod() {
        this.showSpinnerForAction = true;
        this.agentLocalTripData();
        this.reportService.getLocalTripDetailsService(this.lrDto).subscribe(
            (response) => {
                $("#" + this.pageId + "agentlocaltrp").DataTable().destroy();
                this.agentLocalTripDataList = [];
                if (response.length > 0) {
                    console.log(response);
                    this.agentLocalTripDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    this.agentLocalTripDataList = [];
                    this.showSpinnerForAction = false;
                    swal("No Records", "No Records found for this search", "info");
                }
                this.dtTriggerAgentLocalTrip.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Lr Enquiry Advance Details", "info");
            }, () => console.log('done');
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    agentlocaltripDescriptionPopUpBtnLink(agentlocaltripPopUpTemplate, agentLocalTripData) {

        //this.agentLocalTripDataForPrint = null;
        //this.agentLocalTripDataForPrint = agentLocalTripData;
        //console.log('data1 : '+this.agentLocalTripDataForPrint);

        this.modalRefferenceAgentLocalTripPopUp = this.modalService.open(agentlocaltripPopUpTemplate,
            { centered: true, windowClass: 'my-class' });
        this.modalRefferenceAgentLocalTripPopUp.result.then((result) => {
            this.modalRefferenceAgentLocalTripClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceAgentLocalTripClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
        localStorage.clear();
        localStorage.setItem('popupAgentLocalTrip', JSON.stringify(agentLocalTripData));
        this.viewAgentLocalTriptionPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewAgentLocalTriptionPopUp = false;
            //this.changeDetectorRef.detectChanges();
        });
    }
    agentLocalTripPopUp() {
        this.modalRefferenceAgentLocalTripPopUp.close();
    }

    agentLocalTripPopUpPrint() {
        this.modalRefferenceAgentLocalTripPopUp.close();
        //localStorage.clear();
        //localStorage.setItem('popupAgentLocalTrip', JSON.stringify(this.agentLocalTripDataForPrint));
        let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
        if (MainAdminStation != null && (MainAdminStation == 'delhi')) {
            this.viewStockTripsheetPrintOld = true;
            console.log('viewStockTripsheetPrintOld');
        } else {
            this.viewStockTripsheetPrint = true;
            console.log('viewStockTripsheetPrint');
        }
        console.log('agentLocalTripPopUpPrint - 1');
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewStockTripsheetPrint || this.viewStockTripsheetPrintOld) {
                this.viewStockTripsheetPrint = false;
                this.viewStockTripsheetPrintOld = false;
                localStorage.clear();
                console.log('agentLocalTripPopUpPrint - 2');
                this.changeDetectorRef.detectChanges();
            }
        });
        // window.onafterprint = function () {
        //     console.log('agentLocalTripPopUpPrint-onafterprint');
        //     window.close();
        //     this.viewStockTripsheetPrint = false;
        //     localStorage.clear();

        // }
        console.log('agentLocalTripPopUpPrint - 3');
    }

    //ASR_V1_CHG - starts
    customPrintAgentLocalTrp() {
        if (this.agentLocalTripDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            //Chg_V2
            if (this.showLocalTripHireLryRpt) {
                this.cashMemoDtoForCustomPrintListColumnNames = ["Trip Date", "Vehicle No", "Trip No", "Trip From", "Bkg Center", "Tot Lrs", "Tot Art", "Act Wt", "Chg Wt", "Hire Amt", "Pymt Status"];
                this.cashMemoDtoForCustomPrintListColumnWidths = [10, 11, 17, 12, 10, 6, 6, 7, 7, 6, 8];
            } else {
                this.cashMemoDtoForCustomPrintListColumnNames = ["Trip Date", "Vehicle No", "Trip No", "Trip From", "Booking Center", "Tot Lrs", "Tot Art", "Act Wt", "Chg Wt"];
                this.cashMemoDtoForCustomPrintListColumnWidths = [10, 11, 17, 18, 12, 8, 8, 8, 8];
            }
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            //heading logics For Date
            this.selectedToDate = $("#" + this.pageId + "toDate").val();
            this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Dt", "To Dt"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedToDate, this.selectedFromDate];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            //Chg_V2
            if (this.showLocalTripHireLryRpt) {
                this.totLrsSummary = this.totArtSummary = this.chgWtSummary = this.actWtSummary = this.balanceSummay = 0;
                for (let i = 0; i < this.agentLocalTripDataList.length; i++) {
                    this.cashMemoDtoForCustomPrintListColumnValues = [this.agentLocalTripDataList[i].tripDateStr, this.agentLocalTripDataList[i].vehicleNumber,
                    this.agentLocalTripDataList[i].localTripNumber, this.agentLocalTripDataList[i].tripFrom, this.agentLocalTripDataList[i].deliveryarea,
                    this.agentLocalTripDataList[i].totalLrs, this.agentLocalTripDataList[i].totalArticles, this.agentLocalTripDataList[i].actualWeight, this.agentLocalTripDataList[i].chargedWeight,
                    this.agentLocalTripDataList[i].balance, this.agentLocalTripDataList[i].isHireBalancePaid ? 'Paid' : 'Pending'];
                    /////////

                    this.totLrsSummary = this.totLrsSummary + this.agentLocalTripDataList[i].totalLrs;
                    this.totArtSummary = this.totArtSummary + this.agentLocalTripDataList[i].totalArticles;
                    this.chgWtSummary = this.chgWtSummary + this.agentLocalTripDataList[i].chargedWeight;
                    this.actWtSummary = this.actWtSummary + this.agentLocalTripDataList[i].actualWeight;
                    //Chg_V2
                    this.balanceSummay = this.balanceSummay + this.agentLocalTripDataList[i].balance;

                    this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                    this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                    this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                }
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentLocalTripDataList.length, "", "", "", "", this.totLrsSummary, this.totArtSummary, this.actWtSummary, this.chgWtSummary, this.balanceSummay, ""];
            } else {
                this.totLrsSummary = this.totArtSummary = this.chgWtSummary = this.actWtSummary = 0;
                for (let i = 0; i < this.agentLocalTripDataList.length; i++) {
                    this.cashMemoDtoForCustomPrintListColumnValues = [this.agentLocalTripDataList[i].tripDateStr, this.agentLocalTripDataList[i].vehicleNumber,
                    this.agentLocalTripDataList[i].localTripNumber, this.agentLocalTripDataList[i].tripFrom, this.agentLocalTripDataList[i].deliveryarea,
                    this.agentLocalTripDataList[i].totalLrs, this.agentLocalTripDataList[i].totalArticles, this.agentLocalTripDataList[i].actualWeight, this.agentLocalTripDataList[i].chargedWeight];
                    /////////

                    this.totLrsSummary = this.totLrsSummary + this.agentLocalTripDataList[i].totalLrs;
                    this.totArtSummary = this.totArtSummary + this.agentLocalTripDataList[i].totalArticles;
                    this.chgWtSummary = this.chgWtSummary + this.agentLocalTripDataList[i].chargedWeight;
                    this.actWtSummary = this.actWtSummary + this.agentLocalTripDataList[i].actualWeight;

                    this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                    this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                    this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                }
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentLocalTripDataList.length, "", "", "", "", this.totLrsSummary, this.totArtSummary, this.actWtSummary, this.chgWtSummary];
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
                this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
            }
        }


        localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
        localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
        localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
        localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
        //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
        //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
        //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
        localStorage.setItem('printTitle', "Local Booking Trip Report");
        this.viewCustomPrintV1 = true;

        window.addEventListener('afterprint', (onclick) => {
            if (this.viewCustomPrintV1) {
                this.viewCustomPrintV1 = false;
                localStorage.clear();
            }
        });
    }
    //ASR_V1_CHG - Ends

    confirmGenerateConsolidateEwaybill(tripId) {
        swal({
            title: "Confirm Generate",
            text: "You Want to generate the Consolidate Eway Bill for this Trip?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesBtn) => {
            if (yesBtn) {
                this.generateConsolidateEwaybill(tripId);
            } else {
                swal.close();
            }

        })
    }

    generateConsolidateEwaybill(tripId) {
        this.requestDto = new RequestDto();
        this.requestDto.param3 = tripId;
        this.requestDto.mode = 'Booking';
        this.showSpinnerForAction = true;
        this.ewaybillService.generateConsolidateEwaybillLocalTrip(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    swal("Completed", "Consolidated Eway bill generated. Eway Bill No : " + response.ewayBillNo, "info");
                    this.downloadConsolidatedEWBPdf(response.ewayBillNo, response.token1);
                    this.searchMethod();
                } else {
                    swal("Failed", "Failed to generate the Consolidated Eway bill, please try again or contact Admin!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Error while process the Consolidated Eway bill generation request!", "error")
            }, () => console.log('done');
    }

    downloadConsolidatedEWBPdf(ewayBillNo, token) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": 5 };

        const authToken = token;

        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, authToken).subscribe((data) => {
            console.log('downloadPdf - ' + ewayBillNo);
            const blob = new Blob([data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, ewayBillNo + '-CEWB.pdf');
        });
    }

    printCEWB(eWayBillNo) {
        if (eWayBillNo == null) {
            swal("Not Allowed", "Consolidated Eway Bill not found for this Trip, please verify it!", "warning");
        } else {
            this.requestDto = new RequestDto();
            this.showSpinnerForAction = true;
            this.changeDetectorRef.detectChanges();
            this.ewaybillService.generateEwaybillToken(this.requestDto).subscribe(
                (response) => {
                    if (response.status == "Success") {
                        this.downloadConsolidatedEWBPdf(eWayBillNo, response.token1);
                    } else {
                        swal("Failed", "Failed to generate the token for Consolidated Eway Bill print request, please contact Admin!", "error");
                    }
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    this.showSpinnerForAction = false;
                    swal("Server Error", "Failed to generate the token for Consolidated Eway bill print request, please contact Admin!", "error");
                }, () => console.log('done');
        }
    }

    getVehicleDetailsForRead() {
        this.truckDataDto = new TruckDataDto();
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.truckDataDto.isActive = true;
    }
    getVehicleMasterList = () => {
        this.showSpinnerForAction = true;
        this.getVehicleDetailsForRead();
        this.masterReadService.getVehicleSetupMaster(this.truckDataDto).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.vehicleNumberTruckDtoOptions = response;
                    this.vehicleNumberUpdateTA = [];
                    for (let i = 0; i < this.vehicleNumberTruckDtoOptions.length; i++) {
                        this.vehicleNumberUpdateTA.push(this.vehicleNumberTruckDtoOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting vehicle details", "error");
            },
            () => console.log('done');
    }

    vehicleUpdatePopUp(contentVehicleUpdate, agentLocalTripData) {
        this.setTripNo = null;
        this.setTripId = null;
        this.localTripOfc = null;
        this.setTripNo = agentLocalTripData.localTripNumber;
        this.setTripId = agentLocalTripData.id;
        this.localTripOfc = agentLocalTripData.office;
        console.log(this.setTripNo + " - " + this.setTripId);
        this.modalService.open(contentVehicleUpdate, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    updateVehicleBtn() {
        this.updatedVehicleNumber = null;
        this.updatedVehicleNumber = $("#" + this.pageId + "vehicleNumberUpdateId").val();
        console.log($("#" + this.pageId + "vehicleNumberUpdateId").val());
        if ((this.updatedVehicleNumber == null) || (this.updatedVehicleNumber == undefined) || (this.updatedVehicleNumber == '')) {
            swal({
                title: "Mandatory Fields",
                text: "Please select the Vehicle Number to proceed!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                $("#agltrvehicleNumberUpdateId").focus();
            });
        } else {
            swal({
                title: "Update Vehicle Number",
                text: "Sure you want to Update the Vehicle Number '" + this.updatedVehicleNumber + "' for the Local Trip : '" + this.setTripNo + "' ",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.driverDataDtoDriverName = new DriverdetailsDto();
                    this.driverDataDtoDriverName.vehicleNumber = this.updatedVehicleNumber;
                    this.driverDataDtoDriverName.id = this.setTripId;
                    this.driverDataDtoDriverName.userId = this.userDataDtoReturnSession.userName;
                    this.updateVehicleNumberMethod();
                }
            });
        }
    }

    updateVehicleNumberMethod() {
        this.showSpinnerForAction = true;
        this.reportService.updateLocalTripVehicle(this.driverDataDtoDriverName).subscribe(
            (response) => {
                if (response.status == 'success') {
                    this.setTripNo = null;
                    this.setTripId = null;
                    this.updatedVehicleNumber = null;
                    this.localTripOfc = null;
                    $("#" + this.pageId + "vehicleNumberUpdateId").val("");
                    this.modalService.dismissAll();
                    swal({
                        title: "Success",
                        text: "Vehicle Number updated successfully!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    })
                    this.searchMethod();
                } else if (response.status == 'failed') {
                    swal("Failed", "Failed to update the Vehicle Number!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while updating the Vehicle Number", "error");
            }, () => console.log('done');
    }

    paymentStatusMethod(agentLocalTripData) {
        if (!agentLocalTripData.isHireBalancePaid) {
            this.requestDtoUpdate = new RequestDto();
            this.requestDtoUpdate.id = agentLocalTripData.id;
            this.requestDtoUpdate.userId = this.userDataDtoReturnSession.userName;
            console.log(this.requestDtoUpdate);
            swal({
                title: "Confirm",
                text: "Sure U want to Complete this Payment Trip",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yes) => {
                if (yes) {
                    this.reportService.updateAgentLocalTripData(this.requestDtoUpdate).
                        subscribe((data) => {
                            if (data.status == "Success") {
                                swal("Success", "Payment Trip Updated Successfully!", "info");
                            } else {
                                swal("Failed", "Failed to update the payment trip Details!", "error");
                            }
                        }), (error) => {
                            swal("Error", "Server problem occurred while update the payment trip Details!", "error");
                        },
                        () => console.log('done');
                }
                this.changeDetectorRef.detectChanges();
            })
        }
    }

}
