import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
const my = new Date();
import { LRDto } from "src/app/dto/LR-dto";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { DatePipe } from "@angular/common";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, merge } from "rxjs";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { EwaybillService } from "src/app/dataService/ewaybill-service";
import { MultiEwayDetailsPopupComponent } from "src/app/lr/enquiry/multi-eway-details-popup/multi-eway-details-popup.component";
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: "app-ewb-date-expiry-destination-rpt",
    templateUrl: "./ewb-date-expiry-destination-rpt.component.html",
    styleUrls: ["./ewb-date-expiry-destination-rpt.component.css"],
})
export class EWBDateExpiryDestinationRptComponent implements OnInit {
    ewbDateExpiryDestDetails: any;
    model: NgbDateStruct;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    selectedSource: any;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerEwbDateExpiryDestRpt: Subject<any> = new Subject();
    dtOptionsEwbDateExpiryDestRpt: any;

    agentDto: AgentDetailsDto = new AgentDetailsDto();
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
    showSpinnerForAction = false;
    destinationDtoForAll: LRDto = new LRDto();
    destinationList: Array<any> = [];

    ///For Agent Names
    agentNameOptions: AgentDetailsDto[];
    agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
    public modelAgentName: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    searchAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { subStation: string }) => x.subStation;
    lrDto: LRDto = new LRDto();
    selectedDestination: any;
    showDestination: boolean = false;
    multiEwayTableDataList: Array<any> = [];
    private newAttributeEwayBillToPush: any = {};
    ewbDateExpiryDestEwayDetails: Array<any> = [];
    lrDtoList: Array<any> = [];
    selectedLrNumber: any;
    selectedUpdateAllDate: any;
    selectedUpdateDate: any;
    setTodayDateOnUpdateDate: any;
    updateLrForBulkAndSingleLrs: LRDto = new LRDto();
    setTodayDateOnUpdateAllDate: any;
    showSpinnerForActionForUpload = false;
    selectedLRNumberIgnore: any;
    selectedUpdaterAllReason: any;
    selectedUpdateReason: any;
    updateLrForExtensionReasonBulkAndSingleLrs: LRDto = new LRDto();
    multiSelect: boolean = false;
    pageId = "ewbDateExpDestRpt";





    constructor(private modalService: NgbModal, private lrReportService: LrReportService, private router: Router,
        private masterReadService: MasterReadService, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef,
        private ewaybillService: EwaybillService, public dialog: MatDialog) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
            this.getAgentDetailList();
            console.log(this.userDataDtoReturnSession.eWayBillValidBeforeInHrs);

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {

                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights[i])
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "EWBDateDest StockView") {
                        this.showDestination = true;
                        console.log('Dest');
                    }
                }
            }

        }
    }

    ngOnInit(): void {
        this.dtOptionsEwbDateExpiryDestRpt = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                title: function () {
                    var returExtendHistroyDate = null;
                    returExtendHistroyDate = "EWB Date Expiry Destination Report"
                    return returExtendHistroyDate;
                },
                exportOptions: {
                    columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
                },
            }],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var actWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var topay = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var goodVal = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                // $(api.column(1).footer()).html(data.length);
                $(api.column(7).footer()).html(actWgt);
                $(api.column(8).footer()).html(chgWgt);
                $(api.column(9).footer()).html(topay);
                $(api.column(10).footer()).html(paid);
                $(api.column(11).footer()).html(goodVal);

            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerEwbDateExpiryDestRpt.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerEwbDateExpiryDestRpt.next();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "other";
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        // this.lrDtoDestination.mode = "desttrsp";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        this.destinationDtoForAll.destination = "ALL";
                        this.destinationTA.push(this.destinationDtoForAll);

                        this.destinationList = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                            this.destinationList.push(this.destinationOptions[i].destination);
                        }

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };

    //For Agent Names
    getUserValuesForAgentDetailsList() {
        this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDto.column2 = "Working";
        this.agentDto.mode = "Sub";
        this.agentDto.status = "All";

        if (this.userDataDtoReturnSession.role != null
            && this.userDataDtoReturnSession.role == "Booking Administrator" && (this.userDataDtoReturnSession.stationList != '') && (this.userDataDtoReturnSession.stationList != null)) {
            this.agentDto.mode = "Sub";
            this.agentDto.status = "All";
        } else {
            //  this.agentDto.mode = "mainBranch";
            //As per asrar bhai 
            // if ( this.mainStationRpt == 'Paid Rpt Bkg Main Station' ) {
            //     this.agentDto.mainStation = this.userDataDtoReturnSession.mainStation;
            // } else {
            this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
            // }
        }
        console.log(this.agentDto);
    }

    getAgentDetailList() {
        this.getUserValuesForAgentDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getSourceListDetails(this.agentDto).subscribe(
            (response) => {
                this.agentNameTA = [];
                this.agentNameOptions = [];

                console.log(this.agentNameOptions);
                if (response.length > 0) {
                    this.agentNameOptions = response;
                    for (let i = 0; i < this.agentNameOptions.length; i++) {
                        this.agentNameTA.push(this.agentNameOptions[i]);
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            }, () => console.log('done');
    };

    getEWBDateExpiryDestDtls() {
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.lrDto = new LRDto();
        this.lrDto.source = this.selectedSource;
        if (this.showDestination != null && this.showDestination) {
            this.lrDto.destination = this.selectedDestination;
        } else {
            this.lrDto.destination = this.userDataDtoReturnSession.mainStation;
        }
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDto);
        this.getEWBDateExpiryDestDetails();
    }

    getEWBDateExpiryDestDetails = () => {
        this.showSpinnerForAction = true;
        this.lrReportService.getEWBDateExpiryDestRptDetails(this.lrDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "ewbDateExpiryDestRptTableId").DataTable().destroy();
                this.ewbDateExpiryDestDetails = [];
                this.multiSelect = false;
                this.lrDtoList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.ewbDateExpiryDestDetails = response;
                    console.log("ewbDateExpiryDestDetails");
                    console.log(this.ewbDateExpiryDestDetails);
                }
                this.dtTriggerEwbDateExpiryDestRpt.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the EWB Date Expiry Destination Report Details", "info");
            }, () => console.log('done');
    };

    clearAll() {
        this.selectedDestination = '';
        $("#" + this.pageId + "destination").val('');
        this.selectedSource = '';
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "ewbDateExpiryDestRptTableId").DataTable().destroy();
        this.ewbDateExpiryDestDetails = [];
        this.dtTriggerEwbDateExpiryDestRpt.next();
        this.lrDtoList = [];
        this.selectedUpdateAllDate = '';
        this.selectedUpdateDate = '';
        $("#" + this.pageId + "updateDate").val('');
        this.setTodayDateOnUpdateDate = '';
        $("#" + this.pageId + "updateAllDate").val('');
        this.setTodayDateOnUpdateAllDate = '';
        this.selectedLrNumber = '';
        this.showSpinnerForActionForUpload = false;
        this.selectedLRNumberIgnore = '';
        this.updateLrForBulkAndSingleLrs = new LRDto();
        this.lrDtoList = [];
        this.selectedUpdaterAllReason = '';
        $("#" + this.pageId + "ignoreSelectedAll").val('');
        this.selectedUpdateReason = '';
        $("#" + this.pageId + "ignoreSelectedSingle").val('');
        this.updateLrForExtensionReasonBulkAndSingleLrs = new LRDto();
    }

    openMultEwayPopup(ewbDateExpiryDestData) {
        this.ewbDateExpiryDestEwayDetails = [];
        for (let i = 0; i < ewbDateExpiryDestData.listOfmultipleEwayBill.length; i++) {
            this.newAttributeEwayBillToPush = {};
            this.newAttributeEwayBillToPush.autoId = ewbDateExpiryDestData.listOfmultipleEwayBill[i].autoId;
            this.newAttributeEwayBillToPush.lrNumber = ewbDateExpiryDestData.listOfmultipleEwayBill[i].lrNumber;
            this.newAttributeEwayBillToPush.eWayBillNo = ewbDateExpiryDestData.listOfmultipleEwayBill[i].eWayBillNo;
            this.newAttributeEwayBillToPush.ewayBillValidUptoStr = this.datePipe.transform(ewbDateExpiryDestData.listOfmultipleEwayBill[i].ewayBillValidUpto, "yyyy-MM-dd");
            this.ewbDateExpiryDestEwayDetails.push(this.newAttributeEwayBillToPush);
        }
        this.multiEwayDetailsDialog();
    }

    multiEwayDetailsDialog(): void {
        const dialogRef = this.dialog.open(MultiEwayDetailsPopupComponent, {
            data: { multiEwayTableDataList: this.ewbDateExpiryDestEwayDetails }
        });
    }

    rowCheckBoxChecked(e, ewbDateExpiryDestData) {
        if (e.currentTarget.checked) {
            if (this.lrDtoList.length == 0) {
                this.lrDtoList = [];
                this.lrDtoList.push(ewbDateExpiryDestData.lrNumber);
            } else {
                this.lrDtoList.push(ewbDateExpiryDestData.lrNumber);
            }
        } else {
            let index = this.lrDtoList.indexOf(ewbDateExpiryDestData.lrNumber);
            if (index > -1) {
                this.lrDtoList.splice(index, 1);
            }
        }
        console.log(this.lrDtoList);
    }

    extendDateBtn(ewbDateExpiryDestData, updateDatePopUpTemplate) {
        this.selectedLrNumber = ewbDateExpiryDestData.lrNumber;

        if (this.selectedLrNumber != null) {
            this.lrDtoList = [];
            this.lrDtoList.push(ewbDateExpiryDestData.lrNumber);

            // this.modalService.open(updateDatePopUpTemplate,
            //     { centered: true, windowClass: "myCustomModalClassSingle" });
            this.modalService.open(updateDatePopUpTemplate, {
                centered: true,
                windowClass: "myCustomModalClassSingle",
                backdrop: 'static',  
                keyboard: false 
            });
        } else {
            swal({
                title: "No Lrs",
                text: "Select the LR to update the E-Way bill validity!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }

    closeUpdateDatePopUp() {
        this.lrDtoList = [];
        this.selectedUpdateAllDate = '';
        this.selectedUpdateDate = '';
        $("#" + this.pageId + "updateDate").val('');
        this.setTodayDateOnUpdateDate = '';
        $("#" + this.pageId + "updateAllDate").val('');
        this.setTodayDateOnUpdateAllDate = '';
        this.selectedLrNumber = '';
        this.updateLrForBulkAndSingleLrs = new LRDto();
    }

    updateExtendInfo() {
        this.selectedUpdateDate = $("#" + this.pageId + "updateDate").val();
        if (this.selectedUpdateDate == null || this.selectedUpdateDate == '') {
            swal({
                title: "Warning",
                text: "Please Select Date",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Confirm Update",
                text: "Sure you want to change the Eway Bill Valid Upto Date for the selected LR numbers?",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((bulk) => {
                if (bulk) {
                    this.updateBulkAndSingle(this.selectedUpdateDate);
                }
            });
        }
    }

    updateBulkAndSingle(selectedUpdateDate) {
        this.updateLrForBulkAndSingleLrs = new LRDto();
        this.updateLrForBulkAndSingleLrs.list = this.lrDtoList;
        this.updateLrForBulkAndSingleLrs.ewayBillValidUpto = selectedUpdateDate;
        this.updateLrForBulkAndSingleLrs.userName = this.userDataDtoReturnSession.userId;
        this.updateLrForBulkAndSingleLrs.companyId = this.userDataDtoReturnSession.companyId;
        this.updateBulkAndSingleDetails();
        console.log(this.updateLrForBulkAndSingleLrs);
    }

    updateBulkAndSingleDetails = () => {
        this.showSpinnerForActionForUpload = true;
        this.lrReportService.updateMultiEwayBillExpiryDate(this.updateLrForBulkAndSingleLrs).subscribe(
            (response) => {
                this.showSpinnerForActionForUpload = false;
                console.log(response);
                this.lrDtoList = [];
                if (response.status == 'Success') {
                    swal({
                        title: "Success",
                        text: "Eway Bill validation extension done!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Failure",
                        text: "Failed to update the Eway Bill extension date!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.selectedUpdateAllDate = '';
                this.selectedUpdateDate = '';
                $("#" + this.pageId + "updateDate").val('');
                this.setTodayDateOnUpdateDate = '';
                $("#" + this.pageId + "updateAllDate").val('');
                this.setTodayDateOnUpdateAllDate = '';
                this.lrDtoList = [];
                this.selectedLrNumber = '';
                this.updateLrForBulkAndSingleLrs = new LRDto();
                this.getEWBDateExpiryDestDtls();
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForActionForUpload = false;
                swal("Error", "Server error occurred while  update the Eway Bill extension date! ", "info");
            }, () => console.log('done');
    };

    ignoreMethod(validationLrsDetailsData, ignoreSelectedAllPopUpTemplate) {
        this.selectedLRNumberIgnore = validationLrsDetailsData.lrNumber;
        if (this.selectedLRNumberIgnore != null) {
            this.lrDtoList = [];
            this.lrDtoList.push(validationLrsDetailsData.lrNumber);
            this.modalService.open(ignoreSelectedAllPopUpTemplate,
                { centered: true, windowClass: "myCustomModalClass" });
        } else {
            swal({
                title: "No Lrs",
                text: "Select the LR to Ignore!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }

    closeIgnoreAllPopUp() {
        this.lrDtoList = [];
        this.selectedUpdaterAllReason = '';
        $("#" + this.pageId + "ignoreSelectedAll").val('');
        this.selectedUpdateReason = '';
        $("#" + this.pageId + "ignoreSelectedSingle").val('');
        this.selectedLRNumberIgnore = '';
        this.updateLrForExtensionReasonBulkAndSingleLrs = new LRDto();
    }

    updateExtensionValidityBulkInfo() {
        this.selectedUpdaterAllReason = $("#" + this.pageId + "ignoreSelectedAll").val();
        if (this.selectedUpdaterAllReason == null || this.selectedUpdaterAllReason == '') {
            swal({
                title: "Warning",
                text: "Please Enter Reason",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Confirm Update",
                text: "Sure you want to change the Extension  Validity Ignore Details For the selected LR numbers?",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((bulk) => {
                if (bulk) {
                    this.updateExtensionReasonForBulkAndSingle(this.selectedUpdaterAllReason);
                }
            });
        }
    }

    updateExtensionReasonForBulkAndSingle(selectedUpdateReason) {
        this.updateLrForExtensionReasonBulkAndSingleLrs = new LRDto();
        this.updateLrForExtensionReasonBulkAndSingleLrs.list = this.lrDtoList;
        this.updateLrForExtensionReasonBulkAndSingleLrs.reason = selectedUpdateReason;
        this.updateLrForExtensionReasonBulkAndSingleLrs.userName = this.userDataDtoReturnSession.userId;
        this.updateLrForExtensionReasonBulkAndSingleLrs.companyId = this.userDataDtoReturnSession.companyId;
        this.updateExtensionReasonBulkAndSingleDetails();
        console.log(this.updateLrForExtensionReasonBulkAndSingleLrs);
    }

    updateExtensionReasonBulkAndSingleDetails = () => {
        this.showSpinnerForActionForUpload = true;
        this.lrReportService.ignoreEwayBillExpiryDate(this.updateLrForExtensionReasonBulkAndSingleLrs).subscribe(
            (response) => {
                this.showSpinnerForActionForUpload = false;
                console.log(response);
                this.lrDtoList = [];
                if (response.status == 'Success') {
                    swal({
                        title: "Success",
                        text: "Selected LR ignored from the EWay Bill Validation done!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Failure",
                        text: "Failed to update the ignore details!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.lrDtoList = [];
                this.selectedUpdaterAllReason = '';
                $("#" + this.pageId + "ignoreSelectedAll").val('');
                this.selectedUpdateReason = '';
                $("#" + this.pageId + "ignoreSelectedSingle").val('');
                this.selectedLRNumberIgnore = '';
                this.updateLrForExtensionReasonBulkAndSingleLrs = new LRDto();
                this.getEWBDateExpiryDestDtls();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForActionForUpload = false;
                swal("Error", "Server error occurred while  update the Failed to update the ignore details! ", "info");
            }, () => console.log('done');
    };

    bulkAutoDateExtUpdteBtn() {
        if (this.lrDtoList.length == 0) {
            swal({
                title: "No Lrs",
                text: "Select the LR to extend the E-Way bill validity!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.updateBulkAndSingleAutoExtDateDetails();
        }
    }

    updateBulkAndSingleAutoDateExtData() {
        this.updateLrForBulkAndSingleLrs = new LRDto();
        this.updateLrForBulkAndSingleLrs.list = this.lrDtoList;
        this.updateLrForBulkAndSingleLrs.userName = this.userDataDtoReturnSession.userId;
        this.updateLrForBulkAndSingleLrs.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.updateLrForBulkAndSingleLrs);
    }

    updateBulkAndSingleAutoExtDateDetails = () => {
        this.updateBulkAndSingleAutoDateExtData();
        this.showSpinnerForAction = true;
        this.ewaybillService.updateAutoMultiEwayBillExpiryDate(this.updateLrForBulkAndSingleLrs).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.lrDtoList = [];
                if (response.status == 'Success') {
                    swal({
                        title: "Success",
                        text: "Eway Bill validation extension done!",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Failure",
                        text: "Failed to update the Eway Bill extension date!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.lrDtoList = [];
                this.selectedLrNumber = '';
                this.updateLrForBulkAndSingleLrs = new LRDto();
                this.getEWBDateExpiryDestDtls();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error occurred while  update the Eway Bill extension date! ", "info");
            }, () => console.log('done');
    };

    bulkUpdteBtn(updateAllDatePopUpTemplate) {
        if (this.lrDtoList.length == 0) {
            swal({
                title: "No Lrs",
                text: "Select the LR to extend the E-Way bill validity!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.modalService.open(updateAllDatePopUpTemplate,
                { centered: true, windowClass: "myCustomModalClass" });
        }
    }

    closeUpdateAllDatePopUp() {
        this.selectedUpdateAllDate = '';
        this.selectedUpdateDate = '';
        $("#" + this.pageId + "updateDate").val('');
        this.setTodayDateOnUpdateDate = '';
        $("#" + this.pageId + "updateAllDate").val('');
        this.setTodayDateOnUpdateAllDate = '';
        this.selectedLrNumber = '';
        this.updateLrForBulkAndSingleLrs = new LRDto();
    }

    bulkExtendInfo() {
        this.selectedUpdateAllDate = $("#" + this.pageId + "updateAllDate").val();
        if (this.selectedUpdateAllDate == null || this.selectedUpdateAllDate == '') {
            swal({
                title: "Warning",
                text: "Please Select Date",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Confirm Update",
                text: "Sure you want to change the Eway Bill Valid Upto Date for the selected LR numbers?",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((bulk) => {
                if (bulk) {
                    this.updateBulkAndSingle(this.selectedUpdateAllDate);
                }
            });
        }
    }

    ignoreAllMethod(ignoreSelectedAllPopUpTemplate) {
        if (this.lrDtoList.length == 0) {
            swal({
                title: "No Lrs",
                text: "Select LRs to Ignore!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.modalService.open(ignoreSelectedAllPopUpTemplate,
                { centered: true, windowClass: "myCustomModalClass" });
        }
    }

    toggleAllRows(event: Event) {
        this.multiSelect = (event.target as HTMLInputElement).checked;
        this.ewbDateExpiryDestDetails.forEach(item => {
            item.checked = this.multiSelect;
            this.updateLrDtoList(item, this.multiSelect);
        });
    }

    updateLrDtoList(validationLrsDetailsData, isChecked: boolean) {
        if (isChecked) {
            if (!this.lrDtoList.includes(validationLrsDetailsData.lrNumber)) {
                this.lrDtoList.push(validationLrsDetailsData.lrNumber);
            }
        } else {
            const index = this.lrDtoList.indexOf(validationLrsDetailsData.lrNumber);
            if (index > -1) {
                this.lrDtoList.splice(index, 1);
            }
        }
        console.log(this.lrDtoList);
    }

}
