import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';

@Component({
    selector: 'app-lr-blocked-party-wise',
    templateUrl: './lr-blocked-party-wise.component.html',
    styleUrls: ['./lr-blocked-party-wise.component.css']
})
export class LrBlockedPartyWiseComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    showSpinnerForAction: boolean = false;
    destinationDtoForAll: LRDto = new LRDto();
    lrDtoDestination: LRDto = new LRDto();
    destinationOptions: LRDto[];
    dtOptionsPartywiseDetails: any;
    dtTriggerPartywiseDetails: Subject<any> = new Subject();
    dtOptionsLrBlockedPartywiseDetails: any;
    dtTriggerLrBlockedPartywiseDetails: Subject<any> = new Subject();
    enteredGstNumber: any;
    lrDtoForGetPartyDetails: LRDto = new LRDto();
    showPartywiseDetailsForLrBlock: any;
    lrDtoForGetBlockParty: LRDto = new LRDto();
    selectedPartyType: any;
    enteredSourceForSave: any;
    enteredDestinationForSave: any;
    lrDtoRet: any;
    lrDtoForSaveBlockParty: LRDto = new LRDto();
    lrBlockedPartywiseDetailLists: any;
    partyNameRet: any;
    lrDtoForUnblock: LRDto = new LRDto();
    lrDtoRetForUnblock: any;
    pageId = "lrblkpw";
    autoId: number = 0;
    selectedBlockPymtType: any;


    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelMainBookingSource: any;
    mainBookingSourceTA: Array<AgentDetailsDto> = [];
    focusMainBookingSourceTA$ = new Subject<string>();
    searchMainBookingSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainBookingSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainBookingSourceTA
                : this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;
    agentDtoForAll: AgentDetailsDto = new AgentDetailsDto();


    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
            this.getMainBookingSourceDetails();
            this.getLrBlockPartywiseDetailsMethod();
        }
    }

    ngOnInit() {
        this.dtOptionsPartywiseDetails = {
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            pagingType: 'full_numbers',
        },
            this.dtOptionsLrBlockedPartywiseDetails = {
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Export</i>',
                        titleAttr: 'Export',
                        title: function () {
                            return "LR Blocked Partywise Details";
                        },
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4]
                        }
                    }
                ],
                searching: true,
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 350,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                pagingType: 'full_numbers',
                pageLength: 9,
                select: true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                }
            }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerPartywiseDetails.unsubscribe();
        this.dtTriggerLrBlockedPartywiseDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerPartywiseDetails.next();
        this.dtTriggerLrBlockedPartywiseDetails.next();
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "LrForm";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    this.destinationDtoForAll = new LRDto();
                    this.destinationDtoForAll.destination = "ALL";
                    this.destinationTA.push(this.destinationDtoForAll);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log("done");
    };

    getMainBookingSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }

    getMainBookingSourceDetails() {
        this.getMainBookingSourceDataDetails();
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                this.mainBookingSourceTA = [];
                this.agentDtoOptionSourceStation = [];
                this.agentDtoOptionSourceStation = response;
                this.agentDtoForAll = new AgentDetailsDto();
                this.agentDtoForAll.mainBookStations = "ALL";
                this.mainBookingSourceTA.push(this.agentDtoForAll);
                for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                    this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Booking Source Details", "info");
            },
            () => console.log('done');
    }

    validateForSearchByGst(event) {
        if (event.charCode == 13) {
            this.enteredGstNumber = $("#" + this.pageId + "gstNumber").val();
            if (this.enteredGstNumber == null || this.enteredGstNumber == undefined || this.enteredGstNumber == '') {
                swal({
                    title: "Mandatory Fields",
                    text: "Please Enter Gst Number!",
                    icon: "warning",
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                })
            } else {
                this.getPartywiseDetails();
            }
        }
    }

    getPartywiseDetails() {
        this.enteredGstNumber = $("#" + this.pageId + "gstNumber").val();
        this.selectedPartyType = $("#" + this.pageId + "partyType").val();

        this.lrDtoForGetPartyDetails = new LRDto();
        this.lrDtoForGetPartyDetails.gstNumber = this.enteredGstNumber;
        this.lrDtoForGetPartyDetails.partyType = this.selectedPartyType;
        this.showSpinnerForAction = true;
        console.log(this.lrDtoForGetPartyDetails);
        this.masterReadService.getPartywiseDetailsForLrBlock(this.lrDtoForGetPartyDetails).subscribe(
            (response) => {
                console.log(response);
                $("#" + this.pageId + "partywiseDetailsTable").DataTable().destroy();
                this.showPartywiseDetailsForLrBlock = [];
                if (response.length > 0) {
                    this.showPartywiseDetailsForLrBlock = response;
                    this.partyNameRet = this.showPartywiseDetailsForLrBlock[0].partyName;
                } else {
                    swal("Alert", "No details found for the entered Gst Number, Please check the Gst No", "warning");
                }
                this.dtTriggerPartywiseDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Party Details", "warning");
            },
            () => console.log('done');
    }

    validateLrBlockPartywiseDetailsMethod() {
        this.enteredSourceForSave = $("#" + this.pageId + "mainBookingSourceId").val();
        this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
        this.selectedPartyType = $("#" + this.pageId + "partyType").val();
        this.enteredGstNumber = $("#" + this.pageId + "gstNumber").val();
        this.selectedBlockPymtType = $("#" + this.pageId + "blockPymtType").val();

        if (this.enteredSourceForSave == null || this.enteredSourceForSave == undefined || this.enteredSourceForSave == ''
            || this.enteredDestinationForSave == null || this.enteredDestinationForSave == undefined || this.enteredDestinationForSave == ''
            || this.selectedPartyType == null || this.selectedPartyType == undefined || this.selectedPartyType == ''
            || this.enteredGstNumber == null || this.enteredGstNumber == undefined || this.enteredGstNumber == ''
            || this.selectedBlockPymtType == null || this.selectedBlockPymtType == undefined || this.selectedBlockPymtType == '') {
            swal("Not Allowed", "Please Enter! All are mandatory fields to proceed!", "warning");
        } else {
            swal({
                title: "Confirm Block!",
                text: "Sure you want to block the bookings for the entered Party?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yes) => {
                if (yes) {
                    this.saveLrBlockPartywiseDetailsMethod();
                }
            });
        }
    }

    saveLrBlockPartywiseDetailsInfo() {
        this.enteredSourceForSave = $("#" + this.pageId + "mainBookingSourceId").val();
        this.enteredDestinationForSave = $("#" + this.pageId + "destination").val();
        this.selectedPartyType = $("#" + this.pageId + "partyType").val();
        this.enteredGstNumber = $("#" + this.pageId + "gstNumber").val();
        this.selectedBlockPymtType = $("#" + this.pageId + "blockPymtType").val();
        this.lrDtoForSaveBlockParty = new LRDto();
        this.lrDtoForSaveBlockParty.source = this.enteredSourceForSave;
        this.lrDtoForSaveBlockParty.destination = this.enteredDestinationForSave;
        this.lrDtoForSaveBlockParty.partyType = this.selectedPartyType;
        this.lrDtoForSaveBlockParty.gstNumber = this.enteredGstNumber;
        this.lrDtoForSaveBlockParty.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoForSaveBlockParty.id = this.autoId;
        this.lrDtoForSaveBlockParty.partyName = this.partyNameRet;
        this.lrDtoForSaveBlockParty.paymentMode = this.selectedBlockPymtType;
        console.log(this.lrDtoForSaveBlockParty);
    }

    saveLrBlockPartywiseDetailsMethod() {
        this.saveLrBlockPartywiseDetailsInfo();
        this.masterReadService.saveLrBlockPartywiseDetails(this.lrDtoForSaveBlockParty).
            subscribe((data) => {
                this.lrDtoRet = data;
                if (this.lrDtoRet.status == "blocked") {
                    swal("Duplicate", 'Entered Party details already available in blocked list!', "warning");
                } else if (this.lrDtoRet.status == "Success") {
                    swal("Success", "Selected Party blocked for bookings!", "info");
                    this.clearFields();
                    this.getLrBlockPartywiseDetailsMethod();
                } else {
                    swal("Failed", "Failed to block the details!", "error");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server problem occurred while block the details!", "error");
            },
            () => console.log('done');
    }

    getLrBlockPartywiseDetailsMethod() {
        this.lrDtoForGetBlockParty = new LRDto();
        this.masterReadService.getLrBlockPartywiseDetails(this.lrDtoForGetBlockParty).subscribe(
            (response) => {
                console.log(response);
                this.lrBlockedPartywiseDetailLists = [];
                $("#" + this.pageId + "lrBlockedPartywiseDetailsTable").DataTable().destroy();
                this.lrBlockedPartywiseDetailLists = response;
                if (response.length == 0) {
                    swal("No Records", "No LR block partywise details found!", "info");
                }
                setTimeout(() => {
                    this.dtTriggerLrBlockedPartywiseDetails.next();
                }, 1000);
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server problem occurred while getting the LR block partywise details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }

    rowSelectedView(lrBlockedPartywiseDetail) {
        $("#" + this.pageId + "mainBookingSourceId").val(lrBlockedPartywiseDetail.source);
        $("#" + this.pageId + "destination").val(lrBlockedPartywiseDetail.destination);
        $("#" + this.pageId + "partyType").val(lrBlockedPartywiseDetail.partyType);
        $("#" + this.pageId + "gstNumber").val(lrBlockedPartywiseDetail.gstNumber);
        $("#" + this.pageId + "blockPymtType").val(lrBlockedPartywiseDetail.paymentMode);
        this.autoId = lrBlockedPartywiseDetail.id;
    }

    setUnblockLrPartywiseDetails(lrBlockedPartywiseDetail) {
        this.lrDtoForUnblock = new LRDto();
        this.lrDtoForUnblock.id = lrBlockedPartywiseDetail.id;
        this.lrDtoForUnblock.userName = this.userDataDtoReturnSession.userId;
        console.log(this.lrDtoForUnblock);
        this.unblockLrPartywiseDetailsMethod();
    }

    unblockLrPartywiseDetailsMethod() {
        swal({
            title: "Confirm Unblock",
            text: "Sure you want to unblock the booking details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yes) => {
            if (yes) {
                this.masterReadService.unblockLrPartywiseDetails(this.lrDtoForUnblock).
                    subscribe((data) => {
                        this.lrDtoRetForUnblock = data;
                        if (this.lrDtoRetForUnblock.status == "Success") {
                            swal("Success", "Booking details unblocked Successfully!", "info");
                            this.getLrBlockPartywiseDetailsMethod();
                        } else {
                            swal("Failed", "Failed to unblock the booking details!", "error");
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        swal("Error", "Server problem occurred while unblock the booking details!", "error");
                    },
                    () => console.log('done');
            }
        });
    }

    clearFields() {
        $("#" + this.pageId + "mainBookingSourceId").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "partyType").val('Consignee');
        $("#" + this.pageId + "gstNumber").val('');
        $("#" + this.pageId + "blockPymtType").val('Both');
        this.enteredGstNumber = null;
        this.enteredSourceForSave = null;
        this.enteredDestinationForSave = null;
        this.selectedPartyType = null;
        this.selectedBlockPymtType = null;
        $("#" + this.pageId + "partywiseDetailsTable").DataTable().destroy();
        this.showPartywiseDetailsForLrBlock = [];
        this.dtTriggerPartywiseDetails.next();
        this.autoId = 0;
    }
}
