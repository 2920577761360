
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import swal from 'sweetalert';

@Component({
    selector: 'app-payment-voucher-rpt-print',
    templateUrl: './payment-voucher-rpt-print.component.html',
    styleUrls: ['./payment-voucher-rpt-print.component.css']
})
export class PaymentVoucherReportPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;

    dataForhireSlipLocalStorageRpt: any;

    hireslipNumber: any;
    invoiceNumber: any;
    invoicedate: any;

    supplierName: any;
    lorryNo: any;
    hireAmt: any;

    departureDate: any;
    departureTime: any;
    arrivalDate: any;
    arrivalTime: any;
    timeTakenToReach: any;

    drivername: any;
    rewardAmt: any;
    rewardTo: any;
    dateInDate: any;

    num: any;
    n: any;
    printAmt: any;

    hireslipdataForPrint: HireSlipDto = new HireSlipDto();

    address: any;
    image: any;
    showSupplier = false;
    toStation: any;
    paymentDate: any;
    hireslipdate: any;
    fromStation: any;
    chqNo: any;
    chqBankName: any;
    chqNoPdc: any;
    chqBankNamePdc: any;
    showChqWise = false;
    showCashWise = false;
    showPdcWise = false;
    totalHire: any;
    tdsPerc: any;
    paidAmt: any;
    rupees: any;
    totalAmt: any;
    tdsAmt: any;
    pageId = "pvrpc";
    firstPrintAdv = true;
    secondPrintAdv = false;
    thirdPrintAdv = false;
    fourthPrintAdv = false;
    fifthPrintAdv = false;
    printArray: any[] = [];
    otherPrints: boolean = true;

    constructor(private router: Router, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.dataForhireSlipLocalStorageRpt = JSON.parse(localStorage.getItem('paymentVoucherPrint'));
            console.log(this.dataForhireSlipLocalStorageRpt);

            if (this.dataForhireSlipLocalStorageRpt != null && this.dataForhireSlipLocalStorageRpt.mode != null
                && this.dataForhireSlipLocalStorageRpt.mode == 'cash') {
                console.log("cs");
                this.hireslipdataForPrint = this.dataForhireSlipLocalStorageRpt;
                this.otherPrints = false;
                let printSize = 0;
                printSize = this.dataForhireSlipLocalStorageRpt.size || 1;
                this.printArray = Array.from({ length: printSize }, () => ({ ...this.hireslipdataForPrint }));
            } else if (this.dataForhireSlipLocalStorageRpt != null) {
                console.log("Cq");
                this.hireslipdataForPrint = this.dataForhireSlipLocalStorageRpt;
                if (this.dataForhireSlipLocalStorageRpt.size == 1) {
                    this.otherPrints = true;
                }
            }

            console.log(this.dataForhireSlipLocalStorageRpt.mode + "-" + this.dataForhireSlipLocalStorageRpt.size);
            // if (this.dataForhireSlipLocalStorageRpt.size == 1) {
            //     this.firstPrintAdv = true;
            //     this.secondPrintAdv = false;
            //     this.thirdPrintAdv = false;
            //     this.fourthPrintAdv = false;
            //     this.fifthPrintAdv = false;
            // }

            // if (this.dataForhireSlipLocalStorageRpt.size == 2) {
            //     this.secondPrintAdv = true;
            //     this.thirdPrintAdv = false;
            //     this.fourthPrintAdv = false;
            //     this.fifthPrintAdv = false;
            // }
            // if (this.dataForhireSlipLocalStorageRpt.size == 3) {
            //     this.secondPrintAdv = true;
            //     this.thirdPrintAdv = true;
            //     this.fourthPrintAdv = false;
            //     this.fifthPrintAdv = false;
            // }
            // if (this.dataForhireSlipLocalStorageRpt.size == 4) {
            //     this.secondPrintAdv = true;
            //     this.thirdPrintAdv = true;
            //     this.fourthPrintAdv = true;
            //     this.fifthPrintAdv = false;
            // }
            // if (this.dataForhireSlipLocalStorageRpt.size == 5) {
            //     this.secondPrintAdv = true;
            //     this.thirdPrintAdv = true;
            //     this.fourthPrintAdv = true;
            //     this.fifthPrintAdv = true;
            // }

            this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");

            this.image = this.userDataDtoReturnSession.ip;
            console.log(this.hireslipdataForPrint);

            this.validatBeforePrint();
            window.setTimeout(function () {
                window.print();
            }, 1000);
            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    validatBeforePrint() {

        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
        if (this.hireslipdataForPrint.suppliername != null && this.hireslipdataForPrint.suppliername != '') {
            this.supplierName = this.hireslipdataForPrint.suppliername == null ? ' ' : this.hireslipdataForPrint.suppliername;
            this.showSupplier = true;
        }
        this.toStation = this.hireslipdataForPrint.toStation == null ? ' ' : this.hireslipdataForPrint.toStation;
        if (this.hireslipdataForPrint.paymentDate != null) {
            this.paymentDate = this.datePipe.transform(this.hireslipdataForPrint.paymentDate, "dd-MM-yyyy");
        } else {
            this.paymentDate = '';
        }
        if (this.hireslipdataForPrint.hireslipdate != null) {
            this.hireslipdate = this.datePipe.transform(this.hireslipdataForPrint.hireslipdate, "dd-MM-yyyy");
        } else {
            this.hireslipdate = '';
        }
        this.lorryNo = this.hireslipdataForPrint.vehicleNumber == null ? ' ' : this.hireslipdataForPrint.vehicleNumber;
        this.hireslipNumber = this.hireslipdataForPrint.hireslipnumber == null ? ' ' : this.hireslipdataForPrint.hireslipnumber;
        this.fromStation = this.hireslipdataForPrint.fromStation == null ? ' ' : this.hireslipdataForPrint.fromStation;


        //////
        if ((this.hireslipdataForPrint.mode != null)
            && (this.hireslipdataForPrint.mode == "cheque")) {
            if (this.hireslipdataForPrint.chequeno != null
                && this.hireslipdataForPrint.advChqBankName != null) {
                this.chqNo = this.hireslipdataForPrint.chequeno;
                this.chqBankName = this.hireslipdataForPrint.advChqBankName;
                this.showChqWise = true;
            }
        } else if ((this.hireslipdataForPrint.mode != null)
            && (this.hireslipdataForPrint.mode == "pdcCheque")) {
            if (this.hireslipdataForPrint.column1 != null
                && this.hireslipdataForPrint.advPdcChqBankName != null) {

                this.chqNoPdc = this.hireslipdataForPrint.column1;
                this.chqBankNamePdc = this.hireslipdataForPrint.advPdcChqBankName;
                this.showPdcWise = true;
            }

            this.totalHire = this.hireslipdataForPrint.totalhire;
            this.tdsPerc = this.hireslipdataForPrint.tdsPercent;
        }

        if (this.hireslipdataForPrint.advChequeAmt > 0) {
            if (this.hireslipdataForPrint.mode != null
                && this.hireslipdataForPrint.mode == "cheque") {
                console.log('121');
                this.totalHire = this.hireslipdataForPrint.totalhire;
                this.tdsPerc = this.hireslipdataForPrint.tdsPercent;
                this.rupees = +(this.hireslipdataForPrint.paid == null ? 0 : this.hireslipdataForPrint.paid) + +(this.hireslipdataForPrint.tdsAmount == null ? 0 : this.hireslipdataForPrint.tdsAmount);
                this.tdsAmt = this.hireslipdataForPrint.tdsAmount == null ? 0 : this.hireslipdataForPrint.tdsAmount;
                this.totalAmt = this.hireslipdataForPrint.paid == null ? 0 : this.hireslipdataForPrint.paid;
                console.log(this.totalHire, this.tdsPerc, this.rupees, this.tdsAmt);
            } else {

                this.rupees = this.hireslipdataForPrint.paid;
                this.totalAmt = this.hireslipdataForPrint.paid;
            }
        } else {
            if (this.hireslipdataForPrint.mode != null
                && this.hireslipdataForPrint.mode == "cash") {
                this.totalHire = this.hireslipdataForPrint.totalhire == null ? 0 : this.hireslipdataForPrint.totalhire;
                this.tdsPerc = this.hireslipdataForPrint.tdsPercent == null ? 0 : this.hireslipdataForPrint.tdsPercent;
                this.rupees = +(this.hireslipdataForPrint.paid == null ? 0 : this.hireslipdataForPrint.paid) + +(this.hireslipdataForPrint.tdsAmount == null ? 0 : this.hireslipdataForPrint.tdsAmount);
                this.tdsAmt = this.hireslipdataForPrint.tdsAmount == null ? 0 : this.hireslipdataForPrint.tdsAmount;
                this.totalAmt = this.hireslipdataForPrint.paid == null ? 0 : this.hireslipdataForPrint.paid;

            } else {
                this.rupees = this.hireslipdataForPrint.paid;
                this.totalAmt = this.hireslipdataForPrint.paid;
            }
        }

        console.log(this.totalHire, this.tdsPerc, this.rupees, this.tdsAmt);
        if (this.hireslipdataForPrint.paid == 0 || this.hireslipdataForPrint.paid == 0.0) {
            this.printAmt = "ZERO";
        } else {
            this.printAmt = this.inWords();
        }
    }

    inWords() {
        var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ',
            'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ',
            'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ',
            'Seventeen ', 'Eighteen ', 'Nineteen '];
        var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty',
            'Seventy', 'Eighty', 'Ninety'];
        this.num = this.hireslipdataForPrint.paid;
        //        console.log( this.num );
        if ((this.num = this.num.toString()).length > 9)
            return 'overflow';
        this.n = ('000000000' + this.num).substr(-9).match(
            /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!this.n)
            return;
        var str = '';
        str += (this.n[1] != 0) ? (a[Number(this.n[1])] || b[this.n[1][0]] + ' '
            + a[this.n[1][1]])
            + 'Crore ' : '';
        str += (this.n[2] != 0) ? (a[Number(this.n[2])] || b[this.n[2][0]] + ' '
            + a[this.n[2][1]])
            + 'Lakh ' : '';
        str += (this.n[3] != 0) ? (a[Number(this.n[3])] || b[this.n[3][0]] + ' '
            + a[this.n[3][1]])
            + 'Thousand ' : '';
        str += (this.n[4] != 0) ? (a[Number(this.n[4])] || b[this.n[4][0]] + ' '
            + a[this.n[4][1]])
            + 'Hundred ' : '';
        str += (this.n[5] != 0) ? ((str != '') ? 'and ' : '')
            + (a[Number(this.n[5])] || b[this.n[5][0]] + ' ' + a[this.n[5][1]])
            + '' : '';

        str += ' Only ';
        return str;
    }
    clearField() {
        this.address = '';
        this.invoiceNumber = '';
        this.hireslipNumber = '';
        this.supplierName = '';
        this.drivername = '';
        this.lorryNo = '';
        this.invoicedate = '';
        this.rewardAmt = 0.0;
        this.printAmt = '';
        this.totalHire = 0;
        this.tdsPerc = 0;
        this.rupees = 0;
        this.tdsAmt = 0;
        this.totalAmt = 0;
        this.showChqWise = false;
        this.showCashWise = false;
        this.showPdcWise = false;
        this.paidAmt = 0;
        this.chqNoPdc = '';
        this.chqBankNamePdc = '';
        this.chqNo = '';
        this.chqBankName = '';
        this.fromStation = '';
        this.toStation = '';
        this.printArray = [];
        this.otherPrints = false;
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
