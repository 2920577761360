//Chg_SNS_v1 : Not checked instead of list in stop based on days (Fixed) by Asrar Jr on 27/11/2024

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { Subject, merge, Observable } from 'rxjs';
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { UserDataDto } from 'src/app/dto/UserData-dto';
import swal from 'sweetalert';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import { MasterReadService } from 'src/app/dataService/masterread-service';

@Component({
    selector: 'app-stock-notification-setup',
    templateUrl: './stock-notification-setup.component.html',
    styleUrls: ['./stock-notification-setup.component.css']
})
export class StockNotificationSetupComponent implements OnInit {

    userDataDto: UserDataDto = new UserDataDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    pageId = "eccpts";
    enteredInvoiceDate: any;
    enteredInvoiceNo = null;
    selectedDays: any;
    dtOptionsStopNotification: any;
    dtTriggerStopNotification: Subject<any> = new Subject();
    stockNotificationStopDetails: UserDataDto[];
    viewDays = false;
    viewStockDate = false;
    viewInvoice = false;
    lrDtoToStation: LRDto = new LRDto();
    destinationOption: Array<LRDto> = [];
    showSpinnerForAction = false;
    lrDtoToStationAll: LRDto = new LRDto();
    showDestination = false;
    selectedDestination: any;
    showDestinationRights = false;

    public modelDropDownDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDropDownDestinationTA$ = new Subject<string>();
    searchDropDownInputFieldDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDropDownDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDropDownDestination = (x: { godownName: string }) => x.godownName;

    // Chg_SNS_v1
    days = [
        { name: 'Sunday', checked: false },
        { name: 'Monday', checked: false },
        { name: 'Tuesday', checked: false },
        { name: 'Wednesday', checked: false },
        { name: 'Thursday', checked: false },
        { name: 'Friday', checked: false },
        { name: 'Saturday', checked: false },
    ];

    constructor(private router: Router, public changeDetectorRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<StockNotificationSetupComponent>, private masterService: MasterService,
        private masterReadService: MasterReadService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getStockNotificationStopDaysDetails();
            this.getStockNotificationStopDetails();
            this.getToStationDetailsList();

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
                        this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == 'Stock Notification Destination View') {
                        this.showDestinationRights = true;
                    }
                }
            }
        }
    }

    ngOnInit() {
        this.dtOptionsStopNotification = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerStopNotification.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerStopNotification.next();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    searchMethod() {
        console.log($("#" + this.pageId + "searchBy").val());
        if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "Days" && this.showDestinationRights) {
            if (($("#" + this.pageId + "destinationID").val() == null) || ($("#" + this.pageId + "destinationID").val() == undefined) ||
                ($("#" + this.pageId + "destinationID").val() == "") || (($("#" + this.pageId + "checkboxSunday").is(":checked") == false) &&
                    ($("#" + this.pageId + "cbStockMonday").is(":checked") == false) && ($("#" + this.pageId + "cbStockTuesday").is(":checked") == false) &&
                    ($("#" + this.pageId + "cbStockWednesday").is(":checked") == false) && ($("#" + this.pageId + "cbStockThursday").is(":checked") == false) &&
                    ($("#" + this.pageId + "cbStockFriday").is(":checked") == false) && ($("#" + this.pageId + "cbStockSaturday").is(":checked") == false))) {
                swal("Mandatory Fields", "Please select Mandatory Fields", "warning");
                return false;
            } else {
                this.confirmUpdateBlockNotitficationDetails();
            }
        } else if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "Days") {
            if ((($("#" + this.pageId + "checkboxSunday").is(":checked") == false) &&
                ($("#" + this.pageId + "cbStockMonday").is(":checked") == false) && ($("#" + this.pageId + "cbStockTuesday").is(":checked") == false) &&
                ($("#" + this.pageId + "cbStockWednesday").is(":checked") == false) && ($("#" + this.pageId + "cbStockThursday").is(":checked") == false) &&
                ($("#" + this.pageId + "cbStockFriday").is(":checked") == false) && ($("#" + this.pageId + "cbStockSaturday").is(":checked") == false))) {
                swal("Mandatory Fields", "Please select Mandatory Fields", "warning");
                return false;
            } else {
                this.confirmUpdateBlockNotitficationDetails();
            }
        }
        else if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "Stock Date" && this.showDestinationRights) {
            if (($("#" + this.pageId + "destinationID").val() == null) || ($("#" + this.pageId + "destinationID").val() == undefined) ||
                ($("#" + this.pageId + "destinationID").val() == "") || ($("#" + this.pageId + "stopStockNotificationDate").val() == null) ||
                ($("#" + this.pageId + "stopStockNotificationDate").val() == undefined) || ($("#" + this.pageId + "stopStockNotificationDate").val() == "")) {
                swal("Mandatory Fields", "Please select Mandatory Fields", "warning");
                return false;
            } else {
                this.confirmUpdateBlockNotitficationDetails();
            }
        } else if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "Stock Date") {
            if (($("#" + this.pageId + "stopStockNotificationDate").val() == null) ||
                ($("#" + this.pageId + "stopStockNotificationDate").val() == undefined) || ($("#" + this.pageId + "stopStockNotificationDate").val() == "")) {
                swal("Mandatory Fields", "Please select Mandatory Fields", "warning");
                return false;
            } else {
                this.confirmUpdateBlockNotitficationDetails();
            }
        } else if ($("#" + this.pageId + "searchBy").val() != null && $("#" + this.pageId + "searchBy").val() == "Invoice") {
            if (($("#" + this.pageId + "stopStockNotificationInvoice").val() == null) || ($("#" + this.pageId + "stopStockNotificationInvoice").val() == undefined) ||
                ($("#" + this.pageId + "stopStockNotificationInvoice").val() == "")) {
                swal("Mandatory Fields", "Please enter Mandatory Fields", "warning");
                return false;
            } else {
                this.confirmUpdateBlockNotitficationDetails();
            }
        }
    }

    confirmUpdateBlockNotitficationDetails() {
        swal({
            title: "Confirm Update",
            text: "Sure you want to update the Stock Arrival Notification stop details?",
            icon: "info",
            buttons: ["No", "Yes"]
        }).then((isConfirm) => {
            if (isConfirm) {
                this.saveBlockNotificationDetails();
            }
        });
    }

    getBlockNotificationDetailsForUpdate() {
        this.userDataDto = new UserDataDto();
        this.selectedDays = [];
        this.enteredInvoiceDate = $("#" + this.pageId + "stopStockNotificationDate").val();
        this.enteredInvoiceNo = $("#" + this.pageId + "stopStockNotificationInvoice").val();
        console.log($("#" + this.pageId + "checkboxSunday").is(":checked"));

        if ($("#" + this.pageId + "checkboxSunday").is(":checked") == true) {
            this.selectedDays.push('Sunday');
        }

        if ($("#" + this.pageId + "cbStockMonday").is(":checked") == true) {
            this.selectedDays.push('Monday');
        }

        if ($("#" + this.pageId + "cbStockTuesday").is(":checked") == true) {
            this.selectedDays.push('Tuesday');
        }

        if ($("#" + this.pageId + "cbStockWednesday").is(":checked") == true) {
            this.selectedDays.push('Wednesday');
        }

        if ($("#" + this.pageId + "cbStockThursday").is(":checked") == true) {
            this.selectedDays.push('Thursday');
        }

        if ($("#" + this.pageId + "cbStockFriday").is(":checked") == true) {
            this.selectedDays.push('Friday');
        }

        if ($("#" + this.pageId + "cbStockSaturday").is(":checked") == true) {
            this.selectedDays.push('Saturday');
        }

        if (this.showDestination == true) {
            this.selectedDestination = $("#" + this.pageId + "destinationID").val();
            this.userDataDto.city = this.selectedDestination;
        } else {
            this.userDataDto.city = this.userDataDtoReturnSession.mainStation;
        }
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.userName = this.userDataDtoReturnSession.userId;
        this.userDataDto.invoiceNumber = this.enteredInvoiceNo;
        this.userDataDto.invoiceDate = this.enteredInvoiceDate;

        this.userDataDto.list = this.selectedDays;
    }
    saveBlockNotificationDetails = () => {
        this.getBlockNotificationDetailsForUpdate();
        console.log(this.userDataDto);
        this.masterService.updateStockNotification(this.userDataDto).subscribe((response) => {
            if (response.status == "Success") {
                swal("Success", "Stock notifications settings saved/updated successfully!", "success");
                this.clearFeilds();
            } else {
                swal("Failed", "Failed to update the details!", "error");
            }
        }), (error) => {
            swal("Server Error", "Server problem occurred while updating the details!", "error");
            console.log(error.json())
        }, () => console.log('done');
    }

    getStockNotificationStopDaysDetails() {
        this.userDataDto = new UserDataDto();
        this.userDataDto.city = this.userDataDtoReturnSession.mainStation;
        this.masterService.getStockNotificationStopDaysDetails(this.userDataDto).subscribe(
            (response) => {
                // Chg_SNS_v1
                // for (let i = 0; i < response.list.length; i++) {
                //     if (response.list[i] == 'Sunday') {
                //         $("#" + this.pageId + "checkboxSunday").prop('checked', true);
                //     } else if (response.list[i] == 'Monday') {
                //         $("#" + this.pageId + "cbStockMonday").prop('checked', true);
                //     } else if (response.list[i] == 'Tuesday') {
                //         $("#" + this.pageId + "cbStockTuesday").prop('checked', true);
                //     } else if (response.list[i] == 'Wednesday') {
                //         $("#" + this.pageId + "cbStockWednesday").prop('checked', true);
                //     } else if (response.list[i] == 'Thursday') {
                //         $("#" + this.pageId + "cbStockThursday").prop('checked', true);
                //     } else if (response.list[i] == 'Friday') {
                //         $("#" + this.pageId + "cbStockFriday").prop('checked', true);
                //     } else if (response.list[i] == 'Saturday') {
                //         $("#" + this.pageId + "cbStockSaturday").prop('checked', true);
                //     }
                // }

                // Chg_SNS_v1
                for (let i = 0; i < response.list.length; i++) {
                    const day = response.list[i];
                    for (let j = 0; j < this.days.length; j++) {
                        if (this.days[j].name === day) {
                            this.days[j].checked = true;
                            break;
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server problem occurred while getting the details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }

    getStockNotificationStopDetails() {
        this.userDataDto = new UserDataDto();
        this.userDataDto.city = this.userDataDtoReturnSession.mainStation;
        this.masterService.getStockNotificationRecentStopDetails(this.userDataDto).subscribe(
            (response) => {
                this.stockNotificationStopDetails = [];
                $("#" + this.pageId + "stopNotificationDetailsTableId").DataTable().destroy();
                this.stockNotificationStopDetails = response;

                setTimeout(() => {
                    this.dtTriggerStopNotification.next();
                }, 1000);
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal("Error", "Server problem occurred while getting the details!", "error");
                console.log(error.json())
            },
            () => console.log('done');
    }

    searchByMode(searchBy: string) {
        if (searchBy === 'Days' && this.showDestinationRights) {
            this.showDestination = true;
            this.viewDays = true;
            this.viewStockDate = false;
            this.viewInvoice = false;
            this.clearFeilds();
        } else if (searchBy === 'Days') {
            this.showDestination = false;
            this.viewDays = true;
            this.viewStockDate = false;
            this.viewInvoice = false;
            this.clearFeilds();
        } else if (searchBy === 'Stock Date' && this.showDestinationRights) {
            this.showDestination = true;
            this.viewDays = false;
            this.viewStockDate = true;
            this.viewInvoice = false;
            this.clearFeilds();
        }
        else if (searchBy === 'Stock Date') {
            this.showDestination = false;
            this.viewDays = false;
            this.viewStockDate = true;
            this.viewInvoice = false;
            this.clearFeilds();
        } else if (searchBy === 'Invoice') {
            this.showDestination = false;
            this.viewDays = false;
            this.viewStockDate = false;
            this.viewInvoice = true;
            this.clearFeilds();
        } else {
            this.showDestination = false;
            this.viewDays = false;
            this.viewStockDate = false;
            this.viewInvoice = false;
            this.clearFeilds();
        }
    }

    getToStationDetails() {
        this.lrDtoToStation.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
    }
    getToStationDetailsList = () => {
        this.getToStationDetails();
        this.masterReadService.getDestinationListDetails(this.lrDtoToStation).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Destination records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    if (response.length == 0) {
                        this.destinationOption = [];
                        this.destinationTA = [];
                    } else {
                        this.destinationOption = [];
                        this.destinationTA = [];
                        this.destinationOption = response;
                        // this.lrDtoToStationAll.godownName = "ALL";
                        // this.destinationTA.push(this.lrDtoToStationAll);
                        for (let i = 0; i < this.destinationOption.length; i++) {
                            this.destinationTA.push(this.destinationOption[i]);
                        }
                        // $("#" + this.pageId + "destinationID").val('ALL');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    clearFeilds() {
        $("#" + this.pageId + "destinationID").val('');
        $("#" + this.pageId + "stopStockNotificationDate").val('');
        $("#" + this.pageId + "stopStockNotificationInvoice").val('');
        $("#" + this.pageId + "checkboxSunday").prop('checked', false);
        $("#" + this.pageId + "cbStockMonday").prop('checked', false);
        $("#" + this.pageId + "cbStockTuesday").prop('checked', false);
        $("#" + this.pageId + "cbStockWednesday").prop('checked', false);
        $("#" + this.pageId + "cbStockThursday").prop('checked', false);
        $("#" + this.pageId + "cbStockFriday").prop('checked', false);
        $("#" + this.pageId + "cbStockSaturday").prop('checked', false);
        this.modelDropDownDestination = null;
    }
}
