import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";
import { DatePipe } from "@angular/common";

//for datatable ends


@Component({
    selector: 'app-scheduled-local-trips',
    templateUrl: './scheduled-local-trips.component.html',
    styleUrls: ['./scheduled-local-trips.component.css']
})
export class ScheduledLocalTripsComponent implements  OnInit {

    getDataScheduledLocalTripDataTable: any;

    scheduledLocalTripDataList: any;

    onDestroyUnsubscribtionScheduledLocalTrip: Subscription;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerScheduledLocalTrip: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsScheduledLocalTrip: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;

    lrDtoForScheduleTripLocal: LRDto = new LRDto();
    lrDtoForScheduleTripLocalLocalStorage: LRDto = new LRDto();
pageId="scltc";
    constructor(private dashboardService: DashboardService, public dialog: MatDialog,
        private route: ActivatedRoute, private router: Router, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    ngOnInit(): void {
        this.dtOptionsScheduledLocalTrip = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerScheduledLocalTrip.unsubscribe();
    //     //this.onDestroyUnsubscribtionScheduledLocalTrip.unsubscribe();
    // }

    ngAfterViewInit(): void {
        this.dtTriggerScheduledLocalTrip.next();
    }

    gridReconifgureOnReloadBtn() {
        this.lrDtoForScheduleTripLocal = new LRDto();

        this.lrDtoForScheduleTripLocal.status = "Scheduled";
        this.lrDtoForScheduleTripLocal.godownName = this.userDataDtoReturnSession.office;
        this.lrDtoForScheduleTripLocal.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForScheduleTripLocal);
        this.gridReconifgureDetails();
    }
    gridReconifgureDetails = () => {
        this.showSpinnerForAction = true;
        this.dashboardService.getPendingLocalTrips(this.lrDtoForScheduleTripLocal).subscribe(
            (response) => {
                console.log(response);
                this.scheduledLocalTripDataList = [];
               // $("#"+this.pageId+"scheduleLocalTripTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.scheduledLocalTripDataList = response;
                } else {
                    swal("Warning", "No Details found !", "warning");
                }
                this.showSpinnerForAction = false;
                // this.dtTriggerScheduledLocalTrip.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Pending Schedule Local Trip", "info");
            }, () => console.log('done');
    };

    slectedRowOfScheduleLocalTrip(scheduledLocalTripData) {
        if (scheduledLocalTripData.vehicleNumber != null) {
            if (this.userDataDtoReturnSession.office != null &&
                this.userDataDtoReturnSession.office == "Binnymills") {
                this.lrDtoForScheduleTripLocalLocalStorage = new LRDto();
                this.lrDtoForScheduleTripLocalLocalStorage.destination = scheduledLocalTripData.deliverygodown;
                this.lrDtoForScheduleTripLocalLocalStorage.mode = "localgrouping";
                this.lrDtoForScheduleTripLocalLocalStorage.invoiceNumber = null;
                this.lrDtoForScheduleTripLocalLocalStorage.tripId = scheduledLocalTripData.tripId;
                localStorage.clear();
                localStorage.setItem('stockForTripsheetDashboardLocalStorage',
                    JSON.stringify(this.lrDtoForScheduleTripLocalLocalStorage));
                // this.router.navigate(['/stocks-for-tripsheet-page/stocksForTripsheet']);
                this.router.navigate(['/trip-creation/localTripLoadingSheetFlow']);
            } else {
                this.lrDtoForScheduleTripLocalLocalStorage = new LRDto();
                this.lrDtoForScheduleTripLocalLocalStorage.destination = scheduledLocalTripData.deliverygodown;
                this.lrDtoForScheduleTripLocalLocalStorage.mode = "localgrouping";
                this.lrDtoForScheduleTripLocalLocalStorage.invoiceNumber = null;
                this.lrDtoForScheduleTripLocalLocalStorage.module = "destination";
                this.lrDtoForScheduleTripLocalLocalStorage.selectedTripTo = scheduledLocalTripData.deliverygodown;
                this.lrDtoForScheduleTripLocalLocalStorage.toStation = scheduledLocalTripData.deliverygodown;
                this.lrDtoForScheduleTripLocalLocalStorage.tripId = scheduledLocalTripData.tripId;
                localStorage.clear();
                localStorage.setItem('stockForTripsheetDashboardLocalStorage',
                    JSON.stringify(this.lrDtoForScheduleTripLocalLocalStorage));
                // this.router.navigate(['/stocks-for-tripsheet-page/stocksForTripsheet']);
                this.router.navigate(['/trip-creation/localTripLoadingSheetFlow']);
            }
        }
    }
   
}

