import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { Inject, ViewChild } from '@angular/core';
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ReportService } from 'src/app/dataService/report-service';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from "@angular/common";
import swal from 'sweetalert';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseDto } from 'src/app/dto/Response-dto';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { FormControl } from '@angular/forms';
import { LrService } from 'src/app/dataService/lr-service';
export interface DialogData {
    listOfDataToPass: string[];
    listOfDataToPasstoLrEntry: string[];
}
@Component({
    selector: 'app-generate-barcode',
    templateUrl: './generate-barcode.component.html',
    styleUrls: ['./generate-barcode.component.css']
})
export class GenerateBarcodeComponent implements OnInit {
    lrDto: LRDto = new LRDto();
    userDataDtoReturnSession: any;
    lrDetailsDataList: any;
    listOfDataToGet: any;
    list: string[];
    lrDtoMultiLrs: LRDto = new LRDto();
    lrDtoMultiLrsRet: any;
    selectedLrNumber: any;
    readonlyLrNo = true;
    pageId = "gbcs";
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLrDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrDetails: any;
    enteredEWayBillNo: any;
    destinationOptions: LRDto[];
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestnationTA$ = new Subject<string>();

    destnationSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestnationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destinationState.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destinationState: string }) => x.destinationState;
    showSpinnerForAction = false;
    lrtDtoRespForEwayApi: LRDto = new LRDto();
    responseDtoForEwayApi: ResponseDto = new ResponseDto();
    enteredPincode: any;
    destinationMainStation: any;
    userDataDto: any;
    lrDtoSubstation: LRDto = new LRDto();
    controlDestination = new FormControl();
    enteredLrNumber: any;
    destination: any;
    PrintBarcode = [];
    articleCount: number = 1;
    isLoggedIn = true;
    dataForPrint: any;
    viewBarcodePrint = false;
    viewBarcodeEntryForm = true;
    lrDtoBarcode: LRDto = new LRDto();
    lrDtoForLrRet = new LRDto();
    constructor(
        private masterReadService: MasterReadService, private reportService: ReportService,
        private router: Router, private datePipe: DatePipe, public dialog: MatDialog,
        private route: ActivatedRoute, public changeDetectorRef: ChangeDetectorRef,
        private ewaybillService: EwaybillService, private lrService: LrService
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

        }
        this.getHoldLrsDetails();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {


        this.dtOptionsLrDetails = {
            dom: 'frtip',
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            searching: false,
            scrollX: true,
            scrollY: 280,
            scrollCollapse: true,
            paging: false,
            info: false,
            buttons: false,  // Disables all buttons
            footerCallback: function (row, data, start, end, display) {
                var api = this.api();

                // Remove the formatting to get integer data for summation
                var intVal = function (i) {
                    return typeof i === 'string' ? +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ? i : 0;
                };

                var columnData = api.column(0).data();

                // Update footer with the count of LR numbers
                $(api.column(0).footer()).html(
                    columnData.count()
                );
            }
        };

    }
    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerLrDetails.next();

    }



    getHoldLrsInfo() {
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
    }
    getHoldLrsDetails = () => {
        this.getHoldLrsInfo();
        this.masterReadService.getBlockedLrsList(this.lrDto).subscribe(
            (response) => {
                if (response.length == 0) {

                    swal({
                        title: "Warning",
                        text: "No records found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    $("#" + this.pageId + "totalLrs").val('');
                } else {
                    this.lrDetailsDataList = [];
                    $("#" + this.pageId + "barcodeTable").DataTable().destroy();
                    this.lrDetailsDataList = response;

                    $("#" + this.pageId + "lrNumber").val('');
                }
                this.readonlyLrNo = false;

                this.dtTriggerLrDetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
    };





    goToLrEntryPage(lrDetailsData: any) {
        this.selectedLrNumber = lrDetailsData.lrNumber;
        this.enteredLrNumber = lrDetailsData.lrNumber;
        console.log("Selected LR Number:", this.selectedLrNumber);
        console.log("Entered LR Number:", this.enteredLrNumber);
    }


    validateClear() {
        $("#" + this.pageId + "lrNumber").val('');
        $("#" + this.pageId + "eWayBillNo").val('');
        $("#" + this.pageId + "pinCode").val('');
        $("#" + this.pageId + "destination").val('');
    }

    eWayBillNoFocus() {
        if (this.enteredEWayBillNo) {
            this.getDetailsFromEwayBillRefreshEwb();

        }
    }

    getDetailsFromEwayBillRefreshEwb = () => {

        this.clearEwayBillDetails();
        this.showSpinnerForAction = true;
        this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
        this.lrtDtoRespForEwayApi = new LRDto();
        this.responseDtoForEwayApi = new ResponseDto();
        this.responseDtoForEwayApi.ewayBillNo = this.enteredEWayBillNo;
        //CHG-V-LR:26
        this.responseDtoForEwayApi.token1 = this.userDataDtoReturnSession.mainAdminStation;
        this.ewaybillService.getDetailsFromEwayBillRefreshEwb(this.responseDtoForEwayApi).subscribe(
            (response) => {

                this.showSpinnerForAction = false;
                this.lrtDtoRespForEwayApi = response;
                console.log(this.lrtDtoRespForEwayApi);

                if (this.lrtDtoRespForEwayApi.ewayBillStatus != null && this.lrtDtoRespForEwayApi.ewayBillStatus == "CANCEL") {
                    swal("Not Allowed", "This E-Way Bill Number : " + this.enteredEWayBillNo + "  show as Cancelled!.. Please Verify entered E-Way Bill No!", "warning");
                    $("#" + this.pageId + "eWayBillNo").val('');
                    this.enteredEWayBillNo = null;
                } else if (this.lrtDtoRespForEwayApi.isBlockedEwayBill) {
                    swal("Not Allowed", "The entered E-Way Bill No : " + this.enteredEWayBillNo + " is expiring today!.. Please do the extension to continue with the LR Generation.", "warning");
                    $("#" + this.pageId + "eWayBillNo").val('');
                    this.enteredEWayBillNo = null;
                } else {
                    if (this.lrtDtoRespForEwayApi.respStatus == 1) {
                        $("#" + this.pageId + "pinCode").val(this.lrtDtoRespForEwayApi.pinCode);
                        this.validatePincode();



                    } else {

                        console.log('getDetailsFromEwayBillRefreshEwb : no ewaybill details found!');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the details from the EWay Bill Number", "warning");
            }, () => console.log('done');
    }

    clearEwayBillDetails() {
        $("#" + this.pageId + "pinCode").val('');
        $("#" + this.pageId + "destination").val('');
        this.destinationOptions = [];
        this.destinationTA = [];
    }
    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }



    validatePincode() {
        this.enteredPincode = $("#" + this.pageId + "pinCode").val();
        console.log('pincode length : ' + this.enteredPincode.length);
        if (this.enteredPincode == null || this.enteredPincode == "" || this.enteredPincode == undefined) {
            swal({
                title: "Not Allowed",
                text: "Please enter the destination pincode",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                window.setTimeout(function () {
                    $("#lrefcdestination").val('');
                    this.destinationOptions = [];
                    this.destinationTA = [];


                }, 100);
            })
        } else if (this.enteredPincode.length > 6 || this.enteredPincode.length < 6) {
            swal({
                title: "Invalid pincode",
                text: "Please enter the valid pincode!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                window.setTimeout(function () {
                    $("#lrefcdestination").val('');
                    this.destinationOptions = [];
                    this.destinationTA = [];

                }, 100);
            })
        } else {
            console.log('pincode validation');
            this.checkForPinCodeForSame();
        }
    }

    checkForPinCodeForSame() {
        this.destinationMainStation = null;
        this.enteredPincode = null;
        this.enteredPincode = $("#" + this.pageId + "pinCode").val();
        console.log("this.enteredPincode");
        console.log(this.enteredPincode);
        this.userDataDto = new UserDataDto();
        this.userDataDto.pinCodeStr = this.enteredPincode.trim();
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.isDeleted = false;
        //this.userDataDto.mainStation = this.destSplit;

        this.masterReadService.getPincodeDetailsForLrV2(this.userDataDto).subscribe(
            (response) => {
                if (response.status == "not found") {
                    swal({
                        title: "Warning",
                        text: "No destination found for the entered pincode, please verify and enter the correct pincode!",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        window.setTimeout(function () {
                            $("#destination").val('');
                            this.destinationOptions = [];
                            this.destinationTA = [];

                        }, 100);


                    })
                } else if (response.status == "success") {
                    $("#destination").val('');
                    this.destinationMainStation = response.mainAdminStation;
                    this.getEnteredPincodeStation(response.mainStation);
                } else if (response.status == "failed") {
                    swal({
                        title: "Server Error",
                        text: "Server Error While Getting Details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Server Error While Getting Details",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }), () => console.log('done');
    }

    getEnteredPincodeStationDetailsList(station) {
        this.lrDtoSubstation = new LRDto();
        this.lrDtoSubstation.branch = this.userDataDtoReturnSession.office;
        this.lrDtoSubstation.mode = "LrFormPincodeStation";
        this.lrDtoSubstation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSubstation.destination = station;
        this.lrDtoSubstation.pinCode = this.enteredPincode.trim();
    }

    getEnteredPincodeStation = (station) => {
        this.getEnteredPincodeStationDetailsList(station);
        this.masterReadService.getDestinationForLREntryForm(this.lrDtoSubstation).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Destination Records Found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.controlDestination.reset();
                    this.destinationOptions = [];
                    this.destinationTA = [];
                } else {
                    this.controlDestination.reset();
                    console.log("dest response" + response);
                    this.destinationOptions = response;
                    this.destinationTA = [];

                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        console.log("this.destinationOptions[i]" + this.destinationOptions[i]);
                        console.log("this.destinationOptions[i].listOfDestination" + this.destinationOptions[i].listOfDestination);
                        console.log("this.destinationOptions[i].mainstration" + this.destinationOptions[i]);
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    setTimeout(() => {
                        const destinationField = document.getElementById(`${this.pageId}destination`) as HTMLInputElement;
                        destinationField.focus();
                    });
                }
                this.changeDetectorRef.detectChanges();
            },
            (error) => console.log(error),
            () => console.log('done')
        );
    }


    resetDestinationWithEmpty = () => {
        this.destinationOptions = [];
        this.destinationTA = [];
        for (let i = 0; i < this.destinationOptions.length; i++) {
            this.destinationTA.push(this.destinationOptions[i]);
        }
    }
    printBarcodes(lrDtoBarcode) {
        document.getElementById('printSection')!.style.display = 'block';
        console.log("this.dataForPrint", this.dataForPrint);

        this.PrintBarcode = [];
        let listOfBarcodes: string[] = [];

        for (let i = 1; i <= lrDtoBarcode.listOfBarcodes.length; i++) {

            this.PrintBarcode.push({
                barcode: lrDtoBarcode.listOfBarcodes[i - 1],
                Name: `${this.enteredLrNumber}`,
                Destination: `${this.destination}`,
                ArticleIndex: `${i}/${this.articleCount}`
            });


        }

        setTimeout(() => {
            window.print();
            document.getElementById('printSection')!.style.display = 'none';
        }, 500);
        this.changeDetectorRef.detectChanges();
    }



    onCancelPrint() {

        this.viewBarcodePrint = false;
        this.viewBarcodeEntryForm = true;


        this.PrintBarcode = [];


        localStorage.removeItem('printBarcodeData');


        this.changeDetectorRef.detectChanges();
    }






    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestination = e.item;
        console.log(this.modelDestination);
        this.destination = this.modelDestination.destination;
        console.log(this.destination);
    }

    getBarcodeEntryData() {
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();

        this.lrDtoBarcode = new LRDto();
        this.lrDtoBarcode.lrNumber = this.enteredLrNumber;
        this.lrDtoBarcode.totalArticles = this.articleCount;
        this.lrDtoBarcode.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoBarcode.entryBy = this.userDataDtoReturnSession.userId;

        console.log(this.lrDtoBarcode);
    }
    generateBarcode = () => {
        this.getBarcodeEntryData();
        this.showSpinnerForAction = true;

        this.lrService.addBarcodeDetails(this.lrDtoBarcode).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.lrDtoForLrRet = new LRDto();
                this.lrDtoForLrRet = response;

                if (this.lrDtoForLrRet.status === "Already Exists") {
                    swal({
                        title: "Warning",
                        text: "Barcode Already Generated for this LR",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else if (this.lrDtoForLrRet.status === "Success") {
                    this.printBarcodes(this.lrDtoForLrRet);
                } else {
                    swal({
                        title: "Warning",
                        text: "Failed to generate barcode",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }

                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred adding barcode details", "info");
            },
            () => console.log('done')
        );
    };



}
