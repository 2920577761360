import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from "@angular/core";
import { ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DataTableDirective } from "angular-datatables";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import swal from "sweetalert";
import { LRDto } from "src/app/dto/LR-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from "rxjs";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MatDialog } from "@angular/material/dialog";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { LrService } from "src/app/dataService/lr-service";
const my = new Date();

@Component({
  selector: "app-booking-trip-details-barcode",
  templateUrl: "./booking-trip-details-barcode.component.html",
  styleUrls: ["./booking-trip-details-barcode.component.css"],
})
export class BookingTripDetailsBarcodeComponent implements OnInit {
  //to insert value of selected row in table to input field starts
  consignorName: string;
  selectedUser: any;
  //to insert value of selected row in table to input field ends
  getDataFrmServiceFrLrDetailsTable: any;
  getDataFrmServiceFrCheckedLrDetailsTable: any;

  onDestroyUnsubscribtionLrDetails: Subscription;
  onDestroyUnsubscribtionCheckedLrDetails: Subscription;

  //for the select option with filter starts

  controlTruckCapacity = new FormControl();
  controlBins = new FormControl();

  truckCapacityOptions = [
    { id: 1, label: "ADD NEW" },
    { id: 2, label: "0" },
    { id: 3, label: "1" },
    { id: 4, label: "2" },
    { id: 5, label: "3" },
  ];
  binsOptions = [
    { id: 1, label: "All" },
    { id: 2, label: "Bangalore" },
    { id: 3, label: "Chennai" },
    { id: 4, label: "Erode" },
    { id: 5, label: "Kannur" },
  ];

  //for the select option with filter ends

  //for popup modal starts
  closeResultContent: string;
  //for popup modal ends

  //for datepicker starts
  model: NgbDateStruct;
  model2;
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  loadingIndicator = true;
  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;
  dataTable: any;
  dtTriggerLrDetails: Subject<any> = new Subject();
  dtTriggerCheckedLrDetails: Subject<any> = new Subject();
  // @ViewChild('dataTable') table: ElementRef;
  dtOptionsLrDetails: any;
  dtOptionCheckedLrDetails: any;
  lrDetailsDataList: any;
  checkedLrDetailsDataList: any;
  resultList: any = null;
  userDataDtoReturnSession: any;
  isLoggedIn = true;
  showSpinnerForAction = false;
  tripsOfAgentBookingData: any;
  lrDto: LRDto = new LRDto();
  enteredLrNumber: any = null;
  listOfLrs: string[];
  selectedLoaderHead: any = null;
  selectedBayNo: any = null;
  unldDt: NgbDateStruct;
  selectedUnldDt: any;
  selectedUnldTime: any;

  labourHeadNameOption: Array<HireSlipDto> = [];
  hireSlipDtoLabourHeadName: HireSlipDto = new HireSlipDto();
  public modelDropDownLabourHeadName: any;
  labourHeadNameTA: Array<HireSlipDto> = [];
  focusDropDownLabourHeadNameTA$ = new Subject<string>();
  searchDropDownInputFieldLabourHeadName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusDropDownLabourHeadNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.labourHeadNameTA
          : this.labourHeadNameTA.filter(
            (v) => v.loaderHead.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterDropDownLabourHeadName = (x: { loaderHead: string }) => x.loaderHead;

  bayNoOption: Array<HireSlipDto> = [];
  hireSlipDtoBayNo: HireSlipDto = new HireSlipDto();
  public modelDropDownBayNo: any;
  focusDropDownBayNoTA$ = new Subject<string>();
  searchDropDownInputFieldBayNo = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusDropDownBayNoTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.bayNoOption
          : this.bayNoOption.filter(
            (v) => v.bayNo.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterDropDownBayNo = (x: { bayNo: string }) => x.bayNo;

  lrDtoAutoLrPrint: LRDto = new LRDto();
  viewLrEntryPrint = false;
  getvalueForLrPrint: any;
  setValueForLrPrint: LRDto = new LRDto();
  modalRefferenceLrPrintCopyPopUp: NgbModalRef;
  modalRefferenceLrPrintCopyClosePopUp: string;
  @ViewChild("lrPrintCopyPopUpTemplate") private lrPrintCopyPopUpTemplate;
  viewLrPrintCopyPopUp = false;
  selectedLRNo: any;
  pageId = "btdc";
  bayFlow = false;
  showSpinnerForMoveAllLrs = false;
  showMovelAllButton = false;
  barcodeInput: string = '';
  scanTimeout: any;
  lastInputTime: number | null = null;
  lrDtoUpdate: LRDto = new LRDto();
  constructor(
    private router: Router,
    private hireslipService: HireslipService,
    private masterReadService: MasterReadService,
    private modalService: NgbModal,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    private lrService: LrService
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      //sweet alert starts
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
      //sweet alert ends
    }
    if (this.isLoggedIn) {
      // userdata get through from login starts
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );
      this.tripsOfAgentBookingData = JSON.parse(
        localStorage.getItem("tripOfAgntBkgLocalStorage")
      );
      if (
        this.userDataDtoReturnSession.bayFlowInLR != null &&
        this.userDataDtoReturnSession.bayFlowInLR
      ) {
        this.bayFlow = true;
      }
      if (
        this.userDataDtoReturnSession.localBkgStockAllOption != null &&
        this.userDataDtoReturnSession.localBkgStockAllOption
      ) {
        this.showMovelAllButton = true;
      }
      setTimeout(() => {
        $("#" + this.pageId + "tripDate").val(
          this.tripsOfAgentBookingData.depatureDtStr
        );
        $("#" + this.pageId + "vehicleNumber").val(
          this.tripsOfAgentBookingData.vehicleNumber
        );
        $("#" + this.pageId + "tripNo").val(
          this.tripsOfAgentBookingData.tripNo
        );
        $("#" + this.pageId + "agent").val(
          this.tripsOfAgentBookingData.fromStation
        );
        $("#" + this.pageId + "totalLrs").val(
          this.tripsOfAgentBookingData.totalLRs
        );

        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val(
          this.tripsOfAgentBookingData.unloadedBy
        );
        $("#" + this.pageId + "dropDownInputFieldBayNo").val(
          this.tripsOfAgentBookingData.bayNo
        );
        $("#" + this.pageId + "unldDt").val(
          this.tripsOfAgentBookingData.bayUnloadingDate
        );
        $("#" + this.pageId + "unldTime").val(
          this.tripsOfAgentBookingData.bayUnloadingTime
        );
        console.log(
          this.tripsOfAgentBookingData.bayUnloadingDate +
          "-" +
          this.tripsOfAgentBookingData.bayNo
        );
        this.getBookingTripDetailsList();
        this.setLabourHeadNameDetailsList();
        this.setBayNo();
      }, 2000);
    }
  }

  ngOnInit(): void {
    var groupColumnAll = 2;
    var groupColumnChecked = 2;

    this.dtOptionsLrDetails = {
      dom: "Bfrtip",
      buttons: [],
      order: [],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      //scroll in datatable starts
      responsive: true,
      scrollX: true,
      scrollY: 300,
      scrollCollapse: true,
      pagingType: "full_numbers",
      // pageLength: 6,
      paging: false,
      info: false,
      footerCallback: function (row, data, start, end, display) {
        var api = this.api(),
          data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === "string"
            ? +i.replace(/[\$,]/g, "") * 1
            : typeof i === "number"
              ? i
              : 0;
        };

        var actWgt = api
          .column(4)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var chgWgt = api
          .column(5)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var toPay = api
          .column(6)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var paid = api
          .column(7)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        $(api.column(0).footer()).html("Total : " + data.length);
        $(api.column(4).footer()).html(actWgt);
        $(api.column(5).footer()).html(chgWgt);
        $(api.column(6).footer()).html(toPay);
        $(api.column(7).footer()).html(paid);
      },
      drawCallback: function (settings) {
        var api = this.api();
        var rows = api
          .rows({
            page: "current",
          })
          .nodes();
        var last = null;

        var api = this.api();
        var rows = api
          .rows({
            page: "current",
          })
          .nodes();
        var last = null;

        var totalLrs = 0;
        var actWgt = 0;
        var cgdWgt = 0;
        var totToPay = 0;
        var totPaid = 0;
        api
          .column(groupColumnAll, {
            page: "current",
          })
          .data()
          .each(function (group, i) {
            var val = api.row(api.row($(rows).eq(i)).index()).data();
            if (last !== group) {
              if (i != 0) {
                $(rows)
                  .eq(i)
                  .before(
                    $(
                      "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                      {
                        class: "group",
                        "data-id": group,
                      }
                    )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: "Total : " + totalLrs,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 2,
                          text: "",
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: actWgt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: cgdWgt,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: totToPay,
                        })
                      )
                      .append(
                        $("<td></td>", {
                          colspan: 1,
                          text: totPaid,
                        })
                      )
                      .prop("outerHTML")
                  );
                totalLrs = 0;
                actWgt = 0;
                cgdWgt = 0;
                totToPay = 0;
                totPaid = 0;
              }
              $(rows)
                .eq(i)
                .before(
                  $(
                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                    {
                      class: "group",
                      "data-id": group,
                    }
                  )
                    .append(
                      $("<td></td>", {
                        colspan: 6,
                        text: group,
                      })
                    )
                    .prop("outerHTML")
                );
              last = group;
            }
            totalLrs++;
            actWgt += +val[4];
            cgdWgt += +val[5];
            totToPay += +val[6];
            totPaid += +val[7];

            if (i == rows.length - 1) {
              $(rows)
                .eq(i)
                .after(
                  $(
                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                    {
                      class: "group",
                      "data-id": group,
                    }
                  )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: "Total : " + totalLrs,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: "",
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: "",
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: actWgt,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: cgdWgt,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: totToPay,
                      })
                    )
                    .append(
                      $("<td></td>", {
                        colspan: 1,
                        text: totPaid,
                      })
                    )
                    .prop("outerHTML")
                );
              totalLrs = 0;
              actWgt = 0;
              cgdWgt = 0;
              totToPay = 0;
              totPaid = 0;
            }
          });
      },
    };

    this.dtOptionCheckedLrDetails = {
      dom: "Bfrtip",
      buttons: [],
      order: [[1, "asc"]],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      //scroll in datatable starts
      responsive: true,
      scrollX: true,
      scrollY: 300,
      scrollCollapse: true,
      pagingType: "full_numbers",
      // pageLength: 6,
      paging: false,
      info: false,
      footerCallback: function (row, data, start, end, display) {
        const api = this.api();

        const intVal = function (i: any): number {
          return typeof i === 'string'
            ? parseFloat(i.replace(/[\$,]/g, '')) || 0
            : typeof i === 'number'
              ? i
              : 0;
        };

        const actWgt = api
          .column(6)
          .data()
          .reduce((a: any, b: any) => intVal(a) + intVal(b), 0);

        const chgWgt = api
          .column(7)
          .data()
          .reduce((a: any, b: any) => intVal(a) + intVal(b), 0);

        const toPay = api
          .column(8)
          .data()
          .reduce((a: any, b: any) => intVal(a) + intVal(b), 0);

        const paid = api
          .column(9)
          .data()
          .reduce((a: any, b: any) => intVal(a) + intVal(b), 0);

        $(api.column(0).footer()).html('Total : ' + data.length);
        $(api.column(6).footer()).html(actWgt.toFixed(2));
        $(api.column(7).footer()).html(chgWgt.toFixed(2));
        $(api.column(8).footer()).html(toPay.toFixed(2));
        $(api.column(9).footer()).html(paid.toFixed(2));
      }


    };
    window.addEventListener('keydown', this.handleBarcodeScan);
  }

  getBookingTripDetailsList() {
    this.getBookingTripDetails();
    this.showSpinnerForAction = true;
    this.hireslipService
      .getBookingTripDetails(this.lrDto)
      .subscribe((response) => {
        console.log(response);
        this.lrDetailsDataList = [];
        this.checkedLrDetailsDataList = [];
        $("#" + this.pageId + "bkgTripLrsTableId")
          .DataTable()
          .destroy();
        if (response.length > 0) {
          this.lrDetailsDataList = response;
        } else {
          swal("Alert", "No Data Found", "warning");
        }
        this.dtTriggerLrDetails.next();
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server Error While Getting Booking Trip LR Details",
          "warning"
        );
      },
      () => console.log("done");
  }

  getBookingTripDetails() {
    this.lrDto = new LRDto();
    this.lrDto.tripId = this.tripsOfAgentBookingData.tripId;
    this.lrDto.localTripTrns = this.userDataDtoReturnSession.localTripTrns;
  }

  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  ngAfterViewInit(): void {
    this.dtTriggerLrDetails.next();
    this.dtTriggerCheckedLrDetails.next();
    setTimeout(() => {
      $(`#${this.pageId}bkgTripCheckedLrsTableId`).DataTable().columns.adjust();
    }, 100);
  }

  ngOnDestroy(): void {
    this.dtTriggerLrDetails.unsubscribe();
    this.dtTriggerCheckedLrDetails.unsubscribe();
    window.addEventListener('keydown', this.handleBarcodeScan);

  }


  setLRNumberDetailsValueInDatatTable(lrnumber) {
    this.enteredLrNumber = null;
    this.enteredLrNumber = lrnumber;
    this.enteredLrNumber =
      this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();
    this.showSpinnerForAction = true;
    var validateRemaingLrNumber = this.findRemainingLrsDup(
      this.lrDetailsDataList,
      this.enteredLrNumber
    );
    var validateEligibleLrNumber = this.findEligibleLrs(
      this.checkedLrDetailsDataList,
      this.enteredLrNumber
    );
    this.showSpinnerForAction = false;
    if (validateRemaingLrNumber == null && validateEligibleLrNumber == null) {
      swal("Alert", "LR number does not exist", "warning");

    } else if (validateEligibleLrNumber != null) {
      swal(
        "LR Stock In",
        "LR Number '" +
        lrnumber +
        "'Already Transfered",
        "warning"
      );
      $("#" + this.pageId + "lrNumber").focus();
    } else {
      this.getDataForLrPrint(this.enteredLrNumber);
    }
  }

  confirmBtnPopup() {
    this.setLrNumberEligibleAndRemaingLrs();
    this.modalRefferenceLrPrintCopyPopUp.close();
    $("#" + this.pageId + "lrNumber").val("");
    $("#" + this.pageId + "lrNumber").focus();
    this.enteredLrNumber = null;
  }

  findRemainingLrsDup(findRemaingLrs, lrNumber) {
    var checkingRemLrNumber = null;
    var remainLrNumberReturn = null;
    for (let i = 0; i < findRemaingLrs.length; i++) {
      checkingRemLrNumber = findRemaingLrs[i].lrNumber;
      if (checkingRemLrNumber == lrNumber) {
        remainLrNumberReturn = checkingRemLrNumber;
        break;
      } else {
        remainLrNumberReturn = null;
      }
    }
    return remainLrNumberReturn;
  }

  findEligibleLrs(findEligibleLrs, lrNumber) {
    var checkingEligLrNumber = null;
    var eligibleLrNumberReturn = null;
    for (let i = 0; i < findEligibleLrs.length; i++) {
      checkingEligLrNumber = findEligibleLrs[i].lrNumber;
      if (checkingEligLrNumber == lrNumber) {
        eligibleLrNumberReturn = checkingEligLrNumber;
        break;
      } else {
        eligibleLrNumberReturn = null;
      }
    }
    return eligibleLrNumberReturn;
  }

  setLrNumberEligibleAndRemaingLrs() {
    this.resultList = null;
    this.showSpinnerForAction = true;

    console.log("enteredLrNumber" + this.enteredLrNumber);
    this.enteredLrNumber =
      this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();
    this.resultList = this.lrDetailsDataList.filter(
      (remainingLrsData) => remainingLrsData.lrNumber == this.enteredLrNumber
    );
    if (this.resultList.length > 0) {
      for (let i = 0; i < this.resultList.length; i++) {
        this.resultList[i].loadingArticle = 1;
        this.checkedLrDetailsDataList.push(this.resultList[i]);
      }


      $("#" + this.pageId + "bkgTripCheckedLrsTableId")
        .DataTable()
        .destroy();
      setTimeout(() => {
        this.dtTriggerCheckedLrDetails.next();
      }, 500);

      const deletedContrat = this.lrDetailsDataList.find(
        (x) => x.lrNumber === this.enteredLrNumber
      );
      this.lrDetailsDataList.splice(
        this.lrDetailsDataList.indexOf(deletedContrat),
        1
      );
      $("#" + this.pageId + "bkgTripLrsTableId")
        .DataTable()
        .destroy();
      setTimeout(() => {
        this.dtTriggerLrDetails.next();
      }, 500);
      // console.log(deletedContrat);
      this.changeDetectorRef.detectChanges();
    }
    this.showSpinnerForAction = false;
  }

  rowSelectedLrDelete(selectedLrsRowData) {
    this.enteredLrNumber = null;
    this.enteredLrNumber = selectedLrsRowData.lrNumber;
    this.enteredLrNumber =
      this.enteredLrNumber == null ? null : this.enteredLrNumber;
    this.resultList = null;
    this.resultList = this.checkedLrDetailsDataList.filter(
      (eligibleLrsData) =>
        eligibleLrsData.lrNumber == selectedLrsRowData.lrNumber
    );
    if (this.resultList.length > 0) {
      for (let i = 0; i < this.resultList.length; i++) {
        this.lrDetailsDataList.push(this.resultList[i]);
      }
      $("#" + this.pageId + "bkgTripLrsTableId")
        .DataTable()
        .destroy();
      setTimeout(() => {
        this.dtTriggerLrDetails.next();
      }, 500);

      const deletedContrat = this.checkedLrDetailsDataList.find(
        (x) => x.lrNumber === selectedLrsRowData.lrNumber
      );
      this.checkedLrDetailsDataList.splice(
        this.checkedLrDetailsDataList.indexOf(deletedContrat),
        1
      );
      $("#" + this.pageId + "bkgTripCheckedLrsTableId")
        .DataTable()
        .destroy();
      setTimeout(() => {
        this.dtTriggerCheckedLrDetails.next();
      }, 500);
    }
  }

  confirmLrs() {
    if (this.checkedLrDetailsDataList.length == 0) {
      swal("Alert", "No LR found to transfer", "warning");
    }
    // removed the below validation @19/09/2021 only if bayFlow logic true
    else if (
      this.bayFlow == false &&
      ($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() ==
        null ||
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() ==
        undefined ||
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == "" ||
        $("#" + this.pageId + "dropDownInputFieldBayNo").val() == null ||
        $("#" + this.pageId + "dropDownInputFieldBayNo").val() == undefined ||
        $("#" + this.pageId + "dropDownInputFieldBayNo").val() == "" ||
        $("#" + this.pageId + "unldDt").val() == null ||
        $("#" + this.pageId + "unldDt").val() == "" ||
        $("#" + this.pageId + "unldTime").val() == null ||
        $("#" + this.pageId + "unldTime").val() == "")
    ) {
      swal("Mandatory Field", "Please select the mandatory fields", "warning");
      return false;
    } else {
      swal({
        title: "Tansfer Lrs",
        text: "Sure U want to transfer the entered LRs into stock?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((sureYesBtn) => {
        if (sureYesBtn) {
          this.getConfirmLrDetails();
          this.showSpinnerForAction = true;
          this.hireslipService
            .updateLocalBkgLrNo(this.lrDto)
            .subscribe((response) => {
              console.log(response);
              this.checkedLrDetailsDataList = [];
              $("#" + this.pageId + "bkgTripCheckedLrsTableId")
                .DataTable()
                .destroy();
              if (response.status == "Success") {
                swal(
                  "Sucessfully transferred",
                  "Entered LRs transferred to stock",
                  "success"
                );
              } else {
                swal("Alert", "Error while transfer the LR", "warning");
              }
              this.dtTriggerCheckedLrDetails.next();
              this.showSpinnerForAction = false;
              this.changeDetectorRef.detectChanges();
            }),
            (error) => {
              this.showSpinnerForAction = false;
              swal("Error", "Server Error While transfer the LR", "warning");
            },
            () => console.log("done");
        }
      });
    }
  }
  getConfirmLrDetails() {
    this.lrDto = new LRDto();
    this.lrDto.branch = this.userDataDtoReturnSession.office;
    this.lrDto.userName = this.userDataDtoReturnSession.userId;

    this.selectedLoaderHead =
      $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == ""
        ? null
        : $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() ==
          undefined
          ? null
          : $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val();
    this.lrDto.unloaderHead = this.selectedLoaderHead;

    this.selectedBayNo =
      $("#" + this.pageId + "dropDownInputFieldBayNo").val() == ""
        ? null
        : $("#" + this.pageId + "dropDownInputFieldBayNo").val() == undefined
          ? null
          : $("#" + this.pageId + "dropDownInputFieldBayNo").val();
    this.lrDto.bayNo = this.selectedBayNo;

    this.selectedUnldDt =
      $("#" + this.pageId + "unldDt").val() == ""
        ? null
        : $("#" + this.pageId + "unldDt").val() == undefined
          ? null
          : $("#" + this.pageId + "unldDt").val();
    this.lrDto.unLoadingDate = this.selectedUnldDt;

    this.selectedUnldTime =
      $("#" + this.pageId + "unldTime").val() == ""
        ? null
        : $("#" + this.pageId + "unldTime").val() == undefined
          ? null
          : $("#" + this.pageId + "unldTime").val();
    this.lrDto.fromTime = this.selectedUnldTime;

    this.lrDto.tripId = this.tripsOfAgentBookingData.tripId;
    this.listOfLrs = [];
    for (let i = 0; i < this.checkedLrDetailsDataList.length; i++) {
      this.listOfLrs.push(this.checkedLrDetailsDataList[i].lrNumber);
    }
    this.lrDto.list = this.listOfLrs;
    this.lrDto.localTripTrns = this.userDataDtoReturnSession.localTripTrns;
    this.lrDto.mainstation = this.userDataDtoReturnSession.mainAdminStation;
    console.log("ok.." + this.lrDto.unLoadingDate + "-" + this.lrDto.fromTime);
  }

  getLabourHeadNameDetails() {
    this.hireSlipDtoLabourHeadName = new HireSlipDto();
    this.hireSlipDtoLabourHeadName.branch =
      this.userDataDtoReturnSession.mainAdminStation;
    this.hireSlipDtoLabourHeadName.companyid =
      this.userDataDtoReturnSession.companyId;
  }

  setLabourHeadNameDetailsList() {
    this.getLabourHeadNameDetails();
    this.masterReadService
      .getLoaderHeadsDetails(this.hireSlipDtoLabourHeadName)
      .subscribe((response) => {
        console.log("loader : " + response);
        if (response.length > 0) {
          this.labourHeadNameOption = [];
          this.labourHeadNameTA = [];
          this.labourHeadNameOption = response;
          for (let i = 0; i < this.labourHeadNameOption.length; i++) {
            this.labourHeadNameTA.push(this.labourHeadNameOption[i]);
          }
        } else {
          this.labourHeadNameOption = [];
          this.labourHeadNameTA = [];
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Server Error",
          "Problem occur while getting Loader Heads details",
          "error"
        );
      },
      () => console.log("done");
  }

  setBayNo() {
    this.bayNoOption = [];
    for (let i = 1; i < 13; i++) {
      this.hireSlipDtoBayNo = new HireSlipDto();
      this.hireSlipDtoBayNo.bayNo = "Bay " + i;
      this.bayNoOption.push(this.hireSlipDtoBayNo);
    }
  }

  //for the select option with filter starts

  transform(items: any[], searchTerm: string, labelKey?: string): any {
    if (!items || !searchTerm) {
      return items;
    }

    return items.filter(
      (item) =>
        item[labelKey || "label"]
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) === true
    );
  }

  //for the select option with filter ends
  //to insert value of selected row in table to input field starts
  RowSelected(confirmedConsignorListData) {
    this.selectedUser = confirmedConsignorListData;
    this.consignorName = this.selectedUser.name;
    console.log(this.selectedUser.name);
  }
  //to insert value of selected row in table to input field ends

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  //for popup modal ends

  //for datepicker starts
  // the selectToday is the method for  selecting todays'z date
  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate(),
    };
  }

  //for datePicker ends#

  getDataForLrPrint(lrNumber) {
    console.log(this.selectedLRNo);
    this.selectedLRNo = lrNumber;
    if (
      this.selectedLRNo == null ||
      this.selectedLRNo == undefined ||
      this.selectedLRNo == ""
    ) {
      swal("Lr No", "Please Select a Lr No", "info");
      return false;
    } else {
      this.lrDtoAutoLrPrint = new LRDto();
      this.lrDtoAutoLrPrint.lrNumber = this.selectedLRNo;
      this.lrDtoAutoLrPrint.companyId = this.userDataDtoReturnSession.companyId;
      this.showSpinnerForAction = true;
      console.log(this.lrDtoAutoLrPrint);
      this.hireslipService
        .getAutoLrDetails(this.lrDtoAutoLrPrint)
        .subscribe((response) => {
          this.showSpinnerForAction = false;
          console.log(response);
          if (response.lrNumber != "" && response.lrNumber != null) {
            this.getvalueForLrPrint = "";
            this.getvalueForLrPrint = response;
            this.setValueForLrPrint = new LRDto();
            this.setValueForLrPrint = this.getvalueForLrPrint;
            this.popUpPrintMethod(
              this.setValueForLrPrint

            );
          } else {
            swal("Empty", "No Data Found", "info");
          }
          this.changeDetectorRef.detectChanges();
        }),
        (error) => {
          this.showSpinnerForAction = false;
          swal("Error", "", "info");
        },
        () => console.log("done");
    }
  }

  popUpPrintMethod(setValueForLrPrint) {

    this.modalRefferenceLrPrintCopyPopUp = this.modalService.open(
      this.lrPrintCopyPopUpTemplate,
      { centered: true, windowClass: "myCustomModalClass" }
    );
    this.modalRefferenceLrPrintCopyPopUp.result.then(
      (result) => {
        this.modalRefferenceLrPrintCopyClosePopUp = `Closed with: ${result}`;
      },
      (reason) => {
        this.modalRefferenceLrPrintCopyClosePopUp = `Dismissed ${this.getDismissReason(
          reason
        )}`;
      }
    );

    localStorage.clear();
    localStorage.setItem(
      "printAfterSaveFromBkgDashBoardData",
      JSON.stringify(setValueForLrPrint)
    );
    localStorage.setItem(
      "printAfterSaveFromBkgDashBoardType",
      JSON.stringify("BkgDashBoard")
    );

    this.viewLrPrintCopyPopUp = true;
    window.addEventListener("afterPopUp", (event) => {
      this.viewLrPrintCopyPopUp = false;
    });
  }

  closeBtnPopup() {
    this.modalRefferenceLrPrintCopyPopUp.close();
  }
  validateMoveAllBtnForCheckedLr() {
    console.log(this.lrDetailsDataList);
    console.log(this.lrDetailsDataList.length);
    this.showSpinnerForMoveAllLrs = true;
    this.checkedLrDetailsDataList = [];
    for (let i = 0; i < this.lrDetailsDataList.length; i++) {
      this.checkedLrDetailsDataList.push(this.lrDetailsDataList[i]);
    }
    $("#" + this.pageId + "bkgTripLrsTableId")
      .DataTable()
      .destroy();
    setTimeout(() => {
      this.dtTriggerLrDetails.next();
    }, 1000);
    this.lrDetailsDataList = [];
    $("#" + this.pageId + "bkgTripCheckedLrsTableId")
      .DataTable()
      .destroy();
    setTimeout(() => {
      this.dtTriggerCheckedLrDetails.next();
    }, 1000);
    this.showSpinnerForMoveAllLrs = false;
    // this.viewNextDarkBtnForScheduleTrip = true;
  }
  confirmAllLrs() {
    if (this.checkedLrDetailsDataList.length == 0) {
      swal("Alert", "No LR found to transfer", "warning");
    }
    // removed the below validation @19/09/2021 only if bayFlow logic true
    else if (
      this.bayFlow == false &&
      ($("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() ==
        null ||
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() ==
        undefined ||
        $("#" + this.pageId + "dropDownInputFieldLabourHeadName").val() == "" ||
        $("#" + this.pageId + "dropDownInputFieldBayNo").val() == null ||
        $("#" + this.pageId + "dropDownInputFieldBayNo").val() == undefined ||
        $("#" + this.pageId + "dropDownInputFieldBayNo").val() == "" ||
        $("#" + this.pageId + "unldDt").val() == null ||
        $("#" + this.pageId + "unldDt").val() == "" ||
        $("#" + this.pageId + "unldTime").val() == null ||
        $("#" + this.pageId + "unldTime").val() == "")
    ) {
      swal("Mandatory Field", "Please select the mandatory fields", "warning");
      return false;
    } else {
      swal({
        title: "Tansfer Lrs",
        text: "Sure U want to transfer all the LRs into stock?",
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((sureYesBtn) => {
        if (sureYesBtn) {
          this.getConfirmLrDetails();
          this.showSpinnerForAction = true;
          this.hireslipService
            .updateLocalBkgLrNo(this.lrDto)
            .subscribe((response) => {
              console.log(response);
              this.checkedLrDetailsDataList = [];
              $("#" + this.pageId + "bkgTripCheckedLrsTableId")
                .DataTable()
                .destroy();
              if (response.status == "Success") {
                swal(
                  "Sucessfully transferred",
                  "Selected LRs transferred to stock!",
                  "success"
                );
              } else {
                swal("Alert", "Error while transfer the LR", "warning");
              }
              this.dtTriggerCheckedLrDetails.next();
              this.showSpinnerForAction = false;
              this.changeDetectorRef.detectChanges();
            }),
            (error) => {
              this.showSpinnerForAction = false;
              swal("Error", "Server Error While transfer the LR", "warning");
            },
            () => console.log("done");
        }
      });
    }
  }
  handleBarcodeScan = (event: KeyboardEvent) => {
    const currentTime = new Date().getTime();


    if (this.lastInputTime && currentTime - this.lastInputTime > 100) {
      this.barcodeInput = '';
    }
    this.lastInputTime = currentTime;


    if (event.key === 'Enter') {
      if (this.barcodeInput.length > 5) {
        this.processScannedBarcode(this.barcodeInput.trim());
      }
      this.barcodeInput = '';
    } else if (/^[a-zA-Z0-9]$/.test(event.key)) {
      this.barcodeInput += event.key;
    }


    clearTimeout(this.scanTimeout);
    this.scanTimeout = setTimeout(() => {
      this.barcodeInput = '';
    }, 100);
  };

  processScannedBarcode(scannedBarcode: string) {
    console.log('Scanning barcode:', scannedBarcode);


    const checkedLrDetailsData = this.checkedLrDetailsDataList.find(data =>
      data.listOfBarcodes.includes(scannedBarcode)
    );

    if (checkedLrDetailsData) {
      const barcodeIndex = checkedLrDetailsData.listOfBarcodes.indexOf(scannedBarcode);

      if (barcodeIndex !== -1) {
        if (checkedLrDetailsData.loadingArticle < checkedLrDetailsData.totalArticles) {
          checkedLrDetailsData.barcodeValue = scannedBarcode;
          this.updateLoadingSheetLocalTripLr(checkedLrDetailsData, barcodeIndex, 'checked');
        } else {
          this.showSwalWarning(
            'Not Allowed!',
            'Loading Articles has reached the total available articles!'
          );
        }
      } else {
        this.showSwalInfo('Barcode Already Scanned', 'This barcode is already processed.');
      }
      return;
    }


    const lrDetails = this.lrDetailsDataList.find(data =>
      data.listOfBarcodes.includes(scannedBarcode)
    );

    if (lrDetails) {
      const barcodeIndex = lrDetails.listOfBarcodes.indexOf(scannedBarcode);
      if (barcodeIndex !== -1) {
        lrDetails.barcodeValue = scannedBarcode;
        this.updateLoadingSheetLocalTripLr(lrDetails, barcodeIndex, 'unchecked');
      }
    }
  }


  loadingSheetLocalTripLrData(loadingSheetData) {
    console.log("loadingSheetData>>" + loadingSheetData);
    this.lrDtoUpdate = new LRDto();
    this.lrDtoUpdate.userName = this.userDataDtoReturnSession.userId;
    this.lrDtoUpdate.lrNumber = loadingSheetData.lrNumber;

    this.lrDtoUpdate.invoiceNumber =
      loadingSheetData.bookingLocalTripId;
    this.lrDtoUpdate.mode = 'localTrip';

    this.lrDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoUpdate.remarks = loadingSheetData.remarks;
    this.lrDtoUpdate.barcodeValue = loadingSheetData.barcodeValue;
    this.lrDtoUpdate.type = 'Unload';
  }
  updateLoadingSheetLocalTripLr(loadingSheetData, barcodeIndex: number, mode) {
    this.loadingSheetLocalTripLrData(loadingSheetData);
    this.lrService.updateLrLoadingSheetLocalTripSingleLrDetails(this.lrDtoUpdate)
      .subscribe((response) => {
        if (response.status == "Duplicate") {
          swal("Warning", "This article already loaded or invalid barcode", "warning");
        } else if (response.status == "Failed") {
          swal("Error", "Problem occurred while updating the details", "error");
        } else {
          if (mode != null && mode == 'checked') {
            loadingSheetData.loadingArticle += 1;

            loadingSheetData.remArticles = loadingSheetData.totalArticles - loadingSheetData.loadingArticle;

            loadingSheetData.listOfBarcodes.splice(barcodeIndex, 1);

            console.log("Updated listOfBarcodes after removal:", loadingSheetData.listOfBarcodes);
            if (loadingSheetData.remArticles === 0) {
              console.log(loadingSheetData);
              console.log(this.checkedLrDetailsDataList);

              const index = this.checkedLrDetailsDataList.findIndex(
                x => x.lrNumber === loadingSheetData.lrNumber
              );
              // if (index !== -1) {
              //   this.checkedLrDetailsDataList.splice(index, 1);
              // }
            }
          } else {
            this.setLRNumberDetailsValueInDatatTable(loadingSheetData.lrNumber);

          }

        }


        this.refreshDataTables();
      }),
      (error) => {
        swal(
          "Error",
          "Server problem occurred while updating the details!",
          "error"
        );
      },
      () => console.log("done");
  }


  updateDataTables(lrNumber: string) {

    const index = this.lrDetailsDataList.findIndex(data => data.lrNumber === lrNumber);
    if (index !== -1) {
      this.lrDetailsDataList.splice(index, 1);
    }

    this.refreshDataTables();
  }




  refreshDataTables() {
    const dataTableFn = $.fn.DataTable as any;

    if (dataTableFn.isDataTable(`#${this.pageId}bkgTripCheckedLrsTableId`)) {
      $(`#${this.pageId}bkgTripCheckedLrsTableId`).DataTable().destroy();
    }
    if (dataTableFn.isDataTable(`#${this.pageId}bkgTripLrsTableId`)) {
      $(`#${this.pageId}bkgTripLrsTableId`).DataTable().destroy();
    }


    this.changeDetectorRef.detectChanges();
    this.dtTriggerCheckedLrDetails.next();
    this.dtTriggerLrDetails.next();
  }





  showSwalWarning(title: string, text: string) {
    swal({
      title,
      text,
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
  }

  showSwalInfo(title: string, text: string) {
    swal({
      title,
      text,
      icon: 'info',
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
  }

  updateFooterTotals() {
    const api = $(`#${this.pageId}bkgTripCheckedLrsTableId`).DataTable();

    const totalActWgt = api.column(6, { page: 'current' }).data().reduce((a, b) => +a + +b, 0);
    const totalChgWgt = api.column(7, { page: 'current' }).data().reduce((a, b) => +a + +b, 0);

    $(api.column(6).footer()).html(totalActWgt.toFixed(2));
    $(api.column(7).footer()).html(totalChgWgt.toFixed(2));
  }

}
