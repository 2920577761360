//Chg_V1 : Add subArea column on print if user have right as per discuss (Asrar Jr / 05-11-2024)
import { Component, OnInit } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import swal from "sweetalert";
import html2canvas from "html2canvas";

@Component({
  selector: "app-cashmemo-genrate-print-qr",
  templateUrl: "./cashmemo-genrate-print-qr.component.html",
  styleUrls: ["./cashmemo-genrate-print-qr.component.css"],
})
export class CashmemoGeneratePrintQRComponent implements OnInit {
  PrintBarcode = [];

  page: any;
  heroes$: any;
  sub: any;
  data: any;
  todayDate: any;
  //    constructor() { }
  //    constructor(private router: Router) {
  //        console.log(this.router.getCurrentNavigation().extras.state.example); // should log out 'bar'
  //      }
  rateDtoForPrint: RateMasterDto = new RateMasterDto();
  example: string;
  viewgst: boolean;
  userDataDtoReturnSession: any;

  memoTime = "";
  roundOffView = false;
  roundAmt: any;
  message = "";
  dummyMemo = "";
  slash: any = "";
  airtelCentNo = ", AIRTEL CENTEREX NO. 6401/7402";
  astrixDisplay = "** Only for display";
  serChg: any;
  dd: any = "";
  bankDetails = "";
  collMan = "";
  commodity = "";
  area = "";
  eWayBill: any;
  bottomMsg1 = "";
  paidByName = null;
  hamaliDd = 0;
  address: string;
  addressComaSplit: string[];
  addressLineOne: string = "";
  addressLineTwo: string = "";
  city: string;
  pinCode: string;
  phone: string;
  cityPin: string;
  faxNo: string;
  emailId: string;
  grandTotal: any;
  gd: any;
  unloadingChg: any;
  dupMsg: any;
  viewCommodity = false;
  viewEWayBill = false;
  num: any;
  n: any;
  msgForGujDis: any;
  viewUnloadingChg = false;
  viewDoorDlryChg = false;
  viewLocalChg = true;
  viewHamaliGd = false;
  viewHamaliDd = false;
  viewDiscount = false;
  viewOthers = false;
  dateConvert: any;
  //for datepicker starts
  model: NgbDateStruct;
  model2;
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  closeResult: string;
  //for datepicker ends
  isLoggedIn = true;
  viewCashMemoPrint = false;
  barcodeImg: any;
  dataForPrintAfterSave: any;
  dataForPrintTransitMemo: any;
  dataForPrintCashMemo: any;
  dataForPrintAfterUpdate: any;
  bottomMsgDemurage = "";
  remakrsOfferMsg = "";

  memoDateStr: any;
  pageId = "cgepc";
  loadingHamaliOptionFlowPrint: boolean = false;
  viewConsolidatedEwayBill: boolean = false;
  isNewParty: boolean = false;
  qrImgPath: any;
  duplicatePrintText: any;
  showSubArea: boolean = false;

  constructor(private router: Router, private datePipe: DatePipe) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }

    //const navigation = this.router.getCurrentNavigation();
    //        const state = navigation.extras.state as { example: string };
    //        this.example = state.example;

    //        this.heroes$ = this.router.(
    //            switchMap(( params: ParamMap ) => {
    //                // (+) before `params.get()` turns the string into a number
    //                this.selectedId = +params.get( 'id' );
    //                return this.service.getHeroes();
    //            } )
    //        );
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );
      //            this.dataForPrint = localStorage.getItem( 'print' );
      this.dataForPrintAfterSave = JSON.parse(
        localStorage.getItem("printAfterSave")
      );
      this.dataForPrintTransitMemo = JSON.parse(
        localStorage.getItem("printTransitMemo")
      );
      this.dataForPrintCashMemo = JSON.parse(
        localStorage.getItem("printCashMemo")
      );
      this.dataForPrintAfterUpdate = JSON.parse(
        localStorage.getItem("printAfterUpdate")
      );

      //            console.log( this.dataForPrintAfterSave + "<< >>" + this.dataForPrintTransitMemo + "<< >>" + this.dataForPrintCashMemo );
      if (this.dataForPrintAfterSave != null) {
        this.rateDtoForPrint = this.dataForPrintAfterSave;
      } else if (this.dataForPrintTransitMemo != null) {
        this.rateDtoForPrint = this.dataForPrintTransitMemo;
      } else if (this.dataForPrintCashMemo != null) {
        this.rateDtoForPrint = this.dataForPrintCashMemo;
      } else if (this.dataForPrintAfterUpdate != null) {
        this.rateDtoForPrint = this.dataForPrintAfterUpdate;
      }

      if (
        this.userDataDtoReturnSession.showdestmemoloadingchgsetup != null &&
        this.userDataDtoReturnSession.showdestmemoloadingchgsetup
      ) {
        this.loadingHamaliOptionFlowPrint = true;
      }

      this.PrintBarcode = [
        {
          barcode: this.rateDtoForPrint.memoNumber,
          Name: "A",
        },
        //    ,
        //    {
        //        SerialId: 585885,
        //        Name: 'A'
        //    }
      ];

      //console.table( this.rateDtoForPrint.godown );
      //            this.rateDtoForPrint.cashMemoType
      //console.log( this.rateDtoForPrint.withOutSlash );
      this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");

      console.log(this.todayDate);
      this.validatBeforePrint();
      this.area = this.rateDtoForPrint.area;
      window.setTimeout(function () {
        window.print();

        // below is working code
        // html2canvas(document.querySelector("#image-lrprint-consignee")).then(function (canvas) {
        //     // Convert the canvas to blob
        //     canvas.toBlob(function (blob) {
        //         // To download directly on browser default 'downloads' location
        //         let link = document.createElement("a");
        //         // link.download = this.resultDtos.lrNumber + "_image.png";
        //         link.download = "C:\temp\srd\LR_image.png";
        //         console.log('blob');
        //         console.log(blob);
        //         link.href = URL.createObjectURL(blob);
        //         link.click();

        //         // To save manually somewhere in file explorer
        //         //window.saveAs(blob, 'image.png');

        //     }, 'image/png');
        // });
      }, 3000);

      window.onafterprint = function () {
        window.close();
      };
      localStorage.clear();
      window.addEventListener("afterprint", (event) => {
        this.clearField();
      });
      //Added by imran for qr img on 06032024
      //For local
      console.log(this.userDataDtoReturnSession.qrCodePath);
      this.qrImgPath =
        "assets/images/paymentQr/" + this.userDataDtoReturnSession.qrCodePath;

      // alert(this.qrImgPath);

      //Chg_V1
      console.log("showSubArea");
      console.log(this.userDataDtoReturnSession.showSubArea);
      console.log(this.rateDtoForPrint.subArea);
      if (this.userDataDtoReturnSession.showSubArea != null && this.userDataDtoReturnSession.showSubArea
        && this.rateDtoForPrint.subArea != null) {
        this.showSubArea = true;
      }
    }
  }

  ngOnInit() {
    //        this.sub = this.route
    //            .queryParams
    //            .subscribe( params => {
    //                // Defaults to 0 if no query param provided.
    //                this.page = +params['data'] || 0;
    //                console.log( this.page );
    //            } );
    //        this.route.params.subscribe(params=>
    //        {
    //          this.data=params['data']
    //        }
    //        );
  }
  //    printOnLoad() {
  //        window.print();
  //    }
  validatBeforePrint() {
    //        alert("Inside Print Method");
    if (
      this.rateDtoForPrint.memoDateStr != null &&
      this.rateDtoForPrint.memoDateStr != undefined
    ) {
      this.todayDate = this.datePipe.transform(
        this.rateDtoForPrint.memoDateStr,
        "dd-MM-yyyy"
      );
    } else {
      this.todayDate = this.datePipe.transform(
        this.rateDtoForPrint.memoDate,
        "dd-MM-yyyy"
      );
    }
    //this.todayDate = this.datePipe.transform(this.rateDtoForPrint.memoDate, "dd-MM-yyyy");
    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
      for (
        let i = 0;
        i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length;
        i++
      ) {
        //                alert( "inside of For" );
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "HamDDInCM"
        ) {
          //                    alert( "inside of first if" );
          //                    console.log( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] );
          this.hamaliDd = 0;
        }
        if (
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
          this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
          "CashMemo RoundOffView"
        ) {
          //                    alert( "inside of second if" );
          this.roundOffView = true;
        }
      }
    }

    if (this.rateDtoForPrint.deliveryMode == "GD") {
      this.area = "Godown Delivery";
    }
    //console.log(this.rateDtoForPrint.bookingDate);
    this.dateConvert = this.datePipe.transform(
      this.rateDtoForPrint.bookingDate,
      "dd-MM-yyyy"
    );
    this.rateDtoForPrint.bookingDate = this.dateConvert;
    //console.log(this.rateDtoForPrint.bookingDate);

    //changed msg on 18/09/2021 as per Musaddik bh
    //this.bottomMsg1 = "You can now track your consignment on the GO on our website and SRD Logistics mobile app.";
    this.bottomMsg1 =
      "Please do not make the freight payment without collecting the physical copy of the cash memo.";
    if (
      this.userDataDtoReturnSession.demurageMsgPrintInDestMemo &&
      this.rateDtoForPrint.demurrage != null &&
      this.rateDtoForPrint.demurrage != "" &&
      this.rateDtoForPrint.demurrage != "0"
    ) {
      this.bottomMsgDemurage =
        "Delivery of goods should be taken within " +
        this.userDataDtoReturnSession.demurrageExmpDays +
        " days from the date of booking failing which demurrage will be charged at Rs. " +
        this.userDataDtoReturnSession.demurrageChg +
        "ps/kg per day.";
    }

    if (
      this.rateDtoForPrint.isOfferLR != null &&
      this.rateDtoForPrint.isOfferLR
    ) {
      this.remakrsOfferMsg =
        "This LR is in 50% offer scheme, please pay 50% amount of LR total Only.";
    } else {
      this.remakrsOfferMsg = "";
    }

    //console.log( this.bankDetails );
    this.bankDetails = this.userDataDtoReturnSession.bankName;
    //console.log( this.bankDetails );
    //this.bankDetails=this.bankDetails.split("<br>");
    //     console.log( this.bankDetails );
    //Basha
    //        this.hamaliDd = this.rateDtoForPrint.hamaliDD;
    this.hamaliDd = Math.round(this.rateDtoForPrint.hamaliDD);

    this.collMan =
      this.rateDtoForPrint.collectionMan == null
        ? "NA"
        : this.rateDtoForPrint.collectionMan == ""
          ? "NA"
          : this.rateDtoForPrint.collectionMan;

    if (
      this.rateDtoForPrint.cashMemoType != null &&
      this.rateDtoForPrint.cashMemoType == "Cash"
    ) {
      this.collMan = "";
    }

    if (
      this.rateDtoForPrint.goodsValue != null &&
      this.rateDtoForPrint.goodsValue >= 50000
    ) {
      this.eWayBill = this.rateDtoForPrint.eWayBillNo;
    }

    if (
      this.rateDtoForPrint.consolidatedEWayBillNo != null &&
      this.rateDtoForPrint.consolidatedEWayBillNo != "" &&
      this.rateDtoForPrint.consolidatedEWayBillNo != undefined
    ) {
      this.viewConsolidatedEwayBill = true;
    }

    if (
      this.rateDtoForPrint.isNewParty != null &&
      this.rateDtoForPrint.isNewParty == true
    ) {
      this.isNewParty = true;
    }

    //temp set as false, after confirmation need to remove it
    //this.isNewParty = false;

    if (this.rateDtoForPrint.commodityName != null) {
      this.viewCommodity = true;
      this.commodity = this.rateDtoForPrint.commodityName;
    }

    if (
      this.userDataDtoReturnSession.mainStation != null &&
      this.userDataDtoReturnSession.mainStation == "Chennai"
    ) {
      if (
        this.rateDtoForPrint.deliveryMode != null &&
        this.rateDtoForPrint.deliveryMode == "GD"
      ) {
        this.viewUnloadingChg = true;
        //CHG_V-CM:10
        this.viewHamaliGd = true;
      } else {
        this.viewUnloadingChg = true;
        this.viewDoorDlryChg = true;
      }
    } else {
      if (
        this.userDataDtoReturnSession.mainStation != null &&
        this.userDataDtoReturnSession.mainStation == "Bangalore"
      ) {
        this.hamaliDd = 0;
      }

      if (
        this.userDataDtoReturnSession.mainStation != null &&
        (this.userDataDtoReturnSession.mainStation == "Madurai" ||
          this.userDataDtoReturnSession.mainStation == "Trivandrum")
      ) {
        this.viewUnloadingChg = true;
      }

      this.viewHamaliGd = true;
      this.viewHamaliDd = true;
    }

    if (
      this.rateDtoForPrint.discount != null &&
      this.rateDtoForPrint.discount != 0
    ) {
      this.viewDiscount = true;
    }

    if (
      this.userDataDtoReturnSession.mainStation != null &&
      this.userDataDtoReturnSession.mainStation == "Chennai"
    ) {
      if (this.rateDtoForPrint.localCharge == 0) {
        this.viewLocalChg = false;
      }

      this.viewOthers = true;
    }

    this.slash = "/";
    if (
      this.rateDtoForPrint.status != null &&
      this.rateDtoForPrint.status == "dummyMemo"
    ) {
      this.dummyMemo = "-";
      this.slash = "";
    }
    //        logo

    if (this.rateDtoForPrint.inPrint == true) {
      this.message = "";
    }
    this.address =
      this.userDataDtoReturnSession.addressEditCompany == null
        ? " "
        : this.userDataDtoReturnSession.addressEditCompany;
    //        this.address = this.userDataDtoReturnSession.addressEditCompany;
    //        console.log( this.addressComaSplit );
    this.addressComaSplit = this.address.split(",");
    //        console.log( this.address );
    if (this.addressComaSplit.length > 0) {
      this.addressLineTwo =
        this.addressComaSplit[this.addressComaSplit.length - 1] + ",";
      //            console.log( this.addressLineTwo );
      for (let b = 0; b < this.addressComaSplit.length - 1; b++) {
        this.addressLineOne += this.addressComaSplit[b] + ",";
      }
    } else {
      this.addressLineOne = this.address;
    }

    this.city =
      this.userDataDtoReturnSession.city == null
        ? ""
        : this.userDataDtoReturnSession.city;
    this.pinCode =
      this.userDataDtoReturnSession.pinCode == null
        ? ""
        : this.userDataDtoReturnSession.pinCode;
    this.cityPin = this.city + " - " + this.pinCode;
    this.phone =
      this.userDataDtoReturnSession.phoneEditCompany == null
        ? "NA"
        : this.userDataDtoReturnSession.phoneEditCompany;
    this.faxNo =
      this.userDataDtoReturnSession.faxNo == null
        ? "NA"
        : this.userDataDtoReturnSession.faxNo;
    this.emailId =
      this.userDataDtoReturnSession.cmpEmailId == null
        ? "NA"
        : this.userDataDtoReturnSession.cmpEmailId;

    if (
      this.rateDtoForPrint.grandTotal == 0.0 ||
      this.rateDtoForPrint.grandTotal == 0
    ) {
      this.grandTotal = "Zero";
    } else {
      this.grandTotal = this.inWords();
    }

    if (this.rateDtoForPrint.memoTime != null) {
      this.memoTime = this.rateDtoForPrint.memoTime;
    }
    this.rateDtoForPrint.withOutSlash = "BNG1920126238";
    //this.barcodeImg.src="src/cashMemoBarcode/"+this.rateDtoForPrint.withOutSlash+".jpg";
    //this.barcodeImg = document.getElementById("bikeImage") as HTMLImageElement;
    //console.log(this.barcodeImg);

    if (this.roundOffView == true) {
      this.roundAmt =
        "Rounded Of " + this.rateDtoForPrint.roundOffAmt == null
          ? 0.0
          : this.rateDtoForPrint.roundOffAmt;
    }

    this.rateDtoForPrint.withSlash = this.rateDtoForPrint.memoNumber;
    //                this.rateDtoForPrint.withOutSlash = this.rateDtoForPrint.withSlash.replace("/", " ");
    this.gd = this.rateDtoForPrint.hamaligd;
    this.unloadingChg = this.rateDtoForPrint.unloadingChg;
    if (
      this.userDataDtoReturnSession.allowDupMemoMessage == true &&
      this.rateDtoForPrint.mode != null &&
      this.rateDtoForPrint.mode == "message"
    ) {
      this.dupMsg = "THIS CASH MEMO IS FOR TRANSIT, NOT FOR PAYMENT";
    } else {
      this.dupMsg = "";
    }

    if (
      this.rateDtoForPrint.gujDiac != null &&
      this.rateDtoForPrint.gujDiac == true
    ) {
      this.msgForGujDis = "GUJARAT SCHEME - 50% off on LR total";
    }

    this.rateDtoForPrint.godown =
      this.rateDtoForPrint.godown == null ? "NA" : this.rateDtoForPrint.godown;
    if (
      this.rateDtoForPrint.eWayBillNo == null ||
      this.rateDtoForPrint.eWayBillNo == ""
    ) {
      this.viewEWayBill = false;
      this.eWayBill = "";
    } else {
      this.viewEWayBill = true;
      this.eWayBill = this.rateDtoForPrint.eWayBillNo;
    }

    this.rateDtoForPrint.gcNoteNumber =
      this.rateDtoForPrint.gcNoteNumber.toUpperCase();
    //        console.log( this.addressLineOne + " << >> " + this.slash );

    this.rateDtoForPrint.enteredBy =
      this.rateDtoForPrint.enteredBy.toUpperCase();
    if (
      this.rateDtoForPrint.cgst >= 0 &&
      this.rateDtoForPrint.sgst >= 0 &&
      this.rateDtoForPrint.igst >= 0
    ) {
      this.viewgst = true;
    } else {
      this.viewgst = false;
    }
    if (this.rateDtoForPrint.otherChgAmt == null) {
      this.rateDtoForPrint.otherChgAmt = 0;
    }
    //CHG_VER_CGP_01
    if (
      this.rateDtoForPrint.duplicateMemo != null &&
      this.rateDtoForPrint.duplicateMemo == true
    ) {
      this.duplicatePrintText = "DP";
    } else {
      this.duplicatePrintText = "";
    }
  }

  inWords() {
    var a = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "Fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    var b = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    this.num = this.rateDtoForPrint.grandTotal;
    console.log(this.rateDtoForPrint);
    if ((this.num = this.num.toString()).length > 9) return "overflow";
    this.n = ("000000000" + this.num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!this.n) return;
    var str = "";
    str +=
      this.n[1] != 0
        ? (a[Number(this.n[1])] || b[this.n[1][0]] + " " + a[this.n[1][1]]) +
        "Crore "
        : "";
    str +=
      this.n[2] != 0
        ? (a[Number(this.n[2])] || b[this.n[2][0]] + " " + a[this.n[2][1]]) +
        "Lakh "
        : "";
    str +=
      this.n[3] != 0
        ? (a[Number(this.n[3])] || b[this.n[3][0]] + " " + a[this.n[3][1]]) +
        "Thousand "
        : "";
    str +=
      this.n[4] != 0
        ? (a[Number(this.n[4])] || b[this.n[4][0]] + " " + a[this.n[4][1]]) +
        "Hundred "
        : "";
    str +=
      this.n[5] != 0
        ? (str != "" ? "and " : "") +
        (a[Number(this.n[5])] || b[this.n[5][0]] + " " + a[this.n[5][1]]) +
        ""
        : "";

    str += " Only ";
    return str;
  }

  clearField() {
    this.memoTime = "";
    this.roundOffView = false;
    this.roundAmt = "";
    this.message = "";
    this.dummyMemo = "";
    this.slash = "";

    this.serChg = "";
    this.dd = "";
    this.bankDetails = "";
    this.collMan = "";
    this.commodity = "";
    this.area = "";
    this.eWayBill = "";
    this.bottomMsg1 = "";
    this.bottomMsgDemurage = "";
    this.remakrsOfferMsg = "";
    this.paidByName = null;
    this.hamaliDd = 0;
    this.address = "";
    this.addressComaSplit = [];
    this.addressLineOne = "";
    this.addressLineTwo = "";
    this.city = "";
    this.pinCode = "";
    this.phone = "";
    this.cityPin = "";
    this.faxNo = "";
    this.emailId = "";
    this.grandTotal = "";
    this.gd = "";
    this.unloadingChg = "";
    this.dupMsg = "";
    this.viewCommodity = false;
    this.viewEWayBill = false;
    this.num = "";
    this.msgForGujDis = "";
    this.n = "";
    this.viewUnloadingChg = false;
    this.viewDoorDlryChg = false;
    this.viewLocalChg = true;
    this.viewHamaliGd = false;
    this.viewHamaliDd = false;
    this.viewDiscount = false;
    this.viewOthers = false;
    this.dateConvert = "";
    this.barcodeImg = "";
    this.rateDtoForPrint = new RateMasterDto();
    this.viewgst = false;
    this.viewConsolidatedEwayBill = false;
    this.isNewParty = false;
    this.duplicatePrintText = "";
  }

  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }


}
