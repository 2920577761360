import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EntryRoutes } from 'src/app/lr/entry/entry.routing';
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LrEntryFormComponent } from './lr-entry-form/lrentryform.component';
import { MissAgentsComponent, MissAgentsPopupComponent } from 'src/app/lr/entry/miss-agents/miss-agents.component';

import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { LrEntryPrintComponent } from "src/app/lr/entry/lrentry-print/lrentry-print.component";
//import { CashmemoBkgPrintAutoGSTComponent } from './cashmemo-bkg-print-autogst/cashmemo-bkg-print-autogst.component';
//import { BookingCashmemoComponent } from 'src/app/cashmemo/generation/booking-cashmemo/booking-cashmemo.component';
import { GenerationModule } from 'src/app/cashmemo/generation/generation.module';
import { LrEntryFormPrint2Component } from "src/app/lr/entry/lrentryform-print2/lrentryform-print2.component";
import { NgxBarcodeModule } from 'ngx-barcode';
import { MaterialModule } from "src/app/material.module";
import { LrEntryFormPrint3Component } from "src/app/lr/entry/lrentryform-print3/lrentryform-print3.component";
import { BookingCashmemoPopupComponent } from "src/app/lr/entry/booking-cashmemo-popup/booking-cashmemo-popup.component";
import { CashmemoBkgPrintAutoGSTPopupComponent } from 'src/app/lr/entry/cashmemo-bkg-print-autogst-popup/cashmemo-bkg-print-autogst-popup.component';
import { AutomatedLrHoldLrEntryPopupComponent } from "src/app/lr/entry/automatedlrhold-lr-entry-popup/automatedlrhold-lr-entry-popup.component";
import { LrEntryFormBarcodePrintComponent } from './lrentryform-barcode-print/lrentryform-barcode-print.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { LrEntryEnableConsigneecopyComponent } from './lr-entry-enable-consigneecopy/lr-entry-enable-consigneecopy.component';
import { LrHoldReasonComponent } from '../edit/lr-hold-reason/lr-hold-reason.component';
import { GenerateBarcodeComponent } from './generate-barcode/generate-barcode.component';
import { GenerateBarcodePrintComponent } from "src/app/lr/entry/generate-barcode-print/generate-barcode-print.component";
@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild(EntryRoutes),
        FormsModule,
        NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule,
        MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule,
        NgxBarcodeModule, MaterialModule, GenerationModule, MatDialogModule, NgxBarcode6Module],
    declarations: [
        LrEntryFormComponent,
        MissAgentsComponent,
        LrEntryPrintComponent,
        LrEntryFormPrint2Component,
        LrEntryFormPrint3Component,
        MissAgentsPopupComponent,
        BookingCashmemoPopupComponent,
        CashmemoBkgPrintAutoGSTPopupComponent,
        AutomatedLrHoldLrEntryPopupComponent,
        LrEntryFormBarcodePrintComponent,
        LrEntryEnableConsigneecopyComponent,
        LrHoldReasonComponent,
        GenerateBarcodeComponent,
        GenerateBarcodePrintComponent
    ], entryComponents: [BookingCashmemoPopupComponent, AutomatedLrHoldLrEntryPopupComponent, MissAgentsPopupComponent],
    exports: [LrEntryFormPrint3Component, MissAgentsPopupComponent, CashmemoBkgPrintAutoGSTPopupComponent]

})
export class EntryModule { }
