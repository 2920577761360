//Chg_TM_V1 : Add field Insurance Expiring Date and do CRUD for this field (Asrar Jr / 14-11-2024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Router } from "@angular/router";
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { HttpClient } from '@angular/common/http';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import *  as moment from 'moment';
//service ends
@Component({
    //selector: 'app-truck-master',
    selector: 'app-truck-master',
    templateUrl: './truck-master.component.html',
    styleUrls: ['./truck-master.component.css']
})
export class TruckMasterComponent implements OnInit {
    @ViewChild("pinCode") pinField: ElementRef;
    newTruck = false;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    truckDataDto: TruckDataDto = new TruckDataDto();
    truckDataDtoSaveRet: any;
    truckDataDeleteDto: TruckDataDto = new TruckDataDto();
    truckDataDtoDeleteRet: any;
    //isUploaded = false;
    isUploadedRC: boolean = false;
    isUploadedInsu: boolean = false;
    isUploadedPan: boolean = false;
    truckMasterDataList: any;
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerTruckMaster: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsTruckMaster: any;
    controlRegState = new FormControl();

    regStateOptions = [
        { id: 1, label: 'Assam' },
        { id: 2, label: 'Delhi' },
        { id: 3, label: 'Tamil Nadu' },
    ];
    partyMasterDtoOptions: PartyMasterDto[];
    public modelRegState: any;
    modelRegStateTA: Array<PartyMasterDto> = [];
    focusRegStateTA$ = new Subject<string>();
    searchRegState = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusRegStateTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelRegStateTA
                : this.modelRegStateTA.filter(v => v.state.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterRegState = (x: { state: string }) => x.state;

    truckDataDtoOptions: TruckDataDto[];
    truckDataDtoAddNew: TruckDataDto[];
    public modelTruckTypeCapacity: any;
    truckTypeCapacityTA: Array<TruckDataDto> = [];
    focusTruckTypeCapacityTA$ = new Subject<string>();
    searchTruckTypeCapacity = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusTruckTypeCapacityTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.truckTypeCapacityTA
                : this.truckTypeCapacityTA.filter(v => v.truckType.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterTruckTypeCapacity = (x: { truckType: string }) => x.truckType;

    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    truckDto: TruckDataDto = new TruckDataDto();
    selectedFileRC: File;
    selectedFileInsurance: File;
    selectedFilePan: File;

    regStateName: any;
    regStateCode: any;
    truckType: any;
    rowSelectedStateName: any;
    rowSelectedStateCoe: any;
    rowSelectedTruckType: any;
    rowSelectedOwnerName: any;
    rowSelectedAddress: any;
    rowSelectedCity: any;
    rowSelectedPincode: any;
    rowSelectedPan: any;
    rowSelectedTruckCapc: any;
    rowSelectedInsNo: any;
    rowSelectedTruckLgt: any;

    modeStorage: any;
    datatableHideShow = false;
    truckDtoLocalStorage: TruckDataDto = new TruckDataDto();
    isUpdateMode: boolean = false;
    truckDataDtoSetFieldVal: TruckDataDto = new TruckDataDto();
    //localStorageTruckNumber:any;
    retriveTruckDtoSetField: any;
    readonlytruckNumber = false;
    validatePanNumber: any;
    validateStateName_save: any;
    validateTruckType_save: any;
    truckDataDtoValidateTruckNo: TruckDataDto = new TruckDataDto();
    truckDataDtoReturnValidateTruckNo: any;
    showSpinnerForAction: boolean = false;

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;
    pageId = "tmcts";
    vehicleNoSearch: any;
    //bank details
    private newAttributeTosetBankDetails: any = {};
    editBankTableDetailsId: any;
    // bankDetailsDataList: any;
    bankDetailsDataList = [];
    dtTriggerBankdetails: Subject<any> = new Subject();
    dtOptionsBankdetails: any;
    truckDataDtoBank: TruckDataDto = new TruckDataDto();
    truckDataDtoBankList: Array<TruckDataDto> = [];
    showBankdetailsentry: boolean = false;
    validateInsuranceExpDate: any;
    insuranceExpDate: any;
    rowSelectedInsExpDate: any;
    minDate: { year: number; month: number; day: number };

    //Ac Type TA
    public modelAcType: any;
    @ViewChild('instanceAcType') instanceAcType: NgbTypeahead;
    acTypeTA: Array<any> = [];
    focusAcTypeTA$ = new Subject<string>();
    acTypeSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAcTypeTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.acTypeTA
                : this.acTypeTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    acTypeOptions = [
        { id: 1, label: 'Driver' },
        { id: 2, label: 'Supplier' },
        { id: 3, label: 'Owner' },
        { id: 3, label: 'Other' }
    ];

    constructor(private masterService: MasterService,
        private masterReadService: MasterReadService,
        private http: HttpClient,
        private router: Router, private httpClient: HttpClient, public changeDetectorRef: ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getStatesMethod();
            this.getTruckTypesMethod();
            this.modeStorage = null;
            this.modeStorage = localStorage.getItem('fromHireslipPageLocalStorage');
            this.truckDtoLocalStorage = JSON.parse(localStorage.getItem('fromHireslipPassingTruckDataDtoIntoLocalStorage'));
            localStorage.clear();
            if (this.modeStorage != null && this.modeStorage != undefined &&
                this.modeStorage == "Hireslip") {
                this.datatableHideShow = false;
                this.isUpdateMode = true;
            } else {
                this.datatableHideShow = true;
                this.isUpdateMode = false;
            }
            if (this.isUpdateMode) {
                this.setFieldValues(this.truckDtoLocalStorage.truckNumber);
                this.readonlytruckNumber = true;
            }
            this.setAcTypeVal();

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Truck Master Bank Entry") {
                        this.showBankdetailsentry = true;
                    }
                }
            }
        }
    }
    pinfocus(): void {
        this.pinField.nativeElement.focus();
    }
    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    // rerender(): void {
    //     this.dtElements.forEach((dtElement: DataTableDirective) => {
    //         dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //             // Do your stuff
    //             dtInstance.destroy();
    //         });
    //     });
    // }

    //Chg_TM_V1
    validateDate() {
        this.validateInsuranceExpDate = $("#" + this.pageId + "insuranceExpDate").val();
        this.insuranceExpDate = this.validateInsuranceExpDate;
        
        if (this.insuranceExpDate) {
            const enteredDate = moment(this.insuranceExpDate, "YYYY-MM-DD", true);
            const today = moment().startOf('day'); 
    
            if (!enteredDate.isValid()) {
                swal("Invalid Format", "Please enter the date in YYYY-MM-DD format.", "error");
                return false;
            } else if (enteredDate.isBefore(today)) {
                swal("Not Allowed", "The insurance expiry date must not be a past date, Please contact the admin", "warning");
                return false;
            }
            return true;
        }
        return false;
    }
    
    

    ngOnInit(): void {
        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate(),
        };
        this.dtOptionsTruckMaster = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    exportOptions: {
                        columns: ':visible'

                    }
                },
            ],
            /*buttons: ['excel', 'print'],*/
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            "pagingType": 'full_numbers',
            //"pageLength": 25,
            //scroll in datatable ends
        },
            this.dtOptionsBankdetails = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 150,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                searching: false,
            }
    }

    ngOnDestroy(): void {
        this.dtTriggerTruckMaster.unsubscribe();
        this.dtTriggerBankdetails.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerTruckMaster.next();
        this.dtTriggerBankdetails.next();
    }
    //for the select option with filter starts
    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }
        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends
    truckMethod(selectTruck: string) {
        if (selectTruck === 'addNew') {
            this.newTruck = true;
        } else {
            this.newTruck = false;
        }
    }
    //  service starts
    getDriverDetailsForRead() {
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.status = "Working";
        if (this.userDataDtoReturnSession.mainAdminStation != null) {
            this.truckDataDto.mode = "MainStation";
            this.truckDataDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        } else {
            this.truckDataDto.mode = "Station";
            this.truckDataDto.mainStation = this.userDataDtoReturnSession.office;
        }
        this.truckDataDto.mode = null;
    }


    //    getValuesForTruckMasterList() {
    //        var partyDto = {
    //            'mainStation': 'Delhi',
    //            'mainAdminStation': 'Budhpur'
    //        }
    //        return partyDto;
    //    }
    /*let apiUrlTruckMasterModel = `http://localhost:8080/srd/master/v1/returnTruckMasterdetailsModel`;
    this.http.get(apiUrlTruckMasterModel).subscribe(
        (response) => {

            this.truckMasterDataList = response.json();

        }),
        (error) => console.log(error.json()),
        () => console.log('done');*/
    getTruckMasterDetailsList = () => {
        //        console.log( this.truckDataDto.companyId + "," + this.truckDataDto.mainStation + "," + this.truckDataDto.status );
        this.getDriverDetailsForRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getTruck(this.truckDataDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //  console.log( response );
                    this.truckMasterDataList = response;
                    $("#" + this.pageId + "truckMasterDatatableId").DataTable().destroy();
                    this.dtTriggerTruckMaster.next();
                    //                    console.log( this.truckMasterDataList[5] );
                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Truck Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };



    //    signout() {
    //        sessionStorage.clear();
    //        this.router.navigate( ['/authentication/login'] );
    //    }

    //for the select option with filter ends

    validateTruckDetails() {
        var validateTruckNumber = this.truckDataDto.truckNumber;
        var validateOwnerName = this.truckDataDto.ownerName;
        var validateAddress = this.truckDataDto.address;
        var validateCity = this.truckDataDto.city;
        var validatePincode = this.truckDataDto.pinCodeNumber;
        var validatePanNo = this.truckDataDto.panNo;
        var validateTruckCapacity_Tones = this.truckDataDto.truckCapcityDouble;
        var validateInsurancePolicyNo = this.truckDataDto.insPolNo;
        var validateTruckLengthFeet = this.truckDataDto.trkLgth;

        this.validatePanNumber = $("#" + this.pageId + "truckOwnerPanNumber").val();
        var regpan = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;

        this.validateStateName_save = $("#" + this.pageId + "regStateName").val();
        this.validateTruckType_save = $("#" + this.pageId + "truckTypeCapacityId").val();

        //Chg_TM_V1
        this.validateInsuranceExpDate = $("#" + this.pageId + "insuranceExpDate").val();
        var insuranceExpDateValidate = this.validateDate();

        console.log('validateTruckDetails');
        console.log(validateTruckNumber);
        console.log(validateOwnerName);
        console.log(validateAddress);
        console.log(validateCity);
        console.log(validatePincode);
        console.log(validatePanNo);
        console.log(validateTruckCapacity_Tones);
        console.log(validateInsurancePolicyNo);
        console.log(validateTruckLengthFeet);
        console.log(this.validateStateName_save);
        console.log(this.validateTruckType_save);
        console.log(this.validateInsuranceExpDate);


        if ((validateTruckNumber == null) || (validateTruckNumber == undefined) || (validateTruckNumber == '') ||
            (validateOwnerName == null) || (validateOwnerName == undefined) || (validateOwnerName == '') ||
            (validateAddress == null) || (validateAddress == undefined) || (validateAddress == '') ||
            (validateCity == null) || (validateCity == undefined) || (validateCity == '') ||
            (validatePincode == null) || (validatePincode == undefined) || (validatePincode == '') ||
            (validatePanNo == null) || (validatePanNo == undefined) || (validatePanNo == '') ||
            (validateTruckCapacity_Tones == null) || (validateTruckCapacity_Tones == undefined) ||
            (validateInsurancePolicyNo == null) || (validateInsurancePolicyNo == undefined) ||
            (validateInsurancePolicyNo == '') || (validateTruckLengthFeet == null) ||
            (validateTruckLengthFeet == undefined) || (this.validateStateName_save == null) ||
            (this.validateStateName_save == undefined) || (this.validateStateName_save == '') ||
            (this.validateTruckType_save == null) || (this.validateTruckType_save == undefined) ||
            (this.validateTruckType_save == '') || (this.validateInsuranceExpDate == null) ||
            (this.validateInsuranceExpDate == undefined) || (this.validateInsuranceExpDate == '')) {
            swal("Mandatory Field", "Please Enter the Mandetory Fields", "warning");
            return false;
        } else if (!regpan.test(this.validatePanNumber)) {
            swal("Mandatory Field", "Invalid PAN Number", "warning");
            return false;
        } 
        //Chg_TM_V1
        else if (!insuranceExpDateValidate) {
            swal("Not Allowed", "The insurance expiry date must not be a past date, Please contact the admin", "warning");
            return false;
        }
        else {
            this.saveUpdateTruckDetails();
        }
    }
    getTruckDetailsForSave() {
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.userId = this.userDataDtoReturnSession.userId;
        this.truckDataDto.status = "Working";

        if ($("#" + this.pageId + "regStateName").val() != null && $("#" + this.pageId + "regStateName").val() != "") {
            this.regStateName = $("#" + this.pageId + "regStateName").val();
            this.truckDataDto.state = this.regStateName;
            if (this.modelRegState != null && this.modelRegState != undefined) {
                this.truckDataDto.stateCode = this.modelRegState.stateCode;
            }
        }


        if (this.userDataDtoReturnSession.mainAdminStation != null) {
            this.truckDataDto.mainStation = this.userDataDtoReturnSession.mainStation;
        } else {
            this.truckDataDto.mainStation = this.userDataDtoReturnSession.office;

        }

        //        if ( this.truckDataDto.truckNumber  != null) {
        //            dto.settruckNumber((truckNo.getValue().replaceAll(
        //                    pattern, "")));
        //        }

        /* if ( this.truckDataDto.truckType == "addNew" ) {
             if ( ( this.truckDataDto.truckTypeNew == null ) || ( this.truckDataDto.truckTypeNew == "" ) ) {
                 this.truckDataDto.truckType = "NA";
             } else {
                 this.truckDataDto.truckType = this.truckDataDto.truckTypeNew;
             }
 
         } else if ( ( this.truckDataDto.truckType != null ) && ( this.truckDataDto.truckType == "Select Truck" ) ) {
             this.truckDataDto.truckType = "NA";
         } else if ( ( ( this.truckDataDto.truckType == null ) || ( this.truckDataDto.truckType == "" ) ) ) {
             this.truckDataDto.truckType = "NA";
         }*/
        if ($("#" + this.pageId + "truckTypeCapacityId").val() != null && $("#" + this.pageId + "truckTypeCapacityId").val() != "") {
            this.truckType = $("#" + this.pageId + "truckTypeCapacityId").val();
            this.truckDataDto.truckType = this.truckType;
        }


        //        if (listBoxTruckType.getItemText(
        //                listBoxTruckType.getSelectedIndex())
        //                .equalsIgnoreCase("Add New")) {
        //            if (txtNewTruckType.getValue() == null) {
        //                dto.setTruckType("NA");
        //            } else {
        //                dto.setTruckType(txtNewTruckType.getValue());
        //            }
        //        } else {
        //            dto.setTruckType(listBoxTruckType
        //                    .getValue(listBoxTruckType.getSelectedIndex()));
        //            if ((dto.getTruckType() != null)
        //                    && (dto.getTruckType()
        //                            .equalsIgnoreCase("Select Type"))) {
        //                dto.setTruckType("NA");
        //            }
        //        }



        /*if (this.isUploaded) {
            if ($("#"+this.pageId+"rcFileUploadId").val() != null && $("#"+this.pageId+"rcFileUploadId").val() != "") {
                const constImgUrlRc = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + $("#"+this.pageId+"truckNumberId").val() + "_" + this.selectedFileRC.name + "";
                this.truckDataDto.imgURLRc = constImgUrlRc;
            }

            if ($("#"+this.pageId+"insuranceFileUploadId").val() != null && $("#"+this.pageId+"insuranceFileUploadId").val() != "") {
                const constImgUrlInsurance = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + $("#"+this.pageId+"truckNumberId").val() + "_" + this.selectedFileInsurance.name + "";
                this.truckDataDto.imgURLIns = constImgUrlInsurance;
            }

            if ($("#"+this.pageId+"panFileUploadId").val() != null && $("#"+this.pageId+"panFileUploadId").val() != "") {
                const constImgUrlPan = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + $("#"+this.pageId+"truckNumberId").val() + "_" + this.selectedFilePan.name + "";
                this.truckDataDto.imgURLPan = constImgUrlPan;
            }

            this.truckDataDto.imgUploadedIns = true;
            this.truckDataDto.imgUploadedRc = true;
            this.truckDataDto.imgUploadedPan = true;
        } else {
            this.truckDataDto.imgUploadedIns = false;
            this.truckDataDto.imgUploadedRc = false;
            this.truckDataDto.imgUploadedPan = false;

        }*/
        /* RC File Upload Edit */
        if (this.isUploadedRC) {
            if ($("#" + this.pageId + "rcFileUploadId").val() != null && $("#" + this.pageId + "rcFileUploadId").val() != "") {
                const constImgUrlRc = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + $("#" + this.pageId + "truckNumberId").val() + "_" + this.selectedFileRC.name + "";
                this.truckDataDto.imgURLRc = constImgUrlRc;
            }
            this.truckDataDto.imgUploadedRc = true;
        } else {
            this.truckDataDto.imgUploadedRc = false;
        }

        /* Insu File Upload Edit */
        if (this.isUploadedInsu) {
            if ($("#" + this.pageId + "insuranceFileUploadId").val() != null && $("#" + this.pageId + "insuranceFileUploadId").val() != "") {
                const constImgUrlInsurance = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + $("#" + this.pageId + "truckNumberId").val() + "_" + this.selectedFileInsurance.name + "";
                this.truckDataDto.imgURLIns = constImgUrlInsurance;
            }
            this.truckDataDto.imgUploadedIns = true;
        } else {
            this.truckDataDto.imgUploadedIns = false;
        }

        /* Pan File Upload Edit */
        if (this.isUploadedPan) {
            if ($("#" + this.pageId + "panFileUploadId").val() != null && $("#" + this.pageId + "panFileUploadId").val() != "") {
                const constImgUrlPan = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + $("#" + this.pageId + "truckNumberId").val() + "_" + this.selectedFilePan.name + "";
                this.truckDataDto.imgURLPan = constImgUrlPan;
            }
            this.truckDataDto.imgUploadedPan = true;
        } else {
            this.truckDataDto.imgUploadedPan = false;
        }

        // dto.setPanNo(tfPanNo.getValue());
        if ((this.truckDataDto.trkLgth == null) || (this.truckDataDto.trkLgth == 0)) {
            this.truckDataDto.trkLgth = 0.0;
        }

        //get bank details
        this.truckDataDtoBankList = [];
        console.log('bankDetailsDataList');
        console.log(this.bankDetailsDataList);
        for (let i = 0; i < this.bankDetailsDataList.length; i++) {
            this.truckDataDtoBank = new TruckDataDto();
            this.truckDataDtoBank.acType = this.bankDetailsDataList[i].acType;
            this.truckDataDtoBank.acNumber = this.bankDetailsDataList[i].acNo;
            this.truckDataDtoBank.bankName = this.bankDetailsDataList[i].bankName;
            this.truckDataDtoBank.acHolder = this.bankDetailsDataList[i].acHolder;
            this.truckDataDtoBank.ifscCode = this.bankDetailsDataList[i].ifscCode;
            this.truckDataDtoBank.branchName = this.bankDetailsDataList[i].branchName;
            this.truckDataDtoBankList.push(this.truckDataDtoBank);
        }
        console.log(this.truckDataDtoBankList);
        this.truckDataDto.truckDataDtoList = this.truckDataDtoBankList;

        //Chg_TM_V1
        this.insuranceExpDate = $("#" + this.pageId + "insuranceExpDate").val();
        this.truckDataDto.insuranceExpDate = this.insuranceExpDate;
    }
    saveUpdateTruckDetails = () => {
        this.getTruckDetailsForSave();
        console.log(this.truckDataDto);
        this.masterService.createTruck(this.truckDataDto).
            subscribe((data) => {
                this.truckDataDtoSaveRet = data;
                //                console.log( this.truckDataDtoSaveRet.status );
                if (this.truckDataDtoSaveRet.status == "persisted") {
                    swal({
                        title: "Success",
                        text: "Truck Details Saved/Updated Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    //alert(this.modeStorage);
                    if (this.modeStorage != null && this.modeStorage != undefined &&
                        this.modeStorage == "Hireslip") {
                        this.datatableHideShow = false;
                    } else {
                        this.datatableHideShow = true;
                        //this.getTruckMasterDetailsList();
                        $("#" + this.pageId + "truckMasterDatatableId").DataTable().destroy();
                        this.truckMasterDataList = [];
                        this.dtTriggerTruckMaster.next();
                        this.changeDetectorRef.detectChanges();
                    }
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Saving Truck Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Saving Truck Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    clearAll() {
        $("#" + this.pageId + "truckNumberId").val('');
        $("#" + this.pageId + "ownerName").val('');
        $("#" + this.pageId + "truckAddressId").val('');
        $("#" + this.pageId + "city").val('');
        $("#" + this.pageId + "regStateName").val('');
        $("#" + this.pageId + "pinCode").val('');
        $("#" + this.pageId + "truckOwnerPanNumber").val('');
        $("#" + this.pageId + "rcFileUploadId").val('');
        $("#" + this.pageId + "insuranceFileUploadId").val('');
        $("#" + this.pageId + "panFileUploadId").val('');
        $("#" + this.pageId + "truckCapacityInTones").val('');
        $("#" + this.pageId + "truckTypeCapacityId").val('');
        $("#" + this.pageId + "addNewTruckType").val('');
        $("#" + this.pageId + "insurancePolicyNumber").val('');
        $("#" + this.pageId + "truckLengthFeet").val('');
        //$( 'input[type="text"],[type="number"],[type="file"]' ).val( '' );
        this.newTruck = false;
        //$( "#truckType" ).val( 'Select Truck' );
        //$( "#regState" ).val( 'Select State' );
        this.truckDataDto.truckNumber = null;
        //$( "#rcFileUploadId" ).val( '' );
        //this.modeStorage='TruckMaster';
        // this.datatableHideShow=false;
        this.regStateName = null;
        this.regStateCode = null;
        this.truckType = null;
        this.rowSelectedStateName = null;
        this.rowSelectedStateCoe = null;
        this.rowSelectedTruckType = null;
        this.rowSelectedOwnerName = null;
        this.rowSelectedAddress = null;
        this.rowSelectedCity = null;
        this.rowSelectedPincode = null;
        this.rowSelectedPan = null;
        this.rowSelectedTruckCapc = null;
        this.rowSelectedInsNo = null;
        this.rowSelectedTruckLgt = null;

        this.truckDtoLocalStorage = new TruckDataDto();
        this.isUpdateMode = false;
        this.readonlytruckNumber = false;

        this.isUploadedRC = false;
        this.isUploadedInsu = false;
        this.isUploadedPan = false;

        this.bankDetailsDataList = [];
        $("#" + this.pageId + "bankDetailsTableId").DataTable().destroy();
        this.dtTriggerBankdetails.next();

        //Chg_TM_V1
        $("#" + this.pageId + "insuranceExpDate").val('');
        this.insuranceExpDate = null;
        this.rowSelectedInsExpDate = null;

        /* if (this.modeStorage != null && this.modeStorage != undefined &&
             this.modeStorage == "Hireslip") {
             this.datatableHideShow = false;
             //this.isUpdateMode =true;
         } else {
             this.datatableHideShow = true;
             //this.isUpdateMode =false;
         }*/
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rowSelected(truckMasterData) {
        console.log(truckMasterData);
        this.showSpinnerForAction = true;
        // let top = document.getElementById('truckMasterId');
        // if (top !== null) {
        //     top.scrollIntoView();
        //     top = null;
        // }
        //setTimeout(() => {
        this.truckDataDto.truckNumber = truckMasterData.truckNumber;
        this.truckDataDto.ownerName = truckMasterData.ownerName;
        this.truckDataDto.address = truckMasterData.address;
        this.truckDataDto.city = truckMasterData.city;
        //this.truckDataDto.state = truckMasterData.state;
        this.truckDataDto.pinCodeNumber = truckMasterData.pinCodeNumber;
        this.truckDataDto.panNo = truckMasterData.tinNo;
        this.truckDataDto.truckCapcityDouble = truckMasterData.truckCapacity;
        // this.truckDataDto.truckType = truckMasterData.truckType;
        this.truckDataDto.insPolNo = truckMasterData.insPolNo;
        this.truckDataDto.trkLgth = truckMasterData.trkLgth;
        if (truckMasterData.state != null && truckMasterData.state != "") {
            this.rowSelectedStateName = truckMasterData.state;
            //this.modelRegState.stateCode = truckMasterData.stateCode;
            $("#" + this.pageId + "regStateName").val(this.rowSelectedStateName);
        }
        if (truckMasterData.truckType != null && truckMasterData.truckType != "") {
            this.rowSelectedTruckType = truckMasterData.truckType;
            $("#" + this.pageId + "truckTypeCapacityId").val(this.rowSelectedTruckType);
        }
        this.rowSelectedOwnerName = truckMasterData.ownerName;
        $("#" + this.pageId + "ownerName").val(this.rowSelectedOwnerName);

        this.rowSelectedAddress = truckMasterData.address;
        $("#" + this.pageId + "truckAddressId").val(this.rowSelectedAddress);

        this.rowSelectedCity = truckMasterData.city;
        $("#" + this.pageId + "city").val(this.rowSelectedCity);

        this.rowSelectedPincode = truckMasterData.pinCodeNumber;
        $("#" + this.pageId + "pinCode").val(this.rowSelectedPincode);

        this.rowSelectedPan = truckMasterData.tinNo;
        $("#" + this.pageId + "truckOwnerPanNumber").val(this.rowSelectedPan);

        this.rowSelectedTruckCapc = truckMasterData.truckCapacity;
        $("#" + this.pageId + "truckCapacityInTones").val(this.rowSelectedTruckCapc);

        this.rowSelectedInsNo = truckMasterData.insPolNo;
        $("#" + this.pageId + "insurancePolicyNumber").val(this.rowSelectedInsNo);

        this.rowSelectedTruckLgt = truckMasterData.trkLgth;
        $("#" + this.pageId + "truckLengthFeet").val(this.rowSelectedTruckLgt);

        //Chg_TM_V1
        this.rowSelectedInsExpDate = truckMasterData.insuranceExpDate;
        $("#" + this.pageId + "insuranceExpDate").val(this.rowSelectedInsExpDate == null ? null
            : (moment(this.rowSelectedInsExpDate).format("YYYY-MM-DD")));

        this.readonlytruckNumber = true;
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
        this.getDetailsForBankMasterRead(truckMasterData.truckNumber);
    }


    //    service ends

    validateDeleteRow() {
        this.deleteTruckDetails();
    }

    getDeleteTruckDetails() {
        this.truckDataDeleteDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDeleteDto.truckNumber = this.truckDataDto.truckNumber;
        this.truckDataDeleteDto.userId = this.userDataDtoReturnSession.userId;
    }

    deleteTruckDetails = () => {
        this.getDeleteTruckDetails();
        this.masterService.deleteTruck(this.truckDataDeleteDto).
            subscribe((data) => {
                this.truckDataDtoDeleteRet = data;
                if (this.truckDataDtoDeleteRet.status == "success") {
                    swal({
                        title: "Success",
                        text: "Truck Details Deleted Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    //this.getTruckMasterDetailsList();
                    $("#" + this.pageId + "truckMasterDatatableId").DataTable().destroy();
                    this.truckMasterDataList = [];
                    this.dtTriggerTruckMaster.next();
                    this.changeDetectorRef.detectChanges();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Deleting Truck Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Deleting Truck Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    clickListnerForRegState(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelRegState = e.item;
        console.log(this.modelRegState);
        $("#" + this.pageId + "regStateName").val(this.modelRegState.state);
    }

    getStatesMethod() {
        this.partyMasterDto = new PartyMasterDto();
        this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.getStateDetailsService(this.partyMasterDto).subscribe(
            (response) => {
                if (response) {
                    // console.log(response);
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No getStatesMethod records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.controlRegState.reset();
                        this.partyMasterDtoOptions = [];
                        this.modelRegStateTA = [];
                    } else {
                        this.controlRegState.reset();
                        this.partyMasterDtoOptions = response;
                        this.modelRegStateTA = [];
                        for (let i = 0; i < this.partyMasterDtoOptions.length; i++) {
                            this.modelRegStateTA.push(this.partyMasterDtoOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting getStatesMethod",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }
    focusRegStateTA(e) {
        console.log(e);
        if (e.keyCode == 13) {
            $("#" + this.pageId + "pinCode").focus();
        }
    }

    clickListnerForTruckTypeCapacity(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelTruckTypeCapacity = e.item;
        $("#" + this.pageId + "truckType").val(this.modelTruckTypeCapacity.truckType);
    }

    getTruckTypesMethod() {
        this.truckDto = new TruckDataDto();
        this.truckDto.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.getTruckTypes(this.truckDto).subscribe(
            (response) => {
                if (response) {
                    console.log(response);
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No getTruckTypesMethod records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.controlRegState.reset();
                        this.truckDataDtoOptions = [];
                        this.truckTypeCapacityTA = [];
                    } else {
                        this.controlRegState.reset();
                        this.truckDataDtoOptions = response;
                        this.truckTypeCapacityTA = [];
                        for (let i = 0; i < this.truckDataDtoOptions.length; i++) {
                            this.truckTypeCapacityTA.push(this.truckDataDtoOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting getTruckTypesMethod",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }
    focusTruckTypeCapacityTA(e) {
        console.log(e);
        if (e.keyCode == 13) {
            $("#" + this.pageId + "insurancePolicyNumber").focus();
        }
    }
    onFileChangedRC(event) {
        this.selectedFileRC = event.target.files[0];
        console.log(this.selectedFileRC);
    }

    onFileChangedInsurance(event) {
        this.selectedFileInsurance = event.target.files[0];
        console.log(this.selectedFileInsurance);
    }

    onFileChangedPan(event) {
        this.selectedFilePan = event.target.files[0];
        console.log(this.selectedFilePan);
    }

    /* @17062021 As Per Diss with Asrar bhaiya multile upload at time 
    error in backend so for avoid the this error we put each seperate 
    btn for each */

    /* Upload of RC/Ins/Pan Start */

    /*RC Upload Btn Start */
    uploadRCFileBtn() {
        console.log($("#" + this.pageId + "rcFileUploadId").val());
        if ($("#" + this.pageId + "rcFileUploadId").val() != null &&
            $("#" + this.pageId + "rcFileUploadId").val() != undefined &&
            $("#" + this.pageId + "rcFileUploadId").val() != "") {
            if ((this.selectedFileRC.type != null &&
                (this.selectedFileRC.type == "image/jpeg" ||
                    this.selectedFileRC.type == "image/jpg" ||
                    this.selectedFileRC.type == "image/png"))) {
                var splitTypeRc = this.selectedFileRC.type.split("/");
                //const truckLorr_No =$("#"+this.pageId+"truckNumber").val(); 
                //console.log(truckLorr_No);
                const commonListRc = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeRc[1] + "&&" + this.truckDataDto.truckNumber + "";
                const formData = new FormData();
                formData.append(commonListRc, commonListRc);
                formData.append('myfileRC', this.selectedFileRC, this.selectedFileRC.name);
                this.masterReadService.saveRCInsurancePanForTruckMasterFileUpload(formData).subscribe(
                    (response) => {
                        if (response) {
                            if (response[0] != "Error") {
                                swal("File Upload", "File Uploaded Succcessfully", "success");
                                this.isUploadedRC = true;
                            } else {
                                swal("ERROR", "Correct the RC File and Upload Again", "error");
                            }
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => swal({
                        title: "Error",
                        text: "Server Error While Uploading a RC File",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }),
                    () => console.log('done');
            } else {
                swal("Mandatory Field", "Only jpg & png image format are allowed to upload for RC File", "warning");
            }
        } else {
            swal("Mandatory Field", "Please select the RC File", "warning");
        }
    }
    /*RC Upload Btn End*/

    /*Insurance Upload Btn Start */

    uploadInsuranceFileBtn() {
        console.log($("#" + this.pageId + "insuranceFileUploadId").val());
        if ($("#" + this.pageId + "insuranceFileUploadId").val() != null &&
            $("#" + this.pageId + "insuranceFileUploadId").val() != undefined &&
            $("#" + this.pageId + "insuranceFileUploadId").val() != "") {
            if ((this.selectedFileInsurance.type != null &&
                (this.selectedFileInsurance.type == "image/jpeg" ||
                    this.selectedFileInsurance.type == "image/jpg" ||
                    this.selectedFileInsurance.type == "image/png"))) {
                var splitTypeInsurance = this.selectedFileInsurance.type.split("/");
                const commonListInsurance = "vehicledriverdocs&&documents&&ASRAR&& " + splitTypeInsurance[1] + "&&" + this.truckDataDto.truckNumber + "";
                const formData = new FormData();
                formData.append(commonListInsurance, commonListInsurance);
                formData.append('myfileInsurance', this.selectedFileInsurance, this.selectedFileInsurance.name);
                this.masterReadService.saveRCInsurancePanForTruckMasterFileUpload(formData).subscribe(
                    (response) => {
                        if (response) {
                            if (response[0] != "Error") {
                                swal("File Upload", "File Uploaded Succcessfully", "success");
                                this.isUploadedInsu = true;
                            } else {
                                swal("ERROR", "Correct the Insurance File and Upload Again", "error");
                            }
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => swal({
                        title: "Error",
                        text: "Server Error While Uploading a Insurance file",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }),
                    () => console.log('done');
            } else {
                swal("Mandatory Field", "Only jpg & png image format are allowed to upload for insurance file", "warning");
            }
        } else {
            swal("Mandatory Field", "Please select the Insurance File", "warning");
        }
    }

    /*Insurance Upload Btn End */

    /*Pan Upload Btn Start */

    uploadPanFileBtn() {
        console.log($("#" + this.pageId + "panFileUploadId").val());
        if ($("#" + this.pageId + "panFileUploadId").val() != null &&
            $("#" + this.pageId + "panFileUploadId").val() != undefined &&
            $("#" + this.pageId + "panFileUploadId").val() != "") {
            if ((this.selectedFilePan.type != null &&
                (this.selectedFilePan.type == "image/jpeg" ||
                    this.selectedFilePan.type == "image/jpg" ||
                    this.selectedFilePan.type == "image/png"))) {
                var splitTypePan = this.selectedFilePan.type.split("/");
                const commonListPan = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypePan[1] + "&&" + this.truckDataDto.truckNumber + "";
                const formData = new FormData();
                formData.append(commonListPan, commonListPan);
                formData.append('myfilePan', this.selectedFilePan, this.selectedFilePan.name);
                this.masterReadService.saveRCInsurancePanForTruckMasterFileUpload(formData).subscribe(
                    (response) => {
                        if (response) {
                            if (response[0] != "Error") {
                                swal("File Upload", "File Uploaded Succcessfully", "success");
                                this.isUploadedPan = true;
                            } else {
                                swal("ERROR", "Correct the Pan File and Upload Again", "error");
                            }
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => swal({
                        title: "Error",
                        text: "Server Error While Uploading a Pan file",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }),
                    () => console.log('done');
            } else {
                swal("Mandatory Field", "Only jpg & png image format are allowed to upload for pan file", "warning");
            }
        } else {
            swal("Mandatory Field", "Please select the Pan File", "warning");
        }
    }

    /*Pan Upload Btn End */

    /* Upload of RC/Ins/Pan End */

    /* uploadBtn() {
         console.log($("#"+this.pageId+"rcFileUploadId").val());
         console.log($("#"+this.pageId+"insuranceFileUploadId").val());
         console.log($("#"+this.pageId+"panFileUploadId").val());
         if ($("#"+this.pageId+"rcFileUploadId").val() != null && $("#"+this.pageId+"rcFileUploadId").val() != "") {
             if ((this.selectedFileRC.type != null &&
                 (this.selectedFileRC.type == "image/jpeg" ||
                     this.selectedFileRC.type == "image/jpg" ||
                     this.selectedFileRC.type == "image/png"))) {
                 var splitTypeRc = this.selectedFileRC.type.split("/");
                 //const truckLorr_No =$("#"+this.pageId+"truckNumber").val(); 
                 //console.log(truckLorr_No);
                 const commonListRc = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeRc[1] + "&&" + this.truckDataDto.truckNumber + "";
                 const formData = new FormData();
                 formData.append(commonListRc, commonListRc);
                 formData.append('myfileRC', this.selectedFileRC, this.selectedFileRC.name);
                 this.masterReadService.saveRCInsurancePanForTruckMasterFileUpload(formData).subscribe(
                     (response) => {
                         if (response) {
                             if (response[0] != "Error") {
                                 swal("File Upload", "File Uploaded Succcessfully", "success");
                                 this.isUploaded = true;
                             } else {
                                 swal("ERROR", "Correct the RC File and Upload Again", "error");
                             }
                         }
                     }), (error) => swal({
                         title: "Error",
                         text: "Server Error While Uploading a RC File",
                         icon: "warning",
                         closeOnClickOutside: false,
                         closeOnEsc: false,
                     }),
                     () => console.log('done');
             } else {
                 swal("Mandatory Field", "Only jpg & png image format are allowed to upload for RC File", "warning");
             }
         }
 
         if ($("#"+this.pageId+"insuranceFileUploadId").val() != null && $("#"+this.pageId+"insuranceFileUploadId").val() != "") {
             if ((this.selectedFileInsurance.type != null &&
                 (this.selectedFileInsurance.type == "image/jpeg" ||
                     this.selectedFileInsurance.type == "image/jpg" ||
                     this.selectedFileInsurance.type == "image/png"))) {
                 var splitTypeInsurance = this.selectedFileInsurance.type.split("/");
                 const commonListInsurance = "vehicledriverdocs&&documents&&ASRAR&& " + splitTypeInsurance[1] + "&&" + this.truckDataDto.truckNumber + "";
                 const formData = new FormData();
                 formData.append(commonListInsurance, commonListInsurance);
                 formData.append('myfileInsurance', this.selectedFileInsurance, this.selectedFileInsurance.name);
                 this.masterReadService.saveRCInsurancePanForTruckMasterFileUpload(formData).subscribe(
                     (response) => {
                         if (response) {
                             if (response[0] != "Error") {
                                 swal("File Upload", "File Uploaded Succcessfully", "success");
                                 this.isUploaded = true;
                             } else {
                                 swal("ERROR", "Correct the Insurance File and Upload Again", "error");
                             }
                         }
                     }), (error) => swal({
                         title: "Error",
                         text: "Server Error While Uploading a Insurance file",
                         icon: "warning",
                         closeOnClickOutside: false,
                         closeOnEsc: false,
                     }),
                     () => console.log('done');
             } else {
                 swal("Mandatory Field", "Only jpg & png image format are allowed to upload for insurance file", "warning");
             }
         }
 
         if ($("#"+this.pageId+"panFileUploadId").val() != null && $("#"+this.pageId+"panFileUploadId").val() != "") {
             if ((this.selectedFilePan.type != null &&
                 (this.selectedFilePan.type == "image/jpeg" ||
                     this.selectedFilePan.type == "image/jpg" ||
                     this.selectedFilePan.type == "image/png"))) {
                 var splitTypePan = this.selectedFilePan.type.split("/");
                 const commonListPan = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypePan[1] + "&&" + this.truckDataDto.truckNumber + "";
                 const formData = new FormData();
                 formData.append(commonListPan, commonListPan);
                 formData.append('myfilePan', this.selectedFilePan, this.selectedFilePan.name);
                 this.masterReadService.saveRCInsurancePanForTruckMasterFileUpload(formData).subscribe(
                     (response) => {
                         if (response) {
                             if (response[0] != "Error") {
                                 swal("File Upload", "File Uploaded Succcessfully", "success");
                                 this.isUploaded = true;
                             } else {
                                 swal("ERROR", "Correct the Pan File and Upload Again", "error");
                             }
                         }
                     }), (error) => swal({
                         title: "Error",
                         text: "Server Error While Uploading a Pan file",
                         icon: "warning",
                         closeOnClickOutside: false,
                         closeOnEsc: false,
                     }),
                     () => console.log('done');
             } else {
                 swal("Mandatory Field", "Only jpg & png image format are allowed to upload for pan file", "warning");
             }
         }
     }*/

    setFieldValues(lorryNumber) {
        this.truckDataDtoSetFieldVal = new TruckDataDto();
        this.truckDataDtoSetFieldVal.truckNumber = lorryNumber;
        this.masterReadService.getTruckDtlsInHireSlipGeneration(this.truckDataDtoSetFieldVal).
            subscribe((data) => {
                console.log(data);
                this.retriveTruckDtoSetField = data;
                if (this.retriveTruckDtoSetField.status == "Success") {
                    this.truckDataDto.truckNumber = this.retriveTruckDtoSetField.truckNumber;
                    this.truckDataDto.ownerName = this.retriveTruckDtoSetField.ownerName;
                    this.truckDataDto.address = this.retriveTruckDtoSetField.address;
                    this.truckDataDto.city = this.retriveTruckDtoSetField.city;
                    this.truckDataDto.pinCodeNumber = this.retriveTruckDtoSetField.pinCodeNumber;
                    this.truckDataDto.panNo = this.retriveTruckDtoSetField.tinNo;
                    this.truckDataDto.truckCapcityDouble = this.retriveTruckDtoSetField.truckCapacity;
                    this.truckDataDto.insPolNo = this.retriveTruckDtoSetField.insPolNo;
                    this.truckDataDto.trkLgth = this.retriveTruckDtoSetField.trkLgth;
                    if (this.retriveTruckDtoSetField.state != null && this.retriveTruckDtoSetField.state != "") {
                        this.rowSelectedStateName = this.retriveTruckDtoSetField.state;
                        $("#" + this.pageId + "regStateName").val(this.rowSelectedStateName);
                    }
                    if (this.retriveTruckDtoSetField.truckType != null && this.retriveTruckDtoSetField.truckType != "") {
                        this.rowSelectedTruckType = this.retriveTruckDtoSetField.truckType;
                        $("#" + this.pageId + "truckTypeCapacityId").val(this.rowSelectedTruckType);
                    }
                } else {
                    swal("Alert", "Failed to get truck master details", "error");
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Saving Truck Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }

    truckNumberValidationOfBlur(e) {
        this.validatingTruckNumberAlreadyExist();
    }
    validatingTruckNumberAlreadyExist() {
        this.truckDataDtoValidateTruckNo = new TruckDataDto();
        this.truckDataDtoValidateTruckNo.truckNumber = this.truckDataDto.truckNumber;
        // console.log(this.truckDataDtoValidateTruckNo);
        this.masterService.checkTruckExist(this.truckDataDtoValidateTruckNo).subscribe((data) => {
            this.truckDataDtoReturnValidateTruckNo = data;
            //console.log( this.truckDataDtoReturnValidateTruckNo.status);
            if (this.truckDataDtoReturnValidateTruckNo.status == "Exist") {
                swal("Truck Number", "This Truck Number: " + $("#" + this.pageId + "truckNumberId").val() + " is Already Exist", "warning");
                $("#" + this.pageId + "truckNumberId").val('');
                this.truckDataDto.truckNumber = null;
            } else if (this.truckDataDtoReturnValidateTruckNo.status == "Failed") {
                swal("Error", "Server Error While validatingTruckNumberAlreadyExist", "error");
            }
        }),
            (error) => swal("Error", "Server Error While validatingTruckNumberAlreadyExist", "error"),
            () => console.log('done');
    }

    getRCImg(truckMasterData) {
        console.log(truckMasterData);
        if (truckMasterData.imgURLRc == null || truckMasterData.imgURLRc == undefined ||
            truckMasterData.imgURLRc == "NA") {
            swal("RC", "RC Not Uploaded", "info");
            return false;
        } else {
            window.open(truckMasterData.imgURLRc, '_blank');
        }
    }

    getInsuranceImg(truckMasterData) {
        if (truckMasterData.imgURLIns == null || truckMasterData.imgURLIns == undefined ||
            truckMasterData.imgURLIns == "NA") {
            swal("Insurance", "Insurance Not Uploaded", "info");
            return false;
        } else {
            window.open(truckMasterData.imgURLIns, '_blank');
        }
    }

    getPanImg(truckMasterData) {
        if (truckMasterData.imgURLPan == null || truckMasterData.imgURLPan == undefined ||
            truckMasterData.imgURLPan == "NA") {
            swal("Pan", "Pan Not Uploaded", "info");
            return false;
        } else {
            window.open(truckMasterData.imgURLPan, '_blank');
        }
    }

    // imran
    customPrinTruckMaster() {
        if (this.truckMasterDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Truck Number", "Owner Name", "Address", "City", "Pincode", "Pan Number", "Truck Capacity", "Truck Type", "Truck Length(Ft)"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 13, 20, 8, 9, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            for (let i = 0; i < this.truckMasterDataList.length; i++) {

                this.cashMemoDtoForCustomPrintListColumnValues = [this.truckMasterDataList[i].truckNumber, this.truckMasterDataList[i].ownerName,
                this.truckMasterDataList[i].address, this.truckMasterDataList[i].city, this.truckMasterDataList[i].pinCodeNumber,
                this.truckMasterDataList[i].tinNo, this.truckMasterDataList[i].truckCapacity, this.truckMasterDataList[i].truckType, this.truckMasterDataList[i].trkLgth];
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.truckMasterDataList.length, "", "", "", "", "", ""];
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            // localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //	localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Truck Master");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    getTruckMasterDetailsSingle = () => {
        this.getTruckDetailsForReadSingle();
        this.showSpinnerForAction = true;
        this.masterReadService.getTruck(this.truckDataDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "truckMasterDatatableId").DataTable().destroy();
                this.truckMasterDataList = [];
                if (response.length > 0) {
                    this.truckMasterDataList = response;
                } else {
                    swal("No records", "No Truck details found for this search!", "warning");
                }
                this.dtTriggerTruckMaster.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Truck Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    getTruckDetailsForReadSingle() {
        this.vehicleNoSearch = $("#" + this.pageId + "truckNumberSearchId").val();
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.status = "Working";
        this.truckDataDto.mode = "TruckNo";
        this.truckDataDto.vehicleName = this.vehicleNoSearch;
    }

    onKeyPressListnerAcType(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "acNo").focus();
        }
    }

    onKeyPressListnerAcNo(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "BankName").focus();
        }
    }

    onKeyPressListnerBankName(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "AcHolder").focus();
        }
    }

    onKeyPressListnerAcHolder(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "IfscCode").focus();
        }
    }

    onKeyPressListnerIfscCode(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "BranchName").focus();
        }
    }

    onKeyPressListnerBranchName(event) {
        if (event.charCode == 13) {
            if ($("#" + this.pageId + "AcType").val() == null || $("#" + this.pageId + "AcType").val() == undefined || $("#" + this.pageId + "AcType").val() == "" ||
                $("#" + this.pageId + "acNo").val() == null || $("#" + this.pageId + "acNo").val() == undefined || $("#" + this.pageId + "acNo").val() == "" ||
                $("#" + this.pageId + "BankName").val() == null || $("#" + this.pageId + "BankName").val() == undefined || $("#" + this.pageId + "BankName").val() == "" ||
                $("#" + this.pageId + "AcHolder").val() == null || $("#" + this.pageId + "AcHolder").val() == undefined || $("#" + this.pageId + "AcHolder").val() == "" ||
                $("#" + this.pageId + "IfscCode").val() == null || $("#" + this.pageId + "IfscCode").val() == undefined || $("#" + this.pageId + "IfscCode").val() == "") {
                swal("Mandatory Field", "Please enter the mandatory fields!", "warning");
                return false;
            } else {
                this.addInTableOfPenaltyDetailsTable();
            }
        }
    }

    addInTableOfPenaltyDetailsTable() {
        this.newAttributeTosetBankDetails = {};
        this.newAttributeTosetBankDetails.acType = $("#" + this.pageId + "AcType").val();
        this.newAttributeTosetBankDetails.acNo = $("#" + this.pageId + "acNo").val();
        this.newAttributeTosetBankDetails.bankName = $("#" + this.pageId + "BankName").val();
        this.newAttributeTosetBankDetails.acHolder = $("#" + this.pageId + "AcHolder").val();
        this.newAttributeTosetBankDetails.ifscCode = $("#" + this.pageId + "IfscCode").val();
        this.newAttributeTosetBankDetails.branchName = $("#" + this.pageId + "BranchName").val();

        if (this.editBankTableDetailsId != null &&
            this.editBankTableDetailsId != undefined) {
            this.bankDetailsDataList.splice(this.editBankTableDetailsId, 1);
        }

        if (this.bankDetailsDataList == null || this.bankDetailsDataList == undefined ||
            this.bankDetailsDataList.length == 0) {
            this.bankDetailsDataList = [];
        }

        if (this.bankDetailsDataList.length == 0) {
            this.bankDetailsDataList = [];
            $("#" + this.pageId + "bankDetailsTableId").DataTable().destroy();
            this.bankDetailsDataList.push(this.newAttributeTosetBankDetails);
            this.dtTriggerBankdetails.next();
            this.clearFieldBankdetails();
        } else {
            const result = this.bankDetailsDataList.filter(bankDetailsData =>
                bankDetailsData.acNo === this.newAttributeTosetBankDetails.acNo);
            if (result.length > 0) {
                swal("Duplicate Entry", "AC Number already entered, please verify it.", "warning");
            } else {
                $("#" + this.pageId + "bankDetailsTableId").DataTable().destroy();
                this.bankDetailsDataList.push(this.newAttributeTosetBankDetails);
                this.dtTriggerBankdetails.next();
                this.clearFieldBankdetails();
            }
        }
    }

    rowSelectedBankdetailsDataOnEdit(bankDetailsData, index) {
        $("#" + this.pageId + "AcType").val(bankDetailsData.acType);
        $("#" + this.pageId + "acNo").val(bankDetailsData.acNo);
        $("#" + this.pageId + "BankName").val(bankDetailsData.bankName);
        $("#" + this.pageId + "AcHolder").val(bankDetailsData.acHolder);
        $("#" + this.pageId + "IfscCode").val(bankDetailsData.ifscCode);
        $("#" + this.pageId + "BranchName").val(bankDetailsData.branchName);
        this.editBankTableDetailsId = index;
    }

    deleteSelectedBankdetails(index) {
        $("#" + this.pageId + "bankDetailsTableId").DataTable().destroy();
        this.bankDetailsDataList.splice(index, 1);
        this.dtTriggerBankdetails.next();
    }

    setAcTypeVal() {
        for (let i = 0; i < this.acTypeOptions.length; i++) {
            this.acTypeTA.push(this.acTypeOptions[i].label);
        }
    }

    acTypeFocus(e: NgbTypeaheadSelectItemEvent) {
        this.modelAcType = e.item;
    }

    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        // inp._elementRef.nativeElement.focus();
        window.setTimeout(function () {
            inp._elementRef.nativeElement.focus();
        }, 1000);
    }

    clearFieldBankdetails() {
        this.newAttributeTosetBankDetails = {};
        this.editBankTableDetailsId = null;
        $("#" + this.pageId + "AcType").val('');
        $("#" + this.pageId + "acNo").val('');
        $("#" + this.pageId + "BankName").val('');
        $("#" + this.pageId + "AcHolder").val('');
        $("#" + this.pageId + "IfscCode").val('');
        $("#" + this.pageId + "BranchName").val('');
    }

    getDetailsForBankMasterRead(truckNumber) {
        this.truckDataDtoBank = new TruckDataDto();
        this.truckDataDtoBank.truckNumber = truckNumber;
        this.truckDataDtoBank.companyId = this.userDataDtoReturnSession.companyId;
        this.getBankDetailsList();
    }

    getBankDetailsList = () => {
        this.masterService.getTruckBankDetails(this.truckDataDtoBank).subscribe(
            (response) => {
                if (response) {
                    $("#" + this.pageId + "bankDetailsTableId").DataTable().destroy();
                    this.bankDetailsDataList = [];
                    if (response.length > 0) {
                        this.bankDetailsDataList = response;
                    }
                }
                this.dtTriggerBankdetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting the Bank Details", "error");
            },
            () => console.log('done');
    }
}
