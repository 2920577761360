import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { serverUrl } from "src/environments/environment";
import { InvoiceDto } from '../dto/Invoice-dto';
import { HireSlipDto } from '../dto/HireSlip-dto';
import { LRDto } from '../dto/LR-dto';
import { ResponseDto } from '../dto/Response-dto';


@Injectable({
    providedIn: 'root'
})
export class HireslipService {

    private _baseUrl = serverUrl.webHireslip;

    constructor(private http: HttpClient) {

    }
    getStocksInTakenReport(invoiceDto: Object): Observable<InvoiceDto[]> {
        return this.http.post<InvoiceDto[]>(`${this._baseUrl}/getStocksInTakenReportApi`, invoiceDto);
    }

    getUnloadingRptDtls(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getUnloadingRptDtlsApi`, hireSlipDto);
    }

    getHireSlipsUnLoadRptDetails(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/getHireSlipsUnLoadRptDetailsApi`, hireSlipDto);
    }

    getInvoiceDetails(invoiceDto: Object): Observable<InvoiceDto> {
        return this.http.post<InvoiceDto>(`${this._baseUrl}/getInvoiceDetailsApi`, invoiceDto);
    }

    getLorryHireBalanceReport(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getLorryHireBalanceReportApi`, hireSlipDto);
    }


    updateDriverRewardingHireBalance(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/updateDriverRewardingHireBalanceApi`, hireSlipDto);
    }


    setHireSlipBalPrtVoucher(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/setHireSlipBalPrtVoucherApi`, hireSlipDto);
    }

    getHireSlipDetailsForVoucher(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getHireSlipDetailsForVoucherApi`, hireSlipDto);
    }
    updateHireBalance(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/updateHireBalanceApi`, hireSlipDto);
    }

    getHireSlipDetailsForRewarding(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/getHireSlipDetailsForRewardingApi`, hireSlipDto);
    }

    getTimeDifference(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/getTimeDifferenceApi`, hireSlipDto);
    }

    /* updateDriverRewarding(hireSlipDto: Object): Observable<HireSlipDto> {
         return this.http.post<HireSlipDto>(`${this._baseUrl}/updateDriverRewardingApi`, hireSlipDto);
     }*/

    updateDriverRewarding(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/updateDriverRewardingApi`, hireSlipDto);
    }

    scanCodeApi(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/scanCodeApi`, hireSlipDto);
    }
    //TripSheetStock
    getArticlesStocksSummForDestination(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getArticlesStocksSummForDestinationApi`, lrDto);
    }

    getArticlesStocksForDestination(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getArticlesStocksForDestinationApi`, lrDto);
    }

    addTripSheetDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/addTripSheetDetailsApi`, lrDto);
    }

    updateDestLocalTripSheetDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateDestLocalTripSheetDetailsApi`, lrDto);
    }

    getLocalTripsLrsDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLocalTripsLrsDetailsApi`, lrDto);
    }

    //StockGrouping @20-02-2021
    getUnclearedInvoives(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getUnclearedInvoivesApi`, hireSlipDto);
    }

    // basha bh
    getHireSlipsBlkForUnLoadRpt(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/getHireSlipsBlkForUnLoadRptApi`, hireSlipDto);
    }

    updateShipmentStatus(invoiceDto: Object): Observable<InvoiceDto> {
        return this.http.post<InvoiceDto>(`${this._baseUrl}/updateShipmentStatusApi`, invoiceDto);
    }

    getHireSlipUnLoadRptDtls(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getHireSlipUnLoadRptDtlsApi`, hireSlipDto);
    }

    getLRdtsUnloadRpt(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/getLRdtsUnloadRptApi`, hireSlipDto);
    }

    getLRdtsUnloadRptV2(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/getLRdtsUnloadRptV2Api`, hireSlipDto);
    }

    addHireSlipsUnLoadRptDetails(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/addHireSlipsUnLoadRptDetailsApi`, hireSlipDto);
    }
    getSmsStatus(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getSmsStatusApi`, lrDto);
    }

    getPartiesForSMS(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getPartiesForSMSApi`, lrDto);
    }


    sendMultipltSMS(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/sendMultipltSMSApi`, lrDto);
    }

    getBookingTripDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBookingTripDetails`, lrDto);
    }

    updateLocalBkgLrNo(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getUpdateLocalBkgLrNo`, lrDto);
    }
    // basha bh  03042021
    getAutoLrDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getAutoLrDetails`, lrDto);
    }

    getAdvancePaymentDetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getAdvancePaymentDetailsApi`, hireSlipDto);
    }

    getTruckHaltDispatchedDetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getTruckHaltDispatchedDetailsApi`, hireSlipDto);
    }

    lryHireAdvPayment(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/lryHireAdvPaymentApi`, hireSlipDto);
    }

    getFinancialYearForUpload(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/getFinancialYearForUploadApi`, hireSlipDto);
    }

    getTruckRptForTruckDetailsWise(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getTruckRptForTruckDetailsWiseApi`, hireSlipDto);
    }

    getTruckRptForLorryHireWise(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getTruckRptForLorryHireWiseApi`, hireSlipDto);
    }

    getTruckRptForTruckInvoiceWise(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getTruckRptForTruckInvoiceWiseApi`, hireSlipDto);
    }

    getDetailsForValidityExtensionDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getDetailsForValidityExtensionDetailsApi`, lrDto);
    }

    getMethodForExtensionHistoryDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getMethodForExtensionHistoryDetailsApi`, lrDto);
    }

    getTransshipmentTripLrDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getTransshipmentTripLrDetailsApi`, lrDto);
    }

    getOSStocksDestWise(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getOSStocksDestWiseApi`, lrDto);
    }

    getDelayedTripsDashBoard(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getDelayedTripsDashBoardApi`, hireSlipDto);
    }

    getTrackingDetailsFTHireslips(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getTrackingDetailsFTHireslipsApi`, lrDto);
    }

    getTrackingDetailsFT(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getTrackingDetailsFTApi`, lrDto);
    }

    getHireSlipsUnLoadRptLRDetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getHireSlipsUnLoadRptLRDetailsApi`, hireSlipDto);
    }

    getHireslipBarcodeValue(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/getHireslipBarcodeValueApi`, hireSlipDto);
    }

    updateLocalBkgUnloadingDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateLocalBkgUnloadingDetailsApi`, lrDto);
    }

    sendGoodsArrivalWhatsApp(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/goodsArrivalWhatsappApi`, lrDto);
    }

    getHireSlipUnLoadRptDtlsServiceV2(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getHireSlipUnLoadRptDtlsServiceV2Api`, hireSlipDto);
    }

    updateSendToPayHirAmount(invoiceDto: Object): Observable<InvoiceDto> {
        return this.http.post<InvoiceDto>(`${this._baseUrl}/updateSendToPayHirAmountApi`, invoiceDto);
    }

    getEnetHirePaymentDetails(hireSlipDto: Object): Observable<HireSlipDto[]> {
        return this.http.post<HireSlipDto[]>(`${this._baseUrl}/getEnetHirePaymentDetailsApi`, hireSlipDto);
    }

    updateSendToBankHirAmountApi(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/updateSendToBankHirAmountApi`, hireSlipDto);
    }

    updateHirePaymentRejectReason(invoiceDto: Object): Observable<InvoiceDto> {
        return this.http.post<InvoiceDto>(`${this._baseUrl}/updateHirePaymentRejectReasonApi`, invoiceDto);
    }

    validateDestinationLocalTripCleared(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/validateDestinationLocalTripClearedApi`, requestDto);
    }
    checkPanDetailsExists(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/checkPanDetailsExistsApi`, hireSlipDto);
    }

    checkPanAlreadyValidated(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/checkPanDetailsAlreadyValidatedApi`, hireSlipDto);
    }

    getHireslipDetails(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/getHireslipDetailsApi`, hireSlipDto);
    }

    updateHireslipDetails(hireSlipDto: Object): Observable<HireSlipDto> {
        return this.http.post<HireSlipDto>(`${this._baseUrl}/updateHireslipDetailsApi`, hireSlipDto);
    }

    updateTripSheetDepartureDetails(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateTripSheetDepartureDetailsApi`, lrDto);
    }
}