<!--<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu --
        <li class="sidebar-item" [ngClass]="[sidebarnavItem.ddclass]" [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div>
            <a class="sidebar-link waves-effect waves-dark" [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem.title)" (ngChange)="first(sidebarnavItem)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </a>
            <!-- Second level menu --
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0" [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu" [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a class="sidebar-link" [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]" [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;" (click)="addActiveClass(sidebarnavSubItem.title)" (ngChange)="second(sidebarnavSubItem)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title}}</span>
                    </a>
                    <!-- Third level menu --
                    <ul aria-expanded="false" class="collapse second-level" *ngIf="sidebarnavSubItem.submenu.length > 0" [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem.path]" *ngIf="!sidebarnavSubsubItem.extralink;" [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem.title}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>-->


<nav class="sidebar-nav">
    <div class="row">
        <div class="col-md-10">
            <ul id="sidebarnav">
                <!--<li class="nav-item"><a class="nav-link" routerLink="home">Dashboard</a></li>-->
                <li *ngIf="viewDashboardModule" class="sidebar-item" [routerLink]="['/dashboard/classic']"><a
                        class="sidebar-link waves-effect waves-dark" style="color: #fff!important;opacity: 1;"><i
                            class="fas fa-file" style="color: #fff!important;opacity: 1;"></i>Dashboard</a></li>
                <!-- Master Screens Starts Here -->
                <li class="sidebar-item"><a class="sidebar-link waves-effect waves-dark"
                        style="color: #fff!important;opacity: 1;" *ngIf="viewMasterModule"><i class="fas fa-file-alt"
                            style="color: #fff!important;opacity: 1;"></i>Master <span class="drop-icon">▾</span>
                        <label title="Toggle Drop-down" class="drop-icon" for="masterSubmenu">▾</label> </a> <input
                        type="checkbox" id="masterSubmenu">
                    <ul aria-expanded="false" class="collapse sub-menu first-level bg-white">
                        <!-- Communication Starts Here -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCommunicationModule"><i
                                    class="fas fa-file-alt"></i>Communication
                                <span class="drop-icon">▾</span> <label title="Toggle Drop-down" class="drop-icon"
                                    for="communicationSubMenu">▾</label> </a> <input type="checkbox"
                                id="communicationSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/communication/memoMessage']"
                                        *ngIf="viewMessageScreen"><i class="fas fa-file-alt"></i>Message
                                        On Cashmemo</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['masters/communication/sendSms']" *ngIf="viewSendSmsScreen"><i
                                            class="fas fa-file-alt"></i>Send
                                        SMS</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/communication/sendSmsGroupDetails']"
                                        *ngIf="viewSendSmsGroupDtlsScreen"><i class="fas fa-file-alt"></i>Send
                                        SMS Group Details</a></li>
                            </ul>
                        </li>
                        <!-- Communication Ends Here -->
                        <!-- Company Starts Here -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCompanyModule"><i
                                    class="fas fa-file-alt"></i>Company<label title="Toggle Drop-down" class="drop-icon"
                                    for="companySubMenu">▾</label>
                            </a> <input type="checkbox" id="companySubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCommonChargesScr"
                                        [routerLink]="['/masters/company/commonCharges']"><i
                                            class="fas fa-file-alt"></i>Common Charges</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDeveloperPageScr"><i
                                            class="fas fa-file-alt"></i>Developer
                                        Page</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewEditCompanyScr"
                                        [routerLink]="['/masters/company/editCompany']"> <i
                                            class="fas fa-file-alt"></i>Edit Company
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStaffMasterScr"
                                        [routerLink]="['/masters/company/staffMaster']"><i
                                            class="fas fa-file-alt"></i>Loader Name
                                        Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewInchargeMasterScr"
                                        [routerLink]="['/masters/company/inchargeMaster']"><i
                                            class="fas fa-file-alt"></i>Loading Incharge
                                        Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLoaderHeadMasterScr"
                                        [routerLink]="['/masters/company/loaderHeadMaster']"><i
                                            class="fas fa-file-alt"></i>Loader Head
                                        Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStationCreationScr"><i
                                            class="fas fa-file-alt"></i>Station
                                        Creation</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewUserCreationScr"
                                        [routerLink]="['/masters/company/userCreation']"><i
                                            class="fas fa-file-alt"></i>User
                                        Creation</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewUserFeaturesCustomizationScr"
                                        [routerLink]="['/masters/company/userFeaturesCustomization']"><i
                                            class="fas fa-file-alt"></i>User
                                        Features Customization</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewUserDashboardCustomizationScr"><i class="fas fa-file-alt"></i>User
                                        Dashboard Customization</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewUserRightsCustomizationScr"><i class="fas fa-file-alt"></i>User
                                        Rights Customization</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/company/expensesDetailsEntry']"
                                        *ngIf="viewExpensesDetailsEntryScr"><i class="fas fa-file-alt"></i>Expenses
                                        Details Entry</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewConsignmentsClaimSettlementEntryScr"><i
                                            class="fas fa-file-alt"></i>Consignments Claim Settlement Entry</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewLRDeleteMasterPwdSetup"
                                        [routerLink]="['/masters/company/lrDeletePwdSetup']"> <i
                                            class="fas fa-file-alt"></i>LR Delete Master Password Setup
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewPartywisePerformanceMonitorScr"
                                        [routerLink]="['/masters/company/partywisePerformanceMonitor']"> <i
                                            class="fas fa-file-alt"></i>Partywise Performance Monitor
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <!-- Company Ends Here -->
                        <!-- Consignee Starts Here -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewConsigneeModule"><i
                                    class="fas fa-file-alt"></i>Consignee<label title="Toggle Drop-down"
                                    class="drop-icon" for="consigneeSubMenu">▾</label>
                            </a> <input type="checkbox" id="consigneeSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCneeAdminRateMasterScr"
                                        [routerLink]="['/masters/consignee/consigneeAdminRateMaster']"><i
                                            class="fas fa-file-alt"></i>C/nee Admin Rate Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCneeGroupingScr"><i
                                            class="fas fa-file-alt"></i>C/nee
                                        Grouping</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewConsigneeMasterScr"
                                        [routerLink]="['/masters/consignee/consigneeMaster']"> <i
                                            class="fas fa-file-alt"></i>Consignee Master
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewConsigneeMergeScr"
                                        [routerLink]="['/masters/consignee/consigneeMerge']"><i
                                            class="fas fa-file-alt"></i>Consignee Merge</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewConsigneeMobileNoConfirmScr"
                                        [routerLink]="['/masters/consignee/consigneeMobileNoConfirm']"><i
                                            class="fas fa-file-alt"></i>Consignee Mobile Number Confirm</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewRateMasterScr"
                                        [routerLink]="['/masters/consignee/rateMaster']"><i
                                            class="fas fa-file-alt"></i>Rate
                                        Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewConsigneeServiceTaxExpScr"><i class="fas fa-file-alt"></i>Consignee
                                        Service Tax Exemption</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewEdeclarationPasswordScr"><i
                                            class="fas fa-file-alt"></i>E-Declaration Password</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewSpecificConsigneeDetailsScr"
                                        [routerLink]="['/masters/consignee/specificConsigneeDetails']"> <i
                                            class="fas fa-file-alt"></i>Specific Consignee Details
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <!-- Consignee Ends Here -->
                        <!-- Consignor Starts Here -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewConsignorModule"><i
                                    class="fas fa-file-alt"></i>Consignor<label title="Toggle Drop-down"
                                    class="drop-icon" for="consignorSubMenu">▾</label>
                            </a> <input type="checkbox" id="consignorSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/consignor/consignorMaster']"
                                        *ngIf="viewConsignorMasterScr"><i class="fas fa-file-alt"></i>Consignor
                                        Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/consignor/consignorMerge']"
                                        *ngIf="viewConsignorMergeScr"><i class="fas fa-file-alt"></i>Consignor
                                        Merge</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewConsignorMobileNoConfirmScr"
                                        [routerLink]="['/masters/consignor/consignorMobileNoConfirm']"><i
                                            class="fas fa-file-alt"></i>Consignor Mobile Number Confirm</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewConsignorServiceTaxExpScr"><i class="fas fa-file-alt"></i>Consignor
                                        Service Tax Exemption</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewConsignorAdminRateMasterScr"
                                        [routerLink]="['/masters/consignor/consignorRateMaster']"><i
                                            class="fas fa-file-alt"></i>Consignor & LR Entry Rate Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/consignor/consignorTopartyMaster']"
                                        *ngIf="viewConsignorTopartyMasterScr"><i class="fas fa-file-alt"></i>Consignor
                                        Toparty
                                        Master</a></li>
                            </ul>
                        </li>
                        <!-- Consignor Ends Here -->
                        <!-- Details Starts Here -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDetailsModule"><i
                                    class="fas fa-file-alt"></i>Details<label title="Toggle Drop-down" class="drop-icon"
                                    for="detailsSubMenu">▾</label>
                            </a> <input type="checkbox" id="detailsSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <!-- <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDestinationAgentScr"><i
                                            class="fas fa-file-alt"></i>Destination
                                        Agent</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewBookingAgentScr"><i
                                            class="fas fa-file-alt"></i>Booking
                                        Agent</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDestinationTypeScr"><i
                                            class="fas fa-file-alt"></i>Destination
                                        Type</a></li> -->
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewAgentSubstationMasterScr"
                                        [routerLink]="['/masters/details/agentSubstationMaster']"><i
                                            class="fas fa-file-alt"></i>Agent Substation
                                        Master</a></li>
                            </ul>
                        </li>
                        <!-- Details ends Here -->
                        <!-- Fixing Starts Here -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewFixingModule"><i
                                    class="fas fa-file-alt"></i>Fixing<label title="Toggle Drop-down" class="drop-icon"
                                    for="fixingSubMenu">▾</label>
                            </a> <input type="checkbox" id="fixingSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewAgentFeatureCusmtScr"><i
                                            class="fas fa-file-alt"></i>Agent
                                        Features Customization</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewAreaScr"
                                        [routerLink]="['/masters/fixing/areaMaster']"><i
                                            class="fas fa-file-alt"></i>Area</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewBankScr"
                                        [routerLink]="['/masters/fixing/bankMaster']"><i
                                            class="fas fa-file-alt"></i>Bank</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCollectionScr"
                                        [routerLink]="['/masters/fixing/collectionMan']"><i
                                            class="fas fa-file-alt"></i>Collection</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDebitNoteMasterScr"><i
                                            class="fas fa-file-alt"></i>Debit
                                        Note Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCflFormulaSetupScr"
                                        [routerLink]="['/masters/fixing/cflFormulaSetup']"><i
                                            class="fas fa-file-alt"></i>CFT Formula Setup</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCftExemptionSetupScr"
                                        [routerLink]="['/masters/fixing/cftExemptionSetup']"><i
                                            class="fas fa-file-alt"></i>CFT Exemption Setup</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewExpensesHeadsScr"><i
                                            class="fas fa-file-alt"></i>Expenses
                                        Heads</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStandardRateScr"><i
                                            class="fas fa-file-alt"></i>Standard
                                        Rate</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/fixing/fovExemptionMaster']"
                                        *ngIf="viewFovExemptionMasterForBkgScr"><i class="fas fa-file-alt"></i>FOV
                                        Exemption Master for Booking</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/fixing/tdsDetailsEntry']"
                                        *ngIf="viewTdsDetailsEntryScr"><i class="fas fa-file-alt"></i>TDS
                                        Details Entry</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewUnloadingChrgAmtDtlsScr"><i
                                            class="fas fa-file-alt"></i>Unloading Charge Amount Details</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrBkgDateCustomChangeScr"
                                        [routerLink]="['/masters/fixing/lrBkgDateCustomChange']"><i
                                            class="fas fa-file-alt"></i>LR Booking Date - Custom Change</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPerKgRateSetupScr"
                                        [routerLink]="['/masters/fixing/perKgRateSetup']"><i
                                            class="fas fa-file-alt"></i>Per Kg Rate Setup</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCommodityKgLimitSetupScr"
                                        [routerLink]="['/masters/fixing/commodityKgLimitSetup']"><i
                                            class="fas fa-file-alt"></i>Commodity Kg Limit Setup</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrBlockedPartywiseScr"
                                        [routerLink]="['/masters/fixing/lrBlockedPartywise']"><i
                                            class="fas fa-file-alt"></i>LR Blocked Partywise</a></li>
                            </ul>
                        </li>
                        <!-- Fixing Ends Here -->
                        <!-- Supplier Starts Here -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewSupplierModule"><i
                                    class="fas fa-file-alt"></i>Supplier<label title="Toggle Drop-down"
                                    class="drop-icon" for="supplierSubMenu">▾</label>
                            </a> <input type="checkbox" id="supplierSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewBalanceDtlsScr"><i
                                            class="fas fa-file-alt"></i>Balance
                                        Details</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewSupplierMasterScr"
                                        [routerLink]="['/masters/supplier/suppliermaster']"><i
                                            class="fas fa-file-alt"></i>Supplier Master</a></li>
                            </ul>
                        </li>
                        <!-- Supplier Ends Here -->
                        <!-- Transhipment Starts Here -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTranshipmentModule"><i
                                    class="fas fa-file-alt"></i>Transhipment<label title="Toggle Drop-down"
                                    class="drop-icon" for="transhipmentSubMenu">▾</label> </a> <input type="checkbox"
                                id="transhipmentSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewDriverMasterScr"
                                        [routerLink]="['/masters/transhipment/driverMaster']"> <i
                                            class="fas fa-file-alt"></i>Driver Master
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/transhipment/getHireslipBarcodeValue']"
                                        *ngIf="viewGetHireslipBarcodeValueScr"><i class="fas fa-file-alt"></i>Get
                                        Hireslip Barcode Value</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/transhipment/getAdvanceConsent']"
                                        *ngIf="viewAdvanceConsentProcessScr"><i class="fas fa-file-alt"></i>
                                        Trip Advance Consent</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/transhipment/lorryHireDeductionSetupMaster']"
                                        *ngIf="viewLorryHireDeductionSetupScr"><i class="fas fa-file-alt"></i>Lorry Hire
                                        Deduction Setup</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewNatureOfPackScr"
                                        [routerLink]="['/masters/transhipment/natureOfPack']"> <i
                                            class="fas fa-file-alt"></i>Nature Of Pack
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/transhipment/pointToPointServiceCheck']"
                                        *ngIf="viewPointToPointSerChkScr"><i class="fas fa-file-alt"></i>Point
                                        To Point Service Check</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTruckMasterScr"
                                        [routerLink]="['/masters/transhipment/truckMaster']"><i
                                            class="fas fa-file-alt"></i>Truck Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewVehicleSetupMasterScr"
                                        [routerLink]="['/masters/transhipment/vehicleSetupMaster']"><i
                                            class="fas fa-file-alt"></i>Vehicle
                                        Setup Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewVehicleDDChargeSetup"
                                        [routerLink]="['/masters/transhipment/vehicleAreaChargeSetup']"><i
                                            class="fas fa-file-alt"></i>Vehicle
                                        DD Charge Setup</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf=viewAgentTempoMasterScr
                                        [routerLink]="['/masters/transhipment/agentTempoMaster']"><i
                                            class="fas fa-file-alt"></i>Agent Tempo Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/transhipment/expensesPaymentFollowupReport']"
                                        *ngIf="viewExpensesPaymentFollowupScr"><i class="fas fa-file-alt"></i>Expenses
                                        Payment Followup</a></li>

                                <!-- 						<li class="sidebar-item"><a class="sidebar-link" -->
                                <!-- 							*ngIf="viewConsigmentLiveTrackingScr"><i -->
                                <!-- 								class="fas fa-file-alt"></i>Consigment Live Tracking</a></li> -->
                                <!-- 						<li class="sidebar-item"><a class="sidebar-link" -->
                                <!-- 							*ngIf="viewTrackingLiveReportScr"><i class="fas fa-file-alt"></i>Tracking -->
                                <!-- 								Live Report</a></li> -->

                            </ul>
                        </li>
                        <!-- Transhipment Ends Here -->
                        <!-- Trip Starts Here -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTripModule"><i
                                    class="fas fa-file-alt"></i>Trip<label title="Toggle Drop-down" class="drop-icon"
                                    for="tripSubMenu">▾</label>
                            </a> <input type="checkbox" id="tripSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewRoutingFeatureScr"><i
                                            class="fas fa-file-alt"></i>Routing
                                        Feature</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" [routerLink]="['/masters/trip/time']"
                                        *ngIf="viewTimeScr"><i class="fas fa-file-alt"></i>Reward Time Setup</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTruckCapacityScr"><i
                                            class="fas fa-file-alt"></i>Truck
                                        Capacity</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/trip/tripEtaSetup']" *ngIf="viewTripEtaSetupScr"><i
                                            class="fas fa-file-alt"></i>Trip
                                        ETA Setup</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLiveTrack"
                                        [routerLink]="['/report/others-report/vehicleTracking']"><i
                                            class="fas fa-file-alt"></i>Live Tracking</a></li>

                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLiveTrackReport"
                                        [routerLink]="['/masters/transhipment/trackingLiveReport']"><i
                                            class="fas fa-file-alt"></i>Tracking Report</a></li>


                            </ul>
                        </li>
                        <!-- Trip Ends Here -->
                        <!-- Commodity Starts Here -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCommodityModule"><i
                                    class="fas fa-file-alt"></i>Commodity<label title="Toggle Drop-down"
                                    class="drop-icon" for="commoditySubMenu">▾</label>
                            </a> <input type="checkbox" id="commoditySubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/commodity/commodityMaster']"
                                        *ngIf="viewCommodityMasterScr"><i class="fas fa-file-alt"></i>Commodity
                                        Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCommodityRateMasterScr"><i
                                            class="fas fa-file-alt"></i>Rate
                                        Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTpMasterScr"><i
                                            class="fas fa-file-alt"></i>TP
                                        Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewUnloadingRateScr"><i
                                            class="fas fa-file-alt"></i>Unloading
                                        Rate</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCommodityRoutingScr"><i
                                            class="fas fa-file-alt"></i>Commodity
                                        Routing</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/commodity/commodityMerge']"
                                        *ngIf="viewCommodityMergeScr"><i class="fas fa-file-alt"></i>Commodity
                                        Merge</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/masters/commodity/commodityBookingBlock']"
                                        *ngIf="viewCommodityBookingBlockScr"><i class="fas fa-file-alt"></i>Commodity
                                        Booking Block</a></li>
                            </ul>
                        </li>
                        <!-- Commodity Ends Here -->
                    </ul>
                </li>
                <!-- Master Screens Ends Here -->
                <!-- Cashmemo Screens Starts Here -->
                <li class="sidebar-item"><a class="sidebar-link waves-effect waves-dark"
                        style="color: #fff!important;opacity: 1;" *ngIf="viewCashmemoModule"><i class="fas fa-file-alt"
                            style="color: #fff!important;opacity: 1;"></i>Cashmemo<span class="drop-icon">▾</span>
                        <label title="Toggle Drop-down" class="drop-icon" for="cashmemoSubmenu">▾</label> </a> <input
                        type="checkbox" id="cashmemoSubmenu">
                    <ul aria-expanded="false" class="collapse sub-menu first-level bg-white">
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewGenerationModule"><i
                                    class="fas fa-file-alt"></i>Generation<label title="Toggle Drop-down"
                                    class="drop-icon" for="cashgenerationSubMenu">▾</label> </a> <input type="checkbox"
                                id="cashgenerationSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewBookingCashMemoScreen"
                                        [routerLink]="['/cashmemo/generation/bookingCashmemo']"><i
                                            class="fas fa-file-alt"></i>Booking Cash Memo</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewGenerateCashMemoScreen"
                                        [routerLink]="['/cashmemo/generation/generateCashmemo']"><i
                                            class="fas fa-file-alt"></i>Generate Cash Memo</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDummyMemo"
                                        [routerLink]="['/cashmemo/generation/dummyCashmemo']"><i
                                            class="fas fa-file-alt"></i>Dummy</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTransferModule"><i
                                    class="fas fa-file-alt"></i>Transfer<label title="Toggle Drop-down"
                                    class="drop-icon" for="cashTransferSubMenu">▾</label> </a> <input type="checkbox"
                                id="cashTransferSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewBillingToSrdCollectionStockScr"
                                        [routerLink]="['/cashmemo/transfer-cashmemo/billingToSrdCollectionStock']"><i
                                            class="fas fa-file-alt"></i>Billing To SRD Collection Stock</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCashmemoTransferScr"
                                        [routerLink]="['/cashmemo/transfer-cashmemo/cashmemoTransfer']"><i
                                            class="fas fa-file-alt"></i>Cashmemo Transfer</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTransferByLrScr"><i
                                            class="fas fa-file-alt"></i>Transfer
                                        By LR</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewManualCashmemoCheckScr"
                                        [routerLink]="['/cashmemo/transfer-cashmemo/manualCashmemoCheck']"><i
                                            class="fas fa-file-alt"></i>Manual Cashmemo Check</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTransferToCollectionScr"
                                        [routerLink]="['/cashmemo/transfer-cashmemo/transferToCollection']"><i
                                            class="fas fa-file-alt"></i>Transfer To Collection</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewCollectionManStockTransferScr"
                                        [routerLink]="['/cashmemo/transfer-cashmemo/collectionManStockTransfer']"><i
                                            class="fas fa-file-alt"></i>Collection Man Stock Transfer</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewColtManToColtManStockTransferScr"
                                        [routerLink]="['/cashmemo/transfer-cashmemo/collectionManToCollectionManStockTransfer']"><i
                                            class="fas fa-file-alt"></i>Collection Man To Collection Man
                                        Stock Transfer</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewChartsScr"><i
                                            class="fas fa-file-alt"></i>Charts</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewCollectionManCashmemoStockCheckScr"
                                        [routerLink]="['/cashmemo/transfer-cashmemo/collectionManCashmemoStockCheck']"><i
                                            class="fas fa-file-alt"></i>Collection Man Cashmemo Stock Check</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPaymentFollowupScr"
                                [routerLink]="['/cashmemo/paymentFollowup']"><i class="fas fa-file-alt"></i>Payment
                                Followup</a></li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPaymentFollowupBookingScr"
                                [routerLink]="['/cashmemo/paymentFollowupBooking']"><i
                                    class="fas fa-file-alt"></i>Booking Payment
                                Followup</a></li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCashMemoBlockScr"
                                [routerLink]="['/cashmemo/cashmemoBlock']"><i class="fas fa-file-alt"></i>Cash Memo
                                Block</a></li>
                    </ul>
                </li>
                <!-- Cashmemo Screens Ends Here -->

                <!-- Fornight Statement Screens Starts Here -->
                <li class="sidebar-item"><a class="sidebar-link waves-effect waves-dark"
                        style="color: #fff!important;opacity: 1;" *ngIf="viewFortnightStatementModule"><i
                            class="fas fa-file-alt" style="color: #fff!important;opacity: 1;"></i>Fortnight
                        Statement<span class="drop-icon">▾</span> <label title="Toggle Drop-down" class="drop-icon"
                            for="fortSubmenu">▾</label>
                    </a> <input type="checkbox" id="fortSubmenu">
                    <ul aria-expanded="false" class="collapse sub-menu first-level bg-white">
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewFortngtStmtAgentModule"><i
                                    class="fas fa-file-alt"></i>Agent<label title="Toggle Drop-down" class="drop-icon"
                                    for="fortAgentSubMenu">▾</label>
                            </a> <input type="checkbox" id="fortAgentSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCommissionMasterScr"
                                        [routerLink]="['/fortnightStatement/agent/commissionmaster']"><i
                                            class="fas fa-file-alt"></i>Commission Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/fortnightStatement/agent/commodityratemaster']"
                                        *ngIf="viewFortngtStmtCommodityRateMasterScr"><i
                                            class="fas fa-file-alt"></i>Commodity Rate Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewAmountReceivableFromAgentScr"
                                        [routerLink]="['/fortnightStatement/agent/amountReceivableFromAgent']"><i
                                            class="fas fa-file-alt"></i>Amount Receivable From Agent</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewDestinationAgentStmtGenerationScr"
                                        [routerLink]="['/fortnightStatement/agent/destinationAgentStatementGeneration']"><i
                                            class="fas fa-file-alt"></i>Destination Agent Statement
                                        Generation</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewFortngtStmtBookingModule"><i
                                    class="fas fa-file-alt"></i>Booking<label title="Toggle Drop-down" class="drop-icon"
                                    for="forBkgSubMenu">▾</label>
                            </a> <input type="checkbox" id="forBkgSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewAgentCommissionMasterScr"
                                        [routerLink]="['/fortnightStatement/booking/agentCommissionMaster']"><i
                                            class="fas fa-file-alt"></i>Agent Commision Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewAgentStatementGenerationScr"
                                        [routerLink]="['/fortnightStatement/booking/agentStatementGeneration']"><i
                                            class="fas fa-file-alt"></i>Agent Statement Generation</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewMemoInvoiceGenerationScr"><i
                                            class="fas fa-file-alt"></i>Memo Invoice Generation</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewFortngtStmtHamaliModule"><i
                                    class="fas fa-file-alt"></i>Hamali<label title="Toggle Drop-down" class="drop-icon"
                                    for="forHamaliSubMenu">▾</label>
                            </a> <input type="checkbox" id="forHamaliSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewLabourCommissionSetupMumbaiScr"><i class="fas fa-file-alt"></i>Labour
                                        Commission Setup Mumbai</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewSrdLabourMasterScr"
                                        [routerLink]="['/fortnightStatement/hamali-fortnightStatement/srdlabourmaster']"><i
                                            class="fas fa-file-alt"></i>SRD Labour Master</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewSrdLabourStmtGenerationScr"
                                        [routerLink]="['/fortnightStatement/hamali-fortnightStatement/srdLabourStatementGeneration']"><i
                                            class="fas fa-file-alt"></i>SRD Labour Stmt Generation</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewFortngtStmtOthersModule"><i
                                    class="fas fa-file-alt"></i>Others<label title="Toggle Drop-down" class="drop-icon"
                                    for="fortOthersSubMenu">▾</label>
                            </a> <input type="checkbox" id="fortOthersSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPartyLessCheckScr"
                                        [routerLink]="['/fortnightStatement/others/partyLessCheck']"><i
                                            class="fas fa-file-alt"></i>Party Less Details</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewBalancingDifferenceTallyRptScr"
                                        [routerLink]="['/fortnightStatement/others/balancingDifferenceTallyReport']"><i
                                            class="fas fa-file-alt"></i>Balancing Difference Tally Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDebitNoteStmtScr"
                                        [routerLink]="['fortnightStatement/others/debitNoteStmt']"><i
                                            class="fas fa-file-alt"></i>Debit
                                        Note Stmt</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrCrossCheckScr"><i
                                            class="fas fa-file-alt"></i>LR
                                        Cross Check</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPartyLessCheckUploadTypeScr"
                                        [routerLink]="['/fortnightStatement/others/partyLessCheckUploadType']"><i
                                            class="fas fa-file-alt"></i>Party Less Check - Upload Type</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <!-- Fornight Statement Screens Ends Here -->

                <!-- LR Screens Starts Here -->
                <li class="sidebar-item"><a class="sidebar-link waves-effect waves-dark"
                        style="color: #fff!important;opacity: 1;" *ngIf="viewLrModule"><i class="fas fa-file-alt"
                            style="color: #fff!important;opacity: 1;"></i>LR<span class="drop-icon">▾</span> <label
                            title="Toggle Drop-down" class="drop-icon" for="lrSubmenu">▾</label>
                    </a> <input type="checkbox" id="lrSubmenu">
                    <ul aria-expanded="false" class="collapse sub-menu first-level bg-white">
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewEditModule"><i
                                    class="fas fa-file-alt"></i>Edit<label title="Toggle Drop-down" class="drop-icon"
                                    for="lrEditSubMenu">▾</label>
                            </a> <input type="checkbox" id="lrEditSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/lr/edit/invoicelredit']" *ngIf="viewInvoieLrEditScr"><i
                                            class="fas fa-file-alt"></i>Invoice
                                        LR Edit</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" [routerLink]="['/lr/edit/lredit']"
                                        *ngIf="viewLrEditScr"><i class="fas fa-file-alt"></i>LR Edit</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrsShortAndExtraScr"
                                        [routerLink]="['/lr/edit/lrsShortAndExtra']"><i class="fas fa-file-alt"></i>LRs
                                        Short & Extra</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrHoldReasonScr"
                                        [routerLink]="['/lr/edit/lrHoldReason']"><i class="fas fa-file-alt"></i>LR Hold
                                        Reason Entry</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewSalesTaxLrScr"><i
                                            class="fas fa-file-alt"></i>Sales
                                        Tax LR</a></li>



                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewEditLrFreightScr"
                                        [routerLink]="['/lr/edit/editLrFreight']"><i class="fas fa-file-alt"></i>Edit LR
                                        Freight</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewEnquiryModule"><i
                                    class="fas fa-file-alt"></i>Enquiry<label title="Toggle Drop-down" class="drop-icon"
                                    for="lrEnqrySubMenu">▾</label>
                            </a> <input type="checkbox" id="lrEnqrySubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewConsigneeDetailsScr"><i
                                            class="fas fa-file-alt"></i>Consignee
                                        Details</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrDeliveryStatusScr"><i
                                            class="fas fa-file-alt"></i>LR
                                        Delivery Status</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrAdvanceScr"
                                        [routerLink]="['/lr/enquiry/lrAdvance']"><i class="fas fa-file-alt"></i>LR
                                        Advance</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrEnquiryFormScr"
                                        [routerLink]="['/lr/enquiry/lrEnquiry']"><i class="fas fa-file-alt"></i>LR
                                        Enquiry Form</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrInSalesTaxScr"><i
                                            class="fas fa-file-alt"></i>LR
                                        in Sales Tax</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewGstSearchScr"
                                        [routerLink]="['/lr/enquiry/gstSearch']"><i class="fas fa-file-alt"></i>GST
                                        Search</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewGenNewEwbScr"
                                        [routerLink]="['/lr/enquiry/generateNewEwb']"><i
                                            class="fas fa-file-alt"></i>Generate New EWB</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewGstInvoiceDetainedScr"
                                        [routerLink]="['/lr/edit/gstInvoiceDetained']"><i
                                            class="fas fa-file-alt"></i>GST Invoice Detained </a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewEntryModule"><i
                                    class="fas fa-file-alt"></i>Entry<label title="Toggle Drop-down" class="drop-icon"
                                    for="lrEntrySubMenu">▾</label>
                            </a> <input type="checkbox" id="lrEntrySubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrEntryFormScr"
                                        [routerLink]="['/lr/entry/lrentry']"><i class="fas fa-file-alt"></i>LR Entry
                                        Form</a></li>
                                <!-- <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['lr/entry/lrEntryFormBarcode']"><i class="fas fa-file-alt"></i>Barcode Generate</a></li> -->

                                <li class="sidebar-item"><a class="sidebar-link" [routerLink]="['/lr/entry/missAgent']"
                                        *ngIf="viewMissAgentScr"><i class="fas fa-file-alt"></i>Miss Agent</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/lr/entry/enablelrcccopy']" *ngIf="viewEnableCCLRScr"><i
                                            class="fas fa-file-alt"></i>Enable CC Copy In LR Entry</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewGenerateBarcodeScr"
                                        [routerLink]="['/lr/entry/generateBarcode']"><i
                                            class="fas fa-file-alt"></i>Generate Barcode</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewReGenerateBarcodeScr"
                                        [routerLink]="['/lr/entry/reGenerateBarcode']"><i class="fas fa-file-alt"></i>Re
                                        Generate Barcode</a></li>

                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewIssueModule"><i
                                    class="fas fa-file-alt"></i>Issue<label title="Toggle Drop-down" class="drop-icon"
                                    for="lrIssueSubMenu">▾</label>
                            </a> <input type="checkbox" id="lrIssueSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" [routerLink]="['/lr/issue/challan']"
                                        *ngIf="viewChallanScr"><i class="fas fa-file-alt"></i>Challan</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" [routerLink]="['/lr/issue/lrissue']"
                                        *ngIf="viewLrIssueScr"><i class="fas fa-file-alt"></i>LR Issue</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <!-- LR Screens Ends Here -->

                <!-- Mobile App Screens Starts Here -->
                <li class="sidebar-item"><a class="sidebar-link waves-effect waves-dark"
                        style="color: #fff!important;opacity: 1;" *ngIf="viewMobileAppModule"><i class="fas fa-file-alt"
                            style="color: #fff!important;opacity: 1;"></i>Mobile
                        App<span class="drop-icon">▾</span> <label title="Toggle Drop-down" class="drop-icon"
                            for="maSubmenu">▾</label>
                    </a> <input type="checkbox" id="maSubmenu">
                    <ul aria-expanded="false" class="collapse sub-menu first-level bg-white">
                        <li class="sidebar-item"><a class="sidebar-link" [routerLink]="['/mobileApps/aboutus']"
                                *ngIf="viewAboutUsScr"><i class="fas fa-file-alt"></i>About Us</a></li>
                        <li class="sidebar-item"><a class="sidebar-link" [routerLink]="['/mobileApps/latestnews']"
                                *ngIf="viewLatestNewsScr"><i class="fas fa-file-alt"></i>Latest News</a></li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewMobileAppUserRptScr"><i
                                    class="fas fa-file-alt"></i>Mobile
                                Apps User Report</a></li>
                        <li class="sidebar-item"><a class="sidebar-link" [routerLink]="['/mobileApps/ourbranches']"
                                *ngIf="viewOurBranchesScr"><i class="fas fa-file-alt"></i>Our
                                Branches</a></li>
                        <li class="sidebar-item"><a class="sidebar-link" [routerLink]="['/mobileApps/routeMapping']"
                                *ngIf="viewRouteMappingScr"><i class="fas fa-file-alt"></i>Route
                                Mapping</a></li>
                    </ul>
                </li>
                <!-- Mobile App Screens Ends Here -->

                <!-- Stocks Screens Ends Here -->
                <li class="sidebar-item"><a class="sidebar-link waves-effect waves-dark"
                        style="color: #fff!important;opacity: 1;" *ngIf="viewStocksModule"><i class="fas fa-file-alt"
                            style="color: #fff!important;opacity: 1;"></i>Stocks<span class="drop-icon">▾</span>
                        <label title="Toggle Drop-down" class="drop-icon" for="stkSubmenu">▾</label>
                    </a> <input type="checkbox" id="stkSubmenu">
                    <ul aria-expanded="false" class="collapse sub-menu first-level bg-white">
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCustomizationModule"><i
                                    class="fas fa-file-alt"></i>Customization<label title="Toggle Drop-down"
                                    class="drop-icon" for="stkCustomSubMenu">▾</label>
                            </a> <input type="checkbox" id="stkCustomSubMenu">
                            <ul aria-expanded="false" class="collapse second-level bg-white" style="left: -220px;">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewManualStockCheckScr"
                                        [routerLink]="['/stock/customization/manualStockCheck']"><i
                                            class="fas fa-file-alt"></i>Manual Stock Check</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStocksGroupingScr"
                                        [routerLink]="['/trip-creation/stocksGrouping']"><i
                                            class="fas fa-file-alt"></i>Stocks Grouping</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewloadingInvoiceEditScr"
                                        [routerLink]="['/trip-creation/loadingInvoiceEdit']"><i
                                            class="fas fa-file-alt"></i>Loading Invoice Edit</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDriverModule"><i
                                    class="fas fa-file-alt"></i>Driver<label title="Toggle Drop-down" class="drop-icon"
                                    for="stkDriverSubMenu">▾</label>
                            </a> <input type="checkbox" id="stkDriverSubMenu">
                            <ul aria-expanded="false" class="collapse second-level bg-white" style="left: -220px;">

                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewRewardingScr"
                                        [routerLink]="['/stock/driver/rewarding']"><i
                                            class="fas fa-file-alt"></i>Rewarding</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewRewardingNewVersionScr"
                                        [routerLink]="['/stock/driver/rewardingNewVersion']"><i
                                            class="fas fa-file-alt"></i>Rewarding</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewBarcodeScanningScr"><i
                                            class="fas fa-file-alt"></i>Barcode
                                        Scanning</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPaymentsModule"><i
                                    class="fas fa-file-alt"></i>Payments<label title="Toggle Drop-down"
                                    class="drop-icon" for="stkPaySubMenu">▾</label>
                            </a> <input type="checkbox" id="stkPaySubMenu">
                            <ul aria-expanded="false" class="collapse second-level bg-white" style="left: -220px;">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewHireslipAdvanceScr"><i
                                            class="fas fa-file-alt"></i>Hireslip
                                        Advance</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewHireslipBalanceScr"
                                        [routerLink]="['/stock/payment/hireslipBalance']"><i
                                            class="fas fa-file-alt"></i>HireSlip Balance</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStocksTransferModule"><i
                                    class="fas fa-file-alt"></i>Transfer<label title="Toggle Drop-down"
                                    class="drop-icon" for="stkTransferSubMenu">▾</label> </a> <input type="checkbox"
                                id="stkTransferSubMenu">
                            <ul aria-expanded="false" class="collapse second-level bg-white" style="left: -220px;">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewShipmentStatusScr"
                                        [routerLink]="['/stock/transfer/shipmentstatus']"><i
                                            class="fas fa-file-alt"></i>Shipment Status</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStocksForTripsheetScr"
                                        [routerLink]="['/stocks-for-tripsheet-page/stocksForTripsheet']"><i
                                            class="fas fa-file-alt"></i>Stocks For Tripsheet</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStocksInTakenScr"><i
                                            class="fas fa-file-alt"></i>Stocks
                                        In Taken</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStockstoCPScr"
                                        [routerLink]="['/stock/transfer/stocksToCp']"><i
                                            class="fas fa-file-alt"></i>Stocks to CP</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStocksBetweenGodownsScr"
                                        [routerLink]="['/stock/transfer/stocksBetweenGodown']"><i
                                            class="fas fa-file-alt"></i>Stocks Between Godowns</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStocksToGodownScr"><i
                                            class="fas fa-file-alt"></i>Stocks
                                        To Godown</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStocksForLocalBookingScr"
                                        [routerLink]="['/stock/transfer/stockForLocalBooking']"><i
                                            class="fas fa-file-alt"></i>Stocks For Local Booking</a></li>

                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewStocksForLocalBookingBarcodeScr"
                                        [routerLink]="['/stock/transfer/stockForLocalBookingBarcode']"><i
                                            class="fas fa-file-alt"></i>Booking Local Trip Barcode</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewUnclearedLrDetailsScr"
                                        [routerLink]="['/stock/transfer/unclearedLrDetails']"><i
                                            class="fas fa-file-alt"></i>Uncleared Lr Details</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <!-- Stocks Screens Ends Here -->

                <!-- Report Screens Ends Here -->
                <li class="sidebar-item"><a class="sidebar-link waves-effect waves-dark"
                        style="color: #fff!important;opacity: 1;" *ngIf="viewReportModule"><i class="fas fa-file-alt"
                            style="color: #fff!important;opacity: 1;"></i>Reports<span class="drop-icon">▾</span>
                        <label title="Toggle Drop-down" class="drop-icon" for="rptSubmenu">▾</label>
                    </a> <input type="checkbox" id="rptSubmenu">
                    <ul aria-expanded="false" class="collapse sub-menu first-level bg-white">
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewRptAgentsModule"><i
                                    class="fas fa-file-alt"></i>Agents<label title="Toggle Drop-down" class="drop-icon"
                                    for="rptAgentSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptAgentSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/agents/ranking']" *ngIf="viewRankingScr"><i
                                            class="fas fa-file-alt"></i>Ranking</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStatementVerificationScr"
                                        [routerLink]="['/report/agents/statementVerification']"><i
                                            class="fas fa-file-alt"></i> Statement Verification</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDestCommissionScr"
                                        [routerLink]="['/report/agents/destinationCommission']"><i
                                            class="fas fa-file-alt"></i>Destination
                                        Commission</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDestIncomeScr"
                                        [routerLink]="['/report/agents/destinationIncome']"><i
                                            class="fas fa-file-alt"></i>Destination
                                        Income</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewBookingModule"><i
                                    class="fas fa-file-alt"></i>Booking<label title="Toggle Drop-down" class="drop-icon"
                                    for="rptBkgSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptBkgSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/bookings/agentExpenses']"
                                        *ngIf="viewAgentExpensesScr"><i class="fas fa-file-alt"></i>Agent
                                        Expenses</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/bookings/bookingAgentReport']"
                                        *ngIf="viewBookingAgentRptScr"><i class="fas fa-file-alt"></i>Booking
                                        Agent Rpt</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewAgentStatementScr"
                                        [routerLink]="['/report/bookings/agentStatement']"><i
                                            class="fas fa-file-alt"></i>Agent Statement</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/bookings/bkgAgentTdsLdg']"
                                        *ngIf="viewAgentTDSOnLoadingScr"><i class="fas fa-file-alt"></i>Agent
                                        TDS On Loading</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewMemoInvoiceScr"><i
                                            class="fas fa-file-alt"></i>Memo
                                        Invoice</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewMemoScr"><i
                                            class="fas fa-file-alt"></i>Memo</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewOfficeDailyExpensesScr"><i
                                            class="fas fa-file-alt"></i>Office
                                        Daily Expenses</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/bookings/officeHireslip']"
                                        *ngIf="viewOfficeHireslipScr"><i class="fas fa-file-alt"></i>Office
                                        Hireslip</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewAgentLocalTripScr"
                                        [routerLink]="['/report/bookings/agentLocalTrip']"><i
                                            class="fas fa-file-alt"></i>Agent Local Trip</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCashmemoRptModule"><i
                                    class="fas fa-file-alt"></i>Cashmemo<label title="Toggle Drop-down"
                                    class="drop-icon" for="rptCashSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptCashSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewAmountReceivableScr"
                                        [routerLink]="['/report/cashmemo-report/amountreceivable']"><i
                                            class="fas fa-file-alt"></i> Amount Receivable</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCollectionManScr"
                                        [routerLink]="['/report/cashmemo-report/collectionManReport']"><i
                                            class="fas fa-file-alt"></i>Collection Man</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewSRDCollectionStockRptScr"
                                        [routerLink]="['/report/cashmemo-report/srdCollectionStockReport']">
                                        <i class="fas fa-file-alt"></i>SRD Collection Stock Report
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPaidReportScr"
                                        [routerLink]="['/report/cashmemo-report/paidreport']"><i
                                            class="fas fa-file-alt"></i>Paid Report</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewCashMemoReportScr"
                                        [routerLink]="['/report/cashmemo-report/cashmemoreport']"> <i
                                            class="fas fa-file-alt"></i>Cash Memo Report
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewCashmemoDetailsScr"
                                        [routerLink]="['/report/cashmemo-report/cashmemoDetails']"> <i
                                            class="fas fa-file-alt"></i>Cashmemo Details
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLetterHeadReportScr"
                                        [routerLink]="['/report/cashmemo-report/letterHeadReport']"><i
                                            class="fas fa-file-alt"></i>LetterHead Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewToBeBilledPartyScr"><i
                                            class="fas fa-file-alt"></i>To
                                        Be BIlled Party</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewBookingCashmemoScr"><i
                                            class="fas fa-file-alt"></i>Booking
                                        Cashmemo</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewBookingCashmemoByInvoiceScr"
                                        [routerLink]="['/report/cashmemo-report/bookingcashmemobyinvoice']"><i
                                            class="fas fa-file-alt"></i>Booking Cashmemo By Invoice</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPaidCollectionReportScr"
                                        [routerLink]="['/report/cashmemo-report/paidCollectionReport']"><i
                                            class="fas fa-file-alt"></i>Paid Collection Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewBookingPartyOSReportScr"
                                        [routerLink]="['/report/cashmemo-report/bkgPartyOsReport']"><i
                                            class="fas fa-file-alt"></i>Booking Party OS Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewCashMemoInCollectionManStockScr"
                                        [routerLink]="['/report/cashmemo-report/cashMemoInCollectionManStock']"><i
                                            class="fas fa-file-alt"></i>Cash Memo In Collection Man Stock</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewChequeSearchDetailsScr"
                                        [routerLink]="['/report/others-report/chequeSearchReport']">
                                        <i class="fas fa-file-alt"></i>Cheque Search Details
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewChequeDetailsScr"
                                        [routerLink]="['/report/others-report/chequeDetailsReport']">
                                        <i class="fas fa-file-alt"></i>Cheque Details
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewMemoLessDetailsScr"
                                        [routerLink]="['/report/cashmemo-report/memoLessSearchReport']">
                                        <i class="fas fa-file-alt"></i>Memo Less Details
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/cashmemo-report/totalLessRpt']"
                                        *ngIf="viewTotalLessReportScr"><i class="fas fa-file-alt"></i>Total
                                        Less Report</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewMemoPODUploadScr"
                                        [routerLink]="['/report/cashmemo-report/cashmemoPodUpload']">
                                        <i class="fas fa-file-alt"></i>Memo POD Upload
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <!--760 -->
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDebitModule"><i
                                    class="fas fa-file-alt"></i>Debit<label title="Toggle Drop-down" class="drop-icon"
                                    for="rptDebitSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptDebitSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDebitNoteRptScr"
                                        [routerLink]="['/report/debit/debitNoteReport']"><i
                                            class="fas fa-file-alt"></i>Debit Note Rpt</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewSummaryScr"><i
                                            class="fas fa-file-alt"></i>Summary</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDailyModule"><i
                                    class="fas fa-file-alt"></i>Daily<label title="Toggle Drop-down" class="drop-icon"
                                    for="rptDailySubMenu">▾</label>
                            </a> <input type="checkbox" id="rptDailySubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/daily-report/bangaloreOffice']"
                                        *ngIf="viewBangaloreOfficeScr"><i class="fas fa-file-alt"></i>Bangalore
                                        Office Daily Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDailyBookingScr"
                                        [routerLink]="['/report/daily-report/dailyBookingReport']"><i
                                            class="fas fa-file-alt"></i>Daily Booking</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCftRangeRptScr"
                                        [routerLink]="['/report/daily-report/cftRangeReport']"><i
                                            class="fas fa-file-alt"></i>CFT Range Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDoorDeliveryScr"><i
                                            class="fas fa-file-alt"></i>Door
                                        Delivery</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLoadingDtlsScr"><i
                                            class="fas fa-file-alt"></i>Loading
                                        Details</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewOfficeReportScr"
                                        [routerLink]="['/report/daily-report/officeReport']"><i
                                            class="fas fa-file-alt"></i>Office Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewSrcLrRateChkRpt"
                                        [routerLink]="['/report/lr-report/srcLRRateChkRpt']"><i
                                            class="fas fa-file-alt"></i>Source LR Entry Rate Check Report</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewHamaliRptModule"><i
                                    class="fas fa-file-alt"></i>Hamali<label title="Toggle Drop-down" class="drop-icon"
                                    for="rptHamaliSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptHamaliSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewBrokerRptScr"
                                        [routerLink]="['/report/hamali-report/brokerreport']"><i
                                            class="fas fa-file-alt"></i>Broker Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLabourStatementRptScr"
                                        [routerLink]="['/report/hamali-report/labourStatementReport']"><i
                                            class="fas fa-file-alt"></i>Labour Statement Report</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewHireslipAndInvoiceModule"><i
                                    class="fas fa-file-alt"></i>Hireslip/Invoice<label title="Toggle Drop-down"
                                    class="drop-icon" for="rptHireInvSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptHireInvSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewInvoiceModule"><i
                                            class="fas fa-file-alt"></i>Invoice<label title="Toggle Drop-down"
                                            class="drop-icon" for="rptInvSubMenu">▾</label>
                                    </a> <input type="checkbox" id="rptInvSubMenu">
                                    <ul aria-expanded="false" class="collapse sub-menu third-level bg-white">
                                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewInvoiceDtlsScr"
                                                [routerLink]="['/report/hireslip-and-invoice-report/invoiceDetails']"><i
                                                    class="fas fa-file-alt"></i>Invoice Details</a></li>
                                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewServiceTaxScr"
                                                [routerLink]="['/report/hireslip-and-invoice-report/serviceTaxReport']"><i
                                                    class="fas fa-file-alt"></i>Service Tax</a></li>
                                    </ul>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewAdvancePaymentScr"
                                        [routerLink]="['/report/hireslip-and-invoice-report/advancePayment']"><i
                                            class="fas fa-file-alt"></i>Advance Payment</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewHireslipDtlsScr"
                                        [routerLink]="['/report/hireslip-and-invoice-report/hireslipDetails']"><i
                                            class="fas fa-file-alt"></i>Hireslip Details</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewHireslipHistoryRptcr"><i
                                            class="fas fa-file-alt"></i>Hireslip
                                        History Rpt</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewHireslipUnloadingRptScr"
                                        [routerLink]="['/report/hireslip-and-invoice-report/hireslipUnloadingReport']"><i
                                            class="fas fa-file-alt"></i>Hireslip Unloading Report</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewLocalTripSheetScr"
                                        [routerLink]="['/report/hireslip-and-invoice-report/localTripSheet']">
                                        <i class="fas fa-file-alt"></i>Local Trip Sheet
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewHirepaymentEnetBankScr"
                                        [routerLink]="['/report/hireslip-and-invoice-report/hirepaymentEnet']">
                                        <i class="fas fa-file-alt"></i>E-Net Lorry Hire Payment
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/hireslip-and-invoice-report/transhipmentTrip']"
                                        *ngIf="viewTransshipmentTripScr"><i class="fas fa-file-alt"></i>Transshipment
                                        Trip</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrRptModule"><i
                                    class="fas fa-file-alt"></i>LR<label title="Toggle Drop-down" class="drop-icon"
                                    for="rptLrSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptLrSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewAutomatedSearchScr"
                                        [routerLink]="['/report/lr-report/automatedSearch']"><i
                                            class="fas fa-file-alt"></i>Automated Search</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewShortAndExtraConsolidateRpt"
                                        [routerLink]="['/report/lr-report/shortAndExtraConsolidateRpt']">
                                        <i class="fas fa-file-alt"></i>Short & Extra Consolidate
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLrDwsWeightReport"
                                        [routerLink]="['/report/lr-report/lrDwsWgtRpt']"><i
                                            class="fas fa-file-alt"></i>LR DWS Weight Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDelayed_E_SugamScr"><i
                                            class="fas fa-file-alt"></i>Delayed
                                        E-Sugam</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewGcNotReceivedScr"
                                        [routerLink]="['/report/lr-report/gcNotReceivedReport']"><i
                                            class="fas fa-file-alt"></i>G.C. Not Received</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLorryHireBalanceScr"
                                        [routerLink]="['/report/lr-report/lorryHireBalance']"><i
                                            class="fas fa-file-alt"></i>Lorry Hire Balance</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/lr-report/lorryHireStatement']"
                                        *ngIf="viewLorryHireStatementScr"><i class="fas fa-file-alt"></i>Lorry
                                        Hire Statement</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewLRDispatchBookingRptScr"
                                        [routerLink]="['/report/lr-report/lrDispatchBookingReport']">
                                        <i class="fas fa-file-alt"></i>LR Dispatch Booking Rpt
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLRHistoryRptScr"
                                        [routerLink]="['/report/lr-report/lrHistoryReport']"><i
                                            class="fas fa-file-alt"></i>LR History Rpt</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewInSalesTaxScr"><i
                                            class="fas fa-file-alt"></i>In
                                        Sales Tax</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/lr-report/issueBalance']" *ngIf="viewIssueBalanceScr"><i
                                            class="fas fa-file-alt"></i>Issue
                                        Balance</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLRIssueRptScr"
                                        [routerLink]="['/report/lr-report/lrIssueReport']"><i
                                            class="fas fa-file-alt"></i>LR Issue Rpt</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/lr-report/lrPerformanceReport']"
                                        *ngIf="viewPerformanceScr"><i class="fas fa-file-alt"></i>Performance</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLRReportScr"
                                        [routerLink]="['/report/lr-report/lrRpt']"><i class="fas fa-file-alt"></i>LR
                                        Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewChallanIssueScr"
                                        [routerLink]="['/report/lr-report/challanIssueReport']"><i
                                            class="fas fa-file-alt"></i>Challan Issue</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewShortAndExtraScr"
                                        [routerLink]="['/report/lr-report/shortAndExtraReport']"> <i
                                            class="fas fa-file-alt"></i> Short And Extra
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewShortAndExtraConflictScr"><i
                                            class="fas fa-file-alt"></i>Short And Extra Conflict</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewEwayBillDateValidationExtensionReport"
                                        [routerLink]="['/report/lr-report/eWayBillDateValidationExtensionRpt']">
                                        <i class="fas fa-file-alt"></i>EWay Bill Date Validation Extension Report
                                    </a>
                                </li>


                            </ul>
                        </li>

                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPartyModule"><i
                                    class="fas fa-file-alt"></i>Party<label title="Toggle Drop-down" class="drop-icon"
                                    for="rptPartySubMenu">▾</label>
                            </a> <input type="checkbox" id="rptPartySubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewConsigneeRptModule"><i
                                            class="fas fa-file-alt"></i>Consignee<label title="Toggle Drop-down"
                                            class="drop-icon" for="rptConsigneeSubMenu">▾</label> </a> <input
                                        type="checkbox" id="rptConsigneeSubMenu">
                                    <ul aria-expanded="false" class="collapse sub-menu third-level bg-white">
                                        <li class="sidebar-item"><a class="sidebar-link"
                                                [routerLink]="['/report/party-report/consigneeAdminRateReport']"
                                                *ngIf="viewConsigneeAdminRateRptScr"><i
                                                    class="fas fa-file-alt"></i>C/nee Admin Rate Rpt</a></li>
                                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewRateHistoryScr"
                                                [routerLink]="['/report/party-report/rateHistoryReport']"><i
                                                    class="fas fa-file-alt"></i>Rate History</a></li>
                                        <li class="sidebar-item"><a class="sidebar-link"
                                                [routerLink]="['/report/party-report/consigneeRateReport']"
                                                *ngIf="viewRateReportScr"><i class="fas fa-file-alt"></i>Rate
                                                Report</a></li>
                                        <li class="sidebar-item"><a class="sidebar-link"
                                                [routerLink]="['/report/party-report/consigneeReport']"
                                                *ngIf="viewConsingeeRptScr"><i class="fas fa-file-alt"></i>Consignee
                                                Report</a></li>
                                        <li class="sidebar-item"><a class="sidebar-link"
                                                *ngIf="viewBusinessStopReportScr"
                                                [routerLink]="['/report/party-report/businessStopReport']"><i
                                                    class="fas fa-file-alt"></i>Business Stop Report</a></li>
                                        <li class="sidebar-item"><a class="sidebar-link"
                                                *ngIf="viewBusinessStartReportScr"
                                                [routerLink]="['/report/party-report/businessStartReport']"><i
                                                    class="fas fa-file-alt"></i>Business Start Report</a></li>
                                        <li class="sidebar-item"><a class="sidebar-link"
                                                [routerLink]="['/report/party-report/adminBusinessRateReport']"
                                                *ngIf="viewAdminBusinessRateReportScr"><i
                                                    class="fas fa-file-alt"></i>Admin Business Rate Report</a></li>
                                    </ul>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/party-report/consignorReport']"
                                        *ngIf="viewConsignorRptScr"><i class="fas fa-file-alt"></i>Consignor</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDiscontinuousDtlsScr"
                                        [routerLink]="['/report/party-report/discontinuousDetailsReport']"><i
                                            class="fas fa-file-alt"></i>Discontinuous Details</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewPartyLessReportScr"
                                        [routerLink]="['/report/party-report/partyLessReport']"> <i
                                            class="fas fa-file-alt"></i>Party Less
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPartyWiseOscr"
                                        [routerLink]="['/report/party-report/partyWiseOsReport']"><i
                                            class="fas fa-file-alt"></i>Party Wise OS</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPartyWiseTotalStatusDtlsScr"
                                        [routerLink]="['/report/party-report/partyWiseTotalStatusDetails']"><i
                                            class="fas fa-file-alt"></i>Party Wise Total Status Details</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewCommodityMismatchRptScr"
                                        [routerLink]="['/report/party-report/commodityMismatchReport']">
                                        <i class="fas fa-file-alt"></i>Commodity Mismatch Report
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStatementModule"><i
                                    class="fas fa-file-alt"></i>Statement<label title="Toggle Drop-down"
                                    class="drop-icon" for="rptStmtSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptStmtSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewInvoiceRptScr"
                                        [routerLink]="['report/statement-reports/invoiceReport']"><i
                                            class="fas fa-file-alt"></i>Invoice
                                        Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStatementRptScr"
                                        [routerLink]="['/report/statement-reports/statementRpt']"><i
                                            class="fas fa-file-alt"></i>Statement Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewPartyLessCheckedAndUncheckLrDtlsScr"
                                        [routerLink]="['/report/statement-reports/partyLessCheckedAndUncheckedLrDetails']"><i
                                            class="fas fa-file-alt"></i>Party Less Checked And Uncheck LR
                                        Details</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStocksRptModule"><i
                                    class="fas fa-file-alt"></i>Stocks<label title="Toggle Drop-down" class="drop-icon"
                                    for="rptStkSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptStkSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCommodityReportScr"
                                        [routerLink]="['/report/stocks-report/commodityReport']"><i
                                            class="fas fa-file-alt"></i>Commodity Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewGodownStocksScr"
                                        [routerLink]="['/report/stocks-report/godownStocksReport']"><i
                                            class="fas fa-file-alt"></i>Godown Stocks</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewGodownStocksBookingScr"
                                        [routerLink]="['/report/stocks-report/godownStocksBookingReport']"><i
                                            class="fas fa-file-alt"></i>Godown Stocks Booking</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPendingDetailsScr"
                                        [routerLink]="['/report/stocks-report/pendingStocksDetailsReport']"><i
                                            class="fas fa-file-alt"></i>Pending Details</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStocksInTakenRptScr"
                                        [routerLink]="['/report/stocks-report/stocksInTakenReport']"><i
                                            class="fas fa-file-alt"></i>Stocks In Taken Rpt</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStorageReportScr"
                                        [routerLink]="['/report/stocks-report/storageReport']"><i
                                            class="fas fa-file-alt"></i>Storage Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewStocksUnloadingRptScr"
                                        [routerLink]="['/report/stocks-report/stocksUnloadingReport']"><i
                                            class="fas fa-file-alt"></i>Stocks Unloading Report</a></li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" *ngIf="viewBkgAgentsDispatchPerformanceRptScr"
                                        [routerLink]="['/report/stocks-report/bookingAgentDispatchPerformanceReport']">
                                        <i class="fas fa-file-alt"></i> Booking Agents Dispatch Performance Report
                                    </a>
                                </li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDestinationStocksReportScr"
                                        [routerLink]="['/report/stocks-report/destinationStocksReport']"><i
                                            class="fas fa-file-alt"></i>Destination Stocks Report</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTruckModule"><i
                                    class="fas fa-file-alt"></i>Truck<label title="Toggle Drop-down" class="drop-icon"
                                    for="rptTruckSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptTruckSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTruckAverageScr"><i
                                            class="fas fa-file-alt"></i>Truck
                                        Average</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewHaltAndDispatchedDtlsScr"
                                        [routerLink]="['/report/truck-report/haltAndDispatchedDetails']"><i
                                            class="fas fa-file-alt"></i>Halt And Dispatched Details</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewNonAvailabilityOfTruckScr"
                                        [routerLink]="['/report/truck-report/nonAvailabilityOfTruckReport']"><i
                                            class="fas fa-file-alt"></i>Non Availability Of Truck</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewPerformanceReportScr"
                                        [routerLink]="['/report/truck-report/performanceReport']"><i
                                            class="fas fa-file-alt"></i> Performance Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/truck-report/truckReport']"
                                        *ngIf="viewTruckReportScr"><i class="fas fa-file-alt"></i>Truck
                                        Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewHireDetailsByDestScr"><i
                                            class="fas fa-file-alt"></i>Hire
                                        Details By Destination</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewTruckOwnerDeclarationRptScr"
                                        [routerLink]="['/report/truck-report/truckOwnerDeclarationReport']"><i
                                            class="fas fa-file-alt"></i>Truck Owner Declaration Report</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewOthersModule"><i
                                    class="fas fa-file-alt"></i>Others<label title="Toggle Drop-down" class="drop-icon"
                                    for="rptOthersSubMenu">▾</label>
                            </a> <input type="checkbox" id="rptOthersSubMenu">
                            <ul aria-expanded="false" class="collapse sub-menu second-level bg-white">
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewLcAndBcRptScr"
                                        [routerLink]="['/report/others-report/lcAndBcReport']"><i
                                            class="fas fa-file-alt"></i>LC and BC Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/others-report/loaderPerformanceReport']"
                                        *ngIf="viewLoaderPerformanceRptScr"><i class="fas fa-file-alt"></i>Loader
                                        Performance Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewMonthlyReportScr"><i
                                            class="fas fa-file-alt"></i>Monthly
                                        Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewSalesTaxReportScr"><i
                                            class="fas fa-file-alt"></i>Sales
                                        Tax Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewCbrReportScr"><i
                                            class="fas fa-file-alt"></i>CBR
                                        Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link" *ngIf="viewDriverRewardedReportScr"><i
                                            class="fas fa-file-alt"></i>Driver Rewarded Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/others-report/tempoPerformanceReport']"
                                        *ngIf="viewTempoPerformanceReportScr"><i class="fas fa-file-alt"></i>Tempo
                                        Performance Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        [routerLink]="['/report/others-report/localChallanReport']"><i
                                            class="fas fa-file-alt"></i>Local Challan Report</a></li>
                                <li class="sidebar-item"><a class="sidebar-link"
                                        *ngIf="viewPartywiseCFTDetailsReportScr"
                                        [routerLink]="['/report/others-report/partywiseCFTDetailsReport']"><i
                                            class="fas fa-file-alt"></i>Partywise CFT Details Report</a></li>
                            </ul>
                        </li>

                    </ul>
                </li>
                <!-- Report Screens Ends Here -->


                <!-- <li class="nav-item search-box showInDesktopOnly" ><a 
					(click)="showSearch = !showSearch"
					class="nav-link waves-effect waves-dark" href="javascript:void(0)">
					<i style="color:#fff!important" class="ti-search"></i>
					</a>
					
					<form name="myForm"  class="app-search position-absolute" id='formid' 
					
						[ngClass]="{'show-search': showSearch}">
								<input id="menuNameList" type="text" class="form-control" style="cursor:pointer;" #clear
								[(ngModel)]="modelMenuNameList"
								[ngModelOptions]="{standalone: true}"
								[ngbTypeahead]="searchMenuNameList"
								
								[resultFormatter]="formatterMenuNameList"
								[inputFormatter]="formatterMenuNameList"
								(selectItem)="actionTypeFocus($event)"
								(focus)="focusMenuNameListTA$.next($any($event).target.value)"
								placeholder="Search here" ><a class="srh-btn" (click)="clear.value=''"
								(click)="showSearch = !showSearch"> <i class="ti-close"></i>
							</a>
					</form>
					
				</li>
				<li class="nav-item dropdown showInDesktopOnly" ngbDropdown placement="bottom-right">
					<a ngbDropdownToggle
					class="nav-link dropdown-toggle waves-effect waves-dark" style="padding: 0px;color: #fff;"
					href="javascript:void(0)" aria-haspopup="true"
					aria-expanded="false"> <i class="mdi mdi-bell font-24"></i>
				 </a>
					<div
						class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" style="min-width: 20rem;"
						ngbDropdownMenu>
						<span class="with-arrow"> <span class="bg-primary"></span>
						</span>
						<ul class="list-style-none">
							<li>
								<div class="drop-title bg-primary text-white">
									<h4 class="m-b-0 m-t-5">4 New</h4>
									<span class="font-light">Notifications</span>
								</div>
							</li>
							<li>
							
							</li>
							<li><a class="nav-link text-center m-b-5"
								href="javascript:void(0);"> <strong>Check all
										notifications</strong> <i class="fa fa-angle-right"></i>
							</a></li>
						</ul>
					</div>
				</li>
				<li class="nav-item dropdown showInDesktopOnly" ngbDropdown placement="bottom-right" style="margin-top: -3px;">
					<a ngbDropdownToggle
					class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
					href="javascript:void(0)"  aria-haspopup="true"
					aria-expanded="false"> <img src="assets/images/users/1.jpg"
						alt="user" class="rounded-circle" width="25">
				   </a>
					<div
						class="dropdown-menu dropdown-menu-right user-dd animated flipInY" style="min-width: 20rem;"
						ngbDropdownMenu>
						<span class="with-arrow"> <span class="bg-primary"></span>
						</span>
						<div
							class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
							<div class="">
								<img src="assets/images/users/1.jpg" alt="user" class="img-circle"
									width="60">
							</div>
							<div class="m-l-10">
								<h6 class="m-b-0">{{loggedInUserName}}</h6>
								<p class=" m-b-0">{{userRole}}</p>
							</div>
						</div>
						<a class="dropdown-item" href="javascript:void(0)"> <i
							class="ti-user m-r-5 m-l-5"></i> Login Id : {{loggedInUser}}
						</a> <a class="dropdown-item" href="javascript:void(0)"> <i
							class="ti-wallet m-r-5 m-l-5"></i> Branch: {{branch}}
						</a> <a class="dropdown-item" href="javascript:void(0)"> <i
							class="ti-email m-r-5 m-l-5"></i> Last Login: {{lastLogin}}
						</a>
						<!--       <div class="dropdown-divider"></div> -->
                <!--       <a class="dropdown-item" href="javascript:void(0)"> -->
                <!--         <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a> -->
                <!-- <div class="dropdown-divider"></div>
						<a class="dropdown-item" (click)="signout()"> <i
							class="fa fa-power-off m-r-5 m-l-5"></i> Logout
						</a>
						<div class="dropdown-divider"></div>
						<div class="p-l-30 p-10">
							<a href="javascript:void(0)"
								class="btn btn-sm btn-success btn-rounded">View Profile</a>
						</div>
					</div>
				</li>
				<li  class="userId showInDesktopOnly">
					<h6 class="outsideUserIdText">
						{{loggedInUser}}
					</h6> 
				</li> -->

            </ul>
        </div>
        <div class="col-md-2" style="margin-left: -80px;">
            <ul class="sidebarnav" style="background: transparent;">
                <li class="sidebar-item search-box showInDesktopOnly">
                    <a (click)="showSearch = !showSearch" class="sidebar-link waves-effect waves-dark"
                        href="javascript:void(0)">
                        <i style="color:#fff!important" class="ti-search"></i>
                    </a>

                    <form name="myForm" class="app-search position-absolute" id='formid'
                        [ngClass]="{'show-search': showSearch}">
                        <input id="menuNameList" name="menuNameList" type="text" class="form-control"
                            style="cursor:pointer;" #clear [(ngModel)]="modelMenuNameList"
                            [ngModelOptions]="{standalone: true}" [ngbTypeahead]="searchMenuNameList"
                            [resultFormatter]="formatterMenuNameList" [inputFormatter]="formatterMenuNameList"
                            (selectItem)="actionTypeFocus($event);" (click)="clear.value=''"
                            (focus)="focusMenuNameListTA$.next($any($event).target.value)" placeholder="Search here">
                        <a class="srh-btn" (click)="clear.value=''" (click)="showSearch = !showSearch">
                            <i class="ti-close"></i>
                        </a>
                    </form>

                </li>
                <li class="sidebar-item dropdown showInDesktopOnly" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="sidebar-link dropdown-toggle waves-effect waves-dark"
                        style="padding: 0px;color: #fff;" href="javascript:void(0)" aria-haspopup="true"
                        aria-expanded="false"> <i style="color: #fff!important; margin-top: 5px;"
                            class="mdi mdi-bell font-24"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown"
                        style="min-width: 20rem;" ngbDropdownMenu>
                        <span class="with-arrow"> <span class="bg-primary"></span>
                        </span>
                        <ul class="list-style-none">
                            <li>
                                <div class="drop-title bg-primary text-white">
                                    <h4 class="m-b-0 m-t-5">4 New</h4>
                                    <span class="font-light">Notifications</span>
                                </div>
                            </li>
                            <li>

                            </li>
                            <li>
                                <a class="sidebar-link text-center m-b-5" href="javascript:void(0);"> <strong>Check all
                                        notifications</strong> <i class="fa fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="sidebar-item dropdown showInDesktopOnly" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="sidebar-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                        href="javascript:void(0)" aria-haspopup="true" aria-expanded="false"> <img
                            src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="25">
                    </a>
                    <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY" style="min-width: 20rem;"
                        ngbDropdownMenu>
                        <span class="with-arrow"> <span class="bg-primary"></span>
                        </span>
                        <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                            <div class="">
                                <img src="assets/images/users/1.jpg" alt="user" class="img-circle" width="60">
                            </div>
                            <div class="m-l-10">
                                <h6 class="m-b-0">{{loggedInUserName}}</h6>
                                <p class=" m-b-0">{{userRole}}</p>
                            </div>
                        </div>
                        <a class="dropdown-item" href="javascript:void(0)"> <i class="ti-user m-r-5 m-l-5"></i> Login Id
                            : {{loggedInUser}}
                        </a>
                        <a class="dropdown-item" href="javascript:void(0)"> <i class="ti-wallet m-r-5 m-l-5"></i>
                            Branch: {{branch}}
                        </a>
                        <a class="dropdown-item" href="javascript:void(0)"> <i class="ti-email m-r-5 m-l-5"></i> Last
                            Login: {{lastLogin}}
                        </a>
                        <!--       <div class="dropdown-divider"></div> -->
                        <!--       <a class="dropdown-item" href="javascript:void(0)"> -->
                        <!--         <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a> -->
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" (click)="signout()"> <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout
                        </a>
                        <div class="dropdown-divider"></div>
                        <div class="p-l-30 p-10">
                            <a href="javascript:void(0)" (click)="editProfile()"
                                class="btn btn-sm btn-success btn-rounded">Change Password</a>
                        </div>
                    </div>
                </li>
                <li class="sidebar-item userId showInDesktopOnly">
                    <h6 class="outsideUserIdText">
                        {{loggedInUser}}
                    </h6>
                </li>
            </ul>
        </div>
    </div>


</nav>