<div class="row" *ngIf="isLoggedIn" id="{{pageId}}ewbDateExpDestRpt">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Source</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="ti-user"></i>
                                                    </span>
                                                </div>
                                                <input id="{{pageId}}source" type="text" class="form-control"
                                                    [(ngModel)]="modelAgentName" [ngbTypeahead]="searchAgentName"
                                                    [resultFormatter]="formatterAgentName"
                                                    [inputFormatter]="formatterAgentName"
                                                    (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                    placeholder="Select Source" />
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="showDestination" class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>

                                                    <input id="{{pageId}}destination" type="text" class="form-control"
                                                        [(ngModel)]="modelDestination"
                                                        [ngbTypeahead]="searchDestination"
                                                        [resultFormatter]="formatterDestination"
                                                        [inputFormatter]="formatterDestination"
                                                        (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                        placeholder="Select Destination" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style="width: 80%; border-top: none; margin: 3px;">
                                <div class="col-md-12" style="text-align: center;">
                                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
                                        (click)="getEWBDateExpiryDestDtls()">Search</button>
                                    <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
                                        (click)="clearAll()">Clear</button>
                                </div>
                            </div>

                            <div class="col-md-9 vl p-t-10">
                                <div class="card-body">
                                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center'
                                                    style="color: green; margin: auto; font-size: 18px;">
                                                    Please Wait Loading Details.....</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row p-t-10">
                                        <div class="col-md-4">
                                            <h6 class="card-title">EWay Bill Date Expiry Destination Details</h6>
                                        </div>
                                        <div class="col-md-8">
                                            <button type="submit" class="btn btn-primary m-r-10" style="padding: 1px 4px; margin-left: 8px;" id="{{pageId}}bulkdateAutoApiExtend" (click)="bulkAutoDateExtUpdteBtn();">
                                                <i class="fas fa-file-alt"></i>&nbsp;Bulk API Date Extend
                                            </button>
                                            <button type="submit" class="btn btn-primary m-r-10" style="padding: 1px 4px; margin-left: 57x;" id="{{pageId}}bulkdateExtend" (click)="bulkUpdteBtn(updateAllDatePopUpTemplate);">
                                                <i class="fas fa-file-alt"></i>&nbsp;Bulk Date Extend
                                            </button>
                                            <button type="submit" class="btn btn-primary m-r-10" style="padding: 1px 4px;" id="{{pageId}}ignoreSelected" (click)="ignoreAllMethod(ignoreSelectedAllPopUpTemplate);">
                                                <i class="fas fa-file-alt"></i>&nbsp;Ignore Selected
                                            </button>
                                        </div>
                                    </div>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}ewbDateExpiryDestRptTableId"
                                            class="table table-striped table-bordered row-border hover"
                                            [dtOptions]="dtOptionsEwbDateExpiryDestRpt"
                                            [dtTrigger]="dtTriggerEwbDateExpiryDestRpt">
                                            <thead>
                                                <tr>
                                                    <th> <input type="checkbox" [checked]="multiSelect" (change)="toggleAllRows($event)">Actions</th>
                                                    <th>LR Number</th>
                                                    <th>Agent</th>
                                                    <th>Source</th>
                                                    <th>Destination</th>
                                                    <th>EWay Bill</th>
                                                    <th>Eway Bill Valid Upto</th>
                                                    <th>A.Wgt</th>
                                                    <th>C.Wgt</th>
                                                    <th>To Pay</th>
                                                    <th>Paid</th>
                                                    <th>Goods Value</th>
                                                    <th>Commodity Name</th>
                                                    <th>LR Generated By</th>
                                                    <th>LR Status</th>
                                                    <th>Remarks</th>
                                                    <th>Vehicle No</th>
                                                    <th>Invoice No</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let ewbDateExpiryDestData of ewbDateExpiryDestDetails">
                                                    <td><input type="checkbox" [checked]="multiSelect" (change)="rowCheckBoxChecked($event, ewbDateExpiryDestData)" id='selected' style="margin-left: 0px">
                                                        <button style="padding: 1px 1px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}extendDateBtn" (click)="extendDateBtn(ewbDateExpiryDestData,updateDatePopUpTemplate);">
                                                            <i title="Extend Date" class="fas fa-edit"></i>
                                                        </button>
                                                        <button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}ignoreBtn" (click)="ignoreMethod(ewbDateExpiryDestData,ignoreSelectedAllPopUpTemplate);">
                                                            <i title="Ignore" class="fas fa-trash"></i>
                                                        </button>
                                                    </td>
                                                    <td>{{ ewbDateExpiryDestData.lrNumber }}</td>
                                                    <td>{{ ewbDateExpiryDestData.agentName }}</td>
                                                    <td>{{ ewbDateExpiryDestData.source }}</td>
                                                    <td>{{ ewbDateExpiryDestData.destination }}</td>
                                                    <td>{{ ewbDateExpiryDestData.eWayBillNo }}</td>
                                                    <td>
                                                        <span
                                                            *ngIf="ewbDateExpiryDestData.listOfmultipleEwayBill.length == 0">{{
                                                            ewbDateExpiryDestData.ewayBillValidUptoStr }}</span>
                                                        <span
                                                            *ngIf="ewbDateExpiryDestData.listOfmultipleEwayBill.length > 0"
                                                            (click)="openMultEwayPopup(ewbDateExpiryDestData,multipleEwayBillPopupOpen);"><u
                                                                style="cursor: pointer;color:blue;">View Multi
                                                                Eway</u></span>
                                                    </td>
                                                    <td>{{ ewbDateExpiryDestData.actualWeight }}</td>
                                                    <td>{{ ewbDateExpiryDestData.chargedWeight }}</td>
                                                    <td>{{ ewbDateExpiryDestData.toPay }}</td>
                                                    <td>{{ ewbDateExpiryDestData.paid }}</td>
                                                    <td>{{ ewbDateExpiryDestData.goodsValue }}</td>
                                                    <td>{{ ewbDateExpiryDestData.commodityName }}</td>
                                                    <td>{{ ewbDateExpiryDestData.generatedBy }}</td>
                                                    <td>{{ ewbDateExpiryDestData.status }}</td>
                                                    <td>{{ ewbDateExpiryDestData.remarks }}</td>
                                                    <td>{{ ewbDateExpiryDestData.vehicleNumber }}</td>
                                                    <td>{{ ewbDateExpiryDestData.invoiceNumber }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #updateDatePopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h5>Change EWay Bill Valid Upto Date For Selected Lr Number {{selectedLrNumber}}
                        </h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait ....</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>New Valid Upto Date:</label> <input id="{{pageId}}updateDate" class="form-control" [(ngModel)]="setTodayDateOnUpdateDate" placeholder="dd-mm-yyyy" name="updateDate" ngbDatepicker #updateDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="updateDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeUpdateDatePopUp();d('Cross click')">Cancel</button>
                                            <button style="float: right;" type="button" class="btn btn-success m-r-10" id="{{pageId}}updateBtn" (click)="updateExtendInfo();">Update</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #ignoreSelectedAllPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h5>Ignore reason for the selected LRs</h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait ....</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Reason:</label>
                                                    <textarea class="form-control" rows="4" id="{{pageId}}ignoreSelectedAll"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelIgnoreAllBtnId" (click)="closeIgnoreAllPopUp();d('Cross click')">Cancel</button>
                                            <button style="float: right;" type="button" class="btn btn-success m-r-10" id="{{pageId}}ignoreAllBtn" (click)="updateExtensionValidityBulkInfo();">Confirm
                                                Ignore</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #updateAllDatePopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h5>Change EWay Bill Valid Upto Date For Selected Lr Numbers</h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait ....</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label> New Valid Upto Date:</label> <input id="{{pageId}}updateAllDate" class="form-control" [(ngModel)]="setTodayDateOnUpdateAllDate" placeholder="dd-mm-yyyy" name="updateAllDate" ngbDatepicker #updateAllDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="updateAllDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelAllBtnId" (click)="closeUpdateAllDatePopUp();d('Cross click')">Cancel</button>
                                            <button style="float: right;" type="button" class="btn btn-success m-r-10" id="{{pageId}}updateAllBtn" (click)="bulkExtendInfo();">Update
                                                All</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>