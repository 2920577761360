import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
@Component({
    selector: 'app-cashmemo-online-payment-details',
    templateUrl: './cashmemo-online-payment-details.component.html',
    styleUrls: ['./cashmemo-online-payment-details.component.css']
})
export class CashmemoOnlinePaymentDetailsComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    pageId = "cshmemoonlndtls";
    dtTriggerCashmemoOnlinePaymentDetails: Subject<any> = new Subject();
    dtOptionsCashmemoOnlinePaymentDetails: any;

    showSpinnerForAction = false;
    userDtoCollectionMan: UserDataDto = new UserDataDto();
    userDtoCollectionManAll: UserDataDto = new UserDataDto();
    cashmemoForGet: CashMemoDto = new CashMemoDto();
    selectedFromDate: any;
    selectedToDate: any;
    selectedCollectionMan: any;
    toSetDestForConsignee: any;
    toSetDestForColMan: any;
    selecDestinationForTemp: any;
    public modelDestinationFotGet: any;
    cashmemoOnlinePaymentDetailLists: any;
    cashmemoUpdate: CashMemoDto = new CashMemoDto();
    cashmemoRet: any;


    collectionManAllOption: UserDataDto = new UserDataDto();
    collectionManTA: Array<UserDataDto> = [];
    collectionManTATemp: Array<UserDataDto> = [];
    collectionManTATempForDest: Array<UserDataDto> = [];

    focusCollectionManTA$ = new Subject<string>();
    collectionManSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCollectionManTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.collectionManTA
                : this.collectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    collectionManDataList: any;

    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    colmrIsChecked = false;

    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
    viewDestination = false;
    destView = false;
    selectedDestination: any;
    cbFailedTrx = false;
    // cashMemoDtoConfirmList: string[];
    cashMemoDtoConfirmList: Array<any> = [];
    viewConfirmPayChkBox = false;
    sendCollectionCloseWhatsappUpdates: boolean = false;
    userDataDtoForWhatsApp: UserDataDto = new UserDataDto();
    selectedClosingDate: any;
    selectedConfirmCollectionMan: any;
    //collectionman for confirm
    confirmCollectionManTA: Array<UserDataDto> = [];
    confirmCollectionManTATemp: Array<UserDataDto> = [];
    confirmCollectionManTATempForDest: Array<UserDataDto> = [];

    focusConfirmCollectionManTA$ = new Subject<string>();
    confirmCollectionManSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConfirmCollectionManTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.confirmCollectionManTA
                : this.confirmCollectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConfirmCollectionMan = (x: UserDataDto) => x.collectioMan;
    formatterCollectionMan = (x: UserDataDto) => x.collectioMan;

    confirmCollectionManDataList: any;
    selectedConfirmClosingDate: any;
    constructor(private masterReadService: MasterReadService, private http: HttpClient, private router: Router,
        private masterService: MasterService, public changeDetectorRef: ChangeDetectorRef, private lrReportService: LrReportService,
        private memoReport: MemoReport, private memoService: MemoService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.toSetDestForConsignee = this.userDataDtoReturnSession.mainStation;
            this.toSetDestForColMan = this.userDataDtoReturnSession.mainStation;

            this.getCollectionManDetailsList(false);
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Online Payment DestView") {
                        this.destView = true;
                    }
                }
            }
            if (this.destView == true) {
                this.viewDestination = true;
                this.getDestinationDetails();
            } else {
                this.viewDestination = false;
            }
            if (this.userDataDtoReturnSession.sendDestPaymentCollectionWhatsApp != null && this.userDataDtoReturnSession.sendDestPaymentCollectionWhatsApp) {
                this.sendCollectionCloseWhatsappUpdates = true;
            }
        }
        $("#" + this.pageId + "failedTrxChk").prop("checked", false);
    }

    ngOnInit() {
        var groupColumnTrxIdCol = 0;
        this.dtOptionsCashmemoOnlinePaymentDetails = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    footer: true,
                    title: function () {
                        return "Cashmemo Online Payment Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            searching: true,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
            select: true,
            footer: true,
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var count = 0;
                var diffCA = 0;
                var memoAmt = 0;
                var neftAmt = 0;
                var upiAmt = 0;
                var cardAmt = 0;
                var articles = 0;
                var toPay = 0;
                api.column(groupColumnTrxIdCol, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 4,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": diffCA
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": memoAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": neftAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": upiAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": cardAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 4,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": articles
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPay
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 2,
                                                "text": ""
                                            }))
                                        .prop('outerHTML'));



                            count = 0;
                            diffCA = 0;
                            memoAmt = 0;
                            neftAmt = 0;
                            upiAmt = 0;
                            cardAmt = 0;
                            articles = 0;
                            toPay = 0;

                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 15,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }


                    count++;
                    diffCA += +val[6];
                    memoAmt += +val[7];
                    neftAmt += +val[8];
                    upiAmt += +val[9];
                    cardAmt += +val[10];
                    articles += +val[15];
                    toPay += +val[17];

                    if (i == (rows.length - 1)) {

                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total : " + count
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 4,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": diffCA
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": memoAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": neftAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": upiAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": cardAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 4,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": articles
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": toPay
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .prop('outerHTML'));

                        diffCA = 0;
                        memoAmt = 0;
                        neftAmt = 0;
                        upiAmt = 0;
                        cardAmt = 0;
                        articles = 0;
                        toPay = 0;
                    }
                });
            },
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var amount = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var neftAmt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var upiAmt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var cardAmt = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var article = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(1).footer()).html('Total : ' + data.length);
                $(api.column(6).footer()).html(amount);
                $(api.column(7).footer()).html(neftAmt);
                $(api.column(8).footer()).html(upiAmt);
                $(api.column(9).footer()).html(cardAmt);
                $(api.column(14).footer()).html(article);
                $(api.column(16).footer()).html(toPay);
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerCashmemoOnlinePaymentDetails.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerCashmemoOnlinePaymentDetails.next();
    }



    getDetailsForCollectionMasterRead(destShow) {
        this.userDtoCollectionMan = new UserDataDto();
        this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
        this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
        this.userDtoCollectionMan.status = "D";

    }

    getCollectionManDetailsList(destShow) {
        this.collectionManDataList = [];
        this.collectionManTA = [];
        this.confirmCollectionManDataList = [];
        this.confirmCollectionManTA = [];
        this.getDetailsForCollectionMasterRead(destShow);
        this.masterReadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Collection Man Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.collectionManDataList = [];
                    this.collectionManTA = [];
                    this.confirmCollectionManDataList = [];
                    this.confirmCollectionManTA = [];
                    this.userDtoCollectionManAll.collectioMan = "All";
                    this.collectionManTA.push(this.userDtoCollectionManAll);
                    $("#" + this.pageId + "collectionMan").val('All');
                } else {
                    console.log(response);
                    this.collectionManDataList = response;
                    console.log(this.collectionManDataList);
                    this.collectionManTA = [];

                    this.confirmCollectionManDataList = response;
                    console.log(this.confirmCollectionManDataList);
                    this.confirmCollectionManTA = [];


                    this.userDtoCollectionManAll.collectioMan = "All";
                    this.collectionManTA.push(this.userDtoCollectionManAll);
                    for (let i = 0; i < this.collectionManDataList.length; i++) {
                        this.collectionManTA.push(this.collectionManDataList[i]);
                    }

                    for (let i = 0; i < this.confirmCollectionManDataList.length; i++) {
                        this.confirmCollectionManTA.push(this.confirmCollectionManDataList[i]);
                    }
                    this.selecDestinationForTemp = this.toSetDestForColMan;
                    if (this.selecDestinationForTemp == this.userDataDtoReturnSession.mainStation) {
                        this.collectionManTATempForDest = this.collectionManTA;
                    }

                    this.selecDestinationForTemp = this.toSetDestForColMan;
                    if (this.selecDestinationForTemp == this.userDataDtoReturnSession.mainStation) {
                        this.confirmCollectionManTATempForDest = this.confirmCollectionManTA;
                    }

                    this.toSetDestForColMan = '';
                    console.log(this.collectionManTATempForDest, this.selecDestinationForTemp)
                    this.collectionManTATemp = this.collectionManTA;

                    console.log(this.confirmCollectionManTATempForDest, this.selecDestinationForTemp)
                    this.confirmCollectionManTATemp = this.confirmCollectionManTA;

                    $("#" + this.pageId + "collectionMan").val('All');
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Collection Man details", "info");
            }, () => console.log('done');
    };



    setCashmemoOnlinePaymentDtls() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();

        this.cashmemoForGet = new CashMemoDto();
        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
            swal({
                title: "Mandatory Field",
                text: "From Date , To Date Is Mandatory Fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            return false;
        } else {
            this.cashmemoForGet.fromdate = this.selectedFromDate;
            this.cashmemoForGet.todate = this.selectedToDate;
            this.cashmemoForGet.tillDateMode = "false";
        }

        this.cashmemoForGet.reportMode = 'All';
        this.cashmemoForGet.mode = 'amt';
        this.cashmemoForGet.companyId = this.userDataDtoReturnSession.companyId;


        if (this.selectedCollectionMan != null && this.selectedCollectionMan != '' && this.selectedCollectionMan != undefined) {
            this.cashmemoForGet.collectionMan = this.selectedCollectionMan;
        } else {
            this.cashmemoForGet.collectionMan = 'All';
        }

        this.cashmemoForGet.consigneeName = 'All';
        // this.cashmemoForGet.office = this.userDataDtoReturnSession.mainStation;
        if (this.destView == true) {
            if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
                this.cashmemoForGet.office = this.selectedDestination;
            } else {
                this.cashmemoForGet.office = this.userDataDtoReturnSession.mainStation;
            }
        } else {
            this.cashmemoForGet.office = this.userDataDtoReturnSession.mainStation;
        }
        this.cashmemoForGet.memoCheck = this.cbFailedTrx;
        console.log(this.cashmemoForGet);
        this.getCashmemoOnlinePaymentDtls();
    }

    getCashmemoOnlinePaymentDtls = () => {
        this.showSpinnerForAction = true;
        this.memoReport.getCashmemoOnlinePaymentDetails(this.cashmemoForGet).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "cashmemoOnlinePaymentDetailsTableId").DataTable().destroy();
                this.cashmemoOnlinePaymentDetailLists = [];
                if (response.consigneeWiseMemo.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.cashmemoOnlinePaymentDetailLists = response.consigneeWiseMemo;
                }
                this.dtTriggerCashmemoOnlinePaymentDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Cashmemo Online Payment Details", "info");
            }, () => console.log('done');
    };

    clearDetails() {
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "collectionMan").val('');
        this.selectedFromDate = '';
        this.selectedToDate = '';
        this.selectedCollectionMan = '';
        this.cashmemoForGet = new CashMemoDto();
        $("#" + this.pageId + "cashmemoOnlinePaymentDetailsTableId").DataTable().destroy();
        this.cashmemoOnlinePaymentDetailLists = [];
        this.dtTriggerCashmemoOnlinePaymentDetails.next();
        $("#" + this.pageId + "failedTrxChk").prop("checked", false);
        this.cbFailedTrx = false;
        this.viewConfirmPayChkBox = false;

    }

    userUpdateDetails(cashmemoOnlinePaymentData) {
        this.cashmemoUpdate = new CashMemoDto();
        this.cashmemoUpdate.memoNumber = cashmemoOnlinePaymentData.memoNumber;
        this.cashmemoUpdate.userName = this.userDataDtoReturnSession.userId;
        console.log(this.cashmemoUpdate);
        this.updateCashmemoOnlinePaymentDetails();
    }

    updateCashmemoOnlinePaymentDetails() {
        swal({
            title: "Confirm Update",
            text: "Sure you want to update the selected payment details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yes) => {
            if (yes) {
                this.memoReport.updateCashmemoOnlinePaymentDetails(this.cashmemoUpdate).
                    subscribe((data) => {
                        this.cashmemoRet = data;
                        if (this.cashmemoRet.status == "Success") {
                            swal("Success", "Cashmemo Online Payment Details Updated Successfully!", "info");
                            this.setCashmemoOnlinePaymentDtls();
                        } else {
                            swal("Failed", "Failed to Update the Cashmemo Online Payment Details!", "error");
                        }
                    }), (error) => {
                        swal("Error", "Server problem occurred while Updating the Cashmemo Online Payment Details!", "error");
                    },
                    () => console.log('done');
            }
        });
    }
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                        $("#" + this.pageId + "destination").val(this.userDataDtoReturnSession.mainStation);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };
    toggleChkBoxForFailedTrx(event) {
        if (event.target.checked) {
            this.cbFailedTrx = true;
            this.viewConfirmPayChkBox = true;

        } else {
            this.cbFailedTrx = false;
            this.viewConfirmPayChkBox = false;

        }

    }
    rowCheckBoxChecked(e, cashmemoOnlinePaymentData) {
        if (e.currentTarget.checked) {
            if (!this.cashMemoDtoConfirmList.includes(cashmemoOnlinePaymentData.memoNumber)) {
                this.cashMemoDtoConfirmList.push(cashmemoOnlinePaymentData.memoNumber);
            }
        } else {


            let index = this.cashMemoDtoConfirmList.indexOf(cashmemoOnlinePaymentData.memoNumber);
            if (index > -1) {
                this.cashMemoDtoConfirmList.splice(index, 1);
            }
        }
        console.log(this.cashMemoDtoConfirmList);
    }

    confirmPayment() {
        this.selectedConfirmCollectionMan = $("#" + this.pageId + "confirmCollectionMan").val();
        this.selectedConfirmClosingDate = $("#" + this.pageId + "confirmClosingDate").val();
        if (this.cashMemoDtoConfirmList && this.cashMemoDtoConfirmList.length > 0) {
            this.cashmemoUpdate = new CashMemoDto();
            this.cashmemoUpdate.list = this.cashMemoDtoConfirmList;  // Use selected items
            this.cashmemoUpdate.userName = this.userDataDtoReturnSession.userId;
            this.cashmemoUpdate.collectionMan = this.selectedConfirmCollectionMan;
            this.cashmemoUpdate.completedOn = this.selectedConfirmClosingDate;

            console.log(this.cashmemoUpdate);
            this.updateCashmemoOnlineMultiPaymentDetails();
        } else {
            swal({
                title: "Warning",
                text: "Please select transaction to confirm payment !",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }


    }

    updateCashmemoOnlineMultiPaymentDetails() {
        swal({
            title: "Confirm Update",
            text: "Sure you want to update the selected payment details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yes) => {
            if (yes) {
                this.memoReport.updateCashmemoOnlinePaymentDetails(this.cashmemoUpdate)
                    .subscribe(
                        (data) => {
                            this.cashmemoRet = data;
                            if (this.cashmemoRet.status === "Success") {
                                $("#" + this.pageId + "cashmemoOnlinePaymentDetailsTableId").DataTable().destroy();
                                this.cashmemoOnlinePaymentDetailLists = [];
                                this.dtTriggerCashmemoOnlinePaymentDetails.next();

                                swal("Success", "Cashmemo Online Payment Details Updated Successfully!", "info");
                                if (this.sendCollectionCloseWhatsappUpdates) {
                                    this.whatsAppFlowForCollectionClose();
                                } else {
                                    this.cashMemoDtoConfirmList = [];
                                }

                                this.setCashmemoOnlinePaymentDtls();
                            } else {
                                swal("Failed", "Failed to Update the Cashmemo Online Payment Details!", "error");
                            }
                        },
                        (error) => {
                            swal("Error", "Server problem occurred while updating the Cashmemo Online Payment Details!", "error");
                            console.error(error);
                        }
                    );
            }
        });
    }
    whatsAppFlowForCollectionClose = () => {
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.userDataDtoForWhatsApp = new UserDataDto();
        // this.listOfMemoNumber = [];
        // for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
        //     this.listOfMemoNumber.push(this.pfAssignedJobDetailsDataList[i].memoNumber);
        // }
        this.userDataDtoForWhatsApp.list = this.cashMemoDtoConfirmList;
        this.userDataDtoForWhatsApp.city = this.userDataDtoReturnSession.office;
        // this.userDataDtoForWhatsApp.mainStation = this.userDataDtoReturnSession.mainStation;
        if (this.destView == true) {
            if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
                this.userDataDtoForWhatsApp.mainStation = this.selectedDestination;
            } else {
                this.userDataDtoForWhatsApp.mainStation = this.userDataDtoReturnSession.mainStation;
            }
        } else {
            this.userDataDtoForWhatsApp.mainStation = this.userDataDtoReturnSession.mainStation;
        }
        this.userDataDtoForWhatsApp.mode = 'onlinePayment';
        this.memoService.whatsAppNotificationFlowForDestCollectionClose(this.userDataDtoForWhatsApp).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.cashMemoDtoConfirmList = [];
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

}
