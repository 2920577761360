<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <hr style="margin: 0px;">
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center'
                                            style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title">LR Info</h6>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>LR No &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-clipboard-list"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        (keypress)="lrNumberKeyPressEventListner($event);"
                                                        aria-describedby="basic-addon11" id="{{pageId}}lrNumber"
                                                        autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Booking Date</label> <input id="{{pageId}}bookingDates"
                                                        class="form-control" placeholder="yyyy-mm-dd"
                                                        name="bookingDates" ngbDatepicker #bookingDates="ngbDatepicker"
                                                        autocomplete="off" [(ngModel)]="bookingDateModal" readonly>
                                                    <div class="input-group-append" (click)="bookingDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Agent Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="ti-home"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}agentName"
                                                        aria-describedby="basic-addon11" autocomplete="off" readonly>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-road"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}destination"
                                                        aria-describedby="basic-addon11" autocomplete="off" readonly>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Consignor Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}consignorName"
                                                        autocomplete="off" readonly>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Consignee Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class=" fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}consigneeName"
                                                        autocomplete="off" readonly>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Articles</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-clone"></i>
                                                        </span>
                                                    </div>
                                                    <input type="number" class="form-control"
                                                        aria-describedby="basic-addon11" id="{{pageId}}articles"
                                                        autocomplete="off" readonly>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 vl">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title">Reason For Uncleared</h6>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Reasons &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>

                                                        <input id="{{pageId}}reasons" type="text" class="form-control"
                                                            [(ngModel)]="modelReasons" [ngbTypeahead]="searchReason"
                                                            [resultFormatter]="formatterReason"
                                                            [inputFormatter]="formatterReason"
                                                            (focus)="focusReasonTA$.next($any($event).target.value)"
                                                            placeholder="Select Reason" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Date &nbsp;*
                                                    </label> <input id="{{pageId}}dates" class="form-control"
                                                        placeholder="yyyy-mm-dd" name="dates" ngbDatepicker
                                                        #dates="ngbDatepicker" autocomplete="off"
                                                        [(ngModel)]="dateModal">
                                                    <div class="input-group-append" (click)="dates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Remarks
                                                    </label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}remarks"
                                                        aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}toCpBtn"
                                (click)="validateForSaveUnclearedLrdetails();">Update Details</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
                                (click)="clearFields();">Clear</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 vl">
                    <div class="card">
                        <div class="card-body">
                            <div class="box-body">
                                <h6 class="card-title">Uncleared Lr Details</h6>
                                <table datatable id="{{pageId}}unclearedLrDetailsTable"
                                    class="table table-striped table-bordered row-border hover"
                                    [dtOptions]="dtOptionsUnclearedLrDetails" [dtTrigger]="dtTriggerUnclearedLrDetails">

                                    <thead>
                                        <tr>
                                            <th>LR No</th>
                                            <th>Reason</th>
                                            <th>Remarks</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let unclearedLrData of unclearedLrDataList ">
                                            <td>{{ unclearedLrData.lrNumber }}</td>
                                            <td>{{ unclearedLrData.reason }}</td>
                                            <td>{{ unclearedLrData.remarks }}</td>
                                            <td><button style="padding: 1px 4px; background-color: #ffffff00;"
                                                    class="btn m-r-10" id="{{pageId}}editBtn"
                                                    (click)="rowSelectedView(unclearedLrData);">
                                                    <i title="View" class="fas fa-edit"></i>
                                                </button>&nbsp;
                                                <button type="button"
                                                    style="padding: 1px 4px; background-color: #ffffff00;"
                                                    class="btn m-r-10" id="{{pageId}}tableStockToCpBtnId"
                                                    (click)="setClearAndMoveUnclearedLrDetails(unclearedLrData)">
                                                    <i title="Clear & Move this LR" class="fas fa-exchange-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>