<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLrRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input id="{{pageId}}fromDate" [(ngModel)]="fromDateModel" class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" [(ngModel)]="toDateModel" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                                placeholder="Select Destination" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}consignorGstNo">
                                                        <label>Consignor GST No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-hashtag"></i>
															</span>
                                                        </div>
                                                        <input #consignorGSTNumber placeholder="Enter Consignor GST Number" (keyup)="getConsignorGstNumber($event)" type="text" class="form-control" id="{{pageId}}consignorGSTNumber" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Index</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input #consignorIndex id="{{pageId}}consignorIndex" name="consignorIndex" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorDropDownListner($event)" [ngbTypeahead]="consignorIndexSearchTA" (focus)="focusConsignorIndexTA$.next($any($event).target.value)"
                                                            (click)="clickTA(instanceConsignorIndex)" #instanceConsignorIndex="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">

                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignor Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #consignorName id="{{pageId}}consignorName" name="consignorName" type="text" class="form-control" placeholder="Select Consignor Name" [(ngModel)]="modelConsignorName" [ngbTypeahead]="consignorNameSearchTA" (selectItem)="rowSelectedConsignor($event)"
                                                            [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsignorName" [inputFormatter]="formatterConsignorName" (focus)="focusConsignorNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod()">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod()">Clear</button>
                        </div>
                    </div>

                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-8"></div>
                                        </div>
                                        <table width=100%>
                                            <td width=80%>
                                                <div *ngIf="showSpinnerForAction">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                                            <br>
                                                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                                Please Wait Loading Details.....</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </table>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="box-body">
                                                    <table datatable id="{{pageId}}lrRptTable" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLrReport" [dtTrigger]="dtTriggerLrReport">

                                                        <thead>
                                                            <tr>
                                                                <th>LR No</th>
                                                                <th>Bkg Dt</th>
                                                                <th>Entry Dt</th>
                                                                <th>Source</th>
                                                                <th>Destination</th>
                                                                <th>Consignee</th>
                                                                <th>Consignor</th>
                                                                <th>Art</th>
                                                                <th>A.Wt</th>
                                                                <th>C.Wt</th>
                                                                <th>Commodity</th>
                                                                <th>Sub Commodity</th>
                                                                <th>G.Value</th>
                                                                <th>To Pay</th>
                                                                <th>Paid</th>
                                                                <th>Generated By</th>

                                                                <th>Rate Src</th>
                                                                <th>Rate Dest</th>
                                                                <th>Rate For</th>
                                                                <th>Rate Commodity</th>
                                                                <th>Rate Type</th>
                                                                <th>Rate Unit</th>

                                                                <th>MstrRate.Base Rate</th>
                                                                <th>MstrRate.Hamali</th>
                                                                <th>MstrRate.Gc Chg</th>
                                                                <th>MstrRate.AOC</th>
                                                                <th>MstrRate.Others</th>
                                                                <th>MstrRate.FOV</th>

                                                                <th>Mstr.Base Frgt</th>
                                                                <th>Mstr.Hamali</th>
                                                                <th>Mstr.Gc Chg</th>
                                                                <th>Mstr.AOC</th>
                                                                <th>Mstr.Others</th>
                                                                <!-- <th>Mstr.FOV</th> -->

                                                                <th>Act.Base Frgt</th>
                                                                <th>Act.Hamali</th>
                                                                <th>Act.Gc Chg</th>
                                                                <th>Act.AOC</th>
                                                                <th>Act.Others</th>
                                                                <th>Act.FOV</th>
                                                                <th>Act.Lc Chg</th>
                                                                <th>Act.Bc Chg</th>
                                                                <th>Act.DD Amt</th>

                                                                <th>ActRate.Base Rate</th>
                                                                <th>ActRate.Hamali</th>
                                                                <th>ActRate.Gc Chg</th>
                                                                <th>ActRate.AOC</th>
                                                                <th>ActRate.Others</th>
                                                                <th>ActRate.FOV</th>

                                                                <th>DIFF-Base Frgt</th>
                                                                <th>DIFF-Hamali</th>
                                                                <th>DIFF-Gc Chg</th>
                                                                <th>DIFF-AOC</th>
                                                                <th>DIFF-Others</th>
                                                                <th>DIFF-FOV</th>
                                                                <th>DIFF-Lc Chg</th>
                                                                <th>DIFF-Bc Chg</th>
                                                                <th>DIFF-DD Amt</th>
                                                                <th>Is Per Rate Kg</th>
                                                                <th>Is Size Exceeded</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let lrReportData of lrReportDataList ">
                                                                <td>{{ lrReportData.lrNumber }}</td>
                                                                <td>{{ lrReportData.bookingDateStr }}</td>
                                                                <td>{{ lrReportData.actLrEntryDateStr }}</td>
                                                                <td>{{ lrReportData.source }}</td>
                                                                <td>{{ lrReportData.destination }}</td>
                                                                <td>{{ lrReportData.consigneeName }}</td>
                                                                <td>{{ lrReportData.consignorName }}</td>
                                                                <td>{{ lrReportData.totalArticles }}</td>
                                                                <td>{{ lrReportData.actualWeight }}</td>
                                                                <td>{{ lrReportData.chargedWeight }}</td>
                                                                <td>{{ lrReportData.commodityName }}</td>
                                                                <td>{{ lrReportData.subCommodity }}</td>
                                                                <td>{{ lrReportData.goodsValue }}</td>
                                                                <td>{{ lrReportData.toPay }}</td>
                                                                <td>{{ lrReportData.paid }}</td>
                                                                <td>{{ lrReportData.generatedBy }}</td>

                                                                <td>{{ lrReportData.rateSrcStation }}</td>
                                                                <td>{{ lrReportData.rateDest }}</td>
                                                                <td>{{ lrReportData.rateStr }}</td>
                                                                <td>{{ lrReportData.rateCommodity }}</td>
                                                                <td>{{ lrReportData.rateUnit }}</td>
                                                                <td>{{ lrReportData.ratePerUnit }}</td>

                                                                <!-- master rate -->
                                                                <td>{{ lrReportData.rate }}</td>
                                                                <td>{{ lrReportData.rateSrcHamali }}</td>
                                                                <td>{{ lrReportData.rateGcChg }}</td>
                                                                <td>{{ lrReportData.rateAoc }}</td>
                                                                <td>{{ lrReportData.rateOthers }}</td>
                                                                <td>{{ lrReportData.rateFov }}</td>

                                                                <td>{{ lrReportData.mastBaseFgt }}</td>
                                                                <td>{{ lrReportData.mastHamali }}</td>
                                                                <td>{{ lrReportData.mastGcChg }}</td>
                                                                <td>{{ lrReportData.mastAoc }}</td>
                                                                <td>{{ lrReportData.mastOthers }}</td>
                                                                <!--  -->
                                                                <!-- actual lr details entered -->
                                                                <td>{{ lrReportData.baseFreight }}</td>
                                                                <td>{{ lrReportData.hamali }}</td>
                                                                <td>{{ lrReportData.gcCharge }}</td>
                                                                <td>{{ lrReportData.aoc }}</td>
                                                                <td>{{ lrReportData.others }}</td>
                                                                <td>{{ lrReportData.riskCharge }}</td>
                                                                <td>{{ lrReportData.lcChg }}</td>
                                                                <td>{{ lrReportData.bcChg }}</td>
                                                                <td>{{ lrReportData.ddAmt }}</td>

                                                                <td>{{ lrReportData.mastRateBaseFgt }}</td>
                                                                <td>{{ lrReportData.mastRateHamali }}</td>
                                                                <td>{{ lrReportData.mastRateGcChg }}</td>
                                                                <td>{{ lrReportData.mastRateAoc }}</td>
                                                                <td>{{ lrReportData.mastRateOthers }}</td>
                                                                <td>{{ lrReportData.mastRateFov }}</td>
                                                                <!--  -->

                                                                <td>{{ lrReportData.diffBaseFgt }}</td>
                                                                <td>{{ lrReportData.diffHamali }}</td>
                                                                <td>{{ lrReportData.diffGcChg }}</td>
                                                                <td>{{ lrReportData.diffAoc }}</td>
                                                                <td>{{ lrReportData.diffOthers }}</td>
                                                                <td>{{ lrReportData.diffFov }}</td>
                                                                <td>{{ lrReportData.diffLc }}</td>
                                                                <td>{{ lrReportData.diffBc }}</td>
                                                                <td>{{ lrReportData.diffDdAmt }}</td>
                                                                <td *ngIf="lrReportData.isPerRateKg == false" style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ lrReportData.isPerRateKg== true ? 'Yes' : 'No'}}</td>
                                                                <td *ngIf="lrReportData.isPerRateKg == true" style='font-size: 13px; color: red; font-weight: bold;'>
                                                                    {{ lrReportData.isPerRateKg== true ? 'Yes' : 'No'}}</td>
                                                                <td *ngIf="lrReportData.isSizeExceeded == false" style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ lrReportData.isSizeExceeded == true ? 'Yes' : 'No'}}</td>
                                                                <td *ngIf="lrReportData.isSizeExceeded == true" style='font-size: 13px; color: red; font-weight: bold;'>
                                                                    {{ lrReportData.isSizeExceeded == true ? 'Yes' : 'No'}}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
</body>

</html>