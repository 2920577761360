//CHG_V-CM:01 - @Asrar chg, For TDM, service chg will calculate based on the area charge based logic, if less party will consider from admin rate master else from Agentsubstation areas @Sadakath Dated:10/08/2023 (Currently for TDM)
//CHG_V-CM:02 - @Asrar Jr Chg, Filter on Searching (14-08-2024)

import { NgbDateStruct, NgbTypeaheadSelectItemEvent, NgbModalRef, ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { merge } from 'rxjs';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { MatDialog } from '@angular/material/dialog';
import { LRDto } from 'src/app/dto/LR-dto';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import *  as moment from 'moment';
import { UpdateConsigneeAdminRateForAllComponent } from 'src/app/master/consignee/update-consignee-admin-rate-for-all/update-consignee-admin-rate-for-all.component';
import { MasterService } from 'src/app/dataService/master-service';

@Component({
    selector: 'app-consignee-admin-rate-master',
    templateUrl: './consignee-admin-rate-master.component.html',
    styleUrls: ['./consignee-admin-rate-master.component.css']
})
export class ConsigneeAdminRateMasterComponent implements OnInit {

    model: NgbDateStruct;
    model2;
    loadingIndicator = true;
    hoveredDate: NgbDateStruct;
    effectFromDate: NgbDateStruct;
    fixedOnDate: NgbDateStruct;
    closeResult: string;

    partyView = true;
    controlMainSource = new FormControl();
    controlMainDest = new FormControl();
    controlMainCommodity = new FormControl();
    controlMainParty = new FormControl();

    sourceListOptions = [
        { id: 1, label: 'ANDHERI' },
        { id: 2, label: 'BUDHPUR' },
        { id: 3, label: 'CHENNAI' },
        { id: 4, label: 'DELHI' }
    ];

    gettingDataFrmServiceFrRateMasterTable: any;
    rateMasterDataList: any;
    onDestroyUnsubscribtionRateMaster: Subscription;

    gettingDataFrmServiceFrCommodityRateTable: any;
    commodityRateDataList: any;
    onDestroyUnsubscribtionCommodityRate: Subscription;

    gettingDataFrmServiceFrPartyRateTable: any;
    partyRateDataList: any;
    onDestroyUnsubscribtionPartyRate: Subscription;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerRateMaster: Subject<any> = new Subject();
    dtTriggerCommodityRate: Subject<any> = new Subject();
    dtTriggerPartyRate: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsRateMaster: any;
    dtOptionsCommodityRate: any;
    dtOptionsPartyRate: any;

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction: boolean = false;

    /*Drop Down start */
    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoAll: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

    lrDtoDestination: LRDto = new LRDto();
    destinationOptions: LRDto[];
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;


    sourceOptions: AgentDetailsDto[];
    sourceAgentDto: AgentDetailsDto = new AgentDetailsDto();
    lrDtoSourceAllOption: AgentDetailsDto = new AgentDetailsDto();
    public modelSource: any;
    sourceTA: Array<AgentDetailsDto> = [];

    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStation: string }) => x.subStation;

    partyDtoConsigneeName: PartyMasterDto = new PartyMasterDto();
    consigneeNameOptions: PartyMasterDto[];
    public modelConsigneeName: any;
    consigneeNameTA: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    searchConsigneeName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

    showSpinnerForActionForStopEffe: boolean = false;
    selectedDestinationForCommodity: any;
    selectedDestinationForConsigneeName: any;

    rateChangeTxtId = 'Rate';
    viewRateField: boolean = true;
    viewPercentageField: boolean = false;
    viewPercBSFrtField: boolean = true;

    rateMasterDtoGridReconfi1: RateMasterDto = new RateMasterDto();
    rateMasterDtoGridReconfi2: RateMasterDto = new RateMasterDto();
    rateMasterDtoOnLoadGridReconfi: RateMasterDto = new RateMasterDto();
    rateMasterDtoForSave: RateMasterDto[];
    rateMasterDtoForSaveReturn: any;
    rateMasterDtoForSaveReturnOfRecalcBkg: any;

    //rateMasterDtoForUpdate: RateMasterDto[];
    //rateMasterDtoForUpdateReturn: any;

    rateMasterDtoForSelectedRowBothTable: any;
    rateMasterDtoForUpdate: RateMasterDto = new RateMasterDto();
    rateMasterDtoForUpdateList: RateMasterDto[];
    rateMasterDtoForUpdateAny: RateMasterDto = new RateMasterDto();

    rateMasterDtoForDelete: RateMasterDto = new RateMasterDto();
    selectedRowDataDelete: any;
    stopEffDateValidate: any;

    validateGridReconfDestination: any;

    validateSelectedConsigneeId: any;
    validateSelectedDestination: any;

    private newAttributeTosetConsigneeAdminRateMaster: any = {};
    editConsigneeAdminRateMasterTableDetailsId: any;

    modalRefferenceStopRatePopUp: NgbModalRef;
    modalRefferenceStopRateClosePopUp: string;
    @ViewChild('stopRatePopUpTemplate') private stopRatePopUpTemplate;

    isRights: boolean = false;

    validateSelelctedSearchMode: any;
    validateSelelctedDestinationId: any;
    validateSelelctedConsigneeNameId: any;
    validateSelelctedCommodityListId: any;
    validateSelelctedSearchUnit: any;
    validateSelelctedRatePercentageId: any;
    validateSelelctedPercentageRateId: any;
    validateSelelctedHamaliDestinationChg: any;
    validateSelelctedServiceCharges: any;
    validateSelelctedServiceChargesUnit: any;
    validateSelelctedGcCharge: any;
    validateSelelctedSrcHamaliChg: any;
    validateSelelctedCustomHamaliSrcChg: any;
    validateSelelctedReciptCharge: any;
    validateSelelctedPerUnit: any;
    validateSelelctedStatus: any;
    validateSelelctedFixedBy: any;
    validateSelelctedEffectFromDate: any;
    validateSelelctedSource: any;
    validateSelelctedfixedOnDate: any;
    validateFovOn: any;
    validateFovRate: any;
    validateRateOnActWgt: any;

    viewGCChgField: boolean = true;
    viewPerUnitField: boolean = true;
    viewReciptChargeField: boolean = true;
    viewServiceChargeField: boolean = true;

    dtOptionsSourceCneeAdmin: any;
    dtTriggerSourceCneeAdmin: Subject<any> = new Subject();
    sourceDataListCneeAdmin: any;
    lrDtoListSourceCheckedCneeAdmin: Array<LRDto> = [];
    private newAttributeSetSourceListCneeAdmin: any = {};
    pageId = "carmc";
    boxChecked: any;
    rateMasterDtoForRateDeleteChecked: Array<Number> = [];
    viewFovRateField: boolean = false;
    fovRateChg: any;
    fovRate: any;
    viewRateOnActWgtField: boolean = false;
    rateOnActWgt: any;
    rateAtSrcCheckStatus: boolean = false;
    validateSelelctedUnloadingChg: any;
    validateSelelctedUnloadingChgUnit: any;
    validateSelelctedUnloadingChgGd: any;
    validateSelelctedUnloadingChgUnitGd: any;
    response: any;
    viewUnloadingChgField: boolean = false;
    viewLoadingChgSetup: boolean = false;
    customHamailSrcChgCheckStatus: boolean = false;
    showCustomHamailSrcChgCheck: boolean = false;

    //CHG_V-CM:02
    ///For Source Main Booking Station
    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelMainBookingSource: any;
    mainBookingSourceTA: Array<AgentDetailsDto> = [];
    focusMainBookingSourceTA$ = new Subject<string>();
    searchMainBookingSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainBookingSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainBookingSourceTA
                : this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;

    //
    /* For Source Drop Down Start */
    agentDetailsOptionsSet: AgentDetailsDto[];
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    public agentNameModal: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    agentNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    // source
    // subStation
    formatterAgentName = (x: { subStation: string }) => x.subStation;

    /* For Source Drop Down Start Search Party */
    agentDetailsOptionsSetForRateParty: AgentDetailsDto[];
    agentDtoParamForRateParty: AgentDetailsDto = new AgentDetailsDto();
    public agentNameRptSearchPartyModal: any;
    agentNameRptSearchPartyTA: Array<AgentDetailsDto> = [];
    focusAgentNameRptSearchPartyTA$ = new Subject<string>();
    agentNameSearchRptSearchPartyTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameRptSearchPartyTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameRptSearchPartyTA
                : this.agentNameRptSearchPartyTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    // source
    // subStation
    formatterAgentNameRptSearchParty = (x: { subStation: string }) => x.subStation;

    /* For Destination Drop Down search party*/
    public modelDestinationSearchParty: any;
    destinationSearchRptPartyTA: Array<LRDto> = [];
    focusDestinationSearchRptPartyTA$ = new Subject<string>();
    searchDestinationSearchRptParty = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationSearchRptPartyTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationSearchRptPartyTA
                : this.destinationSearchRptPartyTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestinationSearchRptParty = (x: { destination: string }) => x.destination;
    destinationDtoForAll: LRDto = new LRDto();

    /* Commodity Drop Down search party*/
    rateMasterDtoOptionsForRateParties: RateMasterDto[];
    public modelCommodityListSearchRptParty: any;
    commodityListSearchRptPartyTA: Array<RateMasterDto> = [];
    focusCommodityListSearchRptPartyTA$ = new Subject<string>();
    searchCommodityListSearchRptParty = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListSearchRptPartyTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListSearchRptPartyTA
                : this.commodityListSearchRptPartyTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityListSearchRptParty = (x: { commodityName: string }) => x.commodityName;

    /* Consignor  Drop Down search party*/
    consigneeNameOptionsForRateParties: PartyMasterDto[];
    public modelConsigneeNamePartySearch: any;
    consigneeNamePartySearchTA: Array<PartyMasterDto> = [];
    focusConsigneeNamePartySearchTA$ = new Subject<string>();
    consigneeNameSearchPartySearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNamePartySearchTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNamePartySearchTA
                : this.consigneeNamePartySearchTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeNamePartySearch = (x: { consigneeName: string }) => x.consigneeName;

    public mainBookingSourceForRatePartyModal: any;
    mainBookingSourceTAForRateParty: Array<AgentDetailsDto> = [];
    focusMainBookingSourceForRatePartyTA$ = new Subject<string>();
    mainBookingSourceForRateParty = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainBookingSourceForRatePartyTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainBookingSourceTAForRateParty
                : this.mainBookingSourceTAForRateParty.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainBookingSourceForRateParty = (x: { mainBookStations: string }) => x.mainBookStations;

    modelConsigneePartySearch: any;
    enteredConsigneeIdPartySearch: any;
    consigneeNameForAllOption: PartyMasterDto = new PartyMasterDto();
    selectedDestinationForCommodityRateParty: any;
    selectedDestinationForConsigneeNameRateParties: any;
    selectedSourceForPartySearch: any;
    selectedCommodityForPartySearch: any;
    selectedDestinationForPartySearch: any;
    selectedConsigneeForPartySearch: any;
    selectedMainSoureForPartySearch: any;
    showSpinnerForRateParties: boolean = false;

    constructor(
        private router: Router, private mastereadService: MasterReadService,
        private consigneeService: ConsigneeService,
        private modalService: NgbModal, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,
        private masterService: MasterService) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "disableEditOptn CsneAdmRateMtr") {
                        this.isRights = true;
                        //alert(this.isRights);
                    }
                    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights[i]);
                }
            }

            if (!this.isRights) {
                this.disableAllFields();
            }

            this.setOnload();

            this.getDestinationDetails();
            this.getSourceDetails();

            // this.onLoadCommodityGridReconfigure();
            // this.onLoadPartyGridReconfigure();
            this.getMainBookingSourceDetails();

            /*setTimeout(() => {
                $("#"+this.pageId+"nextBtnId").prop('disabled', false);
                $("#"+this.pageId+"saveBtnId").prop('disabled', false);
                $("#"+this.pageId+"updateBtnId").prop('disabled', true);
            }, 1000);*/
        }
    }

    setOnload() {
        $("#" + this.pageId + "rateChangeTxtId").text('Rate');
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {
        $("#" + this.pageId + "nextBtnId").prop('disabled', false);
        $("#" + this.pageId + "saveBtnId").prop('disabled', false);
        $("#" + this.pageId + "updateBtnId").prop('disabled', true);

        this.dtOptionsRateMaster = {
            dom: 'Bfrtip',
            buttons: [

            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 140,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        },
            this.dtOptionsCommodityRate = {
                dom: 'Bfrtip',
                buttons: [

                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            },
            this.dtOptionsPartyRate = {
                dom: 'Bfrtip',
                buttons: [

                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 250,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": true,
                select: true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            },
            this.dtOptionsSourceCneeAdmin = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": false,
                "scrollY": 140,
                "scrollCollapse": false,
                "paging": false,
                "info": false
            }
    }

    ngOnDestroy(): void {
        this.dtTriggerRateMaster.unsubscribe();
        this.dtTriggerCommodityRate.unsubscribe();
        this.dtTriggerPartyRate.unsubscribe();
        this.dtTriggerSourceCneeAdmin.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerRateMaster.next();
        this.dtTriggerCommodityRate.next();
        this.dtTriggerPartyRate.next();
        this.dtTriggerSourceCneeAdmin.next();
    }

    /* Drop Down Start */
    clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent) {
        this.modelCommodityList = e.item;
        $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
    }

    getCommodityMasterDetailsList = () => {
        //this.getCommodityListRead();
        this.rateMasterDto = new RateMasterDto();
        this.selectedDestinationForCommodity = $("#" + this.pageId + "destinationId").val()
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "mainConsigneeMaster";
        this.rateMasterDto.destination = this.selectedDestinationForCommodity;
        this.mastereadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                if (response) {
                    //console.log(response);
                    if (response.length == 0) {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;

                        this.rateMasterDtoAll = new RateMasterDto();
                        this.rateMasterDtoAll.commodityName = "ALL";
                        this.commodityListTA.push(this.rateMasterDtoAll);
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };



    clickListnerForDestinationList(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
        $("#" + this.pageId + "destinationId").focusout();

        if (this.modelDestination.destination == 'Madurai' || this.modelDestination.destination == 'Trivandrum') {
            this.viewUnloadingChgField = true;
        } else {
            this.viewUnloadingChgField = false;
        }
        //CHG_V-CM:01
        if (this.modelDestination.destination == 'Trivandrum') {
            this.viewLoadingChgSetup = true;
        } else {
            this.viewLoadingChgSetup = false;
        }

        if (this.rateMasterDataList == null ||
            this.rateMasterDataList == undefined ||
            this.rateMasterDataList == "" ||
            this.rateMasterDataList.length == 0) {
            this.rateMasterDataList = [];
        }

        if (this.rateMasterDataList != null &&
            this.rateMasterDataList != undefined &&
            this.rateMasterDataList != "" ||
            this.rateMasterDataList.length > 0) {
            const result = this.rateMasterDataList.filter(rateMasterData =>
                rateMasterData.destination !== $("#" + this.pageId + "destinationId").val());
            if (result.length > 0) {
                swal({
                    title: "Not Allowed",
                    text: "Please save or reset the entered details before changing the Destination",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    setTimeout(() => {
                        this.clearDestField();
                    }, 500);
                });
                return false;
            } else {
                this.getCommodityMasterDetailsList();
                this.getConsigneeDetails();
                this.gridReconfigureForCommodity();
            }
        } else {
            this.getCommodityMasterDetailsList();
            this.getConsigneeDetails();
            this.gridReconfigureForCommodity();
        }
    }

    clearDestField() {
        this.modelDestination = null;
        $("#" + this.pageId + "destinationId").val('');
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "LrForm";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.mastereadService.getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    //CHG_V-CM:02
                    this.destinationSearchRptPartyTA = [];
                    // this.destinationDtoForAll = new LRDto();
                    // this.destinationDtoForAll.destination = "ALL";
                    // this.destinationSearchRptPartyTA.push(this.destinationDtoForAll);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                        this.destinationSearchRptPartyTA.push(this.destinationOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log("done");
    };


    clickListnerForSource(e: NgbTypeaheadSelectItemEvent) {
        this.modelSource = e.item;
        $("#" + this.pageId + "source").val(this.modelSource.subStation);
    }

    getSourceDetails() {
        this.sourceAgentDto = new AgentDetailsDto();
        this.sourceAgentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.sourceAgentDto.mode = "Sub";
        this.sourceAgentDto.status = "ALL";
        this.getSourceMethod();
    }

    getSourceMethod = () => {
        this.showSpinnerForAction = true;
        this.mastereadService.getSourceListDetails(this.sourceAgentDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    this.sourceOptions = [];
                    this.sourceTA = [];
                } else {
                    //CHG_V-CM:02
                    // this.sourceDataListCneeAdmin = [];
                    // $("#" + this.pageId + "selectedSourceTableIdCneeAdmin").DataTable().destroy();
                    this.sourceOptions = [];
                    this.sourceTA = [];
                    this.sourceOptions = response;
                    for (let i = 0; i < this.sourceOptions.length; i++) {
                        this.sourceTA.push(this.sourceOptions[i]);
                    }
                    //CHG_V-CM:02
                    // this.sourceDataListCneeAdmin = response;
                }
                //CHG_V-CM:02
                // this.dtTriggerSourceCneeAdmin.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log("done");
    };

    clickListnerForConsigneeNameList(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsigneeName = e.item;
        $("#" + this.pageId + "consigneeNameId").val(this.modelConsigneeName.consigneeName);
        console.log(this.modelConsigneeName.consigneeId);
        if (this.rateMasterDataList == null ||
            this.rateMasterDataList == undefined ||
            this.rateMasterDataList == "" ||
            this.rateMasterDataList.length == 0) {
            this.rateMasterDataList = [];
        }

        if (this.rateMasterDataList != null &&
            this.rateMasterDataList != undefined &&
            this.rateMasterDataList != "" ||
            this.rateMasterDataList.length > 0) {
            const result = this.rateMasterDataList.filter(rateMasterData =>
                rateMasterData.consigneeName !== $("#" + this.pageId + "consigneeNameId").val());
            if (result.length > 0) {
                swal({
                    title: "Not Allowed",
                    text: "Please save or reset the entered details before changing the Consignee Name",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    setTimeout(() => {
                        this.clearConsigneeField();
                    }, 500);
                });
                return false;
            } else {
                this.validateSelectedConsigneeId = 0;
                this.validateSelectedConsigneeId = this.modelConsigneeName.consigneeId;
            }
        } else {
            this.validateSelectedConsigneeId = 0;
            this.validateSelectedConsigneeId = this.modelConsigneeName.consigneeId;
        }
    }

    clearConsigneeField() {
        this.modelConsigneeName = null;
        $("#" + this.pageId + "consigneeNameId").val('');
    }

    getConsigneeDetailsList() {
        this.partyDtoConsigneeName = new PartyMasterDto();
        this.selectedDestinationForConsigneeName = $("#" + this.pageId + "destinationId").val();
        this.partyDtoConsigneeName.companyId = this.userDataDtoReturnSession.companyId;
        this.partyDtoConsigneeName.office = this.selectedDestinationForConsigneeName;
        this.partyDtoConsigneeName.mode = "specific";
    }

    getConsigneeDetails = () => {
        this.getConsigneeDetailsList();
        this.showSpinnerForAction = true;
        this.mastereadService.getConsigneeMaster(this.partyDtoConsigneeName).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                } else {
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                    this.consigneeNameOptions = response;
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };

    /*Drop Down End*/

    searchModeMethod(searchTypes: string) {
        if (searchTypes === 'party') {

            if (this.rateMasterDataList == null ||
                this.rateMasterDataList == undefined ||
                this.rateMasterDataList == "" ||
                this.rateMasterDataList.length == 0) {
                this.rateMasterDataList = [];
            }

            if (this.rateMasterDataList != null &&
                this.rateMasterDataList != undefined &&
                this.rateMasterDataList != "" ||
                this.rateMasterDataList.length > 0) {
                const result = this.rateMasterDataList.filter(rateMasterData =>
                    rateMasterData.editValiateMode !== $("#" + this.pageId + "searchMode").val());
                if (result.length > 0) {
                    swal({
                        title: "Not Allowed",
                        text: "Please save or reset the entered details before changing the Rate Type",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        setTimeout(() => {
                            this.clearDestField();
                        }, 500);
                    });
                    return false;
                } else {
                    this.partyView = true;
                    this.modelConsigneeName = null;
                    this.validateSelectedConsigneeId = 0;
                }
            } else {
                this.partyView = true;
                this.modelConsigneeName = null;
                this.validateSelectedConsigneeId = 0;
            }
        } else if (searchTypes === 'commodity') {
            if (this.rateMasterDataList == null ||
                this.rateMasterDataList == undefined ||
                this.rateMasterDataList == "" ||
                this.rateMasterDataList.length == 0) {
                this.rateMasterDataList = [];
            }

            if (this.rateMasterDataList != null &&
                this.rateMasterDataList != undefined &&
                this.rateMasterDataList != "" ||
                this.rateMasterDataList.length > 0) {
                const result = this.rateMasterDataList.filter(rateMasterData =>
                    rateMasterData.editValiateMode !== $("#" + this.pageId + "searchMode").val());
                if (result.length > 0) {
                    swal({
                        title: "Not Allowed",
                        text: "Please save or reset the entered details before changing the Rate Type",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        setTimeout(() => {
                            this.clearDestField();
                        }, 500);
                    });
                    return false;
                } else {
                    this.partyView = false;
                    this.modelConsigneeName = null;
                    this.validateSelectedConsigneeId = 0;
                }
            } else {
                this.partyView = false;
                this.modelConsigneeName = null;
                this.validateSelectedConsigneeId = 0;
            }
        } else if (searchTypes === 'rateForAll') {
            this.partyView = false;
            this.modelConsigneeName = null;
            this.validateSelectedConsigneeId = 0;

            if (this.isRights) {
                swal({
                    title: "Must Care",
                    text: "The changes done through this can not be Undo..So Please take most care...,Sure you want to open this pop up",
                    icon: "info",
                    buttons: ["No", "Yes"]
                }).then((isConfirm) => {
                    if (isConfirm) {
                        //localStorage.clear();
                        //localStorage.setItem('LRIssueBalRptPopUp', JSON.stringify(issueBalanceData));
                        const dialogRef = this.dialog.open(UpdateConsigneeAdminRateForAllComponent, {
                        });

                        dialogRef.afterClosed().subscribe(result => {
                            console.log(`Dialog result: ${result}`);
                            if (result == "Save") {
                                swal("Alert", "Rate Changes Updated Successfully", "success");
                                this.rateMasterDtoForSave = [];
                                this.rateMasterDtoForSaveReturn = null;
                                this.clearFieldAll();
                            } else if (result == "Empty") {
                                swal("No Changes", "No Related Records Found To Update", "warning");
                                this.rateMasterDtoForSave = [];
                                this.rateMasterDtoForSaveReturn = null;
                                this.clearFieldAll();
                            } else if (result == "Close") {
                                // swal("No Changes", "No Related Records Found To Update", "warning");
                            }
                        });
                    }
                });
            } else {
                swal("Not Allowed", "User " + this.userDataDtoReturnSession.userId + " Not Allowing to Changes Rate For All...");
            }
        } else {
            this.partyView = false;
        }
    }

    searchUnitMethod(searchUnit: string) {
        if (searchUnit === 'rate') {
            this.viewRateField = true;
            this.viewPercentageField = false;
            this.viewPercBSFrtField = true;

            this.viewGCChgField = true;
            this.viewPerUnitField = true;

            this.viewReciptChargeField = true;
            this.viewServiceChargeField = true;

            $("#" + this.pageId + "lcChg").val('no');
            $("#" + this.pageId + "hamaliSrcChg").val('no');
            $("#" + this.pageId + "bcChg").val('no');
            $("#" + this.pageId + "aocChg").val('no');

            $("#" + this.pageId + "fovChg").val('no');
            $("#" + this.pageId + "fovRate").val("");
            $("#" + this.pageId + "fovRateChg").val("");
            this.fovRate = null;
            this.fovRateChg = null;
            this.viewFovRateField = false;

            $("#" + this.pageId + "ddChg").val('no');
            $("#" + this.pageId + "othersChg").val('no');

        } else if (searchUnit === 'percentage') {
            this.viewRateField = false;
            this.viewPercentageField = true;
            this.viewPercBSFrtField = false;

            if ($("#" + this.pageId + "searchUnitPerc").val() == 'PLR') {
                this.viewGCChgField = false;
                this.viewPerUnitField = false;

                this.viewReciptChargeField = true;
                this.viewServiceChargeField = true;
            } else if ($("#" + this.pageId + "searchUnitPerc").val() == 'PCM') {
                this.viewGCChgField = false;
                this.viewPerUnitField = false;

                this.viewReciptChargeField = false;
                this.viewServiceChargeField = false;
            } else if ($("#" + this.pageId + "searchUnitPerc").val() == "PFRT") {
                this.viewGCChgField = true;
                this.viewPerUnitField = true;

                this.viewReciptChargeField = true;
                this.viewServiceChargeField = true;
            } else {
                this.viewGCChgField = false;
                this.viewPerUnitField = false;

                this.viewReciptChargeField = true;
                this.viewServiceChargeField = true;
            }



            $("#" + this.pageId + "lcChg").val('no');
            $("#" + this.pageId + "hamaliSrcChg").val('no');
            $("#" + this.pageId + "bcChg").val('no');
            $("#" + this.pageId + "aocChg").val('no');

            $("#" + this.pageId + "fovChg").val('no');
            $("#" + this.pageId + "fovRate").val("");
            $("#" + this.pageId + "fovRateChg").val("");
            this.fovRate = null;
            this.fovRateChg = null;
            this.viewFovRateField = false;

            $("#" + this.pageId + "ddChg").val('no');
            $("#" + this.pageId + "othersChg").val('no');
        } else {
            this.viewRateField = false;
            this.viewPercentageField = false;
            this.viewPercBSFrtField = false;
        }
    }

    searchRateMethod(searchRate: string) {
        if (searchRate === 'fixedRate') {
            this.rateChangeTxtId = 'Rate';
        } else if (searchRate === 'DiscRate') {
            this.rateChangeTxtId = 'Discount Rate (Rs)';
        } else {
            this.rateChangeTxtId = 'Rate';
        }
    }

    searchUnitPercMethod(searchUnitPerc: string) {
        if (searchUnitPerc === 'PLR') {
            this.viewPercBSFrtField = false;

            this.viewGCChgField = false;
            this.viewPerUnitField = false;

            this.viewReciptChargeField = true;
            this.viewServiceChargeField = true;
        } else if (searchUnitPerc === 'PCM') {
            this.viewPercBSFrtField = false;

            this.viewGCChgField = false;
            this.viewPerUnitField = false;

            this.viewReciptChargeField = false;
            this.viewServiceChargeField = false;
        } else if (searchUnitPerc === 'PFRT') {
            this.viewPercBSFrtField = true;

            this.viewGCChgField = false;
            this.viewPerUnitField = false;

            this.viewReciptChargeField = true;
            this.viewServiceChargeField = true;
        } else {
            this.viewPercBSFrtField = false;
        }
    }

    //CHG_V-CM:02
    // onLoadCommodityGridReconfigure() {
    //     this.rateMasterDtoOnLoadGridReconfi = new RateMasterDto();
    //     this.rateMasterDtoOnLoadGridReconfi.city = this.userDataDtoReturnSession.mainStation;
    //     this.rateMasterDtoOnLoadGridReconfi.mode = "specific";
    //     this.rateMasterDtoOnLoadGridReconfi.rptModeId = 1;
    //     this.rateMasterDtoOnLoadGridReconfi.companyId = this.userDataDtoReturnSession.companyId;
    //     //this.showSpinnerForAction = true;
    //     this.consigneeService.getConsigneeAdminRateMasterDetails(this.rateMasterDtoOnLoadGridReconfi).subscribe(
    //         (response) => {
    //             //this.showSpinnerForAction = false;
    //             $("#" + this.pageId + "consigneeAdminMasterCommDetailsTableId").DataTable().destroy();
    //             this.commodityRateDataList = [];
    //             if (response.length == 0) {
    //                 swal("Warning", "No Commodity Details found !", "warning");
    //             } else {
    //                 this.commodityRateDataList = response;
    //                 console.log("this.response");
    //                 console.log(this.response);
    //                 console.log(this.commodityRateDataList);
    //             }
    //             this.dtTriggerCommodityRate.next();
    //             this.changeDetectorRef.detectChanges();
    //         }), (error) => {
    //             this.showSpinnerForAction = false;
    //             swal("Error", "Server Problem Occurred While getting the Commodity Rate details", "info");
    //         }, () => console.log('done');
    // }

    //CHG_V-CM:02
    // onLoadPartyGridReconfigure() {
    //     this.rateMasterDtoOnLoadGridReconfi = new RateMasterDto();
    //     this.rateMasterDtoOnLoadGridReconfi.city = this.userDataDtoReturnSession.mainStation;
    //     this.rateMasterDtoOnLoadGridReconfi.mode = "specific";
    //     this.rateMasterDtoOnLoadGridReconfi.rptModeId = 0;
    //     this.rateMasterDtoOnLoadGridReconfi.companyId = this.userDataDtoReturnSession.companyId;
    //     this.showSpinnerForAction = true;

    //     this.consigneeService.getConsigneeAdminRateMasterDetails(this.rateMasterDtoOnLoadGridReconfi).subscribe(
    //         (response) => {
    //             this.showSpinnerForAction = false;
    //             $("#" + this.pageId + "consigneeAdminMasterPartyDetailsTableId").DataTable().destroy();
    //             this.partyRateDataList = [];
    //             if (response.length == 0) {
    //                 swal("Warning", "No Party Details found !", "warning");
    //             } else {
    //                 this.partyRateDataList = response;
    //                 console.log(this.partyRateDataList);
    //             }
    //             this.dtTriggerPartyRate.next();
    //             this.changeDetectorRef.detectChanges();
    //         }), (error) => {
    //             this.showSpinnerForAction = false;
    //             swal("Error", "Server Problem Occurred While getting the Commodity Rate details", "info");
    //         }, () => console.log('done');
    // }



    gridReconfigureForCommodity() {
        this.rateMasterDtoOnLoadGridReconfi = new RateMasterDto();
        if ($("#" + this.pageId + "destinationId").val() != null &&
            $("#" + this.pageId + "destinationId").val() != undefined &&
            $("#" + this.pageId + "destinationId").val() != "") {
            this.validateGridReconfDestination = $("#" + this.pageId + "destinationId").val();
            this.rateMasterDtoOnLoadGridReconfi.city = this.validateGridReconfDestination;
        } else {
            this.rateMasterDtoOnLoadGridReconfi.city = this.userDataDtoReturnSession.mainStation;
        }
        this.rateMasterDtoOnLoadGridReconfi.mode = "specific";
        this.rateMasterDtoOnLoadGridReconfi.rptModeId = 1;
        this.rateMasterDtoOnLoadGridReconfi.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        console.log(this.rateMasterDtoOnLoadGridReconfi);
        //console.log(this.validateGridReconfDestination);
        this.consigneeService.getConsigneeAdminRateMasterDetails(this.rateMasterDtoOnLoadGridReconfi).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "consigneeAdminMasterCommDetailsTableId").DataTable().destroy();
                this.commodityRateDataList = [];
                if (response.length == 0) {
                    swal("Warning", "No Commodity Details found !", "warning");
                    // this.gridReconfigureForParty();
                } else {
                    this.commodityRateDataList = response;
                    console.log("this.response");
                    console.log(this.response);
                    console.log(this.commodityRateDataList);
                    // this.gridReconfigureForParty();
                }
                this.dtTriggerCommodityRate.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Commodity Rate details", "info");
            }, () => console.log('done');
    }

    //CHG_V-CM:02
    // gridReconfigureForParty() {
    //     this.rateMasterDtoOnLoadGridReconfi = new RateMasterDto();
    //     if ($("#" + this.pageId + "destinationId").val() != null &&
    //         $("#" + this.pageId + "destinationId").val() != undefined &&
    //         $("#" + this.pageId + "destinationId").val() != "") {
    //         this.validateGridReconfDestination = $("#" + this.pageId + "destinationId").val();
    //         this.rateMasterDtoOnLoadGridReconfi.city = this.validateGridReconfDestination;
    //     } else {
    //         this.rateMasterDtoOnLoadGridReconfi.city = this.userDataDtoReturnSession.mainStation;
    //     }
    //     this.rateMasterDtoOnLoadGridReconfi.mode = "specific";
    //     this.rateMasterDtoOnLoadGridReconfi.rptModeId = 0;
    //     this.rateMasterDtoOnLoadGridReconfi.companyId = this.userDataDtoReturnSession.companyId;
    //     this.showSpinnerForAction = true;
    //     console.log(this.rateMasterDtoOnLoadGridReconfi);
    //     //console.log(this.validateGridReconfDestination);
    //     this.consigneeService.getConsigneeAdminRateMasterDetails(this.rateMasterDtoOnLoadGridReconfi).subscribe(
    //         (response) => {
    //             console.log(this.partyRateDataList);
    //             this.showSpinnerForAction = false;
    //             $("#" + this.pageId + "consigneeAdminMasterPartyDetailsTableId").DataTable().destroy();
    //             this.partyRateDataList = [];
    //             if (response.length == 0) {
    //                 swal("Warning", "No Party Details found !", "warning");
    //             } else {
    //                 this.partyRateDataList = response;
    //             }
    //             this.dtTriggerPartyRate.next();
    //             this.changeDetectorRef.detectChanges();
    //         }), (error) => {
    //             this.showSpinnerForAction = false;
    //             swal("Error", "Server Problem Occurred While getting the Commodity Rate details", "info");
    //         }, () => console.log('done');
    // }


    validateNextBtn() {
        this.filledEntryValuesInGrid();
    }

    filledEntryValuesInGrid() {
        this.addIntoTableOfConsigneeAdminRateDetails();
    }

    addIntoTableOfConsigneeAdminRateDetails() {
        console.log("this.lrDtoListSourceCheckedCneeAdmin  >> addIntoTableOfConsigneeAdminRateDetails()");
        console.log(this.lrDtoListSourceCheckedCneeAdmin);
        if (this.lrDtoListSourceCheckedCneeAdmin != null && this.lrDtoListSourceCheckedCneeAdmin != undefined &&
            this.lrDtoListSourceCheckedCneeAdmin.length > 0) {
            for (let i = 0; i < this.lrDtoListSourceCheckedCneeAdmin.length; i++) {

                this.newAttributeTosetConsigneeAdminRateMaster = {};

                //this.newAttributeTosetConsigneeAdminRateMaster.mode = $("#"+this.pageId+"searchMode").val();
                this.newAttributeTosetConsigneeAdminRateMaster.editValiateMode = $("#" + this.pageId + "searchMode").val();

                if ($("#" + this.pageId + "searchMode").val() == "commodity") {
                    this.validateSelectedDestination = $("#" + this.pageId + "destinationId").val();
                    this.newAttributeTosetConsigneeAdminRateMaster.consigneeId = 1;
                    this.newAttributeTosetConsigneeAdminRateMaster.destination = this.validateSelectedDestination;
                } else if ($("#" + this.pageId + "searchMode").val() == "party") {
                    this.validateSelectedDestination = $("#" + this.pageId + "destinationId").val();
                    this.newAttributeTosetConsigneeAdminRateMaster.consigneeName = $("#" + this.pageId + "consigneeNameId").val();
                    this.newAttributeTosetConsigneeAdminRateMaster.consigneeId = this.validateSelectedConsigneeId;
                    //this.newAttributeTosetConsigneeAdminRateMaster.destination = this.validateSelectedDestination;
                }

                this.newAttributeTosetConsigneeAdminRateMaster.commodityName = $("#" + this.pageId + "commodityListId").val();
                if ($("#" + this.pageId + "searchUnit").val() == "rate") {
                    this.newAttributeTosetConsigneeAdminRateMaster.rate = $("#" + this.pageId + "ratePercentageId").val();
                } else if ($("#" + this.pageId + "searchUnit").val() == "percentage") {
                    this.newAttributeTosetConsigneeAdminRateMaster.rate = $("#" + this.pageId + "percentageRateId").val();
                }

                var unitDiscount = $("#" + this.pageId + "searchUnit").val();
                if ($("#" + this.pageId + "searchUnit").val() != null && $("#" + this.pageId + "searchUnit").val() != undefined &&
                    $("#" + this.pageId + "searchUnit").val() == "percentage") {
                    this.newAttributeTosetConsigneeAdminRateMaster.unit = "Discount";
                    if ($("#" + this.pageId + "searchUnitPerc").val() == "PLR") {
                        this.newAttributeTosetConsigneeAdminRateMaster.column2 = "onLr";
                    } else if ($("#" + this.pageId + "searchUnitPerc").val() == "PCM") {
                        this.newAttributeTosetConsigneeAdminRateMaster.column2 = "onTotal";
                    } else if ($("#" + this.pageId + "searchUnitPerc").val() == "PFRT") {
                        this.newAttributeTosetConsigneeAdminRateMaster.column2 = "onBaseFrgt";
                    }
                } else {
                    this.newAttributeTosetConsigneeAdminRateMaster.unit = "Rate";
                }

                //this.newAttributeTosetConsigneeAdminRateMaster.hamaliSource = null;

                if ($("#" + this.pageId + "hamaliDestinationChg").val() == null || $("#" + this.pageId + "hamaliDestinationChg").val() == undefined ||
                    $("#" + this.pageId + "hamaliDestinationChg").val() == "") {
                    this.newAttributeTosetConsigneeAdminRateMaster.hamaliDestination = 0;
                } else {
                    this.newAttributeTosetConsigneeAdminRateMaster.hamaliDestination = $("#" + this.pageId + "hamaliDestinationChg").val();
                }

                if ($("#" + this.pageId + "serviceCharge").val() == null || $("#" + this.pageId + "serviceCharge").val() == undefined ||
                    $("#" + this.pageId + "serviceCharge").val() == "") {
                    this.newAttributeTosetConsigneeAdminRateMaster.serviceCharges = 0;
                } else {
                    this.newAttributeTosetConsigneeAdminRateMaster.serviceCharges = $("#" + this.pageId + "serviceCharge").val();
                    //CHG_V-CM:01
                    if (this.viewLoadingChgSetup) {
                        this.newAttributeTosetConsigneeAdminRateMaster.serviceChargesUnit = $("#" + this.pageId + "unitServiceChg").val();
                    }
                    else {
                        this.newAttributeTosetConsigneeAdminRateMaster.serviceChargesUnit = null;
                    }
                }

                if ($("#" + this.pageId + "gcCharge").val() == null || $("#" + this.pageId + "gcCharge").val() == undefined || $("#" + this.pageId + "gcCharge").val() == "") {
                    this.newAttributeTosetConsigneeAdminRateMaster.gcCharge = 0;
                } else {
                    this.newAttributeTosetConsigneeAdminRateMaster.gcCharge = $("#" + this.pageId + "gcCharge").val();
                }

                if ($("#" + this.pageId + "reciptCharge").val() == null || $("#" + this.pageId + "reciptCharge").val() == undefined ||
                    $("#" + this.pageId + "reciptCharge").val() == "") {
                    this.newAttributeTosetConsigneeAdminRateMaster.receiptCharges = 0;
                } else {
                    this.newAttributeTosetConsigneeAdminRateMaster.receiptCharges = $("#" + this.pageId + "reciptCharge").val();
                }

                if ($("#" + this.pageId + "perUnit").val() == null || $("#" + this.pageId + "perUnit").val() == undefined || $("#" + this.pageId + "perUnit").val() == "") {
                    this.newAttributeTosetConsigneeAdminRateMaster.perUnit = "";
                } else {
                    this.newAttributeTosetConsigneeAdminRateMaster.perUnit = $("#" + this.pageId + "perUnit").val();
                    this.newAttributeTosetConsigneeAdminRateMaster.rateOnActWgt = $("#" + this.pageId + "rateOnActWgt").val();
                    // alert(this.newAttributeTosetConsigneeAdminRateMaster.rateOnActWgt);
                }

                this.newAttributeTosetConsigneeAdminRateMaster.status = $("#" + this.pageId + "status").val();
                this.newAttributeTosetConsigneeAdminRateMaster.fixedBy = $("#" + this.pageId + "fixedBy").val();
                this.newAttributeTosetConsigneeAdminRateMaster.effectiveFromDate = $("#" + this.pageId + "effectFromDate").val();
                this.newAttributeTosetConsigneeAdminRateMaster.lastModifiedBy = this.userDataDtoReturnSession.userId;
                this.newAttributeTosetConsigneeAdminRateMaster.enteredBy = this.userDataDtoReturnSession.userId;
                // this.newAttributeTosetConsigneeAdminRateMaster.source = $("#"+this.pageId+"source").val();
                this.newAttributeTosetConsigneeAdminRateMaster.source = this.lrDtoListSourceCheckedCneeAdmin[i].subStation;
                this.newAttributeTosetConsigneeAdminRateMaster.fixedDate = $("#" + this.pageId + "fixedOnDate").val();
                this.newAttributeTosetConsigneeAdminRateMaster.commidtyForRpt = "";
                this.newAttributeTosetConsigneeAdminRateMaster.companyId = this.userDataDtoReturnSession.companyId;
                //this.newAttributeTosetConsigneeAdminRateMaster.lastModifiedBy = this.userDataDtoReturnSession.companyId;
                //this.newAttributeTosetConsigneeAdminRateMaster.enteredby = this.userDataDtoReturnSession.companyId;
                this.newAttributeTosetConsigneeAdminRateMaster.rateAtSrcCheck = this.rateAtSrcCheckStatus;
                if ($("#" + this.pageId + "srcHamaliChg").val() == null || $("#" + this.pageId + "srcHamaliChg").val() == undefined || $("#" + this.pageId + "srcHamaliChg").val() == "") {
                    this.newAttributeTosetConsigneeAdminRateMaster.srcHamaliChg = 0;
                } else {
                    this.newAttributeTosetConsigneeAdminRateMaster.srcHamaliChg = $("#" + this.pageId + "srcHamaliChg").val();
                }

                this.newAttributeTosetConsigneeAdminRateMaster.hamaliSrcCustom = this.customHamailSrcChgCheckStatus;
                if ($("#" + this.pageId + "customHamaliSrcChg").val() == null || $("#" + this.pageId + "customHamaliSrcChg").val() == undefined || $("#" + this.pageId + "customHamaliSrcChg").val() == "") {
                    this.newAttributeTosetConsigneeAdminRateMaster.customHamaliSrcChg = 0;
                } else {
                    this.newAttributeTosetConsigneeAdminRateMaster.customHamaliSrcChg = $("#" + this.pageId + "customHamaliSrcChg").val();
                }


                if (this.viewPercBSFrtField) {
                    if ($("#" + this.pageId + "lcChg").val() == "yes") {
                        this.newAttributeTosetConsigneeAdminRateMaster.lcChg = true;
                    } else {
                        this.newAttributeTosetConsigneeAdminRateMaster.lcChg = false;
                    }

                    if ($("#" + this.pageId + "bcChg").val() == "yes") {
                        this.newAttributeTosetConsigneeAdminRateMaster.bcChg = true;
                    } else {
                        this.newAttributeTosetConsigneeAdminRateMaster.bcChg = false;
                    }

                    if ($("#" + this.pageId + "aocChg").val() == "yes") {
                        this.newAttributeTosetConsigneeAdminRateMaster.AOC = true;
                        this.newAttributeTosetConsigneeAdminRateMaster.aocChgStr = "Yes";
                    } else {
                        this.newAttributeTosetConsigneeAdminRateMaster.AOC = false;
                        this.newAttributeTosetConsigneeAdminRateMaster.aocChgStr = "No"
                    }

                    if ($("#" + this.pageId + "fovChg").val() == "yes") {
                        this.newAttributeTosetConsigneeAdminRateMaster.riskCharge = true;
                        this.newAttributeTosetConsigneeAdminRateMaster.fovOn = $("#" + this.pageId + "fovRate").val();
                        this.newAttributeTosetConsigneeAdminRateMaster.fovPerc = $("#" + this.pageId + "fovRateChg").val();
                    } else {
                        this.newAttributeTosetConsigneeAdminRateMaster.riskCharge = false;
                        this.newAttributeTosetConsigneeAdminRateMaster.fovOn = null;
                        this.newAttributeTosetConsigneeAdminRateMaster.fovPerc = null;
                    }

                    if ($("#" + this.pageId + "ddChg").val() == "yes") {
                        this.newAttributeTosetConsigneeAdminRateMaster.DD = true;
                        this.newAttributeTosetConsigneeAdminRateMaster.ddChgStr = "Yes";
                    } else {
                        this.newAttributeTosetConsigneeAdminRateMaster.DD = false;
                        this.newAttributeTosetConsigneeAdminRateMaster.ddChgStr = "No";
                    }

                    if ($("#" + this.pageId + "othersChg").val() == "yes") {
                        this.newAttributeTosetConsigneeAdminRateMaster.others = true;
                    } else {
                        this.newAttributeTosetConsigneeAdminRateMaster.others = false;
                    }

                    if ($("#" + this.pageId + "hamaliSrcChg").val() == "yes") {
                        this.newAttributeTosetConsigneeAdminRateMaster.hamaliChg = true;
                    } else {
                        this.newAttributeTosetConsigneeAdminRateMaster.hamaliChg = false;
                    }
                } else {
                    this.newAttributeTosetConsigneeAdminRateMaster.lcChg = false;
                    this.newAttributeTosetConsigneeAdminRateMaster.bcChg = false;
                    this.newAttributeTosetConsigneeAdminRateMaster.AOC = false;
                    this.newAttributeTosetConsigneeAdminRateMaster.riskCharge = false;
                    this.newAttributeTosetConsigneeAdminRateMaster.DD = false;
                    this.newAttributeTosetConsigneeAdminRateMaster.others = false;
                    this.newAttributeTosetConsigneeAdminRateMaster.hamaliChg = false;
                }

                this.newAttributeTosetConsigneeAdminRateMaster.isFixedRate = true;
                if ($("#" + this.pageId + "searchRate").val() == "DiscRate") {
                    this.newAttributeTosetConsigneeAdminRateMaster.isFixedRate = false;
                }

                if ($("#" + this.pageId + "searchUnit").val() != null && $("#" + this.pageId + "searchUnit").val() != undefined &&
                    $("#" + this.pageId + "searchUnit").val() == "percentage") {
                    this.newAttributeTosetConsigneeAdminRateMaster.rateType =
                        this.newAttributeTosetConsigneeAdminRateMaster.column2;
                } else {
                    if (this.newAttributeTosetConsigneeAdminRateMaster.isFixedRate) {
                        this.newAttributeTosetConsigneeAdminRateMaster.rateType = "Fixed Rate";
                    } else {
                        this.newAttributeTosetConsigneeAdminRateMaster.rateType = "Discount Rate";
                    }
                }
                console.log("this.newAttributeTosetConsigneeAdminRateMaster");
                console.log(this.newAttributeTosetConsigneeAdminRateMaster);
                //here start main  logic 
                if (this.editConsigneeAdminRateMasterTableDetailsId != null &&
                    this.editConsigneeAdminRateMasterTableDetailsId != undefined) {
                    this.rateMasterDataList.splice(this.editConsigneeAdminRateMasterTableDetailsId, 1);
                }

                if (this.rateMasterDataList == null ||
                    this.rateMasterDataList == undefined ||
                    this.rateMasterDataList.length == 0) {
                    this.rateMasterDataList = [];
                }

                if (this.rateMasterDataList.length == 0) {
                    this.rateMasterDataList = [];
                    $("#" + this.pageId + "consigneeAdminMasterDraftDetailsTableId").DataTable().destroy();
                    this.rateMasterDataList.push(this.newAttributeTosetConsigneeAdminRateMaster);
                    this.dtTriggerRateMaster.next();
                    // this.clearFieldConsigneeAdminRate();
                } else {
                    const result = this.rateMasterDataList.filter(rateMasterData =>
                        rateMasterData.commodityName === this.newAttributeTosetConsigneeAdminRateMaster.commodityName &&
                        rateMasterData.source === this.newAttributeTosetConsigneeAdminRateMaster.source);
                    if (result.length > 0) {
                        swal("Duplicate Entry", "Entered Rate details already exist!Please check", "warning");
                        return false;
                    } else {
                        $("#" + this.pageId + "consigneeAdminMasterDraftDetailsTableId").DataTable().destroy();
                        this.rateMasterDataList.push(this.newAttributeTosetConsigneeAdminRateMaster);
                        this.dtTriggerRateMaster.next();
                        // this.clearFieldConsigneeAdminRate();
                    }
                }

                this.newAttributeTosetConsigneeAdminRateMaster.unloadingChg = $("#" + this.pageId + "unloadingChg").val();
                this.newAttributeTosetConsigneeAdminRateMaster.unloadingChgUnit = $("#" + this.pageId + "unloadingChgUnit").val();
                this.newAttributeTosetConsigneeAdminRateMaster.unloadingChgGd = $("#" + this.pageId + "unloadingChgGd").val();
                this.newAttributeTosetConsigneeAdminRateMaster.unloadingChgUnitGd = $("#" + this.pageId + "unloadingChgUnitGd").val();
            }
        }
        this.clearFieldConsigneeAdminRate();
        this.lrDtoListSourceCheckedCneeAdmin = [];
        this.newAttributeSetSourceListCneeAdmin = {};
        $("#carmcselectedSource").prop("checked", false);
    }

    rowSelectedConsigneeAdminDataOnEdit(rateMasterData, index) {
        this.editConsigneeAdminRateMasterTableDetailsId = index;
        console.log(rateMasterData);
        this.validateSelectedConsigneeId = null;

        if (rateMasterData.editValiateMode == "commodity") {
            $("#" + this.pageId + "destinationId").val(rateMasterData.destination);
        } else if (rateMasterData.editValiateMode == "party") {
            $("#" + this.pageId + "consigneeNameId").val(rateMasterData.consigneeName);
            $("#" + this.pageId + "destinationId").val(rateMasterData.destination);
            this.validateSelectedConsigneeId = rateMasterData.consigneeId;
        }

        $("#" + this.pageId + "commodityListId").val(rateMasterData.commodityName);
        if (rateMasterData.unit == "Rate") {
            this.viewPercBSFrtField = true;
            this.viewRateField = true;
            this.viewPercentageField = false;
        } else if (rateMasterData.unit == "Discount") {
            this.viewPercBSFrtField = false;
            this.viewRateField = false;
            this.viewPercentageField = true;
        }
        /* if ($("#"+this.pageId+"searchUnitPerc").val() == "PLR") {
             this.newAttributeTosetConsigneeAdminRateMaster.column2 = "onLr";
         } else if ($("#"+this.pageId+"searchUnitPerc").val() == "PCM") {
             this.newAttributeTosetConsigneeAdminRateMaster.column2 = "onTotal";
         } else if ($("#"+this.pageId+"searchUnitPerc").val() == "PFRT") {
             this.newAttributeTosetConsigneeAdminRateMaster.column2 = "onBaseFrgt";
         }*/
        this.showSpinnerForAction = true;
        setTimeout(() => {
            if (this.viewRateField && !this.viewPercentageField) {
                $("#" + this.pageId + "searchUnit").val('rate');
                $("#" + this.pageId + "ratePercentageId").val(rateMasterData.rate);
                this.viewPerUnitField = true;
                this.viewGCChgField = true;
            } else if (this.viewPercentageField && !this.viewRateField) {
                if (rateMasterData.column2 == "onLr") {
                    $("#" + this.pageId + "searchUnitPerc").val('PLR');
                    this.viewPerUnitField = true;
                    this.viewGCChgField = true;
                } else if (rateMasterData.column2 == "onTotal") {
                    $("#" + this.pageId + "searchUnitPerc").val('PCM');
                    this.viewPerUnitField = false;
                    this.viewGCChgField = false;
                } else if (rateMasterData.column2 == "onBaseFrgt") {
                    $("#" + this.pageId + "searchUnitPerc").val('PFRT');
                    this.viewPerUnitField = true;
                    this.viewGCChgField = true;
                }
                $("#" + this.pageId + "searchUnit").val('percentage');
                $("#" + this.pageId + "percentageRateId").val(rateMasterData.rate);
            }
            this.showSpinnerForAction = false;
        }, 2000);

        $("#" + this.pageId + "hamaliDestinationChg").val(rateMasterData.hamaliDestination);
        $("#" + this.pageId + "serviceCharge").val(rateMasterData.serviceCharges);
        $("#" + this.pageId + "unitServiceChg").val(rateMasterData.serviceChargesUnit);
        $("#" + this.pageId + "gcCharge").val(rateMasterData.gcCharge);
        $("#" + this.pageId + "srcHamaliChg").val(rateMasterData.srcHamaliChg);
        $("#" + this.pageId + "reciptCharge").val(rateMasterData.receiptCharges);

        $("#" + this.pageId + "customHamaliSrcChg").val(rateMasterData.customHamaliSrcChg);

        $("#" + this.pageId + "perUnit").val(rateMasterData.perUnit);
        if (rateMasterData.perUnit == "Actual Weight") {
            $("#" + this.pageId + "rateOnActWgt").val(rateMasterData.rateOnActWgt);
            this.rateOnActWgt = rateMasterData.rateOnActWgt;
            this.viewRateOnActWgtField = true;
        } else {
            $("#" + this.pageId + "rateOnActWgt").val('');
            this.rateOnActWgt = null;
            this.viewRateOnActWgtField = false;
        }

        $("#" + this.pageId + "status").val(rateMasterData.status);
        $("#" + this.pageId + "fixedBy").val(rateMasterData.fixedBy);
        $("#" + this.pageId + "effectFromDate").val(rateMasterData.effectiveFromDate);

        $("#" + this.pageId + "fixedOnDate").val(rateMasterData.fixedDate);


        this.showSpinnerForAction = true;
        setTimeout(() => {
            if (this.viewPercBSFrtField) {
                if (rateMasterData.lcChg) {
                    $("#" + this.pageId + "lcChg").val('yes');
                } else {
                    $("#" + this.pageId + "lcChg").val('no');
                }

                if (rateMasterData.bcChg) {
                    $("#" + this.pageId + "bcChg").val('yes');
                } else {
                    $("#" + this.pageId + "bcChg").val('no');
                }

                if (rateMasterData.AOC) {
                    $("#" + this.pageId + "aocChg").val('yes');
                } else {
                    $("#" + this.pageId + "aocChg").val('no');
                }

                if (rateMasterData.riskCharge) {
                    $("#" + this.pageId + "fovChg").val('yes');
                    $("#" + this.pageId + "fovRate").val(rateMasterData.fovOn);
                    $("#" + this.pageId + "fovRateChg").val(rateMasterData.fovPerc);
                    this.fovRate = rateMasterData.fovOn;
                    this.fovRateChg = rateMasterData.fovPerc;
                    this.viewFovRateField = true;
                } else {
                    $("#" + this.pageId + "fovChg").val('no');
                    $("#" + this.pageId + "fovRate").val("");
                    $("#" + this.pageId + "fovRateChg").val("");
                    this.fovRate = null;
                    this.fovRateChg = null;
                    this.viewFovRateField = false;
                }

                if (rateMasterData.DD) {
                    $("#" + this.pageId + "ddChg").val('yes');
                } else {
                    $("#" + this.pageId + "ddChg").val('no');
                }

                if (rateMasterData.others) {
                    $("#" + this.pageId + "othersChg").val('yes');
                } else {
                    $("#" + this.pageId + "othersChg").val('no');
                }

                if (rateMasterData.hamaliChg) {
                    $("#" + this.pageId + "hamaliSrcChg").val('yes');
                } else {
                    $("#" + this.pageId + "hamaliSrcChg").val('no');
                }
            } else {
                $("#" + this.pageId + "lcChg").val('no');
                $("#" + this.pageId + "bcChg").val('no');
                $("#" + this.pageId + "aocChg").val('no');

                $("#" + this.pageId + "fovChg").val('no');
                $("#" + this.pageId + "fovRate").val("");
                $("#" + this.pageId + "fovRateChg").val("");
                this.fovRate = null;
                this.fovRateChg = null;
                this.viewFovRateField = false;

                $("#" + this.pageId + "ddChg").val('no');
                $("#" + this.pageId + "othersChg").val('no');
                $("#" + this.pageId + "hamaliSrcChg").val('no');
            }
            this.showSpinnerForAction = false;
        }, 2000);

        this.showSpinnerForAction = true;
        setTimeout(() => {
            if (!rateMasterData.isFixedRate) {
                $("#" + this.pageId + "searchRate").val('DiscRate');
                this.rateChangeTxtId = 'Discount Rate (Rs)';
            } else {
                $("#" + this.pageId + "searchRate").val('fixedRate');
                this.rateChangeTxtId = 'Rate';
            }
            this.showSpinnerForAction = false;
        }, 1000);

        this.showSpinnerForAction = true;
        setTimeout(() => {
            if ($("#" + this.pageId + "searchUnit").val() == 'rate') {
                this.viewPerUnitField = true;
                this.viewGCChgField = true;
                this.viewReciptChargeField = true;
                this.viewServiceChargeField = true;
            } else if ($("#" + this.pageId + "searchUnit").val() == 'percentage') {
                if ($("#" + this.pageId + "searchUnitPerc").val() == 'PLR') {
                    this.viewGCChgField = false;
                    this.viewPerUnitField = false;

                    this.viewReciptChargeField = true;
                    this.viewServiceChargeField = true;
                } else if ($("#" + this.pageId + "searchUnitPerc").val() == 'PCM') {
                    this.viewPerUnitField = false;
                    this.viewGCChgField = false;
                    this.viewReciptChargeField = false;
                    this.viewServiceChargeField = false;
                } else if ($("#" + this.pageId + "searchUnitPerc").val() == 'PFRT') {
                    this.viewGCChgField = false;
                    this.viewPerUnitField = false;

                    this.viewReciptChargeField = true;
                    this.viewServiceChargeField = true;
                }
            }
            this.showSpinnerForAction = false;
        }, 2000);
        // $("#" + this.pageId + "source").val(rateMasterData.source);
        console.log("rateMasterData.source");
        console.log(rateMasterData.source);
        this.lrDtoListSourceCheckedCneeAdmin = [];
        for (var i = 0; i < this.sourceDataListCneeAdmin.length; i++) {
            console.log("this.sourceDataListCneeAdmin");
            console.log(this.sourceDataListCneeAdmin);
            if (this.sourceDataListCneeAdmin[i].subStation.toLowerCase() == rateMasterData.source.toLowerCase()) {
                // this.sourceDataListCneeAdmin.checked=true;
                this.newAttributeSetSourceListCneeAdmin = {};
                this.newAttributeSetSourceListCneeAdmin.subStation = rateMasterData.source;
                console.log("inside of For if");
                $("#carmcselectedSource").prop("checked", true);
                this.lrDtoListSourceCheckedCneeAdmin.push(this.newAttributeSetSourceListCneeAdmin);
                break;
            }
        }
    }

    rowSelectedConsigneeAdminDataOnCopy(rateMasterData) {
        this.showSpinnerForAction = true;
        console.log(rateMasterData);
        this.validateSelectedConsigneeId = null;

        if (rateMasterData.editValiateMode == "commodity") {
            $("#" + this.pageId + "destinationId").val(rateMasterData.destination);
        } else if (rateMasterData.editValiateMode == "party") {
            $("#" + this.pageId + "consigneeNameId").val(rateMasterData.consigneeName);
            $("#" + this.pageId + "destinationId").val(rateMasterData.destination);
            this.validateSelectedConsigneeId = rateMasterData.consigneeId;
        }

        $("#" + this.pageId + "commodityListId").val(rateMasterData.commodityName);
        if (rateMasterData.unit == "Rate") {
            this.viewPercBSFrtField = true;
            this.viewRateField = true;
            this.viewPercentageField = false;
        } else if (rateMasterData.unit == "Discount") {
            this.viewPercBSFrtField = false;
            this.viewRateField = false;
            this.viewPercentageField = true;
        }
        /* if ($("#"+this.pageId+"searchUnitPerc").val() == "PLR") {
             this.newAttributeTosetConsigneeAdminRateMaster.column2 = "onLr";
         } else if ($("#"+this.pageId+"searchUnitPerc").val() == "PCM") {
             this.newAttributeTosetConsigneeAdminRateMaster.column2 = "onTotal";
         } else if ($("#"+this.pageId+"searchUnitPerc").val() == "PFRT") {
             this.newAttributeTosetConsigneeAdminRateMaster.column2 = "onBaseFrgt";
         }*/
        //  this.showSpinnerForAction = true;
        setTimeout(() => {
            if (this.viewRateField && !this.viewPercentageField) {
                $("#" + this.pageId + "searchUnit").val('rate');
                $("#" + this.pageId + "ratePercentageId").val(rateMasterData.rate);
                this.viewPerUnitField = true;
                this.viewGCChgField = true;
            } else if (this.viewPercentageField && !this.viewRateField) {
                if (rateMasterData.column2 == "onLr") {
                    $("#" + this.pageId + "searchUnitPerc").val('PLR');
                    this.viewPerUnitField = true;
                    this.viewGCChgField = true;
                } else if (rateMasterData.column2 == "onTotal") {
                    $("#" + this.pageId + "searchUnitPerc").val('PCM');
                    this.viewPerUnitField = false;
                    this.viewGCChgField = false;
                } else if (rateMasterData.column2 == "onBaseFrgt") {
                    $("#" + this.pageId + "searchUnitPerc").val('PFRT');
                    this.viewPerUnitField = true;
                    this.viewGCChgField = true;
                }
                $("#" + this.pageId + "searchUnit").val('percentage');
                $("#" + this.pageId + "percentageRateId").val(rateMasterData.rate);
            }
            // this.showSpinnerForAction = false;
        }, 2000);

        $("#" + this.pageId + "hamaliDestinationChg").val(rateMasterData.hamaliDestination);
        $("#" + this.pageId + "serviceCharge").val(rateMasterData.serviceCharges);
        $("#" + this.pageId + "unitServiceChg").val(rateMasterData.serviceChargesUnit);
        $("#" + this.pageId + "gcCharge").val(rateMasterData.gcCharge);
        $("#" + this.pageId + "srcHamaliChg").val(rateMasterData.srcHamaliChg);
        $("#" + this.pageId + "reciptCharge").val(rateMasterData.receiptCharges);
        $("#" + this.pageId + "perUnit").val(rateMasterData.perUnit);

        $("#" + this.pageId + "customHamaliSrcChg").val(rateMasterData.customHamaliSrcChg);

        if (rateMasterData.perUnit == "Actual Weight") {
            $("#" + this.pageId + "rateOnActWgt").val(rateMasterData.rateOnActWgt);
            this.rateOnActWgt = rateMasterData.rateOnActWgt;
            this.viewRateOnActWgtField = true;
        } else {
            $("#" + this.pageId + "rateOnActWgt").val('');
            this.rateOnActWgt = null;
            this.viewRateOnActWgtField = false;
        }

        $("#" + this.pageId + "status").val(rateMasterData.status);
        $("#" + this.pageId + "fixedBy").val(rateMasterData.fixedBy);
        $("#" + this.pageId + "effectFromDate").val(rateMasterData.effectiveFromDate);
        $("#" + this.pageId + "fixedOnDate").val(rateMasterData.fixedDate);

        // this.showSpinnerForAction = true;
        setTimeout(() => {
            if (this.viewPercBSFrtField) {
                if (rateMasterData.lcChg) {
                    $("#" + this.pageId + "lcChg").val('yes');
                } else {
                    $("#" + this.pageId + "lcChg").val('no');
                }

                if (rateMasterData.bcChg) {
                    $("#" + this.pageId + "bcChg").val('yes');
                } else {
                    $("#" + this.pageId + "bcChg").val('no');
                }

                if (rateMasterData.AOC) {
                    $("#" + this.pageId + "aocChg").val('yes');
                } else {
                    $("#" + this.pageId + "aocChg").val('no');
                }

                if (rateMasterData.riskCharge) {
                    $("#" + this.pageId + "fovChg").val('yes');
                    $("#" + this.pageId + "fovRate").val(rateMasterData.fovOn);
                    $("#" + this.pageId + "fovRateChg").val(rateMasterData.fovPerc);
                    this.fovRate = rateMasterData.fovOn;
                    this.fovRateChg = rateMasterData.fovPerc;
                    this.viewFovRateField = true;
                } else {
                    $("#" + this.pageId + "fovChg").val('no');
                    $("#" + this.pageId + "fovRate").val("");
                    $("#" + this.pageId + "fovRateChg").val("");
                    this.fovRate = null;
                    this.fovRateChg = null;
                    this.viewFovRateField = false;
                }

                if (rateMasterData.DD) {
                    $("#" + this.pageId + "ddChg").val('yes');
                } else {
                    $("#" + this.pageId + "ddChg").val('no');
                }

                if (rateMasterData.others) {
                    $("#" + this.pageId + "othersChg").val('yes');
                } else {
                    $("#" + this.pageId + "othersChg").val('no');
                }

                if (rateMasterData.hamaliChg) {
                    $("#" + this.pageId + "hamaliSrcChg").val('yes');
                } else {
                    $("#" + this.pageId + "hamaliSrcChg").val('no');
                }
            } else {
                $("#" + this.pageId + "lcChg").val('no');
                $("#" + this.pageId + "bcChg").val('no');
                $("#" + this.pageId + "aocChg").val('no');

                $("#" + this.pageId + "fovChg").val('no');
                $("#" + this.pageId + "fovRate").val("");
                $("#" + this.pageId + "fovRateChg").val("");
                this.fovRate = null;
                this.fovRateChg = null;
                this.viewFovRateField = false;

                $("#" + this.pageId + "ddChg").val('no');
                $("#" + this.pageId + "othersChg").val('no');
                $("#" + this.pageId + "hamaliSrcChg").val('no');
            }
            // this.showSpinnerForAction = false;
        }, 2000);

        // this.showSpinnerForAction = true;
        setTimeout(() => {
            if (!rateMasterData.isFixedRate) {
                $("#" + this.pageId + "searchRate").val('DiscRate');
                this.rateChangeTxtId = 'Discount Rate (Rs)';
            } else {
                $("#" + this.pageId + "searchRate").val('fixedRate');
                this.rateChangeTxtId = 'Rate';
            }
            // this.showSpinnerForAction = false;
        }, 1000);

        // this.showSpinnerForAction = true;
        setTimeout(() => {
            if ($("#" + this.pageId + "searchUnit").val() == 'rate') {
                this.viewPerUnitField = true;
                this.viewGCChgField = true;
                this.viewReciptChargeField = true;
                this.viewServiceChargeField = true;
            } else if ($("#" + this.pageId + "searchUnit").val() == 'percentage') {
                if ($("#" + this.pageId + "searchUnitPerc").val() == 'PLR') {
                    this.viewGCChgField = false;
                    this.viewPerUnitField = false;

                    this.viewReciptChargeField = true;
                    this.viewServiceChargeField = true;
                } else if ($("#" + this.pageId + "searchUnitPerc").val() == 'PCM') {
                    this.viewPerUnitField = false;
                    this.viewGCChgField = false;
                    this.viewReciptChargeField = false;
                    this.viewServiceChargeField = false;
                } else if ($("#" + this.pageId + "searchUnitPerc").val() == 'PFRT') {
                    this.viewGCChgField = false;
                    this.viewPerUnitField = false;

                    this.viewReciptChargeField = true;
                    this.viewServiceChargeField = true;
                }
            }
            // this.showSpinnerForAction = false;
        }, 2000);
        this.showSpinnerForAction = false;
        // $("#" + this.pageId + "source").val(rateMasterData.source);
        console.log("rateMasterData.source");
        console.log(rateMasterData.source);
        this.lrDtoListSourceCheckedCneeAdmin = [];
        for (var i = 0; i < this.sourceDataListCneeAdmin.length; i++) {
            console.log("this.sourceDataListCneeAdmin");
            console.log(this.sourceDataListCneeAdmin);
            if (this.sourceDataListCneeAdmin[i].subStation.toLowerCase() == rateMasterData.source.toLowerCase()) {
                // this.sourceDataListCneeAdmin.checked=true;
                this.newAttributeSetSourceListCneeAdmin = {};
                this.newAttributeSetSourceListCneeAdmin.subStation = rateMasterData.source;
                console.log("inside of For if");
                $("#carmcselectedSource").prop("checked", true);
                // this.lrDtoListSourceCheckedCneeAdmin.push(this.newAttributeSetSourceListCneeAdmin);
                break;
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    deleteSelectedConsigneeAdminDetails(index) {
        $("#" + this.pageId + "consigneeAdminMasterDraftDetailsTableId").DataTable().destroy();
        this.rateMasterDataList.splice(index, 1);
        this.dtTriggerRateMaster.next();
    }

    clearFieldConsigneeAdminRate() {
        this.newAttributeTosetConsigneeAdminRateMaster = {};
        this.editConsigneeAdminRateMasterTableDetailsId = null;

        //$("#"+this.pageId+"searchMode").val('party');

        //$("#"+this.pageId+"destinationId").val('');
        //this.modelDestination = null;
        //$("#"+this.pageId+"consigneeNameId").val('');
        //this.modelConsigneeName = null;
        $("#" + this.pageId + "source").val('');
        this.modelSource = null;

        this.rateAtSrcCheckStatus = false;
        $("#carmcrateAtSrcCheckbox").prop("checked", false);

        this.customHamailSrcChgCheckStatus = false;
        $("#customHamaliSrcCheckbox").prop("checked", false);
        $("#" + this.pageId + "customHamaliSrcChg").val('');
        this.showCustomHamailSrcChgCheck = false;

        $("#" + this.pageId + "commodityListId").val('');
        this.modelCommodityList = null;

        $("#" + this.pageId + "searchUnit").val('rate');
        this.viewRateField = true;
        this.viewPercentageField = false;
        $("#" + this.pageId + "searchRate").val('fixedRate');
        $("#" + this.pageId + "ratePercentageId").val('');
        this.rateChangeTxtId = 'Rate';

        $("#" + this.pageId + "searchUnitPerc").val('PLR');
        $("#" + this.pageId + "percentageRateId").val('');

        $("#" + this.pageId + "gcCharge").val('');
        $("#" + this.pageId + "srcHamaliChg").val('');
        $("#" + this.pageId + "customHamaliSrcChg").val('');
        $("#" + this.pageId + "hamaliDestinationChg").val('');
        $("#" + this.pageId + "reciptCharge").val('');
        $("#" + this.pageId + "serviceCharge").val('');
        $("#" + this.pageId + "unitServiceChg").val('Charged Weight');
        $("#" + this.pageId + "perUnit").val('Article');
        $("#" + this.pageId + "rateOnActWgt").val('');
        this.rateOnActWgt = null;
        this.viewRateOnActWgtField = false;
        $("#carmcrateOnActWgt").val('');
        $("#" + this.pageId + "status").val('select');
        $("#" + this.pageId + "fixedBy").val('');
        $("#" + this.pageId + "effectFromDate").val('');
        $("#" + this.pageId + "fixedOnDate").val('');

        $("#" + this.pageId + "lcChg").val('no');
        $("#" + this.pageId + "hamaliSrcChg").val('no');
        $("#" + this.pageId + "bcChg").val('no');
        $("#" + this.pageId + "aocChg").val('no');

        $("#" + this.pageId + "fovChg").val('no');
        $("#carmcfovRate").val('');
        $("#carmcfovRateChg").val('');
        this.fovRate = null;
        this.fovRateChg = null;
        this.viewFovRateField = false;

        $("#" + this.pageId + "ddChg").val('no');
        $("#" + this.pageId + "othersChg").val('no');
        this.viewPercBSFrtField = true;
        $("#carmcselectedSource").prop("checked", false);

    }

    /* save start*/

    validateSaveBtn() {
        if (this.rateMasterDataList == null || this.rateMasterDataList == undefined ||
            this.rateMasterDataList == "" || this.rateMasterDataList.length == 0) {
            swal("Not Allowed", "No Record Found to Save", "warning");
            return false;
        } else {
            this.addConsigneeAdminRateDetails();
        }
    }

    addConsigneeAdminRateDetails() {
        this.rateMasterDtoForSave = [];
        this.rateMasterDtoForSave = this.rateMasterDataList;
        console.log("this.rateMasterDataList");
        console.log(this.rateMasterDataList);
        console.log("this.rateMasterDtoForSave");
        console.log(this.rateMasterDtoForSave);
        this.showSpinnerForAction = true;
        this.consigneeService.addConsigneeAdminRate(this.rateMasterDtoForSave).subscribe(
            (response) => {
                this.rateMasterDtoForSaveReturn = [];
                this.rateMasterDtoForSaveReturn = response;
                //console.log("HAI _ SAVE");
                //console.log(response);
                //console.log(response[0].status);
                console.log(this.rateMasterDtoForSaveReturn);
                console.log(this.rateMasterDtoForSaveReturn.status);
                //this.clearFieldAll();
                if (this.rateMasterDtoForSaveReturn.status == "duplicate") {
                    swal("Duplicate Entry", "This details are Already Exists", "warning");
                    //return false;
                } else if (this.rateMasterDtoForSaveReturn.status == "all") {
                    swal("Not Allowed", "Rate is already set for All commodity.", "warning");
                    //return false;
                } else if (this.rateMasterDtoForSaveReturn.status == "persisted") {
                    this.clearFieldAll();
                    this.calculateMemo(this.rateMasterDtoForSave);
                } else if (this.rateMasterDtoForSaveReturn.status == "not persisted") {
                    swal("Error", "Failed to save add consignee admin rate details", "warning");
                    //return false;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Adding the Consignee Admin Rate Details", "info");
            }, () => console.log('done');
    }

    calculateMemo(rateMasterDtoForSave) {
        //this.rateMasterDtoForSave = [];
        //this.rateMasterDtoForSave = this.rateMasterDataList;
        //console.log(this.rateMasterDataList);
        console.log(rateMasterDtoForSave);
        this.showSpinnerForAction = true;
        this.consigneeService.recalculateBkgMemoAfterAdminRate(rateMasterDtoForSave).subscribe(
            (response) => {
                this.rateMasterDtoForSaveReturnOfRecalcBkg = null;
                this.rateMasterDtoForSaveReturnOfRecalcBkg = response;
                //console.log("HAI _ Rec");
                //console.log(response);
                console.log(this.rateMasterDtoForSaveReturnOfRecalcBkg);
                this.rateMasterDtoForSave = [];
                this.rateMasterDtoForSaveReturn = null;
                //this.clearFieldAll();
                if (this.rateMasterDtoForSaveReturnOfRecalcBkg.status == "success") {
                    swal("Consignee Details", "Consignee Details Saved Successfully", "warning");
                    //return false;
                } else if (this.rateMasterDtoForSaveReturn.status == "failed") {
                    swal("Failed", "Failed to save re-calculate Bkg Memo After Admin Rate", "warning");
                    //return false;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                this.rateMasterDtoForSave = [];
                this.rateMasterDtoForSaveReturn = null;
                swal("Error", "Server Problem Occurred While saving re-calculate Bkg Memo After Admin Rate Details", "info");
            }, () => console.log('done');
    }

    validateUpdateBtn() {
        console.log("this.lrDtoListSourceCheckedCneeAdmin  >> updateDataOfConsigneeAdminRateDetails()");
        console.log(this.lrDtoListSourceCheckedCneeAdmin);
        if (this.rateMasterDtoForSelectedRowBothTable.id == null ||
            this.rateMasterDtoForSelectedRowBothTable.id == undefined ||
            this.rateMasterDtoForSelectedRowBothTable.id == "") {
            swal("Not Allowed", "Please click edit Button", "warning");
            //return false;
        } else if (this.lrDtoListSourceCheckedCneeAdmin == null || this.lrDtoListSourceCheckedCneeAdmin == undefined ||
            this.lrDtoListSourceCheckedCneeAdmin.length == 0) {
            swal("Mandatory Fields", "Please select the Source", "warning");
        } else {
            //immedaitely disable update btn to avoid duplicate entry as user click double @03/02/22
            $("#" + this.pageId + "updateBtnId").prop('disabled', true);
            this.updateConsigneeAdminRate();
        }
    }

    updateDataOfConsigneeAdminRateDetails() {
        this.rateMasterDtoForUpdateAny = new RateMasterDto();

        //this.rateMasterDtoForUpdateAny.mode = $("#"+this.pageId+"searchMode").val();
        this.validateSelelctedSearchMode = $("#" + this.pageId + "searchMode").val();
        this.rateMasterDtoForUpdateAny.editValiateMode = this.validateSelelctedSearchMode;
        this.rateMasterDtoForUpdateAny.id = this.rateMasterDtoForSelectedRowBothTable.id

        if ($("#" + this.pageId + "searchMode").val() == "commodity") {
            this.validateSelelctedDestinationId = $("#" + this.pageId + "destinationId").val();
            this.rateMasterDtoForUpdateAny.consigneeId = 1;
            this.rateMasterDtoForUpdateAny.destination = this.validateSelelctedDestinationId;
        } else if ($("#" + this.pageId + "searchMode").val() == "party") {
            this.validateSelelctedDestinationId = $("#" + this.pageId + "destinationId").val();
            this.validateSelelctedConsigneeNameId = $("#" + this.pageId + "consigneeNameId").val();
            this.rateMasterDtoForUpdateAny.consigneeName = this.validateSelelctedConsigneeNameId;
            this.rateMasterDtoForUpdateAny.consigneeId = this.validateSelectedConsigneeId;
            this.rateMasterDtoForUpdateAny.destination = this.validateSelelctedDestinationId;
        }

        this.validateSelelctedCommodityListId = $("#" + this.pageId + "commodityListId").val();
        this.rateMasterDtoForUpdateAny.commodityName = this.validateSelelctedCommodityListId;

        this.validateSelelctedSearchUnit = null;
        if ($("#" + this.pageId + "searchUnit").val() == "rate") {
            this.validateSelelctedRatePercentageId = $("#" + this.pageId + "ratePercentageId").val();
            this.rateMasterDtoForUpdateAny.rate = this.validateSelelctedRatePercentageId;
        } else if ($("#" + this.pageId + "searchUnit").val() == "percentage") {
            this.validateSelelctedPercentageRateId = $("#" + this.pageId + "percentageRateId").val()
            this.rateMasterDtoForUpdateAny.rate = this.validateSelelctedPercentageRateId;
        }

        var unitDiscount = $("#" + this.pageId + "searchUnit").val();
        if ($("#" + this.pageId + "searchUnit").val() != null && $("#" + this.pageId + "searchUnit").val() != undefined &&
            $("#" + this.pageId + "searchUnit").val() == "percentage") {
            this.rateMasterDtoForUpdateAny.unit = "Discount";
            if ($("#" + this.pageId + "searchUnitPerc").val() == "PLR") {
                this.rateMasterDtoForUpdateAny.column2 = "onLr";
            } else if ($("#" + this.pageId + "searchUnitPerc").val() == "PCM") {
                this.rateMasterDtoForUpdateAny.column2 = "onTotal";
            } else if ($("#" + this.pageId + "searchUnitPerc").val() == "PFRT") {
                this.rateMasterDtoForUpdateAny.column2 = "onBaseFrgt";
            }
        } else {
            this.rateMasterDtoForUpdateAny.unit = "Rate";
        }

        //this.newAttributeTosetConsigneeAdminRateMaster.hamaliSource = null;
        this.validateSelelctedHamaliDestinationChg = $("#" + this.pageId + "hamaliDestinationChg").val();
        this.validateSelelctedServiceCharges = $("#" + this.pageId + "serviceCharge").val();
        this.validateSelelctedServiceChargesUnit = $("#" + this.pageId + "unitServiceChg").val();
        this.validateSelelctedGcCharge = $("#" + this.pageId + "gcCharge").val();
        this.validateSelelctedSrcHamaliChg = $("#" + this.pageId + "srcHamaliChg").val();
        this.validateSelelctedReciptCharge = $("#" + this.pageId + "reciptCharge").val();

        this.validateSelelctedCustomHamaliSrcChg = $("#" + this.pageId + "customHamaliSrcChg").val();

        this.validateSelelctedPerUnit = $("#" + this.pageId + "perUnit").val();
        this.validateRateOnActWgt = $("#" + this.pageId + "rateOnActWgt").val();

        this.validateSelelctedStatus = $("#" + this.pageId + "status").val();
        this.validateSelelctedFixedBy = $("#" + this.pageId + "fixedBy").val();
        this.validateSelelctedEffectFromDate = $("#" + this.pageId + "effectFromDate").val();
        // this.validateSelelctedSource = $("#" + this.pageId + "source").val();
        this.validateSelelctedfixedOnDate = $("#" + this.pageId + "fixedOnDate").val();

        this.validateFovOn = $("#" + this.pageId + "fovRate").val();
        this.validateFovRate = $("#" + this.pageId + "fovRateChg").val();

        this.validateSelelctedUnloadingChg = $("#" + this.pageId + "unloadingChg").val();
        this.validateSelelctedUnloadingChgUnit = $("#" + this.pageId + "unloadingChgUnit").val();
        this.validateSelelctedUnloadingChgGd = $("#" + this.pageId + "unloadingChgGd").val();
        this.validateSelelctedUnloadingChgUnitGd = $("#" + this.pageId + "unloadingChgUnitGd").val();

        this.rateMasterDtoForUpdateAny.hamaliDestination = this.validateSelelctedHamaliDestinationChg;
        this.rateMasterDtoForUpdateAny.serviceCharges = this.validateSelelctedServiceCharges;
        this.rateMasterDtoForUpdateAny.serviceChargesUnit = this.validateSelelctedServiceChargesUnit
        this.rateMasterDtoForUpdateAny.gcCharge = this.validateSelelctedGcCharge;
        this.rateMasterDtoForUpdateAny.srcHamaliChg = this.validateSelelctedSrcHamaliChg;
        this.rateMasterDtoForUpdateAny.customHamaliSrcChg = this.validateSelelctedCustomHamaliSrcChg;
        this.rateMasterDtoForUpdateAny.receiptCharges = this.validateSelelctedReciptCharge;

        this.rateMasterDtoForUpdateAny.perUnit = this.validateSelelctedPerUnit;
        this.rateMasterDtoForUpdateAny.rateOnActWgt = this.validateRateOnActWgt;

        this.rateMasterDtoForUpdateAny.status = this.validateSelelctedStatus;
        this.rateMasterDtoForUpdateAny.fixedBy = this.validateSelelctedFixedBy;
        this.rateMasterDtoForUpdateAny.effectiveFromDate = this.validateSelelctedEffectFromDate;
        this.rateMasterDtoForUpdateAny.lastModifiedBy = this.userDataDtoReturnSession.userId;
        this.rateMasterDtoForUpdateAny.enteredBy = this.userDataDtoReturnSession.userId;
        // this.rateMasterDtoForUpdateAny.source = this.validateSelelctedSource;
        this.rateMasterDtoForUpdateAny.source = this.lrDtoListSourceCheckedCneeAdmin[0].subStation;
        this.rateMasterDtoForUpdateAny.fixedDate = this.validateSelelctedfixedOnDate;
        this.rateMasterDtoForUpdateAny.commidtyForRpt = "";

        this.rateMasterDtoForUpdateAny.companyId = this.userDataDtoReturnSession.companyId;
        //this.newAttributeTosetConsigneeAdminRateMaster.lastModifiedBy = this.userDataDtoReturnSession.companyId;
        //this.newAttributeTosetConsigneeAdminRateMaster.enteredby = this.userDataDtoReturnSession.companyId;
        this.rateMasterDtoForUpdateAny.rateAtSrcCheck = this.rateAtSrcCheckStatus;
        this.rateMasterDtoForUpdateAny.hamaliSrcCustom = this.customHamailSrcChgCheckStatus;

        this.rateMasterDtoForUpdateAny.unloadingChg = this.validateSelelctedUnloadingChg;
        this.rateMasterDtoForUpdateAny.unloadingChgUnit = this.validateSelelctedUnloadingChgUnit;
        this.rateMasterDtoForUpdateAny.unloadingChgGd = this.validateSelelctedUnloadingChgGd;
        this.rateMasterDtoForUpdateAny.unloadingChgUnitGd = this.validateSelelctedUnloadingChgUnitGd;


        if (this.viewPercBSFrtField) {
            if ($("#" + this.pageId + "lcChg").val() == "yes") {
                this.rateMasterDtoForUpdateAny.LcChg = true;
                this.rateMasterDtoForUpdateAny.lcChgStr = "Yes";
            } else {
                this.rateMasterDtoForUpdateAny.LcChg = false;
                this.rateMasterDtoForUpdateAny.lcChgStr = "No"
            }

            if ($("#" + this.pageId + "bcChg").val() == "yes") {
                this.rateMasterDtoForUpdateAny.BcChg = true;
                this.rateMasterDtoForUpdateAny.bcChgStr = "Yes";
            } else {
                this.rateMasterDtoForUpdateAny.BcChg = false;
                this.rateMasterDtoForUpdateAny.bcChgStr = "No";
            }

            if ($("#" + this.pageId + "aocChg").val() == "yes") {
                this.rateMasterDtoForUpdateAny.AOC = true;
                this.rateMasterDtoForUpdateAny.aocChgStr = "Yes";
            } else {
                this.rateMasterDtoForUpdateAny.AOC = false;
                this.rateMasterDtoForUpdateAny.aocChgStr = "No"
            }

            if ($("#" + this.pageId + "fovChg").val() == "yes") {
                this.rateMasterDtoForUpdateAny.riskCharge = true;
                this.rateMasterDtoForUpdateAny.fovOn = this.validateFovOn;
                this.rateMasterDtoForUpdateAny.fovPerc = this.validateFovRate;
            } else {
                this.rateMasterDtoForUpdateAny.riskCharge = false;
                this.rateMasterDtoForUpdateAny.fovOn = null;
                this.rateMasterDtoForUpdateAny.fovPerc = null;
            }

            if ($("#" + this.pageId + "ddChg").val() == "yes") {
                this.rateMasterDtoForUpdateAny.DD = true;
                this.rateMasterDtoForUpdateAny.ddChgStr = "Yes";
            } else {
                this.rateMasterDtoForUpdateAny.DD = false;
                this.rateMasterDtoForUpdateAny.ddChgStr = "No";
            }

            if ($("#" + this.pageId + "othersChg").val() == "yes") {
                this.rateMasterDtoForUpdateAny.otherChg = true;
                this.rateMasterDtoForUpdateAny.otherChgStr = "Yes";
            } else {
                this.rateMasterDtoForUpdateAny.otherChg = false;
                this.rateMasterDtoForUpdateAny.otherChgStr = "No";
            }

            if ($("#" + this.pageId + "hamaliSrcChg").val() == "yes") {
                this.rateMasterDtoForUpdateAny.HamaliChg = true;
                this.rateMasterDtoForUpdateAny.shamaliChgStr = "Yes";
            } else {
                this.rateMasterDtoForUpdateAny.HamaliChg = false;
                this.rateMasterDtoForUpdateAny.shamaliChgStr = "No";
            }
        } else {
            this.rateMasterDtoForUpdateAny.LcChg = false;
            this.rateMasterDtoForUpdateAny.BcChg = false;
            this.rateMasterDtoForUpdateAny.AOC = false;
            this.rateMasterDtoForUpdateAny.riskCharge = false;
            this.rateMasterDtoForUpdateAny.DD = false;
            this.rateMasterDtoForUpdateAny.otherChg = false;
            this.rateMasterDtoForUpdateAny.HamaliChg = false;

            this.rateMasterDtoForUpdateAny.lcChgStr = "No";
            this.rateMasterDtoForUpdateAny.bcChgStr = "No";
            this.rateMasterDtoForUpdateAny.aocChgStr = "No";
            this.rateMasterDtoForUpdateAny.ddChgStr = "No";
            this.rateMasterDtoForUpdateAny.otherChgStr = "No";
            this.rateMasterDtoForUpdateAny.shamaliChgStr = "No";
        }

        this.rateMasterDtoForUpdateAny.isFixedRate = true;
        if ($("#" + this.pageId + "searchRate").val() == "DiscRate") {
            this.rateMasterDtoForUpdateAny.isFixedRate = false;
        }

        if ($("#" + this.pageId + "searchUnit").val() != null && $("#" + this.pageId + "searchUnit").val() != undefined &&
            $("#" + this.pageId + "searchUnit").val() == "percentage") {
            if ($("#" + this.pageId + "searchUnitPerc").val() == "PLR") {
                this.rateMasterDtoForUpdateAny.rateType = "onLr";
            } else if ($("#" + this.pageId + "searchUnitPerc").val() == "PCM") {
                this.rateMasterDtoForUpdateAny.rateType = "onTotal";
            } else if ($("#" + this.pageId + "searchUnitPerc").val() == "PFRT") {
                this.rateMasterDtoForUpdateAny.rateType = "onBaseFrgt";
            }
            //this.rateMasterDtoForUpdateAny.rateType =this.rateMasterDtoForUpdateAny.column2;
        } else {
            if (this.rateMasterDtoForUpdateAny.isFixedRate) {
                this.rateMasterDtoForUpdateAny.rateType = "Fixed Rate";
            } else {
                this.rateMasterDtoForUpdateAny.rateType = "Discount Rate";
            }
        }

        return this.rateMasterDtoForUpdateAny;
    }


    updateConsigneeAdminRate() {
        this.rateMasterDtoForUpdate = new RateMasterDto();
        this.rateMasterDtoForUpdateList = [];
        //this.rateMasterDtoForUpdate = this.rateMasterDtoForSelectedRowBothTable;
        this.rateMasterDtoForUpdate = this.updateDataOfConsigneeAdminRateDetails();
        this.rateMasterDtoForUpdateList.push(this.rateMasterDtoForUpdate);
        // console.log(this.rateMasterDtoForSelectedRowBothTable);
        // console.log(this.rateMasterDtoForUpdate);
        // console.log(this.rateMasterDtoForUpdateList);
        this.showSpinnerForAction = true;
        this.consigneeService.updateConsigneeAdminRate(this.rateMasterDtoForUpdate).subscribe(
            (response) => {
                if (response.status == "persisted") {
                    this.clearFieldAll();
                    this.calculateMemo(this.rateMasterDtoForUpdateList);
                } else if (response.status == "not persisted") {
                    swal("Error", "Failed to save updating consignee admin rate details", "warning");
                    return false;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While updating the Consignee Admin Rate Details", "info");
            }, () => console.log('done');
    }

    /* save end*/

    /* edit field start */
    rowSelectedConsigneeAdminCommodityDataOnEdit(commodityRateData) {
        this.rateMasterDtoForSelectedRowBothTable = null;
        this.rateMasterDtoForSelectedRowBothTable = commodityRateData;

        $("#" + this.pageId + "nextBtnId").prop('disabled', true);
        $("#" + this.pageId + "saveBtnId").prop('disabled', true);
        $("#" + this.pageId + "updateBtnId").prop('disabled', false);

        if (!this.isRights) {
            this.disableAllFields();
        }

        console.log(commodityRateData);
        $("#" + this.pageId + "searchMode").val('commodity');
        if ($("#" + this.pageId + "searchMode").val() == "party") {
            this.partyView = true;
        } else if ($("#" + this.pageId + "searchMode").val() == "commodity") {
            this.partyView = false;
        } else if ($("#" + this.pageId + "searchMode").val() == "rateForAll") {
            this.partyView = false;
        } else {
            this.partyView = false;
        }

        $("#" + this.pageId + "commodityListId").val(commodityRateData.commodityName);
        $("#" + this.pageId + "consigneeNameId").val(commodityRateData.consigneeName);
        this.validateSelectedConsigneeId = commodityRateData.consigneeId;
        $("#" + this.pageId + "destinationId").val(commodityRateData.destination);
        $("#" + this.pageId + "gcCharge").val(commodityRateData.gcCharge);
        $("#" + this.pageId + "srcHamaliChg").val(commodityRateData.srcHamaliChg);
        $("#" + this.pageId + "hamaliDestinationChg").val(commodityRateData.hamaliDestination);
        $("#" + this.pageId + "reciptCharge").val(commodityRateData.receiptCharges);
        $("#" + this.pageId + "serviceCharge").val(commodityRateData.serviceCharges);
        $("#" + this.pageId + "unitServiceChg").val(commodityRateData.serviceChargesUnit);

        $("#" + this.pageId + "rateOnActWgt").val('');
        this.rateOnActWgt = null;
        this.viewRateOnActWgtField = false;
        if (commodityRateData.perUnit != null && commodityRateData.perUnit == "Article") {
            $("#" + this.pageId + "perUnit").val(commodityRateData.perUnit);
        } else if (commodityRateData.perUnit != null && commodityRateData.perUnit == "Actual Weight") {
            $("#" + this.pageId + "perUnit").val(commodityRateData.perUnit);
            $("#" + this.pageId + "rateOnActWgt").val(commodityRateData.rateOnActWgt);
            this.rateOnActWgt = commodityRateData.rateOnActWgt;
            this.viewRateOnActWgtField = true;
        } else if (commodityRateData.perUnit != null && commodityRateData.perUnit == "Chargable Weight") {
            $("#" + this.pageId + "perUnit").val(commodityRateData.perUnit);
        }

        if (commodityRateData.status != null && commodityRateData.status == "Cash") {
            $("#" + this.pageId + "status").val(commodityRateData.status);
        } else if (commodityRateData.status != null && commodityRateData.status == "Account") {
            $("#" + this.pageId + "status").val(commodityRateData.status);
        } else if (commodityRateData.status != null && commodityRateData.status == "Delivery") {
            $("#" + this.pageId + "status").val(commodityRateData.status);
        } else {
            $("#" + this.pageId + "status").val("select");
        }
        $("#" + this.pageId + "fixedBy").val(commodityRateData.fixedBy);
        $("#" + this.pageId + "effectFromDate").val(commodityRateData.effectiveFromDate);
        if (commodityRateData.fixedDate != null) {
            $("#" + this.pageId + "fixedOnDate").val(moment(commodityRateData.fixedDate).format("YYYY-MM-DD"));
        }

        if (commodityRateData.unit == "Rate") {
            this.viewPercBSFrtField = true;
            this.viewRateField = true;
            this.viewPercentageField = false;
        } else if (commodityRateData.unit == "Discount") {
            this.viewPercBSFrtField = false;
            this.viewRateField = false;
            this.viewPercentageField = true;
        }
        this.showSpinnerForAction = true;
        setTimeout(() => {
            if (this.viewRateField && !this.viewPercentageField) {
                $("#" + this.pageId + "searchUnit").val('rate');
                $("#" + this.pageId + "ratePercentageId").val(commodityRateData.rate);
            } else if (this.viewPercentageField && !this.viewRateField) {
                if (commodityRateData.column2 != null && commodityRateData.column2 == "onLr") {
                    $("#" + this.pageId + "searchUnitPerc").val('PLR');
                } else if (commodityRateData.column2 != null && commodityRateData.column2 == "onTotal") {
                    $("#" + this.pageId + "searchUnitPerc").val('PCM');
                } else if (commodityRateData.column2 != null && commodityRateData.column2 == "onBaseFrgt") {
                    $("#" + this.pageId + "searchUnitPerc").val('PFRT');
                }
                $("#" + this.pageId + "searchUnit").val('percentage');
                $("#" + this.pageId + "percentageRateId").val(commodityRateData.rate);
            }
            this.showSpinnerForAction = false;
        }, 2000);

        this.showSpinnerForAction = true;
        setTimeout(() => {
            if (this.viewPercBSFrtField) {
                if (commodityRateData.lcChg != null && commodityRateData.lcChg) {
                    $("#" + this.pageId + "lcChg").val('yes');
                } else {
                    $("#" + this.pageId + "lcChg").val('no');
                }

                if (commodityRateData.hamaliChg != null && commodityRateData.hamaliChg) {
                    $("#" + this.pageId + "hamaliSrcChg").val('yes');
                } else {
                    $("#" + this.pageId + "hamaliSrcChg").val('no');
                }

                if (commodityRateData.bcChg != null && commodityRateData.bcChg) {
                    $("#" + this.pageId + "bcChg").val('yes');
                } else {
                    $("#" + this.pageId + "bcChg").val('no');
                }

                if (commodityRateData.aoc != null && commodityRateData.aoc) {
                    $("#" + this.pageId + "aocChg").val('yes');
                } else {
                    $("#" + this.pageId + "aocChg").val('no');
                }

                if (commodityRateData.riskCharge != null && commodityRateData.riskCharge) {
                    $("#" + this.pageId + "fovChg").val('yes');
                    $("#" + this.pageId + "fovRate").val(commodityRateData.fovOn);
                    $("#" + this.pageId + "fovRateChg").val(commodityRateData.fovPerc);
                    this.fovRate = commodityRateData.fovOn;
                    this.fovRateChg = commodityRateData.fovPerc;
                    this.viewFovRateField = true;
                } else {
                    $("#" + this.pageId + "fovChg").val('no');
                    $("#" + this.pageId + "fovRate").val("");
                    $("#" + this.pageId + "fovRateChg").val("");
                    this.fovRate = null;
                    this.fovRateChg = null;
                    this.viewFovRateField = false;
                }

                if (commodityRateData.dd != null && commodityRateData.dd) {
                    $("#" + this.pageId + "ddChg").val('yes');
                } else {
                    $("#" + this.pageId + "ddChg").val('no');
                }

                if (commodityRateData.others != null && commodityRateData.others) {
                    $("#" + this.pageId + "othersChg").val('yes');
                } else {
                    $("#" + this.pageId + "othersChg").val('no');
                }
            } else {
                $("#" + this.pageId + "lcChg").val('no');
                $("#" + this.pageId + "hamaliSrcChg").val('no');
                $("#" + this.pageId + "bcChg").val('no');
                $("#" + this.pageId + "aocChg").val('no');

                $("#" + this.pageId + "fovChg").val('no');
                $("#" + this.pageId + "fovRate").val("");
                $("#" + this.pageId + "fovRateChg").val("");
                this.fovRate = null;
                this.fovRateChg = null;
                this.viewFovRateField = false;

                $("#" + this.pageId + "ddChg").val('no');
                $("#" + this.pageId + "othersChg").val('no');
            }
            this.showSpinnerForAction = false;
        }, 2000);

        this.showSpinnerForAction = true;
        setTimeout(() => {
            if (!commodityRateData.isFixedRate) {
                $("#" + this.pageId + "searchRate").val('DiscRate');
                this.rateChangeTxtId = 'Discount Rate (Rs)';
            } else {
                $("#" + this.pageId + "searchRate").val('fixedRate');
                this.rateChangeTxtId = 'Rate';
            }
            this.showSpinnerForAction = false;
        }, 1000);

        this.showSpinnerForAction = true;
        setTimeout(() => {
            if ($("#" + this.pageId + "searchUnit").val() == 'rate') {
                this.viewPerUnitField = true;
                this.viewGCChgField = true;
                this.viewReciptChargeField = true;
                this.viewServiceChargeField = true;
            } else if ($("#" + this.pageId + "searchUnit").val() == 'percentage') {
                if ($("#" + this.pageId + "searchUnitPerc").val() == 'PLR') {
                    this.viewGCChgField = false;
                    this.viewPerUnitField = false;

                    this.viewReciptChargeField = true;
                    this.viewServiceChargeField = true;
                } else if ($("#" + this.pageId + "searchUnitPerc").val() == 'PCM') {
                    this.viewPerUnitField = false;
                    this.viewGCChgField = false;
                    this.viewReciptChargeField = false;
                    this.viewServiceChargeField = false;
                } else if ($("#" + this.pageId + "searchUnitPerc").val() == 'PFRT') {
                    this.viewGCChgField = false;
                    this.viewPerUnitField = false;

                    this.viewReciptChargeField = true;
                    this.viewServiceChargeField = true;
                }
            }
            this.showSpinnerForAction = false;
        }, 2000);
        // $("#" + this.pageId + "source").val(commodityRateData.source);
        console.log("commodityRateData.source");
        console.log(commodityRateData.source);
        this.lrDtoListSourceCheckedCneeAdmin = [];
        for (var i = 0; i < this.sourceDataListCneeAdmin.length; i++) {
            console.log("this.sourceDataListCneeAdmin");
            console.log(this.sourceDataListCneeAdmin);
            if (this.sourceDataListCneeAdmin[i].subStation.toLowerCase() == commodityRateData.source.toLowerCase()) {
                // this.sourceDataListCneeAdmin.checked=true;
                this.newAttributeSetSourceListCneeAdmin = {};
                this.newAttributeSetSourceListCneeAdmin.subStation = commodityRateData.source;
                console.log("inside of For if");
                $("#carmcselectedSource").prop("checked", true);
                this.lrDtoListSourceCheckedCneeAdmin.push(this.newAttributeSetSourceListCneeAdmin);
                break;
            }
        }

        $("#" + this.pageId + "unloadingChg").val(commodityRateData.unloadingChg);
        $("#" + this.pageId + "unloadingChgUnit").val(commodityRateData.unloadingChgUnit);
        $("#" + this.pageId + "unloadingChgGd").val(commodityRateData.unloadingChgGd);
        $("#" + this.pageId + "unloadingChgUnitGd").val(commodityRateData.unloadingChgUnitGd);
        this.changeDetectorRef.detectChanges();
    }

    rowSelectedConsigneeAdminPartyDataOnEdit(partyRateData) {
        this.rateMasterDtoForSelectedRowBothTable = null;
        this.rateMasterDtoForSelectedRowBothTable = partyRateData;

        $("#" + this.pageId + "nextBtnId").prop('disabled', true);
        $("#" + this.pageId + "saveBtnId").prop('disabled', true);
        $("#" + this.pageId + "updateBtnId").prop('disabled', false);

        if (!this.isRights) {
            this.disableAllFields();
        }

        console.log(partyRateData);
        $("#" + this.pageId + "searchMode").val('party');
        if ($("#" + this.pageId + "searchMode").val() == "party") {
            this.partyView = true;
        } else if ($("#" + this.pageId + "searchMode").val() == "commodity") {
            this.partyView = false;
        } else if ($("#" + this.pageId + "searchMode").val() == "rateForAll") {
            this.partyView = false;
        } else {
            this.partyView = false;
        }

        $("#" + this.pageId + "commodityListId").val(partyRateData.commodityName);
        $("#" + this.pageId + "consigneeNameId").val(partyRateData.consigneeName);
        this.validateSelectedConsigneeId = partyRateData.consigneeId;
        $("#" + this.pageId + "destinationId").val(partyRateData.destination);

        $("#" + this.pageId + "gcCharge").val(partyRateData.gcCharge);
        $("#" + this.pageId + "srcHamaliChg").val(partyRateData.srcHamaliChg);
        $("#" + this.pageId + "hamaliDestinationChg").val(partyRateData.hamaliDestination);
        $("#" + this.pageId + "reciptCharge").val(partyRateData.receiptCharges);
        $("#" + this.pageId + "serviceCharge").val(partyRateData.serviceCharges);
        $("#" + this.pageId + "unitServiceChg").val(partyRateData.serviceChargesUnit);

        $("#" + this.pageId + "rateOnActWgt").val('');
        this.rateOnActWgt = null;
        this.viewRateOnActWgtField = false;
        if (partyRateData.perUnit != null && partyRateData.perUnit == "Article") {
            $("#" + this.pageId + "perUnit").val(partyRateData.perUnit);
        } else if (partyRateData.perUnit != null && partyRateData.perUnit == "Actual Weight") {
            $("#" + this.pageId + "perUnit").val(partyRateData.perUnit);
            $("#" + this.pageId + "rateOnActWgt").val(partyRateData.rateOnActWgt);
            this.rateOnActWgt = partyRateData.rateOnActWgt;
            this.viewRateOnActWgtField = true;
        } else if (partyRateData.perUnit != null && partyRateData.perUnit == "Chargable Weight") {
            $("#" + this.pageId + "perUnit").val(partyRateData.perUnit);
        }

        if (partyRateData.status != null && partyRateData.status == "Cash") {
            $("#" + this.pageId + "status").val(partyRateData.status);
        } else if (partyRateData.status != null && partyRateData.status == "Account") {
            $("#" + this.pageId + "status").val(partyRateData.status);
        } else if (partyRateData.status != null && partyRateData.status == "Delivery") {
            $("#" + this.pageId + "status").val(partyRateData.status);
        } else {
            $("#" + this.pageId + "status").val("select");
        }
        $("#" + this.pageId + "fixedBy").val(partyRateData.fixedBy);
        $("#" + this.pageId + "effectFromDate").val(partyRateData.effectiveFromDate);
        if (partyRateData.fixedDate != null) {
            $("#" + this.pageId + "fixedOnDate").val(moment(partyRateData.fixedDate).format("YYYY-MM-DD"));
        }

        if (partyRateData.unit == "Rate") {
            this.viewPercBSFrtField = true;
            this.viewRateField = true;
            this.viewPercentageField = false;
        } else if (partyRateData.unit == "Discount") {
            this.viewPercBSFrtField = false;
            this.viewRateField = false;
            this.viewPercentageField = true;
        }
        this.showSpinnerForAction = true;
        setTimeout(() => {
            if (this.viewRateField && !this.viewPercentageField) {
                $("#" + this.pageId + "searchUnit").val('rate');
                $("#" + this.pageId + "ratePercentageId").val(partyRateData.rate);
                this.viewPerUnitField = true;
                this.viewGCChgField = true;
            } else if (this.viewPercentageField && !this.viewRateField) {
                if (partyRateData.column2 != null && partyRateData.column2 == "onLr") {
                    $("#" + this.pageId + "searchUnitPerc").val('PLR');
                    this.viewPerUnitField = true;
                    this.viewGCChgField = true;
                } else if (partyRateData.column2 != null && partyRateData.column2 == "onTotal") {
                    $("#" + this.pageId + "searchUnitPerc").val('PCM');
                    this.viewPerUnitField = false;
                    this.viewGCChgField = false;
                } else if (partyRateData.column2 != null && partyRateData.column2 == "onBaseFrgt") {
                    $("#" + this.pageId + "searchUnitPerc").val('PFRT');
                    this.viewPerUnitField = true;
                    this.viewGCChgField = true;
                }
                $("#" + this.pageId + "searchUnit").val('percentage');
                $("#" + this.pageId + "percentageRateId").val(partyRateData.rate);
            }
            this.showSpinnerForAction = false;
        }, 2000);

        this.showSpinnerForAction = true;
        setTimeout(() => {
            if (this.viewPercBSFrtField) {
                if (partyRateData.lcChg != null && partyRateData.lcChg) {
                    $("#" + this.pageId + "lcChg").val('yes');
                } else {
                    $("#" + this.pageId + "lcChg").val('no');
                }

                if (partyRateData.hamaliChg != null && partyRateData.hamaliChg) {
                    $("#" + this.pageId + "hamaliSrcChg").val('yes');
                    this.showCustomHamailSrcChgCheck = true;
                } else {
                    $("#" + this.pageId + "hamaliSrcChg").val('no');
                    this.showCustomHamailSrcChgCheck = false;
                }

                if (partyRateData.bcChg != null && partyRateData.bcChg) {
                    $("#" + this.pageId + "bcChg").val('yes');
                } else {
                    $("#" + this.pageId + "bcChg").val('no');
                }

                if (partyRateData.aoc != null && partyRateData.aoc) {
                    $("#" + this.pageId + "aocChg").val('yes');
                } else {
                    $("#" + this.pageId + "aocChg").val('no');
                }
                // alert(partyRateData.fovOn + "-" + partyRateData.fovPerc);
                if (partyRateData.riskCharge != null && partyRateData.riskCharge) {
                    //   alert("1 : " + partyRateData.riskCharge);
                    $("#" + this.pageId + "fovChg").val('yes');
                    this.fovRate = partyRateData.fovOn;
                    $("#" + this.pageId + "fovRate").val(partyRateData.fovOn);

                    this.fovRateChg = partyRateData.fovPerc;
                    $("#" + this.pageId + "fovRateChg").val(partyRateData.fovPerc);
                    this.viewFovRateField = true;
                } else {
                    // alert("2 : " + partyRateData.riskCharge);
                    $("#" + this.pageId + "fovChg").val('no');
                    $("#" + this.pageId + "fovRate").val("");
                    this.fovRate = null;
                    this.fovRateChg = null;
                    $("#" + this.pageId + "fovRateChg").val("");
                    this.viewFovRateField = false;
                }

                if (partyRateData.dd != null && partyRateData.dd) {
                    $("#" + this.pageId + "ddChg").val('yes');
                } else {
                    $("#" + this.pageId + "ddChg").val('no');
                }

                if (partyRateData.others != null && partyRateData.others) {
                    $("#" + this.pageId + "othersChg").val('yes');
                } else {
                    $("#" + this.pageId + "othersChg").val('no');
                }

                console.log('partyRateData.customHamaliSrcChg');
                console.log(partyRateData.customHamaliSrcChg);
                console.log(partyRateData.hamaliSrcCustom);
                $("#" + this.pageId + "customHamaliSrcChg").val(partyRateData.customHamaliSrcChg);
                if (partyRateData.hamaliSrcCustom != null && partyRateData.hamaliSrcCustom) {
                    $("#" + this.pageId + "customHamaliSrcCheckbox").prop('checked', true);
                    this.customHamailSrcChgCheckStatus = true;
                } else {
                    $("#" + this.pageId + "customHamaliSrcCheckbox").prop('checked', false);
                    this.customHamailSrcChgCheckStatus = false;
                }
            } else {
                $("#" + this.pageId + "lcChg").val('no');
                $("#" + this.pageId + "hamaliSrcChg").val('no');
                $("#" + this.pageId + "bcChg").val('no');
                $("#" + this.pageId + "aocChg").val('no');

                $("#" + this.pageId + "fovChg").val('no');
                $("#" + this.pageId + "fovRate").val("");
                $("#" + this.pageId + "fovRateChg").val("");
                this.fovRate = null;
                this.fovRateChg = null;
                this.viewFovRateField = false;

                $("#" + this.pageId + "ddChg").val('no');
                $("#" + this.pageId + "othersChg").val('no');
            }
            this.showSpinnerForAction = false;
            this.changeDetectorRef.detectChanges();
        }, 2000);

        this.showSpinnerForAction = true;
        setTimeout(() => {
            if (!partyRateData.isFixedRate) {
                $("#" + this.pageId + "searchRate").val('DiscRate');
                this.rateChangeTxtId = 'Discount Rate (Rs)';
            } else {
                $("#" + this.pageId + "searchRate").val('fixedRate');
                this.rateChangeTxtId = 'Rate';
            }
            this.showSpinnerForAction = false;
        }, 1000);

        this.showSpinnerForAction = true;
        setTimeout(() => {
            if ($("#" + this.pageId + "searchUnit").val() == 'rate') {
                this.viewPerUnitField = true;
                this.viewGCChgField = true;
                this.viewReciptChargeField = true;
                this.viewServiceChargeField = true;
            } else if ($("#" + this.pageId + "searchUnit").val() == 'percentage') {
                if ($("#" + this.pageId + "searchUnitPerc").val() == 'PLR') {
                    this.viewGCChgField = false;
                    this.viewPerUnitField = false;

                    this.viewReciptChargeField = true;
                    this.viewServiceChargeField = true;
                } else if ($("#" + this.pageId + "searchUnitPerc").val() == 'PCM') {
                    this.viewPerUnitField = false;
                    this.viewGCChgField = false;
                    this.viewReciptChargeField = false;
                    this.viewServiceChargeField = false;
                } else if ($("#" + this.pageId + "searchUnitPerc").val() == 'PFRT') {
                    this.viewGCChgField = false;
                    this.viewPerUnitField = false;

                    this.viewReciptChargeField = true;
                    this.viewServiceChargeField = true;
                }
            }
            /*if (this.viewRateField && !this.viewPercentageField) {
                if ($("#"+this.pageId+"searchUnit").val() == 'rate') {
                    this.viewPerUnitField = true;
                    this.viewGCChgField = true;
                }
            } else if (this.viewPercentageField && !this.viewRateField) {
                if ($("#"+this.pageId+"searchUnitPerc").val() == 'PLR') {
                    this.viewPerUnitField = true;
                    this.viewGCChgField = true;
                } else if ($("#"+this.pageId+"searchUnitPerc").val() == 'PCM') {
                    this.viewPerUnitField = false;
                    this.viewGCChgField = false;
                } else if ($("#"+this.pageId+"searchUnitPerc").val() == 'PFRT') {
                    this.viewPerUnitField = true;
                    this.viewGCChgField = true;
                }
            }*/
            this.showSpinnerForAction = false;
        }, 2000);
        // $("#" + this.pageId + "source").val(partyRateData.source);
        console.log("partyRateData.source");
        console.log(partyRateData.source);
        this.lrDtoListSourceCheckedCneeAdmin = [];
        for (var i = 0; i < this.sourceDataListCneeAdmin.length; i++) {
            console.log("this.sourceDataListCneeAdmin");
            console.log(this.sourceDataListCneeAdmin);
            if (this.sourceDataListCneeAdmin[i].subStation.toLowerCase() == partyRateData.source.toLowerCase()) {
                // this.sourceDataListCneeAdmin.checked=true;
                this.newAttributeSetSourceListCneeAdmin = {};
                this.newAttributeSetSourceListCneeAdmin.subStation = partyRateData.source;
                console.log("inside of For if");
                $("#carmcselectedSource").prop("checked", true);
                this.lrDtoListSourceCheckedCneeAdmin.push(this.newAttributeSetSourceListCneeAdmin);
                break;
            }
        }

        $("#" + this.pageId + "unloadingChg").val(partyRateData.unloadingChg);
        $("#" + this.pageId + "unloadingChgUnit").val(partyRateData.unloadingChgUnit);
        $("#" + this.pageId + "unloadingChgGd").val(partyRateData.unloadingChgGd);
        $("#" + this.pageId + "unloadingChgUnitGd").val(partyRateData.unloadingChgUnitGd);
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    deleteSelectedConsigneeAdminCommodityDetails(commodityRateData, stopRatePopUpTemplate) {

        this.modalRefferenceStopRatePopUp = this.modalService.open(stopRatePopUpTemplate,
            { centered: true, windowClass: "modalClassForPopUpTruckOwner" });
        this.modalRefferenceStopRatePopUp.result.then((result) => {
            this.modalRefferenceStopRateClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceStopRateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
        this.selectedRowDataDelete = null;
        this.selectedRowDataDelete = commodityRateData;
    }

    deleteSelectedConsigneeAdminPartyDetails(partyRateData, stopRatePopUpTemplate) {

        this.modalRefferenceStopRatePopUp = this.modalService.open(stopRatePopUpTemplate,
            { centered: true, windowClass: "modalClassForPopUpTruckOwner" });
        this.modalRefferenceStopRatePopUp.result.then((result) => {
            this.modalRefferenceStopRateClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceStopRateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
        this.selectedRowDataDelete = null;
        this.selectedRowDataDelete = partyRateData;
    }

    closePopUp() {
        this.modalRefferenceStopRatePopUp.close();
        this.selectedRowDataDelete = null;
        this.stopEffDateValidate = null;
        this.rateMasterDtoForDelete = new RateMasterDto();
    }

    // deleteSelectedConsigneeDetailsPopUp() {
    //     // this.showSpinnerForActionForStopEffe = true;
    //     if ($("#" + this.pageId + "stopEffectFromDate").val() == null || $("#" + this.pageId + "stopEffectFromDate").val() == undefined ||
    //         $("#" + this.pageId + "stopEffectFromDate").val() == "") {
    //         swal("Mandatory Field", "please select the Effective From ", "warning");
    //         return false;
    //     } else {
    //         swal({
    //             title: "Alert",
    //             text: "Sure You Want To Delete?",
    //             icon: "warning",
    //             buttons: ["No", "Yes"]
    //         }).then((isConfirm) => {
    //             if (isConfirm) {
    //                 console.log(this.selectedRowDataDelete);
    //                 this.rateMasterDtoForDelete = new RateMasterDto();
    //                 this.stopEffDateValidate = null;
    //                 this.stopEffDateValidate = $("#" + this.pageId + "stopEffectFromDate").val();
    //                 this.rateMasterDtoForDelete.id = this.selectedRowDataDelete.id;
    //                 this.rateMasterDtoForDelete.userName = this.userDataDtoReturnSession.userId;
    //                 this.rateMasterDtoForDelete.effectiveFromDate = this.stopEffDateValidate;
    //                 this.showSpinnerForActionForStopEffe = true;
    //                 this.consigneeService.deleteConsigneeAdminRateDetails(this.rateMasterDtoForDelete).subscribe(
    //                     (response) => {
    //                         console.log(response);
    //                         if (response.status == "success") {
    //                             swal("Success", "Selected detail deleted successfully", "success");
    //                             this.selectedRowDataDelete = null;
    //                             this.stopEffDateValidate = null;
    //                             this.rateMasterDtoForDelete = new RateMasterDto();
    //                             this.clearFieldAll();
    //                         } else if (response.status == "failed") {
    //                             swal("Warning", "failed to detail seletced consignee admin rate details", "success");
    //                         }
    //                         this.showSpinnerForActionForStopEffe = false;
    //                         this.changeDetectorRef.detectChanges();
    //                     }), (error) => {
    //                         this.showSpinnerForActionForStopEffe = false;
    //                         swal("Error", "Server Problem Occurred While Deleting the Consignee Admin Rate Details", "info");
    //                     }, () => console.log('done');
    //             }
    //         });
    //     }
    // }

    /* edit field end */

    disableAllFields() {
        //alert("dd");
        setTimeout(() => {
            $("#" + this.pageId + "consigneeNameId").prop('disabled', true);
            $("#" + this.pageId + "destinationId").prop('readonly', false);
            $("#" + this.pageId + "commodityListId").prop('disabled', true);
            $("#" + this.pageId + "source").prop('disabled', true);
            $("#" + this.pageId + "searchUnit").prop('disabled', true);
            $("#" + this.pageId + "searchRate").prop('disabled', true);
            $("#" + this.pageId + "ratePercentageId").prop('readonly', true);
            $("#" + this.pageId + "searchUnitPerc").prop('disabled', true);
            $("#" + this.pageId + "percentageRateId").prop('disabled', true);

            $("#" + this.pageId + "gcCharge").prop('disabled', true);
            $("#" + this.pageId + "srcHamaliChg").prop('disabled', true);
            $("#" + this.pageId + "customHamaliSrcChg").prop('disabled', true);
            $("#" + this.pageId + "hamaliDestinationChg").prop('readonly', true);
            $("#" + this.pageId + "reciptCharge").prop('readonly', true);
            $("#" + this.pageId + "serviceCharge").prop('readonly', true);
            $("#" + this.pageId + "unitServiceChg").prop('readonly', true);
            $("#" + this.pageId + "status").prop('disabled', true);
            $("#" + this.pageId + "perUnit").prop('disabled', true);
            $("#" + this.pageId + "rateOnActWgt").prop('readonly', true);
            $("#" + this.pageId + "fixedBy").prop('readonly', true);
            $("#" + this.pageId + "effectFromDate").prop('readonly', true);
            $("#" + this.pageId + "fixedOnDate").prop('readonly', true);
            $("#" + this.pageId + "lcChg").prop('disabled', true);
            $("#" + this.pageId + "hamaliSrcChg").prop('disabled', true);
            $("#" + this.pageId + "bcChg").prop('disabled', true);
            $("#" + this.pageId + "aocChg").prop('disabled', true);

            $("#" + this.pageId + "fovChg").prop('disabled', true);
            $("#" + this.pageId + "fovRate").prop('readonly', true);
            $("#" + this.pageId + "fovRateChg").prop('readonly', true);

            $("#" + this.pageId + "ddChg").prop('disabled', true);
            $("#" + this.pageId + "othersChg").prop('disabled', true);
        }, 2000);
    }



    validateResetBtn() {
        this.rateMasterDtoForSave = [];
        this.rateMasterDtoForSaveReturn = null;
        this.clearFieldAll();
    }

    clearFieldAll() {
        //$("input[type=text], textarea").val("");
        //$("input[type=number]").val("");
        $("#" + this.pageId + "consigneeNameId").val('');
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "commodityListId").val('');
        $("#" + this.pageId + "ratePercentageId").val('');
        $("#" + this.pageId + "percentageRateId").val('');
        $("#" + this.pageId + "gcCharge").val('');
        $("#" + this.pageId + "srcHamaliChg").val('');
        $("#" + this.pageId + "hamaliDestinationChg").val('');
        $("#" + this.pageId + "reciptCharge").val('');
        $("#" + this.pageId + "serviceCharge").val('');
        $("#" + this.pageId + "unitServiceChg").val('Charged Weight');
        $("#" + this.pageId + "fixedBy").val('');

        this.newAttributeTosetConsigneeAdminRateMaster = {};
        this.editConsigneeAdminRateMasterTableDetailsId = null;

        $("#" + this.pageId + "searchMode").val('party');
        if ($("#" + this.pageId + "searchMode").val() == "party") {
            this.partyView = true;
        } else if ($("#" + this.pageId + "searchMode").val() == "commodity") {
            this.partyView = false;
        } else if ($("#" + this.pageId + "searchMode").val() == "rateForAll") {
            this.partyView = false;
        } else {
            this.partyView = false;
        }
        $("#carmcselectedSource").prop("checked", false);

        this.rateAtSrcCheckStatus = false;
        $("#carmcrateAtSrcCheckbox").prop("checked", false);

        this.customHamailSrcChgCheckStatus = false;
        $("#customHamaliSrcCheckbox").prop("checked", false);
        $("#" + this.pageId + "customHamaliSrcChg").val('');
        this.showCustomHamailSrcChgCheck = false;
        //this.boxChecked = false;
        //alert('ok');
        this.getSourceDetails();
        //$("#"+this.pageId+"destinationId").val('');
        //$("#"+this.pageId+"destinationId").val(this.userDataDtoReturnSession.mainStation);
        //this.modelDestination = null;
        $("#" + this.pageId + "consigneeNameId").val('');
        this.modelConsigneeName = null;
        $("#" + this.pageId + "source").val('');
        this.modelSource = null;

        //this.dtTriggerSourceCneeAdmin.next();
        //this.sourceDataListCneeAdmin = [];
        this.lrDtoListSourceCheckedCneeAdmin = [];
        this.newAttributeSetSourceListCneeAdmin = {};

        $("#" + this.pageId + "commodityListId").val('');
        this.modelCommodityList = null;

        $("#" + this.pageId + "searchUnit").val('rate');
        this.viewRateField = true;
        this.viewPercentageField = false;
        $("#" + this.pageId + "searchRate").val('fixedRate');
        $("#" + this.pageId + "ratePercentageId").val('');
        this.rateChangeTxtId = 'Rate';

        $("#" + this.pageId + "searchUnitPerc").val('PLR');
        $("#" + this.pageId + "percentageRateId").val('');

        $("#" + this.pageId + "gcCharge").val('');
        $("#" + this.pageId + "srcHamaliChg").val('');
        $("#" + this.pageId + "hamaliDestinationChg").val('');
        $("#" + this.pageId + "reciptCharge").val('');
        $("#" + this.pageId + "serviceCharge").val('');
        $("#" + this.pageId + "unitServiceChg").val('Charged Weight');
        $("#" + this.pageId + "perUnit").val('Article');
        $("#" + this.pageId + "rateOnActWgt").val('');
        this.rateOnActWgt = null;
        this.viewRateOnActWgtField = false;
        $("#" + this.pageId + "status").val('select');
        $("#" + this.pageId + "fixedBy").val('');
        $("#" + this.pageId + "effectFromDate").val('');
        $("#" + this.pageId + "fixedOnDate").val('');

        $("#" + this.pageId + "lcChg").val('no');
        $("#" + this.pageId + "hamaliSrcChg").val('no');
        $("#" + this.pageId + "bcChg").val('no');
        $("#" + this.pageId + "aocChg").val('no');

        $("#" + this.pageId + "fovChg").val('no');
        $("#" + this.pageId + "fovRate").val("");
        $("#" + this.pageId + "fovRateChg").val("");
        this.fovRate = null;
        this.fovRateChg = null;
        this.viewFovRateField = false;

        $("#" + this.pageId + "ddChg").val('no');
        $("#" + this.pageId + "othersChg").val('no');
        this.viewPercBSFrtField = true;

        this.selectedDestinationForCommodity = null;
        this.selectedDestinationForConsigneeName = null;

        this.rateMasterDtoGridReconfi1 = new RateMasterDto();
        this.rateMasterDtoGridReconfi2 = new RateMasterDto();
        this.rateMasterDtoOnLoadGridReconfi = new RateMasterDto();
        //this.rateMasterDtoForSave = [];
        //this.rateMasterDtoForSaveReturn = null;

        this.validateGridReconfDestination = null;

        this.validateSelectedConsigneeId = null;
        this.validateSelectedDestination = null;

        this.newAttributeTosetConsigneeAdminRateMaster = {};
        this.editConsigneeAdminRateMasterTableDetailsId = null;

        this.rateMasterDataList = [];
        $("#" + this.pageId + "consigneeAdminMasterDraftDetailsTableId").DataTable().destroy();
        this.dtTriggerRateMaster.next();

        //this.getCommodityMasterDetailsList();
        //this.getConsigneeDetails();

        //temp commented as per req from Abubaker bh to avoid reload while update which taking time to update all parties rate, will need to uncomment soon
        //this.gridReconfigureForCommodity();
        $("#" + this.pageId + "searchUnitPerc").val('PLR');
        $("#" + this.pageId + "perUnit").val('Article');

        $("#" + this.pageId + "consigneeNameId").prop('readonly', false);
        $("#" + this.pageId + "destinationId").prop('readonly', false);
        $("#" + this.pageId + "commodityListId").prop('readonly', false);
        $("#" + this.pageId + "source").prop('readonly', false);
        $("#" + this.pageId + "searchUnit").prop('disabled', false);
        $("#" + this.pageId + "searchRate").prop('disabled', false);
        $("#" + this.pageId + "ratePercentageId").prop('readonly', false);
        $("#" + this.pageId + "searchUnitPerc").prop('disabled', false);
        $("#" + this.pageId + "percentageRateId").prop('readonly', false);

        $("#" + this.pageId + "gcCharge").prop('readonly', false);
        $("#" + this.pageId + "srcHamaliChg").prop('readonly', false);
        $("#" + this.pageId + "customHamaliSrcChg").prop('readonly', false);
        $("#" + this.pageId + "hamaliDestinationChg").prop('readonly', false);
        $("#" + this.pageId + "reciptCharge").prop('readonly', false);
        $("#" + this.pageId + "serviceCharge").prop('readonly', false);
        $("#" + this.pageId + "unitServiceChg").prop('readonly', false);
        $("#" + this.pageId + "status").prop('disabled', false);
        $("#" + this.pageId + "perUnit").prop('disabled', false);
        $("#" + this.pageId + "rateOnActWgt").prop('readonly', false);
        $("#" + this.pageId + "fixedBy").prop('readonly', false);
        $("#" + this.pageId + "effectFromDate").prop('readonly', false);
        $("#" + this.pageId + "fixedOnDate").prop('readonly', false);
        $("#" + this.pageId + "lcChg").prop('disabled', false);
        $("#" + this.pageId + "hamaliSrcChg").prop('disabled', false);
        $("#" + this.pageId + "bcChg").prop('disabled', false);
        $("#" + this.pageId + "aocChg").prop('disabled', false);

        $("#" + this.pageId + "fovChg").prop('disabled', false);
        $("#" + this.pageId + "fovRate").prop('readonly', true);
        $("#" + this.pageId + "fovRateChg").prop('readonly', true);

        $("#" + this.pageId + "ddChg").prop('disabled', false);
        $("#" + this.pageId + "othersChg").prop('disabled', false);

        $("#" + this.pageId + "fovRate").val('');
        $("#" + this.pageId + "fovRateChg").val('');

        $("#" + this.pageId + "nextBtnId").prop('disabled', false);
        $("#" + this.pageId + "saveBtnId").prop('disabled', false);
        $("#" + this.pageId + "updateBtnId").prop('disabled', true);

        if (!this.isRights) {
            this.disableAllFields();
        }

        this.viewGCChgField = true;
        this.viewPerUnitField = true;
        this.viewReciptChargeField = true;
        this.viewServiceChargeField = true;
        this.rateMasterDtoForRateDeleteChecked = [];
        this.viewRateOnActWgtField = false;
        $("#" + this.pageId + "rateOnActWgt").val('');
        this.rateOnActWgt = null;
        this.validateRateOnActWgt = null;
        $("#" + this.pageId + "unloadingChg").val('');
        $("#" + this.pageId + "unloadingChgUnit").val('Article');
        $("#" + this.pageId + "unloadingChgGd").val('');
        $("#" + this.pageId + "unloadingChgUnitGd").val('Article');

        //
        this.enteredConsigneeIdPartySearch = 0;
        this.modelConsigneeNamePartySearch = null;
        this.selectedDestinationForCommodityRateParty = null;
        this.selectedDestinationForConsigneeNameRateParties = null;
    }

    // gridReconfigureSource = () => {
    // 	this.sourceAgentDtoPopUp = new AgentDetailsDto();
    // 	this.sourceAgentDtoPopUp.companyId = this.userDataDtoReturnSession.companyId;
    // 	this.sourceAgentDtoPopUp.mode = "Sub";
    // 	this.sourceAgentDtoPopUp.status = "ALL";
    // 	this.showSpinnerForAction = true;
    // 	this.mastereadService.getSourceListDetails(this.sourceAgentDtoPopUp).subscribe(
    // 		(response) => {
    // 			console.log(response);
    // 			this.showSpinnerForAction = false;
    // this.sourceDataListCneeAdmin = [];
    // $("#"+this.pageId+"selectedSourceTableIdCneeAdmin").DataTable().destroy();
    // 			if (response.length == 0) {
    // 				swal("Warning", "No Source Details found !", "warning");
    // 			} else {
    // 				this.sourceDataListCneeAdmin = response;
    // 			}
    // 			this.dtTriggerSourceCneeAdmin.next();
    // 			this.changeDetectorRef.detectChanges();
    // 		}), (error) => {
    // 			this.showSpinnerForAction = false;
    // 			swal("Error", "Server Problem Occurred While getting the Source Details", "info");
    // 		},
    // 		() => console.log("done");
    // };

    rowCheckBoxForSourceCneeAdmin(e, sourceData, index) {
        console.log("e, sourceData, index");
        console.log(e, sourceData, index);
        if (e.currentTarget.checked) {
            this.newAttributeSetSourceListCneeAdmin = {};
            this.newAttributeSetSourceListCneeAdmin.subStation = sourceData.subStation;
            if (this.lrDtoListSourceCheckedCneeAdmin == null ||
                this.lrDtoListSourceCheckedCneeAdmin == undefined) {
                this.lrDtoListSourceCheckedCneeAdmin = [];
            }
            if (this.lrDtoListSourceCheckedCneeAdmin.length == 0) {
                this.lrDtoListSourceCheckedCneeAdmin.push(this.newAttributeSetSourceListCneeAdmin);
            } else {
                const result = this.lrDtoListSourceCheckedCneeAdmin.filter(lrDtoListSourceData =>
                    lrDtoListSourceData.subStation === this.newAttributeSetSourceListCneeAdmin.subStation);
                if (result.length > 0) {
                    swal("Duplicate Entry", "" + this.newAttributeSetSourceListCneeAdmin.subStation + " Already Exist", "warning");
                } else {
                    this.lrDtoListSourceCheckedCneeAdmin.push(this.newAttributeSetSourceListCneeAdmin);
                }
            }
        } else {
            let index = this.lrDtoListSourceCheckedCneeAdmin.findIndex(x => x.subStation === sourceData.subStation);
            this.lrDtoListSourceCheckedCneeAdmin.splice(index, 1);
        }
        console.log("this.lrDtoListSourceCheckedCneeAdmin >> rowCheckBoxForSourceCneeAdmin");
        console.log(this.lrDtoListSourceCheckedCneeAdmin);
        this.changeDetectorRef.detectChanges();
    }
    rowCheckBoxPartyRateClicked(e, partyRateData, index) {
        //console.log("e, sourceData, index");
        //console.log(e, partyRateData, index);
        if (e.currentTarget.checked) {
            //alert('checkd1 : ' + partyRateData.id);
            this.rateMasterDtoForRateDeleteChecked.push(partyRateData.id);
            //alert('checked : ' + index);
        } else {
            let index = this.rateMasterDtoForRateDeleteChecked.findIndex(x => x === partyRateData.id);
            this.rateMasterDtoForRateDeleteChecked.splice(index, 1);
            //alert('unchekced : ' + partyRateData.id + "-" + index);
        }
        console.log('list : ' + this.rateMasterDtoForRateDeleteChecked)
        this.changeDetectorRef.detectChanges();
    }

    deleteSelectedConsigneeAdminPartyDetailsMulti(stopRatePopUpTemplate) {

        this.modalRefferenceStopRatePopUp = this.modalService.open(stopRatePopUpTemplate,
            { centered: true, windowClass: "modalClassForPopUpTruckOwner" });
        this.modalRefferenceStopRatePopUp.result.then((result) => {
            this.modalRefferenceStopRateClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceStopRateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    deleteSelectedConsigneeDetailsMultiPopUp() {
        if (this.rateMasterDtoForRateDeleteChecked.length == 0) {
            swal("Mandatory Field", "No Rate selected to remove!", "warning");
            return false;
        }
        else if ($("#" + this.pageId + "stopEffectFromDate").val() == null || $("#" + this.pageId + "stopEffectFromDate").val() == undefined ||
            $("#" + this.pageId + "stopEffectFromDate").val() == "") {
            swal("Mandatory Field", "Please select the Effective From Date", "warning");
            return false;
        } else {
            swal({
                title: "Alert",
                text: "Sure You Want To Delete?",
                icon: "warning",
                buttons: ["No", "Yes"]
            }).then((isConfirm) => {
                if (isConfirm) {
                    console.log(this.selectedRowDataDelete);
                    this.rateMasterDtoForDelete = new RateMasterDto();
                    this.stopEffDateValidate = null;
                    this.stopEffDateValidate = $("#" + this.pageId + "stopEffectFromDate").val();
                    this.rateMasterDtoForDelete.listOfRateIds = this.rateMasterDtoForRateDeleteChecked;
                    this.rateMasterDtoForDelete.userName = this.userDataDtoReturnSession.userId;
                    this.rateMasterDtoForDelete.effectiveFromDate = this.stopEffDateValidate;
                    this.showSpinnerForActionForStopEffe = true;
                    this.consigneeService.deleteConsigneeAdminRateDetailsMulti(this.rateMasterDtoForDelete).subscribe(
                        (response) => {
                            console.log(response);
                            if (response.status == "success") {
                                swal("Success", "Selected detail deleted successfully", "success");
                                this.rateMasterDtoForRateDeleteChecked = null;
                                this.selectedRowDataDelete = null;
                                this.stopEffDateValidate = null;
                                this.rateMasterDtoForDelete = new RateMasterDto();
                                this.rateMasterDtoForSave = [];
                                this.rateMasterDtoForSaveReturn = null;
                                this.clearFieldAll();
                            } else if (response.status == "failed") {
                                swal("Warning", "failed to detail seletced consignee admin rate details", "success");
                            }
                            this.showSpinnerForActionForStopEffe = false;
                            this.changeDetectorRef.detectChanges();
                        }), (error) => {
                            this.showSpinnerForActionForStopEffe = false;
                            swal("Error", "Server Problem Occurred While Deleting the Consignee Admin Rate Details", "info");
                        }, () => console.log('done');
                }
            });
        }
    }

    searchFovRateMethod(searchFovRate: string) {
        if (searchFovRate === 'no') {
            this.viewFovRateField = false;
            this.fovRate = null;
            this.fovRateChg = null;
        } else if (searchFovRate === 'yes') {
            this.viewFovRateField = true;
        }
    }

    rateOnActWgtMethod(selectedUnit: string) {
        if (selectedUnit === 'Actual Weight') {
            this.viewRateOnActWgtField = true;
        } else {
            this.viewRateOnActWgtField = false;
            $("#" + this.pageId + "rateOnActWgt").val('');
            this.rateOnActWgt = null;
        }
    }

    rowCheckBoxCommodityRateClicked(e, commodityRateData) {
        if (e.currentTarget.checked) {
            this.rateMasterDtoForRateDeleteChecked.push(commodityRateData.id);
        } else {
            let index = this.rateMasterDtoForRateDeleteChecked.findIndex(x => x === commodityRateData.id);
            this.rateMasterDtoForRateDeleteChecked.splice(index, 1);
        }
        this.changeDetectorRef.detectChanges();
    }

    deleteSelectedConsigneeAdminCommodityDetailsMulti(stopRatePopUpTemplate) {
        this.modalRefferenceStopRatePopUp = this.modalService.open(stopRatePopUpTemplate,
            { centered: true, windowClass: "modalClassForPopUpTruckOwner" });
        this.modalRefferenceStopRatePopUp.result.then((result) => {
            this.modalRefferenceStopRateClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceStopRateClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    toggleRateAtSrcCheckbox(event) {
        if (event.target.checked) {
            this.rateAtSrcCheckStatus = true;
        } else {
            this.rateAtSrcCheckStatus = false;
        }
    }

    toggleCustomHamaliSrcCheckbox(event) {
        if (event.target.checked) {
            this.customHamailSrcChgCheckStatus = true;
        } else {
            this.customHamailSrcChgCheckStatus = false;
        }
    }

    isHamaliSrcMethod(isHamaliSrc: string) {
        if (isHamaliSrc === 'yes') {
            this.showCustomHamailSrcChgCheck = true;
        } else {
            this.showCustomHamailSrcChgCheck = false;
        }
    }

    //CHG_V-CM:02
    //Click Listener For Source main Station
    clickListnerForMainBookingSource(e: NgbTypeaheadSelectItemEvent) {
        this.modelMainBookingSource = e.item;
        $("#" + this.pageId + "mainBookingSourceId").val(this.modelMainBookingSource.mainBookStations);
        this.getAgentDetailList(this.modelMainBookingSource.mainBookStations);
    }

    //CHG_V-CM:02
    getMainBookingSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }

    getMainBookingSourceDetails() {
        this.getMainBookingSourceDataDetails();
        this.mastereadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                this.mainBookingSourceTA = [];
                this.agentDtoOptionSourceStation = [];
                this.mainBookingSourceTAForRateParty = [];
                this.agentDtoOptionSourceStation = response;
                for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                    this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
                    this.mainBookingSourceTAForRateParty.push(this.agentDtoOptionSourceStation[i]);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Booking Source Details", "info");
            },
            () => console.log('done');
    }

    //CHG_V-CM:02
    getUserValuesForAgentDetailsList(mainSource) {
        this.agentDtoParam = new AgentDetailsDto();
        this.agentDtoParam.mode = "MainAndGrpRateMaster";
        this.agentDtoParam.status = "Working";
        this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParam.mainStation = mainSource;
        console.log(this.agentDtoParam);
    }

    getAgentDetailList(mainSource) {
        this.showSpinnerForAction = true;
        $("#" + this.pageId + "agentName").val('');
        // this.enteredSourceForSave = null;
        this.getUserValuesForAgentDetailsList(mainSource);
        // getAgentNamesGrouping
        this.masterService.getAgentsDetails(this.agentDtoParam).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];
                } else {
                    this.agentDetailsOptionsSet = [];
                    this.agentNameTA = [];

                    //CHG_V-CM:02
                    this.sourceDataListCneeAdmin = [];
                    $("#" + this.pageId + "selectedSourceTableIdCneeAdmin").DataTable().destroy();
                    this.agentDetailsOptionsSet = response;
                    console.log("response >> Getting Response");
                    console.log(response);

                    for (let i = 0; i < this.agentDetailsOptionsSet.length; i++) {
                        this.agentNameTA.push(this.agentDetailsOptionsSet[i]);
                    }
                    //CHG_V-CM:02
                    this.sourceDataListCneeAdmin = response;
                }
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentName").focus();
                this.dtTriggerSourceCneeAdmin.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    //CHG_V-CM:02
    clickListnerForConsigneeRptSearch(event) {
        //alert('ok');
        this.modelConsigneePartySearch = null;
        this.modelConsigneePartySearch = event.item;
        this.enteredConsigneeIdPartySearch = 0;
        this.modelConsigneeNamePartySearch = null;
        this.modelConsigneeNamePartySearch = this.modelConsigneePartySearch.consigneeName
        $("#" + this.pageId + "consigneeNamePartySearch").val(this.modelConsigneePartySearch.consigneeName);
        if ($("#" + this.pageId + "consigneeNamePartySearch").val() == null || $("#" + this.pageId + "consigneeNamePartySearch").val() == undefined ||
            $("#" + this.pageId + "consigneeNamePartySearch").val() == "" || $("#" + this.pageId + "consigneeNamePartySearch").val() == "All") {
            this.enteredConsigneeIdPartySearch = 0;
            //alert('ok 1');
        } else {
            this.enteredConsigneeIdPartySearch = this.modelConsigneePartySearch.consigneeId;
            //alert('ok 2' + this.enteredConsigneeIdPartySearch);
        }
    }

    //CHG_V-CM:02
    clickListnerForDestinationRateForParties(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestinationSearchParty = e.item;
        $("#" + this.pageId + "destinationSearchForRateParty").val(this.modelDestinationSearchParty.destination);
        this.getCommodityMasterDetailsListForRateParties();
        this.getConsigneeDetailsForRateParties();
    }

    //CHG_V-CM:02
    getCommodityMasterDetailsListForRateParties = () => {
        this.showSpinnerForRateParties = true;
        this.rateMasterDto = new RateMasterDto();
        this.selectedDestinationForCommodityRateParty = $("#" + this.pageId + "destinationSearchForRateParty").val()
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "mainConsigneeMaster";
        this.rateMasterDto.destination = this.selectedDestinationForCommodityRateParty;
        this.mastereadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                this.showSpinnerForRateParties = false;
                if (response) {
                    //console.log(response);
                    if (response.length == 0) {
                        this.rateMasterDtoOptionsForRateParties = [];
                        //CHG_V-CM:02
                        this.commodityListSearchRptPartyTA = [];
                        $("#" + this.pageId + "commodityListIdSearchRptParty").val('');
                    } else {
                        this.rateMasterDtoOptionsForRateParties = [];
                        //CHG_V-CM:02
                        this.commodityListSearchRptPartyTA = [];
                        $("#" + this.pageId + "commodityListIdSearchRptParty").val('');
                        this.rateMasterDtoOptionsForRateParties = response;
                        console.log(this.rateMasterDtoOptionsForRateParties);

                        this.rateMasterDtoAll = new RateMasterDto();
                        this.rateMasterDtoAll.commodityName = "ALL";
                        //CHG_V-CM:02
                        this.commodityListSearchRptPartyTA.push(this.rateMasterDtoAll);
                        for (let i = 0; i < this.rateMasterDtoOptionsForRateParties.length; i++) {
                            //CHG_V-CM:02
                            this.commodityListSearchRptPartyTA.push(this.rateMasterDtoOptionsForRateParties[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            this.showSpinnerForRateParties = false;
            () => console.log('done');
    };

    //CHG_V-CM:02
    getConsigneeDetailsListForRateParties() {
        this.partyDtoConsigneeName = new PartyMasterDto();
        this.selectedDestinationForConsigneeNameRateParties = $("#" + this.pageId + "destinationSearchForRateParty").val();
        this.partyDtoConsigneeName.companyId = this.userDataDtoReturnSession.companyId;
        this.partyDtoConsigneeName.office = this.selectedDestinationForConsigneeNameRateParties;
        this.partyDtoConsigneeName.mode = "specific";
    }

    //CHG_V-CM:02
    getConsigneeDetailsForRateParties = () => {
        this.getConsigneeDetailsListForRateParties();
        this.showSpinnerForRateParties = true;
        this.mastereadService.getConsigneeMaster(this.partyDtoConsigneeName).subscribe(
            (response) => {
                this.showSpinnerForRateParties = false;
                if (response.length == 0) {
                    this.consigneeNameOptionsForRateParties = [];
                    //CHG_V-CM:02
                    this.consigneeNamePartySearchTA = [];
                    $("#" + this.pageId + "consigneeNamePartySearch").val('');
                } else {
                    this.consigneeNameOptionsForRateParties = [];
                    //CHG_V-CM:02

                    this.consigneeNamePartySearchTA = [];
                    this.consigneeNameForAllOption = new PartyMasterDto();
                    $("#" + this.pageId + "consigneeNamePartySearch").val('');

                    this.consigneeNameForAllOption.consigneeName = "ALL";
                    this.consigneeNamePartySearchTA.push(this.consigneeNameForAllOption);
                    this.consigneeNameOptionsForRateParties = response;
                    console.log(this.consigneeNameOptionsForRateParties);
                    for (let i = 0; i < this.consigneeNameOptionsForRateParties.length; i++) {
                        //CHG_V-CM:02
                        this.consigneeNamePartySearchTA.push(this.consigneeNameOptionsForRateParties[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForRateParties = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };

    searchPartyGridReconfigure() {
        this.rateMasterDtoOnLoadGridReconfi = new RateMasterDto();
        // this.rateMasterDtoOnLoadGridReconfi.city = this.userDataDtoReturnSession.mainStation;
        this.rateMasterDtoOnLoadGridReconfi.mode = "specific";
        this.rateMasterDtoOnLoadGridReconfi.rptModeId = 0;
        this.rateMasterDtoOnLoadGridReconfi.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForRateParties = true;

        //CHG_V-CM:02
        this.selectedSourceForPartySearch = $("#" + this.pageId + "agentNameRptSearchParty").val();
        this.selectedCommodityForPartySearch = $("#" + this.pageId + "commodityListIdSearchRptParty").val();
        this.selectedDestinationForPartySearch = $("#" + this.pageId + "destinationSearchForRateParty").val();
        this.selectedConsigneeForPartySearch = $("#" + this.pageId + "consigneeNamePartySearch").val();
        this.selectedMainSoureForPartySearch = $("#" + this.pageId + "mainBookingSourceForRatePartyId").val();
        this.rateMasterDtoOnLoadGridReconfi.source = this.selectedSourceForPartySearch == null ? 'ALL'
            : this.selectedSourceForPartySearch == undefined ? 'ALL' : this.selectedSourceForPartySearch == '' ? 'ALL'
                : this.selectedSourceForPartySearch;
        this.rateMasterDtoOnLoadGridReconfi.commodityName = this.selectedCommodityForPartySearch;
        this.rateMasterDtoOnLoadGridReconfi.city = this.selectedDestinationForPartySearch;
        this.rateMasterDtoOnLoadGridReconfi.consigneeId = this.enteredConsigneeIdPartySearch;
        this.rateMasterDtoOnLoadGridReconfi.consigneeName = this.selectedConsigneeForPartySearch;
        this.rateMasterDtoOnLoadGridReconfi.mainStation = this.selectedMainSoureForPartySearch;

        console.log("search");
        console.log(this.rateMasterDtoOnLoadGridReconfi);

        this.consigneeService.getConsigneeAdminRateMasterDetails(this.rateMasterDtoOnLoadGridReconfi).subscribe(
            (response) => {
                this.showSpinnerForRateParties = false;
                $("#" + this.pageId + "consigneeAdminMasterPartyDetailsTableId").DataTable().destroy();
                this.partyRateDataList = [];
                if (response.length == 0) {
                    swal("Warning", "No Party Details found !", "warning");
                } else {
                    this.partyRateDataList = response;
                    console.log(this.partyRateDataList);
                }
                this.dtTriggerPartyRate.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForRateParties = false;
                swal("Error", "Server Problem Occurred While getting the Party Rate details", "info");
            }, () => console.log('done');
    }

    validateSetupDetails() {
        this.selectedDestinationForPartySearch = $("#" + this.pageId + "destinationSearchForRateParty").val();

        if (this.selectedDestinationForPartySearch == null || this.selectedDestinationForPartySearch == undefined
            || this.selectedDestinationForPartySearch == '') {
            swal("Not Allowed", "Please select destination is mandatory fields to search!", "warning");
        } else {
            this.searchPartyGridReconfigure();
        }
    }

    getUserValuesForAgentDetailsListForRateParties(mainSource) {
        this.agentDtoParamForRateParty = new AgentDetailsDto();
        this.agentDtoParamForRateParty.mode = "MainAndGrpRateMaster";
        this.agentDtoParamForRateParty.status = "Working";
        this.agentDtoParamForRateParty.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParamForRateParty.mainStation = mainSource;
        console.log(this.agentDtoParamForRateParty);
    }

    getAgentDetailListForRateParties(mainSource) {
        this.showSpinnerForRateParties = true;
        $("#" + this.pageId + "agentName").val('');
        // this.enteredSourceForSave = null;
        this.getUserValuesForAgentDetailsListForRateParties(mainSource);
        // getAgentNamesGrouping
        this.masterService.getAgentsDetails(this.agentDtoParamForRateParty).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.agentDetailsOptionsSetForRateParty = [];
                    this.agentNameRptSearchPartyTA = [];
                    $("#" + this.pageId + "agentNameRptSearchParty").val('');
                } else {
                    this.agentDetailsOptionsSetForRateParty = [];
                    this.agentNameRptSearchPartyTA = [];
                    $("#" + this.pageId + "agentNameRptSearchParty").val('');
                    this.agentDetailsOptionsSetForRateParty = response;
                    console.log("response >> Getting Response");
                    console.log(response);

                    this.agentDtoParamForRateParty = new AgentDetailsDto();
                    this.agentDtoParamForRateParty.subStation = "ALL";
                    this.agentNameRptSearchPartyTA.push(this.agentDtoParamForRateParty);
                    for (let i = 0; i < this.agentDetailsOptionsSetForRateParty.length; i++) {
                        this.agentNameRptSearchPartyTA.push(this.agentDetailsOptionsSetForRateParty[i]);
                    }
                }
                this.showSpinnerForRateParties = false;
                $("#" + this.pageId + "agentName").focus();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForRateParties = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    clickListnerForMainSourceRateForParties(e: NgbTypeaheadSelectItemEvent) {
        this.modelMainBookingSource = e.item;
        $("#" + this.pageId + "mainBookingSourceForRatePartyId").val(this.modelMainBookingSource.mainBookStations);
        this.getAgentDetailListForRateParties(this.modelMainBookingSource.mainBookStations);
    }

}
