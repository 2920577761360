<div class="container custom-container">
	<!-- Header -->
	<div class="row justify-content-center">
		<div class="col-12 mb-3">
			<h6 class="text-center mt-3 mb-3" style="font-size: 1.5rem; font-weight: bold;">
				Loading Process: {{ selectedLoadingLocalTripNo }}
			</h6>
		</div>
	</div>

	<!-- Cards Section -->
	<div class="row justify-content-center">
		<!-- Total LR -->
		<div class="col-6 mb-3">
			<div class="info-card card-color-blue">
				<div class="info-header">
					<mat-icon class="icon-style">summarize</mat-icon>
					<span>Total LR</span>
				</div>
				<div class="info-count">{{ totalLr }}</div>
			</div>
		</div>

		<!-- Total Articles -->
		<div class="col-6 mb-3">
			<div class="info-card card-color-blue">
				<div class="info-header">
					<mat-icon class="icon-style">inventory</mat-icon>
					<span>Total Articles</span>
				</div>
				<div class="info-count">{{ totalArticles }}</div>
			</div>
		</div>
	</div>

	<div class="row justify-content-center">
		<!-- Loaded LR -->
		<div class="col-6 mb-3">
			<div class="info-card card-color-green">
				<div class="info-header">
					<mat-icon class="icon-style">done_all</mat-icon>
					<span>Loaded LR</span>
				</div>
				<div class="info-count">{{ loadedLr }}</div>
			</div>
		</div>

		<!-- Loaded Articles -->
		<div class="col-6 mb-3">
			<div class="info-card card-color-green">
				<div class="info-header">
					<mat-icon class="icon-style">assignment_turned_in</mat-icon>
					<span>Loaded Articles</span>
				</div>
				<div class="info-count">{{ loadedArticles }}</div>
			</div>
		</div>
	</div>
	<button style="float: right;" class="btn btn-success m-r-10 button_custome" id="{{pageId}}departureBtn"
		(click)="validateDepartureTripSheet();">Departure</button>
	<button type="button" class="btn btn-success waves-light" (click)="onPlusButtonClick(contenetUpdateOnAction)">
		Add
	</button>
	<button type="button" class="btn btn-danger waves-light" (click)="onMinusButtonClick(contenetUpdateOnAction)">
		Remove
	</button>
</div>
<ng-template #contenetUpdateOnAction let-c="close" let-d="dismiss">

	<div class="modal-header">
		
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">


				<div class="col-sm-12 col-md-12">
					<div class="control">
						<div class="form-group">
							<div class="input-group" id="{{pageId}}scrollable-dropdown-menu">
								<label>Enter Barcode No:</label>
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="ti-home"></i>
									</span>
								</div>
								<input #barcodeNoPopup id="{{pageId}}barcodeNoPopupId" type="text" class="form-control"
									aria-describedby="basic-addon11">
							</div>
						</div>
					</div>

				</div>

				<div class="col-sm-12 col-md-12">
					<button *ngIf="showPopupPlusBtn" style="padding: 1px 4px; float:right;" type="submit"
						class="btn btn-success" (click)="proceedbarcodeNoPopupPlusBtn()">Proceed</button>
					<button *ngIf="!showPopupPlusBtn" style="padding: 1px 4px; float:right;" type="submit"
						class="btn btn-success" (click)="proceedbarcodeNoPopupMinusBtn()">Proceed</button>
					<button style="padding: 1px 4px; float:right;" type="submit" class="btn btn-dark"
						id="{{pageId}}clearActionbtnBtn" (click)="d('Cross click')">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>