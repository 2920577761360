<!-- <div *ngIf="firstPrintAdv" id="{{pageId}}firstPrint" class="page row system_responsive"> -->
<div *ngFor="let prints of printArray" class="voucher-container" style="margin-top: 15px;">
    <div class="page row system_responsive">
        <div class="col-md-12">

            <div class="row" style="margin-bottom: 10px;">
                <div style="width: 40%; padding-left: 20px; text-align: left;">
                    <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo" style="max-width: 180px;">
                </div>
                <div style="width: 60%; text-align: left; padding-right: 10px;">
                    <h2 style="margin: 0; font-size: 24px;"><u>Payment Voucher</u></h2>
                </div>
            </div>

            <div class="row" style="margin-top: 20px; display: flex; flex-wrap: wrap;">
                <div style="flex: 1; min-width: 50%; padding-left: 20px; box-sizing: border-box;">
                    <h6 style="font-size: 19px; margin: 0; white-space: nowrap; overflow: visible; text-overflow: clip;">
                        <strong>DEBIT/CREDIT</strong>
                    </h6>
                    <h6 style="font-size: 19px; margin-top: 10px; white-space: nowrap; overflow: visible; text-overflow: clip;">
                        <strong>{{toStation}} Lorry Advance</strong>
                    </h6>
                </div>
                
                <div style="padding-right: 90px; flex: 1; min-width: 50%; text-align: right;  box-sizing: border-box;">
                    <h6 style="font-size: 19px; margin: 0; white-space: nowrap; overflow: visible; text-overflow: clip;">
                        <strong>Date: </strong>{{paymentDate}}
                    </h6>
                    <h6 *ngIf="showSupplier" style="font-size: 19px; margin-top: 10px; white-space: nowrap; overflow: visible; text-overflow: clip;">
                        <strong>Supplier: </strong>{{supplierName}}
                    </h6>
                </div>
            </div>
            

            <table width="100%" border="1" cellspacing="0" cellpadding="5"
                style="border-collapse: collapse; margin-top: 20px;">
                <tr>
                    <td colspan="3" style="padding-left: 15px;">
                        <h6 style="font-size: 19px; margin-top: 6px;">
                            <strong>A/c and Pay</strong>
                        </h6>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" align="center">
                        <h6 style="font-size: 23px; margin: 10px 0;">
                            <strong>Amount Paid Against</strong>
                        </h6>
                    </td>
                </tr>
                <tr>
                    <td width="35%" valign="top" style="padding-left: 15px;">
                        <h6 style="font-size: 23px; margin-top: 6px; padding-left: 55px;">
                            <strong>{{fromStation}}</strong>&nbsp; To &nbsp;<strong>{{toStation}}</strong>
                        </h6>
                        <h6 style="font-size: 18px; margin-top: 6px;">
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                        <h6 style="font-size: 18px; margin-top: 6px;">
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                        <h6 style="font-size: 18px; margin-top: 6px;">
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </td>
                    <td width="50%" valign="top" style="padding-left: 15px;">
                        <h6 *ngIf="!showChqWise" style="font-size: 18px; margin-top: 6px;">
                            <strong>Cash Amout :&nbsp;</strong>
                        </h6>
                        <h6 *ngIf="showChqWise" style="font-size: 18px; margin-top: 6px;">
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                        <h6 *ngIf="showPdcWise" style="font-size: 18px; margin-top: 6px;">
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                        <h6 *ngIf="showChqWise" style="font-size: 18px; margin-top: 6px;">
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                        <h6 *ngIf="showPdcWise" style="font-size: 18px; margin-top: 6px;">
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>
                        <h6 style="font-size: 18px; margin-top: 6px;">
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </td>
                    <td width="15%" style="border-left: 1px solid #000; padding-right: 10px;" valign="top">
                        <h6 style="font-size: 18px; text-align: right;">
                            <strong>{{rupees}}</strong>
                        </h6>
                        <h6 style="font-size: 18px; text-align: right; margin-top: 53px;">
                            <strong>{{tdsAmt}}</strong>
                        </h6>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="padding-left: 15px; text-align: right;">
                        <h6 style="font-size: 19px; margin-top: 6px; display: flex; justify-content: flex-end;">
                            <span style="font-size: 20px;">
                                <strong>Total (In Rs)</strong>
                            </span>
                        </h6>
                    </td>
                    <td style="border-left: 1px solid #000; padding-right: 10px;">
                        <h6 style="font-size: 20px; text-align: right;">
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" style="padding-left: 15px;">
                        <h6 style="font-size: 19px; margin-top: 6px;">
                            <strong>Received the sum of Rupees : </strong>{{printAmt}}
                        </h6>
                    </td>
                </tr>
            </table>

            <div class="row" style="margin-top: 10px;">
                <div class="col-md-12">
                    <h6 style="font-size: 19px; margin-left: 20px; margin-top: 10px;">
                        <strong>From :</strong> SRD Logistics Pvt. Ltd.
                    </h6>
                </div>
            </div>

            <div class="row" style="margin-top: 40px; display: flex; justify-content: space-between;">
                <div style="flex: 1; padding-left: 20px; min-width: 0;">
                    <p style="margin: 30px 0 5px 0; font-size: 16px; white-space: nowrap;">
                        <strong>Cashier Signature</strong>
                    </p>
                </div>
                <div style="flex: 1; text-align: right; padding-right: 20px; min-width: 0;">
                    <p style="margin: 30px 0 5px 0; font-size: 16px; white-space: nowrap;">
                        <strong>Receiver's Signature</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="otherPrints" class="voucher-container" style="margin-top: 15px;">
    <div class="page row system_responsive">
        <div class="col-md-12">

            <div class="row" style="margin-bottom: 10px;">
                <div style="width: 40%; padding-left: 20px; text-align: left;">
                    <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo" style="max-width: 180px;">
                </div>
                <div style="width: 60%; text-align: left; padding-right: 10px;">
                    <h2 style="margin: 0; font-size: 24px;"><u>Payment Voucher</u></h2>
                </div>
            </div>

            <div class="row" style="margin-top: 20px; display: flex; flex-wrap: wrap;">
                <div style="flex: 1; min-width: 50%; padding-left: 20px; box-sizing: border-box;">
                    <h6 style="font-size: 19px; margin: 0; white-space: nowrap; overflow: visible; text-overflow: clip;">
                        <strong>DEBIT/CREDIT</strong>
                    </h6>
                    <h6 style="font-size: 19px; margin-top: 10px; white-space: nowrap; overflow: visible; text-overflow: clip;">
                        <strong>{{toStation}} Lorry Advance</strong>
                    </h6>
                </div>
                
                <div style="padding-right: 90px; flex: 1; min-width: 50%; text-align: right;  box-sizing: border-box;">
                    <h6 style="font-size: 19px; margin: 0; white-space: nowrap; overflow: visible; text-overflow: clip;">
                        <strong>Date: </strong>{{paymentDate}}
                    </h6>
                    <h6 *ngIf="showSupplier" style="font-size: 19px; margin-top: 10px; white-space: nowrap; overflow: visible; text-overflow: clip;">
                        <strong>Supplier: </strong>{{supplierName}}
                    </h6>
                </div>
            </div>
            

            <table width="100%" border="1" cellspacing="0" cellpadding="5"
                style="border-collapse: collapse; margin-top: 20px;">
                <tr>
                    <td colspan="3" style="padding-left: 15px;">
                        <h6 style="font-size: 19px; margin-top: 6px;">
                            <strong>A/c and Pay</strong>
                        </h6>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" align="center">
                        <h6 style="font-size: 23px; margin: 10px 0;">
                            <strong>Amount Paid Against</strong>
                        </h6>
                    </td>
                </tr>
                <tr>
                    <td width="35%" valign="top" style="padding-left: 15px;">
                        <h6 style="font-size: 23px; margin-top: 6px; padding-left: 55px;">
                            <strong>{{fromStation}}</strong>&nbsp; To &nbsp;<strong>{{toStation}}</strong>
                        </h6>
                        <h6 style="font-size: 18px; margin-top: 6px;">
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                        <h6 style="font-size: 18px; margin-top: 6px;">
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                        <h6 style="font-size: 18px; margin-top: 6px;">
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </td>
                    <td width="50%" valign="top" style="padding-left: 15px;">
                        <h6 *ngIf="!showChqWise" style="font-size: 18px; margin-top: 6px;">
                            <strong>Cash Amout :&nbsp;</strong>
                        </h6>
                        <h6 *ngIf="showChqWise" style="font-size: 18px; margin-top: 6px;">
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                        <h6 *ngIf="showPdcWise" style="font-size: 18px; margin-top: 6px;">
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                        <h6 *ngIf="showChqWise" style="font-size: 18px; margin-top: 6px;">
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                        <h6 *ngIf="showPdcWise" style="font-size: 18px; margin-top: 6px;">
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>
                        <h6 style="font-size: 18px; margin-top: 6px;">
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </td>
                    <td width="15%" style="border-left: 1px solid #000; padding-right: 10px;" valign="top">
                        <h6 style="font-size: 18px; text-align: right;">
                            <strong>{{rupees}}</strong>
                        </h6>
                        <h6 style="font-size: 18px; text-align: right; margin-top: 53px;">
                            <strong>{{tdsAmt}}</strong>
                        </h6>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="padding-left: 15px; text-align: right;">
                        <h6 style="font-size: 19px; margin-top: 6px; display: flex; justify-content: flex-end;">
                            <span style="font-size: 20px;">
                                <strong>Total (In Rs)</strong>
                            </span>
                        </h6>
                    </td>
                    <td style="border-left: 1px solid #000; padding-right: 10px;">
                        <h6 style="font-size: 20px; text-align: right;">
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" style="padding-left: 15px;">
                        <h6 style="font-size: 19px; margin-top: 6px;">
                            <strong>Received the sum of Rupees : </strong>{{printAmt}}
                        </h6>
                    </td>
                </tr>
            </table>

            <div class="row" style="margin-top: 10px;">
                <div class="col-md-12">
                    <h6 style="font-size: 19px; margin-left: 20px; margin-top: 10px;">
                        <strong>From :</strong> SRD Logistics Pvt. Ltd.
                    </h6>
                </div>
            </div>

            <!-- Signatures - Fixed alignment -->
            <div class="row" style="margin-top: 40px; display: flex; justify-content: space-between;">
                <div style="flex: 1; padding-left: 20px; min-width: 0;"> <!-- min-width: 0 prevents overflow -->
                    <p style="margin: 30px 0 5px 0; font-size: 16px; white-space: nowrap;">
                        <strong>Cashier Signature</strong>
                    </p>
                </div>
                <div style="flex: 1; text-align: right; padding-right: 20px; min-width: 0;">
                    <p style="margin: 30px 0 5px 0; font-size: 16px; white-space: nowrap;">
                        <strong>Receiver's Signature</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <p *ngIf="secondPrintAdv" style='page-break-after: always;'></p>

<div *ngIf="secondPrintAdv" id="{{pageId}}secondPrint" class="page row system_responsive">
    <div class="col-md-12">
        
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 23px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Date : </strong> <span>{{paymentDate}}</span>
                </h6>
            </div>
            <div class="col-md-6" align='right' *ngIf="showSupplier">
                <h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Supplier : </strong> <span>{{supplierName}}</span>
                </h6>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 25px;'>
                    <strong>DEBIT/CREDIT</strong>
                </h6>
            </div>
            <div class="col-md-7" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 18px; font-size: 19px; padding-top: 25px;'>
                    <strong><span>{{toStation}} Lorry Advance</span></strong>
                </h6>
            </div>
            <div class="col-md-2" align='right'>
                <h6 style='margin-left: 15px; font-size: 19px; padding-top: 25px;'>
                    <strong>A/c and</strong>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Pay</strong>
                </h6>
            </div>
            <div class="col-md-10" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>

        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 165px;'>

                <td width=22%>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px;'>
                            <strong>On Account Of :</strong>
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>From :</strong>&nbsp;{{fromStation}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                    </tr>
                </td>
                <td width=27%>
                    <tr>
                        <h6 style='font-size: 19px;'>
                            <u>Amount Paid Against</u>
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>To : &nbsp;</strong>{{toStation}}
                        </h6>
                    </tr>
                </td>


                <td width=35%>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                    </tr>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </tr>

                </td>
                <td width=15.7% style='border-left: 1px solid #000;'>
                    <h6 style='font-size: 19px; margin-right: 5px; text-align: right'>
                        <strong>{{rupees}} </strong>
                    </h6>
                    <h6 style='font-size: 19px; text-align: right; margin-right: 5px; margin-top: 53px;'>
                        <strong>{{tdsAmt}} </strong>
                    </h6>
                </td>

            </table>

        </div>
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1'
                style='table-layout: fixed; border-top: none;'>
                <div class="row">
                    <div class="col-md-2">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong></strong>
                        </h6>
                    </div>
                    <div class="col-md-3">
                        <h6 style='font-size: 19px; margin-left: 20px;'></h6>
                    </div>
                    <div class="col-md-5">
                        <h6 style='font-size: 20px; margin-left: 20px;'>
                            <strong>Total (In Rs)</strong>
                        </h6>
                    </div>
                    <div class="col-md-2" style='border-left: 1px solid #000;'>
                        <h6 style='font-size: 20px; margin-right: 5px; text-align: right'>
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </div>

                </div>
            </table>
        </div>
        <br />
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 70px;'>
                <div class="row">
                    <div class="col-md-4">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong>Received the sum of Rupees :</strong>
                        </h6>
                    </div>
                    <div class="col-md-7">
                        <h6 style='font-size: 19px; margin-left: 15px; border-bottom: 1px dotted #000000;'>
                            <strong>{{printAmt}}</strong>
                        </h6>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div></div>
                <div class="row">
                    <div class="col-md-12">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            From <strong>SRD Logistics Pvt. Ltd.</strong>
                        </h6>
                    </div>



                </div>
            </table>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong>Cashier Signature</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>

<p *ngIf="thirdPrintAdv" style='page-break-after: always;'></p>

<div *ngIf="thirdPrintAdv" id="{{pageId}}thirdPrint" class="page row system_responsive">
    <div class="col-md-12">
        
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 23px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Date : </strong> <span>{{paymentDate}}</span>
                </h6>
            </div>
            <div class="col-md-6" align='right' *ngIf="showSupplier">
                <h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Supplier : </strong> <span>{{supplierName}}</span>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 25px;'>
                    <strong>DEBIT/CREDIT</strong>
                </h6>
            </div>
            <div class="col-md-7" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 18px; font-size: 19px; padding-top: 25px;'>
                    <strong><span>{{toStation}} Lorry Advance</span></strong>
                </h6>
            </div>
            <div class="col-md-2" align='right'>
                <h6 style='margin-left: 15px; font-size: 19px; padding-top: 25px;'>
                    <strong>A/c and</strong>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Pay</strong>
                </h6>
            </div>
            <div class="col-md-10" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>

        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 165px;'>

                <td width=22%>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px;'>
                            <strong>On Account Of :</strong>
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>From :</strong>&nbsp;{{fromStation}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                    </tr>
                </td>
                <td width=27%>
                    <tr>
                        <h6 style='font-size: 19px;'>
                            <u>Amount Paid Against</u>
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>To : &nbsp;</strong>{{toStation}}
                        </h6>
                    </tr>
                </td>


                <td width=35%>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                    </tr>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </tr>

                </td>
                <td width=15.7% style='border-left: 1px solid #000;'>
                    <h6 style='font-size: 19px; margin-right: 5px; text-align: right'>
                        <strong>{{rupees}} </strong>
                    </h6>
                    <h6 style='font-size: 19px; text-align: right; margin-right: 5px; margin-top: 53px;'>
                        <strong>{{tdsAmt}} </strong>
                    </h6>
                </td>

            </table>

        </div>
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1'
                style='table-layout: fixed; border-top: none;'>
                <div class="row">
                    <div class="col-md-2">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong></strong>
                        </h6>
                    </div>
                    <div class="col-md-3">
                        <h6 style='font-size: 19px; margin-left: 20px;'></h6>
                    </div>
                    <div class="col-md-5">
                        <h6 style='font-size: 20px; margin-left: 20px;'>
                            <strong>Total (In Rs)</strong>
                        </h6>
                    </div>
                    <div class="col-md-2" style='border-left: 1px solid #000;'>
                        <h6 style='font-size: 20px; margin-right: 5px; text-align: right'>
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </div>

                </div>
            </table>
        </div>
        <br />
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 70px;'>
                <div class="row">
                    <div class="col-md-4">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong>Received the sum of Rupees :</strong>
                        </h6>
                    </div>
                    <div class="col-md-7">
                        <h6 style='font-size: 19px; margin-left: 15px; border-bottom: 1px dotted #000000;'>
                            <strong>{{printAmt}}</strong>
                        </h6>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div></div>
                <div class="row">
                    <div class="col-md-12">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            From <strong>SRD Logistics Pvt. Ltd.</strong>
                        </h6>
                    </div>



                </div>
            </table>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong>Cashier Signature</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>

<p *ngIf="fourthPrintAdv" style='page-break-after: always;'></p>

<div *ngIf="fourthPrintAdv" id="{{pageId}}fourthPrint" class="page row system_responsive">
    <div class="col-md-12">
        
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 23px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Date : </strong> <span>{{paymentDate}}</span>
                </h6>
            </div>
            <div class="col-md-6" align='right' *ngIf="showSupplier">
                <h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Supplier : </strong> <span>{{supplierName}}</span>
                </h6>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 25px;'>
                    <strong>DEBIT/CREDIT</strong>
                </h6>
            </div>
            <div class="col-md-7" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 18px; font-size: 19px; padding-top: 25px;'>
                    <strong><span>{{toStation}} Lorry Advance</span></strong>
                </h6>
            </div>
            <div class="col-md-2" align='right'>
                <h6 style='margin-left: 15px; font-size: 19px; padding-top: 25px;'>
                    <strong>A/c and</strong>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Pay</strong>
                </h6>
            </div>
            <div class="col-md-10" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>

        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 165px;'>

                <td width=22%>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px;'>
                            <strong>On Account Of :</strong>
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>From :</strong>&nbsp;{{fromStation}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                    </tr>
                </td>
                <td width=27%>
                    <tr>
                        <h6 style='font-size: 19px;'>
                            <u>Amount Paid Against</u>
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>To : &nbsp;</strong>{{toStation}}
                        </h6>
                    </tr>
                </td>


                <td width=35%>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                    </tr>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </tr>

                </td>
                <td width=15.7% style='border-left: 1px solid #000;'>
                    <h6 style='font-size: 19px; margin-right: 5px; text-align: right'>
                        <strong>{{rupees}} </strong>
                    </h6>
                    <h6 style='font-size: 19px; text-align: right; margin-right: 5px; margin-top: 53px;'>
                        <strong>{{tdsAmt}} </strong>
                    </h6>
                </td>

            </table>

        </div>
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1'
                style='table-layout: fixed; border-top: none;'>
                <div class="row">
                    <div class="col-md-2">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong></strong>
                        </h6>
                    </div>
                    <div class="col-md-3">
                        <h6 style='font-size: 19px; margin-left: 20px;'></h6>
                    </div>
                    <div class="col-md-5">
                        <h6 style='font-size: 20px; margin-left: 20px;'>
                            <strong>Total (In Rs)</strong>
                        </h6>
                    </div>
                    <div class="col-md-2" style='border-left: 1px solid #000;'>
                        <h6 style='font-size: 20px; margin-right: 5px; text-align: right'>
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </div>

                </div>
            </table>
        </div>
        <br />
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 70px;'>
                <div class="row">
                    <div class="col-md-4">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong>Received the sum of Rupees :</strong>
                        </h6>
                    </div>
                    <div class="col-md-7">
                        <h6 style='font-size: 19px; margin-left: 15px; border-bottom: 1px dotted #000000;'>
                            <strong>{{printAmt}}</strong>
                        </h6>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div></div>
                <div class="row">
                    <div class="col-md-12">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            From <strong>SRD Logistics Pvt. Ltd.</strong>
                        </h6>
                    </div>



                </div>
            </table>
        </div>


        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong>Cashier Signature</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>

<p *ngIf="fifthPrintAdv" style='page-break-after: always;'></p>

<div *ngIf="fifthPrintAdv" id="{{pageId}}fifthPrint" class="page row system_responsive">
    <div class="col-md-12">
        
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 23px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Date : </strong> <span>{{paymentDate}}</span>
                </h6>
            </div>
            <div class="col-md-6" align='right' *ngIf="showSupplier">
                <h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Supplier : </strong> <span>{{supplierName}}</span>
                </h6>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 25px;'>
                    <strong>DEBIT/CREDIT</strong>
                </h6>
            </div>
            <div class="col-md-7" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 18px; font-size: 19px; padding-top: 25px;'>
                    <strong><span>{{toStation}} Lorry Advance</span></strong>
                </h6>
            </div>
            <div class="col-md-2" align='right'>
                <h6 style='margin-left: 15px; font-size: 19px; padding-top: 25px;'>
                    <strong>A/c and</strong>
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Pay</strong>
                </h6>
            </div>
            <div class="col-md-10" style='border-bottom: 1px dotted #000;'>
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>
            <div class="col-md-1">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>

        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 165px;'>

                <td width=22%>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px;'>
                            <strong>On Account Of :</strong>
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Lorry No : </strong> &nbsp;{{lorryNo}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>From :</strong>&nbsp;{{fromStation}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-left: 13px; margin-top: 6px;'>
                            <strong>Date :</strong> {{hireslipdate}}
                        </h6>
                    </tr>
                </td>
                <td width=27%>
                    <tr>
                        <h6 style='font-size: 19px;'>
                            <u>Amount Paid Against</u>
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Report No :&nbsp;</strong>{{hireslipNumber}}
                        </h6>
                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>To : &nbsp;</strong>{{toStation}}
                        </h6>
                    </tr>
                </td>


                <td width=35%>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No :&nbsp;</strong>{{chqNo}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px;'>
                            <strong>Cheque No : &nbsp;</strong>{{chqNoPdc}}
                        </h6>
                    </tr>
                    <tr *ngIf="showChqWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong>{{chqBankName}}
                        </h6>
                    </tr>
                    <tr *ngIf="showPdcWise">
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>Bank Name :&nbsp;</strong> {{chqBankNamePdc}}
                        </h6>

                    </tr>
                    <tr>
                        <h6 style='font-size: 19px; margin-top: 6px;'>
                            <strong>TDS Deduction</strong> Of (Amount Rs.{{totalHire}}/- @{{tdsPerc}})
                        </h6>
                    </tr>

                </td>
                <td width=15.7% style='border-left: 1px solid #000;'>
                    <h6 style='font-size: 19px; margin-right: 5px; text-align: right'>
                        <strong>{{rupees}} </strong>
                    </h6>
                    <h6 style='font-size: 19px; text-align: right; margin-right: 5px; margin-top: 53px;'>
                        <strong>{{tdsAmt}} </strong>
                    </h6>
                </td>

            </table>

        </div>
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1'
                style='table-layout: fixed; border-top: none;'>
                <div class="row">
                    <div class="col-md-2">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong></strong>
                        </h6>
                    </div>
                    <div class="col-md-3">
                        <h6 style='font-size: 19px; margin-left: 20px;'></h6>
                    </div>
                    <div class="col-md-5">
                        <h6 style='font-size: 20px; margin-left: 20px;'>
                            <strong>Total (In Rs)</strong>
                        </h6>
                    </div>
                    <div class="col-md-2" style='border-left: 1px solid #000;'>
                        <h6 style='font-size: 20px; margin-right: 5px; text-align: right'>
                            <strong>{{totalAmt}}</strong>
                        </h6>
                    </div>

                </div>
            </table>
        </div>
        <br />
        <div class="container border">
            <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed; height: 70px;'>
                <div class="row">
                    <div class="col-md-4">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            <strong>Received the sum of Rupees :</strong>
                        </h6>
                    </div>
                    <div class="col-md-7">
                        <h6 style='font-size: 19px; margin-left: 15px; border-bottom: 1px dotted #000000;'>
                            <strong>{{printAmt}}</strong>
                        </h6>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div></div>
                <div class="row">
                    <div class="col-md-12">
                        <h6 style='font-size: 19px; margin-left: 20px;'>
                            From <strong>SRD Logistics Pvt. Ltd.</strong>
                        </h6>
                    </div>



                </div>
            </table>
        </div>


        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong>Cashier Signature</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 19px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div> -->