import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from 'sweetalert';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { RequestDto } from 'src/app/dto/Request-dto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LRDto } from 'src/app/dto/LR-dto';
import { ResponseDto } from 'src/app/dto/Response-dto';
import { Subject, Observable, merge } from 'rxjs';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';

@Component({
    selector: 'app-consolidate-ewb-print',
    templateUrl: './consolidate-ewb-print.component.html',
    styleUrls: ['./consolidate-ewb-print.component.css']
})

export class ConsolidateEWBPrintComponent implements OnInit {

    userDataDtoReturnSession: any;
    pageId = "cebp";
    gstSearch = true;
    isLoggedIn = true;
    requestDto: RequestDto = new RequestDto();
    enteredEwayBillNo: any;
    showSpinnerForAction = false;
    pdfUrl: SafeResourceUrl | null = null;
    lrtDtoRespForEwayApi: LRDto = new LRDto();
    responseDtoForEwayApi: ResponseDto = new ResponseDto();
    vehicleDetails: any;
    dtTriggerVehicleDetails: Subject<any> = new Subject();
    fromGstin: any;
    fromTrdName: any;
    fromAddr1: any;
    fromAddr2: any;
    fromPincode: any;
    fromPlace: any;
    toGstin: any;
    toTrdName: any;
    toAddr1: any;
    toAddr2: any;
    toPlace: any;
    toPincode: any;
    lrtDtoForEwayApi: LRDto = new LRDto();
    fromAddressDtls: any;
    toAddressDtls: any;
    partyMasterDto: PartyMasterDto = new PartyMasterDto();
    stateCode: any;
    isValid = false;
    itemDetailList: any;
    dtTriggeritemDtls: Subject<any> = new Subject();
    eWayBillNo: any;
    generatedDateStr: any;
    distance: any;
    generatedBy: any;
    ewayBillValidUptoStr: any;
    docNo: any;
    docDate: any;
    totalAmount: any;
    cgst: any;
    sgst: any;
    igst: any;
    cessAmount: any;
    cessNonAdvolAmount: any;
    others: any;
    grandTotal: any;
    transportId: any;
    transportName: any;
    transDocNo2: any;
    transDocDate2: any;
    viewRefreshEway: boolean = false;
    viewPdfEway: boolean = false;
    dtOptionsitemDtls: any;
    dtOptionsVehicleDtls: any;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    constructor(
        private masterReadService: MasterReadService, private router: Router, private sanitizer: DomSanitizer,
        private ewaybillService: EwaybillService, public changeDetectorRef: ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsVehicleDtls = {
            dom: 'Bfrtip',
            buttons: [
            ],
            processing: true,
            responsive: true,
            "scrollCollapse": false,
            "paging": false,
            "info": false,
            searching: false,
            pagingType: 'full_numbers',
            pageLength: 9,
        },
            this.dtOptionsitemDtls = {
                dom: 'Bfrtip',
                buttons: [
                ],
                processing: true,
                responsive: true,
                "scrollCollapse": false,
                "paging": false,
                "info": false,
                searching: false,
                pagingType: 'full_numbers',
                pageLength: 9,
            }
    }

    ngOnDestroy(): void {
        this.dtTriggerVehicleDetails.unsubscribe();
        this.dtTriggeritemDtls.unsubscribe();
    }

    ngAfterViewInit(): void {
        // this.dtTriggerVehicleDetails.next();
        // this.dtTriggeritemDtls.next();
    }

    validateForEWayBillNo() {
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        if (this.enteredEwayBillNo == null || this.enteredEwayBillNo == undefined || this.enteredEwayBillNo == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter E-Way Bill Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            this.clearDetails();
            this.getEwayBillNoDetails();
        }
    }

    getEwayBillNoDetails() {
        this.viewPdfEway = true;
        this.requestDto = new RequestDto();
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        console.log(this.enteredEwayBillNo);
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.generateEwaybillToken(this.requestDto).subscribe(
            (response) => {
                if (response.status == "Success") {
                    this.pdfUrl = '';
                    this.openPdf(this.enteredEwayBillNo, response.token1, 5);
                } else {
                    swal("Failed", "Failed to generate the token for View Eway Bill Details, please contact Admin!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Failed to generate the token for View Eway bill Details, please contact Admin!", "error");
            }, () => console.log('done');
    }

    openPdf(ewayBillNo: string, token: string, type: number) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": type };

        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, token).subscribe({
            next: (data) => {
                if (!data || data.size === 0) {
                    swal("Warning", "Consolidate E-Way Bill not found. Please check the number and try again.", "Not Found");
                    return;
                } else {
                    const blob = new Blob([data], { type: 'application/pdf' });
                    const objectUrl = URL.createObjectURL(blob);

                    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            },
            error: (err) => {
                this.showSpinnerForAction = false;
                console.error('Error opening PDF:', err);
                swal("Error", "Failed to open the PDF. Please try again.", "error");
            }
        });
    }

    validateForEWayBill() {
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        if (this.enteredEwayBillNo == null || this.enteredEwayBillNo == undefined || this.enteredEwayBillNo == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Enter Consolidate E-Way Bill Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            })
        } else {
            this.clearDetails();
            this.getDetailsFromEwayBillRefreshEwb();
        }
    }

    getDetailsFromEwayBillRefreshEwb() {
        this.viewRefreshEway = true;
        this.showSpinnerForAction = true;
        this.enteredEwayBillNo = $("#" + this.pageId + "ewayBillNo").val();
        this.lrtDtoRespForEwayApi = new LRDto();
        this.responseDtoForEwayApi = new ResponseDto();
        this.responseDtoForEwayApi.ewayBillNo = this.enteredEwayBillNo;
        //CHG-V-LR:26
        this.responseDtoForEwayApi.token1 = this.userDataDtoReturnSession.mainAdminStation;
        this.ewaybillService.getDetailsFromEwayBillRefreshEwb(this.responseDtoForEwayApi).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.lrtDtoRespForEwayApi = response;
                this.vehicleDetails = [];
                $("#" + this.pageId + "vehicleDetailsTable").DataTable().destroy();
                this.itemDetailList = [];
                $("#" + this.pageId + "itemDetailsTable").DataTable().destroy();
                console.log(this.lrtDtoRespForEwayApi);
                if (this.lrtDtoRespForEwayApi.ewayBillStatus != null && this.lrtDtoRespForEwayApi.ewayBillStatus == "CANCEL") {
                    swal("Not Allowed", "This E-Way Bill Number : " + this.enteredEwayBillNo + "  show as Cancelled!.. Please Verify entered E-Way Bill No!", "warning");
                    $("#" + this.pageId + "eWayBillNo").val('');
                    this.enteredEwayBillNo = null;
                }
                else if (this.lrtDtoRespForEwayApi.isBlockedEwayBill) {
                    swal("Not Allowed", "The entered E-Way Bill No : " + this.enteredEwayBillNo + " is expiring today!.. Please do the extension to continue with the LR Generation.", "warning");
                    $("#" + this.pageId + "eWayBillNo").val('');
                    this.enteredEwayBillNo = null;
                }
                else {
                    if (this.lrtDtoRespForEwayApi.respStatus == 1) {
                        this.showSpinnerForAction = false;
                        this.setAddressDetails(this.lrtDtoRespForEwayApi);
                    } else {
                        console.log('getDetailsFromEwayBillRefreshEwb : no ewaybill details found!');
                    }
                }
                this.changeDetectorRef.detectChanges();
                this.dtTriggerVehicleDetails.next();
                this.dtTriggeritemDtls.next();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the details from the EWay Bill Number", "warning");
            }, () => console.log('done');
    }

    setAddressDetails(lrtDtoRespForEwayApi) {
        this.fromGstin = lrtDtoRespForEwayApi?.gstNoConsignor || '';
        this.fromTrdName = lrtDtoRespForEwayApi?.name || '';
        this.fromAddr1 = lrtDtoRespForEwayApi?.address1 || '';
        this.fromAddr2 = lrtDtoRespForEwayApi?.address2 || '';
        this.fromPincode = lrtDtoRespForEwayApi?.fromPincode || '';
        this.fromPlace = lrtDtoRespForEwayApi?.source || '';

        this.toGstin = lrtDtoRespForEwayApi?.gstNoConsignee || '';
        this.toTrdName = lrtDtoRespForEwayApi?.consigneeName || '';
        this.toAddr1 = lrtDtoRespForEwayApi?.consigneeAdress || '';
        this.toAddr2 = lrtDtoRespForEwayApi?.consigneeAddress2 || '';
        this.toPlace = lrtDtoRespForEwayApi?.destination || '';
        this.toPincode = lrtDtoRespForEwayApi?.pinCode || '';

        this.fromAddressDtls =
            `${this.fromGstin}\n${this.fromTrdName}\n${this.fromAddr1} ${this.fromAddr2}\n${this.fromPlace} - ${this.fromPincode}`;

        this.toAddressDtls =
            `${this.toGstin}\n${this.toTrdName}\n${this.toAddr1} ${this.toAddr2}\n${this.toPlace} - ${this.toPincode}`;

        this.vehicleDetails = lrtDtoRespForEwayApi?.vehicleDetails || [];

        let vehicleDetailsArray = [];
        if (Array.isArray(this.vehicleDetails)) {
            for (let i = 0; i < this.vehicleDetails.length; i++) {
                let parts = this.vehicleDetails[i]?.split(" #") || [];

                vehicleDetailsArray.push({
                    transDocDate: parts[0] || '',
                    vehicleNo: parts[1] || '',
                    transDocNo: parts[2] || '',
                    changePlace: parts[3] || '',
                    enteredDate: parts[4] || '',
                    enteredBy: parts[5] || '',
                    cewbNo: parts[6] || ''
                });
            }
        }
        this.vehicleDetails = vehicleDetailsArray;

        this.itemDetailList = lrtDtoRespForEwayApi?.itemDetails || [];

        let itemDetailsArray = [];
        if (Array.isArray(this.itemDetailList)) {
            for (let i = 0; i < this.itemDetailList.length; i++) {
                let itemSplit = this.itemDetailList[i]?.split(" #") || [];

                itemDetailsArray.push({
                    hsnCode: itemSplit[0] || '',
                    productName: itemSplit[1] || '',
                    productDesc: itemSplit[2] || '',
                    quantity: itemSplit[3] || '',
                    qtyUnit: itemSplit[4] || '',
                    cgstRate: itemSplit[5] || '',
                    sgstRate: itemSplit[6] || '',
                    igstRate: itemSplit[7] || '',
                    cessRate: itemSplit[8] || '',
                    cessNonAdvol: itemSplit[9] || '',
                    taxableAmount: itemSplit[10] || ''
                });
            }
        }
        this.itemDetailList = itemDetailsArray;

        this.eWayBillNo = lrtDtoRespForEwayApi?.eWayBillNo || '';
        this.generatedDateStr = lrtDtoRespForEwayApi?.generatedDateStr || '';
        this.distance = lrtDtoRespForEwayApi?.distance || 0;
        this.generatedBy = lrtDtoRespForEwayApi?.generatedBy || '';
        this.ewayBillValidUptoStr = lrtDtoRespForEwayApi?.ewayBillValidUptoStr || '';
        this.docNo = lrtDtoRespForEwayApi?.transDocNo || '';
        this.docDate = lrtDtoRespForEwayApi?.transDocDate || '';
        this.totalAmount = lrtDtoRespForEwayApi?.totalAmount || 0;
        this.cgst = lrtDtoRespForEwayApi?.cgst || 0;
        this.sgst = lrtDtoRespForEwayApi?.sgst || 0;
        this.igst = lrtDtoRespForEwayApi?.igst || 0;
        this.cessAmount = lrtDtoRespForEwayApi?.cessAmount || 0;
        this.cessNonAdvolAmount = lrtDtoRespForEwayApi?.cessNonAdvolAmount || 0;
        this.others = lrtDtoRespForEwayApi?.others || 0;
        this.grandTotal = lrtDtoRespForEwayApi?.grandTotal || 0;
        this.transportId = lrtDtoRespForEwayApi?.transportId || '';
        this.transportName = lrtDtoRespForEwayApi?.transportName || '';
        this.transDocNo2 = lrtDtoRespForEwayApi?.transDocNo2 || '';
        this.transDocDate2 = lrtDtoRespForEwayApi?.transDocDate2 || '';

        this.changeDetectorRef.detectChanges();
    }

    clearDetails() {
        this.fromAddressDtls = null;
        this.toAddressDtls = null;
        this.eWayBillNo = null;
        this.transDocDate2 = null;
        this.transDocNo2 = null;
        this.transportName = null;
        this.transportId = null;
        this.grandTotal = null;
        this.others = null;
        this.cessNonAdvolAmount = null;
        this.cessAmount = null;
        this.igst = null;
        this.sgst = null;
        this.cgst = null;
        this.totalAmount = null;
        this.docDate = null;
        this.docNo = null;
        this.ewayBillValidUptoStr = null;
        this.generatedBy = null;
        this.distance = null;
        this.generatedDateStr = null;
        this.viewRefreshEway = false;
        this.viewPdfEway = false;
        $("#" + this.pageId + "vehicleDetailsTable").DataTable().destroy();
        this.vehicleDetails = [];
        // this.dtTriggerVehicleDetails.next();
        $("#" + this.pageId + "itemDetailsTable").DataTable().destroy();
        this.itemDetailList = [];
        // this.dtTriggeritemDtls.next();
        this.pdfUrl = '';
    }

    clearBtn() {
        $("#" + this.pageId + "ewayBillNo").val('');
        this.fromAddressDtls = null;
        this.toAddressDtls = null;
        this.eWayBillNo = null;
        this.transDocDate2 = null;
        this.transDocNo2 = null;
        this.transportName = null;
        this.transportId = null;
        this.grandTotal = null;
        this.others = null;
        this.cessNonAdvolAmount = null;
        this.cessAmount = null;
        this.igst = null;
        this.sgst = null;
        this.cgst = null;
        this.totalAmount = null;
        this.docDate = null;
        this.docNo = null;
        this.ewayBillValidUptoStr = null;
        this.generatedBy = null;
        this.distance = null;
        this.generatedDateStr = null;
        this.viewRefreshEway = false;
        this.viewPdfEway = false;
        $("#" + this.pageId + "vehicleDetailsTable").DataTable().destroy();
        this.vehicleDetails = [];
        this.dtTriggerVehicleDetails.next();
        $("#" + this.pageId + "itemDetailsTable").DataTable().destroy();
        this.itemDetailList = [];
        this.dtTriggeritemDtls.next();
        this.pdfUrl = '';
    }

    printPage() {
        const printContent = document.getElementById("printSection");

        if (!printContent) {
            console.error("Print section not found!");
            return;
        }

        const originalOverflow = document.body.style.overflow;

        const contentClone = printContent.cloneNode(true) as HTMLElement;
        contentClone.style.visibility = 'visible';
        contentClone.style.position = 'absolute';
        contentClone.style.left = '0';
        contentClone.style.top = '0';
        contentClone.style.width = '100%';
        contentClone.style.backgroundColor = 'white';
        contentClone.style.zIndex = '9999';

        document.body.appendChild(contentClone);
        document.body.style.overflow = 'hidden';

        const style = document.createElement('style');
        style.textContent = `
          @media print {
            body > *:not(#printSection) {
              display: none !important;
            }
            body {
              padding: 0 !important;
              margin: 0 !important;
            }
            #printSection {
              position: relative !important;
              left: 0 !important;
              top: 0 !important;
              visibility: visible !important;
              width: 100% !important;
              height: auto !important;
            }
          }
        `;
        document.head.appendChild(style);

        setTimeout(() => {
            window.print();
            setTimeout(() => {
                document.body.removeChild(contentClone);
                document.head.removeChild(style);
                document.body.style.overflow = originalOverflow;
            }, 100);
        }, 100);
    }

}
