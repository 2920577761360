import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { DatePipe } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { StorageReportLrDetailsPopupComponent } from '../storage-report-lr-details-popup/storage-report-lr-details-popup.component.';

@Component({
	selector: 'app-storage-report',
	templateUrl: './storage-report.component.html',
	styleUrls: ['./storage-report.component.css']
})
export class StorageReportComponent implements OnInit {

	gettingDataFrmServiceFrStorageReportTable: any;
	storageReportDataList: any;
	loadingIndicator = true;
	control = new FormControl();
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerStorageReport: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsStorageReport: any;
	//for ConsigneeName
	consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
	consigneeNameTA: Array<PartyMasterDto> = [];
	consigneeNameTATemp: Array<PartyMasterDto> = [];
	focusConsigneeNameTA$ = new Subject<string>();
	consigneeNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeNameTA
				: this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
	///
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	public modelConsigneeNameId: any
	modelConsigneeName: any;
	consigneeNameOptions: any;
	partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
	selectedConsigneeId: any;
	address: any;
	office: any;
	selectedConsigneeName: any;
	searchConsigneeDto: LRDto = new LRDto();
	setConsigneeId: Array<any> = [];

	//For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	viewCustomPrintV1: boolean;
	lrsSummary: number;
	toArtSummary: number;
	toPaySummary: number;
	pageId = "storc";
	selectedConsigneeIdForPopup: any;

	constructor(private router: Router, private masterReadService: MasterReadService, private datePipe: DatePipe,
		private lrReportService: LrReportService, public changeDetectorRef: ChangeDetectorRef, public dialog: MatDialog) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			this.getConsigneeDetails(false);
		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
		this.dtOptionsStorageReport = {
			dom: 'Bfrtip',
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			buttons: [
				{
					extend: 'print',
					text: '<i class="fas fa-print"> Print</i>',
					titleAttr: 'Print',
					customize: function (win) {
						$(win.document.body).css('font-size', '10pt');
						$(win.document.body).find('th, td').
							css('font-family', 'Arial, Helvetica, sans-serif')
							.css('font-size', '13px').css('text-align', 'center');
					},
					footer: true,
					messageTop: function () {
						var returnVal = null;
						returnVal = "<table style='table-layout: fixed;width: 100%;'><tr>" +
							"<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
							"<u>Storage Stocks Report</u><br><br>" +
							" Consignee Name : " + $("#storcconsigneeName").val() + " Date :" + todayDate +
							"</td>" +
							"</tr></table><br>";

						return returnVal;

					},
					title: function () {
						return "<table style='table-layout: fixed;width: 100%;'><tr>" +
							"<td align='left' style='width: 20%;'>" +
							"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
							"</td>" +
							"<td align='left' style='width: 30%;'>" +
							"</td>" +
							"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
							companyAddressDetls +
							"</td>" +
							"</tr></table>";
					}
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {

						var returnSummaryExcel = "Storage Stocks Report  Consignee Name : " + $("#storcconsigneeName").val() + " Date :" + todayDate;

						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				var totalLrs = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totalArt = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var toPay = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);


				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html(totalLrs);
				$(api.column(2).footer()).html(totalArt);
				$(api.column(3).footer()).html(toPay);
				$(api.column(4).footer()).html();
			}

		}

	}

	ngOnDestroy(): void {
		this.dtTriggerStorageReport.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerStorageReport.next();
	}

	getConsigneeDetailsList(destShow) {
		this.consigneeNameOptions = [];
		this.partyMasterDtoForconsignee = new PartyMasterDto();
		this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDtoForconsignee.mode = "StockRptConsignee";
		console.log(this.partyMasterDtoForconsignee);
	}
	getConsigneeDetails = (destShow) => {
		this.getConsigneeDetailsList(destShow);

		this.showSpinnerForAction = true;
		this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Consignee Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.consigneeNameOptions = [];
					this.consigneeNameTA = [];
					this.consigneeNamePartyDtoAllOption.consigneeName = "All";
					this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
					$("#" + this.pageId + "consigneeName").val('All');
				} else {
					this.consigneeNameOptions = response;
					this.consigneeNameTA = [];
					$("#" + this.pageId + "consigneeName").val('');
					this.consigneeNamePartyDtoAllOption.consigneeName = "All";
					this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
					for (let i = 0; i < this.consigneeNameOptions.length; i++) {
						this.consigneeNameTA.push(this.consigneeNameOptions[i]);
					}
					$("#" + this.pageId + "consigneeName").val('All');
					console.log(this.consigneeNameTA);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the consignee details", "info");
			}, () => console.log('done');
	};


	rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
		this.modelConsigneeNameId = e.item;
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
	}
	searchMethod() {
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		console.log(this.selectedConsigneeName);
		this.searchConsigneeDto = new LRDto();
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.searchConsigneeDto.consigneeName = this.selectedConsigneeName;
			this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
			this.setConsigneeId = [];
			this.setConsigneeId.push(this.selectedConsigneeId);
			console.log(this.setConsigneeId);
			this.searchConsigneeDto.listOfConsigneId = this.setConsigneeId;

		} else {
			this.setConsigneeId = [];
			for (let i = 0; i < this.consigneeNameOptions.length; i++) {
				this.setConsigneeId.push(this.consigneeNameOptions[i].consigneeId);
			}
			console.log(this.setConsigneeId);
			this.searchConsigneeDto.listOfConsigneId = this.setConsigneeId;
		}
		this.searchConsigneeDto.destination = this.userDataDtoReturnSession.mainStation;
		this.searchConsigneeDto.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.searchConsigneeDto);
		this.searchStorageDetailsMethod();
	}
	searchStorageDetailsMethod = () => {
		this.showSpinnerForAction = true;
		this.lrReportService.getStorageStockRpt(this.searchConsigneeDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "storageReportTableId").DataTable().destroy();
				this.storageReportDataList = [];

				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.storageReportDataList = response;
					console.log(this.storageReportDataList);
				}
				this.dtTriggerStorageReport.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Storage details", "info");
			}, () => console.log('done');
	};

	clearMethod() {
		this.selectedConsigneeName = '';
		this.showSpinnerForAction = false;
		this.selectedConsigneeId = 0;
		this.modelConsigneeNameId = '';
		$("#" + this.pageId + "consigneeName").val('All');
		$("#" + this.pageId + "storageReportTableId").DataTable().destroy();
		this.storageReportDataList = [];
		this.dtTriggerStorageReport.next();
		this.searchConsigneeDto = new LRDto();
		this.setConsigneeId = [];
		this.selectedConsigneeIdForPopup = 0;
	}

	customPrintStorageRpt() {
		if (this.storageReportDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Party", "Tot LRs", "Tot Art", "To Pay", "Stocks At"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 20, 20, 20];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];



			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}



			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();

				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			this.lrsSummary = this.toArtSummary = this.toPaySummary = 0;

			for (let i = 0; i < this.storageReportDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.storageReportDataList[i].consigneeName, this.storageReportDataList[i].totalLrs,
				this.storageReportDataList[i].totalArticles, this.storageReportDataList[i].toPay,
				this.storageReportDataList[i].stocksAt];

				/////////
				this.lrsSummary = this.lrsSummary + this.storageReportDataList[i].totalLrs;
				this.toArtSummary = this.toArtSummary + this.storageReportDataList[i].totalArticles;
				this.toPaySummary = this.toPaySummary + this.storageReportDataList[i].toPay;



				/////
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.storageReportDataList.length, this.lrsSummary, this.toArtSummary, this.toPaySummary, ""];

			//heading logics
			this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();


			this.cashMemoDtoForCustomPrintListHeadingV1 = [];


			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Consignee Name"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedConsigneeName];




			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "Storage Report");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});

		}
	}

	lrDetailsPopup(storageReportData) {
		console.log(storageReportData.consigneeId);
		this.selectedConsigneeIdForPopup = storageReportData.consigneeId;
		this.lrDetailsDialog();
	}

	lrDetailsDialog(): void {
		const dialogRef = this.dialog.open(StorageReportLrDetailsPopupComponent, {
			data: { consigneeId: this.selectedConsigneeIdForPopup }
		});
	}

}