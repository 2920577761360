import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplierModel } from "src/app/master/supplier/supplier-master/supplier-model";
import { serverUrl } from "src/environments/environment";
import { CashMemoDto } from '../dto/CashMemo-dto';
import { PartyMasterDto } from '../dto/PartyMaster-dto';
import { InvoiceDto } from '../dto/Invoice-dto';
import { RateMasterDto } from '../dto/RateMaster-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { ResponseDto } from '../dto/Response-dto';

@Injectable({
    providedIn: 'root'
})
export class ConsigneeService {

    //private _url = "./assets/data/supplier-master.json";

    private _baseUrl = serverUrl.webConsignee;

    constructor(
        private http: HttpClient,
    ) {
    }

    getSupplierMasterData(): Observable<SupplierModel[]> {
        //return this.http.get<SupplierModel[]>(this._url);
        return this.http.get<SupplierModel[]>('./assets/data/supplier-master.json');


    }


    getSummaryData(): Observable<any[]> {
        //return this.http.get<SupplierModel[]>(this._url);
        return this.http.get<any[]>('./assets/data/lr-dispatch-booking-report.json');

    }

    getPartyOSDetailsSummaryWise(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getPartyOSDetailsSummaryWiseApi`, cashMemoDto);
    }

    getPartyOSDetailsWise(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getPartyOSDetailsWiseApi`, cashMemoDto);
    }

    getPartyOSOverAllSummaryView(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getPartyOSOverAllSummaryViewApi`, cashMemoDto);
    }

    getConsigneeOverallStatus(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsigneeOverallStatusApi`, partyMasterDto);
    }

    getPartyTransitLrDetail(invoiceDto: Object): Observable<InvoiceDto[]> {
        return this.http.post<InvoiceDto[]>(`${this._baseUrl}/getPartyTransitLrDetailApi`, invoiceDto);
    }

    getConsigneeRateHistDtlsRptPartyWise(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsigneeRateHistDtlsRptPartyWiseApi`, rateMasterDto);
    }

    getConsigneeRateHistDtlsRptCommodityWise(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsigneeRateHistDtlsRptCommodityWiseApi`, rateMasterDto);
    }

    getDiscontPartyRptDtls(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getDiscontPartyRptDtlsApi`, lrDto);
    }

    getBusinessStartReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBusinessStartReportApi`, lrDto);
    }
    getBusinessStartindividualReport(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBusinessStartindividualReportApi`, lrDto);
    }

    getConsigneeRepeatDetails(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsigneeRepeatDetailsApi`, partyMasterDto);
    }

    getConsigneeConfirmedDetails(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsigneeConfirmedDetailsApi`, partyMasterDto);
    }

    consigneeConfirmed(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/consigneeConfirmedApi`, partyMasterDto);
    }
    undoCgneeMerge(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/undoCgneeMergeApi`, partyMasterDto);
    }

    consigneeMerge(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/consigneeMergeApi`, partyMasterDto);
    }

    getConsigneeRateReportDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsigneeRateReportDetailsApi`, rateMasterDto);
    }

    /* admin bussiness rate rpt start */
    getConsigneeAdminRateDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsigneeAdminRateDetailsApi`, rateMasterDto);
    }
    /* admin bussiness rate rpt end */

    /* consignee admin rate rpt start */

    getConsigneeAdminRateReportDetailsTrxRpt(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsigneeAdminRateReportDetailsTrxRptApi`, rateMasterDto);
    }

    getConsigneeAdminRateCommodityDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsigneeAdminRateCommodityDetailsApi`, rateMasterDto);
    }
    /* consignee admin rate rpt end */

    getConsigneeAdminRateReportDetailsBusinessTrxRpt(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsigneeAdminRateReportDetailsBusinessTrxRptApi`, partyMasterDto);
    }

    getConsigneeDetailsPendingDB(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsigneeDetailsPendingDBApi`, partyMasterDto);
    }

    /* consignee admin Master  start */

    getConsigneeAdminRateMasterDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsigneeAdminRateMasterDetailsApi`, rateMasterDto);
    }

    addConsigneeAdminRate(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/addConsigneeAdminRateApi`, rateMasterDto);
    }


    recalculateBkgMemoAfterAdminRate(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/recalculateBkgMemoAfterAdminRateApi`, rateMasterDto);
    }


    deleteConsigneeAdminRateDetails(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/deleteConsigneeAdminRateDetailsApi`, rateMasterDto);
    }

    updateConsigneeAdminRate(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/updateConsigneeAdminRateApi`, rateMasterDto);
    }

    updateConsigneeRateForAll(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/updateConsigneeRateForAllApi`, rateMasterDto);
    }
    /* consignee admin Master start */

    /* Consignee Master - start */

    getConsigneeActNonActive(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getConsigneeActNonActiveApi`, lrDto);
    }

    getConsigneeActNonActiveDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getConsigneeActNonActiveDetailsApi`, lrDto);
    }

    /* Consignee Master - end */
    getConsigneeAdminRateMasterRateForAllPartiesDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsigneeAdminRateMasterRateForAllPartiesDetailsApi`, rateMasterDto);
    }

    deleteConsigneeAdminRateDetailsMulti(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/deleteConsigneeAdminRateDetailsMultiApi`, rateMasterDto);
    }

    getConsigneeRateDtls(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsigneeRateDetailsApi`, rateMasterDto);
    }

    deleteConsigneeRateDtls(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/deleteConsigneeRateDetailsApi`, rateMasterDto);
    }

    getConsigneeDtlsByID(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsigneeDetailsByIDApi`, partyMasterDto);
    }

    updateConsigneeRate(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/updateConsigneeRateApi`, rateMasterDto);
    }

    addConsigneeRateDtls(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/addConsigneeRateDetailsApi`, rateMasterDto);
    }

    updateHamliDdAreaWise(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/updateHamliDdAreaWiseApi`, rateMasterDto);
    }

    deleteDestMemoOtherChgCneeAreaDetails(rateMasterDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/deleteDestMemoOtherChgCneeAreaDetailsApi`, rateMasterDto);
    }

    getDestMemoOtherChgCneeDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getDestMemoOtherChgCneeDetailsApi`, rateMasterDto);
    }

    consolidateUpdateDestMemoOtherChgArea(rateMasterDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/consolidateUpdateDestMemoOtherChgAreaApi`, rateMasterDto);
    }

    getBusinessStopReportDetilList(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getBusinessStopReportDetilListApi`, lrDto);
    }

    updateHamliGdAreaWise(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/updateHamliGdAreaWiseApi`, rateMasterDto);
    }

    updateRateSourceWise(rateMasterDto: Object): Observable<RateMasterDto> {
        return this.http.post<RateMasterDto>(`${this._baseUrl}/updateRateSourceWiseApi`, rateMasterDto);
    }

    getNewConsigneeDetailsAndDoMerge(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/getNewConsigneeDetailsAndDoMergeApi`, partyMasterDto);
    }
}

