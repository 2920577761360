import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef } from "@angular/core";
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from 'src/app/dto/LR-dto';
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import swal from 'sweetalert';
import { MasterService } from 'src/app/dataService/master-service';
import { LrService } from 'src/app/dataService/lr-service';
import { HireslipService } from "src/app/dataService/hireslip-service";
import { DatePipe } from "@angular/common";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { MatDialog } from '@angular/material/dialog';
import { MultipleArticleRangePopupComponent } from "src/app/lr/enquiry/multiple-article-range-popup/multiple-article-range-popup.component";
import { HttpClient } from '@angular/common/http';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';

@Component({
    selector: 'app-estimate-enquiry-report',
    templateUrl: './estimate-enquiry-report.component.html',
    styleUrls: ['./estimate-enquiry-report.component.css']
})
export class EstimateEnquiryReportComponent implements OnInit {

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerEstimateEnquiryReport: Subject<any> = new Subject();
    dtOptionsEstimateEnquiryReport: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    lrDtoForSearch: LRDto = new LRDto();
    selectedFromDate: any;
    selectedToDate: any;
    selectedLocation: any;
    showSpinnerForAction: any;
    estimateEnquiryDetails: any;
    listOfMultipleArticlesRange: any;
    closeResult: string;
    rateMasterDto: RateMasterDto = new RateMasterDto();
    estimateEnquiryRateDetails: any;
    consignorRateType: any;
    consignorRatePerUnit: any;
    consignorRatePerUnitRateOnActWgt: any;
    consignorRateBasicRate: any;
    consignorRateHamali: any;
    consignorRateGcChg: any;
    consignorRateAoc: any;
    consignorRateOthers: any;
    consignorRateDDChg: any;
    consignorRateConsignee: any;
    consignorRateWEF: any;
    consignorRateFov: any;
    pageId = "estenqrpt";

    srcDestOptions: LRDto[];
    agentDtoLocation: AgentDetailsDto = new AgentDetailsDto();
    lrDtoLocationAll: LRDto = new LRDto();
    public modelsrcDest: any;
    srcDestTA: Array<LRDto> = [];
    focusSrcDestTA$ = new Subject<string>();
    searchSrcDest = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSrcDestTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.srcDestTA
                : this.srcDestTA.filter(v => v.location.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSrcDest = (x: { location: string }) => x.location;

    constructor(private router: Router, private masterService: MasterService, private masterReadService: MasterReadService,
        private lrService: LrService, private hireslipService: HireslipService, public changeDetectorRef: ChangeDetectorRef,
        private datePipe: DatePipe, public dialog: MatDialog, private http: HttpClient, private modalService: NgbModal) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getSourceAndDestinationDetailList();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsEstimateEnquiryReport = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export</i>',
                    titleAttr: 'Export',
                    footer: true,
                    title: function () {
                        return "Estimate Enquiry Details";
                    },
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16]
                    }
                }
            ],
            searching: true,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            pagingType: 'full_numbers',
            pageLength: 9,
            select: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                $(api.column(0).footer()).html('Total : ' + data.length);
            }
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerEstimateEnquiryReport.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerEstimateEnquiryReport.next();
    }

    getSourceAndDestinationList() {
        this.agentDtoLocation = new AgentDetailsDto();
        this.agentDtoLocation.mode = "activeDeliveryOfc";
        this.agentDtoLocation.status = "Working";
        this.agentDtoLocation.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoLocation.mainStation = "ALL";
    }

    getSourceAndDestinationDetailList() {
        $("#" + this.pageId + "location").val('');
        this.getSourceAndDestinationList();
        this.masterService.getSourceAndDestinationDetails(this.agentDtoLocation).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.srcDestOptions = [];
                    this.srcDestTA = [];
                } else {
                    this.srcDestOptions = [];
                    this.srcDestTA = [];
                    this.srcDestOptions = response;
                    this.lrDtoLocationAll = new LRDto();
                    this.lrDtoLocationAll.location = "ALL";
                    this.srcDestTA.push(this.lrDtoLocationAll);
                    for (let i = 0; i < this.srcDestOptions.length; i++) {
                        this.srcDestTA.push(this.srcDestOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    validateEstimateEnquiryDetails() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedLocation = $("#" + this.pageId + "location").val();

        if (this.selectedFromDate == null || this.selectedFromDate == undefined || this.selectedFromDate == ''
            || this.selectedToDate == null || this.selectedToDate == undefined || this.selectedToDate == ''
            || this.selectedLocation == null || this.selectedLocation == undefined || this.selectedLocation == '') {
            swal("Not Allowed", "Please Enter! All are mandatory fields to proceed!", "warning");
        } else {
            this.getEstimateEnquiryDtls();
        }
    }

    getEstimateEnquiryDetails() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedLocation = $("#" + this.pageId + "location").val();

        this.lrDtoForSearch = new LRDto;
        this.lrDtoForSearch.fromDate = this.selectedFromDate;
        this.lrDtoForSearch.toDate = this.selectedToDate;
        this.lrDtoForSearch.location = this.selectedLocation;
        console.log(this.lrDtoForSearch);
    }

    getEstimateEnquiryDtls = () => {
        this.showSpinnerForAction = true;
        this.getEstimateEnquiryDetails();
        this.lrService.getEstimateEnquiryRptDtls(this.lrDtoForSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "estimateEnquiryReportTableId").DataTable().destroy();
                this.estimateEnquiryDetails = [];
                if (response.length == 0) {
                    swal({
                        title: "No Details Found",
                        text: "No Records found for the selected Estimate Report!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.estimateEnquiryDetails = response;
                    console.log("Est");
                    console.log(this.estimateEnquiryDetails);
                }
                this.dtTriggerEstimateEnquiryReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Estimate Enquiry Details", "info");
            }, () => console.log('done');
    };

    getArticleRangeTable(estimateEnquiryReportData) {
        this.listOfMultipleArticlesRange = estimateEnquiryReportData.listOfmultipleArtCft;
        if (estimateEnquiryReportData.listOfmultipleArtCft == null || estimateEnquiryReportData.listOfmultipleArtCft.length == 0) {
            swal({
                title: "Warning",
                text: "No Multiple Article Size for this Lr",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.multipelArticleCfteDialog();
        }
    }

    multipelArticleCfteDialog(): void {
        const dialogRef = this.dialog.open(MultipleArticleRangePopupComponent, {
            data: { listOfDataToPass: this.listOfMultipleArticlesRange }
        });
    }

    clearMethod() {
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "location").val('');
        this.selectedFromDate = null;
        this.selectedToDate = null;
        this.selectedLocation = null;
        $("#" + this.pageId + "estimateEnquiryReportTableId").DataTable().destroy();
        this.estimateEnquiryDetails = [];
        this.dtTriggerEstimateEnquiryReport.next();
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    viewRateDetails(estimateEnquiryReportData, rateDetails) {
        this.modalService.open(rateDetails, { centered: true, size: 'md', windowClass: "myClassEstimate" })
            .result.then(
                result => {
                    this.closeResult = `Closed with: ${result}`;
                },
                reason => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
        this.clearRateDetails();
        setTimeout(() => {
            this.getRateDetails(estimateEnquiryReportData);
        }, 1000);
    }

    getRateDetails(estimateEnquiryReportData) {
        this.showSpinnerForAction = true;
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.id = estimateEnquiryReportData.consignorRateId;
        console.log(this.rateMasterDto);
        this.masterReadService.getRateDtlsForEstimateEnquiryRpt(this.rateMasterDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.estimateEnquiryRateDetails = [];
                if (response.length == 0) {
                    swal({
                        title: "No Details Found",
                        text: "No Records found for the selected Estimate Report!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.estimateEnquiryRateDetails = response;
                    console.log("estimateEnquiryRateDetails");
                    console.log(this.estimateEnquiryRateDetails);
                    this.consignorRateType = this.estimateEnquiryRateDetails.ratePerUnit;
                    this.consignorRatePerUnit = this.estimateEnquiryRateDetails.perUnit;
                    this.consignorRatePerUnitRateOnActWgt = this.estimateEnquiryRateDetails.rateOnActWgt;
                    this.consignorRateBasicRate = this.estimateEnquiryRateDetails.basicCharge;
                    this.consignorRateHamali = this.estimateEnquiryRateDetails.hamali;
                    this.consignorRateGcChg = this.estimateEnquiryRateDetails.gcCharge;
                    this.consignorRateAoc = this.estimateEnquiryRateDetails.aocChargeAmt;
                    this.consignorRateOthers = this.estimateEnquiryRateDetails.otherChgAmt;
                    this.consignorRateDDChg = this.estimateEnquiryRateDetails.ddChg;
                    this.consignorRateWEF = this.estimateEnquiryRateDetails.effectiveFromDateStg;
                    this.consignorRateFov = this.estimateEnquiryRateDetails.riskChargeAmt;
                }
                // this.dtTriggerEstimateEnquiryReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Rate Details", "info");
            }, () => console.log('done');
    };

    clearRateDetails() {
        this.consignorRateType = null;
        this.consignorRatePerUnit = null;
        this.consignorRatePerUnitRateOnActWgt = null;
        this.consignorRateBasicRate = null;
        this.consignorRateHamali = null;
        this.consignorRateGcChg = null;
        this.consignorRateAoc = null;
        this.consignorRateOthers = null;
        this.consignorRateDDChg = null;
        this.consignorRateWEF = null;
        this.consignorRateFov = null;
    }

}
