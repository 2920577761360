//Chg_GNEWB_1 : dummyCompanyName comments on GST portal Api service for get Null exception (24-08-2024)
//Chg_GNEWB_2 : Add transaction type and units dropdown fields (Asrar Jr / 28-02-2025)
//Chg_GNEWB_3 : Add totalGst field and calculation (Asrar Jr / 04-03-2025)

import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { NgbTypeaheadSelectItemEvent, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { DatePipe } from '@angular/common';
import { ResponseDto } from 'src/app/dto/Response-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { Subject, merge } from 'rxjs';
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import { RequestDto } from 'src/app/dto/Request-dto';

@Component({
    selector: 'app-generate-new-ewb',
    templateUrl: './generate-new-ewb.component.html',
    styleUrls: ['./generate-new-ewb.component.css']
})
export class GenerateNewEWBComponent implements OnInit {
    isLoggedIn = true;
    userDataDtoReturnSession: any;

    generateNewEWBForm = true;
    pageId = "gnewb";
    enteredGstNo: any;
    showSpinnerForAction = false;
    gstNumber: any;
    consignorStatus: any;
    consignorAddress1: any;
    consignorAddress2: any;
    consignorPincode: any;
    consignorStateCode: any;
    consignorShowStateCode: boolean = false;
    consignorLegalName: any;
    lrtDtoRespForGstNo: any;
    responseDtoForGstApi: ResponseDto = new ResponseDto();
    consignorTradeName: any;

    consigneeStatus: any;
    consigneeAddress1: any;
    consigneeAddress2: any;
    consigneePincode: any;
    consigneeStateCode: any;
    consigneeShowStateCode: boolean = false;
    consigneeLegalName: any;
    consigneeTradeName: any;

    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];

    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;
    lrtDtoForSave: LRDto = new LRDto();
    totArticles: any;
    selectedNewCommodity: any;
    enteredSubCommodity: any;
    enteredGoodsValue: any;
    // goodsValue = 0.0;
    goodsValue: any;
    enteredBillNumber: any;
    enteredBillDate: any;
    selectedconsignorGSTNumber: any;
    selectedconsigneeGSTNumber: any;
    selectedCommonCommodity: any;
    showSpinner = false;
    lrDtoSaveRet: any;
    enteredHSNCode: any;
    enteredCgstPerc: any;
    enteredSgstPerc: any;
    enteredCessPerc: any;
    enteredIgstPerc: any;
    enteredCgstAmt: any;
    enteredSgstAmt: any;
    enteredIgstAmt: any;
    enteredCessAmt: any;
    enteredReason: any;
    enteredNonAdvolCessPerc: any;
    enteredNonAdvolCessAmt: any;
    enteredOtherInvAmount: any;
    selectedTransaction: any;
    selectedUnit: any;
    totalGstAmtCal: any;
    totInvValue: any;
    vehicleNo: any;
    generatedEwayBill: any;
    requestDto: RequestDto = new RequestDto();
    isValid = false;
    dtTriggerGenerateNewEwayBill: Subject<any> = new Subject();
    dtOptionsGenerateNewEwayBill: any;
    private newAttribute: any = {};
    cgstAmount = 0.0;
    sgstAmount = 0.0;
    igstAmount = 0.0;
    cessAmount = 0.0;
    nonAdvolCessAmt = 0.0;
    totalGstAmt = 0.0;
    goodsValueCal = 0.0;
    ewayBillLists: any[] = [];
    errorCode: any;
    errorMessage: any;
    totalInvAmt: any;
    totGoodsValue: any;

    unitOptions = [
        { id: 1, label: 'BAGS - BAG' },
        { id: 2, label: 'BALE - BAL' },
        { id: 3, label: 'BUNDLES - BDL' },
        { id: 4, label: 'BUCKLES - BKL' },
        { id: 5, label: 'BILLION OF UNITS - BOU' },
        { id: 6, label: 'BOX - BOX' },
        { id: 7, label: 'BOTTLES - BTL' },
        { id: 8, label: 'BUNCHES - BUN' },
        { id: 9, label: 'CANS - CAN' },
        { id: 10, label: 'CUBIC METERS - CBM' },
        { id: 11, label: 'CUBIC CENTIMETERS - CCM' },
        { id: 12, label: 'CENTI METERS - CMS' },
        { id: 13, label: 'CARTONS - CTN' },
        { id: 14, label: 'DOZENS - DOZ' },
        { id: 15, label: 'DRUMS - DRM' },
        { id: 16, label: 'GREAT GROSS - GGK' },
        { id: 17, label: 'GRAMMES - GMS' },
        { id: 18, label: 'GROSS - GRS' },
        { id: 19, label: 'GROSS YARDS - GYD' },
        { id: 20, label: 'KILOGRAMS - KGS' },
        { id: 21, label: 'KILOLITRE - KLR' },
        { id: 22, label: 'KILOMETRE - KME' },
        { id: 23, label: 'LITRES - LTR' },
        { id: 24, label: 'METERS - MTR' },
        { id: 25, label: 'MILILITRE - MLT' },
        { id: 26, label: 'METRIC TON - MTS' },
        { id: 27, label: 'NUMBERS - NOS' },
        { id: 28, label: 'OTHERS - OTH' },
        { id: 29, label: 'PACKS - PAC' },
        { id: 30, label: 'PIECES - PCS' },
        { id: 31, label: 'PAIRS - PRS' },
        { id: 32, label: 'QUINTAL - QTL' },
        { id: 33, label: 'ROLLS - ROL' },
        { id: 34, label: 'SETS - SET' },
        { id: 35, label: 'SQUARE FEET - SQF' },
        { id: 36, label: 'SQUARE METERS - SQM' },
        { id: 37, label: 'SQUARE YARDS - SQY' },
        { id: 38, label: 'TABLETS - TBS' },
        { id: 39, label: 'TEN GROSS - TGM' },
        { id: 40, label: 'THOUSANDS - THD' },
    ];

    //For Bay Number
    public modelUnits: any;
    @ViewChild('instanceUnits') instanceUnits: NgbTypeahead;
    unitsTA: Array<any> = [];
    focusUnitsTA$ = new Subject<string>();
    unitsSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusUnitsTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.unitsTA
                : this.unitsTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    constructor(private router: Router, public dialog: MatDialog,
        public changeDetectorRef: ChangeDetectorRef, private masterReadService: MasterReadService,
        private ewaybillService: EwaybillService, private datePipe: DatePipe) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getCommodityMasterDetailsList();
            this.setUnits();
        }

    }

    ngOnInit() {
        this.dtOptionsGenerateNewEwayBill = {
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            pagingType: 'full_numbers',
            pageLength: 9,
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerGenerateNewEwayBill.unsubscribe();
    }

    ngAfterViewInit() {
        this.dtTriggerGenerateNewEwayBill.next();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    validateForGstNo(type, e) {
        if (e.keyCode == 13) {
            if (type != null && type == 'consignee') {
                this.enteredGstNo = $("#" + this.pageId + "consigneeGstNumber").val();
            } else if (type != null && type == 'consignor') {
                this.enteredGstNo = $("#" + this.pageId + "consignorGstNumber").val();
            }
            if (this.enteredGstNo == null || this.enteredGstNo == '' || this.enteredGstNo == undefined) {
                swal({
                    title: "Mandatory Fields",
                    text: "Please Enter Gst Number!",
                    icon: "warning",
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                })
            } else {
                this.getDetailsFromEwayBillSearchByGstNoMethod(type);
            }
        }
    }
    setGstNumber(type) {
        this.responseDtoForGstApi = new ResponseDto();
        if (type != null && type == 'consignee') {
            this.enteredGstNo = $("#" + this.pageId + "consigneeGstNumber").val();
        } else if (type != null && type == 'consignor') {
            this.enteredGstNo = $("#" + this.pageId + "consignorGstNumber").val();
        }

        this.responseDtoForGstApi.token1 = this.enteredGstNo;
    }
    getDetailsFromEwayBillSearchByGstNoMethod(type) {
        this.setGstNumber(type);
        this.showSpinnerForAction = true;
        this.ewaybillService.getDetailsFromEwayBillSearchByGstNo(this.responseDtoForGstApi).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                this.lrtDtoRespForGstNo = response;
                console.log("lrtDtoRespForGstNo");
                console.log(this.lrtDtoRespForGstNo);
                if (this.lrtDtoRespForGstNo.respStatus == 1) {

                    this.gstNumber = this.lrtDtoRespForGstNo.gstNoConsignee;
                    if (type != null && type == 'consignee') {
                        this.consigneeShowStateCode = true;
                        this.consigneeLegalName = this.lrtDtoRespForGstNo.name
                        this.consigneeTradeName = this.lrtDtoRespForGstNo.name;
                        this.consigneeStatus = this.lrtDtoRespForGstNo.status;
                        this.consigneeAddress1 = this.lrtDtoRespForGstNo.address1;
                        this.consigneeAddress2 = this.lrtDtoRespForGstNo.address2;
                        this.consigneePincode = this.lrtDtoRespForGstNo.pinCode;
                        this.consigneeStateCode = this.lrtDtoRespForGstNo.fromState;
                    } else if (type != null && type == 'consignor') {
                        this.consignorShowStateCode = true;
                        this.consignorLegalName = this.lrtDtoRespForGstNo.name
                        this.consignorTradeName = this.lrtDtoRespForGstNo.name;
                        this.consignorStatus = this.lrtDtoRespForGstNo.status;
                        this.consignorAddress1 = this.lrtDtoRespForGstNo.address1;
                        this.consignorAddress2 = this.lrtDtoRespForGstNo.address2;
                        this.consignorPincode = this.lrtDtoRespForGstNo.pinCode;
                        this.consignorStateCode = this.lrtDtoRespForGstNo.fromState;
                    }
                } else {
                    swal({
                        title: "Warning",
                        text: "No details found for the given GST Number!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details from the Gst Number", "warning");
            }, () => console.log('done');
    }

    clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelCommodityList = e.item;
        $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
    }

    getCommodityListRead() {
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.destination = null;
        this.rateMasterDto.mode = "mainConsigneeMaster";
        this.rateMasterDto.status = "Working";
    }

    getCommodityMasterDetailsList = () => {
        this.getCommodityListRead();
        this.showSpinnerForAction = true;
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No commodity details records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;

                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                        // $("#" + this.pageId + "commodityListId").val('All');
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    ///For Eway bill
    validateEwayBillGenerate() {
        this.enteredBillNumber = $("#" + this.pageId + "billNo").val();
        let enterBillDate = null;
        this.enteredBillDate = $("#" + this.pageId + "billDate").val();
        this.selectedTransaction = $("#" + this.pageId + "transactionType").val();
        this.vehicleNo = $("#" + this.pageId + "vehicleNo").val();
        //this.enteredBillDate = this.datePipe.transform(enterBillDate, "yyyy-MM-dd");
        this.selectedconsignorGSTNumber = $("#" + this.pageId + "consignorGstNumber").val();
        this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGstNumber").val();

        this.enteredCgstAmt = $("#" + this.pageId + "totalCgstAmt").val();
        this.enteredSgstAmt = $("#" + this.pageId + "totalSgstAmt").val();
        this.enteredIgstAmt = $("#" + this.pageId + "totalIgstAmt").val();
        this.enteredCessAmt = $("#" + this.pageId + "totalCessAdvol").val();
        this.enteredNonAdvolCessAmt = $("#" + this.pageId + "totalNonCessAdvol").val();
        this.enteredOtherInvAmount = $("#" + this.pageId + "otherInvAmount").val();
        this.totalInvAmt = $("#" + this.pageId + "totalInvAmt").val();
        this.totGoodsValue = $("#" + this.pageId + "totalTaxAmt").val();

        // itemDetails
        // this.selectedNewCommodity = $("#" + this.pageId + "commodityListId").val();
        // this.enteredSubCommodity = $("#" + this.pageId + "subCommodity").val();
        // this.enteredHSNCode = $("#" + this.pageId + "hsnCode").val();
        // this.totArticles = $("#" + this.pageId + "noOfArticles").val();
        // this.selectedUnit = $("#" + this.pageId + "units").val();
        // if (this.selectedUnit) {
        //     this.selectedUnit = this.selectedUnit.split(" - ")[1];
        // }
        // this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val();
        // this.goodsValue = (this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue == '' ? 0.0
        //     : this.enteredGoodsValue);
        // this.enteredCgstPerc = $("#" + this.pageId + "cgstPerc").val();
        // this.enteredSgstPerc = $("#" + this.pageId + "sgstPerc").val();
        // this.enteredIgstPerc = $("#" + this.pageId + "igstPerc").val();
        // this.enteredCessPerc = $("#" + this.pageId + "cessPerc").val();
        // this.enteredNonAdvolCessPerc = $("#" + this.pageId + "nonAdvolCessPerc").val();

        this.lrtDtoForSave = new LRDto();
        this.lrtDtoForSave.userName = this.userDataDtoReturnSession.userId;
        this.lrtDtoForSave.billNumber = this.enteredBillNumber;
        this.lrtDtoForSave.branch = this.userDataDtoReturnSession.office;
        // this.lrtDtoForSave.billDateStr = this.enteredBillDate;
        this.lrtDtoForSave.billDate = this.enteredBillDate;
        this.lrtDtoForSave.gstNoConsignor = this.selectedconsignorGSTNumber;
        this.lrtDtoForSave.consignorName = this.consignorTradeName;
        this.lrtDtoForSave.address = this.consignorAddress1;
        this.lrtDtoForSave.address2 = this.consignorAddress2;
        this.lrtDtoForSave.consignorPlace = this.consignorAddress2;
        this.lrtDtoForSave.consignorStateCode = this.consignorStateCode;
        this.lrtDtoForSave.consignorPincode = this.consignorPincode;
        this.lrtDtoForSave.gstNoConsignee = this.selectedconsigneeGSTNumber;
        this.lrtDtoForSave.consigneeName = this.consigneeTradeName;
        this.lrtDtoForSave.consigneeAdress = this.consigneeAddress1;
        this.lrtDtoForSave.consigneeAddress2 = this.consigneeAddress2;
        this.lrtDtoForSave.consigneePlace = this.consigneeAddress2;
        this.lrtDtoForSave.consigneeStateCode = this.consigneeStateCode;
        this.lrtDtoForSave.consigneePincode = this.consigneePincode;
        this.lrtDtoForSave.cgstValue = this.enteredCgstAmt;
        this.lrtDtoForSave.sgstValue = this.enteredSgstAmt;
        this.lrtDtoForSave.igstValue = this.enteredIgstAmt;
        this.lrtDtoForSave.cessValue = this.enteredCessAmt;
        this.lrtDtoForSave.cessNonAdvolValue = this.enteredNonAdvolCessAmt;
        this.lrtDtoForSave.otherValue = (this.enteredOtherInvAmount === '' || this.enteredOtherInvAmount === undefined || this.enteredOtherInvAmount === null) ? null : this.enteredOtherInvAmount;
        this.lrtDtoForSave.transactionType = this.selectedTransaction;
        this.lrtDtoForSave.totInvValue = this.totalInvAmt;
        this.lrtDtoForSave.vehicleNumber = this.vehicleNo;
        this.lrtDtoForSave.totalValue = this.totGoodsValue;

        this.lrtDtoForSave.lrDtoItemDetails = [];

        if (this.ewayBillLists && this.ewayBillLists.length > 0) {
            for (let item of this.ewayBillLists) {
                let lrDtoItem = new LRDto();
                lrDtoItem.commonCommodity = (item.commodityName === '' || item.commodityName === undefined || item.commodityName === null) ? null : item.commodityName;
                lrDtoItem.subCommodity = (item.commodityDesc === '' || item.commodityDesc === undefined || item.commodityDesc === null) ? null : item.commodityDesc;
                lrDtoItem.code = item.hsnCode;
                lrDtoItem.quantity = (item.noOfArticles === '' || item.noOfArticles === undefined || item.noOfArticles === null) ? null : item.noOfArticles;
                if (item.units != null) {
                    this.selectedUnit = item.units.split(" - ")[1];
                }
                lrDtoItem.unit = (this.selectedUnit === '' || this.selectedUnit === undefined || this.selectedUnit === null) ? null : this.selectedUnit;
                lrDtoItem.goodsValue = item.goodsValue;
                lrDtoItem.cgst = item.cgstPerc;
                lrDtoItem.sgst = item.sgstPerc;
                lrDtoItem.igst = item.igstPerc;
                lrDtoItem.cessPerc = item.cessPerc;
                lrDtoItem.cessNonAdvolPerc = item.nonCessPerc;

                this.lrtDtoForSave.lrDtoItemDetails.push(lrDtoItem);
            }
        } else {
            this.lrtDtoForSave.lrDtoItemDetails = null;
        }
        this.generateEwayBill();
    }

    validateItemDetails = () => {
        this.totArticles = $("#" + this.pageId + "noOfArticles").val();
        this.selectedNewCommodity = $("#" + this.pageId + "commodityListId").val();
        this.enteredSubCommodity = $("#" + this.pageId + "subCommodity").val();
        this.enteredHSNCode = $("#" + this.pageId + "hsnCode").val();
        this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val();
        this.goodsValue = (this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue == '' ? 0.0
            : this.enteredGoodsValue);
        this.enteredCgstPerc = $("#" + this.pageId + "cgstPerc").val();
        this.enteredSgstPerc = $("#" + this.pageId + "sgstPerc").val();
        this.enteredIgstPerc = $("#" + this.pageId + "igstPerc").val();
        this.enteredCessPerc = $("#" + this.pageId + "cessPerc").val();
        this.enteredNonAdvolCessPerc = $("#" + this.pageId + "nonAdvolCessPerc").val();

        if (this.enteredCessPerc == null || this.enteredCessPerc == '' ||
            this.enteredCgstPerc == null || this.enteredCgstPerc == '' ||
            this.enteredGoodsValue == null || this.enteredGoodsValue == '' ||
            this.enteredHSNCode == null || this.enteredHSNCode == '' ||
            this.enteredIgstPerc == null || this.enteredIgstPerc == '' ||
            this.enteredNonAdvolCessPerc == null || this.enteredNonAdvolCessPerc == '' ||
            this.enteredSgstPerc == null || this.enteredSgstPerc == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Fill the Mandatory fields*",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.grandSum();
            this.addInTable();
        }
    }

    validateVehicleNumber() {
        this.isValid = false;
        this.vehicleNo = $("#" + this.pageId + "vehicleNo").val();
        if ((this.vehicleNo == '') || (this.vehicleNo == null)) {
            this.isValid = false;
        } else {
            this.vehicleNo = this.vehicleNo.replace(/\s/g, "");

            if (this.vehicleNo.length < 9) {
                this.isValid = false;
            } else {
                if (/\d/.test(this.vehicleNo) && /[a-zA-Z]/.test(this.vehicleNo)) {
                    this.isValid = true;
                } else {
                    this.isValid = false;
                }
            }
        }
        return this.isValid;
    }

    generateEwayBill = () => {
        this.showSpinner = true;
        this.lrtDtoForSave.companyId = this.userDataDtoReturnSession.companyId;
        console.log("this.lrtDtoForSave");
        console.log(this.lrtDtoForSave);
        this.ewaybillService.generateNewEwayBill_V2(this.lrtDtoForSave).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.showSpinner = false;
                    swal({
                        title: "Error",
                        text: "Error Code : " + this.errorCode + " & Message " + this.errorMessage + " While generating the E way bill!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.showSpinner = false;
                    this.lrDtoSaveRet = response;
                    this.generatedEwayBill = this.lrDtoSaveRet.respEwayBillNo;
                    this.errorCode = this.lrDtoSaveRet.respField;
                    this.errorMessage = this.lrDtoSaveRet.message;
                    console.log("Gen EWB");
                    console.log(this.lrDtoSaveRet);
                    if (this.lrDtoSaveRet.status == null || this.lrDtoSaveRet.status == '') {
                        swal({
                            title: "Error",
                            text: "Error Code : " + this.errorCode + " & Message " + this.errorMessage + " While generating the E way bill!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else if (this.lrDtoSaveRet.status != null
                        && (this.lrDtoSaveRet.status ==
                            "failed")) {
                        swal({
                            title: "Error",
                            text: "Error Code : " + this.errorCode + " & Message " + this.errorMessage + " While generating the E way bill!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else if (this.lrDtoSaveRet.status != null
                        && (this.lrDtoSaveRet.status ==
                            "error")) {
                        swal({
                            title: "Error",
                            text: " Message " + this.errorMessage + " - while generating the E way Bill!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else {
                        swal({
                            title: "Success",
                            text: "E-way bill No : " + this.generatedEwayBill + " generated Successfully!",
                            icon: "success",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        }).then(() => {
                            this.printCEWB(this.generatedEwayBill);
                            this.clearfields();
                            this.ewayBillLists = [];
                        });
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    grandSum() {
        this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val() || 0;
        this.enteredCgstPerc = $("#" + this.pageId + "cgstPerc").val() || 0;
        this.enteredSgstPerc = $("#" + this.pageId + "sgstPerc").val() || 0;
        this.enteredIgstPerc = $("#" + this.pageId + "igstPerc").val() || 0;
        this.enteredCessPerc = $("#" + this.pageId + "cessPerc").val() || 0;
        this.enteredNonAdvolCessPerc = $("#" + this.pageId + "nonAdvolCessPerc").val() || 0;

        this.cgstAmount = Math.round((this.enteredGoodsValue * this.enteredCgstPerc) / 100);
        this.sgstAmount = Math.round((this.enteredGoodsValue * this.enteredSgstPerc) / 100);
        this.igstAmount = Math.round((this.enteredGoodsValue * this.enteredIgstPerc) / 100);
        this.cessAmount = Math.round((this.enteredGoodsValue * this.enteredCessPerc) / 100);
        this.nonAdvolCessAmt = Math.round((this.enteredGoodsValue * this.enteredNonAdvolCessPerc) / 100);
    }

    clearfields() {
        $("#" + this.pageId + "noOfArticles").val('');
        $("#" + this.pageId + "commodityListId").val('');
        $("#" + this.pageId + "subCommodity").val('');
        $("#" + this.pageId + "hsnCode").val('');
        $("#" + this.pageId + "goodsValue").val('');
        $("#" + this.pageId + "billNo").val('');
        $("#" + this.pageId + "billDate").val('');
        $("#" + this.pageId + "cgstPerc").val('');
        $("#" + this.pageId + "sgstPerc").val('');
        $("#" + this.pageId + "igstPerc").val('');
        $("#" + this.pageId + "cessPerc").val('');
        $("#" + this.pageId + "nonAdvolCessPerc").val('');
        $("#" + this.pageId + "cgstAmt").val('');
        $("#" + this.pageId + "sgstAmt").val('');
        $("#" + this.pageId + "igstAmt").val('');
        $("#" + this.pageId + "cessAmt").val('');
        $("#" + this.pageId + "nonAdvolCessAmt").val('');
        $("#" + this.pageId + "otherInvAmount").val('');
        // $("#" + this.pageId + "reason").val('');

        $("#" + this.pageId + "consignorGstNumber").val('');
        $("#" + this.pageId + "consigneeGstNumber").val('');

        this.consigneeLegalName = '';
        this.consigneeTradeName = '';
        this.consigneeStatus = '';
        this.consigneeAddress1 = '';
        this.consigneeAddress2 = '';
        this.consigneePincode = '';
        this.consigneeStateCode = '';

        this.consignorLegalName = '';
        this.consignorTradeName = '';
        this.consignorStatus = '';
        this.consignorAddress1 = '';
        this.consignorAddress2 = '';
        this.consignorPincode = '';
        this.consignorStateCode = '';

        $("#" + this.pageId + "transactionType").val('');
        $("#" + this.pageId + "units").val('');
        $("#" + this.pageId + "totalGst").val('');
        this.totInvValue = '';
        $("#" + this.pageId + "vehicleNo").val('');
        this.vehicleNo = null;
        this.newAttribute = {};
        this.ewayBillLists = [];
        if (($.fn as any).DataTable.isDataTable("#" + this.pageId + "generateNewEwayBillTableId")) {
            const dataTableInstance = $("#" + this.pageId + "generateNewEwayBillTableId").DataTable();
            dataTableInstance.clear().destroy();
        }
        setTimeout(() => {
            this.dtTriggerGenerateNewEwayBill.next();
        }, 100);
        this.totalInvAmt = null;
        this.totGoodsValue = null;
        $("#" + this.pageId + "totalTaxAmt").val('');
        $("#" + this.pageId + "totalCgstAmt").val('');
        $("#" + this.pageId + "totalSgstAmt").val('');
        $("#" + this.pageId + "totalIgstAmt").val('');
        $("#" + this.pageId + "totalCessAdvol").val('');
        $("#" + this.pageId + "totalNonCessAdvol").val('');
        $("#" + this.pageId + "totalInvAmt").val('');
        this.enteredOtherInvAmount = null;
    }

    setUnits() {
        for (let i = 0; i < this.unitOptions.length; i++) {
            this.unitsTA.push(this.unitOptions[i].label);
        }
    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    printCEWB(eWayBillNo) {
        if (eWayBillNo == null) {
            swal("Not Allowed", "Not Found E-way Bill No!", "warning");
        } else {
            this.requestDto = new RequestDto();
            this.showSpinnerForAction = true;
            this.changeDetectorRef.detectChanges();
            this.ewaybillService.generateEwaybillToken(this.requestDto).subscribe(
                (response) => {
                    if (response.status == "Success") {
                        this.openGeneratedEwayBillDetails(eWayBillNo, response.token1, 4);
                    } else {
                        swal("Failed", "Failed to show generated E-Way Bill Details!", "error");
                    }
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                }),
                (error) => {
                    this.showSpinnerForAction = false;
                    swal("Server Error", "Failed to show generated E-Way Bill Details!", "error");
                }, () => console.log('done');
        }
    }

    openGeneratedEwayBillDetails(ewayBillNo, token, type) {
        const jsonRequest = { "ewbNo": [ewayBillNo], "type": type };
        const authToken = token;
        this.ewaybillService.downloadConsolidatedEWBApi(jsonRequest, authToken).subscribe((data) => {
            console.log('opening PDF EWB - ' + ewayBillNo);
            const blob = new Blob([data], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        });
    }

    addInTable() {
        if (!this.ewayBillLists) {
            this.ewayBillLists = [];
        }

        this.newAttribute = this.newAttribute || {};

        this.newAttribute.commodityName = ($("#" + this.pageId + "commodityListId").val());
        this.newAttribute.commodityDesc = ($("#" + this.pageId + "subCommodity").val());
        this.newAttribute.hsnCode = ($("#" + this.pageId + "hsnCode").val());
        this.newAttribute.goodsValue = ($("#" + this.pageId + "goodsValue").val());
        this.newAttribute.units = ($("#" + this.pageId + "units").val());
        this.newAttribute.noOfArticles = ($("#" + this.pageId + "noOfArticles").val());
        this.newAttribute.cgstAmt = this.cgstAmount + ' - (' + $("#" + this.pageId + "cgstPerc").val() + ')';
        this.newAttribute.sgstAmt = this.sgstAmount + ' - (' + $("#" + this.pageId + "sgstPerc").val() + ')';
        this.newAttribute.igstAmt = this.igstAmount + ' - (' + $("#" + this.pageId + "igstPerc").val() + ')';
        this.newAttribute.cessAmt = this.cessAmount + ' - (' + $("#" + this.pageId + "cessPerc").val() + ')';
        this.newAttribute.nonCessAmt = this.nonAdvolCessAmt + ' - (' + $("#" + this.pageId + "nonAdvolCessPerc").val() + ')';

        this.newAttribute.cgstPerc = $("#" + this.pageId + "cgstPerc").val();
        this.newAttribute.sgstPerc = $("#" + this.pageId + "sgstPerc").val();
        this.newAttribute.igstPerc = $("#" + this.pageId + "igstPerc").val();
        this.newAttribute.cessPerc = $("#" + this.pageId + "cessPerc").val();
        this.newAttribute.nonCessPerc = $("#" + this.pageId + "nonAdvolCessPerc").val();

        console.log(this.newAttribute);
        if (($.fn as any).DataTable.isDataTable("#" + this.pageId + "generateNewEwayBillTableId")) {
            $("#" + this.pageId + "generateNewEwayBillTableId").DataTable().destroy();
        }

        this.ewayBillLists.push({ ...this.newAttribute });

        this.calculateTotal();
        this.newAttribute = {};

        this.changeDetectorRef.detectChanges();
        this.dtTriggerGenerateNewEwayBill.next();

        $("#" + this.pageId + "commodityListId").val('');
        $("#" + this.pageId + "subCommodity").val('');
        $("#" + this.pageId + "hsnCode").val('');
        $("#" + this.pageId + "goodsValue").val('');
        $("#" + this.pageId + "units").val('');
        $("#" + this.pageId + "noOfArticles").val('');
        $("#" + this.pageId + "cgstPerc").val('');
        $("#" + this.pageId + "sgstPerc").val('');
        $("#" + this.pageId + "igstPerc").val('');
        $("#" + this.pageId + "cessPerc").val('');
        $("#" + this.pageId + "nonAdvolCessPerc").val('');
    }

    rowSelectedDelete(index: number) {
        if (index > -1 && index < this.ewayBillLists.length) {
            this.ewayBillLists.splice(index, 1);

            if (($.fn as any).DataTable.isDataTable("#" + this.pageId + "generateNewEwayBillTableId")) {
                $("#" + this.pageId + "generateNewEwayBillTableId").DataTable().destroy();
            }

            this.changeDetectorRef.detectChanges();
            this.dtTriggerGenerateNewEwayBill.next();

            this.calculateTotal();
        }
    }

    validateForGenerateEway() {
        this.selectedconsignorGSTNumber = $("#" + this.pageId + "consignorGstNumber").val();
        this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGstNumber").val();
        this.selectedTransaction = $("#" + this.pageId + "transactionType").val();
        this.enteredBillNumber = $("#" + this.pageId + "billNo").val();
        this.enteredBillDate = $("#" + this.pageId + "billDate").val();
        this.totGoodsValue = $("#" + this.pageId + "totalTaxAmt").val();
        this.enteredCgstAmt = $("#" + this.pageId + "totalCgstAmt").val();
        this.enteredSgstAmt = $("#" + this.pageId + "totalSgstAmt").val();
        this.enteredIgstAmt = $("#" + this.pageId + "totalIgstAmt").val();
        this.enteredCessAmt = $("#" + this.pageId + "totalCessAdvol").val();
        this.enteredNonAdvolCessAmt = $("#" + this.pageId + "totalNonCessAdvol").val();
        this.totalInvAmt = $("#" + this.pageId + "totalInvAmt").val();
        this.vehicleNo = $("#" + this.pageId + "vehicleNo").val();

        if (this.ewayBillLists.length == 0) {
            swal("Not Allowed", "No E-way bill details found to proceed, please check!", "warning");
        } else if (this.totalInvAmt == null || this.totalInvAmt == '' ||
            this.totGoodsValue == null || this.totGoodsValue == '' ||
            this.selectedconsignorGSTNumber == null || this.selectedconsignorGSTNumber == '' ||
            this.selectedconsigneeGSTNumber == null || this.selectedconsigneeGSTNumber == '' ||
            this.selectedTransaction == null || this.selectedTransaction == '' ||
            this.enteredBillNumber == null || this.enteredBillNumber == '' ||
            this.enteredBillDate == null || this.enteredBillDate == '' ||
            this.enteredCgstAmt == null || this.enteredCgstAmt == '' ||
            this.enteredSgstAmt == null || this.enteredSgstAmt == '' ||
            this.enteredIgstAmt == null || this.enteredIgstAmt == '' ||
            this.enteredCessAmt == null || this.enteredCessAmt == '' ||
            this.enteredNonAdvolCessAmt == null || this.enteredNonAdvolCessAmt == '' ||
            this.vehicleNo == null || this.vehicleNo == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Fill the Mandatory fields*",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
        // else if (!this.validateVehicleNumber()) {
        //     swal({
        //         title: "In-Valid Vehicle Number",
        //         text: "Please enter the correct Vehicle Number to proceed!",
        //         icon: "warning",
        //         closeOnClickOutside: false,
        //         closeOnEsc: false,
        //     });
        // } 
        else {
            swal({
                title: "Confirm",
                text: "Sure you want to Generate E Way Bill",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesBtn) => {
                if (yesBtn) {
                    this.validateEwayBillGenerate();
                } else {
                    swal.close();
                }
            });
        }
    }

    calculateTotal() {
        let totalGoodsValue = 0, totalCgst = 0, totalSgst = 0, totalIgst = 0, totalCess = 0, totalNonCess = 0, otherValue = 0;
        this.enteredOtherInvAmount = $("#" + this.pageId + "otherInvAmount").val() || 0;
        otherValue += Number(this.enteredOtherInvAmount) || 0;
        console.log(this.ewayBillLists);
        for (let i = 0; i < this.ewayBillLists.length; i++) {
            totalGoodsValue += Number(this.ewayBillLists[i].goodsValue) || 0;
            let cgstValue = this.ewayBillLists[i].cgstAmt.split('-')[0].trim();
            let sgstValue = this.ewayBillLists[i].sgstAmt.split('-')[0].trim();
            let igstValue = this.ewayBillLists[i].igstAmt.split('-')[0].trim();
            let cessValue = this.ewayBillLists[i].cessAmt.split('-')[0].trim();
            let nonCessValue = this.ewayBillLists[i].nonCessAmt.split('-')[0].trim();

            totalCgst += Number(cgstValue) || 0;
            totalSgst += Number(sgstValue) || 0;
            totalIgst += Number(igstValue) || 0;
            totalCess += Number(cessValue) || 0;
            totalNonCess += Number(nonCessValue) || 0;
        }

        console.log("Total CGST:", totalCgst);
        console.log("Total SGST:", totalSgst);
        console.log("Total IGST:", totalIgst);

        $("#" + this.pageId + "totalTaxAmt").val(totalGoodsValue);
        $("#" + this.pageId + "totalCgstAmt").val(totalCgst);
        $("#" + this.pageId + "totalSgstAmt").val(totalSgst);
        $("#" + this.pageId + "totalIgstAmt").val(totalIgst);
        $("#" + this.pageId + "totalCessAdvol").val(totalCess);
        $("#" + this.pageId + "totalNonCessAdvol").val(totalNonCess);

        let totalInvoiceAmount = totalGoodsValue + totalCgst + totalSgst + totalIgst + totalCess + totalNonCess;
        if (otherValue !== 0 && otherValue !== null && otherValue !== undefined) {
            totalInvoiceAmount += otherValue;
        }

        $("#" + this.pageId + "totalInvAmt").val(totalInvoiceAmount);
        this.changeDetectorRef.detectChanges();
    }

}