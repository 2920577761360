<div class="row" *ngIf="isLoggedIn">
	<div *ngIf="viewBarcodeEntryForm">
		<h1 class="title_custom">
			<h6 style="margin-bottom: 0px; text-align: center; font-size: 1.2rem;">LR Barcode Generation</h6>
		</h1>

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-2 border-right">
					<div class="card">
						<div class="card-body">
							<h6 class="text-center"><strong>LR's On Hold</strong></h6>
							<table datatable id="{{pageId}}barcodeTable"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">

								<thead>
									<tr>
										<th>LR Number</th>


									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let lrDetailsData of lrDetailsDataList ">
										<td (click)="setFieldsData(lrDetailsData)"><u class="hyperLink_td">{{
												lrDetailsData.lrNumber }}</u></td>

									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>

									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>

				<div class="col-md-10">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<!-- LR Number -->
								<div class="col-md-12">
									<div class="row">
										<div class="col-md-3">
											<label for="{{ pageId }}lrNumber">LR Number:</label>
										</div>
										<div class="col-md-4">
											<div class="input-group mb-3">
												<div class="input-group-prepend">
													<span class="input-group-text"><i class="icon-speech"></i></span>
												</div>
												<input type="text" [(ngModel)]="enteredLrNumber"
													id="{{ pageId }}lrNumber" class="form-control">
											</div>
										</div>
									</div>
								</div>

								<!-- E-Way Bill No. -->
								<div class="col-md-12">
									<div class="row">
										<div class="col-md-3">
											<label for="{{ pageId }}eWayBillNo">E-Way Bill No.:</label>
										</div>
										<div class="col-md-4">
											<div class="input-group mb-3">
												<div class="input-group-prepend">
													<span class="input-group-text"><i class="fas fa-road"></i></span>
												</div>
												<input type="number" class="form-control"
													[(ngModel)]="enteredEWayBillNo" (keyup)="eWayBillNoFocus($event)"
													 id="{{ pageId }}eWayBillNo"
													maxlength="12" autocomplete="off" title="Must Be 12 Numbers" />
											</div>
										</div>
									</div>
								</div>

								<!-- Pin Code -->
								<div class="col-md-12">
									<div class="row">
										<div class="col-md-3">
											<label for="{{ pageId }}pinCode">Pin Code:</label>
										</div>
										<div class="col-md-4">
											<div class="input-group mb-3">
												<div class="input-group-prepend">
													<span class="input-group-text"><i class="icon-speech"></i></span>
												</div>
												<input type="number" id="{{ pageId }}pinCode" class="form-control"
													readonly>
											</div>
										</div>
									</div>
								</div>

								<!-- Destination -->
								<div class="col-md-12">
									<div class="row">
										<div class="col-md-3">
											<label for="{{ pageId }}destination">Destination:</label>
										</div>
										<div class="col-md-4">
											<div class="input-group mb-4">
												<div class="input-group-prepend">
													<span class="input-group-text"><i class="ti-home"></i></span>
												</div>
												<input #destination id="{{ pageId }}destination" type="text"
													class="form-control"
													(selectItem)="clickListnerForDestination($event)"
													[(ngModel)]="modelDestination" [ngbTypeahead]="destnationSearchTA"
													[resultFormatter]="formatterDestination"
													[inputFormatter]="formatterDestination"
													(focus)="focusDestnationTA$.next($any($event).target.value)"
													autocomplete="off" placeholder="Select Destination.." />
											</div>
										</div>
									</div>
								</div>

								<div class="col-md-12">
									<div class="row">
										<div class="col-md-3">
											<label for="{{ pageId }}articles">Articles:</label>
										</div>
										<div class="col-md-4">
											<div class="input-group mb-3">
												<div class="input-group-prepend">
													<span class="input-group-text"><i class="icon-speech"></i></span>
												</div>
												<input type="number" id="{{ pageId }}articles" class="form-control"
													[(ngModel)]="articleCount">
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<!-- spinner start-->
									<div *ngIf="showSpinnerForAction" class="col-md-12">
										<div class="form-group">
											<div class="input-group">
												<mat-progress-bar mode="indeterminate" style="color: green;">
												</mat-progress-bar>
												<br>
												<h6 class="card-title" align='center'
													style="color: green; margin: auto; font-size: 18px;">
													Please Wait Loading Details.....</h6>
											</div>
										</div>
									</div>
									<!-- spinner end-->
								</div>

								<!-- Buttons -->
								<div class="col-md-12">
									<div class="row">
										<div class="col-md-3">
										</div>
										<div class="col-md-3">
											<button type="submit" class="btn btn-dark btn-block mb-2"
												id="{{ pageId }}searchBtn" (click)="validateGenerateBarcode()">Generate
												Barcode</button>
										</div>
										<div class="col-md-3">
											<button type="button" class="btn btn-success btn-block mb-2"
												id="{{ pageId }}clearBtn" (click)="validateClear()">Clear</button>
										</div>
									</div>
								</div>
							</div>

							<!-- Barcode Print Section -->
							<div id="printSection">
								<div *ngFor="let PS of PrintBarcode" class="barcode-page">
									<!-- Watermark -->
									<div class="watermark"></div>
							
									<!-- Barcode Number -->
									<div class="barcode-text">{{ PS.barcode }}</div>
							
									<!-- Barcode Image -->
									<ngx-barcode6 class="barcode-print" [bc-format]="'CODE128'" [bc-width]="4"
										[bc-height]="90" [bc-font-size]="30" [bc-value]="PS.barcode" [bc-display-value]="false">
									</ngx-barcode6>
							
									<!-- Details -->
									<div>
										<div  class="barcode-details">LR No. {{ PS.Name }}</div>
										<div  class="barcode-details">{{ PS.Destination }}</div>
										<div  class="barcode-details-art">art. {{ PS.ArticleIndex }}</div>
									</div>
								</div>
							</div>
							











						</div>
					</div>
				</div>

			</div>

		</div>
	</div>
	
</div>