//CHG_VER_PLCUT_01==Added data retieve after statement no serviec called for party lesss agent entry check added by imran on 21052024
//CHG_VER_PLCUT_02 : req. Abubakar Bh Need Main station grouping summary (Asrar Jr / 19-08-2024)
//CHG_VER_PLCUT_03 : Get commodity data in the dropdown if available at the source; otherwise, get data from group by source (Asrar Jr / 29-10-2024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { LrService } from 'src/app/dataService/lr-service';
import { LRDto } from 'src/app/dto/LR-dto';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';

@Component({
    selector: 'app-party-less-check-upload-type',
    templateUrl: './party-less-check-upload-type.component.html',
    styleUrls: ['./party-less-check-upload-type.component.css']
})
export class PartyLessCheckUploadTypeComponent implements OnInit {
    getDataFrmServiceFrLessLrDetailsTable: any;
    getDataFrmServiceFrBlockedLrsDetails: any;
    lessLrDetailsDataList: any;
    blockedLrsDetailsDataList: any;
    onDestroyUnsubscribtionLessLrDetails: Subscription;
    onDestroyUnsubscribtionBlockedLrsDetails: Subscription;
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLessLrDetails: Subject<any> = new Subject();
    dtTriggerBlockedLrsDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLessLrDetails: any;
    dtOptionsBlockedLrsDetails: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    showSpinnerForAction = false;
    lrDtoMainSrc: LRDto = new LRDto();
    commodityComboDataList: any;
    selectedState: any;

    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();
    validateFromDate: any;
    validateToDate: any;
    validateDestination: any;

    fortNightStmtDtoGetAgentName: FortNightStmtDto = new FortNightStmtDto();
    lrDtoForSingleUpdateInsertLRLess: LRDto = new LRDto();
    lrDtoForSingleUpdateInsertLRLessBlock: LRDto = new LRDto();
    lrDtoForUpdateInsertLRLess: LRDto[];
    lrDtoForUpdateInsertLRLessBlock: LRDto[];
    lrDtoForUpdateInsertLRLessReturn = null;
    lrDtoForIsLrLessChecked: LRDto[];
    lrDtoForIsLrLessCheckedReturn: any;
    lrDtoForRcMemoSingleLrSelected: LRDto = new LRDto();
    lrDtoForRcMemoSingleLr: LRDto[];
    lrDtoForRcMemoSingleLrChecked: Array<LRDto> = [];
    lrDtoForRcMemoCallList: LRDto[];
    lrDtoForRcMemoCallListReturn: any;
    lrDtoForRcMemoLrDetailsSelected: LRDto = new LRDto();
    lrDtoForRcMemoLrDetails: LRDto[];
    lrDtoForGridReconfRcMemoForSelected: LRDto = new LRDto();
    lrDtoForRcMemoForSelected: LRDto = new LRDto();
    lrDtoForRcMemoForSelectedList: LRDto[];
    lrDtoForPrint: LRDto = new LRDto();
    lrDtoForPrintReturn: any;
    lessLrDetailsDataForRC: any;

    hashMap: Map<String, Number> = new Map<String, Number>();

    fileUploadReturnList: any;
    fileUploadReturnListBlock: any;
    fileUploadReturnListAllowed: any;

    validateGetStmtNo: any;
    selectedDestinationForCommodity: any;
    validateSelectedStmtNo: any;
    vaidateSelectedAgentName: any;
    selectedFileUploadRate: File;

    viewCustomPrintV1 = false;
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    //Custom Print Footer
    summary1: number;
    summary2: number;
    summary3: number;
    summary4: number;
    summary5: number;
    summary6: number;

    pageId = "plcut";
    selectedCommodityName: any;
    multiSelect = false;
    fortNightStmtDtoBlockedLr: FortNightStmtDto = new FortNightStmtDto();
    enteredStmtNo: any;
    stmtNoMainSrcDetailsDataList: any;
    dtTriggerStmtNoMainSrcWise: Subject<any> = new Subject();
    dtOptionsStmtNoMainSrcWise: any;
    viewAdminFields: boolean = false;

    constructor(private router: Router,
        private mastereadService: MasterReadService,
        private lrService: LrService, public changeDetectorRef: ChangeDetectorRef, private destStmtReport: DestStmtReport) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.getDestinationDetails();
            for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

                if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                    && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Party Less Check Admin View") {
                    this.viewAdminFields = true;
                }
            }

        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    ngOnInit(): void {
        this.dtOptionsLessLrDetails = {
            // select: true,
            select: {
                style: 'multi',
                // selector: 'td:first-child'
            },
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export All</i>',
                    titleAttr: 'Export All',
                    title: function () {
                        return "Party Less Check Report   (Note:Negative(-) sign indicates excess in Agent Entered Less Amount) - " +
                            " Statement Number: " + $("#plcutstatementNumber").val() + " -  " +
                            "Agent Name : " + $("#plcutagentName").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export Difference Lrs</i>',
                    titleAttr: 'Export Difference Lrs',
                    title: function () {
                        return "Party Less Check Report   (Note:Negative(-) sign indicates excess in Agent Entered Less Amount) - " +
                            " Statement Number: " + $("#plcutstatementNumber").val() + " -  " +
                            "Agent Name : " + $("#plcutagentName").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Export Block Lrs</i>',
                    titleAttr: 'Export Block Lrs',
                    title: function () {
                        return "Party Less Check Report   (Note:Negative(-) sign indicates excess in Agent Entered Less Amount) - " +
                            " Statement Number: " + $("#plcutstatementNumber").val() + " -  " +
                            "Agent Name : " + $("#plcutagentName").val() + "";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                // 'selectAll',
                'selectNone',
                {
                    extend: 'selectAll',
                    className: 'selectall',
                    action: function (e, dt, button, config) {
                        e.preventDefault();
                        dt.rows({ search: 'applied' }).select();
                    }
                }
            ],
            // order: [[1, 'asc']],
            // columnDefs: [ {
            //     orderable: false,
            //     className: 'select-checkbox',
            //     targets:   0,
            //     defaultContent: ''
            // } ],

            // select: 'multi',`


            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 400,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            // columnDefs: [{
            //     className: 'select-checkbox',
            //     targets: 0
            // }],
            // select: {
            //     style: 'multi',
            //     selector: 'td:first-child'
            // },


            "footerCallback": function (row, data, start, end, display) {

                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var articles = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var disAmt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var entryLess = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffValue = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(3).footer()).html(articles);
                $(api.column(4).footer()).html(actWgt);
                $(api.column(5).footer()).html(chgWgt);
                $(api.column(6).footer()).html((disAmt).toFixed(0));
                $(api.column(7).footer()).html((entryLess).toFixed(0));
                $(api.column(8).footer()).html((diffValue).toFixed(0));
            },

        }


        this.dtOptionsBlockedLrsDetails = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 180,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var articles = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var disAmt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var entryLess = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffValue = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(3).footer()).html(articles);
                $(api.column(4).footer()).html(actWgt);
                $(api.column(5).footer()).html(chgWgt);
                $(api.column(6).footer()).html((disAmt).toFixed(0));
                $(api.column(7).footer()).html((entryLess).toFixed(0));
                $(api.column(8).footer()).html((diffValue).toFixed(0));
            }
        },
        //
        this.dtOptionsStmtNoMainSrcWise = {
            dom: 'Bfrtip',
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            buttons: [],
            searching: false,
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 150,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var totActLess = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totEntLess = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totLessDiff = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html((totActLess).toFixed(0));
                $(api.column(3).footer()).html((totEntLess).toFixed(0));
                $(api.column(4).footer()).html((totLessDiff).toFixed(0));
            },
        }
    }


    /*rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }*/

    ngOnDestroy(): void {
        this.dtTriggerLessLrDetails.unsubscribe();
        this.dtTriggerBlockedLrsDetails.unsubscribe();
        this.dtTriggerStmtNoMainSrcWise.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerLessLrDetails.next();
        this.dtTriggerBlockedLrsDetails.next();
        this.dtTriggerStmtNoMainSrcWise.next();
    }

    /* Drop Down */
    clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelCommodityList = e.item;
        $("#" + this.pageId + "commodityListId").val(this.modelCommodityList.commodityName);
    }

    getCommodityMasterDetailsList = () => {
        this.rateMasterDto = new RateMasterDto();
        this.selectedDestinationForCommodity = $("#" + this.pageId + "agentName").val();
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "mainConsigneeMaster";
        this.rateMasterDto.destination = this.selectedDestinationForCommodity;
        this.mastereadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                if (response) {
                    //console.log(response);
                    if (response.length == 0) {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                        this.rateMasterDtoOptions = response;
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    /* ok key press listne */

    onKeyPressListnerForStmtNo(event) {
        if (event.charCode == 13) {
            if ($("#" + this.pageId + "statementNumber").val() == null || $("#" + this.pageId + "statementNumber").val() == undefined ||
                $("#" + this.pageId + "statementNumber").val() == "") {
                swal("Mandatory Field", "Please enter Statement No", "warning");
                return false;
            } else {
                this.getStmtDetails();
            }
        }
    }
    /* ok key press listne */

    getStmtDetails() {
        this.selectedState = null;
        this.fortNightStmtDtoGetAgentName = new FortNightStmtDto();
        this.validateGetStmtNo = null;
        this.validateGetStmtNo = $("#" + this.pageId + "statementNumber").val();
        this.fortNightStmtDtoGetAgentName.stmtNo = this.validateGetStmtNo;
        this.fortNightStmtDtoGetAgentName.mode = "StmtNo";
        this.fortNightStmtDtoGetAgentName.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrService.getDestStmtDetails(this.fortNightStmtDtoGetAgentName)
            .subscribe((response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.size == 0) {
                    swal("No Result", "No Statement Found, Please check the statement number", "warning");
                    return false;
                } else if (response.size == 1) {
                    $("#" + this.pageId + "agentName").val(response.agentName);
                    this.getDestinationForLREntryFormForLessCheck(response.agentName);
                    if ($("#" + this.pageId + "agentName").val() != null && $("#" + this.pageId + "agentName").val() != undefined &&
                        $("#" + this.pageId + "agentName").val() != "") {
                        this.getCommodityMasterDetailsList();
                        //CHG_VER_PLCUT
                        this.getStmNoWiseEnteredLessLrDetailsReport();

                        //CHG_VER_PLCUT_02
                        this.getStmtNoMainSrcWiseReport();
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While update  Destination Statement Details", "info");
            },
            () => console.log("done");
    }

    /* Save Less Details start */

    saveLessDetailsBtn() {
        swal({
            title: "Confirm Update",
            text: "Sure you want to update the Less details",
            icon: "info",
            buttons: ["No", "Yes"]
        }).then((isConfirm) => {
            if (isConfirm) {
                this.setUpdateAndInsertLRLessChkDetails();
                this.getUpdateAndInsertLRLessChkBlockDetails();
                // this.setUpdateAndInsertLRLessChkBlockDetails();
            }
        });
    }

    getUpdateAndInsertLRLessChkBlockDetails() {
        this.lrDtoForUpdateInsertLRLessBlock = [];
        if (this.blockedLrsDetailsDataList != null && this.blockedLrsDetailsDataList != undefined &&
            this.blockedLrsDetailsDataList.length > 0) {
            for (let i = 0; i < this.blockedLrsDetailsDataList.length; i++) {
                this.lrDtoForSingleUpdateInsertLRLessBlock = new LRDto();
                this.validateSelectedStmtNo = $("#" + this.pageId + "statementNumber").val();
                this.vaidateSelectedAgentName = $("#" + this.pageId + "agentName").val();
                this.lrDtoForSingleUpdateInsertLRLessBlock.lrNumber = this.blockedLrsDetailsDataList[i].lrNumber;
                this.lrDtoForSingleUpdateInsertLRLessBlock.agentName = this.vaidateSelectedAgentName;
                this.lrDtoForSingleUpdateInsertLRLessBlock.fortNightStmtNo = this.validateSelectedStmtNo;
                this.lrDtoForSingleUpdateInsertLRLessBlock.discountAmount = this.blockedLrsDetailsDataList[i].enteredLessAmt;
                this.lrDtoForSingleUpdateInsertLRLessBlock.userName = this.userDataDtoReturnSession.userId;
                this.lrDtoForSingleUpdateInsertLRLessBlock.remarks = this.blockedLrsDetailsDataList[i].remarks;
                this.lrDtoForSingleUpdateInsertLRLessBlock.companyId = this.userDataDtoReturnSession.companyId;
                // for this new upload flow marked this id as 0 but need to chk this
                // more if for this reason may not arise other issues
                // @dated : 17/12/2018
                this.lrDtoForSingleUpdateInsertLRLessBlock.id = 0;
                this.lrDtoForUpdateInsertLRLessBlock.push(this.lrDtoForSingleUpdateInsertLRLessBlock);
            }
            this.setUpdateAndInsertLRLessChkBlockDetails();
        }
    }

    setUpdateAndInsertLRLessChkBlockDetails() {
        // this.getUpdateAndInsertLRLessChkBlockDetails();
        this.showSpinnerForAction = true;
        console.log(this.lrDtoForUpdateInsertLRLessBlock);
        this.lrService.insertIntoLRLessCheckBlock(this.lrDtoForUpdateInsertLRLessBlock)
            .subscribe((response) => {
                this.lrDtoForUpdateInsertLRLessReturn = null;
                this.lrDtoForUpdateInsertLRLessReturn = response;
                console.log(response);
                this.showSpinnerForAction = false;
                // if (this.lrDtoForUpdateInsertLRLessReturn.status == "success") {
                //     this.setIsLrLessCheckedDetails(this.lrDtoForUpdateInsertLRLess);
                // } else {
                //     swal("Failed", "Failed to capture the less details", "warning");
                // }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While insert capture the less Details", "info");
            },
            () => console.log("done");
    }

    getUpdateAndInsertLRLessChkDetails() {
        this.lrDtoForUpdateInsertLRLess = [];
        if (this.lessLrDetailsDataList != null && this.lessLrDetailsDataList != undefined &&
            this.lessLrDetailsDataList.length > 0) {
            for (let i = 0; i < this.lessLrDetailsDataList.length; i++) {
                this.lrDtoForSingleUpdateInsertLRLess = new LRDto();
                this.validateSelectedStmtNo = $("#" + this.pageId + "statementNumber").val();
                this.vaidateSelectedAgentName = $("#" + this.pageId + "agentName").val();
                this.lrDtoForSingleUpdateInsertLRLess.lrNumber = this.lessLrDetailsDataList[i].lrNumber;
                this.lrDtoForSingleUpdateInsertLRLess.agentName = this.vaidateSelectedAgentName;
                this.lrDtoForSingleUpdateInsertLRLess.fortNightStmtNo = this.validateSelectedStmtNo;
                this.lrDtoForSingleUpdateInsertLRLess.discountAmount = this.lessLrDetailsDataList[i].enteredLessAmt;
                this.lrDtoForSingleUpdateInsertLRLess.userName = this.userDataDtoReturnSession.userId;
                this.lrDtoForSingleUpdateInsertLRLess.remarks = this.lessLrDetailsDataList[i].remarks;
                this.lrDtoForSingleUpdateInsertLRLess.companyId = this.userDataDtoReturnSession.companyId;
                // for this new upload flow marked this id as 0 but need to chk this
                // more if for this reason may not arise other issues
                // @dated : 17/12/2018
                this.lrDtoForSingleUpdateInsertLRLess.id = 0;
                this.lrDtoForUpdateInsertLRLess.push(this.lrDtoForSingleUpdateInsertLRLess);
            }
        }
    }

    setUpdateAndInsertLRLessChkDetails() {
        this.getUpdateAndInsertLRLessChkDetails();
        this.showSpinnerForAction = true;
        console.log(this.lrDtoForUpdateInsertLRLess);
        this.lrService.insertIntoLRLessCheck(this.lrDtoForUpdateInsertLRLess)
            .subscribe((response) => {
                this.lrDtoForUpdateInsertLRLessReturn = null;
                this.lrDtoForUpdateInsertLRLessReturn = response;
                console.log(response);
                this.showSpinnerForAction = false;
                if (this.lrDtoForUpdateInsertLRLessReturn.status == "success") {
                    this.setIsLrLessCheckedDetails(this.lrDtoForUpdateInsertLRLess);
                } else {
                    swal("Failed", "Failed to capture the less details", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While insert capture the less Details", "info");
            },
            () => console.log("done");
    }

    setIsLrLessCheckedDetails(lrDtoForUpdateInsertLRLess) {
        this.lrDtoForIsLrLessChecked = [];
        this.lrDtoForIsLrLessChecked = lrDtoForUpdateInsertLRLess;
        this.showSpinnerForAction = true;
        this.lrService.setIsLrLessChecked(this.lrDtoForIsLrLessChecked)
            .subscribe((response) => {
                this.lrDtoForIsLrLessCheckedReturn = null;
                this.lrDtoForIsLrLessCheckedReturn = response;
                console.log(response);
                this.showSpinnerForAction = false;
                if (this.lrDtoForIsLrLessCheckedReturn.status == "success") {
                    swal("Success", "Less details successfully updated!", "success");
                    this.clearField();
                } else if (this.lrDtoForIsLrLessCheckedReturn.status == "nodata") {
                    swal("No Record", "No Data Found For This LR No!", "warning");
                } else {
                    swal("Failed", "Failed to update the less details", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While update the less Details", "info");
            },
            () => console.log("done");
    }

    /* Save Less Details end*/

    /* Single LR RC Memo start  & */

    selectRow(lessLrDetailsData) {
        this.lessLrDetailsDataForRC = lessLrDetailsData;
        $("#" + this.pageId + "commodityListId").val(lessLrDetailsData.subCommodity);
        this.getCommodityFromAdminRateMater(this.lessLrDetailsDataForRC);
    }

    rowSelectedRcMemoSingleLrDetailsDataOnEdit() {
        if ($("#" + this.pageId + "commodityListId").val() == null ||
            $("#" + this.pageId + "commodityListId").val() == undefined ||
            $("#" + this.pageId + "commodityListId").val() == "") {
            swal("Not Allowed", "Please select commodity to recalculate the memo", "warning");
            return false;
        } else {
            swal({
                title: "Confirm Action",
                text: "Sure you want to recalculate the discount for the selected LR?",
                icon: "info",
                buttons: ["No", "Yes"]
            }).then((isConfirm) => {
                if (isConfirm) {
                    this.rcMemoCall(this.getRcMemoLrDetailsForSingleLr(this.lessLrDetailsDataForRC));
                }
            });
        }
    }

    getRcMemoLrDetailsForSingleLr(lessLrDetailsData) {
        this.lrDtoForRcMemoSingleLrSelected = new LRDto();
        this.lrDtoForRcMemoSingleLr = [];
        this.lrDtoForRcMemoSingleLrSelected.lrNumber = lessLrDetailsData.lrNumber;
        this.lrDtoForRcMemoSingleLrSelected.source = lessLrDetailsData.source;
        //this.lrDtoForRcMemoSingleLrSelected.subCommodity = lessLrDetailsData.subCommodity;
        this.selectedCommodityName = $("#" + this.pageId + "commodityListId").val();
        this.lrDtoForRcMemoSingleLrSelected.subCommodity = this.selectedCommodityName;
        this.lrDtoForRcMemoSingleLrSelected.consigneeId = lessLrDetailsData.consigneeId;
        this.lrDtoForRcMemoSingleLrSelected.consigneeName = lessLrDetailsData.consigneeName;
        this.lrDtoForRcMemoSingleLrSelected.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoForRcMemoSingleLrSelected.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForRcMemoSingleLr.push(this.lrDtoForRcMemoSingleLrSelected);
        return this.lrDtoForRcMemoSingleLr;
    }

    rowSelectedRcMemoMultiLrDetailsDataOnEdit() {
        var table = $("#" + this.pageId + "lessLRDetailsTableId").DataTable();
        var arrayToSet = [];
        table.rows('.selected').every(function (rowIdx) {
            arrayToSet.push(table.row(rowIdx).data())
        });
        console.log(arrayToSet);
        this.lrDtoForRcMemoSingleLrChecked = arrayToSet;
        console.log("this.lrDtoForRcMemoSingleLrChecked")
        console.log(this.lrDtoForRcMemoSingleLrChecked);

        if ($("#" + this.pageId + "commodityListId").val() == null ||
            $("#" + this.pageId + "commodityListId").val() == undefined ||
            $("#" + this.pageId + "commodityListId").val() == "") {
            swal("Not Allowed", "Please select commodity to recalculate the memo!", "warning");
            return false;
        } else if (this.lrDtoForRcMemoSingleLrChecked.length == 0) {
            swal("Not Allowed", "Please select memo to recalculate!", "warning");
            return false;
        } else {
            swal({
                title: "Confirm Action",
                text: "Sure you want to recalculate the discount for the selected LRs?",
                icon: "info",
                buttons: ["No", "Yes"]
            }).then((isConfirm) => {
                if (isConfirm) {
                    this.rcMemoCall(this.getRcMemoLrDetailsForMultiLr());
                }
            });
        }
    }

    getRcMemoLrDetailsForMultiLr() {
        this.lrDtoForRcMemoLrDetailsSelected = new LRDto();
        this.lrDtoForRcMemoLrDetails = [];
        // for (let i = 0; i < this.lrDtoForRcMemoSingleLrChecked.length; i++) {
        //     this.lrDtoForRcMemoLrDetailsSelected = new LRDto();
        //     this.lrDtoForRcMemoLrDetailsSelected.lrNumber = this.lrDtoForRcMemoSingleLrChecked[i].lrNumber;
        //     this.lrDtoForRcMemoLrDetailsSelected.source = this.lrDtoForRcMemoSingleLrChecked[i].source;
        //     this.selectedCommodityName = $("#" + this.pageId + "commodityListId").val();
        //     this.lrDtoForRcMemoLrDetailsSelected.subCommodity = this.selectedCommodityName;
        //     this.lrDtoForRcMemoLrDetailsSelected.consigneeId = this.lrDtoForRcMemoSingleLrChecked[i].consigneeId;
        //     this.lrDtoForRcMemoLrDetailsSelected.consigneeName = this.lrDtoForRcMemoSingleLrChecked[i].consigneeName;
        //     this.lrDtoForRcMemoLrDetailsSelected.userName = this.userDataDtoReturnSession.userId;
        //     this.lrDtoForRcMemoLrDetailsSelected.companyId = this.userDataDtoReturnSession.companyId;
        //     this.lrDtoForRcMemoLrDetails.push(this.lrDtoForRcMemoLrDetailsSelected);
        // }

        for (let i = 0; i < this.lrDtoForRcMemoSingleLrChecked.length; i++) {
            this.lrDtoForRcMemoLrDetailsSelected = new LRDto();
            this.lrDtoForRcMemoLrDetailsSelected.lrNumber = this.lrDtoForRcMemoSingleLrChecked[i][0];
            this.lrDtoForRcMemoLrDetailsSelected.source = this.lrDtoForRcMemoSingleLrChecked[i][1];
            this.selectedCommodityName = $("#" + this.pageId + "commodityListId").val();
            this.lrDtoForRcMemoLrDetailsSelected.subCommodity = this.selectedCommodityName;
            this.lrDtoForRcMemoLrDetailsSelected.consigneeId = this.lrDtoForRcMemoSingleLrChecked[i][11];
            this.lrDtoForRcMemoLrDetailsSelected.consigneeName = this.lrDtoForRcMemoSingleLrChecked[i][2];
            this.lrDtoForRcMemoLrDetailsSelected.userName = this.userDataDtoReturnSession.userId;
            this.lrDtoForRcMemoLrDetailsSelected.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoForRcMemoLrDetails.push(this.lrDtoForRcMemoLrDetailsSelected);
        }
        console.log("this.lrDtoForRcMemoLrDetails")
        console.log(this.lrDtoForRcMemoLrDetails)
        return this.lrDtoForRcMemoLrDetails;
    }

    rowCheckBoxClicked(e, lessLrDetailsData, index) {
        // console.log("e, sourceData, index");
        // console.log(e, lessLrDetailsData, index);

        // console.log("e");
        // console.log(e);
        if (e.currentTarget.checked) {
            console.log("lessLrDetailsData.lrNumber");
            console.log(lessLrDetailsData.lrNumber);
            this.lrDtoForRcMemoSingleLrSelected = new LRDto();
            this.lrDtoForRcMemoSingleLrSelected.lrNumber = lessLrDetailsData.lrNumber;
            this.lrDtoForRcMemoSingleLrSelected.source = lessLrDetailsData.source;
            this.lrDtoForRcMemoSingleLrSelected.consigneeId = lessLrDetailsData.consigneeId;
            this.lrDtoForRcMemoSingleLrSelected.consigneeName = lessLrDetailsData.consigneeName;
            this.lrDtoForRcMemoSingleLrChecked.push(this.lrDtoForRcMemoSingleLrSelected);
            //alert('checked : ' + this.lrDtoForRcMemoSingleLrSelected.lrNumber + "-" + index);
        } else {
            let index = this.lrDtoForRcMemoSingleLrChecked.findIndex(x => x.lrNumber === lessLrDetailsData.lrNumber);
            this.lrDtoForRcMemoSingleLrChecked.splice(index, 1);

        }
        console.log('list : ' + this.lrDtoForRcMemoSingleLrChecked)
        this.changeDetectorRef.detectChanges();
    }

    bulkLRRCMemoBtn() {
        if (this.lessLrDetailsDataList == null || this.lessLrDetailsDataList == undefined ||
            this.lessLrDetailsDataList == "" || this.lessLrDetailsDataList.length == 0) {
            swal("Not Allowed", "No Lrs found to proceed", "warning");
            return false;
        } else {
            swal({
                title: "Confirm Action",
                text: "Sure you want to recalculate the discount for all the LRs? This process will take some time!",
                icon: "info",
                buttons: ["No", "Yes"]
            }).then((isConfirm) => {
                if (isConfirm) {
                    this.rcMemoCall(this.getRcMemoLrDetails(this.lessLrDetailsDataList));
                }
            });
        }
    }

    getRcMemoLrDetails(lessLrDetailsDataList) {
        this.lrDtoForRcMemoLrDetailsSelected = new LRDto();
        this.lrDtoForRcMemoLrDetails = [];
        for (let i = 0; i < lessLrDetailsDataList.length; i++) {
            this.lrDtoForRcMemoLrDetailsSelected = new LRDto();
            this.lrDtoForRcMemoLrDetailsSelected.lrNumber = lessLrDetailsDataList[i].lrNumber;
            this.lrDtoForRcMemoLrDetailsSelected.source = lessLrDetailsDataList[i].source;
            this.lrDtoForRcMemoLrDetailsSelected.subCommodity = lessLrDetailsDataList[i].subCommodity;
            this.lrDtoForRcMemoLrDetailsSelected.consigneeId = lessLrDetailsDataList[i].consigneeId;
            this.lrDtoForRcMemoLrDetailsSelected.consigneeName = lessLrDetailsDataList[i].consigneeName;
            this.lrDtoForRcMemoLrDetailsSelected.userName = this.userDataDtoReturnSession.userId;
            this.lrDtoForRcMemoLrDetailsSelected.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoForRcMemoLrDetails.push(this.lrDtoForRcMemoLrDetailsSelected);
        }
        return this.lrDtoForRcMemoLrDetails;
    }

    rcMemoCall(listOfLrDtos) {
        this.showSpinnerForAction = true;
        this.lrDtoForRcMemoCallList = [];
        this.lrDtoForRcMemoCallList = listOfLrDtos;
        console.log(listOfLrDtos);
        console.log(this.lrDtoForRcMemoCallList);
        this.lrService.recalculateBkgMemoAfterAdminRateOnDestLessChkUpload(this.lrDtoForRcMemoCallList)
            .subscribe((response) => {
                this.lrDtoForRcMemoCallListReturn = null;
                this.lrDtoForRcMemoCallListReturn = response;
                console.log(response);
                this.showSpinnerForAction = false;
                var failedSuccessMsg = this.lrDtoForRcMemoCallListReturn.status.split("-");
                console.log(failedSuccessMsg);
                if (failedSuccessMsg[0] != null &&
                    failedSuccessMsg[0] == "success") {
                    swal("Info", "Memo recalculate done!", "info");
                    this.lessLrDetailsDataForRC = null;
                    this.lrDtoForRcMemoSingleLrChecked = [];
                    $("#" + this.pageId + "commodityListId").val("");
                    this.reloadGridReconfigureAfterRCMemo();
                } else {
                    var failedMsg = this.lrDtoForRcMemoCallListReturn.status.split("-");
                    swal("Failed", "Failed to recalculate the Discount! The LR Number is " + failedMsg[1] + "", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Recalculate the Discount!", "info");
            },
            () => console.log("done");
    }

    reloadGridReconfigureAfterRCMemo() {
        this.lrDtoForGridReconfRcMemoForSelected = new LRDto();
        this.lrDtoForRcMemoForSelectedList = [];
        this.validateSelectedStmtNo = $("#" + this.pageId + "statementNumber").val();
        this.vaidateSelectedAgentName = $("#" + this.pageId + "agentName").val();
        console.log(this.lessLrDetailsDataList);
        this.lrDtoForGridReconfRcMemoForSelected.agentId = this.vaidateSelectedAgentName;
        this.lrDtoForGridReconfRcMemoForSelected.fortNightStmtNo = this.validateSelectedStmtNo;
        this.lrDtoForGridReconfRcMemoForSelected.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoForGridReconfRcMemoForSelected.companyId = this.userDataDtoReturnSession.companyId;
        var lrNos = "";
        for (let i = 0; i < this.lessLrDetailsDataList.length; i++) {
            this.lrDtoForRcMemoForSelected = new LRDto();
            if (i == 0) {
                lrNos = "'" + this.lessLrDetailsDataList[i].lrNumber + "'";
            } else {
                lrNos += ",'" + this.lessLrDetailsDataList[i].lrNumber + "'";
            }
            this.lrDtoForRcMemoForSelected.lrNumber = this.lessLrDetailsDataList[i].lrNumber;
            this.lrDtoForRcMemoForSelected.enteredLessAmt = this.lessLrDetailsDataList[i].enteredLessAmt;

            this.lrDtoForRcMemoForSelectedList.push(this.lrDtoForRcMemoForSelected);
        }

        this.lrDtoForGridReconfRcMemoForSelected.lrDtos = this.lrDtoForRcMemoForSelectedList;
        this.lrDtoForGridReconfRcMemoForSelected.lrNumber = lrNos;
        console.log(this.lrDtoForGridReconfRcMemoForSelected);
        this.showSpinnerForAction = true;
        this.lrService.getDiscountAmtMemoLrEntryDetails(this.lrDtoForGridReconfRcMemoForSelected).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.fileUploadReturnList = null;
                this.fileUploadReturnList = response;
                console.log(this.fileUploadReturnList);

                $("#" + this.pageId + "lessLRDetailsTableId").DataTable().destroy();
                this.lessLrDetailsDataList = [];
                this.fileUploadReturnListAllowed = [];

                $("#" + this.pageId + "blockLRDetailsTableId").DataTable().destroy();
                this.blockedLrsDetailsDataList = [];
                this.fileUploadReturnListBlock = [];

                if (this.fileUploadReturnList.Exceeded != null &&
                    this.fileUploadReturnList.Exceeded != undefined &&
                    this.fileUploadReturnList.Exceeded.length > 0 &&
                    this.fileUploadReturnList.Exceeded[0].status == "Exceeded") {
                    swal("Not Allowed", "Uploading file contains more than 150 row which is not allowed,"
                        + "please reduce the size to 150 and try again!", "warning");
                } else if (this.fileUploadReturnList.Failed != null &&
                    this.fileUploadReturnList.Failed != undefined &&
                    this.fileUploadReturnList.Failed.length > 0 &&
                    this.fileUploadReturnList.Failed[0].status == "Failed") {
                    swal("Failed", "Failed While Uploading file contains,please try again!", "warning");
                } else if (this.fileUploadReturnList.Allowed != null &&
                    this.fileUploadReturnList.Allowed != undefined &&
                    this.fileUploadReturnList.Allowed.length > 0 &&
                    this.fileUploadReturnList.Blocked != null &&
                    this.fileUploadReturnList.Blocked != undefined &&
                    this.fileUploadReturnList.Blocked.length > 0) {

                    this.fileUploadReturnListAllowed = this.fileUploadReturnList.Allowed;
                    this.lessLrDetailsDataList = this.fileUploadReturnListAllowed;

                    this.fileUploadReturnListBlock = this.fileUploadReturnList.Blocked;
                    this.blockedLrsDetailsDataList = this.fileUploadReturnListBlock;
                } else if (this.fileUploadReturnList.Allowed != null &&
                    this.fileUploadReturnList.Allowed != undefined &&
                    this.fileUploadReturnList.Allowed.length > 0) {

                    this.fileUploadReturnListAllowed = this.fileUploadReturnList.Allowed;
                    this.lessLrDetailsDataList = this.fileUploadReturnListAllowed;
                } else if (this.fileUploadReturnList.Blocked != null &&
                    this.fileUploadReturnList.Blocked != undefined &&
                    this.fileUploadReturnList.Blocked.length > 0) {

                    this.fileUploadReturnListBlock = this.fileUploadReturnList.Blocked;
                    this.blockedLrsDetailsDataList = this.fileUploadReturnListBlock;
                }
                this.dtTriggerBlockedLrsDetails.next();
                this.dtTriggerLessLrDetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Uploading a Pan file", "warning");
            }, () => console.log('done');
    }
    /* Single LR RC Memo end  &*/

    /* File On Upload start*/

    onFileChangedUploadRateFile(event) {
        this.selectedFileUploadRate = event.target.files[0];
        console.log(this.selectedFileUploadRate);
    }


    uploadBtn() {
        console.log($("#" + this.pageId + "uploadRateFileId").val());

        if ($("#" + this.pageId + "uploadRateFileId").val() != null && $("#" + this.pageId + "uploadRateFileId").val() != "") {
            if (this.selectedFileUploadRate.type != null) {
                // var splitTypePan = this.selectedFileInsurance.type.split("/");
                //const commonListUploadRate = "lrDestinationLessCheckUploadServlet&&documents&&ASRAR&&" + this.selectedFileUploadRate + "&&" + this.truckDataDto.truckNumber + "";
                const commonListUploadRate = $("#" + this.pageId + "statementNumber").val() + "&&" + $("#" + this.pageId + "agentName").val() + "&&" + this.userDataDtoReturnSession.userId + "&&" + this.userDataDtoReturnSession.companyId + "";
                const formData = new FormData();
                formData.append(commonListUploadRate, commonListUploadRate);
                formData.append('myfileUploadRate', this.selectedFileUploadRate, this.selectedFileUploadRate.name);
                this.showSpinnerForAction = true;
                this.lrService.readLessCheckFile(formData).subscribe(
                    (response) => {
                        this.showSpinnerForAction = false;
                        console.log(response);
                        this.fileUploadReturnList = null;
                        this.fileUploadReturnList = response;
                        console.log(this.fileUploadReturnList);

                        $("#" + this.pageId + "lessLRDetailsTableId").DataTable().destroy();
                        this.lessLrDetailsDataList = [];
                        this.fileUploadReturnListAllowed = [];

                        $("#" + this.pageId + "blockLRDetailsTableId").DataTable().destroy();
                        this.blockedLrsDetailsDataList = [];
                        this.fileUploadReturnListBlock = [];

                        if (this.fileUploadReturnList.Exceeded != null &&
                            this.fileUploadReturnList.Exceeded != undefined &&
                            this.fileUploadReturnList.Exceeded.length > 0 &&
                            this.fileUploadReturnList.Exceeded[0].status == "Exceeded") {
                            swal("Not Allowed", "Uploading file contains more than 150 row which is not allowed,"
                                + "please reduce the size to 150 and try again!", "warning");
                        } else if (this.fileUploadReturnList.Failed != null &&
                            this.fileUploadReturnList.Failed != undefined &&
                            this.fileUploadReturnList.Failed.length > 0 &&
                            this.fileUploadReturnList.Failed[0].status == "Failed") {
                            swal("Failed", "Failed While Uploading file contains,please try again!", "warning");
                        } else if (this.fileUploadReturnList.Allowed != null &&
                            this.fileUploadReturnList.Allowed != undefined &&
                            this.fileUploadReturnList.Allowed.length > 0 &&
                            this.fileUploadReturnList.Blocked != null &&
                            this.fileUploadReturnList.Blocked != undefined &&
                            this.fileUploadReturnList.Blocked.length > 0) {

                            this.fileUploadReturnListAllowed = this.fileUploadReturnList.Allowed;
                            this.lessLrDetailsDataList = this.fileUploadReturnListAllowed;

                            this.fileUploadReturnListBlock = this.fileUploadReturnList.Blocked;
                            this.blockedLrsDetailsDataList = this.fileUploadReturnListBlock;
                        } else if (this.fileUploadReturnList.Allowed != null &&
                            this.fileUploadReturnList.Allowed != undefined &&
                            this.fileUploadReturnList.Allowed.length > 0) {

                            this.fileUploadReturnListAllowed = this.fileUploadReturnList.Allowed;
                            this.lessLrDetailsDataList = this.fileUploadReturnListAllowed;
                        } else if (this.fileUploadReturnList.Blocked != null &&
                            this.fileUploadReturnList.Blocked != undefined &&
                            this.fileUploadReturnList.Blocked.length > 0) {

                            this.fileUploadReturnListBlock = this.fileUploadReturnList.Blocked;
                            this.blockedLrsDetailsDataList = this.fileUploadReturnListBlock;
                        }
                        this.dtTriggerBlockedLrsDetails.next();
                        this.dtTriggerLessLrDetails.next();
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        this.showSpinnerForAction = false;
                        swal("Error", "Server Error While Uploading a file", "warning");
                    }, () => console.log('done');
            } else {
                swal("Mandatory Field", "Only xlsx & xls file format are allowed to upload!", "warning");
            }
        }

        /* File On Upload start*/



    }
    /* EDIT */
    rowSelectedSetDetailsDataOnEdit(lessLrDetailsData) {
        // console.log(lessLrDetailsData);
        $("#" + this.pageId + "rate").val(lessLrDetailsData.rate);
        $("#" + this.pageId + "gcCharges").val(lessLrDetailsData.gcCharge);
        if (lessLrDetailsData.lcCharge) {
            $("#" + this.pageId + "lc").val('Yes');
        } else {
            $("#" + this.pageId + "lc").val('No');
        }

        if (lessLrDetailsData.hamaliCharge) {
            $("#" + this.pageId + "hamaliSrc").val('Yes');
        } else {
            $("#" + this.pageId + "hamaliSrc").val('No');
        }

        if (lessLrDetailsData.bcCharge) {
            $("#" + this.pageId + "bc").val('Yes');
        } else {
            $("#" + this.pageId + "bc").val('No');
        }
        if (lessLrDetailsData.aoccharge) {
            $("#" + this.pageId + "aoc").val('Yes');
        } else {
            $("#" + this.pageId + "aoc").val('No');
        }
        if (lessLrDetailsData.riskChargeSet) {
            $("#" + this.pageId + "fov").val('Yes');
        } else {
            $("#" + this.pageId + "fov").val('No');
        }

        if (lessLrDetailsData.ddcharge) {
            $("#" + this.pageId + "dd").val('Yes');
        } else {
            $("#" + this.pageId + "dd").val('No');
        }


        if (lessLrDetailsData.othersCharge) {
            $("#" + this.pageId + "others").val('Yes');
        } else {
            $("#" + this.pageId + "others").val('No');
        }

        $("#" + this.pageId + "sourceStation").val(lessLrDetailsData.hamaliSource);
        $("#" + this.pageId + "perUnit").val(lessLrDetailsData.ratePerUnit);
        $("#" + this.pageId + "discount").val(lessLrDetailsData.deliveryMode);

    }

    /* Edit*/

    clearBtn() {
        //this.clearField();
        var tblData = $("#" + this.pageId + "lessLRDetailsTableId").DataTable().rows('.selected').data();
        var tmpData;
        $.each(tblData, function (i, val) {
            tmpData = tblData[i][1];

        });
        // $("#" + this.pageId + "lessLRDetailsTableId").DataTable().rows(".selected").every(function () {
        //     var row = this.node();
        //     //var id = row.id;
        //     var selectedLr = this.data()[1];
        //     alert(selectedLr);
        // });
    }

    clearField() {
        $("#" + this.pageId + "rate").val('');
        $("#" + this.pageId + "gcCharges").val('');
        $("#" + this.pageId + "lc").val('');
        $("#" + this.pageId + "hamaliSrc").val('');
        $("#" + this.pageId + "bc").val('');
        $("#" + this.pageId + "aoc").val('');
        $("#" + this.pageId + "fov").val('');
        $("#" + this.pageId + "dd").val('');
        $("#" + this.pageId + "others").val('');
        $("#" + this.pageId + "sourceStation").val('');
        $("#" + this.pageId + "perUnit").val('');
        $("#" + this.pageId + "discount").val('');

        // $("#" + this.pageId + "statementNumber").val('');
        // $("#" + this.pageId + "agentName").val('');
        $("#" + this.pageId + "uploadRateFileId").val('');

        this.modelCommodityList = null;

        $("#" + this.pageId + "lessLRDetailsTableId").DataTable().destroy();
        this.lessLrDetailsDataList = [];

        $("#" + this.pageId + "blockLRDetailsTableId").DataTable().destroy();
        this.blockedLrsDetailsDataList = [];

        this.dtTriggerBlockedLrsDetails.next();
        this.dtTriggerLessLrDetails.next();

        this.fortNightStmtDtoGetAgentName = new FortNightStmtDto();
        this.lrDtoForSingleUpdateInsertLRLess = new LRDto();
        this.lrDtoForSingleUpdateInsertLRLessBlock = new LRDto();
        this.lrDtoForUpdateInsertLRLess = [];
        this.lrDtoForUpdateInsertLRLessBlock = [];
        this.lrDtoForUpdateInsertLRLessReturn = null;
        this.lrDtoForIsLrLessChecked = [];
        this.lrDtoForIsLrLessCheckedReturn = null;
        this.lrDtoForRcMemoSingleLrSelected = new LRDto();
        this.lrDtoForRcMemoSingleLr = [];
        this.lrDtoForRcMemoCallList = [];
        this.lrDtoForRcMemoCallListReturn = null;
        this.lrDtoForRcMemoLrDetailsSelected = new LRDto();
        this.lrDtoForRcMemoLrDetails = [];
        this.lrDtoForGridReconfRcMemoForSelected = new LRDto();
        this.lrDtoForRcMemoForSelected = new LRDto();
        this.lrDtoForRcMemoForSelectedList = [];

        this.fileUploadReturnList = null;
        this.fileUploadReturnListBlock = null;
        this.fileUploadReturnListAllowed = null;

        // this.validateGetStmtNo = null;
        // this.selectedDestinationForCommodity = null;
        // this.validateSelectedStmtNo = null;
        // this.vaidateSelectedAgentName = null;

        this.selectedFileUploadRate = null;
        this.lessLrDetailsDataForRC = null;
        this.lrDtoForRcMemoSingleLrChecked = [];
    }

    printAllBtn() {
        if ($("#" + this.pageId + "statementNumber").val() == null || $("#" + this.pageId + "statementNumber").val() == undefined ||
            $("#" + this.pageId + "statementNumber").val() == "") {
            swal("Alert", "Please Enter the Statement Number", "warning");
            return false;
        } else {
            this.lrDtoForPrint = new LRDto();
            this.validateSelectedStmtNo = $("#" + this.pageId + "statementNumber").val();
            this.lrDtoForPrint.reportMode = "print";
            this.lrDtoForPrint.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoForPrint.fortNightStmtNo = this.validateSelectedStmtNo;
            this.getLrLessCheckDetailsPrint();
        }
    }

    printDiifLr() {
        if ($("#" + this.pageId + "statementNumber").val() == null || $("#" + this.pageId + "statementNumber").val() == undefined ||
            $("#" + this.pageId + "statementNumber").val() == "") {
            swal("Alert", "Please Enter the Statement Number", "warning");
            return false;
        } else {
            this.lrDtoForPrint = new LRDto();
            this.validateSelectedStmtNo = $("#" + this.pageId + "statementNumber").val();
            this.lrDtoForPrint.reportMode = "diffLrs";
            this.lrDtoForPrint.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoForPrint.fortNightStmtNo = this.validateSelectedStmtNo;
            this.getLrLessCheckDetailsPrint();
        }
    }

    getLrLessCheckDetailsPrint() {
        this.showSpinnerForAction = true;
        this.lrService.getLrLessCheckDetails(this.lrDtoForPrint).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.lrDtoForPrintReturn = [];
                this.lrDtoForPrintReturn = response;
                this.setLrLessCheckDetailsPrint();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Uploading a Pan file", "warning");
            }, () => console.log('done');
    }

    setLrLessCheckDetailsPrint() {
        if (this.lrDtoForPrintReturn.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Bkg Date", "LR No", "Src",
                "Consignee", "Tot Amt", "No Of Article", "Act wt", "Chg wt",
                "F O V", "Act Less", "Ent Less", "Dif Less", "Commodity", "Unit",
                "Per Unit", "Rate", "Gc Chrg", "S.Hamali", "LC", "AOC", "DD"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [4, 10, 10, 5, 16, 8, 6, 6, 6, 6, 7, 7, 7, 11, 7, 7, 7, 7, 7, 5, 5, 5];
            //this.cashMemoDtoForCustomPrintListColumnWidths = [25, 25, 25, 25];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
            for (let i = 0; i < this.lrDtoForPrintReturn.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                var perUnit = this.lrDtoForPrintReturn.perUnit;
                if (this.lrDtoForPrintReturn.perUnit != null && this.lrDtoForPrintReturn == "Article") {
                    perUnit = "ART";
                } else if (this.lrDtoForPrintReturn.perUnit != null && this.lrDtoForPrintReturn == "Actual Weight") {
                    perUnit = "AW";
                } else {
                    perUnit = "CW";
                }
                this.cashMemoDtoForCustomPrintListColumnValues = [
                    this.lrDtoForPrintReturn[i].bookDate,
                    this.lrDtoForPrintReturn[i].lrNumber,
                    this.lrDtoForPrintReturn[i].agentName,
                    this.lrDtoForPrintReturn[i].consigneeName,
                    this.lrDtoForPrintReturn[i].totalAmount,
                    this.lrDtoForPrintReturn[i].totalArticles,
                    this.lrDtoForPrintReturn[i].actualWeight,
                    this.lrDtoForPrintReturn[i].chargedWeight,
                    this.lrDtoForPrintReturn[i].riskCharge,
                    this.lrDtoForPrintReturn[i].discountAmount,
                    this.lrDtoForPrintReturn[i].enteredLessAmt,
                    this.lrDtoForPrintReturn[i].diffValue,
                    this.lrDtoForPrintReturn[i].subCommodity,
                    this.lrDtoForPrintReturn[i].mode,
                    perUnit == null ? "NA" : perUnit,
                    this.lrDtoForPrintReturn[i].rate,
                    this.lrDtoForPrintReturn[i].gcCharge,
                    this.lrDtoForPrintReturn[i].hamaliCharge == null ? "NO" : this.lrDtoForPrintReturn[i].hamaliCharge ? "Yes" : "No",
                    this.lrDtoForPrintReturn[i].lcCharge == null ? "NO" : this.lrDtoForPrintReturn[i].lcCharge ? "Yes" : "No",
                    this.lrDtoForPrintReturn[i].AOCCharge == null ? "NO" : this.lrDtoForPrintReturn[i].AOCCharge ? "Yes" : "No",
                    this.lrDtoForPrintReturn[i].dDCharge == null ? "NO" : this.lrDtoForPrintReturn[i].dDCharge ? "Yes" : "No"
                ];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.summary1 = this.summary1 + this.lrDtoForPrintReturn[i].discountAmount;
                this.summary2 = this.summary2 + this.lrDtoForPrintReturn[i].enteredLessAmt;
                this.summary3 = this.summary3 + this.lrDtoForPrintReturn[i].diffValue;
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lrDtoForPrintReturn.length,
                    "", "", "", "", "", "", "", "",
                this.summary1, this.summary2, this.summary3,
                    "", "", "", "", "", "", "", "", ""];
            }
            //heading logics For Date
            //this.fromDatePrint = $("#"+this.pageId+"fromDates").val();
            //this.toDatePrint = $("#"+this.pageId+"toDates").val();
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Statement No:", "Agent Name"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [$("#" + this.pageId + "statementNumber").val(), $("#" + this.pageId + "agentName").val()];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Party Less Check Report   (Note:Negative(-) sign indicates excess in Agent Entered Less Amount)");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    getDestinationForLREntryFormForLessCheck(agent) {
        this.lrDtoMainSrc = new LRDto();
        this.lrDtoMainSrc.mode = "specific";
        this.lrDtoMainSrc.destination = agent;
        this.lrDtoMainSrc.companyId = this.userDataDtoReturnSession.companyId;

        this.mastereadService.getDestinationForLREntryFormForLessCheck(this.lrDtoMainSrc).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal("Not Found", "No record found for this LR Agent", "info");
                } else {
                    this.selectedState = response[0].state;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server problem occurred while getting the details", "info");
            }, () => console.log('done');
    }

    getCommodityFromAdminRateMater(data) {
        this.selectedDestinationForCommodity = $("#" + this.pageId + "agentName").val();
        this.lrDtoMainSrc = new LRDto();
        this.lrDtoMainSrc.source = data.source;
        this.lrDtoMainSrc.consigneeId = data.consigneeId;
        this.lrDtoMainSrc.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoMainSrc.mode = "partyLessCheck";
        this.lrDtoMainSrc.state = this.selectedState;
        this.lrDtoMainSrc.destination = this.selectedDestinationForCommodity;
        console.log(this.lrDtoMainSrc);

        //CHG_VER_PLCUT_03
        // this.mastereadService.getCommodityFromAdminRateMaster(this.lrDtoMainSrc).subscribe(
        this.mastereadService.getCommodityFromAdminRateMasterGroupBySource(this.lrDtoMainSrc).subscribe(
            (response) => {
                if (response) {
                    this.commodityComboDataList = null;
                    this.commodityComboDataList = response;
                    this.commodityListTA = [];
                    for (let i = 0; i < this.commodityComboDataList.length; i++) {
                        this.commodityListTA.push(this.commodityComboDataList[i]);
                    }

                    $("#" + this.pageId + "commodityCombo").val(data.subCommodity);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Problem occur while getting Commodity From Admin Rate Master",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');

    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.mastereadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    clickListnerForDestinationDateWise(e) {
        if (e.keyCode == 13) {
            this.getStatmentNumberDateWiseService();
        }
    }

    getStatmentNumberDateWise() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.validateFromDate = $("#" + this.pageId + "fromDatesDateWise").val();
        this.validateToDate = $("#" + this.pageId + "toDatesDateWise").val();
        this.validateDestination = $("#" + this.pageId + "destinationIdDateWise").val();
        this.fortNightStmtDto.fromDate = this.validateFromDate;
        this.fortNightStmtDto.toDate = this.validateToDate
        this.fortNightStmtDto.destination = this.validateDestination;
    }
    getStatmentNumberDateWiseService() {
        this.showSpinnerForAction = true;
        $("#" + this.pageId + "statementNumber").val('');
        this.getStatmentNumberDateWise();
        this.destStmtReport.getStmtNoDateWise(this.fortNightStmtDto).subscribe(
            (response) => {
                if (response.status == 'Found') {
                    $("#" + this.pageId + "statementNumber").val(response.stmtNo);
                    $("#" + this.pageId + "statementNumber").focus();
                    this.showSpinnerForAction = false;
                    this.getStmtDetails();
                } else {
                    this.showSpinnerForAction = false;
                    swal("Warning", "No statement found for the selected period!", "warning");
                    $("#" + this.pageId + "statementNumber").val('');
                    $("#" + this.pageId + "agentName").val('');
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    checkUncheckAll() {
        this.lrDtoForRcMemoSingleLrChecked = [];
        if (this.lessLrDetailsDataList.length == 0) {
            swal("Not Allowed", "No data to select!", "warning");
            return false;
        } else {
            if (this.multiSelect == true) {
                this.multiSelect = false;
                this.lrDtoForRcMemoSingleLrChecked = [];
            } else {
                this.multiSelect = true;
                this.lrDtoForRcMemoSingleLrChecked = [];
                for (let i = 0; i < this.lessLrDetailsDataList.length; i++) {
                    this.lrDtoForRcMemoSingleLrSelected = new LRDto();
                    this.lrDtoForRcMemoSingleLrSelected.lrNumber = this.lessLrDetailsDataList[i].lrNumber;
                    this.lrDtoForRcMemoSingleLrSelected.source = this.lessLrDetailsDataList[i].source;
                    this.lrDtoForRcMemoSingleLrSelected.consigneeId = this.lessLrDetailsDataList[i].consigneeId;
                    this.lrDtoForRcMemoSingleLrSelected.consigneeName = this.lessLrDetailsDataList[i].consigneeName;
                    this.lrDtoForRcMemoSingleLrChecked.push(this.lrDtoForRcMemoSingleLrSelected);
                }
            }
        }
        this.changeDetectorRef.detectChanges();
    }
    checkSelectedLrs() {
        // if (this.multiSelect == true) {
        //     this.multiSelect = false;
        // } else {
        //     this.multiSelect = true;
        // }
        var table = $("#" + this.pageId + "lessLRDetailsTableId").DataTable();
        var array = [];
        table.rows('.selected').every(function (rowIdx) {
            array.push(table.row(rowIdx).data())
        });
        console.log(array);
    }
    //CHG_VER_PLCUT
    getTotalLessRptLrDetailsStmtAllData() {
        this.fortNightStmtDto = new FortNightStmtDto();
        this.validateGetStmtNo = $("#" + this.pageId + "statementNumber").val();
        this.fortNightStmtDto.stmtNo = this.validateGetStmtNo;
        this.fortNightStmtDto.mode = "Entredlesslrs";
        this.fortNightStmtDto.reportMode = "StmtLess";
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getStmNoWiseEnteredLessLrDetailsReport() {

        this.getTotalLessRptLrDetailsStmtAllData();
        this.showSpinnerForAction = true;

        this.destStmtReport.getLessUncheckedLrDetailsV2(this.fortNightStmtDto).subscribe(
            (response) => {
                this.lessLrDetailsDataList = [];
                $("#" + this.pageId + "lessLRDetailsTableId").DataTable().destroy();

                // this.blockedLrsDetailsDataList = [];
                // $("#" + this.pageId + "blockLRDetailsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.lessLrDetailsDataList = response;
                    this.dtTriggerLessLrDetails.next();
                    this.getStmtNoBlockedLrReport();
                } else {
                    this.dtTriggerLessLrDetails.next();

                    // this.dtTriggerBlockedLrsDetails.next();
                    swal("Party Less Check", "No datas found for this information!", "info");
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            }, () => console.log('done');
    }

    getStmtBlockedLrDetails() {
        this.fortNightStmtDtoBlockedLr = new FortNightStmtDto();
        this.validateGetStmtNo = $("#" + this.pageId + "statementNumber").val();
        this.fortNightStmtDtoBlockedLr.stmtNo = this.validateGetStmtNo;
        this.fortNightStmtDtoBlockedLr.companyId = this.userDataDtoReturnSession.companyId;
    }

    getStmtNoBlockedLrReport() {
        this.getStmtBlockedLrDetails();
        this.destStmtReport.getLessCheckedBlockLrDetails(this.fortNightStmtDtoBlockedLr).subscribe(
            (response) => {
                this.blockedLrsDetailsDataList = [];
                $("#" + this.pageId + "blockLRDetailsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.blockedLrsDetailsDataList = response;
                    this.dtTriggerBlockedLrsDetails.next();
                } else {
                    this.dtTriggerBlockedLrsDetails.next();
                    swal("Party Less Check- Upload Type", "No datas found for Blocked LR!", "info");
                    this.showSpinnerForAction = false;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            }, () => console.log('done');
    }

    //CHG_VER_PLCUT_02
    getStmtMainScrWiseUserDetails() {
        this.lrDtoMainSrc = new LRDto();
        this.enteredStmtNo = $("#" + this.pageId + "statementNumber").val();
        this.lrDtoMainSrc.fortNightStmtNo = this.enteredStmtNo;
        console.log(this.lrDtoMainSrc);
    }

    getStmtNoMainSrcWiseReport() {
        this.getStmtMainScrWiseUserDetails();
        this.destStmtReport.getLrLessCheckDetailsSrcGrouped(this.lrDtoMainSrc).subscribe(
            (response) => {
                this.stmtNoMainSrcDetailsDataList = [];
                $("#" + this.pageId + "stmtNoMainSrcWiseTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.stmtNoMainSrcDetailsDataList = response;
                    this.dtTriggerStmtNoMainSrcWise.next();
                } else {
                    this.dtTriggerStmtNoMainSrcWise.next();
                    swal("Party Less Check", "No datas found for this information!", "info");
                    this.showSpinnerForAction = false;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Error occurred while getting the details!", "error");
            }, () => console.log('done');
    }
}
