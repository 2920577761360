import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MaterialModule } from "src/app/material.module";
import { EWBSearchComponent } from './ewb-search/ewb-search.component';
import { PartBUpdateomponent } from './part-b-update/part-b-update.component';
import { GenerateNewEWBComponent } from './generate-new-ewb/generate-new-ewb.component';
import { GstSearchComponent } from './gst-search/gst-search.component';
import { UpdateTransporterIdComponent } from './update-transporterId/update-transporterId.component';
import { GenerateConsolidateEWbComponent } from './generate-consolidate-ewb/generate-consolidate-ewb.component';
import { CancelEWBComponent } from './cancel-ewb/cancel-ewb.component';
import { ConsolidateEWBPrintComponent } from './consolidate-ewb-print/consolidate-ewb-print.component';

@NgModule( {
    imports: [CommonModule, 
         FormsModule,  NgbModule, DataTablesModule,MaterialModule],
    declarations: [EWBSearchComponent, PartBUpdateomponent, GenerateNewEWBComponent,
        GstSearchComponent, UpdateTransporterIdComponent, GenerateConsolidateEWbComponent, CancelEWBComponent,ConsolidateEWBPrintComponent]
})
export class EWayBillModule { }
