//comments section
//CHG-V-LR:01 - @Asrar chg, Consigor Rate flow to calculate base and other rates @Dated:31/01/2021
//CHG-V-LR:02 - @Asrar chg, CFT related changes
//CHG-V-LR:03 - @Asrar chg, Self LR block from all src to chennai @as per Musaddik bh req @31-10-2022
//CHG-V-LR:04 - @Asrar chg, no consignee copy from all src (except delhi) to chennai @as per Musaddik bh req @24-12-2022
//CHG-V-LR:05 - @Asrar chg, validate ekm&edp consignee flow @as per Musaddik bh req @23-01-2023
//CHG-V-LR:06 - @Asrar chg, no consignee copy from all src (except delhi) to all dest @as per Musaddik bh req @23-01-2023
//CHG-V-LR:07 - @Asrar chg, CFT related changes, consider cft default logic if consignee(chk from both src rate&dest rate) or consignor rate is set @27-01-2023
//CHG-V-LR:08 - @Asrar chg, remove validate bkg date for delhi @as per Musaddik bh req @06-02-2023
//CHG-V-LR:09 - @Asrar chg, Self & Bank LR block from all src to all dest @as per Musaddik bh req @20-02-2023
//CHG-V-LR:10 - @Asrar chg, print consignee copy if its enabled @as per Musaddik bh req @20-02-2023
//CHG-V-LR:11 - @Asrar chg, remove validate bkg date for bangalore booking @as per Musaddik bh req @03-03-2023
//CHG-V-LR:12 - @Asrar chg, no consignee copy from all src including delhi to all dest @as per Musaddik bh req @05-04-2023
//CHG-V-LR:13 - @Asrar chg, C.C.Attach option hide to all except Delhi @12/04/2023
//CHG-V-LR:14 - @Asrar chg, remove validate bkg date for all except Jamnagar @as per Musaddik bh req @30-05-2023
//CHG-V-LR:15 - @Asrar chg, default cft flow disabled & consider range slabs for less parties for specific destination @Musaddik bh @30/07/2023
//CHG-V-LR:16 - @Asrar chg, default cft flow disabled & consider range slabs for less parties for specific consignee/consignor @Musaddik bh @30/07/2023
//CHG-V-LR:17 - @Asrar chg, for TDM set specific fov @27/08/2023
//CHG-V-LR:18 - @Asrar chg, check consignee gst destinationconsignee table for all destination (previously it was only implemented for Edappally & Ernakulam) @16/10/2023
//CHG-V-LR:19 - @Asrar chg, validate if Eway bill part B updated for the entered ewaybill (check if eway validity available) else update it via api.
//CHG-V-LR:20 - @Asrar chg, again enabled only for edappaly & ekm to check consignee gst destinationconsignee table for all destination @16/12/2023
//CHG-V-LR:21 - @Imran chg, Added fov from consignor admin rate master on 13062024 @Parent sheet ID(13)
//CHG-V-LR:22 - @Imran chg, Blocked consignor rate default fields for andheri and purna on 15062024
//CHG-V-LR:23 - @Imran chg, Changed Base freight value as per req to take calculate base freight from commoncharges (PID-226) ON 12/07/2024
//CHG-V-LR:24 - @Asrar Jr chg, Avoid to show multiewaybill popup if (E-way Bill Valid Upto) date is empty @20-07-2024
//CHG-V-LR:25 - @Asrar Jr chg, Avoid to Save null entry on lrmultiewaybilldetails table if (E-way Bill No & E-way Bill Valid Upto) is Null @20-07-2024
//CHG-V-LR:26 - @Asrar Jr chg, Save Main Station for Consignor Details @25-07-2024
//CHG-V-LR:27 - @Asrar Jr chg, Comments Add New option for both Consignor & Consignee as per discuss @29-07-2024
//CHG-V-LR:28 - @Asrar Jr chg, EWB Api return data if toPincode not matched on Agentsubstationsmaster table show message @29-07-2024
//CHG-V-LR:29 - @Asrar Jr chg, Save Consignor & Consignee Details through GST Api response @01-08-2024
//Chg_V-Lr:30 @Imran Chg, Blocking discount calculation if the size exceed on 01/08/2024
//Chg_V-Lr:31 @Imran Chg, Taking rate from consignee rate for some branch ifsettet true in godown table 08/08/2024
//Chg_V-Lr:32 @Imran Chg, If perkglimit is setted and the value is greater updating perkgboolean column 08/08/2024
//Chg_V-Lr:33 @Imran Chg, Added Perkglimit method if commodity size limit not exits by imran on 20082024
//CHG-V-LR:34 - @Asrar Jr chg, Show Consignee details on select destination as per discuss @21-08-2024
//CHG-V-LR:35 - @Asrar Jr chg, Entered Pincode add new on Consignee details table if haven't GST No and avoid to save GST Api portal toPincode @22-08-2024
//CHG-V-LR:36 - @Asrar Jr Chg, Add parameter on existing services for open Aadhar OTP popup as per discuss (29/08/2024)
//CHG-V-LR:37 - @Asrar Jr Chg, Remove space on enter aadhar no for get details (29/08/2024)
//CHG-V-LR:38 - @Asrar Jr Chg, Validation GST No (30/08/2024)
//CHG-V-LR:39 - @Asrar Jr Chg, Validation on search based on selected type (30/08/2024)
//CHG-V-LR:40 - @Asrar Jr Chg, Avoid to add multiple entry on lrmultiewaybilldetails table (02/09/2024)
//CHG-V-LR:41 - @Imran Chg, Added for blocking commodity if blocked by admin (04/09/2024)
//CHG-V-LR:42 - @Asrar Jr Chg, As per discuss avoid add new from E-Way bill No so this existing code will comments (05/09/2024)
//CHG-V-LR:43 - @Asrar Jr Chg, Remove to Add data on Pan and Aadhar Api response data and show Add New option popup show only for Aadhar if aadhar number not in database as per discuss (06/09/2024)
//CHG-V-LR:44 - @Asrar Jr Chg, Added for blocking commodity if blocked by admin for Actual Commodity drop down selection & enter(07/09/2024)
//CHG-V-LR:45 - @Asrar Jr Chg, Enable Pan Card option to save party details from API Service as per discuss on (13/09/2024)
//CHG-V-LR:46 - @Asrar Jr Chg, As per discuss @Abubakkar Bhai : Enable for all destination (13/09/2024)
//CHG-V-LR:47 - @Asrar Jr Chg, Save aadhar details from Add New option add type to show on lrPrint (17/09/2024)
//CHG-V-LR:48 - @Asrar Jr Chg, Check valid E-Way bill no on save lrEntry details as per discuss (19/09/2024)
//CHG-V-LR:49 - @Asrar Jr Chg, Change swal message for service return list not a single as per discuss (20/09/2024)
//CHG-V-LR:50 - @Asrar Jr Chg, Call PAN Api service on Add New option return data show on popup user can modify details as per discuss on (24/09/2024)
//CHG-V-LR:51 - @Asrar Jr Chg, Change param for migrate WhatsApp_Kaleyra to WhatsApp_Verloop as per discuss (22/10/2024)
//CHG-V-LR:52 - @Imran, Added Barcode print after taking lr entry prints (Imran On 29102024)

import { Component, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ViewChild } from "@angular/core";
const my = new Date();
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from 'src/app/dto/LR-dto';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { MatDialog } from '@angular/material/dialog';
import { OnInit } from '@angular/core';
import { Subject, Subscription, merge } from 'rxjs'
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { AutomatedLrHoldLrEntryPopupComponent } from "src/app/lr/entry/automatedlrhold-lr-entry-popup/automatedlrhold-lr-entry-popup.component";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { MasterService } from 'src/app/dataService/master-service';
import { BookingCashmemoPopupComponent } from "src/app/lr/entry/booking-cashmemo-popup/booking-cashmemo-popup.component";
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { ResponseDto } from 'src/app/dto/Response-dto';
import { RequestDto } from 'src/app/dto/Request-dto';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';
import { LrService } from 'src/app/dataService/lr-service';

export interface DialogData {
  listOfDataToPasstoLrEntry: string[];
  listOfDataToPassLrEntryRet: any;
}

@Component({
  selector: 'app-lr-entry-form',
  templateUrl: './lrentryform.component.html',
  styleUrls: ['./lrentryform.component.css'],
})
export class LrEntryFormComponent implements OnInit {
  arrivalDatesField: any;

  //  focus starts
  @ViewChild("vehicleNumber") vehicleNumberField: ElementRef;
  @ViewChild("challanNo") challanNoField: ElementRef;
  @ViewChild("challanTotLrs") challanTotLrsField: ElementRef;
  @ViewChild("agentName") agentNameField: ElementRef;
  @ViewChild("arrivalDate") arrivalDateField: ElementRef;
  @ViewChild("commodityDescription") commodityDescriptionField: ElementRef;
  @ViewChild("partyGoodsType") partyGoodsTypeField: ElementRef;
  @ViewChild("subCommodity") subCommodityField: ElementRef;
  @ViewChild("ddAmt") ddAmtField: ElementRef;
  @ViewChild("ddAddress") ddAddressField: ElementRef;
  @ViewChild("paymentType") paymentTypeField: ElementRef;
  @ViewChild("automatedLR") automatedLRField: ElementRef;
  @ViewChild("hamali") hamaliField: ElementRef;
  @ViewChild("lcCharge") lcChargeField: ElementRef;
  @ViewChild("gcCharge") gcChargeField: ElementRef;
  @ViewChild("bcCharge") bcChargeField: ElementRef;
  @ViewChild("fov") fovField: ElementRef;
  @ViewChild("aocCharge") aocChargeField: ElementRef;
  @ViewChild("others") othersField: ElementRef;
  @ViewChild("privateMarker") privateMarkerField: ElementRef;
  @ViewChild("bankName") bankNameField: ElementRef;
  @ViewChild("bankLr") bankLrField: ElementRef;
  @ViewChild("doorDeliveryMode") doorDeliveryModeField: ElementRef;
  @ViewChild("baseFreight") baseFreightField: ElementRef;
  @ViewChild("actualWeight") actualWeightField: ElementRef;
  @ViewChild("chargedWeight") chargedWeightField: ElementRef;
  @ViewChild("goodsValue") goodsValueField: ElementRef;
  @ViewChild("lrNumber") lrNumberField: ElementRef;
  @ViewChild("multiArtarticle") multiArtarticleField: ElementRef;
  @ViewChild("noOfArticles") noOfArticlesField: ElementRef;
  @ViewChild("autoLrNumber") autoLrNumberField: ElementRef;
  @ViewChild("nextBtn") nextBtnField: ElementRef;
  @ViewChild("packNature") packNatureField: ElementRef;
  @ViewChild("destination") destinationField: ElementRef;
  @ViewChild("consignorGSTNumber") consignorGSTNumberField: ElementRef;
  @ViewChild("consignorName") consignorNameField: ElementRef;
  @ViewChild("consigneeGSTNumber") consigneeGSTNumberField: ElementRef;
  @ViewChild("consigneeName") consigneeNameField: ElementRef;
  @ViewChild("goodsType") goodsTypeField: ElementRef;
  @ViewChild("newCommodity") newCommodityField: ElementRef;
  @ViewChild("eWayBillNo") eWayBillNoField: ElementRef;
  @ViewChild("edeclarationCommodity") edeclarationCommodityField: ElementRef;
  @ViewChild("consignorMobileNumber") consignorMobileNumberField: ElementRef;
  @ViewChild("consigneeMobileNumber") consigneeMobileNumberField: ElementRef;
  @ViewChild("newConsignor") newConsignorField: ElementRef;
  @ViewChild("newConsignee") newConsigneeField: ElementRef;
  @ViewChild("localChallanKanta") localChallanKantaField: ElementRef;
  @ViewChild("eInvNoConsignor") eInvNoConsignorField: ElementRef;
  hireDto: HireSlipDto = new HireSlipDto();
  loaderHeadOptions: HireSlipDto[];
  hireDtopackNature: HireSlipDto = new HireSlipDto();
  packNatureDetails: any;
  packNatureOptions: LRDto[];
  packNatureOptionsAdd: LRDto = new LRDto();
  agentDetailsOptionsGet: AgentDetailsDto[];
  agentDetailsOptionsSet: any;
  agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
  showMultiArt = false;
  pageId = "lrefc";
  ddAmtRateChg: any;

  //    commodityRateDto: RateMasterDto = new RateMasterDto();
  //    commodityRate: any;
  //    commodityRateRet: RateMasterDto[];
  //    commodityDescriptionOptions: RateMasterDto[];
  //    commodityRateDtoAdd: RateMasterDto = new RateMasterDto();
  lblAddAgent = false;
  selectedAgentName: any;
  selectedconsignorGSTNumber: any;
  selectedconsignorMobileNumber: any;
  selectedconsigneeMobileNumber: any;
  partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
  consignorOptions: any;
  consignorOptionsRet: PartyMasterDto[];
  consignorOptionsAdd: PartyMasterDto = new PartyMasterDto();
  selectedConsignorName: any;

  selectedconsigneeGSTNumber: any;
  partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
  consigneeOptions: any;
  consigneeOptionsRet: PartyMasterDto[];
  consigneeOptionsAdd: PartyMasterDto = new PartyMasterDto();
  selectedConsigneeName: any;
  showGoodsType = false;
  isReadOnly = true;

  selectedDestination: any;

  destinationDetails: any;
  destinationDetailsdd: any;
  destinationOptions: Array<any> = [];

  lrDtoDest: LRDto = new LRDto();
  destinationDetailsGet: LRDto[];
  destinationOptionsAny: any;
  lrDtoSubstation: LRDto = new LRDto();
  showCont1: any;
  showAutoLr: any;

  commaSplitDest: Array<any> = [];
  destSplit: any;
  stateByDest: any;

  showMainCommodity = false;
  selectedConsignee: any;
  selectedConsigneeId: any;
  selectedConsignorId: any;
  selectedConsigneeAddress: any;
  bangaloreConsigneeCommdityOptions: any;
  allCommdityStateOptions: any;
  consigneeComOptions: PartyMasterDto = new PartyMasterDto();

  partyDtoForConsignee: PartyMasterDto = new PartyMasterDto();
  partyDtoForConsigneeAllCom: PartyMasterDto = new PartyMasterDto();
  statusOfCommodity: string;

  consigneeAddressPartyDto: PartyMasterDto = new PartyMasterDto();
  consigneeAddressRet: any;
  goodsTypeCommdityOptions: any;
  selectedPartyCommodity: any;
  selectedCommonCommodity: any;

  goodsCommdityRateDto: RateMasterDto = new RateMasterDto();

  goodsCommdityRateDtoAdd: PartyMasterDto = new PartyMasterDto();

  selectedGoodsTypeCommodity: any;
  showNewCommodity = false;
  selectedNewCommodity: any;

  lrtDtoForAutoLr: LRDto = new LRDto();
  autoLrRet: any;
  userName: any;
  viewAutoLr = true
  viewAutoLrHold = true;
  viewByAutoLr = false;
  viewValidateBkgDate = false;
  ddAmtRange = 0.0;
  ddAmountRange = 0.0;
  enterdChgWgt = 0.0;
  ddAmtSys = 0;
  enteredChargeWeight: any;
  lrtDtoForFovRate: LRDto = new LRDto();
  resultDtos: LRDto = new LRDto();
  resultDtosRet: any;
  lrCheck = false;


  isFOVRateSet = false;
  fovChg = 0.0;
  fovRateValidateRet: any;

  enteredGoodsValue: any;
  goodsValue = 0.0;
  riskPerc = 0.0;
  riskChg = 0.0;
  totalAmt = 0.0;
  baseFreight: any;
  hamali: any;
  gcCharge: any;
  lcCharge: any;
  bcCharge: any;
  aocCharge: any;
  insuranceCharge: any;
  others: any;
  ddAmt: any;
  enteredRiskChg: any;
  paymentType: any;

  enteredTotalAmt: any;
  enteredChargedWeight: any;
  ratePerChgWgt: any;
  actualWeight: any;
  chargedWeight: any;
  lrtDtoForChallan: LRDto = new LRDto();
  lrtDtoForChallanRet: any;
  enteredlrNumber: any;

  dtTriggerMultiArtTable: Subject<any> = new Subject();
  dataTable: any;
  dtOptionsMultiArtTable: any;
  multiArtTableDataList: Array<any> = [];
  editTableId: null;
  lrtDtoForMultiArt: LRDto = new LRDto();
  lrtDtoForMultiArtRet: any;
  lrtDtoForMultiArtRetSet: LRDto = new LRDto();

  isBlock: boolean;
  enterArticles = 0;
  totArticles: any;
  multiArtArticle: any;
  getArticles: any;
  getGCCharges = 0.0;
  getOthers = 0.0;
  setSource: any;
  setGcCharge: any;
  setOthers: any;
  showLrCodeInputFields: any;
  lrtDtoForLrCheck: LRDto = new LRDto();
  lrtDtoForLrCheckRet: any;
  lrtDtoForLrCheckSet: LRDto = new LRDto();
  lrtDtoForMissAgentRet: any;
  lrtDtoForMissAgentSet: LRDto = new LRDto();

  validateBkgDateClicked = true;
  enteredBookingDate: any;
  enteredSource: any;
  enterdActWgt = 0.0;
  enteredActualWeight: any;

  lrtDtoForValidateBkgDate: LRDto = new LRDto();
  lrtDtoForValidateBkgDateRet: any;
  lrtDtoForValidateBkgDateSet: LRDto = new LRDto();
  bookingDateGet: any;
  getIsLrCode: any;
  getLrCode: any;
  eWayBillExempted = false;
  enteredEWayBillNo: any;
  selectedDoorDelivery: any;
  minDDAmt = 0.0;
  minDDPerc = 0.0;
  partyGoodsType = null;
  goodsType = null
  newCommodity = null;
  toBeBilledParty = false;
  cbAoc = false;
  cbPickUpLr = false;
  cbUnloadByConsignee = false;
  cbHandleWithCare = false;
  cbCOD = false;
  cbOrderGoods = false;
  cbGatePass = false;
  cbCCAttached = false;
  cbSelfLr = false;

  lrtDtoForSave: LRDto = new LRDto();
  enteredAutoLrNumber: any;
  showSubCommodity = true;
  enteredSubCommodity: any;
  enteredBillNumber: any;
  enteredClerkName: any;
  enteredBillDate: any;
  enteredPrivateMarker: any;
  totalAmtNew = 0.0;
  isPaidLr = false;
  enteredDDAddress: any;
  selectedPackNature: any;
  selectedSerTaxPayingBy: any;
  selectedBankLr: any;
  enteredChargedWeightLR: any;
  showEDeclarationId = false;
  selectedEDeclarationId: any;
  enteredEDeclarationCommodity: any;
  enteredBankName: any;
  selectedUserGodown: any;
  enteredRemarks: any;
  lrHold: any;
  lrDtoSaveRet: any;
  lrDtoSaveSet: LRDto = new LRDto();
  lrCount = 0;
  lblLRCount = false;
  txtLrCount: any;

  viewLrEntryPrint = false;
  lrEntryForm = true;
  viewLrEntryBkgCashmemoPrintAutoGStPopup = false;
  remarksTexArea: any;
  ddAddressTexArea: any;
  lblNewConsignor = false;
  lblNewConsignee = false;
  enteredChallanTotLrs: any;
  lrtDtoForChallanTotLr: LRDto = new LRDto();
  lrtDtoForChallanTotLrRet: any;
  lrtDtoForChallanTotLrRetDto: LRDto = new LRDto();

  dtOptionsMultiEway: any;
  dtTriggerMultEway: Subject<any> = new Subject();
  multiEwayTableDataList: Array<any> = [];

  dtOptionsLrArtScan: any;
  dtTriggerLrArtScan: Subject<any> = new Subject();
  lrArtScanTableDataList: Array<any> = [];
  lrArtScanAllow = false;
  lrScanAllow = false;
  enteredlrArtScanCode: any;
  private newAttributeLrArtScan: any = {};
  private newAttributeLrArtScanToPush: any = {};
  saveBarcodeValueLrDto: LRDto = new LRDto();
  saveBarcodeValueLrDtoList: Array<LRDto> = [];

  agentNameTA: Array<any> = [];
  userGodownTA: Array<any> = [];
  @ViewChild('instanceUserGodown') instanceUserGodown: NgbTypeahead;
  @ViewChild('instanceAgentName') instanceAgentName: NgbTypeahead;
  focusUserGodownTA$ = new Subject<string>();
  clickUserGodownTA$ = new Subject<string>();
  focusAgentNameTA$ = new Subject<string>();
  clickAgentNameTA$ = new Subject<string>();
  showLoader = true;
  userGodownSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickUserGodownTA$.pipe(filter(() => !this.instanceUserGodown.isPopupOpen()));
    const inputFocus$ = this.focusUserGodownTA$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.userGodownTA
        : this.userGodownTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 100))
    );
  }

  agentNameSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickAgentNameTA$.pipe(filter(() => !this.instanceAgentName.isPopupOpen()));
    const inputFocus$ = this.focusAgentNameTA$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.agentNameTA
        : this.agentNameTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  @ViewChild('instanceVehicleNumber') instanceVehicleNumber: NgbTypeahead;
  vehicleNumberTA: Array<any> = [];
  focusVehicleNumberTA$ = new Subject<string>();
  vehicleNumberSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusVehicleNumberTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.vehicleNumberTA
        : this.vehicleNumberTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  @ViewChild('instanceDestnation') instanceDestnation: NgbTypeahead;
  destinationTA: Array<any> = [];
  focusDestnationTA$ = new Subject<string>();
  destnationSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusDestnationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.destinationTA
        : this.destinationTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  //substation
  substationOptions: Array<any> = [];
  @ViewChild('instanceSubstation') instanceSubstation: NgbTypeahead;
  substationTA: Array<any> = [];
  focusSubstationTA$ = new Subject<string>();
  substationSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusSubstationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.substationTA
        : this.substationTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  @ViewChild('instanceLoaderHead') instanceLoaderHead: NgbTypeahead;
  loaderHeadTA: Array<any> = [];
  focusLoaderHeadTA$ = new Subject<string>();
  loaderHeadSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusLoaderHeadTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.loaderHeadTA
        : this.loaderHeadTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  @ViewChild('instancePackNature') instancePackNature: NgbTypeahead;
  packNatureTA: Array<any> = [];
  focusPackNatureTA$ = new Subject<string>();
  packNatureSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusPackNatureTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.packNatureTA
        : this.packNatureTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  @ViewChild('instancePackNaturePopUp') instancePackNaturePopUp: NgbTypeahead;
  packNaturePopupTA: Array<any> = [];
  focusPackNaturePopupTA$ = new Subject<string>();
  packNatureSearchPopupTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusPackNaturePopupTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.packNaturePopupTA
        : this.packNaturePopupTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  //consignorOptionsRetTA = this.consignorOptionsRet;

  //    formatter = ( consignorNameFilter: consignorOptionsRetTA ) => this.consignorOptionsAdd.consignorName;

  @ViewChild('instanceConsignor') instanceConsignor: NgbTypeahead;
  public modelConsignorName: any;
  consignorTA: Array<any> = [];
  focusConsignorTA$ = new Subject<string>();
  consignorSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsignorTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consignorTA
        : this.consignorTA.filter(v => v.newConsigneeCity.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsignorName = (x: { newConsigneeCity: string }) => x.newConsigneeCity;

  @ViewChild('instanceConsigneeName') instanceConsigneeName: NgbTypeahead;
  consigneeTA: Array<any> = [];
  focusConsigneeNameTA$ = new Subject<string>();
  consigneeNameSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusConsigneeNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.consigneeTA
        : this.consigneeTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

  @ViewChild('instancePartyCommodity') instancePartyCommodity: NgbTypeahead;
  partyCommodityTA: Array<any> = [];
  focusPartyCommodityTA$ = new Subject<string>();
  partyCommoditySearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusPartyCommodityTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.partyCommodityTA
        : this.partyCommodityTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  @ViewChild('instanceMainCommodity') instanceMainCommodity: NgbTypeahead;
  mainCommodityTA: Array<any> = [];
  focusMainCommodityTA$ = new Subject<string>();
  mainCommoditySearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusMainCommodityTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.mainCommodityTA
        : this.mainCommodityTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  @ViewChild('instanceGoodsType') instanceGoodsType: NgbTypeahead;
  goodsTypeTA: Array<any> = [];
  focusGoodsTypeTA$ = new Subject<string>();
  goodsTypeSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusGoodsTypeTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.goodsTypeTA
        : this.goodsTypeTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }


  fieldFocus(e, el) {
    if (e.keyCode == 13) { // press A
      el.focus();
    }
  }
  agentNames(): void {
    this.vehicleNumberField.nativeElement.focus();
  }
  focusCalendar(): void {
    this.challanNoField.nativeElement.focus();
  }

  /* packNatures(): void {
      this.selectedPackNature = $( "#packNature" ).val();
      if ( ( this.selectedPackNature != null ) && ( this.selectedPackNature != '' ) && ( this.selectedPackNature == 'Multiple Pack' ) ) {
          this.showMultiPackNature = true;
      } else {
          this.showMultiPackNature = false;
          this.consignorGSTNumberField.nativeElement.focus();
      }

  } */

  //By Kamil

  packNatures(e: NgbTypeaheadSelectItemEvent, content8) {
    console.log('fff');
    //        this.selectedPackNature = $( "#packNature" ).val();
    this.selectedPackNature = e.item;
    console.log(this.selectedPackNature);
    if ((this.selectedPackNature != null) && (this.selectedPackNature != '') && (this.selectedPackNature == 'Multiple Pack')) {
      //            this.showMultiPackNature = true;
      this.modalService.open(content8);
      window.setTimeout(function () {
        $("#lrefcmultiPackNatureNew").focus();
      }, 100);
    } else {
      //this.showMultiPackNature = false;
      window.setTimeout(function () {
        $("#lrefcconsignorGSTNumber").focus();
      }, 100);

    }

  }

  /* fieldFocusPackNature( e ) {
      if ( e.keyCode == 13 ) {
          this.selectedPackNature = $( "#packNature" ).val();
          if ( ( this.selectedPackNature != null ) && ( this.selectedPackNature != '' ) && ( this.selectedPackNature == 'Multiple Pack' ) ) {
              this.showMultiPackNature = true;
          } else {
              this.showMultiPackNature = false;
              this.consignorGSTNumberField.nativeElement.focus();
          }
      }

  } */
  //By Kamil 
  fieldFocusPackNature(e, content8) {
    if (e.keyCode == 13) {
      this.selectedPackNature = $("#" + this.pageId + "packNature").val();
      console.log(this.selectedPackNature);
      if ((this.selectedPackNature != null) && (this.selectedPackNature != '') && (this.selectedPackNature == 'Multiple Pack')) {
        this.modalService.open(content8);
        window.setTimeout(function () {
          $("#lrefcmultiPackNatureNew").focus();
        }, 100);

      } else {

        window.setTimeout(function () {
          $("#lrefcconsignorGSTNumber").focus();
        }, 100);
      }
    }

  }


  dd(): void {
    this.ddAmtField.nativeElement.focus();
  }
  loaderFocus() {
    this.destinationField.nativeElement.focus();
  }
  /* showMainComm() {
      if ( ( this.viewByAutoLr == true ) || ( this.lrCheck == true ) ) {
          this.autoLrNumberField.nativeElement.focus();
      } else {
          this.showMainCommodity = true;
          this.lrNumberField.nativeElement.focus();
      }

  } */

  /* viewMainCommodity( e ) {
      if ( e.keyCode == 13 ) {
          this.selectedDestination = $( "#destination" ).val();
          if ( this.selectedDestination != null && this.selectedDestination != '' ) {
              this.showMainCommodity = true;
              if ( ( this.viewByAutoLr == true ) || ( this.lrCheck == true ) ) {
                  this.autoLrNumberField.nativeElement.focus();
              } else {
                  this.lrNumberField.nativeElement.focus();
              }

          }

      }

  } */


  //    focusCompanyName(): void {
  //        this.companyNameField.nativeElement.focus();
  //    }

  //    focus ends

  //for the select option with filter starts

  controlAgentName = new FormControl();
  controlLoaderHead = new FormControl();
  controlPackNature = new FormControl();
  controlCommodityDescription = new FormControl();
  controlPartyGoodsType = new FormControl();
  controlUserGodown = new FormControl();
  controlConsignor = new FormControl();
  controlConsignee = new FormControl();
  controlDestination = new FormControl();
  controlGoodsType = new FormControl();



  //for the select option with filter ends

  // @ViewChild(NgbdDatepicker) d: NgbdDatepicker;
  model: NgbDateStruct;
  model2;
  // date: { year: number; month: number };
  doorDeliveryYes = false;
  paymentTypePaid = false;
  doorDeliveryYesddAmt = false;
  bankLrYes = false;
  hoveredDate: NgbDateStruct;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  lrDtoParam: LRDto = new LRDto();
  lrDtosUserGodownOptions: LRDto[];
  agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
  defaultGodownName: string;
  closeResult: string;
  userDataDtoReturnSession: any;
  isLoggedIn = true;
  enteredChallanNo: any;
  enteredChallanNoAt0: any;
  mainAdminStationGet: any;
  challanAgentName: string;
  maxActWgt: any;
  maxChgWgt: any;
  private newAttribute: any = {};
  subCommodityName: any;
  editSelected = false;
  isLRCode = false;
  showLrCode = false;
  isNextLrAllow = true;
  enteredArrivalDate: any;
  enteredVehicleNumber: any;
  enteredDriverName: any;
  selectedLoaderHead: any;
  getOffice: any;
  restrictUserGodownList = false;
  ///For Seting the Auto Lr Value
  autoLrFieldValue = '';
  ////
  showMultiPackNature = false;
  packNatureNewOptions: LRDto[];
  controlPackNatureNew = new FormControl();
  editTableIdMultiPack = null;

  dtTriggerMultiPackTable: Subject<any> = new Subject();
  dtOptionsMultiPackTable: any;
  multiPackNatureTableDataList: Array<any> = [];
  private newAttributeMultiPack: any = {};
  partyMasterDto: PartyMasterDto = new PartyMasterDto();
  stateDetailsList: any;
  consigneeStateOptions: PartyMasterDto[];
  consignorStateOptions: PartyMasterDto[];
  controlConsignorState = new FormControl();
  controlConsigneeState = new FormControl();
  ///pop up  of consingor and consignee
  selectedNewConsignorName: any;
  enteredNewConsignorGSTNo: any;
  enteredNewConsignorAddress: any;
  enteredNewConsignorState: any;
  enteredNewConsignorMobileNo: any;
  enteredNewConsignorOfficeNo: any;
  newConsignorStateCode = '';
  newConsigneeStateCode = '';
  partyDtoForNewConsignor: PartyMasterDto = new PartyMasterDto();
  partyDtoForNewConsignorRet: any;
  partyDtoForNewConsignorSet: PartyMasterDto = new PartyMasterDto();
  //  Consignee
  selectedNewConsigneeName: any;
  enteredNewConsigneeGSTNo: any;
  enteredNewConsigneeAddress: any;
  enteredNewConsigneeState: any;

  partyDtoForNewConsignee: PartyMasterDto = new PartyMasterDto();
  partyDtoForNewConsigneeRet: any;
  partyDtoForNewConsigneeSet: PartyMasterDto = new PartyMasterDto();
  setConsignorAddress: any;
  setConsigneeAddress: any;
  viewBkgCashmemo = false;
  routeQueryParams$: Subscription;
  lrDtoForBooking: LRDto = new LRDto();
  tdyArrivalDate: any;
  tdyBookingDate: any;
  tdyBillDate: any;
  checkAutoLr = false;
  checkAutoLrHold = false;
  showSpinner = false;
  showCodChg = false;
  isPaidLrOnly = false;
  showRiskCharge = false;
  hideRiskCharge = 'Hide Risk Charge';
  entereCODCharge: any;
  setCodCharge: any;
  truckDataDto: TruckDataDto = new TruckDataDto();
  vehicleNumberOptions: TruckDataDto[];
  agentTempoMasterDataList: any;
  vehicleNumberOptionsAddNew: TruckDataDto = new TruckDataDto();
  truckDataDtoSave: TruckDataDto = new TruckDataDto();
  truckDataCheckDto: TruckDataDto = new TruckDataDto();
  truckDataDtoCheckRet: any;
  truckDataDtoSaveRet: any;
  enteredTruckNumber: any;
  enteredTruckCapacity: any;
  enteredOwnerName: any;
  enteredPanNO: any;
  selectedTruckNumber: any;
  public modelVehicleNumber: any;
  isValid = false;
  enteredLocalChallanKanta: any;
  enteredTruckNumberFroValidation: any;
  isPaidLrForManual = false;
  autoLrLoaderHead: any;
  readonlyFOV = false;
  public modelConsigneeName: any;
  tdyBayUnloadingDate: any;
  totActWgtMultiArt = 0;
  totChgWgtMultiArt = 0;
  totArtMultiArt = 0;

  bayNumberOptions = [
    { id: 1, label: 'Bay 1' },
    { id: 2, label: 'Bay 2' },
    { id: 3, label: 'Bay 3' },
    { id: 4, label: 'Bay 4' },
    { id: 5, label: 'Bay 5' },
    { id: 6, label: 'Bay 6' },
    { id: 7, label: 'Bay 7' },
    { id: 8, label: 'Bay 8' },
    { id: 9, label: 'Bay 9' },
    { id: 10, label: 'Bay 10' },
    { id: 11, label: 'Bay 11' },
    { id: 12, label: 'Bay 12' }
  ];

  //For Bay Number
  public modelBayNumber: any;
  @ViewChild('instanceBayNumber') instanceBayNumber: NgbTypeahead;
  bayNumberTA: Array<any> = [];
  focusBayNumberTA$ = new Subject<string>();
  bayNumberSearchTA = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusBayNumberTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.bayNumberTA
        : this.bayNumberTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }

  showBayInfo = false;
  tdyEWayBillValidUptoDate: any;
  @ViewChild("eWayBillValidUptoDate") eWayBillValidUptoDateField: ElementRef;
  bayFlow = false;
  selectedBayNumber: any;
  enteredBayUnloadingDate: any;
  selectedBayUnloadingTime: any;
  enteredEwayBillValidUptoDate: any;
  enteredNewConsigneeMobileNumber: any;
  cityOfNewConsignee: any;
  isDestValid = false;
  isAgentNameValid = false;
  isMainCommodityValid = false;
  isCommonCommodityValid = false;
  viewLrEntryBarcodePrint = false;
  eWayBillValidUptoDateMultiModel: any;
  private newAttributeMultiEway: any = {};
  enteredMultiEwayBillNo: any;
  private newAttributeEwayBillToPush: any = {};
  //CHG-V-LR:01
  consignorRateFlow = false;
  rateMasterDtoConsignor: RateMasterDto = new RateMasterDto();
  rateMasterDtoConsignorResp: any;
  consignorRateFlowSet = false;
  consignorRateId = 0;
  consignorIdRateId = 0;
  consigneeIdRateId = 0;
  consignorRateSrc: any;
  consignorRateFor: any;
  consignorRateConsignor: any;
  consignorRateDest: any;
  consignorRateCommodity: any;
  consignorRateType: any;
  consignorRatePerUnit: any;
  consignorRatePerUnitRateOnActWgt: any;
  consignorRateBasicRate: any;
  consignorRateHamali: any;
  consignorRateGcChg: any;
  consignorRateAoc: any;
  consignorRateOthers: any;
  consignorRateDDChg: any;
  consignorRateConsignee: any;

  consignorRateWEF: any;
  userDataDto: UserDataDto = new UserDataDto();
  viewPincode: boolean = false;
  enteredPincode: any;
  consignorEInvoiceEnableMode: any;
  consignorEInvoiceEnabled = false;
  consignorEInvoiceNoEnabled = false;
  enteredConsignorEInvoiceNo: any;
  modalRefferenceContentEinvoicePopUp: NgbModalRef;
  isConsignorMobileValid = false;
  viewSubstation: boolean = false;
  isLRReadOnly: boolean = false;
  cftFormula = null;
  cftRate = null;
  viewCftFields: boolean = true;
  modalRefferenceContentMultiArtPopUp: NgbModalRef;
  destinationMainStation = null;
  multiArtBlurEvent: boolean = true;
  rateMasterDtoCft: RateMasterDto = new RateMasterDto();
  isCftCommodityExemp = false;
  cftCommodityExempChgWgt = 0.0;
  cftCommodityExempValidateRet: any;
  viewCustomCftFields: boolean = false;
  enteredCustomCftValue = null;
  minDateBkg: any;
  maxDateBkg: any;
  lrtDtoRespForEwayApi: LRDto = new LRDto();
  responseDtoForEwayApi: ResponseDto = new ResponseDto();
  showSpinnerForAction = false;
  showCCAttacheOption = false;
  responseDto: ResponseDto = new ResponseDto();
  requestDto: RequestDto = new RequestDto();
  isDefaultCftFlowDisableForLessParties: boolean = false;
  destinationForCFTValidate: any;
  commaSplitDestForCFTValidate: Array<any> = [];
  destSplitForCFTValidate: any;
  isDefaultCftFlowDisableForConsignor: boolean = false;
  isDefaultCftFlowDisableForConsignee: boolean = false;
  cftRangeApplicableLR: boolean = false;
  chgWgtReadOnly: boolean = false;
  enableEwayBillPartBUpdateFlow = false;
  partBVehicleNumber = null;
  lrtDtoPartBEwayBill: LRDto = new LRDto();
  rateDtoForConsigneeRateDetails: RateMasterDto = new RateMasterDto();
  //CHG-V-LR:21
  consignorRateFov: any;
  //CHG-V-LR:22
  isFrieghtFieldsReadOnly: boolean = false;
  lrDtoPerKgGet: LRDto = new LRDto();
  lrtDtoPerKgRateReturn: LRDto = new LRDto();
  //CHG-V-LR:29
  responseDtoForGstApi: any;
  enteredGstNumber: any;
  lrtDtoRespForGstNo: any;
  swalTextMessage: any;
  destMemoSizeExceedRights: boolean = false;
  rateMasterDtoGet: RateMasterDto = new RateMasterDto();
  commodityKgLimitData: LRDto = new LRDto();
  commodityKglimit: any;
  isSizeCommodityExceed: boolean = false;
  isLrBkgConsiderConsigneeRate: boolean = false;
  isPerKgLimitTaken: boolean = false;
  enteredPanNumberSearch: any;
  panValidation = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
  enteredAadharNumberSearch: any;
  text: any = "Enter Consignee GST Number";
  showInputField: boolean = true;
  aadharDetails: any;
  requestDtoForAadhar: RequestDto = new RequestDto();
  requestDtoAadharRes: RequestDto = new RequestDto();
  enteredOtp: any;
  gstValidation = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  enteredGstNumberSearch: any;
  selectedSearchBy: any;
  dtoBlockedcmdty: RateMasterDto = new RateMasterDto();
  dtoBlockedCmdtyRtn: ResponseDto = new ResponseDto();
  isCommodityBlockedBkg: Boolean = false;
  consignorTextField: any = "Enter Consignor GST Number";
  selectedSearchByConsignor: any;
  partyType: any;
  commodityType: any;
  showSpinnerForPopup = false;
  consignorPopupLabel: any = "Aadhar Number";
  newConsignorName: any;
  enteredNewConsignorGSTNoPan: any;
  consigneePopupLabel: any = "AAdhar Number";
  newConsigneeName: any;
  enteredNewConsigneeGSTNoPan: any;
  gstNoConsignorModel: any;
  panCardDetails: any;
  requestDtoGetPan: RequestDto = new RequestDto();
  viewBarcodePrint = false;
  PrintBarcode = [];
  enableBarcodeGen = false;
  lrDtoBarcode: LRDto = new LRDto();
  lrDtoForLrBarcodeRet = new LRDto();
  showSpinnerForBarcode: boolean = false;
  constructor(private modalService: NgbModal, private masterReadService: MasterReadService, private masterService: MasterService, private router: Router, private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute, public changeDetectorRef: ChangeDetectorRef,
    private ewaybillService: EwaybillService, private lrService: LrService) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
      this.getOffice = this.userDataDtoReturnSession.office;
      this.getUserGodownList();
      //this.getAgentDetailList();
      this.getLoaderHeadListDetails();
      this.getPackNatureListDetails();
      this.getStateDetails();
      //            this.getCommodityNamesDetails();
      //CHG-V-LR:02
      //this.getDestinationDetails();
      //
      this.getLrEntrylistBoxCommodity();
      this.showAutoLr = $("#" + this.pageId + "automatedLR").val();
      //this.multiArtDetailsTable();
      this.multiPackDetailsTable();
      this.setDefaultCharges();
      this.getAgentTempoMasterDetailsList();
      this.setBayNumber();
      this.multiEwayDetailsTable();
      this.showLrCode = this.userDataDtoReturnSession.isLrCode;
      this.showLrCodeInputFields = this.userDataDtoReturnSession.lrCode;
      this.isLRCode = this.userDataDtoReturnSession.isLrCode;

      // const currentYear = new Date().getFullYear();
      // const currentMonth = new Date().getMonth();
      // const currentDate = new Date().getDate();

      // this.minDateBkg = new Date();
      // this.maxDateBkg = new Date(currentYear, currentMonth, currentDate + 1);

      //            console.log( 'aaadd ' + this.showLrCodeInputFields, this.isLRCode );
      //                        if ( this.userDataDtoReturnSession.mainAdminStation != null
      //                            && this.userDataDtoReturnSession.mainAdminStation == "MUMBAI" ) {
      //                            this.showLrCode = true;
      //                            this.showLrCodeInputFields = this.userDataDtoReturnSession.autoLRCode;
      //                            this.isLRCode = true;
      //                        } else if ( this.userDataDtoReturnSession.mainAdminStation != null
      //                            && this.userDataDtoReturnSession.mainAdminStation == "GUJARAT" ) {
      //                            this.showLrCode = true;
      //                            this.showLrCodeInputFields = this.userDataDtoReturnSession.autoLRCode;
      //                            this.isLRCode = true;
      //                        }

      ///Rights for show or hide risk charge
      //            if ( this.hideRiskCharge == "Hide Risk Charge" ) {
      //                this.showRiskCharge = false;
      //            } else {
      //                this.showRiskCharge = true;
      //            }
      //            console.log( this.userDataDtoReturnSession.mainAdminStation );
      if (this.userDataDtoReturnSession.mainAdminStation != null
        && this.userDataDtoReturnSession.mainAdminStation == "Bangalore Bkg") {
        this.showRiskCharge = false;
        this.readonlyFOV = false;
        //                console.log( '1' );
      } else {
        this.showRiskCharge = true;
        this.readonlyFOV = true;
        //           console.log( '2' );

      }
      if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
        for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            "Restrict User Godown List For Agent") {
            this.restrictUserGodownList = true;
          }
        }
      }
      if (this.restrictUserGodownList == true) {
        this.defaultGodownName = this.userDataDtoReturnSession.office;
        //   console.log( 'true' );
      } else {
        //   console.log( 'false' );
        this.getUserGodownList();
      }
      this.selectTodayArrival();
      this.selectTodayBooking();
      this.selectTodayBill();
      this.selectBayDate();
      // this.setEMthod();
      //Need to be uncomment this if condition Pending By Basha newly implemented Flow
      /*this.bayFlow = true;
      this.showBayInfo=true;
      console.log(this.userDataDtoReturnSession.bayFlowInLR);*/
      console.log(this.userDataDtoReturnSession.bayFlowInLR);
      if (this.userDataDtoReturnSession.bayFlowInLR != null && this.userDataDtoReturnSession.bayFlowInLR) {
        this.bayFlow = true;
        this.showBayInfo = true;
      }
      //temp commented this
      //this.lrScanAllow = this.userDataDtoReturnSession.scanInLREntry;
      //this.lrArtScanAllow = this.userDataDtoReturnSession.scanInArtLREntry;

      // CHG-V-LR:01
      this.consignorRateFlow = this.userDataDtoReturnSession.isConsignorRateFlow;
      // CHG-V-LR:02
      this.cftFormula = this.userDataDtoReturnSession.cftFormula;
      this.cftRate = this.userDataDtoReturnSession.cftRate;

      //CHG-V-LR:08
      //CHG-V-LR:11
      //CHG-V-LR:14
      let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
      let office = this.userDataDtoReturnSession.office.toLowerCase();
      if (office != null
        && ((office == 'dhared - jm') || (office == 'udyog nagar - jm'))) {
        this.viewValidateBkgDate = true;
      } else {
        this.viewValidateBkgDate = false;
      }

      // if (MainAdminStation != null
      //     && (MainAdminStation == 'delhi' || MainAdminStation == 'bangalore bkg head ofc')) {
      //     this.viewValidateBkgDate = false;
      // } else {
      //     this.viewValidateBkgDate = true;
      // }

      if (MainAdminStation != null
        && (MainAdminStation == 'delhi')) {
        this.showCCAttacheOption = true;
      } else {
        this.showCCAttacheOption = false;
      }

      //CHG-V-LR:19
      this.enableEwayBillPartBUpdateFlow = this.userDataDtoReturnSession.enableEwaybillPartBFlowOnLrEntry;
      //Chg_V-Lr:30
      if (this.userDataDtoReturnSession.destMemoSizeExceeded) {
        this.destMemoSizeExceedRights = true;
      }
      //Chg_V-Lr:31
      if (this.userDataDtoReturnSession.lrBkgConsiderConsigneeRate) {
        this.isLrBkgConsiderConsigneeRate = true;
      }
      this.showInputField = true;
    }
    this.enableBarcodeGen = this.userDataDtoReturnSession.enableBarcodeGen;
  }
  logInPage() {
    this.router.navigate(['/authentication/login']);
  }

  ngAfterViewInit(): void {
    //this.dtTriggerMultiArtTable.next();
    this.dtTriggerMultiPackTable.next();
    this.dtTriggerMultEway.next();
    this.dtTriggerLrArtScan.next();
  }

  ngOnDestroy(): void {
    //this.dtTriggerMultiArtTable.unsubscribe();
    this.dtTriggerMultiPackTable.unsubscribe();
    this.dtTriggerMultEway.unsubscribe();
    this.dtTriggerLrArtScan.unsubscribe();
  }
  /* doorDeliMode( doorDelivery: string ) {

      if ( doorDelivery == 'Yes' ) {
          this.doorDeliveryYes = true;
          this.sum();
          this.ddAmtField.nativeElement.focus();

      } else {
          this.doorDeliveryYes = false;
          this.sum();
          this.paymentTypeField.nativeElement.focus();

      }
  } */


  doorDeliMode(doorDelivery: string) {
    this.ddAmtRateChg = 0;
    if (doorDelivery == 'Yes') {
      this.doorDeliveryYes = true;
      this.doorDeliveryYesddAmt = true;

      // for dd chg @02062022
      this.ddAmtRateChg = this.rateMasterDtoConsignorResp.ddChargeAmt;
      $("#" + this.pageId + "ddAmt").val(this.rateMasterDtoConsignorResp.ddChargeAmt);

      this.sum();
      this.changeDetectorRef.detectChanges();
      window.setTimeout(function () {
        $("#lrefcddAmt").focus();
      }, 100);

    } else {
      this.doorDeliveryYes = false;
      this.doorDeliveryYesddAmt = false;
      this.ddAmtRateChg = 0;
      $("#" + this.pageId + "ddAmt").val('');
      this.sum();
      $("#lrefcpaymentType").focus();
    }

  }



  paymentTypeMode(paymentType: string) {
    if (paymentType === 'paid') {
      this.paymentTypePaid = true;
      this.bankLrField.nativeElement.focus();
    } else {
      this.paymentTypePaid = false;
      this.bankLrField.nativeElement.focus();
    }
  }

  //bankLrMode( bankLr: string ) {
  //  if ( bankLr === 'Yes' ) {
  //    this.bankLrYes = true;
  //  this.bankNameField.nativeElement.focus();
  //} else {
  //  this.bankLrYes = false;
  //            this.privateMarkerField.nativeElement.focus();
  //      }
  //    }

  //CHG-V-LR:09
  bankLrMode(bankLr: string) {
    if (bankLr === 'Yes') {
      // this.bankLrYes = true;
      // window.setTimeout(function () {
      //     $("#lrefcbankName").focus();
      // }, 100);
      swal("Not Allowed", "Bank LR Booking is not allowed, please contact Admin if any!", "warning");
      this.bankLrYes = false;
      $("#" + this.pageId + "bankLr").val('No');
    } else {
      this.bankLrYes = false;
      $("#lrefcprivateMarker").focus();
    }
  }

  // the selectToday is the method for  selecting todays'z date
  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
  }
  selectTodayArrival() {
    this.tdyArrivalDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()

    };

    //  var today = new Date();
    //alert( 'ok today arrivalDate :' + today.getFullYear() + '-' + ( '0' + ( today.getMonth() + 1 ) ).slice( -2 ) + '-' + ( '0' + today.getDate() ).slice( -2 ) );
    // $( '#arrivalDate' ).val(today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2));

    //alert( 'ok arrivalDate :' + this.datePipe.transform( new Date(), "dd-MM-yyyy" ) );
    //document.getElementById( 'bookingDate' ).valueAsDate = this.datePipe.transform( new Date(), "dd-MM-yyyy" );
    //$( '#arrivalDate' ).val( this.datePipe.transform( new Date(), "dd-MM-yyyy" ) );

    //        $('#arrivalDate').datepicker('setDate', this.datePipe.transform( new Date(), "dd-MM-yyyy" ));
    //        const element = document.getElementById( 'arrivalDate' ) as HTMLInputElement;
    //        element.valueAsDate = new Date();

    //document.getElementById( 'arrivalDate' ).innerHTML = Date();


  }

  selectTodayBooking() {
    // alert( 'ok :' + this.datePipe.transform( new Date(), "dd-MM-yyyy" ) );
    //document.getElementById( 'bookingDate' ).valueAsDate = this.datePipe.transform( new Date(), "dd-MM-yyyy" );
    //$( '#bookingDate' ).val( this.datePipe.transform( new Date(), "dd-MM-yyyy" ) );
    this.tdyBookingDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };

  }

  selectTodayBill() {
    this.tdyBillDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
  }
  //for popup modal starts 
  open2(content) {
    this.modalService.open(content, { centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  open9(content9) {
    this.modalService.open(content9, { centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  open10(content10) {
    this.modalService.open(content10, { centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  open12(content12) {
    this.modalService.open(content12, { centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  //for popup modal ends 

  //don't now what is used for 
  openBackDropCustomClass(content) {
    this.modalService.open(content, { backdropClass: 'light-blue-backdrop' });
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal' });
  }

  openSm(content) {
    this.modalService.open(content, { size: 'sm' });
  }

  openLg(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  getUserValuesForUserGodownList() {
    this.lrDtoParam.city = this.userDataDtoReturnSession.mainAdminStation;
    this.lrDtoParam.companyId = this.userDataDtoReturnSession.companyId;
  }

  getUserGodownList() {
    this.getUserValuesForUserGodownList();
    this.masterReadService.getSubBookingStationDetailsService(this.lrDtoParam).subscribe(
      (response) => {
        this.lrDtosUserGodownOptions = response;
        //                console.log( 'user godown : ' + this.lrDtosUserGodownOptions );



        this.userGodownTA = [];
        this.agentNameTA = [];
        for (let i = 0; i < this.lrDtosUserGodownOptions.length; i++) {
          //console.log( this.agentDetailsOptionsGet[i].subStation );
          this.userGodownTA.push(this.lrDtosUserGodownOptions[i].subStations);
          this.agentNameTA.push(this.lrDtosUserGodownOptions[i].subStations);
        }
        this.defaultGodownName = this.userDataDtoReturnSession.mainStation.toUpperCase();
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  }
  //for Getting Agent
  getUserValuesForAgentDetailsList() {
    this.agentDtoParam.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    this.agentDtoParam.column2 = 'Working';
    this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
    this.agentDtoParam.mode = 'Sub';
  }

  getAgentDetailList() {
    this.getUserValuesForAgentDetailsList();
    this.masterReadService.getSourceListDetails(this.agentDtoParam).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Agents  records found!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.controlAgentName.reset();
          //                    this.agentDetailsOptionsGet = [];
          //                    this.agentDtoAll.subStation = "Add New";
          //                    this.agentDetailsOptionsGet.push( this.agentDtoAll );
        } else {
          this.agentDetailsOptionsGet = response;
          this.agentDetailsOptionsSet = response;
          //                    this.agentDtoAll = this.agentDetailsOptionsSet;
          //                    console.log( this.agentDtoAll );
          //                    if ( this.agentDtoAll.status == 'success' ) {
          //                        swal( {
          //                            title: "Warning",
          //                            text: "No Agents  records found!",
          //                            icon: "warning",
          //                            closeOnClickOutside: false,
          //                            closeOnEsc: false,
          //                        } );
          //                    }

          //                    this.agentDtoAll.subStation = "Add New";
          //                    this.agentDetailsOptionsGet.push( this.agentDtoAll );
          this.agentNameTA = [];
          for (let i = 0; i < this.agentDetailsOptionsGet.length; i++) {
            //console.log( this.agentDetailsOptionsGet[i].subStation );
            this.agentNameTA.push(this.agentDetailsOptionsGet[i].subStation);
          }

        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  }

  //for loader Head 
  /* getLoaderHeadList() {
       // this.hireDto.mainStation = this.userDataDtoReturnSession.mainStation;
       this.hireDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
       this.hireDto.companyid = this.userDataDtoReturnSession.companyId;
       //        console.log( 'mainstation has :' + this.hireDto.mainStation )
   }
 
 
   getLoaderHeadListDetails = () => {
       this.getLoaderHeadList();
       this.masterReadService.getLoaderHeadDetails( this.hireDto ).subscribe(
           ( response ) => {
               if ( response.length == 0 ) {
                   swal( {
                       title: "Warning",
                       text: "No Loader records found!",
                       icon: "warning",
                       closeOnClickOutside: false,
                       closeOnEsc: false,
                   } );
                   this.controlLoaderHead.reset();
                   this.loaderHeadOptions = [];
                   this.loaderHeadTA = [];
               } else {
                   this.controlLoaderHead.reset();
                   this.loaderHeadOptions = response;
 
                   this.loaderHeadTA = [];
                   for ( let i = 0; i < this.loaderHeadOptions.length; i++ ) {
                       this.loaderHeadTA.push( this.loaderHeadOptions[i].loaderHead );
                   }
 
 
               }
           } ),
           ( error ) => console.log( error.json() ),
           () => console.log( 'done' );
   };*/
  getLoaderHeadList() {

    //this.hireDto.mainStation = this.userDataDtoReturnSession.mainAdminStation; 
    this.hireDto.branch = this.userDataDtoReturnSession.mainAdminStation;
    this.hireDto.companyid = this.userDataDtoReturnSession.companyId;
  }


  getLoaderHeadListDetails = () => {
    this.getLoaderHeadList();
    this.masterReadService.getLoaderHeadsDetails(this.hireDto).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Loader records found!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.controlLoaderHead.reset();
          this.loaderHeadOptions = [];
          this.loaderHeadTA = [];
        } else {
          this.controlLoaderHead.reset();
          this.loaderHeadOptions = response;

          this.loaderHeadTA = [];
          for (let i = 0; i < this.loaderHeadOptions.length; i++) {
            this.loaderHeadTA.push(this.loaderHeadOptions[i].loaderHead);
          }


        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };
  //Fort Pack Natature
  getPackNatureList() {
    this.hireDtopackNature.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    this.hireDtopackNature.companyid = this.userDataDtoReturnSession.companyId;

  }


  getPackNatureListDetails = () => {
    this.getPackNatureList();
    this.masterReadService.getNatureOfPack(this.hireDtopackNature).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Pack Nature records found!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.controlPackNature.reset();
          this.packNatureOptions = [];
          this.packNatureTA = [];
          this.controlPackNatureNew.reset();
          this.packNatureNewOptions = [];
        } else {
          // having issue due to different dto
          this.controlPackNature.reset();

          this.packNatureDetails = response;
          this.packNatureOptions = this.packNatureDetails;
          //basha added on 19/04/2021
          this.packNaturePopupTA = [];
          for (let i = 0; i < this.packNatureOptions.length; i++) {
            this.packNaturePopupTA.push(this.packNatureOptions[i].packNature);
          }
          this.packNatureOptionsAdd.packNature = "Multiple Pack";
          this.packNatureOptions.push(this.packNatureOptionsAdd);
          //For new packNature
          this.packNatureTA = this.packNatureDetails;

          this.packNatureTA = [];
          for (let i = 0; i < this.packNatureOptions.length; i++) {
            this.packNatureTA.push(this.packNatureOptions[i].packNature);
          }

        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  //    getCommodityNamesDetailsList() {
  //        this.commodityRateDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
  //        this.commodityRateDto.companyId = this.userDataDtoReturnSession.companyId;
  //
  //    }
  //
  //
  //    getCommodityNamesDetails = () => {
  //        this.getCommodityNamesDetailsList();
  //        this.masterReadService.getCommodityNamesDetails( this.commodityRateDto ).subscribe(
  //            ( response ) => {
  //                if ( response.length == 0 ) {
  //                    alert( 'No records found!' );
  //                } else {
  //                    // having issue due to different dto
  //
  //                    this.commodityDescriptionOptions = response;
  //                    this.commodityRateDtoAdd.commodityName = "NA";
  //                    this.commodityDescriptionOptions.push( this.commodityRateDtoAdd );
  //
  //                }
  //            } ),
  //            ( error ) => console.log( error.json() ),
  //            () => console.log( 'done' );
  //    };
  open1(content1) {
    this.modalService.open(content1, { centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  //
  getAgentName(e) {
    this.showCont1 = $("#" + this.pageId + "content1").val();
    if (e.keyCode == 13) {
      this.selectedAgentName = $("#" + this.pageId + "agentName").val();
      if (this.selectedAgentName != null && this.selectedAgentName == 'Add New') {

        //                this.open1( this.showCont1 );
        //                this.lblAddAgent = true;
        //Pending Adding Agent has been removed 
      } else {
        this.vehicleNumberField.nativeElement.focus();
      }

    }
  }


  //For Getting Consignor Details
  //CHG-V-LR:36
  getConsignorGstNumber(e, openPopupForAadhar) {
    if (e.keyCode == 13) {
      this.selectedconsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
      if (this.selectedconsignorGSTNumber != null && this.selectedconsignorGSTNumber != '') {
        //CHG-V-LR:36
        //CHG-V-LR:39
        // this.getConsignorDetails(openPopupForAadhar);
        this.partyType = 'consignor';
        this.validationGetConsignorDetailsBasedOnSelectType(openPopupForAadhar);
      } else {
        swal({
          title: "Warning",
          text: "Please Enter the Consignor GST number",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(() => {
          $("#" + this.pageId + "consignorGSTNumber").focus();
        })
        this.consignorOptions = [];
        this.consignorOptionsRet = this.consignorOptions;
        //CHG-V-LR:27
        this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
        if (this.enteredEWayBillNo == null || this.enteredEWayBillNo == '') {
          // this.consignorOptionsAdd.consignorName = "Add New"
          // this.consignorOptions.push(this.consignorOptionsAdd);
        }

        $("#" + this.pageId + "consignorName").val('');
        $("#" + this.pageId + "consignorAddress").val('');
        $("#" + this.pageId + "consignorMobNoExist").val('');

      }

    }
  }


  getConsignorDetailsList() {
    $("#" + this.pageId + "consignorName").val('');
    $("#" + this.pageId + "consignorAddress").val('');
    $("#" + this.pageId + "consignorMobNoExist").val('');

    ////CHG-V-LR:39
    // this.selectedconsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
    this.partyMasterDtoForconsignor = new PartyMasterDto();
    this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
    this.partyMasterDtoForconsignor.isGST = true;
    this.partyMasterDtoForconsignor.mode = "LREntry";
    this.partyMasterDtoForconsignor.gstNoConsignor = this.selectedconsignorGSTNumber;
  }

  //CHG-V-LR:36
  getConsignorDetails = (openPopupForAadhar) => {
    this.getConsignorDetailsList();
    this.masterReadService.getConsignorDetailsMasterDaoNew(this.partyMasterDtoForconsignor).subscribe(
      (response) => {
        if (response.length == 0) {
          //CHG-V-LR:27
          // this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
          // if (this.enteredEWayBillNo == null || this.enteredEWayBillNo == '') {

          //CHG-V-LR:29
          // this.getDetailsFromEwayBillSearchByGstNoMethod("consignor");

          if ($("#" + this.pageId + "selectType").val() == "gst") {
            this.validateGstNoField();
          }
          //CHG-V-LR:45
          else if ($("#" + this.pageId + "selectType").val() == "pan") {
            //CHG-V-LR:50
            // this.validatePanNoField();
            //CHG-V-LR:50
            window.setTimeout(function () {
              $("#lrefcconsignorName").focus();
            }, 100);
            this.controlConsignor.reset();
            this.consignorOptions = [];
            this.consignorTA = [];
            this.consignorOptionsRet = this.consignorOptions;
            this.consignorOptionsAdd.consignorName = "Add New";
            this.consignorOptionsAdd.newConsigneeCity = "Add New";
            this.consignorOptions.push(this.consignorOptionsAdd);
            this.consignorOptionsAdd.consignorName = 'Add New';
            this.consignorOptionsAdd.newConsigneeCity = 'Add New';
            this.consignorTA.push(this.consignorOptionsAdd);
          }
          //CHG-V-LR:43
          // else if ($("#" + this.pageId + "selectType").val() == "pan") {
          //   this.validatePanNoField();
          // } else if ($("#" + this.pageId + "selectType").val() == "aadhar") {
          //   this.validateAadharNoField(openPopupForAadhar);
          // }

          // } else {
          //   swal({
          //     title: "Warning",
          //     text: "No records found for this Consignor GST number !",
          //     icon: "warning",
          //     closeOnClickOutside: false,
          //     closeOnEsc: false,
          //   }).then(() => {
          //     //                        this.consignorNameField.nativeElement.focus();
          //     window.setTimeout(function () {
          //       $("#lrefcconsignorName").focus();
          //     }, 100);
          //   });
          // }

          //CHG-V-LR:43
          else if ($("#" + this.pageId + "selectType").val() == "aadhar") {
            window.setTimeout(function () {
              $("#lrefcconsignorName").focus();
            }, 100);

            this.controlConsignor.reset();
            this.consignorOptions = [];
            this.consignorTA = [];
            this.consignorOptionsRet = this.consignorOptions;
            this.consignorOptionsAdd.consignorName = "Add New";
            this.consignorOptionsAdd.newConsigneeCity = "Add New";
            this.consignorOptions.push(this.consignorOptionsAdd);
            this.consignorOptionsAdd.consignorName = 'Add New';
            this.consignorOptionsAdd.newConsigneeCity = 'Add New';
            this.consignorTA.push(this.consignorOptionsAdd);
          }

        } else {
          this.controlConsignor.reset();
          this.consignorOptions = response;
          this.consignorOptionsRet = this.consignorOptions
          this.consignorTA = [];
          for (let i = 0; i < this.consignorOptions.length; i++) {
            this.consignorTA.push(this.consignorOptions[i]);
          }
          //CHG-V-LR:27
          this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
          $("#lrefcconsignorName").focus();
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  rowSelectedConsignor(e: NgbTypeaheadSelectItemEvent, content9, contentEinvoice) {
    this.modelConsignorName = e.item;
    console.log('test2');
    console.log(this.modelConsignorName);
    this.selectedConsignorName = this.modelConsignorName.consignorName;
    $("#" + this.pageId + "consignorAddress").val(this.modelConsignorName.address);
    if (this.modelConsignorName.mobileNumber != null) {
      console.log('rowSelectedConsignor-1');
      console.log(this.modelConsignorName.mobileNumber);
      var splitConsignorMobile = this.modelConsignorName.mobileNumber.split("#");

      console.log(splitConsignorMobile[0]);
      //$("#" + this.pageId + "consignorMobNoExist").val(splitConsignorMobile[0]);
      $("#" + this.pageId + "consignorMobNoExist").val(this.modelConsignorName.mobileNumber);
    }

    this.selectedConsignorId = this.modelConsignorName.consignorId;
    //        this.consigneeGSTNumberField.nativeElement.focus();
    this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
    //      console.log( this.selectedConsignorId, this.modelConsignorName.address );

    this.consignorEInvoiceEnableMode = this.modelConsignorName.consignorEInvoiceEnable;
    if (this.modelConsignorName.consignorEInvoiceEnable == null || this.modelConsignorName.consignorEInvoiceEnable == '' || this.modelConsignorName.consignorEInvoiceEnable == undefined) {
      this.consignorEInvoiceEnabled = false;
    } else if (this.modelConsignorName.consignorEInvoiceEnable == 'Yes') {
      this.consignorEInvoiceNoEnabled = true;
      this.consignorEInvoiceEnabled = true;
    } else if (this.modelConsignorName.consignorEInvoiceEnable == 'No') {
      this.consignorEInvoiceEnabled = true;
      this.consignorEInvoiceNoEnabled = false;
    }

    //CHG-V-LR:16
    console.log('default cft disable');
    console.log(this.modelConsignorName.disableDefaultCft);
    this.isDefaultCftFlowDisableForConsignor = this.modelConsignorName.disableDefaultCft;

    if (this.selectedConsignorName != null && this.selectedConsignorName == "Add New") {

      this.modalService.open(content9, { centered: true });

      //CHG-V-LR:50
      if ($("#" + this.pageId + "selectType").val() == "pan") {
        this.consignorPopupLabel = "Pan Number";
        this.validatePanNoFieldForGetDetails();
      } else {
        this.consignorPopupLabel = "Aadhar Number";
      }
      this.setValueForConsignor();
      $("#" + this.pageId + "newConsignor").focus();

      //            this.lblNewConsignor = true;
    } else if (this.consignorEInvoiceEnabled == false) {
      this.modalRefferenceContentEinvoicePopUp = this.modalService.open(contentEinvoice,
        { centered: true });
    } else {
      if ((this.selectedConsignorName != null) && (this.selectedConsignorName != "") && (this.selectedConsignorName != "ALL")) {
        //                this.consigneeGSTNumberField.nativeElement.focus();
        window.setTimeout(function () {
          $("#lrefcconsigneeGSTNumber").focus();
        }, 100);
      }

      //CHG-V-LR:01
      if (this.consignorRateFlow) {
        this.getConsignorRateDetails();
      }
      this.validateFOVRate();
      this.sum();
    }
  }
  //For Getting Consignee Details
  //CHG-V-LR:36
  getConsigneeGstNumber(e, openPopupForAadhar) {
    if (e.keyCode == 13) {
      this.validateDestinationSelection();
      if (this.isDestValid == true) {
        this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
        //CHG-V-LR:05 CHG-V-LR:36
        this.partyType = 'consignee';
        this.validateDestinationConsignee(openPopupForAadhar);
      }
    }
  }

  validateDestinationConsigneeData() {
    this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();

    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.selectedDestination = this.destSplit.toLowerCase();
    this.selectedDestination = this.selectedDestination.trim();
    this.partyMasterDtoForconsignee = new PartyMasterDto();

    this.partyMasterDtoForconsignee.office = this.destSplit;
    this.partyMasterDtoForconsignee.gstNoConsignee = this.selectedconsigneeGSTNumber;
  }

  // validateDestinationConsignee = () => {
  //     this.validateDestinationConsigneeData();

  //     ////CHG-V-LR:18
  //     // if (this.selectedDestination == 'edappally' || this.selectedDestination == 'ernakulam') {
  //     this.masterReadService.validateDestinationConsigneeLREntry(this.partyMasterDtoForconsignee).subscribe(
  //         (response) => {
  //             if (response.isValidConsigneeDest == false) {
  //                 swal({
  //                     title: "Invalid Destination",
  //                     //text: "This Consignee belongs to " + response.destination + ", please select " + response.destination + " destination by entering the Pincode " + response.pincode,
  //                     text: "This Consignee belongs to " + response.destination + ". Do you want to continue with this else please select " + response.destination + " destination by entering the Pincode " + response.pincode,
  //                     icon: "warning",
  //                     closeOnClickOutside: false,
  //                     closeOnEsc: false,
  //                     buttons: ["No", "Yes"],
  //                 }).then((Yes) => {
  //                     if (Yes) {
  //                         this.setConsigneeValuesAfterGST();
  //                     } else {
  //                         $("#lrefcdestination").val('');
  //                         this.destinationOptions = [];
  //                         this.destinationTA = [];

  //                         this.consigneeOptions = [];
  //                         this.consigneeOptionsRet = this.consigneeOptions;
  //                         this.consigneeTA = [];
  //                         $("#" + this.pageId + "consigneeAddress").val('');
  //                         $("#" + this.pageId + "consigneeName").val('');
  //                         $("#" + this.pageId + "consigneeMobNoExist").val('');

  //                         $("#lrefcentryPinCode").focus();
  //                     }
  //                 });
  //             } else {
  //                 this.setConsigneeValuesAfterGST();
  //             }
  //             this.changeDetectorRef.detectChanges();
  //         }),
  //         (error) => console.log(error.json()),
  //         () => console.log('done');
  //     // } else {
  //     //     this.setConsigneeValuesAfterGST();
  //     // }
  // }

  //CHG-V-LR:20    CHG-V-LR:36
  validateDestinationConsignee = (openPopupForAadhar) => {
    this.validateDestinationConsigneeData();
    ////CHG-V-LR:46
    // if (this.selectedDestination == 'edappally' || this.selectedDestination == 'ernakulam' || this.selectedDestination == 'palakkad') {
    this.masterReadService.validateDestinationConsigneeLREntry(this.partyMasterDtoForconsignee).subscribe(
      (response) => {
        if (response.isValidConsigneeDest == false) {
          //CHG-V-LR:49
          // swal("Invalid Destination", "This Consignee belongs to " + response.destination + ", please select " + response.destination + " destination by entering the Pincode " + response.pincode, "warning");
          //swal("Invalid Destination", "This Consignee not belongs to " + this.destSplit + ", please contact Admin!", "warning");
          swal("Invalid Destination", "This Consignee is configured in the master screen for " + response.city + " destination. Please select the mentioned destination or contact Admin!", "warning");
          $("#lrefcdestination").val('');
          this.destinationOptions = [];
          this.destinationTA = [];
          this.consigneeOptions = [];
          this.consigneeOptionsRet = this.consigneeOptions;
          this.consigneeTA = [];
          $("#" + this.pageId + "consigneeAddress").val('');
          $("#" + this.pageId + "consigneeName").val('');
          $("#" + this.pageId + "consigneeMobNoExist").val('');
          $("#lrefcentryPinCode").focus();
        } else {
          //CHG-V-LR:36
          this.setConsigneeValuesAfterGST(openPopupForAadhar);
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
    //CHG-V-LR:46
    // } else {
    //   //CHG-V-LR:36
    //   this.setConsigneeValuesAfterGST(openPopupForAadhar);
    // }
  }

  //CHG-V-LR:36
  setConsigneeValuesAfterGST(openPopupForAadhar) {
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    // calling this method again to revise the chg for
    // chennai @dated : 15/12/2021
    this.setDefaultCharges();
    this.validateFOVRate();
    console.log(this.selectedDestination);
    console.log(this.selectedconsigneeGSTNumber);

    if (this.selectedDestination != null && this.selectedDestination != '') {
      if (this.selectedconsigneeGSTNumber != null && this.selectedconsigneeGSTNumber != '') {
        //CHG-V-LR:36
        //CHG-V-LR:39
        // this.getConsigneeDetails(openPopupForAadhar);
        this.validationGetConsigneeDetailsBasedOnSelectType(openPopupForAadhar);

      } else {
        if (this.selectedconsigneeGSTNumber == null && this.selectedconsigneeGSTNumber == '') {
          swal({
            title: "Warning",
            text: "Please Enter the Consignee GST number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {
            $("#" + this.pageId + "consigneeGSTNumber").focus();
          });
          this.consigneeOptions = [];
          this.consigneeOptionsRet = this.consigneeOptions;
          this.consigneeTA = [];
        }

        $("#" + this.pageId + "consigneeAddress").val('');
        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "consigneeMobNoExist").val('');
      }
    }
  }



  getConsigneeDetailsList() {
    $("#" + this.pageId + "consigneeAddress").val('');
    $("#" + this.pageId + "consigneeName").val('');
    $("#" + this.pageId + "consigneeMobNoExist").val('');

    //CHG-V-LR:37
    // this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();

    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.stateByDest = this.commaSplitDest[1];
    this.partyMasterDtoForconsignee = new PartyMasterDto();

    this.partyMasterDtoForconsignee.office = this.destSplit;
    this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
    this.partyMasterDtoForconsignee.isGST = true;
    this.partyMasterDtoForconsignee.mode = "Tinnumber";
    this.partyMasterDtoForconsignee.gstNoConsignee = this.selectedconsigneeGSTNumber;
    //        console.log( 'sss' + this.partyMasterDtoForconsignee );
  }


  //CHG-V-LR:36
  getConsigneeDetails = (openPopupForAadhar) => {
    this.getConsigneeDetailsList();
    this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
      (response) => {
        if (response.length == 0) {
          //CHG-V-LR:27
          // this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
          // if (this.enteredEWayBillNo == null || this.enteredEWayBillNo == '') {
          // this.consigneeOptionsAdd.consigneeName = "Add New"
          // this.consigneeTA.push(this.consigneeOptionsAdd);
          //CHG-V-LR:29
          if ($("#" + this.pageId + "searchBy").val() == "gst") {
            //CHG-V-LR:38
            // this.getDetailsFromEwayBillSearchByGstNoMethod("consignee");
            this.validateGstNoField();
          }
          //CHG-V-LR:45
          else if ($("#" + this.pageId + "searchBy").val() == "pan") {
            //CHG-V-LR:50
            // this.validatePanNoField();
            //CHG-V-LR:50
            window.setTimeout(function () {
              $("#lrefcconsigneeName").focus();
            }, 100);

            this.controlConsignee.reset();
            this.consigneeOptions = [];
            this.consigneeOptionsRet = this.consigneeOptions;
            this.consigneeTA = [];
            this.consigneeOptionsAdd.consigneeName = "Add New"
            this.consigneeTA.push(this.consigneeOptionsAdd);
          }
          //CHG-V-LR:43
          // else if ($("#" + this.pageId + "searchBy").val() == "pan") {
          //   this.validatePanNoField();
          // } else if ($("#" + this.pageId + "searchBy").val() == "aadhar") {
          //   //CHG-V-LR:36
          //   this.validateAadharNoField(openPopupForAadhar);
          // }

          // } else {
          //   swal({
          //     title: "Warning",
          //     text: "No records found for this Consignee GST number !",
          //     icon: "warning",
          //     closeOnClickOutside: false,
          //     closeOnEsc: false,
          //   }).then(() => {
          //     //                      this.consignorNameField.nativeElement.focus();
          //     window.setTimeout(function () {
          //       $("#lrefcconsigneeName").focus();
          //     }, 100);

          //   });
          // }

          //CHG-V-LR:43
          else if ($("#" + this.pageId + "searchBy").val() == "aadhar") {
            window.setTimeout(function () {
              $("#lrefcconsigneeName").focus();
            }, 100);

            this.controlConsignee.reset();
            this.consigneeOptions = [];
            this.consigneeOptionsRet = this.consigneeOptions;
            this.consigneeTA = [];
            this.consigneeOptionsAdd.consigneeName = "Add New"
            this.consigneeTA.push(this.consigneeOptionsAdd);
          }
        } else {
          // having issue due to different dto Pending
          this.controlConsignee.reset();
          this.consigneeOptions = response;
          this.consigneeOptionsRet = this.consigneeOptions
          this.consigneeTA = [];
          for (let i = 0; i < this.consigneeOptionsRet.length; i++) {
            this.consigneeTA.push(this.consigneeOptionsRet[i]);
          }
          //CHG-V-LR:27
          this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
          if (this.enteredEWayBillNo == null || this.enteredEWayBillNo == '') {
            // this.consigneeOptionsAdd.consigneeName = "Add New"
            // this.consigneeTA.push(this.consigneeOptionsAdd);
          }


          //this.consigneeNameField.nativeElement.focus();

          //changes related to avoid first name selected as per req @15/09/2021
          //this.selectedConsignee = this.consigneeOptions[0].consigneeName;
          //this.selectedConsigneeId = this.consigneeOptions[0].consigneeId;

          //$("#" + this.pageId + "consigneeName").val(this.consigneeOptions[0].consigneeName);
          //$("#" + this.pageId + "consigneeAddress").val(this.consigneeOptions[0].address);

          //this.isPaidLrOnly = this.consigneeOptions[0].isPaidOnly == null ? false : this.consigneeOptions[0].isPaidOnly;
          //     console.log( this.isPaidLrOnly );
          //                    console.log(this.selectedConsigneeId);
          $("#lrefcconsigneeName").focus();
          ////////////////////////////////////////////////////
          /*if ((this.selectedConsignee != null) && (this.selectedConsignee != "") && (this.selectedConsignee != "ALL")) {

              this.getBangaloreConsigneeCommodityConsignee();
              //this.partyGoodsTypeField.nativeElement.focus();
              window.setTimeout(function () {
                  $("#lrefcpartyCommodity").focus();
              }, 100);
          }*/

        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  //CHG-V-LR:39
  validationGetConsigneeDetailsBasedOnSelectType(openPopupForAadhar) {
    let enteredNumber = $("#" + this.pageId + "consigneeGSTNumber").val().toString().trim().replace(/\s+/g, '');
    this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();

    if ((this.selectedSearchBy === 'gst' && !this.gstValidation.test(enteredNumber))) {
      swal("Invalid", "Invalid GST Number", "warning");
    } else if (this.selectedSearchBy === 'pan' && !this.panValidation.test(enteredNumber)) {
      swal("Invalid", "Invalid PAN Number", "warning");
    } else if (this.selectedSearchBy === 'aadhar' && (enteredNumber.length !== 12 || !/^[2-9][0-9]{11}$/.test(enteredNumber))) {
      swal("Invalid", "Invalid Aadhar Number", "warning");
    } else {
      this.selectedconsigneeGSTNumber = enteredNumber;
      this.getConsigneeDetails(openPopupForAadhar);
    }
  }

  ///For GoodsType On Loading Get the Data
  getLrEntrylistBoxCommodityInfo() {
    this.goodsCommdityRateDto.companyId = this.userDataDtoReturnSession.companyId;
    this.goodsCommdityRateDto.mode = "lrEdit";
  }


  getLrEntrylistBoxCommodity = () => {
    //        this.showGoodsType=true;
    this.getLrEntrylistBoxCommodityInfo();
    this.masterReadService.getGoodsTypeCommdity(this.goodsCommdityRateDto).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Data Found for Goods Type",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.controlGoodsType.reset();
          this.goodsTypeCommdityOptions = [];
          this.goodsTypeTA = [];
        } else {

          ///Pending
          this.controlGoodsType.reset();
          this.goodsTypeCommdityOptions = response;

          //                    console.log(response.length);
          //                    console.log('first '+response.length);
          //this.goodsCommdityRateDtoAdd.multipleCommodity = "Add New";
          //this.goodsTypeCommdityOptions.push(this.goodsCommdityRateDtoAdd);

          this.goodsTypeTA = [];
          for (let i = 0; i < this.goodsTypeCommdityOptions.length; i++) {
            this.goodsTypeTA.push(this.goodsTypeCommdityOptions[i].multipleCommodity);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  ////For getting Destinations List
  getDestinationDetailsList() {
    this.lrDtoDest.branch = this.userDataDtoReturnSession.office;
    this.lrDtoDest.mode = "LrForm";
    this.lrDtoDest.companyId = this.userDataDtoReturnSession.companyId;
  }
  getDestinationDetails = () => {
    this.getDestinationDetailsList();
    this.masterReadService.getDestinationForLREntryForm(this.lrDtoDest).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Destination Records Found",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.controlDestination.reset();
          this.destinationOptions = [];
          this.destinationTA = [];
        } else {
          // having issue due to different dto
          this.controlDestination.reset();
          this.destinationOptions = response;
          this.destinationTA = [];
          for (let i = 0; i < this.destinationOptions.length; i++) {
            this.destinationTA.push(this.destinationOptions[i].listOfDestination);
          }
        }
        $("#lrefcdestination").focus();
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  ///On Selection Of Consignee name

  //  rowSelectedConsignee( consigneeName ) {
  //
  //        this.selectedConsignee = consigneeName.consigneeName;
  //        this.selectedConsigneeId = consigneeName.consigneeId;
  //        $( "#consigneeAddress" ).val( consigneeName.address );
  //
  //        if ( this.selectedConsignee != null && this.selectedConsignee == "Add New" ) {
  //            this.lblNewConsignee = true;
  //
  //            //pending adding new Consignee is Pending
  //        } else {
  //            if ( ( this.selectedConsignee != null ) && ( this.selectedConsignee != "" ) && ( this.selectedConsignee != "ALL" ) ) {
  //                this.getBangaloreConsigneeCommodityConsignee();
  //                this.partyGoodsTypeField.nativeElement.focus();
  //            }
  //        }
  //    }
  //    rowSelectedConsignee( consigneeName, content10 ) {
  //
  //        this.selectedConsignee = consigneeName.consigneeName;
  //        this.selectedConsigneeId = consigneeName.consigneeId;
  //        $( "#consigneeAddress" ).val( consigneeName.address );
  //        // basha Pending ispaidLr
  //        this.isPaidLrOnly = consigneeName.isPaidOnly;
  //        console.log( this.isPaidLrOnly );
  //
  //        if ( this.selectedConsignee != null && this.selectedConsignee == "Add New" ) {
  //            //            this.lblNewConsignee = true;
  //            this.modalService.open( content10, { centered: true } );
  //            this.setValueForConsignee();
  //            $( "#newConsignee" ).focus();
  //
  //            //pending adding new Consignee is Pending
  //        } else {
  //            if ( ( this.selectedConsignee != null ) && ( this.selectedConsignee != "" ) && ( this.selectedConsignee != "ALL" ) ) {
  //                this.getBangaloreConsigneeCommodityConsignee();
  //                //this.partyGoodsTypeField.nativeElement.focus();
  //                window.setTimeout( function() {
  //                    $( "#partyCommodity" ).focus();
  //                }, 100 );
  //            }
  //        }
  //    }

  rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent, content10) {
    this.modelConsigneeName = e.item;
    console.log('test');
    console.log(this.modelConsigneeName);
    this.selectedConsignee = this.modelConsigneeName.consigneeName;
    this.selectedConsigneeId = this.modelConsigneeName.consigneeId;
    this.consigneeIdRateId = 0;

    this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
    let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
    console.log(this.selectedconsigneeGSTNumber.toUpperCase() + "-" + MainAdminStation);

    //CHG-V-LR:16
    this.isDefaultCftFlowDisableForConsignee = this.modelConsigneeName.disableDefaultCft;

    console.log('src block');
    console.log(this.modelConsigneeName.srcAlertMessage);
    if (this.modelConsigneeName.isSrcBlock) {
      swal("No Allowed", this.modelConsigneeName.srcAlertMessage, "warning");
      window.setTimeout(function () {
        this.selectedConsigneeName = null;
        $("#lrefcconsigneeName").val('');
      }, 100);
    }
    //temp blocked Asrar@151124

    else if (this.selectedconsigneeGSTNumber.toUpperCase() == '32AAFFS7232R1ZR' && (MainAdminStation != null
      && (MainAdminStation == 'gujarat' || MainAdminStation == 'mumbai'))) {
      swal("No Allowed", 'Booking blocked, please contact Admin!', "warning");
      window.setTimeout(function () {
        this.selectedConsigneeName = null;
        $("#lrefcconsigneeName").val('');
      }, 100);
    }
    else {
      $("#" + this.pageId + "consigneeAddress").val(this.modelConsigneeName.address);

      if (this.modelConsigneeName.mobileNumber != null) {
        var splitConsigneeMobile = this.modelConsigneeName.mobileNumber.split("#");
        // $("#" + this.pageId + "consigneeMobNoExist").val(splitConsigneeMobile[0]);
        $("#" + this.pageId + "consigneeMobNoExist").val(this.modelConsigneeName.mobileNumber);
      }

      // basha Pending ispaidLr
      this.isPaidLrOnly = this.modelConsigneeName.isPaidOnly;
      //   console.log( this.isPaidLrOnly,this.modelConsigneeName.consigneeId,this.modelConsigneeName.consigneeName);

      if (this.selectedConsignee != null && this.selectedConsignee == "Add New") {
        //            this.lblNewConsignee = true;
        this.modalService.open(content10, { centered: true });
        //CHG-V-LR:50
        if ($("#" + this.pageId + "searchBy").val() == "pan") {
          this.consigneePopupLabel = "Pan Number";
          this.validatePanNoFieldForGetDetails();
        } else {
          this.consigneePopupLabel = "Aadhar Number";
        }
        this.setValueForConsignee();
        //$("#" + this.pageId + "newConsignee").focus();

        //pending adding new Consignee is Pending
      } else {
        if ((this.selectedConsignee != null) && (this.selectedConsignee != "") && (this.selectedConsignee != "ALL")) {
          this.getBangaloreConsigneeCommodityConsignee();
          this.getAllCommodityAsPerState();
          //this.partyGoodsTypeField.nativeElement.focus();
          window.setTimeout(function () {
            $("#lrefcpartyCommodity").focus();
          }, 100);
        }

        this.getConsigneeRateDetails();

        //CHG-V-LR:01
        if (this.consignorRateFlow) {
          this.getConsignorRateDetails();
        }
        this.validateFOVRate();
        this.sum();
      }
    }

    //CHG-V-LR:15
    this.validateDefaultCFTFlowForDestLessParty();
  }

  //For getting the main Commodity of Consignee 

  getBangaloreConsigneeCommodityConsigneeInfo() {
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.stateByDest = this.commaSplitDest[1];
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();
    this.enteredSource = $("#" + this.pageId + "source").val();

    this.partyDtoForConsignee = new PartyMasterDto();
    this.partyDtoForConsignee.consigneeName = this.selectedConsignee;
    this.partyDtoForConsignee.consigneeId = this.selectedConsigneeId;
    //this.partyDtoForConsignee.mode = "bangaloreSpecificConsigneeCommdity";
    this.partyDtoForConsignee.mode = "bangaloreAllCommodityLREntry";
    this.partyDtoForConsignee.city = this.destSplit;
    this.partyDtoForConsignee.state = this.stateByDest;
    this.partyDtoForConsignee.gstNoConsignee = this.selectedconsigneeGSTNumber;
    this.partyDtoForConsignee.subStation = this.selectedAgentName;
    this.partyDtoForConsignee.companyId = this.userDataDtoReturnSession.companyId;
    this.partyDtoForConsignee.mainStation = this.enteredSource;
    this.partyDtoForConsignee.consignorId = this.selectedConsignorId;

    let enterBookingDate = null;
    enterBookingDate = $("#" + this.pageId + "bookingDate").val();
    this.enteredBookingDate = this.datePipe.transform(enterBookingDate, "yyyy-MM-dd");
    this.partyDtoForConsignee.entryDate = this.enteredBookingDate;
  }


  getBangaloreConsigneeCommodityConsignee = () => {
    this.getBangaloreConsigneeCommodityConsigneeInfo();
    this.masterReadService.getCityMainCommodityDetailsV2(this.partyDtoForConsignee).subscribe(
      (response) => {
        this.bangaloreConsigneeCommdityOptions = '';
        this.partyCommodityTA = [];
        if (response.length > 0) {
          this.bangaloreConsigneeCommdityOptions = response;
          //this.consigneeComOptions.multipleCommodity = "Add New";
          //this.bangaloreConsigneeCommdityOptions.push(this.consigneeComOptions);

          for (let i = 0; i < this.bangaloreConsigneeCommdityOptions.length; i++) {
            this.partyCommodityTA.push(this.bangaloreConsigneeCommdityOptions[i].multipleCommodity);
          }

        }
        //commented to avoid commodity issue since only state wise commodity should show in LR entry@23/11/2021
        // else {
        //     this.statusOfCommodity = "forSpecificConsignee";
        //     this.getBangaloreAllCommodityConsignee();
        // }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  }

  getAllCommodityAsPerState = () => {
    this.getBangaloreConsigneeCommodityConsigneeInfo();
    this.masterReadService.getCityMainCommodityDetails(this.partyDtoForConsignee).subscribe(
      (response) => {
        this.allCommdityStateOptions = '';
        this.mainCommodityTA = [];
        if (response.length > 0) {
          this.allCommdityStateOptions = response;
          for (let i = 0; i < this.allCommdityStateOptions.length; i++) {
            this.mainCommodityTA.push(this.allCommdityStateOptions[i].multipleCommodity);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  }

  getBangaloreAllCommodityConsigneeInfo() {

    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.stateByDest = this.commaSplitDest[1];
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();
    this.enteredSource = $("#" + this.pageId + "source").val();

    this.partyDtoForConsigneeAllCom = new PartyMasterDto();

    this.partyDtoForConsigneeAllCom.mode = "bangaloreAllCommodityLREntry";
    this.partyDtoForConsigneeAllCom.state = this.stateByDest;
    this.partyDtoForConsigneeAllCom.subStation = this.selectedAgentName;
    this.partyDtoForConsigneeAllCom.consigneeId = this.selectedConsigneeId;
    this.partyDtoForConsigneeAllCom.companyId = this.userDataDtoReturnSession.companyId;
    this.partyDtoForConsigneeAllCom.mainStation = this.enteredSource;
    this.partyDtoForConsigneeAllCom.city = this.destSplit;
  }


  //this method is not in use
  getBangaloreAllCommodityConsignee = () => {

    this.getBangaloreAllCommodityConsigneeInfo();
    this.masterReadService.getCityMainCommodityDetailsV2(this.partyDtoForConsigneeAllCom).subscribe(
      (response) => {
        if (response.length == 0) {
          //                    alert( '' );
          //                    swal( {
          //                        title: "Warning",
          //                        text: "No Commodity Records Found",
          //                        icon: "warning",
          //                        closeOnClickOutside: false,
          //                        closeOnEsc: false,
          //                    } );
        } else {

          if (this.statusOfCommodity == "forSpecificConsignee") {
            ///here setting the values in Main Commodity
            this.bangaloreConsigneeCommdityOptions = '';
            this.partyCommodityTA = [];
            this.bangaloreConsigneeCommdityOptions = response;

            for (let i = 0; i < this.bangaloreConsigneeCommdityOptions.length; i++) {
              this.partyCommodityTA.push(this.bangaloreConsigneeCommdityOptions[i].multipleCommodity);
            }

          } else if (this.statusOfCommodity == "forGlobalCommodityListBng") {
            ///here setting the values in Goods Type
            this.goodsTypeCommdityOptions = response;
            //                        console.log('secondi'+response.length);

            this.goodsTypeTA = [];
            for (let i = 0; i < this.goodsTypeCommdityOptions.length; i++) {
              this.goodsTypeTA.push(this.goodsTypeCommdityOptions[i].multipleCommodity);
            }

          }



        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  /// for getting Consignee Address For btn change of Consignee Address
  changeAddress() {
    this.getConsigneeAddress();
  }

  getConsigneeAddressInfo() {
    this.selectedConsignee = $("#" + this.pageId + "consigneeName").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedConsigneeAddress = $("#" + this.pageId + "consigneeAddress").val();
    this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.stateByDest = this.commaSplitDest[1];
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();

    this.consigneeAddressPartyDto = new PartyMasterDto();
    this.consigneeAddressPartyDto.destination = this.destSplit;
    this.consigneeAddressPartyDto.gstNoConsignee = this.selectedconsigneeGSTNumber;
    this.consigneeAddressPartyDto.consigneeName = this.selectedConsignee;

    this.consigneeAddressPartyDto.address = this.selectedConsigneeAddress
    this.consigneeAddressPartyDto.mode = "updateAddressGST";
    this.consigneeAddressPartyDto.consigneeId = this.selectedConsigneeId;
    this.consigneeAddressPartyDto.companyId = this.userDataDtoReturnSession.companyId;
  }


  getConsigneeAddress = () => {
    this.getConsigneeAddressInfo();
    this.masterReadService.getConsigneeAddress(this.consigneeAddressPartyDto).subscribe(
      (response) => {
        if (response.length == 0) {
          //                    alert( '' );
          swal({
            title: "Failure",
            text: "Ooops..Problem occur while updating Consignee Address..",
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {

          ///Pending

          this.consigneeAddressRet = response;

          //                   console.log(this.consigneeAddressRet);
          if (this.consigneeAddressRet.status == 'success') {
            swal({
              title: "Success",
              text: "Address updated successfully",
              icon: "success",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          } else {
            swal({
              title: "Error",
              text: "Address updation failed",
              icon: "error",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          }


        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };


  //    partyGoodsTypes(): void {
  //        this.subCommodityField.nativeElement.focus();
  //        this.showMainCommodity = true;
  //    }

  ///For Selecting the Main Commodity
  /* rowSelectedPartyCom( commodity ) {
      this.selectedPartyCommodity = commodity.multipleCommodity;
      //        console.log(this.selectedPartyCommodity);
      if ( this.selectedPartyCommodity != null && this.selectedPartyCommodity == "Add New" ) {
          this.showGoodsType = true;
          this.statusOfCommodity = "forGlobalCommodityListBng";
          this.getBangaloreAllCommodityConsignee();
          this.goodsTypeField.nativeElement.focus();

      } else {
          this.showGoodsType = false;
          this.subCommodityField.nativeElement.focus();
      }

  }*/

  //By Kamil
  //    rowSelectedPartyCom( commodity, e ) {
  //        if ( e.keyCode == 13 ) {
  //            this.selectedPartyCommodity = commodity;
  //            //        console.log(this.selectedPartyCommodity);
  //            if ( this.selectedPartyCommodity != null && this.selectedPartyCommodity == "Add New" ) {
  //                this.showGoodsType = true;
  //                this.goodsTypeCommdityOptions = '';
  //                this.goodsTypeTA = [];
  //                this.statusOfCommodity = "forGlobalCommodityListBng";
  //                this.getBangaloreAllCommodityConsignee();
  //                //this.goodsTypeField.nativeElement.focus();
  //                window.setTimeout( function() {
  //                    $( "#goodsType" ).focus();
  //                }, 100 );
  //            } else {
  //                this.showGoodsType = false;
  //                //this.subCommodityField.nativeElement.focus();
  //                window.setTimeout( function() {
  //                    $( "#subCommodity" ).focus();
  //                }, 100 );
  //            }
  //        }
  //    }

  ///for selecting the Goods Type

  /* rowSelectedGooodsType( commodity ) {
      this.selectedGoodsTypeCommodity = commodity.multipleCommodity;

      if ( this.selectedGoodsTypeCommodity != null && this.selectedGoodsTypeCommodity == "Add New" ) {
          this.showNewCommodity = true;
          this.newCommodityField.nativeElement.focus();
      } else {
          this.showNewCommodity = false;
          this.subCommodityField.nativeElement.focus();
      }

  }*/
  //By Kamil

  rowSelectedGooodsType(e) {
    if (e.keyCode == 13) {
      this.selectedGoodsTypeCommodity = $("#" + this.pageId + "goodsType").val();
      if (this.selectedGoodsTypeCommodity != null && this.selectedGoodsTypeCommodity == "Add New") {
        this.showNewCommodity = true;
        //this.newCommodityField.nativeElement.focus();
        window.setTimeout(function () {
          $("#lrefcnewCommodity").focus();
        }, 100);
      } else {
        this.showNewCommodity = false;
        window.setTimeout(function () {
          $("#lrefcsubCommodity").focus();
        }, 100);

        //window.setTimeout( function() {
        //  $( "#actualWeight" ).focus();
        //}, 100 );
        //this.subCommodityField.nativeElement.focus();
      }
    }
  }
  ///Checkbox of Auto Lr On Click
  toggleEditableForAutoLr(event) {
    if (event.target.checked) {
      this.getAutoLrNo();
      this.viewAutoLr = true;
      this.viewByAutoLr = true;
      this.viewAutoLrHold = false;
      this.checkAutoLr = true;
      this.clearInputFields();
      $("#" + this.pageId + "destination").val('');
      this.showEDeclarationId = false;
      $("#" + this.pageId + "doorDeliveryMode").val('No');
      this.doorDeliveryYes = false;
      this.doorDeliveryYesddAmt = false;
      if (this.restrictUserGodownList) {
        this.showLoader = false;
      }
      this.userDataDto = new UserDataDto();
      this.viewPincode = false;
      this.enteredPincode = null;
      this.isLRReadOnly = true;
      // $("#lrefcentryPinCode").focus();
      $("#lrefceWayBillNo").focus();
    } else {
      this.viewAutoLr = true;
      this.viewByAutoLr = false;
      this.viewAutoLrHold = true;
      this.checkAutoLr = false;
      this.clearInputFields();
      $("#" + this.pageId + "destination").val('');
      this.showEDeclarationId = false;
      $("#" + this.pageId + "doorDeliveryMode").val('No');
      this.doorDeliveryYes = false;
      this.doorDeliveryYesddAmt = false;
      if (!this.restrictUserGodownList) {
        this.showLoader = true;
      }
      this.userDataDto = new UserDataDto();
      this.viewPincode = false;
      this.enteredPincode = null;
      this.isLRReadOnly = false;
    }
  }


  getAutoLrNoInfo() {
    this.lrtDtoForAutoLr = new LRDto();
    this.lrtDtoForAutoLr.mainstation = this.userDataDtoReturnSession.mainAdminStation;
    this.lrtDtoForAutoLr.companyId = this.userDataDtoReturnSession.companyId;
    $("#" + this.pageId + "autoLrNumber").val('');
  }


  getAutoLrNo = () => {

    this.getAutoLrNoInfo();
    this.masterReadService.getAutomaticLrNo(this.lrtDtoForAutoLr).subscribe(
      (response) => {
        if (response.length == 0) {
          //                    alert( '' );
          swal({
            title: "Warning",
            text: "No Lr Records Found",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          $("#" + this.pageId + "autoLrNumber").val('');
        } else {
          this.autoLrFieldValue = '';
          this.autoLrRet = response;
          this.autoLrFieldValue = this.autoLrRet[0].lrNumber;
          //                    console.log( 'Hereaa :' + this.autoLrFieldValue );
          $("#" + this.pageId + "autoLrNumber").val(this.autoLrRet[0].lrNumber);
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };
  //check Box of Auto Lr Hold When Clicked
  toggleEditableForAutoLrHold(event) {
    if (event.target.checked) {
      this.getAutomatedLrHoldDetails();
      this.userName = this.userDataDtoReturnSession.userId;
      this.viewAutoLr = false;
      this.viewByAutoLr = true;
      this.viewAutoLrHold = true;
      this.checkAutoLrHold = true;
      this.clearInputFields();
      $("#" + this.pageId + "destination").val('');
      this.showEDeclarationId = false;
      $("#" + this.pageId + "doorDeliveryMode").val('No');
      this.doorDeliveryYes = false;
      this.doorDeliveryYesddAmt = false;
      if (this.restrictUserGodownList) {
        this.showLoader = false;
      }
      this.userDataDto = new UserDataDto();
      this.viewPincode = false;
      this.enteredPincode = null;
      this.isLRReadOnly = true;
      $("#lrefcentryPinCode").focus();

    } else {
      this.viewAutoLr = true;
      this.viewByAutoLr = false;
      this.viewAutoLrHold = true;
      this.checkAutoLrHold = false;
      this.clearInputFields();
      $("#" + this.pageId + "destination").val('');
      this.showEDeclarationId = false;
      $("#" + this.pageId + "doorDeliveryMode").val('No');
      this.doorDeliveryYes = false;
      this.doorDeliveryYesddAmt = false;
      if (!this.restrictUserGodownList) {
        this.showLoader = true;
      }
      this.userDataDto = new UserDataDto();
      this.viewPincode = false;
      this.enteredPincode = null;
      this.isLRReadOnly = false;
    }
  }

  //for modal starts For Lr Hold Details
  getAutomatedLrHoldDetails(): void {

    const dialogRef = this.dialog.open(AutomatedLrHoldLrEntryPopupComponent, {

      data: { listOfDataToPass: this.userName }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getLrHoldDatafromLocalStorage();// Pending
      // this.router.navigate(['.'], { relativeTo: this.route });
    });

  }

  getLrHoldDatafromLocalStorage() {
    ///pending
    this.lrHold = localStorage.getItem('lrEntry');
    //        console.log( 'hold :' + this.lrHold );
    localStorage.clear();
    if (this.lrHold != null && this.lrHold != '') {
      $("#" + this.pageId + "autoLrNumber").val(this.lrHold);
      this.lrCheck = true;
      this.noOfArticlesField.nativeElement.focus();
    }
  }

  ////calculation Starts From here

  ///CalculateDDAmountRange
  calculateDDAmountRangeToValidate() {
    this.enteredChargeWeight = $("#" + this.pageId + "chargedWeight").val();
    this.ddAmtRange = 0.0;
    this.enterdChgWgt = this.enteredChargeWeight;
    this.ddAmtSys = (this.userDataDtoReturnSession.ddMinPercOnLr * this.enterdChgWgt);
    //        console.log( '1 : ' + this.userDataDtoReturnSession.ddMinPercOnLr );
    //        console.log( '2 : ' + this.enterdChgWgt );
    //        console.log( '3 : ' + this.ddAmtSys );
    if (this.ddAmtSys < this.userDataDtoReturnSession.ddMinAmtOnLr) {
      //            console.log( '4' + this.ddAmtSys );
      this.ddAmtRange = this.userDataDtoReturnSession.ddMinAmtOnLr;
    } else {
      //            console.log( '5' + this.ddAmtSys );
      this.ddAmtRange = this.ddAmtSys;
    }
    //        console.log( 'ddRange' + this.ddAmtRange );
    return this.ddAmtRange;
  }



  fovRateSetupValidationInfo() {
    this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.lrtDtoForFovRate = new LRDto();
    this.lrtDtoForFovRate.companyId = this.userDataDtoReturnSession.companyId;
    this.lrtDtoForFovRate.consigneeId = this.selectedConsigneeId;
    this.lrtDtoForFovRate.consignorId = this.selectedConsignorId;
    this.lrtDtoForFovRate.destination = this.destSplit;
    this.lrtDtoForFovRate.commodityName = this.selectedPartyCommodity;

    if (this.viewByAutoLr == true || this.lrCheck == true) {
      this.lrtDtoForFovRate.source = this.userDataDtoReturnSession.mainStation;
    } else {
      this.lrtDtoForFovRate.source = this.selectedAgentName;
    }
  }


  fovRateSetupValidation = () => {

    this.fovRateSetupValidationInfo();
    this.masterReadService.validateFOVRateWhileLREntry(this.lrtDtoForFovRate).subscribe(
      (response) => {

        this.fovRateValidateRet = response;
        if (response.length > 0) {
          this.isFOVRateSet = true;
          this.fovChg = this.fovRateValidateRet[0].fovPerc;
        } else {
          this.isFOVRateSet = false;
          this.fovChg = 0.0;
          //                    console.log( 'here is the data' );
        }
        this.calculateRiskCharge();
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  ///Calculating the risk charge
  calculateRiskCharge() {
    ///Double value pending for    this.goodsValue
    this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val();
    this.goodsValue = this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue == '' ? 0.0
      : this.enteredGoodsValue;
    this.riskPerc = 0.0, this.riskChg = 0.0;
    $("#" + this.pageId + "fov").val('');
    //CHG-V-LR:21
    //temp commented // enabled at 29/07/2024
    if (this.consignorRateFlowSet) {
      this.riskPerc = this.consignorRateFov;
    } else {
      if (this.isFOVRateSet) {
        this.riskPerc = this.fovChg;
      } else {
        /// we are recevig the fovPerc form backend by basha 
        this.riskPerc = this.validateAndReturnPercV2();
        //            console.log( 'the Risk perc :' + this.riskPerc )
        // new logic based on date 24/10/2019
      }
    }

    this.riskChg = (this.goodsValue * this.riskPerc) / 100;
    //                console.log( 'this is the risk chrg :' + this.riskChg );
    $("#" + this.pageId + "fov").val(Math.round(this.riskChg));
    this.sum();
  }

  //    validateAndReturnPercV2() {
  //        this.riskPerc = 0.0;
  //        this.riskPerc = this.userDataDtoReturnSession.fovPerc;
  //        return this.riskPerc;
  //    }
  validateAndReturnPercV2() {
    this.riskPerc = 0.0;
    let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
    let office = this.userDataDtoReturnSession.office.toLowerCase();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();

    if (this.selectedDestination != null && this.selectedDestination != '') {

      this.commaSplitDest = this.selectedDestination.split("-");
      this.destSplit = this.commaSplitDest[0];
      this.selectedDestination = this.destSplit.toLowerCase();
      this.selectedDestination = this.selectedDestination.trim();
    } else {
      this.selectedDestination = this.selectedDestination;
    }
    //  console.log( this.selectedDestination );
    //CHG-V-LR:17
    if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination == 'trivandrum') {
      this.riskPerc = 0.10;
    } else if (office != null && (office == 'jagadhri')) {
      this.riskPerc = 0.0;
    } else if (MainAdminStation != null
      && (MainAdminStation == 'gujarat')) {
      console.log('validateAndReturnPercV2');
      console.log(MainAdminStation);
      console.log(office);
      // riskPerc = 0.1;
      // changed on 20/07/2019 w.e.f 21/07/2019

      // @09/09/2020
      // from Gujarat to chennai - no fov as per Manoj mail
      // also for Jamnagar (Dhared - JM,Udyog Nagar - JM) fov 0.1% except
      // chennai
      if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination == 'chennai') {
        this.riskPerc = 0.0;
      } else if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination == 'madurai') {
        if (office != null
          && ((office == 'balotra') || (office == 'bhilwara') || (office == 'pali') || (office == 'jaipur vki') || (office == 'jaipur sc road') || (office == 'sanganer')
            || (office == 'jodhpur') || (office == 'udaipur mia') || (office == 'udaipur subcity') || (office == 'udaipur town hall') || (office == 'savarkundla'))) {
          //for rajasthan station fov as per norm and for gujarat fov 0 for Madurai @07/12/2021
          this.riskPerc = 0.05;
        } else {
          this.riskPerc = 0.0;
        }
      } else if (office != null
        && ((office == 'dhared - jm') || (office == 'udyog nagar - jm'))) {
        this.riskPerc = 0.1;
      } else if (office != null
        && ((office == 'balotra') || (office == 'bhilwara') || (office == 'pali') || (office == 'jaipur vki') || (office == 'jaipur sc road') || (office == 'sanganer')
          || (office == 'jodhpur') || (office == 'udaipur mia') || (office == 'udaipur subcity') || (office == 'udaipur town hall') || (office == 'savarkundla'))) {
        // revised as per Manoj mail @20/03/2021
        console.log('validateAndReturnPercV2 - inside');
        console.log(office);
        this.riskPerc = 0.05;
      } else if (office != null && office == 'saroli - st') {
        this.riskPerc = 0.05;
      } else {
        //this.riskPerc = 0.2;
        // changed on 06/06/2021 w.e.f 07/06/2021
        this.riskPerc = 0.25;
      }

    } else if (MainAdminStation != null
      && MainAdminStation == 'mumbai') {
      this.riskPerc = 0.25;
    } else if (MainAdminStation != null
      && MainAdminStation == 'delhi') {
      //fov 0 for DINA NAGAR as per Sadakath req @18/02/2022
      if (this.selectedAgentName != null && this.selectedAgentName != '' && this.selectedAgentName.trim() == 'DINA NAGAR') {
        this.riskPerc = 0;
      } else {
        this.riskPerc = 0.3;
      }
    } else if (MainAdminStation != null
      && MainAdminStation == 'nagpur') {
      this.riskPerc = 0.0;
    } else if (MainAdminStation != null
      && MainAdminStation == 'rajasthan') {
      // this condition will not execute since there is no
      // mainadminstation
      // called Rajasthan, this also comes under Gujarat. @20/03/2021

      // @09/09/2020
      // from RAJASTHAN to chennai - no fov as per Manoj mail
      if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination == 'chennai') {
        this.riskPerc = 0.0;
      } else {
        this.riskPerc = 0.2;
      }
    }
    //for bng booking fov zero to all dest
    if (MainAdminStation != null
      && MainAdminStation == 'bangalore bkg head ofc') {
      // this.riskPerc = 0.2;
      // changed to zero on 22/01/2021 as per Sadakath mail
      this.riskPerc = 0.0;
    }
    //      console.log( this.riskPerc );

    //temp to generate old lrs, must remove
    //this.riskPerc = 0.0;

    return this.riskPerc;
  }

  sum() {

    this.baseFreight = $("#" + this.pageId + "baseFreight").val();
    this.hamali = $("#" + this.pageId + "hamali").val();
    this.gcCharge = $("#" + this.pageId + "gcCharge").val();
    this.lcCharge = $("#" + this.pageId + "lcCharge").val();
    this.bcCharge = $("#" + this.pageId + "bcCharge").val();
    this.aocCharge = $("#" + this.pageId + "aocCharge").val();
    this.others = $("#" + this.pageId + "others").val();
    this.ddAmt = $("#" + this.pageId + "ddAmt").val();
    this.enteredRiskChg = $("#" + this.pageId + "fov").val();
    this.entereCODCharge = this.setCodCharge;
    //        console.log( this.baseFreight );
    this.totalAmt = (+(this.baseFreight == null ? 0.0 : this.baseFreight == '' ? 0.0 : this.baseFreight)
      + +(this.hamali == null ? 0.0 : this.hamali == '' ? 0.0 : this.hamali)
      + +(this.gcCharge == null ? 0.0 : this.gcCharge == '' ? 0.0 : this.gcCharge)
      + +(this.lcCharge == null ? 0.0 : this.lcCharge == '' ? 0.0 : this.lcCharge)
      + +(this.bcCharge == null ? 0.0 : this.bcCharge == '' ? 0.0 : this.bcCharge)
      + +(this.aocCharge == null ? 0.0 : this.aocCharge == '' ? 0.0 : this.aocCharge)
      + +(this.others == null ? 0.0 : this.others == '' ? 0.0 : this.others)
      + +(this.ddAmt == null ? 0.0 : this.ddAmt == '' ? 0.0 : this.ddAmt)
      + +(this.enteredRiskChg == null ? 0.0 : this.enteredRiskChg == '' ? 0.0 : this.enteredRiskChg)
      + +(this.entereCODCharge == null ? 0.0 : this.entereCODCharge == '' ? 0.0 : this.entereCODCharge));

    $("#" + this.pageId + "totalAmount").val(this.totalAmt);

    // @Removed AOC calculation as per Manoj req
    // @Dated : 10/08/2017
    // getAocCalculation();

  }

  validAmtWitChgWt() {
    this.paymentType = $("#" + this.pageId + "paymentType").val();
    this.enteredTotalAmt = $("#" + this.pageId + "totalAmount").val();
    this.enteredChargedWeight = $("#" + this.pageId + "chargedWeight").val();
    //pending of double value,message Alert pending 
    //Pending need to check the parsing 
    if (this.paymentType == 'toPay') {
      //            console.log( this.enteredTotalAmt,this.enteredChargedWeight);
      if (parseInt(this.enteredTotalAmt) < parseInt(this.enteredChargedWeight)) {
        swal({
          title: "Alert",
          text: "To pay should not be less than charged weight",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(() => {
          window.setTimeout(function () {
            $("#lrefcprivateMarker").focus();
          }, 300);
        });
      }
    } else {
      if (parseInt(this.enteredTotalAmt) < parseInt(this.enteredChargedWeight)) {

        swal({
          title: "Alert",
          text: "Paid should not be less than charged weight",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(() => {
          window.setTimeout(function () {
            $("#lrefcprivateMarker").focus();
          }, 300);
        });

      }
    }
    this.ratePerChgWgt = (this.enteredTotalAmt)
      / (this.enteredChargedWeight == null ? 0.0 : this.enteredChargedWeight == '' ? 0.0 : this.enteredChargedWeight);
    this.ratePerChgWgt = this.ratePerChgWgt.toFixed(1);
    //        console.log( 'thisi s1:' + this.ratePerChgWgt );
    //        console.log( 'thisi ss:' + this.userDataDtoReturnSession.ratePerChgWgt );

    if (this.ratePerChgWgt > this.userDataDtoReturnSession.ratePerChgWgt) {

      swal({
        title: "Alert",
        text: "Total Amount is More than the Default Set Value.Please check the Freight Info",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        //BY Kamil
        window.setTimeout(function () {
          $("#lrefcprivateMarker").focus();
        }, 300);
      });
    } else {
      //By Basha for focus error i used this code 
      if (this.paymentType == 'toPay') {
        if (this.enteredTotalAmt < this.enteredChargedWeight) {

        } else {
          window.setTimeout(function () {
            $("#lrefcprivateMarker").focus();
          }, 300);
        }
      } else {
        if (parseInt(this.enteredTotalAmt) < parseInt(this.enteredChargedWeight)) {

        } else {
          window.setTimeout(function () {
            $("#lrefcprivateMarker").focus();
          }, 300);
        }
      }
    }



  }

  //for Base Freight
  baseFreightEvent(e) {
    if (e.keyCode == 13) {
      this.baseFreight = $("#" + this.pageId + "baseFreight").val();
      if (this.baseFreight == 0.0 || this.baseFreight == 0) {
        swal({
          title: "Alert",
          text: "Freight Amount can not be zero",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      } else {
        this.sum();
        this.hamaliField.nativeElement.focus();
      }

    }
  }

  baseFreightBlur(e) {
    this.sum();
  }
  //for hamali
  hamaliEvent(e) {
    if (e.keyCode == 13) {
      this.sum();
      this.gcChargeField.nativeElement.focus();
    }
  }

  hamaliBlur(e) {
    this.sum();
  }
  //for gc charge
  gcChargeEvent(e) {
    if (e.keyCode == 13) {
      this.sum();
      this.lcChargeField.nativeElement.focus();
    }
  }

  gcChargeBlur(e) {
    this.sum();
  }
  //for lc charge
  lcChargeEvent(e) {
    if (e.keyCode == 13) {
      this.sum();
      this.bcChargeField.nativeElement.focus();
    }
  }

  lcChargeBlur(e) {
    this.sum();
  }
  //for bc charge
  bcChargeEvent(e) {
    if (e.keyCode == 13) {
      this.sum();
      this.fovField.nativeElement.focus();
    }
  }

  bcChargeBlur(e) {
    this.sum();
  }
  //for fov
  fovEvent(e) {
    if (e.keyCode == 13) {
      this.sum();
      this.aocChargeField.nativeElement.focus();
    }
  }

  fovBlur(e) {
    this.sum();
  }
  //for aoc charge
  aocChargeEvent(e) {
    if (e.keyCode == 13) {
      this.sum();
      this.othersField.nativeElement.focus();
    }
  }

  aocChargeBlur(e) {
    this.sum();
  }
  //for others
  othersEvent(e) {
    if (e.keyCode == 13) {
      this.sum();
      this.validAmtWitChgWt();
      //            this.doorDeliveryModeField.nativeElement.focus();
    }
  }

  othersBlur(e) {
    this.sum();

  }
  //For DD Amt
  ddAmtEvent(e) {
    if (e.keyCode == 13) {
      this.sum();
      if ((this.viewByAutoLr == true) || (this.lrCheck == true)) {
        this.ddAddressField.nativeElement.focus();
      } else {
        if (this.showRiskCharge == true) {
          window.setTimeout(function () {
            $("#lrefccheckboxCOD").focus();
          }, 1000);
        } else {
          window.setTimeout(function () {
            $("#lrefcpaymentType").focus();
          }, 1000);
        }

      }
    }
  }

  ddAmtBlur(e) {
    this.sum();
  }

  //For Goods Value
  goodsValueEvent(e) {
    if (e.keyCode == 13) {
      this.validateFOVRate();
      this.sum();
      // window.setTimeout(function () {
      //     $("#lrefceWayBillValidUptoDate").focus();
      // }, 1000);
      $("#lrefceWayBillValidUptoDate").focus();
    }
  }

  goodsValueBlur(e) {
    //        console.log( 'aaaa' );
    this.validateFOVRate();
    this.sum();
  }
  validateFOVRate() {
    this.fovRateSetupValidation();
  }

  actualWeightBlur(e) {
    //CHG-V-LR:01
    if (this.consignorRateFlow) {
      this.getConsignorRateDetails();
    }
    this.validateFOVRate();
    this.sum();
  }

  //For actual Weight
  actualWeightEvent(e) {

    if (e.keyCode == 13) {
      this.actualWeight = $("#" + this.pageId + "actualWeight").val();
      this.maxActWgt = this.userDataDtoReturnSession.maxActWgt;
      //            console.log( this.userDataDtoReturnSession.maxActWgt );
      if (this.actualWeight > this.maxActWgt) {
        //                message is Pending

        swal({
          title: "Alert",
          text: "Actual Weight is Maximum...Please Check the Actual Weight",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(() => {
          this.chargedWeightField.nativeElement.focus();
        });

      } else {
        this.chargedWeightField.nativeElement.focus();
      }
      //CHG-V-LR:01
      if (this.consignorRateFlow) {
        this.getConsignorRateDetails();
      }
      this.validateFOVRate();
      this.sum();

    }
  }


  //For charged Weight


  /* chargedWeightEvent( e ) {
      ///Message Alert Pending allowMultiArtPopUp is Pending
      if ( e.keyCode == 13 ) {
          //            this.showMultiArt = true;
          this.dtTriggerMultiArtTable.next();
          this.actualWeight = $( "#actualWeight" ).val();
          this.chargedWeight = $( "#chargedWeight" ).val();
          this.maxChgWgt = this.userDataDtoReturnSession.maxChgWgt;
//            console.log( this.maxChgWgt );

          if ( parseInt( this.chargedWeight ) < parseInt( this.actualWeight ) ) {
              swal( {
                  title: "Alert",
                  text: "Charged Weight Should Be Equal Or Greater Than Actual Weight",
                  icon: "warning",
                  closeOnClickOutside: false,
                  closeOnEsc: false,
              } ).then(() => {
                  $( "#chargedWeight" ).val( '' );
                  this.chargedWeightField.nativeElement.focus();
              } );

          } else if ( parseInt( this.chargedWeight ) > parseInt( this.maxChgWgt ) ) {

              swal( {
                  title: "Alert",
                  text: "Charged Weight is Maximum...Please Check the Charged Weight",
                  icon: "warning",
                  closeOnClickOutside: false,
                  closeOnEsc: false,
              } ).then(() => {
                  this.allowMultiArtPopUp();
              } );
//                console.log( 'here1' );

          } else {
              //                console.log( 'here2' );
              if ( this.chargedWeight == this.actualWeight ) {
                  this.goodsValueField.nativeElement.focus();
              } else {
                  this.allowMultiArtPopUp();
              }
          }
      }
  } */

  chargedWeightBlur(content7) {
    console.log('chargedWeightBlur');
    console.log(this.multiArtBlurEvent);
    if (this.multiArtBlurEvent) {

      this.chargedWgtValidation(content7);




    }
  }

  chargedWeightEvent(e, content7) {
    if (e.keyCode == 13) {
      //this.chargedWgtValidation(content7);
      this.goodsValueField.nativeElement.focus();
    }
  }

  chargedWgtValidation(content7) {

    this.actualWeight = $("#" + this.pageId + "actualWeight").val();
    this.chargedWeight = $("#" + this.pageId + "chargedWeight").val();
    this.maxChgWgt = this.userDataDtoReturnSession.maxChgWgt;
    if (parseInt(this.chargedWeight) < parseInt(this.actualWeight)) {
      swal({
        title: "Alert",
        text: "Charged Weight Should Be Equal Or Greater Than Actual Weight",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        $("#" + this.pageId + "chargedWeight").val('');
        this.chargedWeightField.nativeElement.focus();
      });

    }

    else if (parseInt(this.chargedWeight) > parseInt(this.maxChgWgt)) {

      swal({
        title: "Alert",
        text: "Charged Weight is Maximum...Please Check the Charged Weight",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.allowMultiArtPopUp(content7);
      });
    } else {
      if (this.chargedWeight == this.actualWeight) {
        this.goodsValueField.nativeElement.focus();
        this.multiArtTableDataList = [];
        this.clearMulArt();
      } else {
        this.allowMultiArtPopUp(content7);
      }
    }
    //CHG-V-LR:01
    if (this.consignorRateFlow) {
      this.getConsignorRateDetails();
    }
    this.validateFOVRate();
    this.sum();

  }


  //    checkChallanNo( e ) {
  //        if ( e.keyCode == 13 ) {
  //            this.enteredChallanNo = $( "#challanNo" ).val();
  //            this.enteredChallanNoAt0 = this.enteredChallanNo.charAt( 0 );
  //            this.mainAdminStationGet = this.userDataDtoReturnSession.mainAdminStation;
  //            //            console.log( this.mainAdminStationGet );
  //
  //            if ( this.mainAdminStationGet != null && this.mainAdminStationGet == 'DELHI' ) {
  //
  //                if ( this.enteredChallanNo != null && this.enteredChallanNo != '' ) {
  //                    if ( this.enteredChallanNoAt0 == 0 ) {
  //                        swal( {
  //                            title: "Alert",
  //                            text: "Challan No Should Not Be Starts With 0 ",
  //                            icon: "warning",
  //                            closeOnClickOutside: false,
  //                            closeOnEsc: false,
  //                        } ).then(() => {
  //                            $( "#challanNo" ).val( '' );
  //                            this.challanNoField.nativeElement.focus();
  //                        } );
  //
  //                    } else {
  //                        this.ChallanNumberChk();
  //                    }
  //                } else {
  //                    this.ChallanNumberChk();
  //                }
  //            }
  //            else {
  //                this.agentNameField.nativeElement.focus();
  //            }
  //
  //        }
  //    }
  checkChallanNo(e) {
    if (e.keyCode == 13) {
      this.enteredChallanNo = $("#" + this.pageId + "challanNo").val();
      this.enteredChallanNoAt0 = this.enteredChallanNo.charAt(0);
      this.mainAdminStationGet = this.userDataDtoReturnSession.mainAdminStation;
      let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
      //            console.log( this.mainAdminStationGet );

      if (MainAdminStation != null && MainAdminStation == 'delhi') {

        if (this.enteredChallanNo != null && this.enteredChallanNo != '') {
          if (this.enteredChallanNoAt0 == 0) {
            swal({
              title: "Alert",
              text: "Challan No Should Not Be Starts With 0 ",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then(() => {
              $("#" + this.pageId + "challanNo").val('');
              this.challanNoField.nativeElement.focus();
            });

          } else {
            this.ChallanNumberChk();
          }
        }
      } else {
        this.ChallanNumberChk();
      }
    }
  }
  ChallanNumberChkInfo() {
    this.enteredChallanNo = $("#" + this.pageId + "challanNo").val();
    this.lrtDtoForChallan = new LRDto();
    this.lrtDtoForChallan.challanNo = this.enteredChallanNo;
    this.lrtDtoForChallan.companyId = this.userDataDtoReturnSession.companyId;
  }


  ChallanNumberChk = () => {

    this.ChallanNumberChkInfo();
    this.masterReadService.getAgentNameForChallanNo(this.lrtDtoForChallan).subscribe(
      (response) => {
        if (response.length == 0) {
          //                    alert( '' );
          swal({
            title: "Warning",
            text: "No  Records Found For this Challan Number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.lrtDtoForChallanRet = response;
          //                    console.log( this.lrtDtoForChallanRet );
          //                    this.agentDtoAll.subStation =this.lrtDtoForChallanRet.agentName;
          if (this.lrtDtoForChallanRet.agentName != null) {
            this.challanAgentName = this.lrtDtoForChallanRet.agentName;
            this.challanTotLrsField.nativeElement.focus();

          } else {
            swal({
              title: "Alert",
              text: "This Challan Number: " + this.enteredChallanNo + " not exists in Challan Issue ",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,

            }).then(() => {
              $("#" + this.pageId + "challanNo").val('');
              $("#" + this.pageId + "agentName").val('');
              this.controlAgentName.reset();
              this.challanNoField.nativeElement.focus();
            });
          }

        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  checkLrNo(e, el) {
    if (e.keyCode == 13) {
      this.lrNumberChk();
    }
  }


  //For Multiple Articles pop 
  open7(content7) {
    // this.modalService.open(content7, { centered: true, size: 'lg' }).result.then(
    this.modalService.open(content7, { centered: true, windowClass: "myCustomModalClassMultiArt" }).result.then(

      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  //For Multiple PackNature pop 
  open8(content8) {
    this.modalService.open(content8, { centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  multiArtDetailsTable() {
    console.log('inside multiArtDetailsTable dtOptionsMultiArtTable');
    this.dtOptionsMultiArtTable = {
      searching: false,
      processing: true,
      responsive: true,
      "scrollX": true,
      "scrollY": 280,
      "scrollCollapse": true,
      "paging": false,
      "info": false,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
        var articles = api.column(0).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var actWgt = api.column(4).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        var chgWgt = api.column(5).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        $(api.column(0).footer()).html(articles);
        $(api.column(4).footer()).html(actWgt);
        $(api.column(5).footer()).html(chgWgt);
      }
    }
  }
  ///For Multiple Article  Pop without scancode

  addInTable() {
    this.newAttribute = {};
    this.newAttribute.articlesInMultiple = $("#" + this.pageId + "multiArtarticle").val();
    this.newAttribute.length = $("#" + this.pageId + "multiArtlength").val();
    this.newAttribute.breadth = $("#" + this.pageId + "multiArtbreadth").val();
    this.newAttribute.height = $("#" + this.pageId + "multiArtheight").val();
    this.newAttribute.actualWeight = $("#" + this.pageId + "multiArtActWgt").val();
    this.cftRangeApplicableLR = false;
    console.log('addInTable');

    if (!this.viewCftFields) {
      this.newAttribute.chargedWeight = $("#" + this.pageId + "multiArtChgWgt").val();
      this.newAttribute.length = '0';
      this.newAttribute.breadth = '0';
      this.newAttribute.height = '0';
    }

    if (this.newAttribute.articlesInMultiple != '' && this.newAttribute.length != '' && this.newAttribute.breadth != '' && this.newAttribute.height != '' && this.newAttribute.articlesInMultiple != null && this.newAttribute.length != null && this.newAttribute.breadth != null && this.newAttribute.height != null) {
      if (this.newAttribute.actualWeight == '' || this.newAttribute.actualWeight == null || parseInt(this.newAttribute.actualWeight) <= 0) {
        swal({
          title: "Warning",
          text: "Please enter the valid Actual Weight!",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      } else {
        if (this.editSelected == true) {
          this.enterArticles = +this.enterArticles + -this.getArticles;
          this.editSelected = false;
        } else {
          this.editSelected = false;
        }
        this.isBlock = this.chkMulArtSize();
        if (this.isBlock == true) {
          swal({
            title: "Alert",
            text: "Entered Articles Exceeds Actual Articles",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          if (this.viewCftFields) {
            console.log(this.viewCftFields);
            console.log(this.isCftCommodityExemp);
            this.enteredCustomCftValue = $("#" + this.pageId + "customCftValue").val();
            console.log(this.enteredCustomCftValue);
            console.log('consigneeIdRateId :' + this.consigneeIdRateId);
            console.log('consignorIdRateId :' + this.consignorIdRateId);

            if (this.isCftCommodityExemp) {
              console.log('inside isCftCommodityExemp');
              console.log(this.cftCommodityExempChgWgt);
              var articlesInMultiple: any;
              articlesInMultiple = $("#" + this.pageId + "multiArtarticle").val();
              this.newAttribute.chargedWeight = Math.round(articlesInMultiple * this.cftCommodityExempChgWgt);
              this.multipleArtCftAddInTable();
            } else if (this.viewCustomCftFields && this.enteredCustomCftValue != null && this.enteredCustomCftValue > 0) {
              console.log('inside viewCustomCftFields');
              console.log(this.enteredCustomCftValue);
              this.newAttribute.chargedWeight = this.calculateChgWgtUsingCFT(this.enteredCustomCftValue);
              this.multipleArtCftAddInTable();
            } else if ((this.consignorIdRateId != null && this.consignorIdRateId > 0) ||
              (this.consigneeIdRateId != null && this.consigneeIdRateId > 0)) {
              //CHG-V-LR:07
              console.log('inside cft - consignorrate logic');
              //CHG-V-LR:15
              if (this.isDefaultCftFlowDisableForLessParties) {
                console.log('consignorrate logic : inside cft slabs');
                this.newAttribute.chargedWeight = this.calculateChgWgtUsingCFT(this.cftRate);
                //get slabs value
                this.getCFTSlabOfSepcificStation(this.newAttribute.actualWeight, this.newAttribute.chargedWeight, this.newAttribute.articlesInMultiple);
              } else {
                //CHG-V-LR:16
                if (this.isDefaultCftFlowDisableForConsignor || this.isDefaultCftFlowDisableForConsignee) {
                  console.log('consignorrate logic specifc consignee/consignor disable : inside cft slabs');
                  this.newAttribute.chargedWeight = this.calculateChgWgtUsingCFT(this.cftRate);
                  //get slabs value
                  this.getCFTSlabOfSepcificStation(this.newAttribute.actualWeight, this.newAttribute.chargedWeight, this.newAttribute.articlesInMultiple);
                } else {
                  console.log('consignorrate logic :default cft');
                  this.newAttribute.chargedWeight = this.calculateChgWgtUsingCFT(this.cftRate);
                  this.multipleArtCftAddInTable();
                }
              }
            } else {
              console.log('inside cft slabs');
              this.newAttribute.chargedWeight = this.calculateChgWgtUsingCFT(this.cftRate);
              //get slabs value
              this.getCFTSlabOfSepcificStation(this.newAttribute.actualWeight, this.newAttribute.chargedWeight, this.newAttribute.articlesInMultiple);
            }
          } else {
            console.log(this.viewCftFields);
            this.multipleArtCftAddInTable();
          }
        }
      }
    } else {
      swal({
        title: "Warning",
        text: "All Fields Are Mandatory",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }
  clearMulArt() {
    this.newAttribute = {};
    $("#" + this.pageId + "multiArtarticle").val('');
    $("#" + this.pageId + "multiArtlength").val('');
    $("#" + this.pageId + "multiArtbreadth").val('');
    $("#" + this.pageId + "multiArtheight").val('');
    $("#" + this.pageId + "multiArtActWgt").val('');
    $("#" + this.pageId + "multiArtChgWgt").val('');
    this.editTableId = null;
    window.setTimeout(function () {
      $("#lrefcmultiArtarticle").focus();
    }, 100);
    this.viewCftFields = true;
    this.viewCustomCftFields = false;
    this.enteredCustomCftValue = null;
    $("#" + this.pageId + "customCftValue").val('');
  }
  chkMulArtSize() {
    this.isBlock = false;
    this.totArticles = 0;
    this.totArtMultiArt = 0;

    this.totArticles = $("#" + this.pageId + "noOfArticles").val();
    this.multiArtArticle = $("#" + this.pageId + "multiArtarticle").val();
    for (let i = 0; i < this.multiArtTableDataList.length; i++) {
      this.totArtMultiArt = +this.totArtMultiArt + +this.multiArtTableDataList[i].articlesInMultiple;
    }

    this.totArtMultiArt = +this.totArtMultiArt + +this.multiArtArticle;

    if (this.totArtMultiArt > parseInt(this.totArticles)) {
      this.isBlock = true;
      // this.enterArticles = +this.enterArticles + -this.multiArtArticle;
      // if (this.editTableId != null) {
      //     this.multiArtTableDataList.splice(this.editTableId, 1);
      // }
    } else {
      this.isBlock = false;
    }
    return this.isBlock;
  }

  rowSelectedEdit(multipleArtTableData, index) {
    this.editSelected = true;
    this.getArticles = multipleArtTableData.articlesInMultiple;
    $("#" + this.pageId + "multiArtarticle").val(multipleArtTableData.articlesInMultiple);
    $("#" + this.pageId + "multiArtlength").val(multipleArtTableData.length);
    $("#" + this.pageId + "multiArtbreadth").val(multipleArtTableData.breadth);
    $("#" + this.pageId + "multiArtheight").val(multipleArtTableData.height);
    $("#" + this.pageId + "multiArtActWgt").val(multipleArtTableData.actualWeight);
    $("#" + this.pageId + "multiArtChgWgt").val(multipleArtTableData.chargedWeight);
    $("#" + this.pageId + "hiddenIndex").val(index);
    this.editTableId = index;
  }

  rowSelectedDelete(multipleArtTableData, index) {
    this.multiArtTableDataList.splice(index, 1);
    // this.getArticles = multipleArtTableData.articlesInMultiple;
    // this.enterArticles = +this.enterArticles + -this.getArticles;
    $("#" + this.pageId + "multiArtarticle").val('');
    $("#" + this.pageId + "multiArtlength").val('');
    $("#" + this.pageId + "multiArtbreadth").val('');
    $("#" + this.pageId + "multiArtheight").val('');
    $("#" + this.pageId + "multiArtActWgt").val('');
    $("#" + this.pageId + "multiArtChgWgt").val('');
    $("#" + this.pageId + "hiddenIndex").val('');

    //$("#" + this.pageId + "multipleArticleTable").DataTable().destroy();
    this.dtTriggerMultiArtTable.next();
  }
  //    calculatedEditDelete(multipleArtTableData){
  //        this.getArticles=  multipleArtTableData.article;
  //        console.log('got Multi Art :'+ this.getArticles);
  //        console.log('Eee :'+this.enterArticles)
  //       
  //        console.log(this.enterArticles);
  //    }
  ////

  ///For Multiple Article  Pop with scan
  addInTableWithScan() {
    this.newAttribute.barCodeValue = $("#" + this.pageId + "multiScanCode").val();
    this.newAttribute.articlesInMultiple = $("#" + this.pageId + "multiArtarticle").val();
    this.newAttribute.length = $("#" + this.pageId + "multiArtlength").val();
    this.newAttribute.breadth = $("#" + this.pageId + "multiArtbreadth").val();
    this.newAttribute.height = $("#" + this.pageId + "multiArtheight").val();
    this.newAttribute.actualWeight = $("#" + this.pageId + "multiArtActWgt").val();
    this.newAttribute.chargedWeight = $("#" + this.pageId + "multiArtChgWgt").val();

    if (this.newAttribute.barCodeValue != '' && this.newAttribute.articlesInMultiple != '' && this.newAttribute.length != '' && this.newAttribute.breadth != '' && this.newAttribute.height != ''
      && this.newAttribute.barCodeValue != null && this.newAttribute.articlesInMultiple != null && this.newAttribute.length != null && this.newAttribute.breadth != null && this.newAttribute.height != null) {
      if (this.editSelected == true) {
        this.enterArticles = +this.enterArticles + -this.getArticles;
        this.editSelected = false;
      } else {
        this.editSelected = false;
      }
      this.isBlock = this.chkMulArtSizeWithScan();
      if (this.isBlock == true) {
        swal({
          title: "Alert",
          text: "Entered Articles Exceeds Actual Articles",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
        this.clearMulArtWithScan();
      } else {
        if (this.editTableId != null) {
          this.multiArtTableDataList.splice(this.editTableId, 1);
        }
        if (this.multiArtTableDataList == null) {
          this.multiArtTableDataList = [];
        }
        //$("#" + this.pageId + "multipleArticleTable").DataTable().destroy();
        if (this.multiArtTableDataList.length == 0) {
          this.multiArtTableDataList.push(this.newAttribute);
        } else {
          let array = this.multiArtTableDataList;
          this.multiArtTableDataList.push(this.newAttribute);
        }

        this.dtTriggerMultiArtTable.next();
        this.newAttribute = {};
        $("#" + this.pageId + "multiScanCode").val('');
        $("#" + this.pageId + "multiArtarticle").val('');
        $("#" + this.pageId + "multiArtlength").val('');
        $("#" + this.pageId + "multiArtbreadth").val('');
        $("#" + this.pageId + "multiArtheight").val('');
        $("#" + this.pageId + "multiArtActWgt").val('');
        $("#" + this.pageId + "multiArtChgWgt").val('');

        this.editTableId = null;
        window.setTimeout(function () {
          $("#lrefcmultiScanCode").focus();
        }, 100);
      }
    }
    else {
      swal({
        title: "Warning",
        text: "All Fields Are Mandetory",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

    }
  }
  clearMulArtWithScan() {
    $("#" + this.pageId + "multiScanCode").val('');
    $("#" + this.pageId + "multiArtarticle").val('');
    $("#" + this.pageId + "multiArtlength").val('');
    $("#" + this.pageId + "multiArtbreadth").val('');
    $("#" + this.pageId + "multiArtheight").val('');
    $("#" + this.pageId + "multiArtActWgt").val('');
    $("#" + this.pageId + "multiArtChgWgt").val('');
  }
  chkMulArtSizeWithScan() {
    //here pending we need to get the foooter value of articles of Pop Up
    this.isBlock = false;
    this.totArticles = $("#" + this.pageId + "noOfArticles").val();
    this.multiArtArticle = $("#" + this.pageId + "multiArtarticle").val();
    this.enterArticles = +this.enterArticles + +this.multiArtArticle;
    if (this.enterArticles > parseInt(this.totArticles)) {
      this.isBlock = true;
      this.enterArticles = +this.enterArticles + -this.multiArtArticle;
      if (this.editTableId != null) {
        this.multiArtTableDataList.splice(this.editTableId, 1);
      }
    } else {
      this.isBlock = false;
    }
    return this.isBlock;
  }
  rowSelectedEditWithScan(multipleArtTableData, index) {
    this.editSelected = true;
    this.getArticles = multipleArtTableData.articlesInMultiple;
    $("#" + this.pageId + "multiScanCode").val(multipleArtTableData.barCodeValue);
    $("#" + this.pageId + "multiArtarticle").val(multipleArtTableData.articlesInMultiple);
    $("#" + this.pageId + "multiArtlength").val(multipleArtTableData.length);
    $("#" + this.pageId + "multiArtbreadth").val(multipleArtTableData.breadth);
    $("#" + this.pageId + "multiArtheight").val(multipleArtTableData.height);
    $("#" + this.pageId + "multiArtActWgt").val(multipleArtTableData.actualWeight);
    $("#" + this.pageId + "multiArtChgWgt").val(multipleArtTableData.chargedWeight);
    $("#" + this.pageId + "hiddenIndex").val(index);
    this.editTableId = index;
  }
  rowSelectedDeleteWithScan(multipleArtTableData, index) {
    this.multiArtTableDataList.splice(index, 1);
    this.getArticles = multipleArtTableData.articlesInMultiple;
    this.enterArticles = +this.enterArticles + -this.getArticles;
    $("#" + this.pageId + "multiScanCode").val('');
    $("#" + this.pageId + "multiArtarticle").val('');
    $("#" + this.pageId + "multiArtlength").val('');
    $("#" + this.pageId + "multiArtbreadth").val('');
    $("#" + this.pageId + "multiArtheight").val('');
    $("#" + this.pageId + "multiArtActWgt").val('');
    $("#" + this.pageId + "multiArtChgWgt").val('');
    $("#" + this.pageId + "hiddenIndex").val('');
  }


  /* allowMultiArtPopUp() {
      this.subCommodityName = null;
      this.subCommodityName = $( "#partyCommodity" ).val();
  
      if ( this.subCommodityName == "Add New" ) {
          this.subCommodityName = $( "#goodsType" ).val();
          if ( this.subCommodityName == "Add New" ) {
              this.subCommodityName = $( "#newCommodity" ).val();
          }
  
      }
  
      if ( this.subCommodityName != null && this.subCommodityName != '' ) {
  //            console.log( 'here3' );
  //            console.log( this.subCommodityName );
          this.multiArtPop();
      } else {
          swal( {
              title: "Warning",
              text: "Commodity Name is Empty",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
          } );
      }
  
  } */
  allowMultiArtPopUp(content7) {
    this.subCommodityName = null;
    this.subCommodityName = $("#" + this.pageId + "partyCommodity").val();

    if (this.subCommodityName == "Add New") {
      this.subCommodityName = $("#" + this.pageId + "goodsType").val();
      if (this.subCommodityName == "Add New") {
        this.subCommodityName = $("#" + this.pageId + "newCommodity").val();
      }

    }

    if (this.subCommodityName != null && this.subCommodityName != '') {
      //            console.log( 'here3' );
      //            console.log( this.subCommodityName );
      this.multiArtPop(content7);
    } else {
      swal({
        title: "Warning",
        text: "Commodity Name is Empty",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }

  }
  multiArtPopInfo() {
    this.lrtDtoForMultiArt = new LRDto();
    this.lrtDtoForMultiArt.subCommodity = this.subCommodityName;
    this.lrtDtoForMultiArt.companyId = this.userDataDtoReturnSession.companyId;
  }


  /* multiArtPop = () => {
  
      this.multiArtPopInfo();
      this.masterReadService.isCommodityAllowArtSize( this.lrtDtoForMultiArt ).subscribe(
          ( response ) => {
              if ( response.length == 0 ) {
                  //                    alert( '' );
              } else {
                  this.lrtDtoForChallanRet = response;
                  this.lrtDtoForMultiArtRetSet = this.lrtDtoForChallanRet;
                  if ( this.lrtDtoForMultiArtRetSet.isAllow == false ) {
  
//                        swal( {
//                            title: "Validation",
//                            text: "Sure You Want To Add Multiple Article Sizes",
//                            icon: "warning",
//                            closeOnClickOutside: false,
//                            closeOnEsc: false,
//                            buttons: ["No", "Yes"],
//                        } ).then(( multiarticles ) => {
//                            if ( multiarticles ) {
//                    //            console.log( this.lrtDtoForMultiArtRetSet.isAllow );
//                                this.showMultiArt = true;
//                            } else {
//                                this.goodsValueField.nativeElement.focus();
//                            }
//                        } );
swal({
  title: "Show Two Buttons Inside the Alert",
  showCancelButton: true,
  confirmButtonText: "Confirm",
  confirmButtonColor: "#00ff99",
  cancelButtonColor: "#ff0099"
});
  
                  } else {
                      this.goodsValueField.nativeElement.focus();
                  }
  
              }
          } ),
          ( error ) => console.log( error.json() ),
          () => console.log( 'done' );
  };*/


  multiArtPop(content7) {

    this.multiArtPopInfo();
    this.masterReadService.isCommodityAllowArtSize(this.lrtDtoForMultiArt).subscribe(
      (response) => {
        if (response.length == 0) {
          //                    alert( '' );
        } else {
          this.lrtDtoForChallanRet = response;
          this.lrtDtoForMultiArtRetSet = this.lrtDtoForChallanRet;
          if (this.lrtDtoForMultiArtRetSet.isAllow == false) {

            //CHG-V-LR:02
            this.modalRefferenceContentMultiArtPopUp = this.modalService.open(content7, { centered: true, windowClass: "myCustomModalClassMultiArt" });
            // $("#" + this.pageId + "multipleArticleTable").DataTable().destroy();
            // this.dtTriggerMultiArtTable.next();
            this.multiArtDetailsTable();
            window.setTimeout(function () {
              $("#lrefcmultiArtarticle").focus();
            }, 100);

            // swal({
            //     title: "Validation",
            //     text: "Sure You Want To Add Multiple Article Sizes",
            //     icon: "warning",
            //     closeOnClickOutside: false,
            //     closeOnEsc: false,
            //     buttons: ["No", "Yes"],
            //     dangerMode: true,
            // }).then((multiarticles) => {
            //     if (multiarticles) {
            //         //                                console.log( this.lrtDtoForMultiArtRetSet.isAllow );
            //         //this.showMultiArt = true;
            //         // this.modalService.open(content7, { centered: true, size: 'lg' });
            //         this.modalService.open(content7, { centered: true, windowClass: "myCustomModalClassMultiArt" });
            //         // $("#" + this.pageId + "multipleArticleTable").DataTable().destroy();
            //         // this.dtTriggerMultiArtTable.next();
            //         this.multiArtDetailsTable();

            //         window.setTimeout(function () {
            //             $("#lrefcmultiScanCode").focus();
            //         }, 100);

            //     } else {
            //         //                                this.goodsValueField.nativeElement.focus();
            //         window.setTimeout(function () {
            //             $("#lrefcgoodsValue").focus();
            //         }, 600);
            //     }
            // });


          } else {
            //                        this.goodsValueField.nativeElement.focus();
            window.setTimeout(function () {
              $("#lrefcgoodsValue").focus();
            }, 100);
          }

        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };





  //    setDefaultCharges() {
  //        this.getGCCharges = this.userDataDtoReturnSession.gcCharge;
  //        this.getOthers = this.userDataDtoReturnSession.others;
  //        //        console.log( 'gc' + this.getGCCharges );
  //        //        console.log( 'others ' + this.getOthers );
  //
  //
  //        if ( ( this.getGCCharges != null ) && ( this.getGCCharges != 0 ) && ( this.getOthers != null ) && ( this.getOthers != 0 ) ) {
  //            //            $( "#gcCharge" ).val( this.getGCCharges );
  //            //            $( "#others" ).val( this.getOthers );
  //            //            console.log( 'here ' + this.getGCCharges );
  //            this.setGcCharge = this.getGCCharges;
  //            this.setOthers = this.getOthers;
  //            //            this is not setting inthe input fields is Pending 
  //        } else {
  //            $( "#gcCharge" ).val( '' );
  //            $( "#others" ).val( '' );
  //        }
  //    }
  setDefaultCharges() {
    //    console.log( this.userDataDtoReturnSession.gcCharge );
    //   console.log( this.userDataDtoReturnSession.others );
    this.getOthers = this.userDataDtoReturnSession.others;
    let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
    let office = this.userDataDtoReturnSession.office.toLowerCase();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    if (this.selectedDestination != null && this.selectedDestination != '') {
      this.commaSplitDest = this.selectedDestination.split("-");
      this.destSplit = this.commaSplitDest[0];
      this.selectedDestination = this.destSplit.toLowerCase();
      this.selectedDestination = this.selectedDestination.trim();
    } else {
      this.selectedDestination = this.selectedDestination;
    }
    //    console.log( this.selectedDestination );

    // changed the logic as per Manoj mail @Dated : 09/09/2020
    // from Gujarat & RAJASTHAN to chennai - 70/- Per Bilty (45 + 25)
    // also for Jamnagar (Dhared - JM, Udyog Nagar - JM) 95/- Per Bilty
    // (statistical 50/-, Others 45/-) except
    // chennai
    //        console.log( 'gc' + this.getGCCharges );
    //        console.log( 'others ' + this.getOthers );
    if (MainAdminStation != null
      && MainAdminStation == 'mumbai') {

      this.getGCCharges = this.userDataDtoReturnSession.gcCharge;
      this.getOthers = this.userDataDtoReturnSession.others;
    } else if (MainAdminStation != null
      && MainAdminStation == 'gujarat') {
      if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination == 'chennai') {
        this.getGCCharges = 45
        this.getOthers = 25;
      } else if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination == 'madurai') {
        //for rajasthan station as per norm and from gujarat to Madurai its custom chg @08/12/2021
        //alert(this.selectedDestination);
        if (office != null
          && ((office == 'balotra') || (office == 'bhilwara') || (office == 'pali') || (office == 'jaipur vki') || (office == 'jaipur sc road'))) {
          this.getGCCharges = this.userDataDtoReturnSession.gcCharge;
          this.getOthers = this.userDataDtoReturnSession.others;
          //alert('1');
        } else {
          this.getGCCharges = 45;
          this.getOthers = 0;
          //alert('2');
        }
      }
      else if (office != null
        && ((office == 'dhared - jm') || (office == 'udyog nagar - jm'))) {
        this.getGCCharges = 50
        this.getOthers = 45;
      } else {
        this.getGCCharges = this.userDataDtoReturnSession.gcCharge;
        this.getOthers = this.userDataDtoReturnSession.others;
      }
    } else if (MainAdminStation != null
      && MainAdminStation == 'rajasthan') {
      if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination == 'chennai') {
        // this.getGCCharges = 45;
        //this.getOthers = 25;
        // now same for all as per Manoj @20/03/2021
        this.getGCCharges = this.userDataDtoReturnSession.gcCharge;
        this.getOthers = this.userDataDtoReturnSession.others;
      } else {
        this.getGCCharges = this.userDataDtoReturnSession.gcCharge;
        this.getOthers = this.userDataDtoReturnSession.others;
      }
    }

    //if ((this.getGCCharges != null) && (this.getGCCharges != 0) && (this.getOthers != null) && (this.getOthers != 0)) {
    if ((this.getGCCharges != null) && (this.getGCCharges != 0) && (this.getOthers != null)) {
      //            $( "#gcCharge" ).val( this.getGCCharges );
      //            $( "#others" ).val( this.getOthers );
      //            console.log( 'here ' + this.getGCCharges );
      this.setGcCharge = this.getGCCharges;
      this.setOthers = this.getOthers;
      //            this is not setting inthe input fields is Pending 
    } else {
      // alert('3');
      $("#" + this.pageId + "gcCharge").val('');
      $("#" + this.pageId + "others").val('');
    }
  }

  lrNumberChkInfo() {
    this.enteredlrNumber = $("#" + this.pageId + "lrNumber").val();
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();

    this.getIsLrCode = this.userDataDtoReturnSession.isLrCode;
    this.getLrCode = this.userDataDtoReturnSession.lrCode;
    //        console.log( 'this is islrCode :' + this.getIsLrCode )
    this.lrtDtoForLrCheck = new LRDto();
    if (this.isLRCode == true) {
      //            console.log( 'MUM1' );
      this.lrtDtoForLrCheck.lrNumber = this.showLrCodeInputFields + this.enteredlrNumber;
      //            console.log( 'this is Lr number :' + this.lrtDtoForLrCheck.lrNumber );
    } else {
      this.lrtDtoForLrCheck.lrNumber = this.enteredlrNumber;
    }

    this.lrtDtoForLrCheck.memoNumber = this.enteredlrNumber;
    //        console.log( 'this is Memo no1:' + this.lrtDtoForLrCheck.memoNumber );
    this.lrtDtoForLrCheck.agentName = this.selectedAgentName
    this.lrtDtoForLrCheck.isLRCode = this.getIsLrCode;
    this.lrtDtoForLrCheck.lrCode = this.getLrCode;
    this.lrtDtoForLrCheck.companyId = this.userDataDtoReturnSession.companyId;

    //        console.log( 'thisss :' + this.userDataDtoReturnSession.isLRCode );
  }


  lrNumberChk = () => {

    this.lrNumberChkInfo();
    //        console.log( this.lrtDtoForLrCheck );
    this.masterReadService.addLRNumber(this.lrtDtoForLrCheck).subscribe(
      (response) => {
        if (response.length == 0) {
          //                    alert( '' );
        } else {
          this.lrtDtoForLrCheckRet = response;
          this.lrtDtoForLrCheckSet = this.lrtDtoForLrCheckRet;
          //                    console.log( 'this is the status : ' + this.lrtDtoForLrCheckSet.status );
          if (this.lrtDtoForLrCheckSet.status == 'exists') {

            swal({
              title: "Alert",
              text: "This LR Number: " + this.lrtDtoForLrCheck.lrNumber
                + " already exists",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then(() => {
              $("#" + this.pageId + "lrNumber").val('');
              window.setTimeout(function () {
                $('#lrefclrNumber').focus();
              }, 100);
              //                            this.lrNumberField.nativeElement.focus();
            });

          } else if ((this.enteredlrNumber == null) || (this.enteredlrNumber == '') || (this.enteredlrNumber.length > 7)) {
            swal({
              title: "Alert",
              text: "LR Number Should be not be greater than 7 digit",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then(() => {
              $("#" + this.pageId + "lrNumber").val('');
              window.setTimeout(function () {
                $('#lrefclrNumber').focus();
              }, 100);

              //                            this.lrNumberField.nativeElement.focus();
            });

          } else if (this.lrtDtoForLrCheckSet.status == 'RangeNotExist') {


            swal({
              title: "Alert",
              text: "This LR Number: " + this.lrtDtoForLrCheck.lrNumber
                + " not exists in LRIssue",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then(() => {
              this.chkLRinLrEntryMissAgt();
            });

          } else if (this.lrtDtoForLrCheckSet.status == "Other'sLr") {

            swal({
              title: "Alert",
              text: "This LR Number: " + this.lrtDtoForLrCheck.lrNumber
                + " is belongs to " + this.lrtDtoForLrCheckSet.agentName,
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then(() => {
              $("#" + this.pageId + "lrNumber").val('');
              window.setTimeout(function () {
                $('#lrefclrNumber').focus();
              }, 100);
              //                            this.lrNumberField.nativeElement.focus();
            });

          } else {
            this.chkLRinLrEntryMissAgt();
          }
        }
        this.changeDetectorRef.detectChanges();

      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };


  chkLRinLrEntryMissAgt = () => {
    this.showGoodsType = false;
    this.showNewCommodity = false;
    this.lrNumberChkInfo();
    this.masterReadService.chkLREntryMissAgent(this.lrtDtoForLrCheck).subscribe(
      (response) => {
        if (response.length == 0) {
          //                    alert( '' );
          this.noOfArticlesField.nativeElement.focus();
        } else {
          this.lrtDtoForMissAgentRet = response;
          this.lrtDtoForMissAgentSet = this.lrtDtoForMissAgentRet
          if (this.lrtDtoForMissAgentSet.status == 'notexists') {
            //                        dateField.focus(); is pending
            $("#" + this.pageId + "bookingDate").focus();
            //                        this.noOfArticlesField.nativeElement.focus();
          } else if ((this.lrtDtoForMissAgentSet.status != null) && (this.lrtDtoForMissAgentSet.status == 'blocked')) {
            //                        alert( "This LR Number: " + this.lrtDtoForLrCheck.lrNumber
            //                            + " is Blocked.Contact Booking Administrator" );
            swal({
              title: "Alert",
              text: "This LR Number: " + this.lrtDtoForLrCheck.lrNumber
                + " is Blocked.Contact Booking Administrator",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            }).then(() => {
              $("#" + this.pageId + "lrNumber").val('');
              this.lrNumberField.nativeElement.focus();
            });

          }
        }
        this.changeDetectorRef.detectChanges();

      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };
  toggleEditableForBookingDate(event) {
    if (event.target.checked) {
      this.validateBkgDateClicked = true;
    } else {
      this.validateBkgDateClicked = false;
    }

  }
  /*fieldFocusBkgDate( e ) {
      //        this.enteredBookingDate = $( "#bookingDate" ).val();
  //            console.log(this.enteredBookingDate);
      if ( e.keyCode == 13 ) {
          //            alert( '1' );
          if ( this.viewByAutoLr == true || this.lrCheck == true ) {
              //                alert( '2' );
              if ( this.validateBkgDateClicked == true && this.enteredBookingDate != null ) {
                  //                    alert( '3' );
                  this.validateLrBookingDateGet();
              } else {
                  //                    alert( '4' );
                  this.noOfArticlesField.nativeElement.focus();
              }
          } else {
              //                alert( '4' );
              this.noOfArticlesField.nativeElement.focus();
          }
  
      }
  }*/


  //By Kamil
  fieldFocusBkgDate(e) {
    if (e.keyCode == 13) {
      this.focusBookingCalendar();

    }
  }

  validateLrBookingDateGetInfo() {
    ///pending parse error
    //        this.enteredBookingDate = $( "#bookingDate" ).val();
    //By Kamil
    let enterBookingDate = null;
    enterBookingDate = $("#" + this.pageId + "bookingDate").val();
    this.enteredBookingDate = this.datePipe.transform(enterBookingDate, "yyyy-MM-dd");
    this.lrtDtoForValidateBkgDate = new LRDto();
    this.lrtDtoForValidateBkgDate.companyId = this.userDataDtoReturnSession.companyId;
    this.lrtDtoForValidateBkgDate.mainstation = this.userDataDtoReturnSession.mainAdminStation;
    this.lrtDtoForValidateBkgDate.bookingDate = this.enteredBookingDate;
    this.lrtDtoForValidateBkgDate.groupStation = this.userDataDtoReturnSession.groupStation;
    this.lrtDtoForValidateBkgDate.branch = this.userDataDtoReturnSession.office;

    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.selectedDestination = this.destSplit.toLowerCase();
    this.selectedDestination = this.selectedDestination.trim();
    this.lrtDtoForValidateBkgDate.destination = this.selectedDestination;
    //        console.log( this.lrtDtoForValidateBkgDate.bookingDate );

  }
  validateLrBookingDateGet = () => {
    this.validateLrBookingDateGetInfo();
    this.masterReadService.validateLrBookingDate(this.lrtDtoForValidateBkgDate).subscribe(
      (response) => {
        if (response.length == 0) {
          //                    alert( '' );
        } else {
          this.lrtDtoForValidateBkgDateRet = response;
          this.lrtDtoForValidateBkgDateSet = this.lrtDtoForValidateBkgDateRet;
          //                    console.log( 'returned Bkg Date :' + this.lrtDtoForValidateBkgDateSet );
          if (this.lrtDtoForValidateBkgDateSet.toDayDate != null) {
            /* this.bookingDateGet = this.lrtDtoForValidateBkgDateSet.toDayDate;
//                        console.log( 'this si sthe BookingDate: ' + this.bookingDateGet );
            $( "#bookingDates" ).val( this.bookingDateGet );
            this.noOfArticlesField.nativeElement.focus();*/
            //By Kamil
            this.bookingDateGet = this.datePipe.transform(this.lrtDtoForValidateBkgDateSet.toDayDate, "yyyy-MM-dd");
            //                        console.log( 'this is the BookingDate Return: ' + this.bookingDateGet );
            $("#" + this.pageId + "bookingDate").val(this.bookingDateGet);
            //this.noOfArticlesField.nativeElement.focus();
            window.setTimeout(function () {
              $("#lrefcnoOfArticles").focus();
            }, 1000);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  validateBtnNextAfterCheckingChallanTotalLrs(content11) {

    if ((this.checkAutoLr == false) && (this.checkAutoLrHold == false)) {
      this.enteredChallanTotLrs = $("#" + this.pageId + "challanTotLrs").val();
      if ((this.enteredChallanTotLrs == '') || (this.enteredChallanTotLrs == null)) {

        swal({
          title: "Mandatory Fields",
          text: "Please enter Local Challan Total Lrs",
          icon: "warning",
          closeOnClickOutside: true,
          closeOnEsc: true,
        });
      } else {
        this.lrtDtoForChallanTotLr = new LRDto();
        this.enteredChallanNo = $("#" + this.pageId + "challanNo").val();
        this.lrtDtoForChallanTotLr.challanNo = this.enteredChallanNo;
        this.verifyChallanTotLrs(content11);
      }
    } else {
      this.enteredAutoLrNumber = $("#" + this.pageId + "autoLrNumber").val();
      if ((this.enteredAutoLrNumber == '') || (this.enteredAutoLrNumber == null)) {
        swal("Not Allowed", "LR number not found, please check the generated LR number!", "warning");
      } else {
        this.validateBtnNext(content11);
      }
    }

  }
  verifyChallanTotLrs = (content11) => {
    this.enteredChallanNo = $("#" + this.pageId + "challanNo").val();
    this.validateLrBookingDateGetInfo();
    this.masterReadService.verifyChallanTotalLrs(this.lrtDtoForChallanTotLr).subscribe(
      (response) => {
        if (response.length == 0) {

        } else {
          this.lrtDtoForChallanTotLrRet = response;
          this.lrtDtoForChallanTotLrRetDto = this.lrtDtoForChallanTotLrRet;

          if ((this.lrtDtoForChallanTotLrRetDto.challanTotLrs != null) && (this.lrtDtoForChallanTotLrRetDto.challanTotLrs > 0)) {
            let challanTotLrsGet = this.lrtDtoForChallanTotLrRetDto.challanTotLrs;
            let noOfLrsEnteredGet = (this.lrtDtoForChallanTotLrRetDto.noOfLrs == null ? 0 : this.lrtDtoForChallanTotLrRetDto.noOfLrs);
            let balanceLrs = challanTotLrsGet - noOfLrsEnteredGet;
            if (balanceLrs == 0
              || balanceLrs < 0) {
              swal({
                title: "Not Allowed",
                text: "All the Lrs of Challan Number '" + this.enteredChallanNo + "' is already entered, please verify the Challan Number!",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
              });
            } else {
              this.validateBtnNext(content11);
            }

          } else {
            this.validateBtnNext(content11);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };
  validateBtnNext(content11) {
    //        alert( 'btn' );
    this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val();
    this.goodsValue = this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue == '' ? 0.0
      : this.enteredGoodsValue;
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedconsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
    this.selectedConsignorName = $("#" + this.pageId + "consignorName").val();
    this.selectedNewConsignorName = $("#" + this.pageId + "newConsignor").val();

    this.selectedconsignorMobileNumber = $("#" + this.pageId + "consignorMobNoExist").val();
    this.selectedconsigneeMobileNumber = $("#" + this.pageId + "consigneeMobNoExist").val();

    this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
    this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
    this.selectedNewConsigneeName = $("#" + this.pageId + "newConsignee").val();
    this.totArticles = $("#" + this.pageId + "noOfArticles").val();
    this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
    this.selectedCommonCommodity = $("#" + this.pageId + "mainCommodity").val();
    this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val();
    this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();

    this.enteredEWayBillNo = this.enteredEWayBillNo == null ? null : this.enteredEWayBillNo == '' ? null
      : this.enteredEWayBillNo;

    this.selectedAgentName = $("#" + this.pageId + "agentName").val();
    this.enteredActualWeight = $("#" + this.pageId + "actualWeight").val();
    this.enterdActWgt = this.enteredActualWeight;
    this.enteredChargeWeight = $("#" + this.pageId + "chargedWeight").val();
    this.enterdChgWgt = this.enteredChargeWeight;
    //        alert( this.selectedAgentName + "-" + this.selectedUserGodown );
    this.gcCharge = $("#" + this.pageId + "gcCharge").val();
    this.others = $("#" + this.pageId + "others").val();
    this.paymentType = $("#" + this.pageId + "paymentType").val();
    this.enteredChallanNo = $("#" + this.pageId + "challanNo").val();
    this.selectedLoaderHead = $("#" + this.pageId + "loaderHead").val();
    let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
    //     console.log( this.selectedLoaderHead, MainAdminStation, this.gcCharge, this.others, this.getGCCharges, this.getOthers, this.restrictUserGodownList );

    this.selectedBayNumber = $("#" + this.pageId + "bayNumber").val();
    let enterBayUnloadingDate = $("#" + this.pageId + "bayUnloadingDate").val();
    this.enteredBayUnloadingDate = this.datePipe.transform(enterBayUnloadingDate, "yyyy-MM-dd");
    this.selectedBayUnloadingTime = $("#" + this.pageId + "bayUnloadingTime").val();
    let enterEwayBillValidUptoDate = $("#" + this.pageId + "eWayBillValidUptoDate").val();
    this.enteredEwayBillValidUptoDate = this.datePipe.transform(enterEwayBillValidUptoDate, "yyyy-MM-dd");

    this.enteredPincode = $("#" + this.pageId + "entryPinCode").val();
    this.enteredConsignorEInvoiceNo = $("#" + this.pageId + "eInvNoConsignor").val();

    this.validateCommonCommoditySelection();

    if ((this.viewPincode == true) && (this.enteredPincode.trim() == null || this.enteredPincode.trim() == '' || this.enteredPincode.trim() == undefined)) {
      swal({
        title: "Not Allowed",
        text: "Please enter the destination pincode!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
    // remove this since the charges are fetching from the rate master and it varies based on the rate setup
    // else if ((this.userDataDtoReturnSession.mainAdminStation != null)
    //     && (MainAdminStation == 'delhi')
    //     && (this.gcCharge != null) && (this.gcCharge != 0)
    //     && (this.gcCharge != 0.0)
    //     && (this.gcCharge != this.userDataDtoReturnSession.gcCharge)) {

    //     swal({
    //         title: "Not Allowed",
    //         text: "GC charge must be " + this.getGCCharges + " please verify it!",
    //         icon: "warning",
    //         closeOnClickOutside: false,
    //         closeOnEsc: false,
    //     });

    // } else if ((this.userDataDtoReturnSession.mainAdminStation != null)
    //     && (MainAdminStation == 'delhi')
    //     && (this.others != null) && (this.others != 0)
    //     && (this.others != 0.0)
    //     && (this.others != this.userDataDtoReturnSession.others)) {

    //     swal({
    //         title: "Not Allowed",
    //         text: "Other charge must be " + this.getOthers + " please verify it!",
    //         icon: "warning",
    //         closeOnClickOutside: false,
    //         closeOnEsc: false,
    //     });
    // } 
    else if (this.isPaidLrOnly && this.paymentType == 'toPay') {

      swal({
        title: "Not Allowed",
        text: "The LR of this Consignee can be booked only on Paid basis, please contact System Admin for more details!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if (!this.restrictUserGodownList
      && (this.selectedLoaderHead == null || this.selectedLoaderHead == '')) {

      swal({
        title: "Not Allowed",
        text: "Please select the Choudhary Name!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if (!this.validateVehicleNumber()) {
      swal({
        title: "In-Valid Vehicle Number",
        text: "Please enter the correct Vehicle Number to proceed!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
      //New Added by basha 
    } else if (this.bayFlow == true && (this.selectedBayNumber == null || this.selectedBayNumber == '')) {
      swal({
        title: "Not Allowed",
        text: "Please select the Bay Number",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if (this.bayFlow == true && (this.enteredBayUnloadingDate == null || this.enteredBayUnloadingDate == '' || this.selectedBayUnloadingTime == null || this.selectedBayUnloadingTime == '')) {
      swal({
        title: "Not Allowed",
        text: "Please select Bay Unloading Date & Time",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
    //temp removed mandatory as per manoj @11042022

    // else if (this.consignorEInvoiceNoEnabled == true && (this.enteredConsignorEInvoiceNo == null || this.enteredConsignorEInvoiceNo == '')) {
    //     swal({
    //         title: "Not Allowed",
    //         text: "Please enter e-Invoice ACK No",
    //         icon: "warning",
    //         closeOnClickOutside: false,
    //         closeOnEsc: false,
    //     });
    // } 
    //CHG-V-LR:41
    else if (this.isCommodityBlockedBkg) {
      swal("Not Allowed", this.selectedPartyCommodity + " commodity is blocked for booking, please contact admin!", "warning");
    }
    else {
      if ((this.viewByAutoLr == true) || (this.lrCheck == true)) {
        console.log('test1');
        console.log(this.enteredEWayBillNo);
        // console.log(this.selectedconsignorMobileNumber.length);
        // console.log(this.selectedconsignorMobileNumber);
        // if (this.selectedconsignorMobileNumber.startsWith("6")) {
        //     console.log('yes');
        // }

        if (this.selectedDestination == 'Select Destination' || this.selectedDestination == '' || this.selectedDestination == null) {
          swal({
            title: "Mandatory Fields",
            text: "Please Select The Destination",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.enteredActualWeight == null || this.enteredActualWeight == '') {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Actual Weight",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.enteredChargeWeight == null || this.enteredChargeWeight == '') {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Charged Weight",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (parseInt(this.enteredChargeWeight) < parseInt(this.enteredActualWeight)) {
          swal({
            title: "Not Allowed",
            text: "Charged Weight Should Be Equal Or Greater Than Actual Weight",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.selectedconsignorGSTNumber == null || this.selectedconsignorGSTNumber == '') {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Consignor GST number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if ((this.selectedConsignorName == 'Add New') && ((this.selectedNewConsignorName == null) || (this.selectedNewConsignorName == ''))) {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Consignor Name",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.selectedconsigneeGSTNumber == null || this.selectedconsigneeGSTNumber == '') {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Consignee GST number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if ((this.selectedConsigneeName == 'Add New') && ((this.selectedNewConsigneeName == null) || (this.selectedNewConsigneeName == ''))) {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Consignee Name",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else if ((this.totArticles == null) || (this.totArticles == '')) {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Article",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else if (this.totArticles != null && (parseInt(this.totArticles) > 1000)) {
          swal({
            title: "Mandatory Fields",
            text: "Number of Articles is: " + this.totArticles + ". Is it correct???",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
          }).then((articleNextCheck) => {
            if (articleNextCheck) {
              this.nextBtnField.nativeElement.focus();
            } else {
              this.noOfArticlesField.nativeElement.focus();
            }
          });
          //pop Message pending

        } else if (this.selectedPartyCommodity != null && (this.selectedPartyCommodity == 'Add New')) {
          this.commdityValidation(content11);
        } else if ((this.eWayBillExempted == false) && (parseInt(this.enteredGoodsValue) >= 50000) && ((this.enteredEWayBillNo == null) || (this.enteredEWayBillNo == ''))) {
          swal({
            title: "Mandatory Fields",
            text: "E-Way Bill is complusory",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.enteredEWayBillNo != null && this.enteredEWayBillNo != '' && this.enteredEWayBillNo.length < 12) {
          swal({
            title: "Invalid E-Way Bill number",
            text: "Entered E-Way Bill number is Invalid, please verify it. If its not applicable then please remove it.",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }
        else if ((this.eWayBillExempted == false) && (parseInt(this.enteredGoodsValue) >= 50000) && ((this.enteredEwayBillValidUptoDate == null) || (this.enteredEwayBillValidUptoDate == ''))) {
          swal({
            title: "Mandatory Fields",
            text: "E-Way Bill valid upto date is complusory",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.selectedconsignorMobileNumber == null || this.selectedconsignorMobileNumber == '' || this.selectedconsignorMobileNumber.length < 10 || this.selectedconsignorMobileNumber.startsWith("0") || this.selectedconsignorMobileNumber.startsWith("1") || this.selectedconsignorMobileNumber.startsWith("2") || this.selectedconsignorMobileNumber.startsWith("3") || this.selectedconsignorMobileNumber.startsWith("4") || this.selectedconsignorMobileNumber.startsWith("5")) {
          swal({
            title: "Mandatory Fields",
            text: "Please enter valid Consignor Mobile Number! Please remove '0' or '91' if any.",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }
        // removed mandatory validation as per Musaddik bh @08/07/2022

        else if (this.selectedconsigneeMobileNumber == null || this.selectedconsigneeMobileNumber == '' || this.selectedconsigneeMobileNumber.length < 10 || this.selectedconsigneeMobileNumber.startsWith("0") || this.selectedconsigneeMobileNumber.startsWith("1") || this.selectedconsigneeMobileNumber.startsWith("2") || this.selectedconsigneeMobileNumber.startsWith("3") || this.selectedconsigneeMobileNumber.startsWith("4") || this.selectedconsigneeMobileNumber.startsWith("5")) {
          swal({
            title: "Mandatory Fields",
            text: "Please enter valid Consignee Mobile Number! Please remove '0' or '91' if any.",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }

        else if (this.isCommonCommodityValid == false) {
          swal({
            title: "Not Allowed",
            text: "Selected Main commodity " + this.selectedCommonCommodity + " is not valid, please check and select again!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }
        else if (this.selectedPartyCommodity != null && (this.selectedPartyCommodity != 'Add New')) {
          this.validateMainCommoditySelection();
          if (this.isMainCommodityValid) {
            this.setLrEntryFields(content11);
          }
        }
        else {
          this.setLrEntryFields(content11);
          //                alert( 'd' );
        }
      }
      else {
        this.validateAgentNameSelection();

        if ((this.userDataDtoReturnSession.mainAdminStation != null)
          && (MainAdminStation == 'delhi')
          && ((this.enteredChallanNo == null) || (this.enteredChallanNo == ''))) {

          swal({
            title: "Not Allowed",
            text: "Please enter challan number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          return false;
        }
        if (this.selectedAgentName == null || this.selectedAgentName == '' || this.selectedAgentName == 'Add New') {
          swal({
            title: "Mandatory Fields",
            text: "Please Select The Agent Name",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.enteredActualWeight == null || this.enteredActualWeight == '') {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Actual Weight",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.enteredChargeWeight == null || this.enteredChargeWeight == '') {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Charged Weight",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else if (this.selectedDestination == 'Select Destination' || this.selectedDestination == '' || this.selectedDestination == null) {
          swal({
            title: "Mandatory Fields",
            text: "Please Select The Destination",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.selectedconsignorGSTNumber == null || this.selectedconsignorGSTNumber == '') {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Consignor GST number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if ((this.selectedConsignorName == 'Add New') && ((this.selectedNewConsignorName == null) || (this.selectedNewConsignorName == ''))) {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Consignor Name",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.selectedconsigneeGSTNumber == null || this.selectedconsigneeGSTNumber == '') {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Consignee GST number",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if ((this.selectedConsigneeName == 'Add New') && ((this.selectedNewConsigneeName == null) || (this.selectedNewConsigneeName == ''))) {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Consignee Name",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else if ((this.totArticles == null) || (this.totArticles == '')) {
          swal({
            title: "Mandatory Fields",
            text: "Please enter Article",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        } else if (this.totArticles != null && (parseInt(this.totArticles) > 1000)) {
          swal({
            title: "Mandatory Fields",
            text: "Number of Articles is: " + this.totArticles + ". Is it correct???",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
          }).then((articleNextCheck) => {
            if (articleNextCheck) {
              this.nextBtnField.nativeElement.focus();
            } else {
              this.noOfArticlesField.nativeElement.focus();
            }
          });

        } else if (this.selectedPartyCommodity != null && (this.selectedPartyCommodity == 'Add New')) {
          this.commdityValidation(content11);
        } else if ((this.eWayBillExempted == false) && (parseInt(this.enteredGoodsValue) >= 50000) && (this.enteredEWayBillNo == null || this.enteredEWayBillNo == '')) {
          swal({
            title: "Mandatory Fields",
            text: "E-Way Bill is complusory",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if ((this.eWayBillExempted == false) && (parseInt(this.enteredGoodsValue) >= 50000) && ((this.enteredEwayBillValidUptoDate == null) || (this.enteredEwayBillValidUptoDate == ''))) {
          swal({
            title: "Mandatory Fields",
            text: "E-Way Bill valid upto date is complusory",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.isCommonCommodityValid == false) {
          swal({
            title: "Not Allowed",
            text: "Selected Main commodity " + this.selectedCommonCommodity + " is not valid, please check and select again!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.isAgentNameValid == false) {
          swal({
            title: "Not Allowed",
            text: "Selected Agent Name " + this.selectedAgentName + " is not valid, please check and select again!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else if (this.selectedPartyCommodity != null && (this.selectedPartyCommodity != 'Add New')) {
          this.validateMainCommoditySelection();
          if (this.isMainCommodityValid) {
            this.setLrEntryFields(content11);
          }
        } else {
          this.setLrEntryFields(content11);
          //                alert( 'de' );
        }

      }
    }
  }


  commdityValidation(content11) {
    this.selectedNewCommodity = $("#" + this.pageId + "newCommodity").val();
    if ((this.showNewCommodity == false) && (this.showGoodsType == false)) {
      swal({
        title: "Mandatory Fields",
        text: "Please Select The Party Goods Type",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if ((this.showNewCommodity == true)
      && (this.selectedNewCommodity == null || this.selectedNewCommodity == '')) {
      swal({
        title: "Mandatory Fields",
        text: "Please Enter New Commodity",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if (((this.showGoodsType == true))
      && (this.showNewCommodity == false)
      && ((this.selectedGoodsTypeCommodity != null && this.selectedGoodsTypeCommodity == "Add New"))) {

      swal({
        title: "Mandatory Fields",
        text: "Please Select Commodity",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

    }
    else {
      this.setLrEntryFields(content11);
      //            alert( 'ddc' );
    }
  }

  setLrEntryFields(content11) {
    this.selectedDoorDelivery = $("#" + this.pageId + "doorDeliveryMode").val();
    this.ddAmt = $("#" + this.pageId + "ddAmt").val();
    this.ddAmountRange = this.calculateDDAmountRangeToValidate();
    console.log('tr :' + this.ddAmountRange);
    if (this.userDataDtoReturnSession.isDDAmtBlock && this.selectedDoorDelivery == 'Yes' && ((this.ddAmt == null) || (this.ddAmt == '') || (this.ddAmt == 0) || (this.ddAmountRange > this.ddAmt))) {
      this.minDDAmt = this.userDataDtoReturnSession.ddMinAmtOnLr;
      this.minDDPerc = this.userDataDtoReturnSession.ddMinPercOnLr;
      console.log(this.minDDAmt, this.minDDPerc);
      swal({
        title: "Mandatory Fields",
        text: "Mentioned DD Amount is must be at minimum range of " + this.minDDAmt + " or " + this.minDDPerc + " ps on Charge Weight, whichever is higher!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
      this.ddAmtField.nativeElement.focus();
    } else {
      //            console.log( 'so ' );
      this.lrDetailsAddFlow(content11);
      //            alert( 'ddDDMode' );
    }


  }

  noOfArticlesBlur(e) {
    //CHG-V-LR:01
    if (this.consignorRateFlow) {
      this.getConsignorRateDetails();
    }
    this.validateFOVRate();
    this.sum();
  }

  fieldFocusArticles(e, artilesScanPopupOpen) {
    this.totArticles = $("#" + this.pageId + "noOfArticles").val();
    if (e.keyCode == 13) {
      if ((this.totArticles != null) && (this.totArticles != '') && (parseInt(this.totArticles) > 1000)) {
        swal({
          title: "Validation",
          text: "Number of Articles is: " + this.totArticles + ". Is it correct???",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
          //                    dangerMode: true,
          //            buttons: ["Cancel", true],
          buttons: ["No", "Yes"],
        }).then((articleNext) => {
          if (articleNext) {
            if (this.lrScanAllow == true) {
              this.lrArtScanEvent(artilesScanPopupOpen);
            } else {
              this.packNatureField.nativeElement.focus();
            }
          } else {
            this.noOfArticlesField.nativeElement.focus();
          }
        });
      } else {
        if (this.lrScanAllow == true) {
          this.lrArtScanEvent(artilesScanPopupOpen);
        } else {
          this.packNatureField.nativeElement.focus();
        }
      }
      //CHG-V-LR:01
      if (this.consignorRateFlow) {
        this.getConsignorRateDetails();
      }
      this.validateFOVRate();
      this.sum();
    }
  }

  toggleEditableForEWayBillExempted(event) {
    if (event.target.checked) {
      this.eWayBillExempted = true;
    } else {
      this.eWayBillExempted = false;
    }
  }
  toggleEditableForTobeBilled(event) {
    if (event.target.checked) {
      this.toBeBilledParty = true;
    } else {
      this.toBeBilledParty = false;
    }
  }

  toggleEditableForAOC(event) {
    if (event.target.checked) {
      this.cbAoc = true;
      //            console.log( this.cbAoc );
    } else {
      this.cbAoc = false;
    }
  }
  toggleEditableForPickupLr(event) {
    if (event.target.checked) {
      this.cbPickUpLr = true;
    } else {
      this.cbPickUpLr = false;
    }
  }
  toggleEditableForUnloadByConsignee(event) {
    if (event.target.checked) {
      this.cbUnloadByConsignee = true;
    } else {
      this.cbUnloadByConsignee = false;
    }
  }
  toggleEditableForHandleWithCare(event) {
    if (event.target.checked) {
      this.cbHandleWithCare = true;
    } else {
      this.cbHandleWithCare = false;
    }
  }
  toggleEditableForCOD(event) {
    if (event.target.checked) {
      this.cbCOD = true;
      this.showCodChg = true;
      this.setCodCharge = 200;
      this.sum();
    } else {
      this.cbCOD = false;
      this.showCodChg = false;
      this.setCodCharge = 0;
      this.sum();
    }
  }
  toggleEditableForOrderGoods(event) {
    if (event.target.checked) {
      this.cbOrderGoods = true;
    } else {
      this.cbOrderGoods = false;
    }
  }

  toggleEditableForGatePass(event) {

    if (event.target.checked) {
      this.cbGatePass = true;
      //            console.log( this.cbGatePass );
    } else {
      this.cbGatePass = false;
    }
  }
  toggleEditableForCCAttached(event) {
    if (event.target.checked) {
      this.cbCCAttached = true;
    } else {
      this.cbCCAttached = false;
    }
  }
  //CHG-V-LR:03, CHG-V-LR:09
  toggleEditableForSelfLr(event) {
    if (event.target.checked) {
      // if (this.selectedDestination == 'chennai') {
      $("#" + this.pageId + "checkboxSelfLr").prop("checked", false);
      // swal("Not Allowed", "Self LR Booking is not allowed for Chennai destination, please contact Admin if any!", "warning");
      swal("Not Allowed", "Self LR Booking is not allowed, please contact Admin if any!", "warning");
      this.cbSelfLr = false;
      // } else {
      //     this.cbSelfLr = true;
      // }
    } else {
      this.cbSelfLr = false;
    }
  }



  lrDetailsAddFlow(content11) {
    //        alert( 'dd3' );
    //pending booking date,arrival date, bill date 
    this.enteredAutoLrNumber = $("#" + this.pageId + "autoLrNumber").val();
    this.enteredlrNumber = $("#" + this.pageId + "lrNumber").val();
    //      this.enteredBookingDate = $( "#bookingDate" ).val();
    //By Kamil
    let enterBookingDate = null;
    enterBookingDate = $("#" + this.pageId + "bookingDate").val();
    this.enteredBookingDate = this.datePipe.transform(enterBookingDate, "yyyy-MM-dd");
    //        this.enteredBookingDate = null;
    this.enteredSource = $("#" + this.pageId + "source").val();
    this.enteredActualWeight = $("#" + this.pageId + "actualWeight").val();
    this.enterdActWgt = this.enteredActualWeight;
    this.enteredChargeWeight = $("#" + this.pageId + "chargedWeight").val();
    this.enterdChgWgt = this.enteredChargeWeight;
    this.baseFreight = $("#" + this.pageId + "baseFreight").val();
    this.hamali = $("#" + this.pageId + "hamali").val();
    this.gcCharge = $("#" + this.pageId + "gcCharge").val();
    this.lcCharge = $("#" + this.pageId + "lcCharge").val();
    this.bcCharge = $("#" + this.pageId + "bcCharge").val();
    this.aocCharge = $("#" + this.pageId + "aocCharge").val();
    this.others = $("#" + this.pageId + "others").val();
    //        this.ddAmt = $( "#ddAmt" ).val();
    this.enteredRiskChg = $("#" + this.pageId + "fov").val();
    this.totArticles = $("#" + this.pageId + "noOfArticles").val();
    this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
    this.selectedNewConsigneeName = $("#" + this.pageId + "newConsignee").val();
    //Pending 
    this.selectedNewConsigneeName = this.partyDtoForNewConsigneeSet.consigneeName;
    this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
    this.selectedCommonCommodity = $("#" + this.pageId + "mainCommodity").val();
    this.selectedNewCommodity = $("#" + this.pageId + "newCommodity").val();
    this.selectedGoodsTypeCommodity = $("#" + this.pageId + "goodsType").val();
    this.enteredSubCommodity = $("#" + this.pageId + "subCommodity").val();
    this.selectedConsignorName = $("#" + this.pageId + "consignorName").val();
    //Pending 
    this.selectedNewConsignorName = $("#" + this.pageId + "newConsignor").val();
    this.selectedNewConsignorName = this.partyDtoForNewConsignorSet.consignorName;

    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.stateByDest = this.commaSplitDest[1];
    this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val();
    this.goodsValue = (this.enteredGoodsValue == null ? 0.0 : this.enteredGoodsValue == '' ? 0.0
      : this.enteredGoodsValue);

    this.enteredBillNumber = $("#" + this.pageId + "billNo").val();
    this.enteredClerkName = $("#" + this.pageId + "clerkName").val();
    //      this.enteredBillDate = $( "#billDate" ).val();
    //By Kamil
    let enterBillDate = null;
    enterBillDate = $("#" + this.pageId + "billDate").val();
    this.enteredBillDate = this.datePipe.transform(enterBillDate, "yyyy-MM-dd");
    //        this.enteredBillDate = null;
    this.enteredPrivateMarker = $("#" + this.pageId + "privateMarker").val();
    this.selectedconsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
    this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
    this.selectedconsignorMobileNumber = $("#" + this.pageId + "consignorMobNoExist").val();
    this.selectedconsigneeMobileNumber = $("#" + this.pageId + "consigneeMobNoExist").val();
    this.paymentType = $("#" + this.pageId + "paymentType").val();
    this.selectedDoorDelivery = $("#" + this.pageId + "doorDeliveryMode").val();
    this.ddAmt = $("#" + this.pageId + "ddAmt").val();
    this.enteredDDAddress = this.ddAddressTexArea;
    this.selectedPackNature = $("#" + this.pageId + "packNature").val();
    this.selectedSerTaxPayingBy = $("#" + this.pageId + "serTaxPayingBy").val();
    this.selectedBankLr = $("#" + this.pageId + "bankLr").val();

    this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
    this.enteredEWayBillNo = this.enteredEWayBillNo == null ? null : this.enteredEWayBillNo == '' ? null
      : this.enteredEWayBillNo;

    this.enteredChargedWeightLR = $("#" + this.pageId + "articleChargedWeightExtra").val();
    this.selectedEDeclarationId = $("#" + this.pageId + "edeclarationId").val();
    this.enteredEDeclarationCommodity = $("#" + this.pageId + "edeclarationCommodity").val();
    this.enteredBankName = $("#" + this.pageId + "bankName").val();
    //        this.selectedUserGodown = $( "userGodown" ).val();
    this.selectedUserGodown = this.defaultGodownName;
    this.enteredRemarks = this.remarksTexArea;
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();
    this.enteredChallanNo = $("#" + this.pageId + "challanNo").val();
    //      this.enteredArrivalDate = $( "#arrivalDate" ).val();
    //By Kamil
    let enterArrivalDate = $("#" + this.pageId + "arrivalDate").val();
    //alert( 'before:' + enterArrivalDate );
    this.enteredArrivalDate = this.datePipe.transform(enterArrivalDate, "yyyy-MM-dd");
    //alert( 'after:' + this.enteredArrivalDate );
    //        this.enteredArrivalDate = null;
    this.enteredVehicleNumber = $("#" + this.pageId + "vehicleNumber").val();
    this.enteredDriverName = $("#" + this.pageId + "driverName").val();
    this.selectedLoaderHead = $("#" + this.pageId + "loaderHead").val();
    this.autoLrLoaderHead = $("#" + this.pageId + "loaderHead").val();
    this.entereCODCharge = this.setCodCharge;
    this.enteredLocalChallanKanta = $("#" + this.pageId + "localChallanKanta").val();
    this.enteredChallanTotLrs = $("#" + this.pageId + "challanTotLrs").val();
    this.selectedLoaderHead = $("#" + this.pageId + "loaderHead").val();
    //New Added by basha 
    this.selectedBayNumber = $("#" + this.pageId + "bayNumber").val();
    let enterBayUnloadingDate = $("#" + this.pageId + "bayUnloadingDate").val();
    this.enteredBayUnloadingDate = this.datePipe.transform(enterBayUnloadingDate, "yyyy-MM-dd");
    this.selectedBayUnloadingTime = $("#" + this.pageId + "bayUnloadingTime").val();
    let enterEwayBillValidUptoDate = $("#" + this.pageId + "eWayBillValidUptoDate").val();
    this.enteredEwayBillValidUptoDate = this.datePipe.transform(enterEwayBillValidUptoDate, "yyyy-MM-dd");
    this.lrtDtoForSave = new LRDto();

    if (this.isNextLrAllow == true) {
      //            alert( 'dd6' );
      this.isNextLrAllow = false;
      if ((this.viewByAutoLr == true) || (this.lrCheck == true)) {
        this.lrtDtoForSave.lrNumber = this.enteredAutoLrNumber;
        //                console.log( this.enteredAutoLrNumber );
      } else {
        if (this.isLRCode == true) {
          this.lrtDtoForSave.lrNumber = this.showLrCodeInputFields + this.enteredlrNumber;

        } else {
          this.lrtDtoForSave.lrNumber = this.enteredlrNumber;
        }
      }

      this.lrtDtoForSave.bookingDate = this.enteredBookingDate;
      this.lrtDtoForSave.source = this.enteredSource;
      //Pending Delivery godown
      this.lrtDtoForSave.deliverygodown = this.lrtDtoForSave.deliverygodown;
      this.lrtDtoForSave.actualWeight = this.enteredActualWeight;
      this.lrtDtoForSave.chargedWeight = this.enteredChargeWeight;
      this.lrtDtoForSave.goodsType = "";
      ///Need to check this code  while saving Pending
      if ((this.enterdChgWgt) >= (this.enterdActWgt * 2)) {
        this.lrtDtoForSave.goodsType = "Bulky";
      } else if ((this.enterdChgWgt) == (this.enterdActWgt)) {
        this.lrtDtoForSave.goodsType = "Actual";
      } else {
        this.lrtDtoForSave.goodsType = "Semi Bulky";
      }
      this.lrtDtoForSave.financialYear = this.userDataDtoReturnSession.financialYear;
      this.lrtDtoForSave.hamali = 0.0;
      this.lrtDtoForSave.serviceCharge = 0.0;
      this.lrtDtoForSave.baseFreight = (this.baseFreight == null ? 0.0 : this.baseFreight == '' ? 0.0 : this.baseFreight);
      this.lrtDtoForSave.hamaliLR = (this.hamali == null ? 0.0 : this.hamali == '' ? 0.0 : this.hamali);
      this.lrtDtoForSave.gcCharge = (this.gcCharge == null ? 0.0 : this.gcCharge == '' ? 0.0 : this.gcCharge);
      this.lrtDtoForSave.aoc = (this.aocCharge == null ? 0.0 : this.aocCharge == '' ? 0.0 : this.aocCharge);
      //            this.lrtDtoForSave.insurance= 0.0;
      this.lrtDtoForSave.others = (this.others == null ? 0.0 : this.others == '' ? 0.0 : this.others);
      this.lrtDtoForSave.lcChg = (this.lcCharge == null ? 0.0 : this.lcCharge == '' ? 0.0 : this.lcCharge);
      this.lrtDtoForSave.bcChg = (this.bcCharge == null ? 0.0 : this.bcCharge == '' ? 0.0 : this.bcCharge);
      this.lrtDtoForSave.riskCharge = (this.enteredRiskChg == null ? 0.0 : this.enteredRiskChg == '' ? 0.0 : this.enteredRiskChg);
      this.lrtDtoForSave.userName = this.userDataDtoReturnSession.userId;
      this.lrtDtoForSave.totalArticles = this.totArticles;

      if (this.selectedConsigneeName != null && this.selectedConsigneeName == "Add New") {
        this.lrtDtoForSave.consigneeName = this.selectedNewConsigneeName;
        //Consignee Id Is Pending;
        this.lrtDtoForSave.consigneeId = this.selectedConsigneeId;
      } else {
        this.lrtDtoForSave.consigneeName = this.selectedConsigneeName;
        this.lrtDtoForSave.consigneeId = this.selectedConsigneeId;
      }

      this.partyGoodsType = null, this.goodsType = null, this.newCommodity = null;

      this.partyGoodsType = this.selectedPartyCommodity;
      if (this.showGoodsType == true) {
        this.goodsType = this.selectedGoodsTypeCommodity;
      }
      if (this.showNewCommodity == true) {
        this.newCommodity = this.selectedNewCommodity;
      }

      if ((this.partyGoodsType == "Add New")
        && (this.partyGoodsType != null) && (this.goodsType != null)
        && (this.goodsType == "Add New")) {
        this.lrtDtoForSave.reportMode = "consignCommodityUpdate"
        this.lrtDtoForSave.column2 = this.newCommodity;

      } else if ((this.partyGoodsType == "Add New")
        && (this.partyGoodsType != null) && (this.goodsType != null)
        && (this.goodsType != "Add New")) {
        this.lrtDtoForSave.reportMode = "consigneeUpdate"
        this.lrtDtoForSave.column2 = this.goodsType;

      } else if ((this.partyGoodsType != "Add New")
        && (this.partyGoodsType != null)) {
        this.lrtDtoForSave.column2 = this.partyGoodsType;
      }
      this.lrtDtoForSave.commonCommodity = this.selectedCommonCommodity;

      if (this.showSubCommodity == true) {
        if (this.enteredSubCommodity == null || this.enteredSubCommodity == '') {
          this.lrtDtoForSave.column1 = null;
        } else {
          this.lrtDtoForSave.column1 = this.enteredSubCommodity;
        }

      }

      if (this.selectedConsignorName != null && this.selectedConsignorName == "Add New") {
        this.lrtDtoForSave.consignorName = this.selectedNewConsignorName;
        //Consignor Id Is Pending;
        this.lrtDtoForSave.consignorId = this.selectedConsignorId;
      } else {
        this.lrtDtoForSave.consignorName = this.selectedConsignorName;
        this.lrtDtoForSave.consignorId = this.selectedConsignorId;
      }
      //            console.log( 'localPending :' + this.userDataDtoReturnSession.isLocalPending );
      this.lrtDtoForSave.isLocalPending = this.userDataDtoReturnSession.isLocalPending;

      this.lrtDtoForSave.destination = this.destSplit;
      //check To bebilled Party pending 
      this.lrtDtoForSave.toBeBilledParty = this.toBeBilledParty;
      this.lrtDtoForSave.branch = this.userDataDtoReturnSession.office;
      this.lrtDtoForSave.goodsValue = this.goodsValue;
      this.lrtDtoForSave.billNumber = this.enteredBillNumber;
      this.lrtDtoForSave.clerkName = this.enteredClerkName;
      this.lrtDtoForSave.billDate = this.enteredBillDate;
      this.lrtDtoForSave.privateMarker = this.enteredPrivateMarker;
      this.lrtDtoForSave.gstNoConsignor = this.selectedconsignorGSTNumber;
      this.lrtDtoForSave.gstNoConsignee = this.selectedconsigneeGSTNumber;

      this.lrtDtoForSave.consignorMobileNo = this.selectedconsignorMobileNumber;
      this.lrtDtoForSave.consigneeMobileNo = this.selectedconsigneeMobileNumber;

      this.totalAmtNew = (+(this.baseFreight == null ? 0.0 : this.baseFreight == '' ? 0.0 : this.baseFreight)
        + +(this.hamali == null ? 0.0 : this.hamali == '' ? 0.0 : this.hamali)
        + +(this.gcCharge == null ? 0.0 : this.gcCharge == '' ? 0.0 : this.gcCharge)
        + +(this.lcCharge == null ? 0.0 : this.lcCharge == '' ? 0.0 : this.lcCharge)
        + +(this.bcCharge == null ? 0.0 : this.bcCharge == '' ? 0.0 : this.bcCharge)
        + +(this.aocCharge == null ? 0.0 : this.aocCharge == '' ? 0.0 : this.aocCharge)
        + +(this.others == null ? 0.0 : this.others == '' ? 0.0 : this.others)
        + +(this.ddAmt == null ? 0.0 : this.ddAmt == '' ? 0.0 : this.ddAmt)
        + +(this.enteredRiskChg == null ? 0.0 : this.enteredRiskChg == '' ? 0.0 : this.enteredRiskChg)
        + +(this.entereCODCharge == null ? 0.0 : this.entereCODCharge == '' ? 0.0 : this.entereCODCharge));
      this.lrtDtoForSave.frieghtAmt = this.totalAmtNew;

      ///For Paid or Topay
      if (this.paymentType == 'toPay') {
        this.lrtDtoForSave.toPay = this.totalAmtNew;
        this.lrtDtoForSave.paid = 0.0;
        this.isPaidLr = false;
      } else {
        this.isPaidLr = true;
        this.lrtDtoForSave.toPay = 0.0
        this.lrtDtoForSave.paid = this.totalAmtNew;
      }
      ///For Door Delivery Mode
      this.lrtDtoForSave.ddAddress = null;
      if (this.selectedDoorDelivery == 'Yes') {
        //                console.log( 'a1 :' + this.selectedDoorDelivery );
        this.lrtDtoForSave.isDoorDeliv = true;
        //                this.lrtDtoForSave.DoorDelivAmt = ( this.ddAmt == null ? 0.0 : this.ddAmt == '' ? 0.0 : this.ddAmt );
        this.lrtDtoForSave.doorDeliveryAmount = (this.ddAmt == null ? 0.0 : this.ddAmt == '' ? 0.0 : this.ddAmt);

        //                console.log( 'a2 :' + this.lrtDtoForSave.doorDeliveryAmount );
        if ((this.viewByAutoLr == true) || (this.lrCheck == true)) {
          this.lrtDtoForSave.ddAddress = (this.enteredDDAddress == null ? null : this.enteredDDAddress == '' ? null : this.enteredDDAddress);
        }
      } else {
        this.lrtDtoForSave.isDoorDeliv = false;
        //                this.lrtDtoForSave.DoorDelivAmt = 0.0;
        this.lrtDtoForSave.doorDeliveryAmount = 0.0;

      }
      this.lrtDtoForSave.totalAmount = this.totalAmtNew;
      this.lrtDtoForSave.doorDelivChg = 0.0;
      this.lrtDtoForSave.packNature = this.selectedPackNature;


      this.lrtDtoForSave.withOut = this.cbAoc;
      //Pending  of listboxtpcommodity
      $("#" + this.pageId + "lrNumber").val('');
      this.lrtDtoForSave.serTaxPayBy = 0;


      if (this.cbPickUpLr == true) {
        this.lrtDtoForSave.isPickUpLR = true;
        this.lrtDtoForSave.pickLr = true;
      } else {
        this.lrtDtoForSave.isPickUpLR = false;
        this.lrtDtoForSave.pickLr = false;
      }
      this.lrtDtoForSave.isUnLoadByConsignee = this.cbUnloadByConsignee;
      this.lrtDtoForSave.isHandWithCare = this.cbHandleWithCare;

      //            console.log( this.cbPickUpLr, this.cbUnloadByConsignee, this.cbHandleWithCare, )

      if (this.paymentTypePaid == true) {
        if (this.selectedSerTaxPayingBy == 'Consignor') {
          this.lrtDtoForSave.serTaxPayBy = 1;
        } else if (this.selectedSerTaxPayingBy == 'GTA') {
          this.lrtDtoForSave.serTaxPayBy = 2;
        }
      }

      // Multiple Articles
      if (this.multiArtTableDataList == null) {
        this.lrtDtoForSave.listOfmultipleArt = null;
      } else {
        this.lrtDtoForSave.listOfmultipleArt = this.multiArtTableDataList;
      }


      //Pending Multiple pack nature 
      if (this.multiPackNatureTableDataList == null) {
        this.lrtDtoForSave.listOfmultiplePack = null;
      } else {
        this.lrtDtoForSave.listOfmultiplePack = this.multiPackNatureTableDataList;
      }
      console.log(this.lrtDtoForSave.listOfmultiplePack);
      // multiple e way bill
      // the below logic to save of non popup input value

      //CHG-V-LR:25
      // this.newAttributeEwayBillToPush.eWayBillNo = this.enteredEWayBillNo;
      // this.newAttributeEwayBillToPush.ewayBillValidUpto = this.enteredEwayBillValidUptoDate;
      // this.multiEwayTableDataList.push(this.newAttributeEwayBillToPush);

      if (this.enteredEWayBillNo != null) {
        this.newAttributeEwayBillToPush.eWayBillNo = this.enteredEWayBillNo;
        this.newAttributeEwayBillToPush.ewayBillValidUpto = this.enteredEwayBillValidUptoDate;
      }

      //CHG-V-LR:40 start
      // if (this.newAttributeEwayBillToPush.eWayBillNo) {
      //   this.multiEwayTableDataList.push(this.newAttributeEwayBillToPush);
      // }

      const existingIndex = this.multiEwayTableDataList.findIndex(
        (item) => item.eWayBillNo === this.enteredEWayBillNo
      );

      if (existingIndex !== -1) {
        this.multiEwayTableDataList.splice(existingIndex, 1, this.newAttributeEwayBillToPush);
      } else {
        this.multiEwayTableDataList.push(this.newAttributeEwayBillToPush);
      }
      //CHG-V-LR:40 End

      if (this.multiEwayTableDataList == null) {
        this.lrtDtoForSave.listOfmultipleEwayBill = null;
      } else {
        this.lrtDtoForSave.listOfmultipleEwayBill = this.multiEwayTableDataList;

      }
      console.log("this.multiEwayTableDataList.length");
      console.log(this.multiEwayTableDataList.length);
      if (this.multiEwayTableDataList.length > 1) {
        this.lrtDtoForSave.isMultiEwayBill = true;
      } else {
        this.lrtDtoForSave.isMultiEwayBill = false;
      }


      this.lrtDtoForSave.validBookDate = false;
      if ((this.viewByAutoLr == true) && (this.validateBkgDateClicked == true)) {
        this.lrtDtoForSave.validBookDate = true;
      }
      this.lrtDtoForSave.isEwayBillExempted = this.eWayBillExempted;
      this.lrtDtoForSave.gatePass = false;
      this.lrtDtoForSave.ccAttach = false;
      this.lrtDtoForSave.selfLr = false;
      this.lrtDtoForSave.bankLr = null;
      this.lrtDtoForSave.eSugamNo = null;
      this.lrtDtoForSave.chgWtLr = 0.0;
      this.lrtDtoForSave.isOrderGoods = false;

      if (this.selectedBankLr == 'Yes') {
        this.lrtDtoForSave.isBankLr = "1";
      } else {
        this.lrtDtoForSave.isBankLr = "0";
      }
      this.lrtDtoForSave.mainstation = this.userDataDtoReturnSession.mainAdminStation;
      this.lrtDtoForSave.isCod = this.cbCOD;
      this.lrtDtoForSave.codChg = this.entereCODCharge == null ? 0.0 : this.entereCODCharge == '' ? 0.0 : this.entereCODCharge;
      this.lrtDtoForSave.eWayBillNo = this.enteredEWayBillNo;
      this.lrtDtoForSave.isOrderGoods = this.cbOrderGoods;
      //New Added by basha 
      this.lrtDtoForSave.ewayBillValidUpto = this.enteredEwayBillValidUptoDate;
      this.lrtDtoForSave.consignorEInvoice = this.enteredConsignorEInvoiceNo;
      this.lrtDtoForSave.consignorEInvoiceEnable = this.consignorEInvoiceEnableMode;

      //CHG-V-LR:01
      if (this.consignorRateFlow) {
        this.lrtDtoForSave.consignorRateId = this.consignorRateId;
      }
      this.lrtDtoForSave.isAutoLR = false;
      //Chg_V-Lr:30
      this.lrtDtoForSave.isSizeExceeded = this.isSizeCommodityExceed;
      //Chg_V-Lr:32
      this.lrtDtoForSave.isPerRateKg = this.isPerKgLimitTaken;
      if ((this.viewByAutoLr == true) || (this.lrCheck == true)) {
        this.lrtDtoForSave.arrivalDate = null;
        this.lrtDtoForSave.challanNo = null;
        this.lrtDtoForSave.agentName = this.userDataDtoReturnSession.mainStation;
        // dummy for Auto LR
        // Entry
        this.lrtDtoForSave.vehicleNumber = null;
        this.lrtDtoForSave.driverName = null;
        //this.lrtDtoForSave.unloaderHead = null;
        //done by basha on 30/03/2021 as per new 
        if (this.selectedLoaderHead == null || this.selectedLoaderHead == '') {
          this.lrtDtoForSave.unloaderHead = null
        } else {
          this.lrtDtoForSave.unloaderHead = this.selectedLoaderHead;

        }
        // Newly Introduced Bay Flow
        //New Added by basha 
        if (this.bayFlow == true) {
          this.lrtDtoForSave.bayNo = this.selectedBayNumber;
          this.lrtDtoForSave.bayUnloadingDate = this.enteredBayUnloadingDate;
          /*String unloadingTime = tfBayUnloadingTime.getValue() == null ? null
                  : (dateTimeFormat.format(tfBayUnloadingTime
                          .getValue().getDate()));
          lrDto.setBayUnloadingTime(unloadingTime);*/
          this.lrtDtoForSave.bayUnloadingTime = this.selectedBayUnloadingTime;

        }

        //get all scanned barcode values
        console.log('lrScanAllow : ' + this.lrScanAllow);
        if (this.lrScanAllow == true) {
          console.log('scan : 1');
          this.getLRBarcodeValues();
        }
        this.lrtDtoForSave.listOfLrBarcodes = this.saveBarcodeValueLrDtoList;

        this.lrtDtoForSave.isAutoLR = true;

        if (this.cbGatePass == true) {
          //                    console.log( 'gatPass' + this.cbGatePass );
          this.lrtDtoForSave.gatePass = true;
        }


        if (this.cbCCAttached == true) {
          //                    console.log( 'cbCCAttached' + this.cbCCAttached );
          this.lrtDtoForSave.ccAttach = true;
        }


        if (this.cbSelfLr == true) {
          //                    console.log( 'cbSelfLr' + this.cbSelfLr );
          this.lrtDtoForSave.selfLr = true;
        }

        /// Pending bank Lr,e declaration commodity and Esugam is not Added in dto due to removal Pending
        //                e declaration commodity pending 
        this.lrtDtoForSave.chgWtLr = (this.enteredChargedWeightLR == null ? null : this.enteredChargedWeightLR == '' ? null : this.enteredChargedWeightLR);

        if (this.enteredEDeclarationCommodity != null && this.enteredEDeclarationCommodity != '') {
          this.lrtDtoForSave.eDeclarationCommodity = this.enteredEDeclarationCommodity;
          //                    console.log( this.lrtDtoForSave.eDeclarationCommodity );
        }

        if (this.selectedEDeclarationId == 'Consignor') {
          this.lrtDtoForSave.eDeclarationBy = "1";
        } else if (this.selectedEDeclarationId == 'GTA') {
          this.lrtDtoForSave.eDeclarationBy = "0";
        }
        //                console.log( this.lrtDtoForSave.eDeclarationBy );

        this.lrtDtoForSave.bankName = (this.enteredBankName == null ? null : this.enteredBankName == '' ? null : this.enteredBankName);
        this.lrtDtoForSave.godownEntry = this.selectedUserGodown;
        this.lrtDtoForSave.remarks = this.enteredRemarks;
        //                alert( this.lrtDtoForSave.remarks );
        //                this.showSpinner=true;
        this.addingLrDetails(content11);
        //                alert( 'auto' );


      } else {
        //                alert( 'dd7' );
        this.lrtDtoForSave.isAutoLR = false;
        this.lrtDtoForSave.arrivalDate = this.enteredArrivalDate;
        if (this.enteredChallanNo == null || this.enteredChallanNo == '') {
          this.lrtDtoForSave.challanNo = null
        } else {
          this.lrtDtoForSave.challanNo = this.enteredChallanNo;
        }
        this.lrtDtoForSave.challanKantaWgt = (this.enteredLocalChallanKanta == null ? 0.0 : this.enteredLocalChallanKanta == '' ? 0.0 : this.enteredLocalChallanKanta);
        this.lrtDtoForSave.challanTotLrs = (this.enteredChallanTotLrs == null ? 0 : this.enteredChallanTotLrs == '' ? 0 : this.enteredChallanTotLrs);
        this.lrtDtoForSave.agentName = this.selectedAgentName;
        if (this.enteredVehicleNumber == null || this.enteredVehicleNumber == '') {
          this.lrtDtoForSave.vehicleNumber = null
        } else {
          this.lrtDtoForSave.vehicleNumber = this.enteredVehicleNumber;
        }
        if (this.enteredDriverName == null || this.enteredDriverName == '') {
          this.lrtDtoForSave.driverName = null
        } else {
          this.lrtDtoForSave.driverName = this.enteredDriverName;
        }

        if (this.selectedLoaderHead == null || this.selectedLoaderHead == '') {
          this.lrtDtoForSave.unloaderHead = null
        } else {
          this.lrtDtoForSave.unloaderHead = this.selectedLoaderHead;
          this.autoLrLoaderHead = this.lrtDtoForSave.unloaderHead;
        }
        // Newly Introduced Bay Flow
        //New Added by basha 
        if (this.bayFlow == true) {
          this.lrtDtoForSave.bayNo = this.selectedBayNumber;
          this.lrtDtoForSave.bayUnloadingDate = this.enteredBayUnloadingDate;
          /*String unloadingTime = tfBayUnloadingTime.getValue() == null ? null
                  : (dateTimeFormat.format(tfBayUnloadingTime
                          .getValue().getDate()));
          lrDto.setBayUnloadingTime(unloadingTime);*/
          this.lrtDtoForSave.bayUnloadingTime = this.selectedBayUnloadingTime;

        }
        //get all scanned barcode values
        console.log('lrScanAllow : ' + this.lrScanAllow);
        if (this.lrScanAllow == true) {
          console.log('scan : 1');
          this.getLRBarcodeValues();
        }
        this.lrtDtoForSave.listOfLrBarcodes = this.saveBarcodeValueLrDtoList;
        //                alert( 'dd8' );
        if (this.lrtDtoForSave.lrNumber != null) {
          if (this.lrtDtoForSave.lrNumber != 'MUMnull') {
            //                        alert( 'mul' );
            //                        this.showSpinner=true;
            this.addingLrDetails(content11);
            //this.lrtDtoForSave.lrNumber = null;
          }
        }
      }
    }
  }

  addingLrDetails = (content11) => {
    //                alert( 'ddd' );
    let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
    this.showSpinner = true;
    this.lrtDtoForSave.smsCredential = this.userDataDtoReturnSession.credentials;
    this.lrtDtoForSave.credentialsWhatsApp = this.userDataDtoReturnSession.credentialsWhatsApp;
    this.lrtDtoForSave.whatsAppApiKey = this.userDataDtoReturnSession.whatsAppApiKey;
    //CHG-V-LR:51
    this.lrtDtoForSave.whatsAppApiLRBookFromNoKaleyra = this.userDataDtoReturnSession.whatsAppApiLRBookFromNoKaleyra;
    this.lrtDtoForSave.whatsAppApiLrBkgTempNameKaleyra = this.userDataDtoReturnSession.whatsAppApiLrBkgTempNameKaleyra;
    this.lrtDtoForSave.whatsAppApiLRBookFromNoVerloop = this.userDataDtoReturnSession.whatsAppApiLRBookFromNoVerloop;
    this.lrtDtoForSave.whatsAppApiLrBkgTempNameVerloop = this.userDataDtoReturnSession.whatsAppApiLrBkgTempNameVerloop;

    this.lrtDtoForSave.companyId = this.userDataDtoReturnSession.companyId;
    console.log("this.lrtDtoForSave");
    console.log(this.lrtDtoForSave);
    this.masterReadService.addLRDetails(this.lrtDtoForSave).subscribe(
      (response) => {
        if (response.length == 0) {
          this.showSpinner = false;
          swal({
            title: "Alert",
            text: "Error while Adding Lr details",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.isNextLrAllow = true;
        } else {
          this.showSpinner = false;
          this.isNextLrAllow = true;
          this.lrDtoSaveRet = response;
          this.lrDtoSaveSet = this.lrDtoSaveRet;
          //                    console.log( this.lrDtoSaveSet );
          this.chgWgtReadOnly = false;

          if (this.lrDtoSaveSet.status == null || this.lrDtoSaveSet.status == '') {
            swal({
              title: "Alert",
              text: "Error While Adding LRDetails",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          } else if (this.lrDtoSaveSet.status != null
            && (this.lrDtoSaveSet.status ==
              "failed")) {
            swal({
              title: "Alert",
              text: "Error While Saving LRDetails",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          } else if (this.lrDtoSaveSet.status != null
            && (this.lrDtoSaveSet.status ==
              "InvalidBkgDate")) {
            swal({
              title: "Invalid Booking Date",
              text: "Entered Booking Date is Invalid, please verify it and save again!",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          } else {
            //send whatsapp msg
            if (this.userDataDtoReturnSession.lrbkgWhatsappMsg != null && this.userDataDtoReturnSession.lrbkgWhatsappMsg) {
              this.sendWhatsappMsg(this.lrtDtoForSave);
            }
            //   console.log( '1' );
            //   console.log( this.isPaidLr );
            if (((this.viewByAutoLr == true) && (this.lrDtoSaveSet.lrNumber != null)) || ((this.lrCheck == true) && (this.lrDtoSaveSet.lrNumber != null))) {
              this.getAutoLrDetailsInfo(content11);
              //        console.log( '2' );
            } else if (this.isPaidLr) {
              // adding cashmemo for manual lrs also as per Manoj
              // req @23/07/2020
              //     console.log( '4' );
              this.isPaidLrForManual = false;
              swal({
                title: "Cash Memo Confirmation",
                text: "Do you want to Save this LR :" + this.lrDtoSaveSet.lrNumber + " as PAID LR",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
              }).then((manualpaidLr) => {
                if (manualpaidLr) {
                  this.isPaidLrForManual = true;
                  this.lrDtoForBooking.lrNumber = this.lrDtoSaveSet.lrNumber;
                  this.lrDtoForBooking.mode = 'lrEntry';
                  localStorage.clear();
                  //   console.log( this.lrDtoForBooking );
                  localStorage.setItem('showBkgCashmemo', JSON.stringify(this.lrDtoForBooking));
                  this.modalService.open(content11, { centered: true, windowClass: "myCustomModalClass" });
                } else {
                  this.showformanual();
                }
              });

            }

            if ((this.checkAutoLr == false) && (this.checkAutoLrHold == false) && (this.isPaidLr) && (!this.isPaidLrOnly)) {
              this.showformanual();
            }


            //                        setTimeout(() => 
            //                        {
            //                            this.showformanual();
            //                        },
            //                        5000);



            //                        console.log( '111 ' + this.checkAutoLr );
            //                        console.log( '222 ' + this.checkAutoLrHold );
            if ((this.checkAutoLr == false) && (this.checkAutoLrHold == false) && (!this.isPaidLr)) {
              swal({
                title: "Success",
                text: "LR Details Saved Successfully",
                icon: "success",
                closeOnClickOutside: false,
                closeOnEsc: false,
              }).then(() => {
                this.clearInputFields();
                //                              this.destinationField.nativeElement.focus();
                //                                window.setTimeout( function() {
                //                                    $( "#destination" ).focus();
                //                                }, 100 );
                if (this.restrictUserGodownList) {

                  window.setTimeout(function () {
                    // CHG-V-LR:02
                    // $("#lrefcdestination").focus();
                    $("#lrefcentryPinCode").focus();
                  }, 100);

                } else if ((this.userDataDtoReturnSession.mainAdminStation != null)
                  && (MainAdminStation == 'delhi')
                  && ((this.checkAutoLr == true) || (this.checkAutoLrHold == true))) {

                  window.setTimeout(function () {
                    $("#lrefcloaderHead").focus();
                  }, 100);
                } else if ((this.userDataDtoReturnSession.mainAdminStation != null)
                  && (MainAdminStation == 'delhi')
                  && ((this.checkAutoLr == false) || (this.checkAutoLrHold == false))) {

                  window.setTimeout(function () {
                    $("#lrefcnoOfArticles").focus();
                  }, 100);
                } else {
                  window.setTimeout(function () {
                    // CHG-V-LR:02
                    // $("#lrefcdestination").focus();
                    $("#lrefcentryPinCode").focus();
                  }, 100);

                }
              });

            }
            this.lrCount = +this.lrCount + +1;
            this.lblLRCount = true;
            this.txtLrCount = "No.Of LRs : " + this.lrCount;
            //                        For testing below line is commented Pending
            //                        this.clearInputFields();
            $("#" + this.pageId + "finishBtn").prop("disabled", false);
            //                        this.destinationField.nativeElement.focus();
            this.paymentType = $("#" + this.pageId + "paymentType").val();
            if (this.paymentType === 'paid') {
              this.paymentTypePaid = true;
            } else {
              this.paymentTypePaid = false;
            }
            //pending
            //                        nfDDAmt.clear();
            //                        if ( radDDNo.getValue() ) {
            //                            nfDDAmt.setVisible( false );
            //                            ddAddress.setVisible( false );
            //                        } else {
            //                            nfDDAmt.setVisible( true );
            //                            if ( ( cbAutoLR.getValue() ) || ( lrCheck == true ) ) {
            //                                ddAddress.setVisible( true );
            //                            }
            //                        }

            //CHG-V-LR:02
            // if (this.viewSubstation) {
            //     this.getDestinationDetails();
            //     this.viewSubstation = false;
            // }
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  showformanual() {
    let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
    if ((this.checkAutoLr == false) && (this.checkAutoLrHold == false)) {
      swal({
        title: "Success",
        text: "LR Details Saved Successfully",
        icon: "success",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.clearInputFields();
        //                              this.destinationField.nativeElement.focus();
        //                                window.setTimeout( function() {
        //                                    $( "#destination" ).focus();
        //                                }, 100 );
        if (this.restrictUserGodownList) {

          window.setTimeout(function () {
            // CHG-V-LR:02
            // $("#lrefcdestination").focus();
            $("#lrefcentryPinCode").focus();
          }, 100);

        } else if ((this.userDataDtoReturnSession.mainAdminStation != null)
          && (MainAdminStation == 'delhi')
          && ((this.checkAutoLr == true) || (this.checkAutoLrHold == true))) {

          window.setTimeout(function () {
            $("#lrefcloaderHead").focus();
          }, 100);
        } else if ((this.userDataDtoReturnSession.mainAdminStation != null)
          && (MainAdminStation == 'delhi')
          && ((this.checkAutoLr == false) || (this.checkAutoLrHold == false))) {

          window.setTimeout(function () {
            $("#lrefcnoOfArticles").focus();
          }, 100);
        } else {
          window.setTimeout(function () {
            // CHG-V-LR:02
            // $("#lrefcdestination").focus();
            $("#lrefcentryPinCode").focus();
          }, 100);

        }
      });

    }
  }


  getAutoLrDetailsInfo = (content11) => {

    this.masterReadService.getAutoLrDetails(this.lrDtoSaveSet).subscribe(
      (response) => {
        if (response.length == 0) {
          //                    alert( '' );
          swal({
            title: "Warning",
            text: "No Auto Lr Records Found",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.getAutoLrNo();
        } else {
          //                    if ( this.viewAutoLrHold == true ) {
          //                        console.log('aaa1212');
          //                        this.getAutomatedLrHoldDetails();
          //                    } else {
          //                        this.getAutoLrNo();
          //                    }
          this.resultDtosRet = response;
          this.resultDtos = this.resultDtosRet;

          if (this.isPaidLr) {

            swal({
              title: "Cash Memo Confirmation",
              text: "Do you want to Save this LR :" + this.resultDtos.lrNumber + " as PAID LR",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
              buttons: ["No", "Yes"],
            }).then((paidLr) => {
              if (paidLr) {
                //                         Pending  Of Booking Cashmemo
                //this.showBkgCashmemoPaid(content11);
                this.showBkgCashmemoPaidPopupNew();
                //                                this.modalService.open( content11, { centered: true, windowClass : "myCustomModalClass" });
              } else {
                this.setPrintLrs(this.resultDtos);
              }
            });

          } else {
            //                        console.log( '???/' );
            this.setPrintLrs(this.resultDtos);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };
  clearInputFields() {
    // CHG-V-LR:02
    $("#" + this.pageId + "entryPinCode").val('');
    this.controlDestination.reset();
    this.destinationOptions = [];
    this.destinationTA = [];
    this.isDefaultCftFlowDisableForLessParties = false;
    this.clearMulArt();
    this.chgWgtReadOnly = false;
    //                alert( 'a1' );
    //        $( 'input[type="text"],[type="number"]' ).val( '' );
    //        this.selectedDestination = null;
    //        //        $( "#destination" ).val( '' );
    //        //        $( "#gcCharge" ).val( '' );
    //        $( "#others" ).val( '' );
    //        this.commaSplitDest = null;
    //        this.destSplit = null;
    //        this.stateByDest = null;
    //        this.selectedAgentName = null;
    //        $( "#agentName" ).val( '' );
    //        this.enteredChallanNo = null;
    //        this.enteredChallanNoAt0 = null;
    //        $( "#challanNo" ).val( '' );
    //        this.enteredArrivalDate = null;
    //        this.selectedLoaderHead = null;
    //        $( "#loaderHead" ).val( '' );
    //        this.selectedUserGodown = null;
    //        $( "userGodown" ).val( '' );
    //        consignor
    this.cftRangeApplicableLR = false;
    this.selectedConsignorId = null;
    this.selectedConsigneeId = null;
    this.selectedconsignorGSTNumber = null;
    this.selectedconsignorMobileNumber = null;
    this.selectedconsigneeMobileNumber = null;
    $("#" + this.pageId + "consignorGSTNumber").val('');
    this.selectedConsignorName = null;
    $("#" + this.pageId + "consignorName").val('');
    this.selectedNewConsignorName = null;
    $("#" + this.pageId + "newConsignor").val('');
    $("#" + this.pageId + "consignorAddress").val('');
    $("#" + this.pageId + "consignorMobNoExist").val('');

    ///consignee 
    this.selectedconsigneeGSTNumber = null;
    $("#" + this.pageId + "consigneeGSTNumber").val('');
    this.selectedConsigneeName = null;
    $("#" + this.pageId + "consigneeName").val('');
    this.selectedNewConsigneeName = null;
    $("#" + this.pageId + "newConsignee").val('');
    this.selectedConsigneeAddress = null;
    $("#" + this.pageId + "consigneeAddress").val('');
    $("#" + this.pageId + "consigneeMobNoExist").val('');

    this.isConsignorMobileValid = false;
    ///Commodity
    this.selectedPartyCommodity = null;
    this.selectedCommonCommodity = null;
    this.isMainCommodityValid = false;
    $("#" + this.pageId + "partyCommodity").val('');
    this.selectedNewCommodity = null;
    $("#" + this.pageId + "newCommodity").val('');
    this.selectedGoodsTypeCommodity = null;
    $("#" + this.pageId + "goodsType").val('');
    this.enteredSubCommodity = null;
    $("#" + this.pageId + "subCommodity").val('');
    //        this.selectedPackNature = null;
    //        $( "#packNature" ).val( '' );
    //Door Delivery 
    //        $( "#doorDeliveryMode" ).val( 'No' );
    //        this.selectedDoorDelivery = null;
    this.ddAmt = '';
    $("#" + this.pageId + "ddAddress").val('');
    $("#" + this.pageId + "ddAmt").val('');
    this.enteredDDAddress = null;
    //        this.selectedDoorDelivery = $( "#doorDeliveryMode" ).val();

    //        if ( this.selectedDoorDelivery == 'Yes' ) {
    //            this.doorDeliveryYesddAmt= true;
    //            if ( ( this.viewByAutoLr == true ) || ( this.lrCheck == true ) ) {
    //                this.doorDeliveryYes = true;
    //            } else {
    //                this.doorDeliveryYes = false;
    //            }
    //        } else {
    //            $( "#doorDeliveryMode" ).val( 'No' );
    //            this.doorDeliveryYes = false;
    //            this.doorDeliveryYesddAmt=false;
    //        }

    //As per Manoj
    $("#" + this.pageId + "doorDeliveryMode").val('No');
    this.doorDeliveryYes = false;
    this.doorDeliveryYesddAmt = false;
    this.selectedDoorDelivery = null;

    ///Payment Type
    $("#" + this.pageId + "paymentType").val('toPay');
    this.paymentTypePaid = false;
    $("#" + this.pageId + "remarks").val('');
    $("#" + this.pageId + "bankLr").val('No');
    this.bankLrYes = false;
    ///For check box
    $("#" + this.pageId + "checkboxAoc").prop("checked", false);
    $("#" + this.pageId + "checkboxPickupLr").prop("checked", false);
    $("#" + this.pageId + "checkboxToBeBilled").prop("checked", false);
    $("#" + this.pageId + "checkboxUnloadedByConsignee").prop("checked", false);
    $("#" + this.pageId + "checkboxHandleWithCare").prop("checked", false);
    $("#" + this.pageId + "checkboxGatePass").prop("checked", false);
    $("#" + this.pageId + "checkboxCcAttach").prop("checked", false);
    $("#" + this.pageId + "checkboxSelfLr").prop("checked", false);
    $("#" + this.pageId + "checkboxCOD").prop("checked", false);
    $("#" + this.pageId + "checkboxOrderGoods").prop("checked", false);
    $("#" + this.pageId + "checkboxEwayBillExempted").prop("checked", false);


    this.showGoodsType = false;
    this.cbAoc = false;
    this.cbPickUpLr = false;
    this.cbUnloadByConsignee = false;
    this.cbHandleWithCare = false;
    this.cbCOD = false;
    this.cbOrderGoods = false;
    this.cbGatePass = false;
    this.cbCCAttached = false;
    this.cbSelfLr = false;
    this.eWayBillExempted = false;
    $("#" + this.pageId + "finishBtn").prop("disabled", false);

    //        this.controlAgentName.reset();
    //        this.controlLoaderHead.reset();
    this.controlPackNature.reset();
    this.controlPartyGoodsType.reset();
    this.controlConsignor.reset();
    this.controlConsignee.reset();
    //        this.controlDestination.reset();
    this.controlGoodsType.reset();

    this.consignorOptions = [];
    this.consigneeOptions = [];
    $("#" + this.pageId + "gcCharge").val(this.setGcCharge);
    $("#" + this.pageId + "others").val(this.setOthers);
    this.setDefaultCharges();
    this.getOffice = this.userDataDtoReturnSession.office;
    $("#" + this.pageId + "source").val(this.getOffice);
    this.showMultiArt = false;
    this.showNewCommodity = false;
    //Mulit Art Table 
    this.multiArtTableDataList = [];
    this.newAttribute = {};
    $("#" + this.pageId + "multiScanCode").val('');
    $("#" + this.pageId + "multiArtarticle").val('');
    $("#" + this.pageId + "multiArtlength").val('');
    $("#" + this.pageId + "multiArtbreadth").val('');
    $("#" + this.pageId + "multiArtheight").val('');
    $("#" + this.pageId + "multiArtActWgt").val('');
    $("#" + this.pageId + "multiArtChgWgt").val('');
    this.editTableId = null;

    this.partyMasterDtoForconsignor = new PartyMasterDto();
    this.partyMasterDtoForconsignee = new PartyMasterDto();
    this.partyDtoForConsigneeAllCom = new PartyMasterDto();
    this.consigneeAddressPartyDto = new PartyMasterDto();
    this.lrtDtoForAutoLr = new LRDto();
    this.autoLrRet = '';
    this.lrHold = null;
    this.ddAmtRange = 0.0;
    this.ddAmountRange = 0.0;
    this.enterdChgWgt = 0.0;
    this.ddAmtSys = 0;
    this.enteredChargeWeight = null;
    this.lrtDtoForFovRate = new LRDto();
    this.isFOVRateSet = false;
    this.fovChg = 0.0;
    this.riskPerc = 0.0;
    this.riskChg = 0.0;
    this.enteredGoodsValue = null;
    this.goodsValue = 0.0;
    this.totalAmt = 0.0;
    this.lrtDtoForChallan = new LRDto();
    this.lrCheck = false;
    this.showMultiArt = false;
    this.lrtDtoForMultiArt = new LRDto();
    this.isBlock = false;
    this.enterArticles = 0;
    this.totArticles = '';
    this.editSelected = false;
    this.lrtDtoForLrCheck = new LRDto();
    this.lrtDtoForValidateBkgDate = new LRDto();

    this.minDDAmt = 0.0;
    this.minDDPerc = 0.0;
    this.lrtDtoForSave = new LRDto();
    this.partyGoodsType = null;
    this.goodsType = null;
    this.newCommodity = null;
    this.totalAmtNew = 0.0;
    //    this.isPaidLr = false;
    this.selectedBankLr = null;
    this.statusOfCommodity = '';
    this.validateBkgDateClicked = true;
    $("#" + this.pageId + "validateBookingDate").prop("checked", true);
    this.enterdActWgt = 0.0;
    this.remarksTexArea = '';
    this.ddAddressTexArea = '';
    this.selectTodayArrival();
    this.selectTodayBooking();
    this.selectTodayBill();
    $("#" + this.pageId + "noOfArticles").val('');
    $("#" + this.pageId + "actualWeight").val('');
    $("#" + this.pageId + "chargedWeight").val('');
    $("#" + this.pageId + "goodsValue").val('');
    $("#" + this.pageId + "eWayBillNo").val('');
    $("#" + this.pageId + "edeclarationCommodity").val('');
    $("#" + this.pageId + "baseFreight").val('');
    $("#" + this.pageId + "hamali").val('');
    $("#" + this.pageId + "lcCharge").val('');
    $("#" + this.pageId + "bcCharge").val('');
    $("#" + this.pageId + "fov").val('');
    $("#" + this.pageId + "aocCharge").val('');
    $("#" + this.pageId + "totalAmount").val('');
    $("#" + this.pageId + "bankName").val('');
    $("#" + this.pageId + "privateMarker").val('');
    $("#" + this.pageId + "billNo").val('');
    $("#" + this.pageId + "clerkName").val('');
    $("#" + this.pageId + "codCharge").val('');
    this.setCodCharge = 0;
    this.isPaidLrOnly = false;
    this.clear();
    this.entereCODCharge = '';
    this.modelConsigneeName = '';
    this.modelConsignorName = '';
    this.selectTodayArrival();
    this.selectTodayBooking();
    this.selectTodayBill();
    this.selectBayDate();
    // Newly
    this.selectedBayNumber = '';
    $("#" + this.pageId + "bayNumber").val('');
    this.enteredBayUnloadingDate = '';
    this.selectedBayUnloadingTime = '';
    $("#" + this.pageId + "bayUnloadingTime").val('');
    $("#" + this.pageId + "eWayBillValidUptoDate").val('');
    this.enteredEwayBillValidUptoDate = '';
    this.tdyEWayBillValidUptoDate = null;
    this.multiEwayTableDataList = [];
    this.eWayBillValidUptoDateMultiModel = null;
    this.clearMulEway();
    this.newAttributeMultiEway = {};
    this.enteredMultiEwayBillNo = null;
    this.newAttributeEwayBillToPush = {};

    this.clearLrArtScan();

    this.saveBarcodeValueLrDto = new LRDto();
    this.saveBarcodeValueLrDtoList = [];
    this.consignorRateFlowSet = false;
    this.consignorRateId = 0;
    this.consignorIdRateId = 0;
    this.consigneeIdRateId = 0;
    this.consignorRateSrc = null;
    this.consignorRateFor = null;
    this.consignorRateConsignor = null;
    this.consignorRateDest = null;
    this.consignorRateCommodity = null;
    this.consignorRateType = null;
    this.consignorRatePerUnit = null;
    this.consignorRatePerUnitRateOnActWgt = null;
    this.consignorRateBasicRate = null;
    this.consignorRateHamali = null;
    this.consignorRateGcChg = null;
    this.consignorRateAoc = null;
    this.consignorRateOthers = null;
    this.consignorRateDDChg = null;
    this.consignorRateWEF = null;
    this.consignorRateConsignee = null;

    $("#" + this.pageId + "eInvNoConsignor").val('');
    this.enteredConsignorEInvoiceNo = null;
    this.consignorEInvoiceEnableMode = null;
    this.consignorEInvoiceEnabled = false;
    this.consignorEInvoiceNoEnabled = false;
    this.isDefaultCftFlowDisableForConsignor = false;
    this.isDefaultCftFlowDisableForConsignee = false;
    //CHG-V-LR:21
    this.consignorRateFov = null;
    //CHG-V-LR:22
    this.isFrieghtFieldsReadOnly = false;
    //Chg_V-Lr:30
    this.commodityKglimit = 0;
    this.isSizeCommodityExceed = false;
    //Chg_V-Lr:32
    this.isPerKgLimitTaken = false;
    this.newConsignorName = null;
  }

  onCancelClick(): void {
    this.dtTriggerMultiArtTable.unsubscribe();
  }
  onCancelClickPack(): void {
    this.dtTriggerMultiPackTable.unsubscribe();
  }


  validateReset() {
    this.selectedAgentName = null;
    $("#" + this.pageId + "agentName").val('');
    this.controlAgentName.reset();
    //        this.viewBkgCashmemo = true;
    //        this.showBkgCashmemoPaid();
  }

  validateBtnFinish() {
    this.clearInputFields();
    this.clearAll();
    this.isPaidLrOnly = false;
  }
  clearAll() {
    this.cftRangeApplicableLR = false;
    this.lrCount = 0;
    this.lblLRCount = false;
    this.isPaidLr = false;
    $("#" + this.pageId + "automatedLR").prop("checked", false);
    this.viewAutoLr = true;
    this.viewByAutoLr = false;
    this.viewAutoLrHold = true;
    this.lrDtoSaveSet = new LRDto();
    $("#" + this.pageId + "finishBtn").prop("disabled", true);
    $("#" + this.pageId + "destination").val('');
    this.controlDestination.reset();
    $('input[type="text"],[type="number"]').val('');
    this.selectedDestination = null;
    $("#" + this.pageId + "others").val('');
    this.commaSplitDest = null;
    this.destSplit = null;
    this.stateByDest = null;
    this.selectedAgentName = null;
    $("#" + this.pageId + "agentName").val('');
    this.enteredChallanNo = null;
    this.enteredChallanNoAt0 = null;
    $("#" + this.pageId + "challanNo").val('');
    this.enteredArrivalDate = null;
    this.selectedLoaderHead = null;
    $("#" + this.pageId + "loaderHead").val('');
    this.selectedUserGodown = null;
    $("userGodown").val('');
    this.challanAgentName = '';
    this.enteredVehicleNumber = '';
    this.enteredDriverName = '';
    this.controlAgentName.reset();
    this.controlLoaderHead.reset();
    this.getGCCharges = this.userDataDtoReturnSession.gcCharge;
    this.getOthers = this.userDataDtoReturnSession.others;
    if ((this.getGCCharges != null) && (this.getGCCharges != 0) && (this.getOthers != null) && (this.getOthers != 0)) {
      this.setGcCharge = this.getGCCharges;
      this.setOthers = this.getOthers;
      $("#" + this.pageId + "gcCharge").val(this.setGcCharge);
      $("#" + this.pageId + "others").val(this.setOthers);
    } else {
      $("#" + this.pageId + "gcCharge").val('');
      $("#" + this.pageId + "others").val('');
    }
    if (this.restrictUserGodownList) {
      this.defaultGodownName = this.userDataDtoReturnSession.office;
    } else {
      this.defaultGodownName = this.userDataDtoReturnSession.mainStation.toUpperCase();
    }

    $("#" + this.pageId + "localChallanKanta").val('');
    this.enteredLocalChallanKanta = '';
    this.selectedTruckNumber = '';
    this.modelVehicleNumber = '';
    this.userDataDto = new UserDataDto();
    this.viewPincode = false;
    this.enteredPincode = null;
    this.enteredConsignorEInvoiceNo = null;
    $("#" + this.pageId + "eInvNoConsignor").val('');
    //CHG-V-LR:41
    this.isCommodityBlockedBkg = false;

  }

  /*arrivalDateFocus( e ) {
      if ( e.keyCode == 13 ) {
  //            console.log( 'a' );
          this.challanNoField.nativeElement.focus();
      }
  }*/

  arrivalDateFocus(e) {
    if (e.keyCode == 13) {
      this.focusArrivalCalendar();
    }
  }


  loaderHeadFocus(e) {
    if (e.keyCode == 13) {
      if (this.bayFlow == true) {
        $("#" + this.pageId + "bayNumber").focus();
      } else {
        if ((this.checkAutoLr == true) || (this.checkAutoLrHold == true)) {
          window.setTimeout(function () {
            $("#lrefcdestination").focus();
          }, 100);
        } else {
          window.setTimeout(function () {
            $("#lrefcarrivalDate").focus();
          }, 100);
        }
      }

    }
  }
  consignorNameFocus(e) {
    if (e.keyCode == 13) {
      this.selectedConsignorName = $("#" + this.pageId + "consignorName").val();
      if (this.selectedConsignorName != null && this.selectedConsignorName == "Add New") {
        this.lblNewConsignor = true;
      } else {
        if ((this.selectedConsignorName != null) && (this.selectedConsignorName != "") && (this.selectedConsignorName != "ALL")) {
          this.consigneeGSTNumberField.nativeElement.focus();
        }
      }
    }
  }
  consigneeNameFocus(e, content10) {
    if (e.keyCode == 13) {
      this.selectedConsignee = $("#" + this.pageId + "consigneeName").val();
      //Setting of consignee Id On enter key is Pending and same for consignor also becuase we cant get the option and withour option we cant get the id 
      //            consignee and consignor
      if (this.selectedConsignee != null && this.selectedConsignee == "Add New") {
        this.lblNewConsignee = true;
        //                this.modalService.open( content10, { centered: true } );
        //                this.setValueForConsignee();
        //                $( "#newConsignee" ).focus();
      } else {
        if ((this.selectedConsignee != null) && (this.selectedConsignee != "") && (this.selectedConsignee != "ALL")) {
          this.getBangaloreConsigneeCommodityConsignee();
          this.getAllCommodityAsPerState();
          //                  this.partyGoodsTypeField.nativeElement.focus();
          //By Kamil
          window.setTimeout(function () {
            $("#lrefcpartyCommodity").focus();
          }, 100);
        }
      }

    }
  }
  partyCommodityFocus(e) {
    if (e.keyCode == 13) {
      //this.validateMainCommoditySelection();
      //if (this.isMainCommodityValid) {
      //            console.log( 'a' );
      this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
      //        console.log(this.selectedPartyCommodity);
      // if (this.selectedPartyCommodity != null && this.selectedPartyCommodity == "Add New") {
      //     this.showGoodsType = true;
      //     this.goodsTypeCommdityOptions = '';
      //     this.goodsTypeTA = [];
      //     this.statusOfCommodity = "forGlobalCommodityListBng";
      //     this.getBangaloreAllCommodityConsignee();
      //     //this.goodsTypeField.nativeElement.focus();
      //     $("#" + this.pageId + "goodsType").focus();
      // } else {

      this.showGoodsType = false;
      //this.subCommodityField.nativeElement.focus();
      $("#lrefcmainCommodity").focus();
      // }
      //}
      //CHG-V-LR:41
      this.commodityType = 'rateCommodity';
      this.callCommodityBlockedDetails();
      //CHG-V-LR:01
      if (this.consignorRateFlow) {
        this.getConsignorRateDetails();
      }
      this.validateFOVRate();
      this.sum();
      this.getCFTExemptionForCommoditiesDetails();
    }
  }

  mainCommodityFocus(e) {
    if (e.keyCode == 13) {
      this.selectedCommonCommodity = $("#" + this.pageId + "mainCommodity").val();
      this.subCommodityField.nativeElement.focus();
      //CHG-V-LR:41
      this.commodityType = 'actualCommodity';
      this.callCommodityBlockedDetails();
    }
  }

  goodsTypeFocus(e) {
    if (e.keyCode == 13) {
      this.selectedGoodsTypeCommodity = $("#" + this.pageId + "goodsType").val();

      if (this.selectedGoodsTypeCommodity != null && this.selectedGoodsTypeCommodity == "Add New") {
        this.showNewCommodity = true;
        this.newCommodityField.nativeElement.focus();
      } else {
        this.showNewCommodity = false;
        this.actualWeightField.nativeElement.focus();
      }
    }
  }

  eWayBillNoFocus(e, contentVehicleUpdate) {
    if (e.keyCode == 13) {
      this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
      if ((this.enteredEWayBillNo != null) && (this.enteredEWayBillNo != '')) {
        this.getDetailsFromEwayBillRefreshEwb(contentVehicleUpdate);
        if (this.showEDeclarationId == true) {
          this.edeclarationCommodityField.nativeElement.focus();
        } else {
          //     this.eWayBillValidUptoDateField.nativeElement.focus();
          $("#" + this.pageId + "eWayBillValidUptoDate").focus();
        }
      }
    }
  }

  eWayBillNoBlur(e) {
    // this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
    // if ((this.enteredEWayBillNo != null) && (this.enteredEWayBillNo != '')) {
    //   this.validateEwayBillRefreshEwb();
    // }
  }

  //BY Kamil
  doorDeliModeFocus(e, doorDelivery: string) {
    if (e.keyCode == 13) {
      if (doorDelivery == 'Yes') {
        window.setTimeout(function () {
          $("#lrefcddAmt").focus();
        }, 1000);
        this.doorDeliveryYes = true;
        this.sum();
      } else {
        if (this.showRiskCharge == true) {
          window.setTimeout(function () {
            $("#lrefccheckboxCOD").focus();
          }, 1000);
        } else {
          window.setTimeout(function () {
            $("#lrefcpaymentType").focus();
          }, 1000);

        }
        this.doorDeliveryYes = false;
        this.sum();
      }
    }
  }

  //doorDeliModeFocus( e, doorDelivery: string ) {
  //if ( doorDelivery == 'Yes' ) {
  //  this.doorDeliveryYes = true;
  //    this.sum();
  //      this.ddAmtField.nativeElement.focus();
  //    } else {
  //          this.doorDeliveryYes = false;
  //            this.sum();
  //           this.paymentTypeField.nativeElement.focus();

  //  }
  //}
  //    paymentTypeModeFocus( e, paymentType: string ) {
  //        if ( e.keyCode == 13 ) {
  //            if ( paymentType === 'paid' ) {
  //                this.paymentTypePaid = true;
  //                this.bankLrField.nativeElement.focus();
  //            } else {
  //                this.paymentTypePaid = false;
  //                this.bankLrField.nativeElement.focus();
  //            }
  //        }
  //    }
  //
  //    bankLrModeFocus( e, bankLr: string ) {
  //        if ( e.keyCode == 13 ) {
  //            if ( bankLr === 'Yes' ) {
  //                this.bankLrYes = true;
  //                this.bankNameField.nativeElement.focus();
  //            } else {
  //                this.bankLrYes = false;
  //                this.privateMarkerField.nativeElement.focus();
  //            }
  //        }
  //    }

  setPrintLrs(resultDtos) {
    console.log('setPrintLrs');
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.selectedDestination = this.destSplit.toLowerCase();
    this.selectedDestination = this.selectedDestination.trim();
    console.log(this.selectedDestination);
    console.log(this.stateByDest);
    swal({
      title: "Validation",
      text: "Sure You Want To Take LR Copies Print Out Of LR No:" + resultDtos.lrNumber,
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
    }).then((printLr) => {
      if (printLr) {
        //Pending 
        if (this.stateByDest == 'Kerala') {
          //Pending 
          //                new LrAutoAllPrintOut(resultDtos1, "ALL", "KL",
          //                        "original");
          this.resultDtos.lrEntryCopyType = 'ALL';
          this.resultDtos.lrEntryMode = 'KL';
          this.resultDtos.lrEntryPrintMode = 'original';
          this.lrAutoAllPrintOut(this.resultDtos);

        } else {
          //                    alert( 'JJJ' );
          //CHG-V-LR:04
          //CHG-V-LR:06
          //CHG-V-LR:12
          //revert to delhi again as per Musaddik bh
          let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
          //if (this.selectedDestination == 'chennai') {
          if (MainAdminStation != null && MainAdminStation == 'delhi') {
            this.resultDtos.lrEntryCopyType = 'ALL';
          } else {
            //CHG-V-LR:10
            if (this.resultDtos.ccCopyEnabled == true) {
              this.resultDtos.lrEntryCopyType = 'ALL';
            } else {
              this.resultDtos.lrEntryCopyType = 'CgnorLry';
            }
          }
          // } else {
          //     this.resultDtos.lrEntryCopyType = 'ALL';
          // }
          this.resultDtos.lrEntryMode = 'Others';
          this.resultDtos.lrEntryPrintMode = 'original';
          this.lrAutoAllPrintOut(this.resultDtos);
          //Pending 
          //                new LrAutoAllPrintOut(resultDtos1, "ALL",
          //                        "Others", "original");

        }
      } else {
        this.isPaidLr = false;
        this.selectedDestination = null;
        //                $( "#destination" ).val( '' );
        window.setTimeout(function () {
          // CHG-V-LR:02
          // $("#lrefcdestination").focus();
          $("#lrefcentryPinCode").focus();
        }, 100);
        //CHG-V-LR:52
        if (this.enableBarcodeGen) {
          swal({
            title: "Validation",
            text: "Sure You Want To Take Barcode Print:",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
          }).then((printbarcodes) => {
            if (printbarcodes) {
              this.generateBarcode(resultDtos);
            }
          });
        }

        this.clearInputFields();


        if (this.viewAutoLrHold == true) {
          this.getAutomatedLrHoldDetails();
        } else {
          this.getAutoLrNo();
        }

      }
    });
  }
  lrAutoAllPrintOut(resultDtos) {
    //      this.getUserValues();
    localStorage.clear();
    console.log('lrAutoAllPrintOut -1');
    localStorage.setItem('printAfterSaveForLrEntry', JSON.stringify(this.resultDtos));
    localStorage.setItem('printIsCftRangeLR', JSON.stringify(this.cftRangeApplicableLR));
    this.viewLrEntryPrint = true;
    this.lrEntryForm = false;

    // After printing the main LR entry
    window.addEventListener('afterprint', () => {
      console.log('lrAutoAllPrintOut -2');

      // Reset LR print view and show the LR entry form
      if (this.viewLrEntryPrint) {
        this.viewLrEntryPrint = false;
        this.lrEntryForm = true;

        // Ask for barcode print confirmation
        //CHG-V-LR:52
        if (this.enableBarcodeGen) {
          swal({
            title: "Validation",
            text: "Sure You Want To Take Barcode Print:",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
          }).then((printbarcodes) => {
            if (printbarcodes) {
              this.generateBarcode(resultDtos);
            }
          });
        }

        console.log('lrAutoAllPrintOut -3');
        this.changeDetectorRef.detectChanges();

        // Refocus and clear input fields
        setTimeout(() => {
          $("#lrefcentryPinCode").focus();
        }, 100);
        this.clearInputFields();

        // Check if viewAutoLrHold is enabled and handle accordingly
        if (this.viewAutoLrHold) {
          console.log('lrAutoAllPrintOut -4');
          this.getAutomatedLrHoldDetails();
          setTimeout(() => {
            $("#lrefcautomatedLRHold").prop("checked", true);
          }, 1000);
          setTimeout(() => this.setLoaderHeadVal(), 5000);
        } else {
          console.log('lrAutoAllPrintOut -5');
          this.getAutoLrNo();
          setTimeout(() => {
            $("#lrefcautomatedLR").prop("checked", true);
          }, 1000);
          setTimeout(() => this.setLoaderHeadVal(), 5000);
        }
      }
    }, { once: true });

    this.changeDetectorRef.detectChanges();
  }

  setLoaderHeadVal() {

    $("#" + this.pageId + "loaderHead").val(this.autoLrLoaderHead);
    this.autoLrLoaderHead = '';
  }
  afterPrint() {
    this.viewLrEntryPrint = false;
    this.lrEntryForm = true;
  }


  clear() {
    this.consignorOptionsRet = [];
    this.consigneeOptionsRet = [];

    this.consignorTA = [];
    this.consigneeTA = [];
    this.partyCommodityTA = [];

    this.showMainCommodity = false;
    this.selectedConsignee = '';

    this.bangaloreConsigneeCommdityOptions = '';
    this.allCommdityStateOptions = '';
    this.partyDtoForConsignee = new PartyMasterDto();

    //  lblNewConsignor = true;
    //  lblNewConsignee = true;

    this.consigneeAddressRet = '';
    this.resultDtos = new LRDto();
    this.resultDtosRet = '';
    this.fovRateValidateRet = '';
    this.cftCommodityExempValidateRet = '';
    this.baseFreight = '';
    this.hamali = '';
    this.gcCharge = '';
    this.lcCharge = '';
    this.bcCharge = '';
    this.aocCharge = '';
    this.insuranceCharge = '';
    this.others = '';
    this.enteredRiskChg = '';
    this.paymentType = '';
    this.enteredTotalAmt = '';
    this.enteredChargedWeight = '';
    this.ratePerChgWgt = '';
    this.actualWeight = '';
    this.chargedWeight = '';

    this.lrtDtoForChallanRet = '';
    this.enteredlrNumber = '';
    this.lrtDtoForMultiArtRetSet = new LRDto();
    this.multiArtArticle = '';
    this.getArticles = '';
    this.getGCCharges = 0.0;
    this.getOthers = 0.0;

    this.lrtDtoForLrCheckRet = '';
    this.lrtDtoForLrCheckSet = new LRDto();
    this.lrtDtoForMissAgentRet = '';
    this.lrtDtoForMissAgentSet = new LRDto();

    this.enteredBookingDate = '';
    this.enteredSource = '';
    this.enteredActualWeight = '';

    this.lrtDtoForValidateBkgDateRet = '';
    this.lrtDtoForValidateBkgDateSet = new LRDto();
    this.bookingDateGet = '';
    this.enteredEWayBillNo = '';
    this.toBeBilledParty = false;
    this.enteredAutoLrNumber = '';
    this.showSubCommodity = true;
    this.enteredBillNumber = '';
    this.enteredClerkName = '';
    this.enteredBillDate = '';
    this.enteredPrivateMarker = '';

    this.selectedSerTaxPayingBy = '';
    this.enteredChargedWeightLR = '';
    //Pending
    //  showEDeclarationId = false;
    //  selectedEDeclarationId: any;
    //  enteredEDeclarationCommodity: any;

    this.enteredBankName = '';
    //Pending how to clear the textarea
    this.enteredRemarks = '';
    this.lrDtoSaveRet = '';
    this.lrDtoSaveSet = new LRDto();

    this.mainAdminStationGet = '';
    //        this.challanAgentName = '';
    this.maxActWgt = '';
    this.maxChgWgt = '';
    this.subCommodityName = '';
    //        this.enteredVehicleNumber = '';
    //        this.enteredDriverName = '';
    if (this.viewByAutoLr == true) {
      //    console.log( 'so  :' + this.autoLrFieldValue );
      $("#" + this.pageId + "autoLrNumber").val(this.autoLrFieldValue);

      this.autoLrFieldValue = '';
    } else {
      this.autoLrFieldValue = '';
    }
    this.multiPackNatureTableDataList = [];
    this.newAttributeMultiPack = {};
    this.showMultiPackNature = false;
    this.controlPackNatureNew.reset();
    this.editTableIdMultiPack = null;
    $("#" + this.pageId + "multiPackNatureNew").val('');
    $("#" + this.pageId + "multiPackNatureArtilces").val('');
    $("#" + this.pageId + "hiddenIndexPackNature").val('');
    ///popupconginor and consignee 
    this.controlConsignorState.reset();
    this.controlConsigneeState.reset();
    this.ValidateClearNewConsignorDetails();
    this.ValidateClearNewConsigneeDetails();
    this.newConsignorStateCode = '';
    this.newConsigneeStateCode = '';
    this.partyDtoForNewConsignor = new PartyMasterDto();
    this.partyDtoForNewConsignorRet = '';
    this.partyDtoForNewConsignorSet = new PartyMasterDto();

    this.partyDtoForNewConsignee = new PartyMasterDto();
    this.partyDtoForNewConsigneeRet = '';
    this.partyDtoForNewConsigneeSet = new PartyMasterDto();
    this.setConsignorAddress = '';
    this.setConsigneeAddress = '';
  }

  addInTablePackNature() {
    this.newAttributeMultiPack.multiplePackNatureNew = $("#" + this.pageId + "multiPackNatureNew").val();
    console.log(this.newAttributeMultiPack.multiplePackNatureNew);
    this.newAttributeMultiPack.multiplePackNatureArt = $("#" + this.pageId + "multiPackNatureArtilces").val();

    if ((this.newAttributeMultiPack.multiplePackNatureNew != null) && (this.newAttributeMultiPack.multiplePackNatureNew != '') && (this.newAttributeMultiPack.multiplePackNatureArt != null) && (this.newAttributeMultiPack.multiplePackNatureArt != '')) {


      if (this.editTableIdMultiPack != null) {
        this.multiPackNatureTableDataList.splice(this.editTableIdMultiPack, 1);
      }
      if (this.multiPackNatureTableDataList == null) {
        this.multiPackNatureTableDataList = [];
      }
      $("#" + this.pageId + "timeTable").DataTable().destroy();
      if (this.multiPackNatureTableDataList.length == 0) {
        this.multiPackNatureTableDataList.push(this.newAttributeMultiPack);
      } else {

        let array = this.multiPackNatureTableDataList;
        this.multiPackNatureTableDataList.push(this.newAttributeMultiPack);


      }
      this.dtTriggerMultiPackTable.next();
      this.newAttributeMultiPack = {};
      $("#" + this.pageId + "multiPackNatureNew").val('');
      $("#" + this.pageId + "multiPackNatureArtilces").val('');

      $("#" + this.pageId + "hiddenIndexPackNature").val('');

      this.editTableIdMultiPack = null;
    } else {
      swal({
        title: "Warning",
        text: "Please Enter the Mandetory Fields",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

    }


  }
  rowSelectedEditPackNature(multiplePackNatureTableData, index) {
    $("#" + this.pageId + "multiPackNatureNew").val(multiplePackNatureTableData.multiplePackNatureNew);
    $("#" + this.pageId + "multiPackNatureArtilces").val(multiplePackNatureTableData.multiplePackNatureArt);

    $("#" + this.pageId + "hiddenIndexPackNature").val(index);
    this.editTableIdMultiPack = index;

  }
  rowSelectedDeletePackNature(index) {
    this.multiPackNatureTableDataList.splice(index, 1);
    $("#" + this.pageId + "multiPackNatureNew").val('');
    $("#" + this.pageId + "multiPackNatureArtilces").val('');

    $("#" + this.pageId + "hiddenIndexPackNature").val('');
  }

  multiPackDetailsTable() {

    //the first datatable starts
    this.dtOptionsMultiPackTable = {
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search..."
      },
      //place holder in search/filter in datatable ends
      processing: true,
      //scroll in datatable starts
      responsive: true,
      "scrollX": true,
      "scrollY": 280,
      "scrollCollapse": true,
      //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
      "paging": true,
      "info": true,
      pagingType: 'full_numbers',
      pageLength: 9,
    }
  }
  getMethodForgetStateDetails() {
    this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;

  }

  getStateDetails() {
    this.getMethodForgetStateDetails();
    this.masterReadService.getStateDetailsService(this.partyMasterDto).subscribe(
      (response) => {
        if (response) {
          this.stateDetailsList = response;
          this.consigneeStateOptions = this.stateDetailsList;
          this.consignorStateOptions = this.stateDetailsList;

        }
        this.changeDetectorRef.detectChanges();

      }), (error) => swal({
        title: "Error",
        text: "Server Error While Getting Message In Print Details",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }),
      () => console.log('done');
  }


  // Adding new consignor and Consignee 
  stateSelectedConsignor(option) {
    this.newConsignorStateCode = option.stateCode;
    //        this.consignorMobileNumberField.nativeElement.focus();
  }
  stateSelectedConsignee(option) {
    this.newConsigneeStateCode = option.stateCode;
    //        console.log( this.newConsigneeStateCode );


  }

  validateNewConsignor() {
    this.enteredNewConsignorGSTNo = $("#" + this.pageId + "gstNoConsignor").val();
    this.enteredNewConsignorState = $("#" + this.pageId + "consignorState").val();

    //        console.log( this.enteredNewConsignorState );
    var aadharValid = this.validateAadharNumberFormatConsignor();

    //CHG-V-LR:50
    this.enteredNewConsignorGSTNoPan = $("#" + this.pageId + "gstNoConsignor").val().toString().trim().replace(/\s+/g, '');

    if ((this.enteredNewConsignorGSTNo == null) || (this.enteredNewConsignorGSTNo = '')) {
      swal({
        title: "Mandatory Field",
        text: "Please enter New Consignor Aadhar number",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if ((this.enteredNewConsignorState == null) || (this.enteredNewConsignorState = '')) {
      swal({
        title: "Mandatory Field",
        text: "Please Select the State",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if ((this.consignorEInvoiceEnableMode == null || this.consignorEInvoiceEnableMode == '' || this.consignorEInvoiceEnableMode == undefined || this.consignorEInvoiceEnableMode == 'Select')) {
      swal({
        title: "Not Allowed",
        text: "Please select Consignor e-Invoice Mode",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
    //CHG-V-LR:50
    // else if (!aadharValid) {
    //   swal("Invalid", "Entered Aadhar Number is invalid, please enter the correct number!", "warning");
    // }
    //CHG-V-LR:50
    else if (($("#" + this.pageId + "selectType").val() == "aadhar") && (!aadharValid)) {
      swal("Invalid", "Entered Aadhar Number is invalid, please enter the correct number!", "warning");
    } else if (($("#" + this.pageId + "selectType").val() == "pan") && (!this.panValidation.test(this.enteredNewConsignorGSTNoPan))) {
      swal("Invalid", "Invalid PAN Number", "warning");
      return false;
    } else {
      this.saveNewConsignorDetails();
    }
  }
  saveNewConsignorInfo() {
    this.selectedNewConsignorName = $("#" + this.pageId + "newConsignor").val();
    this.enteredNewConsignorGSTNo = $("#" + this.pageId + "gstNoConsignor").val();
    this.enteredNewConsignorAddress = $("#" + this.pageId + "newConsignorAddress").val();
    this.enteredNewConsignorState = $("#" + this.pageId + "consignorState").val();
    this.enteredNewConsignorMobileNo = $("#" + this.pageId + "consignorMobileNumber").val();
    this.enteredNewConsignorOfficeNo = $("#" + this.pageId + "consignorOfficeNumber").val();

    this.partyDtoForNewConsignor.companyId = this.userDataDtoReturnSession.companyId;
    this.partyDtoForNewConsignor.consignorName = this.selectedNewConsignorName.toUpperCase();
    this.partyDtoForNewConsignor.address = this.enteredNewConsignorAddress;
    this.partyDtoForNewConsignor.officeNumber = this.enteredNewConsignorOfficeNo;
    this.partyDtoForNewConsignor.state = this.enteredNewConsignorState;
    this.partyDtoForNewConsignor.stateCode = this.newConsignorStateCode;
    this.partyDtoForNewConsignor.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    this.partyDtoForNewConsignor.consignorId = 0;
    this.partyDtoForNewConsignor.mobileNumber = this.enteredNewConsignorMobileNo;
    this.partyDtoForNewConsignor.gstNoConsignor = this.enteredNewConsignorGSTNo.toString().trim().replace(/\s+/g, '');
    this.partyDtoForNewConsignor.isConfirmed = false;
    this.partyDtoForNewConsignor.isLatest = true;
    this.partyDtoForNewConsignor.consignorEInvoiceEnable = this.consignorEInvoiceEnableMode;
    this.partyDtoForNewConsignor.userName = this.userDataDtoReturnSession.userName;

    //CHG-V-LR:47
    if ($("#" + this.pageId + "selectType").val() == "aadhar") {
      this.partyDtoForNewConsignor.consignorType = 'Aadhar';
    }
    //CHG-V-LR:50
    else if ($("#" + this.pageId + "selectType").val() == "pan") {
      this.partyDtoForNewConsignor.consignorType = 'Pan';
    }
  }

  saveNewConsignorDetails = () => {
    this.saveNewConsignorInfo();
    console.log(this.partyDtoForNewConsignor);
    this.masterReadService.addConsignorInLrEntry(this.partyDtoForNewConsignor).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "Error while saving the new Consignor Details",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {

          this.partyDtoForNewConsignorRet = response;
          this.partyDtoForNewConsignorSet = this.partyDtoForNewConsignorRet;
          if (this.partyDtoForNewConsignorSet.status == 'exist') {
            swal({
              title: "Failure",
              text: "Consignor Already Exist!",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          } else if (this.partyDtoForNewConsignorSet.status == 'not persisted') {
            swal({
              title: "Failure",
              text: "Ooops..Problem occur while saving Consignor Details..!",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          } else {
            swal({
              title: "Success",
              text: "New Consignor Name added successfully",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
            this.getConsignor();
          }


        }
        this.changeDetectorRef.detectChanges();
      }), (error) => swal({
        title: "Error",
        text: "Ooops..Problem occur while saving Consignor Details..",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }),
      () => console.log('done');
  };

  getConsignor() {
    this.selectedConsignorId = this.partyDtoForNewConsignorSet.consignorId;
    this.selectedNewConsignorName = this.partyDtoForNewConsignorSet.consignorName;
    this.controlConsignor.reset();
    this.consignorOptionsRet = [];
    this.consignorOptionsAdd.consignorName = this.selectedNewConsignorName;
    this.consignorOptionsAdd.consignorEInvoiceEnable = this.consignorEInvoiceEnableMode;
    this.consignorOptionsRet.push(this.consignorOptionsAdd);
    //        this.consignorOptionsAdd.consignorName = "Add New"
    //        this.consignorOptionsRet.push( this.consignorOptionsAdd );

    this.consignorTA = [];
    for (let i = 0; i < this.consignorOptionsRet.length; i++) {
      this.consignorTA.push(this.consignorOptionsRet[i]);
    }

    $("#" + this.pageId + "consignorName").val(this.partyDtoForNewConsignorSet.consignorName);
    //        console.log( this.partyDtoFNewConsignorSet.address );
    this.setConsignorAddress = this.partyDtoForNewConsignorSet.address;
    this.consigneeGSTNumberField.nativeElement.focus();
    this.ValidateClearNewConsignorDetails();
  }
  setValueForConsignor() {
    this.selectedconsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val().toString().trim().replace(/\s+/g, '');
    //CHG-V-LR:50
    // $("#" + this.pageId + "gstNoConsignor").val(this.selectedconsignorGSTNumber);
    this.gstNoConsignorModel = this.selectedconsignorGSTNumber;
    //        this.newConsignorField.nativeElement.focus();

  }

  ValidateClearNewConsignorDetails() {
    this.selectedNewConsignorName = '';
    $("#" + this.pageId + "newConsignor").val('');
    this.enteredNewConsignorGSTNo = '';
    $("#" + this.pageId + "gstNoConsignor").val('');
    this.enteredNewConsignorAddress = '';
    $("#" + this.pageId + "newConsignorAddress").val('');
    this.enteredNewConsignorState = '';
    $("#" + this.pageId + "consignorState").val('');
    this.enteredNewConsignorMobileNo = '';
    $("#" + this.pageId + "consignorMobileNumber").val('');
    this.enteredNewConsignorOfficeNo = '';
    $("#" + this.pageId + "consignorOfficeNumber").val('');
    this.newConsignorName = null;
    this.gstNoConsignorModel = null;
    this.controlConsignorState.setValue('');
    this.partyDtoForNewConsignor = new PartyMasterDto();
  }
  //For Consignee
  validateNewConsignee() {
    this.enteredNewConsigneeGSTNo = $("#" + this.pageId + "gstNoConsignee").val();
    this.enteredNewConsigneeState = $("#" + this.pageId + "consigneeState").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();

    this.cityOfNewConsignee = $("#" + this.pageId + "consigneeCity").val();
    var aadharValid = this.validateAadharNumberFormatConsignee();
    //CHG-V-LR:50
    this.enteredNewConsigneeGSTNoPan = $("#" + this.pageId + "gstNoConsignee").val().toString().trim().replace(/\s+/g, '');

    if ((this.enteredNewConsigneeGSTNo == null) || (this.enteredNewConsigneeGSTNo == '')) {
      swal({
        title: "Mandatory Field",
        text: "Please enter New Consignee Aadhar number",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if ((this.enteredNewConsigneeState == null) || (this.enteredNewConsigneeState == '')) {
      swal({
        title: "Mandatory Field",
        text: "Please Select the State",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if ((this.selectedDestination == null) || (this.selectedDestination == '')) {
      swal({
        title: "Mandatory Field",
        text: "Please Select the Destination",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

    } else if ((this.cityOfNewConsignee == null) || (this.cityOfNewConsignee == '')) {
      swal({
        title: "Mandatory Field",
        text: "City is Empty",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
    //CHG-V-LR:50
    // else if (!aadharValid) {
    //   swal("Invalid", "Entered Aadhar Number is invalid, please enter the correct number!", "warning");
    // } 
    //CHG-V-LR:50
    else if (($("#" + this.pageId + "searchBy").val() == "aadhar") && (!aadharValid)) {
      swal("Invalid", "Entered Aadhar Number is invalid, please enter the correct number!", "warning");
    } else if (($("#" + this.pageId + "searchBy").val() == "pan") && (!this.panValidation.test(this.enteredNewConsigneeGSTNoPan))) {
      swal("Invalid", "Invalid PAN Number", "warning");
      return false;
    } else {
      this.saveNewConsigneeDetails();
    }
  }
  saveNewConsigneeInfo() {
    this.selectedNewConsigneeName = $("#" + this.pageId + "newConsignee").val();
    this.enteredNewConsigneeGSTNo = $("#" + this.pageId + "gstNoConsignee").val();
    this.enteredNewConsigneeAddress = $("#" + this.pageId + "newConsigneeAddress").val();
    this.enteredNewConsigneeState = $("#" + this.pageId + "consigneeState").val();
    this.enteredNewConsigneeMobileNumber = $("#" + this.pageId + "consigneeMobileNumber").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.cityOfNewConsignee = $("#" + this.pageId + "consigneeCity").val();


    this.partyDtoForNewConsignee.companyId = this.userDataDtoReturnSession.companyId;
    this.partyDtoForNewConsignee.consigneeName = this.selectedNewConsigneeName.toUpperCase();
    this.partyDtoForNewConsignee.address = this.enteredNewConsigneeAddress;
    this.partyDtoForNewConsignee.mobileNumber = this.enteredNewConsigneeMobileNumber;
    this.partyDtoForNewConsignee.state = this.enteredNewConsigneeState;
    this.partyDtoForNewConsignee.stateCode = this.newConsigneeStateCode;
    this.partyDtoForNewConsignee.gstNoConsignee = this.enteredNewConsigneeGSTNo.toString().trim().replace(/\s+/g, '');
    this.partyDtoForNewConsignee.status = 'Partial';
    // this.partyDtoForNewConsignee.city = this.destSplit;
    this.partyDtoForNewConsignee.city = this.cityOfNewConsignee;
    this.partyDtoForNewConsignee.userName = this.userDataDtoReturnSession.userName;

    //CHG-V-LR:47
    if ($("#" + this.pageId + "searchBy").val() == "aadhar") {
      this.partyDtoForNewConsignee.consigneeType = 'Aadhar';
    }
    //CHG-V-LR:50
    else if ($("#" + this.pageId + "searchBy").val() == "pan") {
      this.partyDtoForNewConsignee.consigneeType = 'Pan';
    }
  }

  saveNewConsigneeDetails = () => {
    this.saveNewConsigneeInfo();
    console.log(this.partyDtoForNewConsignee);
    this.masterReadService.addConsigneeInLrEntry(this.partyDtoForNewConsignee).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "Error while saving the new Consignee Details",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.getAutoLrNo();
        } else {
          this.partyDtoForNewConsigneeRet = response;
          this.partyDtoForNewConsigneeSet = this.partyDtoForNewConsigneeRet;
          if (this.partyDtoForNewConsignorSet.status == 'exist') {
            swal({
              title: "Failure",
              text: "Consignee Already Exist!",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          } else if (this.partyDtoForNewConsignorSet.status == 'not persisted') {
            swal({
              title: "Failure",
              text: "Ooops..Problem occur while saving Consignee Details..!",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          } else {
            swal({
              title: "Success",
              text: "New Consignee Name added successfully",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
            this.getConsignee();
          }


        }
        this.changeDetectorRef.detectChanges();
      }), (error) => swal({
        title: "Error",
        text: "Ooops..Problem occur while saving Consignor Details..",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }),
      () => console.log('done');
  };

  getConsignee() {
    this.selectedConsigneeId = this.partyDtoForNewConsigneeSet.consigneeId;
    this.selectedNewConsigneeName = this.partyDtoForNewConsigneeSet.consigneeName;
    this.controlConsignor.reset();
    this.consigneeOptionsRet = [];
    this.consigneeOptionsAdd.consigneeName = this.selectedNewConsigneeName;
    this.consigneeOptionsRet.push(this.consigneeOptionsAdd);

    this.consigneeTA = [];
    for (let i = 0; i < this.consigneeOptionsRet.length; i++) {
      this.consigneeTA.push(this.consigneeOptionsRet[i]);
    }

    $("#" + this.pageId + "consigneeName").val(this.partyDtoForNewConsigneeSet.consigneeName);
    //        console.log( this.partyDtoFNewConsigneeSet.address );
    this.setConsigneeAddress = this.partyDtoForNewConsigneeSet.address;
    this.isPaidLrOnly = this.partyDtoForNewConsigneeSet.isPaidOnly == null ? false : this.partyDtoForNewConsigneeSet.isPaidOnly;
    this.consigneeGSTNumberField.nativeElement.focus();
    //    console.log( this.isPaidLrOnly );
    this.ValidateClearNewConsigneeDetails();
  }
  ValidateClearNewConsigneeDetails() {
    this.selectedNewConsigneeName = '';
    $("#" + this.pageId + "newConsignee").val('');
    this.enteredNewConsigneeGSTNo = '';
    $("#" + this.pageId + "gstNoConsignee").val('');
    this.enteredNewConsigneeAddress = '';
    $("#" + this.pageId + "newConsigneeAddress").val('');
    this.enteredNewConsigneeState = '';
    $("#" + this.pageId + "consigneeState").val('');
    this.enteredNewConsigneeMobileNumber = '';
    $("#" + this.pageId + "consigneeMobileNumber").val('');
    this.cityOfNewConsignee = '';
    $("#" + this.pageId + "consigneeCity").val('');
    this.controlConsigneeState.setValue('');
    this.newConsigneeName = null;
    this.partyDtoForNewConsignee = new PartyMasterDto();
  }
  setValueForConsignee() {
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    console.log('lrentry 4: ' + this.selectedDestination);
    console.log('lrentry 5: ' + this.destSplit);
    this.selectedconsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val().toString().trim().replace(/\s+/g, '');
    console.log('lrentry 6: ' + this.selectedconsigneeGSTNumber);
    $("#lrefcgstNoConsignee").val(this.selectedconsigneeGSTNumber);
    $("#lrefcconsigneeCity").val(this.destSplit);
    console.log('lrentry 7: ' + this.selectedconsigneeGSTNumber);
    //        this.newConsigneeField.nativeElement.focus();

  }

  //   /for modal starts
  showBkgCashmemoPaidPopupNew(): void {
    this.lrDtoForBooking = new LRDto();
    this.lrDtoForBooking.lrNumber = this.resultDtos.lrNumber;
    //this.lrDtoForBooking.mode = 'lrEntry';
    localStorage.clear();
    localStorage.setItem('showBkgCashmemo', JSON.stringify(this.lrDtoForBooking));

    const dialogRef = this.dialog.open(BookingCashmemoPopupComponent, {
      data: { listOfDataToPass: this.lrDtoForBooking }
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.router.navigate( ['.'], { relativeTo: this.route } );
      this.viewLrEntryBkgCashmemoPrintAutoGStPopup = true;
      window.addEventListener('afterprint', (onclick) => {
        if (this.viewLrEntryBkgCashmemoPrintAutoGStPopup) {
          this.viewLrEntryBkgCashmemoPrintAutoGStPopup = false;
          localStorage.clear();
          this.setPrintLrs(this.resultDtos);
        }
      });
      this.changeDetectorRef.detectChanges();
    });
    // this.changeDetectorRef.detectChanges();
  }

  //For Multiple Articles pop 
  open11(content11) {
    this.modalService.open(content11, { centered: true, windowClass: "myCustomModalClass" }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  showBkgCashmemoPaid(content11): void {

    this.lrDtoForBooking.lrNumber = this.resultDtos.lrNumber;
    this.lrDtoForBooking.mode = 'lrEntry';
    localStorage.clear();
    //    console.log( this.lrDtoForBooking );
    localStorage.setItem('showBkgCashmemo', JSON.stringify(this.lrDtoForBooking));
    this.modalService.open(content11, { centered: true, windowClass: "myCustomModalClass" });
  }
  showBkgCashmemoPaidClear() {

    if (this.isPaidLrForManual) {
      this.setvalueafterManual();
      this.isPaidLrForManual = false;
    } else {
      window.setTimeout(function () {
        // CHG-V-LR:02
        // $("#lrefcdestination").focus();
        $("#lrefcentryPinCode").focus();
      }, 100);
      this.clearInputFields();
      if (this.viewAutoLrHold == true) {
        this.getAutomatedLrHoldDetails();
      } else {
        this.getAutoLrNo();
      }
    }
  }

  setvalueafterManual() {
    $("#" + this.pageId + "lrNumber").val('');
    let MainAdminStation = this.userDataDtoReturnSession.mainAdminStation.toLowerCase();
    this.clearInputFields();

    if (this.restrictUserGodownList) {

      window.setTimeout(function () {
        // CHG-V-LR:02
        // $("#lrefcdestination").focus();
        $("#lrefcentryPinCode").focus();
      }, 100);

    } else if ((this.userDataDtoReturnSession.mainAdminStation != null)
      && (MainAdminStation == 'delhi')
      && ((this.checkAutoLr == true) || (this.checkAutoLrHold == true))) {

      window.setTimeout(function () {
        $("#lrefcloaderHead").focus();
      }, 100);
    } else if ((this.userDataDtoReturnSession.mainAdminStation != null)
      && (MainAdminStation == 'delhi')
      && ((this.checkAutoLr == false) || (this.checkAutoLrHold == false))) {

      window.setTimeout(function () {
        $("#lrefcnoOfArticles").focus();
      }, 100);
    } else {
      window.setTimeout(function () {
        $("#lrefcdestination").focus();
      }, 100);

    }
  }
  //By Kamil
  showMainComm(option, instance) {
    this.selectedDestination = option;
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.stateByDest = this.commaSplitDest[1];
    if (this.stateByDest == ' Kerala') {
      this.showEDeclarationId = true;
    } else {
      this.showEDeclarationId = false;
    }
    if ((this.viewByAutoLr == true) || (this.lrCheck == true)) {
      $("#" + this.pageId + "bookingDate").focus();
      //            this.autoLrNumberField.nativeElement.focus();
    } else {
      this.showMainCommodity = true;
      $("#" + this.pageId + "lrNumber").focus();
      //            this.lrNumberField.nativeElement.focus();
    }
  }


  viewMainCommodity(e) {
    console.log('viewMainCommodity :1');
    if (e.keyCode == 13) {
      console.log('viewMainCommodity :2');
      this.selectedDestination = $("#" + this.pageId + "destination").val();
      this.commaSplitDest = this.selectedDestination.split("-");
      this.destSplit = this.commaSplitDest[0];
      this.stateByDest = this.commaSplitDest[1];
      if (this.selectedDestination != null && this.selectedDestination != '') {
        this.showMainCommodity = true;
        // calling this method again to revise the chg for
        // chennai @dated : 09/09/2020
        this.setDefaultCharges();
        this.validateFOVRate();
        //                if ( this.stateByDest=='Karnataka') {
        //                    listBoxTPCommodity.clear();
        //                    listBoxTPCommodity.addItem("NA");
        //
        //                } else {
        //                    getCommodityNames();
        //                }
        //
        //                listBoxTPCommodity.clear();
        //                txtSubCommodity.clear();
        //                listBoxTPCommodity.addItem("NA");
        //                lblPartyCommodity.setText("Main Commodity:");
        if ((this.viewByAutoLr == true) || (this.lrCheck == true)) {
          //                    this.autoLrNumberField.nativeElement.focus();
          $("#" + this.pageId + "bookingDate").focus();
        } else {
          //                    this.lrNumberField.nativeElement.focus();
          $("#" + this.pageId + "lrNumber").focus();
        }
      }

      if (this.stateByDest == 'Kerala') {
        this.showEDeclarationId = true;
      } else {
        this.showEDeclarationId = false;
      }
    }
  }

  fieldFocusPackNatureNew(e) {
    if (e.keyCode == 13) {
      window.setTimeout(function () {
        $("#lrefcmultiPackNatureArtilces").focus();
      }, 100);
    }

  }

  packNaturesNew() {
    window.setTimeout(function () {
      $("#lrefcmultiPackNatureArtilces").focus();
    }, 100);
  }

  multiPackNatureFocus() {
    window.setTimeout(function () {
      $("#lrefcconsignorGSTNumber").focus();
    }, 100);
  }

  newCommodityEvent(e) {

    if (e.keyCode == 13) {
      window.setTimeout(function () {
        $("#lrefcactualWeight").focus();
      }, 100);
      //window.setTimeout( function() {
      //  $( "#subCommodity" ).focus();
      //}, 100 );

    }
  }


  multiArtFocus() {
    this.getMultiArtActAndChgWgt();
  }

  multiArtPopupClose(e) {
    this.multiArtBlurEvent = false;
    if (this.multiArtTableDataList.length == 0) {
      console.log('multiArtPopupClose');
      console.log(this.multiArtTableDataList.length);
      window.setTimeout(function () {
        console.log('multiArtPopupClose timeout');
        $("#lrefcchargedWeight").val('');
        $("#lrefcchargedWeight").focus();
      }, 100);
    }
    this.multiArtBlurEvent = true;
    console.log(this.multiArtBlurEvent);
    this.modalRefferenceContentMultiArtPopUp.close();
    this.dtTriggerMultiArtTable.unsubscribe();
  }

  focusArrivalCalendar() {
    window.setTimeout(function () {
      $("#lrefcchallanNo").focus();
    }, 100);
    //        this.challanNoField.nativeElement.focus();
  }

  clerkEvent(e) {
    if (e.keyCode == 13) {
      window.setTimeout(function () {
        $("#lrefcbillDate").focus();
      }, 1000);
    }
  }

  focusBookingCalendar() {
    let enterBookingDate = null;
    enterBookingDate = $("#" + this.pageId + "bookingDate").val();
    this.enteredBookingDate = this.datePipe.transform(enterBookingDate, "yyyy-MM-dd");
    if (this.viewByAutoLr == true || this.lrCheck == true) {
      //                alert( '2' );
      if (this.validateBkgDateClicked == true && this.enteredBookingDate != null) {
        this.validateLrBookingDateGet();
      } else {
        //                    alert( '4' );
        window.setTimeout(function () {
          $("#lrefcnoOfArticles").focus();
        }, 100);
      }
    } else {
      //                alert( '4' );
      window.setTimeout(function () {
        $("#lrefcnoOfArticles").focus();
      }, 100);
    }
    //        this.challanNoField.nativeElement.focus();
  }

  focusBookingCalendarOnChange() {
    let enterBookingDate = null;
    enterBookingDate = $("#" + this.pageId + "bookingDate").val();
    this.enteredBookingDate = this.datePipe.transform(enterBookingDate, "yyyy-MM-dd");
    if (this.viewByAutoLr == true || this.lrCheck == true) {
      //                alert( '2' );
      if (this.validateBkgDateClicked == true && this.enteredBookingDate != null) {
        this.validateLrBookingDateGet();
      }
    }
    //        this.challanNoField.nativeElement.focus();
  }

  focusBillCalendar() {
    this.paymentType = $("#" + this.pageId + "paymentType").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.stateByDest = this.commaSplitDest[1];
    if (this.viewByAutoLr != true && this.paymentType == 'paid') {
      window.setTimeout(function () {
        $("#lrefcserTaxPayingBy").focus();
      }, 100);
    } else if (this.viewByAutoLr != true && this.paymentType == 'toPay') {
      if (this.stateByDest == ' Kerala') {
        window.setTimeout(function () {
          $("#lrefcedeclarationId").focus();
        }, 100);
      } else {
        window.setTimeout(function () {
          //                    $( "#checkboxAoc" ).focus();
          $("#lrefcnextBtn").focus();
        }, 100);
      }

    } else if (this.viewByAutoLr == true) {
      window.setTimeout(function () {
        $("#lrefcremarks").focus();
      }, 100);
    }
  }

  focusBillCalendarEnter(e) {
    if (e.keyCode == 13) {
      this.paymentType = $("#" + this.pageId + "paymentType").val();
      this.selectedDestination = $("#" + this.pageId + "destination").val();
      this.commaSplitDest = this.selectedDestination.split("-");
      this.destSplit = this.commaSplitDest[0];
      this.stateByDest = this.commaSplitDest[1];
      if (this.viewByAutoLr != true && this.paymentType == 'paid') {
        window.setTimeout(function () {
          $("#lrefcserTaxPayingBy").focus();
        }, 100);
      } else if (this.viewByAutoLr != true && this.paymentType == 'toPay') {
        if (this.stateByDest == ' Kerala') {
          window.setTimeout(function () {
            $("#lrefcedeclarationId").focus();
          }, 100);
        } else {
          window.setTimeout(function () {
            //                        $( "#checkboxAoc" ).focus();
            $("#lrefcnextBtn").focus();
          }, 100);
        }

      } else if (this.viewByAutoLr == true) {
        window.setTimeout(function () {
          $("#lrefcremarks").focus();
        }, 100);
      }
    }

  }



  //    serTaxPayingByMethod() {
  //        window.setTimeout( function() {
  //            $( "#checkboxAoc" ).focus();
  //        }, 100 );
  //    }
  remarksFocus(e) {
    if (e.keyCode == 13) {
      $("#" + this.pageId + "articleChargedWeightExtra").focus();
    }
  }

  //    actualChargedWeightExtraFocus( e ) {
  //        this.paymentType = $( "#paymentType" ).val();
  //        if ( e.keyCode == 13 ) {
  //            if ( this.paymentType == 'toPay' ) {
  //                $( "#checkboxAoc" ).focus();
  //            } else if ( this.paymentType == 'paid' ) {
  //                $( "#serTaxPayingBy" ).focus();
  //            }
  //        }
  //    }

  paymentTypeModeFocus(e, paymentType: string) {
    if (e.keyCode == 13) {
      if (paymentType === 'paid') {
        this.paymentTypePaid = true;
        window.setTimeout(function () {
          $("#lrefcbankLr").focus();
        }, 1000);
      } else {
        this.paymentTypePaid = false;
        window.setTimeout(function () {
          $("#lrefcbankLr").focus();
        }, 1000);
      }
    }
  }

  //CHG-V-LR:09
  bankLrModeFocus(e, bankLr: string) {
    if (e.keyCode == 13) {
      if (bankLr === 'Yes') {
        // this.bankLrYes = true;
        // window.setTimeout(function () {
        //     $("#lrefcbankName").focus();
        // }, 1000);
        swal("Not Allowed", "Bank LR Booking is not allowed, please contact Admin if any!", "warning");
        this.bankLrYes = false;
        $("#" + this.pageId + "bankLr").val('No');
      } else {
        this.bankLrYes = false;
        window.setTimeout(function () {
          $("#lrefcprivateMarker").focus();
        }, 1000);
      }
    }


  }

  serTaxPayingByFocusMethod(e) {
    if (e.keyCode == 13) {
      this.selectedDestination = $("#" + this.pageId + "destination").val();
      this.commaSplitDest = this.selectedDestination.split("-");
      this.destSplit = this.commaSplitDest[0];
      this.stateByDest = this.commaSplitDest[1];
      if (this.stateByDest == ' Kerala') {
        $("#" + this.pageId + "edeclarationId").focus();
      } else {
        window.setTimeout(function () {
          //                    $( "#checkboxAoc" ).focus();
          $("#lrefcnextBtn").focus();
        }, 1000);
      }
    }

  }
  serTaxPayingByMethod() {
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.stateByDest = this.commaSplitDest[1];
    if (this.stateByDest == ' Kerala') {
      $("#" + this.pageId + "edeclarationId").focus();
    } else {
      window.setTimeout(function () {
        //                $( "#checkboxAoc" ).focus();
        $("#lrefcnextBtn").focus();
      }, 1000);
    }
  }

  edeclarationIdFocusMethod(e) {
    if (e.keyCode == 13) {
      window.setTimeout(function () {
        //                $( "#checkboxAoc" ).focus();
        $("#lrefcnextBtn").focus();
      }, 1000);
    }

  }

  edeclarationIdMethod() {
    window.setTimeout(function () {
      //            $( "#checkboxAoc" ).focus();
      $("#lrefcnextBtn").focus();
    }, 1000);
  }

  articleChargedWeightExtraFocus(e) {
    this.paymentType = $("#" + this.pageId + "paymentType").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.stateByDest = this.commaSplitDest[1];
    if (e.keyCode == 13) {
      if (this.stateByDest == ' Kerala') {
        if (this.paymentType == 'toPay') {
          $("#" + this.pageId + "edeclarationId").focus();
        } else if (this.paymentType == 'paid') {
          $("#" + this.pageId + "serTaxPayingBy").focus();
        }
      } else {
        if (this.paymentType == 'toPay') {
          //                    $( "#checkboxAoc" ).focus();
          $("#" + this.pageId + "nextBtn").focus();

        } else if (this.paymentType == 'paid') {
          $("#" + this.pageId + "serTaxPayingBy").focus();
        }
      }

    }
  }

  clickTA(inp) {
    inp._elementRef.nativeElement.value = '';
    inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
    inp._elementRef.nativeElement.focus();
  }
  closeNewConsignor() {
    window.setTimeout(function () {
      $("#lrefcconsigneeGSTNumber").focus();
    }, 100);
  }


  closeNewConsignee() {
    if ((this.selectedConsignee != null) && (this.selectedConsignee != "") && (this.selectedConsignee != "ALL")) {
      this.getBangaloreConsigneeCommodityConsignee();
      this.getAllCommodityAsPerState();
      //this.partyGoodsTypeField.nativeElement.focus();
      window.setTimeout(function () {
        $("#lrefcpartyCommodity").focus();
      }, 100);
    }
  }
  viewGodwn(e) {
    if (e.keyCode == 13) {
      window.setTimeout(function () {
        $("#lrefcarrivalDate").focus();
      }, 100);
    }

  }
  ///// started from here 
  getAgentTempoDetailsForRead() {
    this.truckDataDto = new TruckDataDto();
    this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
    this.truckDataDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
  }
  getAgentTempoMasterDetailsList = () => {
    this.getAgentTempoDetailsForRead();
    this.masterService.getAgentTempoDetails(this.truckDataDto).subscribe(
      (response) => {
        if (response) {
          this.agentTempoMasterDataList = response;
          this.vehicleNumberOptions = this.agentTempoMasterDataList;
          this.vehicleNumberTA = [];
          for (let i = 0; i < this.vehicleNumberOptions.length; i++) {
            this.vehicleNumberTA.push(this.vehicleNumberOptions[i].truckNumber);
          }
          this.vehicleNumberOptionsAddNew.truckNumber = 'ADD NEW';
          this.vehicleNumberTA.push(this.vehicleNumberOptionsAddNew.truckNumber);
          if (this.selectedTruckNumber != null && this.selectedTruckNumber != '') {
            this.setValuesInVehicle();
          }

        }
        this.changeDetectorRef.detectChanges();
      }), (error) => swal({
        title: "Error",
        text: "Server Error While Getting Truck Master Details",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }),
      () => console.log('done');
  };
  vehicleNumberFocusEvent(e: NgbTypeaheadSelectItemEvent, content12) {
    this.modelVehicleNumber = e.item;
    this.enteredTruckNumber = this.modelVehicleNumber;
    if (this.enteredTruckNumber != null && this.enteredTruckNumber == 'ADD NEW') {
      this.modalService.open(content12, { centered: true });
      window.setTimeout(function () {
        $("#lrefctruckNumber").focus();
      }, 100);
    } else {
      window.setTimeout(function () {
        $("#lrefccompanyName").focus();
      }, 100);
    }

  }
  checkTempoNumber(e) {
    this.enteredTruckNumber = $("#" + this.pageId + "truckNumber").val();
    if (e.keyCode == 13) {
      if ((this.enteredTruckNumber != '') || (this.enteredTruckNumber != null)) {
        this.tempoNumberChk();
      }
    }
  }
  tempoNumberChk = () => {
    this.enteredTruckNumber = $("#" + this.pageId + "truckNumber").val();
    this.truckDataCheckDto = new TruckDataDto();
    this.truckDataCheckDto.truckNumber = this.enteredTruckNumber;
    this.masterService.checkAgentTempoNumber(this.truckDataCheckDto).subscribe(
      (response) => {
        this.truckDataDtoCheckRet = response;
        if (this.truckDataDtoCheckRet.status == 'exist') {

          swal({
            title: "Alert",
            text: "This Tempo Number: " + this.truckDataCheckDto.truckNumber
              + " already exists",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {
            $("#" + this.pageId + "truckNumber").val('');
            window.setTimeout(function () {
              $("#lrefctruckNumber").focus();
            }, 100);
          });
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };
  validateAgentTempoDetails() {
    this.enteredTruckNumber = $("#" + this.pageId + "truckNumber").val();
    this.enteredTruckCapacity = $("#" + this.pageId + "truckCapacityInTones").val();

    if ((this.enteredTruckNumber == '') || (this.enteredTruckNumber == null) || (this.enteredTruckCapacity == '') || (this.enteredTruckCapacity == null)) {
      swal({
        title: "Warning",
        text: "Please Enter the Mandetory Fields",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      this.enteredTruckNumber = this.enteredTruckNumber.replace(/\s/g, "");
      if (this.enteredTruckNumber.length < 9) {
        swal({
          title: "Alert",
          text: "The Entered Truck Number has less then nine latters",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(() => {
          window.setTimeout(function () {
            $("#lrefctruckNumber").focus();
          }, 100);
        });
      } else {
        if (/\d/.test(this.enteredTruckNumber) && /[a-zA-Z]/.test(this.enteredTruckNumber)) {
          this.saveUpdateAgentTempoDetails();
        } else {
          swal({
            title: "Alert",
            text: "The Entered Truck Number is not valid, it should be Alphanumeric",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {

            window.setTimeout(function () {
              $("#lrefctruckNumber").focus();
            }, 100);
          });
        }
      }
    }
  }
  getAgentTempoDetailsForSave() {
    this.enteredTruckNumber = $("#" + this.pageId + "truckNumber").val();
    this.enteredTruckNumber = this.enteredTruckNumber.replace(/\s/g, "");
    this.enteredTruckCapacity = $("#" + this.pageId + "truckCapacityInTones").val();
    this.enteredOwnerName = $("#" + this.pageId + "ownerName").val();
    this.enteredPanNO = $("#" + this.pageId + "panNumber").val();
    this.truckDataDtoSave = new TruckDataDto();
    this.truckDataDtoSave.companyId = this.userDataDtoReturnSession.companyId;
    this.truckDataDtoSave.userId = this.userDataDtoReturnSession.userId;
    this.truckDataDtoSave.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    this.truckDataDtoSave.suppAdd = 'save';
    this.truckDataDtoSave.truckNumber = this.enteredTruckNumber.toUpperCase();
    this.truckDataDtoSave.ownerName = this.enteredOwnerName;
    this.truckDataDtoSave.panNo = this.enteredPanNO;
    this.truckDataDtoSave.truckCapcityDouble = this.enteredTruckCapacity;
  }
  saveUpdateAgentTempoDetails = () => {
    this.getAgentTempoDetailsForSave();
    this.masterService.createAgentTempo(this.truckDataDtoSave).
      subscribe((data) => {
        this.truckDataDtoSaveRet = data;
        //                console.log( this.truckDataDtoSaveRet.status );
        if (this.truckDataDtoSaveRet.status == "persisted") {
          swal({
            title: "Success",
            text: "Agent Tempo Details Saved/Updated Successfully",
            icon: "success",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {
            this.selectedTruckNumber = this.truckDataDtoSave.truckNumber;
            this.getAgentTempoMasterDetailsList();
          });


        }
        else if (this.truckDataDtoSaveRet.status == "exist") {
          swal({
            title: "Alert",
            text: "This Tempo Number: " + this.truckDataDto.truckNumber
              + " already exists",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {
            //                        $( "#truckNumber" ).val( '' );
            window.setTimeout(function () {
              $("#lrefctruckNumber").focus();
            }, 100);
          });
        }
        else {
          swal({
            title: "Error",
            text: "Error While Saving Truck Details",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

        }
      }),
      (error) => swal({
        title: "Error",
        text: "Server Error While Saving Truck Details",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }),
      () => console.log('done');
  };

  setValuesInVehicle() {
    $("#" + this.pageId + "vehicleNumber").val(this.selectedTruckNumber);
    this.clearAllAgentTempo();
    window.setTimeout(function () {
      $("#lrefccompanyName").focus();
      this.selectedTruckNumber = '';
    }, 100);
  }
  setTempoNext() {
    window.setTimeout(function () {
      $("#lrefccompanyName").focus();
      this.selectedTruckNumber = '';
    }, 100);
  }


  clearAllAgentTempo() {
    this.truckDataDto = new TruckDataDto();
    this.truckDataCheckDto = new TruckDataDto();
    this.truckDataDtoCheckRet = '';
    this.truckDataDtoSaveRet = '';
    this.enteredTruckNumber = '';
    this.enteredTruckCapacity = '';
    this.truckDataDtoSave = new TruckDataDto();
    this.enteredOwnerName = '';
    this.enteredPanNO = '';
  }

  validateVehicleNumber() {
    this.isValid = false;
    if ((this.checkAutoLr == false) && (this.checkAutoLrHold == false)) {
      this.enteredTruckNumberFroValidation = '';
      this.enteredTruckNumberFroValidation = $("#" + this.pageId + "vehicleNumber").val();
      if ((this.enteredTruckNumberFroValidation == '') || (this.enteredTruckNumberFroValidation == null)) {
        this.isValid = false;
      } else {
        this.enteredTruckNumberFroValidation = this.enteredTruckNumberFroValidation.replace(/\s/g, "");

        if (this.enteredTruckNumberFroValidation.length < 9) {
          this.isValid = false;
        } else {
          if (/\d/.test(this.enteredTruckNumberFroValidation) && /[a-zA-Z]/.test(this.enteredTruckNumberFroValidation)) {
            this.isValid = true;
          } else {
            this.isValid = false;
          }
        }
      }
      this.enteredTruckNumberFroValidation = '';
    } else {
      this.isValid = true;
    }
    return this.isValid;
  }

  /*  opennewPrint() {
         this.viewLrEntryPrint = true;
 
         window.addEventListener('afterprint', (onclick) => {
             if (this.viewLrEntryPrint) {
                 this.viewLrEntryPrint = false;
 
             } else {
 
             }
 
 
         });
 
     }*/

  bayUnloadingDateFocus(e) {
    if (e.keyCode == 13) {
      this.focusBayUnloadingCalendar();
    }
  }

  focusBayUnloadingCalendar() {
    window.setTimeout(function () {
      $("#lrefcbayUnlaodingTime").focus();
    }, 100);

  }
  setBayNumber() {
    for (let i = 0; i < this.bayNumberOptions.length; i++) {
      this.bayNumberTA.push(this.bayNumberOptions[i].label);
    }
  }

  selectBayDate() {
    this.tdyBayUnloadingDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };

  }
  bayNumberFocus(e) {
    if (e.keyCode == 13) {
      window.setTimeout(function () {
        $("#lrefcbayUnloadingDate").focus();
      }, 100);
    }

  }

  eWayBillValidUptoDateFocus(e, multipleEwayBillPopupOpen) {
    if (e.keyCode == 13) {
      // this.focusEWayBillValidUptoCalendar();
      this.eWayBillEvent(multipleEwayBillPopupOpen);
    }
  }

  eInvNoConsignorEvent(e) {
    if (e.keyCode == 13) {
      window.setTimeout(function () {
        $("#lrefcbaseFreight").focus();
      }, 100);
    }
  }

  focusEWayBillValidUptoCalendar(multipleEwayBillPopupOpen) {
    // window.setTimeout(function () {
    //     $("#lrefcbaseFreight").focus();
    // }, 100);
    this.eWayBillEvent(multipleEwayBillPopupOpen);
  }

  bayUnloadingTimeFocus(e) {
    if (e.keyCode == 13) {
      if ((this.checkAutoLr == true) || (this.checkAutoLrHold == true)) {
        window.setTimeout(function () {
          $("#lrefcdestination").focus();
        }, 100);
      } else {
        window.setTimeout(function () {
          $("#lrefcarrivalDate").focus();
        }, 100);
      }

    }

  }

  /* setEMthod(){
       var ews ="123456789012";
       var s=ews.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
       console.log(s);
 
   }*/

  validateDestinationSelection() {
    this.isDestValid = false
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    //alert(this.selectedDestination);
    for (let i = 0; i < this.destinationOptions.length; i++) {
      if (this.destinationOptions[i].listOfDestination == this.selectedDestination) {
        this.isDestValid = true;
      }
    }
    if (this.isDestValid == false) {
      swal({
        title: "Not Allowed",
        text: "Selected destination " + this.selectedDestination + " is not valid, please check and select again!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  validateMainCommoditySelection() {
    this.isMainCommodityValid = false
    this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
    //alert(this.selectedPartyCommodity);
    for (let i = 0; i < this.partyCommodityTA.length; i++) {
      if (this.partyCommodityTA[i] == this.selectedPartyCommodity) {
        this.isMainCommodityValid = true;
      }
    }
    if (this.isMainCommodityValid == false) {
      swal({
        title: "Not Allowed",
        text: "Selected Rate commodity " + this.selectedPartyCommodity + " is not valid, please check and select again!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  validateCommonCommoditySelection() {
    this.isCommonCommodityValid = false
    this.selectedCommonCommodity = $("#" + this.pageId + "mainCommodity").val();
    for (let i = 0; i < this.mainCommodityTA.length; i++) {
      if (this.mainCommodityTA[i] == this.selectedCommonCommodity) {
        this.isCommonCommodityValid = true;
      }
    }
  }

  // the below is commentted for non popup input data to edit, so it revert back
  // addInTableEway(multipleEwayBillPopupOpen) {
  //     this.newAttributeMultiEway.eWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
  //     this.newAttributeMultiEway.ewayBillValidUpto = $("#" + this.pageId + "eWayBillValidUptoDate").val();
  //     if (this.newAttributeMultiEway.eWayBillNo != '' && this.newAttributeMultiEway.ewayBillValidUpto != '' && this.newAttributeMultiEway.eWayBillNo != null && this.newAttributeMultiEway.ewayBillValidUpto != null) {
  //         if (this.multiEwayTableDataList.length == 0) {
  //             this.multiEwayTableDataList.push(this.newAttributeMultiEway);
  //             this.newAttributeMultiEway = {};
  //         }
  //         this.eWayBillEvent(multipleEwayBillPopupOpen);
  //     } else {
  //         swal({
  //             title: "Warning",
  //             text: "E way bill & E-Way Bill Valid Upto Fields Are Mandetory",
  //             icon: "warning",
  //             closeOnClickOutside: false,
  //             closeOnEsc: false,
  //         });

  //     }

  // }

  eWayBillEvent(multipleEwayBillPopupOpen) {
    //alert(this.consignorEInvoiceNoEnabled);
    // this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
    // console.log("this.enteredEWayBillNo");
    // console.log(this.enteredEWayBillNo);
    this.enteredGoodsValue = $("#" + this.pageId + "goodsValue").val();
    // console.log("this.enteredGoodsValue");
    // console.log(this.enteredGoodsValue);
    // console.log("this.eWayBillExempted");
    // console.log(this.eWayBillExempted);

    //CHG-V-LR:24
    // if ((parseInt(this.enteredGoodsValue) >= 50000)) {
    let enterEwayBillValidUptoDate = $("#" + this.pageId + "eWayBillValidUptoDate").val();
    this.enteredEwayBillValidUptoDate = this.datePipe.transform(enterEwayBillValidUptoDate, "yyyy-MM-dd");

    if ((parseInt(this.enteredGoodsValue) >= 50000) && (this.enteredEwayBillValidUptoDate != null &&
      this.enteredEwayBillValidUptoDate != '')) {
      if (this.eWayBillExempted == false) {
        this.openMultiEwayPopUpWithAlert(multipleEwayBillPopupOpen);
      } else {
        window.setTimeout(function () {
          // $("#lrefceWayBillValidUptoDate").focus();
          if (this.consignorEInvoiceNoEnabled == true) {
            $("#lrefceInvNoConsignor").focus();
            //this.eInvNoConsignorField.nativeElement.focus();
          } else {
            $("#lrefcbaseFreight").focus();
          }
        }, 100);
      }
    } else {
      if (this.consignorEInvoiceNoEnabled == true) {
        window.setTimeout(function () {
          // $("#lrefceWayBillValidUptoDate").focus();
          $("#lrefceInvNoConsignor").focus();
        }, 100);
        //this.eInvNoConsignorField.nativeElement.focus();
      } else {
        window.setTimeout(function () {
          // $("#lrefceWayBillValidUptoDate").focus();
          $("#lrefcbaseFreight").focus();
        }, 100);
      }
    }
  }
  openMultiEwayPopUpWithAlert(multipleEwayBillPopupOpen) {
    swal({
      title: "E-way Bill Validation",
      text: "You Want To Add Multiple E-way Bill for this LR?",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((multiEwayBill) => {
      if (multiEwayBill) {
        this.modalService.open(multipleEwayBillPopupOpen, { centered: true, windowClass: "myCustomModalClassMultiEwayLr" });

        window.setTimeout(function () {

          // $("#" + this.pageId + "eWayBillNoMulti").val(this.eWayBillInputModel);
          $("#lrefceWayBillNoMulti").focus();
        }, 100);

      } else {
        window.setTimeout(function () {
          $("#lrefcbaseFreight").focus();
        }, 600);
      }
    });
  }
  eWayBillValidUptoDateFocusMulti(e) {
    console.log("am in for focus on plus btn");
    if (e.keyCode == 13) {
      // this.focusEWayBillValidUptoCalendarMulti();
      this.chkDuplicateMultiEwayBillNo();
    }
  }

  focusEWayBillValidUptoCalendarMulti() {
    console.log("am in for focus on plus btn");
    this.chkDuplicateMultiEwayBillNo();
    // window.setTimeout(function () {
    //     $("#lrefcaddInTableMultiEwayBtn").focus();
    // }, 100);
  }

  ///For Multiple Article  Pop 

  findEwayDup(multiEwayTableDataList, eWayBillNo) {
    var checkingRemEwayBillNo = null;
    var remainEwayBillNoReturn = null;
    for (let i = 0; i < multiEwayTableDataList.length; i++) {
      checkingRemEwayBillNo = multiEwayTableDataList[i].eWayBillNo;
      if (checkingRemEwayBillNo == eWayBillNo) {
        remainEwayBillNoReturn = checkingRemEwayBillNo;
        break;
      } else {
        remainEwayBillNoReturn = null;
      }
    }
    return remainEwayBillNoReturn;
  }
  chkDuplicateMultiEwayBillNo() {
    this.enteredMultiEwayBillNo = $("#" + this.pageId + "eWayBillNoMulti").val();
    var validateRemaingLrNumber = this.findEwayDup(this.multiEwayTableDataList, this.enteredMultiEwayBillNo);
    if (validateRemaingLrNumber == this.enteredMultiEwayBillNo && this.editSelected == false) {
      swal("Duplicate", "Eway Bill No '" + this.enteredMultiEwayBillNo + "' Already Added", "warning");
    } else {
      this.addInTableMultiEway();
    }
  }

  addInTableMultiEway() {
    this.newAttributeMultiEway.eWayBillNo = $("#" + this.pageId + "eWayBillNoMulti").val();
    this.newAttributeMultiEway.ewayBillValidUpto = $("#" + this.pageId + "eWayBillValidUptoDateMulti").val();

    // var firstDate = true;
    if (this.newAttributeMultiEway.eWayBillNo != '' && this.newAttributeMultiEway.ewayBillValidUpto != '' && this.newAttributeMultiEway.eWayBillNo != null && this.newAttributeMultiEway.ewayBillValidUpto != null) {
      if (this.editSelected == true) {
        this.enterArticles = +this.enterArticles + -this.getArticles;
        //                console.log( 'true : ' + this.enterArticles )
        this.editSelected = false;
      } else {
        this.editSelected = false;
      }

      if (this.editTableId != null) {
        this.multiEwayTableDataList.splice(this.editTableId, 1);
        console.log("this.newAttributeMultiEway-chk1");
      }
      if (this.multiEwayTableDataList == null) {
        this.multiEwayTableDataList = [];
        console.log("this.newAttributeMultiEway-chk2");
      }
      console.log("this.newAttributeMultiEway");
      console.log(this.newAttributeMultiEway);
      $("#" + this.pageId + "multipleEwayPopupTable").DataTable().destroy();
      if (this.multiEwayTableDataList.length == 0) {
        this.multiEwayTableDataList.push(this.newAttributeMultiEway);
        console.log("this.newAttributeMultiEway-chk3");
        // firstDate = true;
        this.editSelected = false;
      } else {
        // firstDate = false;
        let array = this.multiEwayTableDataList;
        this.multiEwayTableDataList.push(this.newAttributeMultiEway);
        this.editSelected = false;
        console.log("this.newAttributeMultiEway-chk4");
      }
      console.log(this.multiEwayTableDataList);

      this.newAttributeMultiEway = {};
      $("#" + this.pageId + "eWayBillNoMulti").val('');
      $("#" + this.pageId + "eWayBillValidUptoDateMulti").val('');

      this.dtTriggerMultEway.next();
      // if (firstDate == true) {
      //     $("#" + this.pageId + "eWayBillValidUptoDate").val(this.newAttributeMultiEway.ewayBillValidUpto);
      // }


      window.setTimeout(function () {
        $("#lrefceWayBillNoMulti").focus();
      }, 100);
      this.editTableId = null;

    } else {
      swal({
        title: "Warning",
        text: "All Fields Are Mandatory",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

    }


  }
  clearMulEway() {
    $("#" + this.pageId + "eWayBillNoMulti").val('');
    $("#" + this.pageId + "eWayBillValidUptoDateMulti").val('');
  }

  rowSelectedEditMultiEway(multipleArtTableData, index) {
    this.editSelected = true;
    // this.getArticles = multipleArtTableData.eWayBillNo;
    $("#" + this.pageId + "eWayBillNoMulti").val(multipleArtTableData.eWayBillNo);
    $("#" + this.pageId + "eWayBillValidUptoDateMulti").val(multipleArtTableData.ewayBillValidUpto);
    $("#" + this.pageId + "hiddenIndex").val(index);
    this.editTableId = index;
    //        this.calculatedEditDelete(multipleArtTableData.article);
  }
  rowSelectedDeleteMultiEway(multipleArtTableData, index) {
    this.multiEwayTableDataList.splice(index, 1);
    // this.getArticles = multipleArtTableData.eWayBillNo;
    this.enterArticles = +this.enterArticles + -this.getArticles;
    $("#" + this.pageId + "eWayBillNoMulti").val('');
    $("#" + this.pageId + "eWayBillValidUptoDateMulti").val('');
    $("#" + this.pageId + "hiddenIndex").val('');
    //        this.calculatedEditDelete(multipleArtTableData.article);
  }

  multiEwayDetailsTable() {

    this.dtOptionsMultiEway = {
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search..."
      },
      searching: false,
      processing: true,
      responsive: true,
      "scrollX": true,
      "scrollY": 280,
      "scrollCollapse": true,
      "paging": false,
      "info": false,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
        $(api.column(0).footer()).html('Total : ' + data.length);
      }
    }
  }
  eWayBillNoMultiFocus(e) {
    if (e.keyCode == 13) {
      this.getEwayBillValidUptoFromApi(e);
      window.setTimeout(function () {
        $("#lrefceWayBillValidUptoDateMulti").focus();
      }, 100);
    }
  }
  multiEwayFocus() {
    //this.dtTriggerMultEway.unsubscribe();
    this.eWayBillValidUptoDateMultiModel = null;
    window.setTimeout(function () {
      // $("#lrefceWayBillValidUptoDate").focus();
      $("#lrefcbaseFreight").focus();
    }, 100);
  }

  eWayBillValidUptoDateBlur(multipleEwayBillPopupOpen) {
    this.eWayBillEvent(multipleEwayBillPopupOpen);
  }

  lrArtScanEvent(artilesScanPopupOpen) {
    this.openLrArtScanPopup(artilesScanPopupOpen);
  }

  openLrArtScanPopup(artilesScanPopupOpen) {
    this.modalService.open(artilesScanPopupOpen, { centered: true, windowClass: "myCustomModalClassMultiEwayLr" });
    window.setTimeout(function () {
      $("#lrefclrArtScanCode").focus();
    }, 100);
  }

  lrArtScanFocus() {
    this.dtTriggerLrArtScan.unsubscribe();
    window.setTimeout(function () {
      // this.packNatureField.nativeElement.focus();
      $("#lrefcpackNature").focus();
    }, 100);
  }

  lrArtScanCodeFocus(e) {
    if (e.keyCode == 13) {
      this.chkDuplicateLrArtScanCode();
    }
  }

  chkDuplicateLrArtScanCode() {
    this.enteredlrArtScanCode = $("#" + this.pageId + "lrArtScanCode").val();
    var validateLrArtScanCode = this.findLrArtScan(this.lrArtScanTableDataList, this.enteredlrArtScanCode);
    if (validateLrArtScanCode == this.enteredlrArtScanCode) {
      swal("Duplicate", "This code is already scanned!", "warning");
    } else {
      this.addInTableLRArtScan();
    }
  }

  findLrArtScan(lrArtScanTableDataList, enteredlrArtScanCode) {
    var checkingRemLrArtScanCode = null;
    var remainLrArtScanCodeReturn = null;
    for (let i = 0; i < lrArtScanTableDataList.length; i++) {
      checkingRemLrArtScanCode = lrArtScanTableDataList[i].lrArtScanCode;
      if (checkingRemLrArtScanCode == enteredlrArtScanCode) {
        remainLrArtScanCodeReturn = checkingRemLrArtScanCode;
        break;
      } else {
        remainLrArtScanCodeReturn = null;
      }
    }
    return remainLrArtScanCodeReturn;
  }

  addInTableLRArtScan() {
    this.newAttributeLrArtScan.lrArtScanCode = $("#" + this.pageId + "lrArtScanCode").val();

    if (this.newAttributeLrArtScan.lrArtScanCode != '' && this.newAttributeLrArtScan.lrArtScanCode != null) {
      $("#" + this.pageId + "lrArtScanPopupTable").DataTable().destroy();
      if (this.lrArtScanTableDataList == null) {
        this.lrArtScanTableDataList = [];
      }
      this.lrArtScanTableDataList.push(this.newAttributeLrArtScan);
      this.dtTriggerLrArtScan.next();
      this.newAttributeLrArtScan = {};
      $("#" + this.pageId + "lrArtScanCode").val('');
      window.setTimeout(function () {
        $("#lrefclrArtScanCode").focus();
      }, 100);

    } else {
      swal({
        title: "Not Allowed",
        text: "Please scan/enter the code",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

    }
  }
  clearLrArtScan() {
    $("#" + this.pageId + "lrArtScanCode").val('');
    this.newAttributeLrArtScan = {};
    this.lrArtScanTableDataList = [];
    this.enteredlrArtScanCode = null;
    this.newAttributeLrArtScanToPush = {};
  }

  rowSelectedDeleteLrArtScan(index) {
    this.lrArtScanTableDataList.splice(index, 1);
    $("#" + this.pageId + "lrArtScanCode").val('');
  }

  ngOnInit(): void {
    this.dtOptionsLrArtScan = {
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search..."
      },
      searching: false,
      processing: true,
      responsive: true,
      "scrollX": true,
      "scrollY": 180,
      "scrollCollapse": true,
      "paging": false,
      "info": true
    }
  }

  getLRBarcodeValues() {
    this.saveBarcodeValueLrDtoList = [];
    console.log('barcode size : ' + this.lrArtScanTableDataList.length);
    for (let i = 0; i < this.lrArtScanTableDataList.length; i++) {
      this.saveBarcodeValueLrDto = new LRDto();
      this.saveBarcodeValueLrDto.barcodeValue = this.lrArtScanTableDataList[i].lrArtScanCode;
      this.saveBarcodeValueLrDto.userName = this.userDataDtoReturnSession.userId;
      this.saveBarcodeValueLrDto.companyId = this.userDataDtoReturnSession.companyId;
      this.saveBarcodeValueLrDto.lrNumber = this.resultDtos.lrNumber;
      this.saveBarcodeValueLrDtoList.push(this.saveBarcodeValueLrDto);
      console.log('barcode value : ' + this.lrArtScanTableDataList[i].lrArtScanCode);
      console.log('barcode value : ' + this.saveBarcodeValueLrDto.barcodeValue);
    }
  }
  clickListnerForDestination(e: NgbTypeaheadSelectItemEvent) {
    this.setDefaultCharges();
    this.validateFOVRate();
    //CHG-V-LR:34
    // setTimeout(() => {
    //   this.validateDestinationConsignee();
    // }, 1000);
    // setTimeout(() => {
    //   $("#" + this.pageId + "bookingDate").focus();
    // }, 1200);
  }

  getConsignorRateDetailsUserValues() {
    this.rateMasterDtoConsignor = new RateMasterDto();
    this.enteredActualWeight = $("#" + this.pageId + "actualWeight").val();
    this.enteredChargeWeight = $("#" + this.pageId + "chargedWeight").val();
    this.enteredSource = $("#" + this.pageId + "source").val();
    this.totArticles = $("#" + this.pageId + "noOfArticles").val();

    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    //alert(this.selectedDestination);
    //alert(this.destSplit);

    this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
    let enterBookingDate = null;
    enterBookingDate = $("#" + this.pageId + "bookingDate").val();
    this.enteredBookingDate = this.datePipe.transform(enterBookingDate, "yyyy-MM-dd");

    this.rateMasterDtoConsignor.totalActWgt = this.enteredActualWeight;
    this.rateMasterDtoConsignor.totalChgWgt = this.enteredChargeWeight;
    this.rateMasterDtoConsignor.source = this.enteredSource;
    this.rateMasterDtoConsignor.totalArticles = this.totArticles;
    this.rateMasterDtoConsignor.station = this.destSplit;
    this.rateMasterDtoConsignor.commodityName = this.selectedPartyCommodity;
    this.rateMasterDtoConsignor.bookingDate = this.enteredBookingDate;
    this.rateMasterDtoConsignor.consignorId = this.selectedConsignorId;
    this.rateMasterDtoConsignor.companyId = this.userDataDtoReturnSession.companyId;
    console.log('getConsignorRateDetailsUserValues');
    console.log(this.selectedConsigneeId);
    this.rateMasterDtoConsignor.consigneeId = (this.selectedConsigneeId == null ? 0 : this.selectedConsigneeId);
    this.rateMasterDtoConsignor.consigneeIdTemp = (this.selectedConsigneeId == null ? 0 : this.selectedConsigneeId);
    console.log(this.selectedConsigneeId);
  }

  getConsignorRateDetails() {
    this.resetConsignorRateDetails();
    //alert('ok');
    this.getConsignorRateDetailsUserValues();
    this.masterReadService.calculateLRRateBasedOnConsignorRateService(this.rateMasterDtoConsignor).subscribe(
      (response) => {

        this.rateMasterDtoConsignorResp = response;
        if (this.rateMasterDtoConsignorResp.id != null && this.rateMasterDtoConsignorResp.id > 0) {
          console.log("this.rateMasterDtoConsignorResp" + this.rateMasterDtoConsignorResp);
          console.log("this.rateMasterDtoConsignorResp.riskChargeAmt" + this.rateMasterDtoConsignorResp.riskChargeAmt);
          this.consignorRateFlowSet = true;
          this.consignorRateId = this.rateMasterDtoConsignorResp.id;
          this.consignorRateSrc = this.rateMasterDtoConsignorResp.source;
          this.consignorRateFor = this.rateMasterDtoConsignorResp.rateFor;
          this.consignorRateConsignor = this.rateMasterDtoConsignorResp.consignorName;
          this.consignorRateDest = this.rateMasterDtoConsignorResp.destination;
          this.consignorRateCommodity = this.rateMasterDtoConsignorResp.commodityName;
          this.consignorRateType = this.rateMasterDtoConsignorResp.ratePerUnit;
          this.consignorRatePerUnit = this.rateMasterDtoConsignorResp.prevPerUnit;
          this.consignorRatePerUnitRateOnActWgt = this.rateMasterDtoConsignorResp.rateOnActWgt;
          this.consignorRateBasicRate = this.rateMasterDtoConsignorResp.basicCharge;
          this.consignorRateHamali = this.rateMasterDtoConsignorResp.hamali;
          this.consignorRateGcChg = this.rateMasterDtoConsignorResp.gcCharge;
          this.consignorRateAoc = this.rateMasterDtoConsignorResp.aocChargeAmt;
          this.consignorRateOthers = this.rateMasterDtoConsignorResp.otherChgAmt;
          this.consignorRateDDChg = this.rateMasterDtoConsignorResp.ddChg;
          this.consignorRateWEF = this.rateMasterDtoConsignorResp.effectiveFromDateStg;
          this.consignorRateConsignee = this.rateMasterDtoConsignorResp.consigneeName;

          //CHG-V-LR:07
          this.consignorIdRateId = this.rateMasterDtoConsignorResp.consignorId;

          //alert(this.rateMasterDtoConsignorResp.id);
          console.log('rate : ' + this.rateMasterDtoConsignorResp);
          $("#" + this.pageId + "baseFreight").val(this.rateMasterDtoConsignorResp.lorryFreight);
          //CHG-V-LR:01
          if (this.rateMasterDtoConsignorResp.ratePerUnit == 'Fixed' && this.destMemoSizeExceedRights) {
            this.getCommodityKgLimitDetails(this.rateMasterDtoConsignorResp.basicCharge);
          } else {
            //CHG-V-LR:23
            this.callPerkgRateDetails();
          }
          //CHG-V-LR:41
          this.callCommodityBlockedDetails();
          // if (this.userDataDtoReturnSession.lrEntryPerKgLimit != null && this.userDataDtoReturnSession.lrEntryPerKgLimit > 0) {
          //   this.calcBaseFreightWithCommonCharges();
          // }

          $("#" + this.pageId + "hamali").val(this.rateMasterDtoConsignorResp.hamaliDD);
          $("#" + this.pageId + "gcCharge").val(this.rateMasterDtoConsignorResp.gcCharge);
          $("#" + this.pageId + "aocCharge").val(this.rateMasterDtoConsignorResp.aocChargeAmt);
          $("#" + this.pageId + "others").val(this.rateMasterDtoConsignorResp.otherChgAmt);

          // for dd chg @02062022
          this.selectedDoorDelivery = $("#" + this.pageId + "doorDeliveryMode").val();
          if (this.selectedDoorDelivery != null && this.selectedDoorDelivery == 'Yes') {
            $("#" + this.pageId + "ddAmt").val(this.rateMasterDtoConsignorResp.ddChargeAmt);
            this.ddAmtRateChg = this.rateMasterDtoConsignorResp.ddChargeAmt;
          } else {
            $("#" + this.pageId + "ddAmt").val('');
            this.ddAmtRateChg = 0;
          }
          //CHG-V-LR:21
          this.consignorRateFov = this.rateMasterDtoConsignorResp.riskChargeAmt;


          //CHG-V-LR:22
          if (this.userDataDtoReturnSession.blockFreightRateInLrEntrry != null && this.userDataDtoReturnSession.blockFreightRateInLrEntrry) {
            // this.rateFrieghtFieldsReadOnly();
            this.isFrieghtFieldsReadOnly = true;
          } else {
            this.isFrieghtFieldsReadOnly = false;
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');

  }

  resetConsignorRateDetails() {
    this.consignorRateFlowSet = false;
    this.consignorRateId = 0;
    this.consignorIdRateId = 0;
    this.consignorRateSrc = null;
    this.consignorRateFor = null;
    this.consignorRateConsignor = null;
    this.consignorRateDest = null;
    this.consignorRateCommodity = null;
    this.consignorRateType = null;
    this.consignorRatePerUnit = null;
    this.consignorRatePerUnitRateOnActWgt = null;
    this.consignorRateBasicRate = null;
    this.consignorRateHamali = null;
    this.consignorRateGcChg = null;
    this.consignorRateAoc = null;
    this.consignorRateOthers = null;
    this.consignorRateDDChg = null;
    this.consignorRateWEF = null;
    this.consignorRateConsignee = null;
    $("#" + this.pageId + "baseFreight").val('');
    $("#" + this.pageId + "hamali").val('');
    $("#" + this.pageId + "gcCharge").val('');
    $("#" + this.pageId + "aocCharge").val('');
    $("#" + this.pageId + "others").val('');
    //CHG-V-LR:21
    this.consignorRateFov = null;
    //CHG-V-LR:22
    this.isFrieghtFieldsReadOnly = false;
  }

  consignorEInvoiceMode(consignorEInvoiceEnable: string) {
    this.consignorEInvoiceEnableMode = consignorEInvoiceEnable;
    if (this.consignorEInvoiceEnableMode == 'Yes') {
      this.consignorEInvoiceNoEnabled = true;
    } else {
      this.consignorEInvoiceNoEnabled = false;
    }
  }
  validateConsignorEInvoiceSave() {
    if ((this.consignorEInvoiceEnableMode == null || this.consignorEInvoiceEnableMode == '' || this.consignorEInvoiceEnableMode == undefined || this.consignorEInvoiceEnableMode == 'Select')) {
      swal({
        title: "Not Allowed",
        text: "Please select Consignor e-Invoice Mode",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      window.setTimeout(function () {
        $("#lrefcconsigneeGSTNumber").focus();
      }, 100);
      this.modalRefferenceContentEinvoicePopUp.close();
    }
  }

  sendWhatsappMsg(lrDtoSaveSet) {
    console.log('sendWhatsappMsg');
    console.log(lrDtoSaveSet);
    this.masterReadService.sendBkgLrWhatsAppMsg(lrDtoSaveSet).subscribe(
      (response) => {
        console.log(response.status);
      }),
      (error) => {
        swal("Error", "", "info");
      }, () => console.log('done');
  }

  validateConsignorMobile() {
    var conisgnorNumber = this.selectedconsignorMobileNumber;
    console.log('validateConsignorMobile');
    console.log(conisgnorNumber.length);
    console.log('validateConsignorMobile');
    if (conisgnorNumber.length > 10) {
      this.isConsignorMobileValid = true;
    }

    if (this.isConsignorMobileValid == false) {
      swal({
        title: "Not Allowed",
        text: "Consignor mobile number is not valid, please kindly enter the valid number!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  checkForPinCodeKeyUp(e) {
    if (e.keyCode == 13) {
      this.validatePincode();
    }
  }
  validatePincode() {
    this.enteredPincode = $("#" + this.pageId + "entryPinCode").val();
    console.log('pincode length : ' + this.enteredPincode.length);
    if (this.enteredPincode == null || this.enteredPincode == "" || this.enteredPincode == undefined) {
      swal({
        title: "Not Allowed",
        text: "Please enter the destination pincode",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        window.setTimeout(function () {
          $("#lrefcdestination").val('');
          this.destinationOptions = [];
          this.destinationTA = [];
          // this.controlDestination.reset();
          $("#lrefcentryPinCode").focus();
        }, 100);
      })
    } else if (this.enteredPincode.length > 6 || this.enteredPincode.length < 6) {
      swal({
        title: "Invalid pincode",
        text: "Please enter the valid pincode!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        window.setTimeout(function () {
          $("#lrefcdestination").val('');
          this.destinationOptions = [];
          this.destinationTA = [];
          //this.controlDestination.reset();
          $("#lrefcentryPinCode").focus();
        }, 100);
      })
    } else {
      console.log('pincode validation');
      this.checkForPinCodeForSame();
    }
  }

  checkForPinCodeForSame() {
    this.destinationMainStation = null;
    this.enteredPincode = null;
    this.enteredPincode = $("#" + this.pageId + "entryPinCode").val();
    console.log("this.enteredPincode");
    console.log(this.enteredPincode);
    this.userDataDto = new UserDataDto();
    this.userDataDto.pinCodeStr = this.enteredPincode.trim();
    this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
    this.userDataDto.isDeleted = false;
    //this.userDataDto.mainStation = this.destSplit;

    this.masterReadService.getPincodeDetailsForLrV2(this.userDataDto).subscribe(
      (response) => {
        if (response.status == "not found") {
          swal({
            title: "Warning",
            text: "No destination found for the entered pincode, please verify and enter the correct pincode!",
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then(() => {
            window.setTimeout(function () {
              $("#lrefcdestination").val('');
              this.destinationOptions = [];
              this.destinationTA = [];
              $("#lrefcentryPinCode").focus();
              //this.getDestinationDetails();
            }, 100);


          })
        } else if (response.status == "success") {
          $("#lrefcdestination").val('');
          this.destinationMainStation = response.mainAdminStation;
          this.getEnteredPincodeStation(response.mainStation);
        } else if (response.status == "failed") {
          swal({
            title: "Server Error",
            text: "Server Error While Getting Details",
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => swal({
        title: "Server Error",
        text: "Server Error While Getting Details",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }), () => console.log('done');
  }

  getEnteredPincodeStationDetailsList(station) {
    this.lrDtoSubstation = new LRDto();
    this.lrDtoSubstation.branch = this.userDataDtoReturnSession.office;
    this.lrDtoSubstation.mode = "LrFormPincodeStation";
    this.lrDtoSubstation.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoSubstation.destination = station;
    this.lrDtoSubstation.pinCode = this.enteredPincode.trim();
  }

  getEnteredPincodeStation = (station) => {
    this.getEnteredPincodeStationDetailsList(station);
    this.masterReadService.getDestinationForLREntryForm(this.lrDtoSubstation).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Destination Records Found",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.controlDestination.reset();
          this.destinationOptions = [];
          this.destinationTA = [];
        } else {
          this.controlDestination.reset();
          this.destinationOptions = response;
          this.destinationTA = [];
          for (let i = 0; i < this.destinationOptions.length; i++) {
            this.destinationTA.push(this.destinationOptions[i].listOfDestination);
          }
          $("#lrefcdestination").focus();
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  }

  resetDestinationWithEmpty = () => {
    this.destinationOptions = [];
    this.destinationTA = [];
    for (let i = 0; i < this.destinationOptions.length; i++) {
      this.destinationTA.push(this.destinationOptions[i].listOfDestination);
    }
  }

  calculateChgWgtUsingCFT(cftRateValue) {
    console.log('inside calculateChgWgtUsingCFT');
    var articlesInMultiple: any;
    var l: any;
    var b: any;
    var h: any;
    articlesInMultiple = $("#" + this.pageId + "multiArtarticle").val();
    l = $("#" + this.pageId + "multiArtlength").val();
    b = $("#" + this.pageId + "multiArtbreadth").val();
    h = $("#" + this.pageId + "multiArtheight").val();
    console.log(l + " - " + b + " - " + h + " - " + cftRateValue);

    var calculatedChgWgt = ((l * b * h) / 1728) * cftRateValue;
    console.log(Math.round(calculatedChgWgt));
    return Math.round(articlesInMultiple * calculatedChgWgt);
  }

  fieldFocusMultiArt(e) {
    if (e.keyCode == 13) {
      if (this.viewCftFields) {
        $("#lrefcmultiArtlength").focus();
      } else {
        window.setTimeout(function () {
          $("lrefcmultiArtActWgt").focus();
        }, 100);
      }
    }
  }
  fieldFocusMultiLgt(e) {
    if (e.keyCode == 13) {
      if (this.viewCftFields) {
        $("#lrefcmultiArtbreadth").focus();
      }
    }
  }

  fieldFocusMultiBdt(e) {
    if (e.keyCode == 13) {
      if (this.viewCftFields) {
        $("#lrefcmultiArtheight").focus();
      }
    }
  }

  fieldFocusMultiHgt(e) {
    if (e.keyCode == 13) {
      if (this.viewCftFields) {
        $("#lrefcmultiArtActWgt").focus();
      }
    }
  }

  fieldFocusMultiActWgt(e) {
    if (e.keyCode == 13) {
      if (this.viewCftFields) {
        this.addInTable();
      } else {
        $("#lrefcmultiArtChgWgt").focus();
      }
    }
  }

  toggleEditableForAutoCft(event) {
    if (event.target.checked) {
      console.log('cft true');
      this.viewCftFields = true;
    } else {
      console.log('cft false');
      this.viewCftFields = false;
    }
  }

  getMultiArtActAndChgWgt() {
    console.log('getMultiArtActAndChgWgt');
    this.totActWgtMultiArt = 0;
    this.totChgWgtMultiArt = 0;
    this.totArtMultiArt = 0;
    this.totArticles = 0;

    if (this.multiArtTableDataList.length > 0) {
      if (this.userDataDtoReturnSession.blockChgWgtLrEntry != null && this.userDataDtoReturnSession.blockChgWgtLrEntry) {
        this.chgWgtReadOnly = true;
      } else {
        this.chgWgtReadOnly = false;
      }
    } else {
      this.chgWgtReadOnly = false;
    }

    for (let i = 0; i < this.multiArtTableDataList.length; i++) {
      console.log(this.multiArtTableDataList[i].actualWeight);
      this.totActWgtMultiArt = +this.totActWgtMultiArt + +this.multiArtTableDataList[i].actualWeight;
      this.totChgWgtMultiArt = +this.totChgWgtMultiArt + +this.multiArtTableDataList[i].chargedWeight;
      this.totArtMultiArt = +this.totArtMultiArt + +this.multiArtTableDataList[i].articlesInMultiple;
    }
    this.totArticles = $("#" + this.pageId + "noOfArticles").val();

    if (this.totArtMultiArt < parseInt(this.totArticles)) {
      swal({
        title: "Not Allowed",
        text: "Entered multiple article details must equal to LR articles, please enter the remaining article details also!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      $("#" + this.pageId + "actualWeight").val(this.totActWgtMultiArt);
      this.totChgWgtMultiArt = (Math.ceil(this.totChgWgtMultiArt / 5.0)) * 5;
      $("#" + this.pageId + "chargedWeight").val(this.totChgWgtMultiArt);
      //CHG-V-LR:01
      if (this.consignorRateFlow) {
        this.getConsignorRateDetails();
      }
      this.validateFOVRate();
      this.sum();
      window.setTimeout(function () {
        $("#lrefcgoodsValue").focus();
      }, 100);
      this.modalRefferenceContentMultiArtPopUp.close();
      this.dtTriggerMultiArtTable.unsubscribe();
    }
  }

  getCFTSlabOfSepcificStationData(actWgt, chgWgt) {
    this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
    this.enteredSource = $("#" + this.pageId + "source").val();
    this.lrDtoSubstation = new LRDto();
    this.lrDtoSubstation.chargedWeight = chgWgt;
    this.lrDtoSubstation.actualWeight = actWgt;
    this.lrDtoSubstation.destination = this.destinationMainStation;
    this.lrDtoSubstation.source = this.enteredSource;
    this.lrDtoSubstation.commodityName = this.selectedPartyCommodity;
    this.lrDtoSubstation.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoSubstation.consigneeId = this.selectedConsigneeId;
    this.lrDtoSubstation.consignorId = this.selectedConsignorId;
  }

  getCFTSlabOfSepcificStation = (actWgt, chgWgt, article) => {
    this.getCFTSlabOfSepcificStationData(actWgt, chgWgt);
    this.masterService.getCFTSlabOfSepcificStation(this.lrDtoSubstation).subscribe(
      (response) => {
        if (response.size > 0) {
          this.cftRangeApplicableLR = true;
          var calculatedChgWgt = (actWgt) * response.considerValue;
          console.log(Math.round(calculatedChgWgt));
          // this.newAttribute.chargedWeight = Math.round(article * calculatedChgWgt);
          this.newAttribute.chargedWeight = Math.round(calculatedChgWgt);
          this.multipleArtCftAddInTable();
        } else {
          this.newAttribute.chargedWeight = chgWgt;
          this.multipleArtCftAddInTable();
        }
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  }

  multipleArtCftAddInTable() {
    if (this.editTableId != null) {
      this.multiArtTableDataList.splice(this.editTableId, 1);
    }
    if (this.multiArtTableDataList == null) {
      this.multiArtTableDataList = [];
    }
    //$("#" + this.pageId + "multipleArticleTable").DataTable().destroy();
    if (this.multiArtTableDataList.length == 0) {
      this.multiArtTableDataList.push(this.newAttribute);
    } else {
      this.multiArtTableDataList.push(this.newAttribute);
    }
    this.dtTriggerMultiArtTable.next();
    this.clearMulArt();
    console.log(this.newAttribute);
  }

  cftExemptionSetupValidationInfo() {
    this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();

    this.rateMasterDtoCft = new RateMasterDto();
    this.rateMasterDtoCft.commodityName = this.selectedPartyCommodity;
    this.rateMasterDtoCft.mode = 'CFTExemp Spec';
    this.rateMasterDtoCft.source = this.userDataDtoReturnSession.mainAdminStation;
  }

  getCFTExemptionForCommoditiesDetails = () => {
    this.isCftCommodityExemp = false;
    this.cftCommodityExempChgWgt = 0.0;
    this.cftExemptionSetupValidationInfo();
    this.masterService.getCftExemptionDetails(this.rateMasterDtoCft).subscribe(
      (response) => {
        this.cftCommodityExempValidateRet = response;
        console.log('getCFTExemptionForCommoditiesDetails');
        console.log(this.cftCommodityExempValidateRet);
        if (this.cftCommodityExempValidateRet.length > 0) {
          this.isCftCommodityExemp = true;
          this.cftCommodityExempChgWgt = this.cftCommodityExempValidateRet[0].chargedWgt;
          console.log(this.cftCommodityExempChgWgt);
        } else {
          this.isCftCommodityExemp = false;
          this.cftCommodityExempChgWgt = 0.0;
        }
      }), (error) => {
        swal("Error", "Server Problem Occurred While getting the CFT commodity exemption details", "warning");
      }, () => console.log('done');
  }

  toggleEditableForCustomCft(event) {
    this.enteredCustomCftValue = null;
    if (event.target.checked) {
      console.log('custom cft true');
      this.viewCustomCftFields = true;
    } else {
      console.log('custom cft false');
      this.viewCustomCftFields = false;
    }
  }

  getDetailsFromEwayBillRefreshEwb = (contentVehicleUpdate) => {
    this.clearEwayBillDetails();
    this.showSpinnerForAction = true;
    this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
    this.lrtDtoRespForEwayApi = new LRDto();
    this.responseDtoForEwayApi = new ResponseDto();
    this.responseDtoForEwayApi.ewayBillNo = this.enteredEWayBillNo;
    //CHG-V-LR:26
    this.responseDtoForEwayApi.token1 = this.userDataDtoReturnSession.mainAdminStation;
    this.ewaybillService.getDetailsFromEwayBillRefreshEwb(this.responseDtoForEwayApi).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        this.lrtDtoRespForEwayApi = response;
        console.log(this.lrtDtoRespForEwayApi);
        //CHG-V-LR:28
        //CHG-V-LR:42
        // if (this.lrtDtoRespForEwayApi.status == "Invalid Destination") {
        //   swal("Not Allowed", "Destination details not found for this Consignee Please Contact!", "warning");
        // }
        if (this.lrtDtoRespForEwayApi.ewayBillStatus != null && this.lrtDtoRespForEwayApi.ewayBillStatus == "CANCEL") {
          swal("Not Allowed", "This E-Way Bill Number : " + this.enteredEWayBillNo + "  show as Cancelled!.. Please Verify entered E-Way Bill No!", "warning");
          $("#" + this.pageId + "eWayBillNo").val('');
          this.enteredEWayBillNo = null;
        } else if (this.lrtDtoRespForEwayApi.isBlockedEwayBill) {
          swal("Not Allowed", "The entered E-Way Bill No : " + this.enteredEWayBillNo + " is expiring today!.. Please do the extension to continue with the LR Generation.", "warning");
          $("#" + this.pageId + "eWayBillNo").val('');
          this.enteredEWayBillNo = null;
        } else {
          if (this.lrtDtoRespForEwayApi.respStatus == 1) {
            $("#" + this.pageId + "entryPinCode").val(this.lrtDtoRespForEwayApi.pinCode);
            $("#" + this.pageId + "consignorGSTNumber").val(this.lrtDtoRespForEwayApi.gstNoConsignor);
            $("#" + this.pageId + "consigneeGSTNumber").val(this.lrtDtoRespForEwayApi.gstNoConsignee);
            $("#" + this.pageId + "goodsValue").val(this.lrtDtoRespForEwayApi.goodsValue);
            $("#" + this.pageId + "eWayBillValidUptoDate").val(this.lrtDtoRespForEwayApi.ewayBillValidUptoStr);

            //CHG-V-LR:19
            if (this.enableEwayBillPartBUpdateFlow) {
              if (this.lrtDtoRespForEwayApi.ewayBillValidUptoStr == null || this.lrtDtoRespForEwayApi.ewayBillValidUptoStr == '' || this.lrtDtoRespForEwayApi.ewayBillValidUptoStr == undefined) {
                this.ewayPartBUpdatePopup(contentVehicleUpdate);
              }
            }
            // this.callServicesAfterEwaybillDetails();
          } else {
            //swal("Not Allowed", "Unable to get the detials for the Ewaybill Number '" + this.enteredEWayBillNo + "' and seems its not linked with SRD, please verify and enter the valid number! ", "warning");
            //$("#" + this.pageId + "eWayBillNo").val('');
            $("#lrefcentryPinCode").focus();
            console.log('getDetailsFromEwayBillRefreshEwb : no ewaybill details found!');
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Problem Occurred While getting the details from the EWay Bill Number", "warning");
      }, () => console.log('done');
  }

  clearEwayBillDetails() {
    $("#" + this.pageId + "entryPinCode").val('');
    $("#" + this.pageId + "consignorGSTNumber").val('');
    $("#" + this.pageId + "consignorName").val('');
    $("#" + this.pageId + "consignorMobNoExist").val('');
    $("#" + this.pageId + "consignorAddress").val('');
    this.controlConsignor.reset();
    this.consignorOptions = [];
    this.consignorTA = [];

    $("#" + this.pageId + "consigneeGSTNumber").val('');
    $("#" + this.pageId + "consigneeName").val('');
    $("#" + this.pageId + "consigneeMobNoExist").val('');
    $("#" + this.pageId + "consigneeAddress").val('');
    this.consigneeOptions = [];
    this.consigneeTA = [];

    $("#" + this.pageId + "goodsValue").val('');
    $("#" + this.pageId + "eWayBillValidUptoDate").val('');
    $("#" + this.pageId + "destination").val('');
    this.destinationOptions = [];
    this.destinationTA = [];
  }

  //CHG-V-LR:36
  callServicesAfterEwaybillDetails(openPopupForAadhar) {
    this.validatePincode();
    //CHG-V-LR:36
    this.getConsignorDetails(openPopupForAadhar);

    //CHG-V-LR:34
    // window.setTimeout(function () {
    //   $("#lrefcdestination").focus();
    // }, 100);

  }

  validateAgentNameSelection() {
    this.isAgentNameValid = false
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();
    console.log('validateAgentNameSelection');
    console.log(this.selectedAgentName);
    for (let i = 0; i < this.lrDtosUserGodownOptions.length; i++) {
      if (this.lrDtosUserGodownOptions[i].subStations == this.selectedAgentName) {
        this.isAgentNameValid = true;
      }
    }
  }

  //CHG-V-LR:15
  validateDefaultCFTFlowForDestLessParty() {
    this.destinationForCFTValidate = $("#" + this.pageId + "destination").val();
    this.commaSplitDestForCFTValidate = this.destinationForCFTValidate.split("-");
    this.destSplitForCFTValidate = this.commaSplitDestForCFTValidate[0];
    console.log('validateDefaultCFTFlowForDestLessParty');
    console.log(this.selectedDestination);
    console.log(this.destinationForCFTValidate);
    this.requestDto = new RequestDto();
    this.requestDto.param1 = this.destSplit;
    this.requestDto.param2 = this.userDataDtoReturnSession.companyId;
    this.isDefaultCftFlowDisableForLessParties = false;

    this.masterReadService.validateDefaultCFTFlowForDestinationLessParty(this.requestDto).subscribe(
      (response) => {
        this.isDefaultCftFlowDisableForLessParties = response.flag1;
      }), (error) => {
        swal("Error", "Server problem occurred while validating the default CFT Flow For Destination Less Party", "warning");
      }, () => console.log('done');
  }
  //CHG-V-LR:19
  ewayPartBUpdatePopup(contentVehicleUpdate) {
    this.modalService.open(contentVehicleUpdate, { centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  validateEwayPartBUpdate() {
    this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
    this.partBVehicleNumber = $("#" + this.pageId + "partBVehicleNo").val();
    console.log('validateEwayPartBUpdate - ' + this.partBVehicleNumber);
    if (this.partBVehicleNumber == null || this.partBVehicleNumber == '' || this.partBVehicleNumber == undefined
      || this.partBVehicleNumber.length < 7 || this.partBVehicleNumber.length > 11) {
      swal({
        title: "Not Allowed",
        text: "Please enter the Valid Vehicle Number!",
        icon: "warning",
        closeOnClickOutside: true,
        closeOnEsc: true,
      });
    } else {
      swal({
        title: "Confirm Update",
        text: "Sure you want to update the Part-B for the Ewaybill No : " + this.enteredEWayBillNo,
        icon: "info",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((yesBtn) => {
        if (yesBtn) {
          this.ewayBillPartBUpdate();
        } else {
          swal.close();
        }

      })
    }
  }

  ewayBillPartBUpdate() {
    this.enteredlrNumber = $("#" + this.pageId + "lrNumber").val();
    this.lrtDtoRespForEwayApi.vehicleNumber = this.partBVehicleNumber;
    this.lrtDtoRespForEwayApi.fromStation = this.userDataDtoReturnSession.office;
    this.lrtDtoRespForEwayApi.lrNumber = this.enteredlrNumber;
    this.showSpinnerForAction = true;
    this.ewaybillService.ewaybillPartBUpdate(this.lrtDtoRespForEwayApi).subscribe(
      (response) => {
        if (response.status == "Success") {
          swal("Completed", "Part-B successfully updated!", "info");
          $("#" + this.pageId + "eWayBillValidUptoDate").val(response.ewayBillValidUptoStr);
        } else {
          swal("Failed", "Failed to update the Part-B, please try again or contact Admin!", "error");
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Error while updating the Part-B!", "error")
      }, () => console.log('done');
  }

  getConsigneeRateDetails() {
    let enterBookingDate = null;
    enterBookingDate = $("#" + this.pageId + "bookingDate").val();
    this.enteredBookingDate = this.datePipe.transform(enterBookingDate, "yyyy-MM-dd");

    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];

    this.enteredSource = $("#" + this.pageId + "source").val();

    this.rateDtoForConsigneeRateDetails = new RateMasterDto();
    this.rateDtoForConsigneeRateDetails.rateType = "Admin";
    this.rateDtoForConsigneeRateDetails.source = this.enteredSource;
    this.rateDtoForConsigneeRateDetails.consigneeId = this.selectedConsigneeId;
    this.rateDtoForConsigneeRateDetails.city = this.destSplit;
    this.rateDtoForConsigneeRateDetails.commodityName = this.selectedPartyCommodity;
    this.rateDtoForConsigneeRateDetails.bookingDate = this.enteredBookingDate;
    this.rateDtoForConsigneeRateDetails.companyId = this.userDataDtoReturnSession.companyId;

    this.masterReadService.getConsigneeRateDetailsForCashMemoService(this.rateDtoForConsigneeRateDetails).subscribe(
      (response) => {
        console.log('getConsigneeRateDetails');
        console.log(response);
        if (response.length > 0) {
          this.consigneeIdRateId = response[0].id;
        }
      })
  }

  validateEwayBillRefreshEwb = () => {
    this.showSpinnerForAction = true;
    this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
    this.lrtDtoRespForEwayApi = new LRDto();
    this.responseDtoForEwayApi = new ResponseDto();
    this.responseDtoForEwayApi.ewayBillNo = this.enteredEWayBillNo;
    this.ewaybillService.getDetailsFromEwayBillRefreshEwb(this.responseDtoForEwayApi).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        this.lrtDtoRespForEwayApi = response;
        if (this.lrtDtoRespForEwayApi.respStatus == 1) {

        } else {
          swal("Not Allowed", "Unable to get the detials for the Ewaybill Number '" + this.enteredEWayBillNo + "' and seems its not linked with SRD, please verify and enter the valid number! ", "warning");
          $("#" + this.pageId + "eWayBillNo").val('');
          console.log('no ewaybill details found!');
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Problem Occurred While getting the details from the EWay Bill Number", "warning");
      }, () => console.log('done');
  }
  //CHG-V-LR:23
  calcBaseFreightWithPerKGRateChg(perKgRate) {

    var lrEntryCharges = 0.0, perKGRateChg = 0.0, baseFreight = 0.0, actualWgt = 0.0, baseFreightReturn = 0.0;
    baseFreight = parseFloat($("#" + this.pageId + "baseFreight").val().toString()) || 0.0;
    console.log("baseFreight" + baseFreight);
    actualWgt = parseFloat($("#" + this.pageId + "actualWeight").val().toString()) || 0.0;
    console.log("actualWgt" + actualWgt);
    if (baseFreight > 0 && actualWgt > 0) {
      lrEntryCharges = baseFreight / actualWgt;
      console.log("lrEntryCharges" + lrEntryCharges);
      perKGRateChg = perKgRate == null ? 0.0 : perKgRate;
      console.log("perKGRateChg" + perKGRateChg);
      if (lrEntryCharges > perKGRateChg) {
        baseFreightReturn = actualWgt * perKGRateChg;
        console.log("baseFreightReturn" + baseFreightReturn);
        $("#" + this.pageId + "baseFreight").val(baseFreightReturn.toFixed(0));
        console.log("Base field" + $("#" + this.pageId + "baseFreight").val());
        this.sum();
        //Chg_V-Lr:32
        this.isPerKgLimitTaken = true;
      }
    }
    console.log("lrEntryCharges>>>" + lrEntryCharges);
    console.log("baseFreightReturn" + baseFreightReturn);
  }
  callPerkgRateDetails() {

    this.showSpinnerForAction = true;
    this.enteredSource = $("#" + this.pageId + "source").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.stateByDest = this.commaSplitDest[1];
    this.lrDtoPerKgGet = new LRDto();
    this.lrDtoPerKgGet.source = this.enteredSource;
    this.lrDtoPerKgGet.destination = this.destSplit;
    this.lrDtoPerKgGet.companyId = this.userDataDtoReturnSession.companyId;

    this.masterReadService.getPerKgRateDetailsForLR(this.lrDtoPerKgGet).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        this.lrtDtoPerKgRateReturn = response;
        if ((this.lrtDtoPerKgRateReturn != null) &&
          (this.lrtDtoPerKgRateReturn.hirePerKg != null && this.lrtDtoPerKgRateReturn.hirePerKg > 0)) {
          this.calcBaseFreightWithPerKGRateChg(this.lrtDtoPerKgRateReturn.hirePerKg);
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Problem Occurred While getting the details from Per KG Charge", "warning");
      }, () => console.log('done');

  }
  getEwayBillValidUptoFromApi(event) {
    console.log("Popup Multi eway" + event)
    this.showSpinnerForAction = true;
    var enteredEWayBillNo = $("#" + this.pageId + "eWayBillNoMulti").val().toString();
    this.lrtDtoRespForEwayApi = new LRDto();
    this.responseDtoForEwayApi = new ResponseDto();
    this.responseDtoForEwayApi.ewayBillNo = enteredEWayBillNo;
    this.ewaybillService.getDetailsFromEwayBillRefreshEwb(this.responseDtoForEwayApi).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        this.lrtDtoRespForEwayApi = response;
        if (this.lrtDtoRespForEwayApi.ewayBillStatus != null && this.lrtDtoRespForEwayApi.ewayBillStatus == "CANCEL") {
          swal("Not Allowed", "This E-Way Bill Number : " + this.enteredEWayBillNo + "  show as Cancelled!.. Please Verify entered E-Way Bill No!", "warning");
          $("#" + this.pageId + "eWayBillNoMulti").val('');
          this.enteredEWayBillNo = null;
        } else if (this.lrtDtoRespForEwayApi.isBlockedEwayBill) {
          swal("Not Allowed", "The entered E-Way Bill No : " + this.enteredEWayBillNo + " is expiring today!.. Please do the extension to continue with the LR Generation.", "warning");
          $("#" + this.pageId + "eWayBillNoMulti").val('');
          this.enteredEWayBillNo = null;
        } else {
          if (this.lrtDtoRespForEwayApi.respStatus == 1) {
            if (this.lrtDtoRespForEwayApi.ewayBillValidUptoStr == null || this.lrtDtoRespForEwayApi.ewayBillValidUptoStr == undefined) {
              swal({
                title: "Warning",
                text: "There is no 'valid upto date' available for this E-way Bill number",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
              })
            } else {
              $("#" + this.pageId + "eWayBillValidUptoDateMulti").val(this.lrtDtoRespForEwayApi.ewayBillValidUptoStr);
              this.chkDuplicateMultiEwayBillNo();
            }
          } else {

            swal({
              title: "Warning",
              text: "No records found for this E-way Bill number !",
              icon: "warning",
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Problem Occurred While getting the details from the EWay Bill Number", "warning");
      }, () => console.log('done');
  }

  //CHG-V-LR:38
  validateGstNoField() {
    if (this.partyType == "consignor") {
      this.enteredGstNumberSearch = $("#" + this.pageId + "consignorGSTNumber").val();
    } else if (this.partyType == "consignee") {
      this.enteredGstNumberSearch = $("#" + this.pageId + "consigneeGSTNumber").val();
    }
    if (!this.gstValidation.test(this.enteredGstNumberSearch)) {
      swal("Invalid", "Invalid GST Number", "warning");
      return false;
    } else {
      this.getDetailsFromEwayBillSearchByGstNoMethod();
    }
  }

  //CHG-V-LR:29
  setPartyDetailsForGstNumberSearch() {
    this.responseDtoForGstApi = new ResponseDto();
    this.responseDtoForGstApi.token2 = this.userDataDtoReturnSession.mainAdminStation;
    if (this.partyType == "consignor") {
      this.enteredGstNumber = $("#" + this.pageId + "consignorGSTNumber").val();
      this.responseDtoForGstApi.token1 = this.enteredGstNumber;
      this.responseDtoForGstApi.message = "Consignor Party";
    } else if (this.partyType == "consignee") {
      this.enteredGstNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
      this.responseDtoForGstApi.token1 = this.enteredGstNumber;
      this.responseDtoForGstApi.message = "Consignee Party";
      //CHG-V-LR:35
      this.enteredPincode = $("#" + this.pageId + "entryPinCode").val();
      this.responseDtoForGstApi.pincode = this.enteredPincode;
    }
    this.responseDtoForGstApi.type = 'Gst';
  }

  //CHG-V-LR:29
  getDetailsFromEwayBillSearchByGstNoMethod() {
    this.setPartyDetailsForGstNumberSearch();
    console.log(this.responseDtoForGstApi);
    this.showSpinnerForAction = true;
    this.ewaybillService.getDetailsFromEwayBillSearchByGstNo(this.responseDtoForGstApi).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        this.lrtDtoRespForGstNo = response;
        console.log(this.lrtDtoRespForGstNo);
        if (this.lrtDtoRespForGstNo.chkStatus == "Success") {
          this.getConsignorDetailsAfterNewAdd();
          window.setTimeout(function () {
            $("#lrefcconsignorName").focus();
          }, 100);
          this.partyType = null;
          console.log(this.partyType);
        } else if (this.lrtDtoRespForGstNo.dummyStatus == "Success") {
          this.getConsigneeDetailsAfterNewAdd();
          window.setTimeout(function () {
            $("#lrefcconsigneeName").focus();
          }, 100);
          this.partyType = null;
          console.log(this.partyType);
        }
        if (this.lrtDtoRespForGstNo.respStatus != 1) {
          this.swalTextMessage = "GST Details not available in the gst portal please verify the GST No"
        } else if (this.lrtDtoRespForGstNo.chkStatus == "Failed") {
          this.swalTextMessage = "Failed to Add Consignor Details!"
        } else if (this.lrtDtoRespForGstNo.dummyStatus == "Failed") {
          this.swalTextMessage = "Failed to Add Consignee Details!"
        } else if (this.lrtDtoRespForGstNo.dummyStatus == "Invalid Destination") {
          swal("Not Allowed", "Invalid Destination Please Check the Pincode!", "warning");
        }
        if (this.lrtDtoRespForGstNo.respStatus != 1 || this.lrtDtoRespForGstNo.chkStatus == "Failed"
          || this.lrtDtoRespForGstNo.dummyStatus == "Failed") {
          swal({
            title: "Error",
            text: this.swalTextMessage,
            icon: "error",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the details from the Gst Number", "warning");
      }, () => console.log('done');
  }

  getCommodityKgLimitDetails(basicRate) {
    this.commodityKglimit = 0;
    this.isSizeCommodityExceed = false;
    this.enteredChargedWeight = $("#" + this.pageId + "chargedWeight").val();
    this.rateMasterDtoGet = new RateMasterDto();
    this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
    this.selectedAgentName = $("#" + this.pageId + "agentName").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.totArticles = $("#" + this.pageId + "noOfArticles").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.rateMasterDtoGet.destination = this.destSplit;
    this.rateMasterDtoGet.commodityName = this.selectedPartyCommodity;

    if (this.viewByAutoLr == true || this.lrCheck == true) {
      this.rateMasterDtoGet.source = this.userDataDtoReturnSession.mainStation;
    } else {
      this.rateMasterDtoGet.source = this.selectedAgentName;
    }
    this.rateMasterDtoGet.companyId = this.userDataDtoReturnSession.companyId;
    this.masterReadService.getCommoditySizeExceedDetailsForLR(this.rateMasterDtoGet).subscribe(
      (response) => {
        console.log(response);
        var chgWgtPerArt: any;
        this.commodityKgLimitData = response;
        console.log("this.commodityKgLimitData" + this.commodityKgLimitData);
        chgWgtPerArt = this.enteredChargedWeight / this.totArticles;
        console.log("chgWgtPerArt" + chgWgtPerArt);
        if (this.commodityKgLimitData != null) {
          this.commodityKglimit = this.commodityKgLimitData.commodityKgLimit;
          console.log(" this.commodityKglimit" + this.commodityKglimit);
          //Chg_V-Lr:30
          if (this.commodityKglimit != null && this.commodityKglimit) {
            if (parseInt(chgWgtPerArt) > parseInt(this.commodityKglimit)) {
              // swal({
              //   title: "Alert",
              //   text: "Charged Weight is Greater then commodity Limit...Please Check the Charged Weight",
              //   icon: "warning",
              //   closeOnClickOutside: false,
              //   closeOnEsc: false,
              // });
              this.baseFreight = $("#" + this.pageId + "baseFreight").val();

              console.log("this.baseFreight" + this.baseFreight);
              console.log("this.commodityKglimit" + this.commodityKglimit);
              console.log("this.enteredChargedWeight" + this.enteredChargedWeight);
              // var calcRate = ((this.baseFreight / this.commodityKglimit) * this.enteredChargedWeight);
              var calcRate = ((this.baseFreight / this.commodityKglimit) * chgWgtPerArt);

              console.log("calcRate" + calcRate);
              this.baseFreight = calcRate;
              $("#" + this.pageId + "baseFreight").val(calcRate.toFixed(0));
              this.sum();
              this.isSizeCommodityExceed = true;
            } else {
              this.isSizeCommodityExceed = false;
              //Chg_V-Lr:33
              this.callPerkgRateDetails();
            }
          } else {
            //Chg_V-Lr:33
            this.callPerkgRateDetails();
          }

        } else {
          //Chg_V-Lr:33
          this.callPerkgRateDetails();
        }

        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal("Error", "Server problem occurred while getting the Commodity KG Limit Details!", "error");
        console.log(error.json())
      },
      () => console.log('done');
  }

  getConsignorDetailsAfterNewAdd = () => {
    this.getConsignorDetailsList();
    this.masterReadService.getConsignorDetailsMasterDaoNew(this.partyMasterDtoForconsignor).subscribe(
      (response) => {
        this.controlConsignor.reset();
        this.consignorOptions = response;
        this.consignorOptionsRet = this.consignorOptions
        this.consignorTA = [];
        for (let i = 0; i < this.consignorOptions.length; i++) {
          this.consignorTA.push(this.consignorOptions[i]);
        }
        this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
        $("#lrefcconsignorName").focus();
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };

  getConsigneeDetailsAfterNewAdd = () => {
    this.getConsigneeDetailsList();
    this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
      (response) => {
        this.controlConsignee.reset();
        this.consigneeOptions = response;
        this.consigneeOptionsRet = this.consigneeOptions
        this.consigneeTA = [];
        for (let i = 0; i < this.consigneeOptionsRet.length; i++) {
          this.consigneeTA.push(this.consigneeOptionsRet[i]);
        }
        $("#lrefcconsigneeName").focus();
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };


  searchByMode(searchBy: string) {
    if (searchBy === 'gst') {
      $("#" + this.pageId + "consigneeGSTNumber").val('');
      this.text = "Enter Consignee GST Number";
    } else if (searchBy === 'pan') {
      $("#" + this.pageId + "consigneeGSTNumber").val('');
      this.text = "Enter Pan Card Number";
    } else if (searchBy === 'aadhar') {
      $("#" + this.pageId + "consigneeGSTNumber").val('');
      this.text = "Enter Aadhar Card Number";
    } else {
      $("#" + this.pageId + "consigneeGSTNumber").val('');
      this.text = "Enter Consignee GST Number";
    }
  }

  getDetailsFromPanApiMethodForLrEntry() {
    this.requestDto = new RequestDto();
    if (this.partyType == "consignor") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consignorGSTNumber").val();
      this.requestDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
      this.requestDto.partyType = 'consignorParty';
    } else if (this.partyType == "consignee") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consigneeGSTNumber").val();
      this.enteredPincode = $("#" + this.pageId + "entryPinCode").val();
      this.requestDto.pincode = this.enteredPincode;
      this.requestDto.partyType = 'consigneeParty';
    }
    this.requestDto.param1 = this.enteredPanNumberSearch;
    this.requestDto.param2 = null;
    this.requestDto.param4 = this.userDataDtoReturnSession.mainStation;
    this.requestDto.param5 = this.userDataDtoReturnSession.office;
    this.requestDto.userId = this.userDataDtoReturnSession.userId;
    this.requestDto.type = 'LrEntry';
    this.requestDto.mode = 'fromLrEnty';
    this.requestDto.apiType = 'Pan';
    console.log(this.requestDto);
  }

  getDetailsFromPanApiServiceForLrEntry() {
    this.getDetailsFromPanApiMethodForLrEntry();
    this.showSpinnerForAction = true;
    this.changeDetectorRef.detectChanges();
    this.ewaybillService.getDetailsFromPanCardApiServiceForLrEntry(this.requestDto).subscribe((response) => {
      console.log(response);
      this.showSpinnerForAction = false;
      if (response.orgId != 1) {
        swal("Error", "Pan Details not available in the pan portal please verify the PAN No", "error");
      } else if (response.successful == "Success") {
        if (this.partyType == "consignor") {
          console.log(this.partyType);
          this.getConsignorDetailsAfterNewAdd();
          window.setTimeout(function () {
            $("#lrefcconsignorName").focus();
          }, 100);
          this.partyType = null;
          console.log(this.partyType);
        } else if (this.partyType == "consignee") {
          this.getConsigneeDetailsAfterNewAdd();
          window.setTimeout(function () {
            $("#lrefcconsigneeName").focus();
          }, 100);
          this.partyType = null;
          console.log(this.partyType);
        }
      } else if (response.successful == "Failed") {
        swal("Error", "Failed to Add Pan Details!", "error");
      } else if (response.successful == "Invalid Destination") {
        swal("Not Allowed", "Invalid Destination Please Check the Pincode!", "warning");
      }
      this.showSpinnerForAction = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Serve Error",
          "Failed to get Pan details, please try again or contact Admin!",
          "error"
        );
      },
      () => console.log("done");
  }


  validatePanNoField() {
    if (this.partyType == "consignor") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consignorGSTNumber").val();
    } else if (this.partyType == "consignee") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consigneeGSTNumber").val();
    }
    if (!this.panValidation.test(this.enteredPanNumberSearch)) {
      swal("Invalid", "Invalid PAN Number", "warning");
      return false;
    } else {
      this.getDetailsFromPanApiServiceForLrEntry();
    }
  }

  validateAadharNoField(openPopupForAadhar) {
    let enteredAadharNumber;
    if (this.partyType == "consignor") {
      enteredAadharNumber = $("#" + this.pageId + "consignorGSTNumber").val();
    } else if (this.partyType == "consignee") {
      enteredAadharNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
    }
    enteredAadharNumber = enteredAadharNumber.toString();
    enteredAadharNumber = enteredAadharNumber.replace(/\s+/g, '');
    if (enteredAadharNumber.length !== 12 || !/^[2-9][0-9]{11}$/.test(enteredAadharNumber)) {
      swal("Invalid", "Invalid Aadhar Number", "warning");
    } else {
      this.enteredAadharNumberSearch = enteredAadharNumber;
      this.getDetailsFromAadharApiServiceForLrEntry(openPopupForAadhar);
    }
  }

  getDetailsFromAadharApiMethodForLrEntry() {
    this.requestDtoForAadhar = new RequestDto();
    this.requestDtoForAadhar.param1 = this.enteredAadharNumberSearch;
    this.requestDtoForAadhar.userId = this.userDataDtoReturnSession.userId;
    this.showSpinnerForAction = true;
    console.log(this.requestDtoForAadhar);
  }


  getDetailsFromAadharApiServiceForLrEntry(openPopupForAadhar) {
    this.getDetailsFromAadharApiMethodForLrEntry();
    this.changeDetectorRef.detectChanges();
    this.ewaybillService.getDetailsFromAadharCardApiServiceForLrEntry(this.requestDtoForAadhar).subscribe((response) => {
      console.log(response);
      this.showSpinnerForAction = false;
      if (response.orgId == 1) {
        this.aadharDetails = response;
        this.modalService.open(openPopupForAadhar, { centered: true }).result.then(
          result => {
            this.closeResult = `Closed with: ${result}`;
          },
          reason => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      } else if (response.orgId != 1) {
        swal("Error", "Aadhar Details not available in the aadhar portal please verify the Aadhar No", "error");
      }
      this.showSpinnerForAction = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Serve Error",
          "Failed to validate the request, please try again or contact Admin!",
          "error"
        );
      },
      () => console.log("done");
  }

  getDetailsFromAadharMethod() {
    console.log(this.partyType);
    this.requestDtoAadharRes = new RequestDto();
    this.enteredOtp = $("#" + this.pageId + "otpId").val();
    this.enteredPincode = $("#" + this.pageId + "entryPinCode").val();
    if (this.partyType == "consignor") {
      this.requestDtoAadharRes.param2 = this.enteredAadharNumberSearch;
      this.requestDtoAadharRes.mainStation = this.userDataDtoReturnSession.mainAdminStation;
      this.requestDtoAadharRes.partyType = 'consignorParty';
    } else if (this.partyType == "consignee") {
      this.requestDtoAadharRes.param2 = this.enteredAadharNumberSearch;
      this.requestDtoAadharRes.partyType = 'consigneeParty';
      this.requestDtoAadharRes.pincode = this.enteredPincode;
    }

    this.requestDtoAadharRes.otp = this.enteredOtp;
    this.requestDtoAadharRes.param1 = this.aadharDetails.token1;
    this.requestDtoAadharRes.apiType = 'Aadhar';
    this.requestDtoAadharRes.param4 = this.userDataDtoReturnSession.mainStation;
    this.requestDtoAadharRes.param5 = this.userDataDtoReturnSession.office;
    this.requestDtoAadharRes.userId = this.userDataDtoReturnSession.userId;
    this.showSpinnerForAction = true;
    console.log(this.requestDtoAadharRes);
    this.changeDetectorRef.detectChanges();
  }

  getDetailsFromAadharApiMethod() {
    this.getDetailsFromAadharMethod();
    this.ewaybillService.getDetailsFromAadharApiAfterOtpServiceForLrEntry(this.requestDtoAadharRes).subscribe((response) => {
      console.log(response);
      this.showSpinnerForAction = false;
      if (response.orgId != 1) {
        swal("Error", "Aadhar Details not available in the aadhar portal please verify the Aadhar No", "error");
      } else if (response.successful == "Success") {
        this.modalService.dismissAll();
        if (this.partyType == "consignor") {
          console.log(this.partyType);
          this.getConsignorDetailsAfterNewAdd();
          window.setTimeout(function () {
            $("#lrefcconsignorName").focus();
          }, 100);
          this.partyType = null;
          console.log(this.partyType);
        } else if (this.partyType == "consignee") {
          this.getConsigneeDetailsAfterNewAdd();
          window.setTimeout(function () {
            $("#lrefcconsigneeName").focus();
          }, 100);
          this.partyType = null;
          console.log(this.partyType);
        }
      } else if (response.successful == "Failed") {
        swal("Error", "Failed to Add Aadhar Details!", "error");
      } else if (response.successful == "Invalid Destination") {
        swal("Not Allowed", "Invalid Destination Please Check the Pincode!", "warning");
      }
      this.showSpinnerForAction = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Serve Error",
          "Failed to Get Aadhar details, please try again or contact Admin!",
          "error"
        );
      },
      () => console.log("done");
  }

  selectedType(selectType: string) {
    if (selectType === 'gst') {
      $("#" + this.pageId + "consignorGSTNumber").val('');
      this.consignorTextField = "Enter Consignor GST Number";
    } else if (selectType === 'pan') {
      $("#" + this.pageId + "consignorGSTNumber").val('');
      this.consignorTextField = "Enter Pan Card Number";
    } else if (selectType === 'aadhar') {
      $("#" + this.pageId + "consignorGSTNumber").val('');
      this.consignorTextField = "Enter Aadhar Card Number";
    } else {
      $("#" + this.pageId + "consignorGSTNumber").val('');
      this.consignorTextField = "Enter Consignor GST Number";
    }
  }

  validationGetConsignorDetailsBasedOnSelectType(openPopupForAadhar) {
    let enteredNumber = $("#" + this.pageId + "consignorGSTNumber").val().toString().trim().replace(/\s+/g, '');
    this.selectedSearchByConsignor = $("#" + this.pageId + "selectType").val();

    if ((this.selectedSearchByConsignor === 'gst' && !this.gstValidation.test(enteredNumber))) {
      swal("Invalid", "Invalid GST Number", "warning");
    } else if (this.selectedSearchByConsignor === 'pan' && !this.panValidation.test(enteredNumber)) {
      swal("Invalid", "Invalid PAN Number", "warning");
    } else if (this.selectedSearchByConsignor === 'aadhar' && (enteredNumber.length !== 12 || !/^[2-9][0-9]{11}$/.test(enteredNumber))) {
      swal("Invalid", "Invalid Aadhar Number", "warning");
    } else {
      console.log(enteredNumber);
      this.selectedconsignorGSTNumber = enteredNumber;
      this.getConsignorDetails(openPopupForAadhar);
    }
  }

  //CHG-V-LR:41
  callCommodityBlockedDetails() {
    this.showSpinnerForAction = true;
    this.isCommodityBlockedBkg = false;
    this.dtoBlockedcmdty = new RateMasterDto();

    //CHG-V-LR:44
    if (this.commodityType == 'rateCommodity') {
      this.selectedPartyCommodity = $("#" + this.pageId + "partyCommodity").val();
    } else if (this.commodityType == 'actualCommodity') {
      this.selectedPartyCommodity = $("#" + this.pageId + "mainCommodity").val();
    }
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.commaSplitDest = this.selectedDestination.split("-");
    this.destSplit = this.commaSplitDest[0];
    this.enteredSource = $("#" + this.pageId + "source").val();
    this.dtoBlockedcmdty.source = this.enteredSource;
    this.dtoBlockedcmdty.destination = this.destSplit;
    this.dtoBlockedcmdty.commodityName = this.selectedPartyCommodity;
    this.dtoBlockedcmdty.companyId = this.userDataDtoReturnSession.companyId;
    console.log(" this.dtoBlockedcmdty" + this.dtoBlockedcmdty);
    console.log(this.dtoBlockedcmdty);
    this.masterReadService.getBlockedCommodityDetails(this.dtoBlockedcmdty).subscribe(
      (response) => {
        this.dtoBlockedCmdtyRtn = new ResponseDto();
        this.showSpinnerForAction = false;
        this.dtoBlockedCmdtyRtn = response;
        console.log(" this.dtoBlockedCmdtyRtn" + this.dtoBlockedCmdtyRtn);
        this.isCommodityBlockedBkg = this.dtoBlockedCmdtyRtn.blockedCommodity;
        if (this.isCommodityBlockedBkg) {
          swal("Not Allowed", this.selectedPartyCommodity + " commodity is blocked for booking, please contact admin!", "warning");
          //$("#" + this.pageId + "partyCommodity").val('');
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        swal("Error", "Server Problem Occurred While getting the details!", "warning");
      }, () => console.log('done');

  }

  //CHG-V-LR:41
  clickListnerForRateCommodity(e: NgbTypeaheadSelectItemEvent) {
    //alert('ok');
    this.commodityType = 'rateCommodity';
    this.callCommodityBlockedDetails();
  }

  //CHG-V-LR:44
  clickListnerForActualCommodity(e: NgbTypeaheadSelectItemEvent) {
    this.commodityType = 'actualCommodity';
    this.callCommodityBlockedDetails();
  }

  validateAadharNumberFormatConsignee() {
    this.enteredNewConsigneeGSTNo = $("#" + this.pageId + "gstNoConsignee").val().toString().trim().replace(/\s+/g, '');
    if (this.enteredNewConsigneeGSTNo.length !== 12 || !/^[2-9][0-9]{11}$/.test(this.enteredNewConsigneeGSTNo)) {
      return false;
    } else {
      return true;
    }

  }

  validateAadharNumberFormatConsignor() {
    this.enteredNewConsignorGSTNo = $("#" + this.pageId + "gstNoConsignor").val().toString().trim().replace(/\s+/g, '');
    if (this.enteredNewConsignorGSTNo.length !== 12 || !/^[2-9][0-9]{11}$/.test(this.enteredNewConsignorGSTNo)) {
      return false;
    } else {
      return true;
    }
  }

  //CHG-V-LR:48
  checkValidEwayBillNoOnSaveLrEntryDetails(content11) {
    this.showSpinnerForAction = true;
    this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
    this.lrtDtoRespForEwayApi = new LRDto();
    this.responseDtoForEwayApi = new ResponseDto();
    this.responseDtoForEwayApi.ewayBillNo = this.enteredEWayBillNo;
    this.ewaybillService.getDetailsFromEwayBillRefreshEwb(this.responseDtoForEwayApi).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        this.lrtDtoRespForEwayApi = response;
        console.log(this.lrtDtoRespForEwayApi);
        if (this.lrtDtoRespForEwayApi.ewayBillStatus != null && this.lrtDtoRespForEwayApi.ewayBillStatus == "CANCEL") {
          swal("Not Allowed", "This E-Way Bill Number : " + this.enteredEWayBillNo + "  show as Cancelled!.. Please Verify entered E-Way Bill No!", "warning");
        } else if (this.lrtDtoRespForEwayApi.isBlockedEwayBill) {
          swal("Not Allowed", "The entered E-Way Bill No : " + this.enteredEWayBillNo + " is expiring today!.. Please do the extension to continue with the LR Generation.", "warning");
        } else {
          this.validateBtnNextAfterCheckingChallanTotalLrs(content11);
        }
        this.showSpinnerForAction = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the details from the EWay Bill Number", "warning");
      }, () => console.log('done');
  }

  //CHG-V-LR:48
  validateNextBtnSaveLrEntryDetails(content11) {
    this.enteredEWayBillNo = $("#" + this.pageId + "eWayBillNo").val();
    if (this.enteredEWayBillNo != null && this.enteredEWayBillNo != '' && this.enteredEWayBillNo.length < 12) {
      swal({
        title: "Invalid E-Way Bill number",
        text: "Entered E-Way Bill number is Invalid, please verify it. If its not applicable then please remove it.",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else if (this.enteredEWayBillNo != null && this.enteredEWayBillNo != '') {
      this.checkValidEwayBillNoOnSaveLrEntryDetails(content11);
    } else {
      this.validateBtnNextAfterCheckingChallanTotalLrs(content11);
    }
  }

  //CHG-V-LR:50
  validatePanNoFieldForGetDetails() {
    if (this.partyType == "consignor") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consignorGSTNumber").val();
    } else if (this.partyType == "consignee") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consigneeGSTNumber").val();
    }
    if (!this.panValidation.test(this.enteredPanNumberSearch)) {
      swal("Invalid", "Invalid PAN Number", "warning");
      return false;
    } else {
      this.panAadharValidationService();
    }
  }

  //CHG-V-LR:50
  panAadharValidationService() {
    this.requestDtoGetPan = new RequestDto();
    if (this.partyType == "consignor") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consignorGSTNumber").val();
    } else if (this.partyType == "consignee") {
      this.enteredPanNumberSearch = $("#" + this.pageId + "consigneeGSTNumber").val();
    }
    this.requestDtoGetPan.param1 = this.enteredPanNumberSearch;
    this.requestDtoGetPan.param2 = null;
    this.requestDtoGetPan.param4 = this.userDataDtoReturnSession.mainStation;
    this.requestDtoGetPan.param5 = this.userDataDtoReturnSession.office;
    this.requestDtoGetPan.userId = this.userDataDtoReturnSession.userId;
    this.requestDtoGetPan.type = 'Invoice';
    console.log(this.requestDtoGetPan);
    this.showSpinnerForPopup = true;
    this.changeDetectorRef.detectChanges();
    this.ewaybillService.validatePanAadharLinkVerification(this.requestDtoGetPan).subscribe((response) => {
      this.showSpinnerForPopup = false;
      if (response.orgId != 1) {
        swal("Error", "Pan Details not available in the pan portal please verify the PAN No", "error");
      } else {
        this.panCardDetails = response;
        console.log(this.panCardDetails);
        if (this.partyType == "consignor") {
          console.log("Consignor");
          this.newConsignorName = this.panCardDetails.message;
        } else if (this.partyType == "consignee") {
          console.log("Consignee");
          this.newConsigneeName = this.panCardDetails.message;
        }
      }
      this.showSpinnerForPopup = false;
      this.changeDetectorRef.detectChanges();
    }),
      (error) => {
        this.showSpinnerForPopup = false;
        swal(
          "Serve Error",
          "Failed to getting PAN details, please try again or contact Admin!",
          "error"
        );
      },
      () => console.log("done");
  }

  beforePrint() {

    this.viewBarcodePrint = true;
    this.lrEntryForm = false;
    this.changeDetectorRef.detectChanges();
  }

  afterPrint2() {
    setTimeout(() => {

      this.viewBarcodePrint = false;
      this.lrEntryForm = true;
      this.PrintBarcode = [];
      this.clearInputFields();
      window.removeEventListener('beforeprint', this.beforePrint.bind(this));
      this.changeDetectorRef.detectChanges();
      this.showSpinnerForBarcode = false;
    }, 100);
  }

  getBarcodeEntryData(resultDto) {


    this.lrDtoBarcode = new LRDto();
    this.lrDtoBarcode.lrNumber = resultDto.lrnumber;
    this.lrDtoBarcode.totalArticles = resultDto.totalArticles;
    this.lrDtoBarcode.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoBarcode.entryBy = this.userDataDtoReturnSession.userId;

    console.log(this.lrDtoBarcode);
  }
  generateBarcode = (resultDto) => {
    this.getBarcodeEntryData(resultDto);
    this.showSpinnerForBarcode = true;

    this.lrService.addBarcodeDetails(this.lrDtoBarcode).subscribe(
      (response) => {

        console.log(response);
        this.lrDtoForLrBarcodeRet = new LRDto();
        this.lrDtoForLrBarcodeRet = response;

        if (this.lrDtoForLrBarcodeRet.status === "Already Exists") {
          swal({
            title: "Warning",
            text: "Barcode Already Generated for this LR",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
          this.showSpinnerForBarcode = false;
        } else if (this.lrDtoForLrBarcodeRet.status === "Success") {
          this.printBarcodes(this.lrDtoForLrBarcodeRet, resultDto);
        } else {
          swal({
            title: "Warning",
            text: "Failed to generate barcode",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        }

        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.showSpinnerForBarcode = false;
        swal("Error", "Server Problem Occurred adding barcode details", "info");
      },
      () => console.log('done')
    );
  };
  printBarcodes(lrDtoBarcode, resultDto) {
    this.PrintBarcode = [];
    let listOfBarcodes: string[] = [];

    // Prepare the data to be printed
    for (let i = 1; i <= lrDtoBarcode.listOfBarcodes.length; i++) {
      this.PrintBarcode.push({
        barcode: resultDto.lrnumber,
        Name: resultDto.lrnumber,
        Destination: resultDto.destination,
        ArticleIndex: `${i}/${resultDto.totalArticles}`
      });
    }


    this.showSpinnerForBarcode = false;
    this.viewBarcodePrint = true;
    this.lrEntryForm = false;
    this.changeDetectorRef.detectChanges();

    // Wait for DOM update to ensure printSection is rendered
    setTimeout(() => {
      const printSection = document.getElementById('printSection');
      if (printSection) {
        printSection.style.display = 'block';
        window.addEventListener('beforeprint', this.beforePrint.bind(this));
        window.addEventListener('afterprint', this.afterPrint2.bind(this), { once: true });
        window.print();
      } else {
        console.error("printSection element not found.");
      }
    }, 0);
  }


}
