//ChgV1_Imran = Changed path of rewarding screen for new update in rewar flow By Imran On 27062024

import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';

import { LoginComponent } from "src/app/authentication/login/login.component";
import {
    NgbModal,
    ModalDismissReasons,
    NgbPanelChangeEvent,
    NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
declare var $: any;

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements AfterViewInit {
    @Output() toggleSidebar = new EventEmitter<void>();

    public config: PerfectScrollbarConfigInterface = {};

    public showSearch = false;

    loggedInUserName: string;
    loggedInUser: string;
    branch: string;
    userRole: string;
    lastLogin: string;
    userDataDtoReturnSession: any;
    isLoggedIn = true;

    public modelActionType: any;
    //Imran

    showMenu = '';
    showSubMenu = '';




    searchmenuItems: Array<AgentDetailsDto> = [];
    searchmenuItemsAll: AgentDetailsDto = new AgentDetailsDto();
    focusToStationTA$ = new Subject<string>();
    toStationTA: Array<AgentDetailsDto> = [];
    agentDtoFromStationAll: AgentDetailsDto = new AgentDetailsDto();
    public modelToStation: any;
    searchToStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusToStationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.toStationTA
                : this.toStationTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterToStation = (x: { subStation: string }) => x.subStation;
    selectedActionType: any;
    constructor(private modalService: NgbModal, private router: Router) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //alert( 'Your session is expired, please relogin to access the application!' );
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.loggedInUserName = this.userDataDtoReturnSession.name;
            this.loggedInUser = this.userDataDtoReturnSession.userName;
            this.userRole = this.userDataDtoReturnSession.role;
            this.branch = this.userDataDtoReturnSession.office;
            this.lastLogin = this.userDataDtoReturnSession.lastLoginStr;
            //alert(this.userDataDtoReturnSession.lastLoginStr);
            console.log('ret: ' + this.userDataDtoReturnSession)
            this.scrrensToLoadAsPerRights();
        }


    }

    // This is for Notifications
    notifications: Object[] = [
        {
            btn: 'btn-danger',
            icon: 'ti-link',
            title: 'Luanch Admin',
            subject: 'Just see the my new admin!',
            time: '9:30 AM'
        },
        {
            btn: 'btn-success',
            icon: 'ti-calendar',
            title: 'Event today',
            subject: 'Just a reminder that you have event',
            time: '9:10 AM'
        },
        {
            btn: 'btn-info',
            icon: 'ti-settings',
            title: 'Settings',
            subject: 'You can customize this template as you want',
            time: '9:08 AM'
        },
        {
            btn: 'btn-primary',
            icon: 'ti-user',
            title: 'Pavan kumar',
            subject: 'Just see the my admin!',
            time: '9:00 AM'
        }
    ];

    // This is for Mymessages
    mymessages: Object[] = [
        {
            useravatar: 'assets/images/users/1.jpg',
            status: 'online',
            from: 'Pavan kumar',
            subject: 'Just see the my admin!',
            time: '9:30 AM'
        },
        {
            useravatar: 'assets/images/users/2.jpg',
            status: 'busy',
            from: 'Sonu Nigam',
            subject: 'I have sung a song! See you at',
            time: '9:10 AM'
        },
        {
            useravatar: 'assets/images/users/2.jpg',
            status: 'away',
            from: 'Arijit Sinh',
            subject: 'I am a singer!',
            time: '9:08 AM'
        },
        {
            useravatar: 'assets/images/users/4.jpg',
            status: 'offline',
            from: 'Pavan kumar',
            subject: 'Just see the my admin!',
            time: '9:00 AM'
        }
    ];

    ngAfterViewInit() { }

    signout() {
        sessionStorage.clear();
        this.router.navigate(['/authentication/login']);
    }
    scrrensToLoadAsPerRights() {
        this.toStationTA = [];
        for (let i = 0; i < this.userDataDtoReturnSession.listOfScreenNames.length; i++) {
            this.searchmenuItemsAll = new AgentDetailsDto();
            this.searchmenuItemsAll.subStation = this.userDataDtoReturnSession.listOfScreenNames[i];
            this.searchmenuItems.push(this.searchmenuItemsAll);
        }
        for (let i = 0; i < this.searchmenuItems.length; i++) {
            this.toStationTA.push(this.searchmenuItems[i]);
        }

        // this.toStationTA =this.agentDtoFromStationAll;
    }
    clearMethod() {
        this.modelToStation = '';
        $("#toStation").val('');
    }
    actionTypeFocus(e: NgbTypeaheadSelectItemEvent) {
        this.modelActionType = e.item;
        console.log(this.modelActionType);
        this.selectedActionType = this.modelActionType.subStation;
        console.log(this.selectedActionType);



        if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Local Trip') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/agentLocalTrip']);


        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Entry Form') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');

            this.router.navigate(['/lr/entry/lrentry']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Send SMS Group Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/communication/sendSmsGroupDetails']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');

            this.router.navigate(['/masters/consignee/consigneeMaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor Master') {
            this.selectedActionType = '';
            this.modelToStation = '';
            $("#toStation").val('');
            this.modelActionType = '';
            this.modelToStation = null;

            this.router.navigate(['/masters/consignor/consignorMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor Merge') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');

            this.router.navigate(['/masters/consignor/consignorMerge']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Bank') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');

            this.router.navigate(['/masters/fixing/bankMaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Expenses Heads') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');

            this.router.navigate(['/masters/fixing/expensesHeads']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Supplier Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');

            this.router.navigate(['/masters/supplier/suppliermaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Driver Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/driverMaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Nature Of Pack') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/natureOfPack']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LC and BC Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/lcAndBcReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Performance Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/truck-report/performanceReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Non Availability Of Truck') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/truck-report/nonAvailabilityOfTruckReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Agents Dispatch Performance Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/bookingAgentDispatchPerformanceReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks Unloading Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/stocksUnloadingReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Storage Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/storageReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks In Taken Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/stocksInTakenReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Pending Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/pendingStocksDetailsReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Godown Stocks Booking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/godownStocksBookingReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Godown Stocks') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/godownStocksReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/commodityReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Less Checked And Uncheck LR Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/statement-reports/partyLessCheckedAndUncheckedLrDetails']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Statement Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/statement-reports/statementRpt']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Mismatch Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/commodityMismatchReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Wise Total Status Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/partyWiseTotalStatusDetails']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Wise OS') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/partyWiseOsReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Less') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/partyLessReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Discontinuous Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/discontinuousDetailsReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Business Start Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/businessStartReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Business Stop Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/businessStopReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Rate History') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/rateHistoryReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Short And Extra') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/shortAndExtraReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrRpt']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'History Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrHistoryReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Dispatch Booking Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrDispatchBookingReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Lorry Hire Balance') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lorryHireBalance']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'G.C. Not Received') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/gcNotReceivedReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Local Trip Sheet') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/localTripSheet']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Hireslip Unloading Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/hireslipUnloadingReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Service Tax') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/serviceTaxReport']);
        }



        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Invoice Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/invoiceDetails']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Debit Note Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/debit/debitNoteReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Memo Less Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/memoLessSearchReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cheque Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/chequeDetailsReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cheque Search Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/chequeSearchReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cash Memo In Collection Man Stock') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/cashMemoInCollectionManStock']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LetterHead Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/letterHeadReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cashmemo Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/cashmemoDetails']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cash Memo Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/cashmemoreport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'SRD Collection Stock Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/srdCollectionStockReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Collection Man') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/collectionManReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Amount Receivable') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/amountreceivable']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Statement Verification') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/agents/statementVerification']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks Between Godowns') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/stocksBetweenGodown']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks to CP') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/stocksToCp']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'HireSlip Balance') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stock/payment/hireslipBalance']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Rewarding') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stock/driver/rewarding']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Manual Stock Check') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stock/customization/manualStockCheck']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Advance') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/enquiry/lrAdvance']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cash Memo Block') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/cashmemoBlock']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Payment Followup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/paymentFollowup']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Collection Man Cashmemo Stock Check') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/collectionManCashmemoStockCheck']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Collection Man To Collection Man Stock Transfer') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/collectionManToCollectionManStockTransfer']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Collection Man Stock Transfer') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/collectionManStockTransfer']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Transfer To Collection') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/transferToCollection']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Manual Cashmemo Check') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/manualCashmemoCheck']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cashmemo Transfer') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/cashmemoTransfer']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Billing To SRD Collection Stock') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/transfer-cashmemo/billingToSrdCollectionStock']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Dummy') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/generation/dummyCashmemo']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Generate Cash Memo') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/generation/generateCashmemo']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Tempo Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/agentTempoMaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Truck Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/truckMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Edit Company') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/editCompany']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Automated Search') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/automatedSearch']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Local Challan Details Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/localChallanReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Message') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Send SMS') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Common Charges') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/commonCharges']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Developer Page') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Staff Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/staffMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Station Creation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'User Creation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/userCreation']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'User Features Customization') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/userFeaturesCustomization']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'User Dashboard Customization') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'User Rights Customization') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Expenses Details Entry') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/expensesDetailsEntry']);
        }

        //new

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignments Claim Settlement Entry') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/company/consignmentsClaimSettlementEntry']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'C/nee Admin Rate Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignee/consigneeAdminRateMaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'C/nee Grouping') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignee/consigneeGrouping']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Merge') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignee/consigneeMerge']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Rate Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/consignee/rateMaster']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Service Tax Exemption') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'E-Declaration Password') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor Service Tax Exemption') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Destination Agent') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Agent') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Destination Type') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Features Customization') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Area') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Collection') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/collectionMan']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Debit Note Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/debitNoteMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Expenses Heads') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/expensesHeads']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Standard Rate') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'FOV Exemption Master for Booking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/fovExemptionMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'TDS Details Entry') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/tdsDetailsEntry']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Balance Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }


        //new2

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Get Hireslip Barcode Value') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/getHireslipBarcodeValue']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Lorry Hire Deduction Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/lorryHireDeductionSetupMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Point To Point Service Check') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/pointToPointServiceCheck']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Vehicle Setup Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Expenses Payment Followup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/expensesPaymentFollowupReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Routing Feature') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Reward Time Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/trip/time']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Truck Capacity') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Trip ETA Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/trip/tripEtaSetup']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Live Tracking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/vehicleTracking']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Tracking Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/transhipment/trackingLiveReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/commodity/commodityMaster']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'TP Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/commodity/tpMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Unloading Rate') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Routing') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Merge') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/commodity/commodityMerge']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Cash Memo') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/cashmemo/generation/bookingCashmemo']);
        }

        ///new3

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commission Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/agent/commissionmaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Amount Receivable From Agent') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/agent/amountReceivableFromAgent']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Destination Agent Statement Generation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/agent/destinationAgentStatementGeneration']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Commision Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/booking/agentCommissionMaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Statement Generation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/booking/agentStatementGeneration']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Memo Invoice Generation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Labour Commission Setup Mumbai') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/hamali-fortnightStatement/labourcommissionsetupmumbai']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'SRD Labour Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/hamali-fortnightStatement/srdlabourmaster']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'SRD Labour Stmt Generation') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/hamali-fortnightStatement/srdLabourStatementGeneration']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Balancing Difference Tally Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/others/balancingDifferenceTallyReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LRs Short & Extra') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/edit/lrsShortAndExtra']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Enquiry Form') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/enquiry/lrEnquiry']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'About Us') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/mobileApps/aboutus']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Latest News') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/mobileApps/latestnews']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Our Branches') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/mobileApps/ourbranches']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Route Mapping') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/mobileApps/routeMapping']);
        }



        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks Grouping') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/trip-creation/stocksGrouping']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Shipment Status') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/shipmentstatus']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks For Tripsheet') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stocks-for-tripsheet-page/stocksForTripsheet']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Ranking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/agents/ranking']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Expenses') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/agentExpenses']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Statement') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/agentStatement']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent TDS On Loading') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/bkgAgentTdsLdg']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Office Hireslip') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/officeHireslip']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Paid Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/paidreport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Total Less Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/totalLessRpt']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Bangalore Office Daily Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/daily-report/bangaloreOffice']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Daily Booking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/daily-report/dailyBookingReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Office Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/daily-report/officeReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Labour Statement Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/hamali-report/labourStatementReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Advance Payment') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/advancePayment']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Hireslip Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/hireslipDetails']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Transshipment Trip') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/hireslip-and-invoice-report/transhipmentTrip']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Lorry Hire Statement') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lorryHireStatement']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR History Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('/report/lr-report/lrHistoryReport ');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Issue Balance') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/issueBalance']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Issue Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrIssueReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Performance') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/lrPerformanceReport']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Challan Issue') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/challanIssueReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'EWay Bill Date Validation Extension Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/eWayBillDateValidationExtensionRpt']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'C/nee Admin Rate Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consigneeAdminRateReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Rate Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consigneeRateReport']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Admin Business Rate Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Local Challan Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/localChallanReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Loader Performance Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/loaderPerformanceReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Truck Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/truck-report/truckReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Halt And Dispatched Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/truck-report/haltAndDispatchedDetails']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Stocks For Local Booking') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/stockForLocalBooking']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consignorReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consigneeReport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Broker Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/hamali-report/brokerreport']);
        }


        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Edit') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/edit/lredit']);
        }

        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Less Check - Upload Type') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/others/partyLessCheckUploadType']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Challan') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/issue/challan']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Lr Issue') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/issue/lrissue']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Miss Agent') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/entry/missAgent']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignor') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consignorReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consignee Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/party-report/consigneeReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Agent Rpt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/bookingAgentReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Merge') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/commodity/commodityMerge']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Office Hireslip') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/bookings/officeHireslip']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Rate Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/fortnightStatement/agent/commodityratemaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Less Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['fortnightStatement/others/partyLessCheck']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Tempo Performance Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['report/others-report/tempoPerformanceReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Short & Extra Consolidate') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['report/lr-report/shortAndExtraConsolidateRpt']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Invoice Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['report/statement-reports/invoiceReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR DWS Weight Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['report/lr-report/lrDwsWgtRpt']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Booking Local Trip Barcode') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/stockForLocalBookingBarcode']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Debit Note Stmt') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['fortnightStatement/others/debitNoteStmt']);
        }
        else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Send SMS') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['masters/communication/sendSms']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Memo POD Upload') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/memoPodUpload']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'GST Invoice Detained') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/edit/gstInvoiceDetained']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Partywise CFT Details Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/partywiseCFTDetailsReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'GST Search') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/eway-bill/gstSearch']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Booking Date - Custom Change') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/lrBkgDateCustomChange']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Loading Invoice Edit') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/trip-creation/loadingInvoiceEdit']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Agent Substation Master') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/details/agentSubstationMaster']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Generate New EWB') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/eway-bill/generateNewEwb']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Edit LR Freight') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/edit/editLrFreight']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Rewarding') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stock/driver/rewardingNewVersion']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Partywise Performance Monitor') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['masters/company/partywisePerformanceMonitor']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Per Kg Rate Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');

            this.router.navigate(['/masters/fixing/perKgRateSetup']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Kg Limit Setup') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/commodityKgLimitSetup']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Commodity Booking Block') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/commodity/commodityBookingBlock']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Generate Barcode') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');

            this.router.navigate(['/lr/entry/generateBarcode']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'LR Blocked Partywise') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/masters/fixing/lrBlockedPartywise']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Destination Stocks Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/stocks-report/destinationStocksReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Uncleared Lr Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/stock/transfer/unclearedLrDetails']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Re Generate Barcode') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/entry/reGenerateBarcode']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Party Contact Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/others-report/partyContactDetails']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Short & Extra Overview') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['report/lr-report/shortAndExtraOverview']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cashmemo Online Payment Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/cashmemo-report/cashmemoOnlinePaymentDetails']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Freight Calculator') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/lr/enquiry/lrRateCalculator']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Freight Calculation Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/estimateEnquiryReport']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'EWB Search') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/eway-bill/ewbSearch']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Part B Update') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/eway-bill/partBUpdate']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Update Transporter Id') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/eway-bill/updateTransportedId']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Generate Consolidate EWB') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/eway-bill/generateConsolidateEWB']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Cancel EWB') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/eway-bill/cancelEWB']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Payment Link Trx Details') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['report/others-report/paymentLinkTrxDet']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'Consolidate EWB Print') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/eway-bill/consolidateEWBPrint']);
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'EWB Date Expiry Destination Report') {
            this.selectedActionType = '';
            this.modelActionType = '';
            this.modelToStation = null;
            $("#formid").val('');
            this.router.navigate(['/report/lr-report/ewbDateExpiryDestinationRpt']);
        }


        this.clearMethod();
    }
}

